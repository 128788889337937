import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _clone from 'lodash/clone'
import _map from 'lodash/map'
import {
  FETCH_SUPPORT_NOTES,
  CREATE_SUPPORT_NOTES,
  EDIT_SUPPORT_NOTES,
  DELETE_SUPPORT_NOTES
} from '../../../_common/redux/supportNotes/actions'
import { getTableColumns } from '../../utils/sortableTableUtils'
import SortableTable from '../../_library/SortableTable/index'
import Card from '../../_library/Card'
import TextArea from '../../_library/TextArea'
import SupportNotesModal from './supportNotesModal'
import Button from '../../_library/Button'

const SupportNotes = ({
  FETCH_SUPPORT_NOTES,
  CREATE_SUPPORT_NOTES,
  EDIT_SUPPORT_NOTES,
  DELETE_SUPPORT_NOTES,
  createLoading,
  editLoading,
  deleteLoading,
  match,
  event,
  user,
  supportNotes = []
}) => {
  const [showModal, setShowModal] = useState('')
  const [activeNote, setActiveNote] = useState({})

  const tableColumns = getTableColumns(
    [
      {
        key: 'note',
        label: 'Note',
        isSortable: true
      },
      {
        key: 'createdAt',
        label: 'Date Added',
        isSortable: true
      },
      {
        key: 'updatedAt',
        label: 'Last Updated',
        isSortable: true
      },
      {
        key: 'name',
        label: 'Created By',
        isSortable: true
      },
      {
        key: 'email',
        label: 'Creator Email',
        isSortable: true
      },
    ],
    getCurrencySymbol(event)
  )
  supportNotes = _map(supportNotes, item => {
    return { ...item, name: `${item.firstName} ${item.lastName}` }
  })

  useEffect(() => {
    const { id } = match.params
    FETCH_SUPPORT_NOTES(id)
  }, [])

  useEffect(() => {
    if (!createLoading && !editLoading && !deleteLoading) {
      handleClose()
    }
  }, [createLoading, editLoading, deleteLoading])

  const handleClose = () => {
    setShowModal('')
    setActiveNote({})
  }

  const createNote = values => {
    const { id } = match.params
    const payload = { data: { attributes: { note: values.note } } }

    CREATE_SUPPORT_NOTES(id, payload)
  }

  const editNote = values => {
    const { id: eventId } = match.params
    const { id: noteId } = activeNote
    const payload = { data: { attributes: { note: values.note } } }

    EDIT_SUPPORT_NOTES(eventId, noteId, payload)
  }

  const deleteNote = () => {
    const { id: eventId } = match.params
    const { id: noteId } = activeNote

    DELETE_SUPPORT_NOTES(eventId, noteId)
  }

  return (
    <>
      {showModal === 'create' && (
        <SupportNotesModal
          actionType="create"
          loading={createLoading}
          onSubmit={createNote}
          onClose={handleClose}
        />
      )}
      {showModal === 'edit' && (
        <SupportNotesModal
          actionType="edit"
          data={activeNote}
          loading={editLoading}
          onSubmit={editNote}
          onClose={handleClose}
        />
      )}
      {showModal === 'delete' && (
        <SupportNotesModal
          actionType="delete"
          loading={deleteLoading}
          onSubmit={deleteNote}
          onClose={handleClose}
        />
      )}
      <div className="text-right">
        <Button className="btn btn-success btn-shadow" onClick={() => setShowModal('create')}>
          <i className="fa fa-plus" aria-hidden="true" />
          Create New Note
        </Button>
      </div>
      <Card title={'Internal Notes'}>
        <SortableTable
          data={supportNotes}
          tableColumns={tableColumns}
          enableSort={true}
          sortBy={{ column: 'createdAt', asc: false }}
          disableMobileView={true}
          actions={[
            {
              label: 'Edit',
              className: 'btn btn-primary',
              hide: data => data.email !== user.email,
              onClick: data => {
                setActiveNote(data)
                setShowModal('edit')
              }
            },
            {
              label: 'Delete',
              className: 'btn btn-danger',
              hide: data => data.email !== user.email,
              onClick: data => {
                setActiveNote(data)
                setShowModal('delete')
              }
            }
          ]}
        />
      </Card>
    </>
  )
}

export default connect(
  state => {
    const supportNotes = _get(state, 'supportNotes.notes')
    const event = _get(state, 'events.selected')
    const user = _get(state, 'auth.user')
    const createLoading = state.loading.has('CREATE_SUPPORT_NOTES')
    const editLoading = state.loading.has('EDIT_SUPPORT_NOTES')
    const deleteLoading = state.loading.has('DELETE_SUPPORT_NOTES')
    return {
      supportNotes,
      event,
      user,
      createLoading,
      editLoading,
      deleteLoading
    }
  },
  { FETCH_SUPPORT_NOTES, CREATE_SUPPORT_NOTES, EDIT_SUPPORT_NOTES, DELETE_SUPPORT_NOTES }
)(SupportNotes)
