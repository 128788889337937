import _get from 'lodash/get'
import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import React from 'react'
import { connect } from 'react-redux'

import { Tab, TabView } from '../_library/TabView'

import TabIcon from './performance/TabIconTraffic'

import { toPascalCase } from '../../_common/core/utils'

import { get_event } from '../../_common/core/selectors'
import TrafficViews from './traffic/TrafficViews'
import TrafficLocations from './traffic/TrafficLocations'
import TrafficLanguages from './traffic/TrafficLanguages'
import TrafficConversions from './traffic/TrafficConversions'
import TrafficDevices from './traffic/TrafficDevices'
import TrafficOrigins from './traffic/TrafficOrigins'

const title_all = <TabIcon
  title="All"
  imgActive="/resources/images/event/performance/all-ico.svg"
  imgInactive="/resources/images/event/performance/inactive/all-ico.svg"
/>
const title_views = <TabIcon
  title="Views"
  imgActive="/resources/images/system_icons/details.svg"
  imgInactive="/resources/images/system_icons/inactive/details.svg"
  imgStyle={{ height: 10 }}
/>
const title_conversions = <TabIcon
  title="Conversions" 
  imgActive="/resources/images/event/influencers/ref_icon_3.svg"
  imgInactive="/resources/images/event/influencers/inactive/ref_icon_3.svg"
/>
const title_locations = <TabIcon
  title="Locations"
  imgActive="/resources/images/psychographics/Place.svg"
  imgInactive="/resources/images/psychographics/inactive/Place.svg"
/>
const title_languages = <TabIcon
  title="Languages"
  imgActive="/resources/images/system_icons/geographics.svg"
  imgInactive="/resources/images/system_icons/inactive/geographics.svg"
/>
const title_devices = <TabIcon
  title="Devices"
  imgActive="/resources/images/system_icons/devices.svg"
  imgInactive="/resources/images/system_icons/inactive/devices.svg"
/>
const title_origin = <TabIcon
  title="Referrer URL"
  imgActive="/resources/images/system_icons/check-in.svg"
  imgInactive="/resources/images/system_icons/inactive/check-in.svg"
/>

const tabs = event => [
  {
    component: (event, setShowWarning, showWarning) => <TrafficViews
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_views,
    path: 'views',
    url: `/event/${event.id}/traffic/views`
  },
  {
    component: (event, setShowWarning, showWarning) => <TrafficConversions
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_conversions,
    path: 'conversions',
    url: `/event/${event.id}/traffic/conversions`
  },
  {
    component: (event, setShowWarning, showWarning) => <TrafficLocations
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_locations,
    path: 'locations',
    url: `/event/${event.id}/traffic/locations`
  },
  {
    component: (event, setShowWarning, showWarning) => <TrafficLanguages
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_languages,
    path: 'languages',
    url: `/event/${event.id}/traffic/languages`
  },
  {
    component: (event, setShowWarning, showWarning) => <TrafficDevices
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_devices,
    path: 'devices',
    url: `/event/${event.id}/traffic/devices`
  },
  {
    component: (event, setShowWarning, showWarning) => <TrafficOrigins
      event={event}
      showWarning={showWarning}
      setShowWarning={setShowWarning}
    />,
    title: title_origin,
    path: 'origin',
    url: `/event/${event.id}/traffic/origin`
  }
]

@connect(state => {
  const event = get_event(state)
  return {
    event
  }
}, null)
export default class EventTraffic extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false
    }
    const {
      event,
      match: {
        params: { tabId }
      }
    } = props

    const titlePrefix = tabId ? toPascalCase(tabId) : 'Traffic'
    document.title = `${titlePrefix} - ${event.displayName} - The Ticket Fairy Dashboard`
  }

  componentDidUpdate(nextProps) {
    const {
      event,
      match: {
        params: { tabId }
      }
    } = nextProps

    if (tabId !== this.props.match.params.tabId) {
      const titlePrefix = tabId ? toPascalCase(tabId) : 'Traffic'
      document.title = `${titlePrefix} - ${_get(event, 'displayName', '')} - The Ticket Fairy Dashboard`
    }
  }

  setShowWarning = () => {
    this.setState({
      showWarning: true
    })
  }

  render() {
    const {
      showWarning
    } = this.state
    const {
      match: { params },
      event
    } = this.props

    const tabRoute = params.tabId
    const filteredTabs = tabs(event)
    const paths = _map(filteredTabs, item => item.url)
    const selectedIndex = tabRoute ? _findIndex(filteredTabs, t => t.path === tabRoute) + 1 : 0

    return (
      <div className="performance" id="performance">
        {showWarning ? (
          <div className='alert alert-warning alert-dismissible'>
            <p>
              We don&apos;t have enough data for your event, data might not be accurate for conversions
            </p>
          </div>
        ) : null}
        <TabView
          all={true}
          allTitle={title_all}
          headerClassName="performance-tab-header"
          bodyClassName="performance-tab-body"
          passProps
          hasBackground
          hasPerfectScrollBar
          selectedIndex={selectedIndex}
          paths={paths}
          basePath={`/event/${event.id}/traffic`}
          event={event}
        >
          {_map(filteredTabs, t => (
            <Tab key={t.path} title={t.title}>
              {t.component(event, this.setShowWarning, showWarning)}
            </Tab>
          ))}
        </TabView>
      </div>
    )
  }
}
