export const getDraftEventMenuItems = ({ isDraftEventsPage }) => {
  const menuGroup = []

  menuGroup.push({
    img: 'events',
    title: 'Draft Events',
    href: `/draft-events`,
    active: isDraftEventsPage,
    visible: true
  })

  return menuGroup
}
