import React from 'react'
import moment from 'moment'
import _startCase from 'lodash/startCase'
import _get from 'lodash/get'

import Card from '../../../../_library/Card'
import { Field as FormikField } from 'formik'
import Field from '../../../../_library/Field'
import { TextAreaField, SelectField, FileUploaderField } from '../../../../formik/Fields'
import {
  combineValidators,
  or_Validators,
  requiredValidator,
  isTagValidator
} from '../../../../../_common/core/validation'
import ZoomMeetingDetails from './ZoomMeetingDetails'
import { ENV } from '../../../../constants/env'
import DonationsSection from './DonationsSection'

const preEventContentPlaceholderTypeOptions = [
  {
    value: '',
    label: 'None'
  },
  {
    value: 'embed',
    label: 'Embed code'
  },
  {
    value: 'image',
    label: 'Image'
  }
]

export default class OnlineEventHostingDetails extends React.Component {
  handleIconClick = e => {
    const { values, updateField } = this.props
    const id = e.target.id || e.currentTarget.id

    if (id === values.selectedMeeting.provider) {
      updateField('selectedMeeting', { ...values.selectedMeeting, provider: null, embed: null })
      return
    }

    const embed = values.onlineDetails.provider === id ? values.onlineDetails.embed : ''
    updateField('selectedMeeting', { ...values.selectedMeeting, provider: id, embed })
  }

  getLabel = id => `${_startCase(id)} Stream Code`

  onPreEventContentPlaceholderTypeChange = () => {
    const { updateField } = this.props
    updateField('selectedMeeting.preEventContentPlaceholder', '')
  }

  isValidEmbed = value => !or_Validators(
    'Invalid Stream Code (iframe, embed, script)',
    isTagValidator(undefined, 'iframe'),
    isTagValidator(undefined, 'embed'),
    isTagValidator(undefined, 'script')
  )(value)

  render() {
    const {
      values,
      errors,
      touched,
      cardsStatus,
      updateField,
      isNew,
      submitCount,
      event
    } = this.props

    const preEventContentPlaceholderType = _get(event, 'onlineDetails.preEventContentPlaceholderType', '')
    const preEventContentPlaceholder = _get(event, 'onlineDetails.preEventContentPlaceholder', '')
    const published = _get(event, 'status', '') === 'published'

    const { provider } = values.selectedMeeting
    const showCodeField = provider && provider !== 'zoom'
    const showZoomMeetingDetails = provider && provider === 'zoom'
    const showMeetingError = errors.zoomMeeting && submitCount > 0
    const showZoomAccountError = errors.zoomAccount && submitCount > 0
    const showVideoPreview =
      !!values.selectedMeeting.embed &&
      (!errors.selectedMeeting || (errors.selectedMeeting && !errors.selectedMeeting.embed)) &&
      this.isValidEmbed(values.selectedMeeting.embed)

    return (
      <Card
        icon={'fa-map-marker'}
        title={'Hosting Details'}
        status={cardsStatus && cardsStatus.hostingDetails}
      >
        <div className="row hosting-details-container">
          <div className="col-sm-8 col-12">
            <div className="social-media">
              <img
                alt=""
                id="zoom"
                title="Zoom Meeting"
                src={asset('/resources/images/social/zoom-icon-image.png')}
                className={`zoom ${provider === 'zoom' ? 'selected' : ''}`}
                onClick={this.handleIconClick}
              />
              <img
                alt=""
                id="twitch"
                title="Twitch"
                src={asset('/resources/images/social/twitch-icon-image.png')}
                className={`twitch ${provider === 'twitch' ? 'selected' : ''}`}
                onClick={this.handleIconClick}
              />
              <img
                alt=""
                id="facebook"
                title="Facebook"
                src={asset('/resources/images/social/fb-icon-image.png')}
                className={`facebook ${provider === 'facebook' ? 'selected' : ''}`}
                onClick={this.handleIconClick}
              />
              <img
                alt=""
                id="youtube"
                title="Youtube"
                src={asset('/resources/images/social/youtube-icon-image.png')}
                className={`youtube ${provider === 'youtube' ? 'selected' : ''}`}
                onClick={this.handleIconClick}
              />
              <div
                id="other"
                className={`other-component ${provider === 'other' ? 'selected' : ''}`}
                onClick={this.handleIconClick}
              >
                <span> Other</span>
              </div>
            </div>
          </div>
          {!isNew ? (
            <div className="col-sm-4 col-12">
              <div className="btn-preview">
                <a
                  className="btn btn-seablue event-button-sm"
                  type="button"
                  href={`${ENV.API_CONSUMER}/event/${event.slug}/watch/preview/${event.previewKey}?mode=stream`}
                  target="_blank" rel="noreferrer"
                >
                  <img src={asset('/resources/images/viewpage-ico.svg')} />
                  Preview Stream
                </a>
              </div>
            </div>
          ) : null}
          <div className="div-spacing-20" />
          {showMeetingError ? (
            <div className="col-xs-12 col-12">
              <Field
                id="zoomMeeting"
                type="showOnlyError"
                value={''}
                error={errors.zoomMeeting}
                touched={true}
              />
            </div>
          ) : null}
          {showZoomAccountError ? (
            <div className="col-xs-12 col-12">
              <Field
                id="zoomAccount"
                type="showOnlyError"
                value={''}
                error={errors.zoomAccount}
                touched={true}
              />
            </div>
          ) : null}
          {showCodeField && (
            <div className="col-xs-12 col-12">
              <FormikField
                name="selectedMeeting.embed"
                label={this.getLabel(provider)}
                placeholder="Input the stream's HTML embed code"
                component={TextAreaField}
                touchedOnBlur={true}
                validate={combineValidators(published ? requiredValidator() : () => undefined)}
                style={{ maxWidth: '100%', minWidth: '100%' }}
                rows={4}
              />
            </div>
          )}
          {showZoomMeetingDetails ? (
            <ZoomMeetingDetails
              values={values}
              updateField={updateField}
              isNew={isNew}
            />
          ) : null}
          {showVideoPreview && (
            <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: values.selectedMeeting.embed }} />
          )}
          <div className="div-spacing-30" />
          <DonationsSection
            values={values}
            touched={touched}
            errors={errors}
            submitCount={submitCount}
            updateField={updateField}
          />
        </div>

        <div className="row">
          <br />
          <div className="col-sm-8 col-12">
            <div className="mw-400">
              <FormikField
                name="selectedMeeting.preEventContentPlaceholderType"
                label={'Pre-Event Content Placeholder Type'}
                component={SelectField}
                onChange={this.onPreEventContentPlaceholderTypeChange}
                options={preEventContentPlaceholderTypeOptions}
              />
            </div>
          </div>
          {!isNew &&
          !moment(event.startDate).isBefore(new Date()) &&
          preEventContentPlaceholderType &&
          preEventContentPlaceholder ? (
              <div className="col-sm-4 col-12">
                <div className="btn-preview">
                  <a
                    className="btn btn-seablue event-button-sm"
                    type="button"
                    href={`${ENV.API_CONSUMER}/event/${event.slug}/watch/preview/${event.previewKey}`}
                    target="_blank" rel="noreferrer"
                  >
                    <img src={asset('/resources/images/viewpage-ico.svg')} />
                  Preview Pre-Event Content
                  </a>
                </div>
              </div>
            ) : null}
        </div>
        <div style={{ padding: 0 }} className="col-xs-12 col-12">
          {_get(values, 'selectedMeeting.preEventContentPlaceholderType') === 'embed' ? (
            <FormikField
              name="selectedMeeting.preEventContentPlaceholder"
              label="Input stream code to run before event start date (optional)"
              placeholder="Input stream code to run before event start date"
              component={TextAreaField}
              touchedOnBlur={true}
              validate={combineValidators(published ? requiredValidator() : () => undefined)}
              style={{ maxWidth: '100%', minWidth: '100%' }}
              rows={4}
            />
          ) : null}
          <div style={{ padding: 0 }} className="col-xs-12 col-12">
            {_get(values, 'selectedMeeting.preEventContentPlaceholderType') === 'image' ? (
              <FormikField
                name="selectedMeeting.preEventContentPlaceholder"
                label="Add Image (optional)"
                filetype="image"
                component={FileUploaderField}
              />
            ) : null}
          </div>
        </div>
      </Card>
    )
  }
}
