import React from 'react'

// styles
import './preloader.css'
import _get from 'lodash/get'
import { getConfig } from '../../utils/configUtils'

const PreLoader = props => {
  const configFile = getConfig()

  const getContent = () => {
    const { isRnDomain, isMcDomain } = props
    const logo = _get(configFile, '*.appearance.assets.headerLogo', '/resources/images/ttf-logo.png')

    if (isRnDomain || isMcDomain) {
      return (
        <div>
          <img className="preloader-center__is-rn-image" src={asset(logo)} alt="No Data" />
          {isRnDomain && (
            <p className="preloader-center__text">
              POWERED BY
              <br />
              THE<strong>TICKET</strong>FAIRY
            </p>
          )}
        </div>
      )
    }

    return (
      <span>
        THE<strong>TICKET</strong>FAIRY
      </span>
    )
  }

  return (
    <div className="preloader-center">
      <div className="preloader-center__text_container">{getContent()}</div>
      <div className="preloader-ring" />
    </div>
  )
}

export { PreLoader }
