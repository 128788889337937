export default (isTicketTypeExist, selectedFee, loadingTickets) => [
  {
    value: 'forward',
    label1: 'Added',
    label2: 'Customer pays the booking fees and is shown an all-inclusive price on the event page',
    label3: '(most recommended)',
    disabled: (isTicketTypeExist && selectedFee === 'absorb') || !!loadingTickets
  },
  {
    value: 'absorb',
    label1: 'Absorbed',
    label2: 'You pay the booking fees, which are taken out of the face value price of the ticket',
    label3: '',
    disabled:
      (isTicketTypeExist && (selectedFee === 'forward' || selectedFee === 'forward_checkout')) ||
      !!loadingTickets
  },
  {
    value: 'forward_checkout',
    label1: 'Added Later',
    label2: 'Customer pays the booking fees and the final price is shown on the checkout page only',
    label3: '(least recommended)',
    disabled: (isTicketTypeExist && selectedFee === 'absorb') || !!loadingTickets
  }
]
