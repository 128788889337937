import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'

import { withFormik } from 'formik'

import Button from '../../_library/Button'
import { memberRoles } from './BrandTeamInvitationForm'

function validateMember(data) {
  const required = ['role']
  const errors = {}

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  return errors
}

class MyForm extends React.Component {
  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  changeRole = r => () => {
    const { setFieldValue } = this.props
    setFieldValue('role', r)
  }

  onTagsChange = tags => {
    const { setFieldValue } = this.props
    setFieldValue('emails', tags)
  }

  render() {
    const { submitLabel } = this.props
    const { values, isSubmitting, handleSubmit } = this.props
    return (
      <div className="brand-team-invitation-form">
        <div className="row">
          <div className="col-sm-12">
            <label>Role</label>
            {memberRoles.map((mr, index) => (
              <div className="team-new-member-role" key={index}>
                <input
                  type="radio"
                  id={'role-' + mr.value}
                  name="brand-team-role"
                  checked={values.role === mr.value}
                  onChange={this.changeRole(mr.value)}
                />
                <label htmlFor={'role-' + mr.value}>{mr.label}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center btn-toolbar">
          <Button
            type="submit"
            className="btn btn-success btn-lg btn-shadow"
            loading={isSubmitting}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {submitLabel}
          </Button>
          <Button
            className="btn btn-danger btn-lg btn-shadow"
            type="button"
            disabled={isSubmitting}
            onClick={this.handleCancel}
            loading={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}

const BrandEditTeamInvitationForm = withFormik({
  mapPropsToValues: props => ({
    role: _get(props, 'initialValues.attributes.role') || '',
  }),

  validate: values => validateMember(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        attributes: values,
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'BrandEditTeamInvitationForm', // helps with React DevTools
})(MyForm)

export default BrandEditTeamInvitationForm
