import { connect } from 'react-redux'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Logo } from '../newsignin/Logo'
import { Progress } from '../newsignin/Progress'
import { Titles } from '../newsignin/Titles'
import { BackgroundSignUp } from './BackgroundSignUp'
import EmailForm from './EmailForm'
import { Trademark } from './Trademark'
import { FETCH_SESSION } from '../../../_common/redux/auth/actions'
import { PreLoader } from '../../_library/PreLoader'

const NewSignUpPageContainer = ({ history, FETCH_SESSION, isRnDomain }) => {
  const [loadingUser, setLoadingUser] = useState(true)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await FETCH_SESSION({ hideError: true, noAppcues: true })
        if (session) {
          history.push('/')
        } else {
          setLoadingUser(false)
        }
      } catch(e) {
        setLoadingUser(false)
      }
    }
    localStorage.removeItem('createAccountState')
    fetchUser()
  }, [history, FETCH_SESSION])
  useEffect(() => {
    window.dataLayerInit()
    window.runGtagScript()
  }, [])
  return loadingUser ? 
    <PreLoader isRnDomain={isRnDomain} /> 
  : (
    <div className="absolute-container new-sign-up">
      <Logo />
      <Progress width={0} />
      <Titles />
      <BackgroundSignUp>
        <EmailForm />
      </BackgroundSignUp>
      <Trademark />
    </div>
  )
}

const NewSignUpPage = connect(state => {
  const { isRnDomain } = state.shared
  return {
    isRnDomain
  }
}, { FETCH_SESSION })(NewSignUpPageContainer)

export { NewSignUpPage }
