import axios from 'axios'
import { ENV } from '../../../web/constants/env'
import { deleteCookieByName, getCookieByName, setCustomCookie } from '../../../web/utils/cookies'
import _get from 'lodash/get'
import localforage from 'localforage'

let source = axios.CancelToken.source()

const headers = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
}

if (ENV.X_SOURCE_ORIGIN) {
  headers['X-Source-Origin'] = ENV.X_SOURCE_ORIGIN
}

export const baseRequest = axios.create({
  baseURL: `${ENV.API_BASE}/api`,
  withCredentials: true,
  headers,
})

baseRequest.interceptors.response.use(
  response => {
    const xTfCookie = _get(response, 'headers.x-tf-ecommerce')

    if (xTfCookie) {
      setCustomCookie('X-TF-ECOMMERCE', xTfCookie)
    }

    return response
  },
  error => {
    const status = _get(error, 'response.status')

    if (status === 401) {
      localStorage.removeItem('EventsPage')
      localStorage.removeItem('EventPagesFilterByBrand')
      localStorage.removeItem('isModalAlreadyShowed')
      localforage.setItem('events', [])

      source && source.cancel('cancel')
      source = axios.CancelToken.source()

      window.location.href = '/signin'
    }

    throw error
  },
)

baseRequest.interceptors.request.use(config => {
  if (getCookieByName('X-TF-ECOMMERCE')) {
    const updatedHeaders = {
      ...config.headers,
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    }
    config.headers = updatedHeaders
  }

  config.cancelToken = source.token
  return config
})

export const consumerRequest = axios.create({
  baseURL: `${ENV.API_CONSUMER}/api`,
  withCredentials: true,
  headers,
})

consumerRequest.interceptors.response.use(response => {
  const xtfCookie = _get(response, 'headers.x-tf-ecommerce')
  const url = _get(response, 'config.url')
  const method = _get(response, 'config.method')

  if (xtfCookie && !(url === '/auth' && method === 'delete')) {
    setCustomCookie('X-TF-ECOMMERCE', xtfCookie)
  } else {
    deleteCookieByName('X-TF-ECOMMERCE')
    source && source.cancel('cancel')
    source = axios.CancelToken.source()
  }

  return response
})

consumerRequest.interceptors.request.use(config => {
  if (getCookieByName('X-TF-ECOMMERCE')) {
    const updatedHeaders = {
      ...config.headers,
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    }
    config.headers = updatedHeaders
  }

  return config
})

// tickets
export {
  getEventTickets,
  getEventTicketsByGroup,
  getSeatMaps,
  getSeatMap,
  createSeatMap,
  deleteSeatMap,
  updateSeatMap,
  updateTicketsSorting,
  blockSeats,
  unblockSeats,
  getTicketTrackingData,
  createTicketTrackingData,
  deleteTicketTrackingData,
  regenerateTrackingToken,
  getTicketTrackingDataByToken,
  getTicketTypeOrders,
  deleteTicketType,
} from './tickets'

// brands
export { getBrands, getBrandPixelData, getBrandRoles, manageStripeConnectedAccount } from './brands'

// events
export {
  getEvents,
  getDraftEvents,
  updateEventStatus,
  fetchEventTraffic,
  pinEvents,
  fetchEventSupport,
  updateEventSupport,
  getEvent,
  createEvent,
  duplicateEvent,
  updateEvent,
  fetchSupportNotes,
  createSupportNotes,
  editSupportNotes,
  deleteSupportNotes,
  deleteEvent,
  fetchEventTrafficOriginsByDay,
} from './events'

// stripe countries
export { getStripeCountries, getStripeCountryStates } from './stripecountries'

// public
export { checkExistsCustomer } from './public'

// consumer
export { getCustomerProfile, customerLogin } from './consumer'

// performance
export { getPerfomanceData } from './performance'

// zoom
export { logOutZoomUser } from './zoom'

// storage
export { storage_set, storage_get, storage_all_get, storage_remove } from './storage'

// pre-registration
export { deletePreRegistrationActions } from './preregistration'

// buyers locations
export { getBuyersLocations } from './geographics'

// addons
export { createAddOn, createAddOnGroup, updateAddOn, updateAddOnsSorting, deleteAddOn } from './addons'

// alternativeview
export { getEventAlternativeViews } from './alternativeview'

// seatmap
export { fetchSeatMapTickets, bookSeat, deleteBookedSeat, moveReservedSeat } from './seatmap'

// check-in
export {
  getCheckedInData,
  getCheckedInDates,
  getCheckedInSlots,
  getCheckedInAddonsDates,
  getCheckedInAddOnsSlots,
} from './checkin'

export { toggleOrderResale, markOrderAsPaid, issueGuestAddOn } from './orders'

export { getCountries } from './countries'

// tours
export { getTourPerfomanceData } from './tours'

// verification
export { reVerify } from './verification'

// refferers
export { getInfluencerReferrersData } from './refferers'

export { getEventInvitations } from './invitations'

// payments connect
export { getPaymentConnectData, applyPaymentMethod, getStripeConnectLink } from './paymentconnect'

export {
  getCustomer,
  getCustomers,
  createCustomerNote,
  getCustomerMedias,
  createCustomerMedia,
  updateCustomerMedia,
  removeCustomerMedia,
} from './crm'

export { requestDashboardAccess, newBrand, newCustomer, sendBrandInvitation, updateCustomer } from './signup'

export {
  getAllPassesTypes,
  deletePassType,
  createPassType,
  editPassType,
  createEntryPass,
  getAllPasses,
  deletePass,
  sendPass,
  importEntryPass,
  sendAllPasses,
} from './multientrypasses'

export { getAllNotes, deleteNote, createNote, editNote } from './notes'

export {
  getContract,
  createContract,
  getContractStatus,
  deployContract,
  deleteContract,
  updateContract,
  getContractInteractionStatuses,
} from './nft'

export { getPromoters } from './promoters'

export {
  getPredefinedInvitations,
  createPredefinedInvitation,
  deletePredefinedInvitation,
  resendPredefinedInvitation,
} from './predefinedinvitations'
