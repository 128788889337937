import React from 'react'
import { Field } from 'formik'
import Card from '../../_library/Card'
import { CustomToggleField, SimpleField } from '../../formik/Fields'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { FETCH_BRAND } from '../../../_common/redux/brands/actions'
import CustomClipboardButton from '../../_library/CustomClipboardButton'


const Support = props => {
  const { useBrandContacts, brand, event, FETCH_BRAND } = props
  const id = event?.owner?.id

  useEffect(() => {
    if (id) {
      FETCH_BRAND(id)
    }
  }, [id, FETCH_BRAND])

  return (
    <Card /* icon={'fa-briefcase'} */ title={'Ticket Fairy Support'}>
      {useBrandContacts ? null : (
        <>
          <div className="row">
            <div className="col-xs-6 col-12" style={{ marginBottom: 5 }}>
              <Field
                name="externalSupportEmail"
                label="Contact Email for Escalations"
                helperText="External Use to Provide To Customers"
                component={SimpleField}
              />
            </div>
            <div className="col-xs-6 col-12" style={{ marginBottom: 5 }}>
              <Field
                name="internalSupportEmail"
                label="Point of Contact Email for TF Support"
                helperText="Internal Use Only"
                component={SimpleField}
              />
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-sm-12">
          <Field name="useBrandContacts" onText="Use Brand Contacts" component={CustomToggleField} />
        </div>
      </div>
      <div className='div-spacing-15'/>
      <div className="row">
        <div className="col-sm-12">
          <div style={{ fontWeight: 'bold' }}>Brand Contacts</div>
        </div>
      </div>
      <div className='div-spacing-15'/>
      <div className="row">
        <div className="col-sm-12">
          <div style={{ display: 'flex', flexDirection: 'row' }}>Contact Email for Escalations
            {brand?.externalSupportEmail ? <CustomClipboardButton
              id="eventUrlCopied"
              clipboardText={brand?.externalSupportEmail}
              tooltipBeforeCopyText="Copy Contact Email for Escalations"
              icon="fa-clipboard"
            /> : null}
          </div>
          {brand?.externalSupportEmail === undefined
            ? <i className="fa fa-spin fa-spinner" aria-hidden="true" />
            : <small style={{ fontWeight: 'bold' }}>{brand.externalSupportEmail || "Not provided"}</small> 
          }
        </div>
      </div>
      <div className='div-spacing-15'/>
      <div className="row">
        <div className="col-sm-12" >
          <div style={{ display: 'flex', flexDirection: 'row' }}>Point of Contact Email for TF Support
            {brand?.externalSupportEmail ? <CustomClipboardButton
              id="eventUrlCopied"
              clipboardText={brand?.externalSupportEmail}
              tooltipBeforeCopyText="Copy Point of Contact Email for TF Support"
              icon="fa-clipboard"
            /> : null}
          </div>
          {brand?.internalSupportEmail === undefined
            ? <i className="fa fa-spin fa-spinner" aria-hidden="true" />
            : <small style={{ fontWeight: 'bold' }}>{brand.internalSupportEmail || "Not provided"}</small> 
          }
        </div>
      </div>
    </Card>
  )
}

export default connect(state => {
  const brand = state.brands.selected
  return {
    brand
  }
},
{
  FETCH_BRAND
})(Support)
