import { createAsyncAction } from '../actions'
import {
  createAddOn,
  createAddOnGroup,
  deleteAddOn,
  getAddOns,
  updateAddOn,
  updateAddOnGroup,
  updateAddOnsSorting,
} from '../../core/http_services/addons'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_EVENT_ADDONS } = createAsyncAction('FETCH_EVENT_ADDONS', function (eventId) {
  return async dispatch => {
    try {
      const res = await getAddOns(eventId)
      dispatch(this.success({ addons: res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_ADDON } = createAsyncAction('CREATE_ADDON', function (eventId, form) {
  return async dispatch => {
    try {
      const res = await createAddOn(eventId, form)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_ADDON_GROUP } = createAsyncAction('CREATE_ADDON_GROUP', function (eventId, form) {
  return async dispatch => {
    try {
      const res = await createAddOnGroup(eventId, form)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { DELETE_ADDON } = createAsyncAction('DELETE_ADDON', function (addonId, eventId) {
  return async dispatch => {
    try {
      const res = await deleteAddOn(addonId, eventId)
      dispatch(this.success({ addonId, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_ADDON } = createAsyncAction('UPDATE_ADDON', function (addonId, form, eventId) {
  return async dispatch => {
    try {
      const updateData = {
        attributes: form,
        id: addonId,
        type: 'add_on',
      }

      const res = await updateAddOn(addonId, updateData)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_ADDON_GROUP } = createAsyncAction('UPDATE_ADDON_GROUP', function (addonId, form, eventId) {
  return async dispatch => {
    try {
      const res = await updateAddOnGroup(addonId, form, eventId)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_ADDONS_SORTING } = createAsyncAction(
  'UPDATE_ADDONS_SORTING',
  function (eventId, sortingAddons, addons, addOnsGroups) {
    return async dispatch => {
      try {
        await updateAddOnsSorting(eventId, addons)
        dispatch(this.success({ eventId, addons, addOnsGroups }))
      } catch (err) {
        showAxiosError(err)
      }
    }
  },
)

export {
  FETCH_EVENT_ADDONS,
  CREATE_ADDON,
  CREATE_ADDON_GROUP,
  UPDATE_ADDON,
  UPDATE_ADDON_GROUP,
  UPDATE_ADDONS_SORTING,
  DELETE_ADDON,
}
