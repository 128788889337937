import _ from 'lodash'
import { withFormik } from 'formik'
import React from 'react'

import Button from '../../_library/Button'
import Field from '../../_library/Field'
import Card from '../../_library/Card'

function validate(data) {
  const errors = {}

  const required = ['email', 'firstName', 'lastName']
  required.forEach((f) => {
    if (!_.get(data, f)) {
      _.set(errors, f, 'Required')
    }
  })

  if (!validateEmail(_.get(data, 'email'))) {
    _.set(errors, 'email', 'Invalid Email Address')
  }

  if (_.get(data, 'confirmEmail') !== _.get(data, 'email')) {
    _.set(errors, 'confirmEmail', 'Please confirm your email')
  }

  if (_.get(data, 'password') && _.get(data, 'password').length < 8) {
    _.set(errors, 'password', 'Password must be at least 8 characters')
  }

  if (_.get(data, 'confirmPassword') !== _.get(data, 'password')) {
    _.set(errors, 'confirmPassword', 'Please confirm your new password')
  }

  return errors
}

class MyForm extends React.PureComponent {
  render() {
    const {
      error,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Card icon={'fa-info'} title={'Account Settings'}>
          {!!error && (
            <div className="row">
              <div className="col-xs-12">
                <div className="alert alert-danger">{error}</div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Field
                id="firstName"
                label="First name"
                type="text"
                value={values.firstName}
                error={errors.firstName}
                touched={touched.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Field
                id="lastName"
                label="Last name"
                type="text"
                value={values.lastName}
                error={errors.lastName}
                touched={touched.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Field
                id="email"
                label="Email"
                type="email"
                hint="This will be your username"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Field
                id="confirmEmail"
                label="Email"
                type="email"
                hint="This will be your username"
                value={values.confirmEmail}
                error={errors.confirmEmail}
                touched={touched.confirmEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <p>Leave password fields empty to keep your existing password.</p>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Field
                id="password"
                label="Password"
                type="password"
                hint="Must be at least 8 characters"
                value={values.password || ''}
                error={errors.password}
                touched={touched.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Field
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                value={values.confirmPassword || ''}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Button type="submit" className="btn btn-primary btn-shadow" loading={isSubmitting}>
                Update
              </Button>
            </div>
          </div>
        </Card>
      </form>
    )
  }
}

const AccountSettingsForm = withFormik({
  mapPropsToValues: props => ({
    email: props.user.email || '',
    confirmEmail: props.user.email || '',
    firstName: props.user.firstName || '',
    lastName: props.user.lastName || '',
    password: '',
    confirmPassword: ''
  }),

  // Custom sync validation
  validate: values => validate(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        attributes: values
      })
      .finally(() => {
        setSubmitting(false)
      })
  },
  displayName: 'AccountSettingsForm' // helps with React DevTools
})(MyForm)

export default AccountSettingsForm
