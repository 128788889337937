import React from 'react'
import TagsField from '../../_library/TagsField'
import { PublicKey } from '@solana/web3.js'

export default function NFTLists({ errors, touched, nftLists, nftInput, setFieldValue, onTagsChange, size, inputDisabled }) {
  const isMobile = isMobileDevice()
  const solanaValidation = tag => {
    try {
      const address = new PublicKey(tag)
      return PublicKey.isOnCurve(address.toBuffer())
    } catch (e) {
      return false
    }
  }
  return (
    <>
      {size ? <div className='div-spacing-10' /> : null}
      <div className={`col-${size ?? 'md'}-12 nft-fields nft-lists`}>
        <label>Solana Mainnet</label>
        <TagsField
          autocomplete="new-password"
          inputDisabled={inputDisabled}
          tagsContainerClassName={`form-control${
            errors.emails && touched.emails ? ' form-control-error' : ''
          }`}
          name="solana-mainnet"
          value={nftLists}
          onChange={tags => onTagsChange('mainnet', tags)}
          controlOutside
          placeholder={'Add an NFT address'}
          focusOnStart
          error={errors.mainnet}
          touched={touched.nftLists}
          inputValue={nftInput["solana-mainnet"]}
          onChangeInput={value => {
            setFieldValue('nftInput.solana-mainnet', value)
          }}
          renderTag={props => {
            const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
            const isValid = solanaValidation(tag)
            

            return (
              <span
                key={key}
                {...other}
                style={{
                  color: isValid ? 'rgb(233, 233, 233)' : '#d27373',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  verticalAlign: 'bottom'
                }}
              >
                {getTagDisplayValue(tag)}
                {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
              </span>
            )
          }}
          onlyUnique={true}
          addKeys={[9, 13, 32, 188]}
          pasteSplit
          inputClassName="input-responsive"
          tagsInputClassName="limit-input"
        />
      </div>
      {isMobile ? <div className='div-spacing-15' /> : null}
    </>
  )
}
