import _uniqBy from 'lodash/uniqBy'
import _map from 'lodash/map'
import _find from 'lodash/find'
import countries from '../../_common/core/countries'

export const checkCountry = (value, country) => {
  switch (country) {
    case 'us':
      return value === 'us' || value === 'United States'
    case 'au':
      return value === 'au' || value === 'Australia'
    case 'uk':
      return value === 'uk' || value === 'United Kingdom'
    case 'in':
      return value === 'in' || value === 'India'
    case 'nz':
      return value === 'nz' || value === 'New Zealand'
    case 'mx':
      return value === 'mx' || value === 'Mexico'
    case 'ca':
      return value === 'ca' || value === 'Canada'
    default:
      return false
  }
}

export const getCountryCurrencies = () =>
  _uniqBy(
    _map(countries, item => {
      const currency = getCurrencyObj(item.cca2.toLowerCase())
      return { label: currency.currency, value: currency.currency, data: currency }
    }),
    item => item.label,
  )

export const getCurrencyObj = (country, customCurrency) => {
  const currency = { currency: 'USD', decimal_places: 2, symbol: 'US$' }
  switch (country) {
    case 'uk':
      currency.currency = 'GBP'
      currency.symbol = '£'
      break
    case 'gb':
      currency.currency = 'GBP'
      currency.symbol = '£'
      break
    case 'ie':
    case 'eu':
    case 'pt':
    case 'it':
    case 'de':
    case 'fr':
    case 'ie':
    case 'es':
    case 'nl':
    case 'eu':
    case 'at':
    case 'cz':
      currency.currency = 'EUR'
      currency.symbol = '€'
      break
    case 'in':
      currency.currency = 'INR'
      currency.symbol = '₹'
      break
    case 'hr':
      const hrCurrency = (customCurrency || 'EUR').toUpperCase()
      currency.currency = hrCurrency
      switch (hrCurrency) {
        case 'EUR':
          currency.symbol = '€'
          break
        case 'GBP':
          currency.symbol = '£'
          break
      }
      break
    case 'jm':
      const jmCurrency = (customCurrency || 'JMD').toUpperCase()
      currency.currency = jmCurrency
      switch (jmCurrency) {
        case 'JMD':
          currency.symbol = 'J$'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'do':
      const doCurrency = (customCurrency || 'DOP').toUpperCase()
      currency.currency = doCurrency
      switch (doCurrency) {
        case 'DOP':
          currency.symbol = 'Rd$'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'co':
      const coCurrency = (customCurrency || 'COP').toUpperCase()
      currency.currency = coCurrency
      switch (coCurrency) {
        case 'COP':
          currency.symbol = 'Col$'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'jo':
      currency.currency = 'USD'
      currency.symbol = 'US$'
      break
    case 'nz':
      currency.currency = 'NZD'
      currency.symbol = 'NZ$'
      break
    case 'my':
      currency.currency = 'MYR'
      currency.symbol = 'RM'
      break
    case 'mx':
      const mxCurrency = (customCurrency || 'MXN').toUpperCase()
      currency.currency = mxCurrency

      switch (mxCurrency) {
        case 'MXN':
          currency.symbol = 'Mex$'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'sg':
      currency.currency = 'SGD'
      currency.symbol = 'S$'
      break
    case 'au':
      currency.currency = 'AUD'
      currency.symbol = 'A$'
      break
    case 'za':
      currency.currency = 'ZAR'
      currency.symbol = 'R'
      break
    case 'ke':
      currency.currency = 'KES'
      currency.symbol = 'Ksh'
      break
    case 'ca':
      currency.currency = 'CAD'
      currency.symbol = 'CA$'
      break
    case 'th':
      currency.currency = 'THB'
      currency.symbol = '฿'
      break
    case 'is':
      currency.currency = 'ISK'
      currency.symbol = 'Kr'
      break
    case 'se':
      currency.currency = 'SEK'
      currency.symbol = 'kr'
      break
    case 'ae':
      currency.currency = 'AED'
      currency.symbol = 'د.إ'
      break
    case 'ch':
      const chCurrency = (customCurrency || 'USD').toUpperCase()
      currency.currency = chCurrency

      switch (chCurrency) {
        case 'CHF':
          currency.symbol = 'CHf'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'ph':
      currency.currency = 'USD'
      currency.symbol = 'US$'
      break
    case 'ma':
      const maCurrency = (customCurrency || 'EUR').toUpperCase()
      currency.currency = maCurrency

      switch (maCurrency) {
        case 'EUR':
          currency.symbol = '€'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'cz':
      const czCurrency = (customCurrency || 'EUR').toUpperCase()
      currency.currency = czCurrency

      switch (czCurrency) {
        case 'EUR':
          currency.symbol = '€'
          break
        case 'USD':
          currency.symbol = 'US$'
          break
      }
      break
    case 'gr':
      currency.currency = 'EUR'
      currency.symbol = '€'
      break
    case 'sg':
      currency.currency = 'SGD'
      currency.symbol = '$'
      break
    case 'pl':
      currency.currency = 'PLN'
      currency.symbol = 'zł'
      break
    case 'tr':
      const trCurrency = (customCurrency || 'USD').toUpperCase()
      currency.currency = trCurrency

      switch (trCurrency) {
        case 'USD':
          currency.symbol = 'US$'
          break
        case 'EUR':
          currency.symbol = '€'
          break
        case 'TRY':
          currency.symbol = '₺'
          break
      }
      break
    default:
      currency.currency = 'USD'
      currency.symbol = 'US$'
      break
  }

  return currency
}

export const getCurrenciesFromCountry = (countriesList, selectedCountry) => {
  const selectedCountryObj = _find(countries, item => item.name.common === selectedCountry)
  if (!selectedCountry || !selectedCountryObj || !countriesList) return []
  const currencies = _find(countriesList, (item, key) => key === selectedCountryObj.cca2.toLowerCase()) || []
  return currencies
}

export const countryCodeAdapter = (countryCode = '', from, to) => {
  if (countryCode === from) {
    return to
  }

  return countryCode
}
