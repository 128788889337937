export const getToursMenuItems = ({ page, isTour, isRnDomain, isToursPage, isNewTourPage, isMcDomain }) => {
  const menuGroup = []

  menuGroup.push({
    img: 'tours',
    title: 'Tours',
    href: `/tours`,
    active: isToursPage || isNewTourPage || (isTour && page !== ''),
    visible: !isRnDomain && !isMcDomain,
  })

  return menuGroup
}
