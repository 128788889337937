import React, { Component } from 'react'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import LoadingBar from '../../../_library/LoadingBar'
import { FuturePaymentDetails } from '.'

import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDate,
  formatDay,
} from '../../../../_common/core/validation'

// hoc
import { DISPLAY_FORMAT } from '../../../constants/timeFormats'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import { showAxiosError } from '../../../utils/messenger'
import EmptyBar from '../../../_library/EmptyBar'

class FuturePayments extends Component {
  constructor(props) {
    super(props)
    this.openedDetails = new Set()
    this.state = {
      detailsRows: [],
      showFuturePaymentDetails: false,
      installments: [],
    }
    this.tableColumns = getTableColumns([
      {
        key: 'payment_date',
        isSortable: true,
        label: 'Payment Date',
        normalizer: value => formatDay(value),
      },
      {
        key: 'payments_count',
        isSortable: true,
        label: 'No. of Payments',
      },
      {
        key: 'expected_revenue',
        isSortable: true,
        label: 'Expected Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'actual_revenue',
        isSortable: true,
        label: 'Actual Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ])
    this.footbarColumns = [
      {
        key: 'payments_count',
      },
      {
        key: 'expected_revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'actual_revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]
    this.detailTableColumns = getTableColumns([
      {
        key: 'date',
        isSortable: true,
        label: 'Order Date',
        normalizer: value => formatDate(value, DISPLAY_FORMAT),
      },
      {
        key: 'tickets_count',
        isSortable: true,
        label: 'No. of Tickets',
      },
      {
        key: 'total_payments',
        isSortable: true,
        label: 'Total No. of Payments',
      },
      {
        key: 'current_payment_num',
        isSortable: true,
        label: 'No. of Current Payment',
      },
    ])
  }

  shouldComponentUpdate(nextProps, nextState) {
    const data = _get(this.props, 'future_payments')
    const nextData = _get(nextProps, 'future_payments')

    if (!_isEqual(data, nextData) || !_isEqual(this.state, nextState)) return true
    return false
  }

  getDetailRowData = async data => {
    const { event } = this.props
    const filter = { start_date: data.payment_date, end_date: data.payment_date }
    try {
      const { payment_plan_future_sales_orders = [] } = await getPerfomanceData(
        event.id,
        'payment_plan_future_sales_orders',
        filter,
      )

      const detailsRows = this.getDetailRows(
        payment_plan_future_sales_orders,
        (data.payment_date || '') + data.expected_revenue,
      )

      this.setState(prevState => ({
        ...prevState,
        detailsRows,
      }))
    } catch (err) {
      showAxiosError(err)
      return null
    }
  }

  getDetailRows = (data, id) => {
    const detailsRows = []
    const dataRows = []
    _map(data, item => {
      dataRows.push({ ...item, id })
    })
    if (dataRows.length) {
      detailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.detailTableColumns}
            enableSort={true}
            sortBy={{ column: 'date', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
            actions={[
              {
                label: 'Details',
                className: 'btn btn-primary',
                onClick: data => this.onOpenFuturePaymentDetails(data),
              },
            ]}
          />
        ),
      })
    } else {
      detailsRows.push({
        id,
        type: 'detailRow',
        component: <EmptyBar />,
      })
    }

    return detailsRows
  }

  onOpenFuturePaymentDetails(data) {
    this.setState({ installments: data.installments, showFuturePaymentDetails: true })
  }

  onCloseFuturePaymentDetails() {
    this.setState({ showFuturePaymentDetails: false })
  }

  render() {
    const { future_payments } = this.props
    const { detailsRows, showFuturePaymentDetails, installments } = this.state
    const { loading, data } = future_payments

    return (
      <div>
        {loading ? (
          <LoadingBar />
        ) : _isEmpty(data) ? (
          <EmptyBar />
        ) : (
          <SortableTable
            data={_map(data, item => ({ ...item, id: (item.payment_date || '') + item.expected_revenue }))}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={{ column: 'payment_date', asc: true }}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns,
            }}
            isAsyncDetails={true}
            detailsRows={detailsRows}
            getDetailRowData={this.getDetailRowData}
          />
        )}
        {showFuturePaymentDetails && (
          <FuturePaymentDetails
            installments={installments}
            onClose={() => this.onCloseFuturePaymentDetails()}
          />
        )}
      </div>
    )
  }
}

export { FuturePayments }
