import React, { Component } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import PreRegistrationAction from './PreRegistrationAction'
import PreRegistrationPrize from './PreRegistrationPrize'
import PreRegistrationDates from './PreRegistrationDates'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import { DisableSectionByPermissionWrapper } from '../../hoc'

import {
  //dates
  FETCH_PRE_REGISTRATION_DATES,
  UPDATE_PRE_REGISTRATION_DATES,
  //actions
  FETCH_PRE_REGISTRATION_ACTIONS,
  CREATE_PRE_REGISTRATION_ACTIONS,
  UPDATE_PRE_REGISTRATION_ACTIONS,
  DELETE_PRE_REGISTRATION_ACTIONS,
  //prizes
  FETCH_PRE_REGISTRATION_PRIZES,
  DELETE_PRE_REGISTRATION_PRIZES,
  CREATE_PRE_REGISTRATION_PRIZES,
  EDIT_PRE_REGISTRATION_PRIZES
} from '../../../_common/redux/preRegistration/actions'

import {
  get_event,
} from '../../../_common/core/selectors'
import { getTitle } from '../../utils/getTitle'

@connect(
  state => ({
    event: get_event(state),
    //
    preRegistrationDatesLoading: state.loading.has('FETCH_PRE_REGISTRATION_DATES'),
    preRegistrationDates: state.preRegistration.preRegistrationDates.data,

    //
    actionChecked: state.preRegistration.preRegistrationActions.checked,
    preRegistrationActionsLoading: state.loading.has('FETCH_PRE_REGISTRATION_ACTIONS'),

    //
    preRegistrationPrizes: state.preRegistration.preRegistrationPrizes.data,
    prizesChecked: state.preRegistration.preRegistrationActions.checked,
    preRegistrationPrizesLoading: state.loading.has('FETCH_PRE_REGISTRATION_PRIZES'),
    preRegistrationPrizesExisit: state.preRegistration.preRegistrationPrizes.exist,
    preRegistrationActions: _get(state, 'preRegistration.preRegistrationActions.data.attributes', []),
  }),
  {
    //dates
    FETCH_PRE_REGISTRATION_DATES,
    UPDATE_PRE_REGISTRATION_DATES,
    //actions
    FETCH_PRE_REGISTRATION_ACTIONS,
    CREATE_PRE_REGISTRATION_ACTIONS,
    UPDATE_PRE_REGISTRATION_ACTIONS,
    DELETE_PRE_REGISTRATION_ACTIONS,
    //prizes
    FETCH_PRE_REGISTRATION_PRIZES,
    DELETE_PRE_REGISTRATION_PRIZES,
    CREATE_PRE_REGISTRATION_PRIZES,
    EDIT_PRE_REGISTRATION_PRIZES
  }
)
class PreRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preRegistrationPrizesData: []
    }
    this.onActionsSubmit = this.onActionsSubmit.bind(this)
    this.handleDeletePrize = this.handleDeletePrize.bind(this)
    this.handlePrizeCreateSubmit = this.handlePrizeCreateSubmit.bind(this)
    this.handlePrizeUpdateSubmit = this.handlePrizeUpdateSubmit.bind(this)
    this.handleDatesSubmit = this.handleDatesSubmit.bind(this)
  }
  componentDidMount() {
    const { 
      event: { id: eventId, displayName },
      FETCH_PRE_REGISTRATION_DATES, 
      FETCH_PRE_REGISTRATION_ACTIONS, 
      FETCH_PRE_REGISTRATION_PRIZES 
    } = this.props

    FETCH_PRE_REGISTRATION_DATES(eventId)
    FETCH_PRE_REGISTRATION_ACTIONS(eventId)
    FETCH_PRE_REGISTRATION_PRIZES(eventId)

    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  componentDidUpdate(prevProps) {
    const { preRegistrationPrizes } = this.props
    const propsAttributes = preRegistrationPrizes.attributes
    const prevPropsAttributes = prevProps.preRegistrationPrizes.attributes
    if (prevPropsAttributes !== propsAttributes) {
      this.setState({ preRegistrationPrizesData: this.adaptPreRegPrizeData(propsAttributes) })
    }
  }
  onActionsSubmit(formData) {
    const {
      preRegistrationActions,
      CREATE_PRE_REGISTRATION_ACTIONS,
      UPDATE_PRE_REGISTRATION_ACTIONS,
      DELETE_PRE_REGISTRATION_ACTIONS,
      event
    } = this.props

    const eventId = _get(event, 'id')

    const newFormData = {
      type: 'preregistration_actions',
      relationships: [],
      attributes: []
    }
    Object.entries(formData).forEach(([key, value]) => {
      if (parseInt(value)) {
        let params = null
        if (formData[`${key}_active`]) {
          params = {}
          params.action = key
          params.point = +value
          if (formData[`${key}_url`]) {
            params.socialUrl = formData[`${key}_url`]
          }
        }
        if (params) {
          newFormData.attributes.push(params)
        }
      }
    })
    if (preRegistrationActions.length) {
      if (newFormData.attributes.length > 0) {
        return UPDATE_PRE_REGISTRATION_ACTIONS(eventId, newFormData)
      }
      return DELETE_PRE_REGISTRATION_ACTIONS(eventId, newFormData)

    }
    return CREATE_PRE_REGISTRATION_ACTIONS(eventId, newFormData)

  }

  handleDatesSubmit = form => {
    const { event, UPDATE_PRE_REGISTRATION_DATES } = this.props

    return Promise.resolve(UPDATE_PRE_REGISTRATION_DATES(event.id, form))
      .then(event => {
        showSuccessMessage('Successfully updated Pre-registration dates')
        this.setState(prevState => ({
          ...prevState,
          isRewardSystemDisabled: event.flagDisableReferral
        }))
        form_helper_reset()
        return event
      })
      .catch(err => Promise.reject(_.result(err, 'toFieldErrors', err)))
  }



  handleDeletePrize(data, callBack) {
    const { DELETE_PRE_REGISTRATION_PRIZES, event } = this.props
    const id = _get(event, 'id')
    DELETE_PRE_REGISTRATION_PRIZES(id, data).finally(() => {
      // callBack for close modal
      callBack()
    })
  }

  handlePrizeCreateSubmit(data, callBack) {
    const { CREATE_PRE_REGISTRATION_PRIZES, event } = this.props
    const id = _get(event, 'id')
    return CREATE_PRE_REGISTRATION_PRIZES(id, data).finally(() => {
      // callBack for close modal
      callBack()
    })
  }
  handlePrizeUpdateSubmit(data, callBack) {
    const { EDIT_PRE_REGISTRATION_PRIZES, event } = this.props
    const id = _get(event, 'id')
    return EDIT_PRE_REGISTRATION_PRIZES(id, data).finally(() => {
      // callBack for close modal
      callBack()
    })
  }
  adaptPreRegPrizeData(preRegistrationPrizes) {
    const data = preRegistrationPrizes.filter(e => e.phase === 'pre-registration')
    return data
  }

  render() {
    const {
      actionChecked,
      preRegistrationDates,
      preRegistrationActionsLoading,
      preRegistrationPrizesLoading,
      preRegistrationDatesLoading,
      preRegistrationPrizesExisit,
      preRegistrationActions
    } = this.props


    const { preRegistrationPrizesData } = this.state

    return (
      <DisableSectionByPermissionWrapper>
        <div className="pre_registration_page">
          <Card title="Pre-Registration Dates">
            {preRegistrationDatesLoading && <LoadingBar />}
            <PreRegistrationDates enableReinitialize initialValues={preRegistrationDates} onSubmit={this.handleDatesSubmit} />
          </Card>
          <Card title="Pre-Registration Actions">
            {preRegistrationActionsLoading && <LoadingBar />}
            {actionChecked && (
              <PreRegistrationAction data={preRegistrationActions || []} handleSubmit={this.onActionsSubmit} />
            )}
          </Card>
          <Card title="Pre-Registration Prizes">
            {preRegistrationPrizesLoading && <LoadingBar />}
            {!preRegistrationPrizesLoading &&
              preRegistrationPrizesExisit && (
              <PreRegistrationPrize
                handleDeletePrize={this.handleDeletePrize}
                data={preRegistrationPrizesData}
                handlePrizeCreateSubmit={this.handlePrizeCreateSubmit}
                handlePrizeUpdateSubmit={this.handlePrizeUpdateSubmit}
              />
            )}
          </Card>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

export default PreRegistration
