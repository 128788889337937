import createAction from 'redux-actions/lib/createAction'
import { createAsyncAction } from '../actions'
import store from '../store'

const getUserFromState = () => {
  const state = store.getState()
  return state.auth.user
}

// http
import {
  getEvents,
  updateEventStatus,
  getEvent,
  createEvent,
  duplicateEvent,
  updateEvent,
  pinEvents,
  deleteEvent,
} from '../../core/http_services'
import { ParallelFetchByPagination } from '../../core/http_services/utils'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_EVENTS_INCREMENTAL } = createAsyncAction('FETCH_EVENTS_INCREMENTAL', function () {
  return dispatch => {
    const ParallelFetch = new ParallelFetchByPagination(getEvents, undefined, 3)
    ParallelFetch.run(50, 300, (error, res, paginationInfo) => {
      const user = getUserFromState()
      if (!user) {
        ParallelFetch.abort()
        return
      }
      if (error) {
        showAxiosError(error)
      } else {
        res.fullReady = paginationInfo.done
        dispatch(this.success(res))
      }
    })
  }
})

const { CREATE_EVENT } = createAsyncAction('CREATE_EVENT', function (data) {
  return async dispatch => {
    try {
      const event = await createEvent(data)
      dispatch(this.success({ event }))
      return event
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const { DUPLICATE_EVENT } = createAsyncAction('DUPLICATE_EVENT', function (eId, data) {
  return async dispatch => {
    try {
      const event = await duplicateEvent(eId, data)
      dispatch(this.success({ event }))
      return event
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})
const { FETCH_EVENT } = createAsyncAction('FETCH_EVENT', function (eid) {
  return async dispatch => {
    try {
      dispatch(this.start({ eid }))
      const event = await getEvent(eid)
      dispatch(this.success({ event }))
      return event
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const { UPDATE_EVENT } = createAsyncAction('UPDATE_EVENT', function (eid, data) {
  return async dispatch => {
    try {
      const event = await updateEvent(eid, data)
      dispatch(this.success({ event }))
      return event
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const { DELETE_EVENT } = createAsyncAction('DELETE_EVENT', function (eid) {
  return async dispatch => {
    try {
      const event = await deleteEvent(eid)
      dispatch(this.success({ eid, event }))
      return event
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const { UPDATE_EVENT_STATUS } = createAsyncAction('UPDATE_EVENT_STATUS', function (eid, status) {
  return async dispatch => {
    try {
      const res = await updateEventStatus(eid, status)
      dispatch(this.success({ eid, status }))
      return res
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const { PIN_EVENT } = createAsyncAction('PIN_EVENT', function (eventId, pinned = false) {
  return async dispatch => {
    try {
      const res = await pinEvents(eventId, pinned)
      dispatch(this.success({ eventId, pinned: !pinned }))
      return res
    } catch (err) {
      showAxiosError(err)
      throw err
    }
  }
})

const UPDATE_EVENT_TICKET_STATISTICS = createAction('UPDATE_EVENT_TICKET_STATISTICS', stats => stats)

const SET_EVENTS_FROM_INDEXED_DB = createAction('SET_EVENTS_FROM_INDEXED_DB', events => ({ events }))

const CLEAR_SELECTED_EVENT = createAction('CLEAR_SELECTED_EVENT')

const CLEAR_EVENTS = createAction('CLEAR_EVENTS')

export {
  FETCH_EVENTS_INCREMENTAL,
  CREATE_EVENT,
  DUPLICATE_EVENT,
  FETCH_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT_TICKET_STATISTICS,
  SET_EVENTS_FROM_INDEXED_DB,
  CLEAR_SELECTED_EVENT,
  PIN_EVENT,
  UPDATE_EVENT_STATUS,
  CLEAR_EVENTS,
}
