import { RNConfigs } from '../config/rnConfigs'
import { MCConfigs } from '../config/mcConfigs'
import { DefaultConfigs } from '../config/defaultConfigs'
import { ENV } from '../constants/env'
import { getDomain } from './getDomain'
import { QAWConfigs } from '../config/qawConfig'

const configs = {
  restlessnites: RNConfigs,
  manacommon: MCConfigs,
  theticketfairy: DefaultConfigs,
  ticketfairy: DefaultConfigs,
  ttf: DefaultConfigs,
  mana1: MCConfigs,
  qawolf: QAWConfigs,
}

export const getConfig = () => {
  const domain = getDomain(window.location.hostname, true)
  let config = configs[domain]

  if (!config) {
    config = configs[ENV.REQUEST_SOURCE]
  }

  return config || DefaultConfigs
}
