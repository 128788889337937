import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { withFormik } from 'formik'

import Button from '../../_library/Button'
import Field from '../../_library/Field'
import Select from '../../_library/Select'
import { getDayOptions } from '../../utils/ticketUtils'

function validateDelegation(data) {
  const errors = {}

  if (!_get(data, 'name')) {
    _set(errors, 'name', 'Required')
  }

  const types = _get(data, 'types')
  types.forEach((type, index) => {
    if (!type.ticketTypeId) {
      _set(errors, 'types[' + index + '].ticketTypeId', 'Required')
      if (type.selectedGroup) {
        !type.selectedDay && _set(errors, 'types[' + index + '].selectedDay', 'Required')
        !type.selectedTime && _set(errors, 'types[' + index + '].selectedTime', 'Required')
      }
    }
    if (!type.maxQuantity) {
      _set(errors, 'types[' + index + '].maxQuantity', 'Required')
    }
  })

  return errors
}

class NewDelegationFormComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  addNewTicketType() {
    this.props.setFieldValue('types', [
      ...this.props.values.types,
      {
        ticketTypeId: '',
        maxQuantity: 1,
        selectedGroup: '',
        selectedDay: '',
        selectedTime: ''
      }
    ])
  }

  removeTicketType(index) {
    const types = [...this.props.values.types]
    types.splice(index, 1)
    this.props.setFieldValue('types', types)
  }

  handleTimeChange = (e, index) => {
    const { values, tickets } = this.props
    const time = e.target.value
    if (!!time) {
      const selectedTicketType = _find(
        tickets[values.types[index].selectedGroup],
        t => t.slotStartDate === values.types[index].selectedDay + ' ' + time
      )
      const updatedType = {
        ticketTypeId: selectedTicketType.id,
        selectedTime: time
      }

      this.props.setFieldValue('types', this.getUpdatedTypes(index, updatedType))
    }
  }

  handleChangeTicketTypeID = (e, index) => {
    const {
      values: { types }
    } = this.props
    const v = e.target.value
    const isTicketTypeID = (Number(v) || Number(v) === 0) && !v.includes('e')
    const updatedType = {
      ticketTypeId: isTicketTypeID ? v : '',
      selectedGroup: isTicketTypeID ? '' : v,
      selectedDay: '',
      selectedTime: ''
    }

    this.props.setFieldValue('types', this.getUpdatedTypes(index, updatedType))
  }

  getUpdatedTypes = (index, updatedType) => {
    const {
      values: { types }
    } = this.props
    return _map(types, (t, i) => (i === index ? { ...t, ...updatedType } : t))
  }

  showDayTimeSelects = value => !!value

  getTimeOptions = (dayOptions, day) => {
    const selectedDay = _find(dayOptions, d => d.value === day) || {}
    return selectedDay.timeOptions || [{ value: '', label: 'Time' }]
  }

  render() {
    const {
      isSubmitting,
      handleSubmit,
      onCancel,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      tickets,
      tables
    } = this.props
    let ticketTableLabel = ''
    const optionsTickets = [{ value: '', label: '' }]

    if(!_isEmpty(tickets) && !_isEmpty(tables)) {
      ticketTableLabel = 'Ticket/Table Type'
    } else if(!_isEmpty(tables)) {
      ticketTableLabel = 'Table Type'
    } else {
      ticketTableLabel = 'Ticket Type'
    }
    optionsTickets[0] = { value: '', label: ticketTableLabel }

    _map(tickets, (ticket, i) => {
      optionsTickets.push({
        value: ticket.id || i,
        label: ticket.displayName || i
      })
    })

    _map(tables, table => {
      if(table.active) {
        const key = table.displayName || table.name
        optionsTickets.push({ value: table.id || key, label: key })
      }
    })

    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-8 col-xs-12">
            <label htmlFor="new-delegation-name">
              What is the name of the person or company you are giving access to?
            </label>
            <Field
              id="name"
              label="Name"
              value={values.name}
              error={errors.name}
              touched={touched.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <Button
          className="btn btn-primary btn-shadow new-delegation-new-type-btn"
          type="button"
          onClick={this.addNewTicketType.bind(this)}
        >
          Add Ticket Type
        </Button>
        <table className="table new-delegate-tickets-table">
          <thead>
            <tr>
              <th className="text-center">{ticketTableLabel}</th>
              <th className="text-center">Quantity Available</th>
              <th />
            </tr>
          </thead>

          {values.types.map((type, index) => (
            <tbody key={index} className="delegation">
              <tr key={index}>
                <td>
                  <Select
                    options={optionsTickets}
                    id={'types[' + index + '].ticketTypeId'}
                    value={
                      this.showDayTimeSelects(values.types[index].selectedGroup)
                        ? values.types[index].selectedGroup
                        : values.types[index].ticketTypeId
                    }
                    error={
                      this.showDayTimeSelects(values.types[index].selectedGroup)
                        ? errors.types && errors.types[index] && errors.types[index].selectedGroup
                        : errors.types && errors.types[index] && errors.types[index].ticketTypeId
                    }
                    touched={
                      this.showDayTimeSelects(values.types[index].selectedGroup)
                        ? touched.types && touched.types[index] && touched.types[index].selectedGroup
                        : touched.types && touched.types[index] && touched.types[index].ticketTypeId
                    }
                    onChange={e => this.handleChangeTicketTypeID(e, index)}
                    onBlur={handleBlur}
                  />
                </td>
                <td>
                  <Field
                    type="number"
                    id={'types[' + index + '].maxQuantity'}
                    value={values.types[index].maxQuantity}
                    error={errors.types && errors.types[index] && errors.types[index].maxQuantity}
                    touched={touched.types && touched.types[index] && touched.types[index].maxQuantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </td>
                <td className="text-right">
                  <Button
                    className="btn btn-danger btn-shadow new-delegation-delete-type-btn"
                    type="button"
                    onClick={this.removeTicketType.bind(this, index)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
              {this.showDayTimeSelects(values.types[index].selectedGroup) && (
                <tr>
                  <td>
                    <Select
                      id={'types[' + index + '].selectedDay'}
                      label="Ticket Day"
                      options={getDayOptions(tickets, values.types[index].selectedGroup)}
                      value={values.types[index].selectedDay}
                      error={errors.types && errors.types[index] && errors.types[index].selectedDay}
                      touched={touched.types && touched.types[index] && touched.types[index].selectedDay}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <Select
                      id={'types[' + index + '].selectedTime'}
                      label="Ticket Time"
                      options={this.getTimeOptions(
                        getDayOptions(tickets, values.types[index].selectedGroup),
                        values.types[index].selectedDay
                      )}
                      value={values.types[index].selectedTime}
                      error={errors.types && errors.types[index] && errors.types[index].selectedTime}
                      touched={touched.types && touched.types[index] && touched.types[index].selectedTime}
                      onChange={(e) => this.handleTimeChange(e, index)}
                    />
                  </td>
                  <td className="text-right" />
                </tr>
              )}
            </tbody>
          ))}
          {values.types.length === 0 && (
            <tfoot>
              <tr>
                <td colSpan={3} className="text-center">
                  No Ticket Types
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        <div className="btn-toolbar btn-toolbar-right text-right">
          {values.types && values.types.length > 0 && (
            <Button className="btn btn-primary btn-shadow" type="submit" loading={isSubmitting}>
              Create
            </Button>
          )}
          <Button className="btn btn-danger btn-shadow" type="button" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </form>
    )
  }
}

const NewDelegationForm = withFormik({
  mapPropsToValues: props => ({
    name: _get(props, 'initialValues.attributes.name') || '',
    types: _get(props, 'initialValues.attributes.types') || []
  }),

  // Custom sync validation
  validate: values => validateDelegation(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    const updatedValues = {
      name: values.name,
      types: _map(values.types, t => ({ ticketTypeId: t.ticketTypeId, maxQuantity: t.maxQuantity }))
    }
    props
      .onSubmit({
        attributes: { ...updatedValues }
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'NewDelegationForm' // helps with React DevTools
})(NewDelegationFormComponent)

export default NewDelegationForm
