// Re-render Optimization 12.05.2020

import React from 'react'
import { ENV } from '../constants/env'
import _get from 'lodash/get'
import { getConfig } from '../utils/configUtils'

const configFile = getConfig()

export default React.memo(() => (
  <div className="footer-bar" style={{ justifyContent: 'flex-start', WebkitJustifyContent: 'flex-start' }}>
    <div> 
      Copyright &copy; {getCurrentYear()} {' '}
      {_get(
        configFile,
        '*.messages.copyright',
        ''
      ) || <a href={ENV.API_CONSUMER}>THE<strong>TICKET</strong>FAIRY</a>}
    </div>
    <div>
      <a
        href="https://www.facebook.com/theticketfairy"
        target="_blank"
        rel="noreferrer"
      >
        <img src={asset('/resources/images/icon-facebook.png')} width="20" height="20" />
      </a>
      <a href="https://twitter.com/theticketfairy" target="_blank" rel="noreferrer">
        <img src={asset('/resources/images/icon-twitter.png')} width="20" height="20" />
      </a>
    </div>
    </div>
  )
)

