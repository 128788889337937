import React from 'react'
import RichTextArea from '../../../_library/RichTextArea'
import { ENV } from '../../../constants/env'

export default class DescriptionRow extends React.Component {
  updateField = (fieldKey, value) => {
    this.props.updateField(fieldKey, value)
  }

  handleChangeDescription = (value) => {
    this.updateField('description', value)
  }

  render() {
    const { values } = this.props
    return (
      <div className="row">
        <div className="col-xs-12" style={{ paddingBottom: '5px', color: 'white' }}>
          Description
        </div>
        <div className="col-xs-12">
          <RichTextArea
            ref="description"
            id="description"
            label=""
            baseurl={ENV.CDN_URL}
            value={values.description}
            onChange={this.handleChangeDescription}
          />
        </div>
      </div>
    )
  }
}
