import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import _map from 'lodash/map'
import _get from 'lodash/get'

import EventFAQItem from './EventFAQItem'
import { ROLES } from '../../constants/roles'

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    }
  }
}

const disabledRoles = ['tf_support', 'tf_client_support']

const cardTarget = {
  hover(props, monitor, component) {
    if (monitor.getItem().indexSection === undefined) {
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      props.onMove(dragIndex, hoverIndex)
      monitor.getItem().index = hoverIndex
    }
  },
  drop(props, monitor, component) {
    if (monitor.getItem().indexSection !== undefined) {
      const dragIndexItem = monitor.getItem().index
      const dragIndex = monitor.getItem().indexSection
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      // props.onMoveItemAcrossSection(dragIndex, dragIndexItem, hoverIndex)
    }
  }
}

@DropTarget(['EventFAQSection', 'EventFAQItem'], cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource('EventFAQSection', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))
export default class EventFAQSection extends React.Component {
  static propTypes = {
    connectDragSource: PropTypes.any.isRequired,
    connectDropTarget: PropTypes.any.isRequired,
    connectDragPreview: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onMove: PropTypes.func.isRequired
    // onMoveItemAcrossSection: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      section: props.section,
      deleting: false,
      hasNewQuestion: false
    }
  }

  componentWillReceiveProps(newProps) {
    const { section } = this.props
    this.setState({
      hasNewQuestion: section.questions.some(el => el.isNewQuestion)
    })

    this.setState({
      section: newProps.section
    })
  }

  saveFaq() {
    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  delete() {
    if (this.props.onDelete) {
      this.setState(
        {
          deleting: true
        },
        () => {
          this.props.onDelete()
        }
      )
    }
  }

  expand() {
    if (this.props.onExpand) {
      this.props.onExpand()
    }
  }

  collapse() {
    if (this.props.onCollapse) {
      this.props.onCollapse()
    }
  }

  newItem() {
    if (this.props.onNewItem) {
      this.props.onNewItem()
    }
  }

  deleteItem(index) {
    if (this.props.onDeleteItem) {
      this.props.onDeleteItem(index)
    }
  }

  saveItem(index) {
    if (this.props.onSaveItem) {
      this.props.onSaveItem(index)
    }
  }

  moveItem(dragIndex, hoverIndex) {
    if (this.props.onMoveItemInsideSection) {
      this.props.onMoveItemInsideSection(dragIndex, hoverIndex)
    }
  }

  expandItem(index) {
    if (this.props.onExpandItem) {
      this.props.onExpandItem(index)
    }
  }

  collapseItem(index) {
    if (this.props.onCollapseItem) {
      this.props.onCollapseItem(index)
    }
  }

  render() {
    const { section, deleting, hasNewQuestion } = this.state
    const { isDragging, connectDragSource, connectDropTarget, connectDragPreview, changedField, role, user } =
      this.props
    const opacity = isDragging ? 0 : 1
    const isTfSupportOrClientSupport = ROLES.is_tf_support_or_client_support(_get(user, 'tfRole'))

    const questions = _map(section.questions, (question, j) => (
      <EventFAQItem
        id={question.id}
        changedField={changedField}
        key={question.id || j}
        index={j}
        indexSection={this.props.index}
        question={question}
        onSave={() => this.saveItem(j)}
        onDelete={() => this.deleteItem(j)}
        onMove={(dragIndex, hoverIndex) => this.moveItem(dragIndex, hoverIndex)}
        onExpand={() => this.expandItem(j)}
        onCollapse={() => this.collapseItem(j)}
        onChangeQuestion={this.props.onChangeQuestion}
        isTfSupportOrClientSupport={isTfSupportOrClientSupport}
      />
    ))

    return connectDragPreview(
      connectDropTarget(
        <div className="eventfaq-section" style={{ opacity }}>
          <div
            style={{
              border:
                changedField && changedField.isSection && changedField.id === section.id
                  ? '1px solid #ffa46b'
                  : '0px solid transparent'
            }}
            className="eventfaq-section-top"
          >
            {section.expanded && (
              <div className="eventfaq-section-caret" onClick={e => this.collapse()}>
                <i className="fa fa-minus-square-o" aria-hidden="true" />
              </div>
            )}
            {!section.expanded && (
              <div className="eventfaq-section-caret" onClick={e => this.expand()}>
                <i className="fa fa-plus-square-o" aria-hidden="true" />
              </div>
            )}
            <i className="fa fa-question-circle-o question" aria-hidden="true" />
            <input
              type="text"
              value={section.title}
              className="eventfaq-section-title form-control"
              placeholder="Enter New Section Title"
              onChange={e => this.props.onChangeSection(this.props.id, e.target.value, 'title')}
            />
            {!isTfSupportOrClientSupport && (
              <div className="isPublic">
                <div className="line-cell">
                  <div className="checkbox-switch">
                    <input
                      disabled={disabledRoles.includes(role)}
                      type="checkbox"
                      id={`isPublic_${section.id}`}
                      checked={!!section.isPublic}
                      onChange={e => this.props.onChangeSection(this.props.id, e.target.checked, 'isPublic')}
                    />
                    <label htmlFor={`isPublic_${section.id}`} />
                  </div>
                </div>
                <div className="line-cell">
                  <label htmlFor={`isPublic_${section.id}`}>Public</label>
                </div>
              </div>
            )}
            {connectDragSource(
              <div className="eventfaq-section-button eventfaq-section-button-drag">
                <i className="fa fa-arrows" aria-hidden="true" />
              </div>
            )}
            <div
              className="eventfaq-section-button eventfaq-section-button-delete"
              onClick={e => !deleting && this.delete()}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </div>
            <div
              className="eventfaq-section-button eventfaq-section-button-save"
              onClick={e => this.saveFaq()}
            >
              <i className="fa fa-floppy-o" aria-hidden="true" />
            </div>
            <div
              className="eventfaq-section-button eventfaq-section-button-newquestion"
              onClick={e => !hasNewQuestion && this.newItem()}
            >
              <i className={'fa fa-plus ' + (hasNewQuestion && 'disabled')} aria-hidden="true" />
            </div>
          </div>
          {section.expanded && <div className="eventfaq-section-questions">{questions}</div>}
        </div>
      )
    )
  }
}
