import _get from 'lodash/get'
import _result from 'lodash/result'
import { connect } from 'react-redux'
import React from 'react'

import Notifications from '../_library/notifications/Notifications'
import {
  LOGIN,
  FACEBOOK_LOGIN,
  RESTORE_PASSWORD,
  ACCEPT_TEAM_INVITATION,
  FETCH_SESSION
} from '../../_common/redux/auth/actions'
import LoginForm from './LoginForm'
import RnPage from './RnPage'
import McPage from './McPage'
import { ENV } from '../constants/env'
import queryString from 'query-string'
import { getTitle } from '../utils/getTitle'

@connect(
  state => ({
    isRnDomain: state.shared.isRnDomain,
    isMcDomain: state.shared.isMcDomain
  }),
  { LOGIN, FACEBOOK_LOGIN, RESTORE_PASSWORD, ACCEPT_TEAM_INVITATION, FETCH_SESSION }
)
export default class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props)

    const { location } = props
    const initialEmail = _get(location, 'state.email', '')
    this.state = {
      initialEmail
    }
  }

  componentDidMount() {
    const { isRnDomain, configs, isMcDomain } = this.props
    if (this.needToRedirect(isRnDomain) || this.needToRedirect(isMcDomain) ) {
      const base = ENV.API_BASE
      const returnURL = window.location.origin
      window.location = base + '/safari-iframe-cookie-fix?return=' + returnURL
    } else {
      $('html').addClass('authentication')
    }

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  componentWillUnmount() {
    $('html').removeClass('authentication')
  }

  handleRestorePassword = email => {
    const { RESTORE_PASSWORD } = this.props
    return Promise.resolve(RESTORE_PASSWORD(email))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors')))
      .then(res => res)
  }

  handleSubmit = async form => {
    try {
      const { LOGIN, ACCEPT_TEAM_INVITATION, FETCH_SESSION } = this.props
      const { token } = queryString.parse(window.location.search)
      await LOGIN(form)
      const res = await FETCH_SESSION()
      if (token) {
        const {
          data: { firstName, lastName }
        } = res
        const body = {
          attributes: {
            firstName,
            lastName,
            password: form.attributes.password,
            country: 1,
            confirmPassword: form.attributes.password,
            email: form.attributes.username,
            token
          }
        }
        await ACCEPT_TEAM_INVITATION(body)
      }
      return res
    } catch (e) {
      throw e
    }
  }

  setCookie = (name, value, days) => {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  getCookie = name => {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  needToRedirect = isExternalDomain => {
    if (isExternalDomain && !this.getCookie('previous_ttf_visit')) {
      this.setCookie('previous_ttf_visit', 'true', 365)
      return true
    }
    return false
  }

  render() {
    const { isRnDomain, configs, isMcDomain } = this.props
    const { initialEmail } = this.state
    const width = $(window).width()
    const height = $(window).height()
    const isLandscape = width >= height
    const showVideo = _get(configs, 'appearance.showBackgroundVideo')
    let video = 'landscape/1280x720.mp4'
    let image = 'landscape/landscape-desktop.jpg'
    if (isLandscape) {
      if (width <= 668) {
        video = 'landscape/668x376.mp4'
        image = 'landscape/landscape-iphone.jpg'
      } else if (width <= 1024) {
        video = 'landscape/1024x768.mp4'
        image = 'landscape/landscape-ipad.jpg'
      } else if (width <= 1280) {
        video = 'landscape/1280x720.mp4'
        image = 'landscape/landscape-desktop.jpg'
      } else {
        video = 'landscape/1920x1080.mp4'
        image = 'landscape/landscape-desktop.jpg'
      }
    } else {
      if (width < 768) {
        video = 'portrait/376x668.mp4'
        image = 'portrait/portrait-iphone.jpg'
      } else {
        video = 'portrait/768x1024.mp4'
        image = 'portrait/portrait-ipad.jpg'
      }
    }

    return (
      <div className="loginpage">
        {showVideo && (
          <div className="bg_video">
            <video
              poster={asset('/resources/videos/login/' + image)}
              id="bgvid"
              playsInline
              autoPlay
              muted
              loop
            >
              <source src={asset('/resources/videos/login/' + video)} type="video/mp4" />
            </video>
          </div>
        )}
        <Notifications />
        {isRnDomain ? (
          <RnPage onSubmit={this.handleSubmit} onRestorePassword={this.handleRestorePassword} />
        ) : isMcDomain ? 
          <McPage
            onSubmit={this.handleSubmit}
            onRestorePassword={this.handleRestorePassword}
            initialEmail={initialEmail}
            configs={configs}
          />
        : (
          <LoginForm
            onSubmit={this.handleSubmit}
            onRestorePassword={this.handleRestorePassword}
            initialEmail={initialEmail}
          />
        )}
      </div>
    )
  }
}
