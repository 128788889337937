import React, { useState } from 'react'
import Button from '../../_library/Button'
import { Field, Form } from 'formik'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'
import { SimpleField } from '../../formik/Fields'
import OutsideAlerter from './OutsideAlerter'
import { useCallback } from 'react'
import { useMediaQuery } from '../../../_common/core/utils'

const CreateAccountForm = ({
  setShowDropdown,
  isSubmitting,
  onClickOption,
  getSuggestions,
  suggestions,
  setSuggestions,
  isValid,
  history,
  values,
  state,
  countries
}) => {
  const handleGoBack = () => {
    history.goBack()
  }
  const onClickOutside = useCallback(() => {
    setSuggestions([])
  }, [setSuggestions])
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [focusAddress, setFocusAddress] = useState()
  return (
    <Form className="margin-horizontal-25 create-brand-container">
      <div className="title-form">Brand Details</div>
      <label className="margin-top-24">
        Your brand is what your ticket buyers know your events or venue as. You can have as many brands as
        you want, but let’s get your first one created now…
      </label>
      <Field
        type="displayName"
        name="displayName"
        label="Brand Name"
        component={SimpleField}
        formclassname="field-wrapper brand-name-input"
        validate={requiredValidator()}
        touchedOnBlur
      />
      <div className="title-form">Business Details</div>
      <Field
        type="legalEntity"
        name="legalEntity"
        label="Legal Entity Name"
        component={SimpleField}
        formclassname="field-wrapper legal-entity-input"
        validate={requiredValidator()} 
        touchedOnBlur
      />
      <Field
        type="address"
        name="address"
        label="Address"
        autoComplete="off"
        component={SimpleField}
        disabled={!countries.length}
        formclassname={`field-wrapper address-input ${
          (focusAddress || (suggestions && suggestions.length)) ? 'border-white' : ''
        }`}
        validate={requiredValidator()}
        formstyle={{ marginTop: 16 }}
        onChange={(_name, _value, event) => {
          getSuggestions(event)
        }}
        onFocus={() => {
          setShowDropdown(true)
          setFocusAddress(true)
        }}
        onBlur={() => {
          setFocusAddress(false)
        }}
        touchedOnBlur
      />
      {suggestions && suggestions.length && !isMobile ? (
        <div className="suggestions-wrapper">
          <OutsideAlerter onClickOutside={onClickOutside}>
            {suggestions.map((suggestion, i) => (
              <div key={i} className="suggestion-element" onClick={() => onClickOption(suggestion)}>
                <img
                  src={asset('/resources/images/marker-map-dot.svg')}
                  className="fa-map-marker-dot-form color-svg-map-marker"
                  alt=''
                />
                <img
                  src={asset('/resources/images/marker-map.svg')}
                  className="color-svg-map-marker"
                  style={{ position: 'absolute', left: 19, top: 16 }}
                  alt=''
                />
                <span className="suggestion-description">{suggestion.description.split(',')[0]}</span>
                <span className="suggestion-details">
                  {suggestion.description.split(',').reduce((curr, next, i) => {
                    if (i === 0) {
                      return curr
                    }
                    return curr + (i === 1 ? '' : ',') + next
                  }, '')}
                </span>
              </div>
            ))}
          </OutsideAlerter>
        </div>
      ) : null}
      {values.showZipField ? (
        <Field
          type="zip"
          name="zip"
          label="ZIP Code"
          component={SimpleField}
          formclassname="field-wrapper margin-top-16"
          validate={requiredValidator()}
          touchedOnBlur
        />
      ) : null}
      <Field
        type="email"
        name="email"
        label="Contact Email Address"
        component={SimpleField}
        formclassname="field-wrapper margin-top-16"
        validate={combineValidators(requiredValidator(), isEmailValidator())}
        touchedOnBlur
      />
      <div className="help-text">
        Customer replies to emails sent from the Event’s Messaging will come here.
      </div>
      <div className="buttons-wrapper">
        <Button type="button" className="back-button" disabled={isSubmitting} onClick={handleGoBack}>
          Back
        </Button>
        <Button 
          type="submit" 
          className={!isValid ? 'submit-disabled' : 'submit-button-login'} 
          disabled={!isValid} 
          loading={isSubmitting}
        >
          <span>Create account</span>
        </Button>
      </div>
    </Form>
  )
}

export default CreateAccountForm
