import React from 'react'

const BuyersCell = ({ value }) => (
  <div className="value-counts">
    {value}
    <div>Buyers</div>
  </div>
)

export { BuyersCell }
