export const GENRES = [
  {
    id: 'eventGenres_sixtieth',
    label: '60s'
  },
  {
    id: 'eventGenres_seventieth',
    label: '70s'
  },
  {
    id: 'eventGenres_eighties',
    label: '80s'
  },
  {
    id: 'eventGenres_nineties',
    label: '90s'
  },
  {
    id: 'eventGenres_acidElectro',
    label: 'Acid / Electro'
  },
  {
    id: 'eventGenres_african',
    label: 'African'
  },
  {
    id: 'eventGenres_asian',
    label: 'Asian'
  },
  {
    id: 'eventGenres_caribbean',
    label: 'Caribbean'
  },
  {
    id: 'eventGenres_classical',
    label: 'Classical'
  },
  {
    id: 'eventGenres_dubReggae',
    label: 'Dub / Reggae'
  },
  {
    id: 'eventGenres_edm',
    label: 'EDM'
  },
  {
    id: 'eventGenres_hipHopR&B',
    label: 'Hip Hop / R&B'
  },
  {
    id: 'eventGenres_house',
    label: 'House'
  },
  {
    id: 'eventGenres_indieAlt',
    label: 'Indie / Alt'
  },
  {
    id: 'eventGenres_synth',
    label: 'Synth'
  },
  {
    id: 'eventGenres_indieDance',
    label: 'Indie Dance'
  },
  {
    id: 'eventGenres_metalHeavy',
    label: 'Metal / Heavy'
  },
  {
    id: 'eventGenres_rockNRoll',
    label: 'Rock N Roll'
  },
  {
    id: 'eventGenres_soulFunk',
    label: 'Soul / Funk'
  },
  {
    id: 'eventGenres_techno',
    label: 'Techno'
  },
  {
    id: 'eventGenres_traditional',
    label: 'Traditional'
  },
  {
    id: 'eventGenres_ambientNewAge',
    label: 'Ambient / New Age'
  },
  {
    id: 'eventGenres_bassDubstep',
    label: 'Bass / Dubstep'
  },
  {
    id: 'eventGenres_breaksBeats',
    label: 'Breaks / Beats'
  },
  {
    id: 'eventGenres_deepTechHouse',
    label: 'Deep / Tech House'
  },
  {
    id: 'eventGenres_discoBoogie',
    label: 'Disco / Boogie'
  },
  {
    id: 'eventGenres_ebmIndustrial',
    label: 'EBM / Industrial'
  },
  {
    id: 'eventGenres_folkSingerSongwriter',
    label: 'Folk / Singer-Songwriter'
  },
  {
    id: 'eventGenres_glamPowerpop',
    label: 'Glam / Powerpop'
  },
  {
    id: 'eventGenres_gothDeathrock',
    label: 'Goth / Deathrock'
  },
  {
    id: 'eventGenres_indieDanceNuDisco',
    label: 'Indie Dance / Nu-Disco'
  },
  {
    id: 'eventGenres_jazzFreeform',
    label: 'Jazz / Freeform'
  },
  {
    id: 'eventGenres_jungleDrumBass',
    label: 'Jungle / Drum & Bass'
  },
  {
    id: 'eventGenres_latinEspañol',
    label: 'Latin / Español'
  },
  {
    id: 'eventGenres_middleEastern',
    label: 'Middle Eastern'
  },
  {
    id: 'eventGenres_noiseExperimental',
    label: 'Noise / Experimental'
  },
  {
    id: 'eventGenres_popTop40s',
    label: 'Pop / Top 40s'
  },
  {
    id: 'eventGenres_postpunkNewWave',
    label: 'Postpunk / New Wave'
  },
  {
    id: 'eventGenres_psychGarage',
    label: 'Psych / Garage'
  },
  {
    id: 'eventGenres_punkHardcore',
    label: 'Punk / Hardcore'
  },
  {
    id: 'eventGenres_showgazeDreampop',
    label: 'Shoegaze / Dreampop'
  },
  {
    id: 'eventGenres_worldClubNewClub',
    label: 'World Club / New Club'
  }
]
