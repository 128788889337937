import _map from 'lodash/map'
import _clone from 'lodash/clone'
import React from 'react'
import { Field } from 'formik'
import {
  // validators
  combineValidators,
  minNumberValidator,
  requiredValidator,
  // normalizers
  combineNormalizers,
  createUpperCaseNormalizer,
  toNumber
} from '../../../../_common/core/validation'
import { SimpleField, SelectField, DateTimePickerFieldReact } from '../../../formik/Fields'
import PriceRangesComponent from './PriceRangesComponent'

const range10 = _map(new Array(10), (item, index) => ({ label: index + 1, value: index + 1 }))
const displayNameReg = new RegExp(/([^()\[\]\s-]+)/g)
const displayNameOptions = ['ga', 'rsvp', 'vip', 'bf', 'nye', 'aaa', 'byob', 'byo', 'rv', 'abc']

export default class DataRowSlidingScale extends React.Component {
  constructor(props) {
    super(props)
    this.rightItemClassName = props.isEditing ? 'col-xs-12 col-sm-6' : 'col-xs-12 col-lg-3 col-sm-6 col-right'
    this.leftItemClassName = props.isEditing ? 'col-xs-12 col-sm-6' : 'col-xs-12 col-lg-3 col-sm-6 col-left'
    this.showPriceRanges = props.values.priceRanges && props.values.priceRanges.length
  }

  componentDidUpdate() {
    const { values, updateField } = this.props
    const lastIndex = values.priceRanges.length - 1
    if (values.maxInventory && !values.priceRanges[lastIndex].to) {
      const updatedPriceRanges = _clone(values.priceRanges)
      updatedPriceRanges[lastIndex] = {
        ...updatedPriceRanges[lastIndex],
        to: values.maxInventory
      }
      updateField('priceRanges', updatedPriceRanges)
    }
  }

  handleMaxInventoryChange = (key, value, event) => {
    const { values, updateField } = this.props
    const priceRanges = _clone(values.priceRanges)
    const lastIndex = priceRanges.length - 1

    if (lastIndex >= 0 && priceRanges[lastIndex].type === 'info') {
      priceRanges[lastIndex] = {
        from: priceRanges[priceRanges.length - 1].to,
        to: value,
        price: '',
        type: 'info'
      }
    } else {
      priceRanges.push({
        from: '',
        to: value,
        price: '',
        type: 'info'
      })
    }

    updateField('priceRanges', priceRanges)
  }

  render() {
    const { values, isEditing } = this.props

    return (
      <div className="row data-row">
        {!isEditing && (
          <div className="col-xs-12 col-sm-6">
            <p style={{ fontSize: 11, lineHeight: 'normal', fontWeight: '600' }}>
              {`This is a sliding scale ticket model. 
              Please specify the starting price of the ticket and your fundraising goal. 
              The prices for all tickets will start to go down, once the goal is reached. 
              You can control the ranges of sign-ups and corresponding drops in prices too.`}
            </p>
          </div>
        )}
        <div className="div-spacing-20" />
        <div className={isEditing ? 'col-xs-12 col-sm-12' : 'col-xs-12 col-sm-6'}>
          <Field
            name="displayName"
            label="Ticket Name"
            component={SimpleField}
            validate={requiredValidator()}
            normalizer={createUpperCaseNormalizer(displayNameReg, displayNameOptions)}
          />
        </div>
        <div className={this.rightItemClassName}>
          <Field
            name="maxInventory"
            type="number"
            min={0}
            label="Maximum Number of Sales"
            component={SimpleField}
            validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
            onBlurNormalizer={toNumber}
            onChange={this.handleMaxInventoryChange}
          />
        </div>
        <div className={this.leftItemClassName}>
          <Field
            name="quantityIncrement"
            label="Must be bought in multiples of"
            options={range10}
            component={SelectField}
          />
        </div>
        <div className={isEditing ? 'col-xs-12 col-sm-12' : 'col-xs-12 col-sm-6'}>
          <Field
            name="fundraisingGoal"
            label="Fundraising Goal"
            component={SimpleField}
            validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
            onBlurNormalizer={combineNormalizers(toNumber)}
          />
        </div>
        <div className={this.rightItemClassName}>
          <Field
            name="slidingScaleStartDate"
            label="Sliding Sales Start Date"
            placeholder="D MMM YYYY H:M AM"
            component={DateTimePickerFieldReact}
            validate={requiredValidator()}
          />
        </div>
        <div className={this.leftItemClassName}>
          <Field
            name="slidingScaleEndDate"
            label="Sliding Sales End Date (optional)"
            placeholder="D MMM YYYY H:M AM"
            component={DateTimePickerFieldReact}
            customError={true}
          />
        </div>
        {!!this.showPriceRanges && <PriceRangesComponent values={values} />}
      </div>
    )
  }
}
