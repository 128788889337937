// Re-render Optimization 13.05.2020
import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { connect } from 'react-redux'
import { FETCH_LAUNCH_DARKLY_INFO, SET_LD_FLAGS } from '../../_common/redux/launchdarkly/actions'
import { ENV } from '../constants/env'

const launchDarklyHOC = () => {
  @connect(
    state => ({
      user: state.auth.user,
    }),
    {
      FETCH_LAUNCH_DARKLY_INFO,
      SET_LD_FLAGS,
    },
  )
  class LaunchDarklyComponent extends React.PureComponent {
    componentDidMount() {
      const { user } = this.props
      const userId = _get(user, 'id', null)
      this.fetchLaunchDarklyInfo(userId)
    }

    componentDidUpdate(prevProps) {
      const { user } = this.props
      if (!prevProps.user && user) {
        const userId = _get(user, 'id', null)
        this.fetchLaunchDarklyInfo(userId)
      }
    }

    fetchLaunchDarklyInfo = async id => {
      const { user, FETCH_LAUNCH_DARKLY_INFO, SET_LD_FLAGS } = this.props
      const ldClientID = ENV.LD_CLIENT_SIDE_ID
      if (!id) return
      try {
        const result = await FETCH_LAUNCH_DARKLY_INFO(id)
        const launchDarklyInfo = _get(result, 'data.$original')

        if (launchDarklyInfo.launchDarklyKey) {
          const ldUser = {
            key: launchDarklyInfo.launchDarklyKey,
            custom: {
              groups: launchDarklyInfo.groups,
            },
          }

          if (launchDarklyInfo.launchDarklyKey !== 'anonymous') {
            _set(ldUser, 'firstName', _get(user, 'firstName'))
            _set(ldUser, 'lastName', _get(user, 'lastName'))
            _set(ldUser, 'email', _get(user, 'email'))
          }

          const ldClient = LDClient.initialize(ldClientID, ldUser)
          ldClient.on('ready', () => {
            const flags = ldClient.allFlags()
            SET_LD_FLAGS(flags)
          })
        }
      } catch (err) {}
    }

    render() {
      const { component } = this.props
      return component
    }
  }

  return LaunchDarklyComponent
}

export default launchDarklyHOC
