import React from 'react'

import Card from '../../../../_library/Card'
import TagsField from '../../../../_library/TagsField'

export default class OnlineEventTags extends React.Component {
  handleChangeTags = tags => {
    const { updateField } = this.props
    updateField('tags', tags)
  }

  render() {
    const { values, cardsStatus } = this.props
    return (
      <Card icon={'fa-tags'} title={'Tags'} status={cardsStatus && cardsStatus.tags}>
        <TagsField ref="tag" value={values.tags} onChange={this.handleChangeTags} />
      </Card>
    )
  }
}
