import React from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'

const SelectionModal = params => {
  const { isModalOpen = false, handleModalClose = () => null, selectionContent = null } = params
  return (
    <div>
      <Modal
        className="modal-dialog selection_modal modal-trans modal-daterange"
        style={modalStyle}
        isOpen={isModalOpen}
        contentLabel="Modal"
        ariaHideApp={false}
        onRequestClose={handleModalClose}
        closeTimeoutMS={150}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">Selection</p>
              </div>
              <div className="modal-body">{selectionContent}</div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button className="btn btn-cancel btn-shadow" type="button" onClick={handleModalClose}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SelectionModal
