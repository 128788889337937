import React, { Component } from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'

import { orderBy, get } from 'lodash'

import Card from '../../_library/Card'

import AddTicketModal from './AddTicketModal'
import AddItemsModal from './AddItemsModal'
import FilterDataForTicketTable from './FilterDataForTicketTable'
import FilterDataForItemsTable from './FilterDataForItemsTable'

import { withFormik, Form, Field } from 'formik'
import { SimpleField, CheckboxField, RichTextAreaField } from '../../formik/Fields'

// validators
import {
  // field level validators
  requiredValidator,

  // normalizer
  toInt
} from '../../../_common/core/validation'
import RichTextArea from '../../_library/RichTextArea'

class CreateMembershipForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpened: {
        base: false,
        guests: false,
        additionalItems: false
      },
      ticketGroups: [{ value: '', label: '' }],
      modalFieldsIndex: {
        base: 0,
        guests: 0,
        additionalItems: 0
      },
      isEditing: false
    }
    this.ticketTypes = [
      { value: '', label: 'Select...' },
      { value: 'ticket', label: 'Ticket' },
      { value: 'digital-item', label: 'Digital item' },
      { value: 'physical-item', label: 'Physical item' },
      { value: 'experiential-item', label: 'Experiential item' },
      { value: 'miscellaneous', label: 'Miscellaneous' }
    ]
  }

  componentDidMount() {
    const { timeSlotGroups, membershipData } = this.props

    if (membershipData && membershipData.id) {
      this.setState({ isEditing: true })
    }

    if (timeSlotGroups) {
      this.setState({
        ticketGroups: this.convertTicketGroupsForSelectField(timeSlotGroups)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.timeSlotGroups !== nextProps.timeSlotGroups) {
      this.setState({
        ticketGroups: this.convertTicketGroupsForSelectField(nextProps.timeSlotGroups)
      })
    }
  }

  convertTicketGroupsForSelectField = ticketGroups => {
    const group = ticketGroups.map(item => ({ value: item.id, label: item.groupName }))
    group.unshift({ value: '', label: 'Select...' })
    return group
  }

  closeModal = modalName => {
    this.setState(prevState => ({
      ...prevState,
      isModalOpened: {
        ...prevState.isModalOpened,
        [modalName]: false,
        modalTitle: null
      }
    }))
  }

  submitAdditionalItemsModal = modalValues => {
    const { setFieldValue, values } = this.props
    const { modalFieldsIndex } = this.state
    const { additionalItems } = values
    const newArray = [...additionalItems]

    newArray[modalFieldsIndex.additionalItems] = {
      ...modalValues,
      sortOrder: newArray[modalFieldsIndex.additionalItems]
        ? newArray[modalFieldsIndex.additionalItems].sortOrder
        : additionalItems.length + 1
    }

    setFieldValue(`additionalItems`, newArray)
    this.closeModal('additionalItems')
  }

  onSubmitTicketsModal = modalValues => {
    const { setFieldValue, values } = this.props
    const { modalFieldsIndex } = this.state

    const { base } = values
    const newArray = [...base]

    newArray[modalFieldsIndex.base] = { ...modalValues }

    setFieldValue(`base`, newArray)
    this.closeModal('base')
  }

  onSubmitFreeTicketsModal = modalValues => {
    const { setFieldValue, values } = this.props
    const { modalFieldsIndex } = this.state

    const { guests } = values
    const newArray = [...guests]

    newArray[modalFieldsIndex.guests] = { ...modalValues }

    setFieldValue(`guests`, newArray)
    this.closeModal('guests')
  }

  removeField = (modalName, id = 0) => {
    const { values } = this.props

    if (modalName === 'additionalItems') {
      this.props.setFieldValue(
        modalName,
        orderBy(values[modalName], ['sortOrder'])
          .filter(item => item.sortOrder !== id)
          .map(item => {
            if (item.sortOrder > id) {
              return { ...item, sortOrder: --item.sortOrder }
            }
            return item
          })
      )
    } else {
      this.props.setFieldValue(
        modalName,
        values[modalName].filter((item, i) => i !== id)
      )
    }
  }

  openModal = (modalName, index, modalTitle) => {
    this.setState(prevState => ({
      ...prevState,
      isModalOpened: {
        ...prevState.isModalOpened,
        [modalName]: true,
        modalTitle
      }
    }))
    this.setModalFieldsIndex(modalName, index)
  }

  editModal = (modalName, index) => {
    if (modalName === 'additionalItems') {
      this.setModalFieldsIndex(modalName, index - 1)
    } else {
      this.setModalFieldsIndex(modalName, index)
    }

    let modalTitle = null
    switch (modalName) {
    case 'base':
      modalTitle = 'Edit Ticket Type'
      break
    case 'guests':
      modalTitle = 'Edit Free Admission'
      break
    case 'additionalItems':
      modalTitle = 'Edit Item'
      break
    default:
      modalTitle = null
    }

    this.setState(prevState => ({
      ...prevState,
      isModalOpened: {
        ...prevState.isModalOpened,
        [modalName]: true,
        modalTitle
      }
    }))
  }

  updateSortOrder = data => {
    const newAr = [...data]
    const orderedArray = orderBy(newAr, ['sortOrder'])

    orderedArray.map(item => {
      this.props.setFieldValue(`additionalItems.${item.index}.sortOrder`, item.sortOrder)
    })
  }

  updateAdditionalItemsFieldValues = newItemValues => {
    const { setFieldValue } = this.props
    setFieldValue(`additionalItems`, newItemValues)
  }

  setModalFieldsIndex = (modalName, index) => {
    this.setState(prevState => ({
      ...prevState,
      modalFieldsIndex: {
        ...prevState.modalFieldsIndex,
        [modalName]: index
      }
    }))
  }

  getActions = (id, modalName) => (
    <div className="actions-wrapper">
      <button
        type="button"
        className="btn btn-primary btn-shadow actions-button"
        onClick={() => this.editModal(modalName, id)}
      >
        <i className="fa fa-pencil fa-fw" aria-hidden="true" />
          Edit
      </button>
      <button
        type="button"
        className="btn btn-seablue btn-shadow actions-button"
        onClick={() => this.removeField(modalName, id)}
      >
        <i className="fa fa-trash fa-fw" aria-hidden="true" />
          Remove
      </button>
    </div>
  )

  render() {
    const { ticketGroups, isModalOpened, modalFieldsIndex, isEditing } = this.state
    const { timeSlotGroups, values, onCancel, setFieldValue } = this.props

    return (
      <Form>
        <Card title="Membership Details">
          <div className="row">
            <div className="col-xs-12">
              <Field
                name={'name'}
                label={'Name'}
                component={SimpleField}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                name={'description'}
                label={'Description'}
                component={RichTextAreaField}
                validate={requiredValidator()}
              />
            </div>
            <div className="div-spacing-20" />
            <div className="col-xs-6">
              <Field
                name={'duration'}
                label={'Duration (months)'}
                component={SimpleField}
                normalizer={toInt}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-6">
              <Field name={'cost'} label={'Price'} component={SimpleField} />
            </div>
            <div className="col-xs-6">
              <Field name={'enabled'} label={'Enabled'} component={CheckboxField} />
            </div>
            <div className="col-xs-6">
              <Field
                name={'options.guestTimeChecking'}
                label={'Free admission should be booked with main ticket'}
                component={CheckboxField}
              />
            </div>
          </div>
          <div className="div-spacing-10" />
        </Card>
        <Card title={'Tickets'}>
          <div className="row">
            <div className="col-xs-12">
              <button
                className="btn btn-success btn-shadow add-btn"
                onClick={() => this.openModal('base', values.base.length, 'Add Ticket Type')}
                type="button"
              >
                <i className="fa fa-plus" aria-hidden="true" />
                <span>Add Ticket Type</span>
              </button>
            </div>
          </div>
          {!!values.base.length && <div className="div-spacing-15" />}
          {!!values.base.length && (
            <FilterDataForTicketTable
              tableData={values.base}
              timeSlotGroups={timeSlotGroups}
              getActions={index => this.getActions(index, 'base')}
            />
          )}
          {isModalOpened.base && (
            <AddTicketModal
              onCloseModal={() => this.closeModal('base')}
              submitModal={this.onSubmitTicketsModal}
              isModalOpened={isModalOpened.base}
              ticketGroups={ticketGroups}
              values={values.base[modalFieldsIndex.base]}
              modalTitle={isModalOpened.modalTitle}
            />
          )}
        </Card>

        <Card title={'Free Admission'}>
          <div className="row">
            <div className="col-xs-12">
              <button
                className="btn btn-success btn-shadow add-btn"
                onClick={() => this.openModal('guests', values.guests.length, 'Add Free Admission')}
                type="button"
              >
                <i className="fa fa-plus" aria-hidden="true" />
                <span>Add Free Admission</span>
              </button>
            </div>
          </div>
          {!!values.guests.length && <div className="div-spacing-15" />}
          {!!values.guests.length && (
            <FilterDataForTicketTable
              tableData={values.guests}
              timeSlotGroups={timeSlotGroups}
              getActions={index => this.getActions(index, 'guests')}
            />
          )}
          {isModalOpened.guests && (
            <AddTicketModal
              onCloseModal={() => this.closeModal('guests')}
              submitModal={this.onSubmitFreeTicketsModal}
              isModalOpened={isModalOpened.guests}
              ticketGroups={ticketGroups}
              values={values.guests[modalFieldsIndex.guests]}
              modalTitle={isModalOpened.modalTitle}
            />
          )}
        </Card>
        <Card title={'Additional Items'}>
          <div className="row">
            <div className="col-xs-12">
              <button
                className="btn btn-success btn-shadow add-btn "
                onClick={() => this.openModal('additionalItems', values.additionalItems.length)}
                type="button"
              >
                <i className="fa fa-plus" aria-hidden="true" />
                <span>Add Additional Item</span>
              </button>
            </div>
          </div>
          {!!values.additionalItems.length && <div className="div-spacing-15" />}
          {!!values.additionalItems.length && (
            <FilterDataForItemsTable
              updateAdditionalItemsFieldValues={this.updateAdditionalItemsFieldValues}
              updateSortOrder={this.updateSortOrder}
              tableData={values.additionalItems}
              getActions={index => this.getActions(index, 'additionalItems')}
            />
          )}
          {isModalOpened.additionalItems && (
            <AddItemsModal
              onCloseModal={() => this.closeModal('additionalItems')}
              submitModal={this.submitAdditionalItemsModal}
              isModalOpened={isModalOpened.additionalItems}
              ticketTypes={this.ticketTypes}
              values={values.additionalItems[modalFieldsIndex.additionalItems]}
              modalTitle={isModalOpened.modalTitle}
            />
          )}
        </Card>

        <div className="row text-center">
          <div className="col-xs-12">
            <button className="btn btn-success btn-lg btn-shadow submit-button">
              {isEditing ? 'Save' : 'Create'}
            </button>
            <button className="btn btn-cancel btn-lg btn-shadow" type="button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Form>
    )
  }
}

const CreateMembershipFormWrapper = withFormik({
  mapPropsToValues: props => ({
    name: get(props, 'membershipData.name', ''),
    description: get(props, 'membershipData.description', ''),
    duration: get(props, 'membershipData.duration', ''),
    cost: get(props, 'membershipData.cost', ''),
    enabled: Boolean(get(props, 'membershipData.enabled', false)),
    base: get(props, 'membershipData.membershipPlan.base', []),
    guests: get(props, 'membershipData.membershipPlan.guests', []),
    options: {
      guestTimeChecking: get(
        props,
        'membershipData.membershipPlan.options.guestTimeChecking',
        false
      )
    },
    additionalItems: get(props, 'membershipData.membershipPlan.additionalItems', [])
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const valuesForSend = {
      data: {
        attributes: {
          eventId: props.eventId,
          membershipPlan: {
            base: values.base,
            guests: values.guests,
            options: values.options,
            additionalItems: values.additionalItems
          },
          name: values.name,
          description: values.description,
          duration: values.duration,
          price: values.cost,
          enabled: Number(values.enabled)
        }
      }
    }
    props.onSubmit(valuesForSend)
    setSubmitting(false)
  }
})(CreateMembershipForm)

export default CreateMembershipFormWrapper
