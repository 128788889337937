import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'

const { FETCH_LAUNCH_DARKLY_INFO } = createAsyncAction(
  'FETCH_LAUNCH_DARKLY_INFO',
  userId => dispatch =>
    fetchAPI(`/api/launch-darkly/${userId}/key`)
      .catch(err => {
        throw err
      })
      .then(res => res),
)

const { SET_LD_FLAGS } = createAsyncAction('SET_LD_FLAGS', function (flags) {
  return dispatch => dispatch(this.success({ data: { ...flags } }))
})

export { FETCH_LAUNCH_DARKLY_INFO, SET_LD_FLAGS }
