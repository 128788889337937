export const getUsersMenuItems = ({ isUsersPage }) => {
  const menuGroup = []

  menuGroup.push({
    img: 'team',
    title: 'Users',
    href: `/users`,
    active: isUsersPage,
    visible: true
  })

  return menuGroup
}
