import React, { Component } from 'react'
import _isEmpty from 'lodash/isEmpty'
import DataTable from '../../_library/DataTable'

class FilterDataForTicketTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    this.createTableBody(this.props.tableData, this.props.timeSlotGroups)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.createTableBody(nextProps.tableData, nextProps.timeSlotGroups)
    }
    if (this.props.timeSlotGroups !== nextProps.timeSlotGroups) {
      this.createTableBody(nextProps.tableData, nextProps.timeSlotGroups)
    }
  }

  createTableBody = (data, timeSlotGroups) => {
    this.setState({
      tableData: data.map((item, index) => {
        const group =
          !_isEmpty(timeSlotGroups) &&
          timeSlotGroups.find(group => +group.id === +item.ticketTypeGroupId)
        return [!_isEmpty(group) && group.groupName, item.count, this.props.getActions(index)]
      })
    })
  }

  render() {
    const { tableData } = this.state
    return (
      <DataTable
        thead={['Ticket Type', 'Quantity', '']}
        tbody={tableData}
        classNames={{ container: 'membership-usage-table' }}
      />
    )
  }
}

export default FilterDataForTicketTable
