import React, { useEffect, useRef } from 'react'

export const FieldAddress = ({ onClickBack, onClickCancel, options, optionComponent, touched, ...props }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current?.focus()
  }, [])
  return (
    <div className="address-field-container">
      <div className="search-container">
        <i aria-hidden className="fa fa-chevron-left" onClick={onClickBack} />
        <div>
          <i className="fa fa-search" />
          <input ref={inputRef} {...props} />
          <i aria-hidden className="fa fa-times" onClick={onClickCancel} />
        </div>
      </div>
      <div className="fixed-decoration" />
      {options.map((suggestion, i) => optionComponent(suggestion, i))}
    </div>
  )
}
