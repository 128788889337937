import React, { useState, useEffect } from 'react'
import RichTextArea from '../../_library/RichTextArea'
import { ENV } from '../../constants/env'

export default function SMSContainer({ editorRef, values, errors, handleChange, touched, handleBlur }) {
  const [counter, setCounter] = useState('')
  const [count, setCount] = useState(0)
  const [maxLimitReached, setMaxLimitReached] = useState(false)

  useEffect(() => {
    const editorFooter = document.querySelector('.tox-statusbar__branding')
    editorFooter && setCounter((editorFooter.innerHTML = `${count}/480`))
    if (count > 480 && !maxLimitReached) {
      editorFooter.style.color = '#BF5B5A'
      setMaxLimitReached(true)
    } else if (editorFooter && maxLimitReached && count <= 480) {
      editorFooter.style.color = '#89949B'
      setMaxLimitReached(false)
    }
  }, [count])

  const customCounter = chars => {
    setCount(chars)
  }
  return (
    <div className="sms-container">
      <div className="row">
        <div className="col-xs-12">
          <RichTextArea
            customCounter={customCounter}
            editorRef={editorRef}
            smsField={true}
            charLimit={480}
            id="SMSBody"
            disableEmbeds={true}
            disablePlugin={true}
            baseurl={ENV.CDN_URL}
            value={values?.SMSBodyTags}
            error={errors?.SMSBody}
            touched={touched?.SMSBody}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  )
}
