import React, { PureComponent } from 'react'
import _get from 'lodash/get'
import { Field, Form, Formik } from 'formik'
import Modal from 'react-modal'

import { storage_set } from '../../../_common/core/http_services'

import { SimpleField } from '../../formik/Fields'
import Button from '../Button'

import { combineValidators, maxLengthValidator, requiredValidator } from '../../../_common/core/validation'
import modalStyle from '../../../_common/core/modalStyle'
import { showAxiosError } from '../../utils/messenger'

class SaveTemplate extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isModalOpen: false,
    }
  }
  openOrCloseModal = open => {
    this.setState(() => ({ isModalOpen: open }))
  }
  onClickSave = () => {
    this.openOrCloseModal(true)
  }
  onSave = async form => {
    const { storagePath: path, data, onSaveFilter } = this.props
    const value = JSON.stringify(data)
    const { name } = form
    try {
      await storage_set({ name, path, value })
      onSaveFilter()
      this.openOrCloseModal(false)
    } catch (err) {
      const status = _get(err, 'response.status')
      if (status === 409) {
        showAxiosError('A filter template with this name already exists')
      } else {
        showAxiosError(err)
      }
    }
  }
  render() {
    const { loading, isModalOpen } = this.state

    const { saveBtnName = 'Save Filter as a Template', data, isEmptyChecker } = this.props

    return (
      <div>
        <Button
          loading={loading}
          disabled={loading || isEmptyChecker(data)}
          className="btn btn-primary btn-shadow btn-no-text-transform"
          onClick={this.onClickSave}
        >
          {saveBtnName}
        </Button>

        <Modal
          className="modal-dialog modal-trans modal-medium"
          style={modalStyle}
          isOpen={isModalOpen}
          contentLabel="Modal"
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-medium">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <div>Save Filter as a Template</div>
                </div>
                <div className="modal-body">
                  <Formik
                    initialValues={{
                      name: '',
                    }}
                    onSubmit={async (values, actions) => {
                      const { setSubmitting } = actions
                      try {
                        await this.onSave(values)
                      } finally {
                        setSubmitting(false)
                      }
                    }}
                    render={({ values, isSubmitting }) => (
                      <Form autoComplete="off" className="form_holder">
                        <div className="row">
                          <div className="col-xs-12">
                            <Field
                              name="name"
                              label={'Name'}
                              component={SimpleField}
                              validate={combineValidators(
                                requiredValidator(),
                                maxLengthValidator(undefined, 50),
                              )}
                            />
                          </div>
                        </div>

                        <div className="modal-footer credential_modal_action_buttons">
                          <div className="btn-toolbar btn-toolbar-right">
                            <Button
                              className="btn btn-success btn-shadow"
                              type="submit"
                              loading={isSubmitting}
                            >
                              Save
                            </Button>
                            <Button
                              className="btn btn-cancel btn-shadow"
                              type="button"
                              onClick={() => this.openOrCloseModal(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export { SaveTemplate }
