/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _has from 'lodash/has'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'

import { FETCH_BRAND_PAYMENT_DATA, APPLY_PAYMENT_METHOD } from '../../_common/redux/brands/actions'
import Card from '../_library/Card'
import Button from '../_library/Button'
import ConfirmModal from '../_library/ConfirmModal'
import { showAxiosError } from '../utils/messenger'
import { getStripeConnectLink, manageStripeConnectedAccount } from '../../_common/core/http_services'

const modalContent =
  'Do you want to set the same payment account to the events under this brand that do not have sales yet?'
const stripeButtonMessage =
  'The connected Stripe account will be the default payment method for all future events created under this Brand, but will not affect already created events.'
const stripeResumeButtonMessage = 'Finish connecting your stripe account with the link below'

const PayoutMethod = React.memo(props => {
  const {
    brand,
    brandPaymentData,
    FETCH_BRAND_PAYMENT_DATA,
    APPLY_PAYMENT_METHOD,
    isLoading,
    isApplying,
    configs,
    isOwner,
  } = props
  const [stripe, setStripeData] = useState({})
  const [stripeOnboardingLink, setStripeOnBoardingLink] = useState(null)
  const [stripeOnboardingLinkIsLoading, setStripeOnboardingLinkIsLoading] = useState(false)
  const [manageAccountRequestIsLoading, setManageAccountRequestIsLoading] = useState(false)
  const [showAccountConnectModal, setShowAccountConnectModal] = useState(false)
  const overlayRef = useRef(null)

  const stripeConnectedAccount = _get(brandPaymentData, 'stripe.connectedAccount', {})
  const showStripeConnectedAccount = _has(stripeConnectedAccount, 'enabled')
  const isShowStripeConnectedAccountConfig = _get(configs, 'appearance.showStripeConnectedAccount')
  const enableStripeConnectedAccount = _get(stripe, 'connectedAccount.enabled', false)
  const affectableEventsList = _get(brandPaymentData, 'affectableEventsList')
  let showConnectStripetoEvents = !!(affectableEventsList && affectableEventsList.length)

  const fetchPaymentData = async () => {
    const res = await FETCH_BRAND_PAYMENT_DATA(brand.id)
    return res
  }

  const fetchStripeOnBoardingLink = async _ => {
    setStripeOnboardingLinkIsLoading(true)
    try {
      const res = await getStripeConnectLink(brand.id)
      setStripeOnBoardingLink(_get(res, 'connectLink'))
      setStripeOnboardingLinkIsLoading(false)
    } catch (e) {
      setStripeOnboardingLinkIsLoading(false)
      showAxiosError(e)
    }
  }

  useEffect(() => {
    fetchPaymentData()
  }, [])

  useEffect(() => {
    const connectAuthUrl = _get(brandPaymentData, 'stripe.connectAuthUrl')

    if (!connectAuthUrl) {
      if (
        _get(brandPaymentData, 'stripe.connectVersion') !== 'oauth' &&
        !stripeOnboardingLink &&
        _get(brandPaymentData, 'stripe.connectedAccount.canResumeOnboarding')
      ) {
        fetchStripeOnBoardingLink()
      }
      setStripeData(brandPaymentData.stripe || {})
      return
    }

    const url = new URL(connectAuthUrl)
    const stateValue = url.searchParams.get('state')
    const decodedStateValue = atob(stateValue)
    const parsedStateValueObj = JSON.parse(decodedStateValue)
    const updatedStateValueObj = {
      ...parsedStateValueObj,
      redirect_uri: window.location.origin + `/brand/${brand.id}/stripe-connect-complete`,
    }
    const strStateValueObj = JSON.stringify(updatedStateValueObj)
    const encodedStateValueObj = btoa(strStateValueObj)
    url.searchParams.set('state', encodedStateValueObj)

    setStripeData({ ...brandPaymentData.stripe, connectAuthUrl: url.href })
  }, [brand, brandPaymentData])

  const handleButtonClick = () => {
    // in case of stripe connect complete page opened by path
    window.localStorage.removeItem('stripe-connected')

    const listenStorageChange = async () => {
      if (localStorage.getItem('stripe-connected') && !isLoading) {
        const res = await fetchPaymentData()
        showConnectStripetoEvents = !!(_get(res, 'affectableEventsList') || []).length
        window.localStorage.removeItem('stripe-connected')
        window.removeEventListener('storage', listenStorageChange)
        showConnectStripetoEvents && setShowAccountConnectModal(true)
      }
    }

    window.addEventListener('storage', listenStorageChange)
    if (_get(stripe, 'connectVersion') === 'oauth') {
      window.open(stripe.connectAuthUrl, '_blank')
    } else {
      fetchStripeOnBoardingLink()
    }
  }

  const openOnboardingLink = _ => {
    window.open(stripeOnboardingLink, '_blank')
  }

  const showStripeConnectInfo = !!stripe.connectAuthUrl
  const connectedAccountName = _get(stripe, 'connectedAccount.name')
  const connectedAccountStatus = _get(stripe, 'connectedAccount.status', 'unknown')
  const connectedPaymentMethod = _get(stripe, 'paymentMethod')
  const showResumeOnboardingButton = _get(stripe, 'connectedAccount.canResumeOnboarding', false)

  const handleConfirm = async () => {
    try {
      await APPLY_PAYMENT_METHOD(brand.id, connectedPaymentMethod)
      await fetchPaymentData()
      setShowAccountConnectModal(false)
    } catch (error) {
      setShowAccountConnectModal(false)
      showAxiosError(error)
    }
  }

  const handleManageStripeConnectedAccount = async () => {
    try {
      setManageAccountRequestIsLoading(true)
      overlayRef.current.handleHide()
      await manageStripeConnectedAccount(brand.id, connectedPaymentMethod, !enableStripeConnectedAccount)
      setStripeData(prevState => ({
        ...prevState,
        connectedAccount: {
          ...prevState.connectedAccount,
          enabled: !prevState.connectedAccount.enabled,
        },
      }))
    } catch (e) {
      showAxiosError(e)
    } finally {
      setManageAccountRequestIsLoading(false)
    }
  }

  return (
    <>
      <ConfirmModal
        isOpen={showAccountConnectModal}
        header="Confirmation"
        content={
          <>
            <p style={{ padding: 10 }}>{modalContent}</p>
            <ul>
              {_map(affectableEventsList, item => (
                <li key={item.slug}>{item.displayName}</li>
              ))}
            </ul>
          </>
        }
        actions={[
          { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow', loading: isApplying },
          { value: 'no', label: 'No', className: 'btn btn-cancel btn-shadow', disabled: isApplying },
        ]}
        onAction={value => (value === 'no' ? setShowAccountConnectModal(false) : handleConfirm())}
      />
      <Card icon="fa-money" title="Payment Method" closed={false}>
        <div className="stripe-btn-message">
          <p>{stripeButtonMessage}</p>
        </div>
        {showStripeConnectInfo ? (
          <>
            {isOwner ? <Button
              key="stripe-connect-button"
              className="btn btn-primary btn-lg btn-shadow"
              type="button"
              onClick={!!stripeOnboardingLink ? openOnboardingLink : handleButtonClick}
              loading={stripeOnboardingLinkIsLoading}
            >
              {!stripeOnboardingLink ? (
                'Connect Stripe Account'
              ) : (
                <>
                  Go To Onboarding
                  <i
                    style={{ margin: '4px 6px 0 6px' }}
                    className="fa fa-external-link fa-fw"
                    aria-hidden="true"
                  />
                </>
              )}
            </Button> : <OverlayTrigger
              placement={'top'}
              overlay={<Tooltip id="clipboardCopied">An external Stripe account can only be connected by the Brand Owner.</Tooltip>}
              trigger={['hover']}
            >
              <div style={{ width: "221px", padding: "12px" }}>
                <Button
                  key="stripe-connect-button"
                  className="btn btn-primary btn-lg btn-shadow"
                  type="button"
                  disabled
                >
                  Connect Stripe Account
                </Button>
              </div>
            </OverlayTrigger>}
            {stripeOnboardingLinkIsLoading && <span> Preparing your onboarding...</span>}
          </>
        ) : null}
        {!showStripeConnectInfo ? (
          <>
            <p>
              <strong>Connected Account: </strong>
              {connectedAccountName || 'N/A'}
            </p>
            <p>
              <strong>Status: </strong>
              {connectedAccountStatus}
            </p>
            {showResumeOnboardingButton && isOwner && (
              <>
                <div className="stripe-btn-message">
                  <p>{stripeResumeButtonMessage}</p>
                </div>
                <div style={{ marginBottom: '8px' }}>
                  <Button
                    key="stripe-connect-button"
                    className="btn btn-primary btn-lg btn-shadow"
                    type="button"
                    onClick={openOnboardingLink}
                    loading={stripeOnboardingLinkIsLoading}
                    disabled={!stripeOnboardingLink}
                  >
                    Edit Stripe Account
                    <i
                      style={{ margin: '4px 6px 0 6px' }}
                      className="fa fa-external-link fa-fw"
                      aria-hidden="true"
                    />
                  </Button>
                  {stripeOnboardingLinkIsLoading && <span> Preparing link...</span>}
                </div>
              </>
            )}
          </>
        ) : null}
        {showConnectStripetoEvents && !showStripeConnectInfo && isOwner && (
          <>
            <Button
              key="stripe-connect-button"
              className="btn btn-primary btn-lg btn-shadow"
              type="button"
              onClick={() => setShowAccountConnectModal(true)}
            >
              Connect Stripe Account to Events
            </Button>
          </>
        )}
        {showStripeConnectedAccount && isShowStripeConnectedAccountConfig && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="announce-location-button-tooltip">
                {!enableStripeConnectedAccount
                  ? 'Further created sub-brands will inherit this Stripe account details. This will not affect already existing sub-brands'
                  : 'Further created sub-brands will not inherit this Stripe account details.'}
              </Tooltip>
            }
            trigger={['hover']}
            ref={overlayRef}
          >
            <div style={{ width: 'max-content', marginTop: 8 }}>
              <Button
                className={`btn ${
                  !enableStripeConnectedAccount ? 'btn-primary' : 'btn-danger'
                } btn-lg btn-shadow`}
                style={{ outline: 'none' }}
                type="button"
                loading={manageAccountRequestIsLoading}
                onClick={handleManageStripeConnectedAccount}
              >
                {!enableStripeConnectedAccount ? 'Use for sub-brands' : 'Disconnect Stripe account'}
              </Button>
            </div>
          </OverlayTrigger>
        )}
      </Card>
    </>
  )
})

const mapStateToProps = state => {
  const brandPaymentData = _get(state, 'brands.brandPaymentData')
  const isLoading = state.loading.has('FETCH_BRAND_PAYMENT_DATA')
  const isApplying = state.loading.has('APPLY_PAYMENT_METHOD')

  return {
    isLoading,
    isApplying,
    brandPaymentData,
  }
}

const mapDispatchToProps = {
  FETCH_BRAND_PAYMENT_DATA,
  APPLY_PAYMENT_METHOD,
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutMethod)
