import createAction from 'redux-actions/lib/createAction'
import { createAsyncAction } from '../actions'
import { ERROR } from '../notification/actions'
import * as fb from '../../core/fb'
import { fetchAPI } from '../../core/http'
import { ENV } from '../../../web/constants/env'

const ad_api_base = ENV.FBAD_API_BASE

const { CREATE_AD } = createAsyncAction('CREATE_AD', function (form) {
  return dispatch =>
    fetch(ad_api_base + '/generateAll', {
      method: 'POST',
      body: JSON.stringify(form.attributes),
      headers: { 'Content-Type': 'application/json' },
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res.json())
      .then(json => {
        if (json.name === 'FacebookRequestError') {
          const errors = [{ code: json.name, details: json.message }]
          dispatch(ERROR(...errors))
          dispatch(this.failed(json))
        } else {
          dispatch(this.success(json))
        }
      })
})

const { UPDATE_AD } = createAsyncAction('UPDATE_AD', function (ad, campaignId) {
  return dispatch =>
    fetch(ad_api_base + '/updatead', {
      method: 'PUT',
      body: JSON.stringify(ad),
      headers: { 'Content-Type': 'application/json' },
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res.json())
      .then(json => {
        if (json.name === 'FacebookRequestError') {
          const errors = [{ code: json.name, details: json.message }]
          dispatch(ERROR(...errors))
          dispatch(this.failed(json))
        } else {
          // const actId = ad.user.accountId.slice(4)
          // const link = 'https://www.facebook.com/ads/manager/account/campaigns/?act='
          // + actId + '&selected_campaign_ids=' + campaignId
          // const successes = [{code: 'update_ad_success', details:'Your Facebook Ad Campaign is now active! '},
          //   {code: 'ad_success_link', details: 'View it here: ' + link}]

          // dispatch(SUCCESS(...successes))
          dispatch(this.success(json))
          return json
        }
      })
})

const { DEPLOY_AD } = createAsyncAction('DEPLOY_AD', function (adId, user) {
  const ad = {
    id: adId,
    user,
  }
  return dispatch =>
    fetch(ad_api_base + '/deployad', {
      method: 'PUT',
      body: JSON.stringify(ad),
      headers: { 'Content-Type': 'application/json' },
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res.json())
      .then(json => {
        if (json.name === 'FacebookRequestError') {
          const errors = [{ code: json.name, details: json.message }]
          dispatch(ERROR(...errors))
          dispatch(this.failed(json))
        } else {
          dispatch(this.success(json))
          return json
        }
      })
})

const { CREATE_ADCREATIVE } = createAsyncAction('CREATE_ADCREATIVE', function (form) {
  const body = form.attributes.adCreative
  body.user = form.attributes.user
  return dispatch =>
    fetch(ad_api_base + '/adcreatives', {
      //mode: 'cors',
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res.json())
      .then(json => {
        if (json.name === 'FacebookRequestError') {
          const errors = [{ code: json.name, details: json.message }]
          dispatch(ERROR(...errors))
          dispatch(this.failed(json))
        } else {
          dispatch(this.success(json))
          return json
        }
      })
})

const { GENERATE_PREVIEW } = createAsyncAction('GENERATE_PREVIEW', function (id) {
  return dispatch =>
    fb
      .fetchSDK()
      .catch(window.onerror)
      .then(FB => {
        FB.api(`/${id}/previews?ad_format=DESKTOP_FEED_STANDARD`, res => {
          if (!res || res.error) {
            const errors = [{ code: res.error.code, details: res.error.message }]
            dispatch(ERROR(...errors))
            dispatch(this.failed(res))
          } else {
            const { data } = res
            dispatch(this.success(data))
            return data
          }
        })
      })
      .catch(err => {
        dispatch(ERROR(...err))
        dispatch(this.failed(err))
        throw err
      })
})

const { FETCH_ADS } = createAsyncAction('FETCH_ADS', function (userId) {
  return dispatch => {
    const url = `/api/ads/`
    return fetchAPI(url, { params: {} })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        if (res.error) {
          const errors = [{ code: 'Ads Fetch Error', details: res.error }]
          dispatch(ERROR(...errors))
          dispatch(this.failed(res))
        } else {
          dispatch(this.success(res))
          return res
        }
      })
  }
})

const CLEAR_PREVIEW = createAction('CLEAR_PREVIEW')

export { CREATE_AD, UPDATE_AD, DEPLOY_AD, CREATE_ADCREATIVE, GENERATE_PREVIEW, FETCH_ADS, CLEAR_PREVIEW }
