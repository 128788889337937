import React from 'react'

const StripeConnectComplete = () => {
  window.localStorage.setItem('stripe-connected', true)

  return (
    <div className="brand-stripe-connect-complete">
      <p>{`Your onboarding process is complete, and you're connected successfully. You can close this window.`}</p>
    </div>
  )
}
export default StripeConnectComplete
