import React from 'react'
import _ from 'lodash'
import { Formik, Form, Field } from 'formik'

import Button from '../../_library/Button'

import { SelectField } from '../../formik/Fields'
import { MONTHS_OPTIONS } from './constants'

// validators and normalizers
import { requiredValidator } from '../../../_common/core/validation'

import GiftFieldsSection from './GiftFieldsSection'
import LoadingBar from '../../_library/LoadingBar'

const options = [
  { label: 'Select...', value: '' },
  { label: 'Ticket', value: 'flagTicket' },
  { label: 'Amount', value: 'flagAmount' }
  // { label: 'Gift a membership', value: 'flagMemebership' }
]

export const validateForm = (values, ticketGiftCardTypes) => {
  const errors = {}
  if (values.selectedFlag === 'flagAmount' && !(values.amount && values.amount.length)) {
    _.set(errors, 'amountValue', 'Required')
  }
  if (_.find(ticketGiftCardTypes, item => item.ticketGroupId == values.ticketGroupId)) {
    _.set(errors, 'ticketGroupIdCustomError', 'Gift certificate has already been used')
  }
  return errors
}

export default class GiftCardManagementForm extends React.PureComponent {
  getSelectedTicketType = groupedTicketsOptions => groupedTicketsOptions && groupedTicketsOptions.length
    ? groupedTicketsOptions[0].value
    : 0

  showFieldsSection = selectedFlag => {
    if (selectedFlag && selectedFlag.length) {
      return !selectedFlag.includes('flagMemebership')
    } return false
  }

  render() {
    const {
      groupedTicketsOptions,
      ticketGiftCardTypes,
      onSubmit,
      currency,
      handleCancelAdding,
      handleTicketTypeChange,
      isFetchingTickets
    } = this.props

    return (
      <Formik
        initialValues={{
          selectedFlag: '',
          ticketGroupId: '',
          groupItemPrice: '',
          requiresShipping: false,
          enabled: true,
          amount: [],
          duration: '',
          amountValue: ''
        }}
        onSubmit={(values, actions) => {
          const { setSubmitting, resetForm } = actions
          onSubmit(values).finally(() => {
            resetForm()
            setSubmitting(false)
          })
        }}
        validate={values => validateForm(values, ticketGiftCardTypes)}
        render={({ values, isSubmitting, setFieldValue, errors, touched, setFieldTouched }) => (
          <Form autoComplete="off">
            {isFetchingTickets && (
              <div
                className="row"
                style={{
                  opacity: '0.8',
                  position: 'absolute',
                  zIndex: 1000,
                  width: '100%'
                }}
              >
                <LoadingBar />
              </div>
            )}
            <div className="row">
              <div className="col-xs-6 col-sm-6">
                <Field
                  name="selectedFlag"
                  label="Gift Certificate Type"
                  options={options}
                  component={SelectField}
                  validate={requiredValidator()}
                />
              </div>
              <div className="col-xs-6 col-sm-6">
                <Field
                  name="duration"
                  label="Duration (months)"
                  options={MONTHS_OPTIONS}
                  component={SelectField}
                  validate={requiredValidator()}
                />
              </div>
            </div>
            {!!values.selectedFlag.length && this.showFieldsSection(values.selectedFlag) && (
              <GiftFieldsSection
                values={values}
                errors={errors}
                touched={touched}
                currency={currency}
                setFieldValue={setFieldValue}
                groupedTicketsOptions={groupedTicketsOptions}
                handleToggleChange={(fieldKey, checked) => setFieldValue(fieldKey, checked)}
                handleTicketTypeChange={(fieldName, value) => {
                  setFieldTouched(fieldName, true)
                  handleTicketTypeChange(fieldName, value, setFieldValue)
                }}
              />
            )}
            <div className="row">
              <div className="col-xs-12 gift-button-container">
                <Button
                  className="btn btn-primary btn-success"
                  type="submit"
                  loading={isSubmitting}
                >
                  Create Gift Certificate
                </Button>
                <Button
                  className="btn btn-cancel btn-shadow"
                  type="button"
                  onClick={handleCancelAdding}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="div-spacing-10" />
          </Form>
        )}
      />
    )
  }
}
