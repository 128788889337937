import { createAsyncAction } from '../actions'
import { ERROR } from '../notification/actions'

// http
import { getDraftEvents } from '../../core/http_services'

const { FETCH_DRAFT_EVENTS_INCREMENTAL } = createAsyncAction('FETCH_DRAFT_EVENTS_INCREMENTAL', function () {
  return dispatch => {
    const fetchDraftEvents = async (_offset, _limit) => {
      try {
        const res = await getDraftEvents(_offset, _limit)
        let fullReady = true
        if (res.pagination) {
          const { limit, offset, total } = res.pagination
          fullReady = offset + limit >= total
          if (!fullReady) {
            fetchDraftEvents(offset + limit, total)
          }
        }
        res.fullReady = fullReady

        dispatch(this.success(res))
        return res
      } catch (err) {
        dispatch(ERROR([err]))
      }
    }
    return fetchDraftEvents(0, 50)
  }
})

export { FETCH_DRAFT_EVENTS_INCREMENTAL }
