import { parsePlaceResult } from '../../web/events/form/EventForm/utils'

const KEY = 'redux_form_changed_fields'

export const CARD_STATUS_UNCHANGED = 0
export const CARD_STATUS_CHANGED = 1
export const CARD_STATUS_SAVING = 2
export const CARD_STATUS_SAVED = 3

global.form_helper_reset = function helper_reset() {
  localStorage.setItem(KEY, null)
}

global.form_helper_get = function helper_get() {
  let fields = JSON.parse(localStorage.getItem(KEY))
  if (fields == null) fields = {}
  return fields
}

global.form_helper_set = function helper_set(obj) {
  localStorage.setItem(KEY, JSON.stringify(obj))
}

global.form_helper_isEditted = function helper_isEditted() {
  if (JSON.parse(localStorage.getItem(KEY)) == null) return false
  return true
}

global.emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

global.NFTRegex = /^0x[a-fA-F0-9]{40}$/

global.validateEmail = function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

global.ticketTypesSorting = function ticketTypesSorting(tickets) {
  const noGroupedTickets = []
  const groupedTickets = []
  const groupedTicketsMap = {}
  tickets.forEach(el => {
    if (el.slotGroupName) {
      const p = groupedTicketsMap[el.slotGroupName]
      groupedTicketsMap[el.slotGroupName] = p ? [...p, el] : [el]
    } else {
      noGroupedTickets.push(el)
    }
  })
  for (const key in groupedTicketsMap) {
    const g = groupedTicketsMap[key]
    g.groupName = key
    g.groupId = g[0].slotGroupId
    groupedTickets.push(g)
  }
  return [noGroupedTickets, groupedTickets]
}

export const getVenueFromAddress = (address = '') => {
  const { google } = global
  if (!address || !google) return {}
  const geocoder = global.google ? new google.maps.Geocoder() : null
  if (geocoder) {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        const { OK, ZERO_RESULTS, REQUEST_DENIED } = google.maps.GeocoderStatus
        if (status === ZERO_RESULTS || status === REQUEST_DENIED) {
          resolve({ query: address, venue: null })
        }
        if (status !== OK) {
          reject(status)
        }
        resolve({
          query: address,
          venue: results && results.length ? parsePlaceResult(results[0]) : {},
        })
      })
    })
  }
  return {}
}
