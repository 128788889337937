import React from 'react'
import TagsField from '../../_library/TagsField'

export default function NFTForm({ errors, touched, nftContracts, nftInput, setFieldValue, onTagsChange, size, inputDisabled }) {
  const { polygon = [], ethereum = [] } = nftContracts
  const isMobile = isMobileDevice()
  return (
    <>
      {size ? <div className='div-spacing-10' /> : null}
      <div className="col-sm-6 nft-fields">
        <label>Ethereum</label>
        <TagsField
          autocomplete="new-password"
          inputDisabled={inputDisabled}
          tagsContainerClassName={`form-control${
            errors.emails && touched.emails ? ' form-control-error' : ''
          }`}
          name="ethereum"
          value={ethereum}
          onChange={tags => onTagsChange('eth', tags)}
          controlOutside
          placeholder={'Add an NFT contract address'}
          focusOnStart
          error={errors.ethereum}
          touched={touched.nftContracts}
          inputValue={nftInput.ethereum}
          onChangeInput={value => {
            setFieldValue('nftInput.ethereum', value)
          }}
          renderTag={props => {
            const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
            const isValid = global.NFTRegex.test(tag)
            return (
              <span
                key={key}
                {...other}
                style={{
                  color: isValid ? 'rgb(233, 233, 233)' : '#d27373',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  verticalAlign: 'bottom'
                }}
              >
                {getTagDisplayValue(tag)}
                {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
              </span>
            )
          }}
          onlyUnique={true}
          addKeys={[9, 13, 32, 188]}
          pasteSplit
          inputClassName="input-responsive"
          tagsInputClassName="limit-input"
        />
      </div>
      {isMobile ? <div className='div-spacing-15' /> : null}
      <div className="col-sm-6 nft-fields">
        <label>Polygon</label>
        <TagsField
          autocomplete="new-password"
          inputDisabled={inputDisabled}
          tagsContainerClassName={`form-control${
            errors.emails && touched.emails ? ' form-control-error' : ''
          }`}
          name="polygon"
          value={polygon}
          onChange={tags => onTagsChange('pol', tags)}
          controlOutside
          placeholder={'Add an NFT contract address'}
          focusOnStart
          error={errors.polygon}
          touched={touched.nftContracts}
          inputValue={nftInput.polygon}
          onChangeInput={value => {
            setFieldValue('nftInput.polygon', value)
          }}
          renderTag={props => {
            const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
            const isValid = global.NFTRegex.test(tag)
            return (
              <span
                key={key}
                {...other}
                style={{
                  color: isValid ? 'rgb(233, 233, 233)' : '#d27373',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  verticalAlign: 'bottom'
                }}
              >
                {getTagDisplayValue(tag)}
                {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
              </span>
            )
          }}
          onlyUnique={true}
          addKeys={[9, 13, 32, 188]}
          pasteSplit
          inputClassName="input-responsive"
          tagsInputClassName="limit-input"
        />
      </div>
    </>
  )
}
