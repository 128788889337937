import _map from 'lodash/map'
import _get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Notifications from '../../_library/notifications/Notifications'
import EmptyBar from '../../_library/EmptyBar'
import LoadingBar from '../../_library/LoadingBar'

import { FETCH_DRAFT_EVENTS_INCREMENTAL } from '../../../_common/redux/draftevents/actions'

import { getTableColumns } from '../../utils/sortableTableUtils'
import { getVenueFromAddress } from '../../../_common/core/form_helper'
import LazyLoadImage from '../../_library/LazyLoadImage'
import DetailsCell from './components/DetailsCell'
import TableHeadCustom from '../../_library/SortableTable/TableHeadCustom'
import SortableTableWithSearchHOC from '../../../web/hoc/SortableTableWithSearchHOC'
import { getTitle } from '../../utils/getTitle'

const SortableTableWithSearch = SortableTableWithSearchHOC()

@withRouter
@connect(
  state => ({
    loading: state.loading.has('FETCH_DRAFT_EVENTS_INCREMENTAL'),
    draftEventsInitial: state.draftEvents.initial,
    fullReady: state.draftEvents.fullReady,
    draftEvents: state.draftEvents.draftEvents,
  }),
  { FETCH_DRAFT_EVENTS_INCREMENTAL }
)
export default class DraftEventList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      { key: 'eventImage', label: null, className: 'mw-160' },
      { key: 'eventDetails', label: 'Date' }
    ])
    this.tableColumnsCustom = getTableColumns([
      { key: 'title', label: 'Name' },
      { key: 'startDate', label: 'Date' }
    ])
    this.searchBy = ['title', 'venue', 'startDate']

    this.state = {
      draftEvents: this.getUpdatedDraftEvents(props.draftEvents),
      sortBy: { column: 'title', asc: true },
      loadingGetVenue: false,
    }
  }
  componentDidMount() {
    this.props.FETCH_DRAFT_EVENTS_INCREMENTAL()
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  componentDidUpdate(prevProps) {
    const { draftEvents: nEvents } = this.props
    const { draftEvents: pEvents } = prevProps
    if (nEvents !== pEvents) {
      this.setState({
        draftEvents: this.getUpdatedDraftEvents(nEvents)
      })
    }
  }

  getUpdatedDraftEvents = (draftEvents = []) => _map(draftEvents, item => ({
    ...item,
    eventImage: <LazyLoadImage imageUrl={item.image} disableLink={true} />,
    eventDetails: (
      <DetailsCell
        eventTitle={item.title}
        disableLinkToEvent={true}
        isOnline={item.isOnline}
        date={item.startDate}
        venue={{ displayName: item.venue || 'N/A' }}
      />
    )
  }))

  handleSort = (data, sortBy) => {
    this.setState(prevState => ({
      ...prevState,
      draftEvents: data,
      sortBy
    }))
  }

  handleCreateNewEvent = async event => {
    const { history: { push } } = this.props
    const { eventImage, eventDetails, ...rest } = event
    try {
      this.setState(() => ({ loadingGetVenue: true }))
      const venueData = await getVenueFromAddress(rest.address)
      localStorage.setItem('draftEvent', JSON.stringify({ ...rest, venueData }))
    } catch (e) {
      localStorage.setItem('draftEvent', JSON.stringify({ ...rest }))
    } finally {
      this.setState(() => ({ loadingGetVenue: false }))
    }
    push('/events/new')
  }

  render() {
    const { loading, fullReady, draftEventsInitial } = this.props
    const { draftEvents, sortBy, loadingGetVenue } = this.state

    return (
      <div className="body-main events-page">
        <Notifications />
        <div className="draft-event-container">
          <div className="body-panel-header clearfix">
            <div className="left inline">
              <div className="title">Draft Events</div>
            </div>
            <div className="right" />
          </div>
          <div className="body-panel-spacing" />
          <div className="body-panel-content">
            {(draftEventsInitial || loading || loadingGetVenue) && (
              <LoadingBar title={"Hold tight! We're getting your draft events list..."} />
            )}
            {!loading && !loadingGetVenue && fullReady && draftEvents.length === 0 && <EmptyBar />}
            {!loading && !loadingGetVenue && !!draftEvents.length && (
              <div>
                <SortableTableWithSearch
                  data={draftEvents}
                  tableColumns={this.tableColumns}
                  hideHeader={true}
                  enableCopyTable={false}
                  enableSort={false}
                  sortBy={sortBy}
                  searchBy={this.searchBy}
                  actionsClassName="pos-end"
                  actions={[
                    {
                      label: 'Create New Event',
                      className: 'btn btn-primary btn-spacing-5',
                      icon: 'fa fa-pencil fa-fw',
                      onClick: data => this.handleCreateNewEvent(data)
                    }
                  ]}
                  customHead={
                    <TableHeadCustom
                      data={draftEvents}
                      tableColumns={this.tableColumnsCustom}
                      sortBy={sortBy}
                      handleSort={this.handleSort}
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
