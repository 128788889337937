import React, { Component } from 'react'
import Button from '../../_library/Button'
import DeactivateModal from '../../_library/ModalDeactivate'
import PrizeModal from './PrizeModal'
import PreRegistrationPrizeComponent from './PreRegistrationComponent'

class PreRegistrationPrize extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: null, //table dropDown visible key
      deleteModalVisible: false, //delete modal visible
      idForDelete: null, //delete modal item it for delete
      prizeModalVisible: false, //prize modal( add/edit ) visible
      prizeModalHeaderText: '',
      prizeId: null, //prize id flag to decide request method
      prizeInfo: {
        name: '',
        description: '',
        image: ''
      }
    }
  }

  handleDeleteModalVisible = (id = null) => {
    const { deleteModalVisible } = this.state
    this.setState({ deleteModalVisible: !deleteModalVisible, idForDelete: id })
  }

  handleDeleteAccept = param => {
    const { handleDeletePrize } = this.props
    const { idForDelete } = this.state
    const deleteParams = {
      attributes: [
        {
          id: idForDelete
        }
      ]
    }
    handleDeletePrize(deleteParams, this.handleDeleteModalVisible)
  }

  handlePrizeSubmit = values => {
    const { handlePrizeCreateSubmit, handlePrizeUpdateSubmit } = this.props
    const { prizeId } = this.state
    if (!prizeId) {
      const dataForSubmit = {
        type: 'preregistration_prizes',
        relationships: [],
        attributes: [values]
      }
      return handlePrizeCreateSubmit(dataForSubmit, this.handlePrizeModalVisible)
    } 
    const dataForSubmit = {
      type: 'preregistration_prizes',
      relationships: [],
      attributes: {
        [prizeId]: values
      }
    }
    return handlePrizeUpdateSubmit(dataForSubmit, this.handlePrizeModalVisible, prizeId)
    
  }

  handlePrizeModalVisible = () => {
    const { prizeModalVisible } = this.state
    this.setState({
      prizeModalVisible: !prizeModalVisible
    })
  }

  handleCreateClick = () => {
    this.setState({
      prizeModalHeaderText: 'Create Prize',
      prizeId: null,
      prizeInfo: {
        name: '',
        description: '',
        image: ''
      }
    })
    this.handlePrizeModalVisible()
  }

  handleEditClick = item => {
    this.setState({
      prizeModalHeaderText: 'Edit Prize',
      prizeId: item.id,
      prizeInfo: {
        name: item.name,
        description: item.description,
        image: item.image
      }
    })
    this.handlePrizeModalVisible()
  }

  render() {
    const { deleteModalVisible, prizeModalVisible, prizeModalHeaderText, prizeInfo } = this.state
    const { data } = this.props
    return (
      <div className="pre_registration_prize_section">
        <div className="row">
          <div className="col-xs-12">
            <Button className="btn btn-success btn-lg btn-shadow" onClick={this.handleCreateClick}>
              Add new prize
            </Button>
          </div>
        </div>
        {!!data.length && <div className="div-spacing-15" />}
        <div className="pre_reg_prize_container">
          {data.length
            ? data
              .map((item, index) => (
                <PreRegistrationPrizeComponent
                  key={index}
                  data={item}
                  handleEditClick={this.handleEditClick}
                  handleDeleteModalVisible={this.handleDeleteModalVisible}
                />
              ))
            : null}
        </div>
        <DeactivateModal
          deleting={deleteModalVisible}
          header="Delete Prize"
          content="Are you sure you want to delete this prize ?"
          handleCancelDeactivate={this.handleDeleteModalVisible}
          handleDeactivate={this.handleDeleteAccept}
        />
        <PrizeModal
          isOpen={prizeModalVisible}
          header={prizeModalHeaderText}
          handleSubmit={this.handlePrizeSubmit}
          handleCancel={this.handlePrizeModalVisible}
          prizeInfo={prizeInfo}
        />
      </div>
    )
  }
}

export default PreRegistrationPrize
