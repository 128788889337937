import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'
import _isEqual from 'lodash/isEqual'
import { showAxiosError } from '../../../web/utils/messenger'

const initialState = {
  performance: {},
  checkin: null,
  checkinDates: [],
  checkInAddonsDates: [],
}

const FETCH_EVENT_DEMOGRAPHICS = createAsyncHandlers('FETCH_EVENT_DEMOGRAPHICS', {
  success(state, action) {
    const {
      performance: { data },
    } = action.payload
    state.performance = _get(data, '$original')
  },
})

const FETCH_EVENT_CHECKIN = createAsyncHandlers('FETCH_EVENT_CHECKIN', {
  success(state, action) {
    const { data } = action.payload
    try {
      const stateData = JSON.parse(JSON.stringify(state.checkin))
      if (!_isEqual(data, stateData)) {
        state.checkin = data || {}
      }
    } catch (e) {
      showAxiosError(e)
    }
  },
})

const FETCH_EVENT_CHECKIN_TICKETS = createAsyncHandlers('FETCH_EVENT_CHECKIN_TICKETS', {
  success(state, action) {
    const {
      data: { tickets },
    } = action.payload
    state.checkin.tickets = tickets
  },
})

const FETCH_EVENT_CHECKIN_DATES = createAsyncHandlers('FETCH_EVENT_CHECKIN_DATES', {
  success(state, action) {
    const { data } = action.payload
    state.checkinDates = data || []
  },
})

const FETCH_EVENT_CHECKIN_ADD_ONS_DATES = createAsyncHandlers('FETCH_EVENT_CHECKIN_ADD_ONS_DATES', {
  success(state, action) {
    const { data } = action.payload
    state.checkInAddonsDates = data || []
  },
})

const RESET_CHECK_IN = createAsyncHandlers('RESET_CHECK_IN', {
  success(state) {
    state.checkin = null
    state.checkinDates = []
    state.checkInAddonsDates = []
  },
})

export default handleActions(
  {
    ...FETCH_EVENT_DEMOGRAPHICS,
    ...FETCH_EVENT_CHECKIN,
    ...FETCH_EVENT_CHECKIN_TICKETS,
    ...FETCH_EVENT_CHECKIN_DATES,
    ...FETCH_EVENT_CHECKIN_ADD_ONS_DATES,
    ...RESET_CHECK_IN,
  },
  initialState,
)
