import { baseRequest } from '.'
import _get from 'lodash/get'

export const getAllNotes = async orderID => {
  const res = await baseRequest.get(`order/${orderID}/notes`)
  return _get(res, 'data.data.attributes') || []
}

export const deleteNote = async noteID => {
  const res = await baseRequest.delete(`order/note/${noteID}`)
  return res
}

export const editNote = async (noteID, note) => {
  const res = await baseRequest.put(`order/note/${noteID}`, {
    data: {
      attributes: {
        note,
      },
      relationships: [],
      type: 'order_note',
    },
  })
  return res
}

export const createNote = async (orderID, note) => {
  const res = await baseRequest.post(`order/${orderID}/notes/`, {
    data: {
      attributes: {
        note,
      },
      relationships: [],
      type: 'order_note',
    },
  })
  return res
}
