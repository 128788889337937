import React from 'react'
import _identity from 'lodash/identity'
import { Field } from 'formik'
import { AlternativeCheckbox } from '../../../formik/Fields'
import { ENV } from '../../../constants/env'

const AltViewCheckboxField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  label,
  ...props
}) => {
  const { event } = props
  const { values } = props
  const image = !!props.image
  const description = !!props.description

  const initialCheckedData = (check) => {
    const format = {}
    switch(check) {
    case 'titleCheck': {
      if(!values.title) {
        format.title = event.displayName || ''
      }
      break
    }
    case 'slugCheck': {
      if(!values.slug) {
        format.slug = event.slug || ''
      }
      break
    }
    case 'dateCheck': {
      if(!values.startDate || !values.endDate) {
        format.startDate = event.startDate || ''
        format.endDate = event.endDate || ''
      }
      break
    }
    case 'venueDetailsCheck': {
      const venue = {
        venueName : values.venueName || null,
        streetNumber : values.streetNumber || null,
        street : values.street || null,
        city : values.city || null,
        state : values.state || null,
        postalCode : values.postalCode || null,
        country : values.country || null,
        flagHidden : values.flagHidden || null,
        flagDisabled : values.flagDisabled || null,
        googlePlaceId : values.googlePlaceId || null,
        latitude : values.latitude || null,
        longitude : values.longitude || null,
        hideUntil : values.hideUntil || null,
        locationInformation : values.locationInformation  || null
      }
      const override = Object.values(venue).some(Boolean)
      if(!override) {
        format.venueName = event.venue.displayName || ''
        format.streetNumber = event.venue.streetNumber || ''
        format.street = event.venue.street || ''
        format.city = event.venue.city || ''
        format.state = event.venue.state || ''
        format.postalCode = event.venue.postalCode || ''
        format.country = event.venue.country || ''
        format.flagHidden = event.venue.flagHidden || false
        format.flagDisabled = event.venue.flagDisabled || false
        format.googlePlaceId = event.venue.googlePlaceId || ''
        format.latitude = event.venue.latitude || ''
        format.longitude = event.venue.longitude || ''
        format.hideUntil = event.venue.hideUntil || ''
        format.locationInformation = event.venue.locationInformation || ''
      }
      break
    }
    case 'backgroundCheck': {
      if(!values.backgroundImage) {
        format.autoBackground = !event.backgroundURL
        format.backgroundImage = event.backgroundURL
      } else {
        format.autoBackground = !values.backgroundImage
        format.backgroundImage = values.backgroundImage
      }
      break
    }
    case 'eventLogoCheck': {
      format.titleReplacementImage = ''
      if(!values.titleReplacementImage) {
        if (event.titleReplacementImage && !event.titleReplacementImage.startsWith('https://')) {
          format.titleReplacementImage = `${ENV.API_BASE}/uploaded/${event.titleReplacementImage}`
        }
      } else {
        if (values.titleReplacementImage && !values.titleReplacementImage.startsWith('https://')) {
          format.titleReplacementImage = `${ENV.API_BASE}/uploaded/${values.titleReplacementImage}`
        } else {
          format.titleReplacementImage = values.titleReplacementImage
        }
      }
      format.useImageInsteadOfTitle = !!format.titleReplacementImage || false
      break
    }
    default: {
      break
    }
    }
    Object.keys(format).map(key => {
      setFieldValue(key, normalizer(format[key]))
    })
  }

  const localeChecked = check => {
    if(image) {
      const image = values.imageURLs && values.imageURLs[values.imageLanguage] ? values.imageURLs[values.imageLanguage] : event.imageURLs[values.imageLanguage] || null
      const updatedURLs = { ...values.imageURLs, [values.imageLanguage]: image }
      setFieldValue('imageURLs', updatedURLs)
    }
    if(description) {
      const description = values.descriptions && values.descriptions[values.descriptionLanguage] ? values.descriptions[values.descriptionLanguage] : event.descriptions[values.descriptionLanguage] || null
      const updatedDescriptions = { ...values.descriptions, [values.descriptionLanguage]: description }
      setFieldValue('descriptions', updatedDescriptions)
      setFieldValue('descriptionLanguage', values.descriptionLanguage)
      setFieldValue('forceUpdateTextEditor', true)
    }

  }

  return (
    <Field
      {...props}
      {...field}
      customized
      localeChecked={localeChecked}
      initialCheckedData={initialCheckedData}
      component={AlternativeCheckbox}
    />
  )
}

export { AltViewCheckboxField }

