import React from 'react'
import { Button } from 'react-bootstrap'
import ConfirmModal from '../../../../_library/ConfirmModal'
import RichTextArea from '../../../../_library/RichTextArea'
import { ENV } from '../../../../constants/env'
import { announceLocation } from '../../../../../_common/core/http_services/events'
import LoadingBar from '../../../../_library/LoadingBar'
import { showAxiosError, showMessage } from '../../../../utils/messenger'
import Field from '../../../../_library/Field'
import _isEmpty from 'lodash/isEmpty'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'

export default class AnnounceLocationButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      isLoading: false,
      errors: {},
      subject: `Location disclosure for ${props.event.displayName}`
    }
  }

  onConfirmResponse = async response => {
    const { values, event } = this.props
    const { subject } = this.state
    try {
      if (response === 'yes') {
        const errors = {}
        if (!values.locationInformation) {
          errors.locationInformation = "Required"
        }
        if (!subject) {
          errors.subject = "Required"
        }
        if (!_isEmpty(errors)) {
          this.setState({
            errors
          })
          throw new Error('Required')
        }
        this.setState({ isLoading: true })
        await announceLocation(event.id, values.locationInformation, subject)
        showMessage('success', 'Successfully sent!', 3, true)
        this.setState({ modalVisible: false, isLoading: false, errors: {} })
      } else {
        this.setState({ modalVisible: false, errors: {} })
      }
    } catch (e) {
      if (e?.message !== 'Required') {
        showAxiosError({}, 'Error while trying to send emails.')
        this.setState({ modalVisible: false, isLoading: false, errors: {} })
      }
    }
  }

  handleChangeLocationInfo = val => {
    const { updateField, forceUpdate } = this.props
    updateField('locationInformation', val)
    forceUpdate()
  }

  handleChangeLocationInfo = val => {
    const { updateField, forceUpdate } = this.props
    updateField('locationInformation', val)
    forceUpdate()
  }

  onClickAnnounceLocation = () => {
    this.setState({ modalVisible: true })
  }

  render() {
    const { values, disabled } = this.props
    const { modalVisible, isLoading, errors, subject } = this.state
    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={disabled ? (
            <Tooltip id='announce-location-button-tooltip'>
              Save changes to announce new location
            </Tooltip>
          ) : (
            <></>
          )}
          trigger={['hover']}
        >
          <div style={{display: 'inline-block'}}>
            <Button disabled={disabled} className="btn btn-ok btn-shadow announce-location-button" type="button" onClick={this.onClickAnnounceLocation}>
              Announce Location
            </Button>
          </div>
        </OverlayTrigger>
        <ConfirmModal
          isOpen={modalVisible}
          header="Confirm"
          content={
            isLoading ? (
              <LoadingBar key="loadingbar" />
            ) : (
              <>
                <div className="message-modal-content">
                  <strong>
                    Are you sure you want to notify all ticket holders about the event location and unhide the
                    location for future buyers?
                  </strong>
                </div>
                <div style={{ marginLeft: 18, marginRight: 18, marginTop: 40 }}>
                  <Field
                    id="subject"
                    label="Subject"
                    value={subject}
                    error={errors.subject}
                    touched={true}
                    onChange={e => {
                      this.setState({ subject: e.target.value })
                    }}
                    placeholder="Enter subject here..."
                  />
                </div>
                <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
                  <RichTextArea
                    ref={'locationInformation'}
                    id={'locationInformation'}
                    label="Location Information"
                    baseurl={ENV.CDN_URL}
                    value={values.locationInformation}
                    touched={true}
                    error={errors.locationInformation}
                    onChange={this.handleChangeLocationInfo}
                    placeholder={{ text: 'Enter text here...' }}
                  />
                </div>
              </>
            )
          }
          actions={[
            { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow' },
            { value: 'no', label: 'No', className: 'btn btn-default' }
          ]}
          onAction={this.onConfirmResponse}
        />
      </>
    )
  }
}
