import React, { Component } from 'react'
import DataTable from '../../_library/DataTable'
import Button from '../../_library/Button'
import DeactivateModal from '../../_library/ModalDeactivate'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'

class CredentialsTable extends Component {
  constructor(props) {
    super(props)
    this.handleDeactivateActivateModalVisible = this.handleDeactivateActivateModalVisible.bind(this)
    this.handleDeleteModalVisible = this.handleDeleteModalVisible.bind(this)
    this.handleDeactivateActivateAccept = this.handleDeactivateActivateAccept.bind(this)
    this.handleDeleteAccept = this.handleDeleteAccept.bind(this)
    this.renderBodyTicketTypes = this.renderBodyTicketTypes.bind(this)
    this.renderBodyAddOns = this.renderBodyAddOns.bind(this)
    this.state = {
      tableBody: this.renderTableBody(),
      tableHead: ['Status', 'Name', 'Code', 'Type', ' '],
      //
      deleteModalVisible: false,
      idForDelete: null,
      itemForeDeleteHasRules: false,
      ruleId: null,
      //
      deactivateActivateModalVisible: false,
      idForDeactivateActivate: null,
      credentialActive: true
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        tableBody: this.renderTableBody()
      })
    }
  }
  renderBodyTicketTypes(data) {
    const { ticketTypes } = this.props
    return _map(data, item => ticketTypes[item] ? <span key={item}>{ticketTypes[item].name}</span> : null)
  }
  renderBodyAddOns(data, excludedAddon = []) {
    const { ticketAddOns } = this.props
    const dataForReturn = []
    _forEach(data, item => {
      if (ticketAddOns[item] && !excludedAddon.includes(item)) {
        dataForReturn.push(<span key={item}>{ticketAddOns[item].name}</span>)
      }
    })
    if (!dataForReturn.length) {
      return null
    }
    return (
      <div>
        <h4>Add-Ons</h4>
        {dataForReturn}
      </div>
    )
  }
  renderTableBody() {
    const { data, handleCredentialModalVisible } = this.props
    return _map(data, (item, index) => {
      const { attributes } = item
      const row = [
        <i
          className={`fa fa-circle fa-fw ${!!+attributes.enabled ? 'text-success' : 'text-danger'}`}
        />,
        attributes.name,
        attributes.code || '',
        attributes.type,
        <div className="credential_table_action_buttons">
          {!!+attributes.enabled ? (
            <Button
              className="btn btn-seablue"
              type="button"
              onClick={() => this.handleDeactivateActivateModalVisible(item.id)}
            >
              <i className="fa fa-ban" />
              Deactivate
            </Button>
          ) : (
            <Button
              className="btn btn-ok"
              type="button"
              onClick={() =>
                this.handleDeactivateActivateModalVisible(item.id, !!+attributes.enabled)
              }
            >
              <i className="fa fa-cog" />
              Activate
            </Button>
          )}
          <Button
            className="btn btn-primary"
            type="button"
            onClick={() => handleCredentialModalVisible(item.attributes, true, item.id)}
          >
            <i className="fa fa-pencil fa-fw" />
            Edit
          </Button>
          <Button
            className="btn btn-danger"
            type="button"
            onClick={() =>
              this.handleDeleteModalVisible(item.id, attributes.hasRules, attributes.ruleId)
            }
          >
            <i className="fa fa-trash" />
            Delete
          </Button>
        </div>
      ]
      return row
    })
  }

  handleDeleteModalVisible(id = null, itemForeDeleteHasRules = false, ruleId = null) {
    const { deleteModalVisible } = this.state
    this.setState({
      deleteModalVisible: !deleteModalVisible,
      idForDelete: id,
      itemForeDeleteHasRules,
      ruleId,
      isDeleteLoading: false
    })
  }
  handleDeleteAccept() {
    const { handleDelete, data } = this.props
    const { idForDelete, itemForeDeleteHasRules, ruleId } = this.state
    this.setState({ isDeleteLoading: true })
    handleDelete(idForDelete, itemForeDeleteHasRules, data, ruleId, this.handleDeleteModalVisible)
  }
  handleDeactivateActivateModalVisible(id = null, credentialActive = true) {
    const { deactivateActivateModalVisible } = this.state
    this.setState({
      deactivateActivateModalVisible: !deactivateActivateModalVisible,
      idForDeactivateActivate: id,
      credentialActive,
      isLoading: false
    })
  }
  handleDeactivateActivateAccept() {
    const { handleActivateDeactivate, data } = this.props
    const { idForDeactivateActivate } = this.state
    this.setState({ isLoading: true })
    let deactivateParamsActivate = {}
    _forEach(data, element => {
      if (+_get(element, 'id') === +idForDeactivateActivate) {
        const enabled = _get(element, 'attributes.enabled') === '0' ? '1' : '0'
        deactivateParamsActivate = { ...element }
        deactivateParamsActivate.attributes.enabled = enabled
        delete deactivateParamsActivate.ticketType
        delete deactivateParamsActivate.excludedAddon
        delete deactivateParamsActivate.addon
        delete deactivateParamsActivate.hasRules
        delete deactivateParamsActivate.ruleId
      }
    })
    handleActivateDeactivate(
      idForDeactivateActivate,
      deactivateParamsActivate,
      data,
      this.handleDeactivateActivateModalVisible
    )
  }

  render() {
    const {
      tableHead,
      tableBody,
      deactivateActivateModalVisible,
      credentialActive,
      deleteModalVisible,
      isLoading,
      isDeleteLoading
    } = this.state
    const { data } = this.props
    const activateDeactivateText = credentialActive ? 'Deactivate' : 'Activate'
    const activateDeactivateClass = credentialActive ? 'btn-seablue' : 'btn-ok'
    return (
      <div className="credentials_table">
        {data.length ? (
          <DataTable thead={tableHead} tbody={tableBody} />
        ) : (
          <img
            className="no_data_ico"
            src={asset('/resources/images/no_data_icon_new.png')}
          />
        )}
        <DeactivateModal
          deleting={deleteModalVisible}
          content={`Are you sure you want to delete this Credential ?`}
          handleCancelDeactivate={this.handleDeleteModalVisible}
          handleDeactivate={this.handleDeleteAccept}
          deactivateLabel="Delete"
          isLoading={isDeleteLoading}
        />
        <DeactivateModal
          deleting={deactivateActivateModalVisible}
          content={`Are you sure you want to ${activateDeactivateText} this Credential ?`}
          handleCancelDeactivate={this.handleDeactivateActivateModalVisible}
          handleDeactivate={this.handleDeactivateActivateAccept}
          deactivateLabel={activateDeactivateText}
          deactivateClass={activateDeactivateClass}
          isLoading={isLoading}
        />
      </div>
    )
  }
}

export default CredentialsTable
