import React from 'react'
import Button from '../../_library/Button'

export default class CheckInDownload extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      showDownload: false,
    }
  }

	handleDownloadClick = (evt) => {
	  evt.preventDefault()
	  this.setState({ showDownload: !this.state.showDownload })
	}

	render() {
	  const { showDownload } = this.state
	  const isMobile = ($(window).width() >= 320 && $(window).width() <= 480)
	  return (
	    <div>
	      <div className="mobileview_download clearfix">
	        <div className="download_entryfairy">
	          <img src = {asset('/resources/images/mobile-appico.png')} className="icon"/>
	          <p>Entry Fairy</p>
	        </div>
	        <Button onClick={(evt) => this.handleDownloadClick(evt)} className="app_downloadlink btn btn-seablue event-button-s">Download App</Button>
	      </div>
	      { (showDownload || !isMobile) &&
					<div className="app_download">
					  <div className="entry_fairy">
					    <img src = {asset('/resources/images/mobile-appico.png')} className="icon"/>
					    <p>Entry Fairy</p>
					  </div>
					  <div className="app_downloadlinks">
					    <a href="https://itunes.apple.com/us/app/entry-fairy/id900716911?mt=8" target="_blank" rel="noreferrer">
					      <img src = {asset('/resources/images/mobile-ios.png')} className="icon"/>
					    </a>
					    <a href="https://play.google.com/store/apps/details?id=com.theticketfairy.entryfairy" target="_blank" rel="noreferrer">
					      <img src = {asset('/resources/images/mobile-android.png')} className="icon"/>
					    </a>
					  </div>
					</div>
	      }
	    </div>
	  )
	}
}
