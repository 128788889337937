import _get from 'lodash/get'

export const getScrollToElement = errors => {
  let scrollTo = ''
  const slug = _get(errors, 'slug')
  const owner = _get(errors, 'owner')
  const ogImage = _get(errors, 'ogImage')
  const endDate = _get(errors, 'endDate')
  const country = _get(errors, 'country')
  const region = _get(errors, 'region')
  const postalCode = _get(errors, 'postalCode')
  const venueName = _get(errors, 'venueName')
  const oneOfTags = _get(errors, 'oneOfTags')
  const startDate = _get(errors, 'startDate')
  const shortName = _get(errors, 'shortName')
  const eventType = _get(errors, 'eventType')
  const imageURLs = _get(errors, 'imageURLs')
  const displayName = _get(errors, 'displayName')
  const twitterImage = _get(errors, 'twitterImage')
  const descriptions = _get(errors, 'descriptions')
  const backgroundURL = _get(errors, 'backgroundURL')
  const titleReplacementImage = _get(errors, 'titleReplacementImage')
  const externalTicketingURL = _get(errors, 'externalUrl')
  const countryPayout = _get(errors, 'countryPayout')
  const accountName = _get(errors, 'accountName')
  const accountNumber = _get(errors, 'accountNumber')
  const routingNumber = _get(errors, 'routingNumber')
  const bsb = _get(errors, 'bsb')
  const sortCode = _get(errors, 'sortCode')
  const ifscNumber = _get(errors, 'ifscNumber')
  const bankName = _get(errors, 'bankName')
  const swiftCode = _get(errors, 'swiftCode')
  const accountType = _get(errors, 'accountType')
  const clabe = _get(errors, 'clabe')
  const fullAddress = _get(errors, 'fullAddress')
  const ethereum = _get(errors, 'ethereum')
  const polygon = _get(errors, 'polygon')
  const mainnet = _get(errors, 'mainnet')
  const institutionNumber = _get(errors, 'institutionNumber')
  const transitNumber = _get(errors, 'transitNumber')
  const countryCode = _get(errors, 'countryCode')

  if (owner || slug || startDate || endDate || shortName || displayName) {
    scrollTo = 'scroll-to-top'
  } else if (country || venueName || region || postalCode) {
    scrollTo = 'scroll-to-eventVenue'
  } else if (descriptions) {
    scrollTo = 'scroll-to-eventDescription'
  } else if (imageURLs || backgroundURL || titleReplacementImage) {
    scrollTo = 'scroll-to-eventImages'
  } else if (ogImage || twitterImage) {
    scrollTo = 'scroll-to-eventMediaImages'
  } else if (eventType) {
    scrollTo = 'scroll-to-eventType'
  } else if (oneOfTags) {
    scrollTo = 'scroll-to-eventGenres'
  } else if (externalTicketingURL) {
    scrollTo = 'scroll-to-eventAdditionalInformation'
  } else if (ethereum || polygon || mainnet) {
    scrollTo = 'scroll-to-nft'
  } else if (
    countryPayout ||
    accountType ||
    bankName ||
    ifscNumber ||
    sortCode ||
    bsb ||
    routingNumber ||
    accountName ||
    accountNumber ||
    swiftCode ||
    clabe ||
    fullAddress ||
    institutionNumber ||
    transitNumber ||
    countryCode
  ) {
    scrollTo = 'scroll-to-PayoutDetails'
  } else {
    scrollTo = 'scroll-to-top'
  }

  return scrollTo
}
