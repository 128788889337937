import React, { PureComponent } from 'react'
import Modal from 'react-modal'
import produce from 'immer'
import _map from 'lodash/map'

import modalStyle from '../../../../_common/core/modalStyle'
import Button from '../../../_library/Button'
import DataTable from '../../../_library/DataTable'
import { formatDate } from '../../../../_common/core/validation'
import { DISPLAY_FORMAT } from '../../../constants/timeFormats'

import {
  toTitleCase,
} from '../../../../_common/core/validation'

class FuturePaymentDetails extends PureComponent {
  constructor(props) {
    super(props)
    const { installments } = props
    this.state = {
      thead: this.getTableHeader(),
      tbody: this.getTableBody(installments),
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.installments !== this.props.installments) {
      this.setState(() => ({ installments: this.getTableBody(nextProps.installments) }))
    }
  }


  getTableHeader = () => [
    {
      sortable: true,
      value: 'Type',
    },
    {
      sortable: true,
      value: 'Amount',
    },
    {
      sortable: true,
      value: 'Date',
    },
    {
      sortable: true,
      value: 'Status',
    },
  ]

  getTableBody = (_data) => _map(_data, (item) => {
    let { type, amount, status, currency, charge_date, scheduled_date } = item
    const date = charge_date || scheduled_date
    amount = currency + amount
    return [
      {
        value: toTitleCase(type),
      },
      {
        value: amount,
      },
      {
        value: date,
        normalizer: (d) => formatDate(d, DISPLAY_FORMAT)
      },
      {
        value: toTitleCase(status),
      }
    ]
  })

  render() {
    const { thead, tbody, showModal } = this.state
    const { onClose } = this.props

    const content = (
      <DataTable
        thead={thead}
        tbody={tbody}
      />
    )

    return (
      <div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={true}
          contentLabel="Modal"
          onRequestClose={onClose}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Payment Plan</p>
                </div>
                <div className="modal-body">
                  {content}
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-default"
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export { FuturePaymentDetails }
