import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  rsvp: [],
  eid: '',
}

const FETCH_EVENT_RSVP = createAsyncHandlers('FETCH_EVENT_RSVP', {
  success(state, action) {
    const {
      rsvp: { data },
      eventId,
    } = action.payload

    state.eid = eventId
    const result = _get(data, '$original.attributes')
    state.rsvp = [...result.rnRsvp, ...result.rsvp]
  },
})

export default handleActions({ ...FETCH_EVENT_RSVP }, initialState)
