import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { DragDropContext } from 'react-dnd'
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'
import MultiBackend  from 'react-dnd-multi-backend'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _orderBy from 'lodash/orderBy'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

import LoadingBar from '../_library/LoadingBar'
import Button from '../_library/Button'
import EventFAQSection from './faq/EventFAQSection'
import modalStyle from '../../_common/core/modalStyle'
import Card from '../_library/Card'

import {
  FETCH_FAQS,
  CREATE_FAQ,
  UPDATE_FAQ,
  DELETE_FAQ,
  SORT_FAQS,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION
} from '../../_common/redux/faqs/actions'

import {
  get_event,
  get_event_faqs,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'



const NEW_SECTION = {
  title: '',
  id: null,
  type: 'faq',
  questions: [],
  isNewSection: true,
  isPublic: false
}

const NEW_QUESTION = {
  title: '',
  id: null,
  isPublic: false,
  isNewQuestion: true,
  content: ''
}

const resetSortOrder = arr => arr.forEach((el, i) => el.sortOrder = i + 1) || arr

@connect(
  (state) => {
    const event = get_event(state)
    const faqs = get_event_faqs(state)

    return {
      faqsListLoading: state.loading.has('FETCH_FAQS'),
      createFaqLoading: state.loading.has('CREATE_FAQ'),
      updateFaqLoading: state.loading.has('UPDATE_FAQ'),
      deleteFaqLoading: state.loading.has('DELETE_FAQ'),
      createQuestionLoading: state.loading.has('CREATE_QUESTION'),
      updateQuestionLoading: state.loading.has('UPDATE_QUESTION'),
      deleteQuestionLoading: state.loading.has('DELETE_QUESTION'),
      sortFaqsListLoading: state.loading.has('SORT_FAQS'),
      user: state.auth.user,
      event,
      faqs,
    }
  },
  { FETCH_FAQS, CREATE_FAQ, UPDATE_FAQ, DELETE_FAQ, SORT_FAQS, CREATE_QUESTION, UPDATE_QUESTION, DELETE_QUESTION }
)
@DragDropContext(MultiBackend(HTML5toTouch))
export default class EventFAQ extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sections: [],
      initialSections: [],
      moveHistory: [],
      moveHistoryCursor: 0,
      isChangedFieldsConfirmDialogOpen: false,
      changedField: null,
      errorMessage: '',
      onceInited: false,
    }
    this.onChangeQuestion = this.onChangeQuestion.bind(this)
    this.onChangeSection = this.onChangeSection.bind(this)
    this.confirmChangeAction = this.confirmChangeAction.bind(this)
    this.onConfirmClickCancel = this.onConfirmClickCancel.bind(this)
    this.onConfirmClickOk = this.onConfirmClickOk.bind(this)

    this.confrimAction = null
  }

  componentDidMount() {
    const { match: { params: { id } }, event: { displayName }, FETCH_FAQS, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    
    FETCH_FAQS(id)
    this.setState({
      sections: [],
      moveHistory: [],
      moveHistoryCursor: 0,
      isChangedFieldsConfirmDialogOpen: false,
      changedField: null,
      errorMessage: ''
    })
  }

  componentWillReceiveProps(nextProps) {
    const { faqs } = nextProps
    const {
      match: { params: { id } },
      createFaqLoading,
      updateFaqLoading,
      deleteFaqLoading,
      faqsListLoading,
      createQuestionLoading,
      updateQuestionLoading,
      deleteQuestionLoading,
      sortFaqsListLoading
    } = this.props

    const setInitialSections = (sections) => {
      const sortedFaqs = _orderBy(sections, ['sortOrder'], ['asc'])

      this.setState({
        initialSections: _cloneDeep(sortedFaqs),
      })
    }

    if((createFaqLoading && !nextProps.createFaqLoading) ||
      (updateFaqLoading && !nextProps.updateFaqLoading) ||
      (deleteFaqLoading && !nextProps.deleteFaqLoading) ||
      (createQuestionLoading && !nextProps.createQuestionLoading) ||
      (updateQuestionLoading && !nextProps.updateQuestionLoading) ||
      (deleteQuestionLoading && !nextProps.deleteQuestionLoading) ||
      (id !== nextProps.match.params.id) ||
      (sortFaqsListLoading && !nextProps.sortFaqsListLoading)) {
      this.props.FETCH_FAQS(nextProps.match.params.id)
    } else {
      setInitialSections(faqs)
    }

    if(faqsListLoading && !nextProps.faqsListLoading) {
      this.initSectionsFromProps(faqs, this.state.sections)
      this.setState({
        moveHistory: [],
        moveHistoryCursor: 0,
        isChangedFieldsConfirmDialogOpen: false,
        changedField: null,
        errorMessage: ''
      })
      this.confrimAction = null
    }

    if (!this.state.onceInited && faqs.length) { 
      this.setState({
        onceInited: true,
      })
      setInitialSections(faqs)
    }
  }

  checkLoading = () => {
    const {
      createFaqLoading,
      updateFaqLoading,
      deleteFaqLoading,
      faqsListLoading,
      createQuestionLoading,
      updateQuestionLoading,
      deleteQuestionLoading,
      sortFaqsListLoading
    } = this.props

    return (createFaqLoading || updateFaqLoading || deleteFaqLoading ||
      faqsListLoading || createQuestionLoading || updateQuestionLoading ||
      deleteQuestionLoading ||sortFaqsListLoading)
  }

  initSectionsFromProps = (faqs, prevSections) => {
    faqs = _cloneDeep(faqs)
    const sortedFaqs = _orderBy(faqs, ['sortOrder'], ['asc'])
    const sections = []
    for(let i = 0; i < sortedFaqs.length; i++) {
      const faq = sortedFaqs[i]
      const sortedQuestions = _orderBy(faq.questions, ['sortOrder'], ['asc'])

      // restore previously expanded state
      let expanded = false; let qExpanded = -1
      _map(prevSections, pSection => {
        if(+pSection.id === +faq.id){
          if(pSection.expanded)
            expanded = true
          _map(sortedQuestions, (question, index) => {
            for(let j = 0; j < pSection.questions.length; j++) {
              if(+question.id === +pSection.questions[j].id && pSection.questions[j].expanded) {
                qExpanded = index
              }
            }
          })
        }
      })

      faq.expanded = expanded
      if(qExpanded !== -1){
        sortedQuestions[qExpanded].expanded = true
      }
      faq.questions = sortedQuestions
      sections.push(faq)
    }
    this.setState({
      sections
    })
  }

  newSection() {
    this.confirmChangeAction(() => {
      this.setState(prevState => ({
        sections: [...prevState.sections, NEW_SECTION]
      }))
    }, { ...NEW_SECTION, isSection: true })
  }

  validate = (item, isSection) => {
    if(item.title === '') {
      this.setState({
        errorsDialogOpen: true,
        errorMessage: isSection ? 'The section name is required' : 'The question is required'
      })
      return false
    }
    return true
  }

  saveSection(index) {
    this.confirmChangeAction(() => {
      const { sections } = this.state
      const section  = sections[index]
      if(!this.validate(section, true)) {
        return
      }
      if(section.isNewSection) {
        this.props.CREATE_FAQ(this.props.event.id, section)
      } else {
        this.props.UPDATE_FAQ(section, this.props.event.id)
      }
    }, { ...this.state.sections[index], isSection: true })
  }

  deleteSection(index) {
    this.confirmChangeAction(() => {
      this.props.DELETE_FAQ(this.state.sections[index].id, this.props.event.id)
    }, { ...this.state.sections[index].id, isSection: true })
  }

  expandSection(index) {
    const { sections } = this.state
    for (let i=0; i<sections.length; i++) {
      if (i===index) {
        sections[i].expanded = true
      } else {
        sections[i].expanded = false
      }
    }
    this.setState({
      sections
    })
  }

  collapseSection(index) {
    const { sections } = this.state
    sections[index].expanded = false
    this.setState({
      sections
    })
  }

  moveSection(dragIndex, hoverIndex) {
    const isDraggingAction = true
    this.confirmChangeAction(() => {
      const { sections } = this.state
      const dragItem = sections[dragIndex]
      sections.splice(dragIndex, 1)
      sections.splice(hoverIndex, 0, dragItem)
      this.setState({
        sections: resetSortOrder(sections)
      })
      this.addMoveHistory('section', { dragIndex, hoverIndex })
    }, null, isDraggingAction)
  }

  onChangeSection(id, val, key) {
    const { sections } = this.state
    const index = sections.findIndex(item => item.id === id)

    this.confirmChangeAction(() => {
      this.setState(prevState => ({
        sections: [
          ...prevState.sections.slice(0, index),
          {
            ...prevState.sections[index],
            [key]: val
          },
          ...prevState.sections.slice(index + 1)
        ],
        changedField: {
          ...sections[index],
          isSection: true
        }
      }))
    }, { ...this.state.sections[index], isSection: true })
  }

  newItem(index) {
    this.confirmChangeAction(() => {
      const { sections } = this.state
      if(!sections[index].id) {
        if(!this.validate(sections[index], true)) {return}
        this.props.CREATE_FAQ(this.props.event.id, sections[index])
      }
      this.setState(prevState => ({
        sections: [
          ...prevState.sections.slice(0, index),
          {
            ...prevState.sections[index],
            questions: [...prevState.sections[index].questions, NEW_QUESTION]
          },
          ...prevState.sections.slice(index + 1)
        ]
      }), () => {this.expandSection(index)})
    }, NEW_QUESTION)
  }

  deleteItem(indexSection, indexItem) {
    const { event } = this.props
    this.confirmChangeAction(() => {
      this.props.DELETE_QUESTION(this.state.sections[indexSection].id, this.state.sections[indexSection].questions[indexItem].id, event.id)
    }, this.state.sections[indexSection].questions[indexItem])
  }

  saveItem(indexSection, indexItem) {
    this.confirmChangeAction(() => {
      const { sections } = this.state
      const { event } = this.props
      const section  = sections[indexSection]
      const question  = section.questions[indexItem]
      if(!this.validate(question)) {
        return
      }
      if(question.isNewQuestion) {
        this.props.CREATE_QUESTION(event.id, section.id, question)
      } else {
        this.props.UPDATE_QUESTION(section.id, question, event.id)
      }
    }, this.state.sections[indexSection].questions[indexItem])
  }

  moveItemInsideSection(indexSection, dragIndex, hoverIndex) {
    const isDraggingAction = true
    this.confirmChangeAction(() => {
      const { sections } = this.state
      const dragItem = sections[indexSection].questions[dragIndex]
      sections[indexSection].questions.splice(dragIndex, 1)
      sections[indexSection].questions.splice(hoverIndex, 0, dragItem)
      sections[indexSection].questions = resetSortOrder(sections[indexSection].questions)
      this.setState({
        sections
      })
      this.addMoveHistory('question', { indexSection, dragIndex, hoverIndex })
    }, null, isDraggingAction)
  }

  moveItemAcrossSection(dragIndexSection, dragIndexItem, hoverIndexSection) {
    const { sections } = this.state
    const dragItem = sections[dragIndexSection].questions[dragIndexItem]
    sections[hoverIndexSection].questions.push(dragItem)
    sections[dragIndexSection].questions.splice(dragIndexItem, 1)
    this.setState({
      sections
    })
    this.expandSection(hoverIndexSection)
    this.addMoveHistory('question-section', { dragIndexSection, dragIndexItem, hoverIndexSection })
  }

  expandItem(indexSection, indexItem) {
    const { sections } = this.state
    for (let i=0; i<sections[indexSection].questions.length; i++) {
      sections[indexSection].questions[i].expanded = (i===indexItem)
    }
    this.setState({
      sections
    })
  }

  collapseItem(indexSection, indexItem) {
    const { sections } = this.state
    sections[indexSection].questions[indexItem].expanded = false
    this.setState({
      sections
    })
  }

  onChangeQuestion(indexSection, indexItem, val, key) {
    this.confirmChangeAction(() => {
      const { sections } = this.state
      this.setState(prevState => ({
        sections: [
          ...prevState.sections.slice(0, indexSection),
          {
            ...prevState.sections[indexSection],
            questions: [
              ...prevState.sections[indexSection].questions.slice(0, indexItem),
              {
                ...prevState.sections[indexSection].questions[indexItem],
                [key]: val
              },
              ...prevState.sections[indexSection].questions.slice(indexItem + 1),
            ]
          },
          ...prevState.sections.slice(indexSection + 1)
        ],
        changedField: sections[indexSection].questions[indexItem]
      }))
    }, this.state.sections[indexSection].questions[indexItem])
  }

  addMoveHistory(type, info) {
    const { moveHistory, moveHistoryCursor } = this.state
    moveHistory.splice(moveHistoryCursor)
    moveHistory.push({
      type,
      info
    })
    this.setState({
      moveHistory,
      moveHistoryCursor: moveHistoryCursor + 1
    })
  }

  undoMoveHistory() {
    const { moveHistory, moveHistoryCursor, sections } = this.state
    if(moveHistoryCursor==0) {
      return
    }
    const move = moveHistory[moveHistoryCursor-1]
    if(move.type=='section') {
      const { dragIndex, hoverIndex } = move.info
      const dragSection = sections[hoverIndex]
      sections.splice(hoverIndex, 1)
      sections.splice(dragIndex, 0, dragSection)
      this.setState({
        moveHistoryCursor: moveHistoryCursor-1,
        sections: resetSortOrder(sections)
      })
    } else if(move.type=='question') {
      const { indexSection, dragIndex, hoverIndex } = move.info
      const dragItem = sections[indexSection].questions[hoverIndex]
      sections[indexSection].questions.splice(hoverIndex, 1)
      sections[indexSection].questions.splice(dragIndex, 0, dragItem)
      sections[indexSection].questions = resetSortOrder(sections[indexSection].questions)
      this.setState({
        moveHistoryCursor: moveHistoryCursor-1,
        sections: resetSortOrder(sections)
      },() => {
        this.expandSection(indexSection)
      })
    } else if(move.type=='question-section') {
      const { dragIndexSection, dragIndexItem, hoverIndexSection } = move.info
      const dragItem = sections[hoverIndexSection].questions[sections[hoverIndexSection].questions.length-1]
      sections[hoverIndexSection].questions.splice(sections[hoverIndexSection].questions.length-1, 1)
      sections[dragIndexSection].questions.splice(dragIndexItem, 0, dragItem)
      sections[hoverIndexSection].questions = resetSortOrder(sections[hoverIndexSection].questions)
      sections[dragIndexSection].questions = resetSortOrder(sections[dragIndexSection].questions)
      this.setState({
        moveHistoryCursor: moveHistoryCursor-1,
        sections: resetSortOrder(sections)
      },() => {
        this.expandSection(dragIndexSection)
      })
    }
  }

  redoMoveHistory() {
    const { moveHistory, moveHistoryCursor, sections } = this.state
    if(moveHistoryCursor==moveHistory.length) {
      return
    }
    const move = moveHistory[moveHistoryCursor]
    if(move.type=='section') {
      const { dragIndex, hoverIndex } = move.info
      const dragSection = sections[dragIndex]
      sections.splice(dragIndex, 1)
      sections.splice(hoverIndex, 0, dragSection)
      this.setState({
        moveHistoryCursor: moveHistoryCursor+1,
        sections: resetSortOrder(sections)
      })
    } else if(move.type=='question') {
      const { indexSection, dragIndex, hoverIndex } = move.info
      const dragItem = sections[indexSection].questions[dragIndex]
      sections[indexSection].questions.splice(dragIndex, 1)
      sections[indexSection].questions.splice(hoverIndex, 0, dragItem)
      sections[indexSection].questions = resetSortOrder(sections[indexSection].questions)
      this.setState({
        moveHistoryCursor: moveHistoryCursor+1,
        sections: resetSortOrder(sections)
      },() => {
        this.expandSection(indexSection)
      })
    } else if(move.type=='question-section') {
      const { dragIndexSection, dragIndexItem, hoverIndexSection } = move.info
      const dragItem = sections[dragIndexSection].questions[dragIndexItem]
      sections[dragIndexSection].questions.splice(dragIndexItem, 1)
      sections[hoverIndexSection].questions.push(dragItem)
      sections[dragIndexSection].questions = resetSortOrder(sections[dragIndexSection].questions)
      sections[hoverIndexSection].questions = resetSortOrder(sections[hoverIndexSection].questions)
      this.setState({
        moveHistoryCursor: moveHistoryCursor+1,
        sections: resetSortOrder(sections)
      },() => {
        this.expandSection(hoverIndexSection)
      })
    }
  }

  saveMoveHistory() {
    if(this.checkLoading()) {
      return
    }
    this.props.SORT_FAQS(this.props.event.id, this.state.sections)
    this.setState({
      moveHistory: [],
      moveHistoryCursor: 0
    })
  }

  resetMoveHistory() {
    const faqs = _cloneDeep(this.props.faqs)
    _forEach(this.state.sections, (section, index) => {
      if(section.isNewSection) {
        faqs.push(section)
      }
      _forEach(section.questions, (question) => {
        if(question.isNewQuestion) {
          faqs[index].questions.push(question)
        }
      })
    })
    this.setState({
      moveHistory: [],
      moveHistoryCursor: 0,
      sections: resetSortOrder(faqs)
    })
  }

  onConfirmClickOk() {
    const { initialSections } = this.state

    this.setState({
      isChangedFieldsConfirmDialogOpen: false,
      changedField: null,
      moveHistory: [],
      moveHistoryCursor: 0,
      sections: _cloneDeep(initialSections)
    })
  }

  onConfirmClickCancel() {
    this.setState({
      isChangedFieldsConfirmDialogOpen: false,
      errorsDialogOpen: false
    })
    this.confrimAction = null
  }

  confirmChangeAction(action, item, isDraggingAction) {
    const { changedField, moveHistory } = this.state
    const isDraggingProcess = (!changedField && isDraggingAction)
    const isFieldChangeAction = (!changedField && (moveHistory.length === 0 && !isDraggingAction))
    const isChangingPrevFiled = (item && ((changedField && (item.isSection && changedField.isSection && item.id === changedField.id)) ||
      (changedField && (!item.isSection && !changedField.isSection && item.id === changedField.id))))
    if(isFieldChangeAction || isDraggingProcess || isChangingPrevFiled) {
      action()
    } else {
      this.confrimAction = action
      this.setState({
        isChangedFieldsConfirmDialogOpen: true
      })
    }
  }

  render() {
    const { sections, moveHistory, moveHistoryCursor, errorsDialogOpen,
      errorMessage, isChangedFieldsConfirmDialogOpen, changedField
    } = this.state
    const loading = this.checkLoading()
    const { event, user } = this.props

    return (
      <div className="eventfaq">
        {loading && <LoadingBar key='loadingbar' title={this.props.loadingBarTitle} />}
        {!loading && sections.length === 0 &&
          <div className="eventfaq-new">
            <img src={asset('/resources/images/event-faq-new.png')}/>
            <div className="new-div1">Manage Frequently Asked Questions</div>
            <div className="new-div2">Help your customers and reduce support load.</div>
            <div className="new-button">
              <Button className="btn btn-success btn-shadow eventfaq-newsection" onClick={e => this.newSection()}>
                <i className="fa fa-plus" aria-hidden="true" />New FAQ Section
              </Button>
            </div>
          </div>
        }
        {!loading && sections.length > 0 &&
          <div className="eventfaq-buttons">
            <Button className="btn btn-success btn-shadow eventfaq-newsection" disabled={this.checkLoading() || sections.some(el => el.isNewSection)} onClick={e => this.newSection()}>
              <i className="fa fa-plus" aria-hidden="true" />New Section
            </Button>
            <Button className="btn btn-seablue btn-shadow eventfaq-resetorting" disabled={this.checkLoading() || moveHistoryCursor==0} onClick={e => this.resetMoveHistory()}>
              <i className="fa fa-refresh" aria-hidden="true" />Reset Sorting
            </Button>
            <Button className="btn btn-seablue btn-shadow eventfaq-saveorting" disabled={this.checkLoading() || (moveHistoryCursor==0)} onClick={e => this.saveMoveHistory()}>
              <i className="fa fa-floppy-o" aria-hidden="true" />Save Sorting
            </Button>
            <Button className="btn btn-seablue btn-shadow eventfaq-undosorting" disabled={this.checkLoading() || moveHistory.length === 0 || (moveHistoryCursor==0)} onClick={e => this.undoMoveHistory()}>
              <i className="fa fa-undo" aria-hidden="true" />Undo Sorting
            </Button>
            <Button className="btn btn-seablue btn-shadow eventfaq-redosorting" disabled={this.checkLoading() || moveHistory.length === 0 || (moveHistoryCursor==moveHistory.length)} onClick={e => this.redoMoveHistory()}>
              <i className="fa fa-repeat" aria-hidden="true" />Redo Sorting
            </Button>
          </div>
        }
        {!loading && sections.length > 0 &&
          <Card title={'FAQ Section'} className="">
            { _map(sections, (section, i) => (
              <EventFAQSection
                id={section.id}
                key={section.id || i}
                changedField={changedField}
                index={i}
                section={section}
                onSave={() => this.saveSection(i)}
                onDelete={() => this.deleteSection(i)}
                onMove={(dragIndex, hoverIndex) => this.moveSection(dragIndex, hoverIndex)}
                onExpand={() => this.expandSection(i)}
                onCollapse={() => this.collapseSection(i)}
                onChangeSection={this.onChangeSection}
                onNewItem={() => this.newItem(i)}
                onDeleteItem={(index) => this.deleteItem(i, index)}
                onSaveItem={(index) => this.saveItem(i, index)}
                onMoveItemInsideSection={(dragIndex, hoverIndex) => this.moveItemInsideSection(i, dragIndex, hoverIndex)}
                // onMoveItemAcrossSection={(dragIndexSection, dragIndexItem, hoverIndexSection) => this.moveItemAcrossSection(dragIndexSection, dragIndexItem, hoverIndexSection)}
                onExpandItem={(index) => this.expandItem(i, index)}
                onCollapseItem={(index) => this.collapseItem(i, index)}
                onChangeQuestion={this.onChangeQuestion}
                role={_get(event, 'self.role', '')}
                user={user}
              />
            ))}
          </Card>
        }
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!errorsDialogOpen || !!isChangedFieldsConfirmDialogOpen}
          contentLabel="Modal"
          onRequestClose={this.onConfirmClickCancel}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {errorsDialogOpen ? (
                <div>
                  <div className="modal-body">
                    <p>{errorMessage}</p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        onClick={this.onConfirmClickCancel}>Ok</Button>
                    </div>
                  </div>
                </div>
              ) : isChangedFieldsConfirmDialogOpen ? (<div className="modal-confirm">
                <div className="modal-header">
                  <p className="h4 text-compact">Are you sure?</p>
                </div>
                <div className="modal-body">
                  <div className="msg-confirm">Are you sure you want to proceed without saving your changes?</div>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.onConfirmClickOk}>Ok</Button>
                    <Button
                      className="btn btn-default btn-shadow" type="button"
                      onClick={this.onConfirmClickCancel}>Cancel</Button>
                  </div>
                </div>
              </div>) : null}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
