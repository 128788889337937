import moment from 'moment'

export const arrayToObject = arr => {
  const rv = {}
  if (!arr) return rv
  if (Object.prototype.toString.call(arr) === '[object Array]') {
    for (let i = 0; i < arr.length; ++i) rv[i] = arr[i]
  } else {
    for (const attr in arr) {
      rv[attr] = arr[attr]
    }
  }
  return rv
}

export const isDateRangeInvalid = (start, end) => {
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

export const isDateRangeInvalid2 = (start, end) => {
  if (!start) return false
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

export const isDateRangeInvalid3 = (start, end) => {
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isBefore(sm)
}
