import React from 'react'
import { connect } from 'react-redux'
import scrollToComponent from 'react-scroll-to-component'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'
import _slice from 'lodash/slice'
import _isEqual from 'lodash/isEqual'

import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import EmptyBar from '../../_library/EmptyBar'
import { Map, MapNarrator } from '../../_library/Map'

import { FETCH_EVENT_BUYERLOCATION } from '../../../_common/redux/buyerlocation/actions'
import {
  HTTP_INIT,
  HTTP_LOADING,
  HTTP_LOADING_FAILED,
  HTTP_LOADING_SUCCESSED
} from '../../../_common/core/http'
import { get_buyer_locations, get_event } from '../../../_common/core/selectors'

import { TableWithHeader } from './TableWithHeader'
import { LocationRow } from './LocationRow'
import { BuyersCell } from './BuyersCell'
import { NumberOfBuyers } from './NumberOfBuyers'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { getTitle } from '../../utils/getTitle'
import Toggle from "../../_library/Toggle";

const TOP_VALUE = 25

@connect(
  state => {
    const event = get_event(state)
    const buyersLocations = get_buyer_locations(state)

    return {
      event,
      buyersLocations
    }
  },
  { FETCH_EVENT_BUYERLOCATION }
)
export default class EventGeographics extends React.Component {
  constructor(props) {
    super(props)
    const { event: { displayName } } = props
    this.state = {
      status: HTTP_INIT,
      moveMapTo: [],
      markers: [],
      isDataLoaded: false,
      includeNonValidOrders: true
    }

    this.citiesTableColumns = getTableColumns([
      'city',
      { label: 'No. of Buyers', key: 'numBuyers', className: 'pos-right' }
    ])
    this.regionsTableColumns = getTableColumns([
      'region',
      { label: 'No. of Buyers', key: 'numBuyers', className: 'pos-right' }
    ])
    this.countriesTableColumns = getTableColumns([
      'country',
      { label: 'No. of Buyers', key: 'numBuyers', className: 'pos-right' }
    ])

    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  componentDidMount() {
    this.fetchBuyersLocations()
  }

  componentDidUpdate(prevProps, prevState) {
    const { buyersLocations } = this.props
    const { isDataLoaded } = this.state

    if (!_isEqual(buyersLocations, prevProps.buyersLocations) || (!prevState.isDataLoaded && isDataLoaded)) {
      this.getDataFromProps(buyersLocations)
    }
  }

  fetchBuyersLocations = async () => {
    const { event = {}, FETCH_EVENT_BUYERLOCATION } = this.props

    const loadingSetter_buyerlocation = (val, isDataLoaded) => () => {
      this.setState({ status: val, isDataLoaded })
    }

    loadingSetter_buyerlocation(HTTP_LOADING, false)()
    try {
      await FETCH_EVENT_BUYERLOCATION(event.id)
      loadingSetter_buyerlocation(HTTP_LOADING_SUCCESSED, true)()
    } catch (err) {
      loadingSetter_buyerlocation(HTTP_LOADING_FAILED, false)()
    }
  }

  getDataFromProps = (data = {}) => {
    const citiesList = []
    const regionsList = []
    const countriesList = []
    const markers = []
    let orderLocations = _get(data, 'order_locations', [])

    const { includeNonValidOrders } = this.state
    if (includeNonValidOrders) {
      const nonValidOrders = _get(data, 'non_valid_order_locations', [])
      orderLocations = orderLocations.concat(nonValidOrders)
    }

    const groupedByCity = _groupBy(orderLocations, 'city')
    const groupedByRegion = _groupBy(orderLocations, 'region')
    const groupedByCountry = _groupBy(orderLocations, 'country')

    _map(groupedByCity, (item, key) => {
      citiesList.push({
        cityName: key,
        totalBuyers: item.length,
        city: <LocationRow value={key} onClick={() => this.onLocationIconClick(item, item[0].city)} />,
        numBuyers: <BuyersCell value={item.length} />
      })
    })
    _map(groupedByRegion, (item, key) => {
      regionsList.push({
        regionName: key,
        totalBuyers: item.length,
        region: <LocationRow value={key} onClick={() => this.onLocationIconClick(item, item[0].region)} />,
        numBuyers: <BuyersCell value={item.length} />
      })
    })
    _map(groupedByCountry, (item, key) => {
      countriesList.push({
        countryName: key,
        totalBuyers: item.length,
        country: <LocationRow value={key} onClick={() => this.onLocationIconClick(item, item[0].country)} />,
        numBuyers: <BuyersCell value={item.length} />
      })
    })

    // get markers
    _map(orderLocations, (marker, index) => {
      if (marker) markers.push(marker)
    })

    this.setState({
      citiesList: this.getTopValuesList(citiesList),
      regionsList: this.getTopValuesList(regionsList),
      countriesList: this.getTopValuesList(countriesList),
      citiesDataForCopy: _map(this.getTopValuesList(citiesList), item => ({
        city: item.cityName,
        numBuyers: item.totalBuyers
      })),
      regionsDataForCopy: _map(this.getTopValuesList(regionsList), item => ({
        region: item.regionName,
        numBuyers: item.totalBuyers
      })),
      countriesDataForCopy: _map(this.getTopValuesList(countriesList), item => ({
        country: item.countryName,
        numBuyers: item.totalBuyers
      })),
      markers
    })
  }

  onLocationIconClick = (positions, narrator) => {
    scrollToComponent(this.refs.mapRef)
    this.setState({
      moveMapTo: positions,
      narrator
    })
  }

  getTopValuesList = (data = [], sortBy = 'totalBuyers') => {
    const sortedData = _sortBy(data, sortBy)
    const orderedData = _orderBy(sortedData, [sortBy], ['desc'])
    const slicedData = _slice(orderedData, 0, TOP_VALUE)

    return slicedData
  }

  onChangeIncludeNonValidOrders = checked => {
    this.setState({
      includeNonValidOrders: checked
    }, () => {
      const { buyersLocations } = this.props
      this.getDataFromProps(buyersLocations)
    })
  }

  render() {
    const { event = {} } = this.props
    const {
      status,
      citiesList,
      regionsList,
      countriesList,
      citiesDataForCopy = [],
      regionsDataForCopy = [],
      countriesDataForCopy = [],
      moveMapTo,
      narrator,
      markers,
      includeNonValidOrders
    } = this.state
    const { venue } = event
    const isLoading = status <= HTTP_LOADING
    const isEmpty = !markers.length

    return (
      <div className="buyerlocation">
        <div className="include-non-valid-orders">
          <Toggle title="Include refunded and cancelled Orders" id="includeNonValidOrders"
                  value={includeNonValidOrders} onChange={this.onChangeIncludeNonValidOrders}/>
        </div>
        <Card icon={'fa-globe'} title={'Buyer Locations'}>
          <div className="map">
            <Map
              ref="mapRef"
              placeEventMarkersOnce={true}
              clearMarkersBeforeRender={true}
              locations={[venue]}
              markers={markers}
              moveMapTo={moveMapTo}
            />
            <MapNarrator ref="mapNarrator" text={narrator} />
          </div>
          <NumberOfBuyers />
        </Card>
        <Card icon={'fa-bar-chart'} title={'Top Buyer Locations'}>
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your geographics ..."} />
          ) : isEmpty ? (
            <EmptyBar />
          ) : (
            <div className="row">
              <TableWithHeader
                header="Top Cities"
                icon="fa fa-map-marker grey"
                tableClass="small-table"
                rowTableClass="small-table"
                data={citiesList}
                dataForCopy={citiesDataForCopy}
                tableColumns={this.citiesTableColumns}
              />
              <TableWithHeader
                header="Top Regions"
                icon="fa fa-thumb-tack grey"
                tableClass="small-table"
                rowTableClass="small-table"
                data={regionsList}
                dataForCopy={regionsDataForCopy}
                tableColumns={this.regionsTableColumns}
              />
              <TableWithHeader
                header="Top Countries"
                icon="fa fa fa-globe grey"
                tableClass="small-table"
                rowTableClass="small-table"
                data={countriesList}
                dataForCopy={countriesDataForCopy}
                tableColumns={this.countriesTableColumns}
              />
            </div>
          )}
        </Card>
      </div>
    )
  }
}
