import React from 'react'
import { getOrderPosData } from "../../../_common/core/http_services/tickets"
import { showAxiosError } from "../../utils/messenger"

export const OrderPosButton = ({ eventId, orderId }) => {
  function createURL(b64) {
    if (b64 === '') {
      return ''
    }
    const blob = b64toBlob(b64, 'application/pdf')
    const srcUrl = window.URL.createObjectURL(blob)
    return srcUrl
  }
  
  function print(b64) {
    // TODO add a way to regenerate/get tickets again?
    const srcUrl = createURL(b64)
    if (srcUrl === '') {
      showAxiosError({}, 'No tickets to print')
      return
    }
    let iframe = document.getElementById('tickets-pdf')
    const iframeExists = elementExists(iframe)
    if (!iframeExists) {
      iframe = document.createElement('iframe')
      iframe.name = "tickets-pdf"
      iframe.id = "tickets-pdf"
      iframe.style.visibility = "hidden"
    }
    iframe.src = srcUrl
    iframe.onload = () => {
      const pdfFrame = window.frames["tickets-pdf"]
      pdfFrame.focus()
      pdfFrame.print()
    }
    if (!iframeExists) {
      document.body.appendChild(iframe)
    }
  }
  
  function download(b64, name) {
    const srcUrl = createURL(b64)
    if (srcUrl === '') {
      showAxiosError({}, 'No tickets to download')
      return
    }
    let a = document.getElementById('download-tickets')
    const aExists = elementExists(a)
    if (!aExists) {
      a = document.createElement("a")
      a.name = 'download-tickets'
      a.id = 'download-tickets'
    }
    a.href = srcUrl
    a.download = name
    if (!aExists) {
      document.body.appendChild(a)
    }
    a.click()
    URL.revokeObjectURL(srcUrl)
  }
  
  function elementExists(el) {
    if (typeof(el) !== 'undefined' && el != null) {
      return true
    }
    return false
  }
  
  function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
  
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
  
      const byteArray = new Uint8Array(byteNumbers)
  
      byteArrays.push(byteArray)
    }
  
    const blob = new Blob(byteArrays, {
      type: contentType,
    })
  
    return blob
  }
  
  const downloadFetch = async () => {
    const result = await getOrderPosData(eventId, orderId)
    download(result.data.attributes.tickets_data, result.data.attributes.tickets_file)
  }
  
  const printFetch = async () => {
    const result = await getOrderPosData(eventId, orderId)
    print(result.data.attributes.tickets_data)
  }
  
  return <div style={{ display: 'flex' }}>
    <button
      type="button"
      className="btn btn-primary btn-shadow pull-right button-event-order"
      onClick={downloadFetch}
    >
        Download
    </button>
    {window?.chrome ? <button
      type="button"
      className="btn btn-primary btn-shadow pull-right button-event-order"
      onClick={printFetch}
    >
        Print
    </button> : null}
  </div>
}