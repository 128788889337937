import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import LoginForm from '../LoginForm'
import { RESTORE_PASSWORD } from '../../../_common/redux/auth/actions'

import {
  customerLogin,
} from '../../../_common/core/http_services'
import { showAxiosError } from '../../utils/messenger'

@connect(
  null,
  {
    RESTORE_PASSWORD,
  }
)
class LoginSection extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleSubmit = async (form) => {
    const { checkCustomerProfile } = this.props
    try {
      const res = await customerLogin(form)
      const profile = _.get(res, 'data.data')
      checkCustomerProfile(profile, form)
    } catch (err) {
      showAxiosError(err)
      throw _.result(err, 'toFieldErrors')
    }
  }

  handleRestorePassword = async (email) => {
    const { RESTORE_PASSWORD } = this.props
    try {
      return  await RESTORE_PASSWORD(email)
    } catch (err) {
      throw _.result(err, 'toFieldErrors')
    }
  }

  render() {
    const {
      email,
      onClose=_.identity
    } = this.props
    const {
    } = this.state

    return (
      <div className='loginpage'>
        <LoginForm
          initialEmail={email}
          onSubmit={this.handleSubmit}
          onRestorePassword={this.handleRestorePassword}
          onClose={onClose}
        />
      </div>
    )
  }
}

export { LoginSection }
