// Deprecated -- use currency normalizer
import React from 'react'

const Currency = ({ value, code, symbol }) => (
  <span>
    {(!!code || !!symbol) && <small>{code || symbol}</small>} <span>{(value / 100).toFixed(2)}</span>
  </span>
)

export default Currency
