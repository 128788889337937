import React from 'react'
import { default as PaginationDefault } from 'react-js-pagination'
import Select from '../_library/Select'

export const Pagination = ({
  activePage,
  pageRangeDisplayed,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
  pageSizeOptions = [],
  onPageSizeChange,
}) => {
  const enablePageSizeSelect = Boolean(pageSizeOptions && pageSizeOptions.length)

  return (
    <div className="pagination-container">
      <PaginationDefault
        activePage={activePage}
        pageRangeDisplayed={pageRangeDisplayed}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onChange={onChange}
      />
      {enablePageSizeSelect && (
        <div className="pagination-select">
          <Select
            label="Page Size"
            value={itemsCountPerPage}
            options={pageSizeOptions}
            onSelect={onPageSizeChange}
          />
        </div>
      )}
    </div>
  )
}
