import React from 'react'

import Card from '../../../_library/Card'
import Select from '../../../_library/Select'
import FileUploader from '../../../_library/FileUploader'
import EventTitleImagePreview from './EventTitleImagePreview'
import _get from 'lodash/get'

import { ENV } from '../../../constants/env'

export default class EventImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { previewOnBackground: false }
  }

  handleChangeImageURL = img => {
    const { values, updateField, imageLanguage } = this.props
    const updatedURLs = { ...values.imageURLs, [imageLanguage]: img }
    updateField('imageURLs', updatedURLs)
  }

  handleChangeBackgroundURL = img => {
    const { updateField, isRnDomain } = this.props
    const key = isRnDomain ? 'bannerURL' : 'backgroundURL'
    updateField(key, img)
  }

  handleChangeReplacementImage = img => {
    const { updateField } = this.props

    if (img) {
      updateField('titleReplacementImage', img)
    } else {
      updateField('titleReplacementImage', '')
    }
  }

  handlePreviewOnBackground = () => {
    this.setState({ previewOnBackground: !this.state.previewOnBackground })
  }

  getTitleReplacementImage = () => {
    const { titleReplacementImage } = this.props.values
    const imageUrl = titleReplacementImage
    if (imageUrl && !imageUrl.startsWith('https://')) {
      return `${ENV.API_BASE}/uploaded/${imageUrl}`
    }
    return imageUrl
  }

  render() {
    const {
      values,
      errors,
      cardsStatus,
      imageLanguage,
      autoBackground,
      useImageInsteadOfTitle,
      languageOptions,
      onLanguageChange,
      handleChangeAutoBackground,
      handleChangeImageAsEventName,
      isRnDomain,
      closed,
      configs,
    } = this.props
    const eventImage = values.imageURLs ? values.imageURLs[imageLanguage] : ''
    const showUseAutomaticallyGeneratedBackgroundToggle = _get(
      configs,
      'appearance.showUseAutomaticallyGeneratedBackgroundToggle',
      true,
    )
    const showUsetransparentLogoInsteadOfEventNameToggle = _get(
      configs,
      'appearance.showUsetransparentLogoInsteadOfEventNameToggle',
      true,
    )
    return (
      <Card
        closed={closed}
        icon={'fa-camera'}
        title={'Event Images'}
        status={cardsStatus && cardsStatus.images}
        rightAction={
          isRnDomain ? null : (
            <Select
              fieldKey={'imageLanguage'}
              style={{
                width: 'auto',
                margin: '0px 15px 0px 0px',
                height: 30,
                paddingRight: 30,
              }}
              fieldGroupStyle={{ marginTop: 5 }}
              value={imageLanguage}
              onChange={onLanguageChange}
              onClick={e => e.stopPropagation()}
              options={languageOptions}
            />
          )
        }
      >
        <div className="row">
          <div className="col-xs-4 col-12">
            <FileUploader
              id="imageURLs"
              label={isRnDomain ? 'Square image' : 'Add Flyer/Poster'}
              filetype="image"
              value={eventImage}
              onChange={this.handleChangeImageURL}
              accept="image/*"
            />
            {errors.imageURLs ? <small className="text-danger">{errors.imageURLs}</small> : null}
          </div>
          <div className="col-xs-8 col-12">
            {(!autoBackground || isRnDomain) && (
              <FileUploader
                id="backgroundURL"
                label={isRnDomain ? 'Banner image' : 'Add Background Image (optional)'}
                filetype="image"
                value={isRnDomain ? values.bannerURL : values.backgroundURL}
                onChange={this.handleChangeBackgroundURL}
                accept="image/*"
              />
            )}
            {errors.backgroundURL ? <small className="text-danger">{errors.backgroundURL}</small> : null}
            {showUseAutomaticallyGeneratedBackgroundToggle && (
              <div className="line">
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                  <div className="line-cell">
                    <label htmlFor={'autoBackground'}>Use automatically-generated background</label>
                  </div>
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id={'autoBackground'}
                        checked={!!autoBackground}
                        onChange={handleChangeAutoBackground}
                      />
                      <label htmlFor={'autoBackground'} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showUsetransparentLogoInsteadOfEventNameToggle ? (
          <div className="row">
            <div
              className={`col-xs-4 col-12 titleReplacementImageBlock ${
                this.state.previewOnBackground && !!this.getTitleReplacementImage()
                  ? 'hidden_file_uploader'
                  : ''
              }`}
            >
              <div className="line">
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                  <div className="line-cell">
                    <label htmlFor="imageAsEventName">
                      Use transparent logo (PNG/SVG) instead of text event name
                    </label>
                  </div>
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id="imageAsEventName"
                        checked={useImageInsteadOfTitle}
                        onChange={handleChangeImageAsEventName}
                      />
                      <label htmlFor="imageAsEventName" />
                    </div>
                  </div>
                </div>
              </div>
              {useImageInsteadOfTitle && (
                <FileUploader
                  id="titleReplacementImage"
                  label="Add Title Image"
                  filetype="image"
                  accept="image/png, image/svg+xml"
                  value={this.getTitleReplacementImage()}
                  onChange={this.handleChangeReplacementImage}
                  imgClassName="title_replacement_img"
                />
              )}
              {errors.titleReplacementImage ? (
                <small className="text-danger">{errors.titleReplacementImage}</small>
              ) : null}
            </div>
            {useImageInsteadOfTitle &&
              (values.backgroundURL || values.blurredBackgroundUrl ? (
                <div className="use_image_instead_of_title">
                  <button
                    disabled={!!!this.getTitleReplacementImage()}
                    type="button"
                    onClick={this.handlePreviewOnBackground}
                    className="btn btn-default btn-shadow"
                  >
                    Preview on background
                  </button>
                  {this.state.previewOnBackground && !!this.getTitleReplacementImage() && (
                    <div>
                      <div className="col-xs-12">
                        <EventTitleImagePreview
                          backgroundImageUrl={
                            autoBackground
                              ? values.blurredBackgroundUrl
                              : values.backgroundURL
                              ? values.backgroundURL
                              : values.blurredBackgroundUrl
                          }
                          titleImageUrl={this.getTitleReplacementImage()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-xs-8 col-12">
                  <div className="line">Please save the changes to be able to preview the title image.</div>
                </div>
              ))}
          </div>
        ) : null}
      </Card>
    )
  }
}
