import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import { produce } from 'immer'
import handleAction from 'redux-actions/lib/handleAction'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'
import { mergeArraysBy } from '../../core/utils'

const initialState = {
  initial: true,
  total: 0,
  skip: 0,
  tours: [],
  selected: null,
}

const FETCH_TOURS = createAsyncHandlers('FETCH_TOURS', {
  success(state, action) {
    const { total, skip, data } = action.payload

    state.initial = false
    state.total = total
    state.skip = skip
    state.tours = mergeArraysBy(state.tours, data, 'id')
  },
})

const FETCH_TOUR = createAsyncHandlers('FETCH_TOUR', {
  success(state, action) {
    const { data } = action.payload
    state.selected = data
  },
})

const CREATE_TOUR = createAsyncHandlers('CREATE_TOUR', {
  success(state, action) {
    const tour = action.payload.data
    state.initial = false
    state.total++
    state.tours.push(tour)
  },
})

const UPDATE_TOUR = createAsyncHandlers('UPDATE_TOUR', {
  success(state, action) {
    const tour = action.payload.data

    const sid = _get(state, 'selected.id', '')
    const tid = _get(tour, 'id', '')

    const index = _findIndex(state.tours, el => el.id === tid)
    state.tours[index] = tour

    if (sid && sid === tid) {
      state.selected = tour
    }
  },
})

const CLEAR_SELECTED_TOUR = handleAction(
  'CLEAR_SELECTED_TOUR',
  produce(state => {
    state.selected = null
  }),
  initialState,
)

export default handleActions(
  {
    ...FETCH_TOURS,
    ...FETCH_TOUR,
    ...CREATE_TOUR,
    ...UPDATE_TOUR,
    CLEAR_SELECTED_TOUR,
  },
  initialState,
)
