// Re-render Optimization 15.05.2020
import _filter from 'lodash/filter'
import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { IS_FOUND } from '../../../_library/JSONDatatable'
import { Events, scroller } from 'react-scroll'

import EventsList from './EventsList'
import EventComponent from './EventComponent'
import EventImagePlaceholder from '../EventImagePlaceholder'

class EventSwitcher extends React.PureComponent {
  static propType = {
    currentEvent: PropTypes.object,
    events: PropTypes.arrayOf(PropTypes.object),
    onClickEvent: PropTypes.func,
    active: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      search: ''
    }
    this.isCurrentInFilters = false
  }

  onClickSwitcher = () => {
    const { active } = this.props
    const { expanded } = this.state
    if (!active) {
      return
    }

    if (!expanded) {
      this.scrollToWithContainer()
    }
    this.setState(prevState => ({
      ...prevState,
      expanded: !prevState.expanded,
      search: !prevState.expanded ? prevState.search : ''
    }))
  }

  scrollToWithContainer() {
    const goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve()
        Events.scrollEvent.remove('end')
      })
      scroller.scrollTo('scroll-container', {
        duration: 100,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    })
    goToContainer.then(() => {
      scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })
    })
  }

  onClickEvent = event => {
    if (this.props.onClickEvent) {
      this.props.onClickEvent(event)
    }
    this.setState({
      expanded: false,
      search: ''
    })
  }

  onSearch = value => {
    this.setState(() => ({ search: value }))
  }

  handleClickOutside(e) {
    this.setState({
      expanded: false,
      search: ''
    })
  }

  setInFilterEvent = value => {
    this.isCurrentInFilters = value
  }

  render() {
    let { events, currentEventId, active, currentEvent, fullReady } = this.props
    const { expanded, search } = this.state
    const { brandDetails } = this.props
    currentEvent = currentEvent ? currentEvent : events.find(e => e.id === currentEventId)

    // get search result
    let events_filtered = events
    if (search.length > 0) {
      events_filtered = _filter(events, (event, index) => {
        const addr = !!event ? event.venue : null
        const { displayName } = event
        let found = 0
        const keyword = search.toLowerCase()
        found += IS_FOUND(displayName, keyword)
        if (addr) {
          found += IS_FOUND(addr.displayName, keyword)
          found += IS_FOUND(addr.city, keyword)
        }
        return found
      })
    }

    return (
      <div className="event">
        {currentEvent && !!currentEvent.imageURL && (
          <img src={currentEvent.imageURL} height="80" width="80" />
        )}
        {currentEvent && !currentEvent.imageURL && <EventImagePlaceholder />}
        <div className="events-select2">
          <div className="select2">
            <div className="select2-value" onClick={this.onClickSwitcher}>
              {currentEvent && <EventComponent event={currentEvent} brandDetails={brandDetails} />}
              <div className="iconStatus">
                {active && <img className="exchange-ico" src={asset('/resources/images/exchange.svg')} />}
                {!active && <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />}
              </div>
            </div>
            {expanded && (
              <EventsList
                events={events_filtered}
                fullReady={fullReady}
                currentEventId={currentEvent ? currentEvent.id : currentEventId}
                onSearch={this.onSearch}
                onClickEvent={this.onClickEvent}
                setInFilterEvent={this.setInFilterEvent}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default onClickOutside(EventSwitcher)
