import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'

import RichTextArea from '../../_library/RichTextArea'
import { ENV } from '../../constants/env'

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      indexSection: props.indexSection
    }
  }
}

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const dragIndexSection = monitor.getItem().indexSection
    const hoverIndex = props.index
    const hoverIndexSection = props.indexSection

    if (dragIndexSection !== hoverIndexSection) {
      return
    }

    if (dragIndex === hoverIndex) {
      return
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  }
}

@DropTarget('EventFAQItem', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource('EventFAQItem', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))
export default class EventFAQItem extends React.Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onMove: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      question: props.question,
      deleting: false
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      question: newProps.question
    })
  }

  save() {
    if (this.props.onSave) {
      this.props.onSave()
    }
  }

  delete() {
    if (this.props.onDelete) {
      this.setState(
        {
          deleting: true
        },
        () => {
          this.props.onDelete()
        }
      )
    }
  }

  expand() {
    if (this.props.onExpand) {
      this.props.onExpand()
    }
  }

  collapse() {
    if (this.props.onCollapse) {
      this.props.onCollapse()
    }
  }

  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      indexSection,
      index,
      changedField,
      isTfSupportOrClientSupport
    } = this.props
    const opacity = isDragging ? 0 : 1
    const { question, deleting } = this.state
    if (question.expanded) {
      return connectDragPreview(
        connectDropTarget(
          <div
            className="eventfaq-question expanded"
            style={{
              border:
                changedField && !changedField.isSection && changedField.id === question.id
                  ? '1px solid #ffa46b'
                  : '0px solid transparent',
              opacity
            }}
          >
            <div className="eventfaq-question-top">
              <div className="eventfaq-question-caret" onClick={e => this.collapse()}>
                <i className="fa fa-minus-square-o" aria-hidden="true" />
              </div>
              <div className="eventfaq-question-header">Q:</div>
              <input
                type="text"
                value={question.title}
                className="eventfaq-question-title form-control"
                placeholder="Question"
                onChange={e => this.props.onChangeQuestion(indexSection, index, e.target.value, 'title')}
              />
              {connectDragSource(
                <div className="eventfaq-question-button eventfaq-question-button-drag">
                  <i className="fa fa-arrows" aria-hidden="true" />
                </div>
              )}
              {!isTfSupportOrClientSupport && (
                <div className="isPublic">
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id={`isPublic_item_${question.id}`}
                        checked={!!question.isPublic}
                        onChange={e =>
                          this.props.onChangeQuestion(indexSection, index, e.target.checked, 'isPublic')
                        }
                      />
                      <label htmlFor={`isPublic_item_${question.id}`} />
                    </div>
                  </div>
                  <div className="line-cell">
                    <label htmlFor={`isPublic_item_${question.id}`}>Public</label>
                  </div>
                </div>
              )}
              <div
                className="eventfaq-question-button eventfaq-question-button-delete"
                onClick={e => !deleting && this.delete()}
              >
                <i className="fa fa-trash" aria-hidden="true" />
              </div>
              <div
                className="eventfaq-question-button eventfaq-question-button-save"
                onClick={e => this.save()}
              >
                <i className="fa fa-floppy-o" aria-hidden="true" />
              </div>
            </div>
            <div className="eventfaq-question-answer">
              <RichTextArea
                label=""
                value={question.content}
                onChange={val => this.props.onChangeQuestion(indexSection, index, val, 'content')}
                baseurl={ENV.CDN_URL}
              />
            </div>
          </div>
        )
      )
    }
    return connectDragPreview(
      connectDropTarget(
        <div
          className="eventfaq-question collapsed"
          style={{
            border:
              changedField && !changedField.isSection && changedField.id === question.id
                ? '1px solid #ffa46b'
                : '1px solid #666C76',
            opacity
          }}
        >
          <div className="eventfaq-question-caret" onClick={e => this.expand()}>
            <i className="fa fa-plus-square-o" aria-hidden="true" />
          </div>
          <div className="eventfaq-question-header">Q:</div>
          <input
            type="text"
            value={question.title}
            className="eventfaq-question-title form-control"
            placeholder="Enter New Question Title"
            onChange={e => this.props.onChangeQuestion(indexSection, index, e.target.value, 'title')}
          />
          {connectDragSource(
            <div className="eventfaq-question-button eventfaq-question-button-drag">
              <i className="fa fa-arrows" aria-hidden="true" />
            </div>
          )}
          {!isTfSupportOrClientSupport && <div className="isPublic">
            <div className="line-cell">
              <div className="checkbox-switch">
                <input
                  type="checkbox"
                  id={`isPublic_item_${question.id}`}
                  checked={!!question.isPublic}
                  onChange={e =>
                    this.props.onChangeQuestion(indexSection, index, e.target.checked, 'isPublic')
                  }
                />
                <label htmlFor={`isPublic_item_${question.id}`} />
              </div>
            </div>
            <div className="line-cell">
              <label htmlFor={`isPublic_item_${question.id}`}>Public</label>
            </div>
          </div>}
          <div
            className="eventfaq-question-button eventfaq-question-button-delete"
            onClick={e => !deleting && this.delete()}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </div>
          <div className="eventfaq-question-button eventfaq-question-button-save" onClick={e => this.save()}>
            <i className="fa fa-floppy-o" aria-hidden="true" />
          </div>
        </div>
      )
    )
  }
}
