// Need to be refactored
import React from 'react'
import TagsInput from 'react-tagsinput'
import AutosizeInput from 'react-input-autosize'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'
import { ENV } from '../constants/env'
import { shortid } from '../../_common/core/rand'
import _uniq from 'lodash/uniq'
import _get from 'lodash/get'
import { getCompiledTemplate } from '../utils/getCompiledTemplate'

const getCopyUrl = (eventSlug, tag, externalEventPartnerDomain, promotionUrl) => {
  const hostDomain = externalEventPartnerDomain || ENV.API_CONSUMER
  const copyUrl = getCompiledTemplate(promotionUrl, [hostDomain, eventSlug, tag])
  return copyUrl
}

export default class TagsField extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      promotionLinkCopied: false,
    }
    this.pending = ''
  }
  componentDidMount() {
    const { focusOnStart } = this.props
    if (focusOnStart) {
      this.refs.tag.focus()
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.setState(() => ({ value: newProps.value }))
    }
    this.pending = ''
  }

  completeWriting() {
    const currentTags = this.state.value
    if (this.pending === '') return currentTags
    const pendingTags = this.pending
    this.refs.tag.accept()
    if (currentTags) {
      if (!currentTags.includes(pendingTags)) {
        currentTags.push(pendingTags)
      }
      return currentTags
    }
    return [pendingTags]
  }

  onTagsChange = (tags, changed, changedIndexes) => {
    const { controlOutside, onChange, flagFilterUniq = false } = this.props
    let updatedTags = tags.map(promo => promo.replace(' ', ''))
    this.pending = ''

    if (flagFilterUniq) {
      updatedTags = _uniq(updatedTags)
    }

    if (!controlOutside) {
      this.setState({
        value: updatedTags,
      })
    }
    if (onChange) {
      onChange(updatedTags, changed, changedIndexes)
    }
  }

  onPaste = data => {
    this.pending = ''
    const filtered = data.replace(/\r?\n|\r/g, ' ')
    return filtered.split(' ').map(d => d.trim())
  }

  autoSizing = ({ addTag, ...props }) => {
    this.pending = props.value
    const { onChange, value, ...other } = props
    return (
      <AutosizeInput
        type="text"
        className="react-tagsinput-input"
        onChange={onChange}
        value={value}
        {...other}
      />
    )
  }

  copyPromotionLinkAfter = () => {
    this.setState({
      promotionLinkCopied: true,
    })
  }

  copyPromotionLinkOut = e => {
    if (this.state.promotionLinkCopied) {
      setTimeout(() => {
        this.setState({
          promotionLinkCopied: false,
        })
      }, 500)
    }
  }

  renderPromotionTag = (props, eventSlug, externalEventPartnerDomain) => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
    const { configs } = this.props
    const promotionUrl = _get(configs, 'messages.promotionUrl')

    return (
      <span key={key} {...other} onMouseLeave={this.copyPromotionLinkOut}>
        {getTagDisplayValue(tag)}
        {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
        <ClipboardButton
          component="span"
          data-clipboard-text={getCopyUrl(eventSlug, tag, externalEventPartnerDomain, promotionUrl)}
          onSuccess={this.copyPromotionLinkAfter}
        >
          <OverlayTrigger
            placement="right"
            overlay={
              this.state.promotionLinkCopied ? (
                <Tooltip id={shortid()}>Copied</Tooltip>
              ) : (
                <Tooltip id={shortid()}>Copy Link</Tooltip>
              )
            }
          >
            <i className="fa fa-link promotion-link-copy" />
          </OverlayTrigger>
        </ClipboardButton>
      </span>
    )
  }

  render() {
    const { value } = this.state
    const {
      isPromotion,
      placeholder,
      eventSlug,
      error,
      touched,
      disabled = false,
      tagsContainerClassName = '',
      tagsInputClassName = '',
      renderInput,
      validationRegex,
      onValidationReject,
      inputValue,
      onlyUnique,
      addKeys,
      pasteSplit,
      renderTag,
      inputClassName,
      externalEventPartnerDomain,
      autocomplete,
      inputDisabled = false,
    } = this.props

    return (
      <div className={`tags-field ${tagsContainerClassName}`}>
        {isPromotion ? (
          <TagsInput
            ref="tag"
            inputProps={{ placeholder: 'Add a code' }}
            addOnBlur={true}
            addOnPaste={true}
            pasteSplit={data => this.onPaste(data)}
            addKeys={[9, 13, 32]}
            onlyUnique
            value={value || []}
            onChange={(tags, changed, changedIndexes) => this.onTagsChange(tags, changed, changedIndexes)}
            renderInput={({ addTag, ...props }) => this.autoSizing({ addTag, ...props })}
            renderTag={props => this.renderPromotionTag(props, eventSlug, externalEventPartnerDomain)}
            className={`tags-input ${tagsInputClassName}`}
          />
        ) : (
          <TagsInput
            ref="tag"
            addOnBlur={true}
            inputProps={{
              placeholder: placeholder || 'Add a tag',
              inputClassName,
              autoComplete: autocomplete,
              disabled: inputDisabled,
            }}
            value={value || []}
            onChange={(tags, changed, changedIndexes) => this.onTagsChange(tags, changed, changedIndexes)}
            onChangeInput={this.props.onChangeInput}
            inputValue={inputValue}
            renderInput={renderInput || (({ addTag, ...props }) => this.autoSizing({ addTag, ...props }))}
            disabled={disabled}
            className={`tags-input ${tagsInputClassName} ${disabled ? 'disabled' : ''}`}
            validationRegex={validationRegex}
            onValidationReject={onValidationReject}
            onlyUnique={onlyUnique}
            addKeys={addKeys || [9, 13]}
            addOnPaste={pasteSplit}
            renderTag={renderTag}
            pasteSplit={pasteSplit ? data => this.onPaste(data) : undefined}
          />
        )}
        {error && touched && <div className="help-block">{error}</div>}
      </div>
    )
  }
}
