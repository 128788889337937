import React from 'react'
import _map from 'lodash/map'
import queryString from 'query-string'
import EmptyBar from '../_library/EmptyBar'
import PerformanceDiscountCodeBreakdown from '../events/performance/PerformancePromoCode/index'
import { fetchAPI } from '../../_common/core/http'
import LoadingBar from '../_library/LoadingBar'

export default class Promo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const token = queryString.parse(location.search).token || ''
    if (!!token) {
      this.setState({ isLoading: true })

      const promoCodesBreakdownURL = `/api/get-promo-codes-breakdown/`
      fetchAPI(promoCodesBreakdownURL, { params: { token } })
        .catch(err => {
          this.setState({
            isLoading: false,
            error: err,
          })
        })
        .then(res => {
          const data = res ? _map(res.data, d => ({ ...d.$original })) : null
          this.setState({
            isLoading: false,
            data,
          })
        })
    }
  }

  render() {
    const { data, isLoading, error } = this.state
    return (
      <div style={{ paddingLeft: 20 }}>
        {isLoading ? (
          <LoadingBar
            key="loadingbar"
            title={"Hold tight! We're getting your event's sales by discount code..."}
          />
        ) : !error ? (
          data && data.length ? (
            <PerformanceDiscountCodeBreakdown data={data} event={{ currency: data[0].currency }} />
          ) : (
            <EmptyBar />
          )
        ) : (
          <LoadingBar key="loadingbar" title={'Invalid token!'} />
        )}
      </div>
    )
  }
}
