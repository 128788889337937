import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { EventPassRender } from './EventPassRender'

function validateForm(data) {
  const errors = {}

  if (!_get(data, 'entrypassTypeId')) {
    _set(errors, 'entrypassTypeId', 'Required')
  }

  const recipients_mode = _get(data, 'recipients_mode')

  if (recipients_mode === 'csv') {
    const recipients = _get(data, 'recipients')
    if (!recipients || recipients.length === 0) {
      _set(errors, 'recipients', 'You must import at least one recipient.')
    } else {
      recipients.forEach((r, index) => {
        if (!r.firstName) {
          _set(errors, 'recipients[' + index + '].firstName', 'Required')
        }
        if(!r.lastName) {
		    	_set(errors, 'recipients['+index+'].lastName', 'Required')
		    }
        if (!r.email) {
          _set(errors, 'recipients[' + index + '].email', 'Required')
        } else if (!validateEmail(r.email)) {
          _set(errors, 'recipients[' + index + '].email', 'Invalid')
        }
      })
    }
  }

  if (recipients_mode === 'form') {
    const recipient = _get(data, 'recipient')
    if (!recipient.firstName) {
      _set(errors, 'recipient.firstName', 'Required')
    }
    if (!recipient.lastName){
	  	_set(errors, 'recipient.lastName', 'Required')
	  }
    if (!recipient.email) {
      _set(errors, 'recipient.email', 'Required')
    } else if (!validateEmail(recipient.email)) {
      _set(errors, 'recipient.email', 'Invalid Email Address')
    }
  }
  return errors
}

const EventPassForm = ({ submitLabel, passTypes, ...props }) => {
  const options = passTypes.map(type => ({ value: type.id, label: type.name }))
  return (
    <Formik 
      initialValues={{
        entrypassTypeId: _get(props, 'initialValues.attributes.entrypassTypeId') || '',
        issuedByNotes: _get(props, 'initialValues.attributes.issuedByNotes') || '',
        maxUsesPerEvent: _get(props, 'initialValues.attributes.maxUsesPerEvent') || '',
        recipients_mode: _get(props, 'initialValues.attributes.recipients_mode') || 'form',
        recipient: _get(props, 'initialValues.attributes.recipient') || {
          firstName: '',
          lastName: '',
          email: '',
        },
        recipients: _get(props, 'initialValues.attributes.recipients') || []
      }}
      validate={values => validateForm(values)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        props
          .onSubmit({
            ...values
          })
          .then(v => {
            setSubmitting(false)
            resetForm({
              entrypassTypeId: _get(props, 'initialValues.attributes.entrypassTypeId') || '',
              issuedByNotes: _get(props, 'initialValues.attributes.issuedByNotes') || '',
              maxUsesPerEvent: _get(props, 'initialValues.attributes.maxUsesPerEvent') || '',
              recipients_mode: _get(props, 'initialValues.attributes.recipients_mode') || values.recipients_mode,
              recipient: _get(props, 'initialValues.attributes.recipient') || {
                firstName: '',
                lastName: '',
                email: '',
              },
              recipients: _get(props, 'initialValues.attributes.recipients') || []
            })
          })
          .catch(() => {
            setSubmitting(false)
          })
      }}
    >
      {renderProps => <EventPassRender {...renderProps} options={options} submitLabel={submitLabel} />}
    </Formik>
  )
}


export default withRouter(EventPassForm)