import React from 'react'
import _ from 'lodash'
import Button from '../../../_library/Button'
import Field from '../../../_library/Field'
import { getBrands } from '../../../../_common/core/http_services'
import PropTypes from "prop-types"
import { showAxiosError } from '../../../utils/messenger'

export default class BrandRow extends React.Component {
  constructor(props) {
    super(props)
    this.lookUpBrands = _.debounce(this.lookUpBrands, 400)
    this.state = {
      suggestions: [],
      isLoading: false,
      searchValue: props?.values?.owner?.displayName || "",
      error: "",
    }
    this.fetchTry = 0
  }

  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    updateField: PropTypes.func.isRequired,
    onClickNewBrand: PropTypes.func.isRequired,
  }

  handleChangeOwner = e => {
    const { organizations, updateField, FETCH_BRAND } = this.props
    const { value } = e.target
    updateField('owner', _.find(organizations, { id: value }))
    FETCH_BRAND(value)
  }

  async lookUpBrands(value, fetchTry) {
    try {
      this.setState({
        error: "",
      })
      if (!value) {
        this.setState({
          suggestions: [],
        })
        return
      }
      const brands = await getBrands({ compact: 1, search_query: value })
      if (this.fetchTry !== fetchTry) {
        return
      }
      if (!brands?.data?.length) {
        this.setState({
          error: "No results found",
          suggestions: [],
        })
        return
      }
      const suggestions = brands.data.map(brand => ({ id: brand.id, label: brand.displayName }))
      this.setState({
        suggestions,
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  handleChangeAddressQuery = async e => {
    const { value } = e.target
    const validValue = value.trimStart()
    this.setState({ searchValue: validValue }, () => {
      this.fetchTry += 1
      this.lookUpBrands(validValue, this.fetchTry)
    })
  }

  handleAddressSelected = value => {
    const { FETCH_BRAND, updateField } = this.props
    const { suggestions } = this.state
    this.setState({
      searchValue: value.label,
    }, () => {
      updateField('owner', _.find(suggestions, { id: value.id }))
      FETCH_BRAND(value.id)
    })
  }

  render() {
    const { errors, touched, onClickNewBrand } = this.props
    const { isLoading, suggestions, searchValue, error } = this.state
    return (
      <div className="row">
        <label className="control-label-outside" htmlFor="owner">
          Search for the brand running this event
        </label>
        <div className="row-brand col-xs-12 select-brand-val">
          <div className={'form-group dropdown-brand ' + (errors.owner ? 'has-error' : '')}>
            <div className="floating-field-group active">
              <div className="floating-field">
                <label className="control-label" htmlFor="brand">
                  Brand
                </label>
                <Field
                  id="owner"
                  className={'searchbox'}
                  loading={isLoading}
                  onChange={this.handleChangeAddressQuery}
                  value={searchValue}
                  options={suggestions}
                  onSelected={this.handleAddressSelected}
                />
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {!!errors.owner && touched.owner && <div className="help-block">{errors.owner}</div>}
          </div>
          <div className="btn-create-brand" style={{ marginTop: "37px" }}>
            <Button className="btn btn-default btn-shadow" type="button" onClick={onClickNewBrand}>
              Create Brand
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
