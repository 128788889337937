import React from 'react'
import { connect } from 'react-redux'
import EmailTemplates from '../_features/messaging/EmailTemplates'

const EventCreateTemplate = ({ event, configs }) => (
  <EmailTemplates originData={event} originType="events" configs={configs} />
)

const mapStateToProps = state => ({
  event: state.events.selected,
})

export default connect(mapStateToProps)(EventCreateTemplate)
