import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { SHOPIFY_CHECK_CONNECT } = createAsyncAction('SHOPIFY_CHECK_CONNECT', function (id, shopifyType) {
  return dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/connect/`)
      .then(res => {
        const out = { id, shopifyType }
        dispatch(this.success(out))
        dispatch(FETCH_PRODUCTS(id, shopifyType))
        return res
      })
      .catch(err => {
        if (err.errors[0].meta.status !== 422) {
          dispatch(ERROR(...err.errors))
        }
        dispatch(this.failed({ id, shopifyType, err }))
      })
})

const { SHOPIFY_CONNECT } = createAsyncAction('SHOPIFY_CONNECT', (id, shopifyType, url) => {
  const body = {
    data: {
      attributes: {
        shopUrl: url.replace(/https:\/\/|http:\/\//, ''),
        redirectUrl: `${window.location.origin}/shopify/${shopifyType}/redirect-url`,
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/connect/`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        window.location = `${res.data.auth_url}&state=${encodeURI(
          JSON.stringify({ path: `${window.location.pathname}`, shopifyType, id }),
        )}`
        return res
      })
})

const { SHOPIFY_DISCONNECT } = createAsyncAction('SHOPIFY_DISCONNECT', function (id, shopifyType) {
  return dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/connect/`, {
      method: 'DELETE',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success({ id, shopifyType }))
        return res
      })
})

const { SHOPIFY_SET_CONNECT_INFO } = createAsyncAction(
  'SHOPIFY_SET_CONNECT_INFO',
  function (id, shopifyType, info) {
    return dispatch =>
      fetchAPI(`/api/shopify/auth_redirect`, {
        params: info,
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          throw err
        })
        .then(res => {
          dispatch(this.success({ id, shopifyType }))
          dispatch(FETCH_PRODUCTS(id, shopifyType))
          return res
        })
  },
)

const { FETCH_PRODUCTS } = createAsyncAction('FETCH_PRODUCTS', function (id, shopifyType) {
  return dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/products`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        const { attributes: allProducts = [] } = res.data.$original
        let {
          relationships: { selected_product_ids: addedIds = [] },
        } = res.data.$original
        addedIds = addedIds.map(el => +el)
        dispatch(this.success({ shopifyType, id, addedIds, allProducts }))
        return res
      })
})

const { SHOPIFY_UPDATE } = createAsyncAction('SHOPIFY_UPDATE', function (id, shopifyType, newAddedIds) {
  const body = {
    data: {
      attributes: {
        ids: newAddedIds,
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/products/`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success({ shopifyType, id, newAddedIds }))
        return res
      })
})

const { SHOPIFY_REFRESH } = createAsyncAction(
  'SHOPIFY_REFRESH',
  (id, shopifyType) => dispatch =>
    fetchAPI(`/api/${shopifyType}/${id}/shopify/products/sync/`, { method: 'POST' })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(FETCH_PRODUCTS(id, shopifyType))
        return res
      }),
)

export {
  SHOPIFY_CHECK_CONNECT,
  SHOPIFY_CONNECT,
  SHOPIFY_DISCONNECT,
  SHOPIFY_SET_CONNECT_INFO,
  FETCH_PRODUCTS,
  SHOPIFY_UPDATE,
  SHOPIFY_REFRESH,
}
