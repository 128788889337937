import _get from 'lodash/get'
import React from 'react'
import { Form, Formik, Field } from 'formik'
import Button from '../../_library/Button'
import { SelectField, SimpleField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'

export const CreatePassTypeForm = props => {
  const { 
    onCancel, 
    submitLabel,
  } = props

  return (
    <Formik 
      initialValues={{
        name: _get(props, 'initialValues.name') || '',
        description: _get(props, 'initialValues.description') || "",
        type: _get(props, 'initialValues.type') || 'member',
        maxUsesPerEvent: _get(props, 'initialValues.maxUsesPerEvent') || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values)
          .then(() => {
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
          })
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-xs-12">
              <Field
                label="Name"
                name="name"
                placeholder="Name"
                component={SimpleField}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="Description (optional)"
                name="description"
                placeholder="Description (optional)"
                component={SimpleField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="Type"
                name="type"
                placeholder="Type"
                component={SelectField}
                options={[{ value: "member", label: "Member" }, { value: "season", label: "Season" }]}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                className="responsive-label"
                label="Maximum number of uses per event (optional)"
                type="number"
                name="maxUsesPerEvent"
                placeholder="Maximum number of uses per event (optional)"
                component={SimpleField}
              />
            </div>
          </div>
          <div className="text-center btn-toolbar">
            <Button type="submit" className="btn btn-success btn-lg btn-shadow" loading={isSubmitting}>
              {submitLabel}
            </Button>
            {onCancel && (
              <Button
                className="btn btn-danger btn-lg btn-shadow"
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}