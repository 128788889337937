import React from 'react'
import { Field } from 'formik'
import { DateTimePickerFieldReact } from '../../../../formik/Fields/DateTimePickerFieldReact'
import { requiredValidator } from '../../../../../_common/core/validation'

export default class SaleDatesRow extends React.Component {
  render() {
    return (
      <div className="row" style={{ marginTop: 8, marginBottom: 16 }}>
        <div className={'col-xs-6 col-12'} style={{ position: "static" }}>
          <Field
            name="salesStartDate"
            label="Ticket Sales Start (optional)"
            placeholder="D MMM YYYY H:M AM"
            component={DateTimePickerFieldReact}
          />
        </div>
        <div className={'col-xs-6 col-12'} style={{ position: "static" }}>
          <Field
            name="salesEndDate"
            label="Ticket Sales End Date"
            placeholder="D MMM YYYY H:M AM"
            component={DateTimePickerFieldReact}
            validate={requiredValidator()}
          />
        </div>
      </div>
    )
  }
}
