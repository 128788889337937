import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'

import BrandForm from './BrandForm'
import { CREATE_BRAND } from '../../_common/redux/brands/actions'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => ({
    isRnDomain: state.shared.isRnDomain,
    dynamicConfigs: state.launchDarklyInfo.launchDarklyFlags,
    user: state.auth.user,
  }),
  { CREATE_BRAND },
)
export default class NewBrandPage extends React.PureComponent {
  componentDidMount() {
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  handleSubmit = form => {
    const {
      CREATE_BRAND,
      history: { push },
    } = this.props
    return Promise.resolve(CREATE_BRAND({ ...form }))
      .catch(err =>
        Promise.reject(
          Messenger().post({
            type: 'error',
            message: err.errors[0].details,
            hideAfter: 3,
            showCloseButton: true,
          }),
        ),
      )
      .then(res => {
        Messenger().post({
          type: 'success',
          message: 'Brand created successfully',
          hideAfter: 3,
          showCloseButton: true,
        })
        push(`/brand/${res.data.$original.id}/details`)
      })
  }

  render() {
    const { isRnDomain, dynamicConfigs, user, configs } = this.props
    return (
      <div className="body-main">
        <div>
          <div className="body-panel-header">
            <div className="left">
              <div className="title">New Brand</div>
            </div>
          </div>
          <div className="body-panel-spacing" />
          <div className="body-panel-content">
            <BrandForm
              user={user}
              isNew={true}
              onSubmit={this.handleSubmit}
              submitLabel="Create Brand"
              initialValues={null}
              isRnDomain={isRnDomain}
              dynamicConfigs={dynamicConfigs}
              configs={_get(configs, 'children.BrandForm')}
            />
          </div>
        </div>
      </div>
    )
  }
}
