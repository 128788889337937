import url from 'url'
import _has from 'lodash/has'
import _reduce from 'lodash/reduce'
import _get from 'lodash/get'
import _identity from 'lodash/identity'
import _set from 'lodash/set'
import _merge from 'lodash/merge'
import _map from 'lodash/map'
import _keyBy from 'lodash/keyBy'
import _isArray from 'lodash/isArray'
import _extend from 'lodash/extend'
import _forEach from 'lodash/forEach'
import whatwgfetch from 'isomorphic-fetch'
import { isWeb, isNative } from './utils'
import { ENV } from '../../web/constants/env'
import { getCookieByName } from '../../web/utils/cookies'

export const HTTP_INIT = 0
export const HTTP_LOADING = 1
export const HTTP_LOADING_SUCCESSED = 2
export const HTTP_LOADING_FAILED = 3
export const CACHE_SIZE = 1000

const messageByCode = {
  request_failed: [
    'Request failed to complete.',
    'This may be caused by bad network conditions.',
    'Check your connection and try again.',
  ].join(' '),
  unexpected: ['We are having trouble loading this page.', 'Please refresh and try again.'].join(' '),
}

function ServerError(...errs) {
  if (!(this instanceof ServerError)) {
    return new ServerError(...errs)
  }
  const len = errs.length
  this.name = 'ServerError'
  this.message = `${len} error${len > 1 ? 's' : ''} occurred`
  this.errors = this.children = errs
}

ServerError.fromResponse = function (res) {
  if (_has(res, 'errors')) {
    return ServerError(...res.errors)
  } else if (_has(res, 'error') && res.error && _has(res, 'message')) {
    return ServerError({ code: 'unexpected', details: res.message, meta: res })
  }
  return ServerError({ code: 'unexpected', details: messageByCode.unexpected, meta: res })
}

ServerError.prototype = Object.create(Error.prototype)
ServerError.prototype.constructor = ServerError

ServerError.prototype.toFieldErrors = function () {
  const errors = _map(this.errors, fe => {
    if (fe.code !== 'validation_failed') {
      return {}
    }
    let p = _get(fe, 'source.pointer', '').replace(/^\//, '').split('/')
    if (p[0] === 'data') {
      p = p.slice(1)
    }
    const keyPath = p.length ? p : '_error'
    return _set({}, keyPath, fe.details)
  })

  return _reduce(errors, _merge, {})
}

function unpackAPIArray(arr, included) {
  return _map(arr, e => unpackAPIObject(e, included))
}
function unpackAPIObject(obj, included) {
  const rels = {}
  const iter = e => {
    const rel = _get(included, `${e && e.type}:${e && e.id}`)
    return rel ? unpackAPIObject(_get(included, `${e.type}:${e.id}`), included) : e
  }
  _forEach(_get(obj, 'relationships'), (es, k) => {
    const data = _get(es, 'data')
    if (_isArray(data)) {
      rels[k] = _map(data, iter)
    } else {
      rels[k] = iter(data)
    }
  })
  return {
    ...obj.attributes,
    id: obj.id || (obj.attributes && obj.attributes.id),
    type: obj.type,
    $relationships: rels,
    $original: obj,
  }
}

export function unpackAPI(obj) {
  if (!obj) {
    return obj
  }
  const { data, included, ...rest } = obj || {}
  const includeMap = _keyBy(included || [], e => `${e.type}:${e.id}`)

  if (!data) {
    return obj
  }

  let out

  if (_isArray(data)) {
    out = unpackAPIArray(data, includeMap)
  } else {
    out = unpackAPIObject(data, includeMap)
  }

  return { ...rest, data: out }
}

export const { API_BASE } = ENV

// eslint-disable-next-line import/no-mutable-exports
export let makeURL = _identity

if (API_BASE) {
  makeURL = (pathname, params, basePath = null) => {
    const u = url.parse(basePath || API_BASE)
    const p = url.parse(url.format(_extend(u, { pathname })))
    p.pathname += p.pathname.charAt(p.pathname.length - 1) === '/' ? '' : '/'
    p.query = params
    return url.format(p)
  }
}

export function fetchAPI(pathname, options, basePath = null) {
  const opts = {
    credentials: 'include',
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    ...options,
  }
  if (ENV.X_SOURCE_ORIGIN) {
    opts.headers['X-Source-Origin'] = ENV.X_SOURCE_ORIGIN
  }

  if (getCookieByName('X-TF-ECOMMERCE')) {
    opts.headers['X-TF-ECOMMERCE'] = getCookieByName('X-TF-ECOMMERCE')
  }

  if (isWeb()) {
    const url = makeURL(pathname, opts.params, basePath)
    return whatwgfetch(url, opts)
      .catch(err => {
        const code = 'request_failed'
        return Promise.reject(ServerError({ code, details: messageByCode[code] }))
      })
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          return Promise.reject(res)
        }
        return res
      })
      .catch(res => {
        if (res instanceof ServerError) {
          return Promise.reject(res)
        }
        return res
          .json()
          .catch(() => {
            const code = 'unexpected'
            return Promise.reject(ServerError({ status: res.status, code, details: messageByCode[code] }))
          })
          .then(e => Promise.reject(ServerError.fromResponse(e)))
      })
      .then(res => {
        if (res.status === 204) {
          return
        }
        return res.json()
      })
      .then(body => unpackAPI(body))
  } else if (isNative()) {
    return fetch(makeURL(pathname, opts.params), opts)
      .catch(err => {
        const code = 'request_failed'
        return Promise.reject(ServerError({ code, details: messageByCode[code] }))
      })
      .then(res => {
        if (res.status < 200 || res.status >= 300) {
          return Promise.reject(res)
        }
        return res
      })
      .catch(res => {
        if (res instanceof ServerError) {
          return Promise.reject(res)
        }
        return res
          .json()
          .catch(() => {
            const code = 'unexpected'
            return Promise.reject(ServerError({ status: res.status, code, details: messageByCode[code] }))
          })
          .then(e => Promise.reject(ServerError.fromResponse(e)))
      })
      .then(res => {
        if (res.status === 204) {
          return
        }
        return res.json()
      })
      .then(body => unpackAPI(body))
  }
}

export function formatFiltersAsParam(filter) {
  const updatedFilter = _reduce(
    filter,
    (result, val, key) => {
      result[`filters[${key}]`] = val
      return result
    },
    {},
  )

  return updatedFilter
}
