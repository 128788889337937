import React from 'react'
import Card from '../../../_library/Card'
import { getPermissions } from '../../../_theme/utils'
import { ROLES } from '../../../constants/roles'
import _isEmpty from 'lodash/isEmpty'

const EventPaymentMethod = ({ event = {}, configs = {}, user = {} }) => {
  const { paymentMethod, self } = event
  const { tfRole } = user
  const role = self?.role
  const permissions = getPermissions(role)
  const isTfSupport = ROLES.is_tf_support(tfRole)
  const showSection = (permissions.isAdminOrOwner() || isTfSupport) && configs.displaySection

  return showSection ? (
    <Card icon={'fa-money'} title={'Payment Method'}>
      {!_isEmpty(paymentMethod) ? (
        <div style={{ marginBottom: '20px' }}>
          <p>
            <b>Payment Method: </b>
            {paymentMethod.name}
          </p>
          {paymentMethod.connectedAccount && (
            <p>
              <b>Connected Account: </b>
              {paymentMethod.connectedAccount.name}
            </p>
          )}
        </div>
      ) : (
        <p>No payment method is set for this event.</p>
      )}
    </Card>
  ) : null
}

export default EventPaymentMethod
