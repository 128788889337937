// TODO this is not reusable component
import React, { PureComponent } from 'react'
import moment from 'moment-timezone'

import { MultiCheckbox } from '../../formik/Fields'
import SelectSlotDate from '../_library/SelectSlotDate'
import DateTimePickerReact from '../../_library/DateTimePickerReact'

const dateCategories = [
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow'
  }
]

class OrderDateFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dateCategory: '',
      openDateRange: false,
      dateRange: { start: '', end: '' },
      selectedDay: '',
      selectedHour: '',
    }
  }

  onChange = ({ type, value }) => {
    switch (type) {
    case 'dateCategories': {
      const dateInfo = { range: { start: null, end: null } }
      switch (value) {
      case 'yesterday': {
        const today = getDayStartDate(new Date(), this.props.timezone)
        dateInfo.range.start = today - DAY_MS
        dateInfo.range.end = today
        break
      }
      case 'today': {
        const today = getDayStartDate(new Date(), this.props.timezone)
        dateInfo.range.start = today
        dateInfo.range.end = today + DAY_MS
        break
      }
      case 'tomorrow': {
        const today = getDayStartDate(new Date(), this.props.timezone)
        dateInfo.range.start = today + DAY_MS
        dateInfo.range.end = today + 2 * DAY_MS
        break
      }
      }
      return this.props.onChange(dateInfo)
    }
    case 'dateRange': {
      const dateInfo = { range: value }
      return this.props.onChange(dateInfo)
    }
    }
  }

  onSelectDateCategory = category => {
    category = category.filter(el => el !== this.state.dateCategory[0])
    this.setState((state) => ({
      dateCategory: category,
      dateRange: { start: '', end: '' },
      selectedDay: '',
      selectedHour: '',
    }))
    this.onChange({ type: 'dateCategories', value: category[0] })
  }

  onChangeRangeStart = e => {
    let { dateRange } = this.state
    dateRange = { ...dateRange }
    dateRange.start = +moment.utc(e)
    if (!dateRange.end) {
      dateRange.end = getDayStartDate(e, this.props.timezone) + DAY_MS
    }
    this.setState(() => ({
      dateRange,
      dateCategory: '',
      selectedDay: '',
      selectedHour: '',
    }))
    const dateRangeTimezone = {
      start: getTimezoneDate(e, this.props.timezone),
      end: getTimezoneDate(dateRange.end, this.props.timezone)
    }
    this.onChange({ type: 'dateRange', value: dateRangeTimezone })
  }

  onChangeRangeEnd = e => {
    let { dateRange } = this.state
    dateRange = { ...dateRange, end: +moment.utc(e) }
    this.setState(state => ({
      dateRange ,
      dateCategory: '',
      selectedDay: '',
      selectedHour: '',
    }))
    const dateRangeTimezone = {
      start: getTimezoneDate(dateRange.start, this.props.timezone),
      end: getTimezoneDate(e, this.props.timezone)
    }
    this.onChange({ type: 'dateRange', value: dateRangeTimezone })
  }

  onChangeDay = day => {
    const { selectedHour } = this.state
    this.setState(state => ({
      selectedDay:  day,
      dateCategory: '',
      dateRange: { start: '', end: '' },
    }))
    const dateRange = {}
    if (day) {
      if (selectedHour) {
        const d = +moment.tz(`${day} ${selectedHour}`, this.props.timezone).utc()
        dateRange.start = dateRange.end = d
      } else {
        const d = getDayStartDate(day, this.props.timezone)
        dateRange.start = d
        dateRange.end = d + DAY_MS
      }
    }
    this.onChange({ type: 'dateRange', value: dateRange })
  }

  onChangeHour = hour => {
    const { selectedHour, selectedDay } = this.state
    this.setState(state => ({
      selectedHour: hour,
      dateCategory: '',
      dateRange: { start: '', end: '' },
    }))
    const dateRange = {}
    if (hour) {
      const d = +moment.tz(`${selectedDay} ${hour}`, this.props.timezone).utc()
      dateRange.start = dateRange.end = d
    } else {
      const d = getDayStartDate(selectedDay, this.props.timezone)
      dateRange.start = d
      dateRange.end = d + DAY_MS
    }
    this.onChange({ type: 'dateRange', value: dateRange })
  }

  render() {
    const { dateCategory, dateRange, selectedDay, selectedHour } = this.state
    const { tickets } = this.props
    // todo bad styling
    return (
      <div>
        <br/>
        <div className='row'>
          <div className="col-sm-6">
            &ensp;&ensp;&ensp;Filter by Time Slot
          </div>
        </div>
        <div className='row'>
          <div className="col-sm-6">
            <MultiCheckbox
              options={dateCategories}
              value={dateCategory}
              onChange={this.onSelectDateCategory}
              classNames={{ container_item: 'col-sm-4', container: 'radio-group-fields' }}
            />
          </div>
        </div>
        <br/>
        <div className='row'>
          <div className="col-sm-6">
            <div className="col-sm-6 col-12">
              <DateTimePickerReact
                placeholder="D MMM YYYY H:M AM"
                value={dateRange.start}
                label="Start Date"
                onChange={this.onChangeRangeStart}
                timestamp
              />
              <div className='div-spacing-10'/>
            </div>
            <div className="col-sm-6 col-12">
              <DateTimePickerReact
                placeholder="D MMM YYYY H:M AM"
                value={dateRange.end}
                label="End Date"
                onChange={this.onChangeRangeEnd}
                timestamp
              />
              <div className='div-spacing-10'/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div>
              <SelectSlotDate
                tickets={tickets}
                dayValue={selectedDay}
                hourValue={selectedHour}
                onSelectDay={this.onChangeDay}
                onSelectHour={this.onChangeHour}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderDateFilter

const getDayStartDate = (day = new Date(), timezone) => {
  let d = moment.tz(day, timezone).format('MM.DD.YYYY')
  d = moment(d, 'MM.DD.YYYY')
  const today = moment.tz(d, timezone).utc()
  return +today
}

const getTimezoneDate = (date = new Date(), timezone) => {
  const d = moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
  return +moment.tz(d, timezone).utc()
}

const DAY_MS = 1000 * 60 * 60 * 24
