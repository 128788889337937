import React from 'react'
import Button from '../../_library/Button'
import Card from '../../_library/Card'
import Field from '../../_library/Field'

class PasswordsCard extends React.PureComponent{
  constructor(props) {
    super(props)
    this.state = {
      newPassword: '',
      newPasswordCreating: false,
      passwordDeleting: false,
      passwordsRefreshing: false,
    }
  }

  handleNewPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value
    })
  }

  processCreatePassword = () => {
    if (this.props.onCreatePassword && this.state.newPassword !== '') {
      const loadingSetter = (val) => () => this.setState({ newPasswordCreating: val })
      Promise.resolve(this.props.onCreatePassword(this.state.newPassword))
        .catch(loadingSetter(false))
        .then((r) => {
          this.setState({
            newPassword:'',
            newPasswordCreating: false
          })
        })
      loadingSetter(true)()
    }
  }

  processDeletePassword(password) {
    if (this.props.onDeletePassword && password !== '') {
      const loadingSetter = (val) => () => this.setState({ passwordDeleting: val })
      Promise.resolve(this.props.onDeletePassword(password))
        .catch(loadingSetter(false))
        .then(loadingSetter(false))
      loadingSetter(true)()
    }
  }

  processRefreshPasswords = () => {
    const loadingSetter = (val) => () => this.setState({ passwordsRefreshing: val })
    Promise.resolve(this.props.onRefreshPasswords())
      .catch(loadingSetter(false))
      .then(loadingSetter(false))
    loadingSetter(true)()
  }

  render() {
    const { newPassword, newPasswordCreating, passwordDeleting, passwordsRefreshing } = this.state
    const passwords = this.props.passwords || []
    return (
      <Card icon={'fa-key'} title={'Passwords'}>
        <div className="new-password-form row">
          <div className="add-password">
            {!passwords.length &&
                <div className="col-md-12">
                  <p className="control-label">Please add at least one password</p>
                </div>
            }
            <div className="col-xs-6 col-12">
              <Field
                ref="newPassword"
                id="newPassword"
                label="New Password"
                value={newPassword}
                onChange={(e) => this.handleNewPasswordChange(e)}
              />
            </div>
            <div className="col-xs-6 col-12" style={{ paddingLeft: 0 }}>
              <Button className="btn btn-primary btn-shadow new-password-form-btn" type="button" loading={newPasswordCreating} onClick={() => this.processCreatePassword()} disabled={newPasswordCreating || newPassword === ''}>
                  Create
              </Button>
            </div>
          </div>
        </div>
        <div className="text-right password-refreshbtn">
          <Button className="btn btn-seablue btn-shadow refresh-btn" type="button" loading={passwordsRefreshing} onClick={() => this.processRefreshPasswords()} disabled={passwordsRefreshing}>
            <i className="fa fa-fw fa-refresh" aria-hidden="true" /> Refresh
          </Button>
        </div>
        <table className="table passwords-table">
          <thead>
            <tr>
              <th>Existing Passwords</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            { passwords.length > 0 && passwords.map((row, index) => (
              <tr key={index}>
                <td>{row.password}</td>
                <td className="text-right">
                  <Button className="passowrd-deletebtn delete-password" type="button" disabled={passwordDeleting} onClick={() => this.processDeletePassword(row.password)}>
                    <i className="fa fa-trash" aria-hidden="true" /> Delete
                  </Button>
                </td>
              </tr>
            ))
            }
            { passwords.length === 0 &&
              <tr>
                <td className="text-center" colSpan="2">There are no passwords.</td>
              </tr>
            }
          </tbody>
        </table>
      </Card>
    )
  }
}

export default PasswordsCard
