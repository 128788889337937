import React from 'react'
import _groupBy from 'lodash/groupBy'
import _each from 'lodash/each'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import EmptyBar from '../../../_library/EmptyBar'
import LoadingBar from '../../../_library/LoadingBar'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import { PRE_REGISTRATION_TABLE_COLUMN, PRE_REGISTRATION_FILTER_TYPE } from '../constants'
import { HTTP_INIT, HTTP_LOADING_FAILED, HTTP_LOADING_SUCCESSED } from '../../../../_common/core/http'
import ByNameTable from './ByNameTable'
import ByDateTable from './ByDateTable'

const AUTO_REFRESH = 10 * 1000
const SECTION = 'pre_registration'

export default class PerformancePreRegistration extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filterType: PRE_REGISTRATION_FILTER_TYPE.NAME,
      isLoadingPreRegistrationData: true,
      http_status: HTTP_INIT
    }
  }

  componentDidMount() {
    this.getPreRegistrationData()
    this.getPreRegistrationDataId = setInterval(async () => {
      this.getPreRegistrationData()
    }, 10 * 1000)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.getPreRegistrationDataId)
  }

  getPreRegistrationData = async () => {
    const { event } = this.props

    try {
      const data = await getPerfomanceData(event.id, SECTION)
      const preRegistrationData = _map(_get(data, 'pre_registration'), item => ({
        ...item,
        quantity: item.quantity || 0
      }))

      this.handleResponse(preRegistrationData)
    } catch (err) {
      this.handleResponse(null, err)
    }
  }

  handleResponse = (preRegistrationData, error) => {
    const { isLoadingPreRegistrationData } = this.state

    if (this.isUnmounted || _isEqual(this.initialData, preRegistrationData)) return

    isLoadingPreRegistrationData &&
      this.setState({
        isLoadingPreRegistrationData: false
      })

    if (error) {
      this.setState({
        http_status: HTTP_LOADING_FAILED
      })
    } else {
      const groupedByDate = _groupBy(preRegistrationData, 'date')
      const groupedByName = _groupBy(preRegistrationData, 'name')

      this.initialData = preRegistrationData
      this.setState({
        preRegistrationData,
        groupedByDate,
        groupedByName,
        http_status: HTTP_LOADING_SUCCESSED
      })
    }
  }

  onClickTab = type => {
    this.setState({ filterType: type })
  }

  getChildComponent = () => {
    const { event, tickets, hideDateBreakdowns } = this.props
    const { filterType, preRegistrationData, groupedByDate, groupedByName } = this.state

    if (!(preRegistrationData && preRegistrationData.length)) return <EmptyBar />
    switch (filterType) {
    case PRE_REGISTRATION_FILTER_TYPE.NAME:
      return <ByNameTable event={event} data={preRegistrationData} groupedData={groupedByName} />
      break
    case PRE_REGISTRATION_FILTER_TYPE.DATE:
      return <ByDateTable event={event} data={preRegistrationData} groupedData={groupedByDate} />
      break
    }
  }

  render() {
    const { event } = this.props
    const { filterType, isLoadingPreRegistrationData } = this.state

    const tabName = filterType === PRE_REGISTRATION_FILTER_TYPE.NAME ? 'tab-title selected' : 'tab-title'
    const tabDate = filterType === PRE_REGISTRATION_FILTER_TYPE.DATE ? 'tab-title selected' : 'tab-title'

    return isLoadingPreRegistrationData ? (
      <LoadingBar title={`Hold tight! We're getting your event's Pre-Registration...`} />
    ) : (
      <div className="table-background">
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/pre-registration-ico.svg')}
          />
          Pre-Registration
        </div>
        <div className="tab-view">
          <div className="tab-header">
            <div className="text-center">
              Group by:
              <div className={tabName} onClick={() => this.onClickTab(PRE_REGISTRATION_FILTER_TYPE.NAME)}>
                Name
              </div>
              <div className={tabDate} onClick={() => this.onClickTab(PRE_REGISTRATION_FILTER_TYPE.DATE)}>
                Date
              </div>
            </div>
          </div>
        </div>
        {this.getChildComponent()}
      </div>
    )
  }
}
