import React from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import SelectTimeSlot from './SelectTimeSlot'

export default class SelectTimeSlotTickets extends React.Component {
  constructor(props) {
    super(props)
    this.groupSelects = new Map()
    this.state = {
      checkboxes: {}
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.deselectCheckboxes && this.props.deselectCheckboxes) {
      this.setState({ checkboxes: {} })
      this.groupSelects = new Map()
    }
  }

  onChange = (v, groupName) => {
    this.groupSelects.set(groupName, v)
    const ids = [...this.groupSelects.values()].join(',')
    this.props.onChange(ids)
  }

  onChecked = groupName => {
    this.setState(s => {
      const checkboxes = { ...s.checkboxes }
      checkboxes[groupName] = !checkboxes[groupName]
      if (!checkboxes[groupName]) {
        this.groupSelects.set(groupName, '')
        const groupSelects = _filter([...this.groupSelects.values()], g => !!g)
        this.props.onChange(groupSelects.join(','))
      }
      return { checkboxes }
    })
  }

  render() {
    const { groupedTickets } = this.props
    return (
      <div>
        {_map(groupedTickets, el => (
          <div key={el.groupName} style={{ clear: 'both' }}>
            <input
              id={el.groupName}
              type="checkbox"
              checked={!!this.state.checkboxes[el.groupName]}
              onChange={() => this.onChecked(el.groupName)}
            />
            <label style={{ marginLeft: 10 }}>{el.groupName}</label>
            {this.state.checkboxes[el.groupName] ? (
              <SelectTimeSlot onChange={v => this.onChange(v, el.groupName)} timeSlots={el} />
            ) : null}
          </div>
        ))}
      </div>
    )
  }
}
