import React, { useEffect, useState } from 'react'
import { withFormik, Field, Form } from 'formik'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _find from 'lodash/find'
import _map from 'lodash/map'
import Button from '../../_library/Button'
import { isValidInteger, requiredValidator } from '../../../_common/core/validation'
import { RadioGroupField, SimpleField } from '../../formik/Fields'
import { checkCountry, countryCodeAdapter } from '../../utils/coutriesUtils'
import { countries } from '../../constants/countries'
import ConfirmModal from '../../_library/ConfirmModal'
import { showAxiosError } from '../../utils/messenger'
import { movedToHomeBySettingStripe, movedToHomeBySkipingPayout } from '../../../_common/core/gtag'
import { getStripeConnectLink } from '../../../_common/core/http_services'

const modalContent =
'Do you want to set the same payment account to the events under this brand for which sales has not started yet?'

const validateMember = (data, props) => {
  const errors = {}
  if (data.payout_mode === "1") {
    const alphaNumericRegex = RegExp('^([0-9a-zA-Z]*)$')
    const legalFirstName = _get(data, 'legalFirstName') || ''
    const legalLastName = _get(data, 'legalLastName') || ''
    const accountNumber = _get(data, 'accountNumber') || ''
    const swiftCode = _get(data, 'swiftCode') || ''
    const bsb = _get(data, 'bsb') || ''
    const ifscNumber = _get(data, 'ifscNumber') || ''
    const country = _get(data, 'country') || ''
    const fullAddress = _get(data, 'fullAddress') || ''
    const clabe = _get(data, 'clabe') || ''
    const nzbn = _get(data, 'nzbn') || ''
    if (!_get(props, 'isRnDomain')) {
      if (
        _get(data, 'internalSupportEmail') &&
        _get(data, 'internalSupportEmail').length &&
        !validateEmail(_get(data, 'internalSupportEmail'))
      ) {
        _set(errors, 'internalSupportEmail', 'Invalid')
      }
  
      if (
        _get(data, 'externalSupportEmail') &&
        _get(data, 'externalSupportEmail').length &&
        !validateEmail(_get(data, 'externalSupportEmail'))
      ) {
        _set(errors, 'externalSupportEmail', 'Invalid')
      }
    }
  
    if (nzbn && (!isValidInteger(nzbn) || nzbn.length !== 13)) {
      _set(errors, 'nzbn', 'Invalid code: NZBN code must contain 13 digits')
    }
    switch (country) {
      case 'us': {
        if (!legalFirstName) {
          _set(errors, 'legalFirstName', 'First name is required')
        }
  
        if (!legalLastName) {
          _set(errors, 'legalLastName', 'Last name is required')
        }
        break
      }
      case 'au': {
        if (bsb && (!isValidInteger(bsb) || bsb.length !== 6)) {
          _set(errors, 'bsb', 'BSB should be 6 digits')
        }
        break
      }
      case 'in': {
        if (ifscNumber && (!alphaNumericRegex.test(ifscNumber) || ifscNumber.length !== 11)) {
          _set(errors, 'ifscNumber', 'IFSC Number should be 11 alphanumeric characters')
        }
        break
      }
      case 'mx': {
        if (accountNumber && (!isValidInteger(accountNumber) || accountNumber.length !== 18)) {
          _set(errors, 'accountNumber', 'Account Number must contain 18 digits')
        }
  
        if (accountNumber && !swiftCode) {
          _set(errors, 'swiftCode', 'SWIFT Code is required')
        }
  
        if (accountNumber && swiftCode && !alphaNumericRegex.test(swiftCode)) {
          _set(errors, 'swiftCode', 'SWIFT Code can only be alphanumeric')
        }
  
        if (accountNumber && !clabe) {
          _set(errors, 'clabe', 'CLABE Code is required')
        }
  
        if (accountNumber && clabe && (!isValidInteger(clabe) || clabe.length !== 18)) {
          _set(errors, 'clabe', 'CLABE must contain 18 digits')
        }
  
        if (accountNumber && !fullAddress) {
          _set(errors, 'fullAddress', 'Address is required')
        }
  
        if (accountNumber && swiftCode && (swiftCode.length < 8 || swiftCode.length > 11)) {
          _set(errors, 'swiftCode', 'SWIFT Code must contain 8 to 11 characters')
        }
        break
      }
      default:
        break
    }
  }
  return errors
}

const InviteTeamForm = ({
  brand,
  values,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  history,
  isValid,
  SET_HAS_PAYOUT_MODE,
  APPLY_PAYMENT_METHOD,
  handleChange,
  handleBlur,
  brandPaymentData,
  isApplying,
  isLoading,
  fetchPaymentData
}) => {
  const [stripe, setStripeData] = useState({})
  const connectedPaymentMethod = _get(stripe, 'paymentMethod')
  const [showAccountConnectModal, setShowAccountConnectModal] = useState(false)
  const [stripeOnboardingLink, setStripeOnBoardingLink] = useState(null)
  const [stripeOnboardingLinkIsLoading, setStripeOnboardingLinkIsLoading] = useState(false)
  const affectableEventsList = _get(brandPaymentData, 'affectableEventsList')
  let showConnectStripetoEvents = !!(affectableEventsList && affectableEventsList.length)
  useEffect(() => {
    const connectAuthUrl = _get(brandPaymentData, 'stripe.connectAuthUrl')

    if (!connectAuthUrl) {
      setStripeData(brandPaymentData.stripe || {})
      return
    }

    const url = new URL(connectAuthUrl)
    const stateValue = url.searchParams.get('state')
    const decodedStateValue = atob(stateValue)
    const parsedStateValueObj = JSON.parse(decodedStateValue)
    const updatedStateValueObj = {
      ...parsedStateValueObj,
      redirect_uri: window.location.origin + `/brand/${brand.id}/stripe-connect-complete`
    }
    const strStateValueObj = JSON.stringify(updatedStateValueObj)
    const encodedStateValueObj = btoa(strStateValueObj)
    url.searchParams.set('state', encodedStateValueObj)

    setStripeData({ ...brandPaymentData.stripe, connectAuthUrl: url.href })
  }, [brand, brandPaymentData])

  const fetchStripeOnBoardingLink = async _ => {
    setStripeOnboardingLinkIsLoading(true)
    try {
      const res = await getStripeConnectLink(brand.id)
      setStripeOnBoardingLink(_get(res, 'connectLink'))
      setStripeOnboardingLinkIsLoading(false)
    } catch (e) {
      setStripeOnboardingLinkIsLoading(false)
      showAxiosError(e)
    }
  }

  const openOnboardingLink = _ => {
    window.open(stripeOnboardingLink, '_blank')
  }

  const handleButtonClick = async () => {
    // in case of stripe connect complete page opened by path
    window.localStorage.removeItem('stripe-connected')

    const listenStorageChange = async () => {
      if (localStorage.getItem('stripe-connected') && !isLoading) {
        const res = await fetchPaymentData()
        showConnectStripetoEvents = !!(_get(res, 'affectableEventsList') || []).length
        window.localStorage.removeItem('stripe-connected')
        window.removeEventListener('storage', listenStorageChange)
        showConnectStripetoEvents && setShowAccountConnectModal(true)
      }
    }

    window.addEventListener('storage', listenStorageChange)
    if (_get(stripe, 'connectVersion') === 'oauth') {
      window.open(stripe.connectAuthUrl, '_blank')
    } else {
      fetchStripeOnBoardingLink()
    }
  }
  const handleGoBack = () => {
    history.goBack()
  }
  const handleSkip = () => {
    SET_HAS_PAYOUT_MODE(true)
    movedToHomeBySkipingPayout()
    history.push('/')
  }
  const handleConfirm = async () => {
    try {
      await APPLY_PAYMENT_METHOD(brand.id, connectedPaymentMethod)
      movedToHomeBySettingStripe()
      await fetchPaymentData()
      setShowAccountConnectModal(false)
    } catch (error) {
      setShowAccountConnectModal(false)
      showAxiosError(error)
    }
  }
  const disableContinueButton = !isValid
  const firstSelected = values.payout_mode === "1"
  const secondSelected = values.payout_mode === "2"
  const showStripeConnectInfo = !!stripe.connectAuthUrl
  const connectedAccountName = _get(stripe, 'connectedAccount.name')
  return (
    <Form className="payout-mode-container">
      <div className="margin-horizontal-25 title-form">Select payout mode</div>
      <label className="payout-mode-description">
        There are two different options for receiving your ticket revenue. Please select your preferred option:
      </label>
      <div className="margin-horizontal-16">
        <Field
          type="payout_mode"
          name={'payout_mode'}
          component={RadioGroupField}
          validate={requiredValidator()}
          onClickContainers={[
            () => { 
              setFieldValue('payout_mode', '1')
            }, 
            () => { 
              setFieldValue('payout_mode', '2') 
            }
          ]}
          extraDiv 
          classNames={{
            container: `radio-group-container payout-option single-column ${
              firstSelected ? 'first-selected' 
              : secondSelected ? 'second-selected' 
              : ''}`,
            container_item: 'radio-group-item',
          }}
          groups={[
            {
              label: <div className='mode-container'>
                <div className='mode-title'>10-15 business days after your event</div>
                <div className='mode-description'>This is automatic and you can publish your event immediately.</div>
              </div>,
              value: '1',
              component: checkCountry(values.country, 'mx') ? (
                <div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="swiftCode"
                      label="SWIFT Code"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="clabe"
                      label="CLABE"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <Field
                    name="fullAddress"
                    label="Full Address"
                    component={SimpleField}
                    touchedOnBlur
                    formclassname="field-wrapper"
                  />
                </div>
              ) : checkCountry(values.country, 'uk') ? (
                <div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <Field
                    name="sortCode"
                    label="Sort Code"
                    component={SimpleField}
                    touchedOnBlur
                    formclassname="field-wrapper"
                  />
                </div>
              ) : checkCountry(values.country, 'in') ? (
                <div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="ifscNumber"
                      label="IFSC Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="bankName"
                      label="Bank Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                </div>
              ) : checkCountry(values.country, 'au') ? (
                <div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <Field
                    name="bsb"
                    label="BSB"
                    component={SimpleField}
                    touchedOnBlur
                    formclassname="field-wrapper"
                  />
                </div>
              ) : checkCountry(values.country, 'us') ? (
                <div>
                  <div>
                    <label className='control-label type-account-label'>Type of account</label>
                    <div>
                      <Field
                        name="accountType"
                        component={RadioGroupField}
                        groups={[
                          {
                            label: 'Checking',
                            value: 'checking'
                          },
                          {
                            label: 'Saving',
                            value: 'saving'
                          }
                        ]}
                        classNames={{
                          container: 'radio-group-container',
                          container_item: 'radio-group-item account-container-checkbox',
                          component: ''
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="legalFirstName"
                      label="First Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="legalLastName"
                      label="Last Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                  <Field
                    name="routingNumber"
                    label="ACH Routing Number"
                    component={SimpleField}
                    touchedOnBlur
                    formclassname="field-wrapper"
                  />
                </div>
              ) : (
                <div>
                  <div className='half-input-wrapper'>
                    <Field
                      name="accountName"
                      label="Account Holder Name"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                    <Field
                      name="accountNumber"
                      label="Account Number"
                      component={SimpleField}
                      touchedOnBlur
                      formclassname="field-wrapper"
                    />
                  </div>
                </div>
              ),
            },
            {
              label: <div className='mode-container'>
                <div className='mode-title'>Before your event</div>
                <div className='mode-description'>
                  If you would like your funds in advance, then you will need to set up, connect
                  and activate your Stripe account before your event is published.
                </div>
              </div>,
              value: '2',
              component:
              <>
                {showStripeConnectInfo ? (
                  <button
                    type='button'
                    className='stripe-button'
                    onClick={!stripeOnboardingLink ? handleButtonClick : openOnboardingLink}
                  >
                    {!stripeOnboardingLinkIsLoading && ((!stripeOnboardingLink) ?
                      <>Set your <img alt="" src={asset('/resources/images/stripe-icon.png')} height={24} width={57} />{" "}Account</> :
                      <>Go to <img alt="" src={asset('/resources/images/stripe-icon.png')} height={24} width={57} />{" "}onboarding</>
                    )}
                    {stripeOnboardingLinkIsLoading && (
                      <><i className="fa fa-circle-o-notch fa-fw fa-spin" />{" "}Preparing onboarding link...</>
                    )}
                  </button>
                ) : (
                  <p>
                    <div className='div-spacing-20'/>
                    <strong>Connected Account: </strong>
                    {connectedAccountName || 'N/A'}
                  </p>
                )}
                {showConnectStripetoEvents && !showStripeConnectInfo && (
                  <Button
                    key="stripe-connect-button"
                    className="btn btn-primary btn-lg btn-shadow"
                    type="button"
                    onClick={() => setShowAccountConnectModal(true)}
                  >
                    Connect Stripe Account to Events
                  </Button>
                )}
              </>
            },
          ]}
        />
      </div>
      <div className="buttons-wrapper margin-horizontal-25">
        <Button type="button" className="back-button" disabled={true} onClick={handleGoBack}>
          Back
        </Button>
        <div className={!disableContinueButton ? 'submit-button-visible' : 'submit-button-wrapper'}>
          <Button
            type="submit"
            className={
              (disableContinueButton || (values.payout_mode === '2' && !connectedAccountName)) 
              ? 'submit-disabled' 
              : 'submit-button-payout'
            }
            disabled={disableContinueButton || (values.payout_mode === '2' && !connectedAccountName)}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>
      <div onClick={handleSkip} className='skip-step'>Skip step</div>

      <ConfirmModal
        isOpen={showAccountConnectModal}
        header="Confirmation"
        content={
          <>
            <p style={{ padding: 10 }}>{modalContent}</p>
            <ul>
              {_map(affectableEventsList, item => (
                <li key={item.slug}>{item.displayName}</li>
              ))}
            </ul>
          </>
        }
        actions={[
          { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow', loading: isApplying },
          { value: 'no', label: 'No', className: 'btn btn-cancel btn-shadow', disabled: isApplying }
        ]}
        onAction={value => (value === 'no' ? setShowAccountConnectModal(false) : handleConfirm())}
      />
    </Form>
  )
}

const FormInviteTeamFormik = withFormik({
  mapPropsToValues: props => {
    const initialValues = {
      displayName: _get(props, 'initialValues.attributes.displayName') || '',
      slug: _get(props, 'initialValues.attributes.slug') || '',
      legalEntity: _get(props, 'initialValues.attributes.legalEntity') || '',
      legalFirstName: _get(props, 'initialValues.attributes.legalFirstName') || '',
      legalLastName: _get(props, 'initialValues.attributes.legalLastName') || '',
      addressLine1: _get(props, 'initialValues.attributes.addressLine1') || '',
      addressLine2: _get(props, 'initialValues.attributes.addressLine2') || '',
      brandCity: _get(props, 'initialValues.attributes.city') || '',
      brandState: _get(props, 'initialValues.attributes.state') || '',
      zip: _get(props, 'initialValues.attributes.zip') || '',
      accountName: _get(props, 'initialValues.attributes.accountName') || '',
      accountNumber: _get(props, 'initialValues.attributes.accountNumber') || '',
      routingNumber: _get(props, 'initialValues.attributes.routingNumber') || '',
      accountType: _get(props, 'initialValues.attributes.accountType') || 'checking',
      fullAddress: _get(props, 'initialValues.attributes.fullAddress') || '',
      clabe: _get(props, 'initialValues.attributes.clabe') || '',
      bsb: _get(props, 'initialValues.attributes.bsb') || '',
      nzbn: _get(props, 'initialValues.attributes.nzbn') || '',
      sortCode: _get(props, 'initialValues.attributes.sortCode') || '',
      swiftCode: _get(props, 'initialValues.attributes.swiftCode') || '',
      email: _get(props, 'initialValues.attributes.email') || '',
      ifscNumber: _get(props, 'initialValues.attributes.ifscNumber') || '',
      bankName: _get(props, 'initialValues.attributes.bankName') || '',
    }
    const selectedCountry = countryCodeAdapter(_get(props, 'initialValues.attributes.country'), 'gb', 'uk')
    initialValues.country = _find(countries, (item, key) => key === selectedCountry) ? selectedCountry : ''

    return initialValues
  },

  validate: (values, props) => validateMember(values, props),

  handleSubmit: async (values, { props, setSubmitting }) => {
    const { brandCity, brandState, ...rest } = values
    try {
      await props.onSubmit({
        attributes: {
          ...rest,
          city: brandCity,
          state: brandState,
          newSignupFlow: true
        }
      }, values.payout_mode === '2')
      setSubmitting(false)
    } catch(e) {
      setSubmitting(false)
    }
  },
  displayName: 'inviteTeamForm'
})(InviteTeamForm)

export default FormInviteTeamFormik
