export const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  API_BASE: process.env.TTF_PUBLIC_API_BASE,
  API_CONSUMER: process.env.TTF_PUBLIC_API_CONSUMER,
  RN_CONSUMER: process.env.TTF_PUBLIC_RN_CONSUMER,
  MC_CONSUMER: process.env.TTF_PUBLIC_MC_CONSUMER,
  SEAT_MAP_CREATOR_HOST: process.env.TTF_PUBLIC_SEAT_MAP_CREATOR_HOST,
  FB_APP_ID: process.env.TTF_PUBLIC_FB_APP_ID,
  ASSET_URL: process.env.TTF_PUBLIC_ASSET_URL,
  CDN_URL: process.env.TTF_PUBLIC_CDN_URL,
  GOOGLE_MAPS_API_KEY: process.env.TTF_PUBLIC_GOOGLE_MAPS_API_KEY,
  REQUEST_SOURCE: process.env.TTF_PUBLIC_REQUEST_SOURCE,
  LD_CLIENT_SIDE_ID: process.env.TTF_PUBLIC_LD_CLIENT_SIDE_ID,
  FBAD_API_BASE: process.env.TTF_PUBLIC_FBAD_API_BASE,
  GA_TRACKING_ID: process.env.TTF_PUBLIC_GA_TRACKING_ID,
  DROPBOX_APP_KEY: process.env.TTF_PUBLIC_DROPBOX_APP_KEY,
  GOOGLE_DRIVE_CLIENT_ID: process.env.TTF_PUBLIC_GOOGLE_DRIVE_CLIENT_ID,
  GOOGLE_DRIVE_KEY: process.env.TTF_PUBLIC_GOOGLE_DRIVE_KEY,
  X_SOURCE_ORIGIN: process.env.TTF_PUBLIC_X_SOURCE_ORIGIN,
  SENTRY_DSN: process.env.TTF_PUBLIC_SENTRY_DSN,
  TINY_MCE_EDITOR_KEY: process.env.TTF_PUBLIC_TINY_MCE_EDITOR_KEY,
  TTF_PUBLIC_ZENDESK_KEY: process.env.TTF_PUBLIC_ZENDESK_KEY,
  IFRAME_API_KEY: process.env.TTF_PUBLIC_IFRAME_API_KEY
}
