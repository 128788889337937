import _result from 'lodash/result'
import _get from 'lodash/get'
import _find from 'lodash/find'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'

import * as fb from '../../_common/core/fb'
import modalStyle from '../../_common/core/modalStyle'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import Notifications from '../_library/notifications/Notifications'

import OnlineEventForm from './form/OnlineEventForm/index'
import BrandForm from '../brands/BrandForm'
import { CREATE_EVENT } from '../../_common/redux/events/actions'
import { CREATE_BRAND, FETCH_BRANDS } from '../../_common/redux/brands/actions'

import PermissionDenied from '../_library/PermissionDeniedComponent'
import LoadingBar from '../_library/LoadingBar'
import { onlineEventCreationFormError } from '../../_common/core/sentry'
import { getTitle } from '../utils/getTitle'

const createBrandModalStyleElementId = 'create-brand-modal-styles'

@withRouter
@connect(
  state => {
    const { user } = state.auth
    const { brands } = state.brands
    const { isRnDomain } = state.shared
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
    const isLoading = state.loading.has('FETCH_LAUNCH_DARKLY_INFO' || 'SET_LD_FLAGS')
    return {
      user,
      isRnDomain,
      dynamicConfigs,
      brands,
      isLoading
    }
  },
  {
    CREATE_EVENT,
    CREATE_BRAND,
    FETCH_BRANDS
  }
)
export default class EventNewOnline extends React.Component {
  constructor(props) {
    super(props)
    this.createdBrand = null
    
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)

    fbq('trackCustom', 'CreateEventStart')
    form_helper_reset()
    this.state = {
      hasEdittedFields: false,
      nextLocation: null,
      showNewBrand: false,
      resetedDescriptions: []
    }
    this.onCancelNewBrand = this.onCancelNewBrand.bind(this)
    this.handleBrandSubmit = this.handleBrandSubmit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onClickNewBrand = this.onClickNewBrand.bind(this)
  }

  componentDidMount() {
    this.props.FETCH_BRANDS()
  }

  componentWillUnmount() {
    form_helper_reset()
  }

  routerWillLeave = () => !form_helper_isEditted()

  updateResetedDescriptions = value => {
    this.setState({
      resetedDescriptions: value
    })
  }

  onClickNewBrand() {
    this.setState({ showNewBrand: true })
    this.toggleCreateBrandModalStyles()
  }

  onCancelNewBrand() {
    this.setState({ showNewBrand: false })
    this.toggleCreateBrandModalStyles()
  }

  toggleCreateBrandModalStyles = () => {
    const styleElement = document.getElementById(createBrandModalStyleElementId)
    if (styleElement) {
      document.head.removeChild(styleElement)
    } else {
      const newStyleElement = document.createElement('style')
      newStyleElement.id = createBrandModalStyleElementId
      document.head.appendChild(newStyleElement)
    }
  }

  handleSubmit(form) {
    fbq('trackCustom', 'CreateEventSubmit')
    const {
      history: { push },
      CREATE_EVENT
    } = this.props

    // fix default anti-scalping settings error
    form.attributes.flagNameChecks =
      form.attributes.flagNameChecks == null ? true : form.attributes.flagNameChecks
    form.attributes.flagIDRequired =
      form.attributes.flagIDRequired == null ? true : form.attributes.flagIDRequired
    form.attributes.flagResaleEnabled =
      form.attributes.flagResaleEnabled == null ? true : form.attributes.flagResaleEnabled
    form.attributes.flagCollectNames =
      form.attributes.flagCollectNames == null ? true : form.attributes.flagCollectNames

    return Promise.resolve(CREATE_EVENT(form))
      .then(event => {
        const id = _get(event, 'id')
        fbq('trackCustom', 'CreateEventSuccessful', {
          eventId: id
        })
        const next = id ? `/event/${id}/tickets` : '/events'
        form_helper_reset()
        push(next)
        return event
      })
      .catch(err => {
        if (err?.response?.data) {
          onlineEventCreationFormError(err?.response?.data)
        } else {
          onlineEventCreationFormError({ err: err.message })
        }
      })
  }

  handleBrandSubmit(form) {
    const { CREATE_BRAND, FETCH_BRANDS } = this.props
    return Promise.resolve(CREATE_BRAND({ ...form }))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(() => {
        FETCH_BRANDS()
        this.createdBrand = form.attributes.displayName
        this.setState({ showNewBrand: false })
      })
  }

  render() {
    const { user, brands, isRnDomain, dynamicConfigs, isLoading, configs } = this.props
    const showOnlineEvents = _get(dynamicConfigs, 'show_online_events', false)
    let selectedBrand = null
    if (this.createdBrand) {
      const found = _find(brands, { displayName: this.createdBrand })
      if (!!found) {
        this.createdBrand = null
        selectedBrand = found.id
      }
    }

    const initialValues = {}
    const { showNewBrand, resetedDescriptions } = this.state

    return (
      <div>
        <Notifications />
        <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
        {isLoading ? (
          <LoadingBar />
        ) : showOnlineEvents ? (
          <div>
            <div>
              <Modal
                className="event-details modal-trans"
                style={update(modalStyle, { content: { maxHeight: { $set: '100%' } } })}
                isOpen={showNewBrand}
                contentLabel="Modal"
                onRequestClose={this.onCancelNewBrand}
                closeTimeoutMS={150}
                ariaHideApp={false}
                id="event-detail-create-brand"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">Create Brand</div>
                    <div className="modal-body">
                      <BrandForm
                        user={user}
                        isNew={true}
                        closed={true}
                        onSubmit={this.handleBrandSubmit}
                        onCancel={this.onCancelNewBrand}
                        submitLabel="Create Brand"
                        initialValues={null}
                        isRnDomain={isRnDomain}
                        containerId='event-detail-create-brand'
                        configs={_get(configs, 'children.BrandForm')}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
              <OnlineEventForm
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                user={user}
                organizations={brands}
                selectedBrand={selectedBrand}
                onClickNewBrand={this.onClickNewBrand}
                isNew={true}
                updateResetedDescriptions={this.updateResetedDescriptions}
                resetedDescriptions={resetedDescriptions}
                dynamicConfigs={dynamicConfigs}
                configs={_get(configs, 'children.EventForm')}
              />
            </div>
          </div>
        ) : (
          <PermissionDenied subHeader="You don't have access to this page" />
        )}
      </div>
    )
  }
}
