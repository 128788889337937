import _isEqual from 'lodash/isEqual'
import _findIndex from 'lodash/findIndex'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _forEach from 'lodash/forEach'

export const checkStringMatchesPath = (path = '', str = '') => {
  const baseArr = path.split('/') // brand/:id/details
  const compareArr = str.split('/') // brand/3693/details
  const identifiers = _filter(baseArr, item => _includes(item, ':'))

  _forEach(identifiers, (idItem, index) => {
    const baseArrIdIndex = _findIndex(baseArr, item => item === idItem)

    if (baseArrIdIndex !== -1) {
      compareArr[baseArrIdIndex] = idItem
    }
  })

  return _isEqual(baseArr, compareArr)
}
