import React from 'react'

export default class MapNarrator extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      doAniMapNarrator: false
    }
  }

  doAnimation(text) {
    this.setState({ doAniMapNarrator: false, text: '' })
    const self = this
    setTimeout(() => {
      self.setState({ doAniMapNarrator: true, text })
    }, 100)
  }

  render() {
    const classMapNarrator = this.state.doAniMapNarrator ? 'mapNarrator ani' : 'mapNarrator'
    return <div className={classMapNarrator}>{this.state.text}</div>
  }
}
