import React from 'react'
import moment from 'moment'
import DateTimePickerReact from '../../../_library/DateTimePickerReact'

const EventDoorsDatesRow = props => {
  const { values, errors, touched, updateField } = props
  const handleChangeCheckInStart = (val, isSelectedDay) => {
    if(isSelectedDay && !values.checkInStart && values.startDate) {
      const hour = moment.utc(values.startDate).get('hour')
      const minute = moment.utc(values.startDate).get('minute')
      val = moment.utc(val).set({ 'hour': hour, 'minute': minute }).toISOString()
    }
    updateField('checkInStart', val)
  }

  const handleChangeCheckInEnd = (val, isSelectedDay) => {
    if(isSelectedDay && !values.checkInEnd && values.endDate) {
      const hour = moment.utc(values.endDate).get('hour')
      const minute = moment.utc(values.endDate).get('minute')
      val = moment.utc(val).set({ 'hour': hour, 'minute': minute }).toISOString()
    }
    updateField('checkInEnd', val)
  }

  return (
    <div className="row" style={{ marginTop: 8, marginBottom: 16 }}>
      <div className="col-sm-6 col-12" style={{ position: 'static' }}>
        <DateTimePickerReact
          id="checkInStart"
          label="Doors Open Time (optional)"
          placeholder="D MMM YYYY H:M AM"
          error={errors.checkInStart}
          touched={touched.checkInStart}
          value={values.checkInStart || ''}
          onChange={handleChangeCheckInStart}
        />
      </div>
      <div className="col-sm-6 col-12 pad-top-3" style={{ position: 'static' }}>
        <DateTimePickerReact
          id="checkInEnd"
          label="Last Entry Time (optional)"
          placeholder="D MMM YYYY H:M AM"
          error={errors.checkInEnd}
          touched={touched.checkInEnd}
          value={values.checkInEnd || ''}
          onChange={handleChangeCheckInEnd}
        />
      </div>
    </div>
  )
}

export default EventDoorsDatesRow
