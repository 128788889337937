import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import _get from 'lodash/get'
import CustomRoute from './_library/CustomRoute'

import _EventFAQ from './events/EventFAQ'
import _EventCheckIn from './events/checkin/index'
import _EventOrders from './events/EventOrders'
import _EventTraffic from './events/EventTraffic'
import _Customers from './customers/Customers'
import _EventInventory from './events/EventInventory'
import _EventGuestTickets from './events/EventGuestTickets'
import _EventGiftCards from './events/EventGiftCards'
import _EventPromotion from './events/EventPromotion'
import _EventBuyerLocation from './events/geographics/index'
import _EventDevices from './events/EventDevices'
import _AdPage from './ads/NewAdPage'
import _EventTeam from './events/EventTeam'
import _EventBudgetManagement from './events/EventBudgetManagement'
import _EventCredentials from './events/credentials'
import _EventPreRegistration from './events/preRegistration'
import _EventNFT from './events/nft'
import _EventGiftCertificatesMangement from './events/EventGiftCertificatesMangement'
import _EventSalesTracking from './events/performance/salesTrackingPage'
import _EventSupport from './events/EventSupport'
import _EventSupportNotes from './events/supportNotes'
import _EventPredefinedInvitations from './events/predefined_invitations/PredefinedInvitations'

import _ToursPage from './tours/ToursPage'
import _NewTourPage from './tours/NewTourPage'
import _TourDetails from './tours/TourDetails'
import _TourTeam from './tours/team/TourTeam'
import _TourPerformance from './tours/performance/TourPerformance'
import _UsersPage from './users'
import _ShopifyRedirectPage from './shopify'
import _NotFoundPage from './_library/NotFoundPage'
import _AdsPage from './ads/AdsPage'
import _EventDetails from './events/_Details'
import _EventTalent from './events/EventTalent'
import _EventTickets from './events/EventTickets'
import _EventPerformance from './events/EventPerformance'
import _EventInfluencers from './events/EventInfluencers'
import _EventAudienceDemographics from './events/EventAudienceDemographics'
import _EventAudiencePsychographics from './events/EventAudiencePsychographics'
import _EventLikes from './events/EventLikes'
import _EventAudienceMusic from './events/EventAudienceMusic'
import _EventAudienceMusicStreaming from './events/EventAudienceMusicStreaming'
import _EventGaming from './events/EventGaming'
import _EventMessaging from './events/EventMessaging'
import _EventMembership from './events/EventMembership'
import _EventMembershipCreate from './events/EventMembershipCreate'
import _EventMembershipEdit from './events/EventMembershipEdit'
import _EventCreateTemplate from './events/EventCreateTemplate'
import _EventInvitation from './events/EventInvitation'
import _EventMultiEntryPasses from './events/EventMultiEntryPasses'
import _LoginPage from './auth/LoginPage'
import _ResetPasswordPage from './auth/ResetPasswordPage'
import _ConfirmTeamInvitationPage from './auth/ConfirmTeamInvitationPage'
import _SignUpPage from './auth/signup'
import _NewSignUpPage from './auth/newsignup'
import _NewSignInPage from './auth/newsignin'
import _NewSignUpCreateAccountPage from './auth/newsignupcreateaccount'
import _NewSignUpCreateBrandPage from './auth/newsignupcreatebrand'
import _NewSignUpTeamInvitationsPage from './auth/newsignupteaminvitations'
import _NewSignUpPayoutModePage from './auth/newsignuppayoutmode'
import _Promo from './promo/Promo'
import _AccountSettingsPage from './auth/AccountSettingsPage'
import _BrandsPage from './brands/BrandsPage'
import _NewBrandPage from './brands/NewBrandPage'
import _BrandDetails from './brands/BrandDetails'
import _BrandPayments from './brands/BrandPayments'
import _BrandAudienceDemoGraphics from './brands/BrandAudienceDemoGraphics'
import _BrandAudienceMusic from './brands/BrandAudienceMusic'
import _BrandAudienceMusicStreaming from './brands/BrandAudienceMusicStreaming'
import _BrandLikes from './brands/BrandLikes'
import _BrandTeam from './brands/team/BrandTeam'
import _NewSubBrandPage from './brands/NewSubBrandPage'
import _BrandMessaging from './brands/BrandMessaging'
import _BrandCreateTemplate from './brands/BrandCreateTemplate'
import _EventList from './events/_List'
import _EventNew from './events/_New'
import _EventNewOnline from './events/_NewOnline'
import _ZoomResponseComponent from './events/form/OnlineEventForm/OnlineEventHostingDetails/ZoomResponseComponent'
import _DraftEvent from './events/draftevents/_List'
import _HomePage from './_theme/HomePage'
import _BrandEventList from './brands/BrandEventList'
import _StripeConnectComplete from './brands/StripeConnectComplete'
import { getConfig } from './utils/configUtils'

import store from '../_common/redux/store'

const globalStates = store.getState()
const isRnDomain = _get(globalStates, 'shared.isRnDomain')

const configFile = getConfig()
const hideWelcomeProcess = _get(configFile, '*.domain') === 'manacommon'

// hoc
import {
  privateRouterHOC,
  skipIfLoggedInHOC,
  rootWrapper,
  brandWrapper,
  eventWrapper,
  tourWrapper,
  tourHideIfRnHOC,
  limitedStatsWrapperHOC,
  permissionDeniedByConfigWrapper,
  bodyWrapper,
} from './hoc'

// constants
import { RN_ROLES } from './constants/roles'
import { RedirectTo } from './hoc/RedirectTo'

// Home Page
const HomePage = rootWrapper(privateRouterHOC(_HomePage))

// Login Page
const LoginPage = rootWrapper(skipIfLoggedInHOC(_LoginPage), {
  hideSidebar: true,
  hideFooter: true,
  hideHeader: true,
  enableIncDataFetch: false,
})

// Reset Password Page
const ResetPasswordPage = rootWrapper(skipIfLoggedInHOC(_ResetPasswordPage))

// Confirm Team Invitation Page
const ConfirmTeamInvitationPage = rootWrapper(_ConfirmTeamInvitationPage)

// SignUp Page
const SignUpPage = rootWrapper(skipIfLoggedInHOC(_SignUpPage), {
  hideSidebar: true,
  hideFooter: true,
  hideHeader: true,
  enableIncDataFetch: false,
})

const NewSignUpPage = rootWrapper(_NewSignUpPage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// New SignIn Page
const NewSignInPage = rootWrapper(_NewSignInPage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// New SignUp Create Account Page
const NewSignUpCreateAccountPage = rootWrapper(_NewSignUpCreateAccountPage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// New SignUp Create Brand Page
const NewSignUpCreateBrandPage = rootWrapper(_NewSignUpCreateBrandPage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// New SignUp Create Team Invitations Page
const NewSignUpTeamInvitationsPage = rootWrapper(_NewSignUpTeamInvitationsPage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// New SignUp Create Team Invitations Page
const NewSignUpPayoutModePage = rootWrapper(_NewSignUpPayoutModePage, {
  hideFooter: true,
  enableIncDataFetch: false,
  hideSidebar: true,
  noAppcues: true,
  hideHeader: true,
})

// Promo Page
const Promo = rootWrapper(_Promo, {
  hideSidebar: true,
  enableIncDataFetch: false,
})

// Account Settings Page
const AccountSettingsPage = rootWrapper(privateRouterHOC(_AccountSettingsPage))

// Brands Page
const BrandsPage = rootWrapper(privateRouterHOC(_BrandsPage))

// New Brand Page
const NewBrandPage = rootWrapper(privateRouterHOC(_NewBrandPage))

// Brand Details Page
const BrandDetails = rootWrapper(privateRouterHOC(brandWrapper(_BrandDetails)))

// Brand Payments Page
const BrandPayments = rootWrapper(privateRouterHOC(brandWrapper(_BrandPayments)))

// Brand Audience DemoGraphics Page
const BrandAudienceDemoGraphics = rootWrapper(
  privateRouterHOC(brandWrapper(permissionDeniedByConfigWrapper(_BrandAudienceDemoGraphics))),
)

// Brand Audience Music Page
const BrandAudienceMusic = rootWrapper(
  privateRouterHOC(brandWrapper(permissionDeniedByConfigWrapper(_BrandAudienceMusic))),
)

// Brand Audience Music Streaming Page
const BrandAudienceMusicStreaming = rootWrapper(
  privateRouterHOC(brandWrapper(permissionDeniedByConfigWrapper(_BrandAudienceMusicStreaming))),
)

// Brand Likes Page
const BrandLikes = rootWrapper(privateRouterHOC(brandWrapper(_BrandLikes)))

// Brand Stripe Connect Complete Page
const BrandStripeConnectComplete = rootWrapper(privateRouterHOC(_StripeConnectComplete), {
  hideSidebar: true,
  hideFooter: true,
  hideHeader: true,
  enableIncDataFetch: false,
})

// Brand Multi-Entry Passes
const EventMultiEntryPassesBrand = rootWrapper(privateRouterHOC(brandWrapper(_EventMultiEntryPasses)))

// // Brand Event Templates Page
// const BrandEventTemplates = rootWrapper(
//   privateRouterHOC(
//     brandWrapper(
//       asyncComponent(() => import('./brands/BrandEventTemplates'))
//     )))

// Brand Team Page
const BrandTeam = rootWrapper(privateRouterHOC(brandWrapper(_BrandTeam)))

// Brand Messaging Page
const BrandMessaging = rootWrapper(privateRouterHOC(brandWrapper(_BrandMessaging)))

// Brand Create Template Page
const BrandCreateTemplate = rootWrapper(privateRouterHOC(brandWrapper(_BrandCreateTemplate)))

// Customers Page
const Customers = rootWrapper(privateRouterHOC(brandWrapper(_Customers)))

// Event List Page
const BrandEventList = rootWrapper(privateRouterHOC(brandWrapper(_BrandEventList)))

// New SubBrand Page
const NewSubBrandPage = rootWrapper(privateRouterHOC(brandWrapper(_NewSubBrandPage)))

// Event List Page
const EventList = rootWrapper(privateRouterHOC(_EventList))

// New Event Page
const EventNew = rootWrapper(privateRouterHOC(_EventNew))

// New Online Event Page
const EventNewOnline = rootWrapper(
  privateRouterHOC(bodyWrapper(permissionDeniedByConfigWrapper(_EventNewOnline))),
)

// Zoom Response Component Page
const ZoomResponseComponent = rootWrapper(privateRouterHOC(_ZoomResponseComponent))

// Draft Event Page
const DraftEvent = rootWrapper(privateRouterHOC(_DraftEvent, { every_role: [RN_ROLES.ADMIN] }))

// Event Details Page
const EventDetails = rootWrapper(privateRouterHOC(eventWrapper(_EventDetails)))

// Event Talent Page
const EventTalent = rootWrapper(privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventTalent))))

// Event Tickets Page
const EventTickets = rootWrapper(privateRouterHOC(eventWrapper(_EventTickets)))

// Event Performance Page
const EventPerformance = rootWrapper(privateRouterHOC(eventWrapper(_EventPerformance)))

// Event Influencers Page
const EventInfluencers = rootWrapper(privateRouterHOC(eventWrapper(_EventInfluencers)))

// Event Audience Demographics Page
const EventAudienceDemographics = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventAudienceDemographics))),
)

// Event Audience Psychographics Page
const EventAudiencePsychographics = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventAudiencePsychographics))),
)

// Event Likes Page
const EventLikes = rootWrapper(privateRouterHOC(eventWrapper(_EventLikes)))

// Event Audience Music Page
const EventAudienceMusic = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventAudienceMusic))),
)

// Event Audience Music Streaming Page
const EventAudienceMusicStreaming = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventAudienceMusicStreaming))),
)

// Event Gaming Page
const EventGaming = rootWrapper(privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventGaming))))

// Event Messaging Page
const EventMessaging = rootWrapper(privateRouterHOC(eventWrapper(_EventMessaging)))

// Event Membership Page
const EventMembership = rootWrapper(privateRouterHOC(eventWrapper(_EventMembership)))

// Event Membership Create Page
const EventMembershipCreate = rootWrapper(privateRouterHOC(eventWrapper(_EventMembershipCreate)))

// Event Membership Edit Page
const EventMembershipEdit = rootWrapper(privateRouterHOC(eventWrapper(_EventMembershipEdit)))

// Event Create Template Page
const EventCreateTemplate = rootWrapper(privateRouterHOC(eventWrapper(_EventCreateTemplate)))

// Event Invitation Page
const EventInvitation = rootWrapper(privateRouterHOC(eventWrapper(_EventInvitation)))

// Multi-Entry Passes
const EventMultiEntryPasses = rootWrapper(privateRouterHOC(eventWrapper(_EventMultiEntryPasses)))

// Event FAQ Page
const EventFAQ = rootWrapper(privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventFAQ))))

// Event CheckIn Page
const EventCheckIn = rootWrapper(privateRouterHOC(eventWrapper(_EventCheckIn)))

// Event Orders Page
const EventOrders = rootWrapper(privateRouterHOC(eventWrapper(limitedStatsWrapperHOC(_EventOrders))))

// Event Traffic Page
const EventTraffic = rootWrapper(privateRouterHOC(eventWrapper(_EventTraffic)))

// Event Inventory Page
const EventInventory = rootWrapper(privateRouterHOC(eventWrapper(_EventInventory)))

// Event Guest Tickets Page
const EventGuestTickets = rootWrapper(privateRouterHOC(eventWrapper(_EventGuestTickets)))

// Event Gift Cards Page
const EventGiftCards = rootWrapper(privateRouterHOC(eventWrapper(_EventGiftCards)))

// Event Promotion Page
const EventPromotion = rootWrapper(privateRouterHOC(eventWrapper(_EventPromotion)))

// Event Buyer Location Page
const EventBuyerLocation = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventBuyerLocation))),
)

// Event Devices Page
const EventDevices = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventDevices))),
)

// Event Ad Page
const AdPage = rootWrapper(privateRouterHOC(eventWrapper(_AdPage)))

// Event Team Page
const EventTeam = rootWrapper(privateRouterHOC(eventWrapper(_EventTeam)))

// Event Budget Management Page
const EventBudgetManagement = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventBudgetManagement))),
)

// Event Credentials Page
const EventCredentials = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventCredentials))),
)

// Event PreRegistration Page
const EventPreRegistration = rootWrapper(
  privateRouterHOC(eventWrapper(permissionDeniedByConfigWrapper(_EventPreRegistration))),
)

// Event NFTs Page
const EventNFTs = rootWrapper(privateRouterHOC(eventWrapper(_EventNFT)))

// Event PreRegistration Page
const EventGiftCertificatesMangement = rootWrapper(
  privateRouterHOC(eventWrapper(_EventGiftCertificatesMangement)),
)

// Event sales tracking
const EventSalesTracking = rootWrapper(privateRouterHOC(_EventSalesTracking), {
  hideSidebar: true,
  hideFooter: false,
  hideHeader: false,
  enableHomeButton: true,
  enableIncDataFetch: false,
})

//Event Support page
const EventSupport = rootWrapper(privateRouterHOC(eventWrapper(_EventSupport)))

// Event Notes page
const EventSupportNotes = rootWrapper(privateRouterHOC(eventWrapper(_EventSupportNotes)))

// Event Predefined Invitations
const EventPredefinedInvitations = rootWrapper(privateRouterHOC(eventWrapper(_EventPredefinedInvitations)))

// Tours Page
const toursOptions = {
  hideSidebar: isRnDomain,
  hideFooter: isRnDomain,
  hideHeader: isRnDomain,
  enableIncDataFetch: !isRnDomain,
}

const ToursPage = rootWrapper(tourHideIfRnHOC(privateRouterHOC(_ToursPage)), toursOptions)

// New Tour Page
const NewTourPage = rootWrapper(tourHideIfRnHOC(privateRouterHOC(_NewTourPage)), toursOptions)

// Tour Details Page
const TourDetails = rootWrapper(tourHideIfRnHOC(privateRouterHOC(tourWrapper(_TourDetails))), toursOptions)

// Tour Team Page
const TourTeam = rootWrapper(tourHideIfRnHOC(privateRouterHOC(tourWrapper(_TourTeam))), toursOptions)

// Tour Performance Page
const TourPerformance = rootWrapper(
  tourHideIfRnHOC(privateRouterHOC(tourWrapper(_TourPerformance))),
  toursOptions,
)

// Ads Page
const AdsPage = rootWrapper(privateRouterHOC(bodyWrapper(permissionDeniedByConfigWrapper(_AdsPage))))

// Users Page
const UsersPage = rootWrapper(privateRouterHOC(_UsersPage))

// Shopify Redirect Page
const ShopifyRedirectPage = rootWrapper(privateRouterHOC(_ShopifyRedirectPage))

// Not Found Page
const NotFoundPage = rootWrapper(privateRouterHOC(_NotFoundPage), {
  hideSidebar: true,
  hideFooter: true,
  hideHeader: true,
  enableIncDataFetch: false,
})

export const ROUTES = (
  // eslint-disable-next-line react/jsx-filename-extension
  <Switch>
    <CustomRoute exact path="/" component={HomePage} />
    <CustomRoute exact path="/signin" component={LoginPage} />
    <CustomRoute exact path="/reset-password" component={ResetPasswordPage} />
    <CustomRoute exact path="/team-invitations" component={ConfirmTeamInvitationPage} />
    <Route
      exact
      path="/team-invitations/:token"
      render={props => <Redirect to={`/team-invitations?token=${props.match.params.token}`} />}
    />
    {hideWelcomeProcess ? null : <Redirect path="/sign-up" to="/welcome" />}
    {hideWelcomeProcess ? null : <CustomRoute exact path="/welcome" component={NewSignUpPage} />}
    {hideWelcomeProcess ? null : <CustomRoute exact path="/welcome/sign-in" component={NewSignInPage} />}
    {hideWelcomeProcess ? null : (
      <CustomRoute exact path="/welcome/create-account" component={NewSignUpCreateAccountPage} />
    )}
    {hideWelcomeProcess ? null : (
      <CustomRoute
        exact
        path="/welcome/create-brand"
        render={props => (
          <RedirectTo
            to={`/welcome/team-invitations`}
            component={NewSignUpCreateBrandPage}
            hasBrands
            {...props}
          />
        )}
      />
    )}
    {hideWelcomeProcess ? null : (
      <CustomRoute
        exact
        path="/welcome/team-invitations"
        render={props => (
          <RedirectTo
            to={`/welcome/payout-mode`}
            component={NewSignUpTeamInvitationsPage}
            hasInvitations
            {...props}
          />
        )}
      />
    )}
    {hideWelcomeProcess ? null : (
      <CustomRoute
        exact
        path="/welcome/payout-mode"
        render={props => <RedirectTo to={`/`} component={NewSignUpPayoutModePage} hasPayoutMode {...props} />}
      />
    )}
    <CustomRoute exact path="/sales-by-promo-code" component={Promo} />

    <CustomRoute exact path="/account" component={AccountSettingsPage} />
    <CustomRoute exact path="/brands" component={BrandsPage} />
    <CustomRoute exact path="/brands/new" component={NewBrandPage} />

    <Route exact path="/brand/:id/stripe-connect-complete" component={BrandStripeConnectComplete} />
    <Redirect exact from="/brand/:id" to="/brand/:id/details" />
    <CustomRoute exact path="/brand/:id/details" component={BrandDetails} />
    <CustomRoute exact path="/brand/:id/payments" component={BrandPayments} />
    <CustomRoute exact path="/brand/:id/demographics" component={BrandAudienceDemoGraphics} />
    <CustomRoute exact path="/brand/:id/music" component={BrandAudienceMusic} />
    <CustomRoute exact path="/brand/:id/musicstreaming" component={BrandAudienceMusicStreaming} />
    <CustomRoute exact path="/brand/:id/likes" component={BrandLikes} />
    <CustomRoute exact path="/brand/:id/team" component={BrandTeam} />
    <CustomRoute exact path="/brand/:id/sub-brands/new" component={NewSubBrandPage} />
    <CustomRoute exact path="/brand/:id/events" component={BrandEventList} />
    <CustomRoute exact path="/brand/:id/multi-entry-passes" component={EventMultiEntryPassesBrand} />
    <CustomRoute exact path="/brand/:id/customers" component={Customers} />
    <CustomRoute exact path="/brand/:id/messaging" component={BrandMessaging} />
    <CustomRoute exact path="/brand/:id/email-templates" component={BrandCreateTemplate} />

    <Redirect exact from="/event" to="events" />
    <CustomRoute exact path="/events" component={EventList} />
    <CustomRoute exact path="/events/new" component={EventNew} />
    <CustomRoute exact path="/events/new-online" component={EventNewOnline} />
    <CustomRoute exact path="/response-page" component={ZoomResponseComponent} />
    <CustomRoute exact path="/draft-events" component={DraftEvent} />

    <Redirect exact from="/event/:id" to="/event/:id/performance" />
    <CustomRoute exact path="/event/:id/details" component={EventDetails} />
    <CustomRoute exact path="/event/:id/talent" component={EventTalent} />
    <CustomRoute exact path="/event/:id/tickets" component={EventTickets} />
    <CustomRoute exact path="/event/:id/performance/:tabId?" component={EventPerformance} />
    <CustomRoute exact path="/event/:id/influencers" component={EventInfluencers} />
    <CustomRoute exact path="/event/:id/demographics" component={EventAudienceDemographics} />
    <CustomRoute exact path="/event/:id/psychographics" component={EventAudiencePsychographics} />
    <CustomRoute exact path="/event/:id/likes" component={EventLikes} />
    <CustomRoute exact path="/event/:id/music" component={EventAudienceMusic} />
    <CustomRoute exact path="/event/:id/musicstreaming" component={EventAudienceMusicStreaming} />
    <CustomRoute exact path="/event/:id/gaming" component={EventGaming} />
    <CustomRoute exact path="/event/:id/messaging" component={EventMessaging} />
    <CustomRoute exact path="/event/:id/membership" component={EventMembership} />
    <CustomRoute exact path="/event/:id/membership/create" component={EventMembershipCreate} />
    <CustomRoute exact path="/event/:id/membership/edit/:membershipId" component={EventMembershipEdit} />
    <CustomRoute exact path="/event/:id/email-templates" component={EventCreateTemplate} />
    <CustomRoute exact path="/event/:id/messaging-hidden" component={EventMessaging} />
    <CustomRoute exact path="/event/:id/invitations" component={EventInvitation} />
    <CustomRoute exact path="/event/:id/multi-entry-passes" component={EventMultiEntryPasses} />
    <CustomRoute exact path="/event/:id/faq" component={EventFAQ} />
    <CustomRoute exact path="/event/:id/checkin" component={EventCheckIn} />
    <CustomRoute exact path="/event/:id/orders" component={EventOrders} />
    <CustomRoute exact path="/event/:id/traffic/:tabId?" component={EventTraffic} />
    <CustomRoute exact path="/event/:id/traffic" component={EventTraffic} />
    <CustomRoute exact path="/event/:id/inventory" component={EventInventory} />
    <CustomRoute exact path="/event/:id/guest-tickets" component={EventGuestTickets} />
    <CustomRoute exact path="/event/:id/gift-certificates" component={EventGiftCards} />
    <CustomRoute exact path="/event/:id/promotions" component={EventPromotion} />
    <CustomRoute exact path="/event/:id/geographics" component={EventBuyerLocation} />
    <CustomRoute exact path="/event/:id/devices" component={EventDevices} />
    <CustomRoute exact path="/event/:id/musicad/:audienceItemId" component={AdPage} />
    <CustomRoute exact path="/event/:id/musicstreamingad/:audienceItemId" component={AdPage} />
    <CustomRoute exact path="/event/:id/likesad/:audienceItemId" component={AdPage} />
    <CustomRoute exact path="/event/:id/team" component={EventTeam} />
    <CustomRoute exact path="/event/:id/budgets" component={EventBudgetManagement} />
    <CustomRoute exact path="/event/:id/credentials" component={EventCredentials} />
    <CustomRoute exact path="/event/:id/pre-registration" component={EventPreRegistration} />
    <CustomRoute exact path="/event/:id/nft" component={EventNFTs} />
    <CustomRoute
      exact
      path="/event/:id/gift-certificates-management"
      component={EventGiftCertificatesMangement}
    />
    <CustomRoute exact path="/event/:id/contact" component={EventSupport} />
    <CustomRoute exact path="/event/:id/notes" component={EventSupportNotes} />
    <CustomRoute exact path="/event/:id/predefined-invitations" component={EventPredefinedInvitations} />

    <CustomRoute exact path="/track-sales/:token" component={EventSalesTracking} />

    <CustomRoute exact path="/tours" component={ToursPage} />
    <CustomRoute exact path="/tours/new" component={NewTourPage} />

    <Redirect exact from="/tour/:id" to="/tour/:id/details" />
    <CustomRoute exact path="/tour/:id/details" component={TourDetails} />
    <CustomRoute exact path="/tour/:id/team" component={TourTeam} />
    <CustomRoute exact path="/tour/:id/performance" component={TourPerformance} />

    <CustomRoute exact path="/ads" component={AdsPage} />
    <CustomRoute exact path="/users" component={UsersPage} />

    <CustomRoute exact path="/shopify/:shopifyType/redirect-url" component={ShopifyRedirectPage} />
    <CustomRoute path="*" component={NotFoundPage} />
  </Switch>
)
