import _each from 'lodash/each'

export const parsePlaceResult = (res, options = {}) => {
  const {
    isRnDomain = false,
    autoDefineVenue = true,
    useLongNames = true,
    useCountryShortName = false
  } = options

  const componentForm = {
    street_number: 'streetNumber',
    route: 'street',
    locality: 'city',
    postal_town: 'city', // UK only
    sublocality_level_1: 'city', // US partially
    administrative_area_level_1: 'state',
    country: 'country',
    postal_code: 'postalCode'
  }

  let venue = {}

  if (autoDefineVenue) {
    venue = {
      venueName: res.name,
      googlePlaceId: res.place_id,
      longitude: res.geometry.location.lng(),
      latitude: res.geometry.location.lat()
    }
  }

  const components = res.address_components || []
  _each(components, c => {
    const [type] = c.types
    const prop = componentForm[type]

    if (!type || !prop) {
      return
    }

    venue[prop] = useLongNames ? c.long_name === "Czechia" ? "Czech Republic" : c.long_name === "Türkiye" ? "Turkey" : c.long_name : c.short_name.toLowerCase()

    if (type === 'country' && useCountryShortName) {
      venue[prop] = c.short_name.toLowerCase()
    }

    if (type === 'administrative_area_level_1' && isRnDomain) {
      venue[prop] = c.short_name
    }
  })
  _each(componentForm, v => {
    venue[v] = venue[v] || ''
  })

  return venue
}
