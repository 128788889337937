import React from 'react'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _filter from 'lodash/filter'
import _clone from 'lodash/clone'
import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _find from 'lodash/find'
import { Chart } from '../../../_library/Chart'
import { RadioGroup, Radio } from '../../../_library/RadioGroup'
import SalesByTicketType from './SalesByTicketType'
import SalesByOrderDate from './SalesByOrderDate'
import SalesByTimeSlots from './SalesByTimeSlots'
import FiltersSection from './FiltersSection'
import EmptyBar from '../../../_library/EmptyBar'

export default class ReleaseBreakdownComponent extends React.Component {
  constructor(props) {
    super(props)
    const { event } = props
    const isTimeSlotEvent = event && event.flagTimeSlotsEnabled

    this.state = {
      selectedTab: isTimeSlotEvent ? 'salesByTimeSlots' : 'salesByTicketType',
      tags: [],
      onlyShowPaidTickets: false
    }

    this.initialData = null
  }

  componentDidMount() {
    const { data } = this.props
    this.updateComponent(data, false)
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    this.updateComponent(data, false, prevProps.tickets)
  }

  updateComponent = (data, isFiltering, prevPropsTickets) => {
    const { tickets, hideDateBreakdowns } = this.props
    if (!_isEqual(this.initialData, data) || !_isEqual(tickets, prevPropsTickets) || isFiltering) {
      const filteredData = this.getFilteredData(data)

      const groupedByOrderDate = _groupBy(filteredData, 'orderDate')
      const groupedByTimeSlots = _groupBy(filteredData, 'slotStartDate')
      const groupedByTicketType = _groupBy(
        filteredData,
        item => (item.slotGroupName ? item.slotGroupName : item.release_type) + item.cost + (item.depositPercent ?? '')
      )

      this.initialData = data
      this.setState({
        releaseBreakdownData: filteredData,
        groupedByTicketType,
        groupedByOrderDate: hideDateBreakdowns ? null : groupedByOrderDate,
        groupedByTimeSlots,
        tags: this.getAllTags(tickets)
      })
    }
  }

  getAllTags = (data = []) => {
    const tags_all = data.length ? _uniq(_flatten(data.map(item => (item.tags ? item.tags : [])))) : []
    return tags_all
  }

  getFilteredData = data => {
    const { tickets } = this.props
    const { filters, onlyShowPaidTickets } = this.state

    if (!(filters && filters.length) && !onlyShowPaidTickets) return data

    let filteredData = _map(_clone(data), sale => {
      const { tags } = _find(tickets, ticket => ticket.id == sale.option_matrix_record_id)
      return { ...sale, tags }
    })

    // filter by tags
    if (filters && filters.length) {
      filteredData = _filter(filteredData, item => {
        let found = true
        _map(filters, (filter, index) => {
          let found1 = 0
          switch (filter.type) {
            case 'tags':
              if (item.tags && item.tags.length) {
                _map(item.tags, t => {
                  found1 += t === filter.value ? 1 : 0
                })
              }
              break
            default:
              break
          }
          if (filter.isNot) {
            found1 = found1 > 0 ? 0 : 1
          }
          if (filter.isAnd) {
            found = found && found1 > 0
          } else {
            found = found || found1 > 0
          }
        })
        return found
      })
    }

    // filter by payment
    if (onlyShowPaidTickets) {
      filteredData = _filter(filteredData, item => parseFloat(item.cost))
    }

    return filteredData
  }

  getChartData = () => {
    const { releaseBreakdownData } = this.state
    const groupedByTicketType = _groupBy(releaseBreakdownData, item =>
      item.slotGroupName ? item.slotGroupName : item.release_type
    )
    const data_ticketType = {}
    _map(groupedByTicketType, (row, key) => {
      if (!data_ticketType[key]) {
        data_ticketType[key] = 0
      }

      _map(row, rowItem => {
        data_ticketType[key] += rowItem.num_sales ? parseInt(rowItem.num_sales) : 0
      })
    })

    return data_ticketType
  }

  onChangeOnlyShowPaidTickets = checked => {
    this.setState({ onlyShowPaidTickets: checked }, () => this.updateComponent(this.initialData, true))
  }

  onFiltersChange = filters => {
    this.setState({ filters }, () => this.updateComponent(this.initialData, true))
  }

  onChangeShowGroupsByDate = selectedTab => {
    this.setState({
      selectedTab
    })
  }

  getChildComponent = () => {
    const { event, tickets, hideDateBreakdowns } = this.props
    const { selectedTab, releaseBreakdownData, groupedByTicketType, groupedByOrderDate, groupedByTimeSlots } =
      this.state

    if (!(releaseBreakdownData && releaseBreakdownData.length)) return <EmptyBar />

    switch (selectedTab) {
      case 'salesByTicketType':
        return (
          <SalesByTicketType
            event={event}
            data={releaseBreakdownData}
            groupedByTicketType={groupedByTicketType}
            tickets={tickets}
            hideDateBreakdowns={hideDateBreakdowns}
          />
        )
      case 'salesByTimeSlots':
        return (
          <SalesByTimeSlots
            event={event}
            data={releaseBreakdownData}
            groupedByTimeSlots={groupedByTimeSlots}
          />
        )
      case 'salesByOrderDate':
        return (
          <SalesByOrderDate
            event={event}
            data={releaseBreakdownData}
            groupedByOrderDate={groupedByOrderDate}
            tickets={tickets}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { event, showPieChart = true, hideDateBreakdowns } = this.props
    const { selectedTab, tags, onlyShowPaidTickets, releaseBreakdownData = [], filters } = this.state
    const isTimeSlotEvent = event && event.flagTimeSlotsEnabled
    const hasFilters = onlyShowPaidTickets || (filters && filters.length)
    return (
      <div className="performance-by-ticket">
        <div className="table-caption">
          <img
            alt=""
            className="section-main-heading"
            src={asset('/resources/images/event/performance/sales-by-type-ico.svg')}
          />
          Sales by Ticket Type
        </div>
        {!releaseBreakdownData.length && !hasFilters ? (
          <EmptyBar />
        ) : (
          <div className="table-background">
            {showPieChart && (
              <Chart
                height="200px"
                width="200px"
                chartConfig={{
                  type: 'pie',
                  hideLegend: true,
                  displayPercentage: true,
                  data: {
                    labels: _keys(this.getChartData()),
                    datasets: [{ data: _values(this.getChartData()) }]
                  },
                  options: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
            )}
            <RadioGroup name="fruit" selectedValue={selectedTab} onChange={this.onChangeShowGroupsByDate}>
              {isTimeSlotEvent && (
                <Radio name="salesByTimeSlots" value="salesByTimeSlots" label="Sales by Time Slot" />
              )}
              <Radio name="salesByTicketType" value="salesByTicketType" label="Sales by Ticket Type" />
              {!hideDateBreakdowns && (
                <Radio name="salesByOrderDate" value="salesByOrderDate" label="Total Daily Sales" />
              )}
            </RadioGroup>
            <FiltersSection
              tags={tags}
              onlyShowPaidTickets={onlyShowPaidTickets}
              onChangeOnlyShowPaidTickets={this.onChangeOnlyShowPaidTickets}
              onFiltersChange={this.onFiltersChange}
              className="compact-select"
            />
            <div className="div-spacing-20" />
            {this.getChildComponent()}
          </div>
        )}
      </div>
    )
  }
}
