import _isNumber from 'lodash/isNumber'
import { SingularEventEmitter } from 'secure-event-emitter'

class Repeat {
  #isRunning = false
  #delay
  #emitterKey = Symbol()
  #emitter = new SingularEventEmitter(this.#emitterKey)
  #loopId = 0

  constructor(delay) {
    this.#validateDelay(delay)
    this.#delay = delay
  }

  get isRunning() {
    return this.#isRunning
  }
  get isPaused() {
    return !this.#isRunning
  }
  get delay() {
    return this.#delay
  }
  set delay(delay) {
    this.#validateDelay(delay)
  }

  on = (...args) => this.#emitter.on(...args)
  off = (...args) => this.#emitter.off(...args)

  run = () => {
    if (this.isPaused) {
      this.#isRunning = true
      this.#loop()
    }
  }

  stop = () => {
    if (this.isRunning) {
      this.#isRunning = false
      clearTimeout(this.#loopId)
    }
  }

  // PRIVATE
  #loop = () => {
    const handler = () => {
      this.#loopId = setTimeout(() => {
        this.#emitter.unlock(this.#emitterKey).emit()
        handler()
      }, this.delay)
    }

    handler()
  }

  #validateDelay = delay => {
    if (!_isNumber(delay) || delay < 0) {
      throw new TypeError('Repeat [delay] must be a valid number')
    }
  }
}

export { Repeat }
