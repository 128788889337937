import React, { useState } from 'react'
import Collapsible from 'react-collapsible'

import {
  CARD_STATUS_UNCHANGED,
  CARD_STATUS_CHANGED,
  CARD_STATUS_SAVING,
  CARD_STATUS_SAVED,
} from '../../_common/core/form_helper'

export default React.memo(props => {
  const {
    children,
    icon,
    icon2,
    className,
    title,
    closed,
    addition,
    rightAction,
    status = CARD_STATUS_UNCHANGED,
    openedClassName = '',
    triggerSibling,
  } = props

  const [open, setOpen] = useState(!closed)

  const onOpen = () => {
    setTimeout(() => {
      setOpen(true)
    }, 200)
  }

  const onClose = () => {
    setOpen(false)
  }

  const additionalClassName = className ? className : ''

  let statusClass = ''
  let label = null
  if (status === CARD_STATUS_UNCHANGED) {
    statusClass = 'card-unchanged'
  } else if (status === CARD_STATUS_CHANGED) {
    statusClass = 'card-changed'
    label = 'Make sure you save your changes!'
  } else if (status === CARD_STATUS_SAVING) {
    statusClass = 'card-saving'
  } else if (status === CARD_STATUS_SAVED) {
    statusClass = 'card-saved'
    label = 'Changes saved'
  }

  const titleObj = title ? (
    <div className={`${rightAction ? 'card-title-flex' : ''}`}>
      <div className={`card-title ${statusClass} ${rightAction ? 'small-width' : ''}`}>
        {icon && (
          <i className={`fa ${icon}`} aria-hidden="true">
            &nbsp;
          </i>
        )}
        {icon2 && (
          <i className={`fa ${icon2}`} aria-hidden="true">
            &nbsp;
          </i>
        )}
        {title}
        {label && <span className="card-title-label">{label}</span>}
        {addition}
      </div>
      {rightAction && <div className="card-title-right">{rightAction}</div>}
    </div>
  ) : null
  return (
    <Collapsible
      trigger={triggerSibling ? undefined : titleObj}
      triggerSibling={triggerSibling ? () => titleObj : undefined}
      open={!closed}
      openedClassName={`card ${additionalClassName} ${open ? `opened ${openedClassName}` : ''}`}
      className={`card ${additionalClassName} ${open ? 'opened' : ''}`}
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </Collapsible>
  )
})
