import React from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import { getTableColumns } from '../../utils/sortableTableUtils'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
} from '../../../_common/core/validation/normalizers'
import EmptyBar from '../../_library/EmptyBar'
import { showAxiosError } from '../../utils/messenger'
import { getPerfomanceData } from '../../../_common/core/http_services'

export default class PerformancePasswordBreakdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns(
      [
        { key: 'ticketType', label: 'Ticket Type' },
        { key: 'password', label: 'Password' },
        { key: 'cost', label: 'Price (excl. Fees)' },
        { key: 'numSales', label: 'No of Sales' },
        {
          key: 'revenue',
          label: 'Revenue',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      ],
      getCurrencySymbol(props.event),
    )

    this.footbarColumns = [
      { key: 'quantity', sourceKey: 'quantity' },
      {
        key: 'revenue',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'grossRevenue',
        sourceKey: 'grossRevenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    this.state = {
      passwordBreakdownData: null,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.fetchPasswordBreakdown()
    this.fetchPasswordBreakdownID = setInterval(async () => {
      this.fetchPasswordBreakdown()
    }, 10 * 1000)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.fetchPasswordBreakdownID)
  }

  fetchPasswordBreakdown = async () => {
    const { event } = this.props
    const section = 'password_breakdown'

    try {
      const res = await getPerfomanceData(event.id, section)
      const passwordBreakdownData = _get(res, section) || []
      this.handleResponse(passwordBreakdownData)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  handleResponse = (passwordBreakdownData, error) => {
    const { isLoading } = this.state

    if (this.isUnmounted) return
    isLoading && this.setState({ isLoading: false })

    if (error) {
      showAxiosError(error)
    } else {
      const updatedData = _map(passwordBreakdownData, item => ({
        ...item,
        revenue: parseInt(item.numSales) * parseFloat(item.cost) - parseFloat(item.refunded_amount),
        grossRevenue: parseInt(item.numSales) * parseFloat(item.price) - parseFloat(item.refunded_amount),
      }))

      this.setState({ passwordBreakdownData: updatedData })
    }
  }

  render() {
    const { passwordBreakdownData, isLoading } = this.state
    const isDataEmpty = _isEmpty(passwordBreakdownData)

    return (
      <div>
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/sales-by-password-ico.svg')}
          />
          Sales by Password
        </div>
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={`Hold tight! We're getting your event's details...`} />
          ) : isDataEmpty ? (
            <EmptyBar />
          ) : (
            <SortableTable
              data={passwordBreakdownData}
              tableColumns={this.tableColumns}
              calculatedColumns={[
                { for: 'quantityTotal', column: 'quantity' },
                { for: 'revenueTotal', column: 'income' },
                { for: 'grossRevenueTotal', column: 'grossRevenue' },
              ]}
              enableSort={true}
              enableCopyTable={true}
              disableMobileView={true}
              sortBy={{ column: 'ticketType', asc: true }}
              footbar={{
                label: 'Total',
                columns: this.footbarColumns,
              }}
            />
          )}
        </div>
      </div>
    )
  }
}
