import React, { useState } from 'react'

export const FieldPassword = ({ style, type, name, label, error, touched, className, ...props }) => {
  const typeIsPassword = type === 'password'
  const [showPassword, setShowPassword] = useState(false)
  const typeByToggle = typeIsPassword ? (showPassword ? 'text' : 'password') : type
  const passwordToggle = () => {
    if (props.disabled) {
      return
    }
    setShowPassword(state => !state)
  }
  return (
    <div style={style} className={`field-wrapper ${className ?? ''}`}>
      <label htmlFor={name}>{label}</label>
      <div className="input-wrapper">
        <input type={typeByToggle} name={name} {...props} />
        {error && touched && <div className="feedback-input">{error}</div>}
        {type === 'password' && (
          <div aria-hidden className="password-toggle" onClick={passwordToggle}>
            <img
              alt="hide"
              src={asset('/resources/images/eye.svg')}
              style={{ paddingLeft: 13, paddingRight: 13 }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
