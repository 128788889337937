import { ROLES } from '../../constants/roles'

export const getPermissions = permission => {
  const PERMISSION_ALL = true
  const PERMISSION_ADMIN = permission === 'admin'
  const PERMISSION_OWNER = permission === 'owner'
  const PERMISSION_PROMOTER = permission === 'promoter'
  const PERMISSION_SUPPORT = permission === ROLES.SUPPORT
  const PERMISSION_PARTIAL_SUPPORT = permission === ROLES.PARTIAL_SUPPORT
  const PERMISSION_STATS = permission === 'stats'
  const PERMISSION_LIMITED_STATS = permission === 'limited_stats'
  const PERMISSION_ONSITE = permission === 'onsite'
  const PERMISSION_CURATOR = permission === 'curator'

  return {
    PERMISSION_ALL,
    PERMISSION_ADMIN,
    PERMISSION_OWNER,
    PERMISSION_PROMOTER,
    PERMISSION_SUPPORT,
    PERMISSION_PARTIAL_SUPPORT,
    PERMISSION_STATS,
    PERMISSION_LIMITED_STATS,
    PERMISSION_ONSITE,
    PERMISSION_CURATOR,

    isAdminOrOwner() {
      return PERMISSION_ADMIN || PERMISSION_OWNER
    },

    isStatsOrLimitedStats() {
      return PERMISSION_STATS || PERMISSION_LIMITED_STATS
    }
  }
}
