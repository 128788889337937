import React from 'react'
import { Field } from 'formik'
import { DateTimePickerFieldReact } from '../../../../formik/Fields'

export const PickerSingleRow = ({ name, label }) => (<>
  {label && <div className='div-spacing-10' />}
  <div className='row'>
    <div className='col-md-6' id='ticket-form'>
      <Field
        name={name}
        label={label}
        placeholder='D MMM YYYY H:M AM'
        container='ticket-form'
        component={DateTimePickerFieldReact}
        calendarModalClass='calendar-field-inmodal'
      />
      <div className='div-spacing-10' />
    </div>
  </div>
</>)
