import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GET_TIME_SLOT_GROUPS } from '../../_common/redux/tickets/actions'
import { UPDATE_MEMBERSHIP, GET_MEMBERSHIP } from '../../_common/redux/memberships/actions'
import CreateMembershipFormWrapper from './membership/CreateMembershipForm'
import { isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'

import {
  get_event_id,
  get_event_time_slot,
  get_membership_data
} from '../../_common/core/selectors'

@withRouter
@connect(
  state => {
    const timeSlotGroups = get_event_time_slot(state)
    const eventId = get_event_id(state)
    const membershipData = get_membership_data(state)
    return {
      timeSlotGroups,
      eventId,
      membershipData,
    }
  },
  {
    UPDATE_MEMBERSHIP,
    GET_TIME_SLOT_GROUPS,
    GET_MEMBERSHIP,
  }
)
class EventMembershipEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      membershipData: {}
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.membershipData !== this.props.membershipData) {
      this.setState({ membershipData: nextProps.membershipData })
    }
  }

  componentDidMount() {
    const { GET_TIME_SLOT_GROUPS, GET_MEMBERSHIP, eventId, match: { params: { membershipId } } } = this.props
    GET_TIME_SLOT_GROUPS(eventId)
    GET_MEMBERSHIP(membershipId)
  }
  
  handleSubmit = (values) => {
    const { match: { params: { membershipId } }, UPDATE_MEMBERSHIP } = this.props
    UPDATE_MEMBERSHIP(values, membershipId).then(() => {
      this.goMembershipPage()
    })
  }

  goMembershipPage = () => {
    const { history: { push }, eventId } = this.props
    push(`/event/${eventId}/membership`)
  }

  render() {
    const { eventId, timeSlotGroups } = this.props
    const { membershipData } = this.state
    return (
      <div className="membership-create">
        <h3 className="heading_style">Edit Membership</h3>
        {!isEmpty(membershipData) &&
          <CreateMembershipFormWrapper 
            timeSlotGroups={timeSlotGroups}
            onSubmit={this.handleSubmit}
            eventId={eventId}
            membershipData={membershipData}
            onCancel={this.goMembershipPage}
          />
        }
      </div>
    )
  }
}


export default EventMembershipEdit
