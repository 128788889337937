import React, { Component } from 'react'

const asyncComponent = importComponent =>
  class extends Component {
    state = {
      component: null,
    }
    componentDidMount() {
      importComponent().then(cmp => {
        if (this.unMounted) {
          return
        }
        this.setState({ component: cmp.default })
      })
    }
    componentWillUnmount() {
      this.unMounted = true
    }

    render() {
      const C = this.state.component
      return C ? <C {...this.props} /> : null
    }
  }
export { asyncComponent }
