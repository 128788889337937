/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { enableMapSet } from 'immer'
import _get from 'lodash/get'

enableMapSet()

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { loadMessenger, loadLocalize, loadSentry, loadZendeskWidget, loadUsernap } from '../_common/core/lazy_load'
import { ENV } from './constants/env'

loadMessenger()
loadLocalize()
loadSentry()
loadZendeskWidget(ENV.TTF_PUBLIC_ZENDESK_KEY)
import './../_common/core/includeJquery'
import './../_common/core/fb'
import './../_common/core/magics'
import './../_common/core/form_helper'
import './../_common/core/gtag'
import store from './../_common/redux/store'
import 'react-perfect-scrollbar/dist/css/styles.min.css'

import './index.less'

import { ROUTES } from './routes'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MouseflowWrapper } from './MouseFlowWrapper'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ZendeskWidget } from './zendesk-button'
import { getConfig } from '../web/utils/configUtils'

const configFile = getConfig()

if (_get(configFile, 'global.behavior.displayUsersnap')) {
  loadUsernap()
} 

if (_get(configFile, 'global.behavior.hideAppcuesSurvey')) {
  document.head.insertAdjacentHTML("beforeend", `<style>iframe[aria-label="NPS Survey"] { display: none; }</style>`)
}

form_helper_reset()

ReactDOM.render(
  <>
    {_get(configFile, 'global.behavior.displayCustomZendeskButton') && <ZendeskWidget />}
    <ToastContainer
      position="top-center"
      autoClose={false}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
    />
    <Provider store={store}>
      <Router>
        <MouseflowWrapper>{ROUTES}</MouseflowWrapper>
      </Router>
    </Provider>
  </>,
  document.getElementById('app')
)

serviceWorkerRegistration.unregister()
