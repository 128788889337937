import React, { useState } from 'react'
import { BackgroundSignUp } from '../newsignup/BackgroundSignUp'
import CreateAccountForm from './CreateAccountForm'
import { Progress } from '../newsignin/Progress'
import { Steps } from './Steps'
import { NotificationError } from '../newsignin/NotificationError'
import { Titles } from '../newsignin/Titles'
import { Logo } from '../newsignin/Logo'
import modalStyle from '../../../_common/core/modalStyle'
import Modal from 'react-modal'
import Button from '../../_library/Button'
import { ENV } from '../../constants/env'
import { useMediaQuery } from '../../../_common/core/utils'
import { newCustomer, updateCustomer } from '../../../_common/core/http_services'
import { connect } from 'react-redux'
import { LOGIN } from '../../../_common/redux/auth/actions'

const CreateAccountPageConnect = ({ location: { state: savedState }, LOGIN }) => {
  const createAccountState = localStorage.getItem('createAccountState')
    ? JSON.parse(localStorage.getItem('createAccountState'))
    : undefined
  const isGoBack = createAccountState?.goBack
  const state = isGoBack ? createAccountState : savedState
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleRedirect = () => {
    window.location = ENV.API_CONSUMER
  }
  const handleSubmit = async form => {
    try {
      if (form.best_describe === '3') {
        setShow(true)
        throw new Error('Error')
      }
      if (state?.skipCreateAccount) {
        await updateCustomer({
          data: {
            attributes: {
              ...form,
              newSignupFlow: true
            }
          }
        })
        isProd() && fbq('trackCustom', 'CustomerUpdatedAccountSuccesfully', { email: form.email })
      } else {
        await newCustomer({
          data: {
            attributes: form
          }
        })
        isProd() && fbq('trackCustom', 'CustomerCreatedAccountSuccesfully', { email: form.email })
        await LOGIN({
          attributes: { username: form.email, password: form.password },
          noAppcues: true
        })
      }
      return
    } catch (e) {
      throw e
    }
  }
  const [error, setError] = useState('')
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <div className="absolute-container create-account">
      <Logo />
      <Progress width="20%" />
      <Titles text={isMobile ? 'Sign Up as an Event Organizer' : undefined} />
      <Steps
        elements={[
          {
            classNameText: 'step-text-current',
            classNameIcon: 'step-current',
            text: isMobile ? 'Account' : "Create Your Account",
            iconChildren: "1"
          },
          {
            classNameText: 'step-text-next',
            classNameIcon: 'step-next',
            text: isMobile ? 'Brand' : 'Create Your Brand',
            iconChildren: "2"
          },
          {
            classNameText: 'step-text-next',
            classNameIcon: 'step-next',
            text: isMobile ? 'Team' : 'Invite Your Team',
            iconChildren: "3"
          },
          {
            classNameText: 'step-text-next',
            classNameIcon: 'step-next',
            text: 'Payout Mode',
            iconChildren: "4"
          }
        ]}
      />
      {error ? <NotificationError error={error} setError={setError} /> : null}
      <BackgroundSignUp>
        <CreateAccountForm onSubmit={handleSubmit} {...state} />
      </BackgroundSignUp>
      <Modal
        className="modal-dialog modal-trans modal-smallest"
        style={modalStyle}
        isOpen={show}
        contentLabel="Modal"
        onRequestClose={handleClose}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-smallest">
          <div className="modal-content-smallest">
            <div className='title-modal'>To Buy or Resell tickets</div>
            <div className='description-modal'>Please go to www.ticketfairy.com</div>
            <div className='modal-footer'>
              <div className="btn-toolbar btn-toolbar-right">
                <Button className="btn btn-danger" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="btn btn-success" type="button" onClick={handleRedirect}>
                  Go to ticketfairy
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const CreateAccountPage = connect(null, { LOGIN })(CreateAccountPageConnect)

export { CreateAccountPage }
