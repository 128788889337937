import _get from 'lodash/get'

import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  selected: null,
  brands: [],
  brandsInitial: true,
  brandsUpdateTime: Date.now(),
  brandPixelData: {},
  brandPaymentData: {},
  hasBrandsFlag: false,
  pagination: {},
}

const addBrand = {
  success(state, action) {
    state.selected = _get(action, 'payload.data')
  },
}

const UPDATE_BRAND = createAsyncHandlers('UPDATE_BRAND', {
  success(state, action) {
    const brand = _get(action, 'payload.data')
    const sid = _get(state, 'selected.id', '')
    const bid = _get(brand, 'id')
    if (sid && sid === bid) {
      state.selected = brand
    }
  },
})

const FETCH_BRANDS = createAsyncHandlers('FETCH_BRANDS', {
  success(state, action) {
    const {
      brands: { data, pagination },
    } = action.payload
    state.brands = data
    state.brandsInitial = false
    state.brandsUpdateTime = Date.now()
    state.pagination = pagination
  },
})

const SET_HAS_BRANDS = createAsyncHandlers('SET_HAS_BRANDS', {
  success(state, action) {
    state.hasBrandsFlag = action.payload
  },
})

const SET_HAS_PAYOUT_MODE = createAsyncHandlers('SET_HAS_PAYOUT_MODE', {
  success(state, action) {
    state.hasPayoutModeFlag = action.payload
  },
})

const FETCH_BRAND_PIXEL_DATA = createAsyncHandlers('FETCH_BRAND_PIXEL_DATA', {
  success(state, action) {
    const { attributes } = action.payload
    state.brandPixelData = attributes
  },
})

const FETCH_BRAND_PAYMENT_DATA = createAsyncHandlers('FETCH_BRAND_PAYMENT_DATA', {
  success(state, action) {
    const { brandPaymentData } = action.payload
    state.brandPaymentData = brandPaymentData
  },
})

const APPLY_PAYMENT_METHOD = createAsyncHandlers('APPLY_PAYMENT_METHOD', {
  success(state, action) {},
})

const FETCH_BRAND = createAsyncHandlers('FETCH_BRAND', addBrand)
const CREATE_BRAND = createAsyncHandlers('CREATE_BRAND', addBrand)
const UPDATE_BRAND_PAYMENT = createAsyncHandlers('UPDATE_BRAND_PAYMENT', addBrand)

export default handleActions(
  {
    ...CREATE_BRAND,
    ...UPDATE_BRAND,
    ...UPDATE_BRAND_PAYMENT,
    ...FETCH_BRAND,
    ...FETCH_BRANDS,
    ...FETCH_BRAND_PIXEL_DATA,
    ...FETCH_BRAND_PAYMENT_DATA,
    ...APPLY_PAYMENT_METHOD,
    ...SET_HAS_BRANDS,
    ...SET_HAS_PAYOUT_MODE,
  },
  initialState,
)
