import React from 'react'
import { Field } from 'formik'

import Card from '../../../_library/Card'

import {
  TextAreaField
} from '../../../formik/Fields'

export default class UserReferenceDescription extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    const {
      cardsStatus
    } = this.props

    return (
      <div className="alternative-view-field">
        <div className="row">
          <div className="col-xs-12 col-12 text-holders">
            <Field
              name='referenceDescription'
              label='Alternative View Purpose (for your internal reference - this will not be visible on the event page)'
              size="small"
              component={TextAreaField}
            />
          </div>
          <div/>
        </div>
      </div>
    )
  }
}
