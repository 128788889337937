import React from 'react'
import { connect } from 'react-redux'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'

import Notifications from '../_library/notifications/Notifications'
import EmptyBar from '../_library/EmptyBar'
import Currency from '../_library/Currency'
import DateLabel from '../_library/DateLabel'
import LoadingBar from '../_library/LoadingBar'
import { FETCH_ADS } from '../../_common/redux/ads/actions'

import { JSONDatatable, TYPE_FROM_ARRAY, DATATABLE } from '../_library/JSONDatatable'
import { getTitle } from '../utils/getTitle'

@connect(
  state => ({
    user: state.auth.user,
    loading: state.loading.has('FETCH_ADS'),
    ads: state.ads.ads,
  }),
  { FETCH_ADS },
)
export default class AdsPage extends React.Component {
  constructor(props) {
    super(props)

    const configDocTitle = _get(props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
  }

  componentDidMount() {
    const { user, FETCH_ADS } = this.props
    FETCH_ADS(user.id)
  }

  getSortedRows(rows_filtered, sort) {
    if (sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch (sort.index) {
        case 1:
          rows_filtered = _orderBy(rows_filtered, t => t.account && t.account.name, dir)
          break
        case 2:
          rows_filtered = _orderBy(rows_filtered, t => t.ad.name, dir)
          break
        case 3:
          rows_filtered = _orderBy(rows_filtered, t => t.recordCreated, dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  getTableData(datatable, rows_filtered, sort) {
    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: '', sort: false },
        { title: 'Account', sort: true },
        { title: 'Name', sort: true },
        { title: 'Created At', sort: true },
      ],
      sort,
    )

    return rows_filtered.length !== 0 ? (
      <table className="table ads-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.map((row, index) => {
            if (!row.isDetailRow) {
              return (
                <tr key={index} className={row.isExpanded ? 'row-expanded ' : ''}>
                  <td
                    aria-hidden
                    className="toggleExpand"
                    onClick={datatable.onClickDetail.bind(datatable, row.id)}
                  >
                    <div className={row.isExpanded ? 'expandIcon expanded' : 'expandIcon'}>
                      <i className={row.isExpanded ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'} />
                    </div>
                  </td>
                  <td>{row.account && row.account.name}</td>
                  <td>{row.ad && row.ad.name}</td>
                  <td>
                    <DateLabel className="created-at" value={row.recordCreated} format="LLL" />
                  </td>
                </tr>
              )
            }
            return (
              <tr key={index} className="row-details">
                <td colSpan={4}>
                  <div className="ad-details">
                    <div className="ad-details-section">
                      <div className="ad-details-section-title">Account</div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">ID</div>
                        <div className="ad-details-section-item-value">{row.accountId}</div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Name</div>
                        <div className="ad-details-section-item-value">{row.account && row.account.name}</div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Currency</div>
                        <div className="ad-details-section-item-value">
                          {row.account && row.account.currency}
                        </div>
                      </div>
                    </div>
                    <div className="ad-details-section">
                      <div className="ad-details-section-title">Creative</div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">ID</div>
                        <div className="ad-details-section-item-value">{row.adCreativeId}</div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Name</div>
                        <div className="ad-details-section-item-value">
                          {row.adCreative &&
                            row.adCreative.object_story_spec &&
                            row.adCreative.object_story_spec.link_data &&
                            row.adCreative.object_story_spec.link_data.name}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Message</div>
                        <div className="ad-details-section-item-value">
                          {row.adCreative &&
                            row.adCreative.object_story_spec &&
                            row.adCreative.object_story_spec.link_data &&
                            row.adCreative.object_story_spec.link_data.message}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Description</div>
                        <div className="ad-details-section-item-value">
                          {row.adCreative &&
                            row.adCreative.object_story_spec &&
                            row.adCreative.object_story_spec.link_data &&
                            row.adCreative.object_story_spec.link_data.description}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Link</div>
                        <div className="ad-details-section-item-value">
                          {row.adCreative &&
                            row.adCreative.object_story_spec &&
                            row.adCreative.object_story_spec.link_data &&
                            row.adCreative.object_story_spec.link_data.link}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Image</div>
                        <div className="ad-details-section-item-value">
                          {row.adCreative &&
                            row.adCreative.object_story_spec &&
                            row.adCreative.object_story_spec.link_data &&
                            row.adCreative.object_story_spec.link_data.picture && (
                            <img
                              alt="no data"
                              src={
                                row.adCreative &&
                                  row.adCreative.object_story_spec &&
                                  row.adCreative.object_story_spec.link_data &&
                                  row.adCreative.object_story_spec.link_data.picture
                              }
                              width="100"
                            />
                          )}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Page</div>
                        <div className="ad-details-section-item-value">{row.page && row.page.name}</div>
                      </div>
                    </div>
                    <div className="ad-details-section">
                      <div className="ad-details-section-title">Campaign</div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">ID</div>
                        <div className="ad-details-section-item-value">{row.campaignId}</div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Name</div>
                        <div className="ad-details-section-item-value">
                          {row.campaign && row.campaign.name}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Objective</div>
                        <div className="ad-details-section-item-value">
                          {row.campaign && row.campaign.objective}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Start Time</div>
                        <div className="ad-details-section-item-value">
                          <DateLabel value={row.campaign && row.campaign.start_time} format="LLL" />
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">End Time</div>
                        <div className="ad-details-section-item-value">
                          <DateLabel value={row.campaign && row.campaign.end_time} format="LLL" />
                        </div>
                      </div>
                    </div>
                    <div className="ad-details-section">
                      <div className="ad-details-section-title">Audience</div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Page</div>
                        <div className="ad-details-section-item-value">
                          {row.audience && row.audience.page_name}
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Users</div>
                        <div className="ad-details-section-item-value">
                          {row.audience.user_ids.length} Users
                        </div>
                      </div>
                    </div>
                    <div className="ad-details-section">
                      <div className="ad-details-section-title">Ad Set</div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Name</div>
                        <div className="ad-details-section-item-value">{row.adSet && row.adSet.name}</div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Daily Budget</div>
                        <div className="ad-details-section-item-value">
                          <Currency
                            code={(row.account && row.account.currency) || 'USD'}
                            value={row.adSet && row.adSet.daily_budget}
                          />
                        </div>
                      </div>
                      <div className="ad-details-section-item">
                        <div className="ad-details-section-item-label">Bid Amount</div>
                        <div className="ad-details-section-item-value">
                          {row.adSet.is_autobid && <span>Automatic</span>}
                          {!row.adSet.is_autobid && (
                            <Currency
                              code={(row.account && row.account.currency) || 'USD'}
                              value={row.adSet && row.adSet.bid_amount}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    ) : (
      <EmptyBar />
    )
  }

  render() {
    const { loading, ads } = this.props

    return (
      <div>
        <Notifications />
        <div>
          <div className="body-panel-header">
            <div className="left">
              <div className="title">Facebook Advertisements</div>
            </div>
            <div className="right" />
          </div>
          <div className="body-panel-spacing" />
          <div className="body-panel-content">
            {loading && <LoadingBar title={"Hold tight! We're getting your facebook advertisements ..."} />}
            {!loading && (
              <JSONDatatable
                type={TYPE_FROM_ARRAY}
                data={ads}
                sort={{ index: 3, asc: false }}
                getSortedRows={this.getSortedRows}
                getTableData={this.getTableData}
              >
                <div ref={DATATABLE} />
              </JSONDatatable>
            )}
          </div>
        </div>
      </div>
    )
  }
}
