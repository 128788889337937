import React from 'react'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import _clone from 'lodash/clone'
import _get from 'lodash/get'
import _some from 'lodash/some'
import Button from '../Button'
import { TableIconLabels } from './TableIconLabels'
import { formatDay } from '../../../_common/core/validation'

export const getSortedRows = (data, sort, calculatedColumns) => {
  if (sort) {
    const hasCalculatedColumns = calculatedColumns && !!calculatedColumns.length
    const dir = sort.asc ? 'asc' : 'desc'
    data = _orderBy(
      _filter(data, item => item.type !== 'detailRow'),
      d =>
        !!Number(d[sort.column])
          ? parseFloat(d[sort.column])
          : _isString(d[sort.column])
          ? d[sort.column].toLowerCase()
          : d[sort.column],
      dir,
    )

    let updatedData = _clone(data)
    if (hasCalculatedColumns) {
      _map(calculatedColumns, cColumn => {
        let value = 0
        updatedData = _map(updatedData, item => {
          value += Number(item[cColumn.column]) ? Number(item[cColumn.column]) : 0
          return {
            ...item,
            [cColumn.for]: value,
          }
        })
      })
    }

    return hasCalculatedColumns ? updatedData : data
  }
  return data
}

export const getRowValue = (column, props) => {
  const { data, actions, statuses, enableDnd, expandedRowId, loadingRowData } = props
  switch (column.key) {
    case 'actions':
      const isFunction = typeof actions === 'function'
      return _map(isFunction ? actions(data) : actions, action => {
        if (action.hide) {
          if (action.hide === 'boolean') return
          if (action.hide(data)) return
        }

        const isLoading =
          typeof action.isLoading === 'boolean'
            ? action.isLoading
            : action.isLoading
            ? action.isLoading(data)
            : null

        const isDisabled =
          typeof action.disabled === 'boolean'
            ? action.disabled
            : action.disabled
            ? action.disabled(data)
            : null

        const class_name =
          typeof action.className === 'string'
            ? action.className + `${isDisabled ? ' disabled' : ''} ${isLoading ? 'loading' : ''}`
            : action.className(data) + `${isDisabled ? ' disabled' : ''} ${isLoading ? ' loading' : ''}`

        const btnIcon =
          typeof action.icon === 'string'
            ? action.icon
            : typeof action.icon === 'function'
            ? action.icon(data)
            : null
        const btnLabel =
          typeof action.label === 'string' ? action.label : action.label ? action.label(data) : null

        return (
          // eslint-disable-next-line react/jsx-filename-extension
          <Button
            key={action.label}
            loading={isLoading}
            disabled={isDisabled}
            className={class_name}
            type="button"
            onClick={() => action.onClick(data)}
          >
            {action.icon && btnIcon ? <i className={btnIcon} /> : action.icon}
            {btnLabel}
          </Button>
        )
      })
    case 'clipboard':
      return
    case 'status':
      if (!column.showStatusName) {
        const statusesProps = {}
        const statusLabels = {}
        _map(statuses, item => {
          const key = _get(item, 'key') || item
          statusesProps[key] = data[key]
          statusLabels[key] = {
            activeLabel: _get(item, 'activeLabel'),
            inactiveLabel: _get(item, 'inactiveLabel'),
          }
        })
        return <TableIconLabels {...statusesProps} hideDragIcon={!enableDnd} statusLabels={statusLabels} />
      }
      return column.normalizer(data[column.key])
    case 'expand':
      if (data.type === 'detailRow') return
      const isExpanded = data.id === expandedRowId
      const isLoadingRow = loadingRowData[data.id]

      let class_name = 'fa fa-plus-square-o'
      if (isLoadingRow) {
        class_name = 'fa fa-spinner fa-spin'
      } else if (isExpanded) {
        class_name = 'fa fa-minus-square-o'
      }

      return (
        <div className={`expand-icon ${isExpanded || isLoadingRow ? 'expanded' : ''} `}>
          <i className={class_name} />
        </div>
      )
    default:
      return column.normalizer(data[column.key])
  }
}

export const getFilteredRows = ({ searchValue, searchBy, data }) => {
  if (!searchValue) return data

  return _filter(data, item =>
    _some(searchBy, searchKey => {
      const search = searchValue.toLocaleLowerCase()
      let tableNodeValue = `${item[searchKey]}`.toLocaleLowerCase()
      if (['date', 'Date'].some(item => searchKey.includes(item))) {
        tableNodeValue = `${formatDay(item[searchKey])}`.toLocaleLowerCase()
      }
      return tableNodeValue.includes(search)
    }),
  )
}
