import React from 'react'

import Card from '../../../_library/Card'
import TagsField from '../../../_library/TagsField'

export default class EventTags extends React.PureComponent {
  handleChangeTags = tags => {
    const { updateField } = this.props
    updateField('tags', tags)
  }

  render() {
    const { values, cardsStatus, isRnDomain } = this.props
    return (
      <Card icon={'fa-tags'} title={isRnDomain ? 'Artists' : 'Tags'} status={cardsStatus && cardsStatus.tags}>
        <TagsField
          ref="tag"
          value={values.tags}
          onChange={this.handleChangeTags}
          placeholder={isRnDomain ? 'Add an Artist' : ''}
        />
      </Card>
    )
  }
}
