import React, { useState, useEffect, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _values from 'lodash/values'
import _filter from 'lodash/filter'
import _upperFirst from 'lodash/upperFirst'
import SeatMapView, { SEAT_STATUSES } from 'tf-seat-map-view'
import { SimpleField, SelectField } from '../../formik/Fields'
import Button from '../../_library/Button'
import SortableTable from '../../_library/SortableTable'
import { showAxiosError, showSuccessMessage } from '../../utils/messenger'
import Card from '../../_library/Card'
import { getSeatMapData, getSeatMapStatuses } from '../../../_common/core/http_services/seatmap'
import LoadingBar from '../../_library/LoadingBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import {
  createPredefinedInvitation,
  deletePredefinedInvitation,
  getPredefinedInvitations,
  resendPredefinedInvitation,
  getPromoters,
} from '../../../_common/core/http_services'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'

const PredefinedInvitations = props => {
  const eventId = _get(props, 'match.params.id')
  const seatMapWidth = window.innerWidth < 450 ? window.innerWidth - 66 : Math.min(window.innerWidth / 2, 600)
  const [selectedSeats, setSelectedSeats] = useState([])
  const [seatMapData, setSeatMapData] = useState(null)
  const [ticketTypeTireRelationsArray, setTicketTypeTireRelationsArray] = useState(null)
  const [promotersData, setPromotersData] = useState(null)
  const [predefinedData, setPredefinedData] = useState([])
  const [detailRows, setDetailRows] = useState([])
  const [seatsStatuses, setSeatsStatuses] = useState(null)
  const [isLoadingStatuses, setIsLoadingStatuses] = useState(true)
  const [isDeletingInvitationData, setIsDeletingInvitationData] = useState({})
  const [isResendingInvitation, setIsResendingInvitation] = useState({})

  useEffect(() => {
    // Fetch seat-map-data
    const fetchSeatMapData = async () => {
      try {
        const response = await getSeatMapData(eventId)

        const ticketTypeTireRelationsArray = []
        _forEach(response.ticketTypeTierRelations, (item, key) => {
          ticketTypeTireRelationsArray.push(..._values(item))
        })

        setTicketTypeTireRelationsArray(ticketTypeTireRelationsArray)
        setSeatMapData(response)
      } catch (error) {
        showAxiosError(error)
      }
    }

    // Fetch Promoters data
    const fetchPromotersData = async () => {
      try {
        const response = await getPromoters(eventId)
        setPromotersData(
          _map(
            _filter(response.data, promoter => !promoter.email.includes('@theticketfairy.com')),
            item => ({
              ...item,
              fullName: `${item.firstName} ${item.lastName} (${item.email})`,
            }),
          ),
        )
      } catch (error) {
        showAxiosError(error)
      }
    }

    // Fetch seats statuses
    const fetchSeatMapStatuses = async () => {
      try {
        const response = await getSeatMapStatuses(eventId)
        setSeatsStatuses(response)
      } catch (error) {
        showAxiosError(error)
      } finally {
        setIsLoadingStatuses(false)
      }
    }

    fetchSeatMapData()
    fetchPromotersData()
    fetchSeatMapStatuses()

    setInterval(() => {
      fetchSeatMapStatuses()
    }, 5000)
  }, [eventId])

  // Fetch predefined table’s data
  const fetchPredefinedData = useCallback(async () => {
    try {
      const response = await getPredefinedInvitations(eventId)
      setPredefinedData(
        _map(response, item => ({
          ...item,
          statusText: _upperFirst(item.status || ''),
          enableDelete: item.can_delete_invitation,
          enableResend: item.can_resend_invitation,
          seatTicket: _map(item.seats, seatItem => seatItem.name).join(', '),
          recipientEmail: item.email,
          promoterEmail: _find(promotersData, promoterItem => promoterItem.promoterId === item.promoterId)
            ?.email,
        })),
      )

      const detailRows = _map(response, item => {
        const data = _map(item.seats, (seatItem, index) => ({
          id: item.id,
          statusText: _upperFirst(seatItem.status || ''),
          name: seatItem.name,
          seatId: seatItem.id,
          enableDelete: seatItem.can_delete_invitation,
        }))

        return {
          id: item.id,
          type: 'detailRow',
          component: (
            <SortableTable
              e2e_test_id={`predefined-${item.id}`}
              data={data}
              tableColumns={getTableColumns([
                { key: 'name', label: 'Seat Ticket' },
                { key: 'statusText', label: 'Status' },
              ])}
              enableSort={true}
              enableCopyTable={false}
              disableMobileView={true}
              className="child-table"
              actions={[
                {
                  label: 'Delete',
                  className: 'btn btn-primary btn-danger',
                  disabled: rowData => !rowData.enableDelete,
                  onClick: rowData => deleteRecipient(rowData),
                },
              ]}
            />
          ),
        }
      })
      setDetailRows(detailRows)
    } catch (error) {
      showAxiosError(error)
    }
  }, [eventId, promotersData])

  useEffect(() => {
    if (promotersData) {
      fetchPredefinedData()
    }
  }, [fetchPredefinedData, promotersData])

  const handleFormSubmit = async (values, formikActions) => {
    try {
      const response = await createPredefinedInvitation(eventId, {
        email: values.recipientEmail,
        tierId: selectedSeats[0].tierId,
        promoter_id: values.promoterId,
        seats: _map(selectedSeats, item => item.seatId),
      })
      showSuccessMessage(response.message)
      setSelectedSeats([])
      fetchPredefinedData()
    } catch (error) {
      showAxiosError(error)
    } finally {
      formikActions.setSubmitting(false)
      formikActions.resetForm()
    }
  }

  const deleteRecipient = async rowData => {
    try {
      setIsDeletingInvitationData({ [rowData.id]: true })
      const response = await deletePredefinedInvitation(eventId, rowData.id, rowData.seatId)
      showSuccessMessage(response.message)
      fetchPredefinedData()
    } catch (error) {
      showAxiosError(error)
    } finally {
      setIsDeletingInvitationData({ [rowData.id]: false })
    }
  }

  const resendInvitation = async rowData => {
    try {
      setIsResendingInvitation({ [rowData.id]: true })
      const response = await resendPredefinedInvitation(eventId, rowData.id)
      showSuccessMessage(response.message)
    } catch (error) {
      showAxiosError(error)
    } finally {
      setIsResendingInvitation({ [rowData.id]: false })
    }
  }

  return (
    <div className="predefined-invitations">
      <Card title={'Send Predefined Invitations'}>
        {!isLoadingStatuses && Boolean(seatMapData) ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 40 }}>
            <SeatMapView
              disabled={isLoadingStatuses}
              events={{
                onSeatClick: e => {
                  const { seatId } = e.seat
                  if (e.seat.status === SEAT_STATUSES.AVAILABLE) {
                    const isSeatSelected = _find(selectedSeats, item => item.seatId === seatId)
                    if (isSeatSelected) {
                      e.seat.unselect(e.seat.label)
                      setSelectedSeats(_filter(selectedSeats, item => item.seatId !== seatId))
                    } else {
                      e.seat.select(e.seat.label)
                      selectedSeats.push(e.seat)
                      setSelectedSeats([...selectedSeats])
                    }
                  }
                },
              }}
              loading={isLoadingStatuses}
              seatData={JSON.parse(seatMapData.seatData)}
              statuses={seatsStatuses || {}}
              width={seatMapWidth}
              height={seatMapWidth}
              isSelectionOn={true}
              ticketTypeTireRelationsArray={ticketTypeTireRelationsArray}
              isTableMap={true}
            />
            <div style={{ width: '100%', minWidth: '280px', maxWidth: 500 }}>
              <Formik
                initialValues={{ recipientEmail: '', promoterId: '' }}
                onSubmit={(values, formikActions) => handleFormSubmit(values, formikActions)}
              >
                {({ isSubmitting, isValid }) => (
                  <Form>
                    <Field
                      name="recipientEmail"
                      component={SimpleField}
                      type="email"
                      placeholder="Recipient Email"
                      validate={combineValidators(requiredValidator(), isEmailValidator())}
                    />
                    <Field
                      name="promoterId"
                      component={SelectField}
                      options={[
                        {
                          label: 'Select Promoter (Optional)',
                          value: '',
                        },
                        ..._map(promotersData, item => ({
                          label: item.fullName,
                          value: item.promoterId,
                        })),
                      ]}
                      placeholder="Promoter Email"
                    />
                    <div className="text-center">
                      <Button
                        type="submit"
                        disabled={!Boolean(selectedSeats?.length) || !isValid}
                        loading={isSubmitting}
                        className="btn btn-primary btn-success"
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <LoadingBar />
        )}
      </Card>
      <Card title="Recipients">
        <SortableTable
          data={predefinedData}
          tableColumns={getTableColumns([
            { key: 'seatTicket', label: 'Seat Ticket' },
            { key: 'recipientEmail', label: 'Recipient Email' },
            { key: 'promoterEmail', label: 'Promoter Email', normalizer: value => value || 'No Promoter' },
            { key: 'statusText', label: 'Status' },
          ])}
          actions={[
            {
              label: 'Resend',
              className: 'btn btn-primary',
              icon: (
                <img
                  alt="nodata"
                  className="default-icosize"
                  src={asset('/resources/images/resend-ico.svg')}
                />
              ),
              disabled: rowData => !rowData.enableResend,
              isLoading: rowData => isResendingInvitation[rowData.id],
              onClick: rowData => resendInvitation(rowData),
            },
            {
              label: 'Delete',
              className: 'btn btn-primary btn-danger',
              disabled: rowData => !rowData.enableDelete,
              isLoading: rowData => isDeletingInvitationData[rowData.id],
              onClick: rowData => deleteRecipient(rowData),
            },
          ]}
          disableMobileView={true}
          enableSort={true}
          detailsRows={detailRows}
          enableCopyTable={false}
        />
      </Card>
    </div>
  )
}

export default PredefinedInvitations
