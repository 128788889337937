import React, { Component } from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import { Formik, Form, Field } from 'formik'
import Button from '../../_library/Button'
import FileUploader from '../../_library/FileUploader'
import { SimpleField, SelectField, MultiCheckboxField } from '../../formik/Fields'
import {
  // field level validators
  requiredValidator
} from '../../../_common/core/validation'

const typeOptions = [
  { value: '', label: 'Select' },
  { value: 'wristband', label: 'Wristband' },
  { value: 'badge', label: 'Badge' }
]

class CredentialModal extends Component {
  constructor(props) {
    super(props)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
  }
  renderInitialValues() {
    const { formInitialValues = {} } = this.props

    const {
      name = '',
      code = '',
      image = '',
      type = '',
      addon = [],
      // excludedAddon=[],
      ticketType = [],
      enabled = '0'
    } = formInitialValues
    //console.log(initialValues,'initial')
    return {
      name,
      code,
      image,
      type,
      addon,
      //excludedAddon,
      ticketType,
      enabled
    }
  }
  handleChangeImageURL(img, updateField) {
    updateField('image', img)
  }
  onCheckboxChange(value, name, updateField) {
    updateField(name, value)
  }
  render() {
    const {
      isOpen,
      header,
      acceptLabel,
      cancelLabel,
      handleSubmit,
      handleCancel,
      ticketTypesOptions,
      addOnsOptions
    } = this.props
    return (
      <Modal
        className="modal-dialog modal-trans credential_modal"
        style={{ ...modalStyle, overlay: { ...modalStyle.overlay } }}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={handleCancel}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{header || ''}</p>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={this.renderInitialValues()}
                  validate={values => {
                    const errors = {}
                    const image = values.image || ''
                    if (requiredValidator()(image)) {
                      errors.image = requiredValidator()(image)
                    }
                    return errors
                  }}
                  onSubmit={(values, actions) => {
                    const { setFieldValue, setSubmitting, resetForm } = actions
                    handleSubmit(values).finally(() => {
                      resetForm()
                      setSubmitting(false)
                    })
                  }}
                  render={({ values, isSubmitting, errors, touched, setFieldValue }) => (
                    <Form autoComplete="off">
                      <div className="row">
                        <div className="col-xs-6">
                          <div className="col-xs-12">
                            <Field
                              name="name"
                              label="Name"
                              component={SimpleField}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="col-xs-12">
                            <Field name="code" label="Code" component={SimpleField} />
                          </div>
                          <div className="col-xs-12">
                            <Field
                              name="type"
                              label="Type"
                              component={SelectField}
                              options={typeOptions}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="col-xs-12">
                            <FileUploader
                              id="credentialsImg"
                              label={'Credential Image'}
                              filetype="image"
                              value={values.image}
                              onChange={img => this.handleChangeImageURL(img, setFieldValue)}
                            />
                            {!!errors.image && !!touched.image && (
                              <div className="credentials_error_img">{errors.image}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="col-xs-12">
                            <span className="credentials_sub_title">Limit To Certain Ticket Types Only</span>
                            {!ticketTypesOptions.length && <span>{'No tickets available'}</span>}
                            <Field
                              name="ticketType"
                              value={values.ticketType}
                              label="Ticket Types"
                              component={MultiCheckboxField}
                              options={ticketTypesOptions}
                            />
                          </div>
                          <div className="div-spacing-20" />
                          <div className="col-xs-12">
                            <span className="credentials_sub_title">Limit To Certain Add-Ons Only</span>
                            {!addOnsOptions.length && <span>{'No add-ons available'}</span>}
                            <Field
                              name="addon"
                              value={values.addon}
                              label="Add-Ons"
                              component={MultiCheckboxField}
                              options={addOnsOptions}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer credential_modal_action_buttons">
                        <div className="btn-toolbar btn-toolbar-right">
                          <Button className="btn btn-success btn-shadow" type="submit" loading={isSubmitting}>
                            Save
                          </Button>
                          <Button className="btn btn-cancel btn-shadow" type="button" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default CredentialModal
