import React from 'react'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

import LoadingBar from '../../../_library/LoadingBar'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import Summary from './Summary'
import IndvidualPromoter from './IndividualPromoter'
import { showAxiosError } from '../../../utils/messenger'

const AUTO_REFRESH = 10 * 1000

export default class PerformancePromoterSales extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initialData = null
    this.state = {
      isLoadingPromoterSales: true,
    }
  }

  componentDidMount() {
    this.fetchPromoterSales()
    this.fetchId = setInterval(async () => {
      this.fetchPromoterSales()
    }, AUTO_REFRESH)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.fetchId)
  }

  fetchPromoterSales = async () => {
    const { event } = this.props
    const section = 'promoter_sales_breakdown'

    try {
      const data = await getPerfomanceData(event.id, section)
      const promoterSalesData = _get(data, section) || []
      this.handleResponse(promoterSalesData)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  handleResponse = (promoterSalesData, error) => {
    const { isLoadingPromoterSales } = this.state

    if (this.isUnmounted) return
    isLoadingPromoterSales &&
      this.setState({
        isLoadingPromoterSales: false,
      })

    if (error) {
      showAxiosError(error)
    } else {
      if (!_isEqual(this.initialData, promoterSalesData)) {
        this.initialData = promoterSalesData
        this.setState(() => ({ promoterSalesData }))
      }
    }
  }

  render() {
    const { event } = this.props
    const { isLoadingPromoterSales, promoterSalesData = [] } = this.state

    return (
      <div>
        <div className="table-caption">
          <img
            alt="nodataimage"
            className="section-main-heading"
            src={asset('/resources/images/event/performance/sales-by-promoter-ico.svg')}
          />
          Sales By Promoter
        </div>
        {isLoadingPromoterSales ? (
          <LoadingBar />
        ) : (
          <div className="table-background">
            <Summary event={event} promoterSalesData={promoterSalesData} />
            <IndvidualPromoter event={event} promoterSalesData={promoterSalesData} />
          </div>
        )}
      </div>
    )
  }
}
