import React from 'react'
import { Element } from 'react-scroll'
import { Field } from 'formik'

import Card from '../../../_library/Card'

import {
  SimpleField,
  DateTimePickerFieldReact
} from '../../../formik/Fields'


import {
  requiredValidator,
  maxLengthValidator,

  combineValidators,
} from '../../../../_common/core/validation'

import { AltViewCheckboxField } from './AltViewCheckboxField'

export default class EventDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      eventUrlCopied: false
    }
  }
  copyEventUrlOut = e => {
    if (this.state.eventUrlCopied) {
      setTimeout(() => {
        this.setState({ eventUrlCopied:false })
      }, 500)
    }
  }
  copyEventUrlAfter = () => {
    this.setState({ eventUrlCopied:true })
  }
  render() {
    const {
      event,
      cardsStatus,
      values,
      isNew,
      isRnDomain
    } = this.props
    return (
      <Card
        closed={false}
        icon={'fa-info'}
        title={'Event Details'}
        className="create-event event-detail-checks"
      >
        <div className="row checkbox-row">
          <div className="col-xs-12 col-12 text-holders">
            <div className="row">
              <div className="col-xs-1 col-1 left-column">
                <Field name="titleCheck" event={event} values={values} component={AltViewCheckboxField} />
              </div>
              <div className={'col-xs-11 col-11 text-holders right-column'+(!values.titleCheck ? ' disable-element' : '')}>
                <Field
                  name='title'
                  label='Event Name'
                  size="large"
                  component={SimpleField}
                  validate={values.titleCheck?combineValidators(requiredValidator(), maxLengthValidator(undefined, 65)):null}
                />
              </div>
            </div>
          </div>
          <div/>
          <div/>
        </div>
        <Element name="scroll-to-date" />
        <div className="div-spacing-20" />
        <div className="row checkbox-row">
          <div className="col-xs-1 col-1 left-column">
            <Field name="dateCheck" event={event} values={values} component={AltViewCheckboxField} />
          </div>
          <div 
            className={'col-xs-11 col-11 text-holders right-column'+(!values.dateCheck ? ' disable-element' : '')} 
            style={{ position: 'static' }}
          >
            <div className="row">
              <div className="col-xs-12 col-12" style={{ position: 'static' }}>
                <Field
                  name="startDate"
                  label="Event Start Date"
                  placeholder="D MMM YYYY H:M AM"
                  inModal={true}
                  container="alternative-view"
                  component={DateTimePickerFieldReact}
                  validate={values.dateCheck?requiredValidator():null}
                />
              </div>
              <div className="col-xs-12 col-12" style={{ position: 'static' }}>
                <Field
                  name="endDate"
                  label="Event End Date"
                  placeholder="D MMM YYYY H:M AM"
                  inModal={true}
                  container="alternative-view"
                  component={DateTimePickerFieldReact}
                  validate={values.dateCheck?requiredValidator():null}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}
