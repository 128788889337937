import React from 'react'

import Clipboard from '../../../_library/Clipboard'
import DownloadQrCodeButton from '../../../_library/DownloadQrCodeButton'

const TicketHolderCard = ({ row, link, event }) => (
  <div className="inline ticketholder_owner">
    <div className="inner_avatar" />
    <h4 className="inline">
      <img alt='' src={asset('/resources/images/system_icons/user-ico.svg')} className="ico-show-small" />
      {row.firstName} {row.lastName}
    </h4>
    <span className="show_emailico">
      <img alt='' src={asset('/resources/images/system_icons/messaging.svg')} className="ico-show" />
      {row.email}
    </span>
    <div className="show_linkico individual_link_copy_field">
      <img alt='' src={asset('/resources/images/system_icons/links.svg')} className="ico-show-small" />
      Promoter Link: {link}
      <Clipboard text={link} containerStyle={{ marginLeft: '10px' }} />
    </div>
    <div className="promoter_link_qr_code">
      <DownloadQrCodeButton
        label="Promoter Link QR Code"
        className="btn btn-lightpurple"
        value={link}
        size={192}
        id={`promoter-qr-code-${event.id}-${row.promoterId}`}
      />
    </div>
  </div>
)

export default TicketHolderCard
