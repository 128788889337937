import React from 'react'
import moment from 'moment'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'

import { FORMAT } from '../../../../_library/DatesPicker/utils'
import Chip from '../../../../_library/Chip'
import { generateTimeSlots, removeDates, getGroupedTimeSlots, formatDate } from './utils/generateTimeSlots'
import TimeSlotsCreateModal from './TimeSlotsCreateModal'
import TimeSlotsTable from './TimeSlotsTable'

// not used for now
// const daysOptions = [
//   { value: 'weekdays', label: 'Weekdays' },
//   { value: 'weekends', label: 'Weekends' },
//   { value: 'all', label: 'All days' }
// ]

export const defaultTimeSlotValues = {
  startTime: '09:00:00',
  endTime: '18:00:00',
  durationHours: '0',
  durationMinutes: '0'
}

export default class TimeSlotsRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTimeSLotsModalOpen: false
    }
  }

  onDateRangeClear = () => {
    this.props.updateTimeSlotsSettings('selectedDates', [])
  }

  handleApply = selectedDates => {
    this.props.updateTimeSlotsSettings('selectedDates', [])
  }

  handleRemoveTimeSlot = value => {
    const { selectedDates, selectedChip, updateTimeSlotsSettings } = this.props
    const updatedSelectedDates = _filter(selectedDates, s => moment(s.startDate).format(FORMAT) !== value)
    const updatedSelectedChip = selectedChip ? moment(updatedSelectedDates[0].startDate).format(FORMAT) : selectedChip
    updateTimeSlotsSettings('selectedDates', updatedSelectedDates)
    updateTimeSlotsSettings('selectedChip', updatedSelectedChip)
  }

  handleGenerateSlotsButtonClick = () => {
    this.setState({ isTimeSLotsModalOpen: true })
  }

  handleSlotValuesChange = (key, value) => {
    const { timeSlotsValues, updateTimeSlotsSettings } = this.props
    updateTimeSlotsSettings('timeSlotsValues', { ...timeSlotsValues, [key]: value })
  }

  onClickTitle = () => {
    this.setState({
      isTimeSLotsModalOpen: !this.state.isTimeSLotsModalOpen
    })
  }

  onGenerateTimeSlots = () => {
    const {
      values,
      updateField,
      selectedDates,
      updateTimeSlotsSettings,
      timeSlotsValues,
      selectedWeekdays,
      excludedDates
    } = this.props
    let tempGeneratedTimeSlots = values.timeSlots || []
    let tempSelectedDates = selectedDates

    // selected dates
    const days = timeSlotsValues.endDate.diff(timeSlotsValues.startDate, 'days')
    let newSelectedDates = _map(new Array(days + 1), (d, i) => ({
      startDate: moment(timeSlotsValues.startDate).add(i, 'days'),
      endDate: moment(timeSlotsValues.startDate).add(i, 'days')
    }))
    newSelectedDates = removeDates(newSelectedDates, excludedDates, selectedWeekdays)
    tempSelectedDates = tempSelectedDates.concat(newSelectedDates)
    tempSelectedDates = _orderBy(tempSelectedDates, t => moment(t.startDate).format('YYYYMMDD'), ['asc'])

    // slots
    const newGeneratedTimeSlots = generateTimeSlots(values, timeSlotsValues, newSelectedDates)
    tempGeneratedTimeSlots = tempGeneratedTimeSlots.concat(newGeneratedTimeSlots)
    tempGeneratedTimeSlots = _orderBy(tempGeneratedTimeSlots, t => moment(t.checkInStart).format('YYYYMMDD'), ['asc'])

    const selectedChip = tempGeneratedTimeSlots.length
      ? moment(tempGeneratedTimeSlots[0].checkInStart).format(FORMAT)
      : ''

    updateTimeSlotsSettings('selectedDates', tempSelectedDates)
    updateTimeSlotsSettings('selectedChip', formatDate(selectedChip))
    this.setState({ isTimeSLotsModalOpen: false })
    updateField('timeSlots', tempGeneratedTimeSlots)
  }

  handleModalClose = () => {
    this.setState({ isTimeSLotsModalOpen: false })
  }

  handleChipSelect = chip => {
    const { updateTimeSlotsSettings } = this.props
    updateTimeSlotsSettings('selectedChip', formatDate(chip))
  }

  handleTimeSlotRowUpdate = (slot, isDeleting) => {
    const { values, updateField } = this.props
    const generatedTimeSlots = values.timeSlots || []
    const updatedTimeSlots = !isDeleting
      ? _map(generatedTimeSlots, s => (s.checkInStart === slot.checkInStart ? slot : s))
      : _filter(generatedTimeSlots, s => s.checkInStart !== slot.checkInStart)

    updateField('timeSlots', updatedTimeSlots)
  }

  handleWeekdayChange = value => {
    const { selectedWeekdays, updateTimeSlotsSettings } = this.props
    let updatedWeekdays = [...selectedWeekdays]
    if (_find(selectedWeekdays, w => w === value)) {
      updatedWeekdays = selectedWeekdays.length === 1 ? selectedWeekdays : _filter(selectedWeekdays, w => w !== value)
    } else {
      updatedWeekdays.push(value)
    }
    updateTimeSlotsSettings('selectedWeekdays', updatedWeekdays)
  }

  onApplyDates = dates => {
    this.props.updateTimeSlotsSettings('excludedDates', dates)
  }

  render() {
    const {
      values,
      isInventoryMode,
      currency,
      selectedDates,
      selectedChip,
      timeSlotsValues,
      selectedWeekdays,
      excludedDates
    } = this.props
    const { isTimeSLotsModalOpen } = this.state
    const isHidden = !(selectedDates && selectedDates.length)
    const isGenerateButtonDisabled = !values.displayName || !values.maxInventory || !values.faceValue || !!values.timeSlots
    const generatedTimeSlots = values.timeSlots
    const groupedTimeSlots = getGroupedTimeSlots(generatedTimeSlots, selectedDates)

    return (
      <>
        <div className="col-sm-6">
          <div className="date-range">
            <div className="date-range-header">
              <div
                className="date-range-title time-slot-generate-button"
                onClick={!isGenerateButtonDisabled ? this.onClickTitle : () => {}}
                style={{
                  cursor: isGenerateButtonDisabled ? 'no-drop' : 'pointer'
                }}
              >
                <i className="fa fa-calendar-o" />
                {`Generate time slots`}
              </div>
            </div>
          </div>
          <TimeSlotsCreateModal
            display={isTimeSLotsModalOpen}
            values={timeSlotsValues}
            selectedWeekdays={selectedWeekdays}
            defaultPrice={values.faceValue}
            defaultQuantity={values.maxInventory}
            handleChange={this.handleSlotValuesChange}
            onSubmit={this.onGenerateTimeSlots}
            onCancel={this.handleModalClose}
            handleWeekdayChange={this.handleWeekdayChange}
            onApplyDates={this.onApplyDates}
            excludedDates={excludedDates}
            selectedDates={selectedDates}
          />
        </div>
        {!isHidden && (
          <div>
            <div className="div-spacing-20" />
            <div className="col-sm-12 tags-field">
              <div className="react-tagsinput pdtb-5">
                {_map(selectedDates, s => (
                  <Chip
                    key={s.startDate}
                    value={moment(s.startDate).format(FORMAT)}
                    handleRemove={this.handleRemoveTimeSlot}
                    isSelected={selectedChip === moment(s.startDate).format(FORMAT)}
                    onSelectChip={this.handleChipSelect}
                    disableClear={true}
                  />
                ))}
              </div>
            </div>
            <div className="div-spacing-20" />
            {generatedTimeSlots && (
              <div className="col-sm-12">
                <TimeSlotsTable
                  slots={groupedTimeSlots[`${selectedChip}`] || []}
                  isInventoryMode={isInventoryMode}
                  currency={currency}
                  handleUpdate={this.handleTimeSlotRowUpdate}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}
