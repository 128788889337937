import React from 'react'
import { Field } from 'formik'
import { SimpleField } from '../../../../formik/Fields/SimpleField'
import {
  requiredValidator,
  maxLengthNormalizerCreator,
} from '../../../../../_common/core/validation'

const shortNameRE = /[<>'\"]+/
const shortNameLength = 17
const displayNameLength = 65

export default class NameRow extends React.Component {
  handleChangeDisplayName = (key, value, event) => {
    const { updateField } = this.props
    updateField(key, value)
    this.handleChangeShortName(value)
  }

  handleChangeShortName = value => {
    const { updateField } = this.props
    let str = value
    if (str.length > shortNameLength) {
      if (shortNameRE.test(str)) return
      str = str.slice(0, shortNameLength)
    }
    updateField('shortName', str)
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-6 col-12">
          <Field
            name="displayName"
            label="Event Name"
            component={SimpleField}
            normalizer={maxLengthNormalizerCreator(displayNameLength)}
            validate={requiredValidator()}
            onChange={this.handleChangeDisplayName}
          />
        </div>
        <div className="col-xs-6 col-12">
          <Field
            name="shortName"
            label="Short Event Name"
            component={SimpleField}
            normalizer={maxLengthNormalizerCreator(shortNameLength)}
            validate={requiredValidator()}
          />
        </div>
      </div>
    )
  }
}
