import React, { Component } from 'react'
import Modal from 'react-modal'
import { Formik, Form, Field } from 'formik'
import Button from '../../_library/Button'
import FileUploader from '../../_library/FileUploader'
import { SimpleField, TextAreaField } from '../../formik/Fields'
import {
  // field level validators
  requiredValidator,
} from '../../../_common/core/validation'

const modalStyles = {
  overlay: {
    position: 'center',
    top: null,
    left: null,
    right: null,
    bottom: null,
    backgroundColor: null,
  },
  content: {
    position: null,
    top: null,
    left: null,
    right: null,
    bottom: null,
    border: 'none',
    background: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '0',
    outline: 'none',
    padding: '0',
  },
}
class PrizeModal extends Component {
  renderInitialValues() {
    const {
      prizeInfo: { name = '', description = '', image = '' },
    } = this.props
    const initialValues = {
      name,
      description,
      image,
      phase: 'pre-registration',
    }
    return initialValues
  }
  handleChangeImageURL(img, updateField) {
    updateField('image', img)
  }
  render() {
    const { isOpen, header, acceptLabel, cancelLabel, handleSubmit, handleCancel } = this.props
    return (
      <Modal
        className="modal-dialog modal-trans prize_modal"
        style={modalStyles}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={handleCancel}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{header || ''}</p>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={this.renderInitialValues()}
                  onSubmit={(values, actions) => {
                    const { setFieldValue, setSubmitting, resetForm } = actions
                    handleSubmit(values).finally(() => {
                      resetForm()
                      setSubmitting(false)
                    })
                  }}
                  render={({ values, isSubmitting, errors, touched, setFieldValue }) => (
                    <Form autoComplete="off">
                      <div className="row">
                        <div className="col-xs-12">
                          <Field
                            name="name"
                            label="Name"
                            component={SimpleField}
                            validate={requiredValidator()}
                          />
                        </div>
                        <div className="col-xs-12 pre_reg_modal_descriptipon">
                          <Field name="description" label="Description" component={TextAreaField} />
                        </div>
                        <div className="col-xs-12 pre_reg_file_upload">
                          <FileUploader
                            id="prizeImage"
                            label={'Prize Image'}
                            filetype="image"
                            value={values.image}
                            onChange={(img) => this.handleChangeImageURL(img, setFieldValue)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="modal-footer footer-in-form">
                          <div className="col-xs-12">
                            <Button
                              className="btn btn-success btn-lg btn-shadow"
                              type="submit"
                              loading={isSubmitting}
                            >
                              Save
                            </Button>
                            <Button
                              className="btn btn-cancel btn-lg btn-shadow"
                              type="button"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PrizeModal
