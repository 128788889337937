import _get from 'lodash/get'
import { baseRequest } from '.'

export const getCheckedInDataTickets = async (eventId, search_query, sort_by, sort_order) => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in_individual_tickets/`, {
    params: {
      search_query,
      'options[sort_by]': sort_by,
      'options[sort_order]': sort_order,
    },
  })
  return _get(res, 'data.data.check_in')
}

export const getCheckedInData = async eventId => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in/`)
  return _get(res, 'data.data.check_in')
}

export const getCheckedInDates = async eventId => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in_dates/`)
  return _get(res, 'data.data')
}

export const getCheckedInAddonsDates = async eventId => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in_dates/add_ons/`)
  return _get(res, 'data.data')
}

export const getCheckedInSlots = async (eventId, date) => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in_slots/`, {
    params: {
      date,
    },
  })
  return _get(res, 'data.data')
}

export const getCheckedInAddOnsSlots = async (eventId, date) => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/check_in_slots/add_ons/`, {
    params: {
      date,
    },
  })
  return _get(res, 'data.data')
}
