import React from 'react'
import _result from 'lodash/result'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import Card from '../_library/Card'
import EmailToTicketHolders from './messaging/EmailToTicketHolders'
import Mailchimp from '../_features/messaging/Mailchimp'
import SentEmails from '../_features/messaging/SentEmails'

import { EMAIL_BRAND_USERS, FETCH_BRAND } from '../../_common/redux/brands/actions'
import { FETCH_EMAIL_TEMPLATES } from '../../_common/redux/emailtemplates/actions'
import { UPDATE_BRAND_MAILCHIMP } from '../../_common/redux/brands/actions'

import { get_event, get_email_templates } from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'
import PermissionDenied from '../_library/PermissionDeniedComponent'

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const emailTemplates = get_email_templates(state)
    const brand = state.brands.selected
    const { user } = state.auth

    return {
      brand,
      event,
      emailTemplates,
      user,
    }
  },
  {
    EMAIL_BRAND_USERS,
    FETCH_EMAIL_TEMPLATES,
    FETCH_BRAND,
    UPDATE_BRAND_MAILCHIMP,
  },
)
class BrandMessaging extends React.Component {
  constructor(props) {
    super(props)
    form_helper_reset()
    this.state = {
      isEmailSent: false,
    }
    this.mounted = false
  }

  componentDidMount() {
    const {
      brand: { id, displayName },
      configs,
      FETCH_EMAIL_TEMPLATES,
    } = this.props

    FETCH_EMAIL_TEMPLATES(id, 'brand')

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future',
    }

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    this.mounted = true
  }

  componentWillUnmount() {
    form_helper_reset()
    this.mounted = false
  }

  routerWillLeave = () => !form_helper_isEditted()

  confirmEmail = form => {
    const { brand, EMAIL_BRAND_USERS } = this.props
    this.setState({ isEmailSent: false })

    return EMAIL_BRAND_USERS(brand.id, form)
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully sent!',
          hideAfter: 3,
          showCloseButton: true,
        })
        this.setState({
          isEmailSent: true,
        })
        return v
      })
  }

  handleUpdateFinishedCB = () => {
    const {
      FETCH_BRAND,
      brand: { id },
    } = this.props
    FETCH_BRAND(id)
  }

  render() {
    const { emailTemplates, brand, UPDATE_BRAND_MAILCHIMP, configs, user } = this.props
    const { isEmailSent } = this.state
    const showMailchimpSection = _get(configs, 'appearance.showMailchimpSection', false)
    const allowMailchimp = _get(brand, 'allowMailchimp', false)

    return (
      <>
        {brand.isVerified ? (
          <>
            <DisableSectionByPermissionWrapper>
              <div className="EventMessaging-header EventMessaging">
                <div className="tabs-container">
                  <div className="active tab" aria-hidden="true">
                    <img
                      alt="messaging"
                      src={asset('/resources/images/system_icons/messaging.svg')}
                      className="ico-show"
                    />
                    Email
                  </div>
                </div>
              </div>
              <div className="EventMessaging">
                <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
                <Card title={'Emails'}>
                  <EmailToTicketHolders
                    brand={brand}
                    onSubmit={this.confirmEmail}
                    emailTemplates={emailTemplates}
                  />
                </Card>
                <Card title={'Sent Emails'}>
                  <SentEmails originType="brand" id={brand.id} isEmailSent={isEmailSent} />
                </Card>
                {user.tfStaff || allowMailchimp
                  ? showMailchimpSection && (
                    <>
                      <div className="mail_chipbg" />
                      <Card title={'MailChimp Integration'}>
                        <Mailchimp
                          originType="brand"
                          originData={brand}
                          updateFunc={UPDATE_BRAND_MAILCHIMP}
                          updateFinishedCB={this.handleUpdateFinishedCB}
                        />
                      </Card>
                    </>
                  )
                  : null}
              </div>
            </DisableSectionByPermissionWrapper>
          </>
        ) : (
          <PermissionDenied subHeader="You don't have access to this page" />
        )}
      </>
    )
  }
}

export default BrandMessaging
