import { baseRequest } from '.'

export const reVerify = async () => {
  try {
    await baseRequest.post(`re-verify/`)
    return true
  } catch (e) {
    return false
  }
}
