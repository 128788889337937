import React from 'react'
import { Field } from 'formik'

import Card from '../../../../_library/Card'
import Select from '../../../../_library/Select'
import FileUploader from '../../../../_library/FileUploader'
import Button from '../../../../_library/Button'
import EventTitleImagePreview from '../../EventForm/EventTitleImagePreview'
import _get from 'lodash/get'

import { ENV } from '../../../../constants/env'

import {
  fileSizeLimitValidator,
} from '../../../../../_common/core/validation'

import { FileUploaderField, CustomToggleField } from '../../../../formik/Fields'

export default class OnlineEventImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { previewOnBackground: false }
  }

  handleChangeImageURL = img => {
    const { values, updateField, imageLanguage } = this.props
    const updatedURLs = { ...values.imageURLs, [imageLanguage]: img }
    updateField('imageURLs', updatedURLs)
  }

  handleChangeReplacementImage = img => {
    const { updateField } = this.props
    if (img) {
      updateField('titleReplacementImage', img)
    } else {
      updateField('titleReplacementImage', '')
    }
  }

  handlePreviewOnBackground = () => {
    this.setState({ previewOnBackground: !this.state.previewOnBackground })
  }

  getTitleReplacementImage = () => {
    const { titleReplacementImage } = this.props.values
    const imageUrl = titleReplacementImage
    if (imageUrl && !imageUrl.startsWith('https://')) {
      return `${ENV.API_BASE}/uploaded/${imageUrl}`
    }
    return imageUrl
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.titleReplacementImage !== this.props.titleReplacementImage &&
      this.props.titleReplacementImage
    ) {
      this.props.handleChangeImageAsEventName(true)
    }
  }

  render() {
    const {
      values,
      errors,
      cardsStatus,
      imageLanguage,
      autoBackground,
      useImageInsteadOfTitle,
      languageOptions,
      onLanguageChange,
      closed,
      configs,
    } = this.props
    const eventImage = values.imageURLs ? values.imageURLs[imageLanguage] : ''

    const showUseAutomaticallyGeneratedBackgroundToggle = _get(
      configs, 'appearance.showUseAutomaticallyGeneratedBackgroundToggle', true,
    )
    const showUsetransparentLogoInsteadOfEventNameToggle = _get(
      configs, 'appearance.showUsetransparentLogoInsteadOfEventNameToggle', true,
    )

    return (
      <Card
        closed={closed}
        icon={'fa-camera'}
        title={'Event Images'}
        status={cardsStatus && cardsStatus.images}
        rightAction={
          <Select
            fieldKey={'imageLanguage'}
            style={{
              width: 'auto',
              margin: '0px 15px 0px 0px',
              height: 30,
              paddingRight: 30
            }}
            fieldGroupStyle={{ marginTop: 5 }}
            value={imageLanguage}
            onChange={onLanguageChange}
            onClick={e => e.stopPropagation()}
            options={languageOptions}
          />
        }
      >
        <div className="row">
          <div className="col-xs-4 col-12">
            <FileUploader
              id="imageURLs"
              label={'Add Flyer/Poster'}
              filetype="image"
              value={eventImage}
              onChange={this.handleChangeImageURL}
            />
            {
              errors.imageURLs ? <small className='text-danger'>{errors.imageURLs}</small> : null
            }
          </div>
          {showUseAutomaticallyGeneratedBackgroundToggle && (
            <div className="col-xs-8 col-12">
              {!autoBackground && (
                <Field
                  name="backgroundURL"
                  label="Add Background Image (optional)"
                  filetype="image"
                  component={FileUploaderField}
                  validate={fileSizeLimitValidator()}
                />
              )}
              <Field
                name="autoBackground"
                offText="Use automatically-generated background"
                component={props => <CustomToggleField {...props} direction="right" />}
              />
            </div>
          )}
        </div>
        {showUsetransparentLogoInsteadOfEventNameToggle ? <div className="row">
          <div
            className={`col-xs-4 col-12 titleReplacementImageBlock ${
              this.state.previewOnBackground && !!this.getTitleReplacementImage()
                ? 'hidden_file_uploader'
                : ''
            }`}
          >
            <Field
              name="useImageInsteadOfTitle"
              offText="Use transparent logo (PNG/SVG) instead of text event name"
              component={props => <CustomToggleField {...props} direction="right" />}
            />
            <div className="div-spacing-10" />
            {useImageInsteadOfTitle && (
              <Field
                name="titleReplacementImage"
                label="Add Title Image"
                filetype="image"
                accept="image/png, image/svg+xml"
                component={FileUploaderField}
                validate={fileSizeLimitValidator()}
                imgClassName="title_replacement_img"
                value={this.getTitleReplacementImage()}
                onChange={this.handleChangeReplacementImage}
              />
            )}
          </div>
          {useImageInsteadOfTitle &&
            (values.backgroundURL || values.blurredBackgroundUrl ? (
              <div className="col-xs-8 col-12">
                <Button
                  type="button"
                  className="btn btn-default btn-shadow"
                  onClick={this.handlePreviewOnBackground}
                  disabled={!!!this.getTitleReplacementImage()}
                >
                  Preview on background
                </Button>
              </div>
            ) : (
              <div className="col-xs-8 col-12">
                <div className="line">
                  Please save the changes to be able to preview the title image.
                </div>
              </div>
            ))}
          {this.state.previewOnBackground && !!this.getTitleReplacementImage() && (
            <div className="col-xs-12">
              <EventTitleImagePreview
                backgroundImageUrl={
                  autoBackground
                    ? values.blurredBackgroundUrl
                    : values.backgroundURL
                      ? values.backgroundURL
                      : values.blurredBackgroundUrl
                }
                titleImageUrl={this.getTitleReplacementImage()}
              />
            </div>
          )}
        </div> : null}
      </Card>
    )
  }
}
