import React from 'react'
import { Field } from 'formik'
import { SimpleField, RadioGroupField, DateTimePickerFieldReact } from '../../../../formik/Fields'
import {
  combineValidators,
  combineNormalizers,
  minNumberValidator,
  requiredValidator,

  // normalizers
  toInt,
  minNormalizerCreator,
} from '../../../../../_common/core/validation'

export default class ReminderEmailForm extends React.Component {
  render() {
    const { values } = this.props

    return (
      <div style={{ padding: 10 }}>
        <div className="row">
          <div className="col-xs-12">
            <Field
              name="emailDateOption"
              component={RadioGroupField}
              groups={[
                {
                  label: 'On Date',
                  value: 'onDate'
                },
                {
                  label: 'Minutes Before',
                  value: 'minutesBefore'
                }
              ]}
              validate={requiredValidator()}
              classNames={{
                container: 'radio-group-container',
                container_item: 'radio-group-item',
                component: ''
              }}
            />
          </div>
          <div className="div-spacing-15" />
          {values.emailDateOption === 'onDate' && (
            <div className="col-xs-12" style={{ position: 'static' }}>
              <Field
                name="reminderEmailDate"
                label="Date"
                hint="(Please, note, that the process may take up to 10 minutes.)"
                hintClass="hint-show"
                placeholder="D MMM YYYY H:M AM"
                component={DateTimePickerFieldReact}
                validate={requiredValidator()}
              />
            </div>
          )}
          {values.emailDateOption === 'minutesBefore' && (
            <div className="col-xs-12">
              <Field
                name="reminderEmailMinutes"
                label="Minutes Before"
                placeholder=""
                component={SimpleField}
                normalizer={combineNormalizers(toInt, minNormalizerCreator(1))}
                validate={combineValidators(minNumberValidator(undefined, 1), requiredValidator())}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
