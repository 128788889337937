import React from 'react'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _isEmpty from 'lodash/isEmpty'
import _maxBy from 'lodash/maxBy'
import _minBy from 'lodash/minBy'
import moment from 'moment'
import { Chart } from '../../_library/Chart'
import EmptyBar from '../../_library/EmptyBar'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'

export default class CheckInDate extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      { key: 'date', value: 'Date', formatTo: DAY_DISPLAY_FORMAT },
      { key: 'quantity', label: 'No. of Tickets' }
    ])
    this.footbarColumns = ['quantity']

    this.state = {
      tableData: [],
      totalCheckedIn: 0,
      highestCheckedIn: {},
      lowestCheckedIn: {}
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  getTableData = () => {
    const { data } = this.props

    const tableData = _map(_keys(data), item => ({ date: item, quantity: data[item] }))
    const totalCheckedIn = _reduce(tableData, (result, item) => parseInt(result) + parseInt(item.quantity), 0)
    const highestCheckedIn = _maxBy(tableData, 'quantity')
    const lowestCheckedIn = _minBy(tableData, 'quantity')

    this.setState({
      tableData,
      totalCheckedIn,
      highestCheckedIn,
      lowestCheckedIn
    })
  }

  render() {
    const { data } = this.props
    const { tableData, totalCheckedIn, highestCheckedIn, lowestCheckedIn } = this.state

    return (
      <div style={{ margin: 10 }}>
        <div className="daily-title">
          Check-Ins by <strong>DAY</strong>
        </div>
        {_isEmpty(tableData) ? (
          <EmptyBar />
        ) : (
          <div>
            <div className="row">
              <div className="col-xs-12">
                <Chart
                  width={'100%'}
                  height="300px"
                  chartConfig={{
                    type: 'bar',
                    data: {
                      labels: _map(_keys(data), item =>
                        moment(item, 'YYYY-MM-DD').format(DAY_DISPLAY_FORMAT)
                      ),
                      datasets: [
                        {
                          data: _values(data)
                        }
                      ]
                    },
                    options: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-xs-2 col-12 margin-bottom30 text-center col-xs-offset-3">
                <div className="title">TOTAL</div>
                <div className="number">{totalCheckedIn}</div>
                <div className="desc">TOTAL CHECK IN</div>
              </div>
              <div className="col-xs-2 col-12 margin-bottom30 text-center">
                <div className="title">{moment(highestCheckedIn.date).format('D MMM')}</div>
                <div className="number">
                  <img src={asset('/resources/images/demographics-highest.png')} className="icon" />
                  {highestCheckedIn.quantity}
                </div>
                <div className="desc">HIGHEST CHECK IN</div>
              </div>
              <div className="col-xs-2 col-12 margin-bottom30 text-center">
                <div className="title">{moment(lowestCheckedIn.date).format('D MMM')}</div>
                <div className="number">
                  <img src={asset('/resources/images/demographics-lowest.png')} className="icon" />
                  {lowestCheckedIn.quantity}
                </div>
                <div className="desc">LOWEST CHECK IN</div>
              </div>
            </div>
            <div className="table-caption">
              <img src={asset('/resources/images/icon-calendar.png')} className="icon" />
              Daily
            </div>
            <SortableTable
              data={tableData}
              tableColumns={this.tableColumns}
              enableCopyTable={true}
              enableSort={true}
              footbar={{
                label: 'Total:',
                columns: this.footbarColumns
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
