import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { formatDay } from '../../../../_common/core/validation/normalizers'
import SortableTableWithPaginationHOC from '../../../hoc/SortableTableWithPaginationHOC'
import EmptyBar from '../../../_library/EmptyBar'

const SortableTableWithPagination = SortableTableWithPaginationHOC()

export default class ByDateTable extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'date',
        label: 'Date',
        normalizer: formatDay,
        sort: this.sortByColumn
      },
      {
        key: 'quantity',
        label: 'Quantity',
        sort: this.sortByColumn
      }
    ])

    this.footbarColumns = [{ key: 'quantity', component: null }]

    this.state = {
      sortBy: { column: 'date', asc: true },
      rows: null
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupedData } = this.props
    if (!_isEqual(groupedData, prevProps.groupedData)) {
      this.updateData()
    }
  }

  updateData = () => {
    const { groupedData } = this.props
    const tempRows = []

    _map(groupedData, (value, key) => {
      const quantity = _reduce(value, (result, item) => result + parseInt(item.quantity), 0)
      tempRows.push({ ...value[0], quantity })
    })

    this.footbarColumns = [{ key: 'quantity', component: this.getTotalComponent(tempRows) }]

    this.setState({
      rows: _filter(_orderBy(tempRows, 'date', 'asc'), rowItem => !!rowItem.prereg_id)
    })
  }

  sortByColumn = column => {
    this.setState(prevState => {
      const isAsc = column.key === prevState.sortBy.column ? !prevState.sortBy.asc : prevState.sortBy.asc

      return {
        rows: _orderBy(prevState.rows, column.key, isAsc ? 'asc' : 'desc'),
        sortBy: {
          column: column.key,
          asc: isAsc
        }
      }
    })
  }

  getTotalComponent = data => {
    const total = _reduce(data, (result, item) => result + parseInt(item.quantity), 0)

    return (
      <span>
        <strong>{total}</strong>
      </span>
    )
  }

  render() {
    const { rows, sortBy } = this.state

    return (
      <div style={{ position: 'relative' }}>
        {rows && rows.length ? (
          <SortableTableWithPagination
            data={rows}
            dataForCopy={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
