import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'

import { Field } from 'formik'
import { zoomConfigs } from './zoomMeetingConfigs'
import Button from '../../../../_library/Button'
import SortableTable from '../../../../_library/SortableTable'
import { getTableColumns } from '../../../../utils/sortableTableUtils'
import {
  GET_ZOOM_AUTH_URL,
  GET_ZOOM_MEETINGS,
  GET_ZOOM_MEETING,
  GET_ZOOM_USER_DATA,
  LOG_OUT_ZOOM_USER
} from '../../../../../_common/redux/eventhosting/actions'
import { FETCH_SESSION } from '../../../../../_common/redux/auth/actions'
import LoadingBar from '../../../../_library/LoadingBar'
import { SimpleField } from '../../../../formik/Fields'
import CustomToggle from '../../../../_library/CustomToggle'
import { Pagination } from '../../../../_library/Pagination'

const PAGE_SIZE = 6

@connect(
  state => {
    const isLoadingMeetings = state.loading.has('GET_ZOOM_MEETINGS')
    const isLoadingMeeting = state.loading.has('GET_ZOOM_MEETING')
    const { zoomAuthUrl } = state.eventhosting
    const zoomMeetingsData = state.eventhosting.zoomMeetings
    const { zoomMeeting } = state.eventhosting
    const hasZoomAccountAttached = _get(state.auth, 'user.hasZoomAccountAttached', false)
    const { zoomUserData } = state.eventhosting
    return {
      zoomAuthUrl,
      zoomMeetingsData,
      zoomMeeting,
      hasZoomAccountAttached,
      isLoadingMeetings,
      isLoadingMeeting,
      zoomUserData
    }
  },
  {
    GET_ZOOM_AUTH_URL,
    GET_ZOOM_MEETINGS,
    GET_ZOOM_MEETING,
    GET_ZOOM_USER_DATA,
    FETCH_SESSION,
    LOG_OUT_ZOOM_USER
  }
)
export default class ZoomMeetingDetails extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      { key: 'name', label: 'Topic', enableItemCopy: true },
      { key: 'joinUrl', label: 'Meeting URL', enableItemCopy: true, isSortable: false },
      { key: 'id', label: 'Meeting ID', enableItemCopy: true, isSortable: false }
    ])
    this.state = {
      enablePagination: false,
      meetingsList: null,
      selectedMeetingId: null,
      pageNumber: 1,
      totalRecords: 0
    }
  }

  componentDidMount() {
    const { hasZoomAccountAttached } = this.props

    this.fetchZoomAuthUrl()
    if (hasZoomAccountAttached) {
      this.fetchZoomMeetings()
      this.fetchZoomUserData()
    }

    window.addEventListener('storage', this.handleStorageChange)
  }

  componentDidUpdate(prevProps) {
    const { zoomMeetingsData, zoomUserData } = this.props
    if (!_isEqual(prevProps.zoomMeetingsData, zoomMeetingsData) || !this.state.meetingsList) {
      if (zoomMeetingsData) {
        const zoomMeetingsDataObj = zoomMeetingsData
        this.setState({
          meetingsList: zoomMeetingsDataObj.meetings || [],
          enablePagination: zoomMeetingsDataObj.totalRecords > PAGE_SIZE,
          totalRecords: zoomMeetingsDataObj.totalRecords,
          pageNumber: zoomMeetingsDataObj.pageNumber
        })
      }
    }

    if (!_.isEqual(prevProps.zoomUserData, zoomUserData)) {
      this.fetchZoomUserData()
      this.fetchZoomMeetings()
      if (!!prevProps.zoomUserData) {
        this.handleMeetingSelect({ provider: 'zoom', password: '' }, false)
        this.setState(() => ({ selectedMeetingId: null }))
      }
    }
  }

  fetchZoomAuthUrl = () => {
    const { GET_ZOOM_AUTH_URL } = this.props
    try {
      GET_ZOOM_AUTH_URL()
    } catch (err) {}
  }

  fetchZoomMeetings = pageNumber => {
    const { GET_ZOOM_MEETINGS } = this.props
    try {
      GET_ZOOM_MEETINGS(pageNumber || this.state.pageNumber, PAGE_SIZE)
    } catch (err) {}
  }

  fetchZoomMeeting = async meeting => {
    const { GET_ZOOM_MEETING, values, updateField } = this.props

    this.setState({
      selectedMeetingId: meeting.id
    })

    if (values.selectedMeeting.meetingId === meeting.id) {
      this.handleMeetingSelect({ provider: 'zoom' })
    } else {
      try {
        const res = await GET_ZOOM_MEETING(meeting.id)
        this.handleMeetingSelect(res.data)
      } catch (err) {}
    }
  }

  fetchZoomUserData = () => {
    const { GET_ZOOM_USER_DATA } = this.props
    try {
      GET_ZOOM_USER_DATA()
    } catch (error) {}
  }

  handleZoomConnect = async (isSwitchingUser = false) => {
    const { zoomAuthUrl, LOG_OUT_ZOOM_USER } = this.props
    const zoomAuth = zoomAuthUrl ? zoomAuthUrl : null
    if (!zoomAuth) return
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=770,height=700,left=-1000,top=-1000`

    if (isSwitchingUser) {
      this.setState(() => ({ isSwitchLoading: true }))
      const res = await LOG_OUT_ZOOM_USER()
      this.setState(() => ({ isSwitchLoading: false }))
      if (res.success) {
        window.open(zoomAuth.authUrl, 'Switch Zoom Account', params)
      }
    } else {
      window.open(zoomAuth.authUrl, 'Connect Zoom Account', params)
    }
  }

  handleStorageChange = () => {
    const { FETCH_SESSION } = this.props
    const zoomAccountAttached = localStorage.getItem('accountAttached')
    const errorMessage = localStorage.getItem('errorMessage')

    if (errorMessage) {
      localStorage.removeItem('errorMessage')
      Messenger().post({
        type: 'error',
        message: errorMessage,
        hideAfter: 10,
        showCloseButton: true
      })
    } else if (zoomAccountAttached) {
      localStorage.removeItem('accountAttached')
      try {
        FETCH_SESSION()
        this.fetchZoomUserData()
      } catch (error) {}
    }
  }

  handleNewMeetingSelect = (key, value) => {
    const { values, updateField } = this.props
    updateField(key, value)
    if (value) {
      this.handleMeetingSelect({ provider: 'zoom', password: '' }, value)
    }
  }

  handleMeetingSettingsChange = (key, value) => {
    const { values, updateField } = this.props
    updateField('selectedMeeting', { ...values.selectedMeeting, [key]: value })
  }

  handleMeetingSelect = (data, isNewMeeting) => {
    const { updateField } = this.props

    const selectedMeeting = data.settings
      ? {
        meetingId: data.id,
        autoRecording: data.settings.autoRecording !== 'none',
        joinBeforeHost: data.settings.joinBeforeHost,
        muteUponEntry: data.settings.muteUponEntry,
        waitingRoom: data.settings.waitingRoom,
        password: data.password || '',
        embed: '',
        provider: 'zoom'
      }
      : { ...data }

    updateField('selectedMeeting', selectedMeeting)

    if (!isNewMeeting) {
      // new meeting data
      updateField('isNewMeeting', false)
    }

    // event data
    updateField('startDate', data.startDate || '')
    updateField('endDate', data.endDate || '')
    updateField('salesStartDate', data.startDate || '')
    updateField('salesEndDate', data.endDate || '')
    updateField('displayName', data.name || '')
    updateField('shortName', data.name || '')
    updateField('descriptions', { en: data.description || '' })
    updateField('timezone', data.timezone || '')
    updateField('currency', data.currency || '')
  }

  onPageChange = pageNumber => {
    this.setState(() => ({ pageNumber })) // temp
    this.fetchZoomMeetings(pageNumber)
  }

  render() {
    const {
      values,
      hasZoomAccountAttached,
      isLoadingMeetings,
      isLoadingMeeting,
      isNew,
      zoomUserData
    } = this.props
    const {
      meetingsList,
      selectedMeetingId,
      enablePagination,
      pageNumber,
      totalRecords,
      isSwitchLoading
    } = this.state
    const selectedMeeting = values.selectedMeeting || {}
    const isMeetingSelected = selectedMeeting.provider === 'zoom' && selectedMeeting.meetingId
    const isMobile = window.innerWidth < 768
    const firstName = zoomUserData ? zoomUserData.firstName || '' : ''
    const lastName = zoomUserData ? zoomUserData.lastName || '' : ''

    return (
      <div className="col-xs-12 col-12 ticket-radio col-12 sub-title zoom-meeting-details">
        {!hasZoomAccountAttached && (
          <Button
            className="btn btn-success btn-shadow"
            type="button"
            onClick={() => this.handleZoomConnect()}
          >
            {`Connect Zoom Account`}
          </Button>
        )}
        {hasZoomAccountAttached && zoomUserData ? (
          <div className="account-switch-container">
            <p>
              <span>{'You are logged in as:'}</span>
              <strong>{`${firstName} ${lastName}`}</strong>
            </p>
            <Button
              className="btn btn-success btn-shadow zoom-connect-btn"
              type="button"
              onClick={() => this.handleZoomConnect(true)}
              loading={isSwitchLoading}
            >
              {`Switch Zoom Account`}
            </Button>
          </div>
        ) : null}
        <div className="div-spacing-10" />
        {hasZoomAccountAttached && (
          <div className="row">
            <div className="col-xs-6 col-12">
              <Button
                className="btn btn-success btn-shadow zoom-meeting-btn"
                type="button"
                onClick={() => this.handleNewMeetingSelect('isNewMeeting', true)}
                disabled={values.isNewMeeting}
              >
                <i className="fa fa-fw fa-plus" />
                {`New Zoom Meeting`}
              </Button>
            </div>
          </div>
        )}
        <div className="row">
          {(values.isNewMeeting || isMeetingSelected) && <hr style={{ borderColor: '#393d44' }} />}
          <div className="col-xs-6 col-12">
            {(values.isNewMeeting || isMeetingSelected) && (
              <div style={{ marginLeft: 15 }}>
                <h4 className="meeting-title">{`Meeting Details`}</h4>
                <div>
                  {_map(zoomConfigs, item => (
                    <CustomToggle
                      key={item.key}
                      id={item.key}
                      value={selectedMeeting[item.key] || false}
                      checked={selectedMeeting[item.key] || false}
                      onText={item.label}
                      onChange={this.handleMeetingSettingsChange}
                    />
                  ))}
                </div>
                <div className="div-spacing-20" />
                <Field
                  name="selectedMeeting.password"
                  label="Meeting Password (optional)"
                  component={SimpleField}
                />
              </div>
            )}
          </div>
        </div>
        {hasZoomAccountAttached && <div className="div-spacing-30" />}
        {isLoadingMeetings ? (
          <LoadingBar />
        ) : meetingsList && meetingsList.length ? (
          <SortableTable
            data={meetingsList}
            tableColumns={this.tableColumns}
            enableSort={true}
            className={isMobile ? 'block' : ''}
            sortBy={{ column: 'name', asc: 'asc' }}
            actions={[
              {
                label: data =>
                  data.id === selectedMeeting.meetingId ? 'Disconnect Meeting' : 'Connect Meeting',
                className: data =>
                  data.id === selectedMeeting.meetingId
                    ? 'btn btn-danger large'
                    : 'btn btn-primary large',
                icon: '',
                onClick: data => this.fetchZoomMeeting(data),
                isLoading: data => data.id === selectedMeetingId && isLoadingMeeting,
                disabled: data => data.id !== selectedMeetingId && isLoadingMeeting
              }
            ]}
          />
        ) : null}
        {enablePagination && (
          <Pagination
            activePage={pageNumber}
            pageRangeDisplayed={5}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={totalRecords}
            onChange={this.onPageChange}
          />
        )}
      </div>
    )
  }
}
