import { getDomain } from './getDomain'

export function setCustomCookie(name, value, days = 5) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  const domain = getDomain(window.location.hostname)
  document.cookie = name + '=' + (value || '') + expires + `; path=/;domain=${domain}`
}

export function getCookieByName(cname) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function deleteCookieByName(name) {
  const domain = getDomain(window.location.hostname)
  document.cookie = name + `=; Path=/;domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
