import React from 'react'
import _filter from 'lodash/filter'
import _flatten from 'lodash/flatten'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _reduce from 'lodash/reduce'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import Filters from '../../_library/Filters'
import Card from '../../_library/Card'
import { SaveTemplate, SelectFilterTemplate } from '../../_library/FilterTemplate'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import {
  DAY_DISPLAY_FORMAT,
  DAY_FORMAT,
  TIME_FORMAT_ONLY_TIME,
} from '../../constants/timeFormats'

export default class CheckInTicketType extends React.Component {
  constructor(props) {
    super(props)
    // main table
    this.tableColumns = getTableColumns([
      { key: 'ticket_type', label: 'Ticket Type' },
      { key: 'ticketsNumComponent', label: 'No. of Tickets', sort: this.sortByQuantity }
    ])
    this.footbarColumns = [{ key: 'ticketsNumComponent', component: null }]

    // dates table's columns
    this.datesTableColumns = getTableColumns([
      { key: 'date', label: 'Date', formatTo: DAY_DISPLAY_FORMAT, className: 'detail-cell' },
      { key: 'quantityByDate', label: 'No. of Tickets', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Tickets)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    // times table's columns
    this.timesTableColumns = getTableColumns([
      { key: 'time', label: 'Time', className: 'detail-cell' },
      { key: 'quantityByTime', label: 'No. of Tickets', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Tickets)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    this.originalTableData = []
    this.state = {
      filters: [],
      filter_compOrder: false,
      filterUpdateVersion: 0,

      tableData: [],
      dateDetailsRows: [],
      sortBy: { column: 'ticket_type', asc: true }
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  getTableData = () => {
    const { tickets, ticket_checkins } = this.props

    const dateDetailsRows = []
    let allCheckedInTicketsCount = 0
    let allRemainingTicketsCount = 0

    const groupedTickets = _groupBy(ticket_checkins, item => item.ticket_type)
    const tableData = _map(groupedTickets, ticketTypeGroup => {
      const allTicketsCount = ticketTypeGroup.length
      const checkedInTicketsByType = _filter(ticketTypeGroup, groupItem => groupItem.checked_in_at)
      const checkedInTicketsCount = checkedInTicketsByType.length
      const remainingTicketsCount = allTicketsCount - checkedInTicketsCount
      allCheckedInTicketsCount += checkedInTicketsCount
      allRemainingTicketsCount += remainingTicketsCount

      this.getDateDetailRows(checkedInTicketsByType, ticketTypeGroup[0].ticket_type, dateDetailsRows)

      return {
        ...ticketTypeGroup[0],
        id: ticketTypeGroup[0].ticket_type,
        allTicketsCount,
        checkedInTicketsCount,
        remainingTicketsCount,
        ticketsNumComponent: (
          <span>
            {`${checkedInTicketsCount} / `}
            <strong>{allTicketsCount}</strong>
            {` (${remainingTicketsCount} remaining)`}
          </span>
        )
      }
    })

    // tag options
    const tagOptions = []
    const allTags = _flatten(_map(tickets, item => item.tags || []))
    const uniqTags = _uniq(allTags)
    _map(uniqTags, tag => {
      tagOptions.push({ value: tag, label: tag })
    })
    this.footbarColumns = [{ key: 'ticketsNumComponent', component: this.getTotalComponent(tableData) }]

    this.originalTableData = tableData
    this.setState({
      tableData,
      dateDetailsRows,
      uniqTags,
      tagOptions
    })
  }

  getTotalComponent = data => {
    const { ticket_checkins = [] } = this.props

    let allTickets = 0
    let checkedInTickets = 0

    if (data) {
      allTickets = _reduce(data, (sum, item) => sum + item.allTicketsCount, 0)
      checkedInTickets = _reduce(data, (sum, item) => sum + item.checkedInTicketsCount, 0)
    } else {
      allTickets = ticket_checkins.length
      checkedInTickets = _filter(data, item => !!item.checked_in_at).length
    }

    const remainingTicketsCount = allTickets - checkedInTickets

    return (
      <span>
        {`${checkedInTickets} / `}
        <strong>{allTickets}</strong>
        {` (${remainingTicketsCount} remaining)`}
      </span>
    )
  }

  getDateDetailRows = (data, id, dateDetailsRows) => {
    const dataRows = []
    const timeDetailsRows = []

    const groupedData = _groupBy(
      _map(data, item => ({
        ...item,
        date: moment(item.checked_in_at).format(DAY_FORMAT),
        time: moment(item.checked_in_at).format(TIME_FORMAT_ONLY_TIME)
      })),
      groupItem => groupItem.date
    )
    _map(groupedData, (item, key) => {
      this.getTimeDetailRows(item, key, timeDetailsRows)

      dataRows.push({
        id: key,
        date: key,
        quantityByDate: item.length
      })
    })

    if (dataRows.length) {
      dateDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.datesTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'quantityByDate' }]}
            enableSort={true}
            sortBy={{ column: 'date', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
            detailsRows={timeDetailsRows}
          />
        )
      })
    }
  }

  getTimeDetailRows = (data, id, timeDetailsRows) => {
    const dataRows = []

    const groupedData = _groupBy(data, groupItem => groupItem.time)
    _map(groupedData, (item, key) => {
      dataRows.push({
        id: key,
        time: `${key}:00`,
        quantityByTime: item.length
      })
    })

    if (dataRows.length) {
      timeDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.timesTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'quantityByTime' }]}
            enableSort={true}
            sortBy={{ column: 'time', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
    }
  }

  sortByQuantity = column => {
    this.setState(prevState => ({
      ...prevState,
      tableData: _orderBy(
        prevState.tableData,
        ['checkedInTicketsCount'],
        [prevState.sortBy.asc ? 'asc' : 'desc']
      ),
      sortBy: { column: column.key, asc: !prevState.sortBy.asc }
    }))
  }

  onFiltersChange = filters => {
    const { tickets } = this.props

    const filteredData = _filter(this.originalTableData, item => {
      let found = true
      const matchedTicket = tickets.find(ticketType => ticketType.displayName === item.ticket_type)

      _map(filters, (filter, index) => {
        let found1 = 0
        if (matchedTicket && matchedTicket.tags) {
          _map(matchedTicket.tags, t => {
            found1 += t === filter.value ? 1 : 0
          })
        }

        if (filter.isNot) {
          found1 = found1 > 0 ? 0 : 1
        }
        if (filter.isAnd) {
          found = found && found1 > 0
        } else {
          found = found || found1 > 0
        }
      })

      return found
    })

    this.footbarColumns = [{ key: 'ticketsNumComponent', component: this.getTotalComponent(filteredData) }]

    this.setState({
      filters,
      tableData: filteredData
    })
  }

  onSelectFilter = template => {
    let { value } = template
    try {
      value = JSON.parse(value)
      const { filters } = value
      this.onFiltersChange(filters)
    } catch (e) {}
  }

  isEmptyChecker = data => {
    const { filters } = data
    return _isEmpty(filters)
  }

  onSaveFilter = () => {
    this.setState(s => ({ filterUpdateVersion: s.filterUpdateVersion + 1 }))
  }

  render() {
    const { event } = this.props
    const { tableData, dateDetailsRows, tagOptions, sortBy, filterUpdateVersion, filters } = this.state
    const eventId = _get(event, 'id')

    return (
      <div className="ticketType tickettype-checkin">
        <div className="table-caption">
          <img src={asset('/resources/images/icon-ticket.png')} className="icon" />
          Ticket Types
        </div>
        <Card icon={'fa-filter'} title={'Filter'} closed={true} className="filter-card">
          <div style={{ textAlign: 'end' }}>
            <SelectFilterTemplate
              storagePath={`event-${eventId}-checkin-ticket-type-filter`}
              onSelectFilter={this.onSelectFilter}
              updateVersion={filterUpdateVersion}
            />
          </div>
          <Filters
            filters={[{ label: 'Tags', type: 'tags', options: tagOptions }]}
            onFiltersChange={this.onFiltersChange}
          />
          <div>
            <br />
            <SaveTemplate
              data={{ filters }}
              isEmptyChecker={this.isEmptyChecker}
              storagePath={`event-${eventId}-checkin-ticket-type-filter`}
              onSaveFilter={this.onSaveFilter}
            />
          </div>
        </Card>
        {!!tableData.length && (
          <SortableTable
            data={tableData}
            dataForCopy={_map(tableData, item => ({
              ...item,
              ticketsNumComponent: `${item.checkedInTicketsCount} / ${item.allTicketsCount} (${item.remainingTicketsCount} remaining)`
            }))}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            detailsRows={dateDetailsRows}
            sortBy={sortBy}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        )}
      </div>
    )
  }
}
