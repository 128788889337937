import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _clone from 'lodash/clone'
import { FETCH_EVENT_SUPPORT, UPDATE_EVENT_SUPPORT } from '../../../_common/redux/eventSupport/actions'
import PermissionDenied from '../../_library/PermissionDeniedComponent'
import SupportForm from './Form'
import LoadingBar from '../../_library/LoadingBar'
import { getTitle } from '../../utils/getTitle'

const EventSupport = ({
  FETCH_EVENT_SUPPORT,
  UPDATE_EVENT_SUPPORT,
  match,
  eventSupport,
  event: { displayName },
  event,
  isRnDomain,
  isLoading,
  configs
}) => {
  useEffect(() => {
    const { id } = match.params
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    if (!isDenied()) {
      FETCH_EVENT_SUPPORT(id)
    }
  }, [])
  const isDenied = () => isRnDomain
  const handleSubmit = values => {
    const { id } = match.params
    const updatedValues = _clone(values)

    if (updatedValues.useBrandContacts) {
      updatedValues.externalSupportEmail = null
      updatedValues.internalSupportEmail = null
    }
    delete updatedValues.useBrandContacts
    return UPDATE_EVENT_SUPPORT(id, updatedValues)
  }
  const denied = isDenied()
  return denied ? (
    <PermissionDenied subHeader="You don't have access to this page" />
  ) : isLoading ? (
    <LoadingBar title={`Hold tight! We're getting your event's details...`} />
  ) : (
    <div className="eventSupport">
      <SupportForm handleSubmit={handleSubmit} submitLabel="Save" eventSupport={eventSupport} event={event}/>
    </div>
  )
}

export default connect(
  state => {
    const eventSupport = _get(state, 'eventSupport.supportInfo')
    const event = _get(state, 'events.selected')
    const isRnDomain = _get(state, 'shared.isRnDomain')
    const isLoading = state.loading.has('FETCH_EVENT_SUPPORT')

    return {
      eventSupport,
      event,
      isRnDomain,
      isLoading
    }
  },
  { FETCH_EVENT_SUPPORT, UPDATE_EVENT_SUPPORT }
)(EventSupport)
