import { baseRequest } from '.'
import _get from 'lodash/get'

export const storage_set = async data =>
  await baseRequest.post(`customer/storage/`, {
    data: {
      attributes: data,
    },
  })

export const storage_get = async (path, name) => {
  const res = await baseRequest.get(`customer/storage/path/${path}/name/${name}/`)
  return _get(res, 'data.data.attributes', {})
}

export const storage_remove = async (path, name) =>
  await baseRequest.delete(`customer/storage/path/${path}/name/${name}/`)

export const storage_all_get = async path => {
  const res = await baseRequest.get(`customer/storage/path/${path}/`)
  return _get(res, 'data.data.attributes.data', [])
}
