import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import Field from '../../_library/Field'

const SimpleField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  onBlurNormalizer = _identity,
  showErrorOnlyTouched = true,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
  }
  const onChange = event => {
    const { value } = event.target
    if (props.onChange) {
      props.onChange(field.name, value, event)
    }
    setValue(value)
  }
  const onBlur = event => {
    const {
      target: { value },
    } = event
    setValue(value, onBlurNormalizer)
    touchedOnBlur && setFieldTouched(field.name, true)
    if (props.onBlur) {
      props.onBlur(value, event)
    }
  }
  return (
    <div className={props.formclassname} style={props.formstyle}>
      <Field
        {...field}
        {...props}
        id={field.name}
        touched={!showErrorOnlyTouched || _get(touched, field.name)}
        error={_get(errors, field.name)}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { SimpleField }
