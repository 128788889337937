import { ROLES } from '../../constants/roles'
import { getPermissions } from './permissions'

export const getEventInteractMenuItems = ({
  permission,
  itemId,
  path,
  flagTimeSlotsEnabled,
  userTfRole,
  enablePromotions,
  enableGiftCertificates,
  flagMultiEntryPasses,
  flagPredefinedInvitations,
  isVerified,
}) => {
  const permissions = getPermissions(permission)
  const { PERMISSION_CURATOR } = permissions
  const childGroup_interact = []
  const isAdminOrOwner = permissions.isAdminOrOwner()
  const isTfSupport = ROLES.is_tf_support(userTfRole)

  childGroup_interact.push({
    img: 'messaging',
    title: 'Messaging',
    href: `/event/${itemId}/messaging`,
    active: path === `/event/${itemId}/messaging`,
    visible: isAdminOrOwner || isTfSupport,
    disabled: !isVerified,
  })
  childGroup_interact.push({
    img: 'invitation',
    title: 'Invitations',
    href: `/event/${itemId}/invitations`,
    active: path === `/event/${itemId}/invitations`,
    visible: isAdminOrOwner || isTfSupport || PERMISSION_CURATOR,
    disabled: !isVerified,
  })
  childGroup_interact.push({
    img: 'guest-tickets',
    title: 'Guest Tickets',
    href: `/event/${itemId}/guest-tickets`,
    active: path === `/event/${itemId}/guest-tickets`,
    visible: isAdminOrOwner || isTfSupport || PERMISSION_CURATOR,
  })
  childGroup_interact.push({
    img: 'membership',
    title: 'Membership',
    href: `/event/${itemId}/membership`,
    active: path === `/event/${itemId}/membership`,
    visible: (isAdminOrOwner || isTfSupport) && flagTimeSlotsEnabled,
  })
  childGroup_interact.push({
    img: 'guest-tickets',
    title: 'Gift Certificates',
    href: `/event/${itemId}/gift-certificates`,
    active: path === `/event/${itemId}/gift-certificates`,
    visible: (isAdminOrOwner || isTfSupport || PERMISSION_CURATOR) && enableGiftCertificates,
  })
  childGroup_interact.push({
    img: 'promotions',
    title: 'Promotions',
    href: `/event/${itemId}/promotions`,
    active: path === `/event/${itemId}/promotions`,
    visible: enablePromotions,
  })
  childGroup_interact.push({
    img: 'email-templates',
    title: 'Email Templates',
    href: `/event/${itemId}/email-templates`,
    active: path === `/event/${itemId}/email-templates`,
    visible: isAdminOrOwner || isTfSupport,
  })
  childGroup_interact.push({
    img: 'invitation',
    title: 'Predefined Invitations',
    href: `/event/${itemId}/predefined-invitations`,
    active: path === `/event/${itemId}/predefined-invitations`,
    visible: (isAdminOrOwner || isTfSupport) && flagPredefinedInvitations,
  })

  return childGroup_interact
}
