/* eslint-disable react/no-unescaped-entities */
// Re-render Optimization 14.05.2020
import React from 'react'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import Modal from 'react-modal'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Select from 'react-select'

import modalStyle from '../../_common/core/modalStyle'
import Notifications from '../_library/notifications/Notifications'
import Button from '../_library/Button'
import EmptyBar from '../_library/EmptyBar'
import LoadingBar from '../_library/LoadingBar'

import {
  PIN_EVENT,
  UPDATE_EVENT_STATUS,
  DUPLICATE_EVENT,
  DELETE_EVENT,
  FETCH_EVENTS_INCREMENTAL,
} from '../../_common/redux/events/actions'
import EventRow from './_library/EventRow'
import { ROLES } from '../constants/roles'
import { ENV } from '../constants/env'
import { getBrandRoles } from '../../_common/core/http_services'

import {
  JSONDatatable,
  TYPE_FROM_ARRAY,
  SEARCHBAR,
  DATATABLE,
  PAGINATIONBAR,
} from '../_library/JSONDatatable'
import DropDownButton from '../_library/DropDownButton'
import Clipboard from '../_library/Clipboard'
import { isFullVerified } from '../utils/checkVerification'
import DownloadQrCodeButton from '../_library/DownloadQrCodeButton'
import EventDuplicateModal from './EventDuplicateModal'
import ConfirmModal from '../_library/ConfirmModal'
import EventCustomContextMenu from './_library/EventCustomContextMenu'
import moment from 'moment'
import { getTitle } from '../utils/getTitle'
import { showAxiosError } from '../utils/messenger'

const SORT_BY_NAME = 0
const SORT_BY_DATE = 1
const DATATABLE_SORT = { index: SORT_BY_DATE, asc: false }

const Tabs = [
  { name: 'All', filterFn: () => true, icon: 'all' },
  { name: 'Active', filterFn: event => event.status === 'published', icon: 'active' },
  {
    name: 'Upcoming',
    filterFn: event => moment.utc(new Date(event.startDate)).isAfter(moment()),
    icon: 'upcoming',
  },
  {
    name: 'Past',
    filterFn: event => moment.utc(new Date(event.startDate)).isBefore(moment()),
    icon: 'event-past',
  },
  { name: 'Archived', filterFn: event => event.status !== 'published', icon: 'archived' },
]

@withRouter
@connect(
  state => ({
    events: state.events.events,
    eventsInitial: state.events.initial,
    fullReady: state.events.fullReady,
    user: state.auth.user,
    dynamicConfigs: state.launchDarklyInfo.launchDarklyFlags,
    pinLoading: state.loading.has('PIN_EVENT'),
    isRnDomain: _get(state.shared, 'isRnDomain'),
    isMcDomain: _get(state.shared, 'isMcDomain'),
    MANA_CONSUMER: _get(state.shared, 'MANA_CONSUMER'),
  }),

  { PIN_EVENT, UPDATE_EVENT_STATUS, DUPLICATE_EVENT, DELETE_EVENT, FETCH_EVENTS_INCREMENTAL },
)
export default class EventList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filter_brand: [],

      // Publish Event
      loading: {},
      selectedEvent: null,
      isPublish: false,
      showConfirmModal: false,
      showVerificationModal: false,
      pinLoadingId: null,
      showDuplicateEventModal: false,
      brandRoles: [],
      showDeleteConfirmationModal: false,
      deleting: false,
      filterFn: () => true,
      selectedTab: 0,
    }
  }

  componentDidMount() {
    this.onFilterBrand(JSON.parse(localStorage.getItem('EventPagesFilterByBrand') || '[]'))
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
    this.fetchBrandRoles()
  }

  fetchBrandRoles = async () => {
    try {
      const brandRoles = await getBrandRoles()
      this.setState({ brandRoles })
    } catch (error) {
      showAxiosError(error)
    }
  }

  handleDuplicateEvent = event => {
    this.setState({
      showDuplicateEventModal: true,
      selectedEvent: event,
    })
  }

  handleContextClick = event => {
    this.setState({
      showDeleteConfirmationModal: true,
      selectedEvent: event,
    })
  }

  closeDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmationModal: false,
      selectedEvent: null,
    })
  }

  deleteSelectedEvent = () => {
    const { DELETE_EVENT } = this.props
    const { selectedEvent } = this.state
    this.setState(
      {
        deleting: true,
      },
      () =>
        selectedEvent &&
        Promise.resolve(DELETE_EVENT(selectedEvent?.id))
          .then(event => {
            this.setState({ selectedEvent: null, deleting: false, showDeleteConfirmationModal: false })
            return event
          })
          .catch(e => {
            this.setState({ deleting: false, showDeleteConfirmationModal: false })
          }),
    )
  }

  handleDeleteConfirmationModal = value => {
    switch (value) {
      case 'ok': {
        this.deleteSelectedEvent()
        break
      }
      case 'cancel': {
        this.closeDeleteConfirmationModal()
        break
      }
      default:
        break
    }
  }

  handleDuplicateSubmit = (eId, data) => {
    const {
      history: { push },
      DUPLICATE_EVENT,
    } = this.props
    this.setState(
      {
        cloning: true,
      },
      () =>
        Promise.resolve(DUPLICATE_EVENT(eId, data))
          .then(event => {
            const id = _get(event, 'id')
            fbq('trackCustom', 'CreateEventSuccessful', {
              eventId: id,
            })
            const next = id ? `/event/${id}/details` : '/events'
            form_helper_reset()
            push(next)
            this.setState({ cloning: false })
            return event
          })
          .catch(e => {
            this.setState({ cloning: false }, () => {
              this.resetDuplicateForm()
            })
          }),
    )
  }

  resetDuplicateForm() {
    const event = new CustomEvent('duplicate-error')
    document.dispatchEvent(event)
  }

  handleCloseDuplicateEvent = () => {
    this.setState({
      showDuplicateEventModal: false,
      selectedEvent: null,
    })
  }

  switchEvent = event => {
    this.setState({
      selectedEvent: event,
    })
  }

  componentDidUpdate(prevProps) {
    const { pinLoading } = this.props
    if (prevProps.pinLoading && pinLoading) {
      this.setState({ pinLoadingId: null })
    }
  }

  handlePublish = (event, isPublish) => {
    const { user, configs } = this.props
    const canSkipIDVerification = _get(configs, 'behavior.skipIDVerificationStep', false)

    this.setState({
      selectedEvent: event,
      isPublish,
    })
    const isVerified = isFullVerified(_get(user, 'verifyInfo'))
    if (!isVerified && !canSkipIDVerification) {
      this.setState({
        showVerificationModal: true,
      })
    } else {
      this.setState({
        showConfirmModal: true,
      })
    }
  }

  handleCloseVerificationModal = () => {
    this.setState({ showVerificationModal: false })
  }

  handleIDVerify = () => {
    const {
      history: { push },
    } = this.props
    push('/account')
  }

  handleCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false })
  }

  handleProcessEvent = () => {
    const { isPublish } = this.state
    this.handleCloseConfirmModal()
    this.processPublish(isPublish)
  }

  processPublish = async isPublish => {
    const { UPDATE_EVENT_STATUS } = this.props
    const { selectedEvent } = this.state

    const status = isPublish ? 'published' : 'unpublished'

    const loadingSetter = value => () => {
      this.setState({
        loading: {
          ...this.state.loading,
          [selectedEvent.id]: value,
        },
      })
    }

    try {
      loadingSetter(true)()
      await UPDATE_EVENT_STATUS(selectedEvent.id, status)
    } finally {
      loadingSetter(false)()
    }
  }

  getFilteredRows = (rows, search) => {
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) !== -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if (keyword !== '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += isFound(item.displayName, keyword)
        if (item.venue) {
          found += isFound(item.venue.displayName, keyword)
          found += isFound(item.venue.city, keyword)
        }
        if (item.owner.displayName) {
          found += isFound(item.owner.displayName, keyword)
        }
        return found > 0
      })
    }

    // filter by brand
    const { filter_brand } = this.state
    if (filter_brand.length > 0) {
      rows_filtered = _filter(rows_filtered, evt => {
        let found = 0
        const brand = evt.owner
        for (let i = 0; i < filter_brand.length; i++) {
          found += brand.id === filter_brand[i].value ? 1 : 0
        }
        return found > 0
      })
    }
    return rows_filtered
  }

  getSortedRows = (rows_filtered, sort) => {
    const dir = sort.asc ? 'asc' : 'desc'
    switch (sort.index) {
      case SORT_BY_NAME: {
        const sortedData = _orderBy(rows_filtered, row => row.displayName.trim().toLowerCase(), dir)
        rows_filtered = _orderBy(
          sortedData,
          row => {
            const returnForSort = row.defaultPinned ? true : row.pinned && !row.skipSort
            return returnForSort
          },
          'desc',
        )
        break
      }

      case SORT_BY_DATE: {
        const sortedData = _orderBy(rows_filtered, row => row.startDate, dir)
        rows_filtered = _orderBy(
          sortedData,
          row => {
            const returnForSort = row.defaultPinned ? true : row.pinned && !row.skipSort
            return returnForSort
          },
          'desc',
        )
        break
      }

      default:
        break
    }
    return rows_filtered
  }

  handleEventPin = (id = null, pinned = false) => {
    const { PIN_EVENT } = this.props
    this.setState({ pinLoadingId: id })
    PIN_EVENT(id, pinned)
  }
  getTableData = (datatable, rows_filtered, sort) => {
    const { fullReady, isRnDomain, isMcDomain, user, pinLoading, configs, MANA_CONSUMER, dynamicConfigs } =
      this.props
    const userTfRole = _get(user, 'tfRole')
    const { pinLoadingId } = this.state
    const isMobile = window.innerWidth < 768
    const self = this
    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: 'Name', sort: true },
        { title: 'Date', sort: true },
      ],
      sort,
    )
    const rows = rows_filtered.map(event => {
      const { id, status, slug } = event
      const { previewKey } = event
      const published = status === 'published'
      const loading = !!self.state.loading[id]

      const userId = _get(event, 'self.id') || ''
      const ownerAdmin = _get(event, 'owner.id')
      const permission = !!event ? event.self?.role : ''
      let redirectPath = '/performance'
      const supportRedirectPath = '/orders'

      if (permission === 'onsite') {
        redirectPath = '/checkin'
      }
      if (permission === 'curator') {
        redirectPath = '/invitations'
      }
      const { enableEventDuplicate = false } = this.props.configs?.appearance || {}
      const isAdmin = permission === 'admin'
      const isOwner = permission === 'owner'
      const isSupport = ROLES.is_support_or_partial_support(permission)
      const promoterLink = event.self?.referral_link ? event.self?.referral_link : ''
      const isBrandOwnerAdmin = this.state.brandRoles.includes(ownerAdmin)
      const isTfSupport = ROLES.is_tf_support(userTfRole)
      const isTfSupportAdminOrClientAdmin = ROLES.is_tf_support_admin_or_client_admin(userTfRole)
      const hideDeleteButton = _get(configs, 'appearance.hideDeleteButton')

      return (
        <EventRow
          key={id}
          event={event}
          autoRefresh={20 * 1000}
          dynamicConfigs={dynamicConfigs}
          configs={configs}
          promoterLink={promoterLink}
          isMobile={isMobile}
        >
          <div className="btn-toolbar">
            <Link
              className="btn btn-lightpurple event-button-sm"
              to={'/event/' + event.id + (isSupport ? supportRedirectPath : redirectPath)}
            >
              <img alt="nodata" src={asset('/resources/images/eventmanage-ico.svg')} />
              Manage
            </Link>

            {(isAdmin || isOwner || isTfSupportAdminOrClientAdmin) && (
              <Link className="btn btn-blue event-button-sm" to={'/event/' + event.id + '/details'}>
                <img alt="nodata" src={asset('/resources/images/edit-ico.svg')} />
                Edit
              </Link>
            )}
            {(isTfSupport || (isBrandOwnerAdmin && !isRnDomain)) && enableEventDuplicate && (
              <Button
                className="btn btn-primary event-button-sm"
                type="button"
                onClick={() => this.handleDuplicateEvent(event)}
                loading={loading}
              >
                <img alt="nodata" src={asset('/resources/images/content-duplicate.svg')} />
                Duplicate Event
              </Button>
            )}
            {!!published && (
              <a
                className="btn btn-seablue event-button-sm"
                href={
                  isRnDomain
                    ? `${ENV.RN_CONSUMER}/events/${slug}/`
                    : isMcDomain
                    ? `${MANA_CONSUMER}/events/${slug}/`
                    : `${ENV.API_CONSUMER}/event/${slug}/`
                }
                target="_blank"
                rel="noreferrer"
              >
                <img alt="nodata" src={asset('/resources/images/viewpage-ico.svg')} />
                View Page
              </a>
            )}
            {!published && (isAdmin || isOwner || isTfSupport) && previewKey && (
              <a
                className="btn btn-seablue event-button-sm"
                href={
                  isRnDomain
                    ? `${ENV.RN_CONSUMER}/events/${slug}/?pk=${previewKey}`
                    : isMcDomain
                    ? `${MANA_CONSUMER}/events/${slug}/?pk=${previewKey}`
                    : `${ENV.API_CONSUMER}/event/${slug}/?pk=${previewKey}`
                }
                target="_blank"
                rel="noreferrer"
              >
                <img alt="nodata" src={asset('/resources/images/viewpage-ico.svg')} />
                Preview Page
              </a>
            )}

            <Clipboard
              text={promoterLink}
              containerStyle={{ display: 'inline-flex', padding: 0, border: 'none' }}
              containerClassName="btn event-button-sm"
              isMobile={isMobile}
            >
              <div className="btn event-button-sm" style={{ backgroundColor: '#7784aa', width: '100%' }}>
                <i className="fa fa-files-o" aria-hidden="true" />
                Promoter Link
              </div>
            </Clipboard>

            {event.self?.referral_link && (
              <DownloadQrCodeButton
                label="Promoter Link QR Code"
                className="btn btn-blue event-button-sm qr-button"
                value={promoterLink}
                size={192}
                id={`promoter-qr-code-${id}-${userId}`}
              />
            )}
            {!published && (isAdmin || isOwner || isTfSupportAdminOrClientAdmin) && (
              <Button
                className="btn btn-ok event-button-sm"
                type="button"
                onClick={() => self.handlePublish(event, true)}
                loading={loading}
              >
                <img alt="nodata" src={asset('/resources/images/publish-ico.svg')} />
                Publish
              </Button>
            )}
            {!!published && (isAdmin || isOwner || isTfSupportAdminOrClientAdmin) && (
              <Button
                className="btn btn-danger event-button-sm"
                type="button"
                onClick={() => self.handlePublish(event, false)}
                loading={loading}
              >
                <img alt="nodata" src={asset('/resources/images/unpublish-ico.svg')} />
                Unpublish
              </Button>
            )}
            {!hideDeleteButton && (
              <EventCustomContextMenu
                showContextMenu={true}
                onInnerClick={() => this.handleContextClick(event)}
              />
            )}
            <span className="event-pin-option" onClick={() => this.handleEventPin(event.id, event.pinned)}>
              {pinLoading && pinLoadingId === event.id ? (
                <i className="fa fa-circle-o-notch fa-spin loading_spinner" />
              ) : (
                <img
                  src={asset(`/resources/images/${event.pinned ? 'pinned' : 'pushforpin'}.svg`)}
                  alt=""
                  title=""
                />
              )}
            </span>
          </div>
        </EventRow>
      )
    })

    if (this.props.events.length) {
      return (
        <div className="events-table">
          <div className="table-responsive">
            <div>{!fullReady && <p className="loading-dots">We're getting your full event list</p>}</div>
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="clearfix">
                      <div className="eventslist-thumbstitle">Event Details</div>
                      <div className="eventslist-actions eventsgrid-title">Event Status</div>
                    </div>
                  </th>
                </tr>
                <div className="header-content">
                  {content_header}
                  {
                    <div className="tab-container">
                      {Tabs.map(({ name, filterFn, icon }, index) => {
                        const { selectedTab } = this.state
                        const selected = index === selectedTab
                        return (
                          <div
                            className="tab-option"
                            style={{
                              borderBottomColor: selected ? '#EF9C68' : 'transparent',
                              color: selected ? '#EF9C68' : '#EEEEEE',
                            }}
                            onClick={() => {
                              this.setState({ filterFn, selectedTab: index })
                            }}
                          >
                            <img
                              alt=""
                              src={asset(
                                `/resources/images/system_icons/${selected ? '' : 'inactive/'}${icon}.svg`,
                              )}
                              height={13}
                            />
                            {name}
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              </thead>
              <tbody>{rows}</tbody>
            </table>
            <div>
              {!fullReady && rows_filtered.length > 2 && (
                <p className="loading-dots">We're getting your full event list</p>
              )}
            </div>
          </div>
        </div>
      )
    } else if (!fullReady) {
      return <div>{!fullReady && <p className="loading-dots">We're getting your full event list</p>}</div>
    }
    return <EmptyBar />
  }

  onFilterBrand = brand => {
    this.setState({ filter_brand: brand })
    localStorage.setItem('EventPagesFilterByBrand', JSON.stringify(brand))
  }

  render() {
    const { dynamicConfigs, fullReady, eventsInitial, events, user, isRnDomain, configs } = this.props
    const {
      showVerificationModal,
      showConfirmModal,
      isPublish,
      showDuplicateEventModal,
      selectedEvent,
      cloning,
      deleting,
      filterFn,
    } = this.state
    const hideOnlineButton = _get(configs, 'appearance.hideOnlineButton', false)

    const showOnlineEventButton = dynamicConfigs ? dynamicConfigs.show_online_events : false
    const userTfRole = _get(user, 'tfRole')
    const isTfSupport = ROLES.is_tf_support(userTfRole)

    const eventsPublished = _filter(events, event => {
      const permission = !!event ? event.self?.role : ''
      const isAdmin = permission === 'admin'
      const isOwner = permission === 'owner'
      const showAll = isAdmin || isOwner || isTfSupport
      const showPublishedOnly = event.status === 'published'
      const shouldDisplay = showAll || showPublishedOnly
      return shouldDisplay
    })

    const eventsFiltered = eventsPublished.filter(filterFn)

    // get brands list
    const { filter_brand } = this.state
    let select_filter_brands = []
    _map(eventsFiltered, (evt, i) => {
      const brand = evt.owner
      if (!brand) return
      select_filter_brands.push({ value: brand.id, label: brand.displayName })
    })
    select_filter_brands = _orderBy(
      _uniqBy(select_filter_brands, brand => brand.value),
      brand => brand.label?.toLowerCase(),
    )
    return (
      <div className="body-main events-page">
        <Notifications />
        <EventDuplicateModal
          showDuplicateEventModal={showDuplicateEventModal}
          handleCloseDuplicateEvent={this.handleCloseDuplicateEvent}
          onSubmit={this.handleDuplicateSubmit}
          selectedEvent={selectedEvent}
          events={events}
          switchEvent={this.switchEvent}
          isRnDomain={isRnDomain}
          configs={_get(configs, 'children.EventDuplicateModal')}
        />
        <div className="delete-confirm-modal">
          <ConfirmModal
            isOpen={this.state.showDeleteConfirmationModal}
            header={'Delete Event'}
            content={'Please confirm that you want to delete this event?'}
            actions={[
              { value: 'ok', label: 'Ok', className: 'btn btn-success btn-shadow' },
              { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel btn-shadow' },
            ]}
            classNames={{
              modal: 'modal-dialog modal-trans ',
              container: 'modal-dialog',
            }}
            onAction={this.handleDeleteConfirmationModal}
          />
          {deleting && (
            <Modal
              className="modal-dialog modal-trans"
              style={modalStyle}
              isOpen={deleting}
              contentLabel="Modal"
              ariaHideApp={false}
            >
              <LoadingBar
                className="modal-dialog modal-trans"
                title={"Hold tight! We're getting your event list..."}
              />
            </Modal>
          )}
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showVerificationModal}
          contentLabel="Modal"
          onRequestClose={this.handleCloseVerificationModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">ID Verification Needed</p>
                </div>
                <div className="modal-body">
                  <p>You need to verify your account in order to publish an event.</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => this.handleIDVerify()}
                    >
                      Verify
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={this.handleCloseVerificationModal}
                    >
                      Not Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showConfirmModal}
          contentLabel="Modal"
          onRequestClose={this.handleCloseConfirmModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Are you sure?</p>
                </div>
                <div className="modal-body">
                  <p>Please confirm that you want to {isPublish ? 'publish' : 'unpublish'} this event?</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.handleProcessEvent}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={this.handleCloseConfirmModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div>
          <div className="body-panel-header clearfix">
            <div className="left inline">
              <div className="title">Events</div>
            </div>
            <div className="right">
              {showOnlineEventButton ? (
                <DropDownButton
                  label={'Create New Event'}
                  options={[
                    {
                      label: 'Physical Event',
                      value: '/events/new',
                    },
                    {
                      label: 'Online Event',
                      value: '/events/new-online',
                    },
                  ].filter(option => (hideOnlineButton ? option.value !== '/events/new-online' : true))}
                />
              ) : (
                <div className="btn btn-success">
                  <Link className="" to="/events/new">
                    <img alt="nodata" src={asset('/resources/images/plus-ico.svg')} className="plus-ico" />{' '}
                    Create New Event
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="body-panel-spacing" />
          <div className="body-panel-content">
            {eventsInitial && <LoadingBar title={"Hold tight! We're getting your event list..."} />}
            {fullReady && eventsPublished.length === 0 && !eventsInitial && <EmptyBar />}
            {eventsPublished.length > 0 && (
              <JSONDatatable
                ref="JSONDatatable"
                sort={DATATABLE_SORT}
                type={TYPE_FROM_ARRAY}
                data={eventsFiltered}
                getSortedRows={this.getSortedRows}
                getFilteredRows={this.getFilteredRows}
                getTableData={this.getTableData}
                usePagination={true}
                paginationPageSize={50}
                loadingBarTitle={"Hold tight! We're getting your event list..."}
                saveSearchKey={'EventsPage'}
                hideTotalCount={!fullReady}
              >
                {/* It can give additional className to SEARCHBAR, DATATABLE, PAGINATIONBAR by specifying className="XXX" */}
                <div
                  className="filters-size right inline filter-box-"
                  ref={SEARCHBAR}
                  hasSearch
                  autoFocus
                  triggerScroll
                  labelTotalCount="Number of Matching Events"
                />
                <div className="filter_row brand-filter-holder inline filters-size filter-box-">
                  <div className="row">
                    <div className="brand-filters col-xs-12">
                      <label htmlFor={'labelFilterBrand'}>Filter by Brand</label>
                      <Select
                        id={'labelFilterBrand'}
                        multi={true}
                        value={filter_brand}
                        options={select_filter_brands}
                        onChange={this.onFilterBrand}
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div ref={DATATABLE} />
                <div ref={PAGINATIONBAR} />
              </JSONDatatable>
            )}
          </div>
        </div>
      </div>
    )
  }
}
