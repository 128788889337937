import React from 'react'
import { Field } from 'formik'
import _get from 'lodash/get'

import Card from '../../../_library/Card'
import { Map } from '../../../_library/Map'
import countries from '../../../../_common/core/countries'
import RichTextArea from '../../../_library/RichTextArea'

import { parsePlaceResult } from '../EventForm/utils'
import { ENV } from '../../../constants/env'
import EventVenueSearch from '../EventForm/Venue/EventVenueSearch'

import {
  SimpleField,
  CountryField,
  DateTimePickerFieldReact,
  CustomToggleField,
} from '../../../formik/Fields'

import {
  requiredValidator,
  maxLengthValidator,

  combineValidators,
} from '../../../../_common/core/validation'

import { AltViewCheckboxField } from './AltViewCheckboxField'

const rsvpBlockBreakPointWidth = 991

export default class EventVenue extends React.Component {
  constructor(props) {
    super(props)
    const {
      city,
      country,
      googlePlaceId,
      id,
      latitude,
      longitude,
      postalCode,
      state,
      street,
      streetNumber,
      venueName,
    } = props.values

    this.state = {
      venue:
        latitude && longitude
          ? {
            city,
            country,
            googlePlaceId,
            id,
            latitude,
            longitude,
            postalCode,
            state,
            street,
            streetNumber,
            venueName
          }
          : null,
      address: null,
      geocoding: false,
      geocoder: global.google ? new google.maps.Geocoder() : null,
      rsvpInfoContent: props.values.rsvpAlternativeInformation,
      isMdSize: window.innerWidth <= rsvpBlockBreakPointWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.handleWindowResize)
  }

  handleChangeLocationInfo = val => {
    const { setFieldValue } = this.props
    setFieldValue('locationInformation', val)
  }

  updateVenue = venue => {
    this.setState({ venue })
  }

  handleWindowResize = e => {
    const { isMdSize } = this.state
    const { innerWidth } = e.target
    if(innerWidth <= rsvpBlockBreakPointWidth) {
      !isMdSize && this.setState({
        isMdSize: true
      })
    } else {
      isMdSize && this.setState({
        isMdSize: false
      })
    }
  }

  updateGeocoder = e => {
    // this is a bad code
    const { values, setFieldValue } = this.props
    const { geocoder } = this.state

    if (!geocoder) {
      return
    }

    const streetLine = values.street
      ? [values.streetNumber, values.street].filter(Boolean).join(' ')
      : ''
    const cityLine = [values.city, values.state].filter(Boolean).join(' ')
    const countryLine = [values.country, values.postalCode].filter(Boolean).join(' ')
    const address = [streetLine, cityLine, countryLine].filter(Boolean).join(', ')

    if (this.state.address === address) {
      return
    }

    setFieldValue('googlePlaceId', undefined)
    setFieldValue('longitude', undefined)
    setFieldValue('latitude', undefined)
    this.setState({ address })
    if (!address) {
      return
    }

    this.setState({ geocoding: true })
    const p = new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        const { OK, ZERO_RESULTS, REQUEST_DENIED } = google.maps.GeocoderStatus
        this.setState({ geocoding: false })
        if (status === ZERO_RESULTS || status === REQUEST_DENIED) {
          resolve({ query: address, venue: null })
          return
        }
        if (status !== OK) {
          reject(status)
        }
        resolve({ query: address, venue: parsePlaceResult(results[0]) })
      })
    })

    p.then(res => {
      const { query, venue } = res
      if (!venue) {
        return
      }
      if (query === address) {
        setFieldValue('googlePlaceId', venue.googlePlaceId)
        setFieldValue('longitude', venue.longitude)
        setFieldValue('latitude', venue.latitude)
        this.setState({ venue: { ...venue, id: venue.googlePlaceId } })
      }
      return venue
    }).catch(err => {
      console.error('Geocoder error', err)
      throw err
    })
  }

  render() {
    const { event, values, setFieldValue, closed, configs } = this.props
    const { venue } = this.state
    return (
      <Card
        className="alternative-view-venue-details"
        icon={'fa-envelope-open'}
        title={'Venue Details'}
        closed={closed}
      >
        <div className="row checkbox-row-with-detail">
          <div className="col-xs-1 col-1 left-column">
            <Field name="venueDetailsCheck" event={event} values={values} component={AltViewCheckboxField} />
          </div>
          <div className="col-xs-11 col-11 right-column">
            <span>Override event's venue details</span>
          </div>
        </div>
        <div className={'row' + (!values.venueDetailsCheck ? ' disable-element' : ' ')} onChange={() => this.updateGeocoder}>
          <div className="col-md-5 venues-left">

            <EventVenueSearch
              refs={this.refs}
              values={values}
              venue={venue}
              updateVenue={this.updateVenue}
              updateField={setFieldValue}
            />

            <Field
              name="venueName"
              label="Venue name"
              component={SimpleField}
              validate={values.venueDetailsCheck?combineValidators(requiredValidator(), maxLengthValidator(undefined, 100)):null}
            />

            <Field
              name="streetNumber"
              label="Street Number"
              component={SimpleField}
            />

            <Field
              name="street"
              label="Street"
              component={SimpleField}
            />

            <Field
              name="city"
              label="City"
              component={SimpleField}
            />

            <div className="row">
              <div className="col-xs-6">
                <Field
                  name="state"
                  label="State"
                  component={SimpleField}
                />
              </div>

              <div className="col-xs-6">
                <Field
                  name="postalCode"
                  label="Postal Code"
                  component={SimpleField}
                />
              </div>

            </div>

            <Field
              name="country"
              label="Country"
              options={countries}
              component={CountryField}
              validate={values.venueDetailsCheck?requiredValidator():null}
            />

            <div style={{ marginTop: -15, padding: '0 5px' }}>
              <div style={{ margin: '10px 0' }}>
                <Field
                  name="flagDisabled"
                  offText="Disabled access"
                  component={CustomToggleField}
                />
              </div>
              <div style={{ margin: '10px 0' }}>
                <Field
                  name="flagHidden"
                  offText={_get(configs, 'messages.hideToggleLabel')}
                  component={CustomToggleField}
                />
              </div>
              {values.flagHidden && (
                <div style={{ padding: '5px 5px 0', marginBottom: -20 }}>
                  <Field
                    name="hideUntil"
                    label="Location Disclosure Date (optional)"
                    placeholder="D MMM YYYY H:M AM"
                    inModal={true}
                    container="alternative-view"
                    component={DateTimePickerFieldReact}
                  />
                </div>
              )}
            </div>

            <div ref="gattr" />

          </div>
          <div className={`col-xs-12 location-info-top ${values.flagHidden ? "mg-30-responsive" : ""}`}>
            <RichTextArea
              ref={'locationInformation'}
              id={'locationInformation'}
              label="Location Information"
              baseurl={ENV.CDN_URL}
              value={values.locationInformation}
              onChange={this.handleChangeLocationInfo}
              placeholder={{ text: 'Enter text here...' }}
            />
          </div>
          <div className="col-md-7 venues-right">
            <div className="venue-map">
              <Map
                locations={[venue ? { ...venue, displayName: venue.venueName } : null].filter(
                  Boolean
                )}
              />
            </div>
          </div>
        </div>
      </Card>
    )
  }
}
