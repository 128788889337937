import React from 'react'

// components
import Button from '../_library/Button'


class ConnectToShopify extends React.Component {
  state = {
    openConnectSection: false
  }
  openOrCloseModal = (isOpen) => {
    this.setState(() => ({ openConnectSection: isOpen }))
  }

  onConnect = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const url = e.target.shopify_url.value
    this.props.onSubmit(url)
  }

  render() {
    const { openConnectSection } = this.state
    const { shopifyConnectLoading } = this.props
    return (
      <div>
        {openConnectSection ? (
          <div>
            <form onSubmit={this.onConnect}>
              <div>
                <label style={{ marginRight: 10 }}>Shopify URL</label>
                <input
                  required
                  name='shopify_url'
                  type="url"
                  placeholder='Shopify URL'
                />
                <Button
                  loading={shopifyConnectLoading}
                  style={{ marginLeft: 10 }}
                  type='submit'
                  className="btn btn-default"
                >
                  Connect
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type='button'
                  className="btn btn-cancel"
                  onClick={() => this.openOrCloseModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <Button
            className="btn btn-default"
            type='button'
            onClick={() => this.openOrCloseModal(true)}
          >
            Connect To Shopify
          </Button>
        )}

      </div>)
  }
}


export { ConnectToShopify }