import React from 'react'
import { Field } from 'formik'
import { DateTimePickerFieldReact } from '../../../formik/Fields'

const DoorsTimeRow = () => (
  <>
    <div className="col-xs-6 col-6 bottom-field-ticket-form" id="checkin-start">
      <Field
        name="checkInStart"
        label="Doors open time (optional)"
        placeholder="D MMM YYYY H:M AM"
        component={DateTimePickerFieldReact}
      />
    </div>
    <div className="col-xs-6 col-6 bottom-field-ticket-form" id="checkin-end">
      <Field
        name="checkInEnd"
        label="Last entry time (optional)"
        placeholder="D MMM YYYY H:M AM"
        component={DateTimePickerFieldReact}
      />
    </div>
  </>
)

export default DoorsTimeRow
