import _get from 'lodash/get'
import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect } from 'react-redux'
import { asyncComponent } from '../hoc'

const PDF = asyncComponent(() => import('../_library/EventReportPDF'))
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import { FETCH_EVENT_PERFORMANCE } from '../../_common/redux/performance/actions'
import { FETCH_EVENT_INFLUENCERS } from '../../_common/redux/influencers/actions'
import { FETCH_BRANDS } from '../../_common/redux/brands/actions'

import { Tab, TabView } from '../_library/TabView'

import TabIcon from './performance/TabIcon'
import PerformanceSales from './performance/PerformanceSales'
import PerformanceReleaseBreakdown from './performance/PerformanceReleaseBreakdown/index'
import PerformanceCharges from './performance/PerformanceCharges'
import PerformanceDiscountCodeBreakdown from './performance/PerformancePromoCode/index'
import PerformancePromoterSales from './performance/PerformancePromoterSales/index'
import PerformancePasswordBreakdown from './performance/PerformancePasswordBreakdown'
import PerformanceAddOnBreakdown from './performance/PerformanceAddOnBreakdown/index'
import PerformanceWaitingList from './performance/PerformanceWaitingList'
import PerformanceResale from './performance/PerformanceResale'
import PerformanceBoxOfficeSales from './performance/PerformanceBoxOfficeSales'
import PerformancePreRegistration from './performance/PerformancePreRegistration/index'
import PerformanceLockedTickets from './performance/PerformanceLockedTickets'
import PerformanceRsvp from './performance/PerformanceRsvp'
import PerformancePaymentPlans from './performance/PerformancePaymentPlans'
import Clipboard from '../_library/Clipboard'

import { toPascalCase } from '../../_common/core/utils'

import { get_event } from '../../_common/core/selectors'
import { checkIsPromoter, checkIsLimitedStats } from '../utils/permissions'
import { getTitle } from '../utils/getTitle'

const title_all = <TabIcon title="All" img="all-ico" />
const title_sales = <TabIcon title="Ticket Sales" img="ticket-sales-ico" />
const locked_tickets = <TabIcon title="Tickets in Carts" img="tickets-in-carts-ico" />
const title_releasebreakdown = <TabIcon title="Sales by Ticket Type" img="sales-by-type-ico" />
const title_addons = <TabIcon title="Add ons" img="add-ons-ico" />
const title_preregistration = <TabIcon title="Pre-Registration" img="pre-registration-ico" />
const title_waitinglist = <TabIcon title="Waiting List" img="waiting-list-ico" />
const title_resale = <TabIcon title="Ticket Resale" img="tickets-resale-ico" />
const title_discount = <TabIcon title="Sales by Promo Code" img="sales-by-promo-ico" />
const title_promoter = <TabIcon title="Sales by Promoter" img="sales-by-promoter-ico" />
const title_boxofficesales = <TabIcon title="Box Office Sales" img="box-office-sales-ico" />
const title_additionalcharges = <TabIcon title="Additional Charges" img="additional-charges-ico" />
const title_password = <TabIcon title="Sales by Password" img="sales-by-password-ico" />
const title_rsvp = <TabIcon title="RSVP" img="rsvp-ico" />
const title_payment_plans = <TabIcon title="Payment Plans" img="ticket-payment-ico" />

const tabs = event =>
  [
    {
      component: (event, tickets, performance) => (
        <PerformanceSales event={event} performance={performance} />
      ),
      title: title_sales,
      path: 'ticket-sales',
      url: `/event/${event.id}/performance/ticket-sales`,
    },
    {
      component: (event, tickets) => <PerformanceReleaseBreakdown event={event} tickets={tickets} />,
      title: title_releasebreakdown,
      path: 'sales-by-ticket-type',
      url: `/event/${event.id}/performance/sales-by-ticket-type`,
    },
    {
      component: event => <PerformanceAddOnBreakdown event={event} />,
      title: title_addons,
      path: 'add-ons',
      url: `/event/${event.id}/performance/add-ons`,
    },
    {
      component: (event, tickets, performance) => (
        <PerformanceLockedTickets event={event} performance={performance} />
      ),
      title: locked_tickets,
      path: 'tickets-in-carts',
      url: `/event/${event.id}/performance/tickets-in-carts`,
    },
    {
      component: (event, tickets, performance, configs) => (
        <PerformanceWaitingList event={event} configs={configs} />
      ),
      title: title_waitinglist,
      path: 'waiting-list',
      url: `/event/${event.id}/performance/waiting-list`,
    },
    {
      component: event => <PerformanceResale event={event} />,
      title: title_resale,
      path: 'ticket-resale',
      url: `/event/${event.id}/performance/ticket-resale`,
    },
    {
      component: event => <PerformancePromoterSales event={event} />,
      title: title_promoter,
      path: 'sales-by-promoter',
      url: `/event/${event.id}/performance/sales-by-promoter`,
    },
    {
      component: event => <PerformancePreRegistration event={event} />,
      title: title_preregistration,
      path: 'pre-registration',
      url: `/event/${event.id}/performance/pre-registration`,
    },
    {
      component: event => <PerformanceDiscountCodeBreakdown event={event} />,
      title: title_discount,
      path: 'sales-by-promo-code',
      url: `/event/${event.id}/performance/sales-by-promo-code`,
    },
    {
      component: (event, tickets, performance) => (
        <PerformancePaymentPlans event={event} performance={performance} />
      ),
      title: title_payment_plans,
      path: 'payment-plans',
      url: `/event/${event.id}/performance/payment-plans`,
    },
    {
      component: event => <PerformanceBoxOfficeSales event={event} />,
      title: title_boxofficesales,
      path: 'box-office-sales',
      url: `/event/${event.id}/performance/box-office-sales`,
    },
    {
      component: event => <PerformanceCharges event={event} />,
      title: title_additionalcharges,
      path: 'additional-charges',
      url: `/event/${event.id}/performance/additional-charges`,
    },
    {
      component: event => <PerformancePasswordBreakdown event={event} />,
      title: title_password,
      path: 'sales-by-password',
      url: `/event/${event.id}/performance/sales-by-password`,
    },
    {
      component: event => <PerformanceRsvp event={event} />,
      title: title_rsvp,
      path: 'rsvp',
      url: `/event/${event.id}/performance/rsvp`,
    },
  ].filter(Boolean)

const promoter_tabs = event =>
  [
    {
      component: (event, tickets, performance) => (
        <PerformanceSales event={event} performance={performance} />
      ),
      title: title_sales,
      path: 'ticket-sales',
      url: `/event/${event.id}/performance/ticket-sales`,
    },
    {
      component: event => <PerformanceReleaseBreakdown event={event} />,
      title: title_releasebreakdown,
      path: 'sales-by-ticket-type',
      url: `/event/${event.id}/performance/sales-by-ticket-type`,
    },
  ].filter(Boolean)

// event.flagTimeSlotsEnabled && `/event/${event.id}/performance/membership`,

@connect(
  state => {
    const event = get_event(state)
    const { brands } = state.brands
    const eid = _get(event, 'id', '')

    const tickets = _get(state.tickets, `tickets.${eid}`, [])
    let influencers = _get(state.influencers, 'influencers', {})
    if (eid !== state.influencers.eid) {
      influencers = {}
    }

    return {
      event,
      brands,
      tickets,
      influencers,
    }
  },
  { FETCH_EVENT_TICKETS, FETCH_EVENT_PERFORMANCE, FETCH_EVENT_INFLUENCERS, FETCH_BRANDS },
)
export default class EventPerformance extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      event: { displayName },
      match: {
        params: { tabId },
      },
      configs,
    } = props
    this.fetchData = true

    const titlePrefix = tabId ? toPascalCase(tabId) : 'Performance'
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [titlePrefix, displayName])

    this.state = {
      performance: {},
    }
  }

  componentDidMount() {
    const { event, FETCH_BRANDS } = this.props
    this.init(event)
    FETCH_BRANDS()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      event,
      match: {
        params: { tabId },
      },
    } = nextProps

    const updatedState = {}

    if (!_isEmpty(updatedState)) {
      this.setState(() => updatedState)
    }

    if (tabId !== this.props.match.params.tabId) {
      const titlePrefix = tabId ? toPascalCase(tabId) : 'Performance'
      document.title = `${titlePrefix} - ${_get(event, 'displayName', '')} - The Ticket Fairy Dashboard`
    }

    if (_get(event, 'id') !== _get(this.props.event, 'id') || this.props.match.params.tabId !== tabId) {
      clearInterval(this.fetchPerformanceDataIntervalID)
      this.init(event)
    }
  }

  componentWillUnmount() {
    this.unMounted = true
    clearInterval(this.fetchPerformanceDataIntervalID)
  }

  init = event => {
    const { FETCH_EVENT_TICKETS, FETCH_EVENT_PERFORMANCE, FETCH_EVENT_INFLUENCERS } = this.props
    const isPromoter = checkIsPromoter(event)

    if (isPromoter) {
      clearInterval(this.fetchPerformanceDataIntervalID)
      Promise.resolve(FETCH_EVENT_PERFORMANCE(event.id, 'sales', 'datetime')).then(res => {
        const data = _get(res, 'data.$original')
        if (!this.unMounted) {
          this.setState(prevState => ({
            ...prevState,
            performance: {
              ...prevState.performance,
              sales: data.sales,
            },
          }))
        }

        this.fetchPerformanceData('sales')
      })
    } else {
      Promise.resolve(FETCH_EVENT_TICKETS(event.id, { filter: 'all' }))
      Promise.resolve(FETCH_EVENT_INFLUENCERS(event.id))
      clearInterval(this.fetchPerformanceDataIntervalID)
      Promise.resolve(FETCH_EVENT_PERFORMANCE(event.id, 'carts|sales|add_on_breakdown', 'datetime')).then(
        res => {
          const data = _get(res, 'data.$original')
          if (!this.unMounted) {
            this.setState(prevState => ({
              ...prevState,
              performance: {
                ...prevState.performance,
                carts: data.carts,
                sales: data.sales,
                addOns: data.add_on_breakdown,
              },
            }))
          }

          this.fetchPerformanceData('carts|sales')
          this.fetchRebates()
        },
      )
    }
  }

  fetchPerformanceData = sections => {
    const { FETCH_EVENT_PERFORMANCE, event } = this.props
    if (this.unMounted) return
    clearInterval(this.fetchPerformanceDataIntervalID)
    this.fetchPerformanceDataIntervalID = setInterval(async () => {
      try {
        if (this.fetchData) {
          this.fetchData = false
          const result = await FETCH_EVENT_PERFORMANCE(event.id, sections, 'datetime')
          const data = _get(result, 'data.$original')
          this.setState(
            prevState => ({
              ...prevState,
              performance: {
                ...prevState.performance,
                carts: data.carts,
                sales: data.sales,
              },
            }),
            () => {
              if (result) this.fetchData = true
            },
          )
        }
      } catch (err) {
        this.fetchData = true
      }
    }, (sections === 'carts|sales' ? 2 : 5) * 1000)
  }

  fetchRebates = async () => {
    const { FETCH_EVENT_INFLUENCERS, event } = this.props
    try {
      await FETCH_EVENT_INFLUENCERS(event.id)
    } catch (err) {}
  }

  render() {
    const {
      brands,
      match: { params },
      event,
      tickets,
      influencers,
      configs,
    } = this.props
    const { performance } = this.state
    const isLimitedStats = checkIsLimitedStats(event)
    const isPromoter = checkIsPromoter(event)

    const showPaymentPlans = _get(configs, 'appearance.showPaymentPlans', true)
    const showAddOns = _get(configs, 'appearance.showAddOns', true)
    const showPreregistration = _get(configs, 'appearance.showPreregistration', true)
    const showBoxOfficeSales = _get(configs, 'appearance.showBoxOfficeSales', true)
    const showRSVP = _get(configs, 'appearance.showRSVP', true)

    const filterCallback = tab =>
      !(
        (!showPaymentPlans && tab.path === 'payment-plans') ||
        (!showAddOns && tab.path === 'add-ons') ||
        (!showPreregistration && tab.path === 'pre-registration') ||
        (!showBoxOfficeSales && tab.path === 'box-office-sales') ||
        (!showRSVP && tab.path === 'rsvp')
      )

    const tabRoute = params.tabId
    const filteredTabs = isPromoter
      ? promoter_tabs(event).filter(filterCallback)
      : tabs(event).filter(filterCallback)
    const paths = _map(filteredTabs, item => item.url)
    const selectedIndex = tabRoute ? _findIndex(filteredTabs, t => t.path === tabRoute) + 1 : 0
    const promoterLink = event.self?.referral_link ? event.self?.referral_link : ''
    const isMobile = window.innerWidth < 768

    return isLimitedStats ? (
      <div className="performance">
        <PerformanceSales
          event={event}
          performance={performance}
          isLimited={isLimitedStats}
          isSelected={true}
        />
      </div>
    ) : (
      <div className="performance" id="performance">
        {!isPromoter && (
          <PDF
            event={event}
            performance={performance}
            fileName={'performance_' + event.id}
            brands={brands}
            configs={_get(configs, 'children.EventReportPDF')}
          />
        )}
        {isPromoter ? (
          <>
            <Clipboard	
              text={promoterLink}	
              containerStyle={{ display: 'inline-flex', padding: 0, border: 'none' }}	
              containerClassName="btn event-button-sm"	
              isMobile={isMobile}	
            >	
              <div className="btn event-button-sm" style={{ backgroundColor: '#7784aa', width: '100%' }}>	
                <i className="fa fa-files-o" aria-hidden="true" />	
                Promoter Link	
              </div>	
            </Clipboard>
            <div className='div-spacing-15' />
          </>
        ) : null}
        <TabView
          all={true}
          allTitle={title_all}
          headerClassName="performance-tab-header"
          bodyClassName="performance-tab-body"
          passProps
          hasBackground
          hasPerfectScrollBar
          selectedIndex={selectedIndex}
          paths={paths}
          basePath={`/event/${event.id}/performance`}
          event={event}
          performanceData={performance}
          influencersData={influencers}
        >
          {_map(filteredTabs, (t, i) => (
            <Tab key={t.path} title={t.title}>
              {t.component(event, tickets, performance, configs)}
            </Tab>
          ))}
        </TabView>
      </div>
    )
  }
}
