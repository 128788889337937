import React from 'react'
import Modal from 'react-modal'
import _map from 'lodash/map'

import modalStyle from '../../../_common/core/modalStyle'
import LoadingBar from '../../_library/LoadingBar'
import MembershipUsage from './Usage'

export default class UsageModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targetVisitedTicketId: null,
      targetVisitedTicketName: null
    }
  }

  render() {
    const { modalTarget } = this.props
    switch (modalTarget) {
    case 'usageDetails': {
      return this.getUsageDetailsModal()
    }
    case 'addVisit': {
      return this.getRecordCollectedItemModal()
    }
    }
  }

  getUsageDetailsModal = () => {
    const { isOpen, toggleModal, isLoading, ticketDetails, selectedMembership } = this.props
    const { targetVisitedTicketId } = this.state
    let ticketsStatistics = {}
    if (ticketDetails) {
      ticketsStatistics = this.getTicketsStatistics(ticketDetails)
    }
    return (
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={() => toggleModal('UsageDetails')}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-medium2">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">Update Visit</p>
              </div>
              {targetVisitedTicketId ? (
                this.getCollectedItemsUpdatingSection()
              ) : (
                <div>
                  <div className="modal-body">
                    {isLoading ? (
                      <LoadingBar title="Hold tight! We're getting your event membership details..." />
                    ) : (
                      <div>
                        <section className="membership-info membership-details-section">
                          <div className=" membership-details-section_header">
                            {selectedMembership.fullName}
                          </div>
                          <div className=" membership-details-section_body">
                            <div className="membership-details-section_body-row row">
                              {_map(ticketsStatistics.ticketTypes, (item, key) => (
                                <div key={key} className="col-xs-12">
                                  <div className="col-xs-6">{item.name || ''}</div>
                                  <div>
                                    {`${item.sold} tickets are checked in out of ${item.totalCount}`}
                                  </div>
                                </div>
                              ))}
                              {!!ticketsStatistics.freeAdmission.count && (
                                <div className="col-xs-12">
                                  <div className="col-xs-6">Free admission</div>
                                  <div>
                                    {`${ticketsStatistics.freeAdmission.sold} tickets are checked out of ${ticketsStatistics.freeAdmission.count}`}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                        <section className="membership-visits membership-details-section">
                          <div className="membership-details-section_header">Visits</div>
                          <div className="membership-details-section_body">
                            {this.getMembershipVisitsSection()}
                          </div>
                        </section>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={() => toggleModal('UsageDetails')}
                      className="btn btn-cancel align-right"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  getTicketsStatistics = ticketDetails => {
    const statistics = {
      ticketTypes: {},
      freeAdmission: {
        count: 0,
        sold: 0
      }
    }
    ticketDetails.plan.base.forEach(item => {
      statistics.ticketTypes[item.ticketTypeGroupId] = {
        name: item.ticketTypeGroupName,
        totalCount: item.count,
        sold: 0
      }
    })
    ticketDetails.plan.guests.forEach(item => {
      statistics.freeAdmission.count += item.count
    })
    ticketDetails.tickets.forEach(item => {
      if (item.freeAdmission) {
        statistics.freeAdmission.sold += 1
      } else {
        statistics.ticketTypes[item.ticketTypeGroupId].sold += 1
      }
    })
    return statistics
  }

  getMembershipVisitsSection = () => {
    const { membershipVisits } = this.props
    return membershipVisits.length ? (
      membershipVisits.map(item => {
        const data = item.$original
        return (
          <div key={data.id} className="membership-details-section_body-row row">
            <div className="col-xs-12">
              <div className="col-xs-10 ticket_name">{data.ticket.name}</div>
              <div>
                <button
                  onClick={this.handleVisitedTicketUpdate(data.ticket.id, data.ticket.name)}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )
      })
    ) : (
      <p className="text-center">No visits yet</p>
    )
  }

  getRecordCollectedItemModal = () => {
    const { isOpen, isLoading, toggleModal, membershipItems } = this.props
    return (
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={() => toggleModal('RecordCollectedItem')}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-medium">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">Record the collected items</p>
              </div>
              <div className="modal-body">
                {isLoading ? (
                  <LoadingBar title="Hold tight! We're getting your event membership details..." />
                ) : (
                  this.getCollectedItemsAddingSection(membershipItems.$original)
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  getCollectedItemsUpdatingSection = () => {
    const { targetVisitedTicketName, targetVisitedTicketId } = this.state
    const { toggleModal, membershipItems, ticketDetails, membershipVisits } = this.props
    return (
      <MembershipUsage
        targetAction="updateVisit"
        ticketDetails={ticketDetails}
        membershipVisits={membershipVisits}
        ticketId={targetVisitedTicketId}
        ticketName={targetVisitedTicketName}
        onSubmit={this.handleUpdateVisit}
        additionalItems={membershipItems.$original.additionalItems}
        toggleModal={toggleModal}
      />
    )
  }

  getCollectedItemsAddingSection = data => {
    const { toggleModal } = this.props
    const { tickets, additionalItems } = data
    return (
      <MembershipUsage
        targetAction="addVisit"
        onSubmit={this.handleAddVisit}
        tickets={tickets}
        additionalItems={additionalItems}
        toggleModal={toggleModal}
      />
    )
  }

  handleAddVisit = data => {
    const { addMembershipVisit, event, targetMembershipId, toggleModal } = this.props
    const dataToSend = { data: { attributes: { ...data } } }
    addMembershipVisit(event.id, targetMembershipId, dataToSend).then(() => {
      Messenger().post({
        type: 'success',
        message: 'Successfully created!',
        hideAfter: 3,
        showCloseButton: true
      })
      toggleModal('RecordCollectedItem')
    })
  }

  handleUpdateVisit = data => {
    const {
      updateMembershipVisit,
      event,
      targetMembershipId,
      membershipVisits,
      toggleModal
    } = this.props
    const { targetVisitedTicketId } = this.state
    const dataToSend = { data: { attributes: { ticketId: targetVisitedTicketId, ...data } } }
    const selectedVisit = membershipVisits.find(
      item => item.$original.ticket.id === targetVisitedTicketId
    )
    updateMembershipVisit(event.id, targetMembershipId, selectedVisit.id, dataToSend)
    toggleModal('UsageDetails')
  }

  handleVisitedTicketUpdate = (ticketId, ticketName) => () => {
    this.setState(() => ({
      targetVisitedTicketId: ticketId,
      targetVisitedTicketName: ticketName
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(() => ({
        targetVisitedTicketId: null,
        targetVisitedTicketName: null
      }))
    }
  }
}
