import React from 'react'
import moment from 'moment'

const format12Hours = 'hh:mm:ss a'
const format24Hours = 'HH:mm:ss'

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: '0',
      minutes: '0',
      seconds: '0',
      meridiem: 'am',
    }
  }

  componentDidMount() {
    const { value, hideSeconds } = this.props
    const time = value ? moment(value, format24Hours) : moment()
    const { stringHours, stringMinutes, stringSeconds, meridiem } = this.getTimeValues(
      moment(time).format(format12Hours),
    )
    this.setState({
      hours: stringHours,
      minutes: stringMinutes,
      seconds: hideSeconds ? '0' : stringSeconds,
      meridiem,
    })
  }

  getFormatedDate = () => {
    const { hours, minutes, seconds, meridiem } = this.state
    return `${hours}:${minutes}:${seconds} ${meridiem ? meridiem : ''}`
  }

  getTimeValues = (value, updatedMeridiem) => {
    const time = value ? moment(value, format12Hours) : moment(this.getFormatedDate(), format12Hours)
    const hours = Number(moment(time).format('hh'))
    const minutes = time.minutes()
    const seconds = time.seconds()
    const meridiem = updatedMeridiem ? updatedMeridiem : moment(time, format12Hours).format('a')

    const stringHours = hours < 10 && !meridiem ? '0' + hours : `${hours}`
    const stringMinutes = minutes < 10 ? '0' + minutes : `${minutes}`
    const stringSeconds = seconds < 10 ? '0' + seconds : `${seconds}`

    return { hours, minutes, seconds, meridiem, stringHours, stringMinutes, stringSeconds }
  }

  setTimeValue = (id, value) => {
    const { onChange } = this.props
    let { hours, minutes, seconds, meridiem } = this.getTimeValues(null, id === 'meridiem' ? value : '')
    switch (id) {
      case 'hours':
        hours = value
        break
      case 'minutes':
        minutes = value
        break
      case 'seconds':
        seconds = value
        break
      case 'meridiem':
        meridiem = value
        break
      default:
        break
    }

    const time = moment(`${hours}:${minutes}:${seconds} ${meridiem ? meridiem : ''}`, 'h:mm:ss a')
    this.setState({
      [id]: `${value}`,
    })
    onChange(moment(time).format(format24Hours), this.props.id)
  }

  handleChange = e => {
    const ids = e.target.id.split('-')
    const id = ids[0]
    const action = ids[1]

    let { hours, minutes, seconds, meridiem } = this.getTimeValues()
    switch (id) {
      case 'hours':
        if (action === 'add') {
          if (meridiem) {
            hours = hours < 12 ? hours + 1 : 1
          } else {
            hours = hours < 23 ? hours + 1 : 0
          }
        } else {
          hours = hours > 1 ? hours - 1 : meridiem ? 12 : 0
        }
        this.setTimeValue(id, hours)
        break
      case 'minutes':
        if (action === 'add') {
          minutes = minutes < 59 ? minutes + 1 : 0
        } else {
          minutes = minutes > 0 ? minutes - 1 : 59
        }
        this.setTimeValue(id, minutes)
        break
      case 'seconds':
        if (action === 'add') {
          seconds = seconds < 59 ? seconds + 1 : 0
        } else {
          seconds = seconds > 0 ? seconds - 1 : 59
        }
        this.setTimeValue(id, seconds)
        break
      default:
        break
    }
  }

  handleInputChange = e => {
    const { onChange } = this.props
    const { id } = e.target
    let { value } = e.target
    const { meridiem } = this.getTimeValues()

    if (isNaN(value) || value.includes('e') || value === '') {
      if (value === '') {
        onChange('', this.props.id)
        this.setState({ [id]: '' })
      }
      return
    }

    value = Number(value)
    if (value < 0) value = 0
    if (id !== 'hours' && value > 59) value = 59
    if (id === 'hours') {
      if (meridiem) {
        value = value > 12 ? 12 : value
      } else {
        value = value > 23 ? 0 : value
      }
    }
    this.setTimeValue(id, value)
  }

  handleSelectChange = (e, val) => {
    const { id } = e.target
    const { value } = e.target
    this.setTimeValue(id, value)
  }

  render() {
    const { title, hideSeconds, error } = this.props
    const { hours, minutes, seconds, meridiem } = this.state

    return (
      <div className="time-picker-container">
        <div className={`time-picker-label ${error ? 'error' : ''}`}>
          <label>{title}</label>
        </div>
        <div className={`time-picker-input ${error ? 'error' : ''}`}>
          <div className="time-picker-icons">
            <i className="fa fa-caret-up" aria-hidden="true" id="hours-add" onClick={this.handleChange} />
            <input type="text" id="hours" value={hours} onChange={this.handleInputChange} />
            <i className="fa fa-caret-down" aria-hidden="true" id="hours-sub" onClick={this.handleChange} />
          </div>
          <div className="dots">{':'}</div>
          <div className="time-picker-icons">
            <i className="fa fa-caret-up" aria-hidden="true" id="minutes-add" onClick={this.handleChange} />
            <input type="text" id="minutes" value={minutes} onChange={this.handleInputChange} />
            <i className="fa fa-caret-down" aria-hidden="true" id="minutes-sub" onClick={this.handleChange} />
          </div>
          {!hideSeconds && <div className="dots">{':'}</div>}
          {!hideSeconds && (
            <div className="time-picker-icons">
              <i className="fa fa-caret-up" aria-hidden="true" id="seconds-add" onClick={this.handleChange} />
              <input type="text" id="seconds" value={seconds} onChange={this.handleInputChange} />
              <i
                className="fa fa-caret-down"
                aria-hidden="true"
                id="seconds-sub"
                onClick={this.handleChange}
              />
            </div>
          )}
          <div className="dots" />
          <select id="meridiem" onChange={this.handleSelectChange} value={meridiem}>
            <option value={'am'}>am</option>
            <option value={'pm'}>pm</option>
          </select>
        </div>
      </div>
    )
  }
}
