import React from 'react'
import _ from 'lodash'
import Button from '../_library/Button'

import { toPascalCase } from '../../_common/core/utils'

const UsersListItem = ({ item, buttonClass = '', buttonText = '', handleClick }) => {
  const { firstName, lastName, email, rnRoles = [], id, region, image, username, shortBio } = item
  return (
    <div className="users_list_item">
      <div className="user_details_block">
        <img
          className="user_details_block_img"
          src={image || '/resources/images/no_img.png'}
          alt=""
          title=""
        />
        <div className="user_details_block_inner">
          <span className="name_title_block">
            {firstName} {lastName}
          </span>
          {!!username && (
            <span>
              <img src="/resources/images/users/user.svg" alt="" title="" />
              {username}
            </span>
          )}

          <span>
            <img src="/resources/images/users/letter.svg" alt="" title="" />
            {email}
          </span>
          {!!region && (
            <span>
              <img src="/resources/images/users/location-ico.svg" alt="" title="" />
              {toPascalCase(region)}
            </span>
          )}
          {!!shortBio && (
            <span>
              <img src="/resources/images/users/bio.svg" alt="" title="" />
              {shortBio}
            </span>
          )}
        </div>
      </div>
      <Button
        className={`btn btn-lg ${buttonClass}`}
        onClick={() =>
          handleClick(id, {
            rnRoles: rnRoles.includes('curator') ? _.pull(rnRoles, 'curator') : [...rnRoles, 'curator'],
          })
        }
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default UsersListItem
