import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'

import { subtractTwoArrays, makeId } from '../../core/utils'

let URL_FOR_SEAT_MAP_INFO = ''

const { UPLOAD_GUESTTICKETS } = createAsyncAction(
  'UPLOAD_GUESTTICKETS',
  function (eid, recipients, isSeatMap) {
    const body = {
      data: {
        recipients,
        relationships: {
          event: {
            data: {
              id: eid,
              type: 'event',
            },
          },
        },
      },
    }
    return dispatch =>
      fetchAPI(`/api/guest_tickets`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          localStorage.setItem('reservedSeats', JSON.stringify([]))
          localStorage.setItem('tierId', '')
          localStorage.setItem('ttlStart', '')
          dispatch(this.success({ res, isSeatMap }))
          return res
        })
  },
)

const { GET_SEAT_MAP_DATA } = createAsyncAction('GET_SEAT_MAP_DATA', function (eid) {
  return async dispatch => {
    try {
      const res = await fetchAPI(`/api/event/${eid}/seat_map_data`)
      const { apiHost, eventSeats } = res.data
      const { seatData, ticketTypeTierRelations } = res.data
      const ticketTypes = eventSeats.reduce((ac, el) => {
        ac[el.tier_id] = el.ticket_type_id
        return ac
      }, {})
      URL_FOR_SEAT_MAP_INFO = apiHost
      dispatch(this.success({ seatData: JSON.parse(seatData), ticketTypes, eid, ticketTypeTierRelations }))

      const reservation_eid = localStorage.getItem('reservation_eid')
      if (reservation_eid) {
        await dispatch(DELETE_ALL_RESERVATIONS(reservation_eid))
      }

      localStorage.setItem('reservationId', makeId(50))
      localStorage.setItem('reservation_eid', eid)
      dispatch(GET_SEAT_MAP_STATUSES(eid))
    } catch (err) {
      const [{ meta: { status } = {} }] = err.errors
      if (status !== 404) {
        dispatch(ERROR(...err.errors))
      }
      dispatch(this.failed({ err, eid }))
      throw err
    }
  }
})

const { GET_SEAT_MAP_STATUSES } = createAsyncAction(
  'GET_SEAT_MAP_STATUSES',
  function (eid, reservationId = localStorage.getItem('reservationId')) {
    return async dispatch => {
      try {
        const res = await fetchAPI(
          `/api/event/${eid}/seats/status/`,
          { params: { reservation_id: reservationId } },
          URL_FOR_SEAT_MAP_INFO,
        )
        dispatch(this.success(res.data.$original.attributes))
      } catch (err) {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      }
    }
  },
)

const { RESERVE_SEAT } = createAsyncAction(
  'RESERVE_SEAT',
  function (eid, seatId, tierId, reservationId = localStorage.getItem('reservationId')) {
    return async dispatch => {
      try {
        let reservedSeats = localStorage.getItem('reservedSeats')
        reservedSeats = reservedSeats ? JSON.parse(reservedSeats) : []

        let body = {
          data: {
            tierId,
            seatId,
            reservationId,
            ttl: 10,
          },
        }
        body = JSON.stringify(body)
        await fetchAPI(
          `/api/event/${eid}/seats/reserve/`,
          {
            method: 'POST',
            body,
          },
          URL_FOR_SEAT_MAP_INFO,
        )

        reservedSeats.push(seatId)
        if (reservedSeats.length && !localStorage.getItem('ttlStart')) {
          localStorage.setItem('ttlStart', Date.now())
        }
        localStorage.setItem('tierId', tierId)
        localStorage.setItem('reservedSeats', JSON.stringify(reservedSeats))

        dispatch(this.success({ seatId, tierId }))
      } catch (err) {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg)
        dispatch(this.failed(err))
        throw err
      }
    }
  },
)

const { DELETE_ALL_RESERVATIONS } = createAsyncAction('DELETE_ALL_RESERVATIONS', function (eid) {
  return async dispatch => {
    try {
      let reservedSeats = localStorage.getItem('reservedSeats')
      if (reservedSeats) {
        reservedSeats = JSON.parse(reservedSeats)
        const tierId = localStorage.getItem('tierId')
        await dispatch(DELETE_RESERVATIONS(eid, reservedSeats, tierId, localStorage.getItem('reservationId')))
      }
      localStorage.setItem('reservedSeats', JSON.stringify([]))
      localStorage.setItem('tierId', '')
    } catch (err) {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    }
  }
})

const { DELETE_RESERVATIONS } = createAsyncAction(
  'DELETE_RESERVATIONS',
  function (eid, deletedReservations, tierId, reservationId = localStorage.getItem('reservationId')) {
    return async dispatch => {
      try {
        if (!deletedReservations.length) {
          return
        }
        let body = {
          data: {
            tierId,
            seatIds: deletedReservations,
          },
        }
        body = JSON.stringify(body)
        await fetchAPI(
          `/api/event/${eid}/seats/delete-reserved/`,
          {
            params: { reservation_id: reservationId },
            method: 'DELETE',
            body,
          },
          URL_FOR_SEAT_MAP_INFO,
        )
        let reservedSeats = localStorage.getItem('reservedSeats')
        if (reservedSeats) {
          reservedSeats = JSON.parse(reservedSeats)
          reservedSeats = subtractTwoArrays(reservedSeats, deletedReservations)
          localStorage.setItem('reservedSeats', JSON.stringify(reservedSeats))
          if (!reservedSeats.length) {
            localStorage.setItem('ttlStart', '')
          }
        }
        dispatch(this.success({ deletedReservations }))
      } catch (err) {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      }
    }
  },
)

export {
  UPLOAD_GUESTTICKETS,
  GET_SEAT_MAP_DATA,
  GET_SEAT_MAP_STATUSES,
  RESERVE_SEAT,
  DELETE_ALL_RESERVATIONS,
  DELETE_RESERVATIONS,
}
