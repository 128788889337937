import React from 'react'
import Modal from 'react-modal'
import _get from 'lodash/get'
import modalStyle from '../../../../_common/core/modalStyle'
import AddOnForm from '../../addons/AddOnForm'

const AddOnModal = props => {
  const {
    event,
    tickets,
    isEditing,
    initialValues,
    handleSubmitEditAddon,
    handleSubmitEditAddonGroup,
    handleCancelAddon,
    tables,
    enableAddOnsWithVariants,
    configs,
    isAddOnGroupSelected,
    stockLabel,
  } = props

  return (
    <Modal
      className="modal-dialog modal-trans"
      style={modalStyle}
      isOpen={isEditing}
      contentLabel="Modal"
      onRequestClose={handleCancelAddon}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div>Edit Add-On</div>
          </div>
          <AddOnForm
            event={event}
            tables={tables}
            tickets={tickets}
            isEditing={isEditing}
            initialValues={initialValues}
            isAddOnGroupSelected={isAddOnGroupSelected}
            handleSubmit={handleSubmitEditAddon}
            handleSubmitGroup={handleSubmitEditAddonGroup}
            onCancel={handleCancelAddon}
            enableAddOnsWithVariants={enableAddOnsWithVariants}
            configs={_get(configs, 'children.AddOnForm')}
            stockLabel={stockLabel}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddOnModal
