import React from 'react'
import _get from 'lodash/get'
import _identity from 'lodash/identity'
import { Field, Formik, Form } from 'formik'
import Button from '../../_library/Button'
import { SimpleField, SelectField } from '../../formik/Fields'
import {
  combineValidators,
  requiredValidator,
  minPasswordLengthValidator,
  passwordMatchValidator,
  minLengthValidator,
  maxLengthValidator,
  isEmailValidator,
} from '../../../_common/core/validation'
import { getConfig } from '../../utils/configUtils'

class SignupForm extends React.Component {
  handleCountryChange = option => {
    this.props.fetchStripeCountryStates(option.value)
  }

  render() {
    let {
      countries,
      countryStates,
      handleSubmit,
      isLoadingCountries,
      isLoadingCountryStates,
      isStatesFieldDisabled,
      initialValues,
      emailExists,
      onBlurEmail=_identity,
      emailPrefilled
    } = this.props

    initialValues = Object.entries(initialValues || {}).reduce((initialValues, current) => {
      const [key, value] = current
      initialValues[key] = value || ''
      return initialValues
    }, {})

    const configFile = getConfig()
    const logo = _get(configFile, '*.appearance.assets.logo', '/resources/images/ttf-logo.png')

    return (
      <div className="signup_card">
        <div className="signup_logo">
          <img
            src={asset(`${logo}`)}
            style={{ width: 80 }}
            alt=''
          />
        </div>
        <h2 className="heading">Sign Up</h2>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: emailPrefilled || '',
            password: '',
            confirmPassword: '',
            country: '',
            stateId: '',
            city: '',
            company: '',
            typesOfEvent: '',
            userContacts: '',
            ...initialValues,
          }}
          onSubmit={(values, actions) => {
            const { setSubmitting, resetForm } = actions
            handleSubmit(values).finally(() => {
              setSubmitting(false)
            })
          }}
          render={({ values, initialValues, isSubmitting }) => (
            <Form autoComplete="off" className="form_holder">
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="firstName"
                    placeholder="First Name"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="lastName"
                    placeholder="Last Name"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="email"
                    placeholder="Email"
                    component={SimpleField}
                    onBlur={onBlurEmail}
                    disabled={emailExists}
                    validate={combineValidators(
                      requiredValidator(),
                      isEmailValidator()
                    )}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="company"
                    placeholder="Company"
                    component={SimpleField}
                    validate={combineValidators(
                      requiredValidator(),
                      minLengthValidator(undefined, 2),
                      maxLengthValidator(undefined, 50)
                    )}
                  />
                </div>
                {!emailExists && (
                  <div className="col-xs-12">
                    <Field
                      name="password"
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      component={SimpleField}
                      validate={combineValidators(
                        requiredValidator(),
                        minPasswordLengthValidator(undefined, 6)
                      )}
                    />
                  </div>
                )}
                {!emailExists && (
                  <div className="col-xs-12">
                    <Field
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                      component={SimpleField}
                      validate={combineValidators(
                        requiredValidator(),
                        minPasswordLengthValidator(undefined, 6),
                        passwordMatchValidator(undefined, values.password)
                      )}
                    />
                  </div>
                )}
                <div className="col-xs-12">
                  <Field
                    name="country"
                    placeholder="Country"
                    component={SelectField}
                    options={countries}
                    defaultOption={{ value: '', label: 'Select Country...' }}
                    validate={requiredValidator()}
                    onSelect={this.handleCountryChange}
                    disabled={isLoadingCountries}
                    isLoading={isLoadingCountries}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="stateId"
                    placeholder="State"
                    component={SelectField}
                    options={countryStates}
                    defaultOption={{ value: '', label: 'Select State...' }}
                    disabled={isStatesFieldDisabled}
                    isLoading={isLoadingCountryStates}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="city"
                    placeholder="City"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="typesOfEvent"
                    placeholder="What type of event do you organize"
                    component={SimpleField}
                  />
                </div>
                <div className="col-xs-12">
                  <Field
                    name="userContacts"
                    placeholder="Do you have an existing contact at The Ticket Fairy?"
                    component={SimpleField}
                  />
                </div>
              </div>

              <div className="row">
                <div className="text-center">
                  <div className="col-xs-12">
                    <Button className="signup_btn" type="submit" loading={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
        <div className="copy_right">
          Copyright &copy; {getCurrentYear()} THE<strong>TICKET</strong>FAIRY, Inc.
        </div>
      </div>
    )
  }
}

export default SignupForm
