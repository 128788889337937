import React from 'react'

export default class Chip extends React.Component {
  handleRemoveClick = e => {
    e.stopPropagation()
    const { value, handleRemove } = this.props
    handleRemove(value)
  }

  handleSelect = () => {
    const { value, onSelectChip } = this.props
    onSelectChip(value)
  }

  render() {
    const { value, isSelected, disableClear, style } = this.props
    return (
      <span
        aria-hidden
        onClick={this.handleSelect}
        className={`chip-container ${isSelected ? 'selected' : ''}`}
        style={style}
      >
        <span>{value}</span>
        {!disableClear && <a onClick={this.handleRemoveClick} />}
      </span>
    )
  }
}
