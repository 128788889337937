import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'
import { Chart } from '../../_library/Chart'
import EmptyBar from '../../_library/EmptyBar'
import LoadingBar from '../../_library/LoadingBar'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _get from 'lodash/get'
import { getTableColumns } from '../../utils/sortableTableUtils'
import SortableTableWithSearchHOC from '../../hoc/SortableTableWithSearchHOC'
import InfluencerRowDetail from './InfluencerRowDetail'
import { currencyNormalizerCreator, createFixedFloatNormalizer } from '../../../_common/core/validation'

const SortableTableWithSearch = SortableTableWithSearchHOC()

const searchBy = [
  'fullName',
  'sales_referred',
  'impressions',
  'revenue_generated',
  'original_cost',
  'refund_due'
]

export const getCustomerFullName = (customer = {}) =>
  (_get(customer, 'first_name') || '') + ' ' + _get(customer, 'last_name') || ''

const InfluencerReferrers = ({ referrers, loading, event }) => {
  const [detailsRow, setDetailsRow] = useState([])
  const [updatedReferrers, setReferrers] = useState([])
  const [sortBy, handleSort] = useState({ column: 'sales_referred', asc: false })
  const permission = _get(event, 'self.role', '')
  const isLimitedStats = permission === 'limited_stats'

  const sortByCustomer = column => {
    const referrersData = _orderBy(
      updatedReferrers,
      ['first_name', 'last_name'],
      [sortBy.asc ? 'asc' : 'desc']
    )
    setReferrers(referrersData)
    handleSort({ column: column.key, asc: !sortBy.asc })
  }

  const tableColumns = getTableColumns([
    {
      label: 'Customer',
      key: 'customer',
      sort: sortByCustomer
    },
    { label: 'No. of Tickets Referred', key: 'sales_referred', className: 'mw-160' },
    { label: 'Link Clicks Generated', key: 'impressions', className: 'mw-160' },
    {
      label: 'Revenue Generated (excl. fees)',
      key: 'revenue_generated',
      hideColumn: isLimitedStats,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value)))
    },
    {
      label: 'Original Purchase Price (excl.fees)',
      key: 'original_cost',
      hideColumn: isLimitedStats,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value)))
    },
    {
      label: 'Refund Due',
      key: 'refund_due',
      hideColumn: isLimitedStats,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value)))
    }
  ])

  useEffect(() => {
    if (referrers) {
      const detailsRowData = _map(referrers, t => ({
        id: t.referrer_id,
        type: 'detailRow',
        component: <InfluencerRowDetail row={t} />
      }))

      const referrersData = _map(referrers, item => {
        const isFbUser = !!item.fb_user_id
        const letters = (item.first_name[0] + item.last_name[0]).toUpperCase()
        const customer = (
          <div className="customer_name">
            <div className="sales-circle">
              {isFbUser && (
                <a target="_blank" href={'https://www.facebook.com/' + item.fb_user_id} rel="noreferrer">
                  <div className="LazyLoad">
                    <LazyLoad height={40} width={40} once={true}>
                      <img
                        alt="nodata"
                        className="LazyLoadImg"
                        src={'//graph.facebook.com/' + item.fb_user_id + '/picture?type=small'}
                      />
                    </LazyLoad>
                  </div>
                </a>
              )}
              {!isFbUser && letters}
            </div>
            <div className="sales-name">{item.fullName}</div>
          </div>
        )
        return {
          ...item,
          customer
        }
      })
      setReferrers(referrersData)
      setDetailsRow(detailsRowData)
    }
  }, [referrers])

  const frequency = {}
  let max = 0
  _forEach(referrers, t => {
    const referred = t.sales_referred ? parseInt(t.sales_referred) : 0
    if (max < referred) max = referred
  })

  for (let i = 1; i <= max; i++) {
    frequency[i] = 0
  }

  _forEach(referrers, t => {
    if (t.sales_referred && !t.isDetail) {
      const referred = parseInt(t.sales_referred)
      frequency[referred] += 1
    }
  })

  return (
    <div>
      <h3 className="heading_style">Sales Referred by Ticket Buyers</h3>
      {loading ? (
        <LoadingBar />
      ) : _isEmpty(frequency) ? (
        <EmptyBar />
      ) : (
        <div>
          <div className="card">
            <div className="card-block">
              <div className="text-center">
                <Chart
                  width={'100%'}
                  height="300px"
                  chartConfig={{
                    type: 'bar',
                    data: {
                      labels: _keys(frequency),
                      datasets: [
                        {
                          data: _values(frequency)
                        }
                      ]
                    },
                    options: {
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              callback(value) {
                                if (value % 1 === 0) {
                                  return value
                                }
                              }
                            }
                          }
                        ]
                      },
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
                <br />
              </div>
            </div>
          </div>
          <SortableTableWithSearch
            e2e_test_id="event_influencers"
            className="influencers-sales-table"
            data={updatedReferrers}
            dataForCopy={_map(updatedReferrers, item => ({
              ...item,
              id: item.referrer_id,
              customer: item.fullName
            }))}
            sortBy={sortBy}
            tableColumns={tableColumns}
            searchBy={searchBy}
            detailsRows={detailsRow}
            disableMobileView={true}
          />
        </div>
      )}
    </div>
  )
}

export default InfluencerReferrers
