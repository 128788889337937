import _ from 'lodash'
import React from 'react'
import { withFormik } from 'formik'
import moment from 'moment'
import Modal from 'react-modal'
import Scroll from 'react-scroll'
import getFeeModes from './feemodes'
import { OverlayTrigger } from '../../_library/OverlayTrigger'

import Button from '../../_library/Button'
import Field from '../../_library/Field'
import Radios from '../../_library/Radios'
import Card from '../../_library/Card'
import NumberAnimation from '../../_library/NumberAnimation'
import RichTextArea from '../../_library/RichTextArea'
import TagsField from '../../_library/TagsField'

import modalStyle from '../../../_common/core/modalStyle'
import DateTimePickerReact from '../../_library/DateTimePickerReact'
import { CustomToggleField } from '../../formik/Fields'
import { Field as FormikField } from 'formik'
import { ROLES } from '../../constants/roles'

// validators
import {
  // normalizer
  toInt,

  // create normalizers
  maxNormalizerCreator,
} from '../../../_common/core/validation'

// utils
import { combineNormalizers } from '../../../_common/core/validation/utils'

function isDateRangeInvalid2(start, end) {
  if (!start) return false
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

function validate(data) {
  const errors = {}

  const min = _.get(data, 'minOrder')
  const max = _.get(data, 'maxTicketsPerOrder')

  if (max && max < min) {
    _.set(errors, 'maxTicketsPerOrder', 'Value must be greater than ' + min)
  }

  const tier1sales = parseInt(_.get(data, 'referralTier1Sales'))
  const tier2sales = parseInt(_.get(data, 'referralTier2Sales'))
  const tier3sales = parseInt(_.get(data, 'referralTier3Sales'))

  if (tier2sales && tier2sales <= tier1sales) {
    _.set(errors, 'referralTier2Sales', 'Tier 2 target must be greater than Tier 1')
  }

  if (tier3sales && tier3sales <= tier2sales) {
    _.set(errors, 'referralTier3Sales', 'Tier 3 target must be greater than Tier 2')
  }

  if (tier3sales && tier3sales <= tier1sales) {
    _.set(errors, 'referralTier3Sales', 'Tier 3 target must be greater than Tier 1')
  }

  const tier1percentage = parseInt(_.get(data, 'referralTier1Percentage'))
  const tier2percentage = parseInt(_.get(data, 'referralTier2Percentage'))

  if (tier2percentage && tier2percentage <= tier1percentage) {
    _.set(errors, 'referralTier2Percentage', 'Tier 2 percentage must be greater than Tier 1')
  }

  // --- following fields were moved from event-form
  if (!_.get(data, 'salesEndDate')) {
    _.set(errors, 'salesEndDate', 'Required')
  }

  if (_.get(data, 'salesEndDate') && _.get(data, 'endDate')) {
    const isSalesEndDateAfterEventEndDate = moment(_.get(data, 'salesEndDate')).isAfter(moment(_.get(data, 'endDate')))
    if (isSalesEndDateAfterEventEndDate) {
      _.set(errors, 'salesEndDate', 'Sales end date must be before event end date')
    }
  }

  if (_.get(data, 'salesStartDate') && _.get(data, 'startDate')) {
    const isSalesStartDateAfterEventStartDate = moment(_.get(data, 'salesStartDate')).isAfter(moment(_.get(data, 'startDate')))
    if (isSalesStartDateAfterEventStartDate) {
      _.set(errors, 'salesStartDate', 'Sales start date must be before event start date')
    }
  }

  const salesStartDate = _.get(data, 'salesStartDate')
  const salesEndDate = _.get(data, 'salesEndDate')
  if (isDateRangeInvalid2(salesStartDate, salesEndDate)) {
    _.set(errors, 'salesEndDate', 'Sales end date must be after sales start date')
  }

  const externalUrl = _.get(data, 'externalUrl')
  if (externalUrl == '') {
    _.set(errors, 'externalUrl', 'Required')
  } else if (externalUrl != null) {
    if (!/^(https?:\/\/)/i.test(externalUrl)) _.set(errors, 'externalUrl', 'Invalid URL')
  }

  return errors
}

// Constants
const MAX_ORDER_LIMIT = 20

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      importantSetting: false,
      importantSettingID: null,
      submitPressed: false,
      showRefundModal: false,
    }
    this.updateField = this.updateField.bind(this)
    this.processSubmit = this.processSubmit.bind(this)
    this.processImportantSetting = this.processImportantSetting.bind(this)
    this.onImportantSetting = this.onImportantSetting.bind(this)
    this.handleImportantSetting = this.handleImportantSetting.bind(this)
    this.closeImportantSetting = this.closeImportantSetting.bind(this)
    this.decreaseReferralTier1Sales = this.decreaseReferralTier1Sales.bind(this)
    this.increaseReferralTier1Sales = this.increaseReferralTier1Sales.bind(this)
    this.decreaseReferralTier2Sales = this.decreaseReferralTier2Sales.bind(this)
    this.increaseReferralTier2Sales = this.increaseReferralTier2Sales.bind(this)
    this.decreaseReferralTier3Sales = this.decreaseReferralTier3Sales.bind(this)
    this.increaseReferralTier3Sales = this.increaseReferralTier3Sales.bind(this)
    this.decreaseReferralTier1Percentage = this.decreaseReferralTier1Percentage.bind(this)
    this.increaseReferralTier1Percentage = this.increaseReferralTier1Percentage.bind(this)
    this.decreaseReferralTier2Percentage = this.decreaseReferralTier2Percentage.bind(this)
    this.increaseReferralTier2Percentage = this.increaseReferralTier2Percentage.bind(this)
    this.decreaseMaxTicketsPerOrder = this.decreaseMaxTicketsPerOrder.bind(this)
    this.increaseMaxTicketsPerOrder = this.increaseMaxTicketsPerOrder.bind(this)
    this.handleChangeFlagExternalUrl = this.handleChangeFlagExternalUrl.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.handleDatePickerStart = this.handleDatePickerStart.bind(this)
    this.handleDatePickerEnd = this.handleDatePickerEnd.bind(this)
    this.handleChangeFlagDisableReferral = this.handleChangeFlagDisableReferral.bind(this)
    this.handleChangeFlagEnableWaitingList = this.handleChangeFlagEnableWaitingList.bind(this)
    this.handleChangeFlagEnableAddOnWaitingList = this.handleChangeFlagEnableAddOnWaitingList.bind(this)
    this.handleChangeFlagRequirePhone = this.handleChangeFlagRequirePhone.bind(this)
    this.handleChangeFlagHidePhone = this.handleChangeFlagHidePhone.bind(this)
    this.handleChangeFlagTaxesIncluded = this.handleChangeFlagTaxesIncluded.bind(this)
    this.handleChangeFlagValidateAge = this.handleChangeFlagValidateAge.bind(this)
    this.handleChangeFlagNameChanges = this.handleChangeFlagNameChanges.bind(this)
    this.handleChangeFlagTicketSwaps = this.handleChangeFlagTicketSwaps.bind(this)
    this.handleChangeTicketType = this.handleChangeTicketType.bind(this)
    this.handleChangeExternalUrl = this.handleChangeExternalUrl.bind(this)
    this.handleChangeAttendeeTags = this.handleChangeAttendeeTags.bind(this)
    this.handleChangeFlagRefunds = this.handleChangeFlagRefunds.bind(this)
    this.displayDisableTooltip = this.displayDisableTooltip.bind(this)
    this.displayWhenRewardsDisabled = this.displayWhenRewardsDisabled.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { submitPressed } = this.state
    if (!_.isEmpty(nextProps.errors) && submitPressed) {
      console.log('submit failed due to the errors, now scroll to top', nextProps.errors)
      this.setState({ submitPressed: false }, () => {
        const scroll = Scroll.animateScroll
        if (nextProps.errors.externalUrl) {
          Scroll.scroller.scrollTo('scroll-to-flagExternalUrl', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
          })
        } else {
          scroll.scrollToTop()
        }
      })
    }
  }

  displayDisableTooltip(disableRebates) {
    return (
      <span className="in" id="reward-tooltip">
        {disableRebates
          ? 'Rewards System Targets cannot be changed'
          : 'The Rewards System cannot be disabled'}{' '}
        once ticket sales have started, to ensure consistent ticket buyer experience.
      </span>
    )
  }

  getValueForMultiTicketPurchase(flagAllowMultipleTicketTypePurchases, flagSeatMapAllowed, hasTableTypes) {
    if (flagSeatMapAllowed) return true
    if (hasTableTypes) return false
    return !!flagAllowMultipleTicketTypePurchases
  }

  displayWhenRewardsDisabled(disableRebates) {
    return (
      <span className="in" id="reward-tooltip">
        {disableRebates
          ? 'Rewards System Targets cannot be changed'
          : 'The Rewards System cannot be disabled'}{' '}
        once ticket sales have started, to ensure consistent ticket buyer experience.
      </span>
    )
  }

  displayMultiTicketsPurchaseTooltip = (flagSeatMapAllowed, hasTableTypes) => {
    if (flagSeatMapAllowed)
      return <span>The multiple ticket type purchase cannot be disabled for Seat Map</span>
    if (hasTableTypes) return <span>The multiple ticket type purchase cannot be enabled for Table Type</span>
  }

  updateField(field, value) {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fieldName = `attributes.${field}`
    const fields = form_helper_get()
    fields[fieldName] = fieldName
    form_helper_set(fields)
  }

  processSubmit() {
    const { handleSubmit } = this.props
    this.setState({ submitPressed: true }, () => {
      handleSubmit()
    })
  }
  processImportantSetting(id, newValue) {
    const { importantSettingID } = this.state
    const ctlID = !!id ? id : importantSettingID
    switch (ctlID) {
      case 'flagNameChecks':
        this.updateField('flagNameChecks', newValue)
        break
      case 'flagIDRequired':
        this.updateField('flagIDRequired', newValue)
        break
      case 'flagResaleEnabled':
        this.updateField('flagResaleEnabled', newValue)
        break
      case 'flagCollectNames':
        this.updateField('flagCollectNames', newValue)
        break
      case 'flagEnableAddOnResale':
        this.updateField('flagEnableAddOnResale', newValue)
        break
      default:
        break
    }
  }
  onImportantSetting(e) {
    if (e.target.checked) {
      this.setState({ importantSetting: false, importantSettingID: e.target.id })
      this.processImportantSetting(e.target.id, true)
    } else {
      this.setState({ importantSetting: true, importantSettingID: e.target.id })
    }
  }
  handleImportantSetting() {
    this.processImportantSetting(null, false)
    this.setState({ importantSetting: false })
  }
  closeImportantSetting() {
    this.setState({ importantSetting: false })
  }
  closeRefundModal = () => {
    this.setState({ showRefundModal: false })
  }
  handleRefundModal = () => {
    const {
      values: { flagAllowCustomerRefund },
    } = this.props
    this.updateField('flagAllowCustomerRefund', !flagAllowCustomerRefund)
    this.setState({ showRefundModal: false })
  }

  decreaseReferralTier1Sales() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Sales)
    if (value1 > 1) {
      this.updateField('referralTier1Sales', value1 - 1)
    }
  }

  increaseReferralTier1Sales() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Sales)
    const value2 = parseInt(values.referralTier2Sales)
    if (value1 < 20 && value1 < value2 - 1) {
      this.updateField('referralTier1Sales', value1 + 1)
    }
  }

  decreaseReferralTier2Sales() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Sales)
    const value2 = parseInt(values.referralTier2Sales)
    if (value2 > 1 && value2 > value1 + 1) {
      this.updateField('referralTier2Sales', value2 - 1)
    }
  }

  increaseReferralTier2Sales() {
    const { values } = this.props
    const value2 = parseInt(values.referralTier2Sales)
    const value3 = parseInt(values.referralTier3Sales)
    if (value2 < 20 && value2 < value3 - 1) {
      this.updateField('referralTier2Sales', value2 + 1)
    }
  }

  decreaseReferralTier3Sales() {
    const { values } = this.props
    const value2 = parseInt(values.referralTier2Sales)
    const value3 = parseInt(values.referralTier3Sales)
    if (value3 > 1 && value3 > value2 + 1) {
      this.updateField('referralTier3Sales', value3 - 1)
    }
  }

  increaseReferralTier3Sales() {
    const { values } = this.props
    const value3 = parseInt(values.referralTier3Sales)
    if (value3 < 20) {
      this.updateField('referralTier3Sales', value3 + 1)
    }
  }

  decreaseReferralTier1Percentage() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Percentage)
    if (value1 > 5) {
      this.updateField('referralTier1Percentage', value1 - 5)
    }
  }

  increaseReferralTier1Percentage() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Percentage)
    const value2 = parseInt(values.referralTier2Percentage)
    if (value1 < 100 && value1 < value2 - 5) {
      this.updateField('referralTier1Percentage', value1 + 5)
    }
  }

  decreaseReferralTier2Percentage() {
    const { values } = this.props
    const value1 = parseInt(values.referralTier1Percentage)
    const value2 = parseInt(values.referralTier2Percentage)
    if (value2 > 5 && value2 > value1 + 5) {
      this.updateField('referralTier2Percentage', value2 - 5)
    }
  }

  increaseReferralTier2Percentage() {
    const { values } = this.props
    const value2 = parseInt(values.referralTier2Percentage)
    if (value2 < 95) {
      this.updateField('referralTier2Percentage', value2 + 5)
    }
  }

  decreaseMaxTicketsPerOrder() {
    const { values } = this.props
    if (parseInt(values.maxTicketsPerOrder) > 1) {
      this.updateField('maxTicketsPerOrder', parseInt(values.maxTicketsPerOrder) - 1)
    }
  }

  increaseMaxTicketsPerOrder() {
    const { values } = this.props
    if (parseInt(values.maxTicketsPerOrder) < 20) {
      this.updateField('maxTicketsPerOrder', parseInt(values.maxTicketsPerOrder) + 1)
    }
  }

  decreaseMaxTicketsPerAccount = () => {
    const { values } = this.props
    const maxTicketsPerAccount = Number(values.maxTicketsPerAccount)
    if (parseInt(maxTicketsPerAccount) > 1) {
      this.updateField('maxTicketsPerAccount', parseInt(maxTicketsPerAccount) - 1)
    }
  }

  increaseMaxTicketsPerAccount = () => {
    const { values } = this.props
    const maxTicketsPerAccount = Number(values.maxTicketsPerAccount)
    if (parseInt(maxTicketsPerAccount) < 20) {
      this.updateField('maxTicketsPerAccount', parseInt(maxTicketsPerAccount) + 1)
    }
  }

  handleMaxTicketsPerOrderChange = value => {
    this.updateField(
      'maxTicketsPerOrder',
      combineNormalizers(toInt, maxNormalizerCreator(MAX_ORDER_LIMIT))(value),
    )
  }

  handleMaxTicketsPerAccountChange = value => {
    this.updateField(
      'maxTicketsPerAccount',
      combineNormalizers(toInt, maxNormalizerCreator(MAX_ORDER_LIMIT))(value),
    )
  }

  handleChangeFlagExternalUrl(e) {
    const { checked } = e.target
    if (!checked) {
      this.updateField('externalUrl', null)
    } else {
      this.updateField('externalUrl', '')
    }
    this.props.onChangeExternalUrl(checked)
  }

  handleRadioChange(e) {
    const { isTicketTypeExist, values } = this.props
    const radioId = e.target.id
    const FEE_MODES = getFeeModes(isTicketTypeExist, values.feeMode)
    switch (radioId) {
      case 'feeMode_0':
        this.updateField('feeMode', FEE_MODES[0].value)
        break
      case 'feeMode_1':
        this.updateField('feeMode', FEE_MODES[1].value)
        break
      case 'feeMode_2':
        this.updateField('feeMode', FEE_MODES[2].value)
        break
      default:
        this.updateField('feeMode', FEE_MODES[3].value)
        break
    }
  }

  handleDatePickerStart(date) {
    this.updateField('salesStartDate', date)
  }

  handleDatePickerEnd(date) {
    this.updateField('salesEndDate', date)
  }

  handleChangeFlagDisableReferral(e) {
    const { tfRole, ticketSales, isRewardSystemDisabled } = this.props

    if (!ROLES.is_tf_client_support_admin(tfRole) && ticketSales > 0 && !isRewardSystemDisabled) {
      return
    }
    this.updateField('flagDisableReferral', e.target.checked)
  }

  handleChangeFlagEnableWaitingList(e) {
    this.updateField('flagEnableWaitingList', e.target.checked)
  }

  handleChangeFlagEnableAddOnWaitingList(e) {
    this.updateField('flagEnableAddOnWaitingList', e.target.checked)
  }

  handleChangeFlagRequirePhone(e) {
    this.updateField('flagRequirePhone', e.target.checked)
    if (e.target.checked){
      this.updateField('flagHidePhoneField', false)
    }
  }

  handleChangeFlagHidePhone(e) {
    this.updateField('flagHidePhoneField', e.target.checked)
  }

  handleChangeFlagTaxesIncluded(e) {
    this.updateField('flagTaxesIncluded', e.target.checked)
  }

  handleChangeFlagValidateAge(e) {
    const value = e.target.checked
    this.updateField('flagValidateAge', e.target.checked)
    !value && this.updateField('minimumAge', null)
  }

  handleChangeFlagMultipleTicketTypePurchase = e => {
    this.updateField('flagAllowMultipleTicketTypePurchases', e.target.checked)
  }

  handleChangeFlagNameChanges(e) {
    this.updateField('flagNameChanges', e.target.checked)
  }

  handleChangeFlagTicketSwaps(e) {
    this.updateField('flagTicketSwaps', e.target.checked)
  }

  handleChangeTicketType(val) {
    this.updateField('ticketText', val)
  }

  handleChangeExternalUrl(e) {
    this.updateField('externalUrl', e.target.value)
  }

  handleChangeAttendeeTags(tags) {
    this.updateField('attendeeTags', tags)
  }

  handleChangeFlagRefunds(e) {
    const {
      values: { flagFundsRemitted, flagPaidInAdvance },
    } = this.props

    if (flagFundsRemitted || flagPaidInAdvance) {
      return
    }

    this.setState({
      showRefundModal: true,
    })
  }

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      dirty,
      submitLabel,
      isTicketTypeExist,
      loadingTickets,
      ticketSales,
      isRewardSystemDisabled,
      isRnDomain,
      tfRole,
      hasTableTypes,
      event,
      configs,
    } = this.props

    const { importantSetting, showRefundModal } = this.state
    const hideReferral = values.flagDisableReferral
    const externalUrlEnabled = values.externalUrl != null
    const FEE_MODES = getFeeModes(isTicketTypeExist, values.feeMode, loadingTickets)
    const disableRebates = !ROLES.is_tf_client_support_admin(tfRole) && ticketSales && !isRewardSystemDisabled

    const hideWaitingListAfterAddOnSellOutToggle = _.get(configs, 'appearance.hideWaitingListAfterAddOnSellOutToggle', false)
    const hideAllowCustomersToInitiateRefundsToggle = _.get(
      configs,
      'appearance.hideAllowCustomersToInitiateRefundsToggle',
      false,
    )
    const hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle = _.get(
      configs,
      'appearance.hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle',
      false,
    )
    const hideAddOnResaleSystemEnabledToggle = _.get(configs, 'appearance.hideAddOnResaleSystemEnabledToggle', false)
    const showRequireBuyerToEnterTheirPhoneNumberToggle = _.get(
      configs,
      'appearance.showRequireBuyerToEnterTheirPhoneNumberToggle',
      true,
    )

    const showHideBuyerPhoneNumberToggle = _.get(configs, 'appearance.showHideBuyerPhoneNumberToggle', false)

    return (
      <div className="event-ticketoptions">
        <form ref="form" method="POST" onSubmit={handleSubmit}>
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!importantSetting}
            contentLabel="Modal"
            onRequestClose={this.closeImportantSetting}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Are you sure?</p>
                  </div>
                  <div className="modal-body">
                    <p>
                      We strongly recommend leaving this enabled to increase security and reduce the risk of
                      fraudulent payment disputes from customers. Are you sure you want to disable it?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-danger btn-shadow"
                        type="button"
                        onClick={this.handleImportantSetting}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn btn-cancel btn-shadow"
                        type="button"
                        onClick={this.closeImportantSetting}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!showRefundModal}
            contentLabel="Modal"
            onRequestClose={this.closeRefundModal}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Are you sure?</p>
                  </div>
                  <div className="modal-body">
                    <p>
                      {values.flagAllowCustomerRefund
                        ? 'You are disabling self-refunds for this event'
                        : 'You are enabling self-refunds for this event.'}
                    </p>
                    {event?.paymentMethod?.name ? (
                      <p>
                        {values.flagAllowCustomerRefund
                          ? 'Note: Any refund requests will now be directed to you.'
                          : 'Note: There should be sufficient funds in your connected Stripe account to cover all active refunds.'}
                      </p>
                    ) : (
                      <p>
                        {values.flagAllowCustomerRefund
                          ? 'Note: Any refund requests will now be directed to you.'
                          : 'Note: Your event will not be paid out if ended, whilst this option is enabled.'}
                      </p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-success btn-shadow"
                        type="button"
                        onClick={this.handleRefundModal}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="btn btn-danger btn-shadow"
                        type="button"
                        onClick={this.closeRefundModal}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-xs-12">
              <div className="ticket-radio col-12 sub-title">
                <Radios
                  id="feeMode"
                  label="How do you want to handle booking fees?"
                  label1="This option has no effect on free events"
                  options={FEE_MODES}
                  value={values.feeMode}
                  onChange={this.handleRadioChange}
                />
              </div>
            </div>
          </div>
          <Card icon="fa-toggle-on" title="Ticket Settings">
            <div className="row" style={{ marginBottom: 8 }}>
              <div className="col-sm-6 col-12" style={{ position: 'static' }}>
                <DateTimePickerReact
                  id="salesStartDate"
                  label="Ticket Sales Start (optional)"
                  placeholder="D MMM YYYY H:M AM"
                  error={errors.salesStartDate}
                  touched={touched.salesStartDate}
                  value={values.salesStartDate}
                  onChange={this.handleDatePickerStart}
                />
              </div>
              <div className="col-sm-6" style={{ position: 'static' }}>
                <DateTimePickerReact
                  id="salesEndDate"
                  label="Ticket Sales End"
                  placeholder="D MMM YYYY H:M AM"
                  error={errors.salesEndDate}
                  touched={touched.salesEndDate}
                  value={values.salesEndDate}
                  onChange={this.handleDatePickerEnd}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-12">
                <OverlayTrigger
                  id="disableRewardsToggleTooltip"
                  tooltip={this.displayWhenRewardsDisabled()}
                  placement="top"
                  hideTooltip={!disableRebates}
                  delayShow={400}
                  delayHide={10}
                >
                  <div className="line">
                    <div className="line-cell">
                      <div className="checkbox-switch">
                        <input
                          type="checkbox"
                          id="flagDisableReferral"
                          checked={values.flagDisableReferral}
                          value={values.flagDisableReferral}
                          onChange={this.handleChangeFlagDisableReferral}
                          disabled={disableRebates}
                        />
                        <label
                          htmlFor="flagDisableReferral"
                          className={disableRebates ? 'disable-reward-label' : ''}
                        />
                      </div>
                    </div>
                    <div className="line-cell">
                      <label htmlFor="flagDisableReferral">Disable Rewards System</label>
                    </div>
                  </div>
                </OverlayTrigger>
                <div className="line">
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id="flagEnableWaitingList"
                        checked={values.flagEnableWaitingList}
                        value={values.flagEnableWaitingList}
                        onChange={this.handleChangeFlagEnableWaitingList}
                      />
                      <label htmlFor="flagEnableWaitingList" />
                    </div>
                  </div>
                  <div className="line-cell">
                    <label htmlFor="flagEnableWaitingList">Enable waiting list after ticket sell-out</label>
                  </div>
                </div>
                {hideWaitingListAfterAddOnSellOutToggle ? null : <div className="line">
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id="flagEnableAddOnWaitingList"
                        checked={values.flagEnableAddOnWaitingList}
                        value={values.flagEnableAddOnWaitingList}
                        onChange={this.handleChangeFlagEnableAddOnWaitingList}
                      />
                      <label htmlFor="flagEnableAddOnWaitingList" />
                    </div>
                  </div>
                  <div className="line-cell">
                    <label htmlFor="flagEnableAddOnWaitingList">
                      Enable waiting list after add-on sell-out
                    </label>
                  </div>
                </div>}
                {showRequireBuyerToEnterTheirPhoneNumberToggle && (
                  <div className="line">
                    <div className="line-cell">
                      <div className="checkbox-switch">
                        <input
                          type="checkbox"
                          id="flagRequirePhone"
                          checked={values.flagRequirePhone}
                          value={values.flagRequirePhone}
                          onChange={this.handleChangeFlagRequirePhone}
                        />
                        <label htmlFor="flagRequirePhone" />
                      </div>
                    </div>
                    <div className="line-cell">
                      <label htmlFor="flagRequirePhone">Require buyer to enter their phone number</label>
                    </div>
                  </div>
                )}
                {showHideBuyerPhoneNumberToggle && (
                  <OverlayTrigger
                    id="disableRewardsToggleTooltip"
                    tooltip={
                      <span className="in" id="flag-hide-phone-field-tooltip">
                        Cannot be toggle on while "Require buyer to enter their phone number" is
                        on
                      </span>
                    }
                    placement="top"
                    hideTooltip={!values.flagRequirePhone}
                    delayShow={400}
                    delayHide={10}
                  >
                    <div className="line">
                      <div className="line-cell">
                        <div className="checkbox-switch">
                          <input
                            type="checkbox"
                            id="flagHidePhoneField"
                            checked={values.flagHidePhoneField}
                            value={values.flagHidePhoneField}
                            onChange={this.handleChangeFlagHidePhone}
                            disabled={values.flagRequirePhone}
                          />
                          <label htmlFor="flagHidePhoneField"
                            className={values.flagRequirePhone ? 'disable-hide-phone-field' : ''}
                          />
                        </div>
                      </div>
                      <div className="line-cell">
                        <label htmlFor="flagHidePhoneField">Hide phone number of buyer field</label>
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
                {/*
								<div className="line">
									<div className="line-cell">
										<div className="checkbox-switch">
											<input
												type="checkbox"
												id="flagTaxesIncluded"
												checked={values.flagTaxesIncluded}
												value={values.flagTaxesIncluded}
												onChange={this.handleChangeFlagTaxesIncluded}
											/>
											<label htmlFor="flagTaxesIncluded"></label>
										</div>
									</div>
									<div className="line-cell">
										<label htmlFor="flagTaxesIncluded">Show ticket prices including taxes</label>
									</div>
								</div>
								*/}
              </div>
              <div className="col-xs-6 col-12">
                <div className="line">
                  <div className="line-cell">
                    <div className="checkbox-switch">
                      <input
                        type="checkbox"
                        id="flagValidateAge"
                        checked={values.flagValidateAge}
                        value={values.flagValidateAge}
                        onChange={this.handleChangeFlagValidateAge}
                      />
                      <label htmlFor="flagValidateAge" />
                    </div>
                  </div>
                  <div className="line-cell">
                    <label htmlFor="flagValidateAge">Collect and validate DOB against a minimum age</label>
                  </div>
                </div>
                {hideAllowCustomersToInitiateRefundsToggle ? null : <OverlayTrigger
                  id="flagAllowCustomerRefund"
                  placement="top"
                  hideTooltip={!(values.flagFundsRemitted || values.flagPaidInAdvance)}
                  delayShow={400}
                  delayHide={10}
                  tooltip={
                    <span>
                      Self-refunds can no longer be enabled as ticket sales revenue has been released
                    </span>
                  }
                >
                  <div className="line">
                    <div className="line-cell">
                      <div className="checkbox-switch">
                        <input
                          type="checkbox"
                          id="flagAllowCustomerRefund"
                          checked={values.flagAllowCustomerRefund}
                          value={values.flagAllowCustomerRefund}
                          onChange={this.handleChangeFlagRefunds}
                        />
                        <label
                          htmlFor="flagAllowCustomerRefund"
                          className={
                            values.flagFundsRemitted || values.flagPaidInAdvance
                              ? 'disable-initiate-refunds'
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className="line-cell">
                      <label htmlFor="flagAllowCustomerRefund">Allow customers to initiate refunds</label>
                    </div>
                  </div>
                </OverlayTrigger>}
                {ROLES.is_tf_client_support_admin(tfRole) && (
                  <OverlayTrigger
                    id="multiTicketsPurchaseTooltip"
                    placement="top"
                    delayShow={400}
                    delayHide={10}
                    hideTooltip={!values.flagSeatMapAllowed && !hasTableTypes}
                    tooltip={this.displayMultiTicketsPurchaseTooltip(
                      values.flagSeatMapAllowed,
                      hasTableTypes
                    )}
                  >
                    <div className="line">
                      <div className="line-cell">
                        <div className="checkbox-switch">
                          <input
                            type="checkbox"
                            id="flagMultipleTicketTypePurchase"
                            checked={this.getValueForMultiTicketPurchase(
                              values.flagAllowMultipleTicketTypePurchases,
                              values.flagSeatMapAllowed,
                              hasTableTypes
                            )}
                            value={this.getValueForMultiTicketPurchase(
                              values.flagAllowMultipleTicketTypePurchases,
                              values.flagSeatMapAllowed,
                              hasTableTypes
                            )}
                            onChange={this.handleChangeFlagMultipleTicketTypePurchase}
                            disabled={values.flagSeatMapAllowed || hasTableTypes || false}
                          />
                          <label
                            htmlFor="flagMultipleTicketTypePurchase"
                            className={
                              values.flagSeatMapAllowed || hasTableTypes
                                ? 'disable-ticket-type-purchases'
                                : ''
                            }
                          />
                        </div>
                      </div>
                      <div className="line-cell">
                        <label htmlFor="flagMultipleTicketTypePurchase">
                          Enable multiple ticket type purchase
                        </label>
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12" style={{ paddingBottom: '5px' }}>
                Additional text to display on ticket PDF (such as venue directions)
              </div>
              <div className="col-xs-12">
                <RichTextArea
                  id="ticketText"
                  defaultValue="<p><br/></p>"
                  disablePlugin={false}
                  limit={10}
                  value={values.ticketText}
                  onChange={this.handleChangeTicketType}
                />
              </div>
            </div>
            <Scroll.Element name="scroll-to-flagExternalUrl" />
            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-xs-12" style={{ paddingBottom: '5px' }}>
                Optional Attendee Tags
                <br />
                <small>
                  Define tags that can be optionally assigned to ticket holders, in addition to their
                  purchased ticket type.
                </small>
              </div>
              <div className="col-xs-12">
                <TagsField ref="tag" value={values.attendeeTags} onChange={this.handleChangeAttendeeTags} />
              </div>
            </div>
            {hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle ? null : (
              <div className="row">
                <div className="col-xs-6 col-12">
                  <div className="line">
                    <div className="line-cell">
                      <div className="checkbox-switch">
                        <input
                          type="checkbox"
                          id="flagExternalUrl"
                          checked={externalUrlEnabled}
                          onChange={this.handleChangeFlagExternalUrl}
                        />
                        <label htmlFor="flagExternalUrl" />
                      </div>
                    </div>
                    <div className="line-cell">
                      <label htmlFor="flagExternalUrl">
                        Tickets are being sold on a different ticketing platform
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {externalUrlEnabled && !isRnDomain && (
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    id="externalUrl"
                    label="External Ticket URL"
                    size="large"
                    value={values.externalUrl}
                    error={errors.externalUrl}
                    touched={touched.externalUrl}
                    onChange={this.handleChangeExternalUrl}
                  />
                </div>
              </div>
            )}
          </Card>
          <Card icon={'fa-shield'} title={'Anti-Scalping/Fraud Settings'}>
            <div className="card-warning">
              <img src={asset('/resources/images/icon-warning.png')} />
              Important
              <div className="description">
                We recommend that these settings are switched on for <br /> maximum security and fraud
                prevention.
              </div>
            </div>
            <div className="body-panel-spacing" />
            <div className="row">
              <div className="col-xs-5ths col-12 bottom_space">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id="flagCollectNames"
                      type="checkbox"
                      checked={values.flagCollectNames}
                      value={values.flagCollectNames}
                      onChange={this.onImportantSetting}
                    />
                    <label htmlFor="flagCollectNames" />
                  </div>
                  <div className="checkbox-description">Names required for each ticket</div>
                </div>
              </div>
              <div className="col-xs-5ths col-12 bottom_space">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id="flagIDRequired"
                      type="checkbox"
                      checked={values.flagIDRequired}
                      value={values.flagIDRequired}
                      onChange={this.onImportantSetting}
                    />
                    <label htmlFor="flagIDRequired" />
                  </div>
                  <div className="checkbox-description">ID is required</div>
                </div>
              </div>
              <div className="col-xs-5ths col-12 bottom_space">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id="flagResaleEnabled"
                      type="checkbox"
                      checked={values.flagResaleEnabled}
                      value={values.flagResaleEnabled}
                      onChange={this.onImportantSetting}
                    />
                    <label htmlFor="flagResaleEnabled" />
                  </div>
                  <div className="checkbox-description">Ticket resale system enabled</div>
                </div>
              </div>
              {hideAddOnResaleSystemEnabledToggle ? null : <div className="col-xs-5ths col-12 bottom_space">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id="flagEnableAddOnResale"
                      type="checkbox"
                      checked={values.flagEnableAddOnResale}
                      value={values.flagEnableAddOnResale}
                      onChange={this.onImportantSetting}
                    />
                    <label htmlFor="flagEnableAddOnResale" />
                  </div>
                  <div className="checkbox-description">Add-on resale system enabled</div>
                </div>
              </div>}
              <div className="col-xs-5ths col-12 bottom_space">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id="flagNameChecks"
                      type="checkbox"
                      checked={values.flagNameChecks}
                      value={values.flagNameChecks}
                      onChange={this.onImportantSetting}
                    />
                    <label htmlFor="flagNameChecks" />
                  </div>
                  <div className="checkbox-description">Name checks enforced</div>
                </div>
              </div>
            </div>
          </Card>
          {!hideReferral && (
            <Card icon={'fa-random'} title={'Rewards System Targets'}>
              <div className="edit-ticket-tiers">
                <div className="tiers row">
                  <div className="tier-slice">
                    <div className="influencers-tier influencers-tier-0">
                      <div className="tier-left">
                        {values.referralTier1Sales}
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-up fa fa-angle-up"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.increaseReferralTier1Sales : () => {}}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-down fa fa-angle-down"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.decreaseReferralTier1Sales : () => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div className="tier-right">
                        <div className="tier-tickets">
                          Tickets <img src={asset('/resources/images/system_icons/tickets.svg')} />
                        </div>
                        <div className="tier-percentage">
                          <NumberAnimation
                            isLoading={false}
                            initValue={0}
                            target={parseInt(values.referralTier1Percentage)}
                            duration={1500}
                            decimals={0}
                            useGroup={false}
                            animation={'up'}
                            subfix={'%'}
                          />
                          <OverlayTrigger
                            placement="top"
                            delayShow={400}
                            delayHide={10}
                            hideTooltip={!disableRebates}
                            tooltip={this.displayDisableTooltip(disableRebates)}
                          >
                            <i
                              className="tier-percentage-up fa fa-angle-up"
                              aria-hidden="true"
                              onClick={!disableRebates ? this.increaseReferralTier1Percentage : () => {}}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            delayShow={400}
                            delayHide={10}
                            hideTooltip={!disableRebates}
                            tooltip={this.displayDisableTooltip(disableRebates)}
                          >
                            <i
                              className="tier-percentage-down fa fa-angle-down"
                              aria-hidden="true"
                              onClick={!disableRebates ? this.decreaseReferralTier1Percentage : () => {}}
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="tier-rebate color_rebate">Rebate</div>
                      </div>
                    </div>
                    {errors.referralTier1Sales && (
                      <div className="tier-error">{errors.referralTier1Sales}</div>
                    )}
                  </div>
                  <div className="tier-slice">
                    <div className="influencers-tier influencers-tier-1">
                      <div className="tier-left">
                        {values.referralTier2Sales}
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-up fa fa-angle-up"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.increaseReferralTier2Sales : () => {}}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-down fa fa-angle-down"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.decreaseReferralTier2Sales : () => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div className="tier-right">
                        <div className="tier-tickets">
                          Tickets <img src={asset('/resources/images/system_icons/tickets.svg')} />
                        </div>
                        <div className="tier-percentage">
                          <NumberAnimation
                            isLoading={false}
                            initValue={0}
                            target={parseInt(values.referralTier2Percentage)}
                            duration={1500}
                            decimals={0}
                            useGroup={false}
                            animation={'up'}
                            subfix={'%'}
                          />
                          <OverlayTrigger
                            placement="top"
                            delayShow={400}
                            delayHide={10}
                            hideTooltip={!disableRebates}
                            tooltip={this.displayDisableTooltip(disableRebates)}
                          >
                            <i
                              className="tier-percentage-up fa fa-angle-up"
                              aria-hidden="true"
                              onClick={!disableRebates ? this.increaseReferralTier2Percentage : () => {}}
                            />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            delayShow={400}
                            delayHide={10}
                            hideTooltip={!disableRebates}
                            tooltip={this.displayDisableTooltip(disableRebates)}
                          >
                            <i
                              className="tier-percentage-down fa fa-angle-down"
                              aria-hidden="true"
                              onClick={!disableRebates ? this.decreaseReferralTier2Percentage : () => {}}
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="tier-rebate color_rebate">Rebate</div>
                      </div>
                    </div>
                    {errors.referralTier2Sales && (
                      <div className="tier-error">{errors.referralTier2Sales}</div>
                    )}
                  </div>
                  <div className="tier-slice">
                    <div className="influencers-tier influencers-tier-2">
                      <div className="tier-left">
                        {values.referralTier3Sales}
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-up fa fa-angle-up"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.increaseReferralTier3Sales : () => {}}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          delayShow={400}
                          delayHide={10}
                          hideTooltip={!disableRebates}
                          tooltip={this.displayDisableTooltip(disableRebates)}
                        >
                          <i
                            className="tier-value-down fa fa-angle-down"
                            aria-hidden="true"
                            onClick={!disableRebates ? this.decreaseReferralTier3Sales : () => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div className="tier-right">
                        <div className="tier-tickets">
                          Tickets <img src={asset('/resources/images/system_icons/tickets.svg')} />
                        </div>
                        <div className="tier-percentage">100%</div>
                        <div className="tier-rebate color_rebate">Rebate</div>
                      </div>
                    </div>
                    {errors.referralTier3Sales && (
                      <div className="tier-error">{errors.referralTier3Sales}</div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          )}
          <Card icon={'fa-cog'} title={'General Ticket Settings'}>
            <div className="row">
              <div className="max-order">
                <img
                  src={asset('/resources/images/system_icons/tickets.svg')}
                  width="24"
                  height="24"
                  className="max-order-icon"
                  alt=""
                />
                <div className="max-order-label">Maximum ticket quantity per order</div>
                <Field
                  size="large"
                  value={values.maxTicketsPerOrder}
                  className="max-order-field"
                  onChange={event => this.handleMaxTicketsPerOrderChange(event.target.value)}
                />
                <i className="max-order-up fa fa-angle-up" onClick={this.increaseMaxTicketsPerOrder} />
                <i className="max-order-down fa fa-angle-down" onClick={this.decreaseMaxTicketsPerOrder} />
              </div>
            </div>
            <div className="row">
              <div className="order-toggle-group-wrapper">
                <FormikField
                  name="maxTicketsPerAccount"
                  offText="Set custom maximum quantity per account"
                  component={props => <CustomToggleField {...props} direction="right" />}
                  onChange={() => {
                    if (values.maxTicketsPerAccount === '') {
                      return this.updateField('maxTicketsPerAccount', '1')
                    }
                    return this.updateField('maxTicketsPerAccount', '')
                  }}
                />
              </div>
              <div className="div-spacing-10" />
              <div className="max-order">
                {values.maxTicketsPerAccount === '' ? null : (
                  <>
                    <img
                      src={asset('/resources/images/system_icons/tickets.svg')}
                      width="24"
                      height="24"
                      className="max-order-icon"
                      alt=""
                    />
                    <div className="max-order-label">Maximum ticket quantity per account</div>
                    <Field
                      size="large"
                      value={values.maxTicketsPerAccount}
                      className="max-order-field"
                      onChange={event => this.handleMaxTicketsPerAccountChange(event.target.value)}
                    />
                    <i className="max-order-up fa fa-angle-up" onClick={this.increaseMaxTicketsPerAccount} />
                    <i
                      className="max-order-down fa fa-angle-down"
                      onClick={this.decreaseMaxTicketsPerAccount}
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-xs-12 text-center">
              <Button
                className="btn btn-success btn-xl btn-shadow"
                type="button"
                loading={isSubmitting}
                onClick={this.processSubmit}
              >
                {submitLabel || 'Save Ticket Settings'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const TicketOptions = withFormik({
  mapPropsToValues: props => ({
    feeMode: _.get(props, 'initialValues.feeMode') || 'forward',
    minOrder: _.get(props, 'initialValues.minOrder') || 1,
    maxTicketsPerOrder: _.get(props, 'initialValues.maxTicketsPerOrder') || 10,
    maxTicketsPerAccount: _.get(props, 'initialValues.maxTicketsPerAccount') || '',
    flagDisableReferral: _.get(props, 'initialValues.flagDisableReferral'),
    referralTier1Sales: _.get(props, 'initialValues.referralTier1Sales'),
    referralTier1Percentage: _.get(props, 'initialValues.referralTier1Percentage'),
    referralTier2Sales: _.get(props, 'initialValues.referralTier2Sales'),
    referralTier2Percentage: _.get(props, 'initialValues.referralTier2Percentage'),
    referralTier3Sales: _.get(props, 'initialValues.referralTier3Sales'),
    referralTier3Percentage: _.get(props, 'initialValues.referralTier3Percentage'),
    salesStartDate: _.get(props, 'initialValues.salesStartDate'),
    salesEndDate: _.get(props, 'initialValues.salesEndDate'),
    flagCollectNames: _.get(props, 'initialValues.flagCollectNames'),
    flagIDRequired: _.get(props, 'initialValues.flagIDRequired'),
    flagResaleEnabled: _.get(props, 'initialValues.flagResaleEnabled'),
    flagAllowCustomerRefund: _.get(props, 'initialValues.flagAllowCustomerRefund'),
    flagEnableAddOnResale: _.get(props, 'initialValues.flagEnableAddOnResale'),
    flagNameChecks: _.get(props, 'initialValues.flagNameChecks'),
    flagEnableWaitingList: _.get(props, 'initialValues.flagEnableWaitingList'),
    flagEnableAddOnWaitingList: _.get(props, 'initialValues.flagEnableAddOnWaitingList'),
    flagRequirePhone: _.get(props, 'initialValues.flagRequirePhone'),
    flagHidePhoneField: _.get(props, 'initialValues.flagHidePhoneField'),
    flagTaxesIncluded: _.get(props, 'initialValues.flagTaxesIncluded'),
    flagValidateAge: _.get(props, 'initialValues.flagValidateAge'),
    flagNameChanges: _.get(props, 'initialValues.flagNameChanges'),
    flagTicketSwaps: _.get(props, 'initialValues.flagTicketSwaps'),
    flagAllowMultipleTicketTypePurchases: _.get(props, 'initialValues.flagAllowMultipleTicketTypePurchases'),
    externalUrl: _.get(props, 'initialValues.externalUrl'),
    ticketText: _.get(props, 'initialValues.ticketText'),
    attendeeTags: _.get(props, 'initialValues.attendeeTags'),
    flagFundsRemitted: _.get(props, 'initialValues.flagFundsRemitted'),
    flagSeatMapAllowed: _.get(props, 'initialValues.flagSeatMapAllowed'),
    flagPaidInAdvance: _.get(props, 'initialValues.flagPaidInAdvance'),
    endDate: _.get(props, 'initialValues.endDate'),
    startDate: _.get(props, 'initialValues.startDate'),
  }),

  // Custom sync validation
  validate: values => validate(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    // Ignore flagFundsRemitted value
    const { flagFundsRemitted, ...submittedValues } = values

    props
      .onSubmit({
        attributes: { ...submittedValues },
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'TicketOptions', // helps with React DevTools
})(MyForm)

export default TicketOptions
