// Deprecated

import React from 'react'
import moment from 'moment'

export default React.memo(props => {
  const { value, tz, format, className = '' } = props

  let formatted = moment(value)
  if (tz) {
    formatted = formatted.tz(tz)
  }
  formatted = formatted.format(format || 'LLL')
  return <div className={className}>{formatted}</div>
})
