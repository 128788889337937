import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import { default as TagsFieldDefault } from '../../_library/TagsField'

const TagsField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
  }

  const onChange = (tags = [], changed, changedIndexes) => {
    if (props.onChange) {
      props.onChange(field.name, tags, changed, changedIndexes)
    }
    setValue(tags)
  }

  return (
    <TagsFieldDefault
      {...field}
      {...props}
      id={field.name}
      tags={props.value}
      touched={_get(touched, field.name)}
      error={_get(errors, field.name)}
      onChange={onChange}
    />
  )
}

export { TagsField }
