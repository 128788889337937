import React from 'react'
import { connect } from 'react-redux'
import _merge from 'lodash/merge'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _result from 'lodash/result'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'
import Modal from 'react-modal'

import { DragDropContext } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import MultiBackend, { TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { withRouter } from 'react-router-dom'
import { asyncComponent, DisableSectionByPermissionWrapper } from '../hoc'

import modalStyle from '../../_common/core/modalStyle'
import Button from '../_library/Button'
import Card from '../_library/Card'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'

import TicketsTable from './ticket/TicketsTable'
import TicketForm from './ticket/TicketForm'
import TicketOptionsForm from './ticket/TicketOptionsForm'
import TicketAdvancedOptionsForm from './ticket/TicketAdvancedOptionsForm'

const SeatMapsSection = asyncComponent(() => import('./ticket/SeatMapsSection'))

const BlockSeatsCard = asyncComponent(() => import('../events/form/EventForm/SeatsCard/index'))

import AddOnSection from './addons/AddOnSection'

import { UPDATE_EVENT } from '../../_common/redux/events/actions'
import {
  FETCH_EVENT_TICKETS,
  FETCH_EVENT_TABLES,
  CREATE_TICKET,
  CREATE_TABLE,
  UPDATE_TICKET,
  UPDATE_TABLE,
  GET_TIME_SLOT_GROUPS,
} from '../../_common/redux/tickets/actions'
import { FETCH_EVENT_PERFORMANCE } from '../../_common/redux/performance/actions'
import { FETCH_BRAND_PIXEL_DATA } from '../../_common/redux/brands/actions'
// import { getTimeSlotTicketsByDay } from './ticket/ticketFormRows/TimeSlotsRow/utils/generateTimeSlots'
import TimeSlotTicketsTable from './ticket/TimeSlotTicketsTable'
import { TIME_DISPLAY_FORMAT } from '../constants/timeFormats'

import {
  get_event,
  get_brand,
  get_event_tickets,
  get_event_tables,
  get_event_time_slot,
  get_brand_pixel_data,
} from '../../_common/core/selectors'

import {
  updateTicketsSorting,
  getPerfomanceData,
  getTicketTypeOrders,
  deleteTicketType,
} from '../../_common/core/http_services'
import { showAxiosError, showSuccessMessage } from '../utils/messenger'
import SalesTracking from './salesTracking'
import { getTitle } from '../utils/getTitle'
import ConfirmModal from '../_library/ConfirmModal'

const cloneObject = src => JSON.parse(JSON.stringify(src))

const cloneArray = src => _map(src, t => cloneObject(t))

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend({
        enableMouseEvents: true,
        delayTouchStart: 300,
      }),
      preview: true,
      transition: TouchTransition,
    },
  ],
}

@connect(
  state => {
    const brand = get_brand(state)
    const event = get_event(state)
    const tickets = get_event_tickets(state)
    const tables = get_event_tables(state)
    const timeSlotGroups = get_event_time_slot(state)
    const brandPixelData = get_brand_pixel_data(state)
    const { isRnDomain } = state.shared
    const { user } = state.auth

    const loadingTickets =
      state.loading.has('GET_TIME_SLOT_GROUPS') || state.loading.has('FETCH_EVENT_TICKETS')

    const launchDarklyFlags = { flagHoldTicketsEnabled: true } //_get(state, 'launchDarklyInfo.launchDarklyInfo')

    const dynamicConfigs = launchDarklyFlags //_get(state, 'launchDarklyInfo.launchDarklyFlags', {})
    return {
      brand,
      event,
      tickets,
      tables,
      timeSlotGroups,
      loadingTickets,
      brandPixelData,
      launchDarklyFlags,
      dynamicConfigs,
      isRnDomain,
      user,
    }
  },
  {
    FETCH_EVENT_TICKETS,
    FETCH_EVENT_TABLES,
    CREATE_TICKET,
    CREATE_TABLE,
    UPDATE_TICKET,
    UPDATE_TABLE,
    UPDATE_EVENT,
    GET_TIME_SLOT_GROUPS,
    FETCH_EVENT_PERFORMANCE,
    FETCH_BRAND_PIXEL_DATA,
  },
)
@DragDropContext(MultiBackend(HTML5toTouch))
@withRouter
export default class EventTickets extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadingFetch: false,
      loadingTableFetch: false,
      editing: null,
      adding: false,
      tableAdding: false,
      isRewardSystemDisabled: false,
      ticketsDnD: [],
      dndHistory: [],
      dndHistoryCurrentPos: 0,
      tableTypesDnD: [],
      tableTypesDnDHistory: [],
      tableTypesDnDHistoryCurrentPos: 0,
      ticketSales: 0,
      saveSortDialogOpen: false,
      saveTableTypeSortDialogOpen: false,
      savingSort: false,
      tableTypeSavingSort: false,
      savingSortTotal: 0,
      savingSortCurrent: 0,
      flagExternalUrl: !!props.event.externalUrl,
      cartsData: {},
      isLoadingCartsData: false,
      tempDirtyStock: 0,
      tableType: false,
      deleteTicketType: false,
      deleteTableType: false,
      ticketsOrders: [],
      tableTypesOrders: [],
      currentTicketType: null,
      currentDeleteType: null,
      deleteErrorMessage: '',
    }
  }
  componentDidMount() {
    const { event, tickets, tables } = this.props
    this.init(event, tickets, tables)
    window.Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future',
    }
  }

  componentWillUnmount() {
    form_helper_reset()
    clearInterval(this.fetchCarts)
    this.isUnmounted = true
  }

  init = (event, tickets, tables) => {
    this.fetchBrandPixelData(event.id, event.owner.id)
    this.fetchEventPerformance(event)
    this.refresh()
    this.tableTypeRefresh()
    if (tickets.length) {
      this.initDnD(tickets, false)
    }
    if (tables.length) {
      this.initDnD(tables, false, true) // 3rd argumnet is table-type
    }
    this.setDocumentTitle(event)
    form_helper_reset()
    this.fetchCartsData()
    this.fetchCarts = setInterval(async () => {
      this.fetchCartsData()
    }, 10 * 1000)
  }

  fetchCartsData = async () => {
    const { event } = this.props
    const section = 'carts'
    try {
      this.setState({
        isLoadingCartsData: true,
      })
      const res = await getPerfomanceData(event.id, section)
      const cartsData = _get(res, section) || []
      this.handleResponse(cartsData)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  handleResponse = (cartsData, error) => {
    const { isLoadingCartsData } = this.state

    isLoadingCartsData &&
      this.setState({
        isLoadingCartsData: false,
      })

    if (error) {
    } else {
      this.setState({ cartsData })
    }
  }
  fetchEventPerformance = event => {
    const { FETCH_EVENT_PERFORMANCE } = this.props
    if (!event) {
      return
    }
    Promise.resolve(FETCH_EVENT_PERFORMANCE(event.id, 'sales')).then(res => {
      let ticketCount = 0
      const data = _get(res, 'data.$original')

      if (data.sales.length) {
        data.sales.forEach(item => {
          ticketCount += Number(item.quantity)
        })
      }
      !this.isUnmounted &&
        this.setState(prevState => ({
          ...prevState,
          ticketSales: ticketCount,
          isRewardSystemDisabled: event.flagDisableReferral,
        }))
    })
  }

  setDocumentTitle = ({ displayName }) => {
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  fetchBrandPixelData = (eventId, brandId) => {
    const { FETCH_BRAND_PIXEL_DATA } = this.props
    if (!eventId || !brandId) {
      return
    }
    FETCH_BRAND_PIXEL_DATA(eventId, brandId)
  }
  routerWillLeave = () => !form_helper_isEditted()

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { tickets: pTickets } = prevProps
    const { tickets: nTickets, event } = this.props
    const {
      isUpdatingTicket: pIsUpdatingTicket,
      ticketsDnD: pTicketsDnD,
      tableTypesDnD: pTableTypesDnD,
    } = prevState
    const { isUpdatingTicket: nIsUpdatingTicket, ticketsDnD, tableTypesDnD } = this.state

    if (pTickets !== nTickets || (pIsUpdatingTicket && !nIsUpdatingTicket)) {
      const tempTickets = []
      _forEach(nTickets, t => {
        if (t.$original.tickets) {
          _map(t.$original.tickets, o => {
            tempTickets.push({
              $relationships: t.$relationships,
              $original: {
                id: o.id,
                type: o.type,
                attributes: { ...o.attributes },
                relationships: t.$relationships,
              },
              id: o.id,
              ...o.attributes,
            })
          })
        } else {
          tempTickets.push(t)
        }
      })
      this.setState({
        isTimeSlot: false,
        tickets: tempTickets,
        isUpdatingTicket: false,
        deleting: null,
        tableTypeDeleting: null,
      })
      if (nTickets !== pTickets || !ticketsDnD.length) {
        this.initDnD(tempTickets, false)
      }
    }
    if (!_isEqual(pTicketsDnD, ticketsDnD)) {
      const ticketsOrders = ticketsDnD.map(async ticket => await getTicketTypeOrders(event?.id, ticket.id))
      Promise.all(ticketsOrders).then(data => {
        this.setState({
          ticketsOrders: data,
        })
      })
    }
    if (!_isEqual(pTableTypesDnD, tableTypesDnD)) {
      const ticketsOrders = tableTypesDnD.map(
        async tableType => await getTicketTypeOrders(event?.id, tableType.id),
      )
      Promise.all(ticketsOrders).then(data => {
        this.setState({
          tableTypesOrders: data,
        })
      })
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { event: pEvent } = this.props
    const { event: nEvent, tickets } = nextProps

    if (pEvent.id !== nEvent.id) {
      this.init(nEvent, tickets)
    }
  }

  initDnD = (tickets, ignoreSaving, tableType = false) => {
    const ticketsClone = _cloneDeep(tickets)
    const savingSort = tableType ? this.state.tableTypeSavingSort : this.state.savingSort
    const initDnD = ignoreSaving ? true : !savingSort
    if (initDnD) {
      // init ticketsDnD from props->tickets
      let ticketsDnD = _filter(ticketsClone, t => !t.slotGroupName)

      for (let i = 0; i < ticketsDnD.length; i++) {
        ticketsDnD[i].sortOrder = parseInt(ticketsDnD[i].sortOrder)
      }
      ticketsDnD = _orderBy(ticketsDnD, ['sortOrder', 'displayName'], ['asc', 'asc'])
      for (let i = 0; i < ticketsDnD.length; i++) {
        ticketsDnD[i].sortOrder = i + 1
      }

      //add to history
      const newHistory = _map(ticketsDnD, t => ({
        id: t.id,
        sortOrder: t.sortOrder,
        displayName: t.displayName,
      }))
      const dndHistory = []
      dndHistory.push(newHistory)

      if (tableType) {
        !this.isUnmounted &&
          this.setState({
            tableTypesDnD: ticketsDnD,
            tableTypesDnDHistory: dndHistory,
            tableTypesDnDHistoryCurrentPos: 0,
          })
      } else {
        !this.isUnmounted &&
          this.setState({
            ticketsDnD,
            dndHistory,
            dndHistoryCurrentPos: 0,
          })
      }
    }
  }
  moveCard = (dragIndex, hoverIndex) => {
    const { savingSort } = this.state
    let { ticketsDnD } = this.state
    //let padToFour = number => number <= 9999 ? ('000' + number).slice(-4) : number
    if (savingSort) return
    ticketsDnD = _orderBy(ticketsDnD, ['sortOrder', 'displayName'], ['asc', 'asc'])
    for (let i = 0; i < ticketsDnD.length; i++) {
      ticketsDnD[i].sortOrder = i + 1
    }
    ticketsDnD[dragIndex].sortOrder = hoverIndex + 1
    ticketsDnD[hoverIndex].sortOrder = dragIndex + 1

    // add to history
    const newHistory = _map(ticketsDnD, t => ({
      id: t.id,
      sortOrder: t.sortOrder,
      displayName: t.displayName,
    }))
    let { dndHistory, dndHistoryCurrentPos } = this.state
    const dndHistoryCount = dndHistory.length
    if (dndHistoryCount !== dndHistoryCurrentPos + 1) {
      //undo/redo executed
      // get sub-list of history from 0 to newPos, and set it as new history
      const tmpHistory = []
      for (let i = 0; i <= dndHistoryCurrentPos; i++) {
        tmpHistory.push(cloneArray(dndHistory[i]))
      }
      dndHistory = tmpHistory
    }
    dndHistory.push(newHistory)
    dndHistoryCurrentPos++
    this.setState({
      ticketsDnD,
      dndHistory,
      dndHistoryCurrentPos,
    })
  }
  tableTypeMoveCard = (dragIndex, hoverIndex) => {
    let { tableTypesDnD, tableTypesDnDHistory, tableTypesDnDHistoryCurrentPos, tableTypeSavingSort } =
      this.state

    if (tableTypeSavingSort) return
    tableTypesDnD = _orderBy(tableTypesDnD, ['sortOrder', 'displayName'], ['asc', 'asc'])
    for (let i = 0; i < tableTypesDnD.length; i++) {
      tableTypesDnD[i].sortOrder = i + 1
    }
    tableTypesDnD[dragIndex].sortOrder = hoverIndex + 1
    tableTypesDnD[hoverIndex].sortOrder = dragIndex + 1

    // add to history
    const newHistory = _map(tableTypesDnD, t => ({
      id: t.id,
      sortOrder: t.sortOrder,
      displayName: t.displayName,
    }))
    const dndHistoryCount = tableTypesDnDHistory.length
    if (dndHistoryCount !== tableTypesDnDHistoryCurrentPos + 1) {
      //undo/redo executed
      // get sub-list of history from 0 to newPos, and set it as new history
      const tmpHistory = []
      for (let i = 0; i <= tableTypesDnDHistoryCurrentPos; i++) {
        tmpHistory.push(cloneArray(tableTypesDnDHistory[i]))
      }
      tableTypesDnDHistory = tmpHistory
    }
    tableTypesDnDHistory.push(newHistory)
    tableTypesDnDHistoryCurrentPos++
    this.setState({
      tableTypesDnD,
      tableTypesDnDHistory,
      tableTypesDnDHistoryCurrentPos,
    })
  }
  processUndoRedo = (newPos, tableType) => {
    const ticketsDnD = tableType ? this.state.tableTypesDnD : this.state.ticketsDnD
    const dndHistory = tableType ? this.state.tableTypesDnDHistory : this.state.dndHistory
    const dndHistoryCount = dndHistory.length
    let updatedPos = newPos

    if (newPos < 0) {
      updatedPos = 0
    } else if (newPos >= dndHistoryCount) {
      updatedPos = dndHistoryCount - 1
    }

    // set ticketDnD from currentHistory
    const currentHistory = dndHistory[updatedPos]
    for (let i = 0; i < ticketsDnD.length; i++) {
      const { id } = ticketsDnD[i]
      // search from history
      for (let j = 0; j < currentHistory.length; j++) {
        const hid = currentHistory[j].id
        const hsortOrder = currentHistory[j].sortOrder
        if (hid === id) {
          ticketsDnD[i].sortOrder = hsortOrder
          break
        }
      }
    }
    if (tableType) {
      this.setState({ tableTypesDnD: ticketsDnD, tableTypesDnDHistoryCurrentPos: updatedPos })
    } else {
      this.setState({ ticketsDnD, dndHistoryCurrentPos: updatedPos })
    }
  }
  handleUndo = (tableType = false) => {
    const dndHistoryCurrentPos = tableType
      ? this.state.tableTypesDnDHistoryCurrentPos
      : this.state.dndHistoryCurrentPos
    this.processUndoRedo(dndHistoryCurrentPos - 1, tableType)
  }
  handleRedo = (tableType = false) => {
    const dndHistoryCurrentPos = tableType
      ? this.state.tableTypesDnDHistoryCurrentPos
      : this.state.dndHistoryCurrentPos
    this.processUndoRedo(dndHistoryCurrentPos + 1, tableType)
  }
  handleSaveSort = async () => {
    const { loading, ticketsDnD } = this.state
    const { event } = this.props
    const total = ticketsDnD.length

    if (loading) return
    const savingSort = (sortStarted, total) => {
      if (sortStarted) {
        this.setState({ savingSortCurrent: 0, savingSort: sortStarted, savingSortTotal: total })
      } else {
        this.initDnD(ticketsDnD, true)
        this.setState({ savingSort: false, saveSortDialogOpen: false })
      }
    }
    try {
      savingSort(true, total)
      const newTicketsSorting = _map(ticketsDnD, ticket => ({ id: ticket.id, sortOrder: ticket.sortOrder }))
      await updateTicketsSorting(event.id, newTicketsSorting)
    } catch (err) {
      showAxiosError(err)
    } finally {
      savingSort(false, total)
    }
  }
  handleTableTypeSaveSort = async () => {
    const { tableTypesDnD } = this.state
    const { event } = this.props
    const total = tableTypesDnD.length

    const savingSort = (sortStarted, total) => {
      if (sortStarted) {
        this.setState({ tableTypeSavingSort: sortStarted })
      } else {
        this.initDnD(tableTypesDnD, true, true)
        this.setState({ tableTypeSavingSort: false, saveTableTypeSortDialogOpen: false })
      }
    }
    try {
      savingSort(true, total)
      const newTicketsSorting = _map(tableTypesDnD, table => ({ id: table.id, sortOrder: table.sortOrder }))
      await updateTicketsSorting(event.id, newTicketsSorting)
    } catch (err) {
      showAxiosError(err)
    } finally {
      savingSort(false, total)
    }
  }
  handleCancelSave = () => {
    this.setState({ saveSortDialogOpen: false, saveTableTypeSortDialogOpen: false })
  }
  handleCancelChanges = () => {
    const { tickets } = this.state
    this.initDnD(tickets, true)
  }

  refresh = async () => {
    const { dndHistoryCurrentPos, isSlotTicket } = this.state

    if (dndHistoryCurrentPos > 0 && !isSlotTicket) {
      this.setState({ saveSortDialogOpen: true })
    } else {
      const { event, FETCH_EVENT_TICKETS, FETCH_EVENT_TABLES, GET_TIME_SLOT_GROUPS } = this.props
      if (this.state.loading) {
        return
      }
      try {
        !this.isUnmounted && this.setState({ loadingFetch: true, dndHistoryCurrentPos: 0 })

        await FETCH_EVENT_TICKETS(event.id)
        await FETCH_EVENT_TABLES(event.id)

        if (event && event.flagTimeSlotsEnabled) {
          await GET_TIME_SLOT_GROUPS(event.id)
        }
        this.initDnD(this.props.tickets)

        !this.isUnmounted && this.setState({ loadingFetch: false, dndHistoryCurrentPos: 0 })
      } catch (err) {
        !this.isUnmounted && this.setState({ loadingFetch: false, dndHistoryCurrentPos: 0 })
      }
    }
  }
  tableTypeRefresh = async () => {
    const { tableTypesDnDHistoryCurrentPos } = this.state

    if (tableTypesDnDHistoryCurrentPos > 0) {
      this.setState({ saveTableTypeSortDialogOpen: true })
    } else {
      const { event, FETCH_EVENT_TABLES } = this.props
      try {
        !this.isUnmounted && this.setState({ loadingTableFetch: true, tableTypesDnDHistoryCurrentPos: 0 })

        await FETCH_EVENT_TABLES(event.id)

        this.initDnD(this.props.tables, null, true)
      } catch (error) {
        // ...
      } finally {
        !this.isUnmounted && this.setState({ loadingTableFetch: false, tableTypesDnDHistoryCurrentPos: 0 })
      }
    }
  }
  handleAdd = () => {
    const { dndHistoryCurrentPos } = this.state
    if (dndHistoryCurrentPos > 0) {
      this.setState({ saveSortDialogOpen: true })
    } else {
      this.setState({ editing: null, adding: true, isSlotTicket: false })
    }
  }
  handleTableAdd = () => {
    const { tableTypesDnDHistoryCurrentPos } = this.state
    if (tableTypesDnDHistoryCurrentPos > 0) {
      this.setState({ saveTableTypeSortDialogOpen: true })
    } else {
      this.setState({ editing: null, tableAdding: true })
    }
  }
  handleEdit = (ticket, isSlotTicket) => {
    const { dndHistoryCurrentPos } = this.state

    if (dndHistoryCurrentPos > 0 && !isSlotTicket) {
      this.setState({ saveSortDialogOpen: true, tempDirtyStock: 0, tableType: false })
    } else {
      this.setState({ editing: ticket, adding: false, isSlotTicket, tempDirtyStock: 0, tableType: false })
    }
  }
  handleTableEdit = ticket => {
    const { tableTypesDnDHistoryCurrentPos } = this.state

    if (tableTypesDnDHistoryCurrentPos > 0) {
      this.setState({ saveTableTypeSortDialogOpen: true, tempDirtyStock: 0, tableType: true })
    } else {
      this.setState({ editing: ticket, adding: false, tempDirtyStock: 0, tableType: true })
    }
  }
  handleActivate = (ticket, isSlotTicket) => {
    const { dndHistoryCurrentPos } = this.state
    if (dndHistoryCurrentPos > 0 && !isSlotTicket) {
      this.setState({ saveSortDialogOpen: true })
    } else {
      const { UPDATE_TICKET, event } = this.props
      this.setState({ isUpdatingTicket: true })
      Promise.resolve(UPDATE_TICKET(ticket.id, { attributes: { active: true } }, event.id)).then(() => {
        this.refresh()
        !!isSlotTicket && this.setState({ isUpdatingTicket: false })
      })
    }
  }
  handleTableTypeActivate = table => {
    const { tableTypesDnDHistoryCurrentPos } = this.state
    if (tableTypesDnDHistoryCurrentPos > 0) {
      this.setState({ saveTableTypeSortDialogOpen: true })
    } else {
      const { UPDATE_TICKET, event } = this.props
      Promise.resolve(UPDATE_TICKET(table.id, { attributes: { active: true } }, event.id)).then(() => {
        this.tableTypeRefresh()
      })
    }
  }
  handleDeactivate = (ticket, isSlotTicket) => {
    const { dndHistoryCurrentPos } = this.state
    if (dndHistoryCurrentPos > 0 && !isSlotTicket) {
      this.setState({ saveSortDialogOpen: true })
    } else {
      const { deleting } = this.state
      const { UPDATE_TICKET, event } = this.props
      if (deleting && deleting.id === ticket.id) {
        this.setState({ isUpdatingTicket: true })
        Promise.resolve(UPDATE_TICKET(ticket.id, { attributes: { active: false } }, event.id)).finally(() => {
          this.handleCancelDeactivate()
          this.refresh()
        })
      } else {
        this.setState({ deleting: ticket })
      }
    }
  }
  handleTableTypeDeactivate = table => {
    const { tableTypesDnDHistoryCurrentPos } = this.state
    if (tableTypesDnDHistoryCurrentPos > 0) {
      this.setState({ saveTableTypeSortDialogOpen: true })
    } else {
      const { tableTypeDeleting } = this.state
      const { UPDATE_TICKET, event } = this.props
      if (tableTypeDeleting && tableTypeDeleting.id === table.id) {
        Promise.resolve(UPDATE_TICKET(table.id, { attributes: { active: false } }, event.id)).finally(() => {
          this.handleCancelDeactivate()
          this.tableTypeRefresh()
        })
      } else {
        this.setState({ tableTypeDeleting: table })
      }
    }
  }
  handleCancelDeactivate = () => {
    this.setState(() => ({ deleting: null, tableTypeDeleting: null, isUpdatingTicket: false }))
  }
  handleCancel = () => {
    this.setState({ editing: null, adding: false, tableAdding: false })
  }
  handleSubmit = (isNew, tid, form) => {
    const { event, CREATE_TICKET, UPDATE_TICKET, GET_TIME_SLOT_GROUPS } = this.props
    const isTimeSlot = !!(form.attributes.timeSlots && form.attributes.timeSlots.length)
    this.setState({ isTimeSlot })
    // need to check
    if (event.inventoryMode === 'stock') {
      delete form.attributes.maxInventory
    }

    // submit
    if (isNew) {
      const unsetAdding = () => {
        form_helper_reset()
        GET_TIME_SLOT_GROUPS(event.id)
        this.setState({ adding: false, dndHistoryCurrentPos: 0 })
        this.refresh()
      }
      return Promise.resolve(CREATE_TICKET(event.id, form, isTimeSlot))
        .then(unsetAdding)
        .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
    }
    return Promise.resolve(UPDATE_TICKET(tid, form, event.id))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(() => {
        form_helper_reset()
        this.setState({ editing: null })
        this.refresh()
      })
  }
  handleTableSubmit = (isNew, tid, form) => {
    const { event, CREATE_TABLE, UPDATE_TABLE } = this.props
    const unsetAdding = () => {
      form_helper_reset()
      this.setState({ tableAdding: false, tableTypesDnDHistoryCurrentPos: 0 })
      this.tableTypeRefresh()
    }
    if (isNew) {
      return Promise.resolve(CREATE_TABLE(event.id, form))
        .then(unsetAdding)
        .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
    }
    return Promise.resolve(UPDATE_TABLE(tid, form, event.id))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(() => {
        form_helper_reset()
        this.setState({ editing: null, tableType: false })
        this.tableTypeRefresh()
      })
  }
  handleUpdateEvent_OptionsForm = form => {
    const { event, UPDATE_EVENT, isRnDomain } = this.props

    if (isRnDomain) {
      delete form.attributes.externalUrl
    }

    return Promise.resolve(UPDATE_EVENT(event.id, form))
      .then(event => {
        showSuccessMessage('Successfully updated ticket settings')
        this.setState(prevState => ({
          ...prevState,
          isRewardSystemDisabled: event.flagDisableReferral,
        }))
        form_helper_reset()
        return event
      })
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
  }

  handleUpdateEvent = form => {
    const { event, UPDATE_EVENT } = this.props
    return Promise.resolve(UPDATE_EVENT(event.id, form))
      .then(event => {
        showSuccessMessage('Successfully updated advanced options')
        form_helper_reset()
        return event
      })
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
  }

  handleAddAddon = () => {
    this.setState({ addingAddon: true })
  }
  handleSubmitNewAddon = form => {
    const { event, CREATE_ADDON } = this.props

    if (!form.attributes.ticketTypeNeeded) {
      form.attributes.prerequisiteTicketTypeIds = null
    }
    delete form.attributes.ticketTypeNeeded
    if (form.attributes.stockUnlimited) {
      form.attributes.stock = null
    }
    delete form.attributes.stockUnlimited

    // submit
    const unsetAdding = () => this.setState({ addingAddon: false })
    return Promise.resolve(CREATE_ADDON(event.id, form))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(unsetAdding)
  }
  handleSubmitEditAddon = (aid, form) => {
    const { event, UPDATE_ADDON } = this.props

    if (!form.attributes.ticketTypeNeeded) {
      form.attributes.prerequisiteTicketTypeIds = null
    }
    delete form.attributes.ticketTypeNeeded
    if (form.attributes.stockUnlimited) {
      form.attributes.stock = null
    }
    delete form.attributes.stockUnlimited

    // submit
    const unsetEditing = () => this.setState({ editingAddon: null })
    return Promise.resolve(UPDATE_ADDON(event.id, aid, form))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(unsetEditing)
  }
  handleCancelAddon = () => {
    this.setState({ addingAddon: false, editingAddon: null })
  }
  handleEditAddon = ao => {
    this.setState({ editingAddon: ao, addingAddon: false })
  }
  handleActivateAddon = ao => {
    const { event, UPDATE_ADDON } = this.props
    // submit
    return Promise.resolve(UPDATE_ADDON(event.id, ao.id, { attributes: { active: true } })).catch(err =>
      Promise.reject(_result(err, 'toFieldErrors', err)),
    )
  }
  handleDeactivateAddonOpenModal = ao => {
    this.setState({ deactivatingAddon: ao })
  }
  handleDeactivateAddon = ao => {
    const { event, UPDATE_ADDON } = this.props
    // submit
    const unsetDeactivating = () => this.setState({ deactivatingAddon: null })
    return Promise.resolve(UPDATE_ADDON(event.id, ao.id, { attributes: { active: false } }))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(unsetDeactivating)
  }
  handleDeactivateAddonCancel = () => {
    this.setState({ deactivatingAddon: null })
  }

  handleExternalUrl = checked => {
    this.setState({ flagExternalUrl: checked })
  }

  handleHoldRowDelete = data => {
    const { tempDirtyStock } = this.state
    this.setState({
      tempDirtyStock: +tempDirtyStock + data.quantity,
    })
  }

  handleDeleteTicketType = (ticketId, type) => {
    this.setState({
      deleteTicketType: true,
      currentTicketType: ticketId,
      currentDeleteType: type,
    })
  }
  onConfirmResponse = response => {
    switch (response) {
      case 'delete': {
        return this.deleteType()
      }
      default:
        return this.openConfirmModal(false)
    }
  }

  deleteType = async () => {
    const { currentTicketType, currentDeleteType } = this.state
    const currentType = currentDeleteType
    const { event } = this.props
    try {
      const deleted = await deleteTicketType(event.id, currentTicketType)
      this.setState(() => ({
        deleteTicketType: false,
        currentTicketType: null,
        currentDeleteType: null,
      }))
    } catch (e) {
      this.setState({
        deleteErrorMessage: e?.response?.data?.message,
      })
    } finally {
      switch (currentType) {
        case 'Ticket': {
          return this.refresh()
        }
        case 'Table': {
          return this.tableTypeRefresh()
        }
        default:
          return
      }
    }
  }

  openConfirmModal = (deleteTicketType = !this.state.deleteTicketType) => {
    this.setState(() => ({
      deleteTicketType: false,
      currentTicketType: null,
      currentDeleteType: null,
      deleteErrorMessage: '',
    }))
  }

  renderDeleteTicketTypeButton = (ticketTypeId, type) => {
    const { ticketsOrders, tableTypesOrders, cartsData = {} } = this.state

    const orders = [...ticketsOrders, ...tableTypesOrders]
    const currentTicketType = orders.filter(order => order.ticketId === ticketTypeId)
    const orderCount = Number(currentTicketType[0]?.orders.count)
    const ticketsInCart = cartsData?.ticketTypes?.map(ticket => ticket.id) || []
    const isInCart = ticketsInCart.includes(Number(ticketTypeId))

    return (
      <Button
        key={ticketTypeId}
        disabled={orderCount !== 0 || isInCart}
        className="btn btn-danger"
        type="button"
        onClick={() => this.handleDeleteTicketType(ticketTypeId, type)}
      >
        <>
          <i className="fa fa-trash fa-fw" />
          Delete
          <div className="help-text-container">
            <p className="help-text">
              Inactive because this {type} <br />
              is associated with other
              <br />
              orders and add-ons
            </p>
          </div>
        </>
      </Button>
    )
  }

  render() {
    const {
      loadingFetch,
      loadingTableFetch,
      adding,
      tableAdding,
      editing,
      deleting,
      tableTypeDeleting,
      ticketSales,
      isRewardSystemDisabled,
      cartsData,
      ticketsDnD,
      dndHistory,
      dndHistoryCurrentPos,
      tableTypesDnD,
      tableTypesDnDHistory,
      tableTypesDnDHistoryCurrentPos,
      savingSort,
      tableTypeSavingSort,
      saveSortDialogOpen,
      saveTableTypeSortDialogOpen,
      flagExternalUrl,
      isSlotTicket,
      tempDirtyStock,
      tableType,
      deleteTicketType,
      deleteErrorMessage,
    } = this.state

    const {
      event,
      tickets,
      tables,
      loadingTickets,
      timeSlotGroups,
      brandPixelData,
      isRnDomain,
      launchDarklyFlags,
      configs,
      user,
    } = this.props
    const showTables = _get(configs, 'appearance.showTables', true)
    const showAdvancedOptions = _get(configs, 'appearance.showAdvancedOptions', true)
    const showAddOnsSection = _get(configs, 'appearance.showAddOnsSection', false)
    const ticketTableConfigs = _get(configs, 'children.TicketsTable')
    const flagSeatMap = event ? event.flagSeatMap : false
    const isAbsorbedFee = event.feeMode === 'absorb'
    const dndHistoryCount = dndHistory.length
    const tableTypesDnDHistoryCount = tableTypesDnDHistory.length
    const rnEvent = event.externalEventPartner === 'restlessnites'
    let noTimeSlotTicketsDnD = _filter(ticketsDnD, t => !t.slotGroupName)
    const hasTableTypes = loadingTickets ? true : !!tables.length
    // const timeSlotTicketsDnD = getTimeSlotTicketsByDay(tickets)

    noTimeSlotTicketsDnD = _orderBy(noTimeSlotTicketsDnD, ['sortOrder', 'displayName'], ['asc', 'asc'])
    const nodes = _map(noTimeSlotTicketsDnD, (t, index) => (
      <TicketsTable.Row {...t} key={t.id} index={index} moveCard={this.moveCard} event={event}>
        <div className="btn-toolbar2">
          <Button className="btn btn-primary" type="button" onClick={e => this.handleEdit(t)}>
            <i className="fa fa-pencil fa-fw" />
            Edit
          </Button>
          {t.active && (
            <Button className="btn btn-seablue" type="button" onClick={e => this.handleDeactivate(t)}>
              <i className="fa fa-ban" />
              Deactivate
            </Button>
          )}
          {!t.active && (
            <Button className="btn btn-ok" type="button" onClick={e => this.handleActivate(t)}>
              <i className="fa fa-cog" />
              Activate
            </Button>
          )}
          {!rnEvent && this.renderDeleteTicketTypeButton(t.id, 'Ticket')}
        </div>
      </TicketsTable.Row>
    ))

    const orderedTableTypesDnD = _orderBy(tableTypesDnD, ['sortOrder', 'displayName'], ['asc', 'asc'])
    const tableNodes = _map(orderedTableTypesDnD, (t, index) => (
      <TicketsTable.Row {...t} key={t.id} index={index} moveCard={this.tableTypeMoveCard} event={event}>
        <div className="btn-toolbar2">
          <Button className="btn btn-primary" type="button" onClick={e => this.handleTableEdit(t)}>
            <i className="fa fa-pencil fa-fw" />
            Edit
          </Button>
          {t.active && (
            <Button
              className="btn btn-seablue"
              type="button"
              onClick={e => this.handleTableTypeDeactivate(t)}
            >
              <i className="fa fa-ban" />
              Deactivate
            </Button>
          )}
          {!t.active && (
            <Button className="btn btn-ok" type="button" onClick={e => this.handleTableTypeActivate(t)}>
              <i className="fa fa-cog" />
              Activate
            </Button>
          )}
          {!rnEvent && this.renderDeleteTicketTypeButton(t.id, 'Table')}
        </div>
      </TicketsTable.Row>
    ))

    return (
      <DisableSectionByPermissionWrapper>
        <div>
          <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!saveSortDialogOpen || !!saveTableTypeSortDialogOpen}
            contentLabel="Modal"
            onRequestClose={this.handleCancelSave}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Are you sure?</p>
                  </div>
                  <div className="modal-body">
                    <p>Tickets table has been modified. Save current changes?</p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          if (saveSortDialogOpen) {
                            this.handleSaveSort()
                          } else if (saveTableTypeSortDialogOpen) {
                            this.handleTableTypeSaveSort()
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button className="btn btn-default" type="button" onClick={this.handleCancelSave}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!deleting || !!tableTypeDeleting}
            contentLabel="Modal"
            onRequestClose={this.handleCancelDeactivate}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Are you sure?</p>
                  </div>
                  <div className="modal-body">
                    <p>
                      Deactivating the ticket will prevent users from buying it and will trigger the next
                      available ticket type to go live.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-danger"
                        type="button"
                        onClick={e => {
                          if (deleting) {
                            this.handleDeactivate(deleting)
                          } else if (tableTypeDeleting) {
                            this.handleTableTypeDeactivate(tableTypeDeleting)
                          }
                        }}
                      >
                        Deactivate
                      </Button>
                      <Button className="btn btn-default" type="button" onClick={this.handleCancelDeactivate}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <ConfirmModal
            isOpen={!!deleteTicketType}
            header={'Are you sure?'}
            content={
              <>
                <p>
                  Deleting the ticket type will prevent users from buying it and will trigger the next
                  available ticket type to go live.
                </p>{' '}
                <p style={{ textTransform: 'capitalize', color: '#dc2121' }}>
                  {deleteErrorMessage && deleteErrorMessage}{' '}
                </p>
              </>
            }
            actions={[
              {
                value: 'delete',
                label: 'Delete',
                className: 'btn btn-danger btn-shadow',
                disabled: deleteErrorMessage,
              },
              { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' },
            ]}
            onAction={this.onConfirmResponse}
          />
          <Modal
            className="timeslot-edit-modal modal-dialog modal-trans"
            style={{
              overlay: modalStyle.overlay,
              content: { ...modalStyle.content, overflow: 'visible' },
            }}
            isOpen={!!editing}
            contentLabel="Modal"
            onRequestClose={this.handleCancel}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog" style={{ overflow: 'visible' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <div>Edit Ticket</div>
                </div>
                {
                  <TicketForm
                    inModal={true}
                    ref="ticketForm"
                    event={event}
                    tickets={tickets}
                    isSlotTicket={isSlotTicket}
                    isEditing={editing ? true : false}
                    formKey={editing ? editing.id : ''}
                    cartsData={cartsData}
                    initialValues={
                      editing
                        ? _merge(editing.$original, {
                          attributes: {
                            description: mediumEditor_revert(editing.$original.attributes.description),
                          },
                        })
                        : null
                    }
                    onSubmit={form => {
                      if (tableType) {
                        return this.handleTableSubmit(false, editing ? editing.id : null, form)
                      }
                      return this.handleSubmit(false, editing ? editing.id : null, form)
                    }}
                    onCancel={this.handleCancel}
                    launchDarklyFlags={launchDarklyFlags}
                    handleHoldRowDelete={this.handleHoldRowDelete}
                    tempDirtyStock={tempDirtyStock}
                    tableType={tableType}
                    configs={_get(configs, 'children.TicketForm')}
                  />
                }
              </div>
            </div>
          </Modal>
          <div className="row">
            <div className="col-xs-12">
              <TicketOptionsForm
                event={event}
                isRewardSystemDisabled={isRewardSystemDisabled}
                ticketSales={ticketSales}
                isTicketTypeExist={
                  (ticketsDnD && !!ticketsDnD.length) || (timeSlotGroups && timeSlotGroups.length)
                }
                loadingTickets={loadingTickets}
                initialValues={event}
                onSubmit={this.handleUpdateEvent_OptionsForm}
                onChangeExternalUrl={this.handleExternalUrl}
                isRnDomain={isRnDomain}
                tfRole={user?.tfRole}
                hasTableTypes={hasTableTypes}
                configs={_get(configs, 'children.TicketOptionsForm')}
              />
            </div>
          </div>
          <br />
          <br />
          <BlockSeatsCard event={event} />
          <div className="row">
            <div className="col-xs-12">
              <Card icon={'fa-ticket'} title={'Ticket Types'} className="ticket-types">
                {!adding && (
                  <div className="btn-toolbar btn_holder">
                    <Button className="btn btn-ok" type="button" onClick={this.handleAdd}>
                      <i className={'fa fa-fw fa-plus'} />
                      Add ticket type
                    </Button>
                    <Button
                      className="btn btn-seablue"
                      type="button"
                      onClick={this.refresh}
                      loading={loadingFetch}
                    >
                      <i className={'fa fa-fw fa-refresh'} />
                      Refresh
                    </Button>
                    {!(timeSlotGroups && timeSlotGroups.length) && (
                      <Button
                        className="btn btn-seablue"
                        type="button"
                        onClick={() => this.handleUndo()}
                        disabled={!(dndHistoryCount > dndHistoryCurrentPos && dndHistoryCurrentPos > 0)}
                      >
                        <i className={'fa fa-fw fa-undo'} /> Undo
                      </Button>
                    )}
                    {!(timeSlotGroups && timeSlotGroups.length) && dndHistoryCurrentPos > 0 && (
                      <Button
                        className="btn btn-seablue"
                        type="button"
                        onClick={this.handleSaveSort}
                        loading={savingSort}
                      >
                        <i className={'fa fa-fw fa-floppy-o'} /> Save
                      </Button>
                    )}
                    {!(timeSlotGroups && timeSlotGroups.length) && (
                      <Button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => this.handleRedo()}
                        disabled={!(dndHistoryCount > dndHistoryCurrentPos + 1 && dndHistoryCurrentPos >= 0)}
                      >
                        <i className={'fa fa-fw fa-repeat'} /> Redo
                      </Button>
                    )}
                  </div>
                )}
                {adding && (
                  <TicketForm
                    inModal={false}
                    tickets={tickets}
                    timeSlotGroups={timeSlotGroups}
                    ref="ticketForm"
                    event={event}
                    onSubmit={this.handleSubmit.bind(this, true, null)}
                    onCancel={this.handleCancel}
                    cartsData={cartsData}
                    launchDarklyFlags={launchDarklyFlags}
                    configs={_get(configs, 'children.TicketForm')}
                  />
                )}
                {nodes && nodes.length ? (
                  <TicketsTable event={event} configs={ticketTableConfigs}>
                    {nodes}
                  </TicketsTable>
                ) : null}
                {timeSlotGroups && timeSlotGroups.length ? (
                  <TimeSlotTicketsTable
                    event={event}
                    tickets={timeSlotGroups}
                    timeSlotTickets={tickets}
                    statuses={['active', 'flagAlwaysAvailable', 'flagHidden']}
                    tableColumns={[
                      { label: 'Start Time', key: 'slotStartDate', formatTo: TIME_DISPLAY_FORMAT },
                      { label: 'End Time', key: 'slotEndDate', formatTo: TIME_DISPLAY_FORMAT },
                      'faceValue',
                      isAbsorbedFee && { label: 'Price (excl. Fees)', key: 'cost' },
                      !isAbsorbedFee && { label: 'Price (incl. Fees)', key: 'price' },
                      { label: 'Max Number of Sales', key: 'maxInventory' },
                      'stock',
                    ].filter(Boolean)}
                    handleEdit={this.handleEdit}
                    handleActivate={this.handleActivate}
                    handleDeactivate={this.handleDeactivate}
                  />
                ) : null}
              </Card>
            </div>
          </div>
          {/* {event.flagTimeSlotsEnabled && <div className="row">
          <div className="col-xs-12">
            <TicketsLimitTexts />
          </div>
        </div>} */}
          {showTables && (
            <div className="row">
              <div className="col-xs-12">
                <Card icon={'fa-ticket'} title={'Table Types'} className="ticket-types">
                  {tableAdding ? (
                    <TicketForm
                      typeName="table"
                      inModal={false}
                      tickets={tickets}
                      timeSlotGroups={timeSlotGroups}
                      ref="ticketForm"
                      event={event}
                      onSubmit={this.handleTableSubmit.bind(this, true, null)}
                      onCancel={this.handleCancel}
                      cartsData={cartsData}
                      launchDarklyFlags={launchDarklyFlags}
                      tableType={true}
                      configs={_get(configs, 'children.TicketForm')}
                    />
                  ) : (
                    <div className="btn-toolbar btn_holder">
                      <Button className="btn btn-ok" type="button" onClick={this.handleTableAdd}>
                        <i className={'fa fa-fw fa-plus'} />
                        Add table type
                      </Button>
                      <Button
                        className="btn btn-seablue"
                        type="button"
                        onClick={this.tableTypeRefresh}
                        loading={loadingTableFetch}
                      >
                        <i className={'fa fa-fw fa-refresh'} />
                        Refresh
                      </Button>
                      <Button
                        className="btn btn-seablue"
                        type="button"
                        onClick={() => this.handleUndo(true)}
                        disabled={
                          !(
                            tableTypesDnDHistoryCount > tableTypesDnDHistoryCurrentPos &&
                            tableTypesDnDHistoryCurrentPos > 0
                          )
                        }
                      >
                        <i className={'fa fa-fw fa-undo'} /> Undo
                      </Button>
                      {tableTypesDnDHistoryCurrentPos > 0 && (
                        <Button
                          className="btn btn-seablue"
                          type="button"
                          onClick={this.handleTableTypeSaveSort}
                          loading={tableTypeSavingSort}
                        >
                          <i className={'fa fa-fw fa-floppy-o'} /> Save
                        </Button>
                      )}
                      <Button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => this.handleRedo(true)}
                        disabled={
                          !(
                            tableTypesDnDHistoryCount > tableTypesDnDHistoryCurrentPos + 1 &&
                            tableTypesDnDHistoryCurrentPos >= 0
                          )
                        }
                      >
                        <i className={'fa fa-fw fa-repeat'} /> Redo
                      </Button>
                    </div>
                  )}
                  {tableNodes && !!tableNodes.length && (
                    <TicketsTable event={event} configs={ticketTableConfigs}>
                      {tableNodes}
                    </TicketsTable>
                  )}
                </Card>
              </div>
            </div>
          )}
          {!flagExternalUrl && showAddOnsSection && (
            <AddOnSection
              event={event}
              tickets={tickets}
              tables={tables}
              configs={_get(configs, 'children.AddOnSection')}
            />
          )}
          <div className="row">
            <div className="col-xs-12">
              <Card title={'External/3rd-Party Sales Reporting'} className="overflow-visible-card">
                <SalesTracking configs={_get(configs, 'children.SalesTracking')} />
              </Card>
            </div>
          </div>
          {showAdvancedOptions ? (
            <div className="row">
              <div className="col-xs-12">
                <Card icon={'fa-clipboard'} title={'Advanced Options'}>
                  <TicketAdvancedOptionsForm
                    dynamicConfigs={this.props.dynamicConfigs}
                    brandPixelData={brandPixelData ? brandPixelData : null}
                    initialValues={event}
                    onSubmit={this.handleUpdateEvent}
                  />
                </Card>
              </div>
            </div>
          ) : null}
          {flagSeatMap && (
            <div className="row">
              <div className="col-xs-12">
                <Card icon={null} title={'Seat Maps'}>
                  <SeatMapsSection eventId={event.id} venueId={event.venue.id} />
                </Card>
              </div>
            </div>
          )}
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}
