import React from 'react'
import _keys from 'lodash/keys'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import { withFormik, Form } from 'formik'
import { Element, Events, scroller } from 'react-scroll'

import { EVENT_DESCRIPTION_LANGUAGES } from '../../../constants/languages.js'

import PasswordsCard from '../PasswordsCard'
import OnlineEventDetails from './OnlineEventDetails/index'
import OnlineEventDescription from './OnlineEventDescription/index'
import OnlineEventImages from './OnlineEventImages/index'
import OnlineEventType from './OnlineEventType/index'
import OnlineEventSettings from './OnlineEventSettings/index'
import OnlineEventSocialMedia from './OnlineEventSocialMedia/index'
import OnlineEventTags from './OnlineEventTags/index'
import OnlineEventHostingDetails from './OnlineEventHostingDetails'
import OpenGraphImages from './OpenGraphImages'

import { getDataFromProps } from './utils'
import Button from '../../../_library/Button'
import RelativeError from '../../../_library/RelativeError'
import PayoutDetails from '../../../brands/PayoutDetails'
import { validateOnlineEvent } from './validation'
import { getCountryCurrencies } from '../../../utils/coutriesUtils.js'
import { ShopifyCard } from '../../../shopify'
import EventPaymentMethod from '../common/EventPaymentMethod'

const languages = getLanguages()
const countryCurrencies = getCountryCurrencies()
export const defaultLanguage = 'en'

class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      descriptionLanguage: defaultLanguage,
      imageLanguage: defaultLanguage,
      languageOptions: [],
      titleReplacementImage: null,
    }
  }

  componentDidMount() {
    const { values } = this.props
    const titleReplacementImage =
      values.titleReplacementImage || _get(this.props, 'initialValues.titleReplacementImage')
    let languageOptions = _map(_keys(values.descriptions), d => ({
      label: languages[d] + `${d === defaultLanguage ? ' (Default)' : ''}`,
      value: d,
    }))
    if (!languageOptions.length) {
      languageOptions = EVENT_DESCRIPTION_LANGUAGES
    }

    this.setState({
      imageLanguage: defaultLanguage,
      languageOptions,
      titleReplacementImage,
    })

    this.showErrorMessage()
  }

  showErrorMessage = () => {
    const encodedErrorMessage = location.search
    const urlParams = new URLSearchParams(encodedErrorMessage)
    const errorMessage = urlParams.get('error_message')

    if (errorMessage) {
      const decodedErrorMessage = window.atob(errorMessage)
      Messenger().post({
        type: 'error',
        message: decodedErrorMessage,
        hideAfter: 10,
        showCloseButton: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isSubmitting, event } = this.props
    if (!_isEmpty(nextProps.errors) && isSubmitting) {
      Events.scrollEvent.register('end', () => {
        Events.scrollEvent.remove('end')
      })
      const {
        owner,
        eventType,
        displayName,
        shortName,
        slug,
        startDate,
        endDate,
        onDate,
        minutesBefore,
        imageURLs,
        backgroundURL,
        titleReplacementImage,
        ogImage,
        twitterImage,
        currency,
        salesEndDate,
        timezone,
        reminderEmailDate,
        reminderEmailMinutes,
      } = nextProps.errors
      let scrollTo = ''
      if (displayName || shortName || slug) scrollTo = 'scroll-to-details'
      else if (startDate || endDate || currency || salesEndDate || timezone) scrollTo = 'scroll-to-date'
      else if (imageURLs || backgroundURL || titleReplacementImage) scrollTo = 'scroll-to-eventImages'
      else if (ogImage || twitterImage) scrollTo = 'scroll-to-eventMediaImages'
      else if (eventType) scrollTo = 'scroll-to-eventType'
      else if (onDate || minutesBefore || reminderEmailDate || reminderEmailMinutes)
        scrollTo = 'scroll-to-eventSettings'
      else scrollTo = 'scroll-to-top'
      if (owner) scrollTo = 'scroll-to-details'
      scroller.scrollTo(scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    }

    if (nextProps.selectedBrand !== this.props.selectedBrand) {
      const { selectedBrand } = nextProps
      if (selectedBrand) {
        this.updateField('owner', selectedBrand)
      }
    }
    if (nextProps.event) {
      if (nextProps.event.blurredBackgroundUrl !== event.blurredBackgroundUrl) {
        this.updateField('blurredBackgroundUrl', nextProps.event.blurredBackgroundUrl)
      }
    }
  }

  updateField = (field, value) => {
    const { setFieldValue } = this.props
    setFieldValue(field, value)
  }

  handleBrandChange = ({ value, suggestions }) => {
    const countryBrand = _find(suggestions, organization => organization.id === value)
    const countryValue = countryBrand?.country
    this.updateField('country', countryValue)
    this.updateField('owner', value)
  }

  handleChangeAutoBackground = e => {
    const { checked } = e.target
    this.updateField('autoBackground', checked)
  }

  onClickNewBrand = () => {
    if (this.props.onClickNewBrand) this.props.onClickNewBrand()
  }

  onLanguageChange = (e, fieldKey) => {
    this.setState({
      [fieldKey]: e.target.value,
    })
  }

  handleChangeImageAsEventName = e => {
    const checked = e.target ? e.target.checked : e
    this.updateField('useImageInsteadOfTitle', checked)
  }

  render() {
    const { descriptionLanguage, imageLanguage, languageOptions, titleReplacementImage } = this.state
    const {
      submitLabel,
      organizations,
      isNew,
      cardsStatus,
      values,
      touched,
      errors,
      isSubmitting,
      resetedDescriptions,
      updateResetedDescriptions,
      dynamicConfigs,
      event,
      shopifyConnectSubmit,
      onDisconnectShopify,
      onSopifyUpdate,
      onSopifyRefresh,
      shopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      submitCount,
      configs,
      user,
    } = this.props
    const { autoBackground, useImageInsteadOfTitle, flagPaymentDetails } = values
    const flagIsStripeConnected = event?.flagIsStripeConnected
    const showPaymentData =
      (flagPaymentDetails || !flagIsStripeConnected) &&
      _get(configs, 'appearance.showEventPayoutDetails', true)
    const hideShopify = _get(configs, 'appearance.hideShopify', false)

    return (
      <div className="event-form">
        <div className={!!isNew ? 'body-panel-content' : ''}>
          <Form autoComplete="off">
            {!!isNew && (
              <div>
                <div className="body-panel-header">
                  <div className="left">
                    <div className="title">Add New Online Event</div>
                  </div>
                </div>
                <div className="body-panel-spacing" />
              </div>
            )}
            <Element name="scroll-to-top" />
            <OnlineEventHostingDetails
              isNew={isNew}
              event={event}
              cardsStatus={cardsStatus}
              values={values}
              errors={errors}
              touched={touched}
              submitCount={submitCount}
              updateField={this.updateField}
            />
            <Element name="scroll-to-details" />
            <OnlineEventDetails
              isNew={isNew}
              values={values}
              errors={errors}
              touched={touched}
              cardsStatus={cardsStatus}
              organizations={organizations}
              onBrandChange={this.handleBrandChange}
              updateField={this.updateField}
              onClickNewBrand={this.onClickNewBrand}
              configs={_get(configs, 'children.OnlineEventDetails')}
            />
            <OnlineEventDescription
              values={values}
              errors={errors}
              touched={touched}
              cardsStatus={cardsStatus}
              updateField={this.updateField}
              descriptionLanguage={descriptionLanguage}
              languageOptions={languageOptions}
              onLanguageChange={this.onLanguageChange}
              resetedDescriptions={resetedDescriptions}
              updateResetedDescriptions={updateResetedDescriptions}
            />
            <Element name="scroll-to-eventImages" />
            <OnlineEventImages
              values={values}
              errors={errors}
              cardsStatus={cardsStatus}
              updateField={this.updateField}
              imageLanguage={imageLanguage}
              languageOptions={languageOptions}
              onLanguageChange={this.onLanguageChange}
              autoBackground={autoBackground}
              useImageInsteadOfTitle={useImageInsteadOfTitle}
              handleChangeAutoBackground={this.handleChangeAutoBackground}
              handleChangeImageAsEventName={this.handleChangeImageAsEventName}
              titleReplacementImage={titleReplacementImage}
              configs={_get(configs, 'children.OnlineEventImages')}
            />
            <Element name="scroll-to-eventMediaImages" />
            <OpenGraphImages values={values} errors={errors} updateField={this.updateField} />
            <Element name="scroll-to-eventType" />
            <OnlineEventType values={values} cardsStatus={cardsStatus} updateField={this.updateField} />
            {errors.oneOfTags && <RelativeError errorClass="oneOfTags">{errors.oneOfTags}</RelativeError>}
            <Element name="scroll-to-eventSettings" />
            <OnlineEventSettings
              isNew={isNew}
              values={values}
              cardsStatus={cardsStatus}
              updateField={this.updateField}
            />
            {values.flagPasswordProtected && !isNew && (
              <PasswordsCard
                passwords={this.props.passwords}
                onCreatePassword={password => this.props.onCreatePassword(password)}
                onDeletePassword={password => this.props.onDeletePassword(password)}
                onRefreshPasswords={() => this.props.onRefreshPasswords()}
              />
            )}
            <OnlineEventSocialMedia cardsStatus={cardsStatus} />
            <OnlineEventTags values={values} cardsStatus={cardsStatus} updateField={this.updateField} />
            {showPaymentData && (
              <PayoutDetails
                event={event}
                values={values}
                errors={errors}
                touched={touched}
                enableToggle={true}
                handleChange={(e, data) => {
                  const fieldKey = e.target ? e.target.id : e
                  const value = e.target ? e.target.value : data
                  this.updateField(fieldKey, value)
                }}
                flagIsStripeConnected={flagIsStripeConnected}
              />
            )}
            <EventPaymentMethod
              event={event}
              user={user}
              configs={_get(configs, 'children.EventPaymentMethod')}
            />
            {user.tfStaff
              ? !isNew &&
                _get(dynamicConfigs, 'show_connect_shopify', false) &&
                !hideShopify && (
                <ShopifyCard
                  id={event.id}
                  onSubmit={shopifyConnectSubmit}
                  onDisconnectShopify={onDisconnectShopify}
                  shopifyInfo={shopifyInfo}
                  shopifyConnectLoading={shopifyConnectLoading}
                  shopifyDisconnectLoading={shopifyDisconnectLoading}
                  shopifyRefreshLoading={shopifyRefreshLoading}
                  fetchProductsLoading={fetchProductsLoading}
                  onUpdate={onSopifyUpdate}
                  onRefresh={onSopifyRefresh}
                  title="Shopify"
                />
              )
              : null}
            <div className="card-block text-center">
              <Button className="btn btn-success btn-lg btn-shadow" type="submit" loading={isSubmitting}>
                {submitLabel || 'Create Event'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const OnlineEventForm = withFormik({
  mapPropsToValues: props => getDataFromProps(props),
  validate: (values, props) => validateOnlineEvent(values, props),
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting, setFieldValue, setValues }) => {
    const {
      useImageInsteadOfTitle,
      accountName,
      accountNumber,
      routingNumber,
      bsb,
      sortCode,
      ifscNumber,
      institutionNumber,
      transitNumber,
      bankName,
      flagValidateAge,
      minimumAge,
    } = values

    const bankingDetails = {
      accountName,
      accountNumber,
      routingNumber,
      bsb,
      sortCode,
      ifscNumber,
      bankName,
      institutionNumber,
      transitNumber,
    }

    const updatedDescriptions = _cloneDeep(values.descriptions)
    let updatedValues = _cloneDeep(values)
    const currency = _find(countryCurrencies, item => item.label === updatedValues.currency)

    if (updatedValues.autoBackground) {
      updatedValues.backgroundURL = null
      setFieldValue('backgroundURL', null)
    }

    if (!useImageInsteadOfTitle) {
      updatedValues.titleReplacementImage = null
      setValues({ ...updatedValues, titleReplacementImage: null })
    } else {
      const { titleReplacementImage } = updatedValues
      if (titleReplacementImage && !titleReplacementImage.startsWith('https://')) {
        delete updatedValues.titleReplacementImage
        setValues({ ...updatedValues, titleReplacementImage: null })
      }
    }

    _forEach(values.descriptions, (value, key) => {
      if (_find(props.resetedDescriptions, r => r === key)) {
        updatedDescriptions[key] = null
      } else {
        updatedDescriptions[key] = value
      }
    })
    updatedValues.descriptions = updatedDescriptions

    updatedValues = {
      ...updatedValues,
      isOnlineEvent: true,
      hideUntil: updatedValues.flagHidden ? updatedValues.hideUntil : null,
      rsvpAlternativeInformation: updatedValues.rsvpAlternativeInformation || null,
      bankingDetails,
      currency: currency
        ? currency.data
        : {
          currency: 'USD',
          decimal_places: 2,
          symbol: 'US$',
        },
    }

    if (updatedValues.facebookEvent) {
      updatedValues = {
        ...updatedValues,
        facebookEvent: updatedValues.facebookEvent.replace('business.facebook.com', 'www.facebook.com'),
      }
    }

    if (!updatedValues.flagPaymentDetails) {
      updatedValues.bankingDetails = {
        accountName: '',
        accountNumber: '',
        routingNumber: '',
        bsb: '',
        sortCode: '',
        ifscNumber: '',
        bankName: '',
        institutionNumber: null,
        transitNumber: null,
      }
    }

    const isZoomMeeting = updatedValues.selectedMeeting.provider === 'zoom'
    updatedValues.onlineDetails = updatedValues.selectedMeeting

    if (isZoomMeeting) {
      if (updatedValues.isNewMeeting) {
        delete updatedValues.onlineDetails.meetingId
        delete updatedValues.onlineDetails.embed
      } else {
        delete updatedValues.onlineDetails.embed
      }
      updatedValues.onlineDetails = {
        ...updatedValues.onlineDetails,
        preEventContentPlaceholderType: !!updatedValues.onlineDetails.preEventContentPlaceholder
          ? updatedValues.onlineDetails.preEventContentPlaceholderType
          : null,
        preEventContentPlaceholder: updatedValues.onlineDetails.preEventContentPlaceholder,
        autoRecording: updatedValues.onlineDetails.autoRecording ? 'local' : 'none',
      }
    } else {
      updatedValues.onlineDetails = {
        embed: updatedValues.onlineDetails.embed,
        preEventContentPlaceholderType: !!updatedValues.onlineDetails.preEventContentPlaceholder
          ? updatedValues.onlineDetails.preEventContentPlaceholderType
          : null,
        preEventContentPlaceholder: updatedValues.onlineDetails.preEventContentPlaceholder,
        provider: updatedValues.onlineDetails.provider,
      }
    }
    const owner = _find(props.organizations, organization =>
      organization.id === values?.owner || organization.id === values?.owner?.value)

    if (updatedValues.flagReminderEmails) {
      if (updatedValues.emailDateOption === 'onDate') {
        delete updatedValues.reminderEmailMinutes
      } else {
        delete updatedValues.reminderEmailDate
      }
    } else {
      delete updatedValues.reminderEmailMinutes
      updatedValues.reminderEmailDate = ''
    }

    if (!updatedValues.flagDonation) {
      delete updatedValues.donationDetails
    } else {
      const dontationType = _get(updatedValues, 'donationDetails.type')
      if (dontationType === 'fixed') {
        updatedValues.donationDetails.minAmount = null
      }
      if (dontationType === 'choice') {
        updatedValues.donationDetails.amountOptions = null
      }
    }

    if (flagValidateAge) {
      updatedValues = {
        ...updatedValues,
        minimumAge: minimumAge == 0 ? null : minimumAge,
      }
    }

    delete updatedValues.flagPaymentDetails
    delete updatedValues.selectedMeeting
    delete updatedValues.isNewMeeting
    delete updatedValues.flagReminderEmails
    delete updatedValues.emailDateOption

    updatedValues.flagEnableAddOnWaitingList = true
    updatedValues.flagEnableAddOnResale = true

    props
      .onSubmit({
        attributes: { ...updatedValues },
        relationships: {
          owner: {
            data: owner,
          },
        },
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'OnlineEventForm', // helps with React DevTools
})(MyForm)

export default OnlineEventForm
