import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'

import modalStyle from '../../_common/core/modalStyle'
import Button from '../_library/Button'
import Card from '../_library/Card'
import LoadingBar from '../_library/LoadingBar'
import { DisableSectionByPermissionWrapper } from '../hoc'

const OPTIONS_TYPE = [
  { value: 'expense', title: 'Expense' },
  { value: 'revenue', title: 'Revenue' },
]
const OPTIONS_CATEGORY = [
  { value: 'Accommodation', title: 'Accommodation' },
  { value: 'Crew', title: 'Crew' },
  { value: 'Exhibitor', title: 'Exhibitor' },
  { value: 'Marketing', title: 'Marketing' },
  { value: 'Inbound Sponsorship', title: 'Inbound Sponsorship' },
  { value: 'Meal', title: 'Meal' },
  { value: 'Merchandise', title: 'Merchandise' },
  { value: 'Outbound Sponsorship', title: 'Outbound Sponsorship' },
  { value: 'Production', title: 'Production' },
  { value: 'Rider', title: 'Rider' },
  { value: 'Talent', title: 'Talent' },
  { value: 'Transport', title: 'Transport' },
  { value: 'Vendor', title: 'Vendor' },
  { value: 'Venue Hire', title: 'Venue Hire' },
  { value: 'Miscellaneous', title: 'Miscellaneous' },
]
const MAX_LENGTH_COST = 15
const MAX_LENGTH_TEXT = 50
const RENDER_SETTIMEOUT_INTERVAL = 100

import {
  FETCH_SECTIONS, CREATE_SECTION, UPDATE_SECTION, DELETE_SECTION, CREATE_ITEM, UPDATE_ITEM, DELETE_ITEM
} from '../../_common/redux/budgets/actions'

import {
  FETCH_EVENT_TEAM_MEMBERS,
} from '../../_common/redux/eventteam/actions'

import {
  get_event,
  get_event_budgets,
  get_eventteam_event_members,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const event = get_event(state)
    const sections = get_event_budgets(state)
    const eventMembers = get_eventteam_event_members(state)
    return {
      event,
      sections,
      eventMembers,
    }
  },
  { FETCH_SECTIONS, CREATE_SECTION, UPDATE_SECTION, DELETE_SECTION, CREATE_ITEM, UPDATE_ITEM, DELETE_ITEM, FETCH_EVENT_TEAM_MEMBERS }
)
class BudgetManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: [],
      // section & subsection
      selectedSection: null,

      // item
      selectedRow: null,

      // delete
      isConfirmModalOpen: false,
      isSectionDelete: true,

      // edit
      editInfo: null, // { id: rowId, field: fieldName},
      editingValue: null,

      // loading & updating
      isLoading: false,
      isUpdating: false,
      updatingSection: null,

      // add new
      isAdding: false,
      isAddingSection: false,
      parentSection: false,
      addingInfo: null, // { id: rowId, field: fieldName},

      //clipboard
      clipboardCopied: false,
    }
    this.sumSections = []
    this.suppressValidation = false
    // tab management
    this.tabInfo = null
    this.foundInSearch = 0 // 0 : not found, 1: found, 2: set
    // clipboard
    this.clipboardText = ''
    // message management
    this.isMessageOpened = false
    this.previousMessage = null
  }

  componentDidMount() {
    const { event: { id, displayName }, FETCH_SECTIONS, configs } = this.props

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    document.addEventListener('keydown', this.handleKeyDown, false)

    this.setState({ isLoading: true })
    Promise.resolve(FETCH_SECTIONS(id))
      .catch(err => {
        console.log('FETCH_SECTIONS err', err)
        this.showErrorMessage(err)
        !this.isUnmounted && this.setState({ isLoading: false })
      })
      .then(result => {
        // console.log('FETCH_SECTIONS result', result)
        !this.isUnmounted && this.setState({ isLoading: false })
      })

    const { FETCH_EVENT_TEAM_MEMBERS } = this.props
    Promise.resolve(FETCH_EVENT_TEAM_MEMBERS(id))
      .catch(null)
      .then(null)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false)
    this.isUnmounted = true
  }

  handleKeyDown = evt => {
    const { isAdding, isAddingSection, editInfo, isConfirmModalOpen, isUpdating } = this.state
    if (evt.key === 'Enter') {
      if (isConfirmModalOpen) {
        this.processDelete()
        return
      }
      if (isAdding && !editInfo) {
        // console.log('Enter pressed')
        if (isAddingSection) {
          this.handleSaveSection()
        } else {
          this.handleSaveItem()
        }
      }
    } else if (evt.key === 'Escape') {
      if (isAdding) {
        // console.log('Escape pressed')
        this.handleCancelNew()
      }
    } else if (evt.key === 'Tab') {
      // console.log('Tab pressed')
      evt.stopPropagation()
      evt.preventDefault()
      if (!isUpdating) {
        this.moveTabPosition()
      }
    }
  }

  showErrorMessage = (err, placeholder = 'Unknown error', onlyOne = false) => {
    let message = placeholder
    if (err && err.errors.length > 0 && err.errors[0].meta)
      message = err.errors[0].meta.message || 'Unknown'
    if (onlyOne && this.isMessageOpened && this.previousMessage === message) {
      return
    }
    Messenger().post({
      type: 'error',
      message,
      hideAfter: 5,
      showCloseButton: true
    })
    this.isMessageOpened = true
    this.previousMessage = message
    setTimeout(() => {
      this.isMessageOpened = false
      this.previousMessage = null
    }, 5000)
  }

  // *** clipboard ***
  handleClipboardClick() {
    this.setState({
      clipboardCopied: true,
    })
    setTimeout(() => {
      this.setState({
        clipboardCopied: false,
      })
    }, 3000)
  }

  addClipboardLine = (title, amount, type, category, assignedTo, level = 0, restart = false) => {
    if (restart) {
      this.clipboardText = ''
    }
    let prefix = ''
    for (let i = 0; i < level; i++) {
      prefix += '>'
    }
    if (title === '') {
      prefix = ''
    }
    let responsibleUser = ''
    if ( assignedTo ) {
      const { eventMembers } = this.props;
      eventMembers.map((em) => {
        if (assignedTo == em.id) {
          responsibleUser = em.firstName + ' ' + em.lastName
        }
      })
    }
    this.clipboardText += `${prefix}${title}\t${amount}\t${type}\t${category}\t${responsibleUser || assignedTo}\n`
  }

  // *** tab key management ***

  setClicked = () => {
    if (this.tabInfo) {
      // console.log('tabInfo', this.tabInfo)
      this.setState({
        editInfo: {
          id: this.tabInfo.obj.id,
          isSection: this.tabInfo.isSection,
          field: this.tabInfo.field,
          data: this.tabInfo.obj,
        },
        editingValue: this.tabInfo.obj[this.tabInfo.field],
        selectedSection: this.tabInfo.section,
      })
    }
  }

  findInSections = (sections, level) => {
    _map(sections, section => {
      if (this.foundInSearch === 1) { // previous item is matched.
        this.foundInSearch = 2
        this.tabInfo = {
          isSection: true,
          field: 'sectionName',
          obj: section,
          section,
        }
      }
      if (this.tabInfo.isSection) {
        if (section.id.toString() === this.tabInfo.obj.id.toString() && this.foundInSearch === 0) {
          this.foundInSearch = 1
        }
      }
      _map(section.items, item => {
        if (this.foundInSearch === 1) {
          this.foundInSearch = 2
          this.tabInfo = {
            isSection: false,
            field: 'name',
            obj: item,
            section,
          }
        }
        if (!this.tabInfo.isSection) {
          if (item.id.toString() === this.tabInfo.obj.id.toString() && this.foundInSearch === 0) {
            this.foundInSearch = 1
          }
        }
      })
      this.findInSections(section.subsections, level + 1)
    })
  }

  calculateNextPosition = () => {
    if (!this.tabInfo)
      return
    const fields_section = ['sectionName']
    const fields_item = ['name', 'cost', 'type', 'category']
    const fields = this.tabInfo.isSection ? fields_section : fields_item
    const index = fields.indexOf(this.tabInfo.field)
    let newField = this.tabInfo.field
    if (index >= 0 && index < fields.length - 1) {
      // if moving in object
      newField = fields[index + 1]
      this.tabInfo.field = newField
    } else {
      // move to next section/item
      const { sections } = this.props
      const { isAdding } = this.state
      if (isAdding)
        return
      this.foundInSearch = 0
      this.findInSections(sections)
    }
  }

  setTabPosition = (isSection, obj, field, section) => {
    this.tabInfo = {
      isSection,
      field,
      obj, // if isSection is true, it equals to section
      section,
    }
    this.setClicked()
  }

  moveTabPosition = () => {
    // console.log('move tab position', this.tabInfo)
    const { sections } = this.props
    const { editInfo, editingValue } = this.state
    if (!this.tabInfo) { // very starting
      if (sections.length > 0) {
        this.setTabPosition(true, sections[0], 'sectionName', sections[0])
      }
    } else {
      if (editInfo) { // is edit mode
        // validation check
        if (!editingValue || editingValue.length === 0) {
          // if invalid
          return
        }
        // save result
        const isChanged = this.saveResult()
        // move to next position
        this.calculateNextPosition()
        this.setClicked()
      } else {
        // move to next position
        this.calculateNextPosition()
        this.setClicked()
      }
    }
  }

  // *** new handlers ***
  processAdd = (isSection, isSubSection, section) => {
    if (isSection) {
      const newSection = {
        id: `adding_section`,
        isAdding: true,
        sectionName: '',
        parentId: isSubSection ? section.id : null,
        items: [],
        subsections: [],
      }
      this.setState({
        isAdding: true,
        isAddingSection: true,
        parentSection: section,
        addingInfo: newSection,
      })
      this.setTabPosition(true, newSection, 'sectionName', newSection)
    } else {
      const newItem = {
        id: `adding_item`,
        name: '',
        cost: '',
        type: '',
        category: '',
        sectionId: section.id,
        responsibleUsers: ''
      }
      this.setState({
        isAdding: true,
        isAddingSection: false,
        parentSection: section,
        addingInfo: newItem,
      })
      this.setTabPosition(false, newItem, 'name', section)
    }
  }

  handleAddSection = () => {
    this.processAdd(true, false, null)
  }

  handleAddSubSection = (evt, section) => {
    evt.stopPropagation()
    this.processAdd(true, true, section)
  }

  handleAddItem = (evt, section) => {
    evt.stopPropagation()
    this.processAdd(false, false, section)
  }

  handleCancelNew = evt => {
    if (evt)
      evt.stopPropagation()
    this.suppressValidation = true
    this.setState({
      isAdding: false,
      isAddingSection: false,
      addingInfo: null,
    })
  }

  handleSaveSection = evt => {
    if (evt)
      evt.stopPropagation()
    const { addingInfo, parentSection } = this.state
    this.suppressValidation = true
    const payload = {
      sectionName: addingInfo.sectionName,
    }
    // validation
    if (addingInfo.sectionName.length === 0) {
      Messenger().post({
        type: 'error',
        message: 'You should enter the section name!',
        hideAfter: 5,
        showCloseButton: true
      })
      return
    }
    this.handleSubmitSection(payload, parentSection != null, true, parentSection)
  }

  handleSaveItem = evt => {
    if (evt)
      evt.stopPropagation()
    const { addingInfo, parentSection } = this.state

    this.suppressValidation = true
    const payload = {
      name: addingInfo.name,
      cost: addingInfo.cost,
      category: addingInfo.category,
      type: addingInfo.type,
      responsibleUsers: addingInfo.responsibleUsers
    }
    // validation
    let valid = true
    let field = null
    let errorMessage = 'You should enter all the values!'
    if (!addingInfo.category || addingInfo.category.length === 0) {
      valid = false
      field = 'category'
      errorMessage = 'Please select type!'
    }
    if (!addingInfo.type || addingInfo.type.length === 0) {
      valid = false
      field = 'type'
      errorMessage = 'Please select Expense/Revenue!'
    }
    if (!addingInfo.cost || addingInfo.cost.length === 0) {
      valid = false
      field = 'cost'
      errorMessage = 'Please enter amount!'
    }
    if (!addingInfo.name || addingInfo.name.length === 0) {
      valid = false
      field = 'name'
      errorMessage = 'Please enter item name!'
    }
    if (!valid) {
      this.showErrorMessage(null, errorMessage, true)
      if (field) {
        this.handleCellClick(null, addingInfo, field, parentSection, false)
      }
      return
    }
    this.handleSubmitRow(payload, true, parentSection)
  }

  handleSubmitSection = (form, isSubSection, isAdd, selectedSection) => {
    const { event, CREATE_SECTION, UPDATE_SECTION } = this.props

    if (isAdd) {
      const payload = {
        name: form.sectionName,
        parentId: isSubSection ? selectedSection.id : null,
      }
      this.setState({ isUpdating: true, updatingSection: isSubSection ? selectedSection : null })
      Promise.resolve(CREATE_SECTION(event.id, payload))
        .then(result => {
          // console.log('CREATE_SECTION result', result)
          this.setState({
            isUpdating: false,
            isAdding: false,
            isAddingSection: false,
            addingInfo: null,
          })
          this.tabInfo = null
          if (result && result.data) {
            const sectionObj = {
              id: result.data.id.toString(),
              sectionName: result.data.name,
              parentId: result.data.parentId,
            }
            this.tabInfo = {
              isSection: true,
              field: 'sectionName',
              obj: sectionObj,
              section: sectionObj,
            }
          }
        })
        .catch(err => {
          console.log('CREATE_SECTION err', err)
          this.showErrorMessage(err)
          this.setState({ isUpdating: false })
        })
    } else {
      const payload = {
        id: form.id,
        name: form.sectionName,
      }
      this.setState({ isUpdating: true, updatingSection: selectedSection })
      Promise.resolve(UPDATE_SECTION(payload))
        .then(result => {
          // console.log('UPDATE_SECTION result', result)
          this.setState({ isUpdating: false })
        })
        .catch(err => {
          console.log('UPDATE_SECTION err', err)
          this.showErrorMessage(err)
          this.setState({ isUpdating: false })
        })
    }
  }

  handleSubmitRow = (form, isAddRow, selectedSection) => {
    const { event, CREATE_ITEM, UPDATE_ITEM } = this.props

    if (isAddRow) {
      const payload = {
        name: form.name,
        cost: form.cost,
        category: form.category,
        type: form.type,
        responsibleUsers: form.responsibleUsers
      }
      this.setState({ isUpdating: true, updatingSection: selectedSection })
      Promise.resolve(CREATE_ITEM(event.id, selectedSection.id, payload))
        .then(result => {
          // console.log('CREATE_ITEM result', result)
          this.setState({
            isUpdating: false,
            isAdding: false,
            isAddingSection: false,
            addingInfo: null,
          })
          if (result && result.data) {
            this.tabInfo = {
              isSection: false,
              field: this.tabInfo.field,
              obj: {
                id: result.data.id.toString(),
                name: result.data.name,
                cost: result.data.cost,
                category: result.data.category,
                type: result.data.type,
                sectionId: result.data.sectionId,
                responsibleUsers: result.data.responsibleUsers
              },
              section: selectedSection,
            }
          } else {
            this.tabInfo = null
          }
        })
        .catch(err => {
          console.log('CREATE_ITEM err', err)
          this.showErrorMessage(err)
          this.setState({ isUpdating: false })
        })
    } else {
      const payload = {
        name: form.name,
        cost: form.cost,
        category: form.category,
        type: form.type,
        responsibleUsers: form.responsibleUsers
      }
      this.setState({ isUpdating: true, updatingSection: selectedSection })
      Promise.resolve(UPDATE_ITEM(event.id, form.id, payload))
        .then(result => {
          // console.log('UPDATE_ITEM result', result)
          this.setState({ isUpdating: false })
        })
        .catch(err => {
          console.log('UPDATE_ITEM err', err)
          this.showErrorMessage(err)
          this.setState({ isUpdating: false })
        })
    }
  }

  // *** delete handlers ***

  handleDeleteSection = (event, section) => {
    event.stopPropagation()
    if (this.state.isAdding)
      return
    this.setState({
      isConfirmModalOpen: true,
      isSectionDelete: true,
      selectedSection: section
    })
  }

  handleDeleteRow = (event, section, row) => {
    event.stopPropagation()
    if (this.state.isAdding)
      return
    this.setState({
      isConfirmModalOpen: true,
      selectedRow: row,
      selectedSection: section,
      isSectionDelete: false,
    })
  }

  handleConfirmModal = visible => {
    this.setState({ isConfirmModalOpen: visible })
  }

  processDelete = () => {
    this.setState({
      isConfirmModalOpen: false
    })
    const { isSectionDelete } = this.state
    if (isSectionDelete) {
      this.processDeleteSection()
    } else {
      this.processDeleteRow()
    }
  }

  processDeleteSection = () => {
    const { selectedSection } = this.state
    const { event, DELETE_SECTION } = this.props
    this.setState({ isUpdating: true, updatingSection: selectedSection })
    Promise.resolve(DELETE_SECTION(selectedSection.id, event.id))
      .catch(err => {
        console.log('DELETE_SECTION err', err)
        this.showErrorMessage(err)
        this.setState({ isUpdating: false })
      })
      .then(result => {
        // console.log('DELETE_SECTION result', result)
        this.setState({ isUpdating: false })
      })
  }

  processDeleteRow = () => {
    const { selectedSection, selectedRow } = this.state
    const { event, DELETE_ITEM } = this.props
    this.setState({ isUpdating: true, updatingSection: selectedSection })
    Promise.resolve(DELETE_ITEM(event.id, selectedRow.id, selectedSection.id))
      .catch(err => {
        console.log('DELETE_ITEM err', err)
        this.showErrorMessage(err)
        this.setState({ isUpdating: false })
      })
      .then(result => {
        // console.log('DELETE_ITEM result', result)
        this.setState({ isUpdating: false })
      })
  }

  // *** edit handlers ***

  handleTextChange = (e, field) => {
    const { value } = e.target
    this.setState({ editingValue: value })
  }

  handleCategoryChange = (e, isEdit, row, field, section, isSection) => {
    const { value } = e.target
    if (isEdit) {
      this.setState({ editingValue: value })
      this.saveResult(value)
    } else {
      this.handleCellClick(e, row, field, section, isSection)
      setTimeout(() => {
        this.setState({ editingValue: value })
        this.saveResult(value)
      }, RENDER_SETTIMEOUT_INTERVAL)
    }
  }

  handleTextBlur = e => {
    this.saveResult()
  }

  handleTextFocus = e => {
    e.target.select()
  }

  handleTextKeydown = (e, field) => {
    // console.log('TextKeydown', e.key)
    if (e.key === 'Enter') {
      e.stopPropagation()
      this.saveResult()
    } else if (e.key === 'Escape') {
      e.stopPropagation()
      this.setState({ editInfo: null })
    } else if (e.key === 'Tab') {
      e.stopPropagation()
      e.preventDefault()
    } else {
      if (field === 'cost') {
        const specialKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Home', 'End']
        if (specialKeys.indexOf(e.key) < 0) {
          const re = /[0-9\.]+/g
          if (!re.test(e.key)) {
            e.preventDefault()
          }
        }
      }
    }
  }

  handleSelectKeydown = (e, field) => {
    if (e.key === 'Tab') {
      e.stopPropagation()
    }
  }

  isEditMode = (row, field, section, isSection) => {
    const { editInfo } = this.state
    if (!editInfo || !field || field === '')
      return false
    const { isAdding, addingInfo } = this.state
    if (isAdding) {
      if (isSection) {
        if (section.id.toString() !== addingInfo.id.toString()) {
          return false // disable editing for other sections
        }
      } else {
        if (row.id.toString() !== addingInfo.id.toString()) {
          return false // disable editing for other sections
        }
      }
    }
    if (isSection) {
      if (editInfo.id.toString() === section.id.toString() && field === editInfo.field)
        return true
    } else {
      if (editInfo.id.toString() === row.id.toString() && field === editInfo.field)
        return true
    }
    return false
  }

  handleCellClick = (e, row, field, section, isSection = false) => {
    if (e) {
      e.stopPropagation()
    }
    const obj = isSection ? section : row
    this.setTabPosition(isSection, obj, field, section)
  }

  saveResult = v => {
    const { editInfo, editingValue, selectedSection, isAdding } = this.state
    const value = v || editingValue
    if (value.length === 0) {
      if (this.suppressValidation) {
        this.suppressValidation = false
        return false
      }
      if (!isAdding)
        this.showErrorMessage(null, 'This field is required!', true)
      return false
    }
    if (editInfo) {
      const form = editInfo.isSection ? {
        id: editInfo.id,
        sectionName: editInfo.data.sectionName,
        isAdding: editInfo.data.isAdding,
        parentId: editInfo.data.parentId,
        items: editInfo.data.items,
        subsections: editInfo.data.subsections,
      } : {
        id: editInfo.id,
        name: editInfo.data.name,
        cost: editInfo.data.cost,
        category: editInfo.data.category,
        type: editInfo.data.type,
        responsibleUsers: editInfo.data.responsibleUsers,
        sectionId: editInfo.data.sectionId,
      }
      if (form[editInfo.field] === value) {
        // console.log('unchanged!!!')
        // cancel edit mode
        this.setState({ editInfo: null })
        return false
      }
      // update changed field
      form[editInfo.field] = value
      this.tabInfo.obj = { ...this.tabInfo.obj, [editInfo.field]: value }
      if (isAdding) {
        this.setState({
          addingInfo: form
        })
      } else {
        if (editInfo.isSection) {
          this.handleSubmitSection(form, false, false, selectedSection)
        } else {
          this.handleSubmitRow(form, false, selectedSection)
        }
      }
      // cancel edit mode
      this.setState({ editInfo: null })
      return true

    }
  }

  // *** render modules ***

  getClassName = (isSection, isAdding, addingInfo, data) => {
    if (isSection) {
      const shouldInactive = isAdding && addingInfo.id.toString() !== data.id.toString()
      const shouldActive = isAdding && addingInfo.id.toString() === data.id.toString()
      return shouldInactive ? 'section-toolbar inactive' : (shouldActive ? 'section-toolbar isAdding' : 'section-toolbar')
    }
    const shouldInactive = isAdding && addingInfo.id.toString() !== data.id.toString()
    const shouldActive = isAdding && addingInfo.id.toString() === data.id.toString()
    return shouldInactive ? 'section_row inactive' : (shouldActive ? 'section_row isAdding' : 'section_row')

  }

  renderCell = (row, field, section, isSection = false, isAdding = false) => {
    const { event, eventMembers } = this.props
    const currency = getCurrencySymbol(event)
    const { editInfo, editingValue } = this.state
    const isEdit = this.isEditMode(row, field, section, isSection)
    switch (field) {
      case 'name':
      case 'cost':
      case 'sectionName':
        if (isEdit) {
          const placeholder = field === 'name' ? 'Enter item name' : (field === 'sectionName' ? 'Enter section name' : 'Enter amount')
          const maxLength = field === 'cost' ? MAX_LENGTH_COST : MAX_LENGTH_TEXT
          // focus this object
          const ctlID = `${editInfo.id}_${field}`
          setTimeout(() => {
            $(`#${ctlID}`).focus()
          }, RENDER_SETTIMEOUT_INTERVAL)
          return (
            <input
              type="text"
              id={ctlID}
              className={`inputText form-control ${field}`}
              placeholder={placeholder}
              maxLength={maxLength}
              onClick={e => e.stopPropagation()}
              onChange={e => this.handleTextChange(e, field)}
              onBlur={this.handleTextBlur}
              onKeyDown={e => this.handleTextKeydown(e, field)}
              onFocus={this.handleTextFocus}
              value={editingValue}
            />)
        } else {
          let value = null
          switch (field) {
            case 'name':
              value = row.name
              if (isAdding && value === '') {
                value = <div className="placeholder">Enter item name</div>
              }
              break
            case 'cost':
              if (row.cost === '') {
                value = ''
              } else {
                value = `${currency} ${formatNumberToLocale(row.cost, 2, true, true)}`
              }
              if (isAdding && value === '') {
                value = <div className="placeholder">Enter amount</div>
              }
              break
            case 'sectionName':
              value = section.sectionName
              break
            default:
              break
          }
          return <div className="cell_normal" onClick={e => this.handleCellClick(e, row, field, section, isSection)}>{value}</div>
        }
        break
      case 'type':
      case 'category':
        const options = field === 'type' ? OPTIONS_TYPE : OPTIONS_CATEGORY
        const placeholder = field === 'type' ? 'Select Expense/Revenue' : 'Select Type'
        const value = isEdit ? editingValue : row[field]
        const ctlID = `${row.id}_${field}`
        if (isEdit) {
          // focus this object
          setTimeout(() => {
            $(`#${ctlID}`).focus()
          }, RENDER_SETTIMEOUT_INTERVAL)
        }
        return (<select
          key={ctlID}
          id={ctlID}
          className={`inputSelect form-control ${!value ? 'placeholder' : ''}`}
          value={value || ''}
          onChange={e => this.handleCategoryChange(e, isEdit, row, field, section, isSection)}
          onKeyDown={e => this.handleSelectKeydown(e, field)}
        >
          {!value && <option value="">{placeholder}</option>}
          {_map(options, category => <option key={category.value} value={category.value}>{category.title}</option>)}
        </select>)
        break
      case 'responsibleUsers':
        const roptions = eventMembers.map(em => ({
          value: em.id,
          title: em.firstName + ' ' + em.lastName
        }))
        const rplaceholder = 'Select Member'
        const rvalue = isEdit ? editingValue : row[field]
        const rctlID = `${row.id}_${field}`
        if (isEdit) {
          // focus this object
          setTimeout(() => {
            $(`#${rctlID}`).focus()
          }, RENDER_SETTIMEOUT_INTERVAL)
        }
        return (<select
          key={rctlID}
          id={rctlID}
          className={`inputSelect form-control ${!rvalue ? 'placeholder' : ''}`}
          value={rvalue || ''}
          onChange={e => this.handleCategoryChange(e, isEdit, row, field, section, isSection)}
          onKeyDown={e => this.handleSelectKeydown(e, field)}
        >
          {!rvalue && <option value="">{rplaceholder}</option>}
          {_map(roptions, category => <option key={category.value} value={category.value}>{category.title}</option>)}
        </select>)
        break
      default:
        break
    }
  }

  renderSections = (sections, parentSecIndex, level, parentSection) => {
    const { event } = this.props
    const currency = getCurrencySymbol(event)
    const { isUpdating, updatingSection } = this.state
    const { isAdding, isAddingSection, addingInfo } = this.state

    let sections2 = sections
    // add new section in add mode
    if (isAdding && isAddingSection) {
      const isSubSection = addingInfo.parentId
      if (level === 0 && !isSubSection) {
        sections2 = []
        _map(sections, section => {
          sections2.push(section)
        })
        sections2.push(addingInfo)
      }
      if (isSubSection && parentSection && parentSection.id.toString() === addingInfo.parentId.toString()) {
        sections2 = []
        _map(sections, section => {
          sections2.push(section)
        })
        sections2.push(addingInfo)
      }
    }

    return _map(sections2, (section, secIndex) => {
      const index = section.id
      const section_toolbar_normal = (
        <div className="section_header_toolbar">
          <img data-tip="" data-for={`tooltip_addItem_${index}`} src={asset('/resources/images/budget/add-row-icon.svg')} onClick={evt => this.handleAddItem(evt, section)} />
          <img data-tip="" data-for={`tooltip_addSubSection_${index}`} src={asset('/resources/images/budget/split-icon.svg')} onClick={evt => this.handleAddSubSection(evt, section)} />
          <img data-tip="" data-for={`tooltip_deleteSection_${index}`} src={asset('/resources/images/budget/delete-ico.svg')} onClick={evt => this.handleDeleteSection(evt, section)} />
          <ReactTooltip id={`tooltip_addItem_${index}`} place="bottom">
            Add Item
          </ReactTooltip>
          <ReactTooltip id={`tooltip_addSubSection_${index}`} place="bottom">
            Add Sub Section
          </ReactTooltip>
          <ReactTooltip id={`tooltip_deleteSection_${index}`} place="bottom">
            Delete Section
          </ReactTooltip>
        </div>
      )
      const section_toolbar_adding_active = (
        <div className="section_header_toolbar">
          <i data-tip="" data-for={`tooltip_addNewSection_${index}`} className="fa fa-fw fa-check" onClick={evt => this.handleSaveSection(evt)} />
          <i data-tip="" data-for={`tooltip_cancelNewSection_${index}`} className="fa fa-fw fa-times" onClick={evt => this.handleCancelNew(evt)} />
          <ReactTooltip id={`tooltip_addNewSection_${index}`} place="bottom">
            Save
          </ReactTooltip>
          <ReactTooltip id={`tooltip_cancelNewSection_${index}`} place="bottom">
            Cancel
          </ReactTooltip>
        </div>
      )
      const section_toolbar_adding_inactive = (
        <div className="section_header_toolbar">
          <i className="fa" />
        </div>
      )
      const addition = (
        <div className={this.getClassName(true, isAdding, addingInfo, section)}>
          <div className="section_header_title">{this.renderCell(null, 'sectionName', section, true)}</div>
          <div className="section_header_note" />
          {!isAdding ? section_toolbar_normal :
            (isAddingSection && section.id.toString() === addingInfo.id.toString() ? section_toolbar_adding_active : section_toolbar_adding_inactive)
          }
        </div>
      )
      // clipboard
      this.addClipboardLine(section.sectionName, '', '', '', '', level)
      const contentEmpty = null
      // add new item in add mode
      let { items } = section
      if (isAdding && !isAddingSection && addingInfo.sectionId.toString() === section.id.toString()) {
        items = []
        _map(section.items, row => {
          items.push(row)
        })
        items.push(addingInfo)
      }
      const contentRows = _map(items, row => {
        const item_toolbar_normal = (
          <td className="section_row_toolbar">
            <img data-tip="" data-for={`tooltip_deleteRow_${index}`} src={asset('/resources/images/budget/delete-ico.svg')} onClick={evt => this.handleDeleteRow(evt, section, row)} />
            <ReactTooltip id={`tooltip_deleteRow_${row.id}`} place="bottom">
              Delete Row
            </ReactTooltip>
          </td>
        )
        const item_toolbar_adding_active = (
          <td className="section_row_toolbar">
            <i data-tip="" data-for={`tooltip_addNewItem_${row.id}`} className="fa fa-fw fa-check" onClick={evt => this.handleSaveItem(evt)} />
            <i data-tip="" data-for={`tooltip_cancelNewItem_${row.id}`} className="fa fa-fw fa-times" onClick={evt => this.handleCancelNew(evt)} />
            <ReactTooltip id={`tooltip_addNewItem_${row.id}`} place="bottom">
              Save
            </ReactTooltip>
            <ReactTooltip id={`tooltip_cancelNewItem_${row.id}`} place="bottom">
              Cancel
            </ReactTooltip>
          </td>
        )
        const item_toolbar_adding_inactive = (
          <td className="section_row_toolbar">
            <i className="fa" />
          </td>
        )
       this.addClipboardLine(row.name || '', row.cost, row.type !== 'revenue' ? 'Expense' : 'Revenue', row.category, row.responsibleUsers || '' , level + 1) 
       return (
          <tr key={row.id} className={this.getClassName(false, isAdding, addingInfo, row)}>
            <td className="section_row_title">{this.renderCell(row, 'name', section, false, isAdding)}</td>
            <td className="section_row_amount">{this.renderCell(row, 'cost', section, false, isAdding)}</td>
            <td className="section_row_isExpense">{this.renderCell(row, 'type', section, false, isAdding)}</td>
            <td className="section_row_type">{this.renderCell(row, 'category', section, false, isAdding)}</td>
            <td className="section_row_assignedTo">{this.renderCell(row, 'responsibleUsers', section, false, isAdding)}</td>
            {!isAdding ? item_toolbar_normal :
              (!isAddingSection && row.id.toString() === addingInfo.id.toString() ? item_toolbar_adding_active : item_toolbar_adding_inactive)
            }
          </tr>
        )
      })
      let contentSubTotal = null
      let sum = 0
      if (section.items.length > 0) {
        _map(section.items, row => {
          if (row.type === 'expense') {
            sum += parseFloat(row.cost)
          } else {
            sum -= parseFloat(row.cost)
          }
        })
        if (level > 0 && section.subsections.length === 0) {
          this.addClipboardLine(
            'Sub-total',
            `${sum > 0 ? sum : -sum}`,
            sum >= 0 ? 'Expenses' : 'Revenue',
            '',
            '',
            level + 1
          )
        }
        contentSubTotal = (
          <tfoot>
            <tr className={`section_row ${isAdding ? 'inactive' : ''}`}>
              <td className="section_row_title">Sub-total</td>
              <td className="section_row_amount">{`${currency} ${formatNumberToLocale(sum > 0 ? sum : -sum, 2, true, true)}`}</td>
              <td className="section_row_isExpense">{sum >= 0 ? 'Expenses' : 'Revenue'}</td>
              <td className="section_row_type" />
              <td className="section_row_assignedTo" />
              <td className="section_row_toolbar" />
            </tr>
          </tfoot>
        )
      }
      const contentRowTable = items.length === 0 ? null : (
        <div className="row_table">
          <table className="table">
            <tbody>
              {contentRows}
            </tbody>
            {level > 0 && section.subsections.length === 0 && contentSubTotal}
          </table>
        </div>
      )
      // calculate sum
      const sumIndex = level === 0 ? secIndex : parentSecIndex
      if (level === 0)
        this.sumSections[sumIndex] = 0
      this.sumSections[sumIndex] += sum

      const contentSubsections = this.renderSections(section.subsections, sumIndex, level + 1, section)
      // clipboard
      if (level === 0 && !section.isAdding) {
        this.addClipboardLine(
          'Total',
          `${this.sumSections[sumIndex] >= 0 ? this.sumSections[sumIndex] : -this.sumSections[sumIndex]}`,
          `${this.sumSections[sumIndex] >= 0 ? 'Expenses' : 'Revenue'}`,
          '',
          '',
          level
        )
        this.addClipboardLine('', '', '', '', '')
      }
      return (
        <Card key={index} title={' '} addition={addition} className={level > 0 ? 'subSection' : 'parentSection'} triggerSibling>
          <div className="cardDetail">
            <div className="sectionContent">
              {contentEmpty}
              {contentRowTable}
              {contentSubsections}
              {level === 0 && !section.isAdding && <div className={`sectionTotal ${isAdding ? 'inactive' : ''}`}>
                <div className="totalTitle">Total</div>
                <div className="totalAmount">{`${currency} ${formatNumberToLocale(this.sumSections[sumIndex] >= 0 ? this.sumSections[sumIndex] : -this.sumSections[sumIndex], 2, true, true)}`}</div>
                <div className="totalIsExpense">{`${this.sumSections[sumIndex] >= 0 ? 'Expenses' : 'Revenue'}`}</div>
                <div className="totalNote" />
              </div>}
            </div>
            <div className="sectionDecoration1" />
            <div className="sectionDecoration2" />
          </div>
          {isUpdating && updatingSection && (section.id.toString() === updatingSection.id.toString()) &&
            <div className="loadingSpinner">
              <i className="fa fa-spin fa-spinner" />
            </div>}
        </Card>
      )
    })
  }

  render() {
    const {
      // delete
      isConfirmModalOpen,
      isSectionDelete,

      // loading & updating
      isLoading,
      isUpdating,
      updatingSection,

      // add new
      isAdding,

      // clipboard
      clipboardCopied,
    } = this.state
    const { event, sections } = this.props

    // Show empty screen
    const isEmpty = sections.length === 0 && !isAdding && !isLoading
    const contentEmpty = (
      <div className="introContainer">
        <div className="upperPart">
          <div className="intro-title">Budget Management</div>
          <img className="intro-image" src={asset('/resources/images/budget/budget-main-ico.svg')} />
        </div>
        <div className="lowerPart">
          <div className="left">
            <div className="title1">Create a structured expenses and revenue breakdown</div>
            <div className="title2">for real-time P&amp;L reporting.</div>
          </div>
          <Button
            className="btn btn-ok btn-shadow"
            type="button"
            onClick={() => this.handleAddSection()}
          >
            <i className="fa fa-plus fa-fw" />
            New Section
          </Button>
        </div>
      </div>
    )

    // render sections
    this.sumSections = new Array(sections.length)
    this.addClipboardLine('Title', 'Amount', 'Expense/Revenue', 'Type', 'AssignedTo', 0, true)
    this.clipboardText = 'Title\tAmount\tExpense/Revenue\tType\tAssignedTo\n'
    const currency = getCurrencySymbol(event)
    const contentSections = this.renderSections(sections, 0, 0, null)
    const totalAmount = sections.length === 0 ? 0 : this.sumSections.reduce((sum, cur) => sum + cur)
    this.addClipboardLine(
      'Total Budget',
      `${totalAmount >= 0 ? totalAmount : -totalAmount}`,
      `${totalAmount >= 0 ? 'Expenses' : 'Revenue'}`,
      '',
      ''
    )
    this.addClipboardLine('', '', '', '', '')
    const contentHeader = sections.length === 0 ? null : (<div className="sectionHeader">
      <div className="headerTitle">Title</div>
      <div className="headerAmount">Amount</div>
      <div className="headerIsExpense">Expense/Revenue</div>
      <div className="headerType">Type</div>
      <div className="headerAssignedTo">Assigned To</div>
      <div className="headerNote">
        {!isAdding && <ClipboardButton component="span" data-clipboard-text={this.clipboardText} onSuccess={() => this.handleClipboardClick()}>
          <OverlayTrigger placement="left" overlay={
            clipboardCopied
              ? <Tooltip id="copyToClipboard">Copied</Tooltip>
              : <Tooltip id="copyToClipboard">Copy To Clipboard</Tooltip>
          } trigger={['hover']}>
            <i className="fa fa-clipboard" />
          </OverlayTrigger>
        </ClipboardButton>}
      </div>
    </div>)
    const contentTotal = sections.length !== 0 && (<div className="sectionTotal">
      <div className="totalTitle">Total Budget</div>
      <div className="totalAmount">{`${currency} ${formatNumberToLocale(totalAmount >= 0 ? totalAmount : -totalAmount, 2, true, true)}`}</div>
      <div className="totalIsExpense">{`${totalAmount >= 0 ? 'Expenses' : 'Revenue'}`}</div>
      <div className="totalNote" />
    </div>)

    // drawing
    return (
      <DisableSectionByPermissionWrapper>
        <div className="budgetContainer">
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={isConfirmModalOpen}
            contentLabel="Modal"
            onRequestClose={() => this.handleConfirmModal(false)}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Confirm</p>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to remove the selected {isSectionDelete ? 'section' : 'row'}?</p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-danger btn-shadow"
                        type="button"
                        onClick={() => this.processDelete()}>Yes</Button>
                      <Button
                        className="btn btn-cancel btn-shadow" type="button"
                        onClick={() => this.handleConfirmModal(false)}>No</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {!isEmpty &&
            <div className="body-panel-header">
              <div className="left">
                <div className="title">Budget Management</div>
              </div>
              <div className="right">
                {!isAdding && <Button
                  className="btn btn-ok btn-shadow"
                  type="button"
                  onClick={() => this.handleAddSection()}
                >
                  <i className="fa fa-plus fa-fw" />
                New Section
                </Button>}
              </div>
            </div>
          }
          {isLoading && <LoadingBar key='loadingbar' title="Loading budget data..." />}
          {isEmpty && contentEmpty}
          <div className="sectionsContainer">
            <div className="sectionsBody">
              {!isLoading && contentHeader}
              {!isLoading && contentSections}
              {!isLoading && contentTotal}
              {isUpdating && !updatingSection &&
                <div className="loadingSpinner">
                  <i className="fa fa-spin fa-spinner" />
                </div>}
            </div>
          </div>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

export default BudgetManagement
