import _get from 'lodash/get'
import { baseRequest } from '.'

export const toggleOrderResale = async (eventId, orderId, data) => {
  const res = await baseRequest.post(`events/${eventId}/orders/${orderId}/resale-status/`, {
    data,
  })

  return _get(res, 'data')
}

export const markOrderAsPaid = async (eventId, orderId, data) => {
  const res = await baseRequest.post(`events/${eventId}/orders/${orderId}/complete-pending-order/`, {
    data,
  })

  return _get(res, 'data')
}

export const issueGuestAddOn = async (eventId, data) => {
  const res = await baseRequest.post(`events/${eventId}/guest-add-ons`, {
    data: { attributes: data },
  })

  return _get(res, 'data')
}
