import _get from 'lodash/get'

export const getRnMetaData = (values, props) => {
  const {
    eventGrade,
    isAccessFeatured,
    accessSubGroup,
    eventGenres,
    eventCategory,
    rsvpUrl,
    flagPrivate,
    flagRecommended,
    isRnDomain,
    brief,
    region,
    ...rest
  } = values

  const metadata = {
    eventGrade: eventGrade ?? _get(props, 'rnEventMetadataObj.eventGrade'),
    isAccessFeatured: !!accessSubGroup,
    accessSubGroup: accessSubGroup || null,
    eventGenres: eventGenres || null,
    eventCategory: eventCategory || null,
    rsvpUrl,
    flagPrivate: flagPrivate || false,
    flagRecommended: flagRecommended || false,
    brief,
    region,
    sendToRN: true
  }

  return { ...rest, metadata }
}
