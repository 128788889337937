import React from 'react'
import _ from 'lodash'

import Card from '../../../_library/Card'
import Field from '../../../_library/Field'
import CustomToggle from '../../../_library/CustomToggle'
import { arrayToObject } from './utils'
import { EVENT_TYPES } from '../../../constants/eventTypes'
import { EVENT_CATEGORIES } from '../../../constants/eventCategories'
import { GENRES } from '../../../constants/genres'

const EventType = (props) => {
  const { values, errors, touched, cardsStatus, isRnDomain, showGenres, updateField } = props

  const getId = id => {
    const str = showGenres ? 'eventGenres_' : isRnDomain ? 'eventCategory_' : 'eventType_'
    return id.replace(str, '')
  }

  const handleChangeEventType = (fieldKey, value) => {
    const fieldId = showGenres ? 'eventGenres' : isRnDomain ? 'eventCategory' : 'eventType'
    const id = getId(fieldKey)

    const objEventType = arrayToObject(values[fieldId])
    objEventType[id] = value
    updateField(fieldId, objEventType)
  }

  const fieldId = showGenres ? 'eventGenres' : isRnDomain ? 'eventCategory' : 'eventType'
  const objEventType = arrayToObject(values[fieldId])
  const arr = showGenres ? GENRES : isRnDomain ? EVENT_CATEGORIES : EVENT_TYPES

  return (
    <Card
      icon={'fa-toggle-on'}
      title={showGenres ? 'Event Genres' : isRnDomain ? 'Event Categories' : 'Event Type'}
      status={cardsStatus && cardsStatus.type}
    >
      <Field
        id={fieldId}
        type="showOnlyError"
        value={values[fieldId]}
        error={errors[fieldId]}
        touched={touched[fieldId]}
      />
      <div className="row">
        <div
          className={`col-xs-6 col-6 event-details-checkboxes ${isRnDomain ? (showGenres ? 'rn-event-genres' : 'rn-event-categories') : ''}`}
        >
          {_.map(arr, t => (
            <CustomToggle
              key={t.id}
              id={isRnDomain ? t.label : t.id}
              onText={t.label}
              value={!!objEventType[getId(isRnDomain ? t.label : t.id)]}
              checked={!!objEventType[getId(isRnDomain ? t.label : t.id)]}
              onChange={handleChangeEventType}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default EventType
