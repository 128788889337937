import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _reduce from 'lodash/reduce'
import _groupBy from 'lodash/groupBy'
import _forEach from 'lodash/forEach'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  percentNormalizerCreator,
} from '../../../../_common/core/validation/normalizers'
import { checkIsPromoter } from '../../../utils/permissions'
import EmptyBar from '../../../_library/EmptyBar'

export default class SalesByTicketType extends React.Component {
  constructor(props) {
    super(props)
    const isPromoter = checkIsPromoter(props.event)

    // Tables here refers Table ticket types
    const displayTablesColumns = this.shouldDisplayTablesColumns(props.data)

    this.tableColumns = this.getColumns(isPromoter, props, displayTablesColumns)
    this.detailsTableColumns = this.getDetailsColumns(props, displayTablesColumns)

    this.footbarColumns = this.getFootbarColumns(props, displayTablesColumns)

    this.state = {
      sortBy: { column: 'release_type', asc: true },
      rows: null,
      detailsRows: [],
    }
  }

  getColumns(isPromoter, props, includeTablesData = false) {
    const columns = [
      {
        key: 'release_type',
        label: 'Ticket Type',
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
      },
      !isPromoter && {
        key: 'stock',
        label: 'Stock',
      },
      {
        key: 'cost',
        label: 'Price (excl. Fees)',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenueTotal',
          label: 'Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          label: 'Currently Paid',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'depositPercent',
          label: 'Deposit Percent',
          isSortable: true,
          normalizer: value => percentNormalizerCreator(createFixedFloatNormalizer(0)(parseFloat(value))),
        },
        {
          key: 'pendingToPay',
          label: 'Pending to Pay',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenueTotal',
          label: 'Final Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    }

    return getTableColumns(columns.filter(Boolean), getCurrencySymbol(props.event))
  }

  getDetailsColumns(props, includeTablesData = false) {
    const columns = [
      {
        key: 'orderDate',
        label: 'Order Date',
        className: 'detail-cell',
        normalizer: formatDay,
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
        className: 'detail-cell',
      },
      {
        key: 'totalSales',
        label: 'Running Total (No. of Sales)',
        className: 'detail-cell',
      },
      {
        key: 'cost',
        label: 'Price (excl. Fees)',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
    ]

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenue',
          label: 'Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
        {
          key: 'revenueTotal',
          label: 'Running Total (Revenue)',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
        {
          key: 'grossRevenueTotal',
          label: 'Running Total (Gross Revenue)',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          label: 'Currently Paid',
          className: 'detail-cell',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'depositPercent',
          label: 'Deposit Percent',
          className: 'detail-cell',
          isSortable: true,
          normalizer: value => percentNormalizerCreator(createFixedFloatNormalizer(0)(parseFloat(value))),
        },
        {
          key: 'pendingToPay',
          label: 'Pending to Pay',
          className: 'detail-cell',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenue',
          label: 'Final Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
      )
    }

    return getTableColumns(columns)
  }

  getFootbarColumns(props, includeTablesData) {
    const columns = ['num_sales']

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenueTotal',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'pendingToPay',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenueTotal',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    }

    return columns
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { groupedByTicketType, tickets } = this.props
    if (
      !_isEqual(groupedByTicketType, prevProps.groupedByTicketType) ||
      !_isEqual(tickets, prevProps.tickets)
    ) {
      this.updateData()
    }
  }

  shouldDisplayTablesColumns = data =>
    data.reduce(
      (res, currVal) =>
        res | (currVal.depositPercent > Number.EPSILON) | (currVal.totalGuestRevenue > Number.EPSILON),
      false,
    )

  getTicketId = (cost, id, depositPercent) => cost + id + (depositPercent || '')

  updateData = () => {
    const { groupedByTicketType, tickets, hideDateBreakdowns } = this.props
    const tempRows = []
    const detailsRows = []

    _forEach(groupedByTicketType, value => {
      const ticketId = value[0].option_matrix_record_id
      const currentTicket = _find(tickets, item => +item.id === +ticketId) || { stock: 0 }
      const id = this.getTicketId(
        value[0].cost,
        value[0].slotGroupId || value[0].option_matrix_record_id,
        value[0].depositPercent,
      )
      const totalNumSales = this.getTotalNumSales(value)
      !hideDateBreakdowns && this.getDetailRows(value, detailsRows)

      tempRows.push({
        id,
        release_type: value[0].slotGroupName || value[0].release_type,
        num_sales: totalNumSales,
        cost: value[0].cost,
        currentDeposit: this.calculateCurrentDeposit(value),
        depositPercent: value[0].depositPercent,
        pendingToPay: this.calculateCurrentDebt(value),
        revenueTotal: this.calculateRevenue(value),
        stock: parseInt(currentTicket.stock),
        grossRevenue: _reduce(
          value,
          (sum, salesItem) =>
            sum +
            (parseInt(salesItem.num_sales) * parseFloat(salesItem.price) -
              parseFloat(salesItem.refunded_amount)),
          0,
        ),
      })
    })

    this.setState({
      rows: _filter(tempRows, rowItem => !!rowItem.id),
      detailsRows,
    })
  }

  getTotalNumSales = (ticketsArray = []) => {
    let totalSales = 0
    _map(ticketsArray, item => {
      totalSales += parseInt(item.num_sales)
    })

    return totalSales
  }

  calculateRevenue = (ticketsArray = []) => {
    let totalRevenue = 0
    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      totalRevenue +=
        parseInt(item.num_sales) * parseFloat(item.cost) - parseFloat(item.refunded_amount) + guestRevenue
    })

    return totalRevenue
  }

  calculateCurrentDeposit = (ticketsArray = []) => {
    let totalDeposit = 0

    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      const total =
        parseInt(item.num_sales) * parseFloat(item.cost) - parseFloat(item.refunded_amount) + guestRevenue

      let depositPercent = parseFloat(item.depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      totalDeposit += (total * depositPercent) / 100.0
    })

    return totalDeposit
  }

  calculateCurrentDebt = (ticketsArray = []) => {
    let totalDebt = 0

    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      const total = parseInt(item.num_sales) * parseFloat(item.cost) + guestRevenue

      let depositPercent = parseFloat(item.depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      const totalDeposit = (total * depositPercent) / 100.0

      totalDebt += total - totalDeposit
    })

    return totalDebt
  }

  calculateTotalValues = data => {
    const totalNumSales = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0)
    const totalRevenue = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].cost)
    const totalGrossRevenue =
      _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].price) -
      parseFloat(data[0].refunded_amount)

    return [totalNumSales, totalRevenue, totalGrossRevenue]
  }

  getDetailRows = (data, detailsRows) => {
    const detailRows = []
    let totalDeposit = 0
    let totalPendingToPay = 0

    const products = _groupBy(data, item => [item.orderDate, item.cost])

    _forEach(products, item => {
      const id = this.getTicketId(
        item[0].cost,
        item[0].slotGroupId || item[0].option_matrix_record_id,
        item[0].depositPercent,
      )
      const [totalNumSales, totalRevenue, totalGrossRevenue] = this.calculateTotalValues(item)

      let totalGuestRevenue = parseFloat(item[0].totalGuestRevenue)
      if (isNaN(totalGuestRevenue)) {
        totalGuestRevenue = 0
      }
      const totalRowRevenue =
        parseFloat(item[0].num_sales) * parseFloat(item[0].cost) -
        parseFloat(item[0].refunded_amount) +
        totalGuestRevenue

      let depositPercent = parseFloat(item[0].depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      const totalRowDeposit = (totalRowRevenue * depositPercent) / 100.0

      totalDeposit += totalRowDeposit
      totalPendingToPay += totalRowRevenue - totalRowDeposit

      detailRows.push({
        id,
        slotGroupId: item[0].slotGroupId,
        orderDate: item[0].orderDate,
        num_sales: totalNumSales,
        cost: item[0].cost,
        currentDeposit: totalRowDeposit,
        depositPercent: item[0].depositPercent,
        pendingToPay: totalRowRevenue - totalRowDeposit,
        revenue: totalRowRevenue,
        revenueTotal: totalRevenue,
        release_type: item[0].slotGroupName || item[0].release_type,
        grossRevenue: totalGrossRevenue,
        depositTotal: totalDeposit,
        pendingToPayTotal: totalPendingToPay,
      })
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].id,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`sales_by_ticket_type-${detailRowIndex}`}
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
            calculatedColumns={[
              { for: 'totalSales', column: 'num_sales' },
              { for: 'revenueTotal', column: 'revenue' },
              { for: 'grossRevenueTotal', column: 'grossRevenue' },
            ]}
          />
        ),
      })
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            e2e_test_id="sales_by_ticket_type"
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            detailsRows={detailsRows}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns,
            }}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
