import React from 'react'
import Select from '../../_library/Select'
import _ from 'lodash'

export default class Usage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataToSend: {},
      additionalItems: {}
    }
  }

  componentDidMount() {
    this.addInitialAdditionalItemsToState()
  }

  render() {
    const { targetAction } = this.props
    switch (targetAction) {
    case 'addVisit': {
      return this.getAddVisitBody()
    }
    case 'updateVisit': {
      return this.getUpdateVisitBody()
    }
    }
  }

  addInitialAdditionalItemsToState = () => {
    this.setState({
      additionalItems: {
        ...this.state.additionalItems,
        ...this.getAdditionalItemsDefaultValues()
      }
    })
  }

  getAddVisitBody = () => {
    const { tickets, additionalItems, toggleModal } = this.props
    const {
      dataToSend: { ticketId }
    } = this.state
    const selectOptions = tickets
      .filter(ticket => !ticket.isChecked)
      .map(ticket => ({ value: ticket.id, label: ticket.name }))
    selectOptions.unshift({ label: 'Select Ticket', value: '' })
    const additionalItemsSection = this.getAdditionalItemsSection(additionalItems)
    return (
      <section className="collected-items">
        {selectOptions.length > 1 ? (
          <div className="row">
            <div className="col-xs-12">
              <div className="col-xs-12">
                <Select
                  value={ticketId}
                  onChange={this.onTicketChange}
                  className="form-control simple-select"
                  options={selectOptions}
                />
              </div>
            </div>
            {additionalItemsSection.length ? (
              <div className="col-xs-12" style={{ display: 'flex', flexDirection: 'column' }}>
                {additionalItemsSection}
              </div>
            ) : (
              <p className="text-center">No additional item available for this membership plan</p>
            )}
          </div>
        ) : (
          <p className="text-center">No tickets available for this membership plan</p>
        )}
        <div className="row">
          <div className="modal-footer" style={{ margin: '15px 10px -15px' }}>
            {selectOptions.length > 1 ? (
              <button
                disabled={!ticketId}
                onClick={this.handleSubmit}
                className="btn btn-ok btn-shadow"
              >
                Save
              </button>
            ) : null}
            <button
              onClick={() => toggleModal('RecordCollectedItem')}
              className="btn btn-cancel btn-shadow"
            >
              Cancel
            </button>
          </div>
        </div>
      </section>
    )
  }

  getUpdateVisitBody = () => {
    const { additionalItems, toggleModal, ticketName } = this.props
    const { additionalItems: selectedAdditionalItems } = this.state
    const additionalItemsSection = this.getUpdatingAdditionalItemsSection(additionalItems)
    return (
      <div>
        <div className="modal-body collected-items">
          <div className="row">
            <div className="col-xs-12">
              <p>{ticketName}</p>
            </div>
            {additionalItemsSection.length ? (
              <div className="col-xs-12" style={{ display: 'flex', flexDirection: 'column' }}>
                {additionalItemsSection}
              </div>
            ) : (
              <p className="text-center">No additional item available for this membership plan</p>
            )}
          </div>
        </div>
        <div className="modal-footer">
          {!_.isEmpty(selectedAdditionalItems) ? (
            <button onClick={this.handleSubmit} className="btn  btn-ok btn-shadow">
              Save
            </button>
          ) : null}
          <button
            onClick={() => toggleModal('UsageDetails')}
            className="btn btn-cancel align-right"
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  handleSubmit = () => {
    const { dataToSend, additionalItems } = this.state
    dataToSend.additionalItems = _.map(additionalItems, (quantity, id) => ({ id, quantity }))
    this.props.onSubmit(dataToSend)
  }

  getAdditionalItemsSection = additionalItems => {
    const additionalItemsArray = []
    additionalItems.forEach(item => {
      if (!parseInt(item.quantity)) return
      const selectOptions = this.getAdditionalItemsSelectOptions(item.quantity)
      additionalItemsArray.push(
        <div key={item.id} className="membership-add-ons-item">
          <div className="add-on-name col-xs-6">{item.name}</div>
          <div className="col-xs-6">
            <Select
              value={this.state.additionalItems[item.id] || 0}
              onChange={this.onAdditionalItemChange(item.id)}
              className="form-control simple-select"
              options={selectOptions}
            />
          </div>
        </div>
      )
    })
    return additionalItemsArray
  }

  getUpdatingAdditionalItemsSection = additionalItems => {
    const additionalItemsDefaultValues = this.getAdditionalItemsDefaultValues()
    const additionalItemsArray = []
    additionalItems.forEach(item => {
      const defaultValue = additionalItemsDefaultValues[item.id] || 0
      const itemQuantity = +defaultValue + +item.quantity
      if (!itemQuantity) return
      const selectOptions = this.getAdditionalItemsSelectOptions(itemQuantity)
      additionalItemsArray.push(
        <div key={item.id} className="membership-add-ons-item">
          <div className="add-on-name col-xs-6">{item.name}</div>
          <div className="col-xs-6">
            <Select
              value={this.state.additionalItems[item.id] || defaultValue || 0}
              onChange={this.onAdditionalItemChange(item.id)}
              className="form-control simple-select"
              options={selectOptions}
            />
          </div>
        </div>
      )
    })
    return additionalItemsArray
  }

  getAdditionalItemsDefaultValues = () => {
    const { ticketId, membershipVisits } = this.props
    const returnData = {}
    const targetVisit = _.find(membershipVisits, item => ticketId === item.$original.ticket.id)
    targetVisit &&
      targetVisit.$original.items.forEach(item => {
        returnData[item.itemId] = item.quantity
      })
    return returnData
  }

  getAdditionalItemsSelectOptions = itemQunatity => {
    const options = []
    for (let i = 0; i <= itemQunatity; i++) {
      options.push({ value: i, label: i })
    }
    return options
  }

  onTicketChange = event => {
    const ticketId = event.target.value
    this.setTargetTicketId(ticketId)
  }

  onAdditionalItemChange = itemId => event => {
    const itemQuantity = event.target.value
    this.setState(() => ({
      additionalItems: {
        ...this.state.additionalItems,
        [itemId]: itemQuantity
      }
    }))
  }

  setTargetTicketId = ticketId => {
    this.setState(() => ({ dataToSend: { ...this.state.dataToSend, ticketId } }))
  }
}
