import _filter from 'lodash/filter'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  trackingData: [],
  trackingDataByToken: {},
}

const FETCH_TICKET_TRACKING_DATA = createAsyncHandlers('FETCH_TICKET_TRACKING_DATA', {
  success(state, action) {
    const { attributes } = action.payload
    state.trackingData = attributes
  },
})

const CREATE_TICKET_TRACKING_DATA = createAsyncHandlers('CREATE_TICKET_TRACKING_DATA', {
  success(state, action) {
    const { attributes } = action.payload.data.data
    state.trackingData.push(attributes)
  },
})

const DELETE_TICKET_TRACKING_DATA = createAsyncHandlers('DELETE_TICKET_TRACKING_DATA', {
  success(state, action) {
    const { id } = action.payload
    const newList = _filter(state.trackingData, item => item.id !== id)
    state.trackingData = newList
  },
})

const REGENERATE_TRACKING_TOKEN = createAsyncHandlers('REGENERATE_TRACKING_TOKEN', {
  success(state, action) {
    const { id, data } = action.payload
    const newList = state.trackingData.map(item => {
      if (item.id === id) {
        item.token = data.data.data.attributes.token
      }
      return item
    })

    state.trackingData = newList
  },
})

const FETCH_TRACKING_DATA_BY_TOKEN = createAsyncHandlers('FETCH_TRACKING_DATA_BY_TOKEN', {
  success(state, action) {
    state.trackingDataByToken = action.payload
  },
})

export default handleActions(
  {
    ...FETCH_TICKET_TRACKING_DATA,
    ...CREATE_TICKET_TRACKING_DATA,
    ...DELETE_TICKET_TRACKING_DATA,
    ...REGENERATE_TRACKING_TOKEN,
    ...FETCH_TRACKING_DATA_BY_TOKEN,
  },
  initialState,
)
