// Re-render Optimization 18.05.2020
import _map from 'lodash/map'
import _debounce from 'lodash/debounce'
import React from 'react'
import LazyLoad from 'react-lazy-load'
import { Element } from 'react-scroll'
import EventComponent from './EventComponent'
import EventImagePlaceholder from '../EventImagePlaceholder'

const EVENTS_SECTION_COUNT = 50

export default class EventsList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.onSearch = _debounce(this.onSearch, 400)
  }
  componentDidMount() {
    const scrollContainer = document.getElementById('scroll-container')
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount() {
    const scrollContainer = document.getElementById('scroll-container')
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = () => {
    const { events } = this.props
    const { page } = this.state
    if (events.length <= page * EVENTS_SECTION_COUNT) {
      return
    }
    const isBottomNear = this.isBottomNear()
    if (isBottomNear) {
      this.setState(state => ({ page: state.page + 1 }))
    }
  }

  isBottomNear = () => {
    const container = document.getElementById('scroll-container')
    if (!container) return false
    const containerTop = container.scrollTop
    const { clientHeight } = container
    const { scrollHeight } = container
    const bottomNear = scrollHeight - (containerTop + clientHeight)
    return bottomNear < 500
  }

  onSearch = e => {
    const { onSearch } = this.props
    this.setState(() => ({ page: 1 }))
    onSearch(e)
    const container = document.getElementById('scroll-container')
    if (!container) return
    container.scrollTo(0, 0)
  }

  render() {
    let { events, currentEventId, onClickEvent, setInFilterEvent, fullReady } = this.props
    const { page } = this.state
    events = events.slice(0, page * EVENTS_SECTION_COUNT)
    return (
      <div className={'select2-options-container expanded'}>
        <div className="select2-options-search">
          <input className="form-control" onChange={e => this.onSearch(e.target.value)} />
          <i className="fa fa-search" />
        </div>
        <Element name="select2-options-list" className="select2-options-list" id="scroll-container">
          {!fullReady && <p className="loading-dots">We're getting your full event list</p>}
          {_map(events, (event, index) => {
            const isActive = event.id === currentEventId
            const scrollElementName = isActive ? 'scroll-to-element' : 'child' + index
            isActive && setInFilterEvent(true)
            return (
              <Element key={event.id} className="select2-option-container" name={scrollElementName}>
                <div className="select2-option" onClick={() => onClickEvent(event)}>
                  <div className="events-select2-event">
                    <div className="event-image-container">
                      {!!event.imageURL && (
                        <LazyLoad width={40} height={40} once>
                          <img
                            src={event.imageURL}
                            className="event-image LazyLoadImg"
                            style={{ marginLeft: '0px' }}
                          />
                        </LazyLoad>
                      )}
                      {!event.imageURL && <EventImagePlaceholder />}
                    </div>
                    <EventComponent event={event} isActive={isActive} />
                  </div>
                </div>
              </Element>
            )
          })}
          {!fullReady && events.length > 3 && (
            <p className="loading-dots">We're getting your full event list</p>
          )}
        </Element>
      </div>
    )
  }
}
