import React, { Component } from 'react'
import { FieldArray, Field } from 'formik'
import { SimpleField } from '../../../formik/Fields'
import { combineNormalizers, toNumber, requiredValidator } from '../../../../_common/core/validation'

const HoldComponent = props => {
  const { values, isEditing, handleRowDelete = () => null } = props
  return (
    <div>
      <FieldArray
        name="ticketHolds"
        render={arrayHelpers => (
          <div className="col-xs-12 col-12 bottom-field-ticket-form" style={{ padding: 0 }}>
            {values.ticketHolds.map((data, index) => {
              return (
                <div
                  className="hold_for_block"
                  key={index}
                  style={{ alignItems: 'center' }}
                >
                  <div className="col-xs-12 hold_for_item">
                    <Field
                      name={`ticketHolds[${index}].description`}
                      label={`Hold for`}
                      component={SimpleField}
                      touchedOnBlur={true}
                      disabled={data.id ? true : false}
                      validate={requiredValidator()}
                    />
                  </div>
                  <div className="col-xs-12 hold_for_item">
                    <Field
                      name={`ticketHolds.${index}.quantity`}
                      label="Quantity of tickets"
                      component={SimpleField}
                      touchedOnBlur={true}
                      onBlurNormalizer={combineNormalizers(toNumber)}
                      validate={requiredValidator()}
                      disabled={data.id ? true : false}
                      normalizer={toNumber}
                    />
                  </div>
                  <div className="hold_buttons_block">
                    {(!!index || isEditing) && (
                      <div
                        className="hold_trash_icon"
                        style={{
                          marginRight: '20px'
                        }}
                      >
                        <i
                          id={data.description + index}
                          onClick={() => {
                            if (data.id) {
                              handleRowDelete(data)
                            }
                            arrayHelpers.remove(index)
                            if (values.ticketHolds.length === 1) {
                              arrayHelpers.insert(values.ticketHolds.length, {
                                description: '',
                                quantity: ''
                              })
                            }
                          }}
                          style={{ fontSize: '22px', cursor: 'pointer' }}
                          className="fa fa-trash"
                        />
                      </div>
                    )}
                    {values.ticketHolds.length - 1 === index && (
                      <div className="hold_trash_icon">
                        <i
                          className="fa fa-fw fa-plus"
                          onClick={() =>
                            arrayHelpers.insert(values.ticketHolds.length, {
                              description: '',
                              quantity: ''
                            })
                          }
                          style={{ fontSize: '22px', cursor: 'pointer' }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="clear-both" />
                </div>
              )
            })}
          </div>
        )}
      />
    </div>
  )
}

export default HoldComponent
