import React from 'react'
import { getTableColumns } from '../../utils/sortableTableUtils'
import SortableTable from '../../_library/SortableTable'

const TableWithHeader = ({
  data,
  dataForCopy,
  tableColumns,
  header = '',
  icon = '',
  tableClass = '',
  rowTableClass = '',
  hideRows,
  showMore,
  onShowMoreCb,
  id
}) => {
  return (
    <div className="col-xs-12 col-sm-4 buyerCard">
      <div className="title">
        <i className={icon} />
        {header}
      </div>
      <div className="table-responsive ">
        <SortableTable
          data={data}
          dataForCopy={dataForCopy}
          tableColumns={tableColumns}
          enableSort={false}
          enableCopyTable={true}
          sortBy={{ column: 'numBuyers', asc: false }}
          className="top-attendees-tbl"
          parentTableClass={tableClass}
          rowTableClass={rowTableClass}
          disableMobileView={true}
          hideRows={hideRows}
          showMore={showMore}
          onShowMoreCb={onShowMoreCb}
          id={id}
        />
      </div>
    </div>
  )
}

export { TableWithHeader }
