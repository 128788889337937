import React from 'react'
import Multiselect from 'multiselect-react-dropdown'

export default class MultiSelect extends React.Component {
  handleChangeAdd = selectedList => {
    const { id, onSelect } = this.props
    onSelect(id, selectedList)
  }

  handleChangeRemove = selectedList => {
    const { id, onRemove } = this.props
    onRemove(id, selectedList)
  }

  render() {
    const { onSelect, onRemove, ...field } = this.props
    return <Multiselect {...field} onSelect={this.handleChangeAdd} onRemove={this.handleChangeRemove} />
  }
}
