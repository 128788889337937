
export const EVENT_DESCRIPTION_LANGUAGES = [
  {
    label: 'English (Default)',
    value: 'en'
  },
  {
    label: 'Spanish',
    value: 'es'
  },
  {
    label: 'Korean',
    value: 'ko'
  },
  {
    label: 'Hindi',
    value: 'hi'
  },
  {
    label: 'French',
    value: 'fr'
  },
  {
    label: 'Chinese',
    value: 'zh'
  },
  {
    label: 'German',
    value: 'de'
  },
  {
    label: 'Icelandic',
    value: 'is'
  },
  {
    label: 'Italian',
    value: 'it'
  },
  {
    label: 'Brazilian Portuguese',
    value: 'pt-BR'
  },
  {
    label: 'Thai',
    value: 'th'
  },
]