import _map from 'lodash/map'
import React from 'react'
import { connect } from 'react-redux'
import { CONNECT_TO_MAILCHIMP, FETCH_MC_LISTS } from '../../../_common/redux/mailchimp/actions'

import Button from '../../_library/Button'

const STATE_STATUS_INIT = 0
const STATE_STATUS_LOADING = 1
const STATE_STATUS_LOADING_SUCCESSED = 2
const STATE_STATUS_CONNECTING_SUCCESSED = 22
const STATE_STATUS_LOADING_FAILED = 3

import { get_mailchimp, get_mailchimp_redirect_uri } from '../../../_common/core/selectors'

@connect(
  state => {
    const mailchimp = get_mailchimp(state)
    const redirect_uri = get_mailchimp_redirect_uri(state)
    return {
      mailchimp,
      redirect_uri,
    }
  },
  { FETCH_MC_LISTS, CONNECT_TO_MAILCHIMP },
)
export default class Mailchimp extends React.Component {
  constructor(props) {
    super(props)
    this.state = { status: STATE_STATUS_INIT }
    const { originData } = this.props
    this.connectToMailchimp = this.connectToMailchimp.bind(this)
    this.id = originData.id
  }

  componentDidMount() {
    const { originType, FETCH_MC_LISTS } = this.props
    const loadingSetter = val => () => {
      this.setState({ status: val })
    }

    loadingSetter(STATE_STATUS_LOADING)()

    FETCH_MC_LISTS(originType, this.id)
      .catch(loadingSetter(STATE_STATUS_LOADING_FAILED))
      .then(loadingSetter(STATE_STATUS_LOADING_SUCCESSED))
  }

  connectToMailchimp() {
    const { originType, CONNECT_TO_MAILCHIMP } = this.props
    const loadingSetter = val => () => {
      this.setState({ status: val })
    }

    loadingSetter(STATE_STATUS_LOADING)()

    CONNECT_TO_MAILCHIMP(this.id, originType, window.location.href)
      .catch(loadingSetter(STATE_STATUS_LOADING_FAILED))
      .then(loadingSetter(STATE_STATUS_CONNECTING_SUCCESSED))
  }

  useList = mailchimpListId => {
    const { updateFunc = () => {}, updateFinishedCB = () => {}, originType, originData } = this.props

    this.setState({ status: STATE_STATUS_LOADING })

    const attributes = {
      mailChimpListId: mailchimpListId,
    }

    if (originType === 'events') {
      attributes.salesEndDate = originData.salesEndDate
    }

    updateFunc(this.id, { attributes })
      .then(() => {
        this.setState({ status: STATE_STATUS_LOADING_SUCCESSED })
        updateFinishedCB()
      })
      .catch(() => {
        this.setState({ status: STATE_STATUS_LOADING_FAILED })
      })
  }

  render() {
    const { status } = this.state
    const { mailchimp, redirect_uri, originData } = this.props
    const flagMailChimpConnected = originData?.flagMailChimpConnected
    const mailChimpListId = originData?.mailChimpListId

    if (status === STATE_STATUS_CONNECTING_SUCCESSED) {
      this.setState({ status: STATE_STATUS_INIT })
      window.location = redirect_uri.redirect_uri
    }

    let content = null
    const content_connect = (
      <div className="row">
        <div className="col-xs-12 text-center">
          <Button
            className="btn btn-primary btn-shadow"
            type="button"
            disabled={status === STATE_STATUS_LOADING}
            onClick={this.connectToMailchimp}
          >
            {status === STATE_STATUS_LOADING && <i className="fa fa-circle-o-notch fa-fw fa-spin" />}
            {status !== STATE_STATUS_LOADING && <div>Connect To MailChimp</div>}
          </Button>
        </div>
      </div>
    )

    if (flagMailChimpConnected) {
      const lists = mailchimp?.mailchimp_lists?.lists

      let number = 0
      const rows_list = _map(lists, (m, index) => (
        <tr key={index} className={number++ % 2 === 0 ? 'row-stale' : ''}>
          <td>{m.name}</td>
          <td>{m.id}</td>
          <td>
            {m.id !== mailChimpListId && (
              <Button
                className="btn btn-seablue btn-shadow"
                type="button"
                disabled={status === STATE_STATUS_LOADING}
                onClick={() => this.useList(m.id)}
              >
                {status !== STATE_STATUS_LOADING && (
                  <span>
                    <i className="fa fa-check" /> Use this
                  </span>
                )}
                {status === STATE_STATUS_LOADING && <i className="fa fa-circle-o-notch fa-fw fa-spin" />}
              </Button>
            )}
            {m.id === mailChimpListId && (
              <div>
                <i className="fa fa-check using-this-list-check" aria-hidden="true" /> Using this list
              </div>
            )}
          </td>
        </tr>
      ))

      content = (
        <div className="mailchimp-lists-table">
          <table>
            <thead>
              <tr>
                <th>List Name</th>
                <th>ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{rows_list}</tbody>
          </table>
        </div>
      )
    }

    return (
      <div className="Mailchimp">
        {!flagMailChimpConnected && content_connect}
        {content}
      </div>
    )
  }
}
