/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import _reduce from 'lodash/reduce'
import _groupBy from 'lodash/groupBy'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import EmptyBar from '../../../_library/EmptyBar'
import { Radio, RadioGroup } from '../../../_library/RadioGroup'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDay,
} from '../../../../_common/core/validation'
import SortableTable from '../../../_library/SortableTable'
import SalesByTimeSlots from './SalesByTimeSlots'
import TicketHolderCardHeader from './TicketHolderCardHeader'

const SALES_BY_DATE = 'salesByDate'
const SALES_BY_TIME_SLOT = 'salesByTimeSlots'
const SALES_BY_TCKET_TYPE = 'salesByTicketType'

const IndividualPromoter = ({ event, promoterSalesData }) => {
  const byTicketTypeColumns = getTableColumns([
    {
      key: 'ticketTypeName',
      label: 'Ticket Type',
      isSortable: true,
    },
    {
      key: 'quantity',
      label: 'No. of Tickets Sold',
      isSortable: true,
    },
    {
      key: 'revenue',
      label: 'Revenue',
      isSortable: true,
      disableNormalizerOnCopy: true,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value))),
    },
    {
      key: 'grossRevenue',
      label: 'Gross Revenue',
      isSortable: true,
      disableNormalizerOnCopy: true,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value))),
    },
  ])

  const footbarColumns = [
    { key: 'quantity', sourceKey: 'quantity' },
    {
      key: 'revenue',
      sourceKey: 'revenue',
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value))),
    },
    {
      key: 'grossRevenue',
      sourceKey: 'grossRevenue',
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value))),
    },
  ]

  const byDateColumns = getTableColumns([
    {
      key: 'date',
      label: 'Date',
      isSortable: true,
      normalizer: value => formatDay(value),
    },
    {
      key: 'quantity',
      label: 'No. of Tickets Sold',
      isSortable: true,
    },
  ])

  const [selectedTab, setSelectedTab] = useState({})

  const isTimeSlotEvent = event && event.flagTimeSlotsEnabled

  const onChangeShowGroupsByDate = (value, row) => {
    setSelectedTab({
      [row.promoterId]: value,
    })
  }

  const getGroupedData = (data, selectedTab) => {
    const isTimeSlotEvent = event && event.flagTimeSlotsEnabled

    switch (selectedTab) {
      case SALES_BY_TIME_SLOT:
        const groupedByTimeSlot = _groupBy(data, 'startDate')
        return groupedByTimeSlot
      case SALES_BY_TCKET_TYPE:
        const groupedByTicketTypeData = _groupBy(data, isTimeSlotEvent ? 'groupName' : 'ticketTypeName')
        return _map(groupedByTicketTypeData, (item, key) => {
          const quantity = _reduce(item, (sum, n) => sum + n.quantity, 0)
          const revenue = _reduce(item, (sum, n) => sum + n.revenue - parseFloat(n.refundedAmount), 0)
          const grossRevenue = _reduce(
            item,
            (sum, n) => sum + parseInt(n.quantity) * parseFloat(n.price) - parseFloat(n.refundedAmount),
            0,
          )

          return {
            ticketTypeName: key,
            quantity,
            revenue,
            grossRevenue,
          }
        })
      case SALES_BY_DATE:
        const groupedByDateData = _groupBy(data, 'date')
        return _map(groupedByDateData, (item, key) => {
          const totalQuantity = _reduce(item, (sum, n) => sum + n.quantity, 0)
          return {
            date: key,
            quantity: totalQuantity,
          }
        })
    }
  }

  const content = Object.values(promoterSalesData)
    .filter(
      promoter =>
        !(
          promoter.email.includes('@theticketfairy.com') &&
          _reduce(promoter.salesBreakdown, (sum, n) => sum + n.quantity, 0) === 0
        ),
    )
    .map((row, index) => {
      const isSalesByTicketTypeTab =
        selectedTab[row.promoterId] !== SALES_BY_DATE && selectedTab[row.promoterId] !== SALES_BY_TIME_SLOT
      const isSalesByTimeSlot =
        selectedTab[row.promoterId] !== SALES_BY_DATE && selectedTab[row.promoterId] !== SALES_BY_TCKET_TYPE
      const link = row.referralLink

      return (
        <div key={index} className="ticketholder_info">
          <TicketHolderCardHeader row={row} link={link} event={event} />
          {_isEmpty(row.salesBreakdown) ? (
            <EmptyBar />
          ) : (
            <div>
              <RadioGroup
                name="fruit"
                selectedValue={
                  selectedTab[row.promoterId] || (isTimeSlotEvent ? SALES_BY_TIME_SLOT : SALES_BY_TCKET_TYPE)
                }
                onChange={value => onChangeShowGroupsByDate(value, row)}
              >
                {isTimeSlotEvent && (
                  <Radio name="salesByTimeSlots" value="salesByTimeSlots" label="Sales by Time Slot" />
                )}
                <Radio name={SALES_BY_TCKET_TYPE} value={SALES_BY_TCKET_TYPE} label="Sales by Ticket Type" />
                <Radio name={SALES_BY_DATE} value={SALES_BY_DATE} label="Total Daily Sales" />
              </RadioGroup>

              {isTimeSlotEvent && isSalesByTimeSlot ? (
                <SalesByTimeSlots
                  event={event}
                  data={row.salesBreakdown}
                  groupedByTimeSlots={getGroupedData(row.salesBreakdown, SALES_BY_TIME_SLOT)}
                />
              ) : isSalesByTicketTypeTab ? (
                <SortableTable
                  data={getGroupedData(row.salesBreakdown, SALES_BY_TCKET_TYPE)}
                  tableColumns={byTicketTypeColumns}
                  enableSort={true}
                  enableCopyTable={true}
                  disableMobileView={true}
                  sortBy={{ column: 'ticketTypeName', asc: true }}
                  footbar={{
                    label: 'Total',
                    columns: footbarColumns,
                  }}
                />
              ) : (
                <div>
                  <SortableTable
                    data={getGroupedData(row.salesBreakdown, SALES_BY_DATE)}
                    tableColumns={byDateColumns}
                    enableSort={true}
                    enableCopyTable={true}
                    disableMobileView={true}
                    sortBy={{ column: 'date', asc: true }}
                    footbar={{
                      label: 'Total',
                      columns: footbarColumns,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )
    })

  return (
    <>
      <div className="table-title second-title">Individual Promoter Performance</div>
      {content.length ? content : <EmptyBar />}
    </>
  )
}

export default IndividualPromoter
