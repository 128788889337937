import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import SortableTable from '../../../../_library/SortableTable'
import { getTableColumns } from '../../../../utils/sortableTableUtils'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator
} from '../../../../../_common/core/validation/normalizers'
import EmptyBar from '../../../../_library/EmptyBar'
import LoadingBar from '../../../../_library/LoadingBar'

export default class SalesByTicketType extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns(
      [
        {
          key: 'release_type',
          label: 'Ticket Type'
        },
        {
          key: 'num_sales',
          label: 'No. of Sales'
        },
        {
          key: 'stock',
          label: 'Stock'
        },
        {
          key: 'price',
          label: 'Price (incl. Fees)',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'revenueTotal',
          label: 'Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        }
      ],
      getCurrencySymbol(props.event)
    )

    this.detailsTableColumns = getTableColumns([
      {
        key: 'orderDate',
        label: 'Order Date',
        className: 'detail-cell',
        normalizer: formatDay
      },
      {
        key: 'num_sales',
        label: 'No. of Tickets Sold',
        className: 'detail-cell'
      },
      {
        key: 'totalSales',
        label: 'Running Total (No. of Tickets Sold)',
        className: 'detail-cell',
        isSortable: false
      },
      {
        key: 'income',
        label: 'Completed Payments',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'expectedIncome',
        label: 'Expected Payments',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ])

    this.footbarColumns = [
      'num_sales',
      {
        key: 'revenueTotal',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]

    this.state = {
      rows: null,
      detailsRows: []
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { groupedByTicketType, tickets } = this.props
    if (
      !_isEqual(groupedByTicketType, prevProps.groupedByTicketType) ||
      !_isEqual(tickets, prevProps.tickets)
    ) {
      this.updateData()
    }
  }

  updateData = () => {
    const { data, groupedByTicketType, tickets } = this.props
    const tempRows = []
    const detailsRows = []

    _map(groupedByTicketType, (value, key) => {
      const ticketId = value[0].option_matrix_record_id
      const currentTicket = _find(tickets, item => item.id == ticketId) || { stock: 0 }
      const id = value[0].price + (value[0].slotGroupId || value[0].option_matrix_record_id)
      const totalNumSales = this.getTotalNumSales(value)
      this.getDetailRows(value, id, detailsRows)

      tempRows.push({
        id,
        release_type: value[0].slotGroupName || value[0].release_type,
        num_sales: totalNumSales,
        price: value[0].price,
        revenueTotal: this.calculateRevenue(value),
        stock: parseInt(currentTicket.stock)
      })
    })

    this.setState({
      rows: _filter(tempRows, rowItem => !!rowItem.id),
      detailsRows
    })
  }

  getTotalNumSales = (ticketsArray = []) => {
    let totalSales = 0
    _map(ticketsArray, item => {
      totalSales += parseInt(item.num_sales)
    })

    return totalSales
  }

  calculateRevenue = (ticketsArray = []) => {
    let totalRevenue = 0
    _map(ticketsArray, item => {
      totalRevenue += parseInt(item.num_sales) * parseFloat(item.price) - parseFloat(item.refunded_amount)
    })

    return totalRevenue
  }

  getDetailRows = (data, groupId, detailsRows) => {
    let totalSales = 0
    let totalRevenue = 0
    const detailRows = []

    _map(data, item => {
      const id = item.price + (item.slotGroupId || item.option_matrix_record_id)
      totalSales += parseInt(item.num_sales)
      totalRevenue += parseFloat(item.num_sales) * parseFloat(item.price)
      detailRows.push({
        id,
        slotGroupId: item.slotGroupId,
        orderDate: item.orderDate,
        num_sales: item.num_sales,
        totalSales,
        price: item.price,
        revenue: parseFloat(item.num_sales) * parseFloat(item.price),
        revenueTotal: totalRevenue,
        release_type: item.slotGroupName || item.release_type,
        income: item.income,
        expectedIncome: item.expectedIncome
      })
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
  }

  render() {
    const { rows, detailsRows } = this.state
    const { ticketTypeDataLoading, ticketTypeDataLoaded, data } = this.props
    if (ticketTypeDataLoading && !ticketTypeDataLoaded) {
      return <LoadingBar />
    }
    if (data && !data.length) {
      return <EmptyBar />
    }
    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            detailsRows={detailsRows}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        ) : null}
      </div>
    )
  }
}
