import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import Select from 'react-select'
import Button from './Button'
import TagsField from './TagsField'

class Filters extends Component {
  constructor(props) {
    super(props)
    const selected = {}
    _map(props.filters, filter => {
      selected[filter.type] = {
        value: null,
        label: filter.label,
        isNotChecked: false,
      }
    })

    this.state = {
      currentFilter: [],
      selected,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== this.props.value) {
      this.setState({ currentFilter: nextProps.value })
    }
  }

  handleChange = (type, val) => {
    const { selected } = this.state
    selected[type].value = (val && val.value) || null
    this.setState({
      selected,
    })
  }

  onNotChecked = (type, checked) => {
    const { selected } = this.state
    selected[type].isNotChecked = checked
    this.setState({
      selected,
    })
  }

  onOperationClick = (type, isAnd) => {
    const { selected, currentFilter } = this.state
    currentFilter.push({
      isAnd,
      type,
      label: selected[type].label,
      value: selected[type].value,
      isNot: selected[type].isNotChecked,
    })
    this.setState({
      currentFilter,
    })
    this.props.onFiltersChange(currentFilter)
  }

  onTagsChange = (tags, changed, changedIndexes) => {
    const { currentFilter } = this.state
    if (currentFilter.length > tags.length) {
      // remove filter
      currentFilter.splice(changedIndexes[0], 1)
      this.setState({ currentFilter })
      this.props.onFiltersChange(currentFilter)
    }
  }

  render() {
    const { filters, children, className } = this.props
    const { currentFilter, selected } = this.state

    const isInitial = currentFilter.length === 0
    const tags = _map(currentFilter, (filter, index) => {
      let item = ''
      item += index !== 0 ? (filter.isAnd ? '(AND) ' : '(OR) ') : ''
      item += `${filter.label} ${filter.isNot ? '<>' : '='} '${filter.value}'`
      return item
    })

    return (
      <div className={`common-filters ${className || ''}`}>
        <div className="f-body flex-align">
          {_map(filters, (filter, index) => (
            <div key={index} className="f-row">
              <div className="f-dropdown">
                <div className="f-label">{filter.label}</div>
                <Select
                  onChange={evt => this.handleChange(filter.type, evt)}
                  options={filter.options}
                  value={selected[filter.type].value}
                />
              </div>
              <div className="f-checkbox">
                <div className="checkbox-group-square">
                  <div className="checkbox-square">
                    <input
                      id={`chkNegative_${index}`}
                      type="checkbox"
                      disabled={!selected[filter.type].value}
                      checked={selected[filter.type].isNotChecked}
                      onChange={evt => this.onNotChecked(filter.type, evt.target.checked)}
                    />
                    <label htmlFor={`chkNegative_${index}`} />
                  </div>
                </div>
                <div className="checkbox-description">Not</div>
              </div>
              {isInitial && (
                <Button
                  className="btn btn-primary"
                  type="button"
                  disabled={!selected[filter.type].value}
                  onClick={() => this.onOperationClick(filter.type, true)}
                >
                  Add
                </Button>
              )}
              {!isInitial && (
                <Button
                  className="btn btn-primary"
                  type="button"
                  disabled={!selected[filter.type].value}
                  onClick={() => this.onOperationClick(filter.type, true)}
                >
                  And
                </Button>
              )}
              {!isInitial && (
                <Button
                  className="btn btn-default"
                  type="button"
                  disabled={!selected[filter.type].value}
                  onClick={() => this.onOperationClick(filter.type, false)}
                >
                  Or
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className="f-output">
          <TagsField
            value={tags}
            onChange={(tags, changed, changedIndexes) => this.onTagsChange(tags, changed, changedIndexes)}
            controlOutside
            placeholder={' '}
          />
        </div>
        {children}
      </div>
    )
  }
}

Filters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired, // [{label:string, type: string, options:[{label:string, value: string}]}]
}

Filters.defaultProps = {
  filters: [],
}

export default Filters
