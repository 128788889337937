import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import EventOrderNoteForm from '../order/NoteForm'
import { get_event_orders_state } from '../../../_common/core/selectors'
import { REFRESH_ORDERS_DATA } from '../../../_common/redux/orders/actions'
import { connect } from 'react-redux'
import { showAxiosError, showSuccessMessage } from '../../utils/messenger'
import { createNote, deleteNote, editNote, getAllNotes } from '../../../_common/core/http_services'
import { DISPLAY_FORMAT } from '../../constants/timeFormats'

export const NotesRowsRedux = ({ orderID, ordersState, REFRESH_ORDERS_DATA }) => {
  const [isNotesLoading, setIsNotesLoading] = useState(false)
  const [noteEditModalShow, setNoteEditModalShow] = useState(false)
  const [noteEdited, setNoteEdited] = useState(null)
  const [notes, setNotes] = useState([])
  useEffect(() => {
    handleLoadNotes()
  }, [])
  const onClickEditNote = note => {
    if (isNotesLoading) return
    setNoteEdited(note)
    setNoteEditModalShow(true)
  }
  const onClickDeleteNote = noteID => {
    if (isNotesLoading) return
    handleDeleteNote(noteID, orderID)
  }
  const onClickAddNote = () => {
    if (isNotesLoading) return
    setNoteEdited(null)
    setNoteEditModalShow(true)
  }
  const closeNoteEditModal = () => {
    setNoteEditModalShow(false)
  }
  const addSearchNoteToOrder = (res = {}) => {
    const { orderId, note } = res.data
    if(orderId) {
      const data = ordersState.orders.map(item => {
        const { id } = item.order

        if(orderId === id) {
          const newObj = {
            ...item,
          }
          newObj.order.searchOrderNotes = [...newObj.order.searchOrderNotes, note]
          return newObj
        }
        return item
      })
      REFRESH_ORDERS_DATA(data)
    }
  }
  const handleNoteSave = async (form, isCreate) => {
    try {
      setIsNotesLoading(true)
      setNoteEditModalShow(false)
      const noteID = noteEdited && noteEdited.id
      const { note } = form
      const res = await (isCreate ? createNote(orderID, note) : editNote(noteID, note))
      addSearchNoteToOrder(res)
      showSuccessMessage(isCreate ? 'Successfully added' : 'Successfully updated')
      handleLoadNotes()
    } catch(e) {
      showAxiosError(e)
    }
  }
  const handleLoadNotes = async () => {
    try {
      setIsNotesLoading(true)
      const notes = await getAllNotes(orderID)
      setIsNotesLoading(false)
      setNotes(_orderBy(notes, n => n.createdAt, 'desc'))
    } catch(e) {
      setIsNotesLoading(false)
      showAxiosError(e)
    }
  }
  const handleDeleteNote = async (noteID, orderId) => {
    try {
      setIsNotesLoading(true)
      await deleteNote(noteID)
      showSuccessMessage('Successfully removed')
      handleLoadNotes(orderId)
    } catch(e) {
      showAxiosError(e)
    }
  }
  const note_rows = _map(notes, (note, nid) => (
    <div key={nid}>
      <div className={'row-content row-notes row note-bg'}>
        <div className="col-xs-4">
          <div className="content">
            <div className="value" style={{ display: 'flex' }}>
              <i
                className="fa fa-edit"
                aria-hidden="true"
                onClick={() => onClickEditNote(note)}
              />
                &nbsp;&nbsp;
              <i
                className="fa fa-trash"
                aria-hidden="true"
                onClick={() => onClickDeleteNote(note.id, orderID)}
              />
                &nbsp;&nbsp;&nbsp;&nbsp;
              <div>{note.note}</div>
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="content">
            <div className="value">{moment(note.createdAt).format(DISPLAY_FORMAT)}</div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="value">{note.name}</div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="content">
            <div className="value">{note.email}</div>
          </div>
        </div>
      </div>
    </div>
  ))
  return (
    <>
      <div className="row-title row">
        <div className="col-xs-12 withDecorator">
            Notes &nbsp;&nbsp;&nbsp;
          {isNotesLoading && <i className="fa fa-spin fa-spinner" aria-hidden="true" />}
        </div>
      </div>
      <div className="div-spacing-20" />
      <div className="row-content row">
        <div className="col-xs-12">
          <div className="content">
            <div className="value">
              <div className="addNote" onClick={onClickAddNote}>
                <i className="fa fa-plus" aria-hidden="true" />&nbsp;&nbsp;Add Note
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row-content row-notes row">
        <div className="col-xs-4">
          <div className="content">
            <div className="field">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Note
            </div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="content">
            <div className="field">Date Added</div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Created By</div>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="content">
            <div className="field">Creator Email</div>
          </div>
        </div>
      </div>
      <div>{note_rows}</div>
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={noteEditModalShow}
        contentLabel="Modal"
        onRequestClose={closeNoteEditModal}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-event-order-ticket">
          <div className="modal-content">
            <div className="modal-header">{!noteEdited ? 'Create' : 'Edit'} Note</div>
            <div className="modal-body">
              <EventOrderNoteForm
                onSubmit={form => handleNoteSave(form, !noteEdited)}
                onCancel={closeNoteEditModal}
                submitLabel="Save"
                initialValues={noteEdited}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const NotesRows = connect(
  state => {
    const ordersState = get_event_orders_state(state)
    return {
      ordersState,
    }
  },
  {
    REFRESH_ORDERS_DATA
  }
)(NotesRowsRedux)