import React from 'react'
import Modal from 'react-modal'
import Button from '../../_library/Button'
import modalStyle from '../../../_common/core/modalStyle'
import { Formik, Form, Field } from 'formik'
import { SimpleField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'

const FieldComponent = ({ note, onSubmit }) => {
  return (
    <Formik initialValues={{ note: note }} onSubmit={onSubmit}>
      <Form id="noteActionForm">
        <Field
          name="note"
          label="Note"
          placeholder="Note"
          validate={requiredValidator()}
          component={SimpleField}
        />
      </Form>
    </Formik>
  )
}

const actionTypesConfig = onSubmit => ({
  create: {
    title: 'Create Note',
    buttonText: 'Save',
    buttonClass: 'btn btn-primary',
    onSubmit: null,
    content: FieldComponent
  },
  edit: {
    title: 'Edit Note',
    buttonText: 'Save',
    buttonClass: 'btn btn-primary',
    onSubmit: null,
    content: FieldComponent
  },
  delete: {
    title: 'Delete Note',
    buttonText: 'Delete',
    buttonClass: 'btn btn-danger',
    onSubmit: onSubmit,
    content: () => <p>Are you sure you want to delete this note?</p>
  }
})

const SupportNotesModal = ({
  actionType = 'create',
  data: { note = '' } = {},
  loading = false,
  onSubmit = () => {},
  onClose = () => {}
}) => {
  const actionData = actionTypesConfig(onSubmit)[actionType]
  const Content = actionData['content']
  return (
    <Modal
      className="modal-dialog modal-trans"
      style={modalStyle}
      isOpen={true}
      contentLabel="Modal"
      onRequestClose={onClose}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h4 text-compact">{actionData['title']}</p>
          </div>
          <div className="modal-body">
            <Content note={note} onSubmit={onSubmit} />
          </div>
          <div className="modal-footer">
            <div className="btn-toolbar btn-toolbar-right">
              <Button
                type="submit"
                form="noteActionForm"
                className={actionData['buttonClass']}
                loading={loading}
                onClick={actionData['onSubmit']}
              >
                {actionData['buttonText']}
              </Button>
              <Button className="btn btn-default" type="button" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SupportNotesModal
