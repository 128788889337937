import React, {
  useEffect,
  useState,
} from 'react'
import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import _isEmpty from 'lodash/isEmpty'
import _keys from 'lodash/keys'
import _values from 'lodash/values'

import { Chart } from '../../_library/Chart'
import EmptyBar from '../../_library/EmptyBar'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'

const AgeBreakdown = React.memo((props) => {
  const { performance, loading } = props
  const [data, setData] = useState({})

  const age_breakdown = _get(performance, 'age_breakdown')

  useEffect(() => {
    if (age_breakdown) {
      const { data, age_min, age_max } = _reduce(age_breakdown, (acc, age) => {
        acc.data[age.age] = parseInt(age.number)
        const _age = parseInt(age.age)
        if (_age < acc.age_min) {
          acc.age_min = _age
        }
        if (_age > acc.age_max) {
          acc.age_max = _age
        }
        return acc
      }, { data: {}, age_min: 0, age_max: 0 })

      let current_age = age_min
      while (current_age < age_max) {
        if(!data[current_age]) {
          data[current_age] = 0
        }
        current_age++
      }

      setData(data)
    }
  }, [performance])

  let content = null

  if (loading) {
    content = <LoadingBar />
  } else if (_isEmpty(age_breakdown)) {
    content = <EmptyBar />
  } else {
    content = (
      <Chart
        width={'100%'}
        height='400px'
        chartConfig = {{
          type: 'bar',
          data: {
            labels: _keys(data),
            datasets: [{
              data: _values(data),
            }]
          },
          options: {
            scales: {
              xAxes: [{
                ticks: {
                  fontSize: 9
                }
              }],
            },
            legend: {
              display: false
            }
          }
        }}
      />
    )
  }



  return (
    <Card icon='fa-sliders' title='Age Breakdown'>
      <div className="card-block-title">
        <div className="highlight">
          Tickets buyers by<strong className="left-space">AGE</strong>
        </div>
      </div>
      {content}
    </Card>
  )
})

export { AgeBreakdown }
