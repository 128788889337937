import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import { FETCH_AUDIENCE } from '../../_common/redux/audience/actions'
import { FETCH_EVENT_DEMOGRAPHICS } from '../../_common/redux/performance/actions'
import { GenderSection, AgeBreakdown, AgeRanges, Professions } from './demographics'

import {
  get_event,
  get_performance,
  get_event_audience_gender,
  get_event_audience_psychographics
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

const state_values = {
  1: 'status_audience_gender',
  2: 'status_audience_psychographics',
  3: 'status_performance_demographics'
}

const STATE_STATUS_LOADING = 1
const STATE_STATUS_SUCCESSED = 2
const STATE_STATUS_FAILED = 3

@connect(
  state => {
    const event = get_event(state)
    const performance = get_performance(state)
    const audience_gender = get_event_audience_gender(state)
    const audience_psychographics = get_event_audience_psychographics(state)
    
    return {
      event,
      performance,
      audience_gender,
      audience_psychographics,
    }
  },
  {
    FETCH_AUDIENCE,
    FETCH_EVENT_DEMOGRAPHICS
  }
)
export default class EventAudienceDemographics extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      status_audience_gender: STATE_STATUS_LOADING,
      status_audience_psychographics: STATE_STATUS_LOADING,
      status_performance_demographics: STATE_STATUS_LOADING
    }
  }
  componentDidMount() {
    const { event: { id, displayName }, FETCH_AUDIENCE, FETCH_EVENT_DEMOGRAPHICS, configs } = this.props

    const loadingSetter = (type, val) => () => {
      this.setState({ [state_values[type]]: val })
    }

    // audience_gender
    FETCH_AUDIENCE(id, 'event', 'gender')
      .then(loadingSetter(1, STATE_STATUS_SUCCESSED))
      .catch(loadingSetter(1, STATE_STATUS_FAILED))
    // audience_psychographics
    FETCH_AUDIENCE(id, 'event', 'psychographics')
      .then(loadingSetter(2, STATE_STATUS_SUCCESSED))
      .catch(loadingSetter(2, STATE_STATUS_FAILED))
    // performance_demographics
    FETCH_EVENT_DEMOGRAPHICS(id)
      .then(loadingSetter(3, STATE_STATUS_SUCCESSED))
      .catch(loadingSetter(3, STATE_STATUS_FAILED))

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  render() {
    const { status_audience_gender, status_audience_psychographics, status_performance_demographics } =
      this.state
    const { audience_gender, audience_psychographics, performance } = this.props

    return (
      <div className="demographics">
        <GenderSection
          loading={status_audience_gender === STATE_STATUS_LOADING}
          audience_gender={audience_gender}
        />
        <AgeBreakdown
          loading={status_performance_demographics === STATE_STATUS_LOADING}
          performance={performance}
        />

        <AgeRanges
          loading={status_performance_demographics === STATE_STATUS_LOADING}
          performance={performance}
        />

        <Professions
          loading={status_audience_psychographics === STATE_STATUS_LOADING}
          audience_psychographics={audience_psychographics}
        />
      </div>
    )
  }
}
