import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_MEMBERSHIPS } = createAsyncAction('FETCH_MEMBERSHIPS', function (eventId) {
  return dispatch =>
    fetchAPI(`/api/event/${eventId}/membership-details`, {
      method: 'GET',
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { eventId, memberships: res }
        dispatch(this.success(out))
        return res
      })
})

const { FETCH_MEMBERSHIP_ITEMS } = createAsyncAction(
  'FETCH_MEMBERSHIP_ITEMS',
  function (eventId, membershipPlanId) {
    return dispatch =>
      fetchAPI(`/api/event/${eventId}/plan/${membershipPlanId}/tickets`, {
        method: 'GET',
        withCredentials: true,
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const out = { eventId, membershipItems: res }
          dispatch(this.success(out))
          return res
        })
  },
)

const { FETCH_MEMBERSHIP_LIST } = createAsyncAction('FETCH_MEMBERSHIP_LIST', function (eventId) {
  return dispatch =>
    fetchAPI(`/api/event/${eventId}/membership-list`, {
      method: 'GET',
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { eventId, membershipList: res.data.$original.attributes }
        dispatch(this.success(out))
        return res
      })
})

const { ADD_MEMBERSHIP_VISIT } = createAsyncAction(
  'ADD_MEMBERSHIP_VISIT',
  function (eventId, membershipPlanId, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eventId}/membership/plan/${membershipPlanId}/visit`, {
        method: 'POST',
        body: JSON.stringify(data),
        withCredentials: true,
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const out = { eventId, visitAdded: res }
          dispatch(this.success(out))
          return res
        })
  },
)

const { FETCH_MEMBERSHIP_VISITS } = createAsyncAction(
  'FETCH_MEMBERSHIP_VISITS',
  function (eventId, membershipPlanId) {
    return dispatch =>
      fetchAPI(`/api/event/${eventId}/plan/${membershipPlanId}/visits`, {
        method: 'GET',
        withCredentials: true,
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const out = { eventId, membershipVisits: res }
          dispatch(this.success(out))
          return res
        })
  },
)

const { UPDATE_MEMBERSHIP_VISIT } = createAsyncAction(
  'UPDATE_MEMBERSHIP_VISIT',
  function (eventId, membershipPlanId, visitedTicketId, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eventId}/membership/plan/${membershipPlanId}/visit/${visitedTicketId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        withCredentials: true,
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const out = { eventId, visitUpdated: res }
          dispatch(this.success(out))
          return res
        })
  },
)

const { FETCH_MEMBERSHIPS_STATISTICS } = createAsyncAction('FETCH_MEMBERSHIPS_STATISTICS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/performance/`, {
      params: { section: 'membership' },
      node: 'data.membership.*',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const data = res.data.$original.membership
        const memberships = []
        for (const m in data) {
          let tickets = []
          let totalFreeAdmission = 0
          const users = data[m].map((e, i) => {
            if (i === 0) {
              tickets = e.base.map(el => ({
                name: el.ticketTypeGroupName,
                quantity: el.quantity,
              }))
              totalFreeAdmission = e.guests.reduce((sum, el) => sum + el.quantity, 0)
            }
            const remainingQuantitys = e.base.map(el => el.remainingQuantity)
            const freeAdmission = e.guests.reduce((sum, el) => sum + el.remainingQuantity, 0)
            return {
              userName: e.userName,
              expirationDate: e.expirationDate,
              remainingQuantitys,
              freeAdmission,
            }
          })

          const membership = {
            membershipName: m,
            totalFreeAdmission,
            tickets,
            users,
          }

          memberships.push(membership)
        }

        dispatch(this.success({ eid, memberships }))
        return res
      })
})

const { CREATE_MEMBERSHIP } = createAsyncAction('CREATE_MEMBERSHIP', function (data) {
  return dispatch =>
    fetchAPI(`/api/membership`, {
      method: 'POST',
      body: JSON.stringify(data),
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_MEMBERSHIP } = createAsyncAction('UPDATE_MEMBERSHIP', function (data, id) {
  return dispatch =>
    fetchAPI(`/api/membership/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { GET_MEMBERSHIP } = createAsyncAction('GET_MEMBERSHIP', function (id) {
  return dispatch =>
    fetchAPI(`/api/membership/${id}`, {
      method: 'GET',
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export {
  FETCH_MEMBERSHIPS,
  FETCH_MEMBERSHIP_ITEMS,
  FETCH_MEMBERSHIP_LIST,
  ADD_MEMBERSHIP_VISIT,
  FETCH_MEMBERSHIP_VISITS,
  UPDATE_MEMBERSHIP_VISIT,
  FETCH_MEMBERSHIPS_STATISTICS,
  CREATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  GET_MEMBERSHIP,
}
