import React, { Component } from 'react'
import Select from 'react-select'
import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import _intersectionBy from 'lodash/intersectionBy'
import _uniqBy from 'lodash/uniqBy'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import { TIME_DISPLAY_FORMAT, DAY_DISPLAY_FORMAT, FORMAT } from '../../../constants/timeFormats'
import { options } from 'less'

export const dataSort = (arr = []) => _sortBy(arr, ['value'])
export const dateTimeSort = (arr = [], format = FORMAT) =>
  arr.sort((a, b) => moment(a.label, format).valueOf() - moment(b.label, format).valueOf())

const groupTicketsByDate = tickets => {
  const groupedByDates = []
  const groupedByDateMap = {}
  tickets.forEach(el => {
    const p = groupedByDateMap[el.date]
    groupedByDateMap[el.date] = p ? [...p, el] : [el]
  })
  for (const key in groupedByDateMap) {
    const g = groupedByDateMap[key]
    g.date = key
    g.groupId = g[0].slotGroupId
    groupedByDates.push(g)
  }

  return groupedByDates
}

const groupTicketsByTime = tickets => {
  const groupedByTimes = []
  const groupedByTimeMap = {}
  tickets.forEach(el => {
    const p = groupedByTimeMap[el.time]
    groupedByTimeMap[el.time] = p ? [...p, el] : [el]
  })
  for (const key in groupedByTimeMap) {
    const g = groupedByTimeMap[key]
    g.time = key
    g.groupId = g[0].slotGroupId
    groupedByTimes.push(g)
  }

  return groupedByTimes
}

const valuesConstants = {
  typeSelectValues: 'slotGroupName',
  dateSelectValues: 'date',
  timeSelectValues: 'time'
}

class SelectTimeSlotBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typeSelectValues: [],
      dateSelectValues: [],
      timeSelectValues: [],

      typeOptions: props.defaultOptionsList.typeOptions || [],
      dateOptions: props.defaultOptionsList.dateOptions || [],
      timeOptions: props.defaultOptionsList.timeOptions || []
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { onChange, id } = this.props

    const { typeSelectValues, dateSelectValues, timeSelectValues } = this.state

    if (
      typeSelectValues.length !== prevState.typeSelectValues.length ||
      dateSelectValues.length !== prevState.dateSelectValues.length ||
      timeSelectValues.length !== prevState.timeSelectValues.length
    ) {
      const values = [
        { values: typeSelectValues.map(item => item.value), key: 'slotGroupName' },
        { values: dateSelectValues.map(item => item.value), key: 'date' },
        { values: timeSelectValues.map(item => item.value), key: 'time' }
      ]
      onChange(values, id)
    }
  }

  getNewOptions = () => {
    const { timeSlotTickets, groupedTickets } = this.props
    const { typeSelectValues, dateSelectValues, timeSelectValues } = this.state
    const optionsList = {
      typeOptions: [],
      dateOptions: [],
      timeOptions: []
    }
    const groupedByDates = groupTicketsByDate(timeSlotTickets)
    const groupedByTimes = groupTicketsByTime(timeSlotTickets)

    const typesArr = _filter(groupedTickets, g => _findIndex(typeSelectValues || [], { value: g.groupName }) >= 0)
    const datesArr = _filter(groupedByDates, g => _findIndex(dateSelectValues || [], { value: g.date }) >= 0)
    const timesArr = _filter(groupedByTimes, g => _findIndex(timeSelectValues || [], { value: g.time }) >= 0)

    if (dateSelectValues.length && typeSelectValues.length) {
      optionsList.timeOptions = _intersectionBy(...typesArr.concat(datesArr), i => i.time)
    } else if (dateSelectValues.length) {
      optionsList.timeOptions = _intersectionBy(...datesArr, i => i.time)
    } else if (typeSelectValues.length) {
      optionsList.timeOptions = _intersectionBy(...typesArr, i => i.time)
    } else {
      optionsList.timeOptions = timeSlotTickets
    }

    if (timeSelectValues.length && typeSelectValues.length) {
      optionsList.dateOptions = _intersectionBy(...typesArr.concat(timesArr), i => i.date)
    } else if (timeSelectValues.length) {
      optionsList.dateOptions = _intersectionBy(...timesArr, i => i.date)
    } else if (typeSelectValues.length) {
      optionsList.dateOptions = _intersectionBy(...typesArr, i => i.date)
    } else {
      optionsList.dateOptions = timeSlotTickets
    }

    if (timeSelectValues.length && dateSelectValues.length) {
      optionsList.typeOptions = _intersectionBy(...datesArr.concat(timesArr), i => i.slotGroupName)
    } else if (timeSelectValues.length) {
      optionsList.typeOptions = _intersectionBy(...timesArr, i => i.slotGroupName)
    } else if (dateSelectValues.length) {
      optionsList.typeOptions = _intersectionBy(...datesArr, i => i.slotGroupName)
    } else {
      optionsList.typeOptions = timeSlotTickets
    }

    return optionsList
  }

  getOptions = (arr, key) => {
    const options = []
    arr.forEach(item => {
      options.push({
        label: item[key],
        value: item[key],
        date: item.date,
        time: item.time,
        slotGroupName: item.slotGroupName
      })
    })
    return _uniqBy(options, 'value')
  }

  renderAllOptionsNew = (fromWhere, fromWhereValues) => {
    const { timeSlotTickets } = this.props
    const { typeSelectValues, dateSelectValues, timeSelectValues } = this.state
    const optionsList = this.getNewOptions()

    const selectedItems = _filter(timeSlotTickets, item => {
      const itemTime = item[valuesConstants.timeSelectValues]
      const itemDate = item[valuesConstants.dateSelectValues]
      const itemType = item[valuesConstants.typeSelectValues]

      const isTimeSelected =
        timeSelectValues && timeSelectValues.length
          ? _findIndex(timeSelectValues || [], { value: itemTime }) >= 0
          : true
      const isDateSelected =
        dateSelectValues && dateSelectValues.length
          ? _findIndex(dateSelectValues || [], { value: itemDate }) >= 0
          : true
      const isTypeSelected =
        typeSelectValues && typeSelectValues.length
          ? _findIndex(typeSelectValues || [], { value: itemType }) >= 0
          : true

      return isTimeSelected && isDateSelected && isTypeSelected
    })

    const newTimeOptions = this.getOptions(
      _filter(optionsList.timeOptions, t =>
        timeSelectValues.length ? _findIndex(timeSelectValues, { value: t.time }) < 0 : true
      ),
      valuesConstants.timeSelectValues
    )
    const newDateOptions = this.getOptions(
      _filter(optionsList.dateOptions, t =>
        dateSelectValues.length ? _findIndex(dateSelectValues, { value: t.date }) < 0 : true
      ),
      valuesConstants.dateSelectValues
    )
    const newTypeOptions = this.getOptions(
      _filter(optionsList.typeOptions, t =>
        typeSelectValues.length ? _findIndex(typeSelectValues, { value: t.type }) < 0 : true
      ),
      valuesConstants.typeSelectValues
    )

    this.setState(() => ({
      typeOptions: dataSort(newTypeOptions),
      dateOptions: dateTimeSort(newDateOptions, DAY_DISPLAY_FORMAT),
      timeOptions: dateTimeSort(newTimeOptions, TIME_DISPLAY_FORMAT)
    }))
  }

  onSelectChange = (e, selectName) => {
    this.setState({ [selectName]: e }, () => this.renderAllOptionsNew(selectName, e))
  }

  createSelects = () => {
    const { typeOptions, dateOptions, timeOptions, typeSelectValues, dateSelectValues, timeSelectValues } = this.state

    return (
      <div className="selects-block">
        <div className="select-item">
          <span style={{ fontSize: 12, fontWeight: "600" }}>
            Select by ticket type
          </span>
          <Select
            multi
            value={typeSelectValues}
            placeholder="Select"
            onChange={e => this.onSelectChange(e, 'typeSelectValues')}
            options={typeOptions}
            closeMenuOnSelect={false}
          />
        </div>
        <div className="select-item">
          <span style={{ fontSize: 12, fontWeight: "600" }}>
            Select by date
          </span>
          <Select
            multi
            value={dateSelectValues}
            placeholder="Select"
            onChange={e => this.onSelectChange(e, 'dateSelectValues')}
            options={dateOptions}
            closeMenuOnSelect={false}
          />
        </div>
        <div className="select-item">
          <span style={{ fontSize: 12, fontWeight: "600" }}>
            Select by time
          </span>
          <Select
            multi
            value={timeSelectValues}
            placeholder="Select"
            onChange={e => this.onSelectChange(e, 'timeSelectValues')}
            options={timeOptions}
            closeMenuOnSelect={false}
          />
        </div>
      </div>
    )
  }

  render() {
    const { componentsCount, positionIndex, id, addBlock, deleteBlock } = this.props

    return (
      <div className="select-time-slot-block">
        {this.createSelects()}
        <div className="buttons-block">
          {positionIndex !== 0 && (
            <span className="icon-trash tim-slot-icon" onClick={() => deleteBlock(id)}>
              <i className={`fa fa-trash`} />
            </span>
          )}
          {componentsCount === positionIndex && (
            <span className="icon-add tim-slot-icon" onClick={addBlock}>
              <i className={`fa fa-plus`} />
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default SelectTimeSlotBlock
