import React, { Component } from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'

export default class GiftCardDeleteModal extends Component {
  render() {
    const { isOpen, header, content, handleDelete, handleCancel, isDeleting } = this.props

    return (
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={handleCancel}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{header || 'Are you sure?'}</p>
              </div>
              <div className="modal-body">
                <p>{content || 'Are you sure you want to delete the gift certificate?'}</p>
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-danger"
                    type="button"
                    onClick={handleDelete}
                    loading={isDeleting}
                  >
                    {'Delete'}
                  </Button>
                  <Button className="btn btn-default" type="button" onClick={handleCancel}>
                    {'Cancel'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
