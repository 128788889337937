import _forEach from 'lodash/forEach'

export const DOWNLOAD_CSV = (csvData, csvFileName) => {
  if (csvData == null) return
  const filename = csvFileName
  const blob = new Blob([csvData], { type: 'text/csv' })
  const csvUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', csvUrl)
  link.setAttribute('download', filename)
  link.click()
}

export const getCSVData = (tableColumns, data) => {
  const collectedData = []
  const tableColumnsArr = []

  // Colect table columns array
  _forEach(tableColumns, tableColumn => {
    tableColumnsArr.push(tableColumn.label)
  })
  
  // Collect table data array
  _forEach(data, item => {
    const currData = []
    _forEach(tableColumns, column => {
      currData.push([item[column.key] ?? ''])
    })

    collectedData.push([].concat.apply([], currData))
  })

  // Collect final data
  collectedData.unshift(tableColumnsArr)

  return collectedData
}

export const convertToCSV = args => {
  let ctr

  const data = args.data || null
  if (data == null || !data.length) {
    return null
  }

  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'

  const keys = Object.keys(data[0])

  let result = ''

  data.forEach(item => {
    ctr = 0
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

const getLastSymbol = (index, dataLength, separateByComma) => index === dataLength - 1 ? '\n' : separateByComma ? ',' : '\t'

const getValue = (value, column) => column.normalizer ? column.normalizer(value) : value
