import React from 'react'
import moment from 'moment'
import AutoSlugField from '../../../../_library/AutoSlugField'
import Field from '../../../../_library/Field'
import CustomClipboardButton from '../../../../_library/CustomClipboardButton'
import { ENV } from '../../../../constants/env'
import _get from 'lodash/get'

const baseURL = `${ENV.API_CONSUMER}/event/`
const scriptText = values =>
  `<script src="${ENV.API_CONSUMER}/resources/js/widget.js?` +
  Math.round(Math.random() * 10000000000) +
  `"></script><div id="ttf-container" style="width: 100%"></div>
  <script>var widget = new TTFWidget(
  {event : '` +
  values.slug +
  `', compact : 1});</script>`

export default class UrlRow extends React.Component {
  handleChangeSlug = e => {
    const { updateField } = this.props
    const str = e.target ? e.target.value : e
    updateField('slug', str)
  }

  render() {
    const { values, errors, touched, isNew, configs } = this.props
    const slugSuggestion = !isNew
      ? values.slug
      : !values.startDate
      ? values.displayName
      : (values.displayName || '') + `-` + moment.utc(values.startDate).format('DMMMYYYY')

    const showEmbedCode = _get(configs, 'appearance.showEmbedCodeButton')

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="autoslugfield-label">
            Event URL
            <CustomClipboardButton
              id="eventUrlCopied"
              clipboardText={baseURL + values.slug}
              tooltipBeforeCopyText="Copy Event URL"
              icon="fa-clipboard"
            />
            {showEmbedCode && (
              <CustomClipboardButton
                id="embedCodeCopied"
                clipboardText={scriptText(values)}
                tooltipBeforeCopyText="Copy Embed Code"
                icon="fa-code"
              />
            )}
          </div>
          <AutoSlugField
            id="eventSlug"
            label={'Link URL'}
            separator={'-'}
            hint="Letters, numbers and hyphens only"
            suggestion={slugSuggestion}
            value={values.slug}
            error={errors.slug}
            touched={touched.slug}
            onChange={this.handleChangeSlug}
          >
            <Field.PrefixAddon className="link-prefix hidden-xs">
              <img className="link-prefix-img" src={asset('/resources/images/event-url.png')} />
              <div className="link-prefix-url">{baseURL}</div>
            </Field.PrefixAddon>
          </AutoSlugField>
        </div>
      </div>
    )
  }
}
