import { baseRequest } from '.'
import _get from 'lodash/get'

export const getStripeCountries = async (params = { filter: 'stripe' }) => {
  const res = await baseRequest.get(`countries/`, { params })
  return _get(res, 'data.data')
}

export const getStripeCountryStates = async id => {
  const res = await baseRequest.get(`countries/${id}/states/`)
  return _get(res, 'data.data')
}
