import _result from 'lodash/result'
import _get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { UPDATE } from '../../_common/redux/auth/actions'
import AccountSettingsForm from './form/AccountSettingsForm'
import IDVerificationForm from './form/IDVerificationForm'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const { user } = state.auth

    return { user }
  },
  { UPDATE }
)
export default class AccountSettingsPage extends React.PureComponent {
  componentDidMount() {
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top',
      theme: 'future'
    }
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  handleSubmit = form => {
    if (!form.attributes.password || form.attributes.password === '') {
      delete form.attributes.password
      delete form.attributes.confirmPassword
    }
    const {
      attributes: { confirmEmail, ...rest }
    } = form

    const { UPDATE } = this.props
    return UPDATE({ ...form, attributes: { ...rest } })
      .catch(err => {
        const error_message = _get(err, 'errors.0.details', _get(err, 'message'))
        Messenger().post({
          type: 'error',
          message: error_message,
          hideAfter: 3,
          showCloseButton: true
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully updated!',
          hideAfter: 3,
          showCloseButton: true
        })
        return v
      })
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    return (
      <div className="body-main">
        <div className="body-panel-header">
          <div className="left">
            <div className="title">Account Settings</div>
          </div>
          <div className="right" />
          <div className="right" />
        </div>
        <div className="body-panel-spacing" />
        <div className="body-panel-content">
          <AccountSettingsForm user={user} onSubmit={this.handleSubmit} />
          <IDVerificationForm user={user} />
        </div>
      </div>
    )
  }
}
