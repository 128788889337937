export const getTourMenuItems = ({ path, itemId }) => {
  const menuGroup = []
  menuGroup.push({
    img: 'details',
    title: 'Details',
    href: `/tour/${itemId}/details`,
    active: path === `/tour/${itemId}/details`,
    visible: true
  })
  menuGroup.push({
    img: 'performance',
    title: 'Performance',
    href: `/tour/${itemId}/performance`,
    active: path === `/tour/${itemId}/performance`,
    visible: true
  })

  return menuGroup
}
