import _identity from 'lodash/identity'
import React from 'react'
import Select from 'react-select'

export default class ReactSelect extends React.Component {
  handleChange = e => {
    const { onChange = _identity, fieldKey } = this.props
    onChange(e, fieldKey)
  }

  handleInputChange = value => {
    const { onInputChange = _identity, fieldKey } = this.props
    onInputChange(value, fieldKey)
  }

  render() {
    const { id, label, error, fieldGroupStyle, touched, ...field } = this.props
    return (
      <div className={'form-group ' + (error ? 'has-error' : '')}>
        <div className="floating-field-group active" style={fieldGroupStyle}>
          <div className="floating-field" style={{ display: 'contents' }}>
            {!!label && (
              <label className="control-label" htmlFor={id}>
                {label}
              </label>
            )}
            <Select
              id={id}
              className="form-control auto-height"
              onChange={this.handleChange}
              onInputChange={this.handleInputChange}
              {...field}
            />
          </div>
        </div>
        {!!error && touched && <div className="help-block">{error}</div>}
      </div>
    )
  }
}
