import React from 'react'
import _map from 'lodash/map'

const RANGE_30 = new Array(30)

export default class AgeRow extends React.Component {
  handleChangeMinimumAge = e => {
    const { updateField } = this.props
    const { value } = e.target
    updateField('minimumAge', value)
  }

  render() {
    const { values } = this.props
    return (
      <div className="row">
        <div className="col-xs-6 col-12">
          <div className={'form-group'}>
            <div className="floating-field-group active">
              <div className="floating-field">
                <label className="control-label" htmlFor="minimumAge">Minimum age</label>
                <select
                  id="minimumAge"
                  className="form-control"
                  value={values.minimumAge}
                  onChange={this.handleChangeMinimumAge}
                >
                  <option value={0}>All Ages</option>
                  {_map(RANGE_30, (e, i) => (
                    <option key={30 - i} value={30 - i}>
                      {30 - i}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
