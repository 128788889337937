import React from 'react'
import { Formik, Form, Field } from 'formik'
import { SimpleField, CustomToggleField } from '../../formik/Fields'
import { toInt, requiredValidator } from '../../../_common/core/validation'
import Button from '../../_library/Button'
import _isNaN from 'lodash/isNaN'
import _set from 'lodash/set'
import _get from 'lodash/get'

const AddOnVariantForm = ({ stockLabel, handleSubmitForm = () => {} }) => (
  <Formik
    initialValues={{
      name: '',
      stock: '',
      isUnlimited: false,
    }}
    validate={data => {
      const errors = {}
      const stock = _get(data, 'stock')
      if (_isNaN(stock) || stock < 0) {
        _set(errors, 'stock', 'Value must be greater than or equal to 0')
      }
      return errors
    }}
    onSubmit={(values, { resetForm }) => {
      const stock = values.isUnlimited ? null : values.stock
      handleSubmitForm({ ...values, stock })
      resetForm()
    }}
    render={({ values }) => (
      <Form autoComplete="off" className="form_holder">
        <div className="row">
          <div className="col-xs-6">
            <Field name="name" label={'Name'} component={SimpleField} validate={requiredValidator()} />
          </div>
          {!values.isUnlimited && (
            <div className="col-xs-6">
              <Field
                name="stock"
                label={stockLabel}
                component={SimpleField}
                normalizer={toInt}
                validate={requiredValidator()}
              />
            </div>
          )}
          <div className="col-xs-12 col-sm-12" style={{ margin: '20px 0' }}>
            <Field name="isUnlimited" onText="Unlimited" component={CustomToggleField} />
          </div>
          <div className="col-xs-12 col-sm-12" style={{ margin: '20px 0' }}>
            <Button className="btn btn-success btn-shadow">
              <i className="fa fa-fw fa-plus" />
              Add
            </Button>
          </div>
        </div>
      </Form>
    )}
  />
)

export default AddOnVariantForm
