import React from 'react'
import _uniq from 'lodash/uniq'

// components
import Card from '../_library/Card'
import {
  ConnectToShopify,
  DisconnectShopify,
  ProductsAddedList,
  AddNewProducts,
  RefreshProductsBtn,
} from '.'


const ShopifyCard = React.memo((props) => {
  const {
    title,
    id,
    shopifyInfo,
    shopifyConnectLoading,
    shopifyDisconnectLoading,
    shopifyRefreshLoading,
    fetchProductsLoading,

    // functions
    onUpdate,
    onRefresh,
  } = props

  let {
    connectChecked,
    connected,
    allProducts,
    addedIds,
    isFetch,
    shopifyCurrentId,
  } = shopifyInfo
  addedIds = _uniq(addedIds)

  const onSubmit = (url) => {
    props.onSubmit(url)
  }

  const onDisconnectShopify = () => {
    props.onDisconnectShopify()
  }

  const onDeleteProduct = (id) => {
    const { onUpdate } = props
    return onUpdate(addedIds.filter(el => el !== id))
  }

  if (window.location.hash === '#shopify') {
    window.location.hash = ''
    setTimeout(() => {
      const shopifyContainer = document.getElementById('shopify')
      if (shopifyContainer) {
        shopifyContainer.scrollIntoView({ block: 'center' })
      }
    })
  }

  return (
    <div id='shopify'>
      <Card title={title}>
        {
          String(id) === String(shopifyCurrentId)  && (<div>
            {connectChecked && !connected && <ConnectToShopify shopifyConnectLoading={shopifyConnectLoading} onSubmit={onSubmit} />}
            <div>
              {connected && <DisconnectShopify onDisconnect={onDisconnectShopify} shopifyDisconnectLoading={shopifyDisconnectLoading} />}
              {connected && !!(allProducts.length - addedIds.length) &&
                <AddNewProducts
                  allProducts={allProducts}
                  addedIds={addedIds}
                  onUpdate={onUpdate}
                />}
              {connected && <RefreshProductsBtn onRefresh={onRefresh} shopifySyncLoading={shopifyRefreshLoading} />}
            </div>
            {
              isFetch ? (
                <div>
                  <br/>
                  <ProductsAddedList
                    allProducts={allProducts}
                    addedIds={addedIds}

                    onDelete={onDeleteProduct}
                  />
                </div>
              ) : null
            }
          </div>)
        }
      </Card>
    </div>)
})

export { ShopifyCard }