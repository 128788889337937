import React from 'react'
import _isEqual from 'lodash/isEqual'
import _groupBy from 'lodash/groupBy'
import _filter from 'lodash/filter'
import { RadioGroup, Radio } from '../../../_library/RadioGroup'
import SalesByPromoCode from './SalesByPromoCode'
import SalesByOrderDate from './SalesByOrderDate'
import Toggle from '../../../_library/Toggle'

export default class PromoCodeComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.initialData = null
    this.state = {
      promoCodeData: null,
      selectedTab: 'salesByPromoCode'
    }
  }

  componentDidMount() {
    this.updateComponent()
  }

  componentDidUpdate() {
    this.updateComponent()
  }

  updateComponent = (forceUpdate, onlyShowPaidTickets) => {
    const { data } = this.props

    if (!_isEqual(this.initialData, data) || forceUpdate) {
      const filteredData = onlyShowPaidTickets ? _filter(data, item => parseFloat(item.cost) > 0) : data
      const groupedByOrderDate = _groupBy(filteredData, 'datetime')
      const groupedByPromoCode = _groupBy(filteredData, item => item.discountCode + item.ticketType)

      this.initialData = data
      this.setState({
        promoCodeData: filteredData,
        groupedByPromoCode,
        groupedByOrderDate
      })
    }
  }

  onChangeShowGroupsByDate = selectedTab => {
    this.setState({ selectedTab })
  }

  onToggleChange = onlyShowPaidTickets => {
    this.updateComponent(true, onlyShowPaidTickets)
  }

  getChildComponent = () => {
    const { event } = this.props
    const { selectedTab, promoCodeData, groupedByOrderDate, groupedByPromoCode } = this.state

    if (!(promoCodeData && promoCodeData.length)) return null
    switch (selectedTab) {
      case 'salesByPromoCode':
        return <SalesByPromoCode event={event} data={promoCodeData} groupedData={groupedByPromoCode} />
      case 'salesByOrderDate':
        return <SalesByOrderDate event={event} data={promoCodeData} groupedData={groupedByOrderDate} />
      default:
    }
  }

  render() {
    const { selectedTab } = this.state

    return (
      <div className="performance-by-ticket">
        <div className="table-background">
          <RadioGroup name="fruit" selectedValue={selectedTab} onChange={this.onChangeShowGroupsByDate}>
            <Radio name="salesByPromoCode" value="salesByPromoCode" label="Sales by Promo Code" />
            <Radio name="salesByOrderDate" value="salesByOrderDate" label="Total Daily Sales" />
          </RadioGroup>
          <Toggle title="Only show paid tickets" id="onlyShowPaidTickets" onChange={this.onToggleChange} />
          {this.getChildComponent()}
        </div>
      </div>
    )
  }
}
