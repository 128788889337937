import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

const disabledRoles = ['tf_support', 'tf_client_support']

const DisableWrapper = ({ role, children }) => {
  const disableEvent = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (disabledRoles.includes(role)) {
      document.addEventListener('keydown', disableEvent)

      return () => {
        document.removeEventListener('keydown', disableEvent)
      }
    }
  }, [role])

  return <div className={`${disabledRoles.includes(role) ? 'disable-whole-section' : ''}`}>{children}</div>
}

const mapStateToProps = state => ({
  role: _get(state, 'events.selected.self.role'),
})

export const DisableSectionByPermissionWrapper = connect(mapStateToProps)(DisableWrapper)
