import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'

import { createAsyncHandlers } from '../actions'

const initialState = {
  user: null,
  initialized: false,
  checked: false,
  verifyData: {},
}

const loadUser = (state, action) => {
  const user = _get(action, 'payload.data')
  state.initialized = true
  state.checked = true
  state.updateTime = Date.now()
  if (user) {
    state.user = user
    if (!action.payload.noAppcues) {
      window.Appcues &&
        window.Appcues.identify(user.id, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        })
    }
  } else {
    state.user = null
  }
}

const FETCH_SESSION = createAsyncHandlers('FETCH_SESSION', {
  success: loadUser,
  failed: (state, action) => {
    state.checked = true
    state.user = null
  },
})

const LOGIN = createAsyncHandlers('LOGIN', {
  success: loadUser,
})

const FACEBOOK_LOGIN = createAsyncHandlers('FACEBOOK_LOGIN', {
  success: loadUser,
})

const LOGOUT = createAsyncHandlers('LOGOUT', {
  success: loadUser,
})

const REGISTER = createAsyncHandlers('REGISTER', {
  success: loadUser,
})

const VERIFY_USER = createAsyncHandlers('VERIFY_USER', {
  success: (state, action) => {
    const { customerId, netverifyUrl, provider } = _get(action, 'payload')
    state.verifyData = { customerId, netverifyUrl, provider }
  }
})

const UPDATE_VERIFY_USER_STATUS = createAsyncHandlers('UPDATE_VERIFY_USER_STATUS', {
  success(state, action) {
    return state
  },
})

const GET_VERIFY_INFO = createAsyncHandlers('GET_VERIFY_INFO', {
  success: (state, action) => {
    state.user.verifyInfo = _get(action, 'payload.data.$original.attributes')
  },
})

export default handleActions(
  {
    ...FETCH_SESSION,
    ...LOGIN,
    ...FACEBOOK_LOGIN,
    ...LOGOUT,
    ...REGISTER,
    ...VERIFY_USER,
    ...GET_VERIFY_INFO,
    ...UPDATE_VERIFY_USER_STATUS,
  },
  initialState,
)
