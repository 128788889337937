import { createFixedFloatNormalizer, currencyNormalizerCreator } from "../../validation"
import _uniq from 'lodash/uniq'
import _map from 'lodash/map'

export const crmAdapter = data => ({
  data: data.data.customers.map(customer => ({
    id: customer.customerId,
    name: customer.fullName,
    ...(customer.totalRevenue.reduce((curr, next) => {
      curr[`totalRevenue${next.currency}`] = next.revenue
      return curr
    }, {})),
    eventsAttended: customer.eventsAttended ?? '0',
    ticketsPurchased: customer.ticketsPurchased ?? '0',
    ...(customer.referredRevenue.reduce((curr, next) => {
      curr[`referredRevenue${next.currency}`] = next.revenue
      return curr
    }, {})),
    referredTickets: customer.referredTickets ?? '0',
    impressions: customer.impressions ?? '0',
    email: customer.email,
  })),
  pagination: data.pagination,
  currencies: data.data.currencies,
})

export const crmCustomerAdapter = (data, currencies) =>
  data.data.attributes.map(customer => ({
    id: customer.customerId,
    name: customer.billingFirstName + ' ' + customer.billingLastName,
    billingEmail: customer.email ?? '',
    billingPhone: customer.phone ?? '',
    billingFirstName: customer.billingFirstName,
    billingLastName: customer.billingLastName,
    billingCity: customer.city,
    billingCountry: customer.country,
    email: customer.email,
    notes: customer.notes,
    orders: customer.orders.map(
      order => ({
        ...order,
        dateOrder: order.date,
        statusOrder: order.status,
        id: order.orderId,
        name: _uniq(_map(order.tickets, 'eventName')).join(', '),
        eventId: order.tickets[0].eventId,
        totalSymbol: currencyNormalizerCreator(
          (order?.currency?.length || order?.orderCurrency) ? getCurrencySymbol(
            order?.currency?.length
              ? { currency: order.currency }
              : { currency: currencies.find(currency => currency.currency === order.orderCurrency) },
          ) : '')(
            createFixedFloatNormalizer(2)(parseFloat(order.total)),
          ),
        tickets: order.tickets.map(ticket => ({
          ...ticket,
          status: ticket.status.toLowerCase(),
          cancellationReason: null,
          extraInfo: null,
          metadata: null,
          tags: null,
        })),
      })) ?? [],
  }))