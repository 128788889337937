// Deprecated -- use ReactSelectField instead
import _identity from 'lodash/identity'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import PropTypes from 'prop-types'

export default class Select extends React.Component {
  static propType = {
    label: PropTypes.string.isRequired,
    fieldKey: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      }),
    ),
  }

  handleChange = e => {
    const { onChange = _identity, onSelect = _identity, fieldKey, options } = this.props
    const option = options.find(el => el.value == e.target.value)
    onSelect(option)
    onChange(e, fieldKey)
  }

  handleClick = e => {
    const { onClick = _identity, fieldKey } = this.props
    e.stopPropagation()
    onClick(e, fieldKey)
  }

  render() {
    const {
      id,
      label,
      error,
      options,
      fieldGroupStyle,
      onChange,
      fieldKey,
      touched,
      customError,
      defaultOption,
      isLoading,
      ...field
    } = this.props
    return (
      <div className={'form-group ' + (error ? 'has-error' : '')}>
        <div className="floating-field-group active" style={fieldGroupStyle}>
          <div className="floating-field">
            {!!label && (
              <label className="control-label" htmlFor={id}>
                {label}
              </label>
            )}
            <select
              id={id}
              className="form-control"
              onClick={this.handleClick}
              onChange={this.handleChange}
              {...field}
            >
              {defaultOption && <option value="">{defaultOption.label}</option>}
              {_map(options, o => (
                <option key={o.value} value={o.value} disabled={o.disabled}>
                  {o.label}
                </option>
              ))}
            </select>
            {isLoading && <i className="fa fa-spinner fa-pulse fa-fw select-loading-icon" />}
          </div>
        </div>
        {!_isEmpty(error) && touched ? <div className="help-block">{error}</div> : null}
        {customError && touched ? <div className="bottom-error">{customError}</div> : null}
      </div>
    )
  }
}
