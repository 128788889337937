import React from 'react'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import { HTTP_INIT, HTTP_LOADING_SUCCESSED, HTTP_LOADING_FAILED } from '../../../../_common/core/http'
import LoadingBar from '../../../_library/LoadingBar'
import AddOnBreakdownComponent from './AddOnBreakdownComponent'
import { addOnBreakdownDataAdapter } from '../../../../_common/core/http_services/adapters'

export default class PerformanceAddOnBreakdown extends React.PureComponent {
  constructor(props) {
    super(props)

    this.initialData = null
    this.state = {
      isLoadingAddOnBreakdownData: true,
      http_status: HTTP_INIT
    }
  }

  componentDidMount() {
    this.fetchAddOnBreakdown()
    this.fetchAddOnBreakdownId = setInterval(async () => {
      this.fetchAddOnBreakdown()
    }, 10 * 1000)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.fetchAddOnBreakdownId)
  }

  fetchAddOnBreakdown = async () => {
    const { event } = this.props
    const section = 'add_on_breakdown'
    try {
      const res = await getPerfomanceData(event.id, section)
      const addOnBreakdownData = _get(res, section) || []
      const adaptedAddOnBreakdownData = addOnBreakdownDataAdapter(addOnBreakdownData)
      if (!_isEqual(adaptedAddOnBreakdownData, this.initialData)) {
        this.initialData = adaptedAddOnBreakdownData
        this.handleResponse(adaptedAddOnBreakdownData)
      }

      if (!this.isUnmounted) {
        this.setState({
          http_status: HTTP_LOADING_SUCCESSED
        })
      }
    } catch (error) {
      this.handleResponse(null, error)
      if (!this.isUnmounted) {
        this.setState({
          http_status: HTTP_LOADING_FAILED
        })
      }
    }
  }

  handleResponse = (addOnBreakdownData, error) => {
    const { isLoadingAddOnBreakdownData } = this.state

    if (this.isUnmounted) return
    isLoadingAddOnBreakdownData &&
      this.setState({
        isLoadingAddOnBreakdownData: false
      })

    if (error) {
      console.log(error)
    } else {
      this.setState({ addOnBreakdownData })
    }
  }

  render() {
    const { event, tickets } = this.props
    const { isLoadingAddOnBreakdownData, addOnBreakdownData } = this.state

    return isLoadingAddOnBreakdownData ? (
      <LoadingBar title={`Hold tight! We're getting your event's sales details...`} />
    ) : (
      <AddOnBreakdownComponent event={event} tickets={tickets} data={addOnBreakdownData} />
    )
  }
}
