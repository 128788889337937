import React from 'react'
import { Field, Form, withFormik } from 'formik'
import { withRouter } from 'react-router'
import Button from '../../_library/Button'
import { PasswordField } from '../../formik/Fields/PasswordField'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'
import { SimpleField } from '../../formik/Fields'

const EmailPasswordForm = props => {
  const { isSubmitting, isValid } = props
  const handleGoBack = () => {
    props.history.goBack()
  }
  return (
    <Form className="margin-horizontal-25 sign-in-container">
      <Field
        type="email"
        name="email"
        label="Email"
        component={SimpleField}
        formclassname="field-wrapper email-input"
        validate={combineValidators(requiredValidator(), isEmailValidator('Invalid'))} 
        touchedOnBlur
      />
      <Field
        type="password"
        name="password"
        label="Password"
        component={PasswordField}
        className="password-input"
        validate={requiredValidator()} 
      />
      <div className="buttons-wrapper">
        <Button type="button" className="back-button" disabled={isSubmitting} onClick={handleGoBack}>
          Back
        </Button>
        <Button 
          type="submit" 
          className={!isValid ? 'submit-disabled' : 'submit-button-login'} 
          disabled={!isValid} 
          loading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </Form>
  )
}

const EmailPasswordFormFormik = withFormik({
  mapPropsToValues: props => ({ email: props.email ?? '', password: '' }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
      setSubmitting(false)
      props.history.push('/')
    } catch (e) {
      setSubmitting(false)
    }
  },

  displayName: 'EmailPasswordForm'
})(EmailPasswordForm)

const EmailPasswordFormRouter = withRouter(EmailPasswordFormFormik)

export default EmailPasswordFormRouter
