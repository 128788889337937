import React from 'react'
import { Field } from 'formik'
import _ from 'lodash'

import { SelectField, SimpleField } from '../../formik/Fields'
import {
  requiredValidator,
  createFixedFloatNormalizer,
  combineNormalizers,
  toNumber,
  minNormalizerCreator,
  maxNormalizerCreator
} from '../../../_common/core/validation'
import CustomToggle from '../../_library/CustomToggle'
import TagsField2 from '../../_library/TagsField2'
import { MONTHS_OPTIONS } from './constants'

export default class GiftFieldsSection extends React.PureComponent {
  setValues = value => {
    if (!value) return
    const { setFieldValue, values } = this.props
    const amount = _.clone(values.amount)
    if (!_.find(amount, item => item == value)) {
      amount.push(Number(value))
    }
    setFieldValue('amount', amount)
    setFieldValue('amountValue', '')
  }

  render() {
    const {
      groupedTicketsOptions,
      values,
      errors,
      touched,
      handleTicketTypeChange,
      handleToggleChange,
      setFieldValue,
      currency
    } = this.props

    const isFlagTicket = values.selectedFlag.includes('flagTicket')
    const isFlagAmount = values.selectedFlag.includes('flagAmount')

    return (
      <div className="row">
        {isFlagTicket && (
          <div>
            <div className="col-xs-6 col-sm-6">
              <Field
                name="ticketGroupId"
                label="Ticket Type"
                options={groupedTicketsOptions}
                component={SelectField}
                validate={requiredValidator()}
                onChange={handleTicketTypeChange}
                touched={touched}
                customError={errors.ticketGroupIdCustomError}
              />
            </div>
            <div className="col-xs-6 col-sm-6 group-item-price-field">
              <Field
                name="groupItemPrice"
                label="Item Price (average)"
                component={SimpleField}
                validate={requiredValidator()}
                normalizer={toNumber}
                onBlurNormalizer={combineNormalizers(toNumber, createFixedFloatNormalizer(2))}
              />
            </div>
          </div>
        )}
        {isFlagTicket && (
          <div className="col-xs-6 col-sm-6" style={{ padding: '0 15px 15px' }}>
            <CustomToggle
              id="enabled"
              onText={'Enabled'}
              checked={values.enabled}
              onChange={handleToggleChange}
            />
          </div>
        )}
        {isFlagAmount && (
          <div>
            <div className={`col-xs-6 col-sm-$6`}>
              <Field
                name="amountValue"
                label={
                  <span>
                    Amount (press <strong>{'space'}</strong> or <strong>{'enter'}</strong> after
                    each one):
                  </span>
                }
                component={SimpleField}
                type="number"
                normalizer={combineNormalizers(
                  minNormalizerCreator(0),
                  maxNormalizerCreator(999999999)
                )}
                inputContainerClass="hide-arrows"
                onBlur={value => this.setValues(createFixedFloatNormalizer(2)(value))}
                onKeyPress={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault()
                    const value = createFixedFloatNormalizer(2)(e.target.value)
                    this.setValues(value)
                  }
                }}
              />
            </div>
            <div className="col-xs-6 col-sm-6" style={{ padding: '20px 15px' }}>
              <CustomToggle
                id="enabled"
                onText={'Enabled'}
                checked={values.enabled}
                onChange={handleToggleChange}
              />
            </div>
            <div className={`col-xs-12 gift-amount-tags`}>
              {values.amount && values.amount.length ? (
                <TagsField2
                  label=""
                  id={'amount'}
                  tags={values.amount}
                  value={values.amount}
                  containerClassName="no-background"
                  normalizer={value => (value ? `${currency.symbol} ${value}` : value)}
                  onChange={amount => setFieldValue('amount', amount)}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    )
  }
}
