import React from 'react'

const CardHeader = ({ imageUrl, bodyText }) => (
  <div className="table-caption">
    <img className="section-main-heading" src={asset(`${imageUrl}`)} alt="nodata" />
    {bodyText}
  </div>
)

export default CardHeader
