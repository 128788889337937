import React from 'react'

export const BackgroundSignUp = ({ children }) => (
  <div className="form-gradient-wrapper">
    <div className="light-blue-top-right" />
    <div className="light-blue" />
    <div className="light-red" />
    <div className="line-box-wrapper">
      <div className="line-decoration" />
      <div className="box-decoration" />
    </div>
    <div className="line-box-second-wrapper">
      <div className="line-decoration" />
      <div className="box-decoration" />
    </div>
    <img alt="" className="rectangle-decoration" src={asset('/resources/images/rectangle-sign-up.svg')} />
    <img alt="" className="rectangle-decoration-bottom" src={asset('/resources/images/rectangle-sign-up.svg')} />
    <img
      alt=""
      className="rectangle-decoration-bottom-small"
      src={asset('/resources/images/rectangle-sign-up.svg')}
    />
    <div className="flower-wrapper">
      <div className="top-flower">
        <img alt="" className="leaf-top-left" src={asset('/resources/images/leaf-top-left.svg')} />
        <img alt="" className="leaf-top-right" src={asset('/resources/images/leaf-top-right.svg')} />
      </div>
      <div className="bottom-flower">
        <img alt="" className="leaf-bottom-left" src={asset('/resources/images/leaf-bottom-left.svg')} />
        <img alt="" className="leaf-bottom-right" src={asset('/resources/images/leaf-bottom-right.svg')} />
      </div>
    </div>
    <div className="flower-wrapper-small">
      <div className="top-flower-small">
        <img
          alt=""
          className="leaf-top-left-small"
          src={asset('/resources/images/leaf-small-top-left.svg')}
        />
        <img
          alt=""
          className="leaf-top-right-small"
          src={asset('/resources/images/leaf-small-top-right.svg')}
        />
      </div>
      <div className="bottom-flower-small">
        <img
          alt=""
          className="leaf-bottom-left-small"
          src={asset('/resources/images/leaf-small-bottom-left.svg')}
        />
        <img
          alt=""
          className="leaf-bottom-right-small"
          src={asset('/resources/images/leaf-small-bottom-right.svg')}
        />
      </div>
    </div>
    <div className="flower-wrapper-medium">
      <img
        alt=""
        className="leaf-top-right-medium"
        src={asset('/resources/images/leaf-medium-top-right.svg')}
      />
      <img
        alt=""
        className="leaf-bottom-right-small"
        src={asset('/resources/images/leaf-medium-bottom-right.svg')}
      />
    </div>
    <div className="flower-wrapper-bottom">
      <div className="top-flower">
        <img alt="" className="leaf-top-left" src={asset('/resources/images/leaf-top-left.svg')} />
        <img alt="" className="leaf-top-right" src={asset('/resources/images/leaf-top-right.svg')} />
      </div>
      <div className="bottom-flower">
        <img alt="" className="leaf-bottom-left" src={asset('/resources/images/leaf-bottom-left.svg')} />
        <img alt="" className="leaf-bottom-right" src={asset('/resources/images/leaf-bottom-right.svg')} />
      </div>
    </div>
    <div className="form-black-wrapper">
      <div className="form-container">{children}</div>
    </div>
  </div>
)
