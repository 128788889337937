import React, { memo } from 'react'
import Field from '../../_library/Field'

export const Recipent = memo(
  ({
    first_name,
    index,
    error_first_name,
    touched_first_name,
    last_name,
    error_last_name,
    touched_last_name,
    email,
    error_email,
    touched_email,
    handleChange,
    handleBlur,
    deleteRecipient
  }) => (
    <tr>
      <td>
        <Field
          type="text"
          id={'recipients[' + index + '].firstName'}
          value={first_name}
          error={error_first_name}
          touched={touched_first_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <Field
          type="text"
          id={'recipients[' + index + '].lastName'}
          value={last_name}
          error={error_last_name}
          touched={touched_last_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <Field
          type="email"
          id={'recipients[' + index + '].email'}
          value={email}
          error={error_email}
          touched={touched_email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <div aria-hidden={true} className="delete-recipient" onClick={() => deleteRecipient(index)}>
          <i className="fa fa-trash" aria-hidden="true" />
        </div>
      </td>
    </tr>
  )
)
