import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'
import _get from 'lodash/get'
import { consumerRequest } from '../../core/http_services'
import { deleteCookieByName } from '../../../web/utils/cookies'
import localforage from 'localforage'
import store from '../store'

const getNotificationsFromState = () => {
  const state = store.getState()
  return state.notifications
}

const { FETCH_SESSION } = createAsyncAction('FETCH_SESSION', function (options) {
  return dispatch =>
    consumerRequest
      .get('/auth')
      .then(res => {
        const data = _get(res, 'data')
        dispatch(this.success({ ...data, ...options }))
        return data
      })
      .catch(err => {
        const status = _get(err, 'response.status')

        if (status === 401) {
          deleteCookieByName('X-TF-ECOMMERCE')
          localforage.setItem('events', [])
        }

        if (!options || (options && !options.hideError)) {
          dispatch(ERROR({ details: err.response?.data?.message }))
        }
        dispatch(this.failed(err))
      })
})

const { LOGIN } = createAsyncAction('LOGIN', function (form) {
  return dispatch =>
    consumerRequest
      .post('/auth', {
        email: _get(form, 'attributes.username'),
        password: _get(form, 'attributes.password'),
        newSignupFlow: _get(form, 'attributes.newSignupFlow', false),
      })
      .then(res => {
        const data = _get(res, 'data')
        dispatch(this.success({ ...form, ...data }))
        return data
      })
      .catch(err => {
        const notifications = getNotificationsFromState()
        for (const key in notifications) {
          const notification = notifications[key]
          if (notification?.message?.details) {
            if (err.response?.data?.message === notification.message.details) {
              throw err
            }
          }
        }
        dispatch(ERROR({ details: err.response?.data?.message }))
        dispatch(this.failed(err))
        throw err
      })
})

const { FACEBOOK_LOGIN } = createAsyncAction('FACEBOOK_LOGIN', function (form) {
  return dispatch =>
    fetchAPI('/api/fbauthenticate', {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'fbsessionrequest',
          ...form,
        },
      }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { LOGOUT } = createAsyncAction('LOGOUT', function () {
  return dispatch =>
    consumerRequest
      .delete('/auth')
      .then(res => {
        const data = _get(res, 'data')
        deleteCookieByName('X-TF-ECOMMERCE')
        dispatch(this.success(data))
        return data
      })
      .catch(err => {
        showAxiosError(err)
        return err
      })
})

const { REGISTER } = createAsyncAction(
  'REGISTER',
  (form, paramsData = {}) =>
    dispatch =>
      fetchAPI('/api/account/request-dashboard-access/', {
        method: 'POST',
        body: JSON.stringify({
          data: {
            attributes: {
              ...form,
              ...paramsData,
            },
          },
        }),
      }),
)

const { UPDATE } = createAsyncAction('UPDATE', function (form) {
  return dispatch =>
    fetchAPI('/api/authenticate', {
      method: 'PATCH',
      body: JSON.stringify({ data: { type: 'user', ...form } }),
    })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
      .catch(err => {
        dispatch(this.failed(err))
        throw err
      })
})

const { RESTORE_PASSWORD } = createAsyncAction('RESTORE_PASSWORD', function (email) {
  return dispatch =>
    fetchAPI('/api/password-restore', {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'password_reset',
          attributes: {
            email,
          },
          relationships: [],
        },
      }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { RESET_PASSWORD } = createAsyncAction('RESET_PASSWORD', function (form) {
  return dispatch =>
    fetchAPI('/api/reset-password', {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: '',
          ...form,
          relationships: [],
        },
      }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { CHECK_RESET_PASSWORD_HASH } = createAsyncAction('CHECK_RESET_PASSWORD_HASH', function (data) {
  return dispatch =>
    fetchAPI('/api/password-restore', { params: data })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { CHECK_TEAM_INVITATION_HASH } = createAsyncAction('CHECK_TEAM_INVITATION_HASH', function (data) {
  return dispatch =>
    fetchAPI('/api/team-invitations', { params: data })
      .catch(err => {
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { ACCEPT_TEAM_INVITATION } = createAsyncAction('ACCEPT_TEAM_INVITATION', function (form) {
  return dispatch =>
    fetchAPI('/api/team-invitations/accept', {
      method: 'POST',
      params: { token: form.attributes.token },
      body: JSON.stringify({
        data: {
          ...form,
        },
      }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { VERIFY_USER } = createAsyncAction('VERIFY_USER', function () {
  return dispatch =>
    fetchAPI('/api/authenticate/verify/')
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_VERIFY_USER_STATUS } = createAsyncAction('UPDATE_VERIFY_USER_STATUS', function (data) {
  const body = { data: { attributes: { ...data } } }
  return dispatch =>
    fetchAPI('/api/authenticate/verify/', {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
      .catch(err => {
        showAxiosError(err)
      })
})

const { GET_VERIFY_INFO } = createAsyncAction('GET_VERIFY_INFO', function () {
  return dispatch =>
    fetchAPI('/api/authenticate/get-verification-info/')
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export {
  FETCH_SESSION,
  LOGIN,
  FACEBOOK_LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE,
  RESTORE_PASSWORD,
  RESET_PASSWORD,
  CHECK_RESET_PASSWORD_HASH,
  CHECK_TEAM_INVITATION_HASH,
  ACCEPT_TEAM_INVITATION,
  VERIFY_USER,
  GET_VERIFY_INFO,
  UPDATE_VERIFY_USER_STATUS,
}
