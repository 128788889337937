import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _isNull from 'lodash/isNull'
import _filter from 'lodash/filter'
import Card from '../../_library/Card'

import {
  FETCH_EVENT_ADDONS,
  CREATE_ADDON,
  CREATE_ADDON_GROUP,
  UPDATE_ADDON,
  UPDATE_ADDON_GROUP,
  DELETE_ADDON,
  UPDATE_ADDONS_SORTING,
} from '../../../_common/redux/addons/actions'
import { get_event_addons } from '../../../_common/core/selectors'
import { showAxiosError } from '../../utils/messenger'

import AddOnForm from './AddOnForm'
import AddOnTable from './AddOnTable'
import AddOnDeactivateModal from './modals/AddOnDeactivateModal'
import AddOnModal from './modals/AddOnModal'
import {
  addOnFormAdapter,
  addonStockAdapter,
  editAddOnFormAdapter,
  addOnSortAdapter,
} from '../../../_common/core/http_services/adapters/addons'
import EditAddOnVariantModal from './modals/EditAddOnVariantModal'
import DeleteAddOnVariantModal from './modals/DeleteAddOnVariantModal'

@connect(
  state => {
    const addons = get_event_addons(state)
    const isLoadingAddOns = state.loading.has('FETCH_EVENT_ADDONS')
    return {
      addons,
      isLoadingAddOns,
    }
  },
  {
    FETCH_EVENT_ADDONS,
    CREATE_ADDON,
    CREATE_ADDON_GROUP,
    UPDATE_ADDON,
    UPDATE_ADDON_GROUP,
    DELETE_ADDON,
    UPDATE_ADDONS_SORTING,
  },
)
export default class AddOnSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addingAddon: false,
      editingAddon: null,
      isAddOnGroupSelected: false,
      editingAddonVariant: null,
      deactivatingAddon: null,
      deleteingAddonVariantModalData: { isOpen: false, loading: false, deleteingItem: null },
    }
  }

  componentDidMount() {
    const { event, FETCH_EVENT_ADDONS } = this.props
    FETCH_EVENT_ADDONS(event.id)
  }

  handleAddAddon = () => {
    this.setState({ addingAddon: true })
  }

  handleCancelAddon = () => {
    this.setState({ addingAddon: false, editingAddon: null })
  }

  handleEditAddon = selectedAddOn => {
    const isAddOnGroup = _isNull(selectedAddOn.addOnGroupId) ? false : true
    this.setState({ editingAddon: selectedAddOn, isAddOnGroupSelected: isAddOnGroup, addingAddon: false })
  }

  handleCancelEditingAddonVariant = () => {
    this.setState({ editingAddonVariant: null })
  }

  handleEditAddonVariant = selectedAddOnVariant => {
    this.setState({ editingAddonVariant: selectedAddOnVariant, addingAddon: false })
  }

  handleDeactivateAddonOpenModal = selectedAddOn => {
    this.setState({ deactivatingAddon: selectedAddOn })
  }

  handleDeactivateAddonCancel = () => {
    this.setState({ deactivatingAddon: null })
  }

  handleActivateDeactivateAddon = async (selectedAddOn, active) => {
    const { UPDATE_ADDON, UPDATE_ADDON_GROUP, event } = this.props
    const isAddOnGroup = _isNull(selectedAddOn.addOnGroupId) ? false : true
    this.setState(prevState => ({
      ...prevState,
      loadingAddOnUpdate: {
        ...prevState.loadingAddOnUpdate,
        [selectedAddOn.id]: true,
      },
    }))
    try {
      if (isAddOnGroup) {
        await UPDATE_ADDON_GROUP(selectedAddOn.id, { active }, event.id)
      } else {
        await UPDATE_ADDON(selectedAddOn.id, { active }, event.id)
      }
    } catch (err) {
    } finally {
      this.setState(prevState => ({
        ...prevState,
        deactivatingAddon: null,
        loadingAddOnUpdate: {
          ...prevState.loadingAddOnUpdate,
          [selectedAddOn.id]: false,
        },
      }))
    }
  }

  handleSubmitNewAddon = async form => {
    const { event, CREATE_ADDON, addons } = this.props
    const adaptedFormData = addOnFormAdapter(form, addons)

    try {
      await CREATE_ADDON(event.id, adaptedFormData)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ addingAddon: false })
    }
  }

  handleSubmitNewAddonGroup = async (form, variantsData) => {
    const { event, CREATE_ADDON_GROUP, addons } = this.props
    const adaptedFormData = addOnFormAdapter(form, addons, variantsData)

    try {
      await CREATE_ADDON_GROUP(event.id, adaptedFormData)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ addingAddon: false })
    }
  }

  handleSubmitEditAddon = async form => {
    const { UPDATE_ADDON, event } = this.props
    const { editingAddon, editingAddonVariant } = this.state
    const adaptedFormData = editAddOnFormAdapter(form)
    const addonId = editingAddonVariant ? _get(editingAddonVariant, 'id') : _get(editingAddon, 'id')

    // submit
    try {
      await UPDATE_ADDON(addonId, adaptedFormData, event.id)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ editingAddon: null, editingAddonVariant: null })
    }
  }

  handleSubmitEditAddonGroup = async (form, variantsData) => {
    const { UPDATE_ADDON_GROUP, event, addons } = this.props
    const { editingAddon, editingAddonVariant } = this.state
    const adaptedFormData = addOnFormAdapter(form, addons, variantsData)
    const addonId = editingAddonVariant ? _get(editingAddonVariant, 'id') : _get(editingAddon, 'id')

    try {
      await UPDATE_ADDON_GROUP(addonId, adaptedFormData, event.id)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ editingAddon: null, editingAddonVariant: null })
    }
  }

  handleAddonRefresh = async () => {
    const { event, FETCH_EVENT_ADDONS } = this.props
    try {
      await FETCH_EVENT_ADDONS(event.id)
    } catch (error) {
      showAxiosError(error)
    }
  }

  handleSaveSort = async (sorting, data) => {
    const { configs, addons, event, UPDATE_ADDONS_SORTING } = this.props
    const addOnsGroups = _filter(addons, addon => addon.isGroup)
    const enableAddOnsWithVariants = _get(configs, 'behavior.useAddOnsWithVariants')
    const sortedData = enableAddOnsWithVariants ? addOnSortAdapter(data, addons) : data
    await UPDATE_ADDONS_SORTING(event.id, sorting, sortedData, addOnsGroups)
  }

  handleOpenDeleteAddOnVariantModal = data => {
    this.setState({
      deleteingAddonVariantModalData: { isOpen: true, deleteingItem: data, loading: false },
    })
  }

  handleResetDeleteingAddonVariantState = () => {
    this.setState({
      deleteingAddonVariantModalData: { showConfirmModal: false, deleteingItem: null, loading: false },
    })
  }

  handleDeleteAddonVariant = async () => {
    const { DELETE_ADDON, event } = this.props
    const {
      deleteingAddonVariantModalData: {
        deleteingItem: { id },
      },
    } = this.state

    // Set loading
    this.setState(prevState => ({
      ...prevState,
      deleteingAddonVariantModalData: { ...prevState.deleteingAddonVariantModalData, loading: true },
    }))

    await DELETE_ADDON(id, event.id)
    this.handleResetDeleteingAddonVariantState()
  }

  render() {
    const { event, tickets, addons, isLoadingAddOns, tables, configs } = this.props
    const {
      addingAddon,
      editingAddon,
      deactivatingAddon,
      loadingAddOnUpdate,
      editingAddonVariant,
      deleteingAddonVariantModalData,
      isAddOnGroupSelected,
    } = this.state

    const enableAddOnsWithVariants = _get(configs, 'behavior.useAddOnsWithVariants')
    const stockLabel = _get(configs, 'messages.stockLabel')
    const addonsWithCalculatedStocks = addonStockAdapter(addons)

    return (
      <div className="row">
        <div className="col-xs-12">
          <Card icon={'fa-book'} title={'Add-Ons'}>
            {addingAddon && (
              <AddOnForm
                event={event}
                tickets={tickets}
                tables={tables}
                handleSubmit={this.handleSubmitNewAddon}
                handleSubmitGroup={this.handleSubmitNewAddonGroup}
                onCancel={this.handleCancelAddon}
                enableAddOnsWithVariants={enableAddOnsWithVariants}
                configs={_get(configs, 'children.AddOnForm')}
                stockLabel={stockLabel}
              />
            )}
            {editingAddon && (
              <AddOnModal
                event={event}
                tickets={tickets}
                tables={tables}
                isEditing={true}
                initialValues={editingAddon}
                isAddOnGroupSelected={isAddOnGroupSelected}
                handleSubmitEditAddon={this.handleSubmitEditAddon}
                handleSubmitEditAddonGroup={this.handleSubmitEditAddonGroup}
                handleCancelAddon={this.handleCancelAddon}
                enableAddOnsWithVariants={enableAddOnsWithVariants}
                configs={_get(configs, 'children.AddOnModal')}
                stockLabel={stockLabel}
              />
            )}
            {enableAddOnsWithVariants && editingAddonVariant && (
              <EditAddOnVariantModal
                editingAddonVariant={editingAddonVariant}
                handleSubmitEditAddon={this.handleSubmitEditAddon}
                handleCancelEditingAddonVariant={this.handleCancelEditingAddonVariant}
                stockLabel={stockLabel}
              />
            )}
            <AddOnTable
              event={event}
              addons={enableAddOnsWithVariants ? addonsWithCalculatedStocks : addons}
              isLoading={isLoadingAddOns}
              loadingAddOnUpdate={loadingAddOnUpdate}
              handleEditAddon={this.handleEditAddon}
              handleActivateAddon={this.handleActivateDeactivateAddon}
              handleDeactivateAddonOpenModal={this.handleDeactivateAddonOpenModal}
              handleAddAddon={this.handleAddAddon}
              addingAddon={addingAddon}
              handleAddonRefresh={this.handleAddonRefresh}
              handleSaveSort={this.handleSaveSort}
              handleEditAddonVariant={this.handleEditAddonVariant}
              handleOpenDeleteAddOnVariantModal={this.handleOpenDeleteAddOnVariantModal}
              enableAddOnsWithVariants={enableAddOnsWithVariants}
              stockLabel={stockLabel}
            />
            <AddOnDeactivateModal
              deactivatingAddon={deactivatingAddon}
              loadingAddOnUpdate={loadingAddOnUpdate}
              handleDeactivateAddon={this.handleActivateDeactivateAddon}
              handleDeactivateAddonCancel={this.handleDeactivateAddonCancel}
            />
            {enableAddOnsWithVariants && (
              <DeleteAddOnVariantModal
                deleteingAddonVariantModalData={deleteingAddonVariantModalData}
                handleDeleteAddonVariant={this.handleDeleteAddonVariant}
                handleResetDeleteingAddonVariantState={this.handleResetDeleteingAddonVariantState}
              />
            )}
          </Card>
        </div>
      </div>
    )
  }
}
