import { baseRequest } from '.'
import _get from 'lodash/get'

export const getBrands = async params => {
  const res = await baseRequest.get(`brands/`, { params })
  return _get(res, 'data')
}

export const getBrandPixelData = async (eventId, brandId) => {
  const res = await baseRequest.get(`events/${eventId}/organizations/${brandId}/pixels/`)
  return _get(res, 'data.data')
}

export const getBrandRoles = async () => {
  const res = await baseRequest.get('/organizations/associated?roles[]=admin&roles[]=owner')
  return _get(res, 'data.brands_ids')
}

export const manageStripeConnectedAccount = async (brandId, code, enableConnectedAccount) => {
  const res = await baseRequest.patch(`/organizations/${brandId}/payment-methods/stripe`, {
    data: {
      code,
      attributes: {
        enableConnectedAccount,
      },
    },
  })
  return res
}
