// Re-render Optimization 14.05.2020
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import _get from 'lodash/get'
import { connect } from 'react-redux'

import Notifications from './../_library/notifications/Notifications'
import { getTitle } from '../utils/getTitle'

const HomePage = React.memo(props => {
  const { displayName, configs } = props

  useEffect(() => {
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }, [])
  const redirectTo = _get(configs, 'behavior.redirectTo', '')

  if (redirectTo) {
    return (<Redirect to={redirectTo} />)
  }

  const goTo = (e, link) => {
    e.stopPropagation()
    const { history: { push } } = props
    push(link)
  }

  return (
    <div className='body-main'>
      <Notifications />
      <div className='body-panel-spacing res'/>
      <div className='text-center'>
        <h2 style={{ color:'white' }}>Welcome to THE<strong>TICKET</strong>FAIRY</h2>
        <h4>What would you like to do today, {displayName}?</h4>
        <div className="res" style={{ height:80 }}/>
        <div className='accordion'>
          <div className="manage_brands" onClick={(e) => goTo(e, '/brands')}>
            <div className="tab_content">
              <div className='tab_button' onClick={(e) => goTo(e, '/brands/new')}>
                <img src = {asset('/resources/images/btn-add.png')}/>
              </div>
              <div className='tab_label'>
                <div className='type'> Manage Brands</div>
                <div className='description'> Create and manage your brands</div>
              </div>
            </div>
            <div className="tab_icon">
              <img src = {asset('/resources/images/icon-main-brand.png')}/>
            </div>
          </div>
          <div className="manage_events" onClick={(e) => goTo(e, '/events')}>
            <div className="tab_content">
              <div className='tab_button' onClick={(e) => goTo(e, '/events/new')}>
                <img src = {asset('/resources/images/btn-add.png')}/>
              </div>
              <div className='tab_label'>
                <div className='type'> Manage Events</div>
                <div className='description'> Create and manage your events</div>
              </div>
            </div>
            <div className='tab_icon'>
              <img src = {asset('/resources/images/icon-main-event.png')}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default connect(state => ({
  displayName: _get(state.auth, 'user.firstName', ''),
}))(HomePage)
