/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-multi-comp */
// Deprecated -- use Formik's Field
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'

export default class Field extends React.PureComponent {
  static propType = {
    label: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    onSelected: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.handleClick = this.handleClick.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.focusInput = this.focusInput.bind(this)
    this.inputRef = React.createRef()
  }
  handleOptionSelected(o) {
    const { onSelected } = this.props
    if (onSelected) {
      onSelected(o)
    }
  }
  handleFocus(e) {
    this.setState({ focused: true })
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }
  handleBlur(e) {
    this.setState({ focused: false })
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }
  handleClick(e) {
    const { onClick } = this.props
    if (onClick) {
      onClick(e)
    }
  }
  focusInput() {
    if (this.inputRef.current.focus) {
      this.inputRef.current.focus()
    }
  }
  preventWheel(e) {
    e.preventDefault()
  }

  componentDidMount() {
    const { type } = this.props

    if (type === 'number') {
      this.inputRef.current &&
        this.inputRef.current.addEventListener('wheel', e => this.preventWheel(e), { passive: false })
    }
  }

  componentWillUnmount() {
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('wheel', this.preventWheel)
    }
  }

  render() {
    const {
      label,
      loading,
      size,
      hint,
      options,
      children,
      className,
      isAutoSlug,
      autoFocus,
      separator,
      suggestion,
      inputContainerClass,
      readOnly,
      customError,
      helperText,
      ...input
    } = this.props
    const { error, touched } = input
    const hasError = error && touched
    const value = input.value === null || input.value === undefined ? '' : input.value
    const active =
      this.state.focused ||
      (value !== null && value !== undefined) ||
      (this.refs.input && this.refs.input.value)
    const before = []
    const after = []
    const rest = []

    React.Children.forEach(children, c => {
      if (!React.isValidElement(c)) {
        rest.push(c)
      } else if (c.type === FieldPrefixButton || c.type === FieldPrefixAddon) {
        before.push(c)
      } else if (c.type === FieldSuffixButton || c.type === FieldPrefixAddon) {
        after.push(c)
      } else {
        rest.push(c)
      }
    })

    const formGroupClass = classNames(
      'form-group',
      size === 'small' ? 'form-group-sm' : '',
      size === 'large' ? 'form-group-lg' : '',
      hasError ? 'has-error' : '',
      input.type === 'showOnlyError' ? 'showOnlyError' : '',
    )
    const fieldClass = classNames(
      className,
      'floating-field-group',
      loading ? 'loading' : '',
      active ? 'active' : '',
      !!isAutoSlug ? 'form-inAutoSlug' : '',
    )
    const props = { className: 'form-control', ...input }
    const descriptors = []

    let errorNode
    const errorNodeId = input.id ? `${input.id}_error` : ''
    if (hasError) {
      errorNodeId && descriptors.push(errorNodeId)
      errorNode = (
        <div id={errorNodeId} className="help-block">
          {error}
        </div>
      )
    }

    let hintNode
    const hintNodeId = input.id ? `${input.id}_hint` : ''
    if (hint) {
      hintNode = (
        <small id={hintNodeId} className="hint">
          {hint}
        </small>
      )
    }

    if (descriptors.length) {
      props['aria-describedby'] = descriptors.join(' ')
    }

    const optionNodes = (options || []).map(o => {
      const { id, label, sub } = o
      return (
        <li
          className="card-block control-option"
          key={id}
          onMouseDown={this.handleOptionSelected.bind(this, o)}
          style={this.props.itemStyle}
        >
          <div>{label}</div>
          {sub && (
            <div className="text-muted">
              <small>{sub}</small>
            </div>
          )}
        </li>
      )
    })

    let staticControl
    if (input.type === 'hidden') {
      staticControl = (
        <div className="form-control form-control-static" onClick={this.handleClick} aria-hidden={true}>
          {value}
        </div>
      )
    }

    const { onSelected, itemStyle, listStyle, ...restProps } = props
    const tagProps = { ...restProps, value: `${restProps.value}` || '' }
    delete tagProps.error
    delete tagProps.touched

    return (
      <div className={formGroupClass} style={this.props.style}>
        {input.type !== 'showOnlyError' && (
          <div className={fieldClass}>
            {!!before.length && <div className="floating-field-addons">{before}</div>}
            <div className={`floating-field ${inputContainerClass || ''}`}>
              <input
                {...tagProps}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                autoFocus={autoFocus}
                readOnly={readOnly}
                ref={this.inputRef}
              />
              {staticControl}
              {optionNodes.length ? (
                <div className="control-options" style={this.props.listStyle}>
                  <ul className="card card-list list-unstyled">{optionNodes}</ul>
                </div>
              ) : null}
              <label aria-hidden htmlFor={props.id} className="control-label" onClick={this.handleClick}>
                {label} {!readOnly && <span className="fa fa-fw fa-pencil" />} {hintNode}
              </label>
              <div className={loading ? 'loader loading' : 'loader'} />
            </div>
            {!!after.length && <div className="floating-field-addons">{after}</div>}
          </div>
        )}
        {errorNode}
        {input.type !== 'showOnlyError' && <div className="form-group-addons">{rest}</div>}
        {customError && touched ? (
          <div className="bottom-error" style={{ marginTop: 0 }}>
            {customError}
          </div>
        ) : null}
        {helperText ? <div className="bottom-helper-text">{helperText}</div> : null}
      </div>
    )
  }
}

class FieldButton extends React.Component {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>
  }
}

class FieldPrefixButton extends FieldButton {}
class FieldSuffixButton extends FieldButton {}

Field.PrefixButton = FieldPrefixButton
Field.SuffixButton = FieldSuffixButton

class FieldAddon extends React.Component {
  render() {
    const cs = classNames(this.props.className, 'input-group-addon')
    return (
      <div {...this.props} className={cs}>
        {this.props.children}
      </div>
    )
  }
}
class FieldPrefixAddon extends FieldAddon {}
class FieldSuffixAddon extends FieldAddon {}

Field.PrefixAddon = FieldPrefixAddon
Field.SuffixAddon = FieldSuffixAddon
