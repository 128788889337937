import React from 'react'
import { Element } from 'react-scroll'
import Card from '../../../../_library/Card'
import _get from 'lodash/get'

import BrandRow from './BrandRow'
import NameRow from './NameRow'
import UrlRow from './UrlRow'
import DatesRow from './DatesRow'
import SaleDatesRow from './SaleDatesRow'
import TimeZoneRow from './TimeZoneRow'
import AgeRow from '../../EventForm/EventDetailsAgeRow'

export default class OnlineEventDetails extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.values.startDate &&
      prevProps.values.startDate !== this.props.values.startDate &&
      !this.props.values.endDate
    ) {
      this.props.updateField('endDate', this.props.values.startDate)
    }
  }
  render() {
    const {
      values,
      errors,
      touched,
      isNew,
      organizations,
      cardsStatus,
      updateField,
      onClickNewBrand,
      onBrandChange,
      configs,
    } = this.props

    return (
      <Card
        icon={'fa-info'}
        title={'Event Details'}
        className="create-event"
        status={cardsStatus && cardsStatus.details}
      >
        <BrandRow
          onChange={onBrandChange}
          values={values}
          organizations={organizations}
          onClickNewBrand={onClickNewBrand}
        />
        <NameRow updateField={updateField} />
        <UrlRow
          isNew={isNew}
          values={values}
          errors={errors}
          touched={touched}
          updateField={updateField}
          configs={_get(configs, 'children.UrlRow')}
        />
        <Element name="scroll-to-date" />
        <div className="div-spacing-20" />
        <DatesRow />
        <SaleDatesRow />
        <TimeZoneRow values={values} />
        <AgeRow values={values} updateField={updateField} />
      </Card>
    )
  }
}
