import { createAsyncAction } from '../actions'
import { sendTwilioSMS } from '../../core/http_services/twilio'

const { SMS_TICKET_HOLDERS } = createAsyncAction('SMS_TICKET_HOLDERS', function (eid, form) {
  return async dispatch => {
    try {
      const resData = await sendTwilioSMS(eid, form)
      dispatch(this.success(resData))
      return resData
    } catch (error) {
      throw error
    }
  }
})

export { SMS_TICKET_HOLDERS }
