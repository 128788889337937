import React, { Component } from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import _values from 'lodash/values'
import _sortBy from 'lodash/sortBy'
import TimeSlotBlock, { dataSort, dateTimeSort } from './TimeSlotBlock'
import { DAY_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from '../../../constants/timeFormats'

class SelectTimeSlots extends Component {
  constructor(props) {
    super(props)
    this.generateDefaultOptionsList()
    this.ids = []
    this.state = {
      rowsData: [],
      allValues: {}
    }
  }

  componentDidMount = () => {
    const { timeSlotTickets, onChange } = this.props
    this.createRow()
    const ids = timeSlotTickets.map(item => item.id)
    this.ids = ids
    onChange(ids)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { allValues, rowsData } = this.state
    const { timeSlotTickets, onChange } = this.props
    if (prevState.rowsData.length && !rowsData.length) {
      this.createRow()
    }

    if (allValues !== prevState.allValues) {
      let filteredData = []
      let data = [...timeSlotTickets]
      const objToArr = Object.entries(allValues)
      objToArr.forEach(([key, item]) => {
        item.forEach((value1, index) => {
          if (value1.values.length) {
            data = data.filter(filterValue => _includes(value1.values, filterValue[value1.key]))
          }

          if (item.length - 1 === index) {
            filteredData = [...filteredData, ...data]
            data = [...timeSlotTickets]
          }
        })
      })
      const ids = filteredData.map(item => item.id)
      if (ids.length) {
        onChange(_uniq(ids))
      } else {
        onChange(this.ids)
      }
    }
  }

  createRow = () => {
    const { rowsData } = this.state
    this.setState({
      rowsData: [
        ...rowsData,
        {
          Component: TimeSlotBlock,
          id: new Date().valueOf()
        }
      ]
    })
  }

  deleteRow = id => {
    const { rowsData, allValues } = this.state
    const rows = [...rowsData]
    const values = [..._values(allValues)]
    const filteredRows = _filter(rows, item => item.id !== id)
    const filteredValues = _filter(values, item => item.id !== id)
    this.setState({
      rowsData: filteredRows,
      allValues: filteredValues
    })
  }

  generateDefaultOptionsList = () => {
    const { timeSlotTickets, groupedTickets } = this.props
    const defaultOptionsList = {
      typeOptions: [],
      dateOptions: [],
      timeOptions: []
    }
    groupedTickets.forEach(item => {
      defaultOptionsList.typeOptions.push({ label: item.groupName, value: item.groupName })
    })
    timeSlotTickets.forEach(item => {
      defaultOptionsList.dateOptions.push({ label: item.date, value: item.date })
      defaultOptionsList.timeOptions.push({ label: item.time, value: item.time })
    })

    defaultOptionsList.typeOptions = dataSort(_uniqBy(defaultOptionsList.typeOptions, 'value'))
    defaultOptionsList.dateOptions = dateTimeSort(_uniqBy(defaultOptionsList.dateOptions, 'value'), DAY_DISPLAY_FORMAT)
    defaultOptionsList.timeOptions = dateTimeSort(_uniqBy(defaultOptionsList.timeOptions, 'value'), TIME_DISPLAY_FORMAT)

    this.defaultOptionsList = defaultOptionsList
  }

  onTimeSlotsValuesChange = (values, id) => {
    const { allValues } = this.state

    this.setState({
      allValues: {
        ...allValues,
        [id]: values
      }
    })
  }
  renderTimeSlotBlocks = () => {
    const { defaultOptionsList } = this
    const { rowsData } = this.state
    const { groupedTickets, timeSlotTickets } = this.props
    return _map(rowsData, ({ Component, id }, index) => (
      <Component
        key={id}
        positionIndex={index}
        componentsCount={rowsData.length - 1}
        timeSlotTickets={timeSlotTickets}
        groupedTickets={groupedTickets}
        defaultOptionsList={defaultOptionsList}
        id={id}
        onChange={this.onTimeSlotsValuesChange}
        addBlock={this.createRow}
        deleteBlock={this.deleteRow}
      />
    ))
  }
  render() {
    return (
      <div className="time-slot-messaging-section">
        <div className="title-block">Holders of specific tickets</div>
        {this.renderTimeSlotBlocks()}
      </div>
    )
  }
}

export default SelectTimeSlots
