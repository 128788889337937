import React, { useCallback, useEffect, useState } from 'react'
import { BackgroundSignUp } from '../newsignup/BackgroundSignUp'
import { Progress } from '../newsignin/Progress'
import { Steps } from '../newsignupcreateaccount/Steps'
import { withRouter } from 'react-router'
import { Titles } from '../newsignin/Titles'
import { Logo } from '../newsignin/Logo'
import PayoutModeForm from './PayoutModeForm'
import { connect } from 'react-redux'
import { showAxiosError } from '../../utils/messenger'
import { useMediaQuery } from '../../../_common/core/utils'
import { SET_HAS_PAYOUT_MODE } from '../../../_common/redux/brands/actions'
import { 
  FETCH_BRAND, 
  UPDATE_BRAND, 
  FETCH_BRAND_PAYMENT_DATA, 
  APPLY_PAYMENT_METHOD 
} from '../../../_common/redux/brands/actions'
import _get from 'lodash/get'
import { PreLoader } from '../../_library/PreLoader'
import { movedToHomeBySettingPaymentDetails } from '../../../_common/core/gtag'

const InviteTeamPage = ({ 
  SET_HAS_PAYOUT_MODE, 
  FETCH_BRAND,
  UPDATE_BRAND,
  FETCH_BRAND_PAYMENT_DATA,
  APPLY_PAYMENT_METHOD,
  history,
  isRnDomain,
  brandPaymentData,
  isApplying,
  isLoading,
  location: { state }
}) => {
  const brandId = state?.brandId || Number(localStorage.getItem('signUpBrandId'))
  const [brand, setBrand] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingStripe, setLoadingStripe] = useState(true)
  const handleSubmit = async (form, hasStripe) => {
    try {
      if (hasStripe) {
        isProd() && fbq('trackCustom', 'CustomerConnectedStripeAccountSuccesfully', { brandId })
        SET_HAS_PAYOUT_MODE(true)
        history.push('/')
        return
      }
      const response = await UPDATE_BRAND(brandId, { ...form })
      isProd() && fbq('trackCustom', 'CustomerUpdatedPaymentDetailsSuccesfully', { brandId })
      SET_HAS_PAYOUT_MODE(true)
      history.push('/')
      movedToHomeBySettingPaymentDetails()
      return response
    } catch(e) {
      showAxiosError(e)
      throw e
    }
  }
  const fetchPaymentData = useCallback(async () => {
    try {
      const res = await FETCH_BRAND_PAYMENT_DATA(brandId)
      setLoadingStripe(false)
      return res
    } catch(e) {
      setLoadingStripe(false)
      throw e
    }
  }, [brandId, FETCH_BRAND_PAYMENT_DATA])
  useEffect(() => {
    fetchPaymentData()
  }, [fetchPaymentData])
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const resBrand = await FETCH_BRAND(brandId)
        const brand = _get(resBrand, 'data')
        setBrand(brand)
        setLoadingUser(false)
      } catch(e) {
        setLoadingUser(false)
      }
    }
    fetchBrands()
  }, [
    FETCH_BRAND, 
    brandId
  ])
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (loadingUser || loadingStripe) ? <PreLoader isRnDomain={isRnDomain} /> : (
    <div className="absolute-container payout-mode">
      <Logo />
      <Progress width="60%" />
      <Titles text={isMobile ? 'Sign Up as an Event Organizer' : undefined} />
      <Steps
        elements={[
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Account' : "Create Your Account", 
            iconChildren: <i className="fa fa-check" />
          },
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Brand' : 'Create Your Brand', 
            iconChildren: <i className="fa fa-check" />
          },
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Team' : 'Invite Your Team',
            iconChildren: <i className="fa fa-check" />
          },
          { 
            classNameText: 'step-text-current', 
            classNameIcon: 'step-current', 
            text: 'Payout Mode', 
            iconChildren: "4" 
          }
        ]}
      />
      <BackgroundSignUp>
        {brand?.$original ? (
          <PayoutModeForm 
            onSubmit={handleSubmit} 
            history={history} 
            SET_HAS_PAYOUT_MODE={SET_HAS_PAYOUT_MODE} 
            initialValues={brand.$original} 
            isRnDomain={isRnDomain}
            APPLY_PAYMENT_METHOD={APPLY_PAYMENT_METHOD}
            brandPaymentData={brandPaymentData}
            isApplying={isApplying}
            isLoading={isLoading}
            brand={brand}
            fetchPaymentData={fetchPaymentData}
          />
        ) : null}
      </BackgroundSignUp>
    </div>
  )
}

const CreateInviteTeamFormRedux = connect(state => {
  const { isRnDomain } = state.shared
  const brandPaymentData = _get(state, 'brands.brandPaymentData')
  const isLoading = state.loading.has('FETCH_BRAND_PAYMENT_DATA')
  const isApplying = state.loading.has('APPLY_PAYMENT_METHOD')
  return {
    isRnDomain,
    brandPaymentData,
    isLoading,
    isApplying,
  }
}, {
  SET_HAS_PAYOUT_MODE,
  FETCH_BRAND,
  UPDATE_BRAND,
  FETCH_BRAND_PAYMENT_DATA,
  APPLY_PAYMENT_METHOD
})(InviteTeamPage)

const CreateInviteTeamFormRouter = withRouter(CreateInviteTeamFormRedux)

export { CreateInviteTeamFormRouter }
