// Re-render Optimization 14.05.2020
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import React from 'react'

import Notification from './Notification'
import { DISMISS } from '../../../_common/redux/notification/actions'

@connect(
  state => ({
    notifications: state.notifications,
  }),
  { DISMISS },
)
export default class Notifications extends React.PureComponent {
  render() {
    const { notifications, DISMISS } = this.props

    if (_isEmpty(notifications)) {
      return null
    }

    const nodes = _map(notifications, ({ id, level, message }) => (
      <div key={id}>
        <Notification level={level} message={message} onClose={() => DISMISS(id)} />
      </div>
    ))

    return <div className="alerts">{nodes}</div>
  }
}
