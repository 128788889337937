import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { connect } from 'react-redux'

//actions
import { SHOPIFY_SET_CONNECT_INFO } from '../../_common/redux/shopify/actions'

@withRouter
@connect(
  null,
  { SHOPIFY_SET_CONNECT_INFO }
)
class ShopifyRedirectPage extends React.Component {
  constructor(props) {
    super(props)
    const { history: { push } } = props
    this.shopifyType = props.match.params.shopifyType
    const { ...info } = queryString.parse(props.location.search)
    const { path, shopifyType, id } = JSON.parse(decodeURI(info.state))
    props.SHOPIFY_SET_CONNECT_INFO(id, shopifyType, info)
    push({
      pathname: path,
      hash: '#shopify'
    })
  }
  render() {
    return null
  }
}

export { ShopifyRedirectPage }