import React from 'react'
import Card from '../../../../_library/Card'
import FileUploader from '../../../../_library/FileUploader'

class OpenGraphImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { previewOnBackground: false }
  }

  handleChangeImage = (img, fieldName) => {
    const { updateField } = this.props
    updateField(fieldName, img || '')
  }


  render() {
    const {
      values,
      closed = false,
      errors,
    } = this.props
    const {
      ogImage,
      twitterImage
    } = values
    return (
      <Card
        closed={closed}
        icon={'fa-camera'}
        title={'Social Media Link Share Images'}
      >
        <div className="row">
          <div className="col-xs-6 col-12">
            <span>Facebook Open Graph Image</span>
            <FileUploader
              id="ogImage"
              label={'For the best display, use 1200 x 630 pixels'}
              filetype="image"
              value={ogImage}
              onChange={(img) => this.handleChangeImage(img, 'ogImage')}
            />
            {
              errors.ogImage ? <small className='text-danger'>{errors.ogImage}</small> : null
            }
          </div>
          <div className="col-xs-6 col-12">
            <span>Twitter Card Image</span>
            <FileUploader
              id="twitterImage"
              label={'Use a ratio of 2:1 between 300 x 157 and 4096 x 4096 pixels'}
              filetype="image"
              value={twitterImage}
              onChange={(img) => this.handleChangeImage(img, 'twitterImage')}
            />
            {
              errors.twitterImage ? <small className='text-danger'>{errors.twitterImage}</small> : null
            }
          </div>
        </div>
      </Card>
    )
  }
}

export default OpenGraphImages
