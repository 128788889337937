import React from 'react'
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _isEmpty from 'lodash/isEmpty'
import { Chart } from '../../_library/Chart'
import EmptyBar from '../../_library/EmptyBar'

export default class CheckInByStaffMember extends React.Component {
  render() {
    const { names } = this.props
    const chart_names = (
      <Chart
        width="100%"
        height="250px"
        chartConfig={{
          type: 'pie',
          displayPercentage: false,
          data: {
            labels: _keys(names),
            datasets: [
              {
                data: _values(names)
              }
            ]
          },
          options: {
            title: {
              text: 'By Staff Member',
              display: false
            }
          }
        }}
      />
    )

    const total_checked_in_name = _reduce(
      names,
      (sum, n) => sum + n,
      0
    )
    const rows_names = _map(names, (value, index) => (
      <tr key={index}>
        <td className="text-left">{index}</td>
        <td className="text-right">{value}</td>
      </tr>
    ))

    return (
      <div style={{ margin: 10 }}>
        {!_isEmpty(names) && (
          <div className="row">
            <div className="col-xs-12">{chart_names}</div>
          </div>
        )}
        <div className="table-caption">
          <img src={asset('/resources/images/icon-people.png')} className="icon" />
          Staff Members
        </div>
        {_isEmpty(names) ? (
          <EmptyBar />
        ) : (
          <div className="row">
            <div className="col-xs-12">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th className="text-right">No. of Tickets</th>
                  </tr>
                </thead>
                <tbody>{rows_names}</tbody>
                <tfoot>
                  <tr>
                    <td className="text-left">Total:</td>
                    <td className="text-right">{total_checked_in_name}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
      </div>
    )
  }
}
