import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import LoadingBar from '../../../_library/LoadingBar'
import ReleaseBreakdownComponent from './ReleaseBreakdownComponent'

export default class PerformanceReleaseBreakdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingReleaseBreakdownData: true
    }
  }

  componentDidMount() {
    this.fetchReleaseBreakdown()
    this.fetchReleaseBreakdownId = setInterval(async () => {
      this.fetchReleaseBreakdown()
    }, 10 * 1000)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.fetchReleaseBreakdownId)
  }

  fetchReleaseBreakdown = async () => {
    const { event } = this.props
    const section = 'release_breakdown'
    try {
      const res = await getPerfomanceData(event.id, section)
      const releaseBreakdownData = _get(res, section) || []
      const parsedData = _map(releaseBreakdownData, item => ({
        ...item,
        tags: item.tags ? JSON.parse(item.tags) : []
      }))
      this.handleResponse(parsedData)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  handleResponse = (releaseBreakdownData, error) => {
    const { isLoadingReleaseBreakdownData } = this.state

    if (this.isUnmounted) return
    isLoadingReleaseBreakdownData &&
      this.setState({
        isLoadingReleaseBreakdownData: false
      })

    if (error) {
      console.log(error)
    } else {
      this.setState({ releaseBreakdownData })
    }
  }

  render() {
    const { event, tickets } = this.props
    const { isLoadingReleaseBreakdownData, releaseBreakdownData } = this.state
    return isLoadingReleaseBreakdownData ? (
      <LoadingBar title={`Hold tight! We're getting your event's sales details...`} />
    ) : (
      <ReleaseBreakdownComponent event={event} tickets={tickets} data={releaseBreakdownData} />
    )
  }
}
