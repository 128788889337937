import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_MC_LISTS } = createAsyncAction('FETCH_MC_LISTS', function (originType, id) {
  const url = `/api/${originType}/${id}/relationships/mailchimp_lists/`
  return dispatch =>
    fetchAPI(url)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { id, mailchimp: res }
        dispatch(this.success(out))
        return res
      })
})

const { CONNECT_TO_MAILCHIMP } = createAsyncAction('CONNECT_TO_MAILCHIMP', function (id, type, return_uri) {
  const url = type === 'brand' ? 'api/brand_connect_account' : '/api/connect_account'
  const body = {
    data: {
      id,
      return_uri,
      provider: 'mailchimp',
      type,
    },
  }
  return dispatch =>
    fetchAPI(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { EMAIL_TICKET_HOLDERS } = createAsyncAction('EMAIL_TICKET_HOLDERS', function (eid, form) {
  const body = {
    data: {
      ...form,
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/messaging/send_email`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export { FETCH_MC_LISTS, CONNECT_TO_MAILCHIMP, EMAIL_TICKET_HOLDERS }
