import _get from 'lodash/get'

export const APPROVED_VERIFIED = 'APPROVED_VERIFIED'
export const VERIFICATION_MATCH = 'MATCH'
export const VERIFICATION_NO_MATCH = 'NO_MATCH'

// Verification Statuses for Admin Panel
export const FULL_VERIFIED = 'FULL_VERIFIED'
export const PARTLY_VERIFIED = 'PARTLY_VERIFIED'
export const NOT_VERIFIED = 'NOT_VERIFIED'
export const PENDING = 'PENDING'

export const checkIdVerificationStatus = verificationData => {
  const status = _get(verificationData, 'status') || ''
  const adminVerified = _get(verificationData, 'adminVerified')
  const validity = _get(verificationData, 'identityVerification.validity')
  const similarity = _get(verificationData, 'identityVerification.similarity')

  if ((status === APPROVED_VERIFIED && validity && similarity === VERIFICATION_MATCH) || adminVerified) {
    return FULL_VERIFIED
  } else if (status === PENDING) {
    return PENDING
  } else if (
    status !== APPROVED_VERIFIED &&
    !validity &&
    similarity !== VERIFICATION_MATCH &&
    !adminVerified
  ) {
    return NOT_VERIFIED
  } return PARTLY_VERIFIED
}

export const isFullVerified = verificationData => checkIdVerificationStatus(verificationData) === FULL_VERIFIED

export const isPendingVerification = verificationData => checkIdVerificationStatus(verificationData) === PENDING
