import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  promoterGenericInvitation: null,
  invitations: [],
  eventMembers: [],
  brandMembers: [],
  tourMembers: [],
}

const REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT = createAsyncHandlers(
  'REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT',
  {
    success(state, action) {
      state.promoterGenericInvitation = { ...state.promoterGenericInvitation, token: action.payload }
    },
  },
)

const FETCH_EVENT_PROMOTER_GENERIC_INVITATION = createAsyncHandlers(
  'FETCH_EVENT_PROMOTER_GENERIC_INVITATION',
  {
    success(state, action) {
      state.promoterGenericInvitation = action.payload
    },
  },
)

const FETCH_EVENT_TEAM_INVITATIONS = createAsyncHandlers('FETCH_EVENT_TEAM_INVITATIONS', {
  success(state, action) {
    const { data } = action.payload
    state.invitations = data.$original.attributes
  },
})

const UPDATE_EVENT_PROMOTER_GENERIC_INVITATION = createAsyncHandlers(
  'UPDATE_EVENT_PROMOTER_GENERIC_INVITATION',
  {
    success(state, action) {
      const { data } = action.payload
      state.promoterGenericInvitation = {
        ...state.promoterGenericInvitation,
        status: data.$original.attributes.status,
      }
    },
  },
)

const FETCH_EVENT_TEAM_MEMBERS = createAsyncHandlers('FETCH_EVENT_TEAM_MEMBERS', {
  success(state, action) {
    const { data } = action.payload

    const eventMembers = data
      ?.filter(m => m.$relationships.permissions.findIndex(p => p.attributes.scope === 'event') > -1)
      .map(m => {
        const p = m.$relationships.permissions.find(p => p.attributes.scope === 'event')
        return { id: p.id, ...m.$original.attributes, ...p.attributes }
      })

    const brandMembers = data
      ?.filter(m => m.$relationships.permissions.findIndex(p => p.attributes.scope === 'brand') > -1)
      .map(m => {
        const p = m.$relationships.permissions.find(p => p.attributes.scope === 'brand')
        return { id: p.id, ...m.$original.attributes, ...p.attributes }
      })

    const tourMembers = data
      ?.filter(m => m.$relationships.permissions.findIndex(p => p.attributes.scope === 'tour') > -1)
      .map(m => {
        const p = m.$relationships.permissions.find(p => p.attributes.scope === 'tour')
        return { id: p.id, ...m.$original.attributes, ...p.attributes }
      })

    state.eventMembers = eventMembers
    state.brandMembers = brandMembers
    state.tourMembers = tourMembers
  },
})

export default handleActions(
  {
    ...FETCH_EVENT_PROMOTER_GENERIC_INVITATION,
    ...FETCH_EVENT_TEAM_INVITATIONS,
    ...FETCH_EVENT_TEAM_MEMBERS,
    ...REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT,
    ...UPDATE_EVENT_PROMOTER_GENERIC_INVITATION,
  },
  initialState,
)
