import _filter from 'lodash/filter'
import _get from 'lodash/get'
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import EmptyBar from '../_library/EmptyBar'
import LoadingBar from '../_library/LoadingBar'
import { FETCH_TOURS } from '../../_common/redux/tours/actions'

import {
  JSONDatatable,
  TYPE_FROM_ARRAY,
  SEARCHBAR,
  DATATABLE,
  PAGINATIONBAR,
} from '../_library/JSONDatatable'
import { getTitle } from '../utils/getTitle'

@connect(
  state => ({
    tours: state.tours.tours,
    initial: state.tours.initial,
    uid: _get(state.auth, 'user.id', ''),
  }),
  { FETCH_TOURS },
)
export default class ToursPage extends React.PureComponent {
  state = {
    hasError: false,
  }
  componentDidMount() {
    const { FETCH_TOURS, configs } = this.props
    FETCH_TOURS().catch(() => {
      this.setState({ hasError: true })
    })
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  componentDidUpdate(prevProps) {
    const { FETCH_TOURS, uid: nid } = this.props
    const { hasError } = this.state
    const { uid: pid } = prevProps
    if (pid !== nid && nid && !hasError) {
      FETCH_TOURS().catch(() => {
        this.setState({ hasError: true })
      })
    }
  }

  getFilteredRows = (rows, search) => {
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) !== -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if (keyword !== '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += isFound(item.displayName, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getSortedRows = (rows_filtered, sort) => rows_filtered

  getTableData = (datatable, rows_filtered, sort) => {
    const redirectPath = '/details'

    const rows = rows_filtered.map(tour => (
      <tr key={tour.id}>
        <td>
          <div className="tour-details">
            <div className="tour-name">
              <Link to={'/tour/' + tour.id + redirectPath}>{tour.displayName}</Link>
            </div>
            <div className="tour-events">{tour.events.length} Events</div>
          </div>
        </td>
        <td>
          <Link className="btn btn-blue event-button-sm" to={'/tour/' + tour.id + redirectPath}>
            {' '}
            <i className="fa fa-cog" />
            Manage Tour
          </Link>
        </td>
      </tr>
    ))

    return rows_filtered.length ? (
      <div className="tours-table">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Tour Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    ) : (
      <EmptyBar />
    )
  }

  render() {
    const { initial, tours } = this.props
    const { hasError } = this.state
    const toursFiltered = _filter(tours, tour => tour.id !== undefined)
    let content = null
    if (initial && !hasError) {
      content = <LoadingBar title={"Hold tight! We're getting your tour list..."} />
    } else if (toursFiltered.length && !hasError) {
      content = (
        <JSONDatatable
          ref="JSONDatatable"
          type={TYPE_FROM_ARRAY}
          data={toursFiltered}
          getSortedRows={this.getSortedRows}
          getFilteredRows={this.getFilteredRows}
          getTableData={this.getTableData}
          usePagination={true}
          paginationPageSize={10}
          loadingBarTitle={"Hold tight! We're getting your tour list..."}
          saveSearchKey={'ToursPage'}
        >
          <div
            className="filters-size right inline filter-box-"
            ref={SEARCHBAR}
            hasSearch
            autoFocus
            triggerScroll
            labelTotalCount="Number of Matching Tours"
          />
          <div ref={DATATABLE} className="card-no-margin-top" />
          <div ref={PAGINATIONBAR} />
        </JSONDatatable>
      )
    } else {
      content = <EmptyBar />
    }

    return (
      <div className="body-main">
        <div className="body-panel-header">
          <div className="left">
            <div className="title">Tours</div>
          </div>
          <div className="right">
            <Link className="btn btn-success btn-shadow" to="/tours/new">
              <i className="fa fa-fw fa-plus" /> Create Tour
            </Link>
          </div>
        </div>
        <div className="body-panel-spacing" />
        <div className="body-panel-content">{content}</div>
      </div>
    )
  }
}
