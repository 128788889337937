import React from 'react'
import Button from '../../../_library/Button'

export default class ActionsRow extends React.Component {
  render() {
    const { isSubmitting, isSaveButtonDisabled, handleSubmit, onCancel } = this.props
    return (
      <div className="row">
        <div className="col-xs-12 btn-toolbar-right btn-toolbar">
          {onCancel && (
            <Button
              className="btn btn-danger btn-lg btn-shadow"
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              {`Cancel`}
            </Button>
          )}
          <Button
            className="btn btn-success btn-lg btn-shadow"
            type="submit"
            loading={isSubmitting}
            disabled={isSaveButtonDisabled}
            onClick={handleSubmit}
          >
            {`Save`}
          </Button>
        </div>
      </div>
    )
  }
}
