import _get from 'lodash/get'
import _result from 'lodash/result'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'

import * as fb from '../../_common/core/fb'
import modalStyle from '../../_common/core/modalStyle'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import Notifications from '../_library/notifications/Notifications'

import EventForm from './form/EventForm/index'
import BrandForm from '../brands/BrandForm'
import { CREATE_EVENT } from '../../_common/redux/events/actions'
import { CREATE_BRAND, FETCH_BRANDS } from '../../_common/redux/brands/actions'
import { physicalEventCreationFormError } from '../../_common/core/sentry'
import { getTitle } from '../utils/getTitle'


const createBrandModalStyleElementId = 'create-brand-modal-styles'

@withRouter
@connect(
  state => {
    const { user } = state.auth
    const { brands } = state.brands
    const { isRnDomain } = state.shared
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
    const globalCountries = state.shared.countries

    return {
      brands,
      user,
      isRnDomain,
      dynamicConfigs,
      globalCountries
    }
  },
  { CREATE_EVENT, CREATE_BRAND, FETCH_BRANDS }
)
export default class EventNew extends React.PureComponent {
  constructor(props) {
    super(props)

    this.createdBrand = null
    this.state = {
      showNewBrand: false,
      resetedDescriptions: [],
    }
    fbq('trackCustom', 'CreateEventStart')
    form_helper_reset()
  }
  componentDidMount() {
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)

    this.props.FETCH_BRANDS()

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }
  }

  componentWillUnmount() {
    form_helper_reset()
    this.isUnmounted = true
  }
  routerWillLeave = () => {
    if (localStorage.hasOwnProperty('draftEvent')) {
      localStorage.removeItem('draftEvent')
    }

    return !form_helper_isEditted()
  }

  updateResetedDescriptions = value => {
    const { resetedDescriptions } = this.state
    if (!_isEqual(resetedDescriptions, value)) {
      this.setState(() => ({
        resetedDescriptions: value
      }))
    }
  }

  onClickNewBrand = () => {
    this.setState(() => ({ showNewBrand: true }))
    this.toggleCreateBrandModalStyles()
  }

  onCancelNewBrand = () => {
    this.setState(() => ({ showNewBrand: false }))
    this.toggleCreateBrandModalStyles()
  }

  toggleCreateBrandModalStyles = () => {
    const styleElement = document.getElementById(createBrandModalStyleElementId)
    if(styleElement) {
      document.head.removeChild(styleElement)
    } else {
      const newStyleElement = document.createElement('style')
      newStyleElement.id = createBrandModalStyleElementId
      document.head.appendChild(newStyleElement)
    }
  }

  handleSubmit = (form) => {
    fbq('trackCustom', 'CreateEventSubmit')
    const { history: { push }, CREATE_EVENT } = this.props
    // fix default anti-scalping settings error
    form.attributes.flagNameChecks =
      form.attributes.flagNameChecks == null ? true : form.attributes.flagNameChecks
    form.attributes.flagIDRequired =
      form.attributes.flagIDRequired == null ? true : form.attributes.flagIDRequired
    form.attributes.flagResaleEnabled =
      form.attributes.flagResaleEnabled == null ? true : form.attributes.flagResaleEnabled
    form.attributes.flagCollectNames =
      form.attributes.flagCollectNames == null ? true : form.attributes.flagCollectNames

    const venueKeys = [
      'city',
      'country',
      'flagDisabled',
      'flagHidden',
      'googlePlaceId',
      'latitude',
      'longitude',
      'postalCode',
      'state',
      'street',
      'streetNumber',
      'hideUntil'
    ]
    const venue = _pick(form.attributes, venueKeys)
    venue.displayName = form.attributes.venueName

    const formData = {
      ...form,
      attributes: {
        ..._omit(form.attributes, [...venueKeys, 'venueName']),
        venue
      }
    }

    return Promise.resolve(CREATE_EVENT(formData))
      .then(event => {
        const id = _get(event, 'id')
        fbq('trackCustom', 'CreateEventSuccessful', {
          eventId: id
        })
        const next = id ? `/event/${id}/tickets` : '/events'
        form_helper_reset()
        push(next)
        return event
      })
      .catch(err => {
        if (err?.response?.data) {
          physicalEventCreationFormError(err.response.data)
        } else {
          physicalEventCreationFormError({ err: err.message })
        }
      })
  }

  handleBrandSubmit = (form) => {
    const { CREATE_BRAND, FETCH_BRANDS } = this.props
    return Promise.resolve(CREATE_BRAND({ ...form }))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(() => {
        FETCH_BRANDS()
        this.createdBrand = form.attributes.displayName
        this.setState(() => ({ showNewBrand: false }))
        this.toggleCreateBrandModalStyles()
      })
  }

  render() {
    const { isRnDomain, brands, dynamicConfigs, user, globalCountries, configs } = this.props
    let selectedBrand = null
    if (this.createdBrand) {
      const found = _find(brands, { displayName: this.createdBrand })
      if (!!found) {
        this.createdBrand = null
        selectedBrand = found.id
      }
    }

    const initialValues = {}
    const { showNewBrand, resetedDescriptions } = this.state

    return (
      <div className="body-main">
        <Notifications />
        <RouteLeavingGuard
          routerWillLeave={this.routerWillLeave}
        />
        <div>
          <div>
            <Modal
              className="event-details modal-trans"
              style={update(modalStyle, { content: { maxHeight: { $set: '100%' } } })}
              isOpen={showNewBrand}
              contentLabel="Modal"
              onRequestClose={this.onCancelNewBrand}
              closeTimeoutMS={150}
              ariaHideApp={false}
              id="event-detail-create-brand"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">Create Brand</div>
                  <div className="modal-body">
                    <BrandForm
                      user={user}
                      isNew={true}
                      closed={true}
                      onSubmit={this.handleBrandSubmit}
                      onCancel={this.onCancelNewBrand}
                      submitLabel="Create Brand"
                      initialValues={null}
                      isRnDomain={isRnDomain}
                      containerId='event-detail-create-brand'
                      configs={_get(configs, 'children.BrandForm')}
                    />
                  </div>
                </div>
              </div>
            </Modal>
            <EventForm
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              user={user}
              isRnDomain={isRnDomain}
              organizations={brands}
              selectedBrand={selectedBrand}
              onClickNewBrand={this.onClickNewBrand}
              isNew={true}
              updateResetedDescriptions={this.updateResetedDescriptions}
              resetedDescriptions={resetedDescriptions}
              dynamicConfigs={dynamicConfigs}
              globalCountries={globalCountries}
              configs={_get(configs, 'children.EventForm')}
            />
          </div>
        </div>
      </div>
    )
  }
}
