import React from 'react'
import Button from '../../_library/Button'

// constants
import { EMAIL_TEMPLATE_TYPES } from '../../constants/emailTemplatesTypes'

export class EmailTemplateRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      template: props.template,
      enabling: false,
      disabling: false,
    }

    this.previewContent = this.previewContent.bind(this)
    this.confirmDisable = this.confirmDisable.bind(this)
    this.confirmEnable = this.confirmEnable.bind(this)
    this.edit = this.edit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.template) !== JSON.stringify(nextProps.template)) {
      this.setState({
        template: nextProps.template,
        enabling: false,
        disabling: false,
      })
    }
  }

  previewContent() {
    this.props.previewContent()
  }

  confirmEnable() {
    this.props.confirm('Please confirm that you want to enable this email template.', this.enable.bind(this))
  }

  enable() {
    this.makeEnabling()
    this.props
      .enable()
      .then(() => {
        this.clearEnabling()
        const { template } = this.state
        template.enabled = '1'
        this.setState({
          template,
        })
      })
      .catch(() => {
        this.clearEnabling()
      })
  }

  makeEnabling() {
    this.setState({
      enabling: true,
    })
  }

  clearEnabling() {
    this.setState({
      enabling: false,
    })
  }

  confirmDisable() {
    this.props.confirm(
      'Please confirm that you want to disable this email template.',
      this.disable.bind(this),
    )
  }

  disable() {
    this.makeDisabling()
    this.props
      .disable()
      .then(() => {
        this.clearDisabling()
        const { template } = this.state
        template.enabled = '0'
        this.setState({
          template,
        })
      })
      .catch(() => {
        this.clearDisabling()
      })
  }

  makeDisabling() {
    this.setState({
      disabling: true,
    })
  }

  clearDisabling() {
    this.setState({
      disabling: false,
    })
  }

  edit() {
    this.props.edit()
  }

  render() {
    const { template, enabling, disabling } = this.state
    const templateEnabled = template.enabled.toString()

    return (
      <tr>
        <td>
          <div
            className="emailtemplates-status"
            style={{ background: templateEnabled === '0' ? 'red' : 'green' }}
          />
        </td>
        <td>{template.name}</td>
        <td>{template.description}</td>
        <td>{template.subject}</td>
        <td>{EMAIL_TEMPLATE_TYPES[template.type]}</td>
        <td className="text-right nowrap-text-container">
          <Button
            className="btn btn-blue btn-shadow"
            onClick={this.previewContent}
            disabled={templateEnabled === '0'}
          >
            <i className="fa fa-tags" aria-hidden="true" />
            Preview
          </Button>
          {templateEnabled === '1' && (
            <Button className="btn btn-danger btn-shadow" onClick={this.confirmDisable} disabled={disabling}>
              <i className="fa fa-minus-circle" aria-hidden="true" />
              Disable {disabling && <i className="fa fa-circle-o-notch fa-fw fa-spin" />}
            </Button>
          )}
          {templateEnabled === '0' && (
            <Button className="btn btn-success btn-shadow" onClick={this.confirmEnable} disabled={enabling}>
              <i className="fa fa-check-circle" aria-hidden="true" />
              Enable {enabling && <i className="fa fa-circle-o-notch fa-fw fa-spin" />}
            </Button>
          )}
          <Button
            className="btn btn-default btn-shadow"
            onClick={this.edit}
            disabled={templateEnabled === '0'}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
            Edit
          </Button>
        </td>
      </tr>
    )
  }
}
