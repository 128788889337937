//merge function
const adaptCredentialsAndRules = (credData, rulesData) => {
  const data = credData.attributes
  rulesData.attributes.forEach(ruleItem => {
    data.forEach(credItem => {
      if (credItem.id === ruleItem.badge_id) {
        ruleItem.rule.forEach(item => {
          credItem.attributes[item.type] = item.metaData
        })
        credItem.attributes.hasRules = true
        credItem.attributes.ruleId = ruleItem.id
      }
    })
  })
  return data
}

const adaptDeactivateActivateResult = (newData, oldData) => {
  oldData.forEach((element, index) => {
    if (+element.id === +newData.id) {
      oldData[index].attributes = { ...element.attributes, ...newData.attributes }
    }
  })
  return oldData
}

const credentialDeleteAdapter = (id, data) => {
  data.forEach((element, index) => {
    if (element.id === id) {
      data.splice(index, 1)
    }
  })
  return data
}

const adaptCreatedCredentialWithRule = (data, credential, allData) => {
  data.attributes.rule = JSON.parse(data.attributes.rule)
  data.attributes.rule.forEach(item => {
    credential.attributes[item.type] = item.metaData
  })
  allData.push(credential)
  return allData
}

const adaptUpdatedCredential = (item, allData) => {
  allData.forEach((e, index) => {
    if (+e.id === +item.id) {
      allData[index] = { ...allData[index], ...item }
    }
  })
  return allData
}
const adaptUpdateCredentialWithCreateRule = (data, credential, allData) => {
  data.attributes.rule = JSON.parse(data.attributes.rule)
  allData.forEach((e, index) => {
    if (+e.id === +data.attributes.badgeId) {
      const rules = {}
      data.attributes.rule.map((item, index) => {
        rules[item.type] = item.metaData
        return item
      })
      allData[index].attributes = { ...allData[index].attributes, ...rules }
    }
  })
  return allData
}

const adaptUpdateCredentialWithUpdateRule = (data, allData) => {
  data.attributes.rule = JSON.parse(data.attributes.rule)
  allData.forEach((e, index) => {
    if (+e.id === +data.attributes.badgeId) {
      const rules = {}
      data.attributes.rule.forEach((item, index) => {
        rules[item.type] = item.metaData
      })
      allData[index].attributes = { ...allData[index].attributes, ...rules, hasRules: true, ruleId: data.id }
    }
  })
  return allData
}

export {
  adaptCredentialsAndRules,
  adaptDeactivateActivateResult,
  credentialDeleteAdapter,
  adaptCreatedCredentialWithRule,
  adaptUpdatedCredential,
  adaptUpdateCredentialWithCreateRule,
  adaptUpdateCredentialWithUpdateRule,
}
