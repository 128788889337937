import React from 'react'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _keys from 'lodash/keys'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { Chart } from '../../_library/Chart'
import EmptyBar from '../../_library/EmptyBar'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT, DAY_FORMAT, TIME_FORMAT_SHORT } from '../../constants/timeFormats'

export default class CheckInHourly extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'dateTime',
        label: 'Time',
        disableNormalizerOnCopy: true,
        normalizer: value => (
          <span>
            <span className="text-large">{moment(value, 'YYYY-MM-DD HH').format(TIME_FORMAT_SHORT)}</span> /
            {` ${moment(value, 'YYYY-MM-DD HH').format(DAY_DISPLAY_FORMAT)}`}
          </span>
        )
      },
      { key: 'quantity', label: 'No. of Tickets' }
    ])
    this.footbarColumns = ['quantity']

    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  getTableData = () => {
    const { data } = this.props

    const dataArray = _map(_keys(data), item => ({
      dateTime: item,
      date: moment(item, 'YYYY-MM-DD HH').format(DAY_FORMAT),
      time: moment(item, 'YYYY-MM-DD HH').format(TIME_FORMAT_SHORT),
      quantity: data[item]
    }))

    const sortedData = _sortBy(dataArray, ['date'])
    const dataGroupedByDate = _groupBy(sortedData, item => item.date)

    this.setState({
      tableData: dataGroupedByDate
    })
  }

  render() {
    const { data } = this.props
    const { tableData } = this.state

    return (
      <div style={{ margin: 10 }}>
        <div className="daily-title">
          Check-Ins by <strong>HOUR</strong>
        </div>
        {_isEmpty(tableData) ? (
          <EmptyBar />
        ) : (
          <div>
            {_map(tableData, (dataItem, key) => (
              <div key={key} style={{ marginBottom: 30 }}>
                <div className="row">
                  <div className="col-xs-12">
                    <Chart
                      width={'100%'}
                      height="300px"
                      chartConfig={{
                        type: 'bar',
                        data: {
                          labels: _map(dataItem, item => item.time),
                          datasets: [
                            {
                              data: _map(dataItem, item => item.quantity)
                            }
                          ]
                        },
                        options: {
                          legend: {
                            display: false
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <br />
                <div className="table-caption">
                  <img src={asset('/resources/images/icon-timer.png')} className="icon" />
                  {moment(key).format(DAY_DISPLAY_FORMAT)}
                </div>
                <SortableTable
                  data={dataItem}
                  dataForCopy={_map(dataItem, item => ({ dateTime: item.time, quantity: item.quantity }))}
                  tableColumns={this.tableColumns}
                  enableCopyTable={true}
                  enableSort={true}
                  footbar={{
                    label: 'Total:',
                    columns: this.footbarColumns
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
