import React from 'react'
import _identity from 'lodash/identity'
import RichTextEditor from '../../_library/RichTextArea'

const RichTextAreaField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  onChange: _onChange = _identity,
  ...props
}) => {
  const onChange = value => {
    setFieldValue(field.name, normalizer(value))
    _onChange(field.name, normalizer(value))
  }
  const onBlur = () => {
    const { value, name } = field
    setFieldValue(name, normalizer(value))
    touchedOnBlur && setFieldTouched(name, normalizer(value))
  }
  return (
    <div>
      <RichTextEditor
        {...field}
        {...props}
        id={field.name}
        touched={touched[field.name]}
        error={errors[field.name]}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { RichTextAreaField }
