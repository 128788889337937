import React, { PureComponent } from 'react'

import { getDayAndHour } from './SelectTicket'
import Select from '../../_library/Select'

const getDaysHoursMap = (groupedTickets) => {
  const map = new Map()
  groupedTickets.forEach((group) => {
    group.forEach(el => {
      const [d, h] = getDayAndHour(el.startDate)
      if (map.has(d)) {
        map.get(d).add(h)
      } else {
        map.set(d, new Set([h]))
      }
    })
  })
  return map
}

const getDaysArray = (map) => Array.from( map.keys() )
const getDaysOptions = (daysArray) => daysArray.map(el => getOption(el, el))
const getHoursArray = (set) => set ? [...set] : []
const getHoursOptions = (hoursArray) => hoursArray.map(el => getOption(el, el))
const getOption = (value='', label='') => ({ value, label })

class SelectSlotDate extends PureComponent {
  render() {
    const { tickets, dayValue='', hourValue='', onSelectDay, onSelectHour } = this.props
    const [, groupedTickets] = ticketTypesSorting(tickets)
    const map = getDaysHoursMap(groupedTickets)
    if (!map.size) {
      return  null
    }
    const daysArray = getDaysArray(map)
    const daysOptions = [getOption(), ...getDaysOptions(daysArray)]

    const hoursArray = getHoursArray(map.get(dayValue))
    const hoursOptions = [getOption(), ...getHoursOptions(hoursArray)]

    return (
      <div>
        <div className="col-sm-6 col-12">
          <Select
            options={daysOptions}
            value={dayValue}
            label='Select Day'
            onChange={(e) => onSelectDay(e.target.value)}
          />
        </div>
        <div className="col-sm-6 col-12">
          <Select
            disabled={!dayValue}
            options={hoursOptions}
            value={hourValue}
            label='Select Time'
            onChange={(e) => onSelectHour(e.target.value)}
          />
        </div>
      </div>
    )
  }
}

export default SelectSlotDate
