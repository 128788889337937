import React from 'react'
import { BackgroundSignUp } from '../newsignup/BackgroundSignUp'
import { Progress } from '../newsignin/Progress'
import { Steps } from '../newsignupcreateaccount/Steps'
import { withRouter } from 'react-router'
import { Titles } from '../newsignin/Titles'
import { Logo } from '../newsignin/Logo'
import InviteTeamForm from './InviteTeamForm'
import { connect } from 'react-redux'
import { sendBrandInvitation } from '../../../_common/core/http_services'
import { showAxiosError } from '../../utils/messenger'
import { useMediaQuery } from '../../../_common/core/utils'
import { SET_HAS_TEAM_INVITATION } from '../../../_common/redux/brandteam/actions'
import { movedToPayoutBySendingInvitations } from '../../../_common/core/gtag'

const InviteTeamPage = ({ 
  SET_HAS_TEAM_INVITATION,
  history,
  location: { state }
}) => {
  const brandId = state?.brandId || Number(localStorage.getItem('signUpBrandId'))
  const handleSubmit = async form => {
    try {
      const res = await sendBrandInvitation(brandId, form.invitations)
      isProd() && fbq('trackCustom', 'CustomerSentInvitationsSuccesfully', { brandId })
      movedToPayoutBySendingInvitations()
      localStorage.setItem('signUpBrandId', String(brandId))
      history.push('/welcome/payout-mode', {
        brandId
      })
      SET_HAS_TEAM_INVITATION(true)
      return res
    } catch (e) {
      showAxiosError(e)
      throw e
    }
  }
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <div className="absolute-container invite-team">
      <Logo />
      <Progress width="60%" />
      <Titles text={isMobile ? 'Sign Up as an Event Organizer' : undefined} />
      <Steps
        elements={[
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Account' : "Create Your Account", 
            iconChildren: <i className="fa fa-check" />
          },
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Brand' : 'Create Your Brand', 
            iconChildren: <i className="fa fa-check" />
          },
          {
            classNameText: 'step-text-current',
            classNameIcon: 'step-current',
            text: isMobile ? 'Team' : 'Invite Your Team',
            iconChildren: '3'
          },
          { 
            classNameText: 'step-text-next', 
            classNameIcon: 'step-next', 
            text: 'Payout Mode', 
            iconChildren: "4" 
          }
        ]}
      />
      <BackgroundSignUp>
        <InviteTeamForm 
          onSubmit={handleSubmit} 
          history={history} 
          SET_HAS_TEAM_INVITATION={SET_HAS_TEAM_INVITATION} 
          brandId={brandId}
        />
      </BackgroundSignUp>
    </div>
  )
}

const CreateInviteTeamFormRedux = connect(null, {
  SET_HAS_TEAM_INVITATION
})(InviteTeamPage)

const CreateInviteTeamFormRouter = withRouter(CreateInviteTeamFormRedux)

export { CreateInviteTeamFormRouter }
