import React, { useState } from 'react'
import { Field, Formik, Form } from 'formik'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import Button from '../../_library/Button'
import { SelectField } from '../../formik/Fields'
import IssueAddOnTable from './IssueAddOnTable'
import { arrayFromNum } from '../../utils/arrayUtils'
import { showAxiosError } from '../../utils/messenger'

const ADD_ON_SELECT_MAX_VALUE = 10

const IssueAddOnForm = props => {
  const { addOns = [], onCancel, submitLabel = 'Save', handleSubmit } = props
  const [addOnQuantity, setAddOnQuantity] = useState([])
  const [collectedAddOns, setCollectedAddOns] = useState([])

  const showCollectedAddOnsTable = Boolean(collectedAddOns.length)
  const addOnOptions = _map(addOns, item => ({ label: item.name, value: item.id }))

  const handleAdd = (values, resetForm) => {
    const selectedAddOn = _find(addOns, { id: values.addOn })
    const alreadyCollected = _find(collectedAddOns, { name: selectedAddOn.name })
    let updatedArr = []

    if (alreadyCollected) {
      updatedArr = _map(collectedAddOns, item => {
        if (item.name === selectedAddOn.name) {
          const newQuantity = Math.min(
            selectedAddOn.stock ?? Number.MAX_SAFE_INTEGER, // in case of add-on stock is unlimited
            Number(item.quantity) + Number(values.quantity),
          )
          return { ...item, quantity: newQuantity }
        }
        return item
      })
    } else {
      updatedArr = [
        ...collectedAddOns,
        { id: values.addOn, name: selectedAddOn.name, quantity: values.quantity },
      ]
    }

    setCollectedAddOns(updatedArr)
    setAddOnQuantity([])
    resetForm()
  }

  const onDeleteCollectedAddOn = row => {
    const updatedArr = _filter(collectedAddOns, item => item.name !== row.name)
    setCollectedAddOns(updatedArr)
  }

  return (
    <Formik
      initialValues={{
        addOn: '',
        quantity: 1,
      }}
      onSubmit={async (_, { setSubmitting }) => {
        try {
          await handleSubmit(collectedAddOns)
        } catch (err) {
          showAxiosError(err)
        } finally {
          setSubmitting(false)
        }
      }}
      render={({ values, isSubmitting, resetForm }) => (
        <Form autoComplete="off" className="form_holder">
          <div className="row">
            <div className="col-xs-6">
              <Field
                name="addOn"
                label="Add-On"
                component={SelectField}
                options={addOnOptions}
                defaultOption={{ value: '', label: 'Select...' }}
                onChange={(fieldName, value) => {
                  const selectedAddOn = _find(addOns, { id: value })
                  const maxQuantity = selectedAddOn ? selectedAddOn.stock ?? ADD_ON_SELECT_MAX_VALUE : 0
                  const optionsArray = arrayFromNum(maxQuantity)
                  setAddOnQuantity(optionsArray)
                }}
              />
            </div>
            <div className="col-xs-6">
              <Field
                name="quantity"
                label="Quantity"
                component={SelectField}
                options={addOnQuantity}
                disabled={!addOnQuantity.length}
              />
            </div>
            <div className="col-xs-12">
              <Button
                type="button"
                className="btn btn-success"
                onClick={() => handleAdd(values, resetForm)}
                disabled={!(values.addOn && addOnQuantity.length)}
              >
                <i className="fa fa-fw fa-plus" />
                Add
              </Button>
            </div>
            <div className="div-spacing-20" />
          </div>
          {showCollectedAddOnsTable && (
            <IssueAddOnTable data={collectedAddOns} onDeleteRow={onDeleteCollectedAddOn} />
          )}
          <div className="text-center btn-toolbar">
            <Button
              type="submit"
              className="btn btn-primary btn-lg btn-shadow"
              loading={isSubmitting}
              disabled={!collectedAddOns.length}
            >
              {submitLabel}
            </Button>
            {onCancel && (
              <Button
                className="btn btn-cancel btn-lg btn-shadow"
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </div>
        </Form>
      )}
    />
  )
}

export default IssueAddOnForm
