import React, { useState } from 'react'
import _forEach from 'lodash/forEach'
import _values from 'lodash/values'
import { getTableColumns } from '../../utils/sortableTableUtils'
import CustomToggle from '../../_library/CustomToggle'
import SortableTable from '../../_library/SortableTable'
import SelectionModal from './SelectionModal'

const TrackingTable = params => {
  const {
    data,
    handleDeleteClick,
    handleRegenerateClick,
    addons,
  } = params
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectionData, setSelectionData] = useState(null)

  const columns = getTableColumns([
    { key: 'name', label: 'Name' },
    {
      key: 'token',
      label: 'Tracking Link',
      enableItemCopy: true,
      className: 'copy_text_width',
      normalizer: value => `${window.location.host}/track-sales/${value}`
    }
  ])
  const renderTags = (data = []) => {
    const items = data || []
    const styles = {
      position: 'relative',
      backgroundColor: '#24282f',
      borderRadius: '2px',
      color: ' #E9E9E9',
      display: 'inline-block',
      fontSize: '13px',
      fontWeight: '400',
      marginBottom: '5px',
      marginRight: ' 5px',
      marginLeft: '5px',
      padding: '5px 10px'
    }
    return (
      <div>
        {items.map((item, index) => (
          <div key={`items_tag_${index}`} style={styles}>
            {item}
          </div>
        ))}
      </div>
    )
  }

  const tableActions = [
    {
      label: 'Regenerate Link',
      className: 'btn btn-success',
      icon: 'fa fa-undo fa-fw',
      onClick: data => handleRegenerateClick(data)
    },
    {
      label: 'Show Selection',
      className: 'btn btn-seablue',
      icon: 'fa fa-plus fa-fw',
      onClick: data => handleShowSelection(data)
    },
    {
      label: 'Delete',
      className: 'btn btn-danger',
      icon: 'fa fa-trash fa-fw',
      onClick: data => handleDeleteClick(data)
    }
  ]
  const handleShowSelection = data => {
    setSelectionData(data)
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setSelectionData(null)
    setModalOpen(false)
  }

  const generateSelectionContent = () => {
    if (!selectionData) return null

    const selectionDataItems = selectionData.items
    const namesArr = [ ..._values(selectionDataItems.ticketTypeNames, [])]

    if (selectionDataItems.ticketGroupNames) {
      _forEach(selectionDataItems.ticketGroupNames, ticketGroupName => {
        namesArr.push(`${ticketGroupName} - Slots`)
      })
    }

    const ticketTagsData = namesArr.map(item => (
      <div key={`ticket ${item}`}>{item}</div>
    ))

    const addonsData = selectionData.items.addOnIds || []

    const addonTagsData = addonsData.map(item => <div key={`add_on ${item}`}>{addons.find(item1 => item1.id === item).name || ''}</div>)

    return (
      <div className="selection_content">
        <div className="col-sm-12 col-xs-12" style={{ paddingLeft: 0, paddingBottom: 20 }}>
          <CustomToggle
            name="hideDateBreakdowns"
            id="hideDateBreakdowns"
            onText="Hide Breakdown by Date"
            disabled={true}
            value={selectionData.hideDateBreakdowns}
            checked={selectionData.hideDateBreakdowns}
          />
        </div>
        {!!ticketTagsData.length && (
          <div className="selection_block" style={{ padding: '0 10px' }}>
            <h4 className="selection_title">Ticket Type</h4>
            {renderTags(ticketTagsData)}
          </div>
        )}
        {!!addonTagsData.length && (
          <div className="selection_block" style={{ marginTop: 20, padding: '0 10px' }}>
            <h4 className="selection_title">Add-on</h4>
            {renderTags(addonTagsData)}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <SortableTable
        data={data}
        tableColumns={columns}
        actions={tableActions}
        actionsClassName="text-right"
        editModalComponent={
          <SelectionModal
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            selectionContent={generateSelectionContent()}
          />
        }
      />
    </div>
  )
}

export default TrackingTable
