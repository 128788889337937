import React from 'react'
import Modal from 'react-modal'

import modalStyle from '../../../../_common/core/modalStyle'
import Button from '../../../_library/Button'

const ConfirmSaveModal = ({ saveSortDialogOpen, handleCancelSave, handleSaveSort }) => (
  <Modal
    className="modal-dialog modal-trans"
    style={modalStyle}
    isOpen={saveSortDialogOpen}
    contentLabel="Modal"
    onRequestClose={handleCancelSave}
    closeTimeoutMS={150}
    ariaHideApp={false}
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div>
          <div className="modal-header">
            <p className="h4 text-compact">Are you sure?</p>
          </div>
          <div className="modal-body">
            <p>Add-Ons table has been modified. Save current changes?</p>
          </div>
          <div className="modal-footer">
            <div className="btn-toolbar btn-toolbar-right">
              <Button className="btn btn-primary" type="button" onClick={handleSaveSort}>
                Save
              </Button>
              <Button className="btn btn-default" type="button" onClick={handleCancelSave}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)

export default ConfirmSaveModal
