import React from 'react'
import _get from 'lodash/get'

import { Field } from 'formik'
import { SimpleField, CustomToggleField, TagsField } from '../../../../formik/Fields'
import { arrToInt, toNumber } from '../../../../../_common/core/validation'

export default class DonationsSection extends React.Component {
  onDonationTypesChange = type => {
    const { values, updateField } = this.props
    const donationDetails = _get(values, 'donationDetails')
    const isTypeChanged = donationDetails.type !== type

    updateField('donationDetails', {
      ...donationDetails,
      type: isTypeChanged ? type : ''
    })
  }

  render() {
    const { values, errors, touched, updateField, submitCount } = this.props
    const isDonationEnabled = values.flagDonation
    const donationType = _get(values, 'donationDetails.type')
    const isDonationChoice = donationType === 'choice'
    const isDonationFixed = donationType === 'fixed'

    return (
      <div className="col-sm-8 col-12">
        {errors.donationType && submitCount ? (
          <Field
            name="donationType"
            type="showOnlyError"
            value={''}
            showErrorOnlyTouched={false}
            component={SimpleField}
          />
        ) : null}
        <Field
          name="flagDonation"
          key="flagDonation"
          onText={'Allow Donation'}
          component={CustomToggleField}
        />
        {isDonationEnabled && (
          <div className="col-xs-12 col-12 mw-400" style={{ paddingLeft: 50, paddingRight: 0 }}>
            <Field
              name="choice"
              key="choice"
              onText={`Amount of the user's choice`}
              value={isDonationChoice}
              checked={isDonationChoice}
              onChange={this.onDonationTypesChange}
              component={CustomToggleField}
            />
            <Field
              name="fixed"
              key="fixed"
              onText={`Fixed amounts`}
              value={isDonationFixed}
              checked={isDonationFixed}
              onChange={this.onDonationTypesChange}
              component={CustomToggleField}
            />
            {isDonationChoice && (
              <div style={{ paddingLeft: 5 }}>
                <Field
                  name="donationDetails.minAmount"
                  type="number"
                  inputContainerClass="hide-arrows"
                  placeholder={'A minimum amount (optional)'}
                  component={SimpleField}
                  normalizer={toNumber}
                />
              </div>
            )}
            {isDonationFixed && (
              <div style={{ paddingLeft: 5, paddingTop: 6, marginBottom: 20 }}>
                <Field
                  name="donationDetails.amountOptions"
                  placeholder="Add an amount"
                  tagsContainerClassName="bordered"
                  tagsInputClassName="tags-input-bordered"
                  component={TagsField}
                  flagFilterUniq={true}
                  normalizer={arrToInt}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
