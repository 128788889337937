import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _reduce from 'lodash/reduce'
import _forEach from 'lodash/forEach'
import _groupBy from 'lodash/groupBy'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { checkIsPromoter } from '../../../utils/permissions'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
} from '../../../../_common/core/validation'
import EmptyBar from '../../../_library/EmptyBar'
import { percentNormalizerCreator } from '../../../../_common/core/validation/normalizers'

export default class SalesByOrderDate extends React.Component {
  constructor(props) {
    super(props)
    const isPromoter = checkIsPromoter(props.event)

    // Tables here refers Table ticket types
    const displayTablesColumns = this.shouldDisplayTablesColumns(props.data)

    this.tableColumns = this.getColumns(props, displayTablesColumns)
    this.detailsTableColumns = this.getDetailsColumns(isPromoter, props, displayTablesColumns)

    this.footbarColumns = this.getFootbarColumns(props, displayTablesColumns)

    this.state = {
      sortBy: { column: 'orderDate', asc: true },
      rows: null,
      detailsRows: [],
    }
  }

  shouldDisplayTablesColumns = data =>
    data.reduce(
      (res, currVal) =>
        res | (currVal.depositPercent > Number.EPSILON) | (currVal.totalGuestRevenue > Number.EPSILON),
      false,
    )

  getColumns(props, includeTablesData = false) {
    const columns = [
      {
        key: 'orderDate',
        label: 'Order Date',
        normalizer: formatDay,
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
      },
      {
        key: 'totalSales',
        label: 'Running Total (No. of Sales)',
        isSortable: false,
      },
    ]

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenue',
          label: 'Revenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
        {
          key: 'revenueTotal',
          label: 'Running Total (Revenue)',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
        {
          key: 'grossRevenueTotal',
          label: 'Running Total (Gross Revenue)',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          label: 'Currently Paid',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        // {
        //   key: 'depositPercent',
        //   label: 'Deposit Percent',
        //   isSortable: true,
        //   normalizer: value =>
        //     percentNormalizerCreator(
        //       createFixedFloatNormalizer(0)(parseFloat(value))
        //     )
        // },
        {
          key: 'pendingToPay',
          label: 'Pending to Pay',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenueTotal',
          label: 'Final Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    }

    return getTableColumns(columns.filter(Boolean), getCurrencySymbol(props.event))
  }

  getDetailsColumns(isPromoter, props, includeTablesData = false) {
    const columns = [
      {
        key: 'release_type',
        label: 'Ticket Type',
        className: 'detail-cell',
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
        className: 'detail-cell',
      },
      !isPromoter && {
        key: 'stock',
        label: 'Stock',
        className: 'detail-cell',
      },
      {
        key: 'cost',
        label: 'Price (excl. Fees)',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenue',
          label: 'Revenue',
          className: 'detail-cell',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          label: 'Currently Paid',
          className: 'detail-cell',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'depositPercent',
          label: 'Deposit Percent',
          className: 'detail-cell',
          isSortable: true,
          normalizer: value => percentNormalizerCreator(createFixedFloatNormalizer(0)(parseFloat(value))),
        },
        {
          key: 'pendingToPay',
          label: 'Pending to Pay',
          className: 'detail-cell',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenue',
          label: 'Final Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
      )
    }

    return getTableColumns(columns.filter(Boolean), getCurrencySymbol(props.event))
  }

  getFootbarColumns(props, includeTablesData) {
    const columns = [{ key: 'totalSales', sourceKey: 'num_sales' }]

    if (!includeTablesData) {
      columns.push(
        {
          key: 'revenueTotal',
          sourceKey: 'revenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenueTotal',
          sourceKey: 'grossRevenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    } else {
      columns.push(
        {
          key: 'currentDeposit',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'pendingToPay',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenueTotal',
          sourceKey: 'revenue',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      )
    }

    return columns
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { groupedByOrderDate, tickets } = this.props
    if (
      !_isEqual(groupedByOrderDate, prevProps.groupedByOrderDate) ||
      !_isEqual(tickets, prevProps.tickets)
    ) {
      this.updateData()
    }
  }

  updateData = () => {
    const { groupedByOrderDate } = this.props
    const tempRows = []
    const detailsRows = []
    let totalSales = 0
    let totalRevenue = 0

    _forEach(groupedByOrderDate, (value, key) => {
      const grossRevenue = _reduce(
        value,
        (sum, item) => sum + parseInt(item.num_sales) * parseFloat(item.price),
        0,
      )
      const numSales = this.getTotalNumSales(value)
      totalSales += parseInt(numSales)
      totalRevenue += this.calculateRevenue(value)
      this.getDetailRows(groupedByOrderDate[value[0].orderDate], detailsRows)

      tempRows.push({
        id: value[0].orderDate,
        orderDate: key,
        num_sales: numSales,
        cost: value[0].cost,
        currentDeposit: this.calculateCurrentDeposit(value),
        pendingToPay: this.calculateCurrentDebt(value),
        revenue: this.calculateRevenue(value),
        revenueTotal: totalRevenue,
        totalSales,
        grossRevenue,
      })
    })

    this.setState({
      rows: _map(
        _filter(tempRows, rowItem => !!rowItem.id),
        item => ({
          ...item,
        }),
      ),
      detailsRows,
    })
  }

  calculateCurrentDeposit = (ticketsArray = []) => {
    let totalDeposit = 0

    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      const total = parseInt(item.num_sales) * parseFloat(item.cost) + guestRevenue

      let depositPercent = parseFloat(item.depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      totalDeposit += (total * depositPercent) / 100.0
    })

    return totalDeposit
  }

  getTotalNumSales = (ticketsArray = []) => {
    let totalSales = 0
    _forEach(ticketsArray, item => {
      totalSales += parseInt(item.num_sales)
    })

    return totalSales
  }

  calculateRevenue = (ticketsArray = []) => {
    let totalRevenue = 0
    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      totalRevenue +=
        parseInt(item.num_sales) * parseFloat(item.cost) - parseFloat(item.refunded_amount) + guestRevenue
    })

    return totalRevenue
  }

  calculateCurrentDebt = (ticketsArray = []) => {
    let totalDebt = 0

    _map(ticketsArray, item => {
      let guestRevenue = parseFloat(item.totalGuestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }
      const total = parseInt(item.num_sales) * parseFloat(item.cost) + guestRevenue

      let depositPercent = parseFloat(item.depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      const totalDeposit = (total * depositPercent) / 100.0

      totalDebt += total - totalDeposit
    })

    return totalDebt
  }

  calculateTotalValues = data => {
    const totalNumSales = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0)
    const totalRevenue = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].cost)
    const totalGrossRevenue =
      _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].price) -
      parseFloat(data[0].refunded_amount)

    return [totalNumSales, totalRevenue, totalGrossRevenue]
  }

  getDetailRows = (data, detailsRows) => {
    const { tickets } = this.props
    const detailRows = []

    let totalDeposit = 0
    let totalPendingToPay = 0

    const groupData = _groupBy(
      data,
      item =>
        (item.slotGroupId || item.option_matrix_record_id) + '' + item.cost + '' + (item.depositPercent || ''),
    )

    _forEach(groupData, groupItem => {
      const ticketId = groupItem[0].option_matrix_record_id
      const currentTicket = _find(tickets, item => item.id === ticketId) || { stock: 0 }
      const [totalNumSales, totalRevenue, totalGrossRevenue] = this.calculateTotalValues(groupItem)

      let totalGuestRevenue = parseFloat(groupItem[0].totalGuestRevenue)
      if (isNaN(totalGuestRevenue)) {
        totalGuestRevenue = 0
      }
      const totalRowRevenue =
        parseFloat(groupItem[0].num_sales) * parseFloat(groupItem[0].cost) -
        parseFloat(groupItem[0].refunded_amount) +
        totalGuestRevenue

      let depositPercent = parseFloat(groupItem[0].depositPercent)
      if (isNaN(depositPercent)) {
        depositPercent = 100.0
      }
      const totalRowDeposit = (totalRowRevenue * depositPercent) / 100.0

      totalDeposit += totalRowDeposit
      totalPendingToPay += totalRowRevenue - totalRowDeposit

      detailRows.push({
        id: groupItem[0].option_matrix_record_id,
        slotGroupId: groupItem[0].slotGroupId,
        orderDate: data[0].orderDate,
        num_sales: totalNumSales,
        cost: groupItem[0].cost,
        currentDeposit: totalRowDeposit,
        depositPercent: groupItem[0].depositPercent,
        pendingToPay: totalRowRevenue - totalRowDeposit,
        revenue: totalRowRevenue,
        revenueTotal: totalRevenue,
        release_type: groupItem[0].slotGroupName || groupItem[0].release_type,
        stock: parseInt(currentTicket.stock),
        grossRevenue: totalGrossRevenue,
        depositTotal: totalDeposit,
        pendingToPayTotal: totalPendingToPay,
      })
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].orderDate || detailRows[0].id,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`sales_by_order_date-${detailRowIndex}`}
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        ),
      })
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            e2e_test_id="sales_by_order_date"
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            detailsRows={detailsRows}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns,
            }}
            calculatedColumns={[
              { for: 'totalSales', column: 'num_sales' },
              { for: 'revenueTotal', column: 'revenue' },
              { for: 'grossRevenueTotal', column: 'grossRevenue' },
            ]}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
