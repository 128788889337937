import React from 'react'
import _get from 'lodash/get'
import Papa from 'papaparse'
import { Field as FormikField, Form } from 'formik'

import Field from '../../_library/Field'
import Button from '../../_library/Button'
import { Tab, TabView } from '../../_library/TabView'
import Card from '../../_library/Card'
import { SelectField, SimpleField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'
import { Recipent } from './MemoRecipent'
import { shortid } from '../../../_common/core/rand'
import { showAxiosError } from '../../utils/messenger'

export class EventPassRender extends React.Component {
  constructor(props) {
    super(props)
    this.csvInputRef = React.createRef()
    this.state = {
      csvParseCount: 0,
      csvParsing: false
    }
  }

  chunk = results => {
    const { values, setFieldValue } = this.props
    const { csvParseCount } = this.state
    const data = []
    for (let j = 0; j < results.data.length; j++) {
      if (results.data[j].length === 1 && results.data[j][0].trim() === '') {
      } else {
        data.push(results.data[j])
      }
    }
    const dd = data.map(r => ({
      id: shortid(),
      firstName: r[0] && r[0].trim(),
      lastName: r[1] && r[1].trim(),
      email: r[2] && r[2].trim() && r[2].replace('+AEA-', '@')
    }))
    values.recipients.push(...dd)
    setFieldValue('recipients', values.recipients)
    this.setState({ csvParseCount: csvParseCount + data.length })
  }

  importRecipientsCSV = e => {
    const [file] = e.target.files
    const ext = file.name.split('.').pop()
    this.props.setFieldValue('recipients', [])
    setTimeout(() => {
      if (ext === 'csv') {
        this.setState(
          {
            csvParseCount: 0,
            csvParsing: true
          },
          () => {
            Papa.parse(file, {
              chunkSize: 1024,
              chunk: this.chunk,
              complete: () => {
                this.setState({ csvParsing: false }, () => {
                  this.touchRecipientFields()
                })
              }
            })
          }
        )
      } else if (ext === 'xls' || ext === 'xlsx') {
        showAxiosError({}, 'Please upload a CSV file instead of an Excel format file')
      } else {
        showAxiosError({}, 'Please upload a CSV file')
      }
    }, 100)
  }

  touchRecipientFields = () => {
    const touched = {}
    for (let i = 0; i < this.props.values.recipients.length; i++) {
      touched[i] = {}
      touched[i].firstName = true
      touched[i].lastName = true
      touched[i].email = true
    }
    this.props.setTouched({ touched })
    this.csvInputRef.current.value = ''
  }

  selectRecipientsMode = index => {
    this.props.setFieldValue('recipients_mode', index === 1 ? 'form' : 'csv')
  }
  fileUploadAction = () => {
    this.csvInputRef.current.click()
  }

  deleteRecipient = index => {
    this.props.values.recipients.splice(index, 1)
    this.props.setFieldValue('recipients', this.props.values.recipients)
  }

  render() {
    const { values, isSubmitting, touched, errors, handleChange, handleBlur, options, submitLabel } =
      this.props
    const { csvParseCount, csvParsing } = this.state

    return (
      <Form>
        <Card icon={'fa-envelope'} title={'Options'}>
          <div className="row">
            <div className="col-xs-12">
              <FormikField
                label="Type"
                name="entrypassTypeId"
                placeholder="Type"
                component={SelectField}
                defaultOption={{ value: '', label: 'Type' }}
                options={options}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <FormikField
                className="responsive-label"
                label="Maximum number of uses per event (optional)"
                type="number"
                name="maxUsesPerEvent"
                placeholder="Maximum number of uses per event (optional)"
                component={SimpleField}
              />
            </div>
            <div className="col-xs-12">
              <FormikField
                label="Notes (optional)"
                name="issuedByNotes"
                placeholder="Notes (optional)"
                component={SimpleField}
              />
            </div>
          </div>
          <TabView all={false} onSelectTab={this.selectRecipientsMode}>
            <Tab title="Enter Recipient">
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="First name"
                    type="text"
                    id="recipient.firstName"
                    value={values.recipient.firstName}
                    error={errors.recipient && errors.recipient.firstName}
                    touched={touched.recipient && touched.recipient.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="Last name"
                    type="text"
                    id="recipient.lastName"
                    value={values.recipient.lastName}
                    error={errors.recipient && errors.recipient.lastName}
                    touched={touched.recipient && touched.recipient.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="Email"
                    type="email"
                    id="recipient.email"
                    value={values.recipient.email}
                    error={errors.recipient && errors.recipient.email}
                    touched={touched.recipient && touched.recipient.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </Tab>
            <Tab title="Upload Recipients">
              <div className="row">
                <div className="col-sm-10 col-xs-12">
                  Recipients (CSV file with first name, last name, email address and no header row)
                </div>
                <div className="col-sm-2 col-xs-12 text-right">
                  <Button
                    className="btn btn-success btn-shadow btn-importcsv"
                    onClick={this.fileUploadAction}
                  >
                    <i className="fa fa-upload" aria-hidden="true" /> Add from CSV
                  </Button>
                </div>
              </div>
              <input
                type="file"
                accept=".csv"
                onChange={this.importRecipientsCSV}
                className="file-csv"
                ref={this.csvInputRef}
                id="file-csv"
              />
              <div className="recipients">
                <table className="table recipients-table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {csvParsing && (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                          &nbsp;&nbsp;Loaded {csvParseCount}
                        </td>
                      </tr>
                    )}
                    {values.recipients &&
                      values.recipients.map((recipent, index) => (
                        <Recipent
                          key={recipent.id}
                          first_name={_get(values, `recipients[${index}].firstName`)}
                          index={index}
                          error_first_name={_get(errors, `recipients[${index}].firstName`)}
                          touched_first_name={_get(touched, `recipients[${index}].firstName`)}
                          last_name={_get(values, `recipients[${index}].lastName`)}
                          error_last_name={_get(errors, `recipients[${index}].lastName`)}
                          touched_last_name={_get(touched, `recipients[${index}].lastName`)}
                          email={_get(values, `recipients[${index}].email`)}
                          error_email={_get(errors, `recipients[${index}].email`)}
                          touched_email={_get(touched, `recipients[${index}].email`)}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          deleteRecipient={this.deleteRecipient}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
              {(!values.recipients || values.recipients.length === 0) && (
                <div className="addnew-msg">Please Add New Recipients</div>
              )}
            </Tab>
          </TabView>
          <div className="row text-center">
            <Button
              className="btn btn-success btn-lg btn-shadow btn-send"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {submitLabel || (
                <span>
                  <i className="fa fa-paper-plane" aria-hidden="true" /> Create{' '}
                </span>
              )}
            </Button>
          </div>
        </Card>
      </Form>
    )
  }
}
