import React from 'react'
import _reduce from 'lodash/reduce'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _keys from 'lodash/keys'
import _includes from 'lodash/includes'
import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _isArray from 'lodash/isArray'
import _isEqual from 'lodash/isEqual'
import EmptyBar from '../../_library/EmptyBar'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'
import { showAxiosError } from '../../utils/messenger'
import { getCheckedInSlots } from '../../../_common/core/http_services'
import SortableTableWithChipFiltersHOC from '../../hoc/SortableTableWithChipFiltersHOC'

const SortableTableWithChipFilters = SortableTableWithChipFiltersHOC()

export default class CheckInSlotTicketType extends React.Component {
  constructor(props) {
    super(props)
    // main table
    this.tableColumns = getTableColumns([
      { key: 'date', label: 'Slot Date', formatTo: DAY_DISPLAY_FORMAT },
      { key: 'ticketsNumComponent', label: 'No. of Tickets', sort: this.sortByQuantity }
    ])
    this.footbarColumns = [{ key: 'ticketsNumComponent', component: this.getTotalComponent() }]

    // time table's columns
    this.timeTableColumns = getTableColumns([
      { key: 'time', label: 'Slot Time', formatTo: DAY_DISPLAY_FORMAT, className: 'detail-cell' },
      { key: 'ticketsNumComponent', label: 'No. of Tickets', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Tickets)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    // type table's columns
    this.typeTableColumns = getTableColumns([
      { key: 'name', label: 'Ticket Type', className: 'detail-cell' },
      { key: 'ticketsNumComponent', label: 'No. of Tickets', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Tickets)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    this.state = {
      tableData: [],
      timeDetailsRows: [],
      sortBy: { column: 'date', asc: true }
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  componentDidUpdate(prevProps) {
    const { checkInDates } = this.props

    if (!_isEqual(prevProps.checkInDates, checkInDates)) {
      this.getTableData()
    }
  }

  getTableData = () => {
    const { checkInDates } = this.props

    const tableData = _map(checkInDates, item => ({
      ...item,
      id: item.date,
      checkedInCount: parseInt(item.checkedInCount),
      ticketsNumComponent: (
        <span>
          {`${item.checkedInCount} / `}
          <strong>{item.total}</strong>
          {` (${item.total - item.checkedInCount} remaining)`}
        </span>
      )
    }))

    this.setState({ tableData })
  }

  getTotalComponent = () => {
    const { checkInDates = [] } = this.props
    const allTicketsCount = _reduce(checkInDates, (result, item) => result + parseInt(item.total), 0)
    const checkedInTicketsCount = _reduce(
      checkInDates,
      (result, item) => result + parseInt(item.checkedInCount),
      0
    )
    const remainingTicketsCount = allTicketsCount - checkedInTicketsCount

    return (
      <span>
        {`${checkedInTicketsCount} / `}
        <strong>{allTicketsCount}</strong>
        {` (${remainingTicketsCount} remaining)`}
      </span>
    )
  }

  getDateDetailRows = (data, id, timeDetailsRows, ticketTypeNames, selectedChips) => {
    const dataRows = []
    const typeDetailsRows = []

    const groupedData = _groupBy(data, item => item.time)
    _map(groupedData, (groupItem, key) => {
      this.getTypeDetailRows(groupItem, key, typeDetailsRows)
      const sumTotalCount = _reduce(groupItem, (result, item) => result + parseInt(item.total), 0)
      const sumCheckedInCount = _reduce(
        groupItem,
        (result, item) => result + parseInt(item.checkedInCount),
        0
      )

      dataRows.push({
        id: key,
        time: key,
        checkedInCount: sumCheckedInCount,
        ticketTypes: _keys(_groupBy(groupItem, 'name')),
        ticketsNumComponent: (
          <span>
            {`${sumCheckedInCount} / `}
            <strong>{sumTotalCount}</strong>
            {` (${sumTotalCount - sumCheckedInCount} remaining)`}
          </span>
        )
      })
    })

    if (dataRows.length) {
      timeDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTableWithChipFilters
            data={dataRows}
            tableColumns={this.timeTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'checkedInCount' }]}
            enableSort={false}
            sortBy={{ column: 'time', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="dark child-table"
            detailsRows={typeDetailsRows}
            chipOptions={ticketTypeNames}
            selectedChips={selectedChips}
            isMultiSelectEnabled={true}
            onFilter={this.onFilter}
          />
        )
      })
    } else {
      timeDetailsRows.push({
        id,
        type: 'detailRow',
        component: <EmptyBar />
      })
    }
  }

  getTypeDetailRows = (data, id, typeDetailsRows) => {
    const dataRows = []
    _map(data, item => {
      dataRows.push({
        ...item,
        id,
        ticketsNumComponent: (
          <span>
            {`${item.checkedInCount} / `}
            <strong>{item.total}</strong>
            {` (${item.total - item.checkedInCount} remaining)`}
          </span>
        )
      })
    })

    if (dataRows.length) {
      typeDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.typeTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'checkedInCount' }]}
            enableSort={false}
            sortBy={{ column: 'time', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
    }
  }

  sortByQuantity = column => {
    this.setState(prevState => ({
      ...prevState,
      tableData: _orderBy(prevState.tableData, ['checkedInCount'], [prevState.sortBy.asc ? 'asc' : 'desc']),
      sortBy: { column: column.key, asc: !prevState.sortBy.asc }
    }))
  }

  getTableDataForCopy = () => {
    const { tableData } = this.state

    return _map(tableData, item => {
      const checkedInCount = parseInt(item.checkedInCount)
      const total = parseInt(item.total)
      const remainingCount = total - checkedInCount

      return {
        ...item,
        ticketsNumComponent: `${checkedInCount} / ${total} (${remainingCount} remaining)`
      }
    })
  }

  getDetailRowData = async data => {
    const { event } = this.props
    try {
      const timeDetailsRows = []
      const res = await getCheckedInSlots(event.id, data.id)
      const dataGroupedByTicketName = _groupBy(res, 'name')
      const ticketTypeNames = _keys(dataGroupedByTicketName)
      this.getDateDetailRows(res, data.id, timeDetailsRows, ticketTypeNames, [])

      this.setState(prevState => ({
        ...prevState,
        timeDetailsRows,
        selectedRowData: res,
        selectedRowId: data.id,
        ticketTypeNames
      }))
      return res
    } catch (err) {
      showAxiosError(err)
      return null
    }
  }

  onFilter = selectedChips => {
    this.setState(prevState => {
      let filteredData = [...prevState.selectedRowData]
      const timeDetailsRows = []

      if (selectedChips.length) {
        filteredData = this.handleFilter(selectedChips, filteredData, 'name')
      }

      this.getDateDetailRows(
        filteredData,
        prevState.selectedRowId,
        timeDetailsRows,
        prevState.ticketTypeNames,
        selectedChips
      )

      return {
        ...prevState,
        selectedChips,
        timeDetailsRows
      }
    })
  }

  handleFilter = (selectedChips = [], data, chipKey) =>
    _filter(data, item =>
      _some(selectedChips, chip => {
        const value = item[chipKey]
        return _isArray(value) ? _includes(value, chip) : value === chip
      })
    )

  render() {
    const { tableData, timeDetailsRows, sortBy } = this.state

    return (
      <div className="ticketType tickettype-checkin">
        <div className="table-caption">
          <img alt="nodata" src={asset('/resources/images/icon-ticket.png')} className="icon" />
          Ticket Types
        </div>
        {!!tableData.length ? (
          <SortableTable
            data={tableData}
            dataForCopy={this.getTableDataForCopy()}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            detailsRows={timeDetailsRows}
            isAsyncDetails={true}
            getDetailRowData={this.getDetailRowData}
            sortBy={sortBy}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
