import React from 'react'
import { Form, withFormik, Field } from 'formik'
import { requestDashboardAccess } from '../../../_common/core/http_services'
import { withRouter } from 'react-router'
import Button from '../../_library/Button'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'
import { SimpleField } from '../../formik/Fields'
import { movedToCreateAccount, movedToSignIn } from '../../../_common/core/gtag'
import { deleteCookieByName } from '../../utils/cookies'

const EmailForm = props => {
  const { isSubmitting, isValid } = props
  const handleGoBack = () => {
    props.history.goBack()
  }
  return (
    <Form className="margin-horizontal-25 sign-up-container">
      <Field
        type="email"
        name="email"
        label="Email"
        component={SimpleField}
        formclassname="field-wrapper"
        touchedOnBlur
        validate={combineValidators(requiredValidator(), isEmailValidator('Invalid'))} 
      />
      <div className="buttons-wrapper">
        <Button type="button" className="back-button" disabled={isSubmitting} onClick={handleGoBack}>
          Back
        </Button>
        <Button
          type="submit"
          className={!isValid ? 'submit-disabled' : 'submit-button-login'} 
          disabled={!isValid} 
          loading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </Form>
  )
}

const EmailFormFormik = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { email } = values
    try {
      await requestDashboardAccess(email)
      deleteCookieByName('X-TF-ECOMMERCE')
      props.history.push('/welcome/sign-in', { email })
      isProd() && fbq('trackCustom', 'CustomerHasAccount', { email })
      movedToSignIn()
    } catch (e) {
      props.history.push('/welcome/create-account', { email })
      movedToCreateAccount()
      isProd() && fbq('trackCustom', 'CustomerHasNoAccount', { email })
      setSubmitting(false)
    }
  },
  displayName: 'EmailForm'
})(EmailForm)

const EmailFormRouter = withRouter(EmailFormFormik)

export default EmailFormRouter
