import { withFormik, Field } from 'formik'
import React, { useState, useEffect, useRef } from 'react'
import { BackgroundSignUp } from '../newsignup/BackgroundSignUp'
import { Progress } from '../newsignin/Progress'
import { Steps } from '../newsignupcreateaccount/Steps'
import CreateBrandForm from './CreateBrandForm'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import { newBrand } from '../../../_common/core/http_services/signup'
import { NotificationError } from '../newsignin/NotificationError'
import { connect } from 'react-redux'
import { Titles } from '../newsignin/Titles'
import { FixedAddressField } from '../../formik/Fields/FixedAddressField'
import { Logo } from '../newsignin/Logo'
import { getStripeCountries } from '../../../_common/core/http_services'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _entries from 'lodash/entries'
import { useMediaQuery } from '../../../_common/core/utils'
import { SET_HAS_BRANDS } from '../../../_common/redux/brands/actions'
import { parsePlaceResult } from '../../events/form/EventForm/utils'
import { PreLoader } from '../../_library/PreLoader'
import { movedToTeamInvitations } from '../../../_common/core/gtag'

const CreateBrandPage = ({ history, location: { state }, isRnDomain, ...props }) => {
  const [loadingUser, setLoadingUser] = useState(true)
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        if (!state?.email || !state?.lastName || !state?.firstName || !state?.best_describe) {
          history.push('/welcome/create-account')
          return
        }
        const countries = await getStripeCountries()
        setCountries(
          _sortBy(
            _map(_entries(countries), ([key, value]) => ({
              value: String(key),
              label: value
            })),
            'label'
          )
        )
        setLoadingUser(false)
      } catch (e) {
        setLoadingUser(false)
      }
    }
    fetchCountries()
  }, [history, state])
  const [countries, setCountries] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [AutocompleteService] = useState(global.google ? new google.maps.places.AutocompleteService() : null)
  const [suggestions, setSuggestions] = useState([])
  const setError = () => {
    const { setFieldValue } = props
    setFieldValue('error', '')
  }
  const {
    values: { error },
    setFieldValue
  } = props
  const onClickOption = suggestion => {
    const statuses = google.maps.places.PlacesServiceStatus
    const PlacesService = new google.maps.places.PlacesService(ref.current)
    PlacesService?.getDetails({ placeId: suggestion.place_id }, (res, status) => {
      let err
      if (status === statuses.ZERO_RESULTS) {
        err = 'No address found for venue'
      } else if (status !== statuses.OK) {
        err = 'Unexpected error occurred while looking up venue address'
      }
      if (err) {
        setFieldValue('address', '')
        setFieldValue('error', err)
        return
      }
      const { city, postalCode, state } = parsePlaceResult(res, {
        isRnDomain: false,
        autoDefineVenue: false,
        useLongNames: true,
        useCountryShortName: true
      })
      const { address_components } = res
      const countryService = address_components.find(component => component.types.includes('country'))
      if (countryService) {
        const countryName = countryService.long_name
        const countryId = countries.find(country => countryName === country.label)?.value
        const countryCode = countryService.short_name
        setFieldValue('countryId', Number(countryId))
        setFieldValue('countryCode', countryCode)
        setFieldValue(
          'address',
          address_components
            .filter(
              address =>
                !(
                  address.types.includes('administrative_area_level_1') ||
                  address.types.includes('country') ||
                  address.types.includes('locality') ||
                  address.types.includes('postal_code')
                )
            )
            .reduce((curr, next) => curr + next.long_name + ', ', '')
            .slice(0, -2)
        )
      }
      if (city) {
        setFieldValue('city', city)
      }
      if (state) {
        setFieldValue('stateName', state)
      }
      if (postalCode) {
        setFieldValue('zip', postalCode)
        setFieldValue('showZipField', false)
      } else {
        setFieldValue('zip', '')
        setFieldValue('showZipField', true)
      }
    })
    setSuggestions([])
    setShowDropdown(false)
  }
  const onClickBack = () => {
    setShowDropdown(false)
  }
  const onClickCancel = () => {
    setFieldValue('address', '')
  }
  const getSuggestions = e => {
    const { value } = e.target
    const statuses = google.maps.places.PlacesServiceStatus
    AutocompleteService?.getPlacePredictions({ input: value, types: ['address'] }, (suggestions, status) => {
      if (status === statuses.ZERO_RESULTS) {
        return
      } else if (status !== statuses.OK) {
        return
      }
      setSuggestions(suggestions)
    })
  }
  const optionComponent = (suggestion, i) => (
    <div className="suggestion" key={i} onClick={() => onClickOption(suggestion)}>
      <img
        alt=""
        src={asset('/resources/images/marker-map-dot.svg')}
        className="fa-map-marker-dot color-svg-map-marker"
      />
      <img
        alt=""
        src={asset('/resources/images/marker-map.svg')}
        className="color-svg-map-marker"
        style={{ position: 'absolute', left: 20, top: 17 }}
      />
      <div style={{ width: 'calc(100% - 48px)' }}>
        <div className="suggestion-description">{suggestion.description.split(',')[0]}</div>
        <div className="suggestion-details">
          {suggestion.description.split(',').reduce((curr, next, i) => {
            if (i === 0) {
              return curr
            }
            return curr + (i === 1 ? '' : ',') + next
          }, '')}
        </div>
      </div>
    </div>
  )
  const ref = useRef(null)
  const isMobile = useMediaQuery('(max-width: 768px)')
  return loadingUser ? (
    <PreLoader isRnDomain={isRnDomain} />
  ) : (
    <div className="absolute-container create-brand">
      <div ref={ref} />
      <Logo />
      <Progress width="40%" />
      <Titles text={isMobile ? 'Sign Up as an Event Organizer' : undefined} />
      <Steps
        elements={[
          {
            classNameText: 'step-text-finished',
            classNameIcon: 'step-finished',
            text: isMobile ? 'Account' : 'Create Your Account',
            iconChildren: <i className="fa fa-check" />
          },
          {
            classNameText: 'step-text-current',
            classNameIcon: 'step-current',
            text: isMobile ? 'Brand' : 'Create Your Brand',
            iconChildren: '2'
          },
          {
            classNameText: 'step-text-next',
            classNameIcon: 'step-next',
            text: isMobile ? 'Team' : 'Invite Your Team',
            iconChildren: '3'
          },
          {
            classNameText: 'step-text-next',
            classNameIcon: 'step-next',
            text: 'Payout Mode',
            iconChildren: '4'
          }
        ]}
      />
      {showDropdown && (
        <Field
          type="address"
          name="address"
          onClickBack={onClickBack}
          onClickCancel={onClickCancel}
          component={FixedAddressField}
          options={suggestions}
          onChange={getSuggestions}
          optionComponent={optionComponent}
          autoComplete="off"
        />
      )}
      {error ? <NotificationError error={error} setError={setError} /> : null}
      <BackgroundSignUp>
        <CreateBrandForm
          setShowDropdown={setShowDropdown}
          {...props}
          AutocompleteService={AutocompleteService}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          countries={countries}
          onClickOption={onClickOption}
          getSuggestions={getSuggestions}
          history={history}
          state={state}
        />
      </BackgroundSignUp>
    </div>
  )
}

const CreateAccountFormFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
    address: '',
    legalEntity: '',
    displayName: '',
    countryId: '',
    countryCode: '',
    stateName: '',
    zip: '',
    error: '',
    city: '',
    showZipField: false
  }),
  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    delete values.error
    delete values.showZipField
    try {
      const brand = await newBrand({
        data: {
          attributes: values,
          type: 'organization'
        }
      })
      setSubmitting(false)
      localStorage.removeItem('createAccountState')
      localStorage.setItem('signUpBrandId', String(brand?.data?.id))
      props.history.push('/welcome/team-invitations', { brandId: brand?.data?.id })
      movedToTeamInvitations(values.displayName)
      props.SET_HAS_BRANDS(true)
      isProd() && fbq('trackCustom', 'CustomerCreatedBrandSuccesfully', { brandId: brand?.data?.id })
    } catch (e) {
      const error = _get(e, 'response.data.message')
      if (error) {
        setFieldValue('error', error)
      }
      setSubmitting(false)
    }
  },

  displayName: 'CreateAccountForm'
})(CreateBrandPage)

const CreateAccountFormRedux = connect(
  state => {
    const { isRnDomain } = state.shared
    return {
      isRnDomain
    }
  },
  { SET_HAS_BRANDS }
)(CreateAccountFormFormik)

const CreateAccountFormRouter = withRouter(CreateAccountFormRedux)

export { CreateAccountFormRouter }
