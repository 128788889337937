import { createAsyncAction } from '../actions'
import { ERROR } from '../notification/actions'
import { getBuyersLocations } from '../../core/http_services'

const { FETCH_EVENT_BUYERLOCATION } = createAsyncAction('FETCH_EVENT_BUYERLOCATION', function (eventId) {
  return async dispatch => {
    try {
      const buyersLocations = await getBuyersLocations(eventId)
      dispatch(this.success({ eventId, buyersLocations }))
      return buyersLocations
    } catch (err) {
      dispatch(ERROR([err]))
      dispatch(this.failed(err))
    }
  }
})

export { FETCH_EVENT_BUYERLOCATION }
