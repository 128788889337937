// Not reusable
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import SalesInfoComponent from '../events/performance/components/SalesInfoComponent'

export const Tab = () => <div>{this.props.children}</div>

export class TabView extends React.Component {
  constructor(props) {
    super(props)

    // initialize state
    this.state = {
      selectedIndex: props.selectedIndex ? props.selectedIndex : props.all ? 0 : 1,
      scrollToContent: false,
    }
  }

  navigateToTab = tabIndex => {
    this.setState({
      selectedIndex: tabIndex,
      scrollToContent: true,
    })
  }

  render() {
    const {
      all,
      allTitle,
      title,
      passProps,
      headerClassName,
      bodyClassName,
      hasBackground,
      hasPerfectScrollBar,
      event,
      performanceData,
      influencersData,
      titleWidth = '175',
    } = this.props
    const { selectedIndex, scrollToContent } = this.state
    const headers = []
    const bodies = []

    if (all) {
      if (this.props.paths) {
        headers.push(
          <Link
            key={0}
            to={this.props.basePath}
            className={'tab-title' + (selectedIndex === 0 ? ' selected' : '')}
            onClick={() => this.selectIndex(0)}
          >
            {allTitle || 'All'}
          </Link>,
        )
      } else {
        headers.push(
          <div
            aria-hidden
            key={0}
            className={'tab-title' + (selectedIndex === 0 ? ' selected' : '')}
            onClick={() => this.selectIndex(0)}
          >
            {allTitle || 'All'}
          </div>,
        )
      }
    }
    for (let i = 0; i < this.props.children.length; i++) {
      if (this.props.children[i]) {
        if (this.props.paths) {
          headers.push(
            <Link
              key={i + 1}
              to={this.props.paths[i]}
              className={'tab-title' + (selectedIndex === i + 1 ? ' selected' : '')}
              onClick={() => this.selectIndex(i + 1)}
            >
              {this.props.children[i].props.title}
            </Link>,
          )
        } else {
          headers.push(
            <div
              aria-hidden
              key={i + 1}
              className={'tab-title' + (selectedIndex === i + 1 ? ' selected' : '')}
              onClick={() => this.selectIndex(i + 1)}
            >
              {this.props.children[i].props.title}
            </div>,
          )
        }
        let childrenWithProps = null
        const isSelected = selectedIndex === 0 || selectedIndex === i + 1
        const isAllSelected = selectedIndex === 0
        if (!!passProps) {
          childrenWithProps = React.cloneElement(this.props.children[i].props.children, {
            isSelected,
            isAllSelected,
            scrollToContent,
            selectedIndex,
          })
        } else {
          childrenWithProps = this.props.children[i].props.children
        }
        bodies.push(
          <div key={i + 1} className={'tab' + (isSelected ? ' selected' : '')}>
            {childrenWithProps}
          </div>,
        )
      }
    }
    let contentHeader = headers
    if (hasPerfectScrollBar && !isMobileDevice()) {
      contentHeader = (
        <div style={{ position: 'relative', width: title ? `calc(100% - ${titleWidth}px)` : '100%' }}>
          {' '}
          <PerfectScrollbar>{headers}</PerfectScrollbar>
        </div>
      )
    }

    return (
      <div className="tab-view">
        {hasBackground && <div key="tab-background" className="tab-background" />}
        <div
          className={`tab-header ${headerClassName} ${
            isMobileDevice() && hasPerfectScrollBar ? 'responsive-tab-header' : ''
          }`}
        >
          {title && <div className="tab-view-title">{title}</div>}
          {contentHeader}
        </div>
        <SalesInfoComponent
          event={event}
          performanceData={performanceData}
          influencersData={influencersData}
          navigateToTab={this.navigateToTab}
          currentTabIndex={selectedIndex}
        />
        <div className={`tab-body ${bodyClassName}`}>
          {all && selectedIndex === 0 ? bodies : bodies[selectedIndex - 1]}
        </div>
      </div>
    )
  }

  selectIndex(i) {
    this.setState({
      selectedIndex: i,
      scrollToContent: false,
    })
    if (this.props.onSelectTab) {
      this.props.onSelectTab(i)
    }
  }
}
