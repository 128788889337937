import React from 'react'
import _map from 'lodash/map'

import { Field } from 'formik'

import Card from '../../../../_library/Card'
import CustomToggle from '../../../../_library/CustomToggle'
import { arrayToObject } from '../../EventForm/utils'
import { EVENT_TYPES } from '../../../../constants/eventTypes'
import { SimpleField } from '../../../../formik/Fields'

export default class OnlineEventType extends React.Component {
  getId = id => {
    const str = 'eventType_'
    return id.replace(str, '')
  }

  handleChangeEventType = (fieldKey, value) => {
    const { values, updateField } = this.props
    const fieldId = 'eventType'
    const id = this.getId(fieldKey)

    const objEventType = arrayToObject(values[fieldId])
    objEventType[id] = value
    updateField(fieldId, objEventType)
  }

  render() {
    const { values, cardsStatus } = this.props
    const fieldId = 'eventType'
    const objEventType = arrayToObject(values[fieldId])

    return (
      <Card icon={'fa-toggle-on'} title={'Event Type'} status={cardsStatus && cardsStatus.type}>
        <Field
          name={fieldId}
          type="showOnlyError"
          component={SimpleField}
        />
        <div className="row">
          <div
            className="col-xs-6 col-6"
            style={{ display: 'grid', gridTemplateColumns: '100% 100%' }}
          >
            {_map(EVENT_TYPES, t => (
              <CustomToggle
                key={t.id}
                id={t.id}
                onText={t.label}
                value={!!objEventType[this.getId(t.id)]}
                checked={!!objEventType[this.getId(t.id)]}
                onChange={this.handleChangeEventType}
              />
            ))}
          </div>
        </div>
      </Card>
    )
  }
}
