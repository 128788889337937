import _set from 'lodash/set'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import { Field, withFormik } from 'formik'

import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'
import LoadingBar from '../../_library/LoadingBar'
import SelectTimeSlots from '../_library/SelectTimeSlots'
import {
  // normalizers
  formatDay,
  formatTime,
} from '../../../_common/core/validation'

import { CheckboxField, MultiCheckbox } from '../../formik/Fields'
import SMSContainer from './SMSContainer'

const getDayAndHour = date => {
  const d = formatDay(date)
  const h = formatTime(date)
  return [d, h]
}
function validateForm(data) {
  const errors = {}
  const message = data.SMSBody
  if (!message) {
    _set(errors, 'SMSBody', 'Message is Required')
  }
  if (message.length > 480) {
    _set(errors, 'SMSBody', 'Maximum character count exceeded')
  }
  return errors
}

@withRouter
class SMSToTicketHolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toSelectedAddonHolders: false,
      addOnsStateInitialized: false,
      toTicketHolders: false,
      isConfirmingModalOpen: false,
      addOnsWereFetched: false,
      ticketsWereFetched: false,
      checkedAddOnsArray: [],
      selectedNoGroupedTickets: [],
      maxCharExceed: false,
      smsMaxCharError: '',
    }
    this.editorRef = React.createRef()
  }

  componentWillUpdate(nextProps) {
    if (
      !_isEqual(this.props.values, nextProps.values) &&
      !_isEqual(this.props.submitCount, nextProps.submitCount)
    ) {
      this.setState({
        toTicketHolders: !!nextProps.values.ticket_holders,
        toSelectedAddonHolders: !!nextProps.values.add_on_holders,
        selectedNoGroupedTickets: [],
      })
    }
  }

  onCheckboxChange = domEvent => {
    const isChecked = domEvent.target.checked
    if (isChecked) {
      const targetCheckboxId = domEvent.target.id
      const { setFieldValue, eventAddOns } = this.props
      const addOnsArray = _map(eventAddOns, item => item.id)
      setFieldValue('all', targetCheckboxId === 'toAllTicketHolders')
      setFieldValue('checked_in', targetCheckboxId === 'toCheckedInCustomersOnly')
      setFieldValue('opted_in', targetCheckboxId === 'toBrandSubscribers')
      setFieldValue('refunded', targetCheckboxId === 'toRefundedTicketHolders')
      setFieldValue('event_team_members', targetCheckboxId === 'toAllEventTeamMembers')
      setFieldValue('brand_team_members', targetCheckboxId === 'toAllBrandTeamMembers')
      setFieldValue('influencers', targetCheckboxId === 'toInfluencers')

      setFieldValue('ticket_holders', '')

      setFieldValue('add_on_holders', targetCheckboxId === 'toSelectedAddonHolders' ? addOnsArray : [])
      this.setState({
        toSelectedAddonHolders: targetCheckboxId === 'toSelectedAddonHolders',
        toTicketHolders: targetCheckboxId === 'toTicketHolders',
      })
      // before initaliazitation eventAddOns prop will not be available
      if (this.state.addOnsStateInitialized) {
        this.setState({
          checkedAddOnsArray: addOnsArray,
        })
      }
      this.fetchCheckboxRelatedData(targetCheckboxId)
    }
  }

  fetchCheckboxRelatedData = fieldName => {
    const { fetchEventAddOns, fetchEventTickets } = this.props
    switch (fieldName) {
      case 'toTicketHolders': {
        if (!this.state.ticketsWereFetched) {
          fetchEventTickets()
          this.setState({ ticketsWereFetched: true })
        }
        return
      }
      case 'toSelectedAddonHolders': {
        if (!this.state.addOnsWereFetched) {
          fetchEventAddOns()
          this.setState({ addOnsWereFetched: true })
        }
        return
      }
      default:
        return
    }
  }

  initializeAddOnsState = () => {
    const addOnIdsArray = _map(this.props.eventAddOns, item => item.id)
    this.setState({ checkedAddOnsArray: addOnIdsArray })
    this.props.setFieldValue('add_on_holders', addOnIdsArray)
  }

  onAddOnSelect = addOnId => () => {
    const { checkedAddOnsArray } = this.state
    const addOnIdIndex = checkedAddOnsArray.indexOf(addOnId)
    if (addOnIdIndex !== -1) {
      checkedAddOnsArray.splice(addOnIdIndex, 1)
    } else {
      checkedAddOnsArray.push(addOnId)
    }
    this.setState({ checkedAddOnsArray })
    this.props.setFieldValue('add_on_holders', checkedAddOnsArray)
  }

  handleChange = (content, tagContent) => {
    if (content?.length > 480) {
      this.setState({
        maxCharExceed: true,
        smsMaxCharError: 'Maximum character count exceeded',
      })
    } else if (this.state.maxCharExceed) {
      this.setState({
        maxCharExceed: false,
        smsMaxCharError: '',
      })
    }
    this.props.setFieldValue('SMSBodyTags', tagContent)
    this.props.setFieldValue('SMSBody', content)
  }

  setConfirmingModalState = value => () => {
    this.setState({ isConfirmingModalOpen: value })
  }

  handleSubmit = () => {
    this.setConfirmingModalState(false)()
    this.props.handleSubmit()
  }

  componentDidUpdate() {
    if (
      !this.state.addOnsStateInitialized &&
      Object.keys(this.props.eventAddOns).length &&
      this.state.toSelectedAddonHolders
    ) {
      this.setState({ addOnsStateInitialized: true })
      this.initializeAddOnsState()
    }
  }

  isSendButtonDisabled = (eventTickets, addOnsList) => {
    const { values } = this.props
    return (
      (this.state.toTicketHolders && (!values.ticket_holders.length || !eventTickets.length)) ||
      (this.state.toSelectedAddonHolders && (!values.add_on_holders.length || !addOnsList.length))
    )
  }
  getOnlyTimeSlotTickets = () => {
    const { eventTickets } = this.props
    try {
      const filteredData = _filter(eventTickets, item => {
        const [d, h] = getDayAndHour(item.startDate)
        item.time = h
        item.date = d
        return item.slotGroupId ? true : false
      })
      return filteredData
    } catch (err) {
      return []
    }
  }

  render() {
    const { isConfirmingModalOpen, toSelectedAddonHolders, toTicketHolders, maxCharExceed, smsMaxCharError } =
      this.state
    const {
      event,
      values,
      eventTickets,
      isSubmitting,
      handleBlur,
      errors,
      touched,
      submitLabel,
      eventAddOns,
      eventAddOnsLoading,
      eventTicketsLoading,
      configs,
    } = this.props

    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(eventTickets)

    const addOnsList = _map(eventAddOns, (addOn, index) => (
      <div key={index} className="line addon">
        <div className="line-cell">
          <input
            type="checkbox"
            id={`addon-${addOn.id}`}
            defaultChecked={true}
            name={addOn.id}
            onChange={this.onAddOnSelect(addOn.id)}
          />
          <label htmlFor={`addon-${addOn.id}`} />
        </div>
        <div className="line-cell" style={{ paddingLeft: 10 }}>
          <label htmlFor={`addon-${addOn.id}`}>{addOn.name}</label>
        </div>
      </div>
    ))

    const showHoldersOfSpecificAddOns = _get(configs, 'appearance.showHoldersOfSpecificAddOns')

    return (
      <form ref="form" onSubmit={e => e.preventDefault()}>
        <div className="EmailToTicketHolders">
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllTicketHolders"
                checked={values.all}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllTicketHolders">All ticket holders and RSVPs</label>
            </div>

            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toCheckedInCustomersOnly"
                checked={values.checked_in}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toCheckedInCustomersOnly">Checked in customers only</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toBrandSubscribers"
                checked={values.opted_in}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toBrandSubscribers">All opted-in ticket holders</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toTicketHolders"
                checked={toTicketHolders}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toTicketHolders">Holders of specific tickets</label>
              <br />
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllEventTeamMembers"
                checked={values.event_team_members}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllEventTeamMembers">All event team members</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllBrandTeamMembers"
                checked={values.brand_team_members}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllBrandTeamMembers">All brand team members</label>
            </div>
            {showHoldersOfSpecificAddOns && (
              <div className="col-xs-4 col-12 padding-bottom10">
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  id="toSelectedAddonHolders"
                  checked={toSelectedAddonHolders}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="toSelectedAddonHolders">Holders of specific add-ons</label>
              </div>
            )}
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toRefundedTicketHolders"
                checked={values.refunded}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toRefundedTicketHolders">Holders of refunded tickets</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toInfluencers"
                checked={values.influencers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toInfluencers">All Influencers</label>
            </div>
            <div className="col-xs-12 col-12" />
            {toTicketHolders &&
              (eventTickets.length ? (
                <div
                  style={{ marginLeft: '5px' }}
                  className={'col-xs-12 col-12 tickets ' + (values.ticket_holders ? 'show' : '')}
                >
                  <Field
                    name="status_checked_in"
                    label="Only Checked in"
                    component={CheckboxField}
                    checked={values.status_checked_in}
                    containerProps={{ style: { marginLeft: '0' } }}
                    labelProps={{ style: { margin: '5px 0' } }}
                  />
                  {noGroupedTickets.length ? (
                    <div className="no-grouped-tickets">
                      <MultiCheckbox
                        options={_map(noGroupedTickets, e => ({
                          value: e.id,
                          label: e.displayName,
                        }))}
                        value={this.state.selectedNoGroupedTickets}
                        onChange={e => {
                          this.setState(() => ({ selectedNoGroupedTickets: e }))
                          this.props.setFieldValue('ticket_holders', e)
                        }}
                      />
                    </div>
                  ) : null}

                  {groupedTickets.length ? (
                    <SelectTimeSlots
                      groupedTickets={groupedTickets}
                      timeSlotTickets={this.getOnlyTimeSlotTickets()}
                      onChange={v => {
                        this.props.setFieldValue('ticket_holders', v)
                      }}
                    />
                  ) : null}
                </div>
              ) : eventTicketsLoading ? null : (
                <div className="col-xs-12 col-12">No tickets found for this event</div>
              ))}
            {toSelectedAddonHolders &&
              (addOnsList.length ? (
                <div className="col-xs-8 col-12 tickets">{addOnsList}</div>
              ) : eventAddOnsLoading ? null : (
                <div className="col-xs-12 col-12">No add-ons found for this event</div>
              ))}
          </div>
          {(eventAddOnsLoading || eventTicketsLoading) && <LoadingBar />}
          <div className="row">
            <div className="col-xs-12">Event Message</div>
            <div className="col-xs-12">
              <SMSContainer
                editorRef={this.editorRef}
                handleChange={this.handleChange}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                event={event}
              />
            </div>
          </div>
          {smsMaxCharError && (
            <p style={{ color: '#BF5B5A', fontSize: '14px', fontFamily: 'Open Sans' }}>{smsMaxCharError}</p>
          )}
          <div className="row text-center">
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="button"
              onClick={this.setConfirmingModalState(true)}
              disabled={this.isSendButtonDisabled(eventTickets, addOnsList) || maxCharExceed}
            >
              {submitLabel || (
                <span>
                  <i className="fa fa-paper-plane" aria-hidden="true" /> Send
                </span>
              )}
            </Button>
          </div>
        </div>
        <Modal
          className="modal-dialog modal-trans modal-confirming-emailing"
          style={modalStyle}
          isOpen={isConfirmingModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setConfirmingModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">SMS Content Review</div>
              <div className="modal-body">
                <p className="email-body" dangerouslySetInnerHTML={{ __html: values.SMSBody }} />
                {toTicketHolders && (
                  <div className="confirmation-message">
                    Are you sure you want to message the selected ticket holders?
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.handleSubmit}
                  >
                    Send SMS
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.setConfirmingModalState(false)}
                  >
                    Cancel &amp; Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

export default withFormik({
  mapPropsToValues: props => ({
    SMSBody: _get(props, 'initialValues.attributes.SMSBody') || '',
    SMSBodyTags: _get(props, 'initialValues.attributes.SMSBodyTags') || '',
    opted_in: _get(props, 'initialValues.attributes.opted_in') || false,
    all: _get(props, 'initialValues.attributes.all') || true,
    ticket_holders: _get(props, 'initialValues.attributes.ticket_holders') || '',
    add_on_holders: _get(props, 'initialValues.attributes.add_on_holders') || '', // or []
    refunded: _get(props, 'initialValues.attributes.refunded') || false,
    event_team_members: _get(props, 'initialValues.attributes.event_team_members') || false,
    brand_team_members: _get(props, 'initialValues.attributes.brand_team_members') || false,
    influencers: _get(props, 'initialValues.attributes.influencers') || false,
  }),

  // Custom sync validation
  validate: (values, props) => validateForm(values, props),

  handleSubmit: (values, { props, setSubmitting, resetForm, setFieldValue }) => {
    props
      .onSubmit({
        ...values,
      })
      .then(v => {
        setSubmitting(false)
        resetForm()
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'SMSToTicketHolders', // helps with React DevTools
})(SMSToTicketHolders)
