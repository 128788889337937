import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import Select from '../../_library/Select'

const SelectField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  showErrorOnlyTouched = true,
  customError,
  ...props
}) => {
  const onChange = ({ target: { value } = {} }) => {
    if (props.onChange) {
      props.onChange(field.name, normalizer(value))
    }
    setFieldValue(field.name, normalizer(value))
  }
  const onBlur = ({ target: { value } = {} }) => {
    setFieldValue(field.name, normalizer(value))
    touchedOnBlur && setFieldTouched(field.name, normalizer(value))
  }
  return (
    <div>
      <Select
        {...field}
        {...props}
        touched={!showErrorOnlyTouched || _get(touched, field.name)}
        error={customError ? null : _get(errors, field.name)}
        customError={customError}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { SelectField }
