import { createSelector } from 'reselect'
import _get from 'lodash/get'

export const get_event = state => _get(state, 'events.selected')
export const get_event_id = state => _get(state, 'events.selected.id', '')
export const get_event_role = state => _get(state, 'events.selected.self.role', '')

export const get_rn_event_metadata = createSelector(get_event, event => {
  const metadata = _get(event, 'metadata.rn_event_metadata', null)
  return metadata ? JSON.parse(metadata) : null
})
