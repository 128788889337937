import React from 'react'
import _get from 'lodash/get'


const ProductItem = ({ product }) => {
  const imgSrc = _get(product, 'image.src', '')
  return (
    <div>
      <p>{product.title}</p>
      <img width={100} height={100} src={imgSrc} alt={''}/>
    </div>
  )
}

export { ProductItem }