import React from 'react'
import _identity from 'lodash/identity'
import { metaMask } from '../../../_common/core/web3/metamask'

const MetaMaskIcon = props => {
  const { onConnect = _identity } = props

  const onClick = async () => {
    try {
      const account = await metaMask.getFirstAccount()
      onConnect(account)
    } catch (error) {}
  }

  return (
    <div aria-hidden={true} className="mt-icon" onClick={onClick}>
      <img src="/resources/images/icon-metamask.png" alt="MetaMask" />
    </div>
  )
}

export { MetaMaskIcon }
