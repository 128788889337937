import _filter from 'lodash/filter'
import _map from 'lodash/map'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'

export default class Clipboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { clipboardCopied: false }
  }

  clipboardOut = () => {
    if (this.state.clipboardCopied) {
      setTimeout(() => {
        this.setState({
          clipboardCopied: false,
        })
      }, 500)
    }
  }

  copyClipboardAfter = () => {
    this.setState({
      clipboardCopied: true,
    })
  }

  getLastSymbol = (index, dataLength) => (index === dataLength - 1 ? '\n' : '\t')

  getValue = (value, column) =>
    column.normalizer && !column.disableNormalizerOnCopy ? column.normalizer(value) : value

  getContent = () => {
    const { data, text } = this.props
    let { columns } = this.props

    if (text) {
      return text
    }
    let ret = ''
    columns = _filter(columns, column => {
      if (column.key === 'promoter_link') {
        return true
      }
      return (
        typeof column.label !== 'object' &&
        (typeof data[0][column.key] !== 'object' ||
          (typeof data[0][column.key] === 'object' && column.key.includes('date'))) &&
        column.key !== 'status' &&
        column.key !== 'actions' &&
        column.key !== 'expand' &&
        column.key !== 'byAdmin'
      )
    })
    _map(columns, (column, index) => {
      ret += column.label + this.getLastSymbol(index, columns.length)
    })
    _map(data, (d, index) => {
      _map(columns, (column, columnIndex) => {
        if (column.key === 'promoter_link') {
          ret +=
            this.getValue(d[column.key].props.children[1].props.children, column) +
            this.getLastSymbol(columnIndex, columns.length)
        } else {
          ret += this.getValue(d[column.key], column) + this.getLastSymbol(columnIndex, columns.length)
        }
      })
    })
    return ret
  }

  render() {
    const {
      children = null,
      text = null,
      containerStyle = {},
      containerClassName = '',
      isMobile = false,
      placement = 'left',
    } = this.props
    return (
      <div
        style={{ cursor: 'pointer', ...containerStyle }}
        className={'clipboard ' + containerClassName}
        onMouseLeave={this.clipboardOut}
      >
        <ClipboardButton
          component="span"
          data-clipboard-text={text ? text : this.getContent()}
          onSuccess={this.copyClipboardAfter}
        >
          <OverlayTrigger
            placement={isMobile ? 'top' : placement}
            overlay={
              this.state.clipboardCopied ? (
                <Tooltip id="clipboardCopied">Copied</Tooltip>
              ) : (
                <Tooltip id="clipboardCopied">Copy</Tooltip>
              )
            }
            trigger={['hover']}
          >
            {children || <i className="fa fa-clipboard" />}
          </OverlayTrigger>
        </ClipboardButton>
      </div>
    )
  }
}
