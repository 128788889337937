import React from 'react'
import { withFormik } from 'formik'
import SelectTimeSlot from '../_library/SelectTicket'
import Button from '../../_library/Button'

class TicketsTypeBulkUpdate extends React.Component {
  render() {
    const {
      groupedTickets,
      noGroupedTickets,
      values,
      isLoading,
      handleSubmit,
      onCancel,
      setFieldValue,
      defaultTicketTypeId
    } = this.props

    return (
      <form ref="form" onSubmit={handleSubmit}>
        <SelectTimeSlot
          labels={['Ticket Type', 'Date', 'Time']}
          defaultValue={defaultTicketTypeId}
          groupedTickets={groupedTickets}
          noGroupedTickets={noGroupedTickets}
          onChange={e => setFieldValue('ticketTypeId', e.value)}
        />
        <div className="text-center btn-toolbar">
          <Button type="submit" className="btn btn-primary btn-lg btn-shadow" loading={isLoading}>
            Save
          </Button>
          {onCancel && (
            <Button
              className="btn btn-danger btn-lg btn-shadow"
              type="button"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    )
  }
}

export default withFormik({
  mapPropsToValues: props => ({ ticketTypeId: props.defaultTicketTypeId }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({ ...values })
  },
  displayName: 'TicketsTypeBulkUpdate' // helps with React DevTools
})(TicketsTypeBulkUpdate)
