import React from 'react'
import { Link } from 'react-router-dom'
import DateLabel from '../../../_library/DateLabel'
import Address from '../../../_library/Address'

const DetailsCell = ({
  linkToBrand = '/brands',
  brandTitle = '',
  linkToEvent = '/events',
  disableLinkToEvent = false,
  eventTitle = '',
  date = '',
  venue = {},
  isOnline,
  ...props
}) => {
  let venueEl = <div className="address-notdefined">Not Defined</div>
  if (isOnline) {
    venueEl = <div className="address-notdefined">Online</div>
  } else if (venue) {
    venueEl = <Address className="address-form" type="simple" {...venue} />
  }
  return (
    <div className="draft-event-details">
      {!!brandTitle && (
        <div className="draft-event-details__brand">
          <Link to={linkToBrand}>{brandTitle}</Link>
        </div>
      )}
      <div className="draft-event-details__name">
        {disableLinkToEvent ? eventTitle : <Link to={linkToEvent}>{eventTitle}</Link>}
      </div>
      <div className="draft-event-details__time">
        <img src={asset('/resources/images/cal-ico.svg')} alt='No Data' />
        <DateLabel className="starttime" value={date} format="LLL" />
      </div>
      <div className="draft-event-details__address">
        <img src={asset('/resources/images/location-ico.svg')} alt='No Data' />
        {venueEl}
      </div>
    </div>
  )
}

export default DetailsCell
