import React from 'react'
import moment from 'moment'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import EmptyBar from '../../_library/EmptyBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { formatDay, defaultDateFormat } from '../../../_common/core/validation/normalizers'
import CardHeader from '../../_library/CardHeader'
import { FETCH_EVENT_TRAFFIC } from '../../../_common/redux/performance/actions'
import { connect } from 'react-redux'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import { showAxiosError } from '../../utils/messenger'
import { fetchEventTrafficOriginsByDay } from '../../../_common/core/http_services'
import { RadioGroup, Radio } from '../../_library/RadioGroup'

@connect(null, { FETCH_EVENT_TRAFFIC })
export default class TrafficOrigins extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'id',
        label: 'View Day',
        isSortable: true,
        normalizer: formatDay
      },
      {
        key: 'total',
        label: 'No. of Visits',
        isSortable: true
      },
    ])

    this.tableColumnsReferrer = getTableColumns([
      {
        key: 'refererUrl',
        label: 'Referrer URL',
        isSortable: true
      },
      {
        key: 'total',
        label: 'No. of Visits',
        isSortable: true
      },
    ])

    this.detailsTableColumns = getTableColumns([
      {
        key: 'refererUrl',
        label: 'Referrer URL',
        className: 'detail-cell',
        isSortable: true,
        normalizer: value => value ? value : 'N/A'
      },
      {
        key: 'total',
        label: 'No. of Visits',
        className: 'detail-cell',
        isSortable: true
      }
    ])

    this.footbarColumns = [
      { key: 'total', sourceKey: 'total' },
      { key: 'conversion_visitors', sourceKey: 'conversion_visitors' }
    ]

    this.state = {
      sortBy: { column: 'id', asc: false },
      sortBySubTable: { column: 'total', asc: false },
      sortByReferrer: { column: 'total', asc: false },
      isLoading: true,
      tableDisplay: [],
      tableTotals: [],
      selectedTab: 'total'
    }

    this.tableDisplay = []
    this.mounted = true
    this.trafficInterval = null
  }

  componentDidMount() {
    this.fetchTraffic()
    this.trafficInterval = setInterval(() => {
      this.fetchTraffic()
    }, 600 * 1000)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.trafficInterval)
  }

  fetchTraffic = async () => {
    const { event, setShowWarning, showWarning } = this.props
    const { 
      isLoading, 
      tableDisplay: stateTableDisplay, 
    } = this.state
    if (!this.mounted) return
    try {
      const urlsTotals = {}
      const urlsByDay = await fetchEventTrafficOriginsByDay(event.id)
      const table = _groupBy(urlsByDay, 'visitedAt')
      const tableDisplay = _map(table, (detailsRows, key) => {
        let total = 0
        detailsRows.forEach(row => {
          if (urlsTotals[row.refererUrl]) {
            urlsTotals[row.refererUrl] += Number(row.total)
          } else {
            urlsTotals[row.refererUrl] = Number(row.total)
          }
          total += Number(row.total)
        })
        return {
          id: key,
          total,
          detailsRows
        }
      })
      const tableTotals = _map(urlsTotals, (total, key) => ({ refererUrl: key === 'null' ? 'N/A' : key, total }))
      if (this.tableDisplay.length === 0) {
        this.tableDisplay = tableDisplay
      }
      if (
        _isEqual(tableDisplay, stateTableDisplay) && 
        !isLoading
      ) {
        return
      }
      if (!showWarning) {
        if (tableDisplay.find(row => 
          moment(row.id, defaultDateFormat).isBefore(moment('2022-04-17 00:00:00', defaultDateFormat))
        )) {
          setShowWarning()
        }
      }
      this.setState({
        isLoading: false,
        tableDisplay,
        tableTotals
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  onChangeShowGroupsByDate = selectedTab => {
    this.setState({
      selectedTab
    })
  }

  render() {
    const { sortBy, isLoading, tableDisplay, tableTotals, sortByReferrer, selectedTab } = this.state

    const detailsRows = tableDisplay.map(day => ({
      id: day.id,
      type: 'detailRow',
      component: (
        <SortableTable
          data={day.detailsRows}
          tableColumns={this.detailsTableColumns}
          enableSort={true}
          enableCopyTable={true}
          disableMobileView={true}
          className="child-table"
          sortBy={this.state.sortBySubTable}
        />
      )
    }))

    return (
      <div ref="cardContainer" className="performance-sales">
        <CardHeader
          imageUrl="/resources/images/event/performance/ticket-sales-ico.svg"
          bodyText="Referrer URL"
        />
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your statistics..."} />
          ) : this.tableDisplay.length === 0 ? (
            <EmptyBar />
          ) : (
            <div>
              <RadioGroup selectedValue={selectedTab} onChange={this.onChangeShowGroupsByDate}>
                <Radio name="total" value="total" label="Total by URL" />
                <Radio name="byDay" value="by_day" label="Total by Day" />
              </RadioGroup>
              {selectedTab === 'total' ? <SortableTable
                data={tableTotals}
                tableColumns={this.tableColumnsReferrer}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                sortBy={sortByReferrer}
                footbar={{
                  label: 'Total',
                  columns: this.footbarColumns
                }}
              /> : 
              <SortableTable
                data={tableDisplay}
                tableColumns={this.tableColumns}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                sortBy={sortBy}
                detailsRows={detailsRows}
                footbar={{
                  label: 'Total',
                  columns: this.footbarColumns
                }}
              />}
            </div>
            
          )}
        </div>
      </div>
    )
  }
}
