import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  initial: true,
  alternativeview: [],
  eid: '',
}

const FETCH_ALTERNATIVE_VIEWS = createAsyncHandlers('FETCH_ALTERNATIVE_VIEWS', {
  start(state, action) {
    const { eid } = action.payload
    state.eid = eid
  },
  success(state, action) {
    const { alternativeViews, eid } = action.payload
    if (state.eid === eid) {
      state.initial = false
      state.alternativeview = alternativeViews
    }
  },
})

const CREATE_ALTERNATIVE_VIEW = createAsyncHandlers('CREATE_ALTERNATIVE_VIEW', {
  success(state) {
    state.initial = false
  },
})

const UPDATE_ALTERNATIVE_VIEW = createAsyncHandlers('UPDATE_ALTERNATIVE_VIEW', {
  success(state) {
    state.initial = false
  },
})

const DELETE_ALTERNATIVE_VIEW = createAsyncHandlers('DELETE_ALTERNATIVE_VIEW', {
  success(state) {
    state.initial = false
  },
})

export default handleActions(
  {
    ...FETCH_ALTERNATIVE_VIEWS,
    ...CREATE_ALTERNATIVE_VIEW,
    ...UPDATE_ALTERNATIVE_VIEW,
    ...DELETE_ALTERNATIVE_VIEW,
  },
  initialState,
)
