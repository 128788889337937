import React from 'react'
import DateTimePickerReact from '../../../_library/DateTimePickerReact'

const EventTicketSaleDatesRow = (props) => {
  const { values, errors, touched, updateField } = props
  const handleChangeSalesStartDate = val => {
    updateField('salesStartDate', val)
  }

  const handleChangeSalesEndDate = val => {
    updateField('salesEndDate', val)
  }

  return (
    <div className="row" style={{ marginTop: 8 }}>
      <div className="col-sm-6 col-12" style={{ position: 'static' }}>
        <DateTimePickerReact
          id="salesStartDate"
          label="Ticket Sales Start (optional)"
          placeholder="D MMM YYYY H:M AM"
          error={errors.salesStartDate}
          touched={touched.salesStartDate}
          value={values.salesStartDate || ''}
          onChange={handleChangeSalesStartDate}
        />
      </div>
      <div className="col-sm-6" style={{ position: 'static' }}>
        <DateTimePickerReact
          id="salesEndDate"
          label="Ticket Sales End"
          placeholder="D MMM YYYY H:M AM"
          error={errors.salesEndDate}
          touched={touched.salesEndDate}
          value={values.salesEndDate || ''}
          onChange={handleChangeSalesEndDate}
        />
      </div>
    </div>
  )
}

export default EventTicketSaleDatesRow
