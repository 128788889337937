import React from 'react'
import _ from 'lodash'
import { CARD_STATUS_CHANGED } from '../../_common/core/form_helper'

export const getEdittedFields = () => {
  const cardsStatus = {}
  const contentEdittedFields = _.map(form_helper_get(), (field, index) => {
    let field_title = ''
    switch (field) {
    // event details
    case 'owner':
      field_title = 'Brand'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'displayName':
      field_title = 'Event Name'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'shortName':
      field_title = 'Short Event Name'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'slug':
      field_title = 'Event URL'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'startDate':
      field_title = 'Event Start Date'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'endDate':
      field_title = 'Event End Date'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'salesStartDate':
      field_title = 'Event Sales Start Date'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'salesEndDate':
      field_title = 'Event Sales End Date'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'checkInStart':
      field_title = 'Doors open time'
      cardsStatus.details = CARD_STATUS_CHANGED
      break
    case 'checkInEnd':
      field_title = 'Last entry time'
      cardsStatus.details = CARD_STATUS_CHANGED
      break

      // venue details
    case 'searchValue':
    case 'longitude':
    case 'latitude':
      break
    case 'venue':
      field_title = 'Event Venue'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'venueName':
      field_title = 'Venue Name'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'streetNumber':
      field_title = 'Street Number'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'street':
      field_title = 'Street'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'city':
      field_title = 'City'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'state':
      field_title = 'State'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'postalCode':
      field_title = 'Postal Code'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'country':
      field_title = 'Country'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'flagDisabled':
      field_title = 'Disabled access'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'flagHidden':
      field_title = 'Hide from customers'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'hideUntil':
      field_title = 'Location Disclosure Date'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'rsvpAlternativeInformation':
      field_title = 'RSVP Alternative Information'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'locationInformation':
      field_title = 'Location Information'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break
    case 'googlePlaceId':
      field_title = 'Google Place ID'
      cardsStatus.venue = CARD_STATUS_CHANGED
      break

      // event descriptions
    case 'descriptions':
      field_title = 'Event Description'
      cardsStatus.description = CARD_STATUS_CHANGED
      break

      // event images
    case 'imageURLs':
      field_title = 'Event Image'
      cardsStatus.images = CARD_STATUS_CHANGED
      break
    case 'bannerURL':
      field_title = 'Event Banner'
      cardsStatus.images = CARD_STATUS_CHANGED
      break
    case 'backgroundURL':
      field_title = 'Event Background Image'
      cardsStatus.images = CARD_STATUS_CHANGED
      break
    case 'titleReplacementImage':
      field_title = 'Title Replacement Image'
      cardsStatus.images = CARD_STATUS_CHANGED
      break
    case 'useImageInsteadOfTitle':
      field_title = 'Use Transparent Logo (PNG/SVG) Instead of Text Event Name'
      cardsStatus.images = CARD_STATUS_CHANGED
      break
    case 'autoBackground':
      field_title = 'Use automatically-generated background'
      cardsStatus.images = CARD_STATUS_CHANGED
      break

      // event type
    case 'eventType':
      field_title = 'Event Type'
      cardsStatus.type = CARD_STATUS_CHANGED
      break

      // event settings
    case 'flagInvitationsOnly':
      field_title = 'Invitation required to attend'
      cardsStatus.settings = CARD_STATUS_CHANGED
      break
    case 'flagCarer':
      field_title = 'Free carer ticket for disabled guests'
      cardsStatus.settings = CARD_STATUS_CHANGED
      break
    case 'flagRefunds':
      field_title = 'Refunds allowed'
      cardsStatus.settings = CARD_STATUS_CHANGED
      break
    case 'flagPasswordProtected':
      field_title = 'Password required to view event'
      cardsStatus.settings = CARD_STATUS_CHANGED
      break

      // social media
    case 'facebookEvent':
      field_title = 'Social Media Facebook'
      cardsStatus.social = CARD_STATUS_CHANGED
      break
    case 'twitterHashtag':
      field_title = 'Social Media Twitter'
      cardsStatus.social = CARD_STATUS_CHANGED
      break

      // event tags
    case 'tags':
      field_title = 'Tags'
      cardsStatus.tags = CARD_STATUS_CHANGED
      break

      // paymen details
    case 'flagPaymentDetails':
      field_title = 'Payment Details'
      cardsStatus.paymentDetails = CARD_STATUS_CHANGED
      break
    case 'accountName':
      field_title = 'Account Name'
      cardsStatus.paymentDetails = CARD_STATUS_CHANGED
      break
    case 'accountNumber':
      field_title = 'Account Number'
      cardsStatus.paymentDetails = CARD_STATUS_CHANGED
      break

    default:
      field_title = field
      break
    }
    return field_title ? (
      <div key={index}>
        <i className="fa fa-info-circle" aria-hidden="true" /> {field_title}
      </div>
    ) : null
  })

  return { cardsStatus, contentEdittedFields }
}
