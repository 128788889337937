export function getDomain(url, onlyDomainName) {
  let updatedUrl = url.replace(/(https?:\/\/)?(www.)?/i, '')
  updatedUrl = updatedUrl.split('.')

  if (onlyDomainName) {
    const urlArray = updatedUrl
    updatedUrl = updatedUrl.slice(updatedUrl.length - 2, updatedUrl.length - 1).join('.')
    const prefix = urlArray.slice(0, 1).join('.')
    if (updatedUrl === "ticketfairy") {
      if (prefix === 'preproduction-manage') {
        updatedUrl = 'qawolf'
      }
    }
  } else {
    updatedUrl = updatedUrl.slice(1).join('.')
  }

  if (updatedUrl.indexOf('/') !== -1) {
    return updatedUrl.split('/')[0]
  }

  return updatedUrl
}
