// Re-render Optimization 14.05.2020

import React, { useState, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import _get from 'lodash/get'
import _map from 'lodash/map'

import DateLabel from '../../_library/DateLabel'
import Address from '../../_library/Address'
import { ROLES } from '../../constants/roles'

import EventTicketStatistics from './EventTicketStatistics'
import EventImagePlaceholder from './EventImagePlaceholder'
import { useEffect } from 'react'
import { getEventAlternativeViews } from '../../../_common/core/http_services'
import Clipboard from '../../_library/Clipboard'

const EventRow = props => {
  const { event, autoRefresh, dynamicConfigs, configs, promoterLink, isMobile } = props
  const promoterLinkSplit = (promoterLink || '').split('/')
  const promoterId = promoterLinkSplit[promoterLinkSplit.length - 1]
  const showEventAlternativeViews = _get(configs, "appearance.showEventAlternativeViews", true)
  const [alternativeViews, setAlternativeViews] = useState([])
  const isMounted = useRef(true)
  const eventUrl = _get(configs, 'behavior.eventUrl', '')
  const showAlternativeView = !!_get(dynamicConfigs, 'show_alternative_view', false)

  const renderAlternativeView = promoterId =>
    _map(alternativeViews, (item, j) => (
      <tr id={j} key={j}>
        <td className='title-alternative-view'>{item?.title ?? ''}</td>
        <td className='title-alternative-view'>{item?.slug ?? ''}</td>
        <td className='title-alternative-view'>{item?.referenceDescription ?? ''}</td>
        <td className='title-alternative-view'>
          <Clipboard
            text={`${eventUrl}${item.slug}?r=${promoterId}`}
            containerStyle={{ display: 'inline-flex', padding: 0, border: 'none' }}
            containerClassName="btn event-button-sm"
            isMobile={isMobile}
          >
            <div className="btn event-button-sm" style={{ backgroundColor: '#7784aa', width: '100%' }}>
              <i className="fa fa-files-o" aria-hidden="true" />
              <span className='promoter-link-text'>Promoter Link</span>
            </div>
          </Clipboard>
        </td>
      </tr>
    ))

  useEffect(() => {
    const getViews = async () => {
      const alternativeViews = await getEventAlternativeViews(event.id)
      if (isMounted.current) {
        setAlternativeViews(alternativeViews)
      }
    }
    if (showAlternativeView && showEventAlternativeViews && promoterId) {
      getViews()
    }
  }, [showAlternativeView, showEventAlternativeViews, event.id, promoterId])

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  const address = !!event ? event.venue : null
  const organization = !!event ? event.owner.displayName : null
  const { isOnlineEvent } = event
  const eventId = event.id

  const permission = !!event ? event.self?.role : ''
  const organization_link = !!event ? '/brand/' + event.owner.id + '/details' : '/brands'
  let redirectPath = '/performance'

  if (permission === 'onsite') {
    redirectPath = '/details'
  }
  if (permission === 'curator') {
    redirectPath = '/invitations'
  }

  if (ROLES.is_support_or_partial_support(permission)) {
    redirectPath = '/orders'
  }

  const startDate = moment(new Date(event.startDate))
  const startDate_utc = moment.utc(new Date(event.startDate))
  const now = new Date()
  const cs = [
    startDate.isBefore(now) ? 'row-stale eventslist-row ' : 'eventslist-row',
    event.pinned ? 'event-pinned' : 'event-unpinned',
  ].join(' ')

  return (
    <tr className={cs}>
      <td className="eventslist-details">
        <div className="react-table">
          <div className="eventslist-thumbs">
            <div className="LazyLoad">
              <LazyLoad width={150} height={150} once>
                {!!event.imageURL ? (
                  <Link to={'/event/' + eventId + redirectPath}>
                    <img alt="nodata" className="LazyLoadImg" src={event.imageURL} />
                  </Link>
                ) : (
                  <EventImagePlaceholder />
                )}
              </LazyLoad>
            </div>
            <div className="event-details">
              <div className="event-brand">
                <Link to={organization_link}>{organization}</Link>
              </div>
              <div className="event-name">
                <Link to={'/event/' + eventId + redirectPath}>{event.displayName}</Link>
              </div>
              <div className="event-time">
                <img alt="nodata" src={asset('/resources/images/cal-ico.svg')} />
                <DateLabel className="starttime" value={startDate_utc} format="LLL" />
              </div>
              {isOnlineEvent ? (
                <div className="event-address">
                  <img alt="nodata" src={asset('/resources/images/location-ico.svg')} />
                  <div className="address-notdefined">Online Event</div>
                </div>
              ) : (
                <div className="event-address">
                  <img alt="nodata" src={asset('/resources/images/location-ico.svg')} />
                  {!!address && <Address className="address-form" type="simple" {...address} />}
                  {!address && <div className="address-notdefined">Not Defined</div>}
                </div>
              )}
            </div>
          </div>
          <div className="table-cell">
            <div className="eventslist-actions top_position">
              <div className="event-actions-container">
                {permission !== 'curator' && !(permission === ROLES.PARTIAL_SUPPORT) && (
                  <EventTicketStatistics event={event} autoRefresh={autoRefresh} />
                )}
                <div className="eventslist-status">
                  {props.children}
                  {alternativeViews && alternativeViews.length > 0 && promoterId && (
                    <div className="col-12 col-md-12">
                      <div className='card-title card-unchanged small-width'>Additional Pages</div>
                      <div>
                        <table className="table alternative-view-table">
                          <thead>
                            <tr>
                              <th className='title-alternative-view'>
                                <strong>Title</strong>
                              </th>
                              <th className='title-alternative-view'>
                                <strong>URL</strong>
                              </th>
                              <th className='title-alternative-view'>
                                <strong>Purpose</strong>
                              </th>
                              <th className='title-alternative-view'>
                                <strong>Promoter Link</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{renderAlternativeView(promoterId)}</tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default EventRow
