import React from 'react'
import _ from 'lodash'
import Card from '../../_library/Card'
import Button from '../../_library/Button'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import SortableTableWithPaginationHOC from '../../hoc/SortableTableWithPaginationHOC'
import SortableTableWithSearchHOC from '../../hoc/SortableTableWithSearchHOC'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { getEventInvitations } from '../../../_common/core/http_services'
import moment from 'moment'
import { PROJECT_WIDE_FORMAT } from '../../../web/constants/timeFormats'

const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)

const DEFAULT_PAGE_SIZE = 10
const AUTO_REFRESH = 20000
import ConfirmModal from '../../_library/ConfirmModal'
import LoadingBar from '../../_library/LoadingBar'
import RichTextArea from '../../_library/RichTextArea'
import { ENV } from '../../constants/env'
import { RadioGroup } from '../../formik/Fields'
import { sendReminder } from '../../../_common/core/http_services/reminder'
import { showAxiosError, showMessage } from '../../utils/messenger'
import Field from '../../_library/Field'

export default class ExistingInvitations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      modalVisible: false,
      message: '',
      subject: '',
      option: 'old',
      error: '',
      isConfirmModalOpen: false,
      invitationToCancel: null,
      data: [],
      pageNumber: 1,
      sortBy: {},
      limit: DEFAULT_PAGE_SIZE,
      resetPagination: 0,
      errorSubject: undefined,
    }
    this.searchBy = ['full_name', 'email', 'invited_full', 'ticketType', 'maxQuantity', 'status']
    this.tableColumns = getTableColumns([
      { key: 'full_name', label: 'Name', sort: this.sortBy },
      { key: 'email', label: 'Email', sort: this.sortBy },
      { key: 'invited_full', label: 'Invited By', sort: this.sortBy },
      { key: 'ticketType', label: 'Ticket Type', sort: this.sortBy },
      { key: 'maxQuantity', label: 'Max Quantity', sort: this.sortBy },
      { key: 'status_str', label: 'Status', sort: this.sortBy },
      { 
        key: 'created_at', 
        label: 'Invited At', 
        sort: this.sortBy, 
        normalizer: value => moment(moment.tz(value, 'YYYY-MM-DD HH:mm:ss', global.databaseTimezone))
          .tz(props.event.timezone)
          .format(PROJECT_WIDE_FORMAT),  
      },
    ])
  }

  async componentDidMount() {
    this.fetchInvitations(1, DEFAULT_PAGE_SIZE, '')
    this.refreshTimer = setInterval(() => {
      const { pageNumber, limit, searchValue } = this.state
      this.fetchInvitations(pageNumber, limit, searchValue)
    }, AUTO_REFRESH)
  }

  componentWillUnmount() {
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  onPageChange = pageNumber => {
    const { searchValue, limit } = this.state
    this.setState({ isLoadingData: true, pageNumber }, () => {
      this.fetchInvitations(pageNumber, limit, searchValue)
    })
  }

  onPageSizeChange = limit => {
    const { searchValue } = this.state
    this.setState({ isLoadingData: true, limit, pageNumber: 1 }, () => {
      this.fetchInvitations(1, limit, searchValue)
    })
  }

  async cancelInvitation(invitation) {
    try {
      const { onCancelInvitation } = this.props
      const { pageNumber, limit, searchValue } = this.state
      await onCancelInvitation(invitation)
      await this.fetchInvitations(pageNumber, limit, searchValue)
      this.setState({ isConfirmModalOpen: false })
    } catch (error) {}
  }

  getSearchedData = ({ page, limit, searchValue }) => {
    this.setState(
      {
        data: [],
        isLoadingData: true,
        searchValue,
        pageNumber: 1,
        resetPagination: this.state.resetPagination + 1,
        limit: DEFAULT_PAGE_SIZE,
      },
      () => {
        this.fetchInvitations(page, limit, searchValue)
      },
    )
  }

  fetchInvitations = async (page, limit, search_query) => {
    const { event } = this.props
    const { sortBy } = this.state
    try {
      const optionSortBy = (() => {
        switch (sortBy.column) {
          case 'full_name':
            return 'name'
          case 'invited_full':
            return 'invited_by'
          case 'status_str':
            return 'status'
          case 'ticketType':
            return 'ticket_type'
          case 'maxQuantity':
            return 'max_quantity'
          default:
            return sortBy.column
        }
      })()
      const optionSortOrder = sortBy.asc ? 'asc' : 'desc'
      const data = await getEventInvitations(
        event.id,
        search_query,
        page,
        limit,
        optionSortBy,
        optionSortOrder,
        event.timezone,
      )
      this.setState({
        data,
        limit,
        isLoadingData: false,
        pageNumber: page,
      })
    } catch (error) {
      this.setState({ isLoadingData: false })
    }
  }

  sortBy = async column => {
    try {
      const { event } = this.props
      const { searchValue, limit } = this.state
      const sortBy = { column: column.key, asc: !this.state.sortBy.asc }
      const optionSortBy = (() => {
        switch (sortBy.column) {
          case 'full_name':
            return 'name'
          case 'invited_full':
            return 'invited_by'
          case 'status_str':
            return 'status'
          case 'ticketType':
            return 'ticket_type'
          case 'maxQuantity':
            return 'max_quantity'
          default:
            return sortBy.column
        }
      })()
      const optionSortOrder = sortBy.asc ? 'asc' : 'desc'
      this.setState({ isLoadingData: true })
      const data = await getEventInvitations(
        event.id,
        searchValue,
        1,
        limit,
        optionSortBy,
        optionSortOrder,
        event.timezone,
      )
      this.setState({
        data,
        isLoadingData: false,
        pageNumber: 1,
        sortBy,
      })
    } catch (err) {
      this.setState({ isLoadingData: false })
    }
  }

  onConfirmResponse = async response => {
    const { event } = this.props
    const { message, option, subject } = this.state
    try {
      if (response === 'yes') {
        if (!subject && option === 'new') {
          throw new Error('Required subject')
        }
        if (!message && option === 'new') {
          throw new Error('Required message')
        }
        this.setState({ isLoading: true })
        await sendReminder(event.id, { custom_template: message, custom_subject: subject, option })
        showMessage('success', 'Successfully sent!', 10, true)
      }
      this.setState({ isLoading: false, modalVisible: false, errorSubject: undefined, error: '', subject: '', message: '' })
    } catch (err) {
      if (err.message === 'Required message') {
        this.setState({ error: 'Required', isLoading: false })
        return
      }
      if (err.message === 'Required subject') {
        this.setState({ errorSubject: 'Required', isLoading: false })
        return
      }
      showAxiosError(err, _.get(err, 'errors[0].details'))
      this.setState({ isLoading: false })
    }
  }

  handleChangeLocationInfo = message => {
    this.setState({
      message,
      error: '',
    })
  }

  render() {
    const {
      isConfirmModalOpen,
      invitationToCancel,
      data,
      pageNumber,
      isLoadingData,
      sortBy,
      resetPagination,
      searchValue,
      isLoading, 
      modalVisible, 
      message, 
      option, 
      error,
      subject,
      errorSubject,
    } = this.state
    const name = invitationToCancel?.first_name + ' ' + invitationToCancel?.last_name
    return (
      <div className="existing-invitations">
        <Card icon={'fa-users'} title={'Recipients'}>
          <SortableTableWithSearchAndPagination
            data={data}
            tableColumns={this.tableColumns}
            enableCopyTable={true}
            enableSort={true}
            sortBy={sortBy}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            getSearchedData={this.getSearchedData}
            pageNumber={pageNumber}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            searchBy={this.searchBy}
            isAsyncSearch={true}
            className={isLoadingData ? 'loading' : ''}
            isLoadingSearchData={isLoadingData}
            showSearchLoading={true}
            disableMobileView={true}
            resetPagination={resetPagination}
            showAsTag
            searchValue={searchValue}
            actions={[
              {
                label: 'Cancel',
                className: 'btn btn-danger btn-shadow',
                icon: 'fa fa-times fa-fw',
                onClick: data => {
                  this.setState({
                    invitationToCancel: data,
                    isConfirmModalOpen: true,
                  })
                },
                disabled: data => data.status === 'withdrawn' || data.status === 'bought',
              },
            ]}
          />
          {data.length !== 0 && (
            <>
              <div className="div-spacing-20" />
              <div className="row">
                <div className="col-xs-12 text-right">
                  <Button
                    type="button"
                    className="btn btn-success btn-shadow"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      this.setState({ modalVisible: true })
                    }}
                  >
                    Send a Reminder
                  </Button>
                </div>
              </div>
            </>
          )}
        </Card>
        <Modal
          className="event-team-new-invitation modal-trans"
          style={modalStyle}
          isOpen={isConfirmModalOpen}
          contentLabel="Modal"
          onRequestClose={this.onCancelNewInvitation}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Invitation Cancel</div>
              <div className="modal-body">Are you sure you want to cancel the invitation sent to {name}?</div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    onClick={() => {
                      this.cancelInvitation(this.state.invitationToCancel)
                    }}
                  >
                    Ok
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    onClick={() => {
                      this.setState({ isConfirmModalOpen: false })
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <ConfirmModal
          isOpen={modalVisible}
          header="Confirm"
          content={
            isLoading ? (
              <LoadingBar key="loadingbar" />
            ) : (
              <>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="message-modal-content">
                      <strong>
                        Are you sure you want to remind all people that have not bought tickets yet?
                      </strong>
                    </div>
                  </div>
                  <div className="spacer" />
                  <div className="col-xs-12">
                    <RadioGroup
                      value={option}
                      groups={[
                        { label: 'Same message', value: 'old' },
                        { label: 'New message', value: 'new' },
                      ]}
                      onChange={option => {
                        this.setState({ option, subject: '', message: '', error: '', errorSubject: undefined })
                      }}
                      classNames={{
                        container: 'radio-group-container-centered',
                        container_item: 'radio-group-item',
                        component: '',
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
                  {option === 'new' ? (
                    <Field
                      id="subject"
                      label="Subject"
                      value={subject}
                      error={errorSubject}
                      touched={true}
                      onChange={event => {
                        const subject = event.target.value
                        this.setState({ subject })
                      }}
                    />
                  ) : null}
                  {option === 'new' ? (
                    <RichTextArea
                      ref={'reminder'}
                      id={'reminder'}
                      label="Custom reminder"
                      baseurl={ENV.CDN_URL}
                      value={message}
                      touched={true}
                      error={error}
                      onChange={this.handleChangeLocationInfo}
                      placeholder={{ text: 'Enter message here...' }}
                      disablePlugin={true}
                    />
                  ) : null}
                </div>
              </>
            )
          }
          actions={[
            { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow' },
            { value: 'no', label: 'No', className: 'btn btn-default' },
          ]}
          onAction={this.onConfirmResponse}
        />
      </div>
    )
  }
}
