import { baseRequest } from '.'
import _get from 'lodash/get'
import { eventAlternativeViewAdapter } from './adapters'

export const getEventAlternativeViews = async eid => {
  const res = await baseRequest.get(`event/${eid}/alternative-view/`)
  const alternativeViews = _get(res, 'data.data')
  return eventAlternativeViewAdapter(alternativeViews)
}

export default getEventAlternativeViews
