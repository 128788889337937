import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import EmptyBar from '../../_library/EmptyBar'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable'
import Button from '../../_library/Button'
import { FETCH_EVENT_RSVP } from '../../../_common/redux/rsvp/actions'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'
import { DOWNLOAD_CSV, convertToCSV, getCSVData } from '../../utils/csv'

const EMPTY_ARRAY = []

@connect(
  state => {
    let { rsvp } = state.rsvp
    const rsvpEventId = state.rsvp.eid
    const eventId = _get(state.events, 'selected.id', '')
    if (rsvpEventId !== eventId) {
      rsvp = EMPTY_ARRAY
    }

    return {
      rsvp,
      eventId,
      rsvpEventId,
      loading: state.loading.has('FETCH_EVENT_RSVP')
    }
  },
  { FETCH_EVENT_RSVP }
)
export default class PerformanceRsvp extends React.PureComponent {
  constructor(props) {
    super(props)
    this.csvFileName = `performance_rsvp_${props.eventId}.csv`
    this.tableColumns = getTableColumns([
      { label: 'Name', key: 'fullName' },
      'email',
      { label: 'Date', key: 'date', formatTo: DAY_DISPLAY_FORMAT }
    ])
    this.state = {
      rsvpData: []
    }
  }

  componentDidMount() {
    const { eventId, FETCH_EVENT_RSVP } = this.props
    FETCH_EVENT_RSVP(eventId)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { rsvp, rsvpEventId, eventId, loading, FETCH_EVENT_RSVP } = this.props
    if (rsvp !== nextProps.rsvp) {
      this.setState({
        rsvpData: _map(rsvp, item => ({
          ...item,
          fullName: `${item.firstName || ''} ${item.lastName || ''}`
        }))
      })
    }
    if (!loading && rsvpEventId !== eventId) {
      FETCH_EVENT_RSVP(eventId)
    }
  }

  downloadCSVFile = () => {
    const { rsvpData } = this.state
    const data = getCSVData(this.tableColumns, rsvpData)

    if (rsvpData) {
      const csvData = convertToCSV({ data })
      DOWNLOAD_CSV(csvData, this.csvFileName)
    }
  }

  render() {
    const { loading } = this.props
    const { rsvpData } = this.state
    const showTable = !!rsvpData.length
    return (
      <div className="performance-rsvp">
        <div className="export-button-container">
          <Button
            className="btn btn-default btn-shadow tags-clear"
            type="button"
            onClick={this.downloadCSVFile}
          >
            <img src={asset('/resources/images/excel-ico.svg')} />
            {`Export RSVP`}
          </Button>
        </div>
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/rsvp-ico.svg')}
          />
          RSVP
        </div>
        <div className="table-background">
          {!loading && showTable ? (
            <SortableTable
              tableColumns={this.tableColumns}
              data={rsvpData}
              enableCopyTable={true}
            />
          ) : !loading && !showTable ? (
            <EmptyBar />
          ) : (
            <LoadingBar title={`Hold tight! We're getting your event's RSVP...`} />
          )}
        </div>
      </div>
    )
  }
}
