import _get from 'lodash/get'
import _set from 'lodash/set'
import { withFormik, Field } from 'formik'
import React from 'react'
import Button from '../_library/Button'
import countries from 'world-countries'
import { SimpleField, CountryField } from '../formik/Fields'
import { requiredValidator } from '../../_common/core/validation'
import { getConfig } from '../utils/configUtils'

function validateTeamInitiationForm(data) {
  const required = ['password', 'confirmPassword', 'firstName', 'lastName', 'country']
  const errors = {}

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })
  if (_get(data, 'password') && _get(data, 'password').length < 6) {
    _set(errors, 'password', 'Password must be at least 6 characters')
  }

  if (_get(data, 'confirmPassword') !== _get(data, 'password')) {
    _set(errors, 'confirmPassword', 'Passwords do not match')
  }

  return errors
}

function validateTeamInitiationGenericForm(data) {
  const required = ['email']
  const errors = {}

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  if (!validateEmail(_get(data, 'email'))) {
    _set(errors, 'email', 'Invalid Email Address')
  }

  return errors
}

const configFile = getConfig()
const logo = _get(configFile, '*.appearance.assets.logo', '/resources/images/ttf-logo.png')

const MyForm = ({
  tokenValid,
  tokenValidating,
  isNewCustomer,
  type,
  tokenText,
  isSubmitting,
  handleSubmit,
  redirectTeamSignUp,
  user,
  push,
  extraFields,
  ...props
}) => {
  const renderButtons = () => user
  ? (
    <Button type="button" className="login_btn" onClick={() => {
      push('/events')
    }}>
      See Events list
    </Button>
  )
  : (
    <Button type="button" className="login_btn" onClick={() => {
      push('/signin')
    }}>
      Log in
    </Button>
  )
  return (
    <form onSubmit={handleSubmit}>
      <div className="login_holder">
        <div className="invitation_content clearfix">
          <div className="login_card" style={{ marginTop: '-60px' }}>
            <div className="login_data">
              <div className="login_logo">
                <img src={asset(`${logo}`)} style={{ width: 80 }} alt="" />
              </div>
              <h2 className="heading">Team Invitation</h2>
              {tokenValidating && (
                <div className="team-initiation-token-validating">
                  <i className="fa fa-fw fa-circle-o-notch fa-spin" />
                  <div>Checking Team Invitation Hash...</div>
                </div>
              )}
              {redirectTeamSignUp && (
                <div className="form_holder">
                  {/* !!error && <div className="alert alert-danger">{error.details || error.title}</div> */}
                  <p>Please create your account to accept your invitation...</p>
                  <Field
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('firstName', false)}
                  />
                  <Field
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('lastName', false)}
                  />
                  <Field
                    name="country"
                    label="Country"
                    options={countries}
                    placeholder="Country"
                    component={CountryField}
                    validate={requiredValidator()}
                  />
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('password', false)}
                    autoComplete="new-password"
                  />
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('confirmPassword', false)}
                  />
                  {extraFields.map(field => {
                    if (field.field === "company") {
                      return (
                        <Field
                          name="company"
                          label="Company"
                          placeholder="Company"
                          component={SimpleField}
                          validate={field.required ? requiredValidator() : null}
                          onFocus={() => props.setFieldTouched('company', false)}
                        />
                      )
                    }
                    return null
                  })}
                  <div className="text-center">
                    <Button type="submit" className="login_btn" loading={isSubmitting}>
                    Submit
                    </Button>
                  </div>
                </div>
              )}
              {!tokenValidating && tokenValid && isNewCustomer && type !== 'generic' && (
                <div className="form_holder">
                  {/* !!error && <div className="alert alert-danger">{error.details || error.title}</div> */}
                  <p>Please create your account to accept your invitation...</p>
                  <Field
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('firstName', false)}
                  />
                  <Field
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('lastName', false)}
                  />
                  <Field
                    name="country"
                    label="Country"
                    options={countries}
                    placeholder="Country"
                    component={CountryField}
                    validate={requiredValidator()}
                  />
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('password', false)}
                    autoComplete="new-password"
                  />
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    component={SimpleField}
                    validate={requiredValidator()}
                    onFocus={() => props.setFieldTouched('confirmPassword', false)}
                  />
                  {extraFields.map(field => {
                    if (field.field === "company") {
                      return (
                        <Field
                          name="company"
                          label="Company"
                          placeholder="Company"
                          component={SimpleField}
                          validate={field.required ? requiredValidator() : null}
                          onFocus={() => props.setFieldTouched('company', false)}
                        />
                      )
                    }
                    return null
                  })}
                  <div className="text-center">
                    <Button type="submit" className="login_btn" loading={isSubmitting}>
                    Submit
                    </Button>
                  </div>
                </div>
              )}
              {!tokenValidating && tokenValid && isNewCustomer && type === 'generic' && !redirectTeamSignUp && (
                <div className="form_holder">
                  {!props.isUserExists ? (
                  <>
                    <p>Please write your email...</p>
                    <Field
                      name="email"
                      label="Email"
                      placeholder="Email"
                      component={SimpleField}
                      validate={requiredValidator()}
                      onFocus={() => props.setFieldTouched('email', false)}
                    />
                    <div className="text-center">
                      <Button type="submit" className="login_btn" loading={isSubmitting}>
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    Team invitation is already accepted
                    {renderButtons()}
                  </>
                )}
                </div>
              )}
              {!tokenValidating && !tokenValid && (
                <>
                  <div className="team-initiation-token-invalid">{tokenText}</div>
                  {tokenText === "Team invitation is already accepted"
                    ? <div className='form_holder'>{renderButtons()}</div>
                    : null
                  }
                </>
              )}
              <div className="copy_right">
              Copyright &copy; {getCurrentYear()} THE<strong>TICKET</strong>FAIRY, Inc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const ConfirmTeamInvitationForm = withFormik({
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    country: '',
    email: '',
    company: '',
  }),

  // Custom sync validation
  validate: (values, props) =>
    props.type === 'generic' && !props.redirectTeamSignUp
      ? validateTeamInitiationGenericForm(values)
      : validateTeamInitiationForm(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit(
        {
          attributes: values,
        },
        props.type,
      )
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'ConfirmTeamInvitationForm', // helps with React DevTools
})(MyForm)

export default ConfirmTeamInvitationForm
