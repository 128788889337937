import React from 'react'
import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _isEqual from 'lodash/isEqual'

import SearchInput, { SearchResultCount } from '../_library/SearchInput'
import SortableTable from '../_library/SortableTable'
import { formatDay } from '../../_common/core/validation'
import LoadingBar from '../_library/LoadingBar'
import EmptyBar from '../_library/EmptyBar'

const SortableTableWithSearchHOC = (TableComponent = SortableTable) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      const { data = [] } = props
      this.state = {
        searchValue: '',
        filteredData: data,
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { data, isLoadingSearchData } = this.props

      if (!_isEqual(data, prevProps.data) || (prevProps.isLoadingSearchData && !isLoadingSearchData)) {
        this.update(data)
      }
    }

    update = data => {
      const { searchValue } = this.state
      const filteredData = this.handleFilter(searchValue, data)
      this.setState(() => ({ filteredData }))
    }

    handleFilter = (searchValue, data = this.props.data) => {
      const { searchBy } = this.props
      if (!searchValue) return data

      return _filter(data, item =>
        _some(searchBy, searchKey => {
          const search = searchValue.toLocaleLowerCase()
          let tableNodeValue = `${item[searchKey]}`.toLocaleLowerCase()
          if (['date', 'Date'].some(item => searchKey.includes(item))) {
            tableNodeValue = `${formatDay(item[searchKey])}`.toLocaleLowerCase()
          }
          return tableNodeValue.includes(search)
        }),
      )
    }

    onSearch = searchValue => {
      const { getSearchedData, pageSize, onSearchChange = () => {} } = this.props

      if (getSearchedData) {
        this.setState({ searchValue }, () => {
          getSearchedData({
            page: 1,
            limit: pageSize || 10,
            searchValue,
          })
        })
      } else {
        const filteredData = this.handleFilter(searchValue)
        onSearchChange(filteredData, searchValue)
        this.setState(() => ({
          searchValue,
          filteredData,
        }))
      }
    }

    onAsyncSearch = (searchValue = '') => {
      const { pageSize, getSearchedData } = this.props
      getSearchedData({ page: 1, limit: pageSize || 10, searchValue })
    }

    render() {
      const { searchValue, filteredData } = this.state
      const {
        data,
        sortBy,
        tableColumns,
        detailsRows = [],
        customHead = null,
        isAsyncSearch = false,
        resultsCount,
        showSearchLoading,
        isLoadingSearchData,
        searchValue: pSearchValue,
        showEmptyBar,
        asyncSortBy,
        showAsTag,
        placeholder,
        ...rest
      } = this.props

      const value = isAsyncSearch ? pSearchValue : searchValue

      return (
        <div>
          <div className="datatable-searchbar">
            {value && !isLoadingSearchData && (
              <SearchResultCount resultsCount={resultsCount || filteredData.length} />
            )}
            <SearchInput
              placeholder={placeholder}
              onChange={isAsyncSearch ? this.onAsyncSearch : this.onSearch}
              value={value}
              showAsTag={showAsTag}
              searchValue={pSearchValue}
            />
          </div>
          {showSearchLoading && isLoadingSearchData ? (
            <div className="row">
              <LoadingBar key="loadingbar" title="Searching..." />
            </div>
          ) : (
            <>
              {!!customHead && <div className="div-spacing-20" />}
              {customHead}
              <TableComponent
                data={filteredData}
                tableColumns={tableColumns}
                sortBy={sortBy}
                enableCopyTable={true}
                enableSort={true}
                detailsRows={detailsRows}
                className="dark"
                searchValue={searchValue}
                {...rest}
              />
              {showEmptyBar && !filteredData.length && <EmptyBar />}
            </>
          )}
        </div>
      )
    }
  }

export default SortableTableWithSearchHOC
