import _map from 'lodash/map'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _isArray from 'lodash/isArray'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import { withFormik } from 'formik'
import React from 'react'
import { asyncComponent } from '../../../hoc'

import Button from '../../../_library/Button'
import Field from '../../../_library/Field'
import Select from '../../../_library/Select'
import LoadingBar from '../../../_library/LoadingBar'
import Card from '../../../_library/Card'
import TagsField2 from '../../../_library/TagsField2'
import { RadioGroup, Radio } from '../../../_library/RadioGroup'

const SeatMap = asyncComponent(() => import('./SeatMap'))
import RegularTickets from './RegularTickets'

const options_quantity = _map(new Array(40), (e, i) => ({ value: i + 1, label: i + 1 }))

function validateTicket(data) {
  const errors = {}

  let required = ['email', 'confirmEmail', 'ticketTypeID']
  if (!!data.selectedGroup) {
    required.push('selectedDay')
    required.push('selectedTime')
  } else {
    required = _filter(required, r => r !== 'selectedDay' && r !== 'selectedTime')
  }
  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  const quantity = _get(data, 'quantity')
  if (quantity < 1) {
    _set(errors, 'quantity', 'Quantity must be greater than 0')
  }

  const email = _get(data, 'email')
  const confirmEmail = _get(data, 'confirmEmail')
  if (email !== confirmEmail) _set(errors, 'confirmEmail', 'Please confirm the email address for this ticket')

  const ticketHolders = _get(data, 'ticketHolders')
  _map(ticketHolders, (holder, index) => {
    if (!holder.first_name.trim()) {
      _set(errors, 'ticketHolders[' + index + '].first_name', 'Required')
    }
    if (!holder.last_name.trim()) _set(errors, 'ticketHolders[' + index + '].last_name', 'Required')

    const addons = _get(holder, 'add_ons')
    _map(addons, (addon, aindex) => {
      if (!addon.id) _set(errors, 'ticketHolders[' + index + '].add_ons[' + aindex + '].id', 'Required')
      if (!addon.quantity)
        _set(errors, 'ticketHolders[' + index + '].add_ons[' + aindex + '].quantity', 'Required')
      else if (addon.quantity < 1)
        _set(
          errors,
          'ticketHolders[' + index + '].add_ons[' + aindex + '].quantity',
          'Quantity must be greater than 0'
        )
    })
  })
  return errors
}

class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.updateField = this.updateField.bind(this)
    this.handleArrayChange = this.handleArrayChange.bind(this)
    this.handleArrayChange2 = this.handleArrayChange2.bind(this)
    this.handleChangeTicketTypeID = this.handleChangeTicketTypeID.bind(this)
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangeConfirmEmail = this.handleChangeConfirmEmail.bind(this)
    this.handleChangeNotes = this.handleChangeNotes.bind(this)
    this.handleAddOn = this.handleAddOn.bind(this)
    this.removeAddOn = this.removeAddOn.bind(this)

    this.state = {
      ticketTypeOptions: null,
      isShowQuantity: false,
      selectedTab: props.event.tableMapEnabled ? 'regularTickets' : 'ticketsOnMap',
    }
  }

  componentDidMount() {
    this.processQuantity(1)
  }

  updateField(field, value) {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fields = form_helper_get()
    fields[field] = field
    const isValueEmpty = field === 'ticketHolders' ? this.isTicketHoldersValueEmpty(value) : !`${value}`
    if (isValueEmpty) {
      form_helper_reset()
    } else {
      form_helper_set(fields)
    }
  }

  isTicketHoldersValueEmpty = arr => {
    let isAddOnsEmpty = true
    if (_find(arr, item => !!item.first_name || !!item.last_name)) {
      return false
    }
    _map(arr, holderItem => {
      if (_find(holderItem.add_ons, item => !!item.id)) {
        isAddOnsEmpty = false
      }
    })
    return isAddOnsEmpty
  }

  processQuantity(count) {
    const {
      values: { ticketHolders }
    } = this.props
    const count_pre = ticketHolders.length
    if (count < count_pre) {
      for (let i = 0; i < count_pre - count; i++) {
        ticketHolders.splice(count, 1)
      }
    }
    if (count > count_pre) {
      for (let i = 0; i < count - count_pre; i++) {
        ticketHolders.push({
          first_name: '',
          last_name: '',
          add_ons: []
        })
      }
    }
    this.updateField('ticketHolders', ticketHolders)
  }

  handleArrayChange(e, index, field) {
    const { value } = e.target
    const {
      values: { ticketHolders }
    } = this.props
    ticketHolders[index][field] = value
    this.updateField('ticketHolders', ticketHolders)
  }

  handleArrayChange2(e, index, aindex, field) {
    const { value } = e.target
    const {
      values: { ticketHolders }
    } = this.props
    ticketHolders[index].add_ons[aindex][field] = value
    this.updateField('ticketHolders', ticketHolders)
  }

  handleChangeNotes(e) {
    this.updateField('notes', e.target.value)
  }

  handleChangeEmail(e) {
    this.updateField('email', e.target.value)
  }

  handleChangeConfirmEmail(e) {
    this.updateField('confirmEmail', e.target.value)
  }

  handleChangeTicketTypeID(e) {
    const { values } = this.props
    const v = e.target ? e.target.value : e
    if ((Number(v) || Number(v) === 0) && !v.includes('e')) {
      this.updateField('selectedGroup', null)
      this.updateField('selectedDay', '')
      this.updateField('selectedTime', '')
      this.updateField('ticketTypeID', v)
    } else {
      this.updateField('ticketTypeID', null)
      if (values.selectedGroup !== v) {
        this.updateField('selectedDay', '')
        this.updateField('selectedTime', '')
      }
      this.updateField('selectedGroup', v)
    }
  }

  handleChangeQuantity(e) {
    const v = e.target ? e.target.value : e
    this.updateField('quantity', parseInt(v))
    this.processQuantity(v)
  }

  showQuantityField = (isShow = true) => {
    const { isShowQuantity } = this.state
    if (isShow && !isShowQuantity) {
      this.updateField('quantity', 1)
      this.processQuantity(1)
    }
    this.setState(() => ({ isShowQuantity: isShow }))
  }

  handleAddOn(index) {
    const {
      values: { ticketHolders }
    } = this.props
    ticketHolders[index].add_ons.push({
      quantity: '1',
      id: ''
    })
    this.updateField('ticketHolders', ticketHolders)
  }

  removeAddOn(index, aindex) {
    const {
      values: { ticketHolders }
    } = this.props
    ticketHolders[index].add_ons.splice(aindex, 1)
    this.updateField('ticketHolders', ticketHolders)
  }

  copyFirstTicketHolder() {
    const {
      values: { ticketHolders }
    } = this.props
    for (let i = 1; i < ticketHolders.length; i++) {
      ticketHolders[i].first_name = ticketHolders[0].first_name
      ticketHolders[i].last_name = ticketHolders[0].last_name
    }
    this.updateField('ticketHolders', ticketHolders)
  }

  handleChangeTags(tags) {
    this.updateField('tags', tags)
  }

  handleDayChange = e => {
    const day = e.target.value
    this.updateField('selectedDay', day)
    !day && this.updateField('selectedTime', '')
  }

  handleTimeChange = e => {
    const { values, tickets } = this.props
    const time = e.target.value
    const selectedArray = _find(
      tickets,
      (item, key) => key === values.selectedGroup || `${key} - Slots` === values.selectedGroup
    )
    const selectedTicketType = _find(
      selectedArray,
      t => t.slotStartDate === values.selectedDay + ' ' + time
    )
    this.updateField('selectedTime', time)
    this.updateField('ticketTypeID', selectedTicketType ? selectedTicketType.id : null)
  }

  handleTicketTypeSelect = e => {
    const { tickets } = this.props
    const v = e.target ? e.target.value : e
    const ticketTypeOptions = []
    _map(v || [], item => {
      const ticketType = _find(tickets, ticketItem => ticketItem.id == item)
      if (ticketType) {
        ticketTypeOptions.push({
          id: ticketType.id,
          value: ticketType.id,
          label: ticketType.displayName
        })
      }
    })
    this.setState({
      ticketTypeOptions
    })
    this.handleSelect(ticketTypeOptions[0])
  }

  handleSelect = option => {
    this.handleChangeTicketTypeID(option.id)
  }

  onTicketsChange = selectedTab => {
    const { resetForm } = this.props
    if(selectedTab === this.state.selectedTab) return
    this.setState({
      selectedTab
    })
    this.processQuantity(1)
    resetForm()
    this.updateField('isSeatMap', selectedTab === 'ticketsOnMap')
  }

  render() {
    const { submitLabel, tickets, tables, cardsStatus, addons, event } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleSubmit,
      seatData,
      seatDataChecked,
      getSeatDataLoading
    } = this.props
    const { ticketTypeOptions, isShowQuantity, selectedTab } = this.state

    let ticketTableLabel = ''
    const optionsTickets = [{ value: '', label: '' }]
    let filteredTimeSlotTickets = {}

    _forEach(tickets, (groupItem, key) => {
      if (_isArray(groupItem)) {
        // Check for number case with isNaN
        if (filteredTimeSlotTickets[key] || !isNaN(+key)) {
          filteredTimeSlotTickets[`${key} - Slots`] = _filter(groupItem, { active: true })
        } else {
          filteredTimeSlotTickets[key] = _filter(groupItem, { active: true })
        }
      } else if (groupItem.active) {
        const keyName = groupItem.groupName ? groupItem.displayName : groupItem.name
        filteredTimeSlotTickets[keyName] = groupItem
      }
    })
    const eventTableMapEnabled = _some(filteredTimeSlotTickets, ticket => ticket.allowDirectPurchaseForSeatMap)
    if(seatData) {
      filteredTimeSlotTickets = _filter(filteredTimeSlotTickets, ticket => ticket.allowDirectPurchaseForSeatMap)
    }

    if(!_isEmpty(filteredTimeSlotTickets) && !_isEmpty(tables)) {
      ticketTableLabel = 'Ticket/Table Type'
    } else if(!_isEmpty(tables)) {
      ticketTableLabel = 'Table Type'
    } else {
      ticketTableLabel = 'Ticket Type'
    }
    optionsTickets[0] = { value: '', label: ticketTableLabel }

    _forEach(filteredTimeSlotTickets, (ticket, key) => {
      optionsTickets.push({ value: ticket.id || key, label: ticket.displayName || key })
    })

    _forEach(tables, table => {
      if(table.active) {
        const key = table.displayName || table.name
        optionsTickets.push({ value: table.id || key, label: key })
      }
    })

    const optionsAddons = [{ value: '', label: 'Select Add On' }]
    _forEach(addons, addon => {
      if (!addon.isGroup) {
        const addonName = addon.addOnGroupName ? `${addon.addOnGroupName}: ${addon.name}` : addon.name
        optionsAddons.push({ value: addon.id, label: addonName })
      }
    })

    const hasAttendeeTags = event.attendeeTags && event.attendeeTags.length > 0
    const displayRegularTickets = (selectedTab === "regularTickets" || !seatData)
    return (
      <form onSubmit={handleSubmit}>
        <Card
          title={'Send Guest Tickets'}
          className="guest-ticket-form"
          status={cardsStatus && cardsStatus.form}
        >
          {getSeatDataLoading || !seatDataChecked ? (
            <LoadingBar />
          ) : (
              <div>
                {
                  eventTableMapEnabled && (
                    <RadioGroup name="fruit" selectedValue={selectedTab} onChange={this.onTicketsChange}>
                      <Radio value="regularTickets" label="Regular Tickets" />
                      <Radio value="ticketsOnMap" label="Tickets on the Map" />
                    </RadioGroup>
                  )
                }
                {
                  displayRegularTickets ? (
                    <RegularTickets
                      values={values}
                      ticketTableLabel={ticketTableLabel}
                      optionsTickets={optionsTickets}
                      errors={errors}
                      touched={touched}
                      options_quantity={options_quantity}
                      filteredTimeSlotTickets={filteredTimeSlotTickets}
                      handleChangeTicketTypeID={this.handleChangeTicketTypeID}
                      handleChangeQuantity={this.handleChangeQuantity}
                      handleDayChange={this.handleDayChange}
                      handleTimeChange={this.handleTimeChange}
                      handleChangeEmail={this.handleChangeEmail}
                      handleChangeConfirmEmail={this.handleChangeConfirmEmail}
                    />
                  ) : (
                    <SeatMap
                      changeTicketType={this.handleTicketTypeSelect}
                      changeQuantity={this.handleChangeQuantity}
                      showQuantityField={this.showQuantityField}
                      event={event}
                      values={values}
                      touched={touched}
                      errors={errors}
                      ticketTypeOptions={ticketTypeOptions}
                      isShowQuantity={isShowQuantity}
                      options_quantity={options_quantity}
                      handleSelect={this.handleSelect}
                      handleChangeEmail={this.handleChangeEmail}
                      handleChangeConfirmEmail={this.handleChangeConfirmEmail}
                    />
                  )
                }
                {_map(values.ticketHolders, (holder, index) => (
                  <div key={index} className="row">
                    <div className="col-xs-12">
                      <div className="section-title-sytle">
                        <label>Ticket Holder #{index + 1}</label>
                      </div>
                    </div>
                    <div className="col-xs-4 col-lg-5 col-12">
                      <Field
                        id={'firstName' + index}
                        label="First Name"
                        value={_get(values, 'ticketHolders[' + index + '].first_name')}
                        error={_get(errors, 'ticketHolders[' + index + '].first_name')}
                        touched={_get(touched, 'ticketHolders[' + index + '].first_name')}
                        onChange={e => this.handleArrayChange(e, index, 'first_name')}
                      />
                    </div>
                    <div className="col-xs-4 col-lg-5 col-12">
                      <Field
                        id={'lastName' + index}
                        label="Last Name"
                        value={_get(values, 'ticketHolders[' + index + '].last_name')}
                        error={_get(errors, 'ticketHolders[' + index + '].last_name')}
                        touched={_get(touched, 'ticketHolders[' + index + '].last_name')}
                        onChange={e => this.handleArrayChange(e, index, 'last_name')}
                        onBlur={e => this.handleArrayChange(e, index, 'last_name')}
                      />
                    </div>
                    {addons.length > 0 && (
                      <div className="col-xs-2 col-12">
                        <Button
                          className="btn btn-primary btn-shadow guest-ticket-form-ticketholder-add-addon-btn"
                          type="button"
                          onClick={this.handleAddOn.bind(this, index)}
                        >
                          Attach Add-on
                        </Button>
                      </div>
                    )}
                    {_map(values.ticketHolders[index].add_ons, (addon, aindex) => (
                      <div key={aindex} className="col-xs-12 guest-ticket-form-ticketholder-addon">
                        <div className="row">
                          <div className="col-xs-4 col-lg-5 col-12">
                            <Select
                              id="addonId"
                              label={'Add On #' + (aindex + 1)}
                              options={optionsAddons}
                              value={_get(values, 'ticketHolders[' + index + '].add_ons[' + aindex + '].id')}
                              error={_get(errors, 'ticketHolders[' + index + '].add_ons[' + aindex + '].id')}
                              onChange={e => this.handleArrayChange2(e, index, aindex, 'id')}
                              onBlur={e => this.handleArrayChange2(e, index, aindex, 'id')}
                            />
                          </div>
                          <div className="col-xs-4  col-lg-5 col-12">
                            <Select
                              id={'addon_quantity_' + index + '_' + aindex}
                              label="Quantity"
                              options={options_quantity}
                              value={_get(
                                values,
                                'ticketHolders[' + index + '].add_ons[' + aindex + '].quantity'
                              )}
                              error={_get(
                                errors,
                                'ticketHolders[' + index + '].add_ons[' + aindex + '].quantity'
                              )}
                              onChange={e => this.handleArrayChange2(e, index, aindex, 'quantity')}
                              onBlur={e => this.handleArrayChange2(e, index, aindex, 'quantity')}
                            />
                          </div>
                          <div className="col-xs-3 col-lg-2 col-12">
                            <Button
                              className="btn btn-danger btn-shadow guest-ticket-form-ticketholder-remove-addon-btn"
                              type="button"
                              onClick={this.removeAddOn.bind(this, index, aindex)}
                            >
                              <i className="fa fa-trash" />
                              <span>Delete</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {values.ticketHolders.length > 1 && index === 0 && (
                      <div className="col-xs-12 col-12">
                        <Button
                          className="btn btn-primary btn-shadow guest-ticket-form-ticketholder-copy"
                          type="button"
                          onClick={this.copyFirstTicketHolder.bind(this)}
                        >
                          Copy details to all other tickets
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <div className="row">
                  <div className="col-xs-12 col-12 ticket-holders-info">
                    <br />
                    <Field
                      id="notes"
                      label="Notes (optional)"
                      value={values.notes}
                      error={errors.notes}
                      touched={touched.notes}
                      onChange={this.handleChangeNotes}
                    />
                  </div>
                </div>
                {hasAttendeeTags && (
                  <div className="row">
                    <div className="col-xs-12 col-12 ticket-holders-info">
                      <br />
                      <TagsField2
                        label="Attendee Tags"
                        name="tags"
                        value={values.tags}
                        tags={event.attendeeTags}
                        onChange={this.handleChangeTags.bind(this)}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xs-12 col-12">
                    <br />
                    <Button className="btn btn-primary btn-shadow" type="submit" loading={isSubmitting}>
                      {submitLabel || 'Issue Tickets'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
        </Card>
      </form>
    )
  }
}

const TicketGuestForm = withFormik({
  mapPropsToValues: props => ({
    quantity: 1,
    first_name: '',
    last_name: '',
    email: '',
    confirmEmail: '',
    ticketTypeID: '',
    ticketHolders: [
      {
        first_name: '',
        last_name: '',
        add_ons: []
      }
    ],
    notes: '',
    tags: [],
    selectedGroup: '',
    selectedDay: '',
    selectedTime: '',
    isSeatMap: true
  }),

  // Custom sync validation
  validate: values => validateTicket(values),

  handleSubmit: (
    { selectedDay, selectedGroup, selectedTime, ...values },
    { props, setSubmitting, setTouched, resetForm }
  ) => {
    const { isSeatMap } = values
    delete values.isSeatMap

    props
      .onSubmit({ ...values }, isSeatMap)
      .then(v => {
        setTouched(false)
        setSubmitting(false)
        resetForm()
      })
      .catch(err => {
        setTouched(false)
        setSubmitting(false)
      })
  },
  displayName: 'TicketGuestForm' // helps with React DevTools
})(MyForm)

export default TicketGuestForm
