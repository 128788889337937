import * as Sentry from '@sentry/react'

export const logEventListError = (accountId, eventIds) => {
  Sentry.captureException(
    `Event list contains undefined value for user: ${accountId}. Event list: ${JSON.stringify(eventIds)}`,
    Sentry.Severity.Warning,
  )
}

export const logEventListGetFromStorageError = err => {
  Sentry.captureException(`Fetching events from local storage failed: ${err}`, Sentry.Severity.Warning)
}
