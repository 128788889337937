import React from 'react'
import { Field } from 'formik'

import Card from '../../../_library/Card'
import Select from '../../../_library/Select'
import FileUploader from '../../../_library/FileUploader'
import EventTitleImagePreview from '../EventForm/EventTitleImagePreview'
import { ENV } from '../../../constants/env'

import {
  fileSizeLimitValidator,
} from '../../../../_common/core/validation'

import {
  FileUploaderField
} from '../../../formik/Fields'
import { AltViewCheckboxField } from './AltViewCheckboxField'

const svgOrPngMatchingRegexp = new RegExp('^.*\.(png|svg)$')

export default class AlternativeViewEventImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { previewOnBackgroundInView: false }
  }

  handleChangeImageURL = img => {
    const { values, setFieldValue } = this.props
    const updatedURLs = { ...values.imageURLs, [values.imageLanguage]: img }
    setFieldValue('imageURLs', updatedURLs)
  }

  handleChangeAutoBackground = (e) => {
    const { values, setFieldValue } = this.props
    const { checked } = e.target
    setFieldValue(
      'backgroundImage',
      checked ? null : values.backgroundImage
    )
    setFieldValue('autoBackground', checked)
  }

  handleChangeReplacementImage = img => {
    const { setFieldValue } = this.props

    if (img) {
      setFieldValue('titleReplacementImage', img)
    } else {
      setFieldValue('titleReplacementImage', '')
    }


  }

  handlePreviewOnBackground = () => {
    this.setState({ previewOnBackgroundInView: !this.state.previewOnBackgroundInView })
  }

  getTitleReplacementImage = () => {
    const { titleReplacementImage, titleReplacementImageSvg } = this.props.values
    //const imageUrl = titleReplacementImage || titleReplacementImageSvg || this.props.titleReplacementImage
    const imageUrl = titleReplacementImage || this.props.titleReplacementImage
    if (imageUrl && !imageUrl.startsWith('https://')) {
      return `${ENV.API_BASE}/uploaded/${imageUrl}`
    }
    return imageUrl
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.titleReplacementImage !== this.props.titleReplacementImage && this.props.titleReplacementImage) {
      this.props.handleChangeImageAsAlternativeEventName(true)
    }
  }

  render() {
    const {
      event,
      values,
      errors,
      cardsStatus,
      useImageInsteadOfTitle,
      handleChangeImageAsAlternativeEventName,
      closed,

      setFieldValue
    } = this.props
    const eventImage = values.imageURLs ? values.imageURLs[values.imageLanguage] : ''
    const style = {
      width: 'auto',
      margin: '0px 15px 0px 0px',
      height: 30,
      paddingRight: 30,
      marginTop: 0
    }

    return (
      <div className="alternative-view-image-holder">
        <Card
          closed={closed}
          icon={'fa-camera'}
          title={'Event Images'}
          rightAction={(
            <Select
              fieldKey={'imageLanguage'}
              style={style}
              fieldGroupStyle={{ marginTop: 5 }}
              value={values.imageLanguage}
              onChange={(e) => setFieldValue('imageLanguage', e.target.value)}
              onClick={e => e.stopPropagation()}
              options={values.languageOptions}
            />
          )
          }
        >
          <div className="alternative-view-image">
            <div className="row">
              <div className="col-xs-4 col-12">
                <div className="row checkbox-row-with-detail">
                  <div className="col-xs-2 col-2 left-column">
                    <Field locale image name={values.imageLanguage + 'Check'} event={event} values={values} component={AltViewCheckboxField}/>
                  </div>
                </div>
                <div className={!(values[values.imageLanguage + 'Check']) ? 'disable-element' : ''}>
                  <FileUploader
                    id="imageURLs"
                    label={'Add Flyer/Poster'}
                    filetype="image"
                    value={eventImage}
                    onChange={this.handleChangeImageURL}
                  />
                  {
                    errors.imageURLs ? <small className='text-danger'>{errors.imageURLs}</small> : null
                  }
                </div>
              </div>
              <div className="col-xs-8 col-12">
                <div className="row checkbox-row-with-detail">
                  <div className="col-xs-2 col-2 left-column">
                    <Field name="backgroundCheck" event={event} values={values} component={AltViewCheckboxField}/>
                  </div>
                </div>
                <div className={!values.backgroundCheck ? 'disable-element' : ''}>
                  {(!values.autoBackground) && (
                    <Field
                      name="backgroundImage"
                      label='Add Background Image (optional)'
                      filetype="image"
                      validate={fileSizeLimitValidator()}
                      component={FileUploaderField}
                    />
                  )}
                  {(
                    <div className="line">
                      <div className="line-cell">
                        <label htmlFor={'autoBackgroundView'}>Use automatically-generated background</label>
                      </div>
                      <div className="line-cell">
                        <div className="checkbox-switch">
                          <input
                            type="checkbox"
                            id={'autoBackgroundView'}
                            checked={!!values.autoBackground}
                            onChange={this.handleChangeAutoBackground}
                          />
                          <label htmlFor={'autoBackgroundView'}/>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row checkbox-row-with-detail">
              <div className="col-xs-2 col-2 left-column">
                <Field name="eventLogoCheck" event={event} values={values} component={AltViewCheckboxField}/>
              </div>
            </div>
            <div className={!values.eventLogoCheck ? 'disable-element' : ''}>
              {(
                <div className="row">
                  <div
                    className={`col-xs-4 col-12 titleReplacementImageBlock ${this.state.previewOnBackgroundInView && !!this.getTitleReplacementImage() ? 'hidden_file_uploader' : ''}`}>
                    <div className="line">
                      <div className="line-cell">
                        <label htmlFor="imageAsAlternativeEventName">Use transparent logo (PNG/SVG) instead of text event
                        name</label>
                      </div>
                      <div className="line-cell">
                        <div className="checkbox-switch">
                          <input
                            type="checkbox"
                            id="imageAsAlternativeEventName"
                            checked={useImageInsteadOfTitle}
                            onChange={handleChangeImageAsAlternativeEventName}
                          />
                          <label htmlFor="imageAsAlternativeEventName"/>
                        </div>
                      </div>
                    </div>
                    {useImageInsteadOfTitle && (
                      <FileUploader
                        id="titleReplacementImageAlternativeView"
                        label='Add Title Image'
                        filetype="image"
                        accept='image/png, image/svg+xml'
                        value={this.getTitleReplacementImage()}
                        onChange={this.handleChangeReplacementImage}
                        imgClassName='title_replacement_img'
                      />

                    )}
                    {
                      errors.titleReplacementImage ? <small className='text-danger'>{errors.titleReplacementImage}</small> : null
                    }
                  </div>
                  {useImageInsteadOfTitle && (
                    values.backgroundImage || values.blurredBackgroundUrl ? (
                      <div className="use_image_instead_of_title">
                        <button disabled={!!!this.getTitleReplacementImage()} type="button"
                          onClick={this.handlePreviewOnBackground} className="btn btn-default btn-shadow">
                        Preview on background
                        </button>
                        {this.state.previewOnBackgroundInView && !!this.getTitleReplacementImage() && (
                          <div>
                            <div className="col-xs-12">
                              <EventTitleImagePreview
                                backgroundImageUrl={values.autoBackground ? values.blurredBackgroundUrl : values.backgroundImage ? values.backgroundImage : values.blurredBackgroundUrl}
                                titleImageUrl={this.getTitleReplacementImage()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="col-xs-8 col-12">
                        <div className="line">Please save the changes to be able to preview the title image.</div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
