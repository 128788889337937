import moment from 'moment-timezone'
import _map from 'lodash/map'

const getAvailableTimezones = () => {
  const zones = new Set()
  const countries = moment.tz.countries()

  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones)
  }

  return _map([...zones].sort(), item => ({ value: item, label: item }))
}

export default getAvailableTimezones
