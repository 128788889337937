import React from 'react'
import Select from '../../_library/Select'
import moment from 'moment'
import { TIME_DISPLAY_FORMAT } from '../../constants/timeFormats'

export const getDayAndHour = date => {
  let [d, h] = date.split(' ')
  h = h.slice(0, 5)
  return [d, h]
}

const getOption = (value, label, o = { grouped: false }) => ({ value, label, ...o })

export const getOptions = (timeSlots = []) => {
  const days = new Set()
  const daysMap = new Map()
  timeSlots.forEach(el => {
    const [d, h] = getDayAndHour(el.startDate)
    const option = getOption(el.id, h)
    option.isSoldOut = !el.stock
    if (daysMap.has(d)) {
      daysMap.get(d).push(option)
    } else {
      days.add(getOption(d, d))
      daysMap.set(d, [option])
    }
  })
  return { days, daysMap }
}

class SelectTimeSlotItem extends React.Component {
  constructor(props) {
    super(props)
    const { defaultValue, hours } = props
    let initialValue = null
    const ff = hours.find(el => el.value === defaultValue)
    if (ff) {
      initialValue = ff.id
    }
    this.state = {
      initialValue
    }
  }

  componentDidMount() {
    const { initialValue } = this.state
    const { hours } = this.props
    !initialValue && this.props.onChange(hours[0])
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.hours !== this.props.hours) {
      this.props.onChange(nextProps.hours[0])
    }
  }

  onChange = e => {
    this.props.onChange(e)
  }

  modifyTime = () => this.props.hours.map(item => {
    let label = moment(item.label, 'hh:mm').format(TIME_DISPLAY_FORMAT)
    if (item.isSoldOut) {
      label += ' (Sold Out)'
    }
    return { ...item, label }
  })

  render() {
    const { initialValue } = this.state
    const options = this.modifyTime()
    return (
      <div className="col-xs-6 col-12">
        <Select options={options} onSelect={this.onChange} defaultValue={initialValue} />
      </div>
    )
  }
}
class SelectTimeSlot extends React.Component {
  constructor(props) {
    super(props)
    const { timeSlots, defaultValue } = this.props
    const { days, daysMap } = getOptions(timeSlots)
    const options = [...days]
    this.daysMap = daysMap

    let initialValue = null
    let selectedDay = null
    for (const d of daysMap) {
      if (!defaultValue) {
        initialValue = d[0]
        selectedDay = d[1]
        break
      }
      const ff = d[1].find(el => el.value === defaultValue)
      if (ff) {
        initialValue = d[0]
        selectedDay = d[1]
        break
      }
    }
    this.state = {
      selectedDay,
      options,
      initialValue
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.timeSlots !== this.props.timeSlots) {
      const { timeSlots } = nextProps
      const { days, daysMap } = getOptions(timeSlots)
      const options = [...days]
      this.daysMap = daysMap
      let selectedDay = null
      for (const d of daysMap) {
        selectedDay = d[1]
      }
      this.setState(() => ({ options, selectedDay }))
    }
  }

  onChangeDay = e => {
    this.setState(() => ({
      selectedDay: this.daysMap.get(e.value)
    }))
  }
  onChange = e => {
    this.props.onChange(e)
  }
  render() {
    const { selectedDay, options, initialValue } = this.state
    const { defaultValue } = this.props
    return (
      <div className="col-xs-8 col-12">
        <div className="col-xs-6 col-12">
          <Select options={options} onSelect={this.onChangeDay} defaultValue={initialValue} />
        </div>
        {selectedDay && (
          <SelectTimeSlotItem
            defaultValue={defaultValue}
            onChange={this.onChange}
            hours={selectedDay}
          />
        )}
      </div>
    )
  }
}

class SelectTimeSlotTickets extends React.Component {
  constructor(props) {
    super(props)
    let { groupedTickets, noGroupedTickets, defaultValue = null } = props
    let initialValue = ''
    let selectedGroup = null
    if (defaultValue) {
      const ff = noGroupedTickets.find(el => el.id === defaultValue)
      if (!ff) {
        for (const g of groupedTickets) {
          const ff = g.find(el => el.id === defaultValue)
          if (ff) {
            initialValue = g.groupId
            selectedGroup = g
            break
          }
        }
      } else {
        initialValue = ff.id
        defaultValue = null
      }
    }
    this.state = {
      selectedGroup,
      initialValue,
      defaultValue
    }
  }

  onChangeGroup = e => {
    this.setState(() => ({ selectedGroup: e.group || null, defaultValue: null }))
    if (!e.grouped) {
      this.props.onChange(e)
    }
  }

  onChange = e => {
    this.props.onChange(e)
  }

  render() {
    const { groupedTickets, noGroupedTickets } = this.props
    const { selectedGroup, initialValue, defaultValue } = this.state
    const options1 = noGroupedTickets.map(el => getOption(el.id, el.name))
    const options2 = groupedTickets.map(el =>
      getOption(el.groupId, el.groupName, {
        grouped: true,
        group: el
      })
    )
    return (
      <div>
        {selectedGroup && this.getLabels()}
        <div className="row">
          <div className="col-xs-4 col-12 ticet-type__group">
            <Select
              options={[...options1, ...options2]}
              onSelect={this.onChangeGroup}
              defaultValue={initialValue}
            />
          </div>
          {selectedGroup && (
            <SelectTimeSlot
              onChange={this.onChange}
              defaultValue={defaultValue}
              timeSlots={selectedGroup}
            />
          )}
        </div>
      </div>
    )
  }

  getLabels = () => {
    const { labels } = this.props
    return (
      <div className="row">
        <div className="col-xs-4">{labels[0]}</div>
        <div className="col-xs-8">
          <div className="col-xs-6">{labels[1]}</div>
          <div className="col-xs-6">{labels[2]}</div>
        </div>
      </div>
    )
  }
}

export default SelectTimeSlotTickets
