import _get from 'lodash/get'
import { baseRequest } from '.'

export const logOutZoomUser = async () => {
  const res = await baseRequest.delete(`/zoom/auth/`)

  return _get(res, 'data')
}

export default logOutZoomUser
