import React from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../../_common/core/modalStyle'
import * as fb from '../../../../_common/core/fb'
import Field from '../../../_library/Field'
import Button from '../../../_library/Button'

const ImportFaceBookEvent = (props) => {
  const {
    importFacebook,
    fbURL,
    onFbEvent,
    closeFBDialog,
    values,
    descriptionLanguage,
    imageLanguage,
    updateField,
    onTokenResponse,
    handleChangeDescription
  } = props

  const handleFBImport = () => {
    let url = fbURL
    url = url.replace('business.facebook.com', 'www.facebook.com')
    if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/events\/[0-9]+/i.test(url)) {
      url = url.replace(/\/$/, '')
      const id = url.substring(url.lastIndexOf('/') + 1)

      updateField('facebookEvent', fbURL)

      const convertToHTML = pureText => {
        if (!pureText) return ''
        pureText = pureText.replace(/\n/g, '<br>\n')
        return '<div>' + pureText + '</div>'
      }
      const removeTimeOffset = dateStr => {
        if (!dateStr) return null
        return dateStr.substring(0, dateStr.length - 5) + '.000Z'
      }

      fb.fetchSDK()
        .catch(onerror)
        .then(FB => new Promise(resolve => {
          FB.login(
            () => {
              FB.api(
                '/' + id + '?fields=description,timezone,cover,start_time,end_time,name,place',
                (response) => {
                  if (response.error) {
                    console.log('Fetch failed', response.error)
                  } else {
                    const updatedDescriptions = {
                      ...values.descriptions,
                      [descriptionLanguage]: val
                    }
                    const updatedURLs = {
                      ...values.imageURLs,
                      [imageLanguage]: response.cover.source
                    }
                    console.log('Fetch successful!!!', JSON.stringify(response))
                    handleChangeDescription(convertToHTML(response.description))
                    updateField('displayName', response.name)
                    updateField('startDate', removeTimeOffset(response.start_time))
                    updateField('endDate', removeTimeOffset(response.end_time))
                    updateField('imageURLs', updatedURLs)
                    updateField('descriptions', updatedDescriptions)
                    const venueObj = {
                      displayName: response.place.name,
                      street: response.place.location.street,
                      city: response.place.location.city,
                      state: response.place.location.state,
                      country: response.place.location.country,
                      postalCode: response.place.location.zip,
                      longitude: response.place.location.longitude,
                      latitude: response.place.location.latitude
                    }
                    updateField('venue', venueObj)
                    closeFBDialog()
                  }
                }
              )
            },
            { scope: 'public_profile' }
          )
        }).catch(onerror))
        .catch(onerror)
        .then(auth => {
          if (auth.status === 'connected') {
            onTokenResponse(auth.authResponse)
          } else if (auth.status === 'not_authorized') {
            throw new Error(
              'You have not authorized The Ticket Fairy to use your Facebook details.'
            )
          } else {
            throw new Error('You did not successfully sign in to Facebook.')
          }
        })
        .catch(onerror)
    } else {
      console.log('URL is invalid:', url)
    }
  }

  return (
    <Modal
      className="modal-dialog modal-trans"
      style={modalStyle}
      isOpen={!!importFacebook}
      contentLabel="Modal"
      onRequestClose={closeFBDialog}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div>
            <div className="modal-header">
              <p className="h4 text-compact">Import from Facebook Event</p>
            </div>
            <div className="modal-body">
              <Field
                ref="fbevent"
                id="fbevent"
                label="Event URL"
                size="large"
                value={fbURL}
                onChange={onFbEvent}
              />
            </div>
            <div className="modal-footer">
              <div className="btn-toolbar btn-toolbar-right">
                <Button
                  className="btn btn-danger btn-shadow"
                  type="button"
                  onClick={handleFBImport}
                >
                    Import
                </Button>
                <Button
                  className="btn btn-default btn-shadow"
                  type="button"
                  onClick={closeFBDialog}
                >
                    Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ImportFaceBookEvent
