import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import countries from 'world-countries'
import { toUpperCase } from './validation'

export default _filter(countries, c => _some(
  [
    'AUD',
    'EUR',
    'USD',
    'NZD',
    'GBP',
    'INR',
    'KES',
    'CAD',
    'IDR',
    'ISK',
    'MXN',
    'THB',
    'HRK',
    'JMD',
    'COP',
    'DOP',
    'BBD',
    'JOD',
    'ARS',
    'AED',
    'QAR',
    'CRC',
    'CHF',
    'BRL',
    'PHP',
    'MAD',
    'CZK',
    'GRC',
    'SGD',
    'PLN',
    'TRY',
  ],
  cur => _includes(_keys(c.currencies), cur),
)).sort((a, b) => {
  if (a.name.common === "Czechia") {
    a.name.common = a.name.official
  }

  return a.name.common.localeCompare(b.name.common)
})

export const countryName = countryCode => {
  const found = _find(countries, c => c.cca2 === toUpperCase(countryCode))
  return found ? found.name.common : countryCode
}
