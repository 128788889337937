import React from 'react'
import Modal from 'react-modal'
import Field from '../../../../_library/Field'
import CurrencyField from '../../../../_library/CurrencyField'
import Button from '../../../../_library/Button'
import modalStyle from '../../../../../_common/core/modalStyle'

export default class TimeSlotsEditModal extends React.Component {
  handleChangeActive = (e, value) => {
    const { handleChange } = this.props
    handleChange(e.target.id, e.target.checked)
  }

  handleChangeMaxInventory = e => {
    const { handleChange } = this.props
    handleChange(e.target.id, e.target.value)
  }

  handleChangeCurrency = value => {
    const { handleChange } = this.props
    handleChange('price', value)
  }

  render() {
    const { display, values, onSubmit, onCancel } = this.props

    return (
      <Modal
        className="modal-dialog modal-trans modal-daterange"
        style={modalStyle}
        isOpen={display}
        contentLabel="Modal"
        onRequestClose={this.onClose}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="date-range-value">{`Edit Time Slot`}</div>
            </div>
            <div className="modal-body" style={{ width: '100%', display: 'inline-block' }}>
              <div className="col-sm-12">
                <div className="line-cell">
                  <div className="checkbox-switch">
                    <input
                      type="checkbox"
                      id="active"
                      checked={values.active}
                      value={values.active}
                      onChange={this.handleChangeActive}
                    />
                    <label htmlFor="active" />
                  </div>
                </div>
                <div className="line-cell">
                  <label htmlFor="active">Enabled</label>
                </div>
              </div>
              <div className="div-spacing-20" />
              <div className="col-sm-6">
                <Field
                  id="maxInventory"
                  type="number"
                  label="Maximum Number of Sales"
                  value={values.maxInventory}
                  error={!values.maxInventory}
                  onChange={this.handleChangeMaxInventory}
                />
              </div>
              <div className="col-sm-6">
                <CurrencyField
                  id="editPrice"
                  type="text"
                  label="Price"
                  value={values.price}
                  error={!values.price}
                  onChange={this.handleChangeCurrency}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-toolbar btn-toolbar-right">
                <Button className="btn btn-seablue btn-shadow" type="button" onClick={onSubmit}>
                  {'Update'}
                </Button>
                <Button className="btn btn-cancel btn-shadow" type="button" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
