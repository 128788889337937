import _get from 'lodash/get'
import { baseRequest } from '.'

export const sendTwilioSMS = async (eventId, form) => {
  const res = await baseRequest.post(`event/${eventId}/sms/send`, {
    ...form,
  })

  return _get(res, 'data')
}

export const getTwilioSentSMS = async eventId => {
  const res = await baseRequest.get(`events/${eventId}/relationships/sent_smses/`)
  return _get(res, 'data.data.sent_smses', [])
}
