import React from 'react'
import _isEqual from 'lodash/isEqual'
import _slice from 'lodash/slice'
import _map from 'lodash/map'

import { Pagination } from '../_library/Pagination'
import SortableTable from '../_library/SortableTable'
import LoadingBar from '../_library/LoadingBar'

const PAGE_SIZE_OPTIONS = _map([10, 50, 100, 250, 500, 1000, 2500], item => ({ label: item, value: item }))

const SortableTableWithPaginationHOC = (TableComponent = SortableTable) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        enablePagination: false,
        pageNumber: props.pageNumber || 1,
        totalRecords: 0,
        pageSize: props.defaultPageSize || PAGE_SIZE_OPTIONS[0].value,
        data: null,
        resetPagination: props.resetPagination,
      }
    }

    componentDidMount() {
      this.update()
    }

    componentDidUpdate(prevProps, prevState) {
      const { data, sortBy, resetPagination } = this.props
      const { pageNumber, pageSize } = this.state

      if (
        !_isEqual(data, this.initialData) ||
        !_isEqual(pageNumber, prevState.pageNumber) ||
        !_isEqual(resetPagination, prevProps.resetPagination) ||
        !_isEqual(sortBy, prevProps.sortBy) ||
        !_isEqual(pageSize, prevState.pageSize)
      ) {
        this.update()
      }
    }

    update = () => {
      const {
        data = [],
        pageNumber = 1,
        totalRecords,
        defaultPageSize = PAGE_SIZE_OPTIONS[0].value,
        onPageOrLimitChange,
        resetPagination,
      } = this.props
      const totalRecordsValue = totalRecords || data.length
      const isAsyncPagination = !!onPageOrLimitChange

      this.initialData = data

      this.setState(prevState => {
        let pageNum = totalRecords ? pageNumber : prevState.pageNumber
        const startIndex = prevState.pageSize * (pageNum - 1)
        const newData = totalRecords ? data : _slice(data, startIndex, pageNum * prevState.pageSize)

        if (data.length < prevState.pageSize * (pageNum - 1) && !isAsyncPagination) {
          pageNum = 1
        }
        const isSameReset = resetPagination === this.state.resetPagination

        return {
          data: newData || [],
          enablePagination: totalRecordsValue > defaultPageSize,
          totalRecords: totalRecordsValue,
          pageNumber: resetPagination && !isSameReset ? 1 : pageNum,
          resetPagination,
          pageSize: resetPagination && !isSameReset ? defaultPageSize : prevState.pageSize,
        }
      })
    }

    onPageChange = pageNumber => {
      const { onPageOrLimitChange, onPageChange: onPageChangeProp } = this.props
      const { pageSize } = this.state

      if (pageNumber === this.state.pageNumber) return

      this.setState({ pageNumber }, () => {
        if (onPageOrLimitChange) {
          onPageOrLimitChange(pageNumber, pageSize)
        }
        if (onPageChangeProp) {
          onPageChangeProp(pageNumber)
        }
      })
    }

    onPageSizeChange = pageSizeOption => {
      const { onPageOrLimitChange, onPageSizeChange: onPageSizeChangeProp } = this.props

      const pageSize = parseInt(pageSizeOption.value)
      if (pageSize === this.state.pageSize) return

      this.setState({ pageSize }, () => {
        if (onPageOrLimitChange) {
          onPageOrLimitChange(1, pageSize)
        }
        if (onPageSizeChangeProp) {
          onPageSizeChangeProp(pageSize)
        }
      })
    }

    render() {
      const { pageNumber, pageSize, totalRecords, enablePagination } = this.state
      const {
        data,
        sortBy,
        tableColumns,
        detailsRows = [],
        enableCopyTable,
        enableSort,
        isLoadingPageData,
        showPageLoading,
        asyncSortBy,
        ...rest
      } = this.props

      if (showPageLoading && isLoadingPageData) {
        return <LoadingBar />
      }

      return this.state.data ? (
        <div>
          <TableComponent
            tableColumns={tableColumns}
            sortBy={sortBy}
            enableCopyTable={enableCopyTable}
            enableSort={enableSort}
            detailsRows={detailsRows}
            className="dark"
            {...this.state}
            {...rest}
          />
          {enablePagination && (
            <Pagination
              activePage={pageNumber}
              pageRangeDisplayed={5}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalRecords}
              onChange={this.onPageChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onPageSizeChange={this.onPageSizeChange}
            />
          )}
        </div>
      ) : (
        <LoadingBar />
      )
    }
  }

export default SortableTableWithPaginationHOC
