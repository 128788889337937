import _map from 'lodash/map'

export const GIFT_CARD_TYPE = {
  TICKET: 'ticket',
  AMOUNT: 'amount',
  MEMBERSHIP: 'membership'
}

export const GIFT_CARD_TYPE_OPTIONS = [
  { value: '', label: 'Select...' },
  { value: 'ticket', label: 'Ticket' },
  { value: 'amount', label: 'Amount' },
  { value: 'membership', label: 'Memebership' }
]

export const MONTHS_OPTIONS = _map(new Array(13), (item, index) => {
  if (!index) {
    return {
      value: '',
      label: 'Select...'
    }
  }
  return {
    value: `${index}`,
    label: `${index}`
  }
})
