import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  preRegistrationDates: {
    checked: false,
    exist: false,
    data: {},
  },
  preRegistrationActions: {
    checked: false,
    exist: false,
    data: {},
  },
  preRegistrationPrizes: {
    checked: false,
    exist: false,
    data: [],
  },
}

//DATES
const FETCH_PRE_REGISTRATION_DATES = createAsyncHandlers('FETCH_PRE_REGISTRATION_DATES', {
  success(state, action) {
    const { data } = action.payload

    state.preRegistrationDates = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationDates = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

const UPDATE_PRE_REGISTRATION_DATES = createAsyncHandlers('UPDATE_PRE_REGISTRATION_DATES', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationDates = {
      data,
      checked: true,
      exist: true,
    }
  },
})

//ACTIONS
const FETCH_PRE_REGISTRATION_ACTIONS = createAsyncHandlers('FETCH_PRE_REGISTRATION_ACTIONS', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationActions = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationActions = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})
const CREATE_PRE_REGISTRATION_ACTIONS = createAsyncHandlers('CREATE_PRE_REGISTRATION_ACTIONS', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationActions = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationActions = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

const UPDATE_PRE_REGISTRATION_ACTIONS = createAsyncHandlers('UPDATE_PRE_REGISTRATION_ACTIONS', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationActions = {
      data,
      checked: true,
      exist: true,
    }
  },
})
const DELETE_PRE_REGISTRATION_ACTIONS = createAsyncHandlers('DELETE_PRE_REGISTRATION_ACTIONS', {
  success(state, action) {
    state.preRegistrationActions = {
      checked: true,
      exist: false,
      data: {},
    }
  },
})
// PRIZES
const FETCH_PRE_REGISTRATION_PRIZES = createAsyncHandlers('FETCH_PRE_REGISTRATION_PRIZES', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationPrizes = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationPrizes = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})
const DELETE_PRE_REGISTRATION_PRIZES = createAsyncHandlers('DELETE_PRE_REGISTRATION_PRIZES', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationPrizes = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationPrizes = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

const CREATE_PRE_REGISTRATION_PRIZES = createAsyncHandlers('CREATE_PRE_REGISTRATION_PRIZES', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationPrizes = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationPrizes = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

const EDIT_PRE_REGISTRATION_PRIZES = createAsyncHandlers('EDIT_PRE_REGISTRATION_PRIZES', {
  success(state, action) {
    const { data } = action.payload
    state.preRegistrationPrizes = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.preRegistrationPrizes = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})
export default handleActions(
  {
    //dates
    ...FETCH_PRE_REGISTRATION_DATES,
    ...UPDATE_PRE_REGISTRATION_DATES,
    //actions
    ...FETCH_PRE_REGISTRATION_ACTIONS,
    ...CREATE_PRE_REGISTRATION_ACTIONS,
    ...UPDATE_PRE_REGISTRATION_ACTIONS,
    ...DELETE_PRE_REGISTRATION_ACTIONS,
    //prizes
    ...FETCH_PRE_REGISTRATION_PRIZES,
    ...DELETE_PRE_REGISTRATION_PRIZES,
    ...CREATE_PRE_REGISTRATION_PRIZES,
    ...EDIT_PRE_REGISTRATION_PRIZES,
  },
  initialState,
)
