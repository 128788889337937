import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

import { showAxiosError } from '../../../web/utils/messenger'
import { getEventAlternativeViews } from '../../core/http_services'

const { FETCH_ALTERNATIVE_VIEWS } = createAsyncAction('FETCH_ALTERNATIVE_VIEWS', function (eid) {
  return async dispatch => {
    try {
      dispatch(this.start({ eid }))
      const alternativeViews = await getEventAlternativeViews(eid)
      dispatch(this.success({ eid, alternativeViews }))
      return alternativeViews
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_ALTERNATIVE_VIEW } = createAsyncAction('CREATE_ALTERNATIVE_VIEW', function (form) {
  return dispatch => {
    const { eventId } = form
    return fetchAPI(`/api/event/${eventId}/alternative-view/`, {
      method: 'POST',
      body: JSON.stringify({ data: { ...form, type: 'event_alternative_view' } }),
    })
      .catch(err => {
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res)
  }
})

const { UPDATE_ALTERNATIVE_VIEW } = createAsyncAction('UPDATE_ALTERNATIVE_VIEW', function (form) {
  return dispatch => {
    const { viewId } = form.attributes
    return fetchAPI(`/api/event/alternative-view/${viewId}/`, {
      method: 'PUT',
      body: JSON.stringify({ data: { ...form, type: 'event_alternative_view' } }),
    })
      .catch(err => {
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
  }
})

const { DELETE_ALTERNATIVE_VIEW } = createAsyncAction('DELETE_ALTERNATIVE_VIEW', function (id) {
  return dispatch =>
    fetchAPI(`/api/event/alternative-view/${id}/`, {
      method: 'DELETE',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res)
})

export { FETCH_ALTERNATIVE_VIEWS, CREATE_ALTERNATIVE_VIEW, UPDATE_ALTERNATIVE_VIEW, DELETE_ALTERNATIVE_VIEW }
