import { baseRequest } from '.'
import _get from 'lodash/get'
import { addOnAdapter, addOnsAdapter } from './adapters'

export const getAddOns = async eventId => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/add-ons/`)
  return addOnsAdapter(_get(res, 'data.data'))
}

export const createAddOn = async (eventId, data) => {
  const dataWithAttributes = {
    attributes: {
      ...data,
    },
  }

  const res = await baseRequest.post(`/add_ons/`, {
    data: dataWithAttributes,
    relationships: {
      event: {
        data: {
          id: eventId,
        },
      },
    },
  })
  return addOnAdapter(_get(res, 'data.data'))
}

export const createAddOnGroup = async (eventId, data) => {
  const dataWithAttributes = {
    attributes: {
      ...data,
    },
  }

  const res = await baseRequest.post(`/event/${eventId}/add_on_groups/`, {
    data: dataWithAttributes,
    relationships: {
      event: {
        data: {
          id: eventId,
        },
      },
    },
  })
  return addOnAdapter(_get(res, 'data.data'))
}

export const deleteAddOn = async addonId => {
  const res = await baseRequest.delete(`/add_ons/${addonId}/`)
  return addOnAdapter(_get(res, 'data.data'))
}

export const updateAddOn = async (addonId, data) => {
  const res = await baseRequest.patch(`/add_ons/${addonId}/`, { data })
  return addOnAdapter(_get(res, 'data.data'))
}

export const updateAddOnGroup = async (addonId, data, eventId) => {
  const dataWithAttributes = {
    attributes: {
      ...data,
    },
  }

  const res = await baseRequest.patch(`event/${eventId}/add_on_groups/${addonId}`, {
    data: dataWithAttributes,
    relationships: {
      event: {
        data: {
          id: eventId,
        },
      },
    },
  })

  return addOnAdapter(_get(res, 'data.data'))
}

export const updateAddOnsSorting = async (eId, addOns) => {
  await baseRequest.patch(`event/${eId}/add-ons/sorting/`, {
    data: {
      attributes: {
        eventAddOns: addOns,
      },
    },
  })
}
