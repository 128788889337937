import React from 'react'

export const NotificationError = ({ setError, error }) => (
  <div className="error-wrapper-notification">
    <i className="fa fa-exclamation-circle" style={{ fontSize: 20 }} />
    <div style={{ flex: 1, paddingLeft: 20, paddingRight: 20, color: 'white', fontWeight: 600 }}>
      {error}
    </div>
    <i
      className="fa fa-times"
      style={{ fontSize: 20, cursor: 'pointer' }}
      onClick={() => {
        setError('')
      }}
    />
  </div>
)
