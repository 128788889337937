import React from 'react'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import {
  JSONDatatable,
  DATATABLE,
  TYPE_FROM_ARRAY
} from '../../_library/JSONDatatable'
import EmptyBar from '../../_library/EmptyBar'
import Toggle from '../../_library/Toggle'

export default class CheckInAddOn extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      showGroups: false,
    }
  }
	getSortedRows = (rows_filtered, sort) => {
	  if(sort){
	    const dir = sort.asc ? 'asc' : 'desc'
	    switch(sort.index){
	    case 0:
	      rows_filtered = _orderBy(rows_filtered, (t)=>t.name, dir)
	      break
	    case 1:
	      rows_filtered = _orderBy(rows_filtered, (t)=>parseInt(t.num_add_ons), dir)
	      break
	    default:
	      break
	    }
	  }
	  return rows_filtered
	}

	getTableData = (datatable, rows_filtered, sort) => {
	  const clipboard_text = this.getClipboardText(rows_filtered)
	  const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

	  const content_header = datatable.getHeaderRow(datatable, [
	    { title: 'Name', sort: true },
	    { title: 'No. of Add-Ons', sort: true },
	    { title: clipboard, sort: false, className: 'column-clipboard' },
	  ], sort)

	  let total_add_ons_tickettype = 0; let total_performance = 0
	  _map(rows_filtered, (value) =>{
	    total_add_ons_tickettype += parseInt(value.num_add_ons)
	    total_performance += parseInt(value.total)
	    return value
	  })

	  return (rows_filtered.length != 0) ? (
	    <table className="table tickets-table">
	      <thead>
	        {content_header}
	      </thead>
	      <tbody>
	        {
	          rows_filtered.map((row, index) => (
	            <tr key={index} className={index % 2== 0 ? 'row-stale' : ''}>
	              <td>{row.name}</td>
	              <td>{row.num_add_ons} / <strong>{row.total}</strong> ({row.total - row.num_add_ons} remaining)</td>
	              <td />
	            </tr>
	          ))
	        }
	      </tbody>
	      <tfoot>
	        <tr>
	          <td><strong>Total:</strong></td>
	          <td>{total_add_ons_tickettype} / <strong>{total_performance}</strong> ({total_performance - total_add_ons_tickettype} remaining)</td>
	          <td />
	        </tr>
	      </tfoot>
	    </table>
	  ): (
	    <EmptyBar/>
	  )
	}

	getClipboardText(rows_filtered){
	  let ret = ''
	  ret += 'Name' + '\t' + 'No. of Add-Ons' + '\n'
	  _map(rows_filtered, (t)=>{
	    ret += t.name + '\t' + t.num_add_ons + ' / ' + t.total + ' (' + (t.total - t.num_add_ons) + ' remaining)\n'
	  })
	  return ret
	}

	onChangeShowGroups = (checked) => {
	  this.setState({
	    showGroups: checked
	  })
	}

	render() {
	  const { add_ons, performance } = this.props
	  const add_ons_total = {}
	  _map(_groupBy(performance, rows=>rows.name), (value, key) =>{
	    let total = 0
	    _map(value, (row) => {
	      total += !isNaN(parseInt(row.num_sales)) ? parseInt(row.num_sales) : 0
	    })
	    add_ons_total[key]=total
	  })
	  const add_ons_merged = _map(_groupBy(add_ons, rows=>rows.name), (value, key) =>{
	    let total = 0; let group=''
	    _map(value, (row) => {
	      total += parseInt(row.num_add_ons)
	      group = row.group
	    })
	    return {
	      name: key,
	      num_add_ons: total,
	      group,
	      total: add_ons_total[key] || 0,
	    }
	  })
	  const { showGroups } = this.state
	  let content = null
	  if (showGroups) {
	    let index = 0
	    content = _map(_groupBy(add_ons_merged, rows=>rows.group), (value, key) => {
	      index++
	      const subTitle = isNil(key) ? 'Ungrouped' : capitalizeFirstLetter(key)
	      const row_subGroup = _map(value, (row)=>({
	        name: row.name,
	        num_add_ons: row.num_add_ons,
	        total: row.total,
	      }))
	      return (
	        <div key={index}>
	          <h4 className="inline">{subTitle}</h4>
	          <JSONDatatable
	            type={TYPE_FROM_ARRAY}
	            data={row_subGroup}
	            sort={{ index: 0, asc: true }}
	            getSortedRows={(rows_filtered, sort) => this.getSortedRows(rows_filtered, sort)}
	            getTableData={(datatable, rows_filtered, sort) => this.getTableData(datatable, rows_filtered, sort)}
	            getClipboardText={(rows_filtered) => this.getClipboardText(rows_filtered)}
	            usePagination={false}
	          >
	            <div ref={DATATABLE}/>
	          </JSONDatatable>
	          <div className="div-spacing-20"/>
	        </div>
	      )
	    })
	  } else {
	    content = (
	      <JSONDatatable
	        type={TYPE_FROM_ARRAY}
	        data={add_ons_merged}
	        sort={{ index: 0, asc: true }}
	        getSortedRows={(rows_filtered, sort) => this.getSortedRows(rows_filtered, sort)}
	        getTableData={(datatable, rows_filtered, sort) => this.getTableData(datatable, rows_filtered, sort)}
	        getClipboardText={(rows_filtered) => this.getClipboardText(rows_filtered)}
	      >
	        <div ref={DATATABLE}/>
	      </JSONDatatable>
	    )
	  }
	  return (
	    <div style={{ margin: 10 }}>
	      <div className="table-caption"><i className="fa fa-plus"/>Add-Ons</div>
	      <Toggle title="Show in groups" id="showGroups" value={showGroups} onChange={this.onChangeShowGroups}/>
	      {content}
	    </div>
	  )
	}
}
