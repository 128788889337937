import React from 'react'
import Card from '../../../_library/Card'
import RichTextArea from '../../../_library/RichTextArea'
import { ENV } from '../../../constants/env'
import CustomToggle from '../../../_library/CustomToggle'

export default class EventConfirmationText extends React.PureComponent {
  handleChangeDescription = val => {
    const { updateField } = this.props

    updateField('customConfirmationPageText', val)
  }

  handleToggleChange = (id, val) => {
    const { updateField } = this.props
    updateField(id, val)
  }

  render() {
    const {
      values,
      errors,
      touched,
    } = this.props


    const value = values.customConfirmationPageText

    return (
      <div className="event-details-description">
        <Card
          closed={false}
          icon={'fa-pencil-square'}
          title={'Event Custom Confirmation Text'}
        >
          <div className="row">
            <div className="col-xs-12">
              <RichTextArea
                id={'customConfirmationPageText'}
                label=""
                height={300}
                baseurl={ENV.CDN_URL}
                value={value}
                onChange={this.handleChangeDescription}
                error={errors.customConfirmationPageText}
                touched={touched.customConfirmationPageText}
                editorStyle={
                  errors.customConfirmationPageText && touched.customConfirmationPageText ? { border: '2px solid #d9534f' } : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <CustomToggle
                id="customConfirmationPageTextFullReplacement"
                onText="Use as the main confirmation text"
                value={values.customConfirmationPageTextFullReplacement}
                checked={values.customConfirmationPageTextFullReplacement}
                onChange={this.handleToggleChange}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
