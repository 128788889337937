import React from 'react'
import { Chart } from '../../../_library/Chart'
import { TabView, Tab } from '../../../_library/TabView'
import EmptyBar from '../../../_library/EmptyBar'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _isEmpty from 'lodash/isEmpty'

export default React.memo(props => {
  const { isLimited, data_quantity, data_revenue } = props

  return (
    <TabView>
      <Tab
        title={
          <span>
            <i className="fa fa-ticket" />
            Quantity
          </span>
        }
      >
        <br />
        {_isEmpty(data_quantity) ? (
          <EmptyBar />
        ) : (
          <Chart
            width={'100%'}
            height="400px"
            chartConfig={{
              type: 'bar',
              hideXLabel: true,
              data: {
                labels: _keys(data_quantity),
                datasets: [{ data: _values(data_quantity) }]
              },
              options: {
                legend: {
                  display: false
                }
              }
            }}
          />
        )}
        <br />
      </Tab>
      {!isLimited && (
        <Tab
          title={
            <span>
              <i className="fa fa-money" />
              Revenue
            </span>
          }
        >
          <br />
          {_isEmpty(data_revenue) ? (
            <EmptyBar />
          ) : (
            <Chart
              width={'100%'}
              height="400px"
              chartConfig={{
                type: 'bar',
                hideXLabel: true,
                data: {
                  labels: _keys(data_revenue),
                  datasets: [{ data: _values(data_revenue) }]
                },
                options: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          )}
          <br />
        </Tab>
      )}
    </TabView>
  )
})
