import React from 'react'
import { connect } from 'react-redux'
import EmailTemplates from '../_features/messaging/EmailTemplates'

const BrandCreateTemplate = ({ brand, configs }) => (
  <EmailTemplates originData={brand} originType="brand" configs={configs} />
)

const mapStateToProps = state => ({
  brand: state.brands.selected,
})

export default connect(mapStateToProps)(BrandCreateTemplate)
