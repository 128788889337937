import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

// http_services
import { getCheckedInDataTickets } from '../../core/http_services/checkin'
import {
  fetchEventTraffic,
  getCheckedInAddonsDates,
  getCheckedInData,
  getCheckedInDates,
} from '../../core/http_services'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_EVENT_PERFORMANCE } = createAsyncAction(
  'FETCH_EVENT_PERFORMANCE',
  (eid, section, group_by) => dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/performance/`, {
      method: 'GET',
      params: {
        section,
        'options[group_by]': group_by ?? undefined,
      },
      withCredentials: true,
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => res),
)

const { FETCH_EVENT_TRAFFIC } = createAsyncAction('FETCH_EVENT_TRAFFIC', function (eid, params) {
  return async dispatch => {
    try {
      const res = await fetchEventTraffic(eid, params)
      dispatch(this.success(res))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { FETCH_EVENT_DEMOGRAPHICS } = createAsyncAction('FETCH_EVENT_DEMOGRAPHICS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/demographics/`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        const out = { eid, performance: res }
        dispatch(this.success(out))
        return res
      })
})

const { FETCH_EVENT_CHECKIN } = createAsyncAction('FETCH_EVENT_CHECKIN', function (eid) {
  return async dispatch => {
    try {
      const checkInData = await getCheckedInData(eid)
      dispatch(this.success({ eid, data: checkInData }))
      return checkInData
    } catch (err) {
      dispatch(ERROR([err]))
    }
  }
})

const { FETCH_EVENT_CHECKIN_TICKETS } = createAsyncAction(
  'FETCH_EVENT_CHECKIN_TICKETS',
  function (eid, search_query, sort_by, sort_order) {
    return async dispatch => {
      try {
        const checkInData = await getCheckedInDataTickets(eid, search_query, sort_by, sort_order)
        dispatch(this.success({ eid, data: checkInData }))
        return checkInData.tickets
      } catch (err) {
        dispatch(ERROR([err]))
      }
    }
  },
)

const { FETCH_EVENT_CHECKIN_DATES } = createAsyncAction('FETCH_EVENT_CHECKIN_DATES', function (eid) {
  return async dispatch => {
    try {
      const checkInDates = await getCheckedInDates(eid)
      dispatch(this.success({ eid, data: checkInDates }))
      return checkInDates
    } catch (err) {
      dispatch(ERROR([err]))
    }
  }
})

const { FETCH_EVENT_CHECKIN_ADD_ONS_DATES } = createAsyncAction(
  'FETCH_EVENT_CHECKIN_ADD_ONS_DATES',
  function (eid) {
    return async dispatch => {
      try {
        const checkInAddonsDates = await getCheckedInAddonsDates(eid)
        dispatch(this.success({ eid, data: checkInAddonsDates }))
        return checkInAddonsDates
      } catch (err) {
        dispatch(ERROR([err]))
      }
    }
  },
)

const { UNSCAN_TICKET } = createAsyncAction('UNSCAN_TICKET', tid => dispatch => {
  const body = {
    attributes: {
      status: 'valid',
      checkedInByName: null,
      checkedInAt: null,
    },
  }
  return fetchAPI(`/api/issued_tickets/${tid}`, {
    method: 'PATCH',
    body: JSON.stringify({ data: { ...body } }),
  })
    .then(res => res)
    .catch(err => {
      dispatch(ERROR(...err.errors))
      throw err
    })
})

const { RESET_CHECK_IN } = createAsyncAction('RESET_CHECK_IN', function () {
  return async dispatch => {
    dispatch(this.success())
  }
})

export {
  FETCH_EVENT_PERFORMANCE,
  FETCH_EVENT_DEMOGRAPHICS,
  FETCH_EVENT_CHECKIN,
  FETCH_EVENT_CHECKIN_TICKETS,
  FETCH_EVENT_TRAFFIC,
  UNSCAN_TICKET,
  FETCH_EVENT_CHECKIN_DATES,
  FETCH_EVENT_CHECKIN_ADD_ONS_DATES,
  RESET_CHECK_IN,
}
