import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  tickets: {},
  tables: {},
  limitTexts: {},
  timeSlotGroups: {},
}

const FETCH_EVENT_TICKETS = createAsyncHandlers('FETCH_EVENT_TICKETS', {
  success(state, action) {
    const { eid, tickets } = action.payload

    state.tickets[eid] = tickets
  },
})

const FETCH_EVENT_TABLES = createAsyncHandlers('FETCH_EVENT_TABLES', {
  success(state, action) {
    const { eid, tables } = action.payload

    state.tables[eid] = tables
  },
})

const CREATE_TICKET = createAsyncHandlers('CREATE_TICKET', {
  success(state, action) {
    const { eid, ticket } = action.payload

    if (state.tickets[eid]) {
      state.tickets[eid].push(ticket)
    }
  },
})

const CREATE_TABLE = createAsyncHandlers('CREATE_TABLE', {
  success(state, action) {
    const { eid, table } = action.payload

    if (state.tables[eid]) {
      state.tables[eid].push(table)
    }
  },
})

const UPDATE_TICKET = createAsyncHandlers('UPDATE_TICKET', {
  success(state, action) {
    const { eid, ticket } = action.payload
    if (state.tickets[eid]) {
      state.tickets[eid] = state.tickets[eid].map(item => {
        if (item.id === eid) {
          return ticket
        }

        return item
      })
    }
  },
})

const UPDATE_TABLE = createAsyncHandlers('UPDATE_TABLE', {
  success(state, action) {
    const { eid, table } = action.payload
    if (state.tables[eid]) {
      state.tables[eid] = state.tables[eid].map(item => {
        if (item.id === eid) {
          return table
        }

        return item
      })
    }
  },
})

const GET_TIME_SLOT_TEXTS = createAsyncHandlers('GET_TIME_SLOT_TEXTS', {
  success(state, action) {
    const { data, eid } = action.payload
    state.limitTexts[eid] = data
  },
})

const UPDATE_TIME_SLOT_TEXT = createAsyncHandlers('UPDATE_TIME_SLOT_TEXT', {
  success(state, action) {
    const { form, eid } = action.payload

    const index = _findIndex(state.limitTexts[eid], el => el.id === form.id)
    if (index !== -1) {
      state.limitTexts[eid][index] = form
    }
  },
})

const GET_TIME_SLOT_GROUPS = createAsyncHandlers('GET_TIME_SLOT_GROUPS', {
  success(state, action) {
    const { data, eid } = action.payload
    state.timeSlotGroups[eid] = _map(data, item => ({
      ...item,
      slotGroupName: item.groupName,
      slotGroupId: item.id,
    }))
  },
})

export default handleActions(
  {
    ...FETCH_EVENT_TICKETS,
    ...FETCH_EVENT_TABLES,
    ...CREATE_TICKET,
    ...CREATE_TABLE,
    ...UPDATE_TICKET,
    ...UPDATE_TABLE,
    ...GET_TIME_SLOT_TEXTS,
    ...UPDATE_TIME_SLOT_TEXT,
    ...GET_TIME_SLOT_GROUPS,
  },
  initialState,
)
