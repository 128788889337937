import React from 'react'
import moment from 'moment'
import Currency from '../../../../_library/Currency'
import { TableIconLabels } from '../../TableIconLabels'
import { TIME_DISPLAY_FORMAT } from '../../../../constants/timeFormats'

const style = {
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  cursor: 'auto'
}

export default class TimeSlotsTableRow extends React.Component {
  render() {
    const {
      isInventoryMode,
      currency,
      checkInEnd,
      checkInStart,
      maxInventory,
      price,
      faceValue,
      isExisting,
      stock,
      ...rest
    } = this.props

    return (
      <tr className={'row-stale'} style={{ ...style }}>
        {isExisting && <TableIconLabels {...rest} isDesktop={true} hideDragIcon={true} />}
        <td className="tickets-table-td-desktop h4">{moment(checkInStart || rest.slotStartDate).format(TIME_DISPLAY_FORMAT)}</td>
        <td className="tickets-table-td-desktop h4">{moment(checkInEnd || rest.slotEndDate).format(TIME_DISPLAY_FORMAT)}</td>
        {isExisting && (
          <td className="tickets-table-td-desktop">
            <Currency code={currency} value={faceValue} />
          </td>
        )}
        <td className="tickets-table-td-desktop">
          <Currency code={currency} value={(Number(price) || 0) * 100} />
        </td>
        {isInventoryMode && <td className="tickets-table-td-desktop text-center"> {maxInventory}</td>}
        {isExisting && <td className="tickets-table-td-desktop text-center"> {stock}</td>}
        <td className="tickets-table-td-desktop sm">{this.props.children}</td>

        {/* for mobile browsers */}
        <td colSpan={isInventoryMode ? 7 : 6} className="tickets-table-td-mobile">
          {isExisting && <TableIconLabels {...rest} isDesktop={false} hideDragIcon={true} />}
          <div className="ticket-table-td-mobile-values">
            <div className="ticket-table-td-mobile-name">
              <div className="ticket-table-td-mobile-label">Start Time</div>
              <div className="ticket-table-td-mobile-value">
                {moment(checkInStart || rest.slotStartDate).format(TIME_DISPLAY_FORMAT)}
              </div>
            </div>
            <div className="ticket-table-td-mobile-name">
              <div className="ticket-table-td-mobile-label">End Time</div>
              <div className="ticket-table-td-mobile-value">
                {moment(checkInEnd || rest.slotEndDate).format(TIME_DISPLAY_FORMAT)}
              </div>
            </div>
            {isExisting && (
              <div className="ticket-table-td-mobile-price">
                <div className="ticket-table-td-mobile-label">Face Value</div>
                <div className="ticket-table-td-mobile-value">
                  <Currency code={currency} value={faceValue} />
                </div>
              </div>
            )}
            <div className="ticket-table-td-mobile-price">
              <div className="ticket-table-td-mobile-label">Price</div>
              <div className="ticket-table-td-mobile-value">
                <Currency code={currency} value={(Number(price) || 0) * 100} />
              </div>
            </div>
            {isExisting && (
              <div className="ticket-table-td-mobile-name">
                <div className="ticket-table-td-mobile-label">Stock</div>
                <div className="ticket-table-td-mobile-value">{stock}</div>
              </div>
            )}
          </div>
          <div className="ticket-table-td-mobile-action">{this.props.children}</div>
        </td>
      </tr>
    )
  }
}
