import _get from 'lodash/get'

export const getPageInfoData = ({ path, event, brand, tour }) => {
  const isEventPage = path.indexOf('/event') >= 0
  const isBrandPage = path.indexOf('/brand') >= 0
  const isTeamPage = path.indexOf('/team') >= 0
  const isTourPage = path.indexOf('/tour') >= 0
  const isAccountPage = path.indexOf('/account') >= 0
  const isAdsPage = path.indexOf('/ads') >= 0

  let itemId = ''
  let page = ''

  if (isEventPage && path !== '/events') {
    itemId = _get(event, 'id', '')
    page = path.replace(`/event/${itemId}/`, '')
  }

  if (isBrandPage && path !== '/brands') {
    itemId = _get(brand, 'id', '')
    page = path.replace(`/brand/${itemId}/`, '')
  }

  if (isTourPage && path !== '/tours') {
    itemId = _get(tour, 'id', '')
    page = path.replace(`/tour/${itemId}/`, '')
  }

  const isEventsPage = path === '/events'
  const isNewEventPage = path === '/events/new' || path === '/events/new-online'
  const isBrandsPage = path === '/brands'
  const isNewBrandPage = path === '/brands/new'
  const isToursPage = path === '/tours'
  const isNewTourPage = path === '/tours/new'
  const isUsersPage = path === '/users'
  const isDraftEventsPage = path === '/draft-events'

  const isHome = !(
    isEventPage ||
    isBrandPage ||
    isTeamPage ||
    isTourPage ||
    isAccountPage ||
    isAdsPage ||
    isUsersPage ||
    isDraftEventsPage
  )

  return {
    itemId,
    page,
    isHome,
    isEventPage,
    isBrandPage,
    isTeamPage,
    isTourPage,
    isAccountPage,
    isAdsPage,
    isUsersPage,
    isDraftEventsPage,
    isEventsPage,
    isNewEventPage,
    isBrandsPage,
    isNewBrandPage,
    isToursPage,
    isNewTourPage,
  }
}
