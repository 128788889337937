export const PRE_REGISTRATION_TABLE_COLUMN = {
  DATE: 0,
  QUANTITY: 1
}

export const PRE_REGISTRATION_FILTER_TYPE = {
  NAME: 'Name',
  DATE: 'Date'
}

export const DATE_TRAFFIC_SUPPORT = "2022-04-22"
