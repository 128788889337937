import React from 'react'
import { getConfig } from '../utils/configUtils'
import _get from 'lodash/get'
import store from '../../_common/redux/store'
import PermissionDenied from '../_library/PermissionDeniedComponent'
import { ROLES } from '../constants/roles'
import { getPermissions } from '../_theme/utils'

const permissionDeniedByConfig = C => props => {
  const {
    isEvent = false,
    // isBrand = false,
    // isTour = false
  } = props

  const globalStates = store.getState()
  const userRole = _get(globalStates, 'auth.user.tfRole')

  const isTtfTeam = userRole === 'ttf_team'
  const isTfSupport = ROLES.is_tf_support(userRole)

  const eventRole = _get(globalStates, 'events.selected.self.role')
  const permissions = getPermissions(eventRole)
  const isEventAdminOrOwner = permissions.isAdminOrOwner()

  // const tourRole = _get(globalStates, 'tours.selected.role')
  // const isBrandOwner = _get(globalStates, 'brands.selected.isOwner')
  // const isTTFAccount = _get(globalStates, 'brands.selected.isTTFAccount')

  const configFile = getConfig()
  const routePath = _get(props, 'match.path')

  const configs = _get(configFile, routePath)

  /* displaySection - also must be checked if user has corresponding role/permission */
  const displaySection = _get(configs, 'appearance.displaySection', true)

  /* alwaysDisplaySection - displaying page without checked if user has corresponding role/permission */
  const alwaysDisplaySection = _get(configs, 'appearance.alwaysDisplaySection', true)

  /* isVisibleForTtfTeam - display page if user has ttf_team role */
  const isVisibleForTtfTeam = _get(configs, 'appearance.isVisibleForTtfTeam', true)

  /* isVisibleForTtfSupport - display page if user has one of the 4 support roles: tf_support, tf_support_admin,
                              tf_client_support, tf_client_support_admin */
  const isVisibleForTtfSupport = _get(configs, 'appearance.isVisibleForTtfSupport', true)

  /* isVisibleForAdminOrOwner - display page if user has admin or owner role */
  const isVisibleForAdminOrOwner = _get(configs, 'appearance.isVisibleForAdminOrOwner', true)

  /* Permission denied component props */
  const permissionDeniedHeader = _get(configs, 'messages.permissionDeniedHeader')
  const permissionDeniedSubHeader = _get(configs, 'messages.permissionDeniedSubHeader')

  let isPermissionDenied = true

  if (alwaysDisplaySection) {
    isPermissionDenied = false
  } else if (isTtfTeam && isVisibleForTtfTeam) {
    isPermissionDenied = false
  } else if (isTfSupport && isVisibleForTtfSupport) {
    isPermissionDenied = false
  } else if (displaySection && isEvent && isEventAdminOrOwner && isVisibleForAdminOrOwner) {
    isPermissionDenied = false
  }

  return isPermissionDenied ? (
    <PermissionDenied header={permissionDeniedHeader} subHeader={permissionDeniedSubHeader} />
  ) : (
    <C {...props} />
  )
}

export const permissionDeniedByConfigWrapper = permissionDeniedByConfig
