/* eslint-disable max-len */
// Deprecated -- need to compare functionalities with SotrableTable.jsx
import React, { PureComponent } from 'react'
import identity from 'lodash/identity'
import isObject from 'lodash/isObject'
import clone from 'lodash/clone'
import isNumber from 'lodash/isNumber'
import isDate from 'lodash/isDate'

/*
    Props
        * thead - tableNode[]
        * tbody - tableNode[][]
        * tbody with dropDown - tableNode[]{row: tableNode[], isShowComponent: boolean, component: react element}
        * tfoot - tableNode[]
    Interfaces
        * tableNode - // tableNode can be any primitive type
                      // or Object which have this format:
                      // {sortable: bool, sortType: 'asc'|'desc'|'', normalizer: func, compareFunction: func},
                      // "sortable" - this shows the column be sortable or no (this only applies to header nodes)
                      // "compareFunction" - this shows sort algorithm (this only applies to header nodes)
                      // "sortType" - this shows the initial sort state 'asc' or 'desc' or ''
                      // "normalizer" - this function returns a modification of original data for rendering. For example, this function can be used for Date formatting.
*/

class DataTable extends PureComponent {
  constructor(props) {
    super(props)
    const sortInfo = getSortedColumnInfo(props.thead)
    const data = getStateCopy(props)
    sortTypesToNull(data.thead)
    this.originalData = getStateCopy(data)
    setSortType(data.thead, sortInfo)
    const createdTable = this.createTableData(data, sortInfo)
    this.state = {
      ...createdTable,
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.thead !== this.props.thead ||
      nextProps.tbody !== this.props.tbody ||
      nextProps.tfoot !== this.props.tfoot
    ) {
      const sortInfo = getSortedColumnInfo(this.state.thead)
      const data = getStateCopy(nextProps)
      sortTypesToNull(data.thead)
      this.originalData = getStateCopy(data)
      setSortType(data.thead, sortInfo)
      const createdTable = this.createTableData(data, sortInfo)
      this.updateState(createdTable)
    }
  }
  createTableData = ({ thead = [], tbody = [], tfoot = [] }, sortInfo) => {
    tbody = getTableSortedBody(tbody, sortInfo, this.originalData.tbody)
    return { thead, tbody, tfoot }
  }
  onSort = sortInfo => {
    const data = getStateCopy(this.state)
    sortTypesToNull(data.thead)
    setSortType(data.thead, sortInfo)
    const createdTable = this.createTableData(data, sortInfo)
    this.updateState(createdTable)
  }
  updateState = newState => {
    this.setState(() => newState)
  }
  render() {
    let { thead, tbody, tfoot } = this.state
    const { classNames: { dropClassName = '', container = '' } = {} } = this.props

    tbody =
      tbody &&
      (Array.isArray(tbody)
        ? tbody.reduce((acm, _row, i) => {
          let row = _row
          let component = null
          let isShowComponent = false
          if (!Array.isArray(row)) {
            row = _row.row
            component = (
              <tr className={dropClassName} key={`c${i}`}>
                <td colSpan={row.length}>{_row.component}</td>
              </tr>
            )
            isShowComponent = _row.isShowComponent
          }
          const rowEl = (
            <tr key={i} className={i % 2 === 0 ? 'row-stale' : ''}>
              {row.map((el, i) => (
                <td key={i}>{getNormalizedItem(el)}</td>
              ))}
            </tr>
          )
          acm.push(rowEl)
          isShowComponent && acm.push(component)
          return acm
        }, [])
        : tbody)

    return (
      <div style={{ overflowX: 'auto' }}>
        <table className={`table tickets-table ${container}`}>
          {thead && (
            <thead>
              <tr>
                {thead.map((el, i) => (
                  <th key={i}>
                    <TableHeaderItem index={i} onClick={this.onSort} item={el} />
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {tbody && Array.isArray(tbody) ? <tbody>{tbody.map((row, i) => row)}</tbody> : tbody}
          {tfoot && (
            <tfoot>
              <tr>
                {tfoot.map((el, i) => (
                  <td key={i}>{getNormalizedItem(el)}</td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    )
  }
}

export default DataTable

// helpful components
const SortIcon = ({ sortType }) =>
  sortType ? (
    <span>
      &ensp;
      <i className={`sort-direction fa fa-caret-${sortType === 'asc' ? 'up' : 'down'}`} aria-hidden="true" />
    </span>
  ) : null

const TableHeaderItem = ({ item, index, onClick }) => {
  const { sortType, sortable } = item
  const normalizedItem = getNormalizedItem(item)
  const handleSortChange = () =>
    sortable &&
    onClick({ sortType: getNextSortType(sortType), index, compareFunction: getCompareFunction(item) })
  return (
    <div aria-hidden onClick={handleSortChange} style={{ cursor: sortable ? 'pointer' : '' }}>
      {normalizedItem}
      {sortable && <SortIcon sortType={sortType} />}
    </div>
  )
}

// helpful functions
const isReadyItem = tableNode => !isObject(tableNode) || React.isValidElement(tableNode) || isDate(tableNode)
const getNormalizer = ({ normalizer = identity }) => normalizer
export const getNormalizedItem = tableNode =>
  isReadyItem(tableNode) ? tableNode : getNormalizer(tableNode)(tableNode.value)
export const getTableNodeValue = tableNode => (isReadyItem(tableNode) ? tableNode : tableNode.value)
const getCompareFunction = ({ compareFunction = defaultCompareFunction }) => compareFunction

const getStateCopy = ({ thead = [], tbody = [], tfoot = [] }) => ({
  thead: copyTableRow(thead),
  tbody: clone(tbody),
  tfoot: copyTableRow(tfoot),
})
const copyTableRow = tableRow => tableRow.map(copyTableNode)
const copyTableNode = tableNode => (isReadyItem(tableNode) ? tableNode : clone(tableNode))

const defaultCompareFunctionForNumbers = (a, b) => -b + a
const defaultCompareFunctionForDates = (a, b) => -b + a
const defaultCompareFunctionForStrings = (a, b) => `${a}`.localeCompare(`${b}`)
const defaultCompareFunction = (a, b) => {
  switch (true) {
    case isNumber(a) || isNumber(b):
      return defaultCompareFunctionForNumbers(a, b)
    case isDate(a) || isDate(b):
      return defaultCompareFunctionForDates(a, b)

    default:
      return defaultCompareFunctionForStrings(a, b)
  }
}
const sortTypesToNull = (_th = []) => _th.forEach(el => (isReadyItem(el) ? null : (el.sortType = '')))
const setSortType = (_th = [], { index, sortType }) => index !== -1 && (_th[index].sortType = sortType)
const getSortedColumnInfo = (_th = []) => {
  const index = _th.findIndex(el => el.sortType === 'asc' || el.sortType === 'desc')
  const sortInfo = { index }
  if (index !== -1) {
    sortInfo.sortType = _th[index].sortType
    sortInfo.compareFunction = getCompareFunction(_th[index])
  }
  return sortInfo
}

const getTableSortedBody = (tbody = [], { index, sortType, compareFunction }, originalTBody) => {
  if (!sortType) {
    return clone(originalTBody)
  }
  if (index !== -1) {
    tbody.sort((row_1, row_2) => {
      if (!Array.isArray(row_1)) {
        row_1 = row_1.row
      }
      if (!Array.isArray(row_2)) {
        row_2 = row_2.row
      }
      const item_1 = row_1[index]
      const item_2 = row_2[index]
      const c = (-1) ** (sortType === 'desc')
      return c * compareFunction(getTableNodeValue(item_1), getTableNodeValue(item_2))
    })
  }
  return tbody
}

const getNextSortType = sortType => ({ asc: 'desc', desc: '', '': 'asc' }[sortType])
