import _set from 'lodash/set'
import _get from 'lodash/get'
import React from 'react'
import { withFormik } from 'formik'

import Button from '../../_library/Button'
import Field from '../../_library/Field'

function validateAccess(data) {
  const errors = {}
	
  const access = _get(data, 'access')
  access.forEach((acc, index) => {
    if(!acc.firstName) {
      _set(errors, 'access['+index+'].firstName', 'Required')
    }
    if(!acc.lastName) {
      _set(errors, 'access['+index+'].lastName', 'Required')
    }
    if(!acc.email) {
      _set(errors, 'access['+index+'].email', 'Required')
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (acc.email && !re.test(acc.email)){
      _set(errors, 'access['+index+'].email', 'Invalid Email')
    }
  })
	
  return errors
}

class DelegationManageAccessFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.addNewAccess = this.addNewAccess.bind(this)
  }

  addNewAccess() {
    this.props.setFieldValue('access', [...this.props.values.access, {
      firstName: '',
      lastName: '',
      email: '',
      isNew: true
    }])
  }

  removeAccess(index) {
    const access = [...this.props.values.access]
    access.splice(index, 1)
    this.props.setFieldValue('access', access)
  }

  render() {
    const { isSubmitting, handleSubmit, onCancel, values, touched, errors, handleChange, handleBlur, skippable } = this.props

    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <Button className="btn btn-primary btn-shadow delegation-access-add-access-btn" type="button" onClick={this.addNewAccess}>Grant Permission to New Person</Button>
        { skippable && <Button className="btn btn-success btn-shadow delegation-access-skip-btn" type="button" onClick={onCancel}>Skip for now</Button> }
        <div className="table-responsive">
          <table className="table delegation-access-table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th className="delegation-access-table-remove-item" />
              </tr>
            </thead>
            <tbody>
              { values.access.map((acc, index) =>(
                <tr key={index}>
                  <td>
                    { acc.isNew && 
										<Field
										  type="text"
										  id={'access[' + index + '].firstName'}
										  value={values.access[index].firstName}
										  error={errors.access && errors.access[index] && errors.access[index].firstName}
										  touched={touched.access && touched.access[index] && touched.access[index].firstName}
										  onChange={handleChange}
										  onBlur={handleBlur}
										/> 
                    }
                    { !acc.isNew && <span>{acc.firstName}</span> }
                  </td>
                  <td>
                    { acc.isNew && 
										<Field
										  type="text"
										  id={'access[' + index + '].lastName'}
										  value={values.access[index].lastName}
										  error={errors.access && errors.access[index] && errors.access[index].lastName}
										  touched={touched.access && touched.access[index] && touched.access[index].lastName}
										  onChange={handleChange}
										  onBlur={handleBlur}
										/> 
                    }
                    { !acc.isNew && <span>{acc.lastName}</span> }
                  </td>
                  <td>
                    { acc.isNew && 
										<Field
										  type="text"
										  id={'access[' + index + '].email'}
										  value={values.access[index].email}
										  error={errors.access && errors.access[index] && errors.access[index].email}
										  touched={touched.access && touched.access[index] && touched.access[index].email}
										  onChange={handleChange}
										  onBlur={handleBlur}
										/> 
                    }
                    { !acc.isNew && <span>{acc.email}</span> }
                  </td>
                  <td className="text-right">
                    <Button className="btn btn-danger btn-shadow delegation-access-delete-access-btn" type="button" onClick={this.removeAccess.bind(this, index)}><i className="fa fa-trash" /></Button>
                  </td>
                </tr>
              )) }
              { values.access.length === 0 &&
							<tr>
							  <td colSpan={4} className="text-center">No Persons Granted</td>
							</tr>
              }
            </tbody>
          </table>
        </div>
        <div className="btn-toolbar btn-toolbar-right text-right">
          <Button className="btn btn-primary btn-shadow" type="submit" loading={isSubmitting}>
            Update Permissions
          </Button>
          <Button
            className="btn btn-danger btn-shadow"
            type="button"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </form>
    )
  }
}

const DelegationManageAccessForm = withFormik({
  mapPropsToValues: (props) => ({
    access: _get(props, 'initialValues.attributes.access') || [],
  }),
  
  // Custom sync validation
  validate: values => validateAccess(values),
  
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit({
      attributes: { ...values }
    })
      .then((v) => {
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
      })
  },
  displayName: 'DelegationManageAccessForm', // helps with React DevTools
})(DelegationManageAccessFormComponent)
  
export default DelegationManageAccessForm
