import React from 'react'

export const Steps = ({ elements = [] }) => (
  <div className={`steps-wrapper`}>
    {elements.map(({ classNameText, classNameIcon, iconChildren, text }, i) => (
      <React.Fragment key={i}>
        {i !== 0 ? <div className="line-flex" /> : null}
        <div className="step-box">
          <div className={classNameIcon}>
            {iconChildren}
          </div>
          <div className={classNameText}>{text}</div>
        </div>
      </React.Fragment>
    ))}
  </div>
)
