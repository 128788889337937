import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GET_TIME_SLOT_GROUPS } from '../../_common/redux/tickets/actions'
import { CREATE_MEMBERSHIP } from '../../_common/redux/memberships/actions'
import CreateMembershipFormWrapper from './membership/CreateMembershipForm'
import { withRouter } from 'react-router-dom'

import {
  get_event_id,
  get_event_time_slot,
} from '../../_common/core/selectors'

@withRouter
@connect(
  state => {
    const timeSlotGroups = get_event_time_slot(state)
    const eventId = get_event_id(state)
    return {
      timeSlotGroups,
      eventId,
    }
  },
  {
    CREATE_MEMBERSHIP,
    GET_TIME_SLOT_GROUPS,
  }
)
class EventMembershipCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.GET_TIME_SLOT_GROUPS(this.props.eventId)
  }

  handleSubmit = (values) => {
    const { CREATE_MEMBERSHIP } = this.props   
    
    CREATE_MEMBERSHIP(values).then(() => {
      this.goMembershipPage()
    })
  }

  goMembershipPage = () => {
    const { history: { push }, eventId } = this.props
    push(`/event/${eventId}/membership`)
  }

  render() {
    const { eventId, timeSlotGroups } = this.props
    return (
      <div className="membership-create">
        <h3 className="heading_style">Create Membership</h3>
        <CreateMembershipFormWrapper 
          timeSlotGroups={timeSlotGroups}
          onSubmit={this.handleSubmit}
          eventId={eventId}
          onCancel={this.goMembershipPage}
        />
      </div>
    )
  }
}


export default EventMembershipCreate
