import Axios from 'axios'
import _get from 'lodash/get'
import { baseRequest } from '.'
import { ENV } from '../../../web/constants/env'

export const fetchSeatMapTickets = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/seat_map/tickets/`)
  return _get(res, 'data.data.attributes.tickets')
}

export const bookSeat = async (eventId, data) =>
  await baseRequest.post(`event/${eventId}/seat_map/ticket/book/`, {
    data: {
      attributes: { ...data },
    },
  })

export const deleteBookedSeat = async (eventId, ticketId) =>
  await baseRequest.delete(`event/${eventId}/seat_map/ticket/book/`, {
    data: {
      data: {
        attributes: { ticketId },
      },
    },
  })

export const moveReservedSeat = async (eventId, ticketId) =>
  await baseRequest.post(`event/${eventId}/seat_map/ticket/move/`, {
    data: {
      attributes: {
        ticketId,
      },
    },
  })

export const getSeatMapData = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/seat_map_data`)
  return _get(res, 'data.data.attributes')
}

const consumerRequestNoCookies = Axios.create({
  baseURL: `${ENV.API_CONSUMER}/api`,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
})

export const getSeatMapStatuses = async eventId => {
  const res = await consumerRequestNoCookies.get(`event/${eventId}/seats/status`)
  return _get(res, 'data.data.attributes')
}
