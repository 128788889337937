import _ from 'lodash'
import _isEqual from 'lodash/isEqual'
import React from 'react'
import Chip from '../../_library/Chip'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import moment from 'moment'
import { DAY_FORMAT } from '../../constants/timeFormats'
import { DatePicker } from '../../_library/DatesPicker'
import { getUrl } from './utils/timeSlotGroupUtils'
import LoadingBar from '../../_library/LoadingBar'

export default class TimeSlotTicketsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTimeSlotGroup: null,
      selectedDay: null,
      ticketGroups: this.getSortedTicketGroups(props.tickets),
      timeSlots: null,
      isLoading: false,
      isNeedUpdate: false,
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    const { tickets } = this.props
    this.updateTickets(tickets)
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  componentDidUpdate(prevProps, prevState) {
    const { tickets, timeSlotTickets } = this.props
    const { selectedTimeSlotGroup, timeSlots, isLoading, selectedDay } = this.state

    if (
      (!timeSlots && selectedTimeSlotGroup && selectedDay && !isLoading)
      || (!_isEqual(prevProps.timeSlotTickets, timeSlotTickets) && selectedTimeSlotGroup && selectedDay)
    ) {
      this.fetchTimeSlots(selectedTimeSlotGroup?.slotGroupId, selectedDay, false)
    }

    if (prevProps.tickets.length !== tickets.length) {
      this.updateTickets(tickets)
    }
  }

  updateTickets = tickets => {
    this.setState(() => ({
      ticketGroups: this.getSortedTicketGroups(tickets)
    }))
  }

  fetchTimeSlots = (selectedGroupId, selectedDay, enableLoading = true) => {
    const { event } = this.props
    const { url, opts } = getUrl(event.id, selectedDay, selectedGroupId)

    if (enableLoading) {
      this.setState(() => ({
        isLoading: true
      }))
    }

    fetch(url, opts)
      .then(res => res.json())
      .then(result => {
        const timeSlots = _.map(result.data, item => ({
          $original: item,
          ...item,
          ...item.attributes
        }))
        this.setState(() => ({
          isLoading: false,
          isNeedUpdate: false,
          timeSlots
        }))
      })
      .catch(error => {
        this.setState(() => ({
          isLoading: false,
          isNeedUpdate: false
        }))
      })
  }

  getSortedTicketGroups = () => {
    const { tickets } = this.props
    return _.map(tickets, item => ({
      ...item,
      dates: _.sortBy(item.dates, date => moment(date, DAY_FORMAT))
    }))
  }

  setDefaultSelectedGroup = () => {
    const { ticketGroups } = this.state
    const defaultSelectedGroup = ticketGroups[0]
    const days = _.sortBy(defaultSelectedGroup.dates, date =>
      moment(date, DAY_FORMAT).format(DAY_FORMAT)
    )
    this.setState({
      selectedTimeSlotGroup: defaultSelectedGroup
    })
  }

  handleGroupChipSelect = chip => {
    const ticketGroups = this.getSortedTicketGroups()
    const selectedGroup = _.find(ticketGroups, item => item.slotGroupName === chip)
    const days = selectedGroup ? selectedGroup.dates : []
    setTimeout(() => {
      this.setState({ isNeedUpdate: false })
    }, 600)
    this.setState(prevState => {
      const selectedDay =
        prevState.selectedTimeSlotGroup && prevState.selectedTimeSlotGroup.slotGroupName === chip
          ? prevState.selectedDay
          : days[0]

      return {
        ...prevState,
        selectedTimeSlotGroup: selectedGroup,
        selectedDay: null,
        isNeedUpdate: this.compareYearAndMonth(selectedDay, prevState.selectedDay),
        timeSlots: null
      }
    })
  }

  getSelectedDatesForCalendar = days => {
    const startDate = moment(days[0], DAY_FORMAT)
    const endDate = moment(days[days.length - 1], DAY_FORMAT)
    return _.map(days, item => ({
      startDate: moment(item, DAY_FORMAT),
      endDate: moment(item, DAY_FORMAT)
    }))
  }

  getStartEndDates = days => {
    const startDate = moment(days[0], DAY_FORMAT)
    const endDate = moment(days[days.length - 1], DAY_FORMAT)
    return { startDate, endDate }
  }

  onDatesChange = date => {
    const { selectedTimeSlotGroup } = this.state
    const selectedDay = moment(date.startDate).format(DAY_FORMAT)
    this.setState(() => ({ selectedDay }))
    this.fetchTimeSlots(selectedTimeSlotGroup.slotGroupId, selectedDay)
  }

  compareYearAndMonth = (date1, date2) => {
    if (!date2) {
      return true
    } else if (moment(date1, DAY_FORMAT).year() !== moment(date2, DAY_FORMAT).year()) {
      return true
    } else if (moment(date1, DAY_FORMAT).month() !== moment(date2, DAY_FORMAT).month()) {
      return true
    } return false
  }

  render() {
    const {
      event,
      tickets,
      statuses,
      handleEdit,
      handleActivate,
      handleDeactivate,
      editModalComponent
    } = this.props
    const {
      selectedDay,
      selectedTimeSlotGroup,
      ticketGroups,
      isLoading,
      timeSlots,
      isNeedUpdate,
      width,
    } = this.state
    const currency = event.currency ? event.currency.currency || 'USD' : 'USD'
    const tableColumns = getTableColumns(this.props.tableColumns, currency)
    const selectedGroup = _.find(
      ticketGroups,
      item => selectedTimeSlotGroup && item.slotGroupName === selectedTimeSlotGroup.slotGroupName
    )
    const days = selectedGroup ? selectedGroup.dates : ticketGroups[0].dates
    const isMobile = width < 700

    return (
      <div>
        <div className="row">
          <div className="div-spacing-20" />
          <div className="col-sm-12 tags-field">
            <div className="react-tagsinput pdtb-5">
              {_.map(ticketGroups, g => (
                <Chip
                  key={g.slotGroupId}
                  value={g.slotGroupName}
                  isSelected={
                    selectedTimeSlotGroup && selectedTimeSlotGroup.slotGroupName === g.slotGroupName
                  }
                  onSelectChip={this.handleGroupChipSelect}
                  disableClear={true}
                />
              ))}
            </div>
          </div>
          {!isNeedUpdate && selectedTimeSlotGroup && <div className="div-spacing-20" />}
          <div className="col-sm-12">
            {!isNeedUpdate && selectedTimeSlotGroup ? (
              <DatePicker
                selectedDates={[{ startDate: moment(selectedDay, DAY_FORMAT) }]}
                availableDays={this.getSelectedDatesForCalendar(days)}
                buttonLabel={'Select Day'}
                onDatesChange={this.onDatesChange}
                onApplyDates={this.onDatesChange}
                isRangesDisabled={true}
                startDate={this.getStartEndDates(days).startDate}
                endDate={this.getStartEndDates(days).endDate}
                numberOfMonths={isMobile ? 1 : 2}
                className={'time-slot-date-picker'}
                isAlwaysOpen={true}
              />
            ) : null}
          </div>
          {!isNeedUpdate && selectedTimeSlotGroup && <div className="div-spacing-20" />}
          <div className="col-sm-12">
            {!isLoading && !isNeedUpdate ? (
              timeSlots ? (
                <SortableTable
                  data={timeSlots}
                  tableColumns={tableColumns}
                  statuses={statuses}
                  enableSort={false}
                  enableDnd={false}
                  enableDefaultActions={true}
                  isTimeSlotTickets={true}
                  handleEdit={handleEdit}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                  editModalComponent={editModalComponent}
                />
              ) : null
            ) : (
              <LoadingBar />
            )}
          </div>
        </div>
        {!isLoading && !isNeedUpdate && <div className="div-spacing-20" />}
      </div>
    )
  }
}
