import _ from 'lodash'
import React from 'react'
import { Field, Form, Formik } from 'formik'
import Button from '../../_library/Button'
import { SimpleField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'
import { UrlField } from '../../formik/Fields/UrlField'

const EventOrderMediaForm = ({ onCancel, submitLabel, initialValues, onSubmit }) => (
  <Formik
    initialValues={{
      name: _.get(initialValues, 'name') || '',
      url: _.get(initialValues, 'url') || '',
    }}
    onSubmit={(values, { setSubmitting }) => {
      onSubmit({ ...values })
        .then(() => {
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
        })
    }}
  >
    {({ isSubmitting, isValid }) => (
      <Form>
        <div className="row">
          <div className="col-xs-12">
            <Field
              name="name"
              label="Social Media Name"
              placeholder=""
              component={SimpleField}
              validate={requiredValidator()}
              touchedOnBlur
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              name="url"
              label="URL"
              placeholder=""
              component={UrlField}
              validate={requiredValidator()}
              classname="url-input"
            />
          </div>
        </div>
        <div className="btn-toolbar btn-toolbar-right">
          {onCancel && <Button className="btn btn-danger" type="button" disabled={isSubmitting} onClick={onCancel}>Cancel</Button>}
          <Button type="submit" className="btn btn-success" loading={isSubmitting} disabled={!isValid}>{submitLabel}</Button>
        </div>
      </Form>
    )}
  </Formik>
)

export default EventOrderMediaForm
