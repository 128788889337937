import React from 'react'
import moment from 'moment'
import DateTimePickerReact from '../../../_library/DateTimePickerReact'
import { OverlayTrigger } from '../../../_library/OverlayTrigger'

const defaultValues = {
  startTime: 21, // 24 hour format
  minutes: 0,
  duration: 4 // hours
}

const EventDatesRow = props => {
  const { values, errors, touched, isRnDomain, updateField, isFundsRemmited } = props

  const handleChangeStartDate = (val, isSelectedDay) => {
    if (isRnDomain && val && !values.draftEventId) {
      if(isSelectedDay && !values.startDate) {
        val = moment.utc(val).set({ 'hour': defaultValues.startTime, 'minute': defaultValues.minutes }).toISOString()
      }
      const endDate = moment.utc(val).add(defaultValues.duration, 'hours').format()
      updateField('endDate', endDate)
      const now = new Date()
      updateField('salesStartDate', moment.utc().hours(now.getHours()).minutes(now.getMinutes()).format())
      updateField('salesEndDate', endDate)
    } else if (!values.endDate) {
      const endDate = moment.utc(val).format()
      updateField('endDate', endDate)
    }
    updateField('startDate', val)
  }

  const handleChangeEndDate = val => {
    updateField('endDate', val)
  }
  return (
    <div className="row" style={{ marginTop: 8, marginBottom: 16 }}>
      <OverlayTrigger
        id = 'tooltipOFStartDate'
        placement='bottom'
        tooltip='You are unable to change the date for this event as funds 
                have been released. Please contact clientsupport@ticketfairy.com
                if you require changes'
        hideTooltip = {!isFundsRemmited}
      >
        <div className={'col-xs-6 col-12'} style={{ position: 'static' }}>
          <DateTimePickerReact
            id="startDate"
            label="Event Start Date"
            placeholder="D MMM YYYY H:M AM"
            error={errors.startDate}
            touched={touched.startDate}
            value={values.startDate || ''}
            onChange={handleChangeStartDate}
            suggestion={values.startDate || ''}
            disabled={isFundsRemmited}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        id = 'tooltipOFEndDate'
        placement='bottom'
        tooltip='You are unable to change the date for this event as funds 
                have been released. Please contact clientsupport@ticketfairy.com
                if you require changes'
        hideTooltip = {!isFundsRemmited}
      >
        <div className={'col-xs-6 col-12'} style={{ position: 'static' }}>
          <DateTimePickerReact
            id="endDate"
            label="Event End Date"
            placeholder="D MMM YYYY H:M AM"
            error={errors.endDate}
            touched={touched.endDate}
            value={values.endDate || ''}
            suggestion={values.draftEventId ? '' : values.startDate}
            onChange={handleChangeEndDate}
            disabled={isFundsRemmited}
          />
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default EventDatesRow
