import React from 'react'
import _pull from 'lodash/pull'
import _identity from 'lodash/identity'

export const MultiCheckboxField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  options = [], // example [{value: 'banana', label: 'Banana'}, {label: 'Other', value: 'other', component: (<input />)}]
  normalizer = _identity,
  classNames = {
    container: '',
    container_item: '',
    component: '',
  },
  ...props
}) => {
  const setValue = value => {
    setFieldValue(field.name, normalizer(value))
  }

  return <MultiCheckbox value={field.value} options={options} classNames={classNames} onChange={setValue} />
}

export const MultiCheckbox = ({
  value,
  options = [], // example: [{ value: 'banana', label: 'Banana' }, { label: 'Other', value: 'other', component: (<input />) }]
  classNames = {
    container: '',
    container_item: '',
    component: '',
  },
  onChange: pOnChange,
}) => {
  const onChange = ({ target: { name, checked } }) => {
    const selectedValues = value || []
    pOnChange(checked ? [...selectedValues, name] : _pull(selectedValues, name))
  }

  const checkboxEls = options.map(option => {
    const checked = !!value && value.includes(option.value)
    return (
      <div className={classNames.container_item} key={option.value}>
        <input
          type="checkbox"
          name={option.value}
          id={`mc_${option.value}`}
          checked={checked}
          onChange={onChange}
          disabled={option.disabled}
        />
        <label htmlFor={`mc_${option.value}`}>&ensp;{option.label}</label>
        {checked && !!option.component && <div className={classNames.component}>{option.component}</div>}
      </div>
    )
  })
  return <div className={classNames.container}>{checkboxEls}</div>
}
