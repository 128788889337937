import React from 'react'
import moment from 'moment'
import _map from 'lodash/map'
import _has from 'lodash/has'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _startCase from 'lodash/startCase'
import _reduce from 'lodash/reduce'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns, makeRunningTotalLabel } from '../../../utils/sortableTableUtils'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
} from '../../../../_common/core/validation/normalizers'

export default class SalesByOrderDate extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'orderDate',
        label: 'Order Date',
        normalizer: formatDay,
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
      },
      {
        key: 'total_num_sales',
        label: makeRunningTotalLabel('No. of Sales').value,
        labelComponent: makeRunningTotalLabel('No. of Sales').component,
        isSortable: false,
      },
      {
        key: 'revenue',
        label: 'Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
      {
        key: 'total_revenue',
        label: makeRunningTotalLabel('Revenue').value,
        labelComponent: makeRunningTotalLabel('Revenue').component,
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'grossRevenue',
        label: 'Gross Revenue',
        isSortable: true,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
      {
        key: 'grossRevenueTotal',
        label: makeRunningTotalLabel('Gross Revenue').value,
        labelComponent: makeRunningTotalLabel('Gross Revenue').component,
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ])

    this.detailsTableColumns = getTableColumns(
      [
        {
          key: 'name',
          label: 'Name',
          className: 'detail-cell',
        },
        {
          key: 'num_sales',
          label: 'No. of Sales',
          className: 'detail-cell',
        },
        {
          key: 'cost',
          label: 'Price (excl. Fees)',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenue',
          label: 'Revenue',
          className: 'detail-cell',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          className: 'detail-cell',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      ],
      getCurrencySymbol(props.event),
    )

    this.footbarColumns = [
      {
        key: 'total_num_sales',
        sourceKey: 'num_sales',
      },
      {
        key: 'total_revenue',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'grossRevenueTotal',
        sourceKey: 'grossRevenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    this.state = {
      sortBy: { column: 'orderDate', asc: true },
      rows: null,
      detailsRows: [],
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, tickets, showGroups } = this.props
    if (
      !_isEqual(data, prevProps.data) ||
      !_isEqual(tickets, prevProps.tickets) ||
      !_isEqual(showGroups, prevProps.showGroups)
    ) {
      this.updateData()
    }
  }

  updateData = () => {
    const { data, tickets, showGroups } = this.props
    const dataGroupedByDate = _groupBy(data, 'orderDate')
    const dataGroupedByGroupName = _groupBy(data, 'group')

    const groupedRows = {}
    const tempRows = []
    const detailsRows = []

    _map(dataGroupedByGroupName, (groupItem, groupKey) => {
      const groupedTempRows = []
      const groupedDetailsRows = []
      this.getRows(groupItem, _groupBy(groupItem, 'orderDate'), groupedTempRows, groupedDetailsRows)
      groupedRows[groupKey] = { data: groupedTempRows, detailsRows: groupedDetailsRows }
    })

    this.getRows(data, dataGroupedByDate, tempRows, detailsRows)

    this.setState({
      rows: _filter(tempRows, rowItem => !!rowItem.id),
      detailsRows,
      groupedRows,
    })
  }

  getRows = (data, groupedData, tempRows, detailsRows) => {
    _map(groupedData, (value, key) => {
      const num_sales = _reduce(value, (sum, n) => sum + parseInt(n.num_sales), 0)
      const revenue = _reduce(
        value,
        (sum, n) => sum + parseInt(n.num_sales) * parseFloat(n.cost) - parseFloat(n.refunded_amount),
        0,
      )
      const grossRevenue = _reduce(
        value,
        (sum, n) => sum + parseInt(n.num_sales) * parseFloat(n.price) - parseFloat(n.refunded_amount),
        0,
      )
      this.getDetailRows(data, value[0].orderDate, detailsRows)

      const updatedObj = {
        id: key,
        orderDate: key,
        cost: value[0].cost,
        num_sales,
        revenue,
        grossRevenue,
      }
      tempRows.push(updatedObj)
    })
  }

  getDetailRows = (data, date, detailsRows) => {
    const detailRows = []

    _map(_sortBy(data, 'name'), item => {
      if (moment(item.orderDate).isSame(date)) {
        detailRows.push({
          id: item.name + item.cost,
          orderDate: item.orderDate,
          num_sales: item.num_sales,
          cost: item.cost,
          revenue: parseInt(item.num_sales) * parseFloat(item.cost) - parseFloat(item.refunded_amount),
          grossRevenue: parseInt(item.num_sales) * parseFloat(item.price) - parseFloat(item.refunded_amount),
          name: item.name,
        })
      }
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].orderDate || detailRows[0].id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        ),
      })
  }

  render() {
    const { showGroups } = this.props
    const { rows, sortBy, detailsRows, groupedRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          showGroups ? (
            <div>
              {_map(groupedRows, (value, key) => (
                <div key={key}>
                  <h4 className="inline group-title">{_startCase(key)}</h4>
                  <SortableTable
                    data={value.data}
                    tableColumns={this.tableColumns}
                    enableSort={true}
                    enableCopyTable={true}
                    disableMobileView={true}
                    sortBy={sortBy}
                    detailsRows={value.detailsRows}
                    calculatedColumns={[
                      { for: 'total_num_sales', column: 'num_sales' },
                      { for: 'total_revenue', column: 'revenue' },
                      { for: 'grossRevenueTotal', column: 'grossRevenue' },
                    ]}
                    footbar={{
                      label: 'Total',
                      columns: this.footbarColumns,
                    }}
                  />
                  <div className="div-spacing-30" />
                </div>
              ))}
            </div>
          ) : (
            <SortableTable
              data={rows}
              tableColumns={this.tableColumns}
              enableSort={true}
              enableCopyTable={true}
              disableMobileView={true}
              sortBy={sortBy}
              detailsRows={detailsRows}
              calculatedColumns={[
                { for: 'total_num_sales', column: 'num_sales' },
                { for: 'total_revenue', column: 'revenue' },
                { for: 'grossRevenueTotal', column: 'grossRevenue' },
              ]}
              footbar={{
                label: 'Total',
                columns: this.footbarColumns,
              }}
            />
          )
        ) : null}
      </div>
    )
  }
}
