import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_PASSWORDS } = createAsyncAction('FETCH_PASSWORDS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/passwords`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        const out = { eid, passwords: res }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_PASSWORD } = createAsyncAction('CREATE_PASSWORD', function (eid, password) {
  const body = {
    data: {
      attributes: {
        password,
      },
      type: 'passwords',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/event/${eid}/passwords`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { DELETE_PASSWORD } = createAsyncAction('DELETE_PASSWORD', function (eid, password) {
  const body = {
    data: {
      attributes: {
        password,
      },
      type: 'passwords',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/event/${eid}/passwords`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export { FETCH_PASSWORDS, CREATE_PASSWORD, DELETE_PASSWORD }
