import React, { useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import _isEqual from 'lodash/isEqual'
import ConfirmModal from '../../../_library/ConfirmModal'
import SortableTable from '../../../_library/SortableTable'
import AddOnVariantForm from '../AddOnVariantForm'
import { getTableColumns } from '../../../../web/utils/sortableTableUtils.js'
import { addOnStockNormalizer } from '../normalizers/addOnStockNormalizer'

const AddOnVariantCreateModal = ({ isOpen, stockLabel, handleAction = () => {} }) => {
  const tableOrdersColumns = getTableColumns([
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: stockLabel,
      key: 'stock',
      normalizer: addOnStockNormalizer,
    },
  ])

  const [variants, setVariants] = useState([])

  const resetVariants = () => {
    setVariants([])
  }

  const onAction = response => {
    resetVariants()
    handleAction(response, variants)
  }

  const onAddVariant = values => {
    const { name, stock } = values
    setVariants(prevVariants => [...prevVariants, { name, stock }])
  }

  const onDeleteVariant = data => {
    const updatedVariantsData = _filter(variants, dataItem => !_isEqual(data, dataItem))
    setVariants(updatedVariantsData)
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      header="Add Variant"
      content={
        <div className="modal-variants">
          <AddOnVariantForm stockLabel={stockLabel} handleSubmitForm={onAddVariant} />
          {!_isEmpty(variants) && (
            <SortableTable
              data={variants}
              tableColumns={tableOrdersColumns}
              enableCopyTable={true}
              enableSort={false}
              disableInnerSort={true}
              actions={[
                {
                  label: 'Delete',
                  className: 'btn btn-danger',
                  icon: 'fa fa-trash',
                  onClick: onDeleteVariant,
                },
              ]}
            />
          )}
        </div>
      }
      actions={[
        {
          label: 'Apply',
          className: 'btn btn-success btn-shadow',
          value: 'save',
          disabled: _isEmpty(variants),
        },
        { label: 'Cancel', className: 'btn btn-default', value: 'cancel' },
      ]}
      onAction={onAction}
    />
  )
}

export default AddOnVariantCreateModal
