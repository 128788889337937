import React from 'react'
import _find from 'lodash/find'
import _filter from 'lodash/filter'

import Card from '../../../../_library/Card'
import Button from '../../../../_library/Button'
import Select from '../../../../_library/Select'
import RichTextArea from '../../../../_library/RichTextArea'
import { ENV } from '../../../../constants/env'

const defaultLanguage = 'en'

export default class OnlineEventDescription extends React.Component {
  handleChangeDescription = (val, resetedDescriptions) => {
    const {
      values,
      updateField,
      updateResetedDescriptions,
      descriptionLanguage,
    } = this.props

    const updatedDescriptions = {
      ...values.descriptions,
      [descriptionLanguage]: val
    }
    const descriptions = resetedDescriptions || this.props.resetedDescriptions
    const updatedResetedDescriptions = !!val
      ? _filter(descriptions, r => r !== descriptionLanguage)
      : descriptions

    updateField('descriptions', updatedDescriptions)
    if (updateResetedDescriptions) {
      updateResetedDescriptions(updatedResetedDescriptions)
    }
  }

  resetDescription = () => {
    const { resetedDescriptions, descriptionLanguage } = this.props
    const isReseted = !!_find(resetedDescriptions, d => d === descriptionLanguage)
    if (!isReseted) {
      const updatedResetedDescriptions = resetedDescriptions
      updatedResetedDescriptions.push(descriptionLanguage)
      this.handleChangeDescription('', updatedResetedDescriptions)
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      cardsStatus,
      descriptionLanguage,
      languageOptions,
      onLanguageChange,
      closed
    } = this.props
    const showClearButton = descriptionLanguage !== defaultLanguage
    const fieldId = `description-${descriptionLanguage}`
    const value = values.descriptions ? values.descriptions[descriptionLanguage] : ''

    return (
      <div className="event-details-description">
        <Card
          closed={closed}
          icon={'fa-pencil-square'}
          title={'Event Description'}
          status={cardsStatus && cardsStatus.description}
          rightAction={
            <Select
              fieldKey="descriptionLanguage"
              style={{
                width: 'auto',
                margin: '0px 15px 0px 0px',
                height: 30,
                paddingRight: 30
              }}
              fieldGroupStyle={{ marginTop: 5 }}
              value={descriptionLanguage}
              onChange={onLanguageChange}
              onClick={e => e.stopPropagation()}
              options={languageOptions}
            />
          }
        >
          <div className="row">
            <div className="col-xs-12">
              <RichTextArea
                id={fieldId}
                label=""
                baseurl={ENV.CDN_URL}
                value={value}
                onChange={this.handleChangeDescription}
                editorStyle={
                  errors.descriptions && touched.descriptions
                    ? { border: '2px solid #d9534f' }
                    : null
                }
              />
            </div>
          </div>
          {showClearButton && (
            <div className="row">
              <div className="col-xs-12" style={{ textAlign: 'center' }}>
                <Button
                  className="btn btn-default btn-shadow"
                  type="button"
                  onClick={this.resetDescription}
                >
                  Clear and use default description
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    )
  }
}
