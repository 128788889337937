import _ from 'lodash'
import React from 'react'
import { withFormik } from 'formik'
import Field from '../../_library/Field'
import Select from '../../_library/Select'
import Button from '../../_library/Button'
import TagsField2 from '../../_library/TagsField2'
import SelectTimeSlot from '../_library/SelectTicket'

function validateOrderTicket(data) {
  const required = []
  const errors = {}

  required.forEach(f => {
    if (!_.get(data, f)) {
      _.set(errors, f, 'Required')
    }
  })

  const email = _.get(data, 'email')

  if (email && !global.validateEmail(email)) {
    _.set(errors, 'email', 'Invalid')
  }

  return errors
}

class MyForm extends React.Component {
  handleChangeTags(tags) {
    this.props.setFieldValue('tags', tags)
  }

  render() {
    const { onCancel, submitLabel } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      event,
      tickets,
      loadingTickets
    } = this.props
    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(tickets)

    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <Field
              label="Email"
              type="email"
              id="email"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              id="firstName"
              label="First Name"
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              id="lastName"
              label="Last Name"
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        {event?.attendeeTags ? <div className="row">
          <div className="col-xs-12">
            <TagsField2
              label="Tags"
              name="tags"
              value={values.tags}
              tags={event.attendeeTags}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={this.handleChangeTags.bind(this)}
            />
          </div>
        </div> : null}
        <div className="row">
          <div className="col-xs-12">
            <Select
              id="status"
              label="Status"
              name="status"
              value={values.status}
              error={errors.status}
              touched={touched.status}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: 'valid', label: 'Valid' },
                { value: 'cancelled', label: 'Cancelled' },
                { value: 'unpaid', label: 'Unpaid', disabled: true },
                { value: 'for sale', label: 'For Sale', disabled: true },
                { value: 'sale in progress', label: 'Sale In Progress', disabled: true },
                { value: 'sold', label: 'Sold', disabled: true },
                { value: 'checked in', label: 'Checked In', disabled: true },
                { value: 'refunded', label: 'Refunded', disabled: true },
                { value: 'mailed', label: 'Mailed', disabled: true },
                { value: 'replaced', label: 'Replaced', disabled: true },
                { value: 'on-site', label: 'On-Site', disabled: true }
              ]}
            />
          </div>
        </div>

        {loadingTickets !== undefined && loadingTickets ? 
          <i className="fa fa-spin fa-spinner" /> 
        : <div>
          <SelectTimeSlot
            labels={['Ticket Type', 'Date', 'Time']}
            defaultValue={values.ticketTypeId}
            groupedTickets={groupedTickets}
            noGroupedTickets={noGroupedTickets}
            onChange={e => setFieldValue('ticketTypeId', e.value)}
          />
        </div>}

        <div className="text-center btn-toolbar">
          <Button type="submit" className="btn btn-primary btn-lg btn-shadow" loading={isSubmitting}>
            {submitLabel}
          </Button>
          {onCancel && (
            <Button
              className="btn btn-danger btn-lg btn-shadow"
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    )
  }
}

const TicketForm = withFormik({
  mapPropsToValues: props => ({
    email: _.get(props, 'initialValues.email') || '',
    firstName: _.get(props, 'initialValues.firstName') || '',
    lastName: _.get(props, 'initialValues.lastName') || '',
    tags: _.get(props, 'initialValues.tags') || [],
    status: _.get(props, 'initialValues.status') || 'valid',
    ticketTypeId: _.get(props, 'initialValues.ticketTypeID') || ''
  }),

  // Custom sync validation
  validate: values => validateOrderTicket(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({ ...values })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'TicketForm' // helps with React DevTools
})(MyForm)
export default TicketForm
