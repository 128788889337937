import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import { get_event_id } from '.'

export const get_all_tickets = state => _get(state, 'tickets.tickets')
export const get_all_tables = state => _get(state, 'tickets.tables')

export const get_event_tickets = createSelector(get_all_tickets, get_event_id, (all_tickets, eid) =>
  _get(all_tickets, eid, []),
)

export const get_event_tables = createSelector(get_all_tables, get_event_id, (all_tables, eid) =>
  _get(all_tables, eid, []),
)

export const get_all_time_slots = state => _get(state, 'tickets.timeSlotGroups')

export const get_event_time_slot = createSelector(get_all_time_slots, get_event_id, (all_tickets, eid) =>
  _get(all_tickets, eid, []),
)

export const get_all_limit_texts = state => _get(state, 'tickets.limitTexts')

export const get_event_limit_texts = createSelector(
  get_all_limit_texts,
  get_event_id,
  (all_limit_texts, eid) => _get(all_limit_texts, eid, []),
)

export const get_tracking_data = state => _get(state, 'ticketSalesTracking.trackingData')
