import React, { useEffect, useState } from 'react'
import _get from 'lodash/get'
import { connect } from 'react-redux'

import Header from './_theme/Header'
import Footer from './_theme/Footer'
import Sidebar from './_theme/Sidebar'
import DataManager from './_theme/DataManager'

import launchDarklyHOC from './hoc/launchDarklyHOC'

const ChildrenWithLaunchDarkly = launchDarklyHOC()

import verificationHOC from './hoc/verificationHOC'
import { getConfig } from './utils/configUtils'

const ChildrenWithVerificationPasseed = verificationHOC(ChildrenWithLaunchDarkly)

const Root = React.memo(props => {
  const { options, children = null, isLogged, isRnDomain, isMcDomain } = props
  const routePath = _get(props, 'children.props.match.path')
  const hideSidebar = _get(options, 'hideSidebar', false) || !isLogged
  const hideFooter = _get(options, 'hideFooter', false) || !isLogged
  const hideHeader = _get(options, 'hideHeader', false) || !isLogged
  const enableHomeButton = _get(options, 'enableHomeButton', false) || isLogged
  const enableIncDataFetch = _get(options, 'enableIncDataFetch', true)
  const noAppcues = _get(options, 'noAppcues', false)
  const [configFile, setConfigFile] = useState({})

  useEffect(() => {
    const favicon = document.getElementById('favicon')
    favicon.href = _get(configFile, '*.appearance.icons.favicon', '/resources/images/favicon.ico')
  }, [configFile])

  useEffect(() => {
    const configFile = getConfig()
    setConfigFile(configFile)
    const configFontFamily = _get(configFile, 'global.appearance.fontFamily', '')

    document.documentElement.style.setProperty('--baseFont', configFontFamily)
  }, [])

  return (
    <div id="container">
      <DataManager enableIncrementalDataFetch={enableIncDataFetch} noAppcues={noAppcues} />

      {!hideHeader && (
        <Header
          hideMenuButton={hideSidebar}
          enableHomeButton={enableHomeButton}
          isRnDomain={isRnDomain}
          isMcDomain={isMcDomain}
          configFile={configFile}
          routePath={routePath}
        />
      )}

      {!hideSidebar && <Sidebar configFile={configFile} routePath={routePath} />}

      <ChildrenWithVerificationPasseed component={children} />

      {!hideFooter && <Footer />}
    </div>
  )
})

const mapStateToProps = state => ({
  isLogged: state.auth.initialized,
  isRnDomain: state.shared.isRnDomain,
  isMcDomain: state.shared.isMcDomain
})

export default connect(mapStateToProps)(Root)
