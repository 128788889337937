import _map from 'lodash/map'
import _get from 'lodash/get'
import React from 'react'
import { Field } from 'formik'
import {
  // validators
  combineValidators,
  minNumberValidator,
  maxNumberValidator,
  requiredValidator,
  // normalizers
  combineNormalizers,
  createFixedFloatNormalizer,
  createUpperCaseNormalizer,
  toNumber,
  trimNormalizer,
} from '../../../../_common/core/validation'
import { SimpleField, SelectField, ReactSelectField, CustomToggleField } from '../../../formik/Fields'
import { OverlayTrigger } from '../../../_library/OverlayTrigger'

const range10 = _map(new Array(10), (item, index) => ({ label: index + 1, value: index + 1 }))
const displayNameReg = new RegExp(/([^()\[\]\s-]+)/g)
const displayNameOptions = ['ga', 'rsvp', 'vip', 'bf', 'nye', 'aaa', 'byob', 'byo', 'rv', 'abc']

const DataRow = ({
  values,
  isEditing,
  isInventoryMode,
  allowPriceEditing,
  isSlotTicket,
  ticketGroupOptions,
  flagTicketHasTimeSlots,
  updateField,
  tableType,
  configs,
}) => {
  const stockLabel = _get(configs, 'messages.stockLabel')
  const showWaitingListMaxQuantity = _get(configs, 'appearance.showWaitingListMaxQuantity', true)

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-6 first-field-ticket-form">
          <Field
            name={isSlotTicket ? 'slotGroupName' : 'displayName'}
            label={isSlotTicket ? 'Ticket Group Name' : 'Name'}
            component={SimpleField}
            validate={requiredValidator()}
            onBlurNormalizer={combineNormalizers(
              trimNormalizer,
              createUpperCaseNormalizer(displayNameReg, displayNameOptions),
            )}
          />
        </div>
        <div className="col-xs-12 col-sm-6 first-field-ticket-form">
          <Field // need to dicuss --- values.faceValue || values.price
            name="faceValue"
            type="number"
            label="Price"
            disabled={!!isEditing && !allowPriceEditing && !isSlotTicket}
            component={SimpleField}
            validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
            onBlurNormalizer={combineNormalizers(toNumber, createFixedFloatNormalizer(2))}
          />
        </div>
        <div className="col-xs-12 col-sm-6 top-field-ticket-form">
          <Field
            name={isInventoryMode ? 'maxInventory' : 'stock'}
            type="number"
            min={10}
            label={isInventoryMode ? stockLabel : 'Stock'}
            component={SimpleField}
            validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
            onBlurNormalizer={toNumber}
          />
        </div>
        <div className="col-xs-12 col-sm-6 top-field-ticket-form">
          <Field
            name="quantityIncrement"
            label="Must be bought in multiples of"
            options={range10}
            component={SelectField}
          />
        </div>
        {tableType && (
          <>
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="minGuests"
                type="number"
                min={0}
                max={10}
                label="Minimum Guests Per Reservation"
                component={SimpleField}
                validate={combineValidators(
                  requiredValidator(),
                  minNumberValidator(undefined, 0),
                  maxNumberValidator(values.maxGuests ? undefined : '', +values.maxGuests),
                )}
                onBlurNormalizer={toNumber}
              />
            </div>
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="maxGuests"
                type="number"
                min={0}
                label="Maximum Guests Per Reservation"
                component={SimpleField}
                validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
                onBlurNormalizer={toNumber}
              />
            </div>
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="guestPrice"
                type="number"
                min={0}
                label="Price Per Additional Guest After Minimum"
                component={SimpleField}
                validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
                onBlurNormalizer={toNumber}
              />
            </div>
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="depositPercent"
                type="number"
                min={0}
                label={
                  <label>
                    {'Online Deposit Percentage '}
                    <OverlayTrigger
                      className="inline-block"
                      tooltip="This will be the percentage paid to secure the reservation.
                          You will need to collect the remaining amount directly."
                      placement="top"
                      id="parcentageFieldTooltip"
                      trigger={['click']}
                    >
                      <i className="fa fa-info-circle" />
                    </OverlayTrigger>
                  </label>
                }
                component={SimpleField}
                validate={combineValidators(requiredValidator(), minNumberValidator(undefined, 0))}
                onBlurNormalizer={toNumber}
                touchedOnBlur
              />
            </div>
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="maxQuantity"
                type="number"
                min={0}
                label="Maximum ticket quantity per order"
                component={SimpleField}
                validate={minNumberValidator(undefined, 0)}
                onBlurNormalizer={toNumber}
              />
            </div>
          </>
        )}
        {!tableType && (
          <>
            <div className="col-xs-12 col-sm-6 ticket-toggle" style={{ marginBottom: 20 }}>
              <Field
                name="displayMaxQuantity"
                offText="Set custom maximum quantity per order"
                component={props => <CustomToggleField {...props} direction="right" />}
                onChange={() => {
                  updateField('maxQuantity', '')
                }}
              />
            </div>
            {values.displayMaxQuantity && (
              <div className="col-xs-12 col-sm-6 top-field-ticket-form">
                <Field
                  name="maxQuantity"
                  type="number"
                  min={0}
                  label="Maximum ticket quantity per order"
                  component={SimpleField}
                  validate={minNumberValidator(undefined, 0)}
                  onBlurNormalizer={toNumber}
                />
              </div>
            )}
          </>
        )}
        {flagTicketHasTimeSlots && (
          <div className="col-xs-12">
            <Field
              name="nestedGroup"
              label="Relative Ticket Types"
              multi={true}
              clearable={true}
              options={ticketGroupOptions}
              component={ReactSelectField}
            />
          </div>
        )}
        {flagTicketHasTimeSlots && <div className="div-spacing-15" />}
      </div>
      {!tableType && showWaitingListMaxQuantity && (
        <div className="row">
          <div className={`col-xs-12 col-sm-6 ticket-toggle`} style={{ marginBottom: 20 }}>
            <Field
              name="displayWaitingListMaxQuantity"
              offText="Set custom maximum quantity for waiting list"
              component={props => <CustomToggleField {...props} direction="right" />}
              onChange={() => {
                updateField('waitingListMaxQuantity', '')
              }}
            />
          </div>
          {values.displayWaitingListMaxQuantity && (
            <div className="col-xs-12 col-sm-6 top-field-ticket-form">
              <Field
                name="waitingListMaxQuantity"
                type="number"
                min={0}
                label="Maximum tickets for waiting list"
                component={SimpleField}
                validate={minNumberValidator(undefined, 0)}
                onBlurNormalizer={toNumber}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DataRow
