export const hasTaxes = (country, state) => {
  let hasTaxes = false
  const search = `${country.toLowerCase().trim()}-${state.toLowerCase().trim()}`

  switch (search) {
    case 'united states-new york':
    case 'united states-ny':
    case 'united states-nevada':
    case 'united states-nv':
      hasTaxes = true
      break
    default:
      hasTaxes = false
      break
  }

  return hasTaxes
}
