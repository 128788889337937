import _get from 'lodash/get'
import _result from 'lodash/result'
import { connect } from 'react-redux'
import React from 'react'
import { withRouter } from 'react-router-dom'
import BrandForm from './BrandForm'
import { CREATE_BRAND } from '../../_common/redux/brands/actions'
import { showAxiosError } from '../utils/messenger'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => ({
    dynamicConfigs: state.launchDarklyInfo.launchDarklyFlags,
    user: state.auth.user,
  }),
  { CREATE_BRAND },
)
export default class NewSubBrandPage extends React.Component {
  constructor(props) {
    super(props)
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  handleSubmit = form => {
    const {
      CREATE_BRAND,
      history: { push },
      match: {
        params: { id: parentBrandId },
      },
    } = this.props
    form.attributes.parentId = parentBrandId
    return Promise.resolve(CREATE_BRAND({ ...form }))
      .catch(err => {
        showAxiosError(err, _get(err, 'errors[0].details'))
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(() => {
        push('/brands')
      })
  }

  render() {
    const { dynamicConfigs, user, configs } = this.props
    return (
      <div>
        <div className="body-panel-spacing" />
        <div className="body-panel-content">
          <BrandForm
            user={user}
            isNew={true}
            isSubBrand={true}
            onSubmit={this.handleSubmit}
            submitLabel="Create Sub Brand"
            initialValues={null}
            dynamicConfigs={dynamicConfigs}
            configs={_get(configs, 'children.BrandForm')}
          />
        </div>
      </div>
    )
  }
}
