export const EVENT_SETTINGS = [
  {
    id: 'flagInvitationsOnly',
    label: 'Invitation required to attend',
    subLabel: 'This setting restricts ticket purchases to those who have a unique invitation email from Ticket Fairy. You can send these from Interact > Invitations'
  },
  {
    id: 'flagCarer',
    label: 'Free carer ticket for disabled guests'
  },
  {
    id: 'flagRefunds',
    label: 'Refunds allowed'
  },
  {
    id: 'flagPasswordProtected',
    label: 'Password required to view event'
  },
  {
    id: 'flagNftOwnershipRequired',
    label: 'Require ownership of NFTs to attend'
  }
]

export const ONLINE_EVENT_SETTINGS = [
  {
    id: 'flagInvitationsOnly',
    label: 'Invitation required to attend',
    subLabel: 'This setting restricts ticket purchases to those who have a unique invitation email from Ticket Fairy. You can send these from Interact > Invitations'
  },
  {
    id: 'flagRefunds',
    label: 'Refunds allowed'
  },
  {
    id: 'flagPasswordProtected',
    label: 'Password required to view event'
  },
  {
    id: 'flagReminderEmails',
    label: 'Reminder Emails'
  }
]
