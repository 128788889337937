import React from 'react'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { clone } from 'lodash'

import Button from '../../_library/Button'

import {
  TextAreaField,
  SimpleField,
  SelectField,
} from '../../formik/Fields'

import {
  GIFT_CARD_TYPE
} from '../giftcards/constants'

// validators
import {
  // field level validators
  requiredValidator,
  isEmailValidator,
  combineValidators,

  // normalizer
  toInt,

} from '../../../_common/core/validation'


const CreateGiftCardForm = ({ onSubmit, giftCardTypes, ticketGroups, amounts, emailTemplatesOptions, eventId, history: { replace } }) => (
  <Formik
    initialValues={{
      giftId: '',
      email: '',
      type: '',
      quantity: '',
      firstName: '',
      lastName: '',
      message: '',
    }}
    onSubmit={(values, actions) => {
      const { setFieldValue, setSubmitting, resetForm } = actions
      const formValues = clone(values)
      if(formValues.type === GIFT_CARD_TYPE.AMOUNT) {
        setFieldValue('giftId', amounts.id)
        formValues.giftId = amounts.id
      }

      onSubmit(formValues)
        .finally(() => {
          resetForm()
          setSubmitting(false)
        })
    }}
    render={({ values, isSubmitting }) => (
      <Form autoComplete='off' className="issue-gift-card-form">
        <div className="row">
          <div className="col-xs-6 col-sm-6">
            <Field
              name='type'
              label='Gift Certificate Type'
              options={giftCardTypes}
              component={SelectField}
              validate={requiredValidator()}
            />
          </div>
          <div className="col-xs-6 col-sm-6">
            <Field
              name='email'
              label='Email'
              component={SimpleField}
              validate={combineValidators(requiredValidator(), isEmailValidator())}
            />
          </div>
        </div>
        {values.type && (
          <div className="row">
            {values.type == GIFT_CARD_TYPE.AMOUNT && (
              <div className="col-xs-6 col-sm-6">
                <Field
                  name='quantity'
                  label='Certificate Amounts'
                  options={amounts.amountsList}
                  disabled={!amounts.amountsList.length}
                  component={SelectField}
                  validate={requiredValidator()}
                />
              </div>
            )}
            {values.type == GIFT_CARD_TYPE.TICKET && (
              <div>
                <div className="col-xs-6 col-sm-6">
                  <Field
                    name='giftId'
                    label='Ticket Types'
                    disabled={!ticketGroups.length}
                    options={ticketGroups}
                    component={SelectField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="col-xs-6 col-sm-6">
                  <Field
                    name='quantity'
                    label='Quantity'
                    component={SimpleField}
                    validate={requiredValidator()}
                    normalizer={toInt}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="row">
          <div className="col-xs-6 col-sm-6">
            <Field
              name='firstName'
              label='First Name'
              component={SimpleField}
              validate={requiredValidator()}
            />
          </div>
          <div className="col-xs-6 col-sm-6">
            <Field
              name='lastName'
              label='Last Name'
              component={SimpleField}
              validate={requiredValidator()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <Field
              name='emailTemplateId'
              label='Email Templates'
              disabled={!emailTemplatesOptions.length}
              options={emailTemplatesOptions}
              component={SelectField}
            />
          </div>
          <div className="col-xs-6 col-xs-12">
            <div style={{ paddingTop: '22px' }}>
              <Button
                type='button'
                className="btn btn-primary btn-shadow"
                onClick={() => replace(`/event/${eventId}/email-templates`)}
              >
                  Add New Template
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <Field
              name='message'
              label='Note'
              component={TextAreaField}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Button
              className="btn btn-primary btn-shadow"
              type="submit"
              loading={isSubmitting}
            >
                Issue Gift Certificate
            </Button>
          </div>
        </div>
      </Form>
    )}
  />
)

export default withRouter(CreateGiftCardForm)
