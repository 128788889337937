import React from 'react'
import scrollToComponent from 'react-scroll-to-component'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _groupBy from 'lodash/groupBy'
import { Element, scroller } from 'react-scroll'
import LoadingBar from '../../_library/LoadingBar'
import EmptyBar from '../../_library/EmptyBar'
import { Map } from '../../_library/Map'
import Card from '../../_library/Card'
import Toggle from '../../_library/Toggle'
import MapNarrator from './components/MapNarrator'
import SortableTable from '../../_library/SortableTable/index'
import { shortid } from '../../../_common/core/rand'
import { getTableColumns } from '../../utils/sortableTableUtils'

const scrollTo = 'PerformanceLockedTickets'

export default class PerformanceLockedTickets extends React.PureComponent {
  constructor(props) {
    super(props)
    this.cityTableColumns = getTableColumns([
      { key: 'city', label: 'City' },
      { key: 'buyers', label: 'Quantity' }
    ])
    this.ticketTypesTableColumns = getTableColumns([
      { key: 'name', label: 'Ticket Type' },
      { key: 'qty', label: 'Quantity' }
    ])
    this.state = {
      isPageInitialized: false,
      hasAutoFocus: true,
      isScrolled: false
    }
  }

  componentDidMount() {
    const { scrollToContent } = this.props
    const isMobile = window.innerWidth <= 768
    if (scrollToContent && isMobile) {
      this.scrollToCard()
    }
  }

  componentDidUpdate(prevProps) {
    const { performance, scrollToContent, selectedIndex } = this.props
    const { isScrolled } = this.state
    const isMobile = window.innerWidth <= 768
    if (!this.state.isPageInitialized && performance.carts) {
      this.setState({ isPageInitialized: true })
    }
    if (scrollToContent && !isScrolled && isMobile) {
      this.scrollToCard()
    }
    if (!selectedIndex && isScrolled) {
      this.setState({ isScrolled: false })
    }
  }

  scrollToCard = () => {
    this.setState({ isScrolled: true })
    scroller.scrollTo(scrollTo, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  moveMap = (positionArray, narrator) => {
    if (!this.refs.map) return
    scrollToComponent(this.refs.map)
    this.refs.map.moveMap([positionArray])
    if (this.refs.mapNarrator) this.refs.mapNarrator.doAnimation(narrator)
  }

  onChangeAutoFocus = checked => {
    this.setState({
      hasAutoFocus: checked
    })
  }

  getCityItem = geo => (
    <div>
      <div className="location-icon">
        <i className="fa fa-location-arrow" onClick={() => this.moveMap(geo, geo.city)} />
      </div>
      <div className="location-icon-label">{geo.city || 'Not defined'}</div>
    </div>
  )

  render() {
    const { event, performance } = this.props
    const { isPageInitialized, hasAutoFocus } = this.state
    const lockedTickets = performance.carts ? performance.carts : {}
    const { venue } = event
    let markers = []
    let contentTable = null

    const ticketTypes = lockedTickets.ticketTypes || []
    const locations = _map(lockedTickets.locations || [], item => {
      const { geo } = item
      const city = this.getCityItem(geo)
      return {
        ...item,
        city,
        buyers: item.buyers ? item.buyers.length : 0,
        id: shortid()
      }
    })

    if (!performance.carts || !isPageInitialized) {
      return <LoadingBar title={"Hold tight! We're getting your current shopping carts..."} />
    }
    markers = _map(lockedTickets.locations, item => item.geo)
    if (markers.length === 0) {
      contentTable = <EmptyBar />
    } else {
      const cities = _sortBy(_groupBy(locations, loc => loc.geo.country && loc.city))
      contentTable = (
        <div className="row">
          <div className="col-xs-12 col-sm-6 buyerCard">
            <div className="title">
              <i className="fa fa-map-marker grey" />
              Buyers by City
            </div>
            <SortableTable
              data={locations}
              dataForCopy={_map(lockedTickets.locations, item => ({
                buyers: item.buyers ? item.buyers.length : 0,
                city: item.geo.city || 'Not Defined'
              }))}
              tableColumns={this.cityTableColumns}
              enableSort={false}
              enableCopyTable={true}
            />
          </div>
          <div className="col-xs-12 col-sm-6 buyerCard">
            <div className="title">
              <i className="fa fa-ticket" />
              Ticket Types in Carts
            </div>
            <SortableTable
              data={ticketTypes}
              tableColumns={this.ticketTypesTableColumns}
              enableSort={false}
              enableCopyTable={true}
            />
          </div>
        </div>
      )
    }

    return (
      <Element name={scrollTo}>
        <div className="buyerlocation">
          <Card icon={'fas fa-cart-plus'} title={'Cart Breakdowns'}>
            {contentTable}
          </Card>
          <div className="locked-ticets-card">
            <Card icon={'fa-globe'} title={'Cart Locations'}>
              <div className="map">
                <Map
                  ref="map"
                  placeEventMarkersOnce={true}
                  clearMarkersBeforeRender={true}
                  hasAutoFocus={hasAutoFocus}
                  locations={[venue]}
                  markers={markers}
                />
                <MapNarrator ref="mapNarrator" />
              </div>
              <div style={{ margin: 10 }}>
                <Toggle
                  title="Enable map auto zooming"
                  id="showAutoFocus"
                  value={hasAutoFocus}
                  onChange={this.onChangeAutoFocus}
                />
              </div>
            </Card>
          </div>
        </div>
      </Element>
    )
  }
}
