import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import promise from 'redux-promise'
import thunk from 'redux-thunk'

import { loadingReducer } from './actions'
import notificationReducer from './notification/reducers'
import authReducer from './auth/reducers'
import adsReducer from './ads/reducers'
import brandsReducer from './brands/reducers'
import eventsReducer from './events/reducers'
import alternativeviewReducer from './alternativeview/reducers'
import ticketsReducer from './tickets/reducers'
import addonsReducer from './addons/reducers'
import performanceReducer from './performance/reducers'
import influencersReducer from './influencers/reducers'
import giftcardsReducer from './giftcards/reducers'
import buyerlocationReducer from './buyerlocation/reducers'
import audienceReducer from './audience/reducers'
import mailchimpReducer from './mailchimp/reducers'
import promotionReducer from './promotion/reducers'
import talentReducer from './talent/reducers'
import emailtemplatesReducer from './emailtemplates/reducers'
import inventorydelegationsReducer from './inventorydelegations/reducers'
import passwordsReducer from './passwords/reducers'
import toursReducer from './tours/reducers'
import eventteamReducer from './eventteam/reducers'
import faqsReducer from './faqs/reducers'
import budgetsReducer from './budgets/reducers'
import brandteamReducer from './brandteam/reducers'
import tourteamReducer from './tourteam/reducers'
import tourperformanceReducer from './tourperformance/reducers'
import guestticketsReducer from './guesttickets/reducers'
import sharedReducer from './shared/reducers'
import membershipsReducer from './memberships/reducers'
import rsvpReducer from './rsvp/reducers'
import credentialsReducer from './credentials/reducers'
import launchdarklyReducer from './launchdarkly/reducers'
import preRegistrationReducer from './preRegistration/reducers'
import usersReducer from './users/reducers'
import eventhostingReducer from './eventhosting/reducers'
import shopifyReducer from './shopify/reducers'
import ordersReducer from './orders/reducers'
import draftEventsReducer from './draftevents/reducers'
import ticketTrackingReducer from './ticketSalesTracking/reducers'
import eventSupportReducer from './eventSupport/reducers'
import supportNotesReducer from './supportNotes/reducers'

const enhancers = [applyMiddleware(thunk, promise), null].filter(Boolean)
const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const factory = composeEnhancers(...enhancers)(createStore)

const appReducer = combineReducers({
  loading: loadingReducer,
  notifications: notificationReducer,
  auth: authReducer,
  ads: adsReducer,
  brands: brandsReducer,
  events: eventsReducer,
  alternativeview: alternativeviewReducer,
  tickets: ticketsReducer,
  addons: addonsReducer,
  performance: performanceReducer,
  influencers: influencersReducer,
  giftcards: giftcardsReducer,
  buyerlocation: buyerlocationReducer,
  audience: audienceReducer,
  mailchimp: mailchimpReducer,
  promotions: promotionReducer,
  talent: talentReducer,
  emailtemplates: emailtemplatesReducer,
  inventorydelegations: inventorydelegationsReducer,
  passwords: passwordsReducer,
  tours: toursReducer,
  eventteam: eventteamReducer,
  faqs: faqsReducer,
  budgets: budgetsReducer,
  brandteam: brandteamReducer,
  tourteam: tourteamReducer,
  tourperformance: tourperformanceReducer,
  guesttickets: guestticketsReducer,
  shared: sharedReducer,
  memberships: membershipsReducer,
  rsvp: rsvpReducer,
  credentials: credentialsReducer,
  launchDarklyInfo: launchdarklyReducer,
  preRegistration: preRegistrationReducer,
  users: usersReducer,
  eventhosting: eventhostingReducer,
  shopify: shopifyReducer,
  orders: ordersReducer,
  draftEvents: draftEventsReducer,
  ticketSalesTracking: ticketTrackingReducer,
  eventSupport: eventSupportReducer,
  supportNotes: supportNotesReducer,
})

const store = factory((state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
})

export default store
