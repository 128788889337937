import React from 'react'

export default function EventTitleImagePreview(props) {
  const { backgroundImageUrl, titleImageUrl } = props
  return <div className="event-form__title-image-preview-on-background">
    <div className="event_background_image" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className="event_title_image" style={{ backgroundImage: `url(${titleImageUrl})` }}/>
    </div>
  </div>
}
