import React from 'react'
import _get from 'lodash/get'
import store from '../../_common/redux/store'
import { Redirect } from 'react-router-dom'

const limitedStatsWrapperHOC = C => props => {
  const globalState = store.getState()
  const permission = _get(globalState, 'events.selected.self.role')

  if (permission === 'limited_stats') {
    return <Redirect to="/" />
  }

  return <C {...props} />
}

export { limitedStatsWrapperHOC }
