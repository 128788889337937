import React from 'react'

const InfluencerRowDetail = ({ row }) => (
  <div className="row-detail">
    <div className="row-title row">
      <div className="col-xs-6 withDecorator">Contact Information</div>
      <div className="col-xs-6 withDecorator">Location</div>
    </div>
    <div className="div-spacing-20" />
    <div className="row-content row">
      <div className="col-xs-3">
        <div className="content">
          <div className="field">
            <i className="fa fa-envelope" aria-hidden="true" /> Email Address
          </div>
          <div className="value">{row.email}</div>
        </div>
      </div>
      <div className="col-xs-3">
        <div className="content">
          <div className="field">
            <i className="fa fa-phone-square" aria-hidden="true" /> Phone Number
          </div>
          <div className="value">{row.phone}</div>
        </div>
      </div>
      <div className="col-xs-3">
        <div className="content">
          <div className="field">
            <i className="fa fa-map-marker" aria-hidden="true" /> City
          </div>
          <div className="value">{row.city}</div>
        </div>
      </div>
      <div className="col-xs-3">
        <div className="content">
          <div className="field">
            <i className="fa fa-globe" aria-hidden="true" /> Country
          </div>
          <div className="value">{row.country}</div>
        </div>
      </div>
    </div>
  </div>
)

export default InfluencerRowDetail