import React, { useState } from 'react'
import RnLoginForm from './RnLoginForm'
import RnForgotPasswordForm from './RnForgotPasswordForm'

const RnPage = ({ onSubmit, initialEmail, onRestorePassword }) => {
  const [showForgotPasswordPage, setShowForgotPasswordPage] = useState(false)

  const handleOpenForgotPassword = () => {
    setShowForgotPasswordPage(true)
  }

  const handleBackToLogin = () => {
    setShowForgotPasswordPage(false)
  }

  return !showForgotPasswordPage ? (
    <RnLoginForm
      handleSubmit={onSubmit}
      initialEmail={initialEmail}
      handleOpenForgotPassword={handleOpenForgotPassword}
    />
  ) : (
    <RnForgotPasswordForm onRestorePassword={onRestorePassword} handleBackToLogin={handleBackToLogin} />
  )
}

export default RnPage
