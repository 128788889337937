import React, { useState, useRef, useEffect, useCallback } from 'react'
import Button from '../../../web/_library/Button'

export default function EventCustomContextMenu({ onInnerClick }) {
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [clickedOutside, setClickedOutside] = useState(false)

  const myRef = useRef()

  const handleClickOutside = useCallback(e => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true)
      setShowContextMenu(false)
    }
  },[])

  const handleClickInside = () => {
    setClickedOutside(false)
    onInnerClick()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  return (
    <div className="custom-context-menu-wrapper" ref={myRef}>
      <Button onClick={() => setShowContextMenu(true)}>
        <img
          className="event-button-ellipsis"
          alt="nodata"
          src={asset('/resources/images/ellipsis-vertical-solid.svg')}
        />
      </Button>
      {showContextMenu && (
        <nav role="menu" tabIndex="-1" className="event-contextmenu" >
          <div
            className="react-contextmenu-item"
            role="menuitem"
            tabIndex="-1"
            aria-disabled="false"
            onClick={handleClickInside}
            onKeyDown={()=>{}}
          >
            Delete Event
          </div>
        </nav>
      )}
    </div>
  )
}
