import { baseRequest } from '.'

export const checkExistsCustomer = async email => {
  try {
    email = window.encodeURI(email)
    await baseRequest.get(`customer/check/`, { params: { email } })
    return true
  } catch (e) {
    return false
  }
}
