import React from 'react'
import _map from 'lodash/map'
import { shortid } from '../../../_common/core/rand'

const numberRanges = [
  {
    type: 'icon',
    label: 'No. of Buyers',
    icon: 'fa fa-fw fa-users'
  },
  {
    type: 'color',
    label: '0-9',
    color: 'p1'
  },
  {
    type: 'color',
    label: '10-99',
    color: 'p2'
  },
  {
    type: 'color',
    label: '100-999',
    color: 'p3'
  },
  {
    type: 'color',
    label: '1000-9999',
    color: 'p4'
  },
  {
    type: 'color',
    label: '10000+',
    color: 'p5'
  }
]

const NumberOfBuyers = props => {
  const { buyers = numberRanges, label } = props
  return (
    <div className="numberOfBuyers">
      <div className="row">
        {_map(buyers, (item, i) => {
          let content = null
          if (item.type === 'icon') {
            content = (
              <div>
                <i className={item.icon} />
                {i === 0 && label ? label : item.label}
              </div>
            )
          } else {
            content = (
              <div className="range">
                <div className={`color ${item.color}`} />
                {item.label}
              </div>
            )
          }

          return (
            <div key={shortid()} className="col-sm-2 col-xs-12 title text-center">
              {content}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { NumberOfBuyers }
