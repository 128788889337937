import React from 'react'

const PermissionDenied = props => {
  const { header, subHeader } = props

  return (
    <div className="permission-denied">
      {header || `Permission Denied`}
      <p>{subHeader || `You don't have access to this event`}</p>
    </div>
  )
}

export default PermissionDenied
