/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'

export class Radio extends React.Component {
  render() {
    const { name, selectedValue, onChange } = this.context.radioGroup
    const className = 'ctlRadio' + (this.props.value === selectedValue ? ' selected' : '')
    return (
      <div className={className} onClick={onChange.bind(null, this.props.value, this.props.name)}>
        {this.props.label}
      </div>
    )
  }
}

Radio.contextTypes = {
  radioGroup: PropTypes.object,
}

export class RadioGroup extends React.PureComponent {
  getChildContext() {
    const { name, selectedValue, onChange } = this.props
    return {
      radioGroup: {
        name,
        selectedValue,
        onChange,
      },
    }
  }

  render() {
    const { Component, name, selectedValue, onChange, children, ...rest } = this.props
    return (
      <Component role="radiogroup" className="ctlRadioGroup" {...rest}>
        {children}
      </Component>
    )
  }
}

RadioGroup.defaultProps = {
  Component: 'div',
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
}

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object,
}
