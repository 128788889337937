import React from 'react'
import { Form, Formik, Field } from 'formik'
import { combineValidators, requiredValidator, isEmailValidator } from '../../_common/core/validation/'
import { SimpleField } from '../formik/Fields'

const RnLoginForm = props => {
  const { handleSubmit, handleOpenForgotPassword } = props

  return (
    <div className="rn_login_page">
      <div className="rn_login_page__header">
        <div className="rn_login_page__svgContent">
          <div className="rn_login__header__leftside">
            <div className="rn_login_page__image">
              <img src={asset('/resources/images/rn-logo.svg')} alt="no data" />
            </div>
            <div className="rn_login_page__restlessnites__title">Restless Nites</div>
          </div>
          <div className="header-subtext-container">
            {'POWERED BY'}
            <br />
            {'THE'}
            <strong>{'TICKET'}</strong>
            {'FAIRY'}
          </div>
        </div>
        <div className="rn_login_modal-title">Login</div>
      </div>
      <div className="rn_login_page_content">
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          onSubmit={values => {
            handleSubmit({ attributes: values })
          }}
          render={() => (
            <Form>
              <div className="rn_login_email">
                <Field
                  name="username"
                  placeholder="Email address"
                  component={SimpleField}
                  validate={combineValidators(requiredValidator(), isEmailValidator())}
                />
              </div>
              <div className="rn_login_password">
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={SimpleField}
                  validate={requiredValidator()}
                />
              </div>
              <div className="rn_login_button">
                <button type="submit">LOGIN</button>
              </div>
              <div
                className="rn_login_actions_content"
                onClick={handleOpenForgotPassword}
                onKeyDown={handleOpenForgotPassword}
              >
                <p>FORGOT YOUR PASSWORD?</p>
                <p>LET US HELP</p>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  )
}

export default RnLoginForm
