import React from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { createFixedFloatNormalizer, currencyNormalizerCreator } from '../../../_common/core/validation'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import SortableTableWithDnDHOC from '../../hoc/SortableTableWithDND'
import { addOnGroupSortAdapter } from '../../../_common/core/http_services/adapters/addons'
import { addOnStockNormalizer } from './normalizers/addOnStockNormalizer'

const SortableTableWithDND = SortableTableWithDnDHOC()

export default class AddOnTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      { key: 'name', label: 'Name' },
      { key: 'groupName', label: 'Group', hideColumn: props.enableAddOnsWithVariants },
      {
        key: 'cost',
        label: 'Cost',
        className: 'half minw-100',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) / 100),
          ),
      },
      {
        key: 'price',
        label: 'Price',
        className: 'half minw-100',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) / 100),
          ),
      },
      {
        key: 'stock',
        label: props.stockLabel,
        className: 'minw-100',
        normalizer: addOnStockNormalizer,
      },
    ])
    this.variantsTableColumns = getTableColumns([
      {
        key: 'name',
        label: 'Name of variant',
        className: 'detail-cell',
        isSortable: false,
      },
      {
        key: 'stock',
        label: props.stockLabel,
        className: 'detail-cell',
        isSortable: false,
        normalizer: addOnStockNormalizer,
      },
    ])
    this.state = {
      addonGroups: [],
      eventDetailsRow: [],
    }
  }

  setDetailsRows = () => {
    const {
      addons = [],
      handleEditAddonVariant,
      handleOpenDeleteAddOnVariantModal,
      enableAddOnsWithVariants,
    } = this.props
    const addonVariants = addons.filter(item => item.addOnGroupId)
    const addonGroups = addons.filter(item => !addonVariants.includes(item))
    const sortedAddOnGroups = addOnGroupSortAdapter(addonGroups, addonVariants)
    const addonData = enableAddOnsWithVariants ? sortedAddOnGroups : addons

    const eventDetailsRow = sortedAddOnGroups.map(item => {
      const addonVariantsArray = addonVariants.filter(variant => variant.addOnGroupId === item.id)

      return {
        id: item.id,
        type: 'detailRow',
        component: () =>
          !_isEmpty(addonVariantsArray) ? (
            <SortableTable
              e2e_test_id={`add-on-${item.id}`}
              data={addonVariantsArray}
              tableColumns={this.variantsTableColumns}
              disableMobileView={true}
              sortBy={{
                column: 'sortOrder',
                asc: true,
              }}
              className="child-table"
              actions={[
                {
                  label: 'Edit',
                  className: 'btn btn-primary',
                  icon: 'fa fa-pencil fa-fw',
                  onClick: data => {
                    handleEditAddonVariant(data)
                  },
                },
                {
                  label: 'Delete',
                  className: 'btn btn-danger',
                  icon: 'fa fa-trash fa-fw',
                  disabled: addonVariantsArray.length === 1,
                  onClick: data => handleOpenDeleteAddOnVariantModal(data),
                },
              ]}
            />
          ) : (
            <div>No Addon variant to show</div>
          ),
      }
    })

    this.setState({
      addonGroups: addonData,
      eventDetailsRow,
    })
  }

  componentDidMount() {
    this.setDetailsRows()
  }

  componentDidUpdate(prevProps) {
    const { addons } = this.props

    if (!_isEqual(addons, prevProps.addons)) {
      this.setDetailsRows()
    }
  }

  render() {
    const {
      addons = [],
      isLoading = true,
      loadingAddOnUpdate = {},
      handleEditAddon,
      handleActivateAddon,
      handleDeactivateAddonOpenModal,
      handleAddAddon,
      addingAddon,
      event,
      handleAddonRefresh,
      loadingAddonsTable,
      handleSaveSort,
      enableAddOnsWithVariants,
    } = this.props
    const { addonGroups, eventDetailsRow } = this.state
    const isAddOnsEmpty = !addons.length
    const detailsRows = enableAddOnsWithVariants ? eventDetailsRow : []
    const enableDetailsRowsReinitialize = enableAddOnsWithVariants ? true : false

    return (
      <div>
        {isLoading && <LoadingBar title="Hold tight! We're getting add-ons data..." />}
        <SortableTableWithDND
          data={addonGroups}
          event={event}
          tableColumns={this.tableColumns}
          enableCopyTable={false}
          enableSort={false}
          className="table addons-table"
          actionsClassName="pos-start mw-240"
          statuses={[
            {
              key: 'active',
              activeLabel: `This add-on is available for sale`,
              inactiveLabel: (
                <span>
                  This add-on is <strong>not</strong> available for sale
                </span>
              ),
            },
          ]}
          actions={[
            {
              label: 'Edit',
              className: 'btn btn-primary',
              icon: 'fa fa-pencil fa-fw',
              onClick: data => handleEditAddon(data),
            },
            {
              label: data => (data.active ? 'Deactivate' : 'Activate'),
              className: data => (data.active ? 'btn btn-seablue' : 'btn btn-ok'),
              icon: data => (data.active ? 'fa fa-ban fa-fw' : 'fa fa-cog fa-fw'),
              isLoading: data => !data.active && loadingAddOnUpdate[_get(data, 'id')],
              onClick: data =>
                data.active ? handleDeactivateAddonOpenModal(data) : handleActivateAddon(data, true),
            },
          ]}
          isDndHistoryEnabled={true}
          hideDndActionBtns={addingAddon}
          isEmpty={isAddOnsEmpty}
          showEmptyBar={false}
          isLoading={isLoading}
          tableMainActions={[
            {
              label: 'Add Add-On',
              className: 'btn btn-ok',
              icon: 'fa fa-fw fa-plus',
              onClick: handleAddAddon,
              hideBtn: addingAddon,
            },
            {
              label: 'Refresh',
              className: 'btn btn-seablue',
              icon: 'fa fa-fw fa-refresh',
              onClick: handleAddonRefresh,
              loading: loadingAddonsTable,
              hideBtn: addingAddon,
            },
          ]}
          handleSaveSort={handleSaveSort}
          disableRefreshIfDraggDropped={true}
          detailsRows={detailsRows}
          enableDetailsRowsReinitialize={enableDetailsRowsReinitialize}
        />
      </div>
    )
  }
}
