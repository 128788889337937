// Re-render Optimization 15.05.2020
import React from 'react'

export default React.memo(props => {
  const { title = 'Loading...', className = '' } = props

  return (
    <div className={'card text-center ' + className}>
      <div className="card-block text-center text-muted">
        <div>
          <img height={200} width={200} alt={title} src={asset('/resources/images/loading-bar.gif')} />
        </div>
        {title}
      </div>
    </div>
  )
})
