import React, { PureComponent } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

export default class ProgressCircle extends PureComponent {
  state = { initial: true }
  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({ initial: false }))
    }, 0)
  }

  render() {
    const { img, value } = this.props
    const { initial } = this.state
    const isImgCentered = img != null

    return (
      <div className="progressCircle">
        <CircularProgressbar
          strokeWidth={6}
          value={initial ? 0 : value}
          styles={buildStyles({
            pathTransitionDuration: 0.7,
          })}
        />
        <div className="decoration">
          <div className="innerCircle" />
        </div>
        {!isImgCentered && <div className="progrsssText">{value}%</div>}
        {isImgCentered && <img alt="no data" className="centerImage" src={img} />}
      </div>
    )
  }
}
