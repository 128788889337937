import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'
import _result from 'lodash/result'
import Button from '../../_library/Button'
import ConfirmModal from '../../_library/ConfirmModal'
import { getTableColumns } from '../../utils/sortableTableUtils'

// hoc
import { asyncComponent } from '../../hoc'
import SortableTableWithPaginationHOC from '../../hoc/SortableTableWithPaginationHOC'
import SortableTableWithSearchHOC from '../../hoc/SortableTableWithSearchHOC'

const QRCode = asyncComponent(() => import('qrcode.react'))

const DEFAULT_PAGE_SIZE = 2500

const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)
export default class CheckInIndividualTickets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      qr_list: {},
      scanning: {},
      ticketToUnscan: null,
      unscanTicketConfirmDialogOpen: false,
      openScanModal: false,
      qrData: '',
      data: this.adoptData(props.tickets),
      sortBy: { column: 'order_id', asc: false },
      isLoadingData: false,
      pageNumber: 1,
      searchValue: '',
      resetPagination: 0,
    }

    this.tableColumns = getTableColumns([
      {
        label: 'Ticket ID',
        key: 'qr_data',
        sort: this.sortBy,
      },
      {
        label: 'Order ID',
        key: 'order_id',
        sort: this.sortBy,
      },
      {
        label: 'Ticket Holder',
        key: 'first_name',
        sort: this.sortBy,
      },
      {
        label: 'Email',
        key: 'email',
        sort: this.sortBy,
      },
      {
        label: 'Ticket Type',
        key: 'ticket_type',
        sort: this.sortBy,
      },
      {
        label: 'Status',
        key: 'statusValue',
        normalizer: value => _startCase(value || 'Not Yet'),
        sort: this.sortBy,
      },
      {
        label: 'Checked In At',
        key: 'checked_in_at',
        normalizer: value => value || 'Not Yet',
        sort: this.sortBy,
      },
      {
        label: 'Checked In By',
        key: 'checked_in_by_name',
        normalizer: value => value || 'Not Yet',
        sort: this.sortBy,
      },
      {
        label: 'Credential ID',
        key: 'credential_id',
        normalizer: value => value || 'Not Linked',
        sort: this.sortBy,
      },
    ])
  }

  addStatusValue = (data = []) => _map(data, item => ({ ...item, statusValue: item.status }))

  // status key is reserved
  adoptData = (data = []) => this.addStatusValue(data).sort((a, b) => Number(b.order_id) - Number(a.order_id))

  handleQRCode = t => {
    const { qr_list } = this.state
    const isVisible = _get(qr_list, t.ticket_hash, 'none') === 'none' ? false : true
    _set(qr_list, t.ticket_hash, !isVisible ? 'visible' : 'none')
    this.setState({ qr_list })
  }

  openUnscanTicketConfirmDialog = t => {
    this.setState({
      ticketToUnscan: t,
      unscanTicketConfirmDialogOpen: true,
    })
  }

  closeUnscanTicketConfirmDialog = () => {
    this.setState({
      ticketToUnscan: null,
      unscanTicketConfirmDialogOpen: false,
    })
  }

  unscanTicket = t => {
    const { UNSCAN_TICKET } = this.props
    const that = this
    const { scanning } = this.state

    scanning[t.ticket_hash] = true
    that.setState({ scanning })

    return Promise.resolve(UNSCAN_TICKET(t.ticket_hash))
      .catch(err => {
        scanning[t.ticket_hash] = false
        that.setState({
          scanning,
          unscanTicketConfirmDialogOpen: false,
          ticketToUnscan: null,
        })
        that.manualRefreshTicketsTable()
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(res => {
        scanning[t.ticket_hash] = false
        that.setState({
          scanning,
          unscanTicketConfirmDialogOpen: false,
          ticketToUnscan: null,
        })
        that.manualRefreshTicketsTable()
        return res
      })
  }

  manualRefreshTicketsTable = () => {
    this.setState({ isLoadingData: true }, async () => {
      try {
        await this.fetchCheckInTickets(false)
      } catch (err) {
        this.setState({ isLoadingData: false })
      }
    })
  }

  handleQRCodeClick = data => {
    this.setState({
      openScanModal: true,
      qrData: data.qr_data,
    })
  }

  getSearchedData = ({ searchValue }) => {
    this.setState(
      { resetPagination: this.state.resetPagination + 1, searchValue, isLoadingData: true },
      async () => {
        try {
          await this.fetchCheckInTickets(true)
        } catch (err) {
          this.setState({ isLoadingData: false })
        }
      },
    )
  }

  sortBy = column => {
    this.setState(
      { sortBy: { column: column.key, asc: !this.state.sortBy.asc }, isLoadingData: true },
      async () => {
        try {
          await this.fetchCheckInTickets(true)
        } catch (e) {
          this.setState({ isLoadingData: false })
        }
      },
    )
  }

  fetchCheckInTickets = async reset => {
    const { eventId, FETCH_EVENT_CHECKIN_TICKETS } = this.props
    const { searchValue, sortBy } = this.state
    const optionSortBy = sortBy.column
    const optionSortOrder = sortBy.asc ? 'asc' : 'desc'
    const pageNumber = reset ? { pageNumber: 1 } : {}
    const data = await FETCH_EVENT_CHECKIN_TICKETS(eventId, searchValue, optionSortBy, optionSortOrder)
    this.setState({
      data: this.addStatusValue(data),
      isLoadingData: false,
      ...pageNumber,
    })
  }

  render() {
    const { isLoading } = this.props

    const newData = this.state.data.map(row => ({
      ...row,
      first_name: row.first_name + ' ' + row.last_name,
    }))

    const {
      unscanTicketConfirmDialogOpen,
      ticketToUnscan,
      qrData,
      openScanModal,
      isLoadingData,
      pageNumber,
      sortBy,
      resetPagination,
      searchValue,
    } = this.state

    return (
      <div style={{ margin: 10 }}>
        <ConfirmModal
          isOpen={!!unscanTicketConfirmDialogOpen}
          header="Unscan ticket"
          classNames={{
            modal: 'modal-dialog modal-trans modal-small',
            container: 'modal-dialog modal-small center',
          }}
          content={
            <div className="message-modal-content">
              <p>Are you sure you want to unscan ticket?</p>
            </div>
          }
          actions={[
            { value: 'yes', label: 'Yes', className: 'btn btn-danger btn-shadow' },
            { value: 'no', label: 'No', className: 'btn btn-cancel btn-shadow' },
          ]}
          onAction={value => {
            if (value === 'yes') {
              this.unscanTicket(ticketToUnscan)
            } else {
              this.closeUnscanTicketConfirmDialog()
            }
          }}
        />
        <ConfirmModal
          isOpen={openScanModal}
          header="Ticket QR Code"
          classNames={{
            modal: 'modal-dialog modal-trans modal-small',
            container: 'modal-dialog modal-small center',
          }}
          content={
            <div className="message-modal-content">
              <QRCode value={qrData} size={150} />
            </div>
          }
          actions={[{ label: 'Close', value: 'close', className: 'btn btn-cancel' }]}
          onAction={() => {
            this.setState({
              openScanModal: false,
              qrData: '',
            })
          }}
        />
        <div className="table-caption">
          <img src={asset('/resources/images/icon-person.png')} className="icon" alt="No data" />
          Individual Tickets
        </div>
        <div className="row event-checkin-table-container">
          <div className="col-xs-12">
            <Button
              onClick={() => this.manualRefreshTicketsTable()}
              className="btn btn-blue btn-reloadtickets"
              disabled={isLoading || isLoadingData}
            >
              Reload Tickets
            </Button>
            <SortableTableWithSearchAndPagination
              data={newData}
              tableColumns={this.tableColumns}
              enableCopyTable={true}
              sortBy={sortBy}
              enableSort={true}
              actionsLabel="Scan Ticket"
              actions={[
                {
                  label: data => (data.status !== 'checked in' ? 'Scan Ticket' : 'Unscan Ticket'),
                  className: 'btn btn-primary btn-spacing-5',
                  onClick: data => {
                    if (data.status !== 'checked in') {
                      this.handleQRCodeClick(data)
                    } else {
                      this.openUnscanTicketConfirmDialog(data)
                    }
                  },
                },
              ]}
              disableInnerSort={true}
              // search props
              searchBy={this.searchBy}
              getSearchedData={this.getSearchedData}
              disableMobileView={true}
              isLoadingSearchData={isLoadingData || isLoading}
              showSearchLoading={true}
              pageNumber={pageNumber}
              isAsyncSearch={true}
              resetPagination={resetPagination}
              showAsTag
              searchValue={searchValue}
              className={isLoadingData ? 'loading' : ''}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              setRowProps={row => {
                if (row.is_disputed) {
                  return { className: 'ticket-orange' }
                }
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
