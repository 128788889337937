import _get from 'lodash/get'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_PROMOTIONS } = createAsyncAction('FETCH_PROMOTIONS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/promotions`)
      .catch(err => {
        const errMsg = _get(err, 'errors[0].meta.details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const out = { eid, promotions: res }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_PROMOTION } = createAsyncAction('CREATE_PROMOTION', function (eid, form) {
  const body = {
    data: {
      ...form,
      type: 'promotion',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/promotions`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        const errMsg = _get(err, 'errors[0].meta.details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_PROMOTION } = createAsyncAction('UPDATE_PROMOTION', function (pid, eid, form) {
  const body = {
    data: {
      ...form,
      id: pid,
      type: 'promotion',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/promotions/${pid}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
      .catch(err => {
        const errMsg = _get(err, 'errors[0].meta.details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export { FETCH_PROMOTIONS, CREATE_PROMOTION, UPDATE_PROMOTION }
