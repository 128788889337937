import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  inventorydelegations: {},
}

const FETCH_INVENTORY_DELEGATIONS = createAsyncHandlers('FETCH_INVENTORY_DELEGATIONS', {
  success(state, action) {
    const {
      eid,
      inventoryDelegations: { data },
    } = action.payload
    state.inventorydelegations[eid] = data
  },
})

const CREATE_INVENTORY_DELEGATION = createAsyncHandlers('CREATE_INVENTORY_DELEGATION', {
  success(state, action) {
    const {
      res: { data },
      eid,
    } = action.payload
    data.id = String(_get(data, '$original.id'))
    if (state.inventorydelegations[eid]) {
      state.inventorydelegations[eid].push(data)
    }
  },
})

const DELETE_INVENTORY_DELEGATION = createAsyncHandlers('DELETE_INVENTORY_DELEGATION', {
  success(state, action) {
    const { did, eid } = action.payload
    if (state.inventorydelegations[eid]) {
      const index = _findIndex(state.inventorydelegations[eid], el => String(el.id) === String(did))
      if (index !== -1) {
        state.inventorydelegations[eid].splice(index, 1)
      }
    }
  },
})

const UPDATE_INVENTORY_DELEGATION = createAsyncHandlers('UPDATE_INVENTORY_DELEGATION', {
  success(state, action) {
    const {
      res: { data },
      eid,
    } = action.payload
    data.id = String(_get(data, '$original.id'))
    if (state.inventorydelegations[eid]) {
      const index = _findIndex(state.inventorydelegations[eid], el => String(el.id) === String(data.id))
      if (index !== -1) {
        state.inventorydelegations[eid][index].access = data.access
      }
    }
  },
})

export default handleActions(
  {
    ...FETCH_INVENTORY_DELEGATIONS,
    ...CREATE_INVENTORY_DELEGATION,
    ...DELETE_INVENTORY_DELEGATION,
    ...UPDATE_INVENTORY_DELEGATION,
  },
  initialState,
)
