import React from 'react'
import CustomToggle from '../../../../_library/CustomToggle'
import DateTimePickerReact from '../../../../_library/DateTimePickerReact'
import AnnounceLocationButton from './AnnounceLocationButton'
import _get from 'lodash/get'

export default class EventVenueFlags extends React.Component {
  handleChangeDate = date => {
    const { updateField } = this.props
    updateField('hideUntil', date)
  }

  debouncedUpdateField = () => {
    const { updateField, values: { flagHidden } } = this.props
    updateField('flagHidden', !flagHidden)
  }
 
  render() {
    const { values, flagRsvpInfo, handleRsvpInfoCheckbox, updateField, event, forceUpdate, hasVenueChanged, configs } = this.props

    const hideSendAlternativeInformationForRSVPs = _get(configs, 'hideSendAlternativeInformationForRSVPs', false)
    const hideDisabledAccess = _get(configs, 'hideDisabledAccess', false)
    const showApplyTax = _get(configs, 'showApplyTax', false)
    
    return (
      <div style={{ marginTop: -15, padding: '0 5px' }}>
        {(showApplyTax && values.flagRegionHasTax) ? <div style={{ margin: '10px 0' }}>
          <CustomToggle
            direction="right"
            id="flagApplyTax"
            offText="Add local taxes"
            value={values.flagApplyTax}
            checked={values.flagApplyTax}
            onChange={updateField}
            disabled={values.hasSales}
          />
        </div> : null}
        {hideDisabledAccess ? null : <div style={{ margin: '10px 0' }}>
          <CustomToggle
            direction="right"
            id="flagDisabled"
            offText="Disabled access"
            value={values.flagDisabled}
            checked={values.flagDisabled}
            onChange={updateField}
          />
        </div>}
        <div style={{ margin: '10px 0' }}>
          <CustomToggle
            direction="right"
            id="flagHidden"
            offText={_get(configs, 'messages.hideToggleLabel')}
            value={values.flagHidden}
            checked={values.flagHidden}
            onChange={this.debouncedUpdateField}
          />
        </div>
        <div style={{ padding: '5px 5px 0', marginBottom: 20 }}>
          <DateTimePickerReact
            id="hideUntil"
            label="Location Disclosure Date (optional)"
            placeholder="D MMM YYYY H:M AM"
            initialValue={values.hideUntil || ''}
            value={values.hideUntil || ''}
            onChange={this.handleChangeDate}
          />
        </div>
        {(event?.self?.role === 'admin' || event?.self?.role === 'owner') && (
          <AnnounceLocationButton
            values={values}
            event={event}
            disabled={hasVenueChanged}
            updateField={updateField}
            forceUpdate={forceUpdate}
          />
        )}
        {hideSendAlternativeInformationForRSVPs ? null : <div style={{ margin: '10px 0' }}>
          <CustomToggle
            direction="right"
            id="flagRsvpInfo"
            offText="Send alternative information for RSVPs"
            value={flagRsvpInfo}
            checked={flagRsvpInfo}
            onChange={handleRsvpInfoCheckbox}
          />
        </div>}
      </div>
    )
  }
}
