import React from 'react'

const canUseDOM = () => !(typeof window === 'undefined' || !window.document || !window.document.createElement)

export const ZendeskWidget = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args)
  } else {
    console.warn('Zendesk has not finished booting')
  }

  const onZendeskButtonClick = () => {
    window.zE('messenger', 'open')
  }

  const getBottomPosition = () => {
    const bottomPosition = 16

    return bottomPosition
  }

  return (
    <div className="zendesk_widget" style={{ bottom: getBottomPosition() }}>
      <i aria-hidden={true} className="icon text-red" onClick={onZendeskButtonClick}>
        <img
          alt="zendesk_widget"
          width="64px"
          height="64px"
          src={asset('/resources/images/zendesk-help.svg')}
        />
      </i>
    </div>
  )
}
