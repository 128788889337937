export const DEFAULT_CHART_COLORS = [
  '#3bc77a',
  '#6C88CD',
  '#B1CE78',
  '#FF76A5',
  '#53DCEE',
  '#8C8AFC',
  '#00E2A8',
  '#00EEAF',
  '#495893',
  '#c378ce',
  '#77b754',
  '#cd5356',
  '#b4cdea',
  '#908c9c',
  '#e6c877',
  '#eb7f5e',
  '#72b791',
  '#28a474',
  '#0395e9',
  '#a0a0a0',
]
