import React from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _startCase from 'lodash/startCase'

import { formatDate } from '../../_common/core/validation/normalizers'
import Currency from '../_library/Currency'
import { DISPLAY_FORMAT } from '../constants/timeFormats'

export const getTableColumns = (data, currency) => {
  if (!data.length) return null

  const filteredData = _filter(data, item => !item.hideColumn)
  return _map(filteredData, (item, index) => ({
    label: item.label === null ? '' : item.label || _startCase(item.key || item || ''),
    labelComponent: item.labelComponent || null,
    key: item.key || item,
    isSortable: item.isSortable === undefined ? true : item.isSortable,
    normalizer: item.normalizer ? item.normalizer : value => getNormalizer(item, value, currency),
    sort: item.sort ? item.sort : null,
    className: item.className || '',
    enableItemCopy: item.enableItemCopy || false,
    disableNormalizerOnCopy: !!item.disableNormalizerOnCopy,
    showStatusName: item.showStatusName || false
  }))
}

export const getNormalizer = (item, value, currency) => {
  switch (item.key || item) {
    case 'date':
    case 'slotStartDate':
    case 'slotEndDate':
    case 'startDate':
    case 'endDate':
    case 'createdAt':
    case 'updatedAt':
    case 'expirationDate':
    case 'order_date':
      return value
        ? item.formatTo
          ? formatDate(value, item.formatTo)
          : formatDate(value, DISPLAY_FORMAT)
        : ''
    case 'faceValue':
    case 'price':
      return <Currency value={Number(value || 0)} code={currency} />
    case 'groupItemPrice':
      return <Currency value={Number(value || 0)} symbol={currency} />
    case 'amountItem':
      return <Currency value={Number(value * 100 || 0)} symbol={currency} />
    default:
      return value === 0 ? value : value || ''
  }
}

export const makeRunningTotalLabel = (value = '') => {
  const content = value ? `(${value})` : ''
  const newLine = value ? <br /> : null
  const component = (
    <span>
      Running Total {newLine} {content}
    </span>
  )

  return { value: `Running Total ${content}`, component }
}
