import _get from 'lodash/get'

class ParallelFetchByPagination {
  constructor(fetchFunction, selectPagination = res => _get(res, 'pagination'), maxSimultaneousCount = 6) {
    this.fetchFunction = fetchFunction
    this.selectPagination = selectPagination
    this.maxSimultaneousCount = maxSimultaneousCount
    this.queue = []
    this.index = -1
  }

  fetchChunk = async (_offset, _limit, _total) => {
    this.nextOffset = _offset + _limit
    const res = await this.fetchFunction(_offset, _limit, _total)
    return res
  }

  abort = () => {
    this.aborted = true
  }

  getPaginationInfo = res => {
    const paginationInfo = this.selectPagination(res)
    const { limit, offset, total } = paginationInfo
    paginationInfo.done = offset + limit >= total
    return paginationInfo
  }

  callback = (error, res) => {
    if (!this.aborted) {
      if (error) {
        this.cb(error)
      } else {
        const paginationInfo = this.getPaginationInfo(res)
        this.cb(null, res, paginationInfo)
      }
    }
  }

  preCallback = (error, res, index) => {
    this.queue[index] = { error, res, isSent: false }
    for (let i = 0; i < this.queue.length; ++i) {
      if (!this.queue[i]) {
        break
      }
      if (!this.queue[i].isSent) {
        if (this.queue[i].error) {
          this.callback(this.queue[i].error)
        } else {
          this.callback(null, this.queue[i].res)
        }
        this.queue[i].isSent = true
      }
    }
  }

  callbackManager = async (_offset, limit, index) => {
    if (!this.aborted && !this.done) {
      try {
        this.done = _offset + limit >= this.total
        const res = await this.fetchChunk(_offset, limit, this.total)
        this.preCallback(null, res, index)
      } catch (e) {
        this.preCallback(e, null, index)
      } finally {
        this.callbackManager(this.nextOffset, limit, ++this.index)
      }
    }
  }

  parallelRequestsManager = async () => {
    if (!this.aborted && !this.done) {
      const unFetchedSize = this.total - this.nextOffset
      let stepCount = Math.ceil(unFetchedSize / this.limit)
      stepCount = stepCount > this.maxSimultaneousCount ? this.maxSimultaneousCount : stepCount
      for (let i = 0; i < stepCount; ++i) {
        this.callbackManager(this.nextOffset, this.limit, ++this.index)
      }
    }
  }

  run = async (firstLimit, limit, cb) => {
    this.firstLimit = firstLimit
    this.limit = limit
    this.cb = cb
    try {
      const res = await this.fetchChunk(0, this.firstLimit, this.total)
      const paginationInfo = this.getPaginationInfo(res)
      this.done = paginationInfo.done
      this.total = paginationInfo.total
      this.preCallback(null, res, ++this.index)
      this.parallelRequestsManager()
    } catch (e) {
      this.preCallback(e, null, ++this.index)
    }
  }
}

export { ParallelFetchByPagination }
