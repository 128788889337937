import React from 'react'
import { Field as FormikField } from 'formik'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import CountryField from '../../../../_library/CountryField'
import { default as localCountries } from '../../../../../_common/core/countries'
import { RadioGroupField } from '../../../../formik/Fields'
import { requiredValidator } from '../../../../../_common/core/validation'
import { getCurrenciesFromCountry } from '../../../../utils/coutriesUtils'

export default class CountryCurrencyForm extends React.PureComponent {
  getCurrencies = () => {
    const { globalCountries, values } = this.props

    const selectedCountry = values.country
    const currencies = getCurrenciesFromCountry(globalCountries, selectedCountry)
    const currencyOptions = currencies && currencies.length > 1 ? currencies : []

    return _map(currencyOptions, item => ({ label: item, value: item }))
  }

  render() {
    const { values, touched, errors, onChange, onCurrencyChange, isCurrencyChangeDisabled } = this.props
    const currencyOptions = this.getCurrencies()

    const hasSales = _get(values, 'hasSales')

    return (
      <div>
        <CountryField
          id="country"
          label="Country"
          disabled={hasSales}
          options={localCountries}
          value={values.country}
          error={errors.country}
          touched={touched.country}
          onChange={onChange}
        />
        {!_isEmpty(currencyOptions) && (
          <div
            className={(isCurrencyChangeDisabled || hasSales) ? 'disable-element' : ''}
            style={{ marginTop: -15, marginBottom: 25 }}
          >
            <FormikField
              name="currency.currency"
              component={RadioGroupField}
              groups={currencyOptions}
              validate={requiredValidator()}
              onChange={onCurrencyChange}
              classNames={{
                container: 'radio-group-container',
                container_item: 'radio-group-item',
                component: ''
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
