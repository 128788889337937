import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'
import _map from 'lodash/map'

const initialState = {
  memberships_detail: [],
  membershipList: [],
  memberships: [],
  currentEventId: '',
  isFetch: false,
  membershipCreated: false,
  membershipData: [],
}

const FETCH_MEMBERSHIPS = createAsyncHandlers('FETCH_MEMBERSHIPS', {
  success(state, action) {
    const {
      memberships: { data },
    } = action.payload
    state.memberships = _map(data || [], item => ({ ...item.$original }))
  },
})

const FETCH_MEMBERSHIP_ITEMS = createAsyncHandlers('FETCH_MEMBERSHIP_ITEMS', {
  success(state, action) {
    const {
      membershipItems: { data },
    } = action.payload
    state.membershipItems = data
  },
})

const FETCH_MEMBERSHIP_LIST = createAsyncHandlers('FETCH_MEMBERSHIP_LIST', {
  success(state, action) {
    const { membershipList } = action.payload
    state.membershipList = membershipList
  },
})

const ADD_MEMBERSHIP_VISIT = createAsyncHandlers('ADD_MEMBERSHIP_VISIT', {
  success(state, action) {
    const {
      visitAdded: { data },
    } = action.payload
    state.visitAdded = data
  },
})

const FETCH_MEMBERSHIP_VISITS = createAsyncHandlers('FETCH_MEMBERSHIP_VISITS', {
  success(state, action) {
    const {
      membershipVisits: { data },
    } = action.payload
    state.membershipVisits = data
  },
})

const UPDATE_MEMBERSHIP_VISIT = createAsyncHandlers('UPDATE_MEMBERSHIP_VISIT', {
  success(state, action) {
    const {
      visitUpdated: { data },
    } = action.payload
    state.visitUpdated = data
  },
})

const FETCH_MEMBERSHIPS_STATISTICS = createAsyncHandlers('FETCH_MEMBERSHIPS_STATISTICS', {
  success(state, action) {
    const { eid, memberships } = action.payload
    state.memberships = memberships
    state.isFetch = true
    state.currentEventId = eid
  },
})

const CREATE_MEMBERSHIP = createAsyncHandlers('CREATE_MEMBERSHIP', {
  success(state, action) {
    const { data } = action.payload
    state.membershipCreated = data
  },
})

const UPDATE_MEMBERSHIP = createAsyncHandlers('UPDATE_MEMBERSHIP', {
  success(state, action) {
    const { data } = action.payload
    state.membershipData = data
  },
})

const GET_MEMBERSHIP = createAsyncHandlers('GET_MEMBERSHIP', {
  success(state, action) {
    const { data } = action.payload
    state.membershipData = data
  },
})

export default handleActions(
  {
    ...FETCH_MEMBERSHIPS,
    ...FETCH_MEMBERSHIP_ITEMS,
    ...FETCH_MEMBERSHIP_LIST,
    ...ADD_MEMBERSHIP_VISIT,
    ...FETCH_MEMBERSHIP_VISITS,
    ...UPDATE_MEMBERSHIP_VISIT,
    ...FETCH_MEMBERSHIPS_STATISTICS,
    ...CREATE_MEMBERSHIP,
    ...GET_MEMBERSHIP,
    ...UPDATE_MEMBERSHIP,
  },
  initialState,
)
