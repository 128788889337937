import React from 'react'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _entries from 'lodash/entries'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Notifications from '../../_library/notifications/Notifications'
import VideoBackgroundComponent from '../../_library/VideoBgComponent'
import SignupForm from './SignupForm'
import { REGISTER, LOGIN, ACCEPT_TEAM_INVITATION } from '../../../_common/redux/auth/actions'
import ConfirmModal from '../../_library/ConfirmModal'
import LoadingBar from '../../_library/LoadingBar'
import { LoginSection } from '.'
import {
  getStripeCountries,
  getStripeCountryStates,
  checkExistsCustomer,
  getCustomerProfile
} from '../../../_common/core/http_services'
import queryString from "query-string"
import { isEmail } from '../../../_common/core/validation'
import { getTitle } from '../../utils/getTitle'
import { deleteCookieByName } from '../../utils/cookies'

@withRouter
@connect(
  null,
  {
    REGISTER,
    LOGIN,
    ACCEPT_TEAM_INVITATION
  }
)
class SignUpPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isConfirmModalOpen: false,
      showLoginSection: false,
      isLoginSuccess: false,
      isLoadingCountries: true,
      isLoadingCountryStates: false,
      isStatesFieldDisabled: true,
      countries: [],
      countryStates: [],
      checkedCustomerProfile: false,
      initialValues: null,
      emailExists: false
    }
  }

  componentWillMount() {
    dataLayerInit()
  }

  async componentDidMount() {
    this.fetchStripeCountries()
    runGtagScript()

    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
    this.checkCustomerProfile()
  }

  checkCustomerProfile = async (_profile, usernamePassword) => {
    const {
      history: { push },
      LOGIN
    } = this.props
    try {
      let profile = _profile
      if (!profile) {
        profile = await getCustomerProfile()
      }
      if (profile.hasDashboardAccess) {
        if (usernamePassword) {
          await LOGIN(usernamePassword)
          push('/')
        } else {
          push({
            pathname: '/signin',
            state: { email: profile.email }
          })
        }
      } else {
        this.setState(() => {
          const { firstName, lastName, email, country, city, company, stateId } = profile
          const initialValues = {
            firstName,
            lastName,
            email,
            country,
            stateId,
            city,
            company
          }
          if (country) {
            this.fetchStripeCountryStates(country)
          }
          return {
            initialValues,
            showLoginSection: false,
            checkedCustomerProfile: true,
            emailExists: true
          }
        })
      }
    } catch (e) {
      this.setState(() => ({
        checkedCustomerProfile: true
      }))
    }
  }

  fetchStripeCountries = async () => {
    try {
      const countries = await getStripeCountries()
      deleteCookieByName('X-TF-ECOMMERCE')
      this.setState(() => ({
        isLoadingCountries: false,
        countries: _sortBy(
          _map(_entries(countries), ([key, value]) => ({
            value: String(key),
            label: value
          })),
          'label'
        )
      }))
    } catch (error) {
      this.setState(() => ({
        isLoadingCountries: false
      }))
    }
  }

  fetchStripeCountryStates = async countryId => {
    this.setState(() => ({
      isLoadingCountryStates: true
    }))
    try {
      const states = await getStripeCountryStates(countryId)
      let statesOptions = _map(_entries(states, {}), ([value, label]) => ({
        value: String(value),
        label
      }))

      statesOptions = _sortBy(statesOptions, 'label')

      this.setState(() => ({
        isLoadingCountryStates: false,
        isStatesFieldDisabled: !statesOptions.length,
        countryStates: statesOptions
      }))
    } catch (error) {
      this.setState(() => ({
        isLoadingCountryStates: false
      }))
    }
  }

  handleSubmit = async form => {
    const { REGISTER, history: { push }, ACCEPT_TEAM_INVITATION } = this.props
    const urlParams = this.getUrlParams()

    const { country, confirmPassword, ...rest } = form
    const { token } = queryString.parse(window.location.search)


    try {
      if (token) {
        const body = {
          attributes: {
            ...rest,
            country,
            confirmPassword,
            token
          }
        }
        try {
          await ACCEPT_TEAM_INVITATION(body)
        } catch(e) {
          return push(`/team-invitations?token=${token}`)
        }
      } else {
        await REGISTER({
          ...rest,
          ...urlParams,
          countryId: country
        })
      }
      this.setState(() => ({
        isLoginSuccess: true
      }))
    } catch (error) {
      const errMessage = error.errors && error.errors.length ? error.errors[0].details : null
      if (errMessage === 'The email already used') {
        this.openConfirmModal(true)
      }
    }
  }

  getUrlParams = () => {
    const urlParamsList = window.location.search
    const urlParams = new URLSearchParams(urlParamsList)

    return {
      utmSource: urlParams.get('utm_source'),
      utmMedium: urlParams.get('utm_medium'),
      utmCampaign: urlParams.get('utm_campaign'),
      utmTerm: urlParams.get('utm_term'),
      utmContent: urlParams.get('utm_content')
    }
  }

  onConfirmResponse = response => {
    const { history: { push } } = this.props
    switch (response) {
    case 'login': {
      push('/signin')
      break
    }
    case 'cancel': {
      break
    }
    }
    this.openConfirmModal(false)
  }

  openConfirmModal = (isConfirmModalOpen = !this.state.isConfirmModalOpen) => {
    this.setState(() => ({
      isConfirmModalOpen,
      isLoginSuccess: false
    }))
  }

  onBlurEmail = async value => {
    try {
      if (isEmail(value)) {
        this.setState(() => ({ emailExists: false }))
        const isExist = await checkExistsCustomer(value)
        if (isExist) {
          this.setState(() => ({
            showLoginSection: true,
            emailExists: true,
            email: value
          }))
        }
      }
    } catch(e) {
      this.setState(() => ({ emailExists: false }))
    }
  }

  onCloseLoginSection = async () => {
    this.setState(() => ({
      showLoginSection: false,
      emailExists: false
    }))
  }

  render() {
    const {
      isConfirmModalOpen,
      showLoginSection,
      isLoginSuccess,
      countries,
      countryStates,
      isLoadingCountries,
      isLoadingCountryStates,
      isStatesFieldDisabled,
      checkedCustomerProfile,
      initialValues,
      emailExists,
      email
    } = this.state
    const { token, email: emailPrefilled } = queryString.parse(window.location.search)

    if (!checkedCustomerProfile) {
      return (
        <div className="col-xs-12" style={{ marginLeft: 10 }}>
          <LoadingBar />
        </div>
      )
    }

    return (
      <div className="signup-page">
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          header="Warning"
          content="There is an account with this email."
          actions={[
            { value: 'login', label: 'Go To Login Page', className: 'btn btn btn-seablue' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' }
          ]}
          onAction={this.onConfirmResponse}
          classNames={{
            modal: 'modal-dialog modal-trans modal-medium modal-small',
            container: 'modal-dialog modal-medium modal-small'
          }}
        />
        <ConfirmModal
          isOpen={isLoginSuccess}
          header="Success"
          content="Your account successfully created."
          actions={[{ value: 'login', label: `Login as ${token ? "a Promoter" : "an Event Organizer"}`, className: 'btn btn btn-success' }]}
          onAction={this.onConfirmResponse}
          classNames={{
            modal: 'modal-dialog modal-trans modal-medium modal-small',
            container: 'modal-dialog modal-medium modal-small',
            modal_footer_toolbar: 'btn-toolbar btn-toolbar-right-center'
          }}
          style={{
            content: {
              top: '18%'
            }
          }}
        />
        <VideoBackgroundComponent />
        <Notifications />
        {showLoginSection ? (
          <LoginSection
            onClose={this.onCloseLoginSection}
            email={email}
            checkCustomerProfile={this.checkCustomerProfile}
          />
        ) : (
          <SignupForm
            handleSubmit={this.handleSubmit}
            initialValues={initialValues}
            countries={countries}
            countryStates={countryStates}
            fetchStripeCountryStates={this.fetchStripeCountryStates}
            isLoadingCountries={isLoadingCountries}
            isLoadingCountryStates={isLoadingCountryStates}
            isStatesFieldDisabled={isStatesFieldDisabled}
            emailExists={emailExists}
            onBlurEmail={this.onBlurEmail}
            emailPrefilled={emailPrefilled}
          />
        )}
      </div>
    )
  }
}

export { SignUpPage }
