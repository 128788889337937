import _ from 'lodash'
import { arrayToObject, isDateRangeInvalid, isDateRangeInvalid2, isDateRangeInvalid3 } from './utils'
import countries, { countryName } from '../../../../_common/core/countries'
import { fileSizeLimitValidator, isValidInteger } from '../../../../_common/core/validation'
import { countryCodeAdapter } from '../../../utils/coutriesUtils'
import { PublicKey } from '@solana/web3.js'
import moment from 'moment'

const shortNameRE = /[<>'\"]+/
const slugRE = /^[\w-]*$/

export function validateDupEvent(data) {
  const displayNameLength = data.isRnDomain ? 50 : 65
  const errors = {}
  if (_.get(data, 'displayName', '').length > displayNameLength) {
    _.set(errors, 'displayName', `${displayNameLength} characters maximum`)
  }
  if (!data.isRnDomain) {
    if (_.get(data, 'shortName')) {
      const shortName = _.get(data, 'shortName', '')
      if (shortName && shortName.length > 17) {
        _.set(errors, 'shortName', 'Maximum 17 characters')
      } else {
        if (shortNameRE.test(shortName))
          _.set(errors, 'shortName', 'Short Event Name are not allowed to use the symbols (>, <, \', ")')
      }
    }
  }
  const slug = _.get(data, 'slug', '')
  if (!slugRE.test(slug)) {
    _.set(
      errors,
      'slug',
      'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)'
    )
  }
  return errors
}

export function validateEvent(data, props) {
  let required = ['displayName', 'slug', 'owner', 'venueName', 'country']
  const countriesByName = _.keyBy(countries, c => _.get(c, 'name.common'))
  const displayNameLength = data.isRnDomain ? 50 : 65
  const errors = {}
  if (data.isRnDomain) {
    required = required.concat(['city', 'state', 'postalCode', 'region'])
  }
  if (!data.isRnDomain) {
    required = required.concat(['shortName'])
  }

  required.forEach(f => {
    if (!_.get(data, f)) {
      _.set(errors, f, 'Required')
    }
  })

  const descriptions = _.get(data, 'descriptions')
  if ((!descriptions || (!!descriptions && !descriptions.en)) && data.isRnDomain) {
    _.set(errors, 'descriptions', 'This field is required.')
  }

  if (!errors.country && !_.has(countriesByName, data.country)) {
    errors.country = 'This country is not supported'
  }

  if (_.get(data, 'displayName', '').length > displayNameLength) {
    _.set(errors, 'displayName', `${displayNameLength} characters maximum`)
  }

  if (data.isRnDomain) {
    const eventGenres = _.get(data, 'eventGenres') || {}
    const eventCategory = _.get(data, 'eventCategory') || {}
    const externalUrl = _.get(data, 'externalUrl')

    if (!Object.values(eventGenres).some(_.identity) && !Object.values(eventCategory).some(_.identity)) {
      _.set(errors, 'oneOfTags', 'You must apply at least one of Event Genres or Event Categories')
    }

    if (externalUrl) {
      if (!/^(https?:\/\/)/i.test(externalUrl)) _.set(errors, 'externalUrl', 'Invalid URL')
    }
  }

  if (_.get(data, 'eventType')) {
    let eventType = _.get(data, 'eventType')
    eventType = arrayToObject(eventType)
    const strEventType = JSON.stringify(eventType)
    if (strEventType.indexOf('true') === -1) {
      _.set(errors, 'eventType', 'Please select at least one event type.')
    }
  } else {
    _.set(errors, 'eventType', 'Please select at least one event type')
  }

  if (!data.isRnDomain) {
    if (_.get(data, 'shortName')) {
      const shortName = _.get(data, 'shortName', '')
      if (shortName && shortName.length > 17) {
        _.set(errors, 'shortName', 'Maximum 17 characters')
      } else {
        if (shortNameRE.test(shortName))
          _.set(errors, 'shortName', 'Short Event Name are not allowed to use the symbols (>, <, \', ")')
      }
    }
  }

  if (_.get(data, 'facebookEvent')) {
    const fbURL = _.get(data, 'facebookEvent', '')
    if (!/^(?:https?:\/\/)?(?:www.)?(?:m.)?facebook.com\/events\/+/i.test(fbURL))
      _.set(errors, 'facebookEvent', 'Invalid Facebook URL')
  }

  const slug = _.get(data, 'slug', '')
  if (!slugRE.test(slug)) {
    _.set(
      errors,
      'slug',
      'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)'
    )
  }

  const startDate = _.get(data, 'startDate')
  const endDate = _.get(data, 'endDate')
  const checkInStart = _.get(data, 'checkInStart')
  const checkInEnd = _.get(data, 'checkInEnd')
  if (!startDate) {
    _.set(errors, 'startDate', 'Required')
  }
  if (!endDate) {
    _.set(errors, 'endDate', 'Required')
  }
  if (_.get(data, 'endDate') && _.get(data, 'startDate') && isDateRangeInvalid(startDate, endDate)) {
    _.set(errors, 'endDate', 'End date must be after start date')
  }

  if (_.get(data, 'checkInStart') && !_.get(data, 'checkInEnd')) {
    _.set(errors, 'checkInEnd', 'Required')
  }

  if (!_.get(data, 'checkInStart') && _.get(data, 'checkInEnd')) {
    _.set(errors, 'checkInStart', 'Required')
  }

  if (
    _.get(data, 'checkInStart') &&
    _.get(data, 'startDate') &&
    isDateRangeInvalid3(checkInStart, startDate)
  ) {
    _.set(errors, 'checkInStart', 'Doors open time must be earlier than start date')
  }

  if (_.get(data, 'checkInEnd') && _.get(data, 'startDate') && isDateRangeInvalid(startDate, checkInEnd)) {
    _.set(errors, 'checkInEnd', 'Last entry time must be after start date')
  }

  if (_.get(data, 'checkInEnd') && _.get(data, 'endDate') && isDateRangeInvalid3(checkInEnd, endDate)) {
    _.set(errors, 'checkInEnd', 'Doors close time must be earlier than end date')
  }

  if (!_.get(data, 'salesEndDate')) {
    _.set(errors, 'salesEndDate', 'Required')
  }

  if (_.get(data, 'salesEndDate') && _.get(data, 'endDate')) {
    const isSalesEndDateAfterEventEndDate = moment(_.get(data, 'salesEndDate')).isAfter(moment(_.get(data, 'endDate')))
    if (isSalesEndDateAfterEventEndDate) {
      _.set(errors, 'salesEndDate', 'Sales end date must be before event end date')
    }
  }

  if (_.get(data, 'salesStartDate') && _.get(data, 'startDate')) {
    const isSalesStartDateAfterEventStartDate = moment(_.get(data, 'salesStartDate')).isAfter(moment(_.get(data, 'startDate')))
    if (isSalesStartDateAfterEventStartDate) {
      _.set(errors, 'salesStartDate', 'Sales start date must be before event start date')
    }
  }

  const salesStartDate = _.get(data, 'salesStartDate')
  const salesEndDate = _.get(data, 'salesEndDate')
  if (isDateRangeInvalid2(salesStartDate, salesEndDate)) {
    _.set(errors, 'salesEndDate', 'Sales end date must be after sales start date')
  }

  const ogImage = _.get(data, 'ogImage')
  if (fileSizeLimitValidator()(ogImage)) {
    _.set(errors, 'ogImage', fileSizeLimitValidator()(ogImage))
  }

  const twitterImage = _.get(data, 'twitterImage')
  if (fileSizeLimitValidator()(twitterImage)) {
    _.set(errors, 'twitterImage', fileSizeLimitValidator()(twitterImage))
  }

  const imageURLs = _.get(data, 'imageURLs')
  _.forEach(imageURLs, img => {
    if (fileSizeLimitValidator()(img)) {
      _.set(errors, 'imageURLs', fileSizeLimitValidator()(img))
    }
  })

  const backgroundURL = _.get(data, 'backgroundURL')
  if (fileSizeLimitValidator()(backgroundURL)) {
    _.set(errors, 'backgroundURL', fileSizeLimitValidator()(backgroundURL))
  }

  const titleReplacementImage = _.get(data, 'titleReplacementImage')
  if (fileSizeLimitValidator()(titleReplacementImage)) {
    _.set(errors, 'titleReplacementImage', fileSizeLimitValidator()(titleReplacementImage))
  }

  const {
    accountNumber,
    swiftCode,
    accountType,
    countryCode,
    flagPaymentDetails,
    accountName,
    routingNumber,
    bsb,
    sortCode,
    ifscNumber,
    bankName,
    clabe,
    fullAddress,
    country,
    transitNumber,
    institutionNumber
  } = data

  const brandCountry = countryCodeAdapter(props.brand?.country, 'uk', 'gb') || ''
  const isSameCountry = countryName(brandCountry) === data.country
  const swiftRegex = RegExp('^([0-9a-zA-Z]*)$')

  if (flagPaymentDetails) {
    if (!isSameCountry && !countryCode) {
      _.set(errors, 'countryCode', 'You must enter country')
    }
    if ((!isSameCountry && countryCode === 'MX') || (isSameCountry && country === 'Mexico')) {
      if (accountNumber && (!isValidInteger(accountNumber) || accountNumber.length !== 18)) {
        _.set(errors, 'accountNumber', 'Account Number must contain 18 digits')
      }
      if (accountNumber && !swiftCode) {
        _.set(errors, 'swiftCode', 'SWIFT Code is required')
      }
      if (accountNumber && !clabe) {
        _.set(errors, 'clabe', 'CLABE Code is required')
      }
      if (accountNumber && clabe && (!isValidInteger(clabe) || clabe.length !== 18)) {
        _.set(errors, 'clabe', 'CLABE must contain 18 digits')
      }
      if (accountNumber && !fullAddress) {
        _.set(errors, 'fullAddress', 'Address is required')
      }
      if (accountNumber && swiftCode && !swiftRegex.test(swiftCode)) {
        _.set(errors, 'swiftCode', 'SWIFT Code can only be alphanumeric')
      }
      if (accountNumber && swiftCode && (swiftCode.length < 8 || swiftCode.length > 11)) {
        _.set(errors, 'swiftCode', 'SWIFT Code must contain 8 to 11 characters')
      }
    }
    if ((!isSameCountry && countryCode === 'US') || (isSameCountry && country === 'United States')) {
      if (!accountType) {
        _.set(errors, 'accountType', 'You must enter account type')
      }
    }
    if ((!isSameCountry && countryCode === 'AU') || (isSameCountry && country === 'Australia')) {
      if (bsb && (!isValidInteger(bsb) || bsb.length !== 6)) {
        _.set(errors, 'bsb', 'BSB should be 6 digits')
      }
    }
    if ((!isSameCountry && countryCode === 'GB') || (isSameCountry && country === 'United Kingdom')) {
      if (sortCode && !/^\d{2}-\d{2}-\d{2}$/.test(sortCode)) {
        _.set(errors, 'sortCode', 'SORT Code must contain 6 pair digits e.g: 12-34-56')
      }
    }
    if ((!isSameCountry && countryCode === 'IN') || (isSameCountry && country === 'India')) {
      if (ifscNumber && !swiftRegex.test(ifscNumber)) {
        _.set(errors, 'ifscNumber', 'IFSC Code can only be alphanumeric')
      }
      if (ifscNumber && ifscNumber.length !== 11) {
        _.set(errors, 'ifscNumber', 'IFSC Number should be 11 alphanumeric characters')
      }
    }
    if ((!isSameCountry && countryCode === 'CA') || (isSameCountry && country === 'Canada')) {
      if (transitNumber && (!isValidInteger(transitNumber, false) || String(transitNumber).length !== 5)) {
        _.set(errors, 'transitNumber', 'Transit Number should be 5 digits')
      }
      if (
        institutionNumber &&
        (!isValidInteger(institutionNumber, false) || String(institutionNumber).length !== 3)
      ) {
        _.set(errors, 'institutionNumber', 'Institution Number should be 3 digits')
      }
    }
  }
  if (_.get(data, 'flagNftOwnershipRequired')) {
    const ethereum = _.get(data, 'nftContracts.ethereum')
    const polygon = _.get(data, 'nftContracts.polygon')
    const mainnet = _.get(data, 'nftLists.solana-mainnet')
    if (ethereum.length === 0 && polygon.length === 0 && mainnet.length === 0) {
      _.set(errors, 'ethereum', 'At least 1 NFT address is required')
      _.set(errors, 'polygon', 'At least 1 NFT address is required')
      _.set(errors, 'mainnet', 'At least 1 NFT address is required')
    }
    const re = global.NFTRegex
    for (const em of ethereum) {
      if (!re.test(em)) {
        _.set(errors, 'ethereum', 'Invalid')
      }
    }
    for (const em of polygon) {
      if (!re.test(em)) {
        _.set(errors, 'polygon', 'Invalid')
      }
    }
    for (const em of mainnet) {
      try {
        const address = new PublicKey(em)
        const isValid = PublicKey.isOnCurve(address.toBuffer())
        if (!isValid) _.set(errors, 'mainnet', 'Invalid')
      } catch (e) {
        _.set(errors, 'mainnet', 'Invalid')
      }
    }
    if ((ethereum.length || polygon.length) && mainnet.length) {
      _.set(errors, 'nfts', 'Ethereum/Polygon combination with Solana Mainnet is not allowed')
    }
  }
  return errors
}

export const validateAlternativeEvent = data => {
  const errors = {}

  const slugCheck = _.get(data, 'slugCheck')
  const dateCheck = _.get(data, 'dateCheck')
  const venueDetailsCheck = _.get(data, 'venueDetailsCheck')
  const imageURLs = _.get(data, 'imageURLs')
  const titleReplacementImage = _.get(data, 'titleReplacementImage')

  if (venueDetailsCheck) {
    const countriesByName = _.keyBy(countries, c => _.get(c, 'name.common'))
    if (!errors.country && !_.has(countriesByName, data.country)) {
      errors.country = 'This country is not supported'
    }
  }

  if (slugCheck) {
    const slug = _.get(data, 'slug', '')
    if (!slugRE.test(slug)) {
      _.set(
        errors,
        'slug',
        'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)'
      )
    }
  }

  if (dateCheck) {
    const startDate = _.get(data, 'startDate')
    const endDate = _.get(data, 'endDate')
    if (isDateRangeInvalid(startDate, endDate)) {
      _.set(errors, 'endDate', 'End date must be after start date')
    }
  }

  _.forEach(imageURLs, img => {
    if (fileSizeLimitValidator()(img)) {
      _.set(errors, 'imageURLs', fileSizeLimitValidator()(img))
    }
  })

  if (fileSizeLimitValidator()(titleReplacementImage)) {
    _.set(errors, 'titleReplacementImage', fileSizeLimitValidator()(titleReplacementImage))
  }

  return errors
}
