import createAction from 'redux-actions/lib/createAction'
import handleActions from 'redux-actions/lib/handleActions'
import { produce } from 'immer'
import { CLEAR } from './notification/actions'

const LOADING_START = createAction('async/LOADING_START')
const LOADING_END = createAction('async/LOADING_END')

export function createAsyncAction(name, worker) {
  const start = `${name}_START`
  const success = `${name}_SUCCESS`
  const fail = `${name}_FAIL`

  const stages = {
    start: createAction(start),
    success: createAction(success),
    failed: createAction(fail),
  }

  return {
    [start]: stages.start,
    [success]: stages.success,
    [fail]: stages.failed,
    [name](...args) {
      return dispatch => {
        dispatch(LOADING_START(name))

        return Promise.resolve(worker.apply(stages, args)(dispatch))
          .catch(function () {
            dispatch(LOADING_END(name))
            return Promise.reject.apply(Promise, arguments)
          })
          .then(function () {
            dispatch(LOADING_END(name))
            setTimeout(() => dispatch(CLEAR()), 10000)
            return Promise.resolve.apply(Promise, arguments)
          })
      }
    },
  }
}

export function createAsyncHandlers(name, { start, failed, success }) {
  return {
    [`${name}_START`]: produce((state, action) => {
      if (start) {
        start(state, action)
      }
    }),
    [`${name}_FAIL`]: produce((state, action) => {
      if (failed) {
        failed(state, action)
      }
    }),
    [`${name}_SUCCESS`]: produce((state, action) => {
      if (success) {
        success(state, action)
      }
    }),
  }
}

export const loadingReducer = handleActions(
  {
    'async/LOADING_START': produce((state, action) => {
      state.add(action.payload)
    }),
    'async/LOADING_END': produce((state, action) => {
      state.delete(action.payload)
    }),
  },
  new Set(),
)
