import React from 'react'
import LoadingBar from '../../_library/LoadingBar'
import EmptyBar from '../../_library/EmptyBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import CardHeader from '../../_library/CardHeader'
import Card from '../../_library/Card'
import { Map, MapNarrator } from '../../_library/Map'
import { NumberOfBuyers } from '../geographics/NumberOfBuyers'
import { TableWithHeader } from '../geographics/TableWithHeader'
import { connect } from 'react-redux'
import { FETCH_EVENT_TRAFFIC } from '../../../_common/redux/performance/actions'
import { LocationRow } from '../geographics/LocationRow'
import { BuyersCell } from '../geographics/BuyersCell'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import { createRef } from 'react'
import scrollToComponent from 'react-scroll-to-component'
import { showAxiosError } from '../../utils/messenger'
import moment from 'moment'
import { defaultDateFormat } from '../../../_common/core/validation/normalizers'
import { DATE_TRAFFIC_SUPPORT } from '../performance/constants/constants'

@connect(null, { FETCH_EVENT_TRAFFIC })
export default class TrafficViews extends React.PureComponent {
  constructor(props) {
    super(props)
    this.citiesTableColumns = getTableColumns([
      'city',
      { label: 'No. of Visitors', key: 'numVisitors', className: 'pos-right' }
    ])
    this.countriesTableColumns = getTableColumns([
      'country',
      { label: 'No. of Visitors', key: 'numVisitors', className: 'pos-right' }
    ])
    this.regionsTableColumns = getTableColumns([
      'region',
      { label: 'No. of Visitors', key: 'numVisitors', className: 'pos-right' }
    ])
    this.state = {
      unique: [],
      isLoading: true,
      moveMapTo: [],
      showMore: {
        topCities: false,
        topRegions: false,
        topCountries: false,
      },
    }

    this.mounted = true
    this.mapRef = createRef()
    this.trafficInterval = null
  }

  componentDidMount() {
    this.fetchTraffic()
    this.trafficInterval = setInterval(() => {
      this.fetchTraffic()
    }, 600 * 1000)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.trafficInterval)
  }

  fetchTraffic = async () => {
    const { FETCH_EVENT_TRAFFIC, event, showWarning, setShowWarning } = this.props
    const { isLoading, unique: stateUnique } = this.props
    if (!this.mounted) return
    try {
      const unique_users = await FETCH_EVENT_TRAFFIC(event.id, { group_by: ['unique', 'location'] })
      const unique = _map(_groupBy(unique_users, "location.city"), ([firstUser, ...users]) => {
        firstUser.total = users.length + 1
        return firstUser
      }) ?? []
      if (_isEqual(unique, stateUnique) && !isLoading) {
        return
      }
      if (!showWarning) {
        if (unique.find(row => 
          moment(row.created_at, defaultDateFormat).isBefore(moment(DATE_TRAFFIC_SUPPORT, defaultDateFormat))
        )) {
          setShowWarning()
        }
      }
      this.setState({
        unique,
        isLoading: false
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  onLocationIconClick = (positions, narrator) => {
    scrollToComponent(this.mapRef.current)
    this.setState({
      moveMapTo: [{ lat: Number(positions.latitude), lng: Number(positions.longitude) }],
      narrator
    })
  }

  onShowMoreCb = id => {
    this.setState({
      showMore : { ...this.state.showMore, [id]: true }
    })
  }

  render() {
    const { event } = this.props
    const { isLoading, unique, moveMapTo, narrator, showMore } = this.state

    const { venue } = event

    const { markers, citiesData, citiesDataForCopy } = unique.reduce((curr, row) => {
      curr.markers.push({
        city: row.location.city,
        country: row.location.country,
        lat: row.location.latitude,
        lng: row.location.longitude
      })
      curr.citiesData.push({
        cityName: row.location.city,
        totalVisitors: row.total,
        city: (
          <LocationRow
            value={row.location.city ? row.location.city : "N/A"}
            onClick={() => this.onLocationIconClick(row.location, row.location.city)}
          />
        ),
        numVisitors: <BuyersCell value={row.total} />
      })
      curr.citiesDataForCopy.push({
        city: row.location.city ? row.location.city : "N/A",
        numVisitors: row.total
      })
      return curr
    }, { markers: [], citiesData: [], citiesDataForCopy: [] })

    const groupedByCountry = _groupBy(unique, row => row.location.country ? row.location.country : 'N/A')

    const { countriesData, countriesDataForCopy } = Object.entries(groupedByCountry).reduce((curr, keyValue) => {
      const total = keyValue[1].reduce((curr, next) => curr + Number(next.total), 0)
      curr.countriesDataForCopy.push({
        country: keyValue[0],
        numVisitors: total
      })
      curr.countriesData.push({
        countryName: keyValue[0],
        totalVisitors: total,
        country: (
          <LocationRow
            value={keyValue[0]}
            onClick={() => this.onLocationIconClick(keyValue[1][0].location, keyValue[1][0].location.country)}
          />
        ),
        numVisitors: <BuyersCell value={total} />
      })
      return curr
    }, { countriesDataForCopy: [], countriesData: [] })

    const groupedByRegion = _groupBy(unique, row => row.location.region ? row.location.region : 'N/A')

    const { regionsData, regionsDataForCopy } = Object.entries(groupedByRegion).reduce((curr, keyValue) => {
      const total = keyValue[1].reduce((curr, next) => curr + Number(next.total), 0)
      curr.regionsDataForCopy.push({
        region: keyValue[0],
        numVisitors: total
      })
      curr.regionsData.push({
        regionName: keyValue[0],
        totalVisitors: total,
        region: (
          <LocationRow
            value={keyValue[0]}
            onClick={() => this.onLocationIconClick(keyValue[1][0].location, keyValue[1][0].location.region)}
          />
        ),
        numVisitors: <BuyersCell value={total} />
      })
      return curr
    }, { regionsDataForCopy: [], regionsData: [] })

    return (
      <div ref="cardContainer" className="performance-sales">
        <CardHeader
          imageUrl="/resources/images/event/performance/ticket-sales-ico.svg"
          bodyText="Locations"
        />
        <div className="table-background">
          <div className="buyerlocation">
            <Card icon={'fa-globe'} title={'Visitor Locations'}>
              <div className="map">
                <Map
                  ref={this.mapRef}
                  placeEventMarkersOnce={true}
                  clearMarkersBeforeRender={true}
                  locations={[venue]}
                  markers={markers}
                  moveMapTo={moveMapTo}
                  hasAutoFocus
                  includeEventLocationsInAutoFocus
                />
                <MapNarrator ref="mapNarrator" text={narrator} />
              </div>
              <NumberOfBuyers label="No. of Visitors" />
            </Card>
            <Card icon={'fa-bar-chart'} title={'Top Visitor Locations'}>
              {isLoading ? (
                <LoadingBar title={"Hold tight! We're getting your statistics..."} />
              ) : unique.length === 0 ? (
                <EmptyBar />
              ) : (
                <div className="row">
                  <TableWithHeader
                    header="Top Cities"
                    icon="fa fa-map-marker grey"
                    tableClass="small-table"
                    rowTableClass="small-table"
                    data={citiesData.sort((a, b) => b.totalVisitors - a.totalVisitors)}
                    dataForCopy={citiesDataForCopy.sort((a, b) => b.numVisitors - a.numVisitors)}
                    tableColumns={this.citiesTableColumns}
                    hideRows={true}
                    showMore={showMore}
                    onShowMoreCb={this.onShowMoreCb}
                    id={'topCities'}
                  />
                  <TableWithHeader
                    header="Top Regions"
                    icon="fa fa-thumb-tack grey"
                    tableClass="small-table"
                    rowTableClass="small-table"
                    data={regionsData.sort((a, b) => b.totalVisitors - a.totalVisitors)}
                    dataForCopy={regionsDataForCopy.sort((a, b) => b.numVisitors - a.numVisitors)}
                    tableColumns={this.regionsTableColumns}
                    hideRows={true}
                    showMore={showMore}
                    onShowMoreCb={this.onShowMoreCb}
                    id={'topRegions'}
                  />
                  <TableWithHeader
                    header="Top Countries"
                    icon="fa fa fa-globe grey"
                    tableClass="small-table"
                    rowTableClass="small-table"
                    data={countriesData.sort((a, b) => b.totalVisitors - a.totalVisitors)}
                    dataForCopy={countriesDataForCopy.sort((a, b) => b.numVisitors - a.numVisitors)}
                    tableColumns={this.countriesTableColumns}
                    hideRows={true}
                    showMore={showMore}
                    onShowMoreCb={this.onShowMoreCb}
                    id={'topCountries'}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    )
  }
}
