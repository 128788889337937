import React from 'react'
import _get from 'lodash/get'
import NotFoundPage from '../_library/NotFoundPage'
import store from '../../_common/redux/store'

const tourHideIfRnHOC = C => props => {
  const globalStates = store.getState()
  const isRnDomain = _get(globalStates, 'shared.isRnDomain')

  if (isRnDomain) {
    return <NotFoundPage />
  }

  return <C {...props} />
}

export { tourHideIfRnHOC }
