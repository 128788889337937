import React from 'react'
import _result from 'lodash/result'
import _find from 'lodash/find'
import _get from 'lodash/get'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmTeamInvitationForm from './ConfirmTeamInvitationForm'
import Notifications from '../_library/notifications/Notifications'
import {
  CHECK_TEAM_INVITATION_HASH,
  ACCEPT_TEAM_INVITATION,
  LOGOUT,
  LOGIN,
} from '../../_common/redux/auth/actions'
import countries from 'world-countries'
import { checkExistsCustomer } from '../../_common/core/http_services'
import ConfirmModal from '../_library/ConfirmModal'
import { getTitle } from '../utils/getTitle'
import { Background } from '../_library/Background'
import { showAxiosError } from '../utils/messenger'

@withRouter
@connect(
  state => ({
    user: state.auth.user,
  }),
  { CHECK_TEAM_INVITATION_HASH, ACCEPT_TEAM_INVITATION, LOGOUT, LOGIN },
)
export default class ConfirmTeamInvitationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tokenValid: false,
      tokenValidating: true,
      isNewCustomer: true,
      tokenText: 'Token is invalid',
      showModal: false,
      currentEmail: '',
      directEmail: '',
      redirectTeamSignUp: false,
      extraFields: [],
    }
    const configDocTitle = _get(props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkTeamInitiationHash()
    }, 1000)
  }

  async checkTeamInitiationHash() {
    try {
      const {
        CHECK_TEAM_INVITATION_HASH,
        ACCEPT_TEAM_INVITATION,
        location,
        user,
        history: { push },
      } = this.props
      const token = queryString.parse(location.search)
      const payload = await CHECK_TEAM_INVITATION_HASH(token)
      const type = token.token?.length < 11 ? 'generic' : 'direct'
      const status = payload?.data?.invitation?.status
      const invitationEmail = payload?.data?.invitation?.email?.toLowerCase()
      const userEmail = user?.email?.toLowerCase()
      if (status === 'accepted') {
        throw new Error('Already accepted')
      }
      if (status === 'disabled') {
        throw new Error('Disabled token')
      }
      const showModalObj =
        userEmail && invitationEmail && userEmail !== invitationEmail && type === 'direct'
          ? {
            showModal: true,
            directEmail: invitationEmail,
            currentEmail: userEmail,
          }
          : {}
      const isGeneric = userEmail && type === 'generic'
      if (isGeneric) {
        const body = {
          attributes: {
            firstName: user.firstName,
            lastName: user.lastName,
            country: 1,
            email: userEmail,
            token: token.token,
          },
        }
        await ACCEPT_TEAM_INVITATION(body)
        this.setState({ tokenText: 'Invitation accepted', tokenValid: false, tokenValidating: false }, () => {
          setTimeout(() => {
            push('/')
          }, 5000)
        })
        return
      }
      this.setState(
        {
          ...showModalObj,
          tokenValidating: false,
          tokenValid: true,
          isNewCustomer: payload.data.isNewCustomer,
          extraFields: payload?.data?.extraFields ?? [],
        },
        () => {
          const { directEmail, currentEmail, isNewCustomer } = this.state
          if (directEmail === currentEmail && !isNewCustomer) {
            this.handleSubmit({ attributes: {} })
          }
        },
      )
    } catch (e) {
      const error = _get(e, 'errors[0].details')
      this.setState({
        tokenValidating: false,
        tokenValid: false,
        tokenText: (() => {
          if (error) {
            return error
          }
          switch (e.message) {
            case 'Disabled token':
              return 'Invitation is currently disabled'
            case 'Already accepted':
              return 'Team invitation is already accepted'
            default:
              return 'Token is invalid'
          }
        })(),
      })
    }
  }

  handleSubmit = async (form, type) => {
    const {
      ACCEPT_TEAM_INVITATION,
      history: { push },
      location,
    } = this.props
    const { directEmail, currentEmail } = this.state
    const { token } = queryString.parse(location.search)
    form.attributes.token = token
    const selectedCountry = countries.find(c => c.name.common === form.attributes.country)
    const email = currentEmail && directEmail && currentEmail !== directEmail ? { email: currentEmail } : {}
    const updatedForm = {
      attributes: {
        ...form.attributes,
        ...email,
        country: selectedCountry ? selectedCountry.cca2 : null,
      },
    }

    if (type === 'generic') {
      try {
        const exists = await checkExistsCustomer(form.attributes.email)
        if (!exists && this.state.redirectTeamSignUp) {
          Promise.resolve(ACCEPT_TEAM_INVITATION(updatedForm)).then(() => {
            this.props
              .LOGIN({
                attributes: {
                  ...form,
                  password: form.attributes.password,
                  username: form.attributes.email,
                  newSignupFlow: true,
                  noAppcues: true,
                },
              })
              .then(() => {
                push('/')
              })
          })
        }
        if (!exists) {
          this.setState({ redirectTeamSignUp: true })
        } else {
          this.setState({ isUserExists: true })
        }
      } catch (e) {
        showAxiosError(e)
      }
    } else {
      return Promise.resolve(ACCEPT_TEAM_INVITATION(updatedForm))
        .catch(err => {
          const errors = _result(err, 'errors')
          if (_find(errors, e => e.details === 'Invitation already accepted')) {
            this.props.history.push('signin')
          }
          const error = _get(err, 'errors[0].details')
          if (error) {
            this.setState({
              tokenValidating: false,
              tokenValid: false,
              tokenText: error,
            })
          }
          return Promise.reject(_result(err, 'toFieldErrors'))
        })
        .then(v => {
          push('/signin')
          return v
        })
    }
  }

  onConfirmResponse = response => {
    switch (response) {
      case 'yes':
        this.onContinue()
        break
      case 'logout':
        this.onLogout()
        break
      default:
        break
    }
  }

  onContinue = () => {
    this.setState({ showModal: false }, () => {
      this.handleSubmit({ attributes: {} }, 'direct')
    })
  }

  onLogout = () => {
    const { isNewCustomer } = this.state
    const {
      history: { push },
      LOGOUT,
    } = this.props
    LOGOUT()
    if (isNewCustomer) {
      this.setState({
        currentEmail: '',
        directEmail: '',
        showModal: false,
      })
    } else {
      setTimeout(() => {
        push('/signin')
      }, 500)
    }
  }

  render() {
    const { tokenValid, isNewCustomer, tokenValidating, tokenText, showModal, directEmail, currentEmail, extraFields } =
      this.state
    const { location, user, history: { push } } = this.props
    const type = queryString.parse(location.search)?.token?.length < 11 ? 'generic' : 'direct'

    return (
      <div className="loginpage confirm-team-invitation-container">
        <Background />
        <Notifications />
        <ConfirmModal
          isOpen={showModal}
          header="Warning"
          content={
            <div>
              <p>{`This invitation is for ${directEmail}. You are currently logged in as ${currentEmail}.`}</p>
              <p>Are you sure you would like to accept the invitation as this account?</p>
            </div>
          }
          actions={[
            { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow' },
            { value: 'logout', label: 'Logout', className: 'btn btn-cancel btn-shadow' },
          ]}
          onAction={this.onConfirmResponse}
          classNames={{
            modal: 'modal-dialog modal-trans modal-medium modal-small',
            container: 'modal-dialog modal-medium modal-small',
          }}
          style={{ overlay: { zIndex: 9999999999999999999 } }}
        />
        <ConfirmTeamInvitationForm
          onSubmit={this.handleSubmit}
          isNewCustomer={isNewCustomer}
          tokenValidating={tokenValidating}
          tokenValid={tokenValid}
          type={type}
          tokenText={tokenText}
          redirectTeamSignUp={this.state.redirectTeamSignUp}
          isUserExists={this.state.isUserExists}
          user={user}
          push={push}
          extraFields={extraFields}
        />
      </div>
    )
  }
}
