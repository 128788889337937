export const invitationAdapter = (data, timezone) => {
  const newData = data.map(row => ({
    ...row,
    full_name: row.first_name + ' ' + row.last_name,
    invited_full: row.invited_by_first_name + ' ' + row.invited_by_last_name,
    status_str: row.status ?? '',
    ticketType: row.ticketType ?? '',
    maxQuantity: row.maxQuantity ?? '',
    created_at: row.created_at,
  }))
  return newData
}
