// Not reusable -- need to refactored
import React from 'react'
import TagsInput from 'react-tagsinput'
import Button from './Button'

export default class SearchBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
    }
    this.onChange = this.onChange.bind(this)
    this.autoSizing = this.autoSizing.bind(this)
    this.saveTag = this.saveTag.bind(this)
  }

  componentDidMount() {
    if (this.props.autoFocus) this.refs.tag.focus()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.setState(() => ({ value: newProps.value }))
    }
  }

  onChange(tags) {
    if (this.props.onChange) {
      this.props.onChange(tags, this.props.focusAfter, this.props.triggerScroll)
    }
  }

  saveTag() {
    this.refs.tag.accept()
    if (this.props.autoFocus) this.refs.tag.focus()
  }

  autoSizing({ addTag, ...props }) {
    let { onChange, value, ...other } = props
    if (!!this.state.value && this.state.value.length > 0) {
      return null
    }
    if (value.length > 30) {
      value = value.substring(0, 30)
    }
    return (
      <input type="text" className="react-tagsinput-input" onChange={onChange} value={value} {...other} />
    )
  }

  render() {
    const { value } = this.state
    return (
      <div className="tags-field searchbar find_order">
        <TagsInput
          ref="tag"
          inputProps={{ placeholder: '' }}
          addKeys={[9, 13]}
          maxTags={1}
          onlyUnique
          value={value || []}
          onChange={this.onChange}
          renderInput={this.autoSizing}
        />
        <Button className="search-btn" onClick={this.saveTag}>
          <img alt="no data" className="" src={asset('/resources/images/search-ico.svg')} />
        </Button>
      </div>
    )
  }
}
