import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import _map from 'lodash/map'
import _clone from 'lodash/clone'

import Card from '../_library/Card'
import LoadingBar from '../_library/LoadingBar'
import EmptyBar from '../_library/EmptyBar'
import TextArea from '../_library/TextArea'

import { DAY_DISPLAY_FORMAT } from '../../web/constants/timeFormats'

import SortableTableWithSearchHOC from '../hoc/SortableTableWithSearchHOC'

import CreateGiftCardForm from './giftcards/CreateGiftCardForm'
import ImportGiftCardForm from './giftcards/ImportGiftCardForm'

import {
  ISSUE_GIFT_CARD,
  FETCH_GIFT_CARD_TYPES,
  FETCH_ISSUED_GIFT_CARDS
} from '../../_common/redux/giftcards/actions'

import { FETCH_EMAIL_TEMPLATES } from '../../_common/redux/emailtemplates/actions'

import { GIFT_CARD_TYPE } from './giftcards/constants'
import { getTableColumns } from '../utils/sortableTableUtils'
import PermissionDenied from '../_library/PermissionDeniedComponent'

import {
  get_event,
  get_event_id,
  get_email_templates,
  get_gift_card_types,
  get_issued_gift_cards,
} from '../../_common/core/selectors'

const SortableTableWithSearch = SortableTableWithSearchHOC()

@connect(
  state => {
    const event = get_event(state)
    const eventId = get_event_id(state)
    const emailTemplates = get_email_templates(state)
    const giftCardTypes = get_gift_card_types(state)
    const issuedGiftCards = get_issued_gift_cards(state)
    const fetchGiftCardTypesLoading = state.loading.has('FETCH_GIFT_CARD_TYPES')
    const fetchIssuedGiftCardsLoading = state.loading.has('FETCH_ISSUED_GIFT_CARDS')

    return {
      event,
      eventId,
      emailTemplates,
      giftCardTypes,
      issuedGiftCards,
      fetchGiftCardTypesLoading,
      fetchIssuedGiftCardsLoading,
    }
  },
  {
    FETCH_GIFT_CARD_TYPES,
    ISSUE_GIFT_CARD,
    FETCH_ISSUED_GIFT_CARDS,
    FETCH_EMAIL_TEMPLATES
  }
)
export default class EventGiftCards extends Component {
  constructor(props) {
    super(props)
    this.searchBy = ['name', 'email', 'giftSenderFullName']
    this.tableColumns = getTableColumns([
      'name',
      'email',
      { label: 'Ticket/Amount', key: 'ticketAmount' },
      { label: 'Expiration Date', key: 'expirationDate', formatTo: DAY_DISPLAY_FORMAT },
      { label: 'Issued On', key: 'createdAt', formatTo: DAY_DISPLAY_FORMAT, isSortable: true },
      { label: 'Issued By', key: 'giftSenderFullName' },
      { label: ' ', key: 'byAdmin' }
    ])
    this.currency = getCurrencySymbol(props.event)
    this.state = {
      ticketGroupsOptions: [],
      giftCardTypesOptions: [],
      amountsInfo: {
        id: null,
        amountsList: []
      },
      extendedIssuedGiftCards: []
    }
  }

  componentDidMount() {
    const {
      FETCH_GIFT_CARD_TYPES,
      FETCH_ISSUED_GIFT_CARDS,
      FETCH_EMAIL_TEMPLATES,
      eventId,
      issuedGiftCards
    } = this.props
    FETCH_GIFT_CARD_TYPES(eventId)
    FETCH_ISSUED_GIFT_CARDS(eventId)
    FETCH_EMAIL_TEMPLATES(eventId)
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }

    const [extendedIssuedGiftCards, detailsRows] = this.getExtendedIssuedGiftCards(issuedGiftCards)

    this.setState({
      extendedIssuedGiftCards,
      detailsRows
    })
  }

  componentWillReceiveProps(nextProps) {
    const { giftCardTypes, issuedGiftCards } = nextProps

    if (giftCardTypes !== this.props.giftCardTypes) {
      const giftCardTypesOptions = this.getGiftCardTypeOptions(giftCardTypes)
      const { ticketGroupsOptions, amountsInfo } = this.getIssueGiftCardFormOptions(giftCardTypes)
      this.setState(() => ({ giftCardTypesOptions, ticketGroupsOptions, amountsInfo }))
    }
    if (this.state.extendedIssuedGiftCards.length !== issuedGiftCards.length) {
      const [extendedIssuedGiftCards, detailsRows] = this.getExtendedIssuedGiftCards(issuedGiftCards)
      this.setState({
        extendedIssuedGiftCards,
        detailsRows
      })
    }
  }

  getExtendedIssuedGiftCards = issuedGiftCards => {
    const detailsRows = []
    const extendedIssuedGiftCards = issuedGiftCards.length
      ? _map(issuedGiftCards, (item, key) => {
        detailsRows.push({
          id: item.id,
          type: 'detailRow',
          component: <TextArea label="Note" value={item.message} disabled={true} />
        })
        return {
          ...item,
          name: `${item.firstName || ''} ${item.lastName || ''}`,
          giftSenderFullName: `${item.giftSender.firstName || ''} ${item.giftSender.lastName ||
              ''}`,
          expirationDate: item.eventGiftCard.expiryDate,
          ticketAmount: item.eventGiftCard.ticketGroupName
            ? `${item.quantity}x ${item.eventGiftCard.ticketGroupName}`
            : `${this.currency || 'US$'} ${item.amount}`,
          byAdmin: (
            <span>
              {item.eventGiftCard.issuedByAdmin && (
                <span>
                  <i
                    className="fa fa-user"
                    aria-hidden="true"
                    data-tip=""
                    data-for="by-admin"
                  />
                  <ReactTooltip place="bottom" id="by-admin">
                      Issued by admin
                  </ReactTooltip>
                </span>
              )}
            </span>
          )
        }
      })
      : []
    return [extendedIssuedGiftCards, detailsRows]
  }

  getGiftCardTypeOptions = giftCardTypes => {
    const giftCardTypesOptions = [{ value: '', label: 'Select' }]
    giftCardTypes.some(
      item =>
        item.ticketGroupId &&
        giftCardTypesOptions.push({ value: GIFT_CARD_TYPE.TICKET, label: 'Ticket' })
    )
    giftCardTypes.some(
      item =>
        item.amount &&
        item.amount.length &&
        giftCardTypesOptions.push({ value: GIFT_CARD_TYPE.AMOUNT, label: 'Amount' })
    )
    return giftCardTypesOptions
  }

  getIssueGiftCardFormOptions = (giftCardTypes = []) => {
    const ticketGroupsOptions = [{ value: '', label: 'Select' }]
    const amountsInfo = {
      id: null,
      amountsList: []
    }

    for (const giftCard of giftCardTypes) {
      giftCard.ticketGroupName &&
        ticketGroupsOptions.push({ value: giftCard.id, label: giftCard.ticketGroupName })
      const amountItems = _clone(giftCard.amount)
      if (amountItems && amountItems.length && this.currency) {
        amountsInfo.amountsList = [
          ...amountItems.map(amountItem => ({
            value: amountItem,
            label: `${this.currency} ${amountItem}`
          }))
        ]
        amountsInfo.amountsList.unshift({ value: '', label: 'Select' })
        amountsInfo.id = giftCard.id
      }
    }
    return { ticketGroupsOptions, amountsInfo }
  }

  handleSubmit = async values => {
    const { eventId, FETCH_ISSUED_GIFT_CARDS, ISSUE_GIFT_CARD } = this.props
    try {
      const res = await ISSUE_GIFT_CARD(eventId, values)
      Messenger().post({
        type: 'success',
        message: 'Successfully Issued!',
        hideAfter: 10,
        showCloseButton: true
      })
      setTimeout(() => {
        FETCH_ISSUED_GIFT_CARDS(eventId)
      }, 4000)
      return res
    } catch (err) {
      Messenger().post({
        type: 'error',
        message: err,
        hideAfter: 5,
        showCloseButton: true
      })
      return Promise.reject(err)
    }
  }

  getPermission = () => {
    const { event } = this.props
    return event ? event.flagGiftCertificatesEnabled : false
  }

  render() {
    const { fetchIssuedGiftCardsLoading, emailTemplates, eventId } = this.props
    const {
      giftCardTypesOptions,
      ticketGroupsOptions,
      amountsInfo,
      extendedIssuedGiftCards,
      detailsRows
    } = this.state
    const isEnabled = this.getPermission()

    const emailTemplatesOptions = emailTemplates
      .filter(el => el.type === 'gift_certificates')
      .map(el => ({ value: el.id, label: el.name }))
    emailTemplatesOptions.unshift({ value: '', label: 'Default Template' })

    return isEnabled ? (
      <div className="gift-cards">
        <Card title={'Issue Gift Certificate'}>
          <CreateGiftCardForm
            eventId={eventId}
            giftCardTypes={giftCardTypesOptions}
            amounts={amountsInfo}
            ticketGroups={ticketGroupsOptions}
            emailTemplatesOptions={emailTemplatesOptions}
            onSubmit={this.handleSubmit}
          />
        </Card>
        <Card title={'Upload Gift Certificate'}>
          <ImportGiftCardForm
            eventId={eventId}
            ticketGroups={ticketGroupsOptions}
            emailTemplatesOptions={emailTemplatesOptions}
            onSubmit={this.handleSubmit}
          />
        </Card>
        <Card title={'Issued Gift Certificates'}>
          {fetchIssuedGiftCardsLoading ? (
            <LoadingBar />
          ) : extendedIssuedGiftCards.length ? (
            <SortableTableWithSearch
              data={extendedIssuedGiftCards}
              sortBy={{ column: 'createdAt', asc: false }}
              tableColumns={this.tableColumns}
              searchBy={this.searchBy}
              detailsRows={detailsRows}
            />
          ) : (
            <EmptyBar />
          )}
        </Card>
      </div>
    ) : (
      <PermissionDenied subHeader={`You don't have access to this section`} />
    )
  }
}
