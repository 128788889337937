import _get from 'lodash/get'
import _set from 'lodash/get'
import React from 'react'
import { withFormik } from 'formik'

import Field from '../../_library/Field'
import Button from '../../_library/Button'
import RichTextArea from '../../_library/RichTextArea'
import { Tab, TabView } from '../../_library/TabView'
import FileUploader from '../../_library/FileUploader'
import Select from '../../_library/Select'

import { EMAIL_TEMPLATE_TYPES } from '../../constants/emailTemplatesTypes'

const emailTemplateOptions = EMAIL_TEMPLATE_TYPES.getOptions()

class EditEmailTemplateFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onContentModeSelect = this.onContentModeSelect.bind(this)
    this.processCancel = this.processCancel.bind(this)
    this.onZipChange = this.onZipChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onTemplateTypeChange = this.onTemplateTypeChange.bind(this)
  }

  onContentModeSelect(index) {
    this.props.setFieldValue('emailtemplate_edit_contentmode', index === 1 ? 'body' : 'zip')
  }

  componentDidMount() {
    this.refs.tabview.selectIndex(this.props.values.emailtemplate_edit_contentmode === 'body' ? 1 : 2)
  }

  processCancel() {
    this.props.onCancel()
  }

  onBodyChange(content) {
    this.props.setFieldValue('emailtemplate_edit_body', content)
  }
  onTemplateTypeChange(e) {
    this.props.setFieldValue('emailtemplate_edit_type', e.target.value)
  }

  onZipChange(url) {
    this.props.setFieldValue('emailtemplate_edit_zip', url)
  }

  render() {
    const {
      values,
      isSubmitting,
      handleSubmit,
      submitLabel,
      errors,
      touched,
      handleChange,
      handleBlur,
      configs,
    } = this.props
    const showUploadZippedContent = _get(configs, 'appearance.showUploadZippedContent', false)
    return (
      <form ref="form" method="POST">
        <div className="emailtemplate-edit">
          <div className="row">
            <div className="col-xs-12">
              <Select
                id="emailtemplate_edit_type"
                label="Email Template"
                options={emailTemplateOptions}
                value={values.emailtemplate_edit_type}
                error={errors.emailtemplate_edit_type}
                touched={touched.emailtemplate_edit_type}
                onChange={this.onTemplateTypeChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_edit_name"
                label="Name"
                value={values.emailtemplate_edit_name}
                error={errors.emailtemplate_edit_name}
                touched={touched.emailtemplate_edit_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_edit_description"
                label="Description"
                value={values.emailtemplate_edit_description}
                error={errors.emailtemplate_edit_description}
                touched={touched.emailtemplate_edit_description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_edit_subject"
                label="Subject"
                value={values.emailtemplate_edit_subject}
                error={errors.emailtemplate_edit_subject}
                touched={touched.emailtemplate_edit_subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 template-content">
              <TabView ref="tabview" all={false} onSelectTab={this.onContentModeSelect}>
                <Tab title="Enter Content">
                  <RichTextArea
                    id="emailtemplate_edit_body"
                    disableEmbeds={true}
                    value={values.emailtemplate_edit_body}
                    error={errors.emailtemplate_edit_body}
                    touched={touched.emailtemplate_edit_body}
                    onBlur={handleBlur}
                    onChange={this.onBodyChange}
                  />
                </Tab>
                {showUploadZippedContent && (
                  <Tab title="Upload Zipped Content">
                    <p>Your zip file should contain an index.html file with an optional images folder</p>
                    {!!values.emailtemplate_edit_zip && (
                      <div className="zip-path">{values.emailtemplate_edit_zip}</div>
                    )}
                    <FileUploader
                      label="Load Zip File"
                      filetype="archive"
                      value={values.emailtemplate_edit_zip}
                      error={errors.emailtemplate_edit_zip}
                      touched={touched.emailtemplate_edit_zip}
                      onBlur={handleBlur}
                      onChange={this.onZipChange}
                    />
                  </Tab>
                )}
              </TabView>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <Button
                className="btn btn-success btn-shadow"
                type="button"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {submitLabel || (
                  <span>
                    <i className="fa fa-paper-plane" aria-hidden="true" /> Save
                  </span>
                )}
              </Button>
              <Button className="btn btn-cancel btn-shadow" type="button" onClick={this.processCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

function validateEditEmailTemplateForm(data) {
  const errors = {}
  if (!_get(data, 'emailtemplate_edit_name')) {
    _set(errors, 'emailtemplate_edit_name', 'Required')
  }
  if (!_get(data, 'emailtemplate_edit_subject')) {
    _set(errors, 'emailtemplate_edit_subject', 'Required')
  }
  if (_get(data, 'emailtemplate_edit_contentmode') === 'body') {
    if (!_get(data, 'emailtemplate_edit_body')) {
      _set(errors, 'emailtemplate_edit_body', 'This field is required')
    }
  }
  if (_get(data, 'emailtemplate_edit_contentmode') === 'zip') {
    if (!_get(data, 'emailtemplate_edit_zip')) {
      _set(errors, 'emailtemplate_edit_zip', 'Please select a zip file')
    }
  }
  return errors
}

export const EditEmailTemplateForm = withFormik({
  mapPropsToValues: props => ({
    emailtemplate_edit_id: _get(props, 'initialValues.id') || '',
    emailtemplate_edit_type: _get(props, 'initialValues.type'),
    emailtemplate_edit_name: _get(props, 'initialValues.name') || '',
    emailtemplate_edit_description: _get(props, 'initialValues.description') || '',
    emailtemplate_edit_subject: _get(props, 'initialValues.subject') || '',
    emailtemplate_edit_contentmode: _get(props, 'initialValues.content_mode') || 'body',
    emailtemplate_edit_body: _get(props, 'initialValues.body') || null,
    emailtemplate_edit_zip: _get(props, 'initialValues.zip') || null,
  }),

  // Custom sync validation
  validate: (values, props) => validateEditEmailTemplateForm(values, props),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        id: values.emailtemplate_edit_id,
        type: values.emailtemplate_edit_type,
        name: values.emailtemplate_edit_name,
        description: values.emailtemplate_edit_description,
        subject: values.emailtemplate_edit_subject,
        content_mode: values.emailtemplate_edit_contentmode,
        body: values.emailtemplate_edit_body,
        zip: values.emailtemplate_edit_zip,
      })
      .then(() => {
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  },
  displayName: 'EditEmailTemplateForm', // helps with React DevTools
})(EditEmailTemplateFormComponent)
