import _get from 'lodash/get'
import React from 'react'
import { Form, Formik, Field } from 'formik'
import Button from '../../_library/Button'
import { SelectField, SimpleField } from '../../formik/Fields'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'

export const EditPassForm = props => {
  const { 
    onCancel, 
    submitLabel,
    passTypes
  } = props

  const options = passTypes.map(type => ({ value: type.id, label: type.name }))

  return (
    <Formik 
      initialValues={{
        entrypassTypeId: _get(props, 'initialValues.entrypassTypeId') || '',
        firstName: _get(props, 'initialValues.firstName') || '',
        lastName: _get(props, 'initialValues.lastName') || '',
        email: _get(props, 'initialValues.email') || '',
        issuedByNotes: _get(props, 'initialValues.issuedByNotes') || '',
        maxUsesPerEvent: _get(props, 'initialValues.maxUsesPerEvent') || ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values)
          .then(() => {
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
          })
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-xs-12">
              <Field
                label="Type"
                name="entrypassTypeId"
                placeholder="Type"
                component={SelectField}
                options={options}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="First name"
                name="firstName"
                placeholder="First name"
                component={SimpleField}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="Last name"
                name="lastName"
                placeholder="Last name"
                component={SimpleField}
                validate={requiredValidator()}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="Email"
                name="email"
                placeholder="Email"
                component={SimpleField}
                validate={combineValidators(requiredValidator(), isEmailValidator())}
              />
            </div>
            <div className="col-xs-12">
              <Field
                className="responsive-label"
                label="Maximum number of uses per event (optional)"
                type="number"
                name="maxUsesPerEvent"
                placeholder="Maximum number of uses per event (optional)"
                component={SimpleField}
              />
            </div>
            <div className="col-xs-12">
              <Field
                label="Notes (optional)"
                name="issuedByNotes"
                placeholder="Notes (optional)"
                component={SimpleField}
              />
            </div>
          </div>
          <div className="text-center btn-toolbar">
            <Button type="submit" className="btn btn-success btn-lg btn-shadow" loading={isSubmitting}>
              {submitLabel}
            </Button>
            {onCancel && (
              <Button
                className="btn btn-danger btn-lg btn-shadow"
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}