import React from 'react'

export default function EventImagePlaceholder({
  image = '/resources/images/tickets.svg',
  label = 'No image'
}) {
  return (
    <div className="events-placeholder">
      <img className="LazyLoadNoImg" src={asset(image)} />
      <p className="events-placeholder-text">{label}</p>
    </div>
  )
}
