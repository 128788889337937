import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { withFormik } from 'formik'

// import Field from '../_library/Field'
import Field from '../../_library/Field'
import Select from '../../_library/Select'
import Button from '../../_library/Button'
import RichTextArea from '../../_library/RichTextArea'
import { Tab, TabView } from '../../_library/TabView'
import FileUploader from '../../_library/FileUploader'

import { EMAIL_TEMPLATE_TYPES } from '../../constants/emailTemplatesTypes'

const emailTemplateOptions = EMAIL_TEMPLATE_TYPES.getOptions()

class NewEmailTemplateFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onContentModeSelect = this.onContentModeSelect.bind(this)
    this.onTemplateTypeChange = this.onTemplateTypeChange.bind(this)
    this.onZipChange = this.onZipChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
  }

  onContentModeSelect(index) {
    this.props.setFieldValue('emailtemplate_contentmode', index === 1 ? 'body' : 'zip')
  }

  onBodyChange(content) {
    this.props.setFieldValue('emailtemplate_body', content)
  }
  onTemplateTypeChange(e) {
    this.props.setFieldValue('emailtemplate_type', e.target.value)
  }

  onZipChange(url) {
    this.props.setFieldValue('emailtemplate_zip', url)
  }

  render() {
    const {
      values,
      isSubmitting,
      handleSubmit,
      submitLabel,
      errors,
      touched,
      handleChange,
      handleBlur,
      configs,
    } = this.props
    const showUploadZippedContent = _get(configs, 'appearance.showUploadZippedContent', false)

    return (
      <form ref="form" method="POST">
        <div className="emailtemplate-new">
          <div className="row">
            <div className="col-xs-12">
              <Select
                id="emailtemplate_type"
                label="Email Template"
                options={emailTemplateOptions}
                value={values.emailtemplate_type}
                error={errors.emailtemplate_type}
                touched={touched.emailtemplate_type}
                onChange={this.onTemplateTypeChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_name"
                label="Name"
                value={values.emailtemplate_name}
                error={errors.emailtemplate_name}
                touched={touched.emailtemplate_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_description"
                label="Description"
                value={values.emailtemplate_description}
                error={errors.emailtemplate_description}
                touched={touched.emailtemplate_description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="emailtemplate_subject"
                label="Subject"
                value={values.emailtemplate_subject}
                error={errors.emailtemplate_subject}
                touched={touched.emailtemplate_subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 template-content">
              <TabView all={false} onSelectTab={this.onContentModeSelect}>
                <Tab title="Enter Content">
                  <RichTextArea
                    id="emailtemplate_body"
                    disableEmbeds={true}
                    value={values.emailtemplate_body}
                    error={errors.emailtemplate_body}
                    touched={touched.emailtemplate_body}
                    onBlur={handleBlur}
                    onChange={this.onBodyChange}
                  />
                </Tab>
                {showUploadZippedContent && (
                  <Tab title="Upload Zipped Content">
                    <p>Your zip file should contain an index.html file with an optional images folder</p>
                    <FileUploader
                      label="Add Zip File"
                      filetype="archive"
                      value={values.emailtemplate_zip}
                      error={errors.emailtemplate_zip}
                      touched={touched.emailtemplate_zip}
                      onBlur={handleBlur}
                      onChange={this.onZipChange}
                    />
                  </Tab>
                )}
              </TabView>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <Button
                className="btn btn-success btn-lg btn-shadow"
                type="button"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {submitLabel || <span>Create</span>}
              </Button>
              <Button
                className="btn btn-cancel btn-lg btn-shadow"
                type="button"
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

function validateNewEmailTemplateForm(data) {
  const errors = {}
  if (!_get(data, 'emailtemplate_name')) {
    _set(errors, 'emailtemplate_name', 'Required')
  }
  if (!_get(data, 'emailtemplate_subject')) {
    _set(errors, 'emailtemplate_subject', 'Required')
  }
  if (_get(data, 'emailtemplate_contentmode') === 'body') {
    if (!_get(data, 'emailtemplate_body')) {
      _set(errors, 'emailtemplate_body', 'This field is required')
    }
  } else if (_get(data, 'emailtemplate_contentmode') === 'zip') {
    if (!_get(data, 'emailtemplate_zip')) {
      _set(errors, 'emailtemplate_zip', 'Please select a zip file')
    }
  }
  return errors
}

export const NewEmailTemplateForm = withFormik({
  mapPropsToValues: props => ({
    emailtemplate_type: emailTemplateOptions[0].value,
    emailtemplate_name: _get(props, 'initialValues.attributes.name') || '',
    emailtemplate_description: _get(props, 'initialValues.attributes.description') || '',
    emailtemplate_subject: _get(props, 'initialValues.attributes.subject') || '',
    emailtemplate_contentmode: _get(props, 'initialValues.attributes.content_mode') || 'body',
    emailtemplate_body: _get(props, 'initialValues.attributes.body') || null,
    emailtemplate_zip: _get(props, 'initialValues.attributes.zip') || null,
  }),

  // Custom sync validation
  validate: (values, props) => validateNewEmailTemplateForm(values, props),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        type: values.emailtemplate_type,
        name: values.emailtemplate_name,
        description: values.emailtemplate_description,
        subject: values.emailtemplate_subject,
        content_mode: values.emailtemplate_contentmode,
        body: values.emailtemplate_body,
        zip: values.emailtemplate_zip,
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'NewEmailTemplateForm', // helps with React DevTools
})(NewEmailTemplateFormComponent)
