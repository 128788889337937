import React  from 'react'
import Field from '../../../_library/Field'

const shortNameRE = /[<>'\"]+/
const shortNameLength = 17

const EventNameRow = (props) => {
  const { values, errors, touched, isRnDomain, updateField } = props

  const handleChangeDisplayName = e => {
    const displayNameLength = isRnDomain ? 50 : 65
    let str = e.target.value
    if (str.length > displayNameLength) {
      str = str.slice(0, displayNameLength)
    }
    updateField('displayName', str)
    handleChangeShortName(str)
  }

  const handleChangeShortName = e => {
    let str = e.target ? e.target.value : e
    if (str.length > shortNameLength) {
      if (shortNameRE.test(str)) return
      str = str.slice(0, shortNameLength)
    }
    updateField('shortName', str)
  }

  return (
    <div className="row">
      <div className={`col-xs-${isRnDomain ? '12' : '6'} col-12 text-holders`}>
        <Field
          id="eventDisplayName"
          label={isRnDomain ? 'Event Title' : 'Event Name'}
          size="large"
          value={values.displayName}
          error={errors.displayName}
          touched={touched.displayName}
          onChange={handleChangeDisplayName}
        />
      </div>
      {!isRnDomain && (
        <div className="col-xs-6 col-12 text-holders">
          <Field
            id="shortName"
            label="Short Event Name"
            size="large"
            value={values.shortName}
            error={errors.shortName}
            touched={touched.shortName}
            onChange={handleChangeShortName}
          />
        </div>
      )}
    </div>
  )
}

export default EventNameRow
