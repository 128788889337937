import React from 'react'
import Modal from 'react-modal'
import { Formik, Form, Field } from 'formik'
import Button from '../../_library/Button'
import CustomToggle from '../../_library/CustomToggle'

import { SelectField, SimpleField } from '../../formik/Fields'
import { validateForm } from './GiftCardManagementForm'
import modalStyle from '../../../_common/core/modalStyle'
import {
  requiredValidator,
  createFixedFloatNormalizer,
  combineNormalizers,
  toNumber
} from '../../../_common/core/validation'
import { MONTHS_OPTIONS } from './constants'

export default class GiftCardEditModal extends React.Component {
  render() {
    const { display, initialValues, onSubmit, handleCancel, groupedTicketsOptions } = this.props

    return (
      <div>
        {display && (
          <Modal
            className="modal-dialog modal-trans modal-daterange"
            style={modalStyle}
            isOpen={true}
            contentLabel="Modal"
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            {initialValues && (
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="date-range-value">{`Edit Gift Certificate`}</div>
                  </div>
                  <div className="modal-body" style={{ width: '100%' }}>
                    <Formik
                      initialValues={
                        initialValues || {
                          ticketGroupId: '',
                          groupItemPrice: '',
                          enabled: true,
                          duration: ''
                        }
                      }
                      onSubmit={(values, actions) => {
                        const { setSubmitting, resetForm } = actions
                        onSubmit(values).finally(() => {
                          resetForm()
                          setSubmitting(false)
                        })
                      }}
                      validate={validateForm}
                      render={({ values, isSubmitting, setFieldValue }) => (
                        <Form autoComplete="off">
                          <div className="row">
                            <div className="col-xs-6 col-sm-6">
                              <Field
                                name="ticketGroupId"
                                label="Ticket Type"
                                options={groupedTicketsOptions}
                                component={SelectField}
                                validate={requiredValidator()}
                                disabled={true}
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6 group-item-price-field editting">
                              <Field
                                name="groupItemPrice"
                                label="Item Price (average)"
                                component={SimpleField}
                                validate={requiredValidator()}
                                normalizer={toNumber}
                                onBlurNormalizer={combineNormalizers(
                                  toNumber,
                                  createFixedFloatNormalizer(2)
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                name="duration"
                                label="Expiration Duration (months)"
                                options={MONTHS_OPTIONS}
                                component={SelectField}
                                validate={requiredValidator()}
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6" style={{ padding: '20px 15px' }}>
                              <CustomToggle
                                id="enabled"
                                onText={'Enabled'}
                                checked={values.enabled}
                                onChange={(fieldKey, checked) => setFieldValue(fieldKey, checked)}
                              />
                            </div>
                            <div className="col-xs-12 gift-button-container">
                              <Button
                                className="btn btn-primary btn-success"
                                type="submit"
                                loading={isSubmitting}
                              >
                                Save
                              </Button>
                              <Button
                                className="btn btn-primary btn-danger"
                                type="button"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
      </div>
    )
  }
}
