/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import _identity from 'lodash/identity'
import CustomToggle from '../../_library/CustomToggle'

const CustomToggleField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  label,
  ...props
}) => {
  const setValue = value => {
    setFieldValue(field.name, normalizer(value))
    if (props.onChange) {
      props.onChange(field.name, normalizer(value))
    }
  }
  const onChange = (key, checked) => {
    setValue(checked)
  }

  return (
    <CustomToggle {...{ ...field, checked: field.value }} {...props} id={field.name} onChange={onChange} />
  )
}

export { CustomToggleField }
