import { Field } from 'formik'
import { DateTimePickerFieldReact } from '../../../../formik/Fields'
import React from 'react'

export const PickerRow = () => (
  <>
    <div className='row' style={{ marginBottom: 8 }}>
      <div className='col-md-6 datetime-field-ticket-form' id='sales-start' style={{ position: 'static' }}>
        <Field
          name='salesStart'
          label='Sales start date'
          placeholder='D MMM YYYY H:M AM'
          component={DateTimePickerFieldReact}
        />
      </div>
      <div className='col-md-6 datetime-field-ticket-form' id='sales-end' style={{ position: 'static' }}>
        <Field
          name='salesEnd'
          label='Sales end date'
          placeholder='D MMM YYYY H:M AM'
          component={DateTimePickerFieldReact}
        />
      </div>
    </div>
  </>
)
