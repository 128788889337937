export const getEventsMenuItems = ({ page, isEvent, isEventsPage, isNewEventPage }) => {
  const menuGroup = []

  menuGroup.push({
    img: 'events',
    title: 'Events',
    href: `/events`,
    active: isEventsPage || isNewEventPage,
    visible: true,
    open: isEvent && page !== ''
  })

  return menuGroup
}
