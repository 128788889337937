import { baseRequest } from '.'
import _get from 'lodash/get'

export const getPaymentConnectData = async brandId => {
  const res = await baseRequest.get(`organizations/${brandId}/payment-methods`)
  return _get(res, 'data.data')
}

export const applyPaymentMethod = async (brandId, paymentMethodId) => {
  const res = await baseRequest.post(`organizations/${brandId}/payment-method/apply-on-events`, {
    paymentMethodCode: paymentMethodId,
  })
  return _get(res, 'data.data')
}

export const getStripeConnectLink = async brandId => {
  const res = await baseRequest.get(`brand/${brandId}/stripe-connect-link`);
  return _get(res, 'data.data')
}
