import _get from 'lodash/get'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import {
  adaptCredentialsAndRules,
  adaptDeactivateActivateResult,
  credentialDeleteAdapter,
  adaptCreatedCredentialWithRule,
  adaptUpdatedCredential,
  adaptUpdateCredentialWithCreateRule,
  adaptUpdateCredentialWithUpdateRule,
} from './utils'
import { showAxiosError } from '../../../web/utils/messenger'

//CREDENTIALS
const { FETCH_CREDENTIALS } = createAsyncAction('FETCH_CREDENTIALS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/credentials`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const ret = _get(res.data, '$original')
        dispatch(FETCH_CREDENTIALS_RULES(eid, ret))
        return res
      })
})

const { CREATE_CREDENTIALS } = createAsyncAction(
  'CREATE_CREDENTIALS',
  function (eid, data, ruleParams, hasRules, allData) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/credentials`, {
        method: 'POST',
        body: JSON.stringify({ data }),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)

          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          if (hasRules) {
            ruleParams.attributes.badgeId = ret.id
            dispatch(CREATE_CREDENTIALS_RULES(eid, ruleParams, ret, allData))
          } else {
            dispatch(this.success({ data: [...allData, ret] }))
          }
          return res
        })
  },
)
const { UPDATE_CREDENTIALS } = createAsyncAction(
  'UPDATE_CREDENTIALS',
  function (id, data, ruleParams, hasRules, allData, editRuleId) {
    return dispatch =>
      fetchAPI(`/api/event/credentials/${id}/`, {
        method: 'PUT',
        body: JSON.stringify({ data }),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)

          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          if (hasRules) {
            if (editRuleId) {
              const adaptedData = adaptUpdatedCredential(ret, allData)
              dispatch(UPDATE_CREDENTIALS_RULES(editRuleId, ruleParams, adaptedData, allData))
            } else {
              dispatch(CREATE_CREDENTIALS_RULES(data.attributes.eventId, ruleParams, ret, allData, true))
            }
          } else {
            const adaptedData = adaptUpdatedCredential(ret, allData)
            dispatch(this.success({ data: adaptedData }))
            if (editRuleId) {
              dispatch(DELETE_CREDENTIALS_RULES(editRuleId))
            }
          }

          return res
        })
  },
)
const { ACTIVATE_DEACTIVATE_CREDENTIALS } = createAsyncAction(
  'ACTIVATE_DEACTIVATE_CREDENTIALS',
  function (id, data, allData) {
    return dispatch =>
      fetchAPI(`/api/event/credentials/${id}`, {
        method: 'put',
        body: JSON.stringify({ data }),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')

          const adaptedData = adaptDeactivateActivateResult(ret, allData)
          dispatch(this.success({ data: adaptedData }))
          return res
        })
  },
)
const { DELETE_CREDENTIALS } = createAsyncAction(
  'DELETE_CREDENTIALS',
  function (id, hasRules, allData, ruleId) {
    return dispatch =>
      fetchAPI(`/api/event/credentials/${id}/`, {
        method: 'delete',
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const adaptedData = credentialDeleteAdapter(id, allData)
          dispatch(this.success({ data: adaptedData }))
          return res
        })
  },
)
//CREDENTIAL RULES

const { FETCH_CREDENTIALS_RULES } = createAsyncAction('FETCH_CREDENTIALS_RULES', function (eid, credData) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/credentials-rules`)
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const rulesData = _get(res.data, '$original')
        const adaptedData = adaptCredentialsAndRules(credData, rulesData)
        dispatch(this.success({ data: adaptedData }))
        return res
      })
})

const { DELETE_CREDENTIALS_RULES } = createAsyncAction(
  'DELETE_CREDENTIALS_RULES',
  id => dispatch =>
    fetchAPI(`/api/event/credentials-rules/${id}/`, {
      method: 'delete',
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => res),
)

const { CREATE_CREDENTIALS_RULES } = createAsyncAction(
  'CREATE_CREDENTIALS_RULES',
  function (eid, data, mergeData, allData, fromEdit = false) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/credentials-rules`, {
        method: 'POST',
        body: JSON.stringify({ data }),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)

          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          let adaptedData = []
          if (fromEdit) {
            adaptedData = adaptUpdateCredentialWithCreateRule(ret, mergeData, allData)
          } else {
            adaptedData = adaptCreatedCredentialWithRule(ret, mergeData, allData)
          }
          dispatch(this.success({ data: adaptedData }))

          return res
        })
  },
)

const { UPDATE_CREDENTIALS_RULES } = createAsyncAction(
  'UPDATE_CREDENTIALS_RULES',
  function (id, data, mergeData, allData) {
    return dispatch =>
      fetchAPI(`/api/event/credentials-rules/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ data }),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)

          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          const adaptedData = adaptUpdateCredentialWithUpdateRule(ret, mergeData)
          dispatch(this.success({ data: adaptedData }))

          return res
        })
  },
)

export {
  FETCH_CREDENTIALS,
  CREATE_CREDENTIALS,
  UPDATE_CREDENTIALS,
  ACTIVATE_DEACTIVATE_CREDENTIALS,
  DELETE_CREDENTIALS,
  FETCH_CREDENTIALS_RULES,
  DELETE_CREDENTIALS_RULES,
  CREATE_CREDENTIALS_RULES,
  UPDATE_CREDENTIALS_RULES,
}
