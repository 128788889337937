import React from 'react'
import { Field } from 'formik'
import { SelectField } from '../../../../formik/Fields/SelectField'
import getAvailableTimezones from '../../../../utils/timezones'
import { getCountryCurrencies } from '../../../../utils/coutriesUtils'
import _get from 'lodash/get'

const timezoneOptions = getAvailableTimezones()
const currencyOptions = getCountryCurrencies()

const TimeZoneRow = ({ values }) => (
  <div className="row">
    <div className={'col-xs-6 col-12'}>
      <Field
        name="timezone"
        label="Timezone"
        component={SelectField}
        options={timezoneOptions}
        defaultOption={{ value: '', label: 'Select Timezone...' }}
      />
    </div>
    <div className={'col-xs-6 col-12'}>
      <Field
        disabled={_get(values, 'hasSales')}
        name="currency"
        label="Currency"
        component={SelectField}
        options={currencyOptions}
        defaultOption={{ value: '', label: 'Select Currency...' }}
      />
    </div>
  </div>
)

export default TimeZoneRow
