import React from 'react'
import _reduce from 'lodash/reduce'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _isEqual from 'lodash/isEqual'
import EmptyBar from '../../_library/EmptyBar'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'
import { showAxiosError } from '../../utils/messenger'
import { getCheckedInAddOnsSlots } from '../../../_common/core/http_services'

export default class CheckInSlotAddOn extends React.Component {
  constructor(props) {
    super(props)
    // main table
    this.tableColumns = getTableColumns([
      { key: 'date', label: 'Slot Date', formatTo: DAY_DISPLAY_FORMAT },
      { key: 'addonsNumComponent', label: 'No. of Add-Ons', sort: this.sortByQuantity }
    ])
    this.footbarColumns = [{ key: 'addonsNumComponent', component: this.getTotalComponent() }]

    // time table's columns
    this.timeTableColumns = getTableColumns([
      { key: 'time', label: 'Slot Time', formatTo: DAY_DISPLAY_FORMAT, className: 'detail-cell' },
      { key: 'addonsNumComponent', label: 'No. of Add-Ons', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Add-Ons)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    // type table's columns
    this.typeTableColumns = getTableColumns([
      { key: 'name', label: 'Ticket Type', className: 'detail-cell' },
      { key: 'addonsNumComponent', label: 'No. of Add-Ons', className: 'detail-cell' },
      {
        key: 'totalQuantity',
        label: 'Running Total (No. of Add-Ons)',
        isSortable: false,
        className: 'detail-cell'
      }
    ])

    this.state = {
      tableData: [],
      timeDetailsRows: [],
      sortBy: { column: 'date', asc: true }
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  componentDidUpdate(prevProps) {
    const { checkInAddonsDates } = this.props

    if (!_isEqual(prevProps.checkInAddonsDates, checkInAddonsDates)) {
      this.getTableData()
    }
  }

  getTableData = () => {
    const { checkInAddonsDates } = this.props

    const tableData = _map(checkInAddonsDates, item => ({
      ...item,
      id: item.date,
      checkedInCount: parseInt(item.checkedInCount),
      addonsNumComponent: (
        <span>
          {`${item.checkedInCount} / `}
          <strong>{item.total}</strong>
          {` (${item.total - item.checkedInCount} remaining)`}
        </span>
      )
    }))

    this.setState({ tableData })
  }

  getTotalComponent = () => {
    const { checkInAddonsDates = [] } = this.props
    const allTicketsCount = _reduce(checkInAddonsDates, (result, item) => result + parseInt(item.total), 0)
    const checkedInTicketsCount = _reduce(
      checkInAddonsDates,
      (result, item) => result + parseInt(item.checkedInCount),
      0
    )
    const remainingTicketsCount = allTicketsCount - checkedInTicketsCount

    return (
      <span>
        {`${checkedInTicketsCount} / `}
        <strong>{allTicketsCount}</strong>
        {` (${remainingTicketsCount} remaining)`}
      </span>
    )
  }

  getDateDetailRows = (data, id, timeDetailsRows) => {
    const dataRows = []
    const typeDetailsRows = []

    const groupedData = _groupBy(data, item => item.time)
    _map(groupedData, (groupItem, key) => {
      this.getTypeDetailRows(groupItem, key, typeDetailsRows)
      const sumTotalCount = _reduce(groupItem, (result, item) => result + parseInt(item.total), 0)
      const sumCheckedInCount = _reduce(
        groupItem,
        (result, item) => result + parseInt(item.checkedInCount),
        0
      )

      dataRows.push({
        id: key,
        time: key,
        checkedInCount: _reduce(groupItem, (result, item) => result + parseInt(item.checkedInCount), 0),
        addonsNumComponent: (
          <span>
            {`${sumCheckedInCount} / `}
            <strong>{sumTotalCount}</strong>
            {` (${sumTotalCount - sumCheckedInCount} remaining)`}
          </span>
        )
      })
    })

    if (dataRows.length) {
      timeDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.timeTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'checkedInCount' }]}
            enableSort={false}
            sortBy={{ column: 'time', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
            detailsRows={typeDetailsRows}
          />
        )
      })
    } else {
      timeDetailsRows.push({
        id,
        type: 'detailRow',
        component: <EmptyBar />
      })
    }
  }

  getTypeDetailRows = (data, id, typeDetailsRows) => {
    const dataRows = []
    _map(data, item => {
      dataRows.push({
        ...item,
        id,
        addonsNumComponent: (
          <span>
            {`${item.checkedInCount} / `}
            <strong>{item.total}</strong>
            {` (${item.total - item.checkedInCount} remaining)`}
          </span>
        )
      })
    })

    if (dataRows.length) {
      typeDetailsRows.push({
        id,
        type: 'detailRow',
        component: (
          <SortableTable
            data={dataRows}
            tableColumns={this.typeTableColumns}
            calculatedColumns={[{ for: 'totalQuantity', column: 'checkedInCount' }]}
            enableSort={false}
            sortBy={{ column: 'time', asc: true }}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
    }
  }

  sortByQuantity = column => {
    this.setState(prevState => ({
      ...prevState,
      tableData: _orderBy(prevState.tableData, ['checkedInCount'], [prevState.sortBy.asc ? 'asc' : 'desc']),
      sortBy: { column: column.key, asc: !prevState.sortBy.asc }
    }))
  }

  getTableDataForCopy = () => {
    const { tableData } = this.state

    return _map(tableData, item => {
      const checkedInCount = parseInt(item.checkedInCount)
      const total = parseInt(item.total)
      const remainingCount = total - checkedInCount

      return {
        ...item,
        addonsNumComponent: `${checkedInCount} / ${total} (${remainingCount} remaining)`
      }
    })
  }

  getDetailRowData = async data => {
    const { event } = this.props
    try {
      const timeDetailsRows = []
      const res = await getCheckedInAddOnsSlots(event.id, data.id)
      this.getDateDetailRows(res, data.id, timeDetailsRows)

      this.setState(prevState => ({
        ...prevState,
        timeDetailsRows
      }))
      return res
    } catch (err) {
      showAxiosError(err)
      return null
    }
  }

  render() {
    const { tableData, timeDetailsRows, sortBy } = this.state

    return (
      <div className="ticketType tickettype-checkin">
        <div className="table-caption">
          <i className="fa fa-plus" />
          Add-Ons
        </div>
        {!!tableData.length ? (
          <SortableTable
            data={tableData}
            dataForCopy={this.getTableDataForCopy()}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            detailsRows={timeDetailsRows}
            isAsyncDetails={true}
            getDetailRowData={this.getDetailRowData}
            sortBy={sortBy}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
