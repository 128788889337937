import React from 'react'

// components
import Button from '../_library/Button'

const DisconnectShopify = ({ onDisconnect, shopifyDisconnectLoading }) => (
  <div style={{ margin: '5px', display: 'inline-block' }}>
    <Button
      loading={shopifyDisconnectLoading}
      className="btn btn-default"
      type='button'
      onClick={onDisconnect}
    >
        Disconnect Shopify
    </Button>
  </div>
)

export { DisconnectShopify }