import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'

import {
  FETCH_EVENT_CHECKIN,
  UNSCAN_TICKET,
  FETCH_EVENT_CHECKIN_DATES,
  FETCH_EVENT_CHECKIN_ADD_ONS_DATES,
  RESET_CHECK_IN,
  FETCH_EVENT_CHECKIN_TICKETS
} from '../../../_common/redux/performance/actions'

import { showAxiosError } from '../../utils/messenger'
import { Tab, TabView } from '../../_library/TabView'
import LoadingBar from '../../_library/LoadingBar'

import CheckInDownload from './CheckInDownload'
import CheckInDate from './CheckInDate'
import CheckInHourly from './CheckInHourly'
import CheckInTicketType from './CheckInTicketType'
import CheckInSlotTicketType from './CheckInSlotTicketType'
import CheckInAddOn from './CheckInAddOn'
import CheckInSlotAddOn from './CheckInSlotAddOn'
import CheckInByStaffMember from './CheckInByStaffMember'
import CheckInIndividualTickets from './CheckInIndividualTickets'
import { getEventTickets, getPerfomanceData } from '../../../_common/core/http_services'
import {
  get_event,
  get_checkin,
  get_checkin_dates,
  get_checkin_addons_dates
} from '../../../_common/core/selectors'
import { getTitle } from '../../utils/getTitle'

@connect(
  state => {
    const event = get_event(state)
    const checkInData = get_checkin(state)
    const checkInDates = get_checkin_dates(state)
    const checkInAddonsDates = get_checkin_addons_dates(state)
    const isLoading =
      state.loading.has('FETCH_EVENT_CHECKIN_DATES') ||
      state.loading.has('FETCH_EVENT_CHECKIN_ADD_ONS_DATES') ||
      state.loading.has('FETCH_EVENT_CHECKIN')

    return {
      event,
      checkInData,
      checkInDates,
      checkInAddonsDates,
      isLoading,
    }
  },
  {
    FETCH_EVENT_CHECKIN,
    FETCH_EVENT_CHECKIN_DATES,
    FETCH_EVENT_CHECKIN_ADD_ONS_DATES,
    UNSCAN_TICKET,
    RESET_CHECK_IN,
    FETCH_EVENT_CHECKIN_TICKETS
  }
)
export default class EventCheckIn extends React.Component {
  constructor(props) {
    super(props)
    const displayName = _get(props, 'event.displayName') || ''
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    this.state = {
      checkinTickets: {},
      dailyData: [],
      hourlyData: [],
      names: [],
      addOnBreakdownData: [],
      tickets: [],
      initialLoaded: false
    }
  }

  componentDidMount() {
    const { event, checkInData } = this.props

    const eventId = _get(event, 'id')
    const isTimeSlotEvent = _get(event, 'flagTimeSlotsEnabled') || false

    if (!eventId) return

    this.fetchCheckedInData(eventId)

    if (isTimeSlotEvent) {
      this.fetchCheckedInDates(eventId)
      this.fetchCheckedInAddonsDates(eventId)
    } else {
      this.fetchAddonsBreakDown(eventId)
      this.fetchEventTickets(eventId)
    }

    this.setCheckinData(checkInData)
  }

  componentDidUpdate(prevProps) {
    const { checkInData } = this.props
    if (!_isEqual(checkInData, prevProps.checkInData)) {
      this.setCheckinData(checkInData)
    }
  }

  componentWillUnmount() {
    const { RESET_CHECK_IN } = this.props
    RESET_CHECK_IN()
  }

  setCheckinData = checkInData => {
    if (_isEmpty(checkInData)) return

    const checkinTickets = _get(checkInData, 'tickets') || []
    const dailyData = _get(checkInData, 'check_in_stats.daily') || []
    const hourlyData = _get(checkInData, 'check_in_stats.hourly') || []
    const names = _get(checkInData, 'check_in_stats.names') || []
    const addOns = _get(checkInData, 'check_in_stats.add_ons') || []

    this.setState({
      checkinTickets,
      dailyData,
      hourlyData,
      names,
      addOns,
      initialLoaded: true
    })
  }

  fetchCheckedInData = async eventId => {
    const { FETCH_EVENT_CHECKIN } = this.props
    try {
      await FETCH_EVENT_CHECKIN(eventId)
    } catch (err) {
      showAxiosError(err)
    }
  }

  fetchCheckedInDates = async eventId => {
    const { FETCH_EVENT_CHECKIN_DATES } = this.props
    try {
      await FETCH_EVENT_CHECKIN_DATES(eventId)
    } catch (err) {
      showAxiosError(err)
    }
  }

  fetchCheckedInAddonsDates = async eventId => {
    const { FETCH_EVENT_CHECKIN_ADD_ONS_DATES } = this.props
    try {
      await FETCH_EVENT_CHECKIN_ADD_ONS_DATES(eventId)
    } catch (err) {
      showAxiosError(err)
    }
  }

  fetchAddonsBreakDown = async eventId => {
    try {
      const res = await getPerfomanceData(eventId, 'add_on_breakdown')
      this.setState({
        addOnBreakdownData: _get(res, 'add_on_breakdown')
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  fetchEventTickets = async eventId => {
    this.setState({ isLoadingTickets: true })
    try {
      const res = await getEventTickets(eventId)
      this.setState({
        tickets: _map(res, item => ({
          id: item.id,
          type: item.type,
          ...item.attributes
        }))
      })
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ isLoadingTickets: false })
    }
  }

  render() {
    const { event, checkInDates, checkInAddonsDates, isLoading, FETCH_EVENT_CHECKIN_TICKETS, checkInData } =
      this.props
    const {
      checkinTickets,
      dailyData,
      hourlyData,
      names,
      addOnBreakdownData,
      tickets,
      isLoadingTickets,
      addOns,
      initialLoaded
    } = this.state

    const isLimitedStats = event && _get(event, 'self.role') === 'limited_stats'
    const ticketsIndividual = _get(checkInData, 'tickets') || []

    const isTimeSlotEvent = event && _get(event, 'flagTimeSlotsEnabled')
    const eventId = event && _get(event, 'id')

    return (isLoading && !initialLoaded) || isLoadingTickets ? (
      <LoadingBar title="Hold tight! We're getting your event checked in details..." />
    ) : (
      <div className="checkin_container">
        <CheckInDownload />
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="checkin">
                <TabView
                  title="View Check-in Status:"
                  all={true}
                  headerClassName="check-in-tab-header"
                  hasPerfectScrollBar
                >
                  <Tab title="Date">
                    <CheckInDate data={dailyData} />
                  </Tab>
                  <Tab title="Hourly">
                    <CheckInHourly data={hourlyData} />
                  </Tab>
                  {!isLimitedStats && (
                    <Tab title="Ticket Type">
                      {isTimeSlotEvent ? (
                        <CheckInSlotTicketType event={event} checkInDates={checkInDates} />
                      ) : (
                        <CheckInTicketType event={event} tickets={tickets} ticket_checkins={checkinTickets} />
                      )}
                    </Tab>
                  )}
                  {!isLimitedStats && (
                    <Tab title="Add-Ons">
                      {isTimeSlotEvent ? (
                        <CheckInSlotAddOn event={event} checkInAddonsDates={checkInAddonsDates} />
                      ) : (
                        <CheckInAddOn add_ons={addOns} performance={addOnBreakdownData} />
                      )}
                    </Tab>
                  )}
                  {!isLimitedStats && (
                    <Tab title="By Staff Member">
                      <CheckInByStaffMember names={names} />
                    </Tab>
                  )}
                  {!isLimitedStats && (
                    <Tab title="Individual Tickets">
                      <CheckInIndividualTickets
                        tickets={ticketsIndividual}
                        fetchCheckedInData={() => this.fetchCheckedInData(eventId)}
                        isLoading={isLoading}
                        UNSCAN_TICKET={this.props.UNSCAN_TICKET}
                        FETCH_EVENT_CHECKIN_TICKETS={FETCH_EVENT_CHECKIN_TICKETS}
                        eventId={eventId}
                      />
                    </Tab>
                  )}
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
