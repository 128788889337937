import React from 'react'
import moment from 'moment'
import { DateRangePicker } from '../../_library/DateRangePicker'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import EmptyBar from '../../_library/EmptyBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { formatDay, formatDateTime, defaultDateFormat } from '../../../_common/core/validation/normalizers'
import { SaveTemplate, SelectFilterTemplate } from '../../_library/FilterTemplate'
import CardHeader from '../../_library/CardHeader'
import TrafficViewChart from './components/TrafficViewChart'
import { FETCH_EVENT_TRAFFIC } from '../../../_common/redux/performance/actions'
import { connect } from 'react-redux'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import { showAxiosError } from '../../utils/messenger'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'
import { DATE_TRAFFIC_SUPPORT } from '../performance/constants/constants'
import ProgressCircle from '../../_library/ProgressCircle'

@connect(null, { FETCH_EVENT_TRAFFIC })
export default class TrafficViews extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'created_at',
        label: 'View Date',
        isSortable: true,
        normalizer: formatDay,
      },
      {
        key: 'total',
        label: 'No. of Visitors',
        isSortable: true,
      },
      {
        key: 'conversion_visitors',
        label: 'No. of Conversions',
        isSortable: true,
      },
    ])

    this.detailsTableColumns = getTableColumns([
      {
        key: 'created_at',
        label: 'View Hour',
        className: 'detail-cell',
        isSortable: true,
        normalizer: formatDateTime,
      },
      {
        key: 'total',
        label: 'No. of Visitors',
        className: 'detail-cell',
        isSortable: true,
      },
      {
        key: 'conversion_visitors',
        label: 'No. of Conversions',
        className: 'detail-cell',
        isSortable: true,
      },
    ])

    this.footbarColumns = [
      { key: 'total', sourceKey: 'total' },
      { key: 'conversion_visitors', sourceKey: 'conversion_visitors' },
    ]

    this.state = {
      startDate: null,
      endDate: null,
      sortBy: { column: 'created_at', asc: false },
      sortBySubTable: { column: 'created_at', asc: true },
      filterUpdateVersion: 0,
      isLoading: true,
      time: 'day',
      unique: [],
      hourly: [],
    }

    this.hourly = []
    this.mounted = true
    this.trafficInterval = null
  }

  componentDidMount() {
    this.fetchTraffic()
    this.trafficInterval = setInterval(() => {
      this.fetchTraffic()
    }, 600 * 1000)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.trafficInterval)
  }

  fetchTraffic = async () => {
    const { FETCH_EVENT_TRAFFIC, event, setShowWarning, showWarning } = this.props
    const { time, startDate, endDate, isLoading, unique: stateUnique, hourly: stateHourly } = this.state
    if (!this.mounted) return
    try {
      const dates =
        startDate && endDate
          ? { date_from: startDate.format('YYYY-MM-DD'), date_to: endDate.format('YYYY-MM-DD') }
          : {}
      const uniqueRaw =
        (await FETCH_EVENT_TRAFFIC(event.id, {
          group_by: [time, 'unique'],
          page: 'confirmation',
          ...dates,
        })) ?? []
      const unique = _map(
        _groupBy(uniqueRaw, row =>
          time === 'week'
            ? moment(row.created_at).startOf('isoWeek').format(DAY_DISPLAY_FORMAT) +
              ' - ' +
              moment(row.created_at).endOf('isoWeek').format(DAY_DISPLAY_FORMAT)
            : moment(row.created_at).format(
                time === 'hour'
                  ? DAY_DISPLAY_FORMAT + ' h:[00] a'
                  : time === 'month'
                  ? 'MMMM YYYY'
                  : DAY_DISPLAY_FORMAT,
            ),
        ),
        rows => {
          rows[0].total = rows.length
          return rows[0]
        },
      )
      const conversions =
        (await FETCH_EVENT_TRAFFIC(event.id, {
          group_by: ['hour', 'unique'],
          page: 'confirmation',
          ...dates,
        })) ?? []
      const hourly =
        (await FETCH_EVENT_TRAFFIC(event.id, {
          group_by: ['hour', 'unique'],
          page: 'event',
          ...dates,
        })) ?? []
      let converted = 0
      let visitors = 0
      hourly.push(...conversions)
      const hourlyWithConversions = _map(
        _groupBy(hourly, row => moment(row.created_at, defaultDateFormat).format('YYYY-MM-DD h:[00] a')),
        hourList => {
          const [hourRow] = hourList
          let conversion_visitors = 0
          let total = 0
          for (const idx in hourList) {
            if (hourList[idx].page === 'EVENT') {
              total++
              visitors++
            } else {
              conversion_visitors++
              converted++
            }
          }
          hourRow.total = total
          hourRow.conversion_visitors = conversion_visitors
          return hourRow
        },
      )
      if (this.hourly.length === 0) {
        this.hourly = hourly
      }
      if (_isEqual(unique, stateUnique) && _isEqual(hourlyWithConversions, stateHourly) && !isLoading) {
        return
      }
      if (!showWarning) {
        if (
          hourlyWithConversions.find(row =>
            moment(row.created_at, defaultDateFormat).isBefore(
              moment(DATE_TRAFFIC_SUPPORT, defaultDateFormat),
            ),
          )
        ) {
          setShowWarning()
        }
      }
      this.setState({
        unique,
        hourly: hourlyWithConversions,
        converted,
        notConverted: visitors - converted,
        isLoading: false,
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  changeTime = time => {
    this.setState({ time, isLoading: true }, () => {
      this.fetchTraffic()
    })
  }

  onDateRangeApply = (startDate, endDate) => {
    this.setState({ startDate, endDate, isLoading: true }, () => {
      this.fetchTraffic()
    })
  }

  onDateRangeClear = () => {
    this.setState({ startDate: null, endDate: null, isLoading: true }, () => {
      this.fetchTraffic()
    })
  }

  isEmptyChecker = data => {
    const { startDate, endDate } = data
    return !(startDate && endDate)
  }

  onSaveFilter = () => {
    this.setState(s => ({ filterUpdateVersion: s.filterUpdateVersion + 1 }))
  }

  onSelectFilter = template => {
    let { value } = template
    try {
      value = JSON.parse(value)
      const { startDate, endDate } = value
      this.onDateRangeApply(moment(startDate), moment(endDate))
    } catch (e) {
      showAxiosError(e)
    }
  }
  getPercentage = (total, value) => Math.round((value * 100) / total)

  render() {
    const { event } = this.props
    const {
      startDate,
      endDate,
      sortBy,
      filterUpdateVersion,
      isLoading,
      time,
      unique,
      hourly,
      converted,
      notConverted,
    } = this.state
    const showSaveTemplateBtn = startDate && endDate
    const countState = hourly.length
    const total = notConverted + converted
    const convertedPercent = this.getPercentage(total, converted)
    const notConvertedPercent = this.getPercentage(total, notConverted)
    const count = this.hourly.length

    const days = hourly.reduce((curr, { total, conversion_visitors, created_at }) => {
      const date = moment(created_at).format('YYYY-MM-DD')
      const index = curr.findIndex(item => item.id === date)
      if (index !== -1) {
        curr[index].total += Number(total)
        curr[index].conversion_visitors += Number(conversion_visitors)
        return curr
      }
      curr.push({ id: date, total, conversion_visitors, created_at })
      return curr
    }, [])

    const detailsRows = Object.entries(
      _groupBy(hourly, hourRow => moment(hourRow.created_at).format('YYYY-MM-DD')),
    ).map(keyValue => ({
      id: keyValue[0],
      type: 'detailRow',
      component: (
        <SortableTable
          data={keyValue[1]}
          tableColumns={this.detailsTableColumns}
          enableSort={true}
          enableCopyTable={true}
          disableMobileView={true}
          className="child-table"
          sortBy={this.state.sortBySubTable}
        />
      ),
    }))

    return (
      <div ref="cardContainer" className="performance-sales traffic-conversions">
        <CardHeader
          imageUrl="/resources/images/event/performance/ticket-sales-ico.svg"
          bodyText="Conversions"
        />
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your statistics..."} />
          ) : this.hourly.length === 0 ? (
            <EmptyBar />
          ) : (
            <div>
              <TrafficViewChart traffic={unique} changeTime={this.changeTime} time={time} isConversions />
              {countState !== 0 ? (
                <div className="event-traffic-circles">
                  <div style={{ width: '600px' }} className="event-traffic-circle conversions">
                    <div className="col-xs-12 col-sm-4 blue-circle">
                      <ProgressCircle value={convertedPercent} />
                      <div className="circle-info">
                        <div className="circle-description">
                          <div className="description1">
                            <div className="circle-value">{converted}</div>
                            <div className="circle-type">Converted</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 pink-circle">
                      <ProgressCircle value={notConvertedPercent} />
                      <div className="circle-info">
                        <div className="circle-description">
                          <div className="description1">
                            <div className="circle-value">{notConverted}</div>
                            <div className="circle-type">Not Converted</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {count > 0 && (
                <div className="row performance-sales-daterange">
                  <div className="col-sm-8 col-xs-12">
                    <div
                      style={{ display: 'inline-block', marginTop: 10, marginBottom: 10, marginRight: 10 }}
                    >
                      <DateRangePicker
                        onApply={this.onDateRangeApply}
                        onClear={this.onDateRangeClear}
                        value={{ startDate, endDate }}
                      />
                    </div>
                    {showSaveTemplateBtn ? (
                      <div style={{ margin: '10px 0px', display: 'inline-block' }}>
                        <SaveTemplate
                          data={{
                            startDate,
                            endDate,
                          }}
                          isEmptyChecker={this.isEmptyChecker}
                          storagePath={`event-${event.id}-performance-ticket-sales-filter`}
                          onSaveFilter={this.onSaveFilter}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="col-sm-4 col-xs-12"
                    style={{ margin: '10px 0', textAlign: isMobileDevice() ? 'start' : 'end' }}
                  >
                    <SelectFilterTemplate
                      storagePath={`event-${event.id}-performance-ticket-sales-filter`}
                      onSelectFilter={this.onSelectFilter}
                      updateVersion={filterUpdateVersion}
                    />
                  </div>
                </div>
              )}
              <SortableTable
                data={days}
                tableColumns={this.tableColumns}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                sortBy={sortBy}
                detailsRows={detailsRows}
                footbar={{
                  label: 'Total',
                  columns: this.footbarColumns,
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
