import React from 'react'
import Modal from 'react-modal'
import { Formik, Form, Field } from 'formik'
import Button from '../../../_library/Button'
import { SimpleField, CustomToggleField } from '../../../formik/Fields'
import { toInt, requiredValidator } from '../../../../_common/core/validation'
import modalStyle from '../../../../_common/core/modalStyle'
import _isNumber from 'lodash/isNumber'
import _isNaN from 'lodash/isNaN'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { addOnStockNormalizer } from '../normalizers/addOnStockNormalizer'

const EditAddOnVariantModal = ({
  editingAddonVariant,
  handleSubmitEditAddon,
  handleCancelEditingAddonVariant,
  stockLabel,
}) => {
  const { name, stock, sortOrder } = editingAddonVariant
  const normalizedStock = addOnStockNormalizer(stock)
  return (
    <Modal
      className="modal-dialog modal-trans"
      isOpen={true}
      style={modalStyle}
      contentLabel="Modal"
      onRequestClose={handleCancelEditingAddonVariant}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="add-on-variant-edit-container modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div>Edit Add-On Variant</div>
          </div>
          <div className="modal-variants">
            <Formik
              initialValues={{
                name,
                stock: _isNumber(normalizedStock) ? normalizedStock : '',
                isUnlimited: !_isNumber(normalizedStock) ? true : false,
              }}
              validate={data => {
                const errors = {}
                const stock = _get(data, 'stock')
                if (_isNaN(stock) || stock < 0) {
                  _set(errors, 'stock', 'Value must be greater than or equal to 0')
                }
                return errors
              }}
              onSubmit={values => {
                handleSubmitEditAddon({ ...values, sortOrder })
              }}
              render={({ values, isSubmitting }) => (
                <Form autoComplete="off" className="addon-variant-edit-form">
                  <div className="row">
                    <div className="col-xs-6">
                      <Field
                        name="name"
                        label={'Name'}
                        component={SimpleField}
                        validate={requiredValidator()}
                      />
                    </div>
                    {!values.isUnlimited && (
                      <div className="col-xs-6">
                        <Field
                          name="stock"
                          label={stockLabel}
                          component={SimpleField}
                          normalizer={toInt}
                          validate={requiredValidator()}
                        />
                      </div>
                    )}
                    <div className="col-xs-12 col-sm-12" style={{ margin: '20px 0' }}>
                      <Field name="isUnlimited" onText="Unlimited" component={CustomToggleField} />
                    </div>
                  </div>
                  <div className="modal-footer center no-border">
                    <Button className="btn btn-success btn-shadow" loading={isSubmitting}>
                      Save
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      disabled={isSubmitting}
                      onClick={handleCancelEditingAddonVariant}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditAddOnVariantModal
