import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import { isJson } from '../utils/jsonUtils'

import Button from './Button'
import LoadingBar from './LoadingBar'

export default class IDVerificationModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingURL: true,
      netverifyUrl: '',
    }
  }

  componentDidMount() {
    this.unMounted = false
    window.addEventListener('message', this.callbackNetVerify)
  }

  componentWillUnmount() {
    this.unMounted = true
    window.removeEventListener('message', this.callbackNetVerify)
  }

  componentWillReceiveProps = nextProps => {
    let { verifyData: pVerifyData } = this.props
    const { doVerification } = this.props
    let { verifyData: nVerifyData } = nextProps
    pVerifyData = pVerifyData || null
    nVerifyData = nVerifyData || null

    if (nextProps.doVerification !== this.props.doVerification && nextProps.doVerification) {
      this.setState({
        isLoadingURL: true,
      })
    }
    if ((_isEmpty(pVerifyData) || doVerification !== nextProps.doVerification) && !_isEmpty(nVerifyData)) {
      this.setState({
        isLoadingURL: false,
        netverifyUrl: nVerifyData.netverifyUrl,
      })
    }
  }

  iframe = netverifyUrl => {
    // eslint-disable-next-line max-len
    const iframe = `<iframe allowFullScreen id="verificationIframe" frameBorder="0" width="100%" height="570px" allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" src="${netverifyUrl}"></iframe>`
    return {
      __html: iframe,
    }
  }

  hasError = false
  callbackNetVerify = e => {
    const { handleSubmitSuccess, handleSubmitError } = this.props

    // Stripe Events
    if (e.data && e.origin === "https://verify.stripe.com") {
      const {data} = e
      if (data.type === 'STRIPE_IDENTITY_CLOSE') {
        if (this.hasError) {
          this.hasError = false
          handleSubmitError()
        } else {
          this.hasError = false
          handleSubmitSuccess()
        }
      } else if (data.type === 'STRIPE_IDENTITY_ERROR') {
        this.hasError = true
      }
    }

    if (e.data && isJson(e.data)) {
      try {
        const result = JSON.parse(e.data)
        if (
          result.payload &&
          (result.payload.value === 'success' || result.payload.transactionStatus === 'SUCCESS')
        ) {
          handleSubmitSuccess()
        } else if (
          result.payload &&
          (result.payload.value === 'error' || result.payload.transactionStatus === 'ERROR')
        ) {
          handleSubmitError()
        }
      } catch (e) {}
    }
  }

  render() {
    const { doVerification, handleClose } = this.props
    const { netverifyUrl, isLoadingURL } = this.state
    return (
      <div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={doVerification}
          contentLabel="Modal"
          onRequestClose={handleClose}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Account Verification</p>
                </div>
                <div className="modal-body">
                  {doVerification && !isLoadingURL && (
                    <div
                      dangerouslySetInnerHTML={this.iframe(netverifyUrl)}
                      ref={f => {
                        this.ifr = f
                      }}
                    />
                  )}
                  {isLoadingURL && (
                    <LoadingBar
                      title={"Hold tight! We're initializing the account verification process..."}
                    />
                  )}
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button className="btn btn-default" type="button" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
