import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import { CreatePassTypeForm } from './multientrypasses/CreatePassTypeForm'
import { get_brand, get_event } from '../../_common/core/selectors'
import Card from '../_library/Card'
import _get from 'lodash/get'
import {
  deletePassType,
  createPassType,
  getAllPassesTypes,
  editPassType,
  createEntryPass,
  getAllPasses,
  deletePass,
  sendPass,
  importEntryPass,
  sendAllPasses
} from '../../_common/core/http_services'
import SortableTable from '../_library/SortableTable'
import { getTableColumns } from '../utils/sortableTableUtils'
import ConfirmModal from '../_library/ConfirmModal'
import EventPassForm from './multientrypasses/EventPassForm'
import SortableTableWithSearchHOC from '../hoc/SortableTableWithSearchHOC'
import SortableTableWithPaginationHOC from '../hoc/SortableTableWithPaginationHOC'
import { EditPassForm } from './multientrypasses/EditPassForm'
import { putEditPass } from '../../_common/core/http_services/multientrypasses'
import { showAxiosError, showSuccessMessage } from '../utils/messenger'
import Button from '../_library/Button'
import EmptyBar from '../_library/EmptyBar'
import { getTitle } from '../utils/getTitle'

const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)

const DEFAULT_PAGE_SIZE = 10

@withRouter
@connect(state => {
  const event = get_event(state)
  const brand = get_brand(state)
  return {
    event,
    brand
  }
}, null)
export default class EventMultiEntryPasses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      passTypes: [],
      showEditCreatePass: false,
      showDeletePassType: false,
      selectedType: null,
      createText: 'Create Pass Type',
      passes: [],
      selectedPass: null,
      showEditPass: false,
      showSendPass: false,
      showSendAllPasses: false,
      resetPagination: 0,
      searchValue: '',
      sortBy: { column: 'full_name', asc: false }
    }
    this.passTypesTableColumns = getTableColumns([
      { key: 'name', label: 'Name', isSortable: true },
      { key: 'description', label: 'Description', isSortable: false },
      {
        key: 'type',
        label: 'Type',
        isSortable: true
      },
      { key: 'maxUsesPerEvent', label: 'Max. num. of uses', isSortable: true }
    ])
    this.passesTableColumns = getTableColumns([
      { key: 'full_name', label: 'Name', isSortable: true },
      { key: 'email', label: 'Email', isSortable: true },
      {
        key: 'entrypassTypeName',
        label: 'Type',
        isSortable: true
      },
      { key: 'issuedByNotes', label: 'Notes', isSortable: false },
      { key: 'maxUsesPerEvent', label: 'Max. num. of uses', isSortable: true }
    ])
    this.mounted = true
  }

  componentDidMount() {
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }

    const {
      isEvent,
      isBrand,
      event,
      brand,
      configs
    } = this.props
    const { displayName: eventDisplayName } = event || {}
    const { displayName: brandDisplayName } = brand || {}

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    const displayName = isEvent ? eventDisplayName : isBrand ? brandDisplayName: ''
    document.title = getTitle(configDocTitle, [displayName])
    this.fetchAll()
  }

  fetchAll = async () => {
    try {
      const { event, brand, isEvent } = this.props
      const level = isEvent ? 'event' : 'brand'
      const id = isEvent ? event.id : brand.id
      const [passTypes, passes] = await Promise.all([getAllPassesTypes(id, level), getAllPasses(id, level)])
      this.setState({ passTypes, passes })
    } catch (err) {
      showAxiosError(err)
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  createPassType = async form => {
    const { event, brand, isEvent } = this.props
    const id = isEvent ? event.id : brand.id
    const level = isEvent ? 'event' : 'brand'
    try {
      await createPassType(id, level, form)
      showSuccessMessage('Successfully created!')
      this.handleModalOpenClose('showEditCreatePass', false, { selectedType: null })
      this.fetchAll()
    } catch (err) {
      showAxiosError(err)
    }
  }

  editPassType = async form => {
    const { event, brand, isEvent } = this.props
    const { selectedType } = this.state
    const id = isEvent ? event.id : brand.id
    const level = isEvent ? 'event' : 'brand'
    try {
      await editPassType(selectedType.id, id, level, form)
      showSuccessMessage('Successfully edited!')
      this.handleModalOpenClose('showEditCreatePass', false, { selectedType: null })
      this.fetchAll()
    } catch (err) {
      showAxiosError(err)
    }
  }

  deletePassType = async () => {
    const { selectedType } = this.state
    try {
      await deletePassType(selectedType.id)
      showSuccessMessage('Successfully deleted!')
      this.handleModalOpenClose('showDeletePassType', false, { selectedType: null })
      this.fetchAll()
    } catch (err) {
      showAxiosError(err)
    }
  }

  deletePass = async () => {
    const { selectedPass } = this.state
    try {
      await deletePass(selectedPass.id)
      showSuccessMessage('Successfully deleted!')
      this.handleModalOpenClose('showDeletePass', false, { selectedPass: null })
      this.fetchAll()
    } catch (err) {
      showAxiosError(err)
    }
  }

  sendPassToEmail = async () => {
    const { selectedPass } = this.state
    try {
      await sendPass(selectedPass.id)
      showSuccessMessage('Successfully sent!')
      this.handleModalOpenClose('showSendPass', false, { selectedPass: null })
    } catch (err) {
      showAxiosError(err)
    }
  }

  handleModalOpenClose = (modalName, value, props = {}) => {
    this.setState({ [modalName]: value, ...props })
  }

  onConfirmResponse = response => {
    switch (response) {
      case 'delete':
        return this.deletePassType()
      default:
        return this.handleModalOpenClose('showDeletePassType', false, { selectedType: null })
    }
  }

  onConfirmDeletePassResponse = response => {
    switch (response) {
      case 'delete':
        return this.deletePass()
      default:
        return this.handleModalOpenClose('showDeletePass', false, { selectedPass: null })
    }
  }

  onConfirmSendResponse = response => {
    switch (response) {
      case 'send':
        return this.sendPassToEmail()
      default:
        return this.handleModalOpenClose('showSendPass', false, { selectedPass: null })
    }
  }

  onConfirmSendAllResponse = response => {
    switch (response) {
      case 'send all':
        return this.sendAllPassesToEmail()
      default:
        return this.handleModalOpenClose('showSendAllPasses', false)
    }
  }

  sendPass = async form => {
    const { event, brand, isEvent } = this.props
    const id = isEvent ? event.id : brand.id
    const level = isEvent ? 'event' : 'brand'
    try {
      if (form.recipients_mode === 'csv') {
        await importEntryPass(id, level, form)
      } else {
        await createEntryPass(id, level, form)
      }
      showSuccessMessage('Successfully created!')
      this.fetchAll()
    } catch (err) {
      showAxiosError(err)
    }
  }

  editPass = async form => {
    const { selectedPass } = this.state
    try {
      await putEditPass(selectedPass.id, form)
      showSuccessMessage('Successfully edited!')
      this.fetchAll()
      this.handleModalOpenClose('showEditPass', false, { selectedPass: null })
    } catch (err) {
      showAxiosError(err)
    }
  }

  sendAllPassesToEmail = async () => {
    const { event, brand, isEvent } = this.props
    const id = isEvent ? event.id : brand.id
    const level = isEvent ? 'event' : 'brand'
    try {
      await sendAllPasses(id, level)
      showSuccessMessage('Successfully sent!')
      this.handleModalOpenClose('showSendAllPasses', false)
    } catch (err) {
      showAxiosError(err)
    }
  }

  getSearchedData = ({ searchValue }) => {
    this.setState({
      searchValue,
      resetPagination: this.state.resetPagination + 1
    })
  }

  render() {
    const { event } = this.props
    const {
      showEditCreatePass,
      passTypes,
      createText,
      showDeletePassType,
      selectedType,
      selectedPass,
      passes,
      showDeletePass,
      showEditPass,
      showSendPass,
      showSendAllPasses,
      resetPagination,
      searchValue,
      sortBy
    } = this.state

    const searchValueLower = searchValue.toLowerCase()

    const filteredPasses = passes
      .filter(
        el =>
          el.full_name.toLowerCase().includes(searchValueLower) ||
          el.email.toLowerCase().includes(searchValueLower) ||
          el.entrypassTypeName.toLowerCase().includes(searchValueLower) ||
          (el.maxUsesPerEvent || '').toLowerCase().includes(searchValueLower)
      )
      .sort((a, b) =>
        sortBy.asc
          ? a[sortBy.column].localeCompare(b[sortBy.column])
          : b[sortBy.column].localeCompare(a[sortBy.column])
      )

    return (
      <div className="EventInvitation">
        <h3 className="heading_style">Create New Pass Type</h3>
        <Card icon={'fa-envelope'} title={'Manage types'}>
          <div className="row">
            <div className="col-xs-12 text-right">
              <Button
                className="btn btn-success btn-shadow"
                onClick={() =>
                  this.handleModalOpenClose('showEditCreatePass', true, { createText: 'Create Pass Type' })
                }
              >
                Create new entry pass type
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="div-spacing-20" />
              {passTypes.length === 0 ? <EmptyBar /> : <SortableTable
                data={passTypes}
                tableColumns={this.passTypesTableColumns}
                enableSort={true}
                enableCopyTable={true}
                actions={[
                  {
                    label: 'Edit',
                    className: 'btn btn-primary',
                    icon: 'fa fa-pencil fa-fw',
                    onClick: selectedType =>
                      this.handleModalOpenClose('showEditCreatePass', true, {
                        createText: 'Edit Pass Type',
                        selectedType
                      })
                  },
                  {
                    label: 'Delete',
                    className: 'btn btn-danger',
                    icon: 'fa fa-trash fa-fw',
                    onClick: selectedType =>
                      this.handleModalOpenClose('showDeletePassType', true, { selectedType })
                  }
                ]}
              />}
            </div>
          </div>
        </Card>
        <h3 className="heading_style">Create New Pass</h3>
        <EventPassForm passTypes={passTypes} onSubmit={this.sendPass} />
        <Card icon={'fa-envelope'} title={'Existing Passes'}>
          <div className="row">
            <div className="col-xs-12">
              {filteredPasses.length === 0 ? <EmptyBar /> :
              <SortableTableWithSearchAndPagination
                resetPagination={resetPagination}
                getSearchedData={this.getSearchedData}
                data={filteredPasses}
                tableColumns={this.passesTableColumns}
                enableCopyTable={true}
                sortBy={sortBy}
                searchBy={this.searchBy}
                disableMobileView={true}
                enableSort={true}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                isAsyncSearch={true}
                showAsTag
                searchValue={searchValue}
                actions={[
                  {
                    label: 'Send',
                    className: 'btn btn-success btn-shadow',
                    icon: 'fa fa-paper-plane fa-fw',
                    onClick: selectedPass => this.handleModalOpenClose('showSendPass', true, { selectedPass })
                  },
                  {
                    label: 'Edit',
                    className: 'btn btn-primary btn-shadow',
                    icon: 'fa fa-pencil fa-fw',
                    onClick: selectedPass => this.handleModalOpenClose('showEditPass', true, { selectedPass })
                  },
                  {
                    label: 'Delete',
                    className: 'btn btn-danger btn-shadow',
                    icon: 'fa fa-times fa-fw',
                    onClick: selectedPass =>
                      this.handleModalOpenClose('showDeletePass', true, { selectedPass })
                  }
                ]}
              />}
            </div>
          </div>
          {passes.length !== 0 ? <div className="row">
            <div className="col-xs-12">
              <div className="col-xs-12 text-right">
                <Button
                  className="btn btn-success btn-shadow"
                  onClick={() => this.handleModalOpenClose('showSendAllPasses', true)}
                >
                  Send All
                </Button>
              </div>
            </div>
          </div> : null}
        </Card>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showEditCreatePass}
          contentLabel="Modal"
          onRequestClose={() =>
            this.handleModalOpenClose('showEditCreatePass', false, { selectedType: null })
          }
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">{createText}</div>
              <div className="modal-body">
                <CreatePassTypeForm
                  onSubmit={createText === 'Create Pass Type' ? this.createPassType : this.editPassType}
                  onCancel={() =>
                    this.handleModalOpenClose('showEditCreatePass', false, { selectedType: null })
                  }
                  submitLabel="Save"
                  initialValues={selectedType}
                  event={event}
                />
              </div>
            </div>
          </div>
        </Modal>
        <ConfirmModal
          isOpen={showDeletePassType}
          header={'Warning'}
          content={'Are you sure you want to delete this Pass Type?'}
          actions={[
            { value: 'delete', label: 'Delete', className: 'btn btn-danger btn-shadow' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' }
          ]}
          onAction={this.onConfirmResponse}
        />
        <ConfirmModal
          isOpen={showDeletePass}
          header={'Warning'}
          content={'Are you sure you want to delete this Pass?'}
          actions={[
            { value: 'delete', label: 'Delete', className: 'btn btn-danger btn-shadow' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' }
          ]}
          onAction={this.onConfirmDeletePassResponse}
        />
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showEditPass}
          contentLabel="Modal"
          onRequestClose={() =>
            this.handleModalOpenClose('showEditCreatePass', false, { selectedType: null })
          }
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">{'Edit Pass'}</div>
              <div className="modal-body">
                <EditPassForm
                  onSubmit={this.editPass}
                  onCancel={() => this.handleModalOpenClose('showEditPass', false, { selectedPass: null })}
                  submitLabel="Save"
                  initialValues={selectedPass}
                  event={event}
                  passTypes={passTypes}
                />
              </div>
            </div>
          </div>
        </Modal>
        <ConfirmModal
          isOpen={showSendPass}
          header={'Confirmation'}
          content={'Are you sure you want to send this Pass?'}
          actions={[
            { value: 'send', label: 'Send', className: 'btn btn-success btn-shadow' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' }
          ]}
          onAction={this.onConfirmSendResponse}
        />
        <ConfirmModal
          isOpen={showSendAllPasses}
          header={'Confirmation'}
          content={'Are you sure you want to send all Passes?'}
          actions={[
            { value: 'send all', label: 'Send All', className: 'btn btn-success btn-shadow' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel' }
          ]}
          onAction={this.onConfirmSendAllResponse}
        />
      </div>
    )
  }
}
