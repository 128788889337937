import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  usersData: {
    checked: false,
    exist: false,
    data: {},
  },
}

const FETCH_USERS = createAsyncHandlers('FETCH_USERS', {
  success(state, action) {
    const { data } = action.payload

    state.usersData = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.usersData = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

const UPDATE_USER_TYPE = createAsyncHandlers('UPDATE_USER_TYPE', {
  success(state, action) {
    const { data } = action.payload

    state.usersData = {
      data,
      checked: true,
      exist: true,
    }
  },
  failed(state) {
    state.usersData = {
      data: {},
      checked: true,
      exist: false,
    }
  },
})

export default handleActions(
  {
    ...FETCH_USERS,
    ...UPDATE_USER_TYPE,
  },
  initialState,
)
