import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import Papa from 'papaparse'
import Select from 'react-select'
import Modal from 'react-modal'
import { withFormik } from 'formik'
import { Field as FormikField } from 'formik'
import { withRouter } from 'react-router-dom'

import Field from '../../_library/Field'
import Button from '../../_library/Button'
import RichTextArea from '../../_library/RichTextArea'
import modalStyle from '../../../_common/core/modalStyle'
import { Tab, TabView } from '../../_library/TabView'
import { ENV } from '../../constants/env'
import Card from '../../_library/Card'
import { SelectField } from '../../formik/Fields'
import {
  requiredValidator,
  intValidator,
  combineValidators,
  maxNumberValidator
} from '../../../_common/core/validation'

const selectOptions = _map(new Array(30), (e, i) => ({ value: 30 - i, label: 30 - i }))

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function validateForm(data) {
  const errors = {}

  if (!_get(data, 'subject')) {
    _set(errors, 'subject', 'Required')
  }

  if (!_get(data, 'body')) {
    _set(errors, 'body', 'Required')
  }

  const recipients_mode = _get(data, 'recipients_mode')

  if (recipients_mode == 'csv') {
    const recipients = _get(data, 'recipients')
    if (!recipients || recipients.length == 0) {
      _set(errors, 'recipients', 'You must import at least one recipient.')
    } else {
      recipients.forEach((r, index) => {
        if (!r.first_name) {
          _set(errors, 'recipients[' + index + '].first_name', 'Required')
        }
        /*if(!r.last_name) {
					_set(errors, 'recipients['+index+'].last_name', 'Required')
				}*/
        if (!r.email) {
          _set(errors, 'recipients[' + index + '].email', 'Required')
        } else if (!validateEmail(r.email)) {
          _set(errors, 'recipients[' + index + '].email', 'Invalid')
        }
      })
    }
  }

  if (recipients_mode == 'form') {
    const recipient = _get(data, 'recipient')
    if (!recipient.first_name) {
      _set(errors, 'recipient.first_name', 'Required')
    }
    /*if (!recipient.last_name){
			_set(errors, 'recipient.last_name', 'Required')
		}*/
    if (!recipient.email) {
      _set(errors, 'recipient.email', 'Required')
    } else if (!validateEmail(recipient.email)) {
      _set(errors, 'recipient.email', 'Invalid Email Address')
    }
  }
  return errors
}

@withRouter
class EventInvitationFormComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      emailTemplatesModalOpen: false,
      csvParsing: false,
      csvParseCountAll: 0,
      csvParseCount: 0,
    }

    this.onBodyChange = this.onBodyChange.bind(this)
    this.onTicketTypeChange = this.onTicketTypeChange.bind(this)
    this.selectRecipientsMode = this.selectRecipientsMode.bind(this)
    this.closeEmailTemplatesModal = this.closeEmailTemplatesModal.bind(this)
    this.editorRef = React.createRef()
  }

  openCSV() {
    $(this.refs.fileCSV).click()
  }

  importRecipientsCSV(e) {
    const that = this

    this.props.setFieldValue('recipients', [])

    const ext = e.target.files[0].name.split('.').pop()

    // if(e.target.files[0].type === 'text/csv') {
    if (ext === 'csv') {
      this.setState({
        csvParsing: true,
        csvParseCountAll: 0,
        csvParseCount: 0
      })

      Papa.parse(e.target.files[0], {
        complete (results) {
          const data = []
          for (let j = 0; j < results.data.length; j++) {
            if (results.data[j].length === 1 && results.data[j][0].trim() === '') {
            } else {
              data.push(results.data[j])
            }
          }
          let i = 0
          that.setState({ csvParseCountAll: data.length })
          const addRecipientInterval = setInterval(() => {
            const dd = data.slice(i, i + 100).map(r => {
              const maxQuantity = !!r[3] ? r[3].trim() : ''
              const maxUses = !!r[4] ? r[4].trim() : ''
              return ({
                first_name: r[0] && r[0].trim(),
                last_name: r[1] && r[1].trim(),
                email: r[2] && r[2].trim() && r[2].replace('+AEA-', '@'),
                maxQuantity,
                maxUses: Number(maxUses) > Number(maxQuantity) ? maxQuantity : maxUses
              })
            })
            that.props.setFieldValue('recipients', [...that.props.values.recipients, ...dd])
            i += 100
            that.setState({ csvParseCount: i })
            if (i >= data.length) {
              clearInterval(addRecipientInterval)
              that.setState(
                {
                  csvParsing: false
                },
                () => {
                  that.touchRecipientFields()
                }
              )
            }
          }, 100)
        }
      })
      // } else if (e.target.files[0].type === 'application/vnd.ms-excel' || e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    } else if (ext === 'xls' || ext === 'xlsx') {
      Messenger().post({
        type: 'error',
        message: 'Please upload a CSV file instead of an Excel format file',
        hideAfter: 5,
        showCloseButton: true
      })
    } else {
      Messenger().post({
        type: 'error',
        message: 'Please upload a CSV file',
        hideAfter: 5,
        showCloseButton: true
      })
    }

    $(this.refs.fileCSV).val('')
  }

  touchRecipientFields() {
    for (let i = 0; i < this.props.values.recipients.length; i++) {
      this.props.setFieldTouched('recipients[' + i + '].first_name', true)
      this.props.setFieldTouched('recipients[' + i + '].last_name', true)
      this.props.setFieldTouched('recipients[' + i + '].email', true)
      this.props.setFieldTouched('recipients[' + i + '].maxQuantity', true)
      this.props.setFieldTouched('recipients[' + i + '].maxUses', true)
    }
  }

  deleteRecipient(index) {
    const recipients = [...this.props.values.recipients]
    recipients.splice(index, 1)
    this.props.setFieldValue('recipients', recipients)
  }

  openEmailTemplatesModal() {
    this.setState({
      emailTemplatesModalOpen: true
    })
  }

  closeEmailTemplatesModal() {
    this.setState({
      emailTemplatesModalOpen: false
    })
  }

  selectEmailTemplate(template) {
    this.props.setFieldValue('subject', template.subject)
    if (template.body) {
      this.editorRef.current?.setContent(template.body)
    } else if (template.preview_url) {
      $.get(template.preview_url, result => {
        this.editorRef.current?.setContent(result)
      })
    }
    this.setState({
      emailTemplatesModalOpen: false
    })
  }

  selectRecipientsMode(index) {
    this.props.setFieldValue('recipients_mode', index == 1 ? 'form' : 'csv')
  }

  changeMaxQuantity(e, rowIndex, val) {
    if (rowIndex != undefined) {
      this.props.setFieldValue('recipients[' + rowIndex + '].maxQuantity', val)
      this.props.setFieldValue('recipients[' + rowIndex + '].maxUses', val)
    } else {
      this.props.setFieldValue('recipient.maxQuantity', e.target.value)
      this.props.setFieldValue('recipient.maxUses', e.target.value)
    }
  }

  changeMaxUses(e, rowIndex, val) {
    const { values } = this.props
    if (rowIndex != undefined) {
      const { maxQuantity } = values.recipients[rowIndex]
      const maxUses = val
      if (Number(maxUses) > Number(maxQuantity)) {
        this.props.setFieldValue('recipients[' + rowIndex + '].maxQuantity', val)
        this.props.setFieldValue('recipients[' + rowIndex + '].maxUses', val)
      } else {
        this.props.setFieldValue('recipients[' + rowIndex + '].maxUses', val)
      }
    } else {
      const { maxQuantity } = values.recipient
      const maxUses = e.target.value
      if (Number(maxUses) > Number(maxQuantity)) {
        this.props.setFieldValue('recipient.maxQuantity', e.target.value)
        this.props.setFieldValue('recipient.maxUses', e.target.value)
      } else {
        this.props.setFieldValue('recipient.maxUses', e.target.value)
      }
    }
  }

  onTicketTypeChange(types) {
    this.props.setFieldValue(
      'ticketTypeID',
      _map(types, t => t.value)
    )
  }

  onBodyChange(content) {
    this.props.setFieldValue('body', content)
  }

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      isValid,
      submitLabel,
      event,
      tickets,
      emailTemplates,
      cardsStatus,
      history: { replace }
    } = this.props

    const {
      emailTemplatesModalOpen,
      csvParsing,
      csvParseCountAll,
      csvParseCount,
    } = this.state

    const ticketsHidden = tickets.filter(ticket => ticket.flagHidden)
    const ticketsHiddenOptions = _map(ticketsHidden, t => ({ value: t.id, label: t.displayName }))

    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <Card icon={'fa-envelope'} title={'Message'} status={cardsStatus && cardsStatus.message}>
          <div className="row">
            <div className="col-xs-12 text-right">
              <div className="btn btn-success btn-shadow" onClick={this.openEmailTemplatesModal.bind(this)}>
                Select from Email Templates
              </div>
            </div>
            <div className="col-xs-12">
              <Field
                id="subject"
                label="Subject"
                value={values.subject}
                error={errors.subject}
                touched={touched.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-xs-12">
              <label className="control-label">
                <span>Invitation Content</span>
              </label>
              <RichTextArea
                ref={this.editorRef}
                id="body"
                baseurl={ENV.CDN_URL}
                value={values.body}
                error={errors.body}
                touched={touched.body}
                onChange={this.onBodyChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </Card>
        <Card
          icon={'fa-unlock-alt'}
          title={'Unlock Registration for Hidden Ticket Type (optional)'}
          status={cardsStatus && cardsStatus.unlock}
          className="card-hidden-ticket"
        >
          <Select
            id="hidden-ticket-type"
            multi={true}
            value={values.ticketTypeID}
            options={ticketsHiddenOptions}
            onChange={this.onTicketTypeChange}
          />
        </Card>
        <Card icon={'fa-users'} title={'Recipients'} status={cardsStatus && cardsStatus.recipients}>
          <TabView all={false} onSelectTab={this.selectRecipientsMode}>
            <Tab title="Enter Recipient">
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="First name"
                    type="text"
                    id="recipient.first_name"
                    value={values.recipient.first_name}
                    error={errors.recipient && errors.recipient.first_name}
                    touched={touched.recipient && touched.recipient.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="Last name"
                    type="text"
                    id="recipient.last_name"
                    value={values.recipient.last_name}
                    error={errors.recipient && errors.recipient.last_name}
                    touched={touched.recipient && touched.recipient.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field
                    label="Email"
                    type="email"
                    id="recipient.email"
                    value={values.recipient.email}
                    error={errors.recipient && errors.recipient.email}
                    touched={touched.recipient && touched.recipient.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="recipient.maxQuantity" className="number-dropdown-label">
                      Maximum number of tickets
                    </label>
                    <select
                      id="recipient.maxQuantity"
                      className="form-control"
                      value={values.recipient.maxQuantity}
                      onChange={e => this.changeMaxQuantity(e)}
                    >
                      {_map(new Array(30), (e, i) => (
                        <option key={30 - i} value={30 - i}>
                          {30 - i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="recipient.maxUses" className="number-dropdown-label">
                      Maximum number of transactions
                    </label>
                    <select
                      id="recipient.maxUses"
                      className="form-control"
                      value={values.recipient.maxUses}
                      onChange={e => this.changeMaxUses(e)}
                    >
                      {_map(new Array(30), (e, i) => (
                        <option key={30 - i} value={30 - i}>
                          {30 - i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab title="Upload Recipients">
              <div className="row">
                <div className="col-sm-10 col-xs-12">
                  Recipients (CSV file with first name, last name, email address, max tickets and max
                  transactions columns and no header row)
                </div>
                <div className="col-sm-2 col-xs-12 text-right">
                  <div className="btn btn-success btn-shadow btn-importcsv" onClick={this.openCSV.bind(this)}>
                    <i className="fa fa-upload" aria-hidden="true" /> Add from CSV
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept=".csv"
                onChange={this.importRecipientsCSV.bind(this)}
                ref="fileCSV"
                className="file-csv"
              />
              <div className="recipients">
                <table className="table recipients-table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Maximum number of tickets</th>
                      <th>Maximum number of transactions</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {csvParsing && (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                          &nbsp;&nbsp;Loaded {csvParseCount} of {csvParseCountAll}
                        </td>
                      </tr>
                    )}
                    {values.recipients &&
                      values.recipients.map((recip, index) => (
                        <tr key={index}>
                          <td>
                            <Field
                              type="text"
                              id={'recipients[' + index + '].first_name'}
                              value={values.recipients[index].first_name}
                              error={
                                errors.recipients &&
                                errors.recipients[index] &&
                                errors.recipients[index].first_name
                              }
                              touched={
                                touched.recipients &&
                                touched.recipients[index] &&
                                touched.recipients[index].first_name
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </td>
                          <td>
                            <Field
                              type="text"
                              id={'recipients[' + index + '].last_name'}
                              value={values.recipients[index].last_name}
                              error={
                                errors.recipients &&
                                errors.recipients[index] &&
                                errors.recipients[index].last_name
                              }
                              touched={
                                touched.recipients &&
                                touched.recipients[index] &&
                                touched.recipients[index].last_name
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </td>
                          <td>
                            <Field
                              type="email"
                              id={'recipients[' + index + '].email'}
                              value={values.recipients[index].email}
                              error={
                                errors.recipients &&
                                errors.recipients[index] &&
                                errors.recipients[index].email
                              }
                              touched={
                                touched.recipients &&
                                touched.recipients[index] &&
                                touched.recipients[index].email
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </td>
                          <td>
                            <FormikField
                              name={'recipients[' + index + '].maxQuantity'}
                              component={SelectField}
                              defaultOption={{ value: '', label: '' }}
                              value={values.recipients[index].maxQuantity}
                              options={selectOptions}
                              validate={combineValidators(
                                requiredValidator(),
                                intValidator(),
                                maxNumberValidator('invalid', 30)
                              )}
                              onChange={(e, val) => this.changeMaxQuantity(e, index, val)}
                            />
                          </td>
                          <td>
                            <FormikField
                              name={'recipients[' + index + '].maxUses'}
                              component={SelectField}
                              defaultOption={{ value: '', label: '' }}
                              value={values.recipients[index].maxUses}
                              options={selectOptions}
                              validate={combineValidators(
                                requiredValidator(),
                                intValidator(),
                                maxNumberValidator('invalid', 30)
                              )}
                              onChange={(e, val) => this.changeMaxUses(e, index, val)}
                            />
                          </td>
                          <td>
                            <div
                              className="delete-recipient"
                              onClick={this.deleteRecipient.bind(this, index)}
                            >
                              <i className="fa fa-trash" aria-hidden="true" />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {(!values.recipients || values.recipients.length == 0) && (
                <div className="addnew-msg">Please Add New Recipients</div>
              )}
            </Tab>
          </TabView>
        </Card>
        <div className="row text-center">
          {isValid &&
            (values.recipients_mode === 'csv'
              ? !csvParsing && values.recipients && values.recipients.length > 0
              : true) && (
            <Button
              className="btn btn-success btn-lg btn-shadow btn-send"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {submitLabel || (
                <span>
                  <i className="fa fa-paper-plane" aria-hidden="true" /> Send{' '}
                </span>
              )}
            </Button>
          )}
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!emailTemplatesModalOpen}
          contentLabel="Modal"
          onRequestClose={this.closeEmailTemplatesModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Select Email Template</p>
                </div>
                <div className="modal-body">
                  <div className="emailtemplates-table-container">
                    <div className="email-template-containter">
                      <div className="email-tempalate-title clearfix">
                        <span className="template-left-side">Details</span>
                        <span className="template-right-side mobile-template-action">Action</span>
                      </div>
                      <div className="email-template-content">
                        {emailTemplates &&
                          emailTemplates.map((template, index) => (
                            <div key={index} className="clearfix">
                              {template.enabled === 1 && (
                                <div className="template-list">
                                  <div className="template-left-side">
                                    <div>
                                      <b className="template-subtitle">Name:</b>
                                      {template.name}
                                    </div>
                                    <div>
                                      <b className="template-subtitle">Subject:</b>
                                      {template.subject}
                                    </div>
                                    <div>
                                      <b className="template-subtitle">Description:</b>
                                      {template.description}
                                    </div>
                                  </div>
                                  <div className="template-right-side clearfix">
                                    <span
                                      className="btn btn-primary btn-shadow select-template-btn"
                                      onClick={this.selectEmailTemplate.bind(this, template)}
                                    >
                                      Select
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => replace(`/event/${event.id}/email-templates`)}
                    >
                      Add New Template
                    </Button>
                    <Button className="btn btn-default" type="button" onClick={this.closeEmailTemplatesModal}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

const EventInvitationForm = withFormik({
  mapPropsToValues: props => ({
    subject: _get(props, 'initialValues.attributes.subject') || '',
    body: _get(props, 'initialValues.attributes.body') || '',
    ticketTypeID: _get(props, 'initialValues.attributes.ticketTypeID') || [],
    recipients_mode: _get(props, 'initialValues.attributes.recipients_mode') || 'form',
    recipient: _get(props, 'initialValues.attributes.recipient') || {
      first_name: '',
      last_name: '',
      email: '',
      maxQuantity: 1,
      maxUses: 1
    },
    recipients: _get(props, 'initialValues.attributes.recipients') || []
  }),

  // Custom sync validation
  validate: values => validateForm(values),

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props
      .onSubmit({
        ...values
      })
      .then(() => {
        setSubmitting(false)
        resetForm({
          subject: _get(props, 'initialValues.attributes.subject') || '',
          body: _get(props, 'initialValues.attributes.body') || '',
          ticketTypeID: _get(props, 'initialValues.attributes.ticketTypeID') || [],
          recipients_mode: _get(values, 'recipients_mode') || 'form',
          recipient: _get(props, 'initialValues.attributes.recipient') || {
            first_name: '',
            last_name: '',
            email: '',
            maxQuantity: 1,
            maxUses: 1
          },
          recipients: _get(props, 'initialValues.attributes.recipients') || []
        })
      })
      .catch(() => {
        setSubmitting(false)
      })
  },
  displayName: 'EventInvitationForm' // helps with React DevTools
})(EventInvitationFormComponent)

export default EventInvitationForm
