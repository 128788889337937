import _find from 'lodash/find'
import { createAsyncAction } from '../actions'
import { fetchAPI, formatFiltersAsParam } from '../../core/http'
import { ERROR } from '../notification/actions'
import { baseRequest } from '../../core/http_services'
import { showAxiosError, showSuccessMessage } from '../../../web/utils/messenger'

const { FETCH_EVENT_TEAM_INVITATIONS } = createAsyncAction('FETCH_EVENT_TEAM_INVITATIONS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/members/invitations`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { FETCH_EVENT_PROMOTER_GENERIC_INVITATION } = createAsyncAction(
  'FETCH_EVENT_PROMOTER_GENERIC_INVITATION',
  function (eid) {
    return dispatch => {
      const filter = formatFiltersAsParam({ type: 'generic' })

      return fetchAPI(`/api/event/${eid}/members/invitations`, {
        params: {
          ...filter,
        },
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const promoterInvitation = _find(res.data, invitation => invitation.role === 'promoter')
          dispatch(this.success(promoterInvitation))
          return promoterInvitation
        })
    }
  },
)

const { UPDATE_EVENT_PROMOTER_GENERIC_INVITATION } = createAsyncAction(
  'UPDATE_EVENT_PROMOTER_GENERIC_INVITATION',
  function (token, form) {
    return dispatch =>
      fetchAPI(`/api/team-invitations/${token}`, {
        method: 'PUT',
        body: JSON.stringify({ data: { attributes: { ...form } } }),
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          dispatch(this.success(res))
          return res
        })
  },
)

const { ADD_EVENT_TEAM_INVITATION } = createAsyncAction('ADD_EVENT_TEAM_INVITATION', function (eid, form) {
  return dispatch => {
    form.attributes.eventId = parseInt(eid)
    return fetchAPI('/api/team-invitations', {
      method: 'POST',
      body: JSON.stringify({ data: { ...form } }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
  }
})

const { FETCH_EVENT_TEAM_MEMBERS } = createAsyncAction('FETCH_EVENT_TEAM_MEMBERS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/members`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { DELETE_PERMISSION } = createAsyncAction('DELETE_PERMISSION', function (pid) {
  return dispatch =>
    fetchAPI(`/api/permissions/${pid}`, {
      method: 'DELETE',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { EDIT_PERMISSION } = createAsyncAction('EDIT_PERMISSION', function (pid, form) {
  return dispatch =>
    baseRequest
      .put(`/permissions/${pid}`, {
        data: form,
      })
      .catch(err => {
        showAxiosError(err)
        throw err
      })
      .then(res => {
        showSuccessMessage('Successfully edited')
        dispatch(this.success(res))
        return res
      })
})

const { CANCEL_INVITATION } = createAsyncAction('CANCEL_INVITATION', function (token) {
  return dispatch =>
    fetchAPI(`/api/team-invitations`, {
      method: 'DELETE',
      params: {
        token,
      },
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { RESEND_INVITATION } = createAsyncAction('RESEND_INVITATION', function (eid, email) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/team-invitation/reminder`, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: {
            emails: [email],
          },
        },
      }),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT } = createAsyncAction(
  'REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT',
  function (token) {
    return dispatch =>
      fetchAPI(`/api/team-invitations/regenerate`, {
        method: 'POST',
        params: {
          token,
        },
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          dispatch(this.success(res.data.token))
          return res.data.token
        })
  },
)

export {
  FETCH_EVENT_TEAM_INVITATIONS,
  FETCH_EVENT_PROMOTER_GENERIC_INVITATION,
  UPDATE_EVENT_PROMOTER_GENERIC_INVITATION,
  ADD_EVENT_TEAM_INVITATION,
  FETCH_EVENT_TEAM_MEMBERS,
  DELETE_PERMISSION,
  CANCEL_INVITATION,
  RESEND_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT,
  EDIT_PERMISSION,
}
