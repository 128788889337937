import React, { Component } from 'react'
import Button from '../../_library/Button'

export default class PreRegistrationPrizeComponent extends Component {
  render() {
    const { data, handleEditClick, handleDeleteModalVisible } = this.props
    return (
      <div className="pre_reg_prize_component">
        <div className="image_container">
          <img src={data.image || '/resources/images/no_img.png'} />
        </div>
        <div className="text_container">
          <div className="text_component">{data.name}</div>
          <div className="buttons_component">
            <Button
              className="btn btn-primary"
              type="button"
              onClick={() => handleEditClick(data)}
            >
              <i className="fa fa-pencil fa-fw" />
              Edit
            </Button>
            <Button
              className="btn btn-seablue"
              type="button"
              onClick={() => handleDeleteModalVisible(data.id)}
            >
              <i className="fa fa-trash" />
              Delete
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
