import React, { useState } from 'react'
import { Form, Formik, Field } from 'formik'
import { SimpleField } from '../formik/Fields'
import { combineValidators, requiredValidator, isEmailValidator } from '../../_common/core/validation/'
import { showAxiosError } from '../utils/messenger'

const RnForgotPasswordForm = props => {
  const [loading, setLoading] = useState(false)
  const [serverSuccess, setServerSuccess] = useState(false)
  const [serverMessage, setServerMessage] = useState('')

  return (
    <div className="rn_login_page">
      <div className="rn_login_page__header">
        <div className="rn_login_page__svgContent">
          <div className="rn_login__header__leftside">
            <div className="rn_login_page__image">
              <img src={asset('/resources/images/rn-logo.svg')} alt="no data" />
            </div>
            <div className="rn_login_page__restlessnites__title">Restless Nites</div>
          </div>
          <div className="header-subtext-container">
            {'POWERED BY'}
            <br />
            {'THE'}
            <strong>{'TICKET'}</strong>
            {'FAIRY'}
          </div>
        </div>
        <div className="rn_login_modal-title">Password Reset</div>
      </div>
      {serverSuccess ? (
        <p className="forgot_password_info_block">{serverMessage}</p>
      ) : (
        <Formik
          initialValues={{
            username: props.initialEmail || ''
          }}
          onSubmit={values => {
            setLoading(true)
            try {
              props.onRestorePassword(values.username).then(data => {
                if (data.success) {
                  setServerSuccess(true)
                  setServerMessage(data.message)
                }
              })
            } catch (e) {
              showAxiosError(e)
            } finally {
              setLoading(false)
            }
          }}
          render={() => (
            <Form>
              <div className="rn_login_page_content">
                <div className="rn_login_email">
                  <Field
                    name="username"
                    placeholder="Email address"
                    component={SimpleField}
                    validate={combineValidators(requiredValidator(), isEmailValidator())}
                  />
                </div>
                <div className="rn_login_button">
                  <button type="submit" disabled={loading}>
                    SUBMIT
                  </button>
                </div>
                <div className="rn_login_actions_content">
                  <div aria-hidden={true} onClick={props.handleBackToLogin}>
                    Back to Log In
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      )}
    </div>
  )
}

export default RnForgotPasswordForm
