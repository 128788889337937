import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _each from 'lodash/each'
import React from 'react'
import { withFormik } from 'formik'
import Modal from 'react-modal'

import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'
import TextArea from '../../_library/TextArea'
import Field from '../../_library/Field'

import * as fb from '../../../_common/core/fb'
import EventBrandLevelFBPixel from './EventBrandLevelFBPixel'

const rgx = /'init','(.*)'\);fbq/g
const normalize = /[\s|\n]+/g

function validate(data, brandPixelData) {
  const gaIdRegexp = /^ua-\d{4,9}-\d{1,4}$/i
  const ga4IdRegexp = /^g-[A-Za-z0-9]{4,}$/i
  const gtmRegex = /^gtm-[A-Z0-9]{4,9}$|^aw-[A-Z0-9]{4,}$/i
  const errors = {}

  const pagePixels = _get(data, 'pagePixels')
  const checkoutPixels = _get(data, 'checkoutPixels')
  const conversionPixels = _get(data, 'conversionPixels')
  const googleAnalyticsKey = _get(data, 'googleAnalyticsKey')
  const googleAnalyticsMeasurementId = _get(data, 'googleAnalyticsMeasurementId')
  const googleTagManagerKey = _get(data, 'googleTagManagerKey')

  if (
    brandPixelData &&
    brandPixelData.pagePixels &&
    checkDuplicateFBPixel(pagePixels, brandPixelData.pagePixels)
  ) {
    _set(errors, 'pagePixels', 'This code is already imported at Brand level')
  }
  if (
    brandPixelData &&
    brandPixelData.checkoutPixels &&
    checkDuplicateFBPixel(checkoutPixels, brandPixelData.checkoutPixels)
  ) {
    _set(errors, 'checkoutPixels', 'This code is already imported at Brand level')
  }
  if (
    brandPixelData &&
    brandPixelData.conversionPixels &&
    checkDuplicateFBPixel(conversionPixels, brandPixelData.conversionPixels)
  ) {
    _set(errors, 'conversionPixels', 'This code is already imported at Brand level')
  }

  if (!!googleAnalyticsKey && !gaIdRegexp.test(googleAnalyticsKey.toString())) {
    _set(errors, 'googleAnalyticsKey', 'Invalid Tracking ID')
  } else if (!!googleAnalyticsKey && googleAnalyticsKey === brandPixelData.googleAnalyticsKey) {
    _set(errors, 'googleAnalyticsKey', 'This tracking ID is already imported at Brand level')
  }

  if (!!googleAnalyticsMeasurementId && !ga4IdRegexp.test(googleAnalyticsMeasurementId.toString())) {
    _set(errors, 'googleAnalyticsMeasurementId', 'Invalid Measurement ID')
  } else if (
    !!googleAnalyticsMeasurementId &&
    googleAnalyticsMeasurementId === brandPixelData.googleAnalyticsMeasurementId
  ) {
    _set(errors, 'googleAnalyticsMeasurementId', 'This measurement ID is already imported at Brand level')
  }

  if (!!googleTagManagerKey && !gtmRegex.test(googleTagManagerKey.toString())) {
    _set(errors, 'googleTagManagerKey', 'Invalid ID')
  }

  return errors
}

function checkDuplicateFBPixel(eventPixel, brandPixel) {
  rgx.lastIndex = 0
  const eventPixelId = rgx.exec(eventPixel.replace(normalize, ''))
  rgx.lastIndex = 0
  const brandPixelId = rgx.exec(brandPixel.replace(normalize, ''))
  return !!(eventPixelId && brandPixelId && eventPixelId[1] === brandPixelId[1])
}

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showImportFbPixelDialog: false,
      showBrandFbPixelDialog: false,
      showGoogleTagManager: true, //this.props.dynamicConfigs.get('google_tag_manager', false)
    }
    this.fbAdAccounts = []
    this.fbAdAccountsLoading = false

    this.updateField = this.updateField.bind(this)
    this.handleChangeTextArea = this.handleChangeTextArea.bind(this)
    this.clickImportFbPixelDialog = this.clickImportFbPixelDialog.bind(this)
    this.closeImportFbPixelDialog = this.closeImportFbPixelDialog.bind(this)
    this.openBrandFbPixelDialog = this.openBrandFbPixelDialog.bind(this)
    this.closeBrandFbPixelDialog = this.closeBrandFbPixelDialog.bind(this)
  }

  updateField(field, value) {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fieldName = `attributes.${field}`
    const fields = form_helper_get()
    fields[fieldName] = fieldName
    form_helper_set(fields)
  }

  handleChangeTextArea(field, event) {
    this.updateField(field, event.target.value)
  }

  openBrandFbPixelDialog() {
    this.setState({ showBrandFbPixelDialog: true })
  }

  closeBrandFbPixelDialog() {
    this.setState({ showBrandFbPixelDialog: false })
  }

  clickImportFbPixelDialog() {
    this.openImportFbPixelDialog()
    this.fetchFbPixelsAll()
  }

  openImportFbPixelDialog() {
    this.setState({ showImportFbPixelDialog: true })
  }

  closeImportFbPixelDialog() {
    this.setState({ showImportFbPixelDialog: false })
  }

  fetchFbPixelsAll() {
    const that = this

    this.fbAdAccountsLoading = true
    this.fbAdAccounts = []
    this.forceUpdate()
    fb.fetchSDK()
      .catch(onerror)
      .then(FB => {
        FB.login(
          () => {
            that.fetchFbAdAccounts(FB)
          },
          { scope: 'ads_management,business_management' },
        )
      })
  }

  fetchFbAdAccounts(FB) {
    const that = this
    FB.api('/me/adaccounts?fields=id,name,currency,owner,business', response => {
      if (response.data) {
        that.fbAdAccounts = _map(response.data, (fbAdAccount, fbAdAccountIndex) => ({
          id: fbAdAccount.id,
          name: fbAdAccount.name,
          currency: fbAdAccount.currency,
          owner_id: fbAdAccount.owner,
          business: fbAdAccount.business,
          pixels: [],
          pixelsLoading: true,
          pixelCreating: false,
        }))
        that.fbAdAccountsLoading = false
        that.fbAdAccountsLoadingError = null
        that.forceUpdate()
        _each(that.fbAdAccounts, fbAdAccount => {
          that.fetchFbAdAccountOwner(FB, fbAdAccount)
          that.fetchFbPixels(FB, fbAdAccount)
        })
      } else if (response.error) {
        that.fbAdAccountsLoading = false
        that.fbAdAccountsLoadingError = response.error.message
        that.forceUpdate()
      }
    })
  }

  fetchFbAdAccountOwner(FB, fbAdAccount) {
    const that = this
    if (fbAdAccount.business) {
      fbAdAccount.owner = fbAdAccount.business
      that.forceUpdate()
    } else {
      FB.api('/' + fbAdAccount.owner_id, response => {
        fbAdAccount.owner = response
        that.forceUpdate()
      })
    }
  }

  fetchFbPixels(FB, fbAdAccount) {
    fbAdAccount.pixelsLoading = true
    this.forceUpdate()
    const that = this
    FB.api('/' + fbAdAccount.id + '/adspixels?fields=id,name,code', response => {
      if (response.data) {
        fbAdAccount.pixels = _map(response.data, p => ({
          id: p.id,
          name: p.name,
          code: p.code,
        }))
        fbAdAccount.pixelsLoading = false
        fbAdAccount.pixelsLoadingError = null
        that.forceUpdate()
      } else if (response.error) {
        fbAdAccount.pixelsLoading = false
        fbAdAccount.pixelsLoadingError = response.error.message
        that.forceUpdate()
      }
    })
  }

  importFbPixelCode(fbPixel) {
    const { values } = this.props
    const extraCodePage = "<script>\nfbq('track', 'ViewContent');\n</script>"
    const extraCodeCheckout = "<script>\nfbq('track','InitiateCheckout');\n</script>"
    const extraCodePurchase =
      "<script>\nfbq('track','Purchase',{\nvalue:'{VALUE}',\ncurrency: '{CURRENCY}'\n});\n</script>"

    this.updateField(
      'pagePixels',
      (values.pagePixels ? values.pagePixels + '\n' : '') + fbPixel.code + '\n' + extraCodePage,
    )
    this.updateField(
      'checkoutPixels',
      (values.checkoutPixels ? values.checkoutPixels + '\n' : '') + fbPixel.code + '\n' + extraCodeCheckout,
    )
    this.updateField(
      'conversionPixels',
      (values.conversionPixels ? values.conversionPixels + '\n' : '') +
        fbPixel.code +
        '\n' +
        extraCodePurchase,
    )
    this.closeImportFbPixelDialog()
  }

  createFbPixel(fbAdAccount) {
    if (fbAdAccount.pixels.length > 0) return
    const that = this
    fbAdAccount.pixelCreating = true
    this.forceUpdate()
    fb.fetchSDK()
      .catch(onerror)
      .then(FB => {
        FB.login(
          () => {
            FB.api('/' + fbAdAccount.id + '/adspixels', 'post', { name: 'The Ticket Fairy' }, response => {
              if (response.error) {
                fbAdAccount.pixelCreating = false
                fbAdAccount.pixelCreatingError = response.error.message
                that.forceUpdate()
              } else {
                that.fetchFbPixels(FB, fbAdAccount)
                fbAdAccount.pixelCreating = false
                fbAdAccount.pixelCreatingError = null
                that.forceUpdate()
              }
            })
          },
          { scope: 'ads_management' },
        )
      })
  }

  render() {
    const that = this
    const { submitLabel, brandPixelData } = this.props
    const { showGoogleTagManager } = this.state
    const showBrandModal =
      brandPixelData &&
      (brandPixelData.pagePixels || brandPixelData.checkoutPixels || brandPixelData.conversionPixels)
    const { values, touched, errors, isSubmitting, handleSubmit } = this.props

    const fbAdAccounts_element = _map(this.fbAdAccounts, (fbAdAccount, fbAdAccountIndex) => {
      const fbPixels_element = _map(fbAdAccount.pixels, (fbPixel, fbPixelIndex) => (
        <tr key={fbPixelIndex}>
          <td>{fbPixel.name}</td>
          <td className="text-right">
            <Button
              key={fbPixelIndex}
              className="btn btn-blue"
              type="button"
              onClick={that.importFbPixelCode.bind(that, fbPixel)}
            >
              Import
            </Button>
          </td>
        </tr>
      ))
      return (
        <table key={fbAdAccountIndex} className="table" style={{ marginTop: '20px' }}>
          <thead>
            <tr>
              <th>
                {fbAdAccount.name}
                &nbsp;&#91;{fbAdAccount.currency}&#93;
                {fbAdAccount.owner && <span>&nbsp;owned by {fbAdAccount.owner.name}</span>}
                {fbAdAccount.pixelsLoading && (
                  <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
                )}
                {fbAdAccount.pixelsLoadingError && (
                  <div className="fbpixels-loading-error text-danger">
                    Loading Pixels failed: {fbAdAccount.pixelsLoadingError}
                  </div>
                )}
                {fbAdAccount.pixelCreatingError && (
                  <div className="fbpixel-creating-error text-danger">
                    Creating Pixel failed: {fbAdAccount.pixelCreatingError}
                  </div>
                )}
              </th>
              <th className="text-right">
                {fbAdAccount.pixels.length === 0 && (
                  <Button
                    key={fbAdAccountIndex}
                    className="btn btn-blue"
                    disabled={fbAdAccount.pixelCreating}
                    onClick={that.createFbPixel.bind(that, fbAdAccount)}
                  >
                    Create New Pixel
                    {fbAdAccount.pixelCreating && (
                      <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
                    )}
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>{fbPixels_element}</tbody>
        </table>
      )
    })

    return (
      <form ref="form" method="POST" onSubmit={handleSubmit} className="event-ticket-advanced-options">
        <div className="row">
          <div className="col-xs-12">
            <span className="junior_text">Conversion Tracking JavaScript Code</span>
            <button
              className="btn btn-primary"
              style={{ float: 'right' }}
              type="button"
              onClick={this.clickImportFbPixelDialog}
            >
              <i className="fa fa-facebook-official" aria-hidden="true" /> Import Facebook Pixel
            </button>
          </div>
        </div>
        {showBrandModal && (
          <div className="row brand-pixel-indicator-holder">
            <div className="col-xs-12">
              <div className="brand-pixel-indicator">
                <i className="fa fa-info-circle" aria-hidden="true" />
                <span className="junior_text">Conversion Tracking Code is Imported at Brand Level</span>
                <button
                  className="btn btn-success"
                  style={{ float: 'right' }}
                  type="button"
                  onClick={this.openBrandFbPixelDialog}
                >
                  View Brand Facebook Pixel
                </button>
              </div>
            </div>
          </div>
        )}
        <br />
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="pagePixels"
              label="View of Event Page"
              value={values.pagePixels}
              error={errors.pagePixels}
              touched={touched.pagePixels}
              onChange={this.handleChangeTextArea.bind(this, 'pagePixels')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="checkoutPixels"
              label="Start of Checkout"
              value={values.checkoutPixels}
              error={errors.checkoutPixels}
              touched={touched.checkoutPixels}
              onChange={this.handleChangeTextArea.bind(this, 'checkoutPixels')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="conversionPixels"
              label="Completion of Purchase (use {CURRENCY} and {VALUE} to auto-insert conversion data)"
              value={values.conversionPixels}
              error={errors.conversionPixels}
              touched={touched.conversionPixels}
              onChange={this.handleChangeTextArea.bind(this, 'conversionPixels')}
            />
          </div>
        </div>
        <div className="row" style={{ opacity: 0.6 }}>
          <div className="col-xs-12">
            <Field
              className="ga-field"
              id="googleAnalyticsKey"
              label="Google Analytics Tracking ID"
              value={values.googleAnalyticsKey}
              error={errors.googleAnalyticsKey}
              touched={touched.googleAnalyticsKey}
              placeholder="UA-XXXXX-Y"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              className="ga-field"
              id="googleAnalyticsMeasurementId"
              label="Google Analytics Measurement ID"
              value={values.googleAnalyticsMeasurementId}
              error={errors.googleAnalyticsMeasurementId}
              touched={touched.googleAnalyticsMeasurementId}
              placeholder="G-XXXXX"
              onChange={this.handleChangeTextArea.bind(this, 'googleAnalyticsMeasurementId')}
            />
          </div>
        </div>
        {showGoogleTagManager && (
          <div className="row">
            <div className="col-xs-12">
              <Field
                className="ga-field"
                id="googleTagManagerKey"
                label="Google Tag Manager Container ID"
                value={values.googleTagManagerKey}
                error={errors.googleTagManagerKey}
                touched={touched.googleTagManagerKey}
                placeholder="GTM-XXXX"
                onChange={this.handleChangeTextArea.bind(this, 'googleTagManagerKey')}
              />
            </div>
          </div>
        )}
        {values.hiveAccountId && (
          <>
            <br />
            <div className="row">
              <div className="col-xs-12">
                <Field
                  className="ga-field"
                  id="hiveSegment"
                  label="Hive Segment for this event"
                  value={values.hiveSegment}
                  error={errors.hiveSegment}
                  touched={touched.hiveSegment}
                  placeholder=""
                  onChange={this.handleChangeTextArea.bind(this, 'hiveSegment')}
                />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-xs-12 text-center">
            <Button className="btn btn-success btn-lg btn-shadow" type="submit" loading={isSubmitting}>
              {submitLabel || 'Save Advanced Options'}
            </Button>
          </div>
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!this.state.showImportFbPixelDialog}
          contentLabel="Modal"
          onRequestClose={this.closeImportFbPixelDialog}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">
                    Facebook Pixels
                    {this.fbAdAccountsLoading && (
                      <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
                    )}
                  </p>
                </div>
                <div className="modal-body">
                  {this.fbAdAccountsLoadingError && (
                    <div className="text-danger">
                      Loading Ad Accounts failed: {this.fbAdAccountsLoadingError}
                    </div>
                  )}
                  {fbAdAccounts_element}
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      onClick={this.closeImportFbPixelDialog}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!this.state.showBrandFbPixelDialog}
          contentLabel="Modal"
          onRequestClose={this.closeBrandFbPixelDialog}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Brand Facebook Pixel</p>
                </div>
                <div className="modal-body">
                  <EventBrandLevelFBPixel values={brandPixelData ? brandPixelData : null} />
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      onClick={this.closeBrandFbPixelDialog}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

const TicketAdvancedOptionsForm = withFormik({
  mapPropsToValues: props => ({
    pagePixels: _get(props, 'initialValues.pagePixels') || '',
    checkoutPixels: _get(props, 'initialValues.checkoutPixels') || '',
    conversionPixels: _get(props, 'initialValues.conversionPixels') || '',
    googleAnalyticsKey: _get(props, 'initialValues.googleAnalyticsKey') || '',
    googleTagManagerKey: _get(props, 'initialValues.googleTagManagerKey') || '',
    hiveAccountId: _get(props, 'initialValues.hiveAccountId') || '',
    hiveSegment: _get(props, 'initialValues.hiveSegment') || '',
    googleAnalyticsMeasurementId: _get(props, 'initialValues.googleAnalyticsMeasurementId') || '',
  }),

  // Custom sync validation
  validate: (values, props) => {
    const { brandPixelData } = props
    return validate(values, brandPixelData)
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        attributes: {
          ...values,
          endDate: props.initialValues.endDate,
          startDate: props.initialValues.startDate,
          salesEndDate: props.initialValues.salesEndDate,
        },
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'TicketAdvancedOptionsForm', // helps with React DevTools
})(MyForm)

export default TicketAdvancedOptionsForm
