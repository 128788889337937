import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import moment from 'moment-timezone'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDate
} from '../../../../_common/core/validation/normalizers'
import { DISPLAY_FORMAT } from '../../../constants/timeFormats'

export default class SalesByTimeSlots extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'release_type',
        label: 'Time Slot Start Date',
        normalizer: value => formatDate(value, DISPLAY_FORMAT)
      },
      {
        key: 'num_sales',
        label: 'No. of Sales'
      },
      {
        key: 'totalSales',
        label: 'Running Total (No. of Sales)',
        isSortable: false
      },
      {
        key: 'revenue',
        label: 'Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0')
          )
      },
      {
        key: 'revenueTotal',
        label: 'Running Total (Revenue)',
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ])

    this.detailsTableColumns = getTableColumns(
      [
        {
          key: 'release_type',
          label: 'Ticket Type'
        },
        {
          key: 'num_sales',
          label: 'No. of Sales'
        },
        {
          key: 'cost',
          label: 'Price (excl. Fees)',
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'revenue',
          label: 'Revenue',
          isSortable: false,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        }
      ],
      getCurrencySymbol(props.event)
    )

    this.footbarColumns = [
      { key: 'totalSales', sourceKey: 'num_sales' },
      {
        key: 'revenueTotal',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]

    this.state = {
      sortBy: { column: 'release_type', asc: true },
      rows: null,
      detailsRows: []
    }
  }


  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { groupedByTimeSlots } = this.props
    if (!_isEqual(groupedByTimeSlots, prevProps.groupedByTimeSlots)) {
      this.updateData(groupedByTimeSlots)
    }
  }

  updateData = () => {
    const { data, groupedByTimeSlots } = this.props
    const tempRows = []
    const detailsRows = []
    let totalSales = 0
    let totalRevenue = 0

    _map(groupedByTimeSlots, (value, key) => {
      const numSales = this.getTotalNumSales(value)
      totalSales += parseInt(numSales)
      totalRevenue += this.calculateRevenue(value)
      this.getDetailRows(data, value[0].startDate, detailsRows)

      tempRows.push({
        id: value[0].startDate,
        release_type: key,
        num_sales: numSales,
        cost: value[0].cost,
        revenue: this.calculateRevenue(value),
        revenueTotal: totalRevenue,
        totalSales
      })
    })

    const mergedStock = {}
    this.setState({
      rows: _map(
        _filter(tempRows, rowItem => !!rowItem.id),
        item => ({
          ...item,
          stock: mergedStock[item.id]
        })
      ),
      detailsRows
    })
  }

  getTotalNumSales = (ticketsArray = []) => {
    let totalSales = 0
    _map(ticketsArray, item => {
      totalSales += parseInt(item.quantity)
    })

    return totalSales
  }

  calculateRevenue = (ticketsArray = []) => {
    let totalRevenue = 0
    _map(ticketsArray, item => {
      totalRevenue += parseFloat(item.revenue)
    })

    return totalRevenue
  }

  getDetailRows = (data, date, detailsRows) => {
    const detailRows = []

    _map(_sortBy(data, 'groupId'), item => {
      if (moment(item.startDate).isSame(date)) {

        detailRows.push({
          id: date,
          slotGroupId: item.groupId,
          num_sales: item.quantity,
          cost: item.cost,
          revenue: parseFloat(item.revenue),
          release_type: item.groupName
        })
      }
    })

    !!detailRows.length &&
      detailsRows.push({
        id: date,
        type: 'detailRow',
        component: (
          <SortableTable
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state
    
    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            detailsRows={detailsRows}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
            calculatedColumns={[
              { for: 'totalSales', column: 'num_sales' },
              { for: 'revenueTotal', column: 'revenue' }
            ]}
          />
        ) : null}
      </div>
    )
  }
}
