import React from 'react'
import Button from '../../../../_library/Button'
import Field from '../../../../_library/Field'
import { showAxiosError } from '../../../../utils/messenger'
import { getBrands } from '../../../../../_common/core/http_services'
import _debounce from "lodash/debounce"

export default class BrandRow extends React.Component {
  constructor(props) {
    super(props)
    this.lookUpBrands = _debounce(this.lookUpBrands, 400)
    this.state = {
      suggestions: [],
      isLoading: false,
      searchValue: props?.values?.ownerName || "",
      error: "",
    }
    this.fetchTry = 0
  }

  async lookUpBrands(value, fetchTry) {
    try {
      this.setState({
        error: "",
      })
      if (!value) {
        this.setState({
          suggestions: [],
        })
        return
      }
      const brands = await getBrands({ compact: 1, search_query: value })
      if (this.fetchTry !== fetchTry) {
        return
      }
      if (!brands?.data?.length) {
        this.setState({
          error: "No results found",
          suggestions: [],
        })
        return
      }
      const suggestions = brands.data.map(brand => ({ id: brand.id, label: brand.displayName }))
      this.setState({
        suggestions,
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  handleChangeAddressQuery = async e => {
    const { value } = e.target
    const validValue = value.trimStart()
    this.setState({ searchValue: validValue }, () => {
      this.fetchTry += 1
      this.lookUpBrands(validValue, this.fetchTry)
    })
  }

  handleAddressSelected = value => {
    const { onChange } = this.props
    const { suggestions } = this.state
    this.setState({
      searchValue: value.label,
    }, () => {
      onChange({
        value: value.id,
        suggestions,
      })
    })
  }

  render() {
    const { onClickNewBrand } = this.props
    const { searchValue, isLoading, suggestions, error } = this.state

    return (
      <div className="row">
        <label className="control-label-outside-online" htmlFor="owner">
          Search for the brand running this event
        </label>
        <div className="row-brand col-xs-12">
          <div className={'dropdown-brand '}>
            <label className="control-label" htmlFor="brand">
              Brand
            </label>
            <Field
              id="owner"
              className={'searchbox'}
              loading={isLoading}
              onChange={this.handleChangeAddressQuery}
              value={searchValue}
              options={suggestions}
              onSelected={this.handleAddressSelected}
            />
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
          <div className="btn-create-brand">
            <Button className="btn btn-default btn-shadow" style={{ marginTop: "42px" }} type="button" onClick={onClickNewBrand}>
              Create Brand
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
