import React from 'react'
import _map from 'lodash/map'

export default class InfluencerTiers extends React.Component {
  render() {
    const { statistics, isLoading, event } = this.props
    let tiers = []
    const classname = 'tier-slice'
    let number = 0

    if (statistics && statistics.referral_tiers) {
      tiers = _map(statistics.referral_tiers, (el) => el )
    } else{
      tiers = [
        { sales: event.referralTier1Sales, percentage: event.referralTier1Percentage },
        { sales: event.referralTier2Sales, percentage: event.referralTier2Percentage },
        { sales: event.referralTier3Sales, percentage: event.referralTier3Percentage },
      ]
    }
    const classNumberAnimation = isLoading ? 'animation-container' : ''
    
    const rows_tiers = _map(tiers, (value, index) => (
      <div key={index} className={classname}>
        <div className={`influencers-tier influencers-tier-${number++}`}>
          <div className="tier-left">
            <div className={classNumberAnimation}>
              {value.sales}
            </div>
          </div>
          <div className="tier-right">
            <div className="tier-tickets">
                Tickets <img src={asset('/resources/images/influencers-star.svg')}/>
            </div>
            <div className="tier-percentage">
              <div className={classNumberAnimation}>
                {value.percentage}%
              </div>
            </div>
            <div className="tier-rebate color_rebate">
                Rebate
            </div>
          </div>
        </div>
      </div>
    ))

    return (<div>
      <h3 className="heading_style">
          Reward Targets
      </h3>
      <div className = "row">
        {rows_tiers}
      </div>
    </div>
    )
  }
}
