import React, { useState, useCallback, useEffect, useRef } from 'react'
import { withFormik, Field, Form } from 'formik'
import TagsField from '../../_library/TagsField'
import _get from 'lodash/get'
import _set from 'lodash/set'
import Button from '../../_library/Button'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'
import { uuid } from '../../../_common/core/rand'
import { SimpleField } from '../../formik/Fields'
import OutsideAlerter from '../newsignupcreatebrand/OutsideAlerter'
import { useMediaQuery } from '../../../_common/core/utils'
import { movedToPayoutBySkipingTeamInvitations } from '../../../_common/core/gtag'

const roles = [
  { label: "Owner - Full Access", value: "owner" },
  { label: "Administrator - Full Access", value: "admin" },
  { label: "Promoter - Traffic Partner /Affiliate Only", value: "promoter" }
]

const validateMember = data => {
  const errors = {}
  const invitations = _get(data, 'invitations')

  if (_get(data, 'invitations').length === 0) {
    _set(errors, 'invitations', 'Required')
  } else {
    const re = global.emailRegex
    invitations.forEach((r, index) => {
      if (!r.role) {
        _set(errors, 'invitations[' + index + '].role', 'Required')
      }
      if (!r.email) {
        _set(errors, 'invitations[' + index + '].email', 'Required')
      } else if (!re.test(r.email)) {
        _set(errors, 'invitations[' + index + '].email', 'Invalid')
      }
    })
  }

  return errors
}

const InviteTeamForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  setFieldValue,
  className,
  isSubmitting,
  history,
  setTouched,
  setErrors,
  isValid,
  SET_HAS_TEAM_INVITATION,
  brandId
}) => {
  const [tagsValues, setTagsValues] = useState([])
  const [showDropdown, setShowDropdown] = useState(-1)
  const onTagsChange = tags => {
    setTagsValues(tags)
  }
  const addValues = () => {
    const validEmails = tagsValues.filter(tag => global.emailRegex.test(tag))
    const invalidEmails = tagsValues.filter(tag => !global.emailRegex.test(tag))
    const newInvitations = [...values.invitations, ...validEmails.map(email => ({ email, role: '', id: uuid() }) )]
    setFieldValue('invitations', newInvitations)
    setTagsValues(invalidEmails)
    const touched = {
      email: true,
      invitations: []
    }
    for (let i = 0; i < newInvitations.length; i++) {
      touched.invitations.push({ email: true, role: true })
    }
    setTouched(touched)
  }
  const handleGoBack = () => {
    history.goBack()
  }
  const handleSkip = () => {
    SET_HAS_TEAM_INVITATION(true)
    history.push('/welcome/payout-mode', {
      brandId
    })
    movedToPayoutBySkipingTeamInvitations()
  }
  const disableContinueButton = !isValid
  const noTags = tagsValues.length === 0 
  const noInvitations = values.invitations.length === 0
  const onClickOption = (role, index) => () => {
    values.invitations[index].role = role
    setFieldValue('invitations', values.invitations)
    setShowDropdown(-1)
  }
  const onClickSelect = index => () => {
    setShowDropdown(index)
  }
  const onClickDelete = index => () => {
    values.invitations.splice(index, 1)
    setFieldValue('invitations', values.invitations)
    if (errors.invitations) {
      errors.invitations.splice(index, 1)
      setErrors(errors)
    }
    if (touched.invitations) {
      touched.invitations.splice(index, 1)
      setTouched(touched)
    }
  }
  const isMobile = useMediaQuery('(max-width: 768px)')
  const onClickOutside = useCallback(() => {
    setShowDropdown(-1)
  }, [setShowDropdown])
  const buttonRef = useRef(null)
  const [buttonHeight, setButtonHeight] = useState(0)
  useEffect(() => {
    setButtonHeight(buttonRef.current.clientHeight)
  }, [tagsValues])
  return (
    <div className="invite-team-container">
      <div className="margin-horizontal-25 title-form">Invite your Team</div>
      <label className="team-invitation-description">
        Get your event up and running faster by directing inviting your team members to your organization.
      </label>
      <div className="margin-horizontal-25 invite-team-input">
        <div className={`add-field-wrapper ${className ?? ''}`}>
          <label htmlFor="invite-team"> Email Addresses (press space or enter after each one)</label>
          <div className="input-wrapper">
            <TagsField
              tagsContainerClassName={`form-control`}
              value={tagsValues}
              onChange={onTagsChange}
              controlOutside
              placeholder={noTags ? 'Add an email' : ' '}
              focusOnStart
              inputValue={values.email}
              onChangeInput={value => {
                setFieldValue('email', value)
              }}
              renderTag={props => {
                const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
                const isValid = global.emailRegex.test(tag)
                return (
                  <span
                    key={key}
                    {...other}
                    style={{
                      color: isValid ? 'white' : '#d27373',
                      maxWidth: '297px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      verticalAlign: 'bottom'
                    }}
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
                  </span>
                )
              }}
              onlyUnique={true}
              addKeys={[9, 13, 32, 188]}
              pasteSplit
              inputClassName="input-responsive"
              tagsInputClassName={`limit-input ${(noTags && !values.email) ? 'ln-24' : 'ln-16'}`}
            />
            <div ref={buttonRef} className="icon-wrapper" onClick={addValues} style={buttonHeight <= 40 ? { margin: 0 } : {}}>
              <img src={asset((noTags && !values.email) ? '/resources/images/add-inactive.svg' : '/resources/images/add.svg')} />
              <span className="icon-text" style={{ color: (noTags && !values.email) ? "#999999" : "white" }}>Add</span>
            </div>
          </div>
          <Button
            type="button"
            className={`add-button ${!noTags ? 'enabled' : ''}`}
            disabled={isSubmitting}
            onClick={addValues}
          >
            Add Emails
          </Button>
        </div>
      </div>
      {values.invitations.length !== 0 ? <div className="margin-horizontal-25 invite-team-form">
        <div className="invite-team-header">
          <div className="invite-team-email">Email</div>
          <div className="invite-team-role">Role</div>
        </div>
        <Form>
          {values.invitations.map(({ id, role, email }, index) => (
            <div key={id} className="invitation-row">
              <div className='email-no-input'>{email}</div>
              <div className='field-half-wrapper'>
                <Field 
                  type="email"
                  name={'invitations[' + index + '].email'}
                  component={SimpleField}
                  validate={combineValidators(requiredValidator(), isEmailValidator())}
                  formclassname="field-wrapper"
                />
              </div>
              <div className='select-half-wrapper'>
                {errors?.invitations && Array.isArray(errors.invitations) && errors.invitations[index]?.role 
                  ? <div className="help-block-select">{errors.invitations[index]?.role}</div> 
                  : null
                }
                <div 
                  onClick={onClickSelect(index)}
                  className={`role-select-box ${showDropdown === index ? 'active' : ''}`}
                >
                  <div className={`role-option-text ${role ? 'active' : '' }`}>
                    {role ? roles.find(roleEl => roleEl.value === role).label : "Select an option"}
                  </div>
                  <img 
                    src={asset(
                    (role || (showDropdown === index && isMobile)) 
                      ? '/resources/images/caret-down-active.svg' 
                      : isMobile 
                      ? '/resources/images/caret-down-mobile-inactive.svg' 
                      : '/resources/images/caret-down.svg')} 
                    alt="" 
                    width={12} 
                  />
                </div>
                <div className='delete-box' onClick={onClickDelete(index)}>
                  <div className='circle'>
                    <div className='line' />
                  </div>
                </div>
                {showDropdown === index ? <div className="roles-wrapper">
                  <OutsideAlerter onClickOutside={onClickOutside}>
                    {roles.map((role, i) => (
                      <div key={i} className="role-element" onClick={onClickOption(role.value, index)}>
                        {role.label}
                      </div>
                    ))}
                  </OutsideAlerter>
                </div> : null}
              </div>
            </div>
          ))}
        </Form>
      </div> : null}
      <div className="buttons-wrapper margin-horizontal-25">
        <Button type="button" className="back-button" disabled={true} onClick={handleGoBack}>
          Back
        </Button>
        <div className={!noInvitations ? 'submit-button-visible' : 'submit-button-wrapper'}>
          <Button
            type="submit"
            className={disableContinueButton ? 'submit-disabled' : 'submit-button-invite'}
            disabled={disableContinueButton}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Send Invite
          </Button>
        </div>
      </div>
      <div onClick={handleSkip} className='skip-step'>Skip step</div>
    </div>
  )
}

const FormInviteTeamFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
    invitations: []
  }),

  validate: values => validateMember(values),

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
      setSubmitting(false)
    } catch(e) {
      setSubmitting(false)
    }
  },
  displayName: 'inviteTeamForm'
})(InviteTeamForm)

export default FormInviteTeamFormik
