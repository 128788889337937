import React from 'react'
import { withRouter } from 'react-router'

const LogoRouter = ({ history: { goBack } }) => (
  <div className="logo-container">
    <div
      className="icon-back"
      onClick={() => {
        goBack()
      }}
    >
      <i className="fa fa-chevron-left" />
    </div>
    <img className="logo" src={asset('/resources/images/ttf-logo-only.png')} alt="logo" />
    <img className="text-logo" src={asset('/resources/images/ttf-text-only.png')} alt="logo" />
  </div>
)

export const Logo = withRouter(LogoRouter)
