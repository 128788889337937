import React from 'react'
import _identity from 'lodash/identity'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'
import Field from '../../_library/Field'
import { getConfig } from '../../utils/configUtils'
import _get from 'lodash/get'

const configFile = getConfig()
const baseURL = _get(
  configFile,
  '/event/:id/details.children.EventForm.children.EventUrlField.behavior.eventUrl',
)

const EventUrlField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  onBlurNormalizer = _identity,
  showErrorOnlyTouched = true,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
  }
  const onChange = event => {
    const value = event.target ? event.target.value : event
    if (props.onChange) {
      props.onChange(value, event)
    }
    setValue(value)
  }
  const onBlur = event => {
    const {
      target: { value },
    } = event
    setValue(value, onBlurNormalizer)
    touchedOnBlur && setFieldTouched(field.name, value)
    if (props.onBlur) {
      props.onBlur(value, event)
    }
  }

  const slugSuggestion = !props.isNew
    ? props.values.slug
    : !props.values.startDate
    ? props.values.title
    : (props.values.title || '') + `${props.isRnDomain ? '' : '-'}-event`

  return (
    <div className={`row slugfield-holder-alternative-view ${props.inValidSlugClass}`}>
      <div className="col-xs-12">
        <label className="autoslugfield-label-alternative-view" onMouseLeave={props.copyEventUrlOut}>
          Alternative View URL
          {field.disabled && (
            <ClipboardButton
              component="span"
              data-clipboard-text={baseURL + props.values.slug}
              onSuccess={props.copyEventUrlAfter}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="alternativeViewUrlCopied">
                    {props.eventUrlCopied ? 'Copied' : 'Copy Alternative View URL'}
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <i className="fa fa-clipboard event-url-copy" />
              </OverlayTrigger>
            </ClipboardButton>
          )}
        </label>
        <Field
          id={field.name}
          label={field.isRnDomain ? 'Custom URL' : 'Link URL'}
          separator={field.isRnDomain ? '' : '-'}
          hint="Letters, numbers and hyphens only"
          suggestion={slugSuggestion}
          value={field.value}
          error={errors[field.name]}
          touched={touched[field.name]}
          onChange={onChange}
          disabled={props.disabled}
          isAutoSlug={true}
          onBlur={onBlur}
        >
          <Field.PrefixAddon className="link-prefix hidden-xs">
            <img alt="no data" className="link-prefix-img" src={asset('/resources/images/event-url.png')} />
            <div className="link-prefix-url">{baseURL}</div>
          </Field.PrefixAddon>
        </Field>
      </div>
    </div>
  )
}

export { EventUrlField }
