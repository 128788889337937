// Need to be merged with TagssField.jsx
import React from 'react'

export default class TagsField2 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  clickTag = tag => {
    const { value } = this.state
    if (!value.includes(tag)) {
      this.toggleTag(tag)
    }
  }

  toggleTag(tag) {
    const { value } = this.state
    let newValue
    if (value.includes(tag)) {
      newValue = value.filter(v => v !== tag)
    } else {
      newValue = [...value, tag]
    }
    this.setState({
      value: newValue,
    })
    this.props.onChange(newValue)
  }

  removeTag = tag => {
    const { value } = this.state
    if (value.includes(tag)) {
      const newValue = value.filter(v => v !== tag)
      this.setState({
        value: newValue,
      })
      this.props.onChange(newValue)
    }
  }

  render() {
    const { value } = this.state
    const { tags, label, normalizer, containerClassName } = this.props
    return (
      <div className="tags-field2 form-group">
        <label className="tags-field2-label">{label}</label>
        <div className={`tags-field2-tags ${containerClassName || ''}`}>
          {tags.map((tag, index) => (
            <div
              aria-hidden
              key={index}
              className={
                'tags-field2-tag ' +
                (value.includes(tag) ? 'tags-field2-tag-active' : 'tags-field2-tag-inactive')
              }
              onClick={() => this.clickTag(tag)}
            >
              {value.includes(tag) && (
                <i aria-hidden className="fa fa-times" onClick={() => this.removeTag(tag)} />
              )}
              {normalizer ? normalizer(tag) : tag}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
