import React, { PureComponent } from 'react'
import produce from 'immer'

import {
  getPerfomanceData,
} from '../../../../_common/core/http_services'
import {
  FuturePaymentDetails,
} from '.'

import {
  formatDate,
} from '../../../../_common/core/validation'

import { DISPLAY_FORMAT } from '../../../constants/timeFormats'

import { showAxiosError } from '../../../utils/messenger'
import LoadingBar from '../../../_library/LoadingBar'
import DataTable from '../../../_library/DataTable'
import _map from 'lodash/map'
import EmptyBar from '../../../_library/EmptyBar'

// hoc
import {
  tableWithCopy,
} from '../../../hoc'

const TableWithCopy = tableWithCopy(DataTable)

class FuturePaymentItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      thead: this.getTableHeader(),
      tbody: [],
      items: {
        loading: false,
        exist: false,
        data: null,
      },
      detailsRows: []
    }
  }
  componentDidMount() {
    const { future_payment, event: { id } } = this.props
    this.getItems(future_payment, id)
  }

  componentWillReceiveProps(nextProps) {
    const { future_payment: p_future_payment } = this.props
    const { future_payment: n_future_payment, event: { id } } = nextProps
    if (n_future_payment !== p_future_payment) {
      this.getItems(n_future_payment, id)
    }
  }

  getItems = async (future_payment, eid) => {
    const {
      payment_date,
      payment_week_start,
      payment_week_end,
    } = future_payment
    const by_week = !!(payment_week_start && payment_week_end)
    const filter = {
      start_date: by_week ? payment_week_start : payment_date,
      end_date: by_week ? payment_week_end : payment_date,
    }

    try {
      this.setState(produce(draftState => {
        draftState.items.loading = true
      }))
      const { payment_plan_future_sales_orders=[] } = await getPerfomanceData(eid, 'payment_plan_future_sales_orders', filter)

      this.setState(produce(draftState => {
        draftState.items.exist = true
        draftState.items.data = payment_plan_future_sales_orders
        draftState.tbody = this.getTableBody(payment_plan_future_sales_orders)
      }))
    } catch (error) {
      showAxiosError(error)
    } finally {
      this.setState(produce(draftState => {
        draftState.items.loading = false
      }))
    }
  }

  getTableHeader = () => [
    {
      sortable: true,
      value: 'Order Date',
    },
    {
      sortable: true,
      value: 'No. of Tickets',
    },
    {
      sortable: true,
      value: 'Total Number of Payments',
    },
    {
      value: 'No. of Current Payment',
    },
    ''
  ]

  getTableBody = (d) => {
    const { items: { data } } = this.state
    const _data = d || data
    return _map(_data, (item) => {
      const { date, tickets_count, total_payments, current_payment_num=[], installments=[] } = item
      return [
        {
          value: date,
          normalizer: (d) => formatDate(d, DISPLAY_FORMAT)
        },
        {
          value: +tickets_count,
        },
        {
          value: +total_payments,
        },
        {
          value: current_payment_num + '',
        },
        <FuturePaymentDetails
          installments={installments}
        />,
      ]
    })
  }

  render() {
    const { thead, tbody, items } = this.state
    const {
      loading,
      exist,
    } = items


    let content = null
    if (loading && !exist) {
      content =  <LoadingBar />
    } else if (exist) {
      const _tbody = tbody.length ? tbody : <tbody><tr><td colSpan={thead.length}><EmptyBar /></td></tr></tbody>
      content = (
        <TableWithCopy
          thead={thead}
          tbody={_tbody}
        />)
    }
    return (
      <div>
        {content}
      </div>
    )
  }
}

export { FuturePaymentItem }
