import React from 'react'

import Field from '../_library/Field'
import Card from '../_library/Card'

const Support = props => {
  const { values, touched, errors, handleChange, handleBlur, closed } = props

  return (
    <Card /* icon={'fa-briefcase'} */ title={'Ticket Fairy Support'} closed={closed}>
      <div className="row">
        <div className="col-xs-6 col-12" style={{ marginBottom: 5 }}>
          <Field
            id="externalSupportEmail"
            label="Contact Email for Escalations"
            helperText="External Use to Provide To Customers"
            type="email"
            value={values.externalSupportEmail}
            error={errors.externalSupportEmail}
            touched={touched.externalSupportEmail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-xs-6 col-12" style={{ marginBottom: 5 }}>
          <Field
            id="internalSupportEmail"
            label="Point of Contact Email for TF Support"
            helperText="Internal Use Only"
            type="email"
            value={values.internalSupportEmail}
            error={errors.internalSupportEmail}
            touched={touched.internalSupportEmail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </Card>
  )
}

export default Support
