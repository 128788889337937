import React from 'react'
import Field from '../../../_library/Field'
import Card from '../../../_library/Card'
import CustomToggle from '../../../_library/CustomToggle'
import { checkCountry, countryCodeAdapter } from '../../../utils/coutriesUtils'
import { requiredValidator } from '../../../../_common/core/validation'
import { RadioGroupField } from '../../../formik/Fields'
import { Field as FormikField } from 'formik'
import CountryField from './CountryField'
import { countryName } from '../../../../_common/core/countries'
import countries from 'world-countries'

class PayoutDetails extends React.PureComponent {
  handleSwiftChange = e => {
    const { handleChange } = this.props
    const event = e
    event.target.value = event.target.value.toUpperCase()
    handleChange(event)
  }

  handleChangeCountry = value => {
    const { updateField } = this.props
    if (typeof value === 'string') {
      const countryCode = countries.find(country => country.name.common === value)?.cca2
      updateField('countryCode', countryCode)
    } else {
      const countryCode = countries.find(country => country.name.common === value.target.value)?.cca2
      updateField('countryCode', countryCode)
    }
  }

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      enableToggle,
      closed,
      brand,
      flagIsStripeConnected
    } = this.props
    const showData = (enableToggle && values.flagPaymentDetails) || !enableToggle
    const country = values.countryCode || countries.find(cntr => cntr.name.common === values.country)?.cca2
    const brandCountry = countryCodeAdapter(brand?.country, 'uk', 'gb') || ''
    const isSameCountryEventBrand = values.country === countryName(brandCountry)
    const countriesFiltered = countries
      .filter(
        country => country.name.common === values.country || country.name.common === countryName(brandCountry)
      )
      .map(c => ({ id: c.name.common, label: c.name.common }))
    const countryCommon = countries.find(cntr => cntr.cca2 === country)?.name.common
    const formikCountry = countries.find(cntr => cntr.cca2 === values.countryCode)?.name.common

    return (
      <Card icon={'fa-money'} title={'Payout Details'} closed={closed}>
        {enableToggle && !flagIsStripeConnected && (
          <div className="row">
            <div className="col-sm-12">
              <CustomToggle
                id="flagPaymentDetails"
                onText="Use alternative payout details for this event"
                value={values.flagPaymentDetails}
                checked={values.flagPaymentDetails}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        {showData && !isSameCountryEventBrand && (
          <>
            <div className="div-spacing-20" />
            <CountryField
              onKeyDown={e => {
                e.preventDefault()
                return false
              }}
              id="countryCode"
              label="Country"
              options={countriesFiltered}
              value={formikCountry || ''}
              error={errors.countryCode}
              touched={touched.countryCode}
              onChange={this.handleChangeCountry}
            />
          </>
        )}
        {enableToggle && values.flagPaymentDetails && <div className="div-spacing-20" />}
        {showData && checkCountry(countryCommon, 'us') && (
          <>
            <div className="row">
              <div className="col-sm-6">
                <strong>Type of Account</strong>
                <FormikField
                  name="accountType"
                  component={RadioGroupField}
                  label={'prueba'}
                  groups={[
                    { value: 'checking', label: 'Checking' },
                    { value: 'saving', label: 'Saving' }
                  ]}
                  validate={requiredValidator()}
                  onChange={handleChange}
                  classNames={{
                    container: 'radio-group-container',
                    container_item: 'radio-group-item',
                    component: ''
                  }}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id="accountName"
                  label="Account Holder Name"
                  value={values.accountName}
                  error={errors.accountName}
                  touched={touched.accountName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Field
                  id="routingNumber"
                  label="Routing Number"
                  value={values.routingNumber}
                  error={errors.routingNumber}
                  touched={touched.routingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id="accountNumber"
                  label="Account Number"
                  value={values.accountNumber}
                  error={errors.accountNumber}
                  touched={touched.accountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </>
        )}
        {showData && !checkCountry(countryCommon, 'us') && (
          <div className="row">
            <div className="col-sm-6 col-12">
              <Field
                id="accountName"
                label="Account Holder Name"
                value={values.accountName}
                error={errors.accountName}
                touched={touched.accountName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6 col-12">
              <Field
                id="accountNumber"
                label="Account Number"
                value={values.accountNumber}
                error={errors.accountNumber}
                touched={touched.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}
        {showData && checkCountry(countryCommon, 'mx') && (
          <>
            <div className="row">
              <div className="col-xs-12">
                <Field
                  id="swiftCode"
                  label="SWIFT Code"
                  value={values.swiftCode}
                  error={errors.swiftCode}
                  touched={touched.swiftCode}
                  onChange={this.handleSwiftChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Field
                  id="clabe"
                  label="CLABE"
                  value={values.clabe}
                  error={errors.clabe}
                  touched={touched.clabe}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id="fullAddress"
                  label="Full Address"
                  value={values.fullAddress}
                  error={errors.fullAddress}
                  touched={touched.fullAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </>
        )}
        {showData && checkCountry(countryCommon, 'au') && (
          <div className="row">
            <div className="col-xs-6 col-12">
              <Field
                id="bsb"
                label="BSB"
                value={values.bsb}
                error={errors.bsb}
                touched={touched.bsb}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}
        {showData && checkCountry(countryCommon, 'uk') && (
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="sortCode"
                label="Sort Code"
                value={values.sortCode}
                error={errors.sortCode}
                touched={touched.sortCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}
        {showData && checkCountry(countryCommon, 'in') && (
          <div className="row">
            <div className="col-sm-6">
              <Field
                id="ifscNumber"
                label="IFSC Number"
                value={values.ifscNumber}
                error={errors.ifscNumber}
                touched={touched.ifscNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6">
              <Field
                id="bankName"
                label="Bank Name"
                value={values.bankName}
                error={errors.bankName}
                touched={touched.bankName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}
        {showData && checkCountry(countryCommon, 'ca') && (
          <div className="row">
            <div className="col-sm-6">
              <Field
                id="transitNumber"
                label="Transit Number"
                value={values.transitNumber || ''}
                error={errors.transitNumber}
                touched={touched.transitNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-sm-6">
              <Field
                id="institutionNumber"
                label="Institution Number"
                value={values.institutionNumber || ''}
                error={errors.institutionNumber}
                touched={touched.institutionNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}
      </Card>
    )
  }
}

export default PayoutDetails
