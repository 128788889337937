import React from 'react'
import Modal from 'react-modal'
import _map from 'lodash/map'
import _find from 'lodash/find'
import moment, { weekdaysShort } from 'moment'

import Field from '../../../../_library/Field'
import Button from '../../../../_library/Button'
import CurrencyField from '../../../../_library/CurrencyField'
import modalStyle from '../../../../../_common/core/modalStyle'
import Chip from '../../../../_library/Chip'
import { DatePicker, SingleDatePicker } from '../../../../_library/DatesPicker'
import TimePicker from '../../../../_library/TimePicker'

export default class TimeSlotsCreateModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = (e, fieldId) => {
    const { handleChange, values } = this.props
    const id = fieldId || e.target.id
    let value = e.target ? e.target.value : e
    if (id === 'durationHours') {
      if (Number(values.durationMinutes)) {
        value = Number(value) >= 23 ? '23' : value
      } else {
        value = Number(value) >= 24 ? '24' : value
      }
    }
    if (id === 'durationMinutes') {
      value = Number(value) >= 59 ? '59' : value
    }
    handleChange(id, value)
  }

  onFocusChange = (value, id) => {
    this.setState({
      [id]: value
    })
  }

  render() {
    const {
      display,
      values,
      defaultPrice,
      defaultQuantity,
      onSubmit,
      onCancel,
      handleChange,
      hideDurationField,
      selectedWeekdays,
      handleWeekdayChange,
      onApplyDates,
      excludedDates
    } = this.props
    const price = values.price || defaultPrice
    const quantity = Number(values.quantity) || Number(defaultQuantity)
    const durationHours = Number(values.durationHours)
    const durationMinutes = Number(values.durationMinutes)
    const durationError =
      values.startTime && values.endTime
        ? moment(values.endTime, 'HH:mm:ss').diff(moment(values.startTime, 'HH:mm:ss')) < 0
        : false
    const isSubmitButtonDisabled =
      !values.startTime ||
      !values.endTime ||
      (!durationHours && !durationMinutes) ||
      !values.startDate ||
      !values.endDate ||
      !price ||
      !quantity ||
      durationError

    return (
      <Modal
        className="modal-dialog modal-trans modal-daterange generate-time-slot"
        style={modalStyle}
        isOpen={display}
        contentLabel="Modal"
        onRequestClose={onCancel}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <div className="date-range-value">Time Slots</div>
              </div>
              <div className="modal-body" style={{ display: 'inline-block', paddingBottom: 20 }}>
                <div className="col-sm-6 col-12">
                  <SingleDatePicker
                    id="startDate"
                    label="Start Date"
                    placeholder=""
                    value={values.startDate || null}
                    required={true}
                    shouldBlockDays={true}
                    disableDaysAfter={values.endDate || null}
                    focused={this.state.startDate}
                    handleChange={this.handleChange}
                    onFocusChange={this.onFocusChange}
                  />
                </div>
                <div className="col-sm-6 col-12 customDatePickerWidth">
                  <SingleDatePicker
                    id="endDate"
                    label="End Date"
                    placeholder=""
                    value={values.endDate || null}
                    required={true}
                    shouldBlockDays={true}
                    disableDaysBefore={values.startDate || null}
                    focused={this.state.endDate}
                    handleChange={this.handleChange}
                    onFocusChange={this.onFocusChange}
                    initialMonthDate={values.startDate || null}
                  />
                </div>
                <div className="col-sm-12">
                  <div
                    className = 'create-time-slot-chip-section'
                  >
                    {_map(weekdaysShort(), (w, i) => (
                      <Chip
                        key={w}
                        value={w}
                        style={{ width: '100%', marginLeft: i ? 10 : 0 }}
                        isSelected={_find(selectedWeekdays, s => s === w)}
                        disableClear={true}
                        onSelectChip={handleWeekdayChange}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-sm-6">
                  <TimePicker
                    id="startTime"
                    title="Start Time"
                    value={values.startTime}
                    hideSeconds={true}
                    onChange={this.handleChange}
                    error={durationError}
                  />
                </div>
                <div className="col-sm-6">
                  <TimePicker
                    id="endTime"
                    title="End Time"
                    value={values.endTime}
                    hideSeconds={true}
                    onChange={this.handleChange}
                    error={durationError}
                  />
                </div>
                {!hideDurationField && (
                  <div className="col-sm-12">
                    <p className="duration-form-title">Each Slot Duration</p>
                    <div className="col-sm-6 duration-fields-block">
                      <div className="col-xs-6 duration-field">
                        <Field
                          id="durationHours"
                          label="Hours"
                          type="number"
                          min={0}
                          max={durationMinutes === 0 ? 24 : 23}
                          value={values.durationHours}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-xs-6 duration-field">
                        <Field
                          id="durationMinutes"
                          label="Minutes"
                          type="number"
                          min={0}
                          max={59}
                          value={values.durationMinutes}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="div-spacing-15" />
                  </div>
                )}
                <div className="col-sm-6">
                  <CurrencyField
                    id="createPrice"
                    type="text"
                    label="Price"
                    value={price}
                    error={!price}
                    onChange={value => this.handleChange(value, 'price')}
                  />
                </div>
                <div className="col-sm-6 resp-top-distance-20">
                  <Field
                    id="quantity"
                    label="Max Number of Sales per Slot"
                    type="number"
                    min={0}
                    value={quantity}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="div-spacing-20" />
                <div className="col-sm-6 exclude-dates-date-picker">
                  <DatePicker
                    selectedDates={excludedDates}
                    isDisabled={!values.startDate || !values.endDate}
                    buttonLabel={'Exclude Dates (Optional)'}
                    onApplyDates={onApplyDates}
                    isRangesDisabled={true}
                    startDate={values.startDate}
                    endDate={values.endDate}
                    isExcluding={true}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-seablue btn-shadow"
                    type="button"
                    disabled={isSubmitButtonDisabled}
                    onClick={onSubmit}
                  >
                    {'Generate Slots'}
                  </Button>
                  <Button className="btn btn-cancel btn-shadow" type="button" onClick={onCancel}>
                    {'Cancel'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
