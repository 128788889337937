import React, { Component } from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'

export default class GiftCardWarningModal extends Component {
  render() {
    const { isOpen, header, content, handleClose } = this.props

    return (
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={isOpen}
        contentLabel="Modal"
        onRequestClose={handleClose}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{header || 'Warning'}</p>
              </div>
              <div className="modal-body">
                <p>{content || 'Gift certificate has already been used.'}</p>
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button className="btn btn-default" type="button" onClick={handleClose}>
                    {'Ok'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
