import React from 'react'

// components
import Button from '../_library/Button'

const RefreshProductsBtn = ({ onRefresh, shopifySyncLoading }) => (
  <div className='right' style={{ margin: '5px', display: 'inline-block' }}>
    <Button
      loading={shopifySyncLoading}
      className="btn btn-default"
      type='button'
      onClick={onRefresh}
    >
        Sync Products
    </Button>
  </div>
)

export { RefreshProductsBtn }