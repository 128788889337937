import React from 'react'
import _get from 'lodash/get'

import Card from '../../_library/Card'
import NFTForm from './NFTForm'
import NFTLists from './NFTLists'

class NFTCard extends React.PureComponent{
  onTagsChange = (field, tags) => {
    const { setFieldValue } = this.props
    switch (field) {
      case 'eth':
        setFieldValue('nftContracts.ethereum', tags)
        break
      case 'pol':
        setFieldValue('nftContracts.polygon', tags)
        break
      case 'mainnet':
        setFieldValue('nftLists.solana-mainnet', tags)
        break
      default:
        break
    }
  }
  render() {
    const { nftContracts, setFieldValue, nftInput, errors, touched, nftLists, size } = this.props
    const ethereum = _get(nftContracts, 'ethereum')
    const polygon = _get(nftContracts, 'polygon')
    const mainnet = _get(nftLists, 'solana-mainnet')
    return (
      <Card icon={'fa-key'} title={'NFT Contract Addresses'}>
        <div className="nft-tags row">
          <div className="nft-tags-field">
            <>
              {!(polygon?.length && ethereum?.length && mainnet?.length) && (
                <div className={`col-${size ?? 'md'}-12`}>
                  <p className="control-label">
                    Enter valid NFT contract addresses that allow attendance 
                    (Ethereum/Polygon <strong>OR</strong> Solana Mainnet. Press space or enter after each one):
                  </p>
                </div>
              )}
            </>
            {errors?.nfts && touched && 
            <div className='col-md-12 tags-field'>
              <div className="help-block" style={{ position:"relative", maxWidth:"none" }}>{errors.nfts}</div>
            </div>}
            <NFTForm
              inputDisabled={mainnet.length}
              errors={errors}
              touched={touched}
              nftInput={nftInput}
              setFieldValue={setFieldValue}
              nftContracts={nftContracts}
              onTagsChange={this.onTagsChange}
            />
          </div>
          <div className='div-spacing-15' />
          <div className="nft-tags-field">
            <NFTLists
              inputDisabled={(polygon.length || ethereum.length)}
              errors={errors}
              touched={touched}
              nftInput={nftInput}
              setFieldValue={setFieldValue}
              nftLists={mainnet}
              onTagsChange={this.onTagsChange}
              size={size}
            />
          </div>
        </div>
      </Card>
    )
  }
}

export default NFTCard
