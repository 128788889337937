import React, { useState } from 'react'
import classNames from 'classnames'

export default React.memo((props) => {
  const { title, img, active, className } = props
  const [onHover, setOnHover] = useState(false)

  const onMouseEnter = () => {
    setOnHover(true)
  }
  const onMouseLeave = () => {
    setOnHover(false)
  }

  let iconObj = null; let iconObj_inActive = null
  if (img) {
    iconObj = <img className="activeIcon" src = {asset('/resources/images/event/performance/' + img + '.svg')}/>
    iconObj_inActive = <img className="inactiveIcon" src = {asset('/resources/images/event/performance/inactive/' + img + '.svg')}/>
  }
  const classes = classNames({
    'tab-title-content': true,
    active,
    'hover': onHover,
  }, className)

  return (
    <div
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {iconObj}
      {iconObj_inActive}
      <div className='title'>
        {title}
      </div>
    </div>
  )
})
