import _get from 'lodash/get'
import _has from 'lodash/has'
import _result from 'lodash/result'
import React from 'react'
import { connect } from 'react-redux'
import {
  RESEND_ORDER,
  CANCEL_ORDER,
  UPDATE_ORDER_TICKET,
  TICKETS_TYPE_BULK_UPDATE,
  FETCH_EVENT_ORDERS,
  TOGGLE_ORDER_RESALE,
  REFRESH_ORDERS_DATA,
} from '../../_common/redux/orders/actions'
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import SortableTableWithPaginationHOC from '../hoc/SortableTableWithPaginationHOC'
import SortableTableWithSearchHOC from '../hoc/SortableTableWithSearchHOC'
import { getTableColumns } from '../utils/sortableTableUtils'
import { getTicketRows, orderDetailRow } from '../events/EventOrders'
import SortableTable from '../_library/SortableTable'
import { getCustomers, createCustomerNote, getCustomer } from '../../_common/core/http_services'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import EventOrderTicketForm from '../events/order/TicketForm'
import TicketsTypeBulkUpdate from '../events/order/TicketsTypeBulkUpdate'
import LoadingBar from '../_library/LoadingBar'
import Field from '../_library/Field'
import Button from '../_library/Button'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDate,
} from '../../_common/core/validation'
import EventOrderNoteForm from '../events/order/NoteForm'
import { DISPLAY_FORMAT } from '../constants/timeFormats'
import { showAxiosError, showSuccessMessage } from '../utils/messenger'
import { DOWNLOAD_CSV, convertToCSV, getCSVData } from '../utils/csv'
import { baseRequest } from '../../_common/core/http_services/index'
import { crmAdapter } from '../../_common/core/http_services/adapters'

const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)
const DEFAULT_PAGE_SIZE = 10

@connect(
  state => {
    const isTicketsTypeUpdating = _has(state.loading, 'TICKETS_TYPE_BULK_UPDATE')
    const brand = state.brands.selected
    const { user } = state.auth

    return {
      isTicketsTypeUpdating,
      brand,
      user,
    }
  },
  {
    RESEND_ORDER,
    CANCEL_ORDER,
    UPDATE_ORDER_TICKET,
    FETCH_EVENT_TICKETS,
    TICKETS_TYPE_BULK_UPDATE,
    FETCH_EVENT_ORDERS,
    TOGGLE_ORDER_RESALE,
    REFRESH_ORDERS_DATA,
  },
)
export default class Customers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortBy: { column: 'ticketsPurchased', asc: false },
      sortByOrders: { column: 'total', asc: false },
      isLoadingData: true,
      data: [],
      searchValue: '',
      resetPagination: 0,
      detailsRows: [],
      ticketEdited: null,
      ticketEditModalShow: false,
      expandedRowId: null,
      orderID: null,
      showResend: false,
      resendEmail: '',
      showCancel: false,
      cancelMessage: '',
      cancelled: false,
      cancelling: false,
      toggleButtonLoading: false,
      toggleButtonLoadingId: null,
      noteCustomerEditModalShow: false,
      customerId: null,
      noteEdited: '',
      isNotesLoading: false,
      limit: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      loadingTickets: false,
      tickets: [],
      offset: 0,
      customerData: {},
      isLoadingExport: false,
    }
    this.tableColumns = getTableColumns([
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Email Address',
        key: 'email',
        className: 'email-event-customer',
      },
      {
        label: 'Total Revenue',
        key: 'totalRevenueUSD',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(null))(createFixedFloatNormalizer(2)(parseFloat(value))),
      },
      {
        label: 'No. of Events Attended',
        key: 'eventsAttended',
      },
      {
        label: 'No. of Tickets Purchased',
        key: 'ticketsPurchased',
      },
      {
        label: 'Total Revenue Referred',
        key: 'referredRevenueUSD',
      },
      {
        label: 'No. of Tickets Referred',
        key: 'referredTickets',
      },
      {
        label: 'No. of Link Clicks Referred',
        key: 'impressions',
      },
    ])
    this.tableOrdersColumns = getTableColumns([
      {
        label: 'Order ID',
        key: 'orderId',
      },
      {
        label: 'Event Name',
        key: 'name',
      },
      {
        label: 'Due Date',
        key: 'dateOrder',
        normalizer: value => formatDate(value, DISPLAY_FORMAT),
      },
      {
        label: 'Status',
        key: 'statusOrder',
      },
      {
        label: 'Net Revenue',
        key: 'totalSymbol',
      },
    ])
  }

  setCurrencies = currencies => {
    const currenciesColumn = currencies.map(currency => ({
      label: 'Total Revenue ' + currency.currency,
      key: 'totalRevenue' + currency.currency,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol({ currency }))(
          createFixedFloatNormalizer(2)(parseFloat(value ?? 0)),
        ),
      sort: this.sortBy,
    }))
    const referredColumn = currencies.map(currency => ({
      label: 'Total Revenue Referred ' + currency.currency,
      key: 'referredRevenue' + currency.currency,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol({ currency }))(
          createFixedFloatNormalizer(2)(parseFloat(value ?? 0)),
        ),
      sort: this.sortBy,
    }))
    const columns = [
      {
        label: 'Name',
        key: 'name',
        sort: this.sortBy,
      },
      {
        label: 'Email Address',
        key: 'email',
        className: 'email-event-customer',
        sort: this.sortBy,
      },
      ...currenciesColumn,
      {
        label: 'No. of Events Attended',
        key: 'eventsAttended',
        sort: this.sortBy,
      },
      {
        label: 'No. of Tickets Purchased',
        key: 'ticketsPurchased',
        sort: this.sortBy,
      },
      ...referredColumn,
      {
        label: 'No. of Tickets Referred',
        key: 'referredTickets',
        sort: this.sortBy,
      },
      {
        label: 'No. of Link Clicks Referred',
        key: 'impressions',
        sort: this.sortBy,
      },
    ]
    this.tableColumns = getTableColumns(columns)
  }

  onClickResend = (orderID, email, eventId) => {
    this.setState({ showResend: true, resendEmail: email, orderID, eventId })
  }

  onDisableToggle = (id, disabled, eventId, customerId) => {
    const { TOGGLE_ORDER_RESALE, brand } = this.props
    const { currencies } = this.state
    this.setState({ toggleButtonLoading: true, toggleButtonLoadingId: id }, () => {
      const detailsRows = this.renderCustomer(this.state.customerData)
      this.setState({ detailsRows }, async () => {
        try {
          await TOGGLE_ORDER_RESALE(eventId, id, { disabled })
          const data = await getCustomer(brand.id, customerId, currencies)
          this.setState({ toggleButtonLoading: false, toggleButtonLoadingId: null }, () => {
            const detailsRows = this.renderCustomer(data)
            this.setState({ detailsRows })
          })
        } catch (e) {
          showAxiosError(e)
        }
      })
    })
  }

  sortBy = column => {
    this.setState(
      { sortBy: { column: column.key, asc: !this.state.sortBy.asc }, isLoadingPageData: true },
      async () => {
        this.fetchCustomers()
      },
    )
  }

  renderCustomer = data => {
    const { sortByOrders, expandedRowId, toggleButtonLoading, toggleButtonLoadingId, isNotesLoading } =
      this.state
    const { user } = this.props
    const buttonStyle = { paddingRight: 4, fontSize: 16 }
    return data.map((customer, index) => {
      const t = {
        id: customer.id,
        notes: customer.notes,
        order: {
          billingFirstName: customer.billingFirstName,
          billingLastName: customer.billingLastName,
          billingEmail: customer.billingEmail,
          billingPhone: customer.billingPhone,
          billingCity: customer.billingCity,
          billingCountry: customer.billingCountry,
        },
      }
      const detailsRow = {
        id: customer.id,
        type: 'detailRow',
        component: (
          <table className="table">
            <tbody>
              {orderDetailRow({
                t,
                index,
                includeTablesData: false,
                openTicketEditModal: this.openTicketEditModal,
                setTicketTypesBulkUpdateModalState: this.setTicketTypesBulkUpdateModalState,
                openCustomerNoteEditModal: this.openCustomerNoteEditModal,
                deleteCustomerNote: this.deleteCustomerNote,
                isNotesLoading,
                isCustomerCRM: true,
                SortableTable: (
                  <SortableTable
                    data={customer.orders}
                    tableColumns={this.tableOrdersColumns}
                    enableCopyTable={true}
                    actionsLabel="Actions"
                    actions={data =>
                      [
                        {
                          label: 'Resend',
                          className: 'transparent-btn',
                          icon: (
                            <img
                              className="default-icosize"
                              style={buttonStyle}
                              src={asset('/resources/images/resend-ico.svg')}
                              alt=""
                            />
                          ),
                          onClick: data => {
                            this.onClickResend(data.id, customer.billingEmail, data.eventId)
                          },
                        },
                        {
                          label: 'Cancel',
                          className: 'transparent-btn',
                          icon: (
                            <img
                              className="default-icosize"
                              style={buttonStyle}
                              src={asset('/resources/images/cross-ico.svg')}
                              alt=""
                            />
                          ),
                          onClick: data => {
                            this.onClickCancel(data.id, data.eventId, customer.id)
                          },
                        },
                        {
                          label: 'Block Resale',
                          className: 'transparent-btn',
                          icon: (
                            <i
                              className="fa fa-ban"
                              aria-hidden="true"
                              style={{ ...buttonStyle, marginBottom: 0 }}
                            />
                          ),
                          onClick: data => {
                            this.onDisableToggle(data.id, true, data.eventId, customer.id)
                          },
                        },
                        {
                          label: 'Unblock Resale',
                          className: 'transparent-btn',
                          icon: (
                            <i
                              className="fa fa-fw fa-check"
                              aria-hidden="true"
                              style={{ ...buttonStyle, marginBottom: 0 }}
                            />
                          ),
                          onClick: data => {
                            this.onDisableToggle(data.id, false, data.eventId, customer.id)
                          },
                        },
                        {
                          label: '',
                          className: 'transparent-btn',
                          icon: (
                            <i
                              className="fa fa-spin fa-spinner"
                              style={{ ...buttonStyle, marginBottom: 0 }}
                            />
                          ),
                        },
                      ].filter(action => {
                        if (data.eventsInOrder !== 1) {
                          return false
                        }
                        const isToggleButtonsVisible = _get(data, 'resaleEnabled', false)
                        if (action.label === 'Resend') {
                          return true
                        }
                        if (action.label === 'Cancel' && Number(data.total) === 0) {
                          return true
                        }
                        const resaleDisabled = _get(data, 'disableResale', false)
                        if (action.label === 'Block Resale' && isToggleButtonsVisible) {
                          if (toggleButtonLoading && data.id === toggleButtonLoadingId) {
                            return false
                          }
                          if (!resaleDisabled) {
                            return true
                          }
                        }
                        if (action.label === 'Unblock Resale' && isToggleButtonsVisible) {
                          if (toggleButtonLoading && data.id === toggleButtonLoadingId) {
                            return false
                          }
                          if (resaleDisabled) {
                            return true
                          }
                        }
                        if (action.label === '' && isToggleButtonsVisible) {
                          if (toggleButtonLoading && data.id === toggleButtonLoadingId) {
                            return true
                          }
                        }
                        return false
                      })
                    }
                    enableSort={true}
                    expandedRowId={expandedRowId}
                    detailsRows={customer.orders.map(order => ({
                      id: order.id,
                      type: 'detailRow',
                      component: getTicketRows({
                        tickets: order.tickets,
                        status: order.status,
                        paymentPlanDetails: [],
                        addOns: order.addOns,
                        ticketDataCapture: [],
                        orderId: order.id,
                        discountCode: order.discountCode,
                        includeTablesData: false,
                        guestCount: order.guestCount,
                        currency: '',
                        debt: '',
                        total: '',
                        openTicketEditModal: this.openTicketEditModal,
                        setTicketTypesBulkUpdateModalState: this.setTicketTypesBulkUpdateModalState,
                        event: null,
                        eventId: order.eventId,
                        timeSlotsEnabled: order.timeSlotsEnabled,
                        eventsInOrder: order.eventsInOrder,
                        customerId: t.id,
                        user,
                      }),
                    }))}
                    sortBy={sortByOrders}
                    disableMobileView={true}
                  />
                ),
              })}
            </tbody>
          </table>
        ),
      }
      return detailsRow
    })
  }

  fetchCustomers = async () => {
    try {
      const { brand } = this.props
      const { limit, searchValue, pageNumber, offset, sortBy } = this.state
      const { data, pagination, currencies } = await getCustomers(
        searchValue,
        limit,
        pageNumber,
        brand.id,
        offset,
        sortBy.column,
        sortBy.asc,
      )

      this.setCurrencies(currencies)
      this.setState({
        isLoadingData: false,
        data,
        pagination,
        isLoadingPageData: false,
        currencies,
      })
    } catch (e) {
      this.setState({
        isLoadingData: false,
      })
    }
  }

  componentDidMount() {
    document.title = `Customers - The Ticket Fairy Dashboard`
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future',
    }
    this.fetchCustomers()
  }

  getSearchedData = ({ searchValue }) => {
    this.setState(
      {
        resetPagination: this.state.resetPagination + 1,
        searchValue,
        isLoadingData: true,
        limit: DEFAULT_PAGE_SIZE,
      },
      async () => {
        try {
          this.fetchCustomers()
        } catch (err) {
          this.setState({ isLoadingData: false })
        }
      },
    )
  }

  openTicketEditModal = (ticket, orderId, eventId, customerId) => {
    this.setState(
      {
        ticketEditModalShow: true,
        ticketEdited: ticket,
        expandedRowId: orderId,
        customerId,
      },
      async () => {
        try {
          if (eventId) {
            const { FETCH_EVENT_TICKETS } = this.props
            this.setState({ loadingTickets: true, tickets: [] })
            const res = await FETCH_EVENT_TICKETS(eventId)
            this.setState({ loadingTickets: false, tickets: res?.data })
          }
        } catch (e) {
          this.setState({ loadingTickets: false, tickets: [] })
        }
      },
    )
  }

  closeTicketEditModal = () => {
    this.setState({
      ticketEditModalShow: false,
    })
  }

  handleTicketSave = async form => {
    try {
      const { UPDATE_ORDER_TICKET, brand } = this.props
      const { ticketEdited, customerId, currencies } = this.state
      const orderTicketResponse = await UPDATE_ORDER_TICKET(ticketEdited.ticketHash, form)
      const [{ changeStatus }] = orderTicketResponse.data.$original.tickets
      this.setState({ ticketEditModalShow: false })
      const message =
        changeStatus === 'pending_user_confirmation'
          ? 'Successfully updated! \n Awaiting for customer confirmation.'
          : 'Successfully updated!'
      const data = await getCustomer(brand.id, customerId, currencies)
      const detailsRows = this.renderCustomer(data)
      this.setState({ detailsRows }, () => {
        showSuccessMessage(message)
      })
      return orderTicketResponse
    } catch (err) {
      showAxiosError(err)
      return Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  setTicketTypesBulkUpdateModalState =
    (isOpen, orderID = null, eventId, timeSlotsEnabled, customerId) =>
      () => {
        this.setState(
          {
            orderID,
            isTicketTypesBulkUpdateModalOpen: isOpen,
            ...(eventId ? { eventId } : {}),
            ...(timeSlotsEnabled ? { timeSlotsEnabled } : { timeSlotsEnabled: false }),
            customerId,
            expandedRowId: orderID,
          },
          async () => {
            try {
              if (eventId) {
                const { FETCH_EVENT_TICKETS } = this.props
                this.setState({ loadingTickets: true, tickets: [] })
                const res = await FETCH_EVENT_TICKETS(eventId)
                this.setState({ loadingTickets: false, tickets: res?.data })
              }
            } catch (e) {
              this.setState({ loadingTickets: false, tickets: [] })
            }
          },
        )
      }

  handleTicketsTypeBulkUpdate = async data => {
    try {
      const { TICKETS_TYPE_BULK_UPDATE, brand } = this.props
      const { eventId, customerId, currencies } = this.state
      await TICKETS_TYPE_BULK_UPDATE(eventId, this.state.orderID, data.ticketTypeId)
      const customerData = await getCustomer(brand.id, customerId, currencies)
      const detailsRows = this.renderCustomer(customerData)
      this.setState({ detailsRows })
      showSuccessMessage('Successfully updated!')
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setTicketTypesBulkUpdateModalState(false)()
    }
  }

  closeResendDialog = () => {
    this.setState({ showResend: false })
  }

  onResendEmailChanged = e => {
    this.setState({ resendEmail: e.target.value })
  }

  handleResend = async () => {
    try {
      const { RESEND_ORDER } = this.props
      const { orderID, resendEmail, eventId } = this.state
      const resendOrderResponse = await RESEND_ORDER(eventId, { orderID, resendEmail })
      showSuccessMessage('Successfully Sent')
      this.setState({ showResend: false })
      return resendOrderResponse
    } catch (err) {
      showAxiosError(err)
      this.setState({ showResend: false })
      return Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  onClickCancel = (orderID, eventId, customerId) => {
    this.setState({
      showCancel: true,
      orderID,
      cancelMessage: 'Do you really want to cancel the order?',
      eventId,
      customerId,
    })
  }

  closeCancelDialog = () => {
    this.setState({
      showCancel: false,
      cancelled: false,
    })
  }

  handleCancel = async () => {
    try {
      const { CANCEL_ORDER, brand } = this.props
      const { orderID, eventId, customerId, currencies } = this.state
      this.setState({
        cancelling: true,
      })
      const cancel_order_response = await CANCEL_ORDER(eventId, { orderID })
      this.setState({
        showCancel: true,
        cancelled: true,
        cancelling: false,
        cancelMessage: 'The order has been successfully cancelled',
      })
      const data = await getCustomer(brand.id, customerId, currencies)
      const detailsRows = this.renderCustomer(data)
      this.setState({ detailsRows, customerId: null })
      return cancel_order_response
    } catch (err) {
      showAxiosError(err)
      this.setState({
        showCancel: false,
        cancelled: false,
        cancelling: false,
        customerId: null,
      })
      return Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  closeCustomerNoteEditModal = () => {
    this.setState({
      noteCustomerEditModalShow: false,
      customerId: null,
      noteEdited: '',
    })
  }

  openCustomerNoteEditModal = (customerId, noteEdited) => {
    this.setState({
      noteCustomerEditModalShow: true,
      customerId,
      noteEdited,
    })
  }

  handleCustomerNoteSave = async (form, customerId) => {
    const { noteEdited, currencies } = this.state
    const { brand } = this.props
    this.setState(
      {
        noteCustomerEditModalShow: false,
        isNotesLoading: true,
      },
      () => {
        const detailsRows = this.renderCustomer(this.state.customerData)
        this.setState({ detailsRows }, async () => {
          try {
            const { note } = form
            await createCustomerNote(customerId, note)
            showSuccessMessage(noteEdited ? 'Successfully updated' : 'Successfully created')
            const data = await getCustomer(brand.id, customerId, currencies)
            this.setState({ isNotesLoading: false }, () => {
              const detailsRows = this.renderCustomer(data)
              this.setState({ detailsRows })
            })
          } catch (e) {
            showAxiosError(e)
          }
        })
      },
    )
  }

  deleteCustomerNote = async customerId => {
    const { brand } = this.props
    const { currencies } = this.state
    this.setState({ isNotesLoading: true }, async () => {
      try {
        await createCustomerNote(customerId, '')
        const detailsRows = this.renderCustomer(this.state.customerData)
        this.setState({ detailsRows }, async () => {
          showSuccessMessage('Successfully deleted')
          const data = await getCustomer(brand.id, customerId, currencies)
          this.setState({ isNotesLoading: false }, () => {
            const detailsRows = this.renderCustomer(data)
            this.setState({ detailsRows })
          })
        })
      } catch (e) {
        showAxiosError(e)
      }
    })
  }

  handlePageChange = (pageNumber, limit) => {
    this.setState({ isLoadingPageData: true, limit, pageNumber, offset: (pageNumber - 1) * 10 }, () => {
      this.fetchCustomers()
    })
  }

  getDetailRowData = async data => {
    try {
      const { brand } = this.props
      const { currencies } = this.state
      const customerData = await getCustomer(brand.id, data.id, currencies)
      const detailsRows = this.renderCustomer(customerData)
      this.setState({ detailsRows, customerData })
    } catch (e) {
      showAxiosError(e)
    }
  }

  onClickExportCSV = async () => {
    this.setState({ isLoadingExport: true })
    const { brand } = this.props
    const { sortBy, pagination } = this.state
    const registersPerQuery = 25000
    const queriesNumber = Math.floor(pagination.total / registersPerQuery)
    const queries = []
    const getPromise = offset =>
      baseRequest.get('crm/customers', {
        params: {
          search_query: '',
          limit: registersPerQuery,
          page: '',
          brandId: brand.id,
          offset,
          sortBy: sortBy.column,
          sortOrder: sortBy.asc ? 'asc' : 'desc',
        },
      })
    try {
      for (let i = 0; i <= queriesNumber; i++) {
        queries.push(getPromise(registersPerQuery * i))
      }
      const response = await Promise.all(queries)
      const data = response.reduce((prev, curr) => prev.concat(crmAdapter(curr.data).data), [])
      const csvData = getCSVData(this.tableColumns, data).map(customer =>
        customer.map(column => (column === '' ? 0 : column)),
      )
      const csv = convertToCSV({
        data: csvData,
      })

      DOWNLOAD_CSV(csv, `customers.csv`)
    } catch (error) {
      showAxiosError(error)
    } finally {
      this.setState({ isLoadingExport: false })
    }
  }

  render() {
    const {
      sortBy,
      data,
      isLoadingData,
      searchValue,
      resetPagination,
      detailsRows,
      ticketEdited,
      ticketEditModalShow,
      isTicketTypesBulkUpdateModalOpen,
      showResend,
      resendEmail,
      cancelMessage,
      cancelled,
      showCancel,
      cancelling,
      noteCustomerEditModalShow,
      noteEdited,
      customerId,
      loadingTickets,
      tickets,
      timeSlotsEnabled,
      pagination,
      isLoadingPageData,
      isLoadingExport,
    } = this.state
    const { isTicketsTypeUpdating, user } = this.props
    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(tickets)

    return (
      <div className="event-order event-customer">
        <div style={{ display: 'flex' }}>
          <img alt="" src={asset('/resources/images/system_icons/customer.svg')} style={{ marginRight: 8 }} />
          <div className="customers-title">Customers</div>
          {user.tfStaff && pagination?.total > 0 && (
            <Button
              onClick={this.onClickExportCSV}
              id="export-customers-button"
              className="btn btn-primary"
              style={{ margin: '0 0 0 auto' }}
              type="button"
              disabled={isLoadingExport}
            >
              {isLoadingExport ? <LoadingSpinner /> : 'Export To CSV'}
            </Button>
          )}
        </div>
        <SortableTableWithSearchAndPagination
          data={data}
          tableColumns={this.tableColumns}
          enableCopyTable={true}
          enableSort={true}
          sortBy={sortBy}
          disableMobileView={true}
          showAsTag
          detailsRows={detailsRows}
          resetPagination={resetPagination}
          placeholder={'Search user, tickets'}
          // pagination props
          showPageLoading={true}
          isLoadingPageData={isLoadingPageData}
          pageNumber={_get(pagination, 'page', 1)}
          totalRecords={_get(pagination, 'total', DEFAULT_PAGE_SIZE)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          getSearchedData={this.getSearchedData}
          onPageOrLimitChange={this.handlePageChange}
          // search props
          showSearchLoading={true}
          isLoadingSearchData={isLoadingData}
          searchValue={searchValue}
          searchBy={this.searchBy}
          isAsyncSearch={true}
          resultsCount={_get(pagination, 'total', DEFAULT_PAGE_SIZE)}
          showEmptyBar={true}
          disableInnerSort={true}
          isAsyncDetails
          getDetailRowData={this.getDetailRowData}
        />
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={noteCustomerEditModalShow}
          contentLabel="Modal"
          onRequestClose={this.closeCustomerNoteEditModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">{!noteEdited ? 'Create' : 'Edit'} Note</div>
              <div className="modal-body">
                <EventOrderNoteForm
                  onSubmit={form => this.handleCustomerNoteSave(form, customerId)}
                  onCancel={this.closeCustomerNoteEditModal}
                  submitLabel="Save"
                  initialValues={{ note: noteEdited }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={ticketEditModalShow}
          contentLabel="Modal"
          onRequestClose={() => this.closeTicketEditModal()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">Edit Ticket</div>
              <div className="modal-body">
                <EventOrderTicketForm
                  onSubmit={form => this.handleTicketSave(form)}
                  onCancel={() => this.closeTicketEditModal()}
                  submitLabel="Save"
                  initialValues={
                    ticketEdited
                      ? {
                        ...ticketEdited,
                        ticketTypeID: String(ticketEdited.ticketTypeId),
                      }
                      : ticketEdited
                  }
                  event={null}
                  tickets={tickets}
                  loadingTickets={loadingTickets}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={isTicketTypesBulkUpdateModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setTicketTypesBulkUpdateModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">
                {timeSlotsEnabled ? 'Change Time Slot' : 'Change Ticket Type'}
              </div>
              <div className="modal-body">
                {groupedTickets.length || noGroupedTickets.length ? (
                  <TicketsTypeBulkUpdate
                    defaultTicketTypeId={noGroupedTickets[0].id}
                    isLoading={isTicketsTypeUpdating || loadingTickets}
                    onSubmit={form => this.handleTicketsTypeBulkUpdate(form)}
                    onCancel={this.setTicketTypesBulkUpdateModalState(false)}
                    groupedTickets={groupedTickets}
                    noGroupedTickets={noGroupedTickets}
                  />
                ) : (
                  <LoadingBar />
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showResend}
          contentLabel="Modal"
          onRequestClose={() => this.closeResendDialog()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Resend Order</p>
                </div>
                <div className="modal-body">
                  <Field
                    ref="resend_email"
                    id="resend_email"
                    value={resendEmail}
                    label="Email Address"
                    size="large"
                    onChange={evt => this.onResendEmailChanged(evt)}
                  />
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => this.handleResend()}
                    >
                      Resend
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={() => this.closeResendDialog()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showCancel}
          contentLabel="Modal"
          onRequestClose={() => this.closeCancelDialog()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Cancel Order</p>
                </div>
                <div className="modal-body">
                  <p>{cancelMessage}</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    {!cancelled && (
                      <Button
                        className="btn btn-danger btn-shadow"
                        type="button"
                        disabled={cancelling}
                        onClick={() => this.handleCancel()}
                      >
                        Confirm Cancellation
                        {cancelling && <i className="fa fa-circle-o-notch fa-spin" />}
                      </Button>
                    )}
                    {cancelled && (
                      <Button
                        className="btn btn-primary btn-shadow"
                        type="button"
                        onClick={() => this.closeCancelDialog()}
                      >
                        OK
                      </Button>
                    )}
                    {!cancelled && (
                      <Button
                        className="btn btn-default btn-shadow"
                        type="button"
                        disabled={cancelling}
                        onClick={() => this.closeCancelDialog()}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

function LoadingSpinner() {
  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        height: '28px',
      }}
    >
      <div className="loading-button-spinner" />
    </div>
  )
}
