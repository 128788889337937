import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { BackgroundSignUp } from '../newsignup/BackgroundSignUp'
import EmailPasswordForm from './EmailPasswordForm'
import { Logo } from './Logo'
import { Progress } from './Progress'
import { LOGIN } from '../../../_common/redux/auth/actions'
import _get from 'lodash/get'
import { NotificationError } from './NotificationError'
import { Trademark } from '../newsignup/Trademark'
import { Titles } from './Titles'

const NewSignInPageRedux = ({ location: { state }, LOGIN, history }) => {
  useEffect(() => {
    localStorage.removeItem('createAccountState')
  }, [])
  const handleSubmit = async form => {
    try {
      const res = await LOGIN({
        attributes: { ...form, username: form.email, newSignupFlow: true }
      })
      const hasDashboardAccess = _get(res, 'data.hasDashboardAccess')
      if (hasDashboardAccess !== undefined) {
        isProd() && fbq('trackCustom', 'CustomerLoggedInSuccesfully', { email: form.email })
        history.push('/welcome/create-account', {
          skipCreateAccount: true, 
          password: form.password,
          email: form.email,
          firstName: _get(res, 'data.firstName'),
          lastName: _get(res, 'data.lastName'),
          confirmPassword: form.password,
        })
        throw new Error('Can update his account and create a brand.')
      }
      return res
    } catch (e) {
      const error = _get(e, 'response.data.message')
      if (error) {
        setError(error)
      }
      throw e
    }
  }
  const [error, setError] = useState('')
  return (
    <div className="absolute-container new-sign-in">
      <Logo />
      <Progress width={0} />
      <Titles text="Sign in as an event organizer" />
      {error ? <NotificationError error={error} setError={setError} /> : null}
      <BackgroundSignUp>
        <EmailPasswordForm onSubmit={handleSubmit} {...state} />
      </BackgroundSignUp>
      <Trademark />
    </div>
  )
}

const NewSignInPage = connect(null, { LOGIN })(NewSignInPageRedux)

export { NewSignInPage }
