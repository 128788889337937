import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import LoadingBar from '../_library/LoadingBar'
import Card from '../_library/Card'
import Button from '../_library/Button'
import SortableTable from '../_library/SortableTable/index'
import PermissionDenied from '../_library/PermissionDeniedComponent'

import { getTableColumns } from '../utils/sortableTableUtils'

import { GET_TIME_SLOT_GROUPS } from '../../_common/redux/tickets/actions'
import {
  CREATE_GIFT_CARD,
  FETCH_GIFT_CARD_TYPES,
  UPDATE_GIFT_CARD,
  DELETE_GIFT_CARD
} from '../../_common/redux/giftcards/actions'

import GiftCardManagementForm from './giftcards/GiftCardManagementForm'
import GiftCardEditModal from './giftcards/GiftCardEditModal'
import {
  createFixedFloatNormalizer,
  durationNormalizerCreater
} from '../../_common/core/validation'
import { getEventTicketsByGroup } from '../../_common/core/http_services'
import GiftCardDeleteModal from './giftcards/GiftCardDeleteModal'
import GiftCardWarningModal from './giftcards/GiftCardWarningModal'
import { showMessage } from '../utils/messenger'
import _get from 'lodash/get'

import {
  get_event,
  get_event_time_slot,
  get_gift_card_types,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'


@connect(
  state => {
    const event = get_event(state)
    const isLoadingTimeSlots = state.loading.has('GET_TIME_SLOT_GROUPS')
    const isLoadingGiftCards = state.loading.has('FETCH_GIFT_CARD_TYPES')
    const timeSlotGroups = get_event_time_slot(state)
    const giftCardTypes = get_gift_card_types(state)

    return {
      event,
      isLoadingTimeSlots,
      isLoadingGiftCards,
      timeSlotGroups,
      giftCardTypes,
    }
  },
  {
    CREATE_GIFT_CARD,
    GET_TIME_SLOT_GROUPS,
    FETCH_GIFT_CARD_TYPES,
    UPDATE_GIFT_CARD,
    DELETE_GIFT_CARD
  }
)
export default class EventGiftCertificatesMangement extends React.Component {
  constructor(props) {
    super(props)
    const currency = props.event.currency ? props.event.currency.symbol || 'US$' : null
    this.amountTableColumns = getTableColumns(
      [
        { label: 'Amount', key: 'amountItem', isSortable: true },
        {
          key: 'duration',
          label: 'Duration',
          normalizer: durationNormalizerCreater('month'),
          isSortable: false
        }
      ],
      currency
    )
    this.ticketsTableColumns = getTableColumns(
      [
        { key: 'ticketGroupName', label: 'Ticket', isSortable: true },
        { key: 'groupItemPrice', label: 'Item Price', isSortable: true },
        {
          key: 'duration',
          label: 'Duration',
          normalizer: durationNormalizerCreater('month'),
          isSortable: true
        }
      ],
      currency
    )
    this.state = {
      selectedGiftCard: null,
      display: false,
      deletingItems: {},
      isAddingCertificate: false,
      isFetchingTickets: false,
      isFetchingTicketsForEdit: false
    }
  }

  componentDidMount() {
    const { event: { id, displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    if (id) {
      this.fetchEventTickets(id)
      this.fetchGiftCards(id)
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  fetchGiftCards = async (eventId, isDeleting, removingItem) => {
    const { FETCH_GIFT_CARD_TYPES } = this.props
    try {
      await FETCH_GIFT_CARD_TYPES(eventId)
      !this.isUnmounted && this.setState({ isAddingCertificate: false })
      if (isDeleting) {
        this.setState(prevState => {
          const deletingItems = { ...prevState.deletingItems }
          delete deletingItems[removingItem]
          return {
            ...prevState,
            deletingItems
          }
        })
      }
    } catch (error) {}
  }

  fetchEventTickets = async eventId => {
    const { GET_TIME_SLOT_GROUPS } = this.props
    try {
      await GET_TIME_SLOT_GROUPS(eventId)
    } catch (error) {}
  }

  handleSubmit = async values => {
    const { event, CREATE_GIFT_CARD } = this.props
    const { selectedFlag, amount, ticketGroupId, amountItem, groupItemPrice, ...rest } = values
    try {
      const res = await CREATE_GIFT_CARD(event.id, {
        imageUrl: null,
        amount: amount.length ? amount : null,
        ticketGroupId: amount.length ? null : ticketGroupId,
        groupItemPrice: groupItemPrice * 100,
        ...rest
      })
      showMessage('success', 'Successfully Created!')
      this.fetchGiftCards(event.id)
      return res
    } catch (err) {
      showMessage('error', 'Error')
    }
  }

  handleUpdate = async (values, removingItem) => {
    const { event, UPDATE_GIFT_CARD } = this.props
    const {
      id,
      amount,
      ticketGroupId,
      requiresShipping,
      duration,
      enabled,
      groupItemPrice
    } = values
    try {
      const res = await UPDATE_GIFT_CARD(event.id, id, {
        imageUrl: null,
        amount: ticketGroupId ? null : amount && amount.length ? amount : null,
        ticketGroupId: ticketGroupId ? ticketGroupId : null,
        requiresShipping: !!requiresShipping,
        groupItemPrice: groupItemPrice * 100,
        duration,
        enabled
      })
      this.fetchGiftCards(event.id, true, removingItem)
      this.handleClose()
      showMessage('success', removingItem ? 'Successfully Deleted!' : 'Successfully Updated!')
      return res
    } catch (err) {
      this.handleClose()
      showMessage('error', 'Something went wrong!')
    }
  }

  handleEdit = selectedGiftCard => {
    if (selectedGiftCard.ticketGroupId) {
      _.set(selectedGiftCard, 'selectedFlag', ['flagTicket'])
    } else if (selectedGiftCard.amount) {
      _.set(selectedGiftCard, 'selectedFlag', ['flagAmount'])
    }
    this.setState(prevState => ({
      ...prevState,
      selectedGiftCard: {
        ...selectedGiftCard,
        groupItemPrice: createFixedFloatNormalizer(2)(selectedGiftCard.groupItemPrice / 100)
      },
      display: !prevState.display
    }))
  }

  handleDeleteButtonClick = selectedGiftCard => {
    if (selectedGiftCard.flagPurchased) {
      this.setState({ showWarningModal: true })
    } else {
      this.setState(() => ({ selectedGiftCard, isDeleteModalOpen: true }))
    }
  }

  handleGiftCertificateDelete = async id => {
    const { event, DELETE_GIFT_CARD } = this.props
    const { selectedGiftCard } = this.state
    const giftCardId = id && typeof id !== 'object' ? id : selectedGiftCard.id
    this.setState({ isDeleting: true })

    try {
      await DELETE_GIFT_CARD(event.id, giftCardId)
      this.setState({ isDeleting: false, isDeleteModalOpen: false, deletingItems: {} })
      showMessage('success', 'Successfully Deleted!')
      this.fetchGiftCards(event.id)
    } catch (err) {
      this.setState({ isDeleting: false, isDeleteModalOpen: false, deletingItems: {} })
    }
  }

  handleCancelDelete = () => {
    this.setState({
      selectedGiftCard: null,
      isDeleteModalOpen: false
    })
  }

  handleWarningModalClose = () => {
    this.setState({ showWarningModal: false })
  }

  handleAmountDelete = amount => {
    const { amountObj, amountArray } = this.getParsedAmountObj()
    if (amountObj.flagPurchased && amountArray.length === 1) {
      this.setState({ showWarningModal: true })
    } else {
      const newAmountArray = _.filter(amountArray, item => item !== amount.id)
      this.setState({ deletingItems: { [amount.id]: true } })
      if (newAmountArray.length) {
        this.handleUpdate({ ...amountObj, amount: newAmountArray }, amount.id)
      } else {
        this.handleGiftCertificateDelete(amount.giftCardId)
      }
    }
  }

  handleClose = () => {
    this.setState({
      selectedGiftCard: null,
      display: false
    })
  }

  getParsedAmountObj = () => {
    const { giftCardTypes } = this.props
    const amountGiftCardTypes = _.filter(giftCardTypes, item => !!item.amount)
    let amountObj = null
    let amountArray = null
    if (amountGiftCardTypes && amountGiftCardTypes[0]) {
      amountObj = amountGiftCardTypes[0]
      amountArray = amountObj.amount ? amountObj.amount : null
    }
    return { amountObj, amountArray }
  }

  addGiftCertificate = () => {
    this.setState({ isAddingCertificate: true })
  }

  handleCancelAdding = () => {
    this.setState({ isAddingCertificate: false })
  }

  getGroupItemPrice = (groupId, tickets) => {
    if (!groupId || !(tickets && tickets.length)) return ''
    let price = 0
    _.map(tickets, item => {
      price += item.attributes.price
    })
    return `${price / (tickets.length * 100)}`
  }

  handleTicketTypeChange = (fieldName, groupId, setFieldValue) => {
    const { event } = this.props
    const { selectedGiftCard } = this.state
    if (selectedGiftCard) {
      this.setState({ isFetchingTicketsForEdit: true })
    } else {
      this.setState({ isFetchingTickets: true })
    }

    getEventTicketsByGroup(event.id, groupId).then(res => {
      setFieldValue(
        'groupItemPrice',
        createFixedFloatNormalizer(2)(this.getGroupItemPrice(groupId, res.data.data))
      )
      this.setState({ isFetchingTickets: false, isFetchingTicketsForEdit: false })
    })
  }

  render() {
    const {
      event,
      timeSlotGroups,
      giftCardTypes,
      isLoadingTimeSlots,
      isLoadingGiftCards
    } = this.props
    const {
      selectedGiftCard,
      display,
      deletingItems,
      isAddingCertificate,
      isFetchingTickets,
      isFetchingTicketsForEdit,
      isDeleteModalOpen,
      isDeleting,
      showWarningModal
    } = this.state
    const currency = event.currency ? event.currency.symbol : 'US$'
    const isPermissionDenied = event ? !event.flagGiftCertificatesEnabled : false
    const ticketGiftCardTypes = _.filter(giftCardTypes, item => !!item.ticketGroupId)

    const { amountObj, amountArray } = this.getParsedAmountObj()
    const updatedAmountArray = amountArray
      ? _.map(amountArray, item => ({
        id: item,
        giftCardId: amountObj.id,
        amountItem: item,
        enabled: +amountObj.enabled,
        duration: amountObj.duration,
        flagPurchased: !!amountObj.flagPurchased
      }))
      : null

    const groupedTicketsOptions = _.map(timeSlotGroups.filter(timeSlotGroups => !!timeSlotGroups), ticketItem => ({
      label: ticketItem.groupName,
      value: ticketItem.id
    }))

    groupedTicketsOptions.unshift({ label: 'Select...', value: '' })

    return (
      <div>
        {isLoadingTimeSlots && isLoadingGiftCards ? (
          <LoadingBar title={"Hold tight! We're getting your gift cards' list..."} />
        ) : isPermissionDenied ? (
          <PermissionDenied subHeader={`You don't have access to this section`} />
        ) : (
          <div>
            <GiftCardDeleteModal
              isOpen={isDeleteModalOpen}
              handleDelete={this.handleGiftCertificateDelete}
              handleCancel={this.handleCancelDelete}
              isDeleting={isDeleting}
            />
            <GiftCardWarningModal
              isOpen={showWarningModal}
              handleClose={this.handleWarningModalClose}
            />
            <Card icon={'fa-toggle-on'} title={'Create Gift Certificates'}>
              <div className="row">
                <div className="col-sm-12">
                  {isAddingCertificate ? (
                    <GiftCardManagementForm
                      currency={event.currency}
                      groupedTicketsOptions={groupedTicketsOptions}
                      ticketGiftCardTypes={ticketGiftCardTypes}
                      onSubmit={this.handleSubmit}
                      handleCancelAdding={this.handleCancelAdding}
                      handleTicketTypeChange={this.handleTicketTypeChange}
                      isFetchingTickets={isFetchingTickets}
                    />
                  ) : (
                    <Button className="btn btn-ok" type="button" onClick={this.addGiftCertificate}>
                      Add Gift Certificate
                    </Button>
                  )}
                </div>
              </div>
            </Card>
            {ticketGiftCardTypes && ticketGiftCardTypes.length ? (
              <Card icon={'fa fa-ticket'} title={'Ticket Certificates'}>
                <div className="row">
                  <div className="col-sm-12">
                    <SortableTable
                      data={ticketGiftCardTypes}
                      tableColumns={this.ticketsTableColumns}
                      enableSort={true}
                      statuses={['enabled']}
                      actions={[
                        {
                          label: 'Edit',
                          className: 'btn btn-primary',
                          icon: 'fa fa-pencil fa-fw',
                          onClick: data => this.handleEdit(data)
                        },
                        {
                          label: 'Delete',
                          className: 'btn btn-danger',
                          icon: 'fa fa-trash fa-fw',
                          onClick: data => this.handleDeleteButtonClick(data)
                        }
                      ]}
                      editModalComponent={
                        <GiftCardEditModal
                          display={display}
                          initialValues={selectedGiftCard}
                          groupedTicketsOptions={groupedTicketsOptions}
                          onSubmit={this.handleUpdate}
                          handleCancel={this.handleClose}
                          currency={event.currency}
                          handleTicketTypeChange={this.handleTicketTypeChange}
                          isFetchingTickets={isFetchingTicketsForEdit}
                        />
                      }
                    />
                  </div>
                </div>
              </Card>
            ) : null}
            {amountArray && amountArray.length ? (
              <Card icon={'fa fa-money'} title={'Amount Certificates'}>
                <div className="row">
                  <div className="col-sm-12" style={{ maxWidth: 500 }}>
                    <SortableTable
                      data={updatedAmountArray}
                      tableColumns={this.amountTableColumns}
                      enableSort={true}
                      statuses={['enabled']}
                      actions={[
                        {
                          label: 'Delete',
                          className: 'btn btn-danger',
                          icon: 'fa fa-trash fa-fw',
                          isLoading: data => !!deletingItems[data.id],
                          onClick: data => this.handleAmountDelete(data)
                        }
                      ]}
                    />
                  </div>
                </div>
              </Card>
            ) : null}
          </div>
        )}
      </div>
    )
  }
}
