import _get from 'lodash/get'

export const alternativeViewChecks = [
  'titleCheck',
  'slugCheck',
  'dateCheck',
  'venueDetailsCheck',
  'descriptionsCheck',
  'backgroundCheck',
  'eventLogoCheck',
  'imageURLsCheck'
]

export const getAlternativeViewFromInitialValues = props => {
  const initialValues = _get(props, 'initialValues', {})
  const latitude = _get(initialValues, 'venue.latitude') || _get(initialValues, 'venue.venueLatitude') || 0
  const longitude = _get(initialValues, 'venue.longitude') || _get(initialValues, 'venue.venueLongitude') || 0
  const isImageURLs = !!_get(initialValues, 'imageURLs')
  const isDescriptions = !!_get(initialValues, 'descriptions')

  const initialData = {
    owner: _get(initialValues, 'owner'),
    descriptions: _get(initialValues, 'descriptions') || null,
    bannerURL: _get(initialValues, 'bannerURL'),
    imageURLs: _get(initialValues, 'imageURLs'),
    backgroundImage: _get(initialValues, 'backgroundImage') || null,
    autoBackground: !_get(initialValues, 'backgroundImage') || false,
    useImageInsteadOfTitle:
      !!_get(initialValues, 'titleReplacementImage') || !!_get(initialValues, 'titleReplacementImageSvg'),
    titleReplacementImage:
      _get(initialValues, 'titleReplacementImage') || _get(initialValues, 'titleReplacementImageSvg') || null,
    startDate: _get(initialValues, 'startDate') || '',
    endDate: _get(initialValues, 'endDate') || '',
    // Venue
    venueName: _get(initialValues, 'venue.displayName') || '',
    streetNumber: _get(initialValues, 'venue.venueStreetNumber') || '',
    street: _get(initialValues, 'venue.venueStreet') || '',
    city: _get(initialValues, 'venue.city') || '',
    state: _get(initialValues, 'venue.venueState') || '',
    postalCode: _get(initialValues, 'venue.venuePostalCode') || '',
    country: _get(initialValues, 'venue.country') || '',
    flagHidden: _get(initialValues, 'venue.venueHidden') || false,
    flagDisabled: _get(initialValues, 'venue.venueDisabledAccess') || false,
    googlePlaceId: _get(initialValues, 'venue.googlePlaceId'),
    latitude: typeof latitude == 'string' ? Number(latitude) : latitude,
    longitude: typeof longitude == 'string' ? Number(longitude) : longitude,
    searchValue: '',
    hideUntil: _get(initialValues, 'venue.hideVenueUntil') || '',
    locationInformation: _get(initialValues, 'venue.locationInformation'),

    title: _get(initialValues, 'displayName') || '',
    viewId: _get(initialValues, 'viewId') || null,
    slug: _get(initialValues, 'slug') || '',
    showOnHomePage: _get(initialValues, 'showOnHomePage') || null,
    ticketTypes: _get(initialValues, 'ticketTypes') || [],
    addOns: _get(initialValues, 'addOns') || [],
    referenceDescription: _get(initialValues, 'referenceDescription') || '',
    titleCheck: !!_get(initialValues, 'displayName'),
    dateCheck: !!_get(initialValues, 'startDate') && !!_get(initialValues, 'endDate'),
    backgroundCheck: !!_get(initialValues, 'backgroundImage'),
    eventLogoCheck:
      !!_get(initialValues, 'titleReplacementImage') || !!_get(initialValues, 'titleReplacementImageSvg'),
    slugCheck: !!_get(initialValues, 'slug'),
    venueDetailsCheck: !!_get(initialValues, 'venue.displayName'),
    descriptionsCheck: !!_get(initialValues, 'descriptions'),
    imageURLsCheck: !!_get(initialValues, 'imageURLs')
  }

  // image and description per language
  _get(initialValues, 'languages').map(locale => {
    initialData[locale + 'Check'] = isImageURLs ? !!_get(initialValues, 'imageURLs')[locale] : false
    initialData[locale + 'DescriptionCheck'] = isDescriptions
      ? !!_get(initialValues, 'descriptions')[locale]
      : false
  })

  return initialData
}

export const formatUncheckedData = (check, values) => {
  switch (check) {
  case 'titleCheck': {
    values.title = null
    break
  }
  case 'dateCheck': {
    values.startDate = null
    values.endDate = null
    break
  }
  case 'venueDetailsCheck': {
    values.venueId = null
    values.venue = {
      displayName: null,
      streetNumber: null,
      street: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      flagHidden: null,
      flagDisabled: null,
      googlePlaceId: null,
      latitude: null,
      longitude: null,
      searchValue: null,
      hideUntil: null,
      locationInformation: null
    }
    break
  }
  case 'descriptionsCheck': {
    values.descriptionLanguage = null
    values.descriptions = null
    values.forceUpdateTextEditor = null
    break
  }
  case 'backgroundCheck': {
    values.autoBackground = null
    values.backgroundImage = null
    break
  }
  case 'eventLogoCheck': {
    values.titleReplacementImage = null
    values.useImageInsteadOfTitle = null
    break
  }
  default: {
    break
  }
  }
  return values
}
