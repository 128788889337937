import _get from 'lodash/get'
import { ROLES } from '../../constants/roles'
import { visibleByConfig } from './visibleByConfig'

export const getBrandMenuItems = ({
  path,
  itemId,
  flagMultiEntryPassesBrand,
  userTfRole,
  configs,
  isBrandVerified,
}) => {
  const isTtfTeam = ROLES.is_ttf_team(userTfRole)
  const isTfSupport = ROLES.is_tf_support(userTfRole)
  const menuGroup = []
  const isDemographicsVisible = _get(configs, 'appearance.showDemographicTab', true)
  const isMusicVisible = _get(configs, 'appearance.showStreamingTab', true)

  const isTfSupportOrTtfTeam = isTtfTeam || isTfSupport

  menuGroup.push({
    img: 'details',
    title: 'Details',
    href: `/brand/${itemId}/details`,
    active: path === `/brand/${itemId}/details`,
    visible: true,
  })
  menuGroup.push({
    img: 'team',
    title: 'Team',
    href: `/brand/${itemId}/team`,
    active: path === `/brand/${itemId}/team`,
    visible: true,
  })
  menuGroup.push({
    img: 'credentials',
    title: 'Multi-Entry Passes',
    href: `/brand/${itemId}/multi-entry-passes`,
    active: path === `/brand/${itemId}/multi-entry-passes`,
    visible: flagMultiEntryPassesBrand,
  })
  menuGroup.push({
    img: 'events',
    title: 'Events',
    href: `/brand/${itemId}/events`,
    active: path === `/brand/${itemId}/events`,
    visible: true,
  })
  menuGroup.push({
    img: 'demographics',
    title: 'Demographics',
    href: `/brand/${itemId}/demographics`,
    active: path === `/brand/${itemId}/demographics`,
    visible: visibleByConfig(isDemographicsVisible, isTfSupportOrTtfTeam, true),
  })
  menuGroup.push({
    img: 'streaming',
    title: 'Music',
    href: `/brand/${itemId}/musicstreaming`,
    active: path === `/brand/${itemId}/musicstreaming`,
    visible: visibleByConfig(isMusicVisible, isTfSupportOrTtfTeam, true),
  })
  menuGroup.push({
    img: 'customer',
    title: 'Customers',
    href: `/brand/${itemId}/customers`,
    active: path === `/brand/${itemId}/customers`,
    visible: userTfRole === 'ttf_team' || userTfRole === 'tf_developer',
  })
  menuGroup.push({
    img: 'messaging',
    title: 'Messaging',
    href: `/brand/${itemId}/messaging`,
    active: path === `/brand/${itemId}/messaging`,
    visible: true,
    disabled: !isBrandVerified,
  })
  menuGroup.push({
    img: 'email-templates',
    title: 'Email Templates',
    href: `/brand/${itemId}/email-templates`,
    active: path === `/brand/${itemId}/email-templates`,
    visible: true,
  })

  return menuGroup
}
