import React from 'react'
import Modal from 'react-modal'
import _get from 'lodash/get'

import modalStyle from '../../../../_common/core/modalStyle'
import Button from '../../../_library/Button'

const AddOnDeactivateModal = props => {
  const {
    deactivatingAddon,
    loadingAddOnUpdate = {},
    handleDeactivateAddon,
    handleDeactivateAddonCancel,
  } = props
  const isDeactivating = loadingAddOnUpdate[_get(deactivatingAddon, 'id')]

  return (
    <Modal
      className="modal-dialog modal-trans"
      style={modalStyle}
      isOpen={!!deactivatingAddon}
      contentLabel="Modal"
      onRequestClose={handleDeactivateAddonCancel}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div>
            <div className="modal-header">
              <p className="h4 text-compact">Are you sure?</p>
            </div>
            <div className="modal-body">
              <p>Deactivating the add-on will prevent users from buying it.</p>
            </div>
            <div className="modal-footer">
              <div className="btn-toolbar btn-toolbar-right">
                <Button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => handleDeactivateAddon(deactivatingAddon, false)}
                  loading={isDeactivating}
                >
                  Deactivate
                </Button>
                <Button
                  className="btn btn-cancel"
                  type="button"
                  disabled={isDeactivating}
                  onClick={handleDeactivateAddonCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddOnDeactivateModal
