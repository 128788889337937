import _get from 'lodash/get'
import { baseRequest } from '.'

export const getPredefinedInvitations = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/reserved-seat`)
  return _get(res, 'data.data.attributes')
}

export const createPredefinedInvitation = async (eventId, data) => {
  const res = await baseRequest.post(`event/${eventId}/reserved-seat`, {
    data: {
      attributes: data,
    },
  })
  return _get(res, 'data')
}

export const deletePredefinedInvitation = async (eventId, predefinedId, seatId = '') => {
  const res = await baseRequest.delete(`event/${eventId}/reserved-seat/${predefinedId}`, {
    data: { data: { seat_id: seatId } },
  })
  return _get(res, 'data')
}

export const resendPredefinedInvitation = async (eventId, predefinedId) => {
  const res = await baseRequest.post(`event/${eventId}/reserved-seat/${predefinedId}/resend`)
  return _get(res, 'data')
}
