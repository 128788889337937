import React from 'react'
import TagsInput from 'react-tagsinput'
import Button from './Button'

export const SearchResultCount = ({ resultsCount }) => (
  <span className="search-result-text">Number of Matching: {resultsCount}</span>
)

const SearchInput = ({ value, onChange, searchValue, showAsTag, placeholder }) => {
  const tagsInputRef = React.useRef(null)
  const inputChangeHandler = ({ target: { value } }) => {
    onChange(value)
  }
  const autoSizing = ({ addTag, ...props }) => {
    const { onChange, value, ...other } = props
    if (searchValue) return null
    return (
      <input type="text" className="react-tagsinput-input" onChange={onChange} value={value} {...other} />
    )
  }
  const onChangeTags = tags => {
    onChange(tags.length === 1 ? tags[0] : '')
  }
  const saveTag = () => {
    tagsInputRef.current?.accept()
  }
  return (
    <div className="searchbar find_order">
      <div className="tags-field input-wrapper">
        {showAsTag ? (
          <TagsInput
            ref={tagsInputRef}
            inputProps={{ placeholder: placeholder ?? '' }}
            addKeys={[9, 13]}
            maxTags={1}
            onlyUnique
            value={searchValue ? [searchValue] : []}
            onChange={onChangeTags}
            renderInput={autoSizing}
          />
        ) : (
          <input
            onChange={inputChangeHandler}
            value={value}
            className="react-tagsinput table-search-no-focus"
          />
        )}
      </div>
      <Button className="search-btn" onClick={showAsTag ? saveTag : undefined}>
        <img alt="" src={asset('/resources/images/search-ico.svg')} />
      </Button>
    </div>
  )
}

export default SearchInput
