import React from 'react'
import produce from 'immer'
import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _keys from 'lodash/keys'
import _groupBy from 'lodash/groupBy'
import _values from 'lodash/values'
import EmptyBar from '../../../_library/EmptyBar'
import LoadingBar from '../../../_library/LoadingBar'
import SortableTable from '../../../_library/SortableTable'
import Toggle from '../../../_library/Toggle'
import { Radio, RadioGroup } from '../../../_library/RadioGroup'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { DAY_DISPLAY_FORMAT, DAY_FORMAT } from '../../../constants/timeFormats'

import { FuturePayments } from '../PaymentPlans'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import { RadioGroup as _RadioGroup } from '../../../formik/Fields'
import {
  currencyNormalizerCreator,
  createFixedFloatNormalizer,
  formatDate,
} from '../../../../_common/core/validation'
import { showAxiosError } from '../../../utils/messenger'
import { DateRangePicker } from '../../../_library/DateRangePicker'
import SalesByTicketType from './SalesByTicketType'
import { Chart } from '../../../_library/Chart'

const AUTO_REFRESH = 10 * 1000

export default class PerformancePaymentPlans extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initialData = null
    this.tableColumns = getTableColumns([
      { label: 'Order Date', key: 'order_date', formatTo: DAY_DISPLAY_FORMAT },
      { label: 'No. of Tickets Sold', key: 'quantity' },
      { label: 'Running Total  (No. of Tickets Sold)', key: 'numSalesTotal', isSortable: false },
      {
        label: 'Completed Payments',
        key: 'income',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        label: 'Expected Payments',
        key: 'expected_income',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ])
    this.footbarColumns = [
      'quantity',
      {
        key: 'income',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'expected_income',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    this.state = {
      isLoadingPaymentPlans: true,
      activeTable: 'all_orders',
      future_payments: {
        loading: false,
        exist: false,
        data: null,
        filter: {
          group_by: 'day',
          start_date: undefined,
          end_date: undefined,
          include_past_payments: 0,
        },
      },
      ticketTypeData: [],
      groupedByTicketType: {},
      ticketTypeDataLoading: false,
      ticketTypeDataLoaded: false,
    }
  }

  componentDidMount() {
    this.fetchPaymentPlans()
    this.fetchFuturePayments(true)
    this.fetchTicketTypePayments()
    this.fetchPaymentPlansId = setInterval(async () => {
      this.fetchPaymentPlans()
      this.fetchFuturePayments()
      this.fetchTicketTypePayments()
    }, AUTO_REFRESH)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearInterval(this.fetchPaymentPlansId)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState)) {
      return true
    }
    return false
  }

  fetchPaymentPlans = async () => {
    const { event } = this.props
    const section = 'payment_plan_sales'
    try {
      const data = await getPerfomanceData(event.id, section)
      const paymentPlansData = _get(data, section) || []
      this.handleResponse(paymentPlansData)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  fetchFuturePayments = async (isNew = false) => {
    const { event } = this.props
    const { future_payments } = this.state
    const { filter } = future_payments
    try {
      this.setState(
        produce(draftState => {
          if (isNew) {
            draftState.future_payments.loading = true
            draftState.future_payments.exist = false
          }
        }),
      )
      const { payment_plan_future_sales = [] } = await getPerfomanceData(
        event.id,
        'payment_plan_future_sales',
        filter,
      )

      this.setState(
        produce(draftState => {
          draftState.future_payments.exist = true
          draftState.future_payments.data = payment_plan_future_sales
        }),
      )
    } catch (error) {
      showAxiosError(error)
    } finally {
      this.setState(
        produce(draftState => {
          draftState.future_payments.loading = false
        }),
      )
    }
  }

  adaptPaymentPlanResponse = data => {
    const { tickets } = this.props
    const { paymentPlansData = [] } = this.state
    const adaptedData = _map(data, (item, index) => {
      const ticketId = item.option_matrix_record_id
      const currentTicket = _find(tickets, item1 => item1.id == ticketId) || { stock: 0 }

      const dataToReturn = {
        ...item,
        stock: currentTicket.stock,
        revenue: +item.cost * +item.num_sales,
        id: item.option_matrix_record_id + item.orderDate + item.cost,
      }
      const matchedItem = _find(paymentPlansData, item1 => item1.order_date == item.orderDate) || {
        expected_income: 0,
        income: 0,
      }
      dataToReturn.income = matchedItem.income
      dataToReturn.expectedIncome = matchedItem.expected_income
      return dataToReturn
    })
    const groupedByTicketType = _groupBy(
      adaptedData,
      item => (item.slotGroupName ? item.slotGroupName : item.release_type) + item.cost,
    )
    return { groupedByTicketType, adaptedData }
  }
  fetchTicketTypePayments = async () => {
    const { event } = this.props
    const { ticketTypeDataLoading } = this.state

    try {
      this.setState({
        ticketTypeDataLoading: true,
      })

      const { release_breakdown = [] } = await getPerfomanceData(event.id, 'release_breakdown', {
        type: 'payment_plan',
      })
      const { adaptedData, groupedByTicketType } = this.adaptPaymentPlanResponse(release_breakdown)
      this.setState({
        ticketTypeData: adaptedData,
        groupedByTicketType,
        ticketTypeDataLoading: false,
        ticketTypeDataLoaded: true,
      })
    } catch (error) {
      this.setState({
        ticketTypeDataLoading: false,
      })
      showAxiosError(error)
    }
  }

  handleResponse = (paymentPlansData, error) => {
    const { isLoadingPaymentPlans } = this.state

    if (this.isUnmounted) return
    isLoadingPaymentPlans &&
      this.setState({
        isLoadingPaymentPlans: false,
      })

    if (error) {
      showAxiosError(error)
    } else {
      if (!_isEqual(this.initialData, paymentPlansData)) {
        this.initialData = paymentPlansData
        this.setState(() => ({ paymentPlansData }))
      }
    }
  }

  onChangeActiveTable = value => {
    this.setState(() => ({ activeTable: value }))
  }

  getAllOrdersContent = () => {
    const { isLoadingPaymentPlans, paymentPlansData } = this.state
    const isEmptyData = !(paymentPlansData && paymentPlansData.length)

    if (isLoadingPaymentPlans) {
      return <LoadingBar />
    }
    if (isEmptyData) {
      return <EmptyBar />
    }

    return (
      <div className="table-background">
        <SortableTable
          tableColumns={this.tableColumns}
          data={paymentPlansData || []}
          enableCopyTable={true}
          enableSort={true}
          disableMobileView={true}
          calculatedColumns={[{ for: 'numSalesTotal', column: 'quantity' }]}
          footbar={{
            label: 'Total',
            columns: this.footbarColumns,
          }}
        />
      </div>
    )
  }

  changeFilter = valuesSet => {
    this.setState(
      produce(draftState => {
        _forEach(valuesSet, (value, key) => {
          draftState.future_payments.filter[key] = value
        })
      }),
      () => {
        this.fetchFuturePayments(true)
      },
    )
  }

  onChangeGroupByRadioGroup = value => {
    this.changeFilter({ group_by: value })
  }
  onChangeDateRange = (start, end) => {
    start = formatDate(start, DAY_FORMAT)
    end = formatDate(end, DAY_FORMAT)
    this.changeFilter({
      start_date: start,
      end_date: end,
    })
  }

  onClearDateRange = () => {
    this.changeFilter({
      start_date: undefined,
      end_date: undefined,
    })
  }

  onChangeIncludePastPayments = value => {
    this.changeFilter({ include_past_payments: +value })
  }

  getFuturePaymentsContent = () => {
    const { event } = this.props
    const { future_payments } = this.state
    const { loading, exist } = future_payments
    const { group_by, include_past_payments } = future_payments.filter

    return (
      <div className="table-background">
        <div>
          <fieldset disabled={loading && !exist}>
            <_RadioGroup
              name="show_by"
              groups={[
                { value: 'day', label: 'Show by Day' },
                { value: 'week', label: 'Show by Week' },
              ]}
              value={group_by}
              onChange={this.onChangeGroupByRadioGroup}
              classNames={{
                container: 'radio-group-container display-block',
                container_item: 'radio-group-item',
                component: '',
              }}
            />
            <br />
            <DateRangePicker
              onApply={this.onChangeDateRange}
              clearText="Clear Range"
              onClear={this.onClearDateRange}
            />
            <br />
            <Toggle
              id={'include_past_payments'}
              value={!!include_past_payments}
              title="Include Past Payments"
              onChange={this.onChangeIncludePastPayments}
            />
          </fieldset>
        </div>
        <div>
          <FuturePayments event={event} future_payments={future_payments} />
        </div>
      </div>
    )
  }
  getChartData = () => {
    const { ticketTypeData } = this.state

    const groupedByTicketType = _groupBy(ticketTypeData, item =>
      item.slotGroupName ? item.slotGroupName : item.release_type,
    )
    const data_ticketType = {}
    _map(groupedByTicketType, (row, key) => {
      if (!data_ticketType[key]) {
        data_ticketType[key] = 0
      }

      _map(row, rowItem => {
        data_ticketType[key] += rowItem.num_sales ? parseInt(rowItem.num_sales) : 0
      })
    })
    return data_ticketType
  }

  getPaymentTypeContent = () => {
    const { ticketTypeData, ticketTypeDataLoading, ticketTypeDataLoaded, groupedByTicketType } = this.state
    const { event, tickets } = this.props
    return (
      <div className="table-background">
        {!!ticketTypeData.length && (
          <Chart
            height="200px"
            width="200px"
            chartConfig={{
              type: 'pie',
              hideLegend: true,
              displayPercentage: true,
              data: {
                labels: _keys(this.getChartData()),
                datasets: [{ data: _values(this.getChartData()) }],
              },
              options: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        )}
        <SalesByTicketType
          data={ticketTypeData}
          event={event}
          tickets={tickets}
          ticketTypeDataLoading={ticketTypeDataLoading}
          ticketTypeDataLoaded={ticketTypeDataLoaded}
          groupedByTicketType={groupedByTicketType}
        />
      </div>
    )
  }

  render() {
    const { activeTable } = this.state

    let content = null
    if (activeTable === 'all_orders') {
      content = this.getAllOrdersContent()
    } else if (activeTable === 'future_payments') {
      content = this.getFuturePaymentsContent()
    } else if ('ticket_type') {
      content = this.getPaymentTypeContent()
    }

    return (
      <div>
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/ticket-payment-ico.svg')}
          />
          Payment Plans
        </div>
        <RadioGroup selectedValue={activeTable} onChange={this.onChangeActiveTable}>
          <Radio value={'all_orders'} label="All Orders" />
          <Radio value={'future_payments'} label="Future Payments" />
          <Radio value={'ticket_type'} label="Sales by Ticket Type" />
        </RadioGroup>
        {content}
      </div>
    )
  }
}
