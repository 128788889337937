import { connect } from 'react-redux'
import React from 'react'
import Notifications from '../_library/notifications/Notifications'
import { withRouter } from 'react-router-dom'
import LoadingBar from '../_library/LoadingBar'
import { FETCH_BRAND } from '../../_common/redux/brands/actions'
import PermissionDenied from '../_library/PermissionDeniedComponent'

@connect(
  state => ({
    brand: state.brands.selected,
  }),
  { FETCH_BRAND },
)
@withRouter
export default class BrandPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      brandLoading: true,
    }
  }
  componentDidMount() {
    const {
      FETCH_BRAND,
      match: {
        params: { id },
      },
    } = this.props
    FETCH_BRAND(id)
      .then(() => this.setState({ brandLoading: false }))
      .catch(() => this.setState({ brandLoading: false }))
  }

  render() {
    const {
      brand,
      location: { pathname },
      match: {
        params: { id },
      },
    } = this.props

    const { brandLoading } = this.state

    let content = null
    if (brand && String(brand.id) === id) {
      if (pathname.includes('sub-brands') || brand.parent) {
        content = this.getSubBrandPageBody(brand)
      } else {
        content = this.getBrandPageBody(brand)
      }
    } else {
      if (brandLoading) {
        content = <LoadingBar />
      } else {
        content = <PermissionDenied />
      }
    }

    return (
      <div className="body-main">
        <Notifications />
        {content}
      </div>
    )
  }

  getBrandPageBody = brand => {
    const { displayName } = brand
    const {
      children,
      location: { pathname },
    } = this.props
    return (
      <div>
        {!brand.isVerified && !pathname.includes('messaging') && (
          <div className="body-panel-header">
            <div className="left">
              <div className="title">
                <h3>{displayName}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="body-panel-spacing" />
        <div className="body-panel-content">{children}</div>
      </div>
    )
  }

  getSubBrandPageBody = brand => {
    const { displayName, parent } = brand
    const { children } = this.props
    return (
      <div>
        <div className="body-panel-header">
          <div className="left">
            <div className="title">
              <h3>{parent ? displayName : 'New Sub Brand'}</h3>
            </div>
          </div>
        </div>
        <div className="parent_brand__name bran-page__parent-brand">
          <div
            title="Parent brand"
            style={{
              backgroundImage: `url('${asset('/resources/images/system_icons/inactive/brands.svg')}')`,
            }}
          />
          <p>{parent ? parent.displayName : displayName}</p>
        </div>
        <div className="body-panel-content">{children}</div>
      </div>
    )
  }
}
