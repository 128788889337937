import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'

import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _get from 'lodash/get'

import {
  get_event,
} from '../../_common/core/selectors'

import {
  JSONDatatable, 
  TYPE_FROM_URL,
  SEARCHBAR, DATATABLE, PAGINATIONBAR, 
  IS_FOUND,
} from '../_library/JSONDatatable'
import EmptyBar from '../_library/EmptyBar'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const event = get_event(state)

    return {
      event,
    }
  },
)
export default class EventAudiencePsychographics extends React.Component {
  constructor(props) {
    super(props)
    this.entryCounts = {}
    this.state = {
      category: '_all',
      width: $(window).width(),
    }
    this.category_mapping = { '_all' : 'All' }
    this.activeSlideNumber = -1
    this.previousSlideNumber = 0
    this.totalSlideNumber = 0
    this.table = null

    this.fetchCallback = this.fetchCallback.bind(this)
    this.validateData = this.validateData.bind(this)
    this.getFilteredRows = this.getFilteredRows.bind(this)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
    this.getClipboardText = this.getClipboardText.bind(this)
    this.getTabHeader = this.getTabHeader.bind(this)
  }

  componentDidMount() {
    const { event: { displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])    
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillMount() {
    this.updateDimensions.bind(this)
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions(){
    this.autoSlide()
    this.setState({ width: $(window).width() })
  }

  onDatatableUpdated(){
    // this is called after JSONDatatable has been updated.
    this.setState({ category: this.state.category })
  }

  fetchCallback(rows, status){
    const category = { '_all' : 'All' }
    _map(rows, row=>{
      if(row.category){
        category[row.category] = row.category
      }
    })
    // cateogry name conversion
    _map(category, (cat, key)=>{
      switch(key){
        case 'default':
          category[key] = 'Interest'
          break
        case 'trait':
          category[key] = 'Type of Person'
          break				
        default:
          category[key] = cat.charAt(0).toUpperCase() + cat.slice(1)
          break
      }
    })
    this.category_mapping = category
  }

  validateData(data, index){
    // validate goes here
    // must set id!!!
    data.id = index
    return data
  }

  getFilteredRows(rows, search){
    const { category } = this.state
    let rows_filtered = rows		
		
    // filter by search
    const keyword = search.join('').trim().toLowerCase()
    if(keyword != ''){
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += IS_FOUND(item.name, keyword)
        found += IS_FOUND(item.category, keyword)
        return found > 0
      })
    }

    // calcuate counts per each category
    const self = this
    _map(this.category_mapping, (cat, key)=>{
      let count = rows_filtered.length
      if(key != '_all')
        count = (_filter(rows_filtered, { 'category': key })).length
      self.entryCounts[key] = count
    })

    // filter by category
    if(category != '_all')
      rows_filtered = _filter(rows_filtered, { category })

    return rows_filtered
  }

  getSortedRows(rows_filtered, sort){
    if(sort){
      const dir = sort.asc ? 'asc' : 'desc'
      switch(sort.index){
        case 0: // name
          rows_filtered = _orderBy(rows_filtered, t=>t.name.toLowerCase(), dir)
          break
        case 1: // weight
          rows_filtered = _orderBy(rows_filtered, t=>{
            const average_score = t.scores.reduce((p, c)=>p + c) / t.scores.length
            const weight = parseInt(t.count, 10) * average_score
            return parseFloat(weight)
          }, dir)
          break
        case 2: // category
          rows_filtered = _orderBy(rows_filtered, t=>t.category, dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  getClipboardText(rows_filtered){
    let ret = ''
    ret += 'Name' + '\t' + 'Weight' + '\n'
    _map(rows_filtered, t=>{
      const average_score = t.scores.reduce((p, c)=>p + c) / t.scores.length
      const weight = parseInt(t.count, 10) * average_score
      ret += t.name + '\t' + parseInt(weight) + '\n'
    })
    return ret
  }

  getTableData(datatable, rows_filtered, sort){
    const clipboard_text = this.getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)
    const content_header = datatable.getHeaderRow(datatable, [
      { title: 'Name', sort: true },
      { title: 'Weight', sort: true, className: 'column-weight' },
      { title: clipboard, sort: false, className: 'column-clipboard' },
    ], sort)		
			
    let number = 0
    const content_table = _map(rows_filtered, (t, index)=>{
      const average_score = t.scores.reduce((p, c)=>p + c) / t.scores.length
      const weight = parseInt(t.count, 10) * average_score
      const category = this.category_mapping[t.category] ? this.category_mapping[t.category] : t.category

      const content_row = <tr key={index} className={t.isExpanded ? ' tr-expanded-row' : (number++ % 2==0 ? 'table-stripe-row1' : 'table-stripe-row2')}>					
        <td>{t.name}</td>
        <td className='column-weight'>{parseInt(weight)}</td>
        <td>&nbsp;</td>
      </tr>
      return content_row
    })

    return (rows_filtered.length != 0) ? (
      <table className="table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {content_table}
        </tbody>
      </table>
    ): (
      <EmptyBar/>
    )
  }

  onClickTab(key){
    this.setState({ category: key })
  }

  getVisibleSlideCount() {
    const width = $(window).width()
    if(width < 376)	return 1
    else if(width < 641) return 2
    else if(width < 769) return 3
    else if(width < 961) return 4
    else if(width < 1025) return 5
    else if(width < 1121) return 6
    else if(width < 1201) return 7
    else if(width < 1401) return 8
    else if(width < 10001) return 10
    return 0
  }

  autoSlide(){
    let slider = null
    if(this.table && this.table.refs.slider_category){
      slider = this.table.refs.slider_category
    }
    if(this.activeSlideNumber != -1 && slider){
      setTimeout(()=>{
        const visibleCount = this.getVisibleSlideCount()
        const old = this.previousSlideNumber
        if(this.previousSlideNumber > this.activeSlideNumber)
          this.previousSlideNumber = this.activeSlideNumber
        if(this.previousSlideNumber + visibleCount <= this.activeSlideNumber)
          this.previousSlideNumber = this.activeSlideNumber - visibleCount + 1
        if(this.previousSlideNumber >= this.totalSlideNumber - visibleCount && this.totalSlideNumber >= visibleCount)
          this.previousSlideNumber = this.totalSlideNumber - visibleCount
        if(old != this.previousSlideNumber && slider){
          // save current position and move to it
          slider.slickGoTo(this.previousSlideNumber)
        }
      }, 500)
    }else{
      this.previousSlideNumber = 0
      this.activeSlideNumber = -1
    }
  }

  onClickSlide(slideNumber){
    this.previousSlideNumber = slideNumber
  }

  getTabHeader(){
    const { category } = this.state
    let tab_keys = Object.keys(this.category_mapping)
    const images = ['All', 'Artist', 'Brand', 'Celebrity', 'Community', 'Event', 'Game', 'Interest', 'Nonprofit', 'Place', 'Publisher', 'Show', 'Team', 'Type of Person']
    tab_keys = _orderBy(tab_keys, key=>this.entryCounts[key], 'desc')
    const tab_header = _map(tab_keys, (key, index)=>{
      const title = this.category_mapping[key]
      const imageName = images.indexOf(title) == -1 ? '_Unknown' : title
      if(key == category){
        this.activeSlideNumber = index
      }
      return (
        <div key={index} className={'tab-title' + (key == category ? ' selected' : '')} onClick={() => this.onClickTab(key)}>					
          <img src={asset('/resources/images/psychographics/' + imageName + '.svg')}/>
          {title}					
        </div>
      )
    })

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      draggable: false,
      swipeToSlide: false,
      slidesToShow: this.getVisibleSlideCount(),
      afterChange: this.onClickSlide.bind(this),
    }
    return (
      <div key='slider' className='psychographics-slider'>
        <Slider ref='slider_category' {...settings}>
          {tab_header}
        </Slider>
      </div>
    )
  }

  render() {
    const { event } = this.props

    return (
      <div className="event-psychographics">
        <div className="row">
          <div className="col-xs-12">
            <JSONDatatable
              onRef={ref => (this.table = ref)}
              type={TYPE_FROM_URL}
              data={{ url: `/api/events/${event.id}/relationships/audience/psychographics`, node: 'data.audience.interests.*' }}
              sort={{ index: 1, asc: false }}
              fetchCallback={this.fetchCallback}
              validateData={this.validateData}
              getFilteredRows={this.getFilteredRows}
              getSortedRows={this.getSortedRows}
              getTableData={this.getTableData}
              getClipboardText={this.getClipboardText}
              usePagination={true}
              loadingBarTitle={'Hold tight! We\'re getting your event\'s psychographics...'}
            >
              {/* It can give additional className to SEARCHBAR, DATATABLE, PAGINATIONBAR by specifying className="XXX" */}
              <div ref={SEARCHBAR} hasSearch autoFocus labelTotalCount="Number of Matching Categories" focusAfter=".tab-title.selected" />
              <div getContent={this.getTabHeader}/>
              <div ref={DATATABLE}/> 
              <div ref={PAGINATIONBAR}/>
            </JSONDatatable>
          </div>
        </div>
      </div>
    )
  }
}

