import React from 'react'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import _groupBy from 'lodash/groupBy'
import _clone from 'lodash/clone'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import moment from 'moment'
import { DateRangePicker } from '../../_library/DateRangePicker'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import EmptyBar from '../../_library/EmptyBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import {
  formatDay,
  formatDateTime,
  createFixedFloatNormalizer,
  currencyNormalizerCreator
} from '../../../_common/core/validation/normalizers'
import SalesBarChart from './components/SalesBarChart'
import { SaveTemplate, SelectFilterTemplate } from '../../_library/FilterTemplate'
import { DAY_DISPLAY_FORMAT, DAY_FORMAT } from '../../constants/timeFormats'
import CardHeader from '../../_library/CardHeader'
import { ROLES } from '../../constants/roles'

export default class PerformanceSales extends React.PureComponent {
  constructor(props) {
    super(props)
    this.userRoleOnEvent = _get(props, 'event.self.role')
    this.tableColumns = getTableColumns(
      [
        {
          key: 'order_date',
          label: 'Order Date',
          isSortable: true,
          normalizer: value => formatDay(value)
        },
        {
          key: 'quantity',
          label: 'No. of Tickets Sold',
          isSortable: true
        },
        {
          key: 'quantityTotal',
          label: 'Running Total (No. of Tickets Sold)',
          labelComponent: (
            <span>
              Running Total <br /> (No. of Tickets Sold)
            </span>
          ),
          isSortable: false
        },
        {
          key: 'income',
          label: 'Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          hideColumn: this.userRoleOnEvent === ROLES.LIMITED_STATS,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'revenueTotal',
          label: 'Running Total (Revenue)',
          labelComponent: (
            <span>
              Running Total <br /> (Revenue)
            </span>
          ),
          isSortable: false,
          disableNormalizerOnCopy: true,
          hideColumn: this.userRoleOnEvent === ROLES.LIMITED_STATS,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'grossIncome',
          label: 'Gross Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          hideColumn: this.userRoleOnEvent === ROLES.LIMITED_STATS,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'grossRevenueTotal',
          label: 'Running Total (Gross Revenue)',
          labelComponent: (
            <span>
              Running Total <br /> (Gross Revenue)
            </span>
          ),
          isSortable: false,
          disableNormalizerOnCopy: true,
          hideColumn: this.userRoleOnEvent === ROLES.LIMITED_STATS,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        }
      ],
      getCurrencySymbol(props.event)
    )

    this.detailsTableColumns = getTableColumns([
      {
        key: 'order_datetime',
        label: 'Time of the Order',
        className: 'detail-cell',
        isSortable: false,
        normalizer: formatDateTime
      },
      {
        key: 'quantity',
        label: 'No. of Tickets Sold',
        className: 'detail-cell',
        isSortable: false
      },
      {
        key: 'income',
        label: 'Revenue',
        className: 'detail-cell',
        isSortable: false,
        disableNormalizerOnCopy: true,
        hideColumn: this.userRoleOnEvent === ROLES.LIMITED_STATS,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0')
          )
      }
    ])

    this.footbarColumns = [
      { key: 'quantityTotal', sourceKey: 'quantity' },
      {
        key: 'revenueTotal',
        sourceKey: 'income',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenueTotal',
        sourceKey: 'grossIncome',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]

    this.state = {
      startDate: null,
      endDate: null,
      sortBy: { column: 'order_date', asc: true },
      sortBySubTable: { column: 'order_datetime', asc: true },
      filterUpdateVersion: 0,
      detailsRows: [],
      dataCalcutions: null,
      data_revenue: {},
      data_quantity: {},
      filterApplied: false
    }
  }

  componentDidUpdate(prevProps) {
    const { performance } = this.props
    const { dataCalcutions } = this.state

    if (!_isEmpty(performance) && (!_isEqual(performance, prevProps.performance) || !dataCalcutions)) {
      const { sales } = performance
      this.calculateData(sales, this.state.startDate, this.state.endDate)
    }
  }

  calculateData = (sales = this.props.performance.sales || [], startDate, endDate) => {
    if (!(sales && sales.length)) {
      this.setState({ dataCalcutions: [], detailsRows: [] })
      return []
    }

    let filteredSales = _clone(sales)
    if (startDate && endDate) {
      filteredSales = _filter(
        sales,
        item =>
          moment(item.order_date).isSameOrAfter(moment(startDate).format(DAY_FORMAT)) &&
          moment(item.order_date).isSameOrBefore(moment(endDate).format(DAY_FORMAT))
      )
    }

    const salesGroupedByDate = _groupBy(filteredSales, 'order_date')
    this.setChartData(salesGroupedByDate)
    const detailsRows = []
    const dataCalcutions = _map(salesGroupedByDate, (item, key) => {
      const [totalQuantity, totalIncome, totalGrossIncome] = this.calculateTotalValues(item)
      this.getDetailRows(item, key, detailsRows)

      return {
        id: key,
        order_date: key,
        quantity: totalQuantity,
        income: totalIncome,
        grossIncome: totalGrossIncome
      }
    })

    this.setState({ dataCalcutions, detailsRows })
  }

  getDetailRows = (data, date, detailsRows) => {
    const groupedData = _groupBy(data, item => moment(item.order_datetime).startOf('hours').format('h:mm a'))
    const detailsData = []

    _map(groupedData, (item, time) => {
      const [totalQuantity, totalIncome] = this.calculateTotalValues(item)

      detailsData.push({
        id: item[0].order_date,
        order_datetime: item[0].order_datetime,
        quantity: totalQuantity,
        income: totalIncome
      })
    })

    !!detailsData.length &&
      detailsRows.push({
        id: detailsData[0].id,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`ticket_sales-${detailRowIndex}`}
            data={detailsData || []}
            tableColumns={this.detailsTableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
            sortBy={this.state.sortBySubTable}
          />
        )
      })
  }

  setChartData = salesGroupedByDate => {
    const data_revenue = {}
    const data_quantity = {}

    _forEach(salesGroupedByDate, (item, date) => {
      const [totalQuantity, totalIncome] = this.calculateTotalValues(item)

      data_quantity[moment(date).format(DAY_DISPLAY_FORMAT)] = totalQuantity
      data_revenue[moment(date).format(DAY_DISPLAY_FORMAT)] = totalIncome
    })

    this.setState({ data_revenue, data_quantity })
  }

  calculateTotalValues = data => {
    const totalQuantity = _reduce(data, (sum, n) => sum + parseInt(n.quantity), 0)
    const totalGrossIncome = _reduce(data, (sum, n) => (sum += parseFloat(n.grossIncome)), 0)

    const totalIncome = _reduce(
      data,
      (sum, n) => {
        let income = parseFloat(n.currentDeposit)
        if (isNaN(income)) {
          income = parseFloat(n.income)
        }
        return sum + income
      },
      0
    )

    return [totalQuantity, totalIncome, totalGrossIncome]
  }

  onDateRangeApply = (start, end) => {
    this.setState({ filterApplied: true })
    this.calculateData(this.props.performance.sales, start, end)
  }

  onDateRangeClear = () => {
    this.calculateData()
  }

  isEmptyChecker = data => {
    const { startDate, endDate } = data
    return !(startDate && endDate)
  }

  onSaveFilter = () => {
    this.setState(s => ({ filterUpdateVersion: s.filterUpdateVersion + 1 }))
  }

  onSelectFilter = template => {
    let { value } = template
    try {
      value = JSON.parse(value)
      const { startDate, endDate } = value
      this.onDateRangeApply(moment(startDate), moment(endDate))
    } catch (e) {}
  }

  render() {
    const { event, isLimited, performance } = this.props
    const {
      startDate,
      endDate,
      sortBy,
      filterUpdateVersion,
      dataCalcutions,
      data_revenue,
      data_quantity,
      detailsRows,
      filterApplied
    } = this.state
    const isLoaded = !!dataCalcutions
    const showSaveTemplateBtn = startDate && endDate
    const count = performance && performance.sales && performance.sales.length

    return (
      <div ref="cardContainer" className="performance-sales">
        <CardHeader
          imageUrl="/resources/images/event/performance/ticket-sales-ico.svg"
          bodyText="Ticket Sales"
        />
        <div className="table-background">
          {!isLoaded ? (
            <LoadingBar title={"Hold tight! We're getting your sales..."} />
          ) : !filterApplied && !dataCalcutions.length ? (
            <EmptyBar />
          ) : (
            <div>
              <SalesBarChart
                isLimited={isLimited}
                data_quantity={data_quantity}
                data_revenue={data_revenue}
              />
              {count > 0 && (
                <div className="row performance-sales-daterange">
                  <div className="col-sm-8 col-xs-12">
                    <div
                      style={{ display: 'inline-block', marginTop: 10, marginBottom: 10, marginRight: 10 }}
                    >
                      <DateRangePicker
                        onApply={this.onDateRangeApply}
                        onClear={this.onDateRangeClear}
                        value={{ startDate, endDate }}
                      />
                    </div>
                    {showSaveTemplateBtn ? (
                      <div style={{ margin: '10px 0px', display: 'inline-block' }}>
                        <SaveTemplate
                          data={{
                            startDate,
                            endDate
                          }}
                          isEmptyChecker={this.isEmptyChecker}
                          storagePath={`event-${event.id}-performance-ticket-sales-filter`}
                          onSaveFilter={this.onSaveFilter}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="col-sm-4 col-xs-12"
                    style={{ margin: '10px 0', textAlign: isMobileDevice() ? 'start' : 'end' }}
                  >
                    <SelectFilterTemplate
                      storagePath={`event-${event.id}-performance-ticket-sales-filter`}
                      onSelectFilter={this.onSelectFilter}
                      updateVersion={filterUpdateVersion}
                    />
                  </div>
                </div>
              )}
              <SortableTable
                e2e_test_id="ticket_sales"
                data={dataCalcutions}
                tableColumns={this.tableColumns}
                calculatedColumns={[
                  { for: 'quantityTotal', column: 'quantity' },
                  { for: 'revenueTotal', column: 'income' },
                  { for: 'grossRevenueTotal', column: 'grossIncome' }
                ]}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                sortBy={sortBy}
                detailsRows={detailsRows}
                footbar={{
                  label: 'Total',
                  columns: this.footbarColumns
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
