export const EVENT_CATEGORIES = [
  {
    id: 'eventCategory_art',
    label: 'Art'
  },
  {
    id: 'eventCategory_cannabis',
    label: 'Cannabis'
  },
  {
    id: 'eventCategory_charity',
    label: 'Charity'
  },
  {
    id: 'eventCategory_comedy',
    label: 'Comedy'
  },
  {
    id: 'eventCategory_dayParty',
    label: 'Day Party'
  },
  {
    id: 'eventCategory_fashion',
    label: 'Fashion'
  },
  {
    id: 'eventCategory_festival',
    label: 'Festival'
  },
  {
    id: 'eventCategory_film',
    label: 'Film'
  },
  {
    id: 'eventCategory_free',
    label: 'Free'
  },
  {
    id: 'eventCategory_lateNight',
    label: 'Late Night'
  },
  {
    id: 'eventCategory_literary',
    label: 'Literary'
  },
  {
    id: 'eventCategory_outdoors',
    label: 'Outdoors'
  },
  {
    id: 'eventCategory_poc',
    label: 'POC'
  },
  {
    id: 'eventCategory_communityActivism',
    label: 'Community / Activism'
  },
  {
    id: 'eventCategory_djDanceParty',
    label: 'DJ / Dance Party'
  },
  {
    id: 'eventCategory_esotericSpiritual',
    label: 'Esoteric / Spiritual'
  },
  {
    id: 'eventCategory_fitnessWellness',
    label: 'Fitness / Wellness'
  },
  {
    id: 'eventCategory_foodDining',
    label: 'Food / Dining'
  },
  {
    id: 'eventCategory_gamingEsports',
    label: 'Gaming / eSports'
  },
  {
    id: 'eventCategory_lecturePanel',
    label: 'Lecture / Panel'
  },
  {
    id: 'eventCategory_lgbtqia+',
    label: 'LGBTQIA+'
  },
  {
    id: 'eventCategory_liveMusic',
    label: 'Live Music'
  },
  {
    id: 'eventCategory_onlineVirtual',
    label: 'Online / Virtual'
  },
  {
    id: 'eventCategory_performingArts',
    label: 'Performing Arts'
  },
  {
    id: 'eventCategory_scienceTech',
    label: 'Science / Tech'
  },
  {
    id: 'eventCategory_sexFetish',
    label: 'Sex / Fetish'
  }
]
