import React, { useState, useCallback } from 'react'
import _map from 'lodash/map'
import moment from 'moment-timezone'

import EmptyBar from '../../_library/EmptyBar'
import modalStyle from '../../../_common/core/modalStyle'
import ConfirmModal from '../../_library/ConfirmModal'
import { getTableColumns } from '../../utils/sortableTableUtils'
import SortableTable from '../../_library/SortableTable'
import { useEffect } from 'react'
import { getTwilioSentSMS } from '../../../_common/core/http_services/twilio'
import { showAxiosError } from '../../utils/messenger'

export default function SentSMS({ event, isSMSSent, resetSMSState }) {
  const [contentModalOpen, setContentModalOpen] = useState(false)
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false)
  const [mailoutDisplayed, setMailoutDisplayed] = useState(null)
  const [sentSMS, setSentSMS] = useState([])
  const [recipientsData, setRecipientsdata] = useState({})

  const calculateData = useCallback(rows => {
    const formattedData = _map(rows, (item, key) => ({
      id: key,
      message: item?.content,
      sent_by: `${item?.sent_by_first_name} ${item?.sent_by_last_name}`,
      recipients: JSON.parse(item?.recipients)?.length,
      date: moment.utc(item?.sent_data).format('DD MMM YYYY hh:mm a'),
      show_content: (
        <div aria-hidden={true} className="btn btn-blue btn-shadow" onClick={() => showContentModal(item)}>
          <i className="fa fa-file-text" aria-hidden="true" />
          Show Content
        </div>
      ),
      show_recipients: (
        <div
          aria-hidden={true}
          className="btn btn-default btn-shadow"
          onClick={() => showRecipientsModal(item?.recipients)}
        >
          <i className="fa fa-users" aria-hidden="true" />
          Show Recipients
        </div>
      )
    }))
    return formattedData
  }, [])

  const fetchSMSData = useCallback(async () => {
    try {
      const sentSMSData = await getTwilioSentSMS(event.id)
      const formattedData = calculateData(sentSMSData) || []
      return formattedData
    } catch (e) {
      showAxiosError(e)
    }
  }, [calculateData, event])

  useEffect(() => {
    const fetchSentSMSData = async () => {
      try {
        const formattedData = await fetchSMSData()
        setSentSMS(formattedData)
      } catch (e) {
        showAxiosError(e)
      }
    }
    fetchSentSMSData()
  }, [calculateData, event, fetchSMSData])

  useEffect(() => {
    const updateSMSData = async () => {
      const formattedData = await fetchSMSData()
      setSentSMS(formattedData)
    }
    if (isSMSSent) {
      updateSMSData()
      resetSMSState()
    }
  }, [isSMSSent, fetchSMSData, resetSMSState])

  const showContentModal = mailout => {
    setContentModalOpen(true)
    setMailoutDisplayed(mailout)
  }

  const showRecipientsModal = mailout => {
    try {
      const mailOutData = JSON.parse(mailout)
      const recipients = _map(mailOutData, (recipient, key) => ({
        id: key,
        first_name: recipient.first_name,
        last_name: recipient.last_name,
        email: recipient.email,
        phone: recipient.phone
      }))
      setMailoutDisplayed(mailout)
      setRecipientsdata(recipients)
      setRecipientsModalOpen(true)
    } catch (e) {
      console.warn(e)
    }
  }

  const tableColumns = getTableColumns([
    {
      key: 'message',
      label: 'Message',
      isSortable: true
    },
    {
      key: 'sent_by',
      label: 'Sent By',
      isSortable: true
    },
    {
      key: 'recipients',
      label: 'No of Recipients',
      isSortable: true
    },
    {
      key: 'date',
      label: 'Date & Time',
      isSortable: true
    },
    {
      key: 'show_content',
      label: ' ',
      isSortable: false,
      className: 'detail-cell'
    },
    {
      key: 'show_recipients',
      label: ' ',
      isSortable: false,
      className: 'detail-cell'
    }
  ])

  const recipientsTableColumns = getTableColumns([
    {
      key: 'first_name',
      label: 'First Name',
      isSortable: true
    },
    {
      key: 'last_name',
      label: 'Last Name',
      isSortable: true
    },
    {
      key: 'email',
      label: 'Email',
      isSortable: true
    },
    {
      key: 'phone',
      label: 'Phone',
      isSortable: true
    }
  ])

  return (
    <div className="SentEmails">
      {sentSMS.length ? (
        <SortableTable
          data={sentSMS}
          tableColumns={tableColumns}
          enableSort={true}
          enableCopyTable={true}
          disableMobileView={false}
          className="child-table"
          sortBy={{ column: 'date', asc: true }}
        />
      ) : (
        <EmptyBar />
      )}
      <ConfirmModal
        isOpen={!!contentModalOpen}
        header="Content of Sent SMS"
        content={
          <div className="mailout-content-preview">
            <div dangerouslySetInnerHTML={{ __html: mailoutDisplayed && mailoutDisplayed.content }} />
          </div>
        }
        actions={[{ value: 'cancel', label: 'Close', className: 'btn btn-default' }]}
        onAction={() => setContentModalOpen(false)}
        className="modal-dialog modal-trans"
        style={modalStyle}
      />
      <ConfirmModal
        isOpen={!!recipientsModalOpen}
        header="Recipients of Sent SMS"
        content={
          <div className="mailout-recipients-preview">
            {mailoutDisplayed && (
              <SortableTable
                data={recipientsData}
                tableColumns={recipientsTableColumns}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                className="child-table"
                sortBy={{ column: 'first_name', asc: true }}
              />
            )}
          </div>
        }
        actions={[{ value: 'cancel', label: 'Close', className: 'btn btn-default' }]}
        onAction={() => setRecipientsModalOpen(false)}
        className="modal-dialog modal-trans"
        style={modalStyle}
      />
    </div>
  )
}
