import _get from 'lodash/get'
import _set from 'lodash/set'
import _forEach from 'lodash/forEach'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _includes from 'lodash/includes'
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import { withFormik } from 'formik'
import { Link } from 'react-router-dom'
import moment from 'moment'
import React from 'react'
import Scroll, { Element, scroller } from 'react-scroll'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'

import Button from '../_library/Button'
import Field from '../_library/Field'
import RichTextArea from '../_library/RichTextArea'
import AutoSlugField from '../_library/AutoSlugField'
import LazyLoad from 'react-lazy-load'
import Address from '../_library/Address'
import DateLabel from '../_library/DateLabel'
import Card from '../_library/Card'
import LoadingBar from '../_library/LoadingBar'
import { getDocumentHeight } from '../../_common/core/utils'
import { ShopifyCard } from '../shopify'
import { ENV } from '../constants/env'
import {
  UPDATE_TOUR_PROMOTER_GENERIC_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR,
  FETCH_TOUR_PROMOTER_GENERIC_INVITATION,
} from '../../_common/redux/tourteam/actions'
import { connect } from 'react-redux'
import Clipboard from '../_library/Clipboard'
import EventImagePlaceholder from '../events/_library/EventImagePlaceholder'

function validateTour(data, props) {
  const required = ['displayName', 'slug']

  const errors = {}
  const { tours, initialValues } = props

  _forEach(required, f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  const slug = _get(data, 'slug', '')
  const slugRE = new RegExp(/^[\w-]*$/)
  if (!slugRE.test(slug)) {
    _set(
      errors,
      'slug',
      'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)',
    )
  }

  const otherSlugs = _map(
    _filter(tours, t => t.id !== initialValues.attributes.id),
    t => t.slug,
  )

  if (_includes(otherSlugs, slug)) {
    _set(errors, 'slug', 'Other tour has already taken this slug')
  }

  return errors
}

const getFilteredEvents = (events, search, noEvents) => {
  const eventsFiltered = events || []
  return _filter(eventsFiltered, e => {
    const { id } = e
    if (_find(noEvents, ev => String(ev.id) === String(id))) {
      return false
    }
    if (search) {
      return e.displayName.toLowerCase().includes(search.toLowerCase())
    }
    return true
  })
}

const getEventsSections = (events, page) => events.slice(0, page * EVENTS_SECTION_COUNT)

const EVENTS_SECTION_COUNT = 50

@connect(
  state => {
    const { user } = state.auth
    return {
      promoterGenericInvitation: state.tourteam.promoterGenericInvitation,
      user,
    }
  },
  {
    UPDATE_TOUR_PROMOTER_GENERIC_INVITATION,
    FETCH_TOUR_PROMOTER_GENERIC_INVITATION,
    REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR,
  },
)
class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.totalEventsFiltered = getFilteredEvents(props.events, '', props.values.events)
    this.eventsPage = 1
    const eventsFiltered = getEventsSections(this.totalEventsFiltered, 1)
    this.state = {
      submitPressed: false,
      eventSearchKeyword: '',
      eventsFiltered,
      loadingGenericPromoterInvitation: false,
    }

    this.onSearch = _debounce(this.onSearch, 300)
    this.description = props.values.description
    this.regenerateGenericPromoterInvitation = this.regenerateGenericPromoterInvitation.bind(this)
    this.fetchGenericPromoterInvitation = this.fetchGenericPromoterInvitation.bind(this)
    this.disableOrEnableGenericPromoterInvitation = this.disableOrEnableGenericPromoterInvitation.bind(this)
    this.isUnmounted = false
  }

  componentWillReceiveProps(nextProps) {
    const {
      isSubmitting,
      events: pEvents,
      values: { events: pValuesEvents },
    } = this.props
    const {
      errors,
      events: nEvents,
      values: { events: nValuesEvents },
    } = nextProps
    const { submitPressed, eventSearchKeyword } = this.state

    if (!_isEmpty(nextProps.errors) && isSubmitting) {
      if (errors.displayName || errors.slug) {
        scroller.scrollTo('scroll-to-tour-details', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        })
      }
    }

    if (!_isEmpty(nextProps.errors) && submitPressed) {
      this.setState({ submitPressed: false }, () => {
        const scroll = Scroll.animateScroll
        scroll.scrollToTop()
      })
    }

    if (pEvents !== nEvents || pValuesEvents !== nValuesEvents) {
      this.totalEventsFiltered = getFilteredEvents(nEvents, eventSearchKeyword, nValuesEvents)
      const eventsFiltered = getEventsSections(this.totalEventsFiltered, this.eventsPage)
      this.setState(() => ({ eventsFiltered }))
    }
  }

  regenerateGenericPromoterInvitation = async () => {
    try {
      const {
        REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR,
        promoterGenericInvitation: { token },
      } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR(token)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  fetchGenericPromoterInvitation = async () => {
    try {
      const { tour, FETCH_TOUR_PROMOTER_GENERIC_INVITATION } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await FETCH_TOUR_PROMOTER_GENERIC_INVITATION(tour.id)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  disableOrEnableGenericPromoterInvitation = async () => {
    try {
      const { UPDATE_TOUR_PROMOTER_GENERIC_INVITATION, promoterGenericInvitation } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await UPDATE_TOUR_PROMOTER_GENERIC_INVITATION(promoterGenericInvitation.id, {
        id: promoterGenericInvitation.id,
        status: promoterGenericInvitation.status === 'disabled' ? 'pending' : 'disabled',
      })
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  componentDidMount() {
    this.fetchGenericPromoterInvitation()

    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    this.isUnmounted = true
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (this.totalEventsFiltered.length <= this.eventsPage * EVENTS_SECTION_COUNT) {
      if (this.eventsPage !== 1 && this.isTopNear()) {
        this.eventsPage = 1
        const eventsFiltered = getEventsSections(this.totalEventsFiltered, this.eventsPage)
        this.setState(() => ({ eventsFiltered }))
      }
      return
    }
    const isBottomNear = this.isBottomNear()
    if (isBottomNear) {
      this.eventsPage++
      const eventsFiltered = getEventsSections(this.totalEventsFiltered, this.eventsPage)
      this.setState(() => ({ eventsFiltered }))
    } else if (this.eventsPage !== 1 && this.isTopNear()) {
      this.eventsPage = 1
      const eventsFiltered = getEventsSections(this.totalEventsFiltered, this.eventsPage)
      this.setState(() => ({ eventsFiltered }))
    }
  }

  isBottomNear = () => {
    const scrollHeight = window.scrollY + window.innerHeight
    const documentHeight = getDocumentHeight()
    return documentHeight - scrollHeight <= 1000
  }
  isTopNear = () => {
    const { scrollY } = window
    return scrollY <= 2000
  }

  copyTourUrl = e => {
    const url = `${ENV.API_CONSUMER}/tour/${this.props.values.slug}`
    const temp = $('<input>')
    $('body').append(temp)
    temp.val(url).select()
    document.execCommand('copy')
    temp.remove()
    this.setState({
      tourUrlCopied: true,
    })
  }

  copyTourUrlOut = e => {
    if (this.state.tourUrlCopied) {
      setTimeout(() => {
        this.setState({
          tourUrlCopied: false,
        })
      }, 500)
    }
  }

  handleChangeDisplayName = e => {
    const str = e.target.value
    this.updateField('displayName', str)
  }

  handleChangeSlug = e => {
    const str = e.target ? e.target.value : e
    this.updateField('slug', str)
  }

  handleChangeDescription = val => {
    this.updateField('description', val)
  }

  addEvent = (e, event) => {
    e.stopPropagation()

    const { values } = this.props

    const valuesEvents = [
      ...values.events,
      {
        id: parseInt(event.id),
        sortOrder: 0,
      },
    ]

    this.updateField('events', valuesEvents)
  }

  removeEvent = (e, event) => {
    e.stopPropagation()
    const { values } = this.props

    const valuesEvents = values.events.filter(e => String(e.id) !== String(event.id))

    this.updateField('events', valuesEvents)
  }

  onSearch = eventSearchKeyword => {
    const { events, values } = this.props
    this.totalEventsFiltered = getFilteredEvents(events, eventSearchKeyword, values.events)
    const eventsFiltered = getEventsSections(this.totalEventsFiltered, this.eventsPage)

    this.setState(() => ({
      eventsFiltered,
    }))
  }

  handleEventSearchKeywordChange = e => {
    const eventSearchKeyword = e.target.value
    this.setState(() => ({
      eventSearchKeyword,
    }))
    this.onSearch(eventSearchKeyword)
  }

  updateField = (field, value) => {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fields = form_helper_get()
    const fieldName = `attributes.${field}`
    fields[fieldName] = fieldName
    if (field === 'description' && this.description.includes('/figure') && !value.includes('/figure')) {
      this.description = value
      return
    }
    form_helper_set(fields)
  }

  copyToClipboard = () => {
    const { promoterGenericInvitation } = this.props
    const promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`
    navigator.clipboard.writeText(promoterLink)
  }

  render() {
    const { eventSearchKeyword, eventsFiltered, loadingGenericPromoterInvitation } = this.state

    const {
      submitLabel,
      isNew,
      cardsStatus,
      eventsFullReady,
      events,
      tour,
      shopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      shopifyConnectSubmit,
      onDisconnectShopify,
      onSopifyUpdate,
      onSopifyRefresh,
      dynamicConfigs,
      promoterGenericInvitation,
      configs,
    } = this.props

    const { values, touched, errors, isSubmitting, handleSubmit, handleBlur, user } = this.props

    const slugSuggestion = !isNew ? values.slug : values.displayName

    const eventsAll = events || []
    const showGenericPromoterInvitationButton =
      promoterGenericInvitation?.status === 'pending' || promoterGenericInvitation?.status === 'disabled'
    const isActivatedGenericPromoterInvitation = promoterGenericInvitation?.status === 'pending'
    const promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`

    const hideShopify = _get(configs, 'appearance.hideShopify', false)

    return (
      <div>
        {!!isNew && (
          <div>
            <div className="body-panel-header">
              <div className="left">
                <div className="title">Add New Tour</div>
              </div>
              <div className="right" />
            </div>
            <div className="body-panel-spacing" />
          </div>
        )}
        <Element name="scroll-to-tour-details" />
        <div className={!!isNew ? 'body-panel-content tour-form' : 'tour-form'}>
          <form ref="form">
            <Card icon={'fa-info'} title={'Tour Details'} status={cardsStatus && cardsStatus.details}>
              <div className="row">
                <div className="col-sm-12">
                  <Field
                    id="displayName"
                    label="Tour Name"
                    size="large"
                    type="text"
                    value={values.displayName}
                    error={errors.displayName}
                    touched={touched.displayName}
                    onChange={this.handleChangeDisplayName}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <label className="autoslugfield-label" onMouseLeave={this.copyTourUrlOut}>
                    Tour URL
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        this.state.tourUrlCopied ? (
                          <Tooltip id="tourUrlCopied">Copied</Tooltip>
                        ) : (
                          <Tooltip id="tourUrlCopied">Copy Tour URL</Tooltip>
                        )
                      }
                      trigger={['hover']}
                    >
                      <i aria-hidden className="fa fa-copy event-url-copy" onClick={this.copyTourUrl} />
                    </OverlayTrigger>
                  </label>
                  <AutoSlugField
                    id="slug"
                    label="Link URL"
                    value={values.slug}
                    error={errors.slug}
                    touched={touched.slug}
                    onChange={this.handleChangeSlug}
                    onBlur={handleBlur}
                    separator="-"
                    hint="Letters, numbers and hyphens only"
                    suggestion={slugSuggestion}
                  >
                    <Field.PrefixAddon className="link-prefix hidden-xs">
                      <img
                        alt="no data"
                        className="link-prefix-img"
                        src={asset('/resources/images/event-url.png')}
                      />
                      <div className="link-prefix-url">{`${ENV.API_CONSUMER}/tour/`}</div>
                    </Field.PrefixAddon>
                  </AutoSlugField>
                </div>
              </div>
            </Card>
            <Card
              icon={'fa-pencil-square'}
              title={'Intro Text (Shown Above Tour Dates)'}
              status={cardsStatus && cardsStatus.description}
            >
              <div className="row">
                <div className="col-xs-12">
                  <RichTextArea
                    id="description"
                    label=""
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    onChange={this.handleChangeDescription}
                    onBlur={handleBlur}
                    baseurl={ENV.CDN_URL}
                  />
                </div>
              </div>
            </Card>
            <h3 className="heading_style">Invite Your Promoters</h3>
            {promoterGenericInvitation?.token ? (
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-xs-12">
                  <label className="autoslugfield-label" onMouseLeave={this.copyBlur}>
                    Copy Promoter Invitation Link
                    <Clipboard text={promoterLink} />
                  </label>
                  <div className="wrapper-promoter-link-box">
                    <div aria-hidden onClick={this.copyToClipboard} className="wrapper-promoter-link">
                      <img
                        alt="no data"
                        className="ico-show-small image-promoter-link"
                        src={asset('/resources/images/system_icons/links.svg')}
                      />
                      <div className="link-prefix-url" style={{ display: 'flex', flexDirection: 'row' }}>
                        {promoterLink}
                        <Clipboard text={promoterLink} containerStyle={{ marginLeft: 10 }} />
                      </div>
                    </div>
                    <div className="generic-buttons-box">
                      <Button
                        className="btn btn-default btn-shadow"
                        type="button"
                        disabled={!isActivatedGenericPromoterInvitation}
                        onClick={this.regenerateGenericPromoterInvitation}
                      >
                        <i className="fa fa-undo" style={{ paddingRight: 5 }} /> Regenerate
                      </Button>
                      {showGenericPromoterInvitationButton && (
                        <Button
                          className={`btn ${
                            isActivatedGenericPromoterInvitation ? 'btn-danger' : 'btn-success'
                          } btn-shadow`}
                          type="button"
                          style={{ marginLeft: 10 }}
                          onClick={this.disableOrEnableGenericPromoterInvitation}
                        >
                          <i className="fa fa-minus-circle" aria-hidden="true" />{' '}
                          {isActivatedGenericPromoterInvitation ? 'Deactivate' : 'Enable'}{' '}
                          {loadingGenericPromoterInvitation && (
                            <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <i className="fa fa-circle-o-notch fa-fw fa-spin" />
            )}
            {user.tfStaff
              ? !isNew &&
                _get(dynamicConfigs, 'show_connect_shopify', false) &&
                !hideShopify && (
                <ShopifyCard
                  id={tour.id}
                  onSubmit={shopifyConnectSubmit}
                  onDisconnectShopify={onDisconnectShopify}
                  shopifyInfo={shopifyInfo}
                  shopifyConnectLoading={shopifyConnectLoading}
                  shopifyDisconnectLoading={shopifyDisconnectLoading}
                  shopifyRefreshLoading={shopifyRefreshLoading}
                  fetchProductsLoading={fetchProductsLoading}
                  onUpdate={onSopifyUpdate}
                  onRefresh={onSopifyRefresh}
                  title="Shopify"
                />
              )
              : null}
            <Card icon={'fa-calendar-o'} title={'Added Events'} status={cardsStatus && cardsStatus.events}>
              <div className="tour-events-table">
                {!eventsFullReady && <LoadingBar title={"Hold tight! We're getting your event list..."} />}
                {eventsFullReady &&
                  values.events &&
                  eventsAll &&
                  eventsAll.length > 0 &&
                  [...values.events]
                    .sort((e1, e2) => {
                      const e1v = eventsAll.find(e => e.id === e1.id.toString())
                      const e2v = eventsAll.find(e => e.id === e2.id.toString())
                      return e1v.startDate < e2v.startDate
                    })
                    .map((eve, index) => {
                      const ev = eventsAll.find(e => e.id === eve.id.toString())
                      if (!ev) {
                        return null
                      }
                      return (
                        <div className="tour-events-table-event" key={index}>
                          <div className="tour-events-table-event-image">
                            <LazyLoad>
                              {!!ev.imageURL ? (
                                <img alt="no data" className="LazyLoadImg thumb" src={ev.imageURL} />
                              ) : (
                                <EventImagePlaceholder />
                              )}
                            </LazyLoad>
                          </div>
                          <div className="tour-events-table-event-details">
                            <div className="tour-event-brand">{ev.owner.displayName}</div>
                            <div className="tour-event-name">
                              <Link to={`/event/${ev.id}/details`}>{ev.displayName}</Link>
                            </div>
                            <div className="tour-event-time">
                              <i className="fa fa-clock-o" aria-hidden="true" />
                              <DateLabel
                                className="starttime"
                                value={moment.utc(new Date(ev.startDate))}
                                format="LLL"
                              />
                            </div>
                            <div className="tour-event-address">
                              <i className="fa fa-map-marker" aria-hidden="true" />
                              {!!ev.venue ? (
                                <Address className="address-form" type="simple" {...ev.venue} />
                              ) : (
                                <div className="address-notdefined">Not Defined</div>
                              )}
                            </div>
                            <div className="tour-event-actions">
                              <button
                                type="button"
                                className="btn btn-danger btn-shadow btn-remove"
                                onClick={e => this.removeEvent(e, ev)}
                              >
                                Remove from tour
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </div>
            </Card>
            <div className="row mb-20">
              <div className="col-xs-12 text-center">
                <Button
                  className="btn btn-success btn-lg btn-shadow"
                  type="button"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  {submitLabel || 'Create Tour'}
                </Button>
              </div>
            </div>
            <Card icon={'fa-calendar-o'} title={'Available Events'}>
              {eventsFullReady && (
                <div className="row">
                  <div className="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-0">
                    <Field
                      id="eventSearchKeyword"
                      label=""
                      placeholder="Filter Events"
                      value={eventSearchKeyword}
                      onChange={this.handleEventSearchKeywordChange}
                    />
                  </div>
                </div>
              )}
              <div className="tour-events-table">
                {!eventsFullReady && <LoadingBar title={"Hold tight! We're getting your event list..."} />}
                {eventsFullReady &&
                  eventsFiltered &&
                  eventsFiltered.map((event, index) => (
                    <div className="tour-events-table-event" key={index}>
                      <div className="tour-events-table-event-image">
                        <LazyLoad>
                          {!!event.imageURL ? (
                            <img alt="no data" className="LazyLoadImg thumb" src={event.imageURL} />
                          ) : (
                            <EventImagePlaceholder />
                          )}
                        </LazyLoad>
                      </div>
                      <div className="tour-events-table-event-details">
                        <div className="tour-event-brand">{event.owner.displayName}</div>
                        <div className="tour-event-name">
                          <Link to={`/event/${event.id}/details`}>{event.displayName}</Link>
                        </div>
                        <div className="tour-event-time">
                          <i className="fa fa-clock-o" aria-hidden="true" />
                          <DateLabel
                            className="starttime"
                            value={moment.utc(new Date(event.startDate))}
                            format="LLL"
                          />
                        </div>
                        <div className="tour-event-address">
                          <i className="fa fa-map-marker" aria-hidden="true" />
                          {!!event.venue ? (
                            <Address className="address-form" type="simple" {...event.venue} />
                          ) : (
                            <div className="address-notdefined">Not Defined</div>
                          )}
                        </div>
                        <div className="tour-event-actions">
                          <button
                            type="button"
                            className="btn btn-primary btn-shadow btn-add"
                            onClick={e => this.addEvent(e, event)}
                          >
                            Add to tour
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Card>
          </form>
        </div>
      </div>
    )
  }
}

const TourForm = withFormik({
  mapPropsToValues: props => ({
    id: _get(props, 'initialValues.attributes.id') || null,
    displayName: _get(props, 'initialValues.attributes.displayName') || '',
    slug: _get(props, 'initialValues.attributes.slug') || '',
    description: _get(props, 'initialValues.attributes.description') || '',
    bannerURL: _get(props, 'initialValues.attributes.bannerURL') || '',
    backgroundURL: _get(props, 'initialValues.attributes.backgroundURL') || '',
    events: _get(props, 'initialValues.attributes.events') || [],
  }),

  // Custom sync validation
  validate: (values, props) => {
    const errors = validateTour(values, props)
    return errors
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({
        attributes: { ...values },
      })
    } catch (e) {
      // todo : Error handling
    } finally {
      setSubmitting(false)
    }
  },
  displayName: 'TourForm', // helps with React DevTools
})(MyForm)

export default TourForm
