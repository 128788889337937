import React from 'react'
import { Field } from 'formik'
import _get from 'lodash/get'
import CustomToggle from '../../../_library/CustomToggle'
import CurrencyField from '../../../_library/CurrencyField'
import { toInt, toNumber, maxNormalizerCreator } from '../../../../_common/core/validation'
import { combineNormalizers, } from '../../../../_common/core/validation/utils'
import { defaultPriceRange } from '../TicketForm'
import HoldRow from './HoldRow'
import RelativeError from '../../../_library/RelativeError'
import { DateTimePickerFieldReact } from '../../../formik/Fields'
import HiddenRow from './HiddenRow'
import ExternalRedirectComponent from "./ExternalRedirectRow"
import DoorsTimeRow from './DoorTimesRow'
import NFTForm from '../../form/NFTForm'
import TimeSlotsRow from './TimeSlotsRow'
import NFTLists from '../../form/NFTLists'

const MAX_ACCOUNT_LIMIT = 20

export default class CheckBoxesRow extends React.Component {
  state = {
    isMobile: window.matchMedia("(max-width: 600px)").matches
  }
  updateField = (fieldKey, value) => {
    this.props.updateField(fieldKey, value)
  }

  handleChange = (e, val) => {
    const id = e.target ? e.target.id : e
    const value = e.target ? e.target.checked : val

    switch (id) {
      case 'finalPrice':
        this.updateField(id, parseFloat(value))
        break
      case 'flagSlidingScale':
        this.updateField(id, value)
        !value && this.updateField('priceRanges', defaultPriceRange)
        break
      default:
        this.updateField(id, value)
    }
  }

  handleChangeTimeSlots = (fieldKey, value, e) => {
    const { values } = this.props
    this.updateField(fieldKey, value)
  }

  handleMaxAccountChange = value => {
	  this.updateField('maxQuantityPerAccount', combineNormalizers(toInt, maxNormalizerCreator(MAX_ACCOUNT_LIMIT))(value) )
  };

  increaseMaxAccount = ()=> {
    const { values } = this.props
    if(parseInt(values.maxQuantityPerAccount)<20) {
      this.updateField('maxQuantityPerAccount', parseInt(values.maxQuantityPerAccount)+1)
    }
  }

  decreaseMaxAccount = () => {
    const { values } = this.props
    if(parseInt(values.maxQuantityPerAccount)>1) {
      this.updateField('maxQuantityPerAccount', parseInt(values.maxQuantityPerAccount)-1)
    }
  }

  handleAccountToggle = (_fieldKey, value) => value ? this.updateField('maxQuantityPerAccount', 1) : 
  this.updateField('maxQuantityPerAccount', '')
    

  componentDidMount() {
    const handler = e => this.setState({ isMobile: e.matches })
    window.matchMedia("(max-width: 600px)").addEventListener('change', handler)
  }

  render() {
    const {
      values,
      errors,
      touched,
      // flagDifferentTime,
      isSlotTicket,
      flagTimeSlotsEnabled,
      flagSlidingScale,
      flagHoldTickets,
      submitCount,
      isEditing,
      launchDarklyFlags,
      handleHoldRowDelete,
      inModal,
      tableType,
      hidingOptions,
      setFieldValue,
      onTagsChange,
      isInventoryMode,
      currency,
      updateField,
      updateTimeSlotsSettings,
      selectedDates,
      selectedChip,
      timeSlotsValues,
      selectedWeekdays,
      excludedDates,
      configs,
      typeName,
    } = this.props
    const {
      isMobile,
    } = this.state

    const hideCheckBox = values && !values.flagTicketHasTimeSlots
    const flagAvailableAfterRegistration = values && values.flagAvailableAfterRegistration
    const showEnableScale = !(!isEditing || (isEditing && inModal))
    const mainnet = _get(values?.nftLists, 'solana-mainnet')
    const ethereum = _get(values?.nftContracts, 'ethereum')
    const polygon = _get(values?.nftContracts, 'polygon')
    const hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle = _get(
      configs,
      'appearance.hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle',
    )
    const isTable = typeName === 'table'

    return (
      <>
        <div className='row'>
          <div className={values.flagHidden || isMobile ? 'col-xs-12' : 'col-xs-6'} style={{ marginTop: 20 }}>
            <span>&nbsp;</span>
            <CustomToggle
              id='flagHidden'
              onText='Hidden'
              value={values.flagHidden}
              checked={values.flagHidden}
              onChange={this.handleChange}
            />
            {values.flagHidden && !tableType && (
              <>
                <div className='div-spacing-20' />
                <div className='form-group'>
                  <HiddenRow
                    hidingOptions={hidingOptions}
                    updateField={this.updateField}
                  />
                </div>
              </>
            )}
          </div>
          {!values.flagHidden && (
            <div 
              className={`${(isMobile) ? 'col-xs-12' : 'col-xs-6'} date-field-ticket-form`} 
              id={'ticket-form'}
            >
              <Field
                name='hideUnhideDate'
                label={values.flagHidden
                  ? `Unhide ${isTable ? 'table' : 'ticket'} on (optional)`
                  : `Hide ${isTable ? 'table' : 'ticket'} on (optional)`
                }
                placeholder='D MMM YYYY H:M AM'
                component={DateTimePickerFieldReact}
              />
            </div>
          )}
          <div className='col-xs-12'>
            <div className='line-toggle' />
          </div>
        </div>
        <div className='row'>
          <div className={`col-xs-12 col-sm-12`}>
            {!isSlotTicket && flagTimeSlotsEnabled && !inModal && (
              <div
                style={{ marginBottom: !hideCheckBox && '10px' }}
              >
                <CustomToggle
                  id='flagTicketHasTimeSlots'
                  onText={`${isTable ? 'Tables' : 'Tickets'} has time slots`}
                  value={values.flagTicketHasTimeSlots}
                  checked={values.flagTicketHasTimeSlots}
                  onChange={this.handleChangeTimeSlots}
                />
              </div>
            )}
          </div>
          {values && values.flagTicketHasTimeSlots && (
            <TimeSlotsRow
              values={values}
              isInventoryMode={isInventoryMode}
              currency={currency}
              updateField={updateField}
              updateTimeSlotsSettings={updateTimeSlotsSettings}
              selectedDates={selectedDates}
              selectedChip={selectedChip}
              timeSlotsValues={timeSlotsValues}
              selectedWeekdays={selectedWeekdays}
              excludedDates={excludedDates}
            />
          )}
          {!isSlotTicket && flagTimeSlotsEnabled && !inModal ? (
            <div className='col-xs-12'>
              <div className='line-toggle' />
            </div>
          ) : null}
          {hideCheckBox && (
            <div className='col-xs-12 col-sm-12'>
              <CustomToggle
                id='active'
                onText='Enabled'
                value={values.active}
                checked={values.active}
                onChange={this.handleChange}
              />
            </div>
          )}
          {hideCheckBox ? (
            <div className='col-xs-12'>
              <div className='line-toggle' />
            </div>
          ) : null}

          {showEnableScale && (
            <div className='col-xs-12 col-sm-12'>
              <CustomToggle
                id='flagSlidingScale'
                onText='Enable Sliding Scale'
                value={values.flagSlidingScale}
                checked={values.flagSlidingScale}
                onChange={this.handleChange}
                // eslint-disable-next-line max-len
                subText={`By using this model, you'll enable customers to make the ${isTable ? 'tables' : 'tickets'} cheaper by spreading the word, while your fundraising goal will remain the same.`}
              />
            </div>
          )}
          {showEnableScale ? (
            <div className='col-xs-12'>
              <div className='line-toggle' />
            </div>
          ) : null}
          {hideCheckBox && !flagTimeSlotsEnabled && flagSlidingScale && (
            <>
              <div className='col-xs-12 col-sm-12'>
                <CustomToggle
                  id='flagAvailableAfterRegistration'
                  onText='Available after registration period'
                  value={values.flagAvailableAfterRegistration}
                  checked={values.flagAvailableAfterRegistration}
                  onChange={this.handleChange}
                />
                {flagAvailableAfterRegistration && (
                  <div style={{ marginTop: 25 }}>
                    <CurrencyField
                      id='finalPrice'
                      label='Price after registration period'
                      value={values.finalPrice || ''}
                      initialValue={values.finalPrice || ''}
                      error={errors.finalPrice || ''}
                      touched={touched.finalPrice || ''}
                      onChange={value => this.handleChange('finalPrice', value)}
                      onBlurNormalizer={toNumber}
                    />
                  </div>
                )}
              </div>
              <div className='col-xs-12'>
                <div className='line-toggle' />
              </div>
            </>
          )}          

          {launchDarklyFlags.flagHoldTicketsEnabled && flagHoldTickets && (
            <>
              <div className='col-xs-12 col-sm-12'>
                <CustomToggle
                  id='holdTicketsEnabled'
                  onText={`Hold ${isTable ? 'tables' : 'tickets'}`}
                  value={values.holdTicketsEnabled}
                  checked={values.holdTicketsEnabled}
                  onChange={this.handleChange}
                />
              </div>
              {values.holdTicketsEnabled && (
                <>
                  {!!errors.holdsError && !!submitCount && (
                    <div className='col-xs-12'>
                      <div className='div-spacing-10' />
                      <RelativeError>{errors.holdsError}</RelativeError>
                      <div className='div-spacing-10' />
                    </div>
                  )}
                  <HoldRow values={values} isEditing={isEditing} handleRowDelete={handleHoldRowDelete} />
                </>
              )}
              <div className='col-xs-12'>
                <div className='line-toggle' />
              </div>
            </>
          )}
          {hideCheckBox && !isSlotTicket && (
            <div className='col-xs-12'>
              <CustomToggle
                id='flagDifferentTime'
                onText={`This ${isTable ? 'table' : 'ticket'} has different door times`}
                value={values.flagDifferentTime}
                checked={values.flagDifferentTime}
                onChange={this.handleChange}
              />
            </div>
          )}
          {values.flagDifferentTime && (
            <>
              <DoorsTimeRow inModal={inModal} />
            </>
          )}
          {hideCheckBox && !isSlotTicket && (
            <div className='col-xs-12'>
              <div className='line-toggle' />
            </div>
          )}
          {!tableType && !hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle && (
            <>
              <div className='col-xs-12'>
                <CustomToggle
                  id='flagNftOwnershipRequired'
                  onText={`Require ownership of NFTs to register for this ${isTable ? 'table' : 'ticket'} type`}
                  value={values.flagNftOwnershipRequired}
                  checked={values.flagNftOwnershipRequired}
                  onChange={this.handleChange}
                />
              </div>
              {values.flagNftOwnershipRequired && (
                <>
                  {errors?.nfts && touched &&
                  <div className='col-md-12 tags-field'>
                    <div className="help-block nft-help-block">{errors.nfts}</div>
                  </div>}
                  <NFTForm
                    inputDisabled={mainnet.length}
                    nftContracts={values.nftContracts}
                    nftInput={values.nftInput}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onTagsChange={onTagsChange}
                    size={'md'}
                  />
                  <div className='div-spacing-15' />
                  <div className="nft-tags-field">
                    <NFTLists
                      inputDisabled={(polygon.length || ethereum.length)}
                      errors={errors}
                      touched={touched}
                      nftInput={values.nftInput}
                      setFieldValue={setFieldValue}
                      nftLists={mainnet}
                      onTagsChange={onTagsChange}
                      size={'md'}
                    />
                  </div>
                </>
              )}
              <div className='col-xs-12'>
                <div className='line-toggle' />
              </div>
            </>
          )}
          <div className='col-xs-12'>
            <CustomToggle
              id='flagDifferentTicketingPlatform'
              onText={`This ${isTable ? 'table' : 'ticket'} is being sold on a different platform`}
              value={values.flagDifferentTicketingPlatform}
              checked={values.flagDifferentTicketingPlatform}
              onChange={this.handleChange}
            />
          </div>
          {values.flagDifferentTicketingPlatform && (
            <>
              <ExternalRedirectComponent values={values} isEditing={isEditing} />
            </>
          )}
          <div className='col-xs-12'>
            <div className='line-toggle' />
          </div>
          <div className="col-xs-12">
            <CustomToggle
              id="maxQuantityPerAccount"
              onText="Set custom maximum quantity per Account"
              value={values.maxQuantityPerAccount}
              checked={values.maxQuantityPerAccount}
              onChange={this.handleAccountToggle}
            /> 
          </div>
          {values.maxQuantityPerAccount && (
            <div className="col-xs-6 col-6 bottom-field-ticket-form">
              <div className="max-order">
                <img
                  alt="max per Account"
                  src={asset('/resources/images/system_icons/tickets.svg')} 
                  width='24' 
                  height='24' 
                  className="max-order-icon" />
                <div className="max-order-label">{`Maximum ${isTable ? 'table' : 'ticket'} quantity per account`}</div>
                <div className='max-account-field'>
                  <Field
                    size="large"
                    value={values.maxQuantityPerAccount}
                    className="max-order-field"
                    onChange={event => this.handleMaxAccountChange(event.target.value)}
                  />
                  <i className="max-order-up fa fa-angle-up" onClick={this.increaseMaxAccount} />
                  <i className="max-order-down fa fa-angle-down" onClick={this.decreaseMaxAccount} />
                </div>
              </div>
            </div>
          )}
          <div className='col-xs-12'>
            <div className='line-toggle' />
          </div>
        </div>
      </>
    )
  }
}
