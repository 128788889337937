import React from 'react'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import _get from 'lodash/get'
import { shortid } from '../../../_common/core/rand'

const activeTooltip = label => (
  <Tooltip id={`tt-${shortid()}`}>{label || `This ticket is available for sale`}</Tooltip>
)
const inActiveTooltip = label => (
  <Tooltip id={`tt-${shortid()}`}>
    {label || `This ticket is ${(<strong>not</strong>)} available for sale`}
  </Tooltip>
)
const alwaysAvailableTooltip = (
  <Tooltip id={`tt-${shortid()}`}>This ticket is always visible on the event page</Tooltip>
)
const hiddenTooltip = (
  <Tooltip id={`tt-${shortid()}`}>This ticket is hidden unless revealed with a special link</Tooltip>
)

export const TableIconLabels = props => {
  const {
    flagAlwaysAvailable,
    flagHidden,
    active,
    isDesktop,
    hideDragIcon,
    hideToolTip,
    enabled,
    statusLabels,
  } = props
  const moveLabel = hideDragIcon ? null : <i className="fa fa-arrows" />
  const flagActive = active || enabled

  const activeLabel = (
    <OverlayTrigger
      placement="top"
      overlay={
        hideToolTip ? (
          <span />
        ) : flagActive ? (
          activeTooltip(_get(statusLabels, 'active.activeLabel') || _get(statusLabels, 'enabled.activeLabel'))
        ) : (
          inActiveTooltip(
            _get(statusLabels, 'active.inactiveLabel') || _get(statusLabels, 'enabled.inactiveLabel'),
          )
        )
      }
      trigger={['hover', 'click']}
    >
      <i className={`fa fa-circle ${flagActive ? 'text-success' : 'text-danger'} fa-fw`} />
    </OverlayTrigger>
  )

  const visibleLabel = flagAlwaysAvailable ? (
    <OverlayTrigger placement="top" overlay={alwaysAvailableTooltip} trigger={['hover', 'click']}>
      <i className="fa fa-eye fa-fw brand-info" />
    </OverlayTrigger>
  ) : null

  const hiddenLabel = flagHidden ? (
    <OverlayTrigger placement="top" overlay={hiddenTooltip} trigger={['hover', 'click']}>
      <i className="fa fa-eye-slash fa-fw brand-info" />
    </OverlayTrigger>
  ) : null

  return isDesktop ? (
    <td className="tickets-table-td-desktop">
      {moveLabel}
      {visibleLabel}
      {hiddenLabel}
      {activeLabel}
    </td>
  ) : (
    <div className="ticket-table-td-mobile-labels">
      {moveLabel}
      {visibleLabel}
      {hiddenLabel}
      {activeLabel}
    </div>
  )
}
