import React from 'react'
import { useWindowSize } from '../hooks'

export const Background = () => {
  const size = useWindowSize()
  const { width, height } = size
  const isLandscape = width >= height
  let video = 'landscape/1280x720.mp4'
  let image = 'landscape/landscape-desktop.jpg'
  if (isLandscape) {
    if (width <= 668) {
      video = 'landscape/668x376.mp4'
      image = 'landscape/landscape-iphone.jpg'
    } else if (width <= 1024) {
      video = 'landscape/1024x768.mp4'
      image = 'landscape/landscape-ipad.jpg'
    } else if (width <= 1280) {
      video = 'landscape/1280x720.mp4'
      image = 'landscape/landscape-desktop.jpg'
    } else {
      video = 'landscape/1920x1080.mp4'
      image = 'landscape/landscape-desktop.jpg'
    }
  } else {
    if (width < 768) {
      video = 'portrait/376x668.mp4'
      image = 'portrait/portrait-iphone.jpg'
    } else {
      video = 'portrait/768x1024.mp4'
      image = 'portrait/portrait-ipad.jpg'
    }
  }

  return (
    <div className="bg_video">
      <video poster={asset('/resources/videos/login/' + image)} id="bgvid" playsInline autoPlay muted loop>
        <source src={asset('/resources/videos/login/' + video)} type="video/mp4" />
      </video>
    </div>
  )
}
