import { baseRequest } from '.'

export const sendReminder = async (eid, form) =>
  await baseRequest.post('reminders/', {
    data: {
      ...form,
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  })
