import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index
    }
  }
}

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex, props.isTimeSlotTicket)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

@DropTarget('AdditionalItemsTableCard', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource('AdditionalItemsTableCard', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  connectDragPreview: connect.dragPreview()
}))
class AdditionalItemsTableCard extends Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isDragging !== nextProps.isDragging) {
      this.props.onDropped(nextProps.isDragging)
    }
  }

  render() {
    const { row, isDragging, connectDragSource, connectDropTarget, connectDragPreview } = this.props
    const opacity = isDragging ? 0 : 1

    return connectDragPreview(
      connectDropTarget(
        <tr style={{ opacity }}>
          {row.values.map((item, index) =>
            index === 0 ? (
              connectDragSource(
                <td style={{ cursor: 'move' }} key={index}>
                  {item}
                </td>
              )
            ) : index === row.values.length - 1 ? (
              <td className="actions" key={index}>
                {item}
              </td>
            ) : (
              <td key={index}>{item}</td>
            )
          )}
        </tr>
      )
    )
  }
}
export default AdditionalItemsTableCard
