// Re-render Optimization 20.05.2020
import _result from 'lodash/result'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import queryString from 'query-string'
import React from 'react'
import { withRouter } from 'react-router-dom'

import Notifications from '../_library/notifications/Notifications'
import ResetPasswordForm from './ResetPasswordForm'
import RnResetPasswordForm from './RnResetPasswordForm'

import { RESET_PASSWORD, CHECK_RESET_PASSWORD_HASH } from '../../_common/redux/auth/actions'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => ({
    isRnDomain: state.shared.isRnDomain,
    isMcDomain: state.shared.isMcDomain
  }),
  {
    RESET_PASSWORD,
    CHECK_RESET_PASSWORD_HASH
  }
)
export default class ResetPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      width: $(window).width(),
      tokenValid: false,
      tokenValidating: false,
      resetPasswordResponse: '',
    }
  }

  componentDidMount() {
    this.checkResetPasswordHash()

    $('html').addClass('authentication')
    window.addEventListener('resize', this.updateDimensions)

    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  componentWillUnmount() {
    $('html').removeClass('authentication')
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState(() => ({ width: $(window).width() }))
  }

  checkResetPasswordHash = () => {
    const { CHECK_RESET_PASSWORD_HASH, location } = this.props
    const token = queryString.parse(location.search)
    this.setState(() => ({ tokenValidating: true }))
    return Promise.resolve(CHECK_RESET_PASSWORD_HASH(token))
      .catch(err => {
        this.setState(() => ({
          tokenValidating: false,
          tokenValid: false
        }))
        return Promise.reject(_result(err, 'toFieldErrors'))
      })
      .then(v => {
        this.setState(() => ({
          tokenValidating: false,
          tokenValid: true
        }))
        return v
      })
  }

  handleSubmit = form => {
    const {
      RESET_PASSWORD,
      history: { push },
      location
    } = this.props
    form.attributes.token = queryString.parse(location.search).token
    this.setState(() => ({ resetPasswordResponse: '' }))
    return Promise.resolve(RESET_PASSWORD(form))
      .catch(err => {
        this.setState(() => ({ resetPasswordResponse: '' }))
        return Promise.reject(_result(err, 'toFieldErrors'))
      })
      .then(v => {
        this.setState(() => ({ resetPasswordResponse: v.message }))
        push('signin')
        return v
      })
  }

  render() {
    const { tokenValid, tokenValidating, resetPasswordResponse } = this.state
    const { isRnDomain, history, configs } = this.props

    const width = $(window).width()
    const height = $(window).height()
    const isLandscape = width >= height
    let video = 'landscape/1280x720.mp4'
    let image = 'landscape/landscape-desktop.jpg'
    if (isLandscape) {
      if (width <= 668) {
        video = 'landscape/668x376.mp4'
        image = 'landscape/landscape-iphone.jpg'
      } else if (width <= 1024) {
        video = 'landscape/1024x768.mp4'
        image = 'landscape/landscape-ipad.jpg'
      } else if (width <= 1280) {
        video = 'landscape/1280x720.mp4'
        image = 'landscape/landscape-desktop.jpg'
      } else {
        video = 'landscape/1920x1080.mp4'
        image = 'landscape/landscape-desktop.jpg'
      }
    } else {
      if (width < 768) {
        video = 'portrait/376x668.mp4'
        image = 'portrait/portrait-iphone.jpg'
      } else {
        video = 'portrait/768x1024.mp4'
        image = 'portrait/portrait-ipad.jpg'
      }
    }

    return (
      <div className="loginpage">
        <div className="bg_video">
          <video
            poster={asset('/resources/videos/login/' + image)}
            id="bgvid"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={asset('/resources/videos/login/' + video)} type="video/mp4" />
          </video>
        </div>
        {tokenValid && <Notifications />}
        {isRnDomain ? (
          <RnResetPasswordForm 
            onSubmit={this.handleSubmit}
            history={history}
            tokenValidating={tokenValidating}
            tokenValid={tokenValid}
          />
        ) :
        (
          <ResetPasswordForm
            configs={configs}
            onSubmit={this.handleSubmit}
            tokenValidating={tokenValidating}
            tokenValid={tokenValid}
            resetPasswordResponse={resetPasswordResponse}
          />
        )}
      </div>
    )
  }
}
