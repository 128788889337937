import React from 'react'
import _ from 'lodash'

import { Field } from 'formik'
import Card from '../../../_library/Card'
import Button from '../../../_library/Button'
import Select from '../../../_library/Select'
import RichTextArea from '../../../_library/RichTextArea'

import { AltViewCheckboxField } from './AltViewCheckboxField'
import { ENV } from '../../../constants/env'

import { defaultLanguage } from './index'

export default class AlternativeViewEventDescription extends React.Component {
  handleChangeDescription = (val, _resetedDescriptions) => {
    const {
      values,
      setFieldValue,
      updateResetedDescriptions,
      resetedDescriptions
    } = this.props

    const updatedDescriptions = {
      ...values.descriptions,
      [values.descriptionLanguage]: val
    }
    const descriptions = _resetedDescriptions || resetedDescriptions
    const updatedResetedDescriptions = !!val
      ? _.filter(descriptions, r => r !== values.descriptionLanguage)
      : descriptions

    setFieldValue('descriptions', updatedDescriptions)
    if (updateResetedDescriptions) {
      updateResetedDescriptions(updatedResetedDescriptions)
    }
  }

  resetDescription = () => {
    const { values, resetedDescriptions } = this.props
    const isReseted = !!_.find(resetedDescriptions, d => d === values.descriptionLanguage)
    if (!isReseted) {
      const updatedResetedDescriptions = resetedDescriptions
      updatedResetedDescriptions.push(values.descriptionLanguage)
      this.handleChangeDescription('', updatedResetedDescriptions, true)
    }
  }

  handleChangeShortDescription = e => {
    const { setFieldValue } = this.props
    let { value } = e.target
    if (value && value.length > 100) {
      value = value.slice(0, 100)
    }
    setFieldValue('brief', value)
  }

  render() {
    const {
      event,
      values,
      errors,
      touched,
      cardsStatus,
      closed,

      setFieldValue
    } = this.props
    const showClearButton = values.descriptionLanguage !== defaultLanguage
    const fieldId = `description-${values.descriptionLanguage}`
    const value = values.descriptions ? values.descriptions[values.descriptionLanguage] : ''
    const style = {
      width: 'auto',
      margin: '0px 15px 0px 0px',
      height: 30,
      paddingRight: 30,
      marginTop: 0
    }

    return (
      <div className="event-details-description alternative-view-description">
        <Card
          closed={closed}
          icon={'fa-pencil-square'}
          title={'Event Description'}
          rightAction={(
            <Select
              fieldKey="descriptionLanguage"
              style={style}
              fieldGroupStyle={{ marginTop: 5 }}
              value={values.descriptionLanguage}
              onChange={(e) => {
                setFieldValue('descriptionLanguage', e.target.value)
                setFieldValue('forceUpdateTextEditor', true)
              }}
              onClick={e => e.stopPropagation()}
              options={values.languageOptions}
            />
          )
          }
        >
          <div className="row checkbox-row-with-detail">
            <div className="col-xs-1 col-1 left-column">
              <Field locale description name={values.descriptionLanguage + 'DescriptionCheck'} event={event} values={values} component={AltViewCheckboxField}/>
            </div>
            <div className="col-xs-11 col-11 right-column">
              <span>Override event's description</span>
            </div>
          </div>
          <div className={!(values[values.descriptionLanguage + 'DescriptionCheck']) ? 'disable-element' : ''}>
            <div className="row">
              <div className="col-xs-12">
                <RichTextArea
                  id={fieldId}
                  label=""
                  baseurl={ENV.CDN_URL}
                  value={value}
                  onChange={this.handleChangeDescription}
                  editorStyle={
                    errors.descriptions && touched.descriptions ? { border: '2px solid #d9534f' } : null
                  }
                />
              </div>
            </div>
            {showClearButton && (
              <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    onClick={this.resetDescription}
                  >
                    Clear and use default description
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    )
  }
}
