import React from 'react'
import _map from 'lodash/map'
import _identity from 'lodash/identity'
import { shortid } from '../../../_common/core/rand'
import CustomClipboardButton from '../CustomClipboardButton'
import { getRowValue } from './utils'

const TableRow = props => {
  const { data, columns, isDesktop, handleRowExpand, disableMobileView, rowIndex, rowProps = {} } = props
  const isDetailRow = data.type === 'detailRow'

  const dataComponent = isDetailRow
    ? typeof data.component === 'function'
      ? data.component({ detailRowIndex: rowIndex - 1 })
      : data.component
    : null

  const getTestId = key => {
    const { e2e_test_id, rowIndex } = props
    return `${e2e_test_id}-${rowIndex}-${key}`
  }

  return isDesktop || disableMobileView ? (
    <tr key={shortid()} className={`${rowProps.className} ${isDetailRow ? 'tr-detail-row' : 'row-stale'}`}>
      {isDetailRow ? (
        <td className="td-detail" colSpan={columns.length} style={{ padding: 10 }}>
          <div className="td-div-container">{dataComponent}</div>
        </td>
      ) : (
        _map(columns, (column, index) => (
          <td
            data-e2e-test-id={getTestId(column.key)}
            key={shortid()}
            aria-hidden="true"
            onClick={column.key === 'expand' ? () => handleRowExpand(data) : _identity}
            className={`td-desktop ${column.className || ''} ${
              column.key === 'message' ? 'custom-width-row' : ''
            }`}
          >
            {column.enableItemCopy ? (
              <CustomClipboardButton
                id={shortid()}
                clipboardText={getRowValue(column, props)}
                tooltipBeforeCopyText="Copy"
                label={getRowValue(column, props)}
              />
            ) : (
              getRowValue(column, props)
            )}
          </td>
        ))
      )}
    </tr>
  ) : (
    <div>
      {_map(
        columns,
        (column, index) =>
          column.key !== 'clipboard' && (
            <div
              key={shortid()}
              className={`td-mobile-name ${column.className || ''}`}
              data-e2e-test-id={getTestId(column.key)}
              onClick={column.key === 'expand' ? () => handleRowExpand(data) : _identity}
              aria-hidden="true"
            >
              <div className="td-mobile-label">{column.label}</div>
              <div className="td-mobile-value">
                {column.enableItemCopy ? (
                  <CustomClipboardButton
                    id={shortid()}
                    clipboardText={getRowValue(column, props)}
                    tooltipBeforeCopyText="Copy"
                    label={getRowValue(column, props)}
                  />
                ) : (
                  getRowValue(column, props)
                )}
              </div>
            </div>
          ),
      )}
    </div>
  )
}

export default TableRow
