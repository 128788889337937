import React from 'react'
import cx from 'classnames'

const Disabled = props => {
  const { children, disabled = false, opacity = 0.3, className = '', style = {} } = props

  const updatedClassName = cx('disabled_wrapper', className, {
    disabled,
  })

  const updatedStyle = { ...style }
  if (disabled) {
    updatedStyle.opacity = opacity
  }

  return (
    <div className={updatedClassName} style={updatedStyle}>
      {children}
    </div>
  )
}

export default Disabled
