import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  notes: [],
}

const FETCH_SUPPORT_NOTES = createAsyncHandlers('FETCH_SUPPORT_NOTES', {
  success(state, action) {
    state.notes = action.payload
  },
})

const CREATE_SUPPORT_NOTES = createAsyncHandlers('CREATE_SUPPORT_NOTES', {
  success(state, action) {
    const note = _get(action, 'payload.attributes')
    state.notes.push(note)
  },
})

const EDIT_SUPPORT_NOTES = createAsyncHandlers('EDIT_SUPPORT_NOTES', {
  success(state, action) {
    const updatedNote = _get(action, 'payload.attributes')
    state.notes = _map(state.notes, note => {
      if (note.id === updatedNote.id) {
        return updatedNote
      }
      return note
    })
  },
})

const DELETE_SUPPORT_NOTES = createAsyncHandlers('DELETE_SUPPORT_NOTES', {
  success(state, action) {
    state.notes = _filter(state.notes, note => note.id !== action.payload)
  },
})

export default handleActions(
  {
    ...FETCH_SUPPORT_NOTES,
    ...CREATE_SUPPORT_NOTES,
    ...EDIT_SUPPORT_NOTES,
    ...DELETE_SUPPORT_NOTES,
  },
  initialState,
)
