import React, { Component } from 'react'
import { DragDropContext } from 'react-dnd'
import MultiBackend  from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'
import AdditionalItemsTableCard from './AdditionalItemsTableCard'
import { orderBy, isEmpty } from 'lodash'

@DragDropContext(MultiBackend(HTML5toTouch))
class AdditionalItemsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.items !== this.props.items) {
      this.setState({ list: nextProps.items })
    }
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { list } = this.state
    const newList = [...orderBy(list, ['sortOrder'])]
    
    newList[dragIndex].sortOrder = hoverIndex

    for(let i = 0; i < newList.length; i++){
      newList[i].sortOrder = i + 1
    }
    newList[dragIndex].sortOrder = hoverIndex + 1
    newList[hoverIndex].sortOrder = dragIndex + 1

    this.setState({ list: newList }, () => {
      this.props.updateSortOrder(this.state.list)
    })
  }

  render() {
    const orderedList = orderBy(this.state.list, ['sortOrder'])
    return (
      <table className="table tickets-table membership-usage-table">
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Type</th>
            <th>Quantity</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isEmpty(orderedList) && orderedList.map((row, index) => (
            <AdditionalItemsTableCard 
              key={row.index} 
              moveCard={this.moveCard} 
              index={index} 
              onDropped={this.props.onDropped}
              row={row}
            />
          ))}
        </tbody>
      </table>
    )
  }
}

export default AdditionalItemsTable
