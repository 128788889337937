import React from 'react'
import _get from 'lodash/get'
import _identity from 'lodash/identity'
import TextArea from '../../_library/TextArea'

const TextAreaField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  ...props
}) => {
  const onChange = ({ target: { value } }) => {
    if (props.onChange) {
      props.onChange(field.name, normalizer(value))
    }
    setFieldValue(field.name, normalizer(value))
  }
  const onBlur = ({ target: { value } }) => {
    if (props.onChange) {
      props.onChange(field.name, normalizer(value))
    }
    setFieldValue(field.name, normalizer(value))
    touchedOnBlur && setFieldTouched(field.name, normalizer(value))
  }
  return (
    <div>
      <TextArea
        {...field}
        {...props}
        touched={_get(touched, field.name)}
        error={_get(errors, field.name)}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { TextAreaField }
