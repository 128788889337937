import { connect } from 'react-redux'
import React from 'react'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'

import Notifications from '../_library/notifications/Notifications'
import LoadingBar from '../_library/LoadingBar'
import { FETCH_TOUR } from '../../_common/redux/tours/actions'

@connect(
  state => {
    const tour = state.tours.selected
    return {
      tour,
    }
  },
  { FETCH_TOUR },
)
@withRouter
export default class TourPage extends React.PureComponent {
  componentDidMount() {
    const {
      FETCH_TOUR,
      tour,
      match: {
        params: { id },
      },
    } = this.props

    if (!tour || String(_get(tour, 'id')) !== id) {
      FETCH_TOUR(id)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      FETCH_TOUR,
      match: {
        params: { id: newId },
      },
    } = nextProps
    const {
      match: {
        params: { id: oldId },
      },
    } = this.props
    if (newId !== oldId) {
      FETCH_TOUR(newId)
    }
  }

  render() {
    const {
      tour,
      match: {
        params: { id },
      },
      children,
    } = this.props
    const showLoading = !tour || String(_get(tour, 'id')) !== id

    return (
      <div className="body-main">
        <Notifications />
        <div>
          <div className="body-panel-header">
            <div className="left">
              <div className="title">Tour</div>
              <div className="description">
                {!showLoading && (
                  <div className="tour">
                    {!!_get(tour, 'bannerURL') && <img alt="no data" src={_get(tour, 'bannerURL')} />}
                    <div>
                      <div>{_get(tour, 'displayName')}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="body-panel-spacing" />
          <div className="body-panel-content">
            {!showLoading && children}
            {!!showLoading && <LoadingBar title={"Hold tight! We're getting your tour's details..."} />}
          </div>
        </div>
      </div>
    )
  }
}
