import { createAsyncAction } from '../actions'
import { ERROR } from '../notification/actions'
import { getCountries } from '../../core/http_services/countries'
import createAction from 'redux-actions/lib/createAction'

const { GET_COUNTRIES } = createAsyncAction('GET_COUNTRIES', function () {
  return async dispatch => {
    try {
      const countries = await getCountries()
      dispatch(this.success({ countries }))
      return countries
    } catch (err) {
      dispatch(ERROR([err]))
    }
  }
})

const SET_COUNTRIES_FROM_INDEXED_DB = createAction('SET_COUNTRIES_FROM_INDEXED_DB', countries => ({
  countries,
}))

export { GET_COUNTRIES, SET_COUNTRIES_FROM_INDEXED_DB }
