import React from 'react'
import Field from '../../../_library/Field'

export default class CountryField extends React.Component {
  constructor(props) {
    super(props)
    this.handleCountryChanged = this.handleCountryChanged.bind(this)
  }
  handleCountryChanged(e) {
    const { onChange } = this.props
    if (onChange) { onChange(e) }
  }
  componentDidMount() {
    const { value, options, onChange } = this.props
    if (!value && options && options[0]?.label) {
      onChange(options[0].label)
    }
  }
  render() {
    const onChange = this.props.onChange || (() => {})
    return (
      <Field
        {...this.props}
        itemStyle={this.props.itemStyle}
        listStyle={this.props.listStyle}
        onChange={this.handleCountryChanged}
        onSelected={(o) => onChange(o.id)} />
    )
  }
}
