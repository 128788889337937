import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _result from 'lodash/result'
import _get from 'lodash/get'

import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'

import { SEND_INVITATIONS, CANCEL_INVITATION } from '../../_common/redux/invitation/actions'
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import { FETCH_EMAIL_TEMPLATES } from '../../_common/redux/emailtemplates/actions'
import { CARD_STATUS_UNCHANGED, CARD_STATUS_CHANGED, CARD_STATUS_SAVED } from '../../_common/core/form_helper'

import EventInvitationForm from './invitation/EventInvitationForm'
import ExistingInvitations from './invitation/ExistingInvitations'

import { get_event, get_event_tickets, get_email_templates } from '../../_common/core/selectors'
import { showAxiosError, showMessage } from '../utils/messenger'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const tickets = get_event_tickets(state)
    const emailTemplates = get_email_templates(state)

    return {
      event,
      tickets,
      emailTemplates,
    }
  },
  { SEND_INVITATIONS, CANCEL_INVITATION, FETCH_EVENT_TICKETS, FETCH_EMAIL_TEMPLATES },
)
export default class EventInvitation extends React.Component {
  constructor(props) {
    super(props)
    form_helper_reset()

    this.cardsStatus = {
      message: CARD_STATUS_UNCHANGED,
      unlock: CARD_STATUS_UNCHANGED,
      recipients: CARD_STATUS_UNCHANGED,
    }
  }

  componentDidMount() {
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future',
    }
    const {
      event: { id, displayName },
      FETCH_EVENT_TICKETS,
      FETCH_EMAIL_TEMPLATES,
      configs,
    } = this.props

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    FETCH_EVENT_TICKETS(id)
    FETCH_EMAIL_TEMPLATES(id)
  }

  componentWillUnmount() {
    form_helper_reset()
    this.isUnmounted = true
  }

  routerWillLeave = () => !form_helper_isEditted()

  sendNotification = form => {
    const { event, SEND_INVITATIONS } = this.props

    if (form.recipients_mode == 'form') {
      form.recipients = []
      form.recipients.push(form.recipient)
      delete form.recipient
    } else if (form.recipients_mode == 'csv') {
      delete form.recipient
    }
    delete form.recipients_mode

    return Promise.resolve(SEND_INVITATIONS(event.id, form))
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully sent!',
          hideAfter: 10,
          showCloseButton: true,
        })
        form_helper_reset()
        this.changeCardStatusToNormal()
        return v
      })
  }

  changeCardStatusToNormal() {
    const that = this
    if (this.cardsStatus.message != CARD_STATUS_UNCHANGED) {
      this.cardsStatus.message = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.message = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.unlock != CARD_STATUS_UNCHANGED) {
      this.cardsStatus.unlock = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.unlock = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.recipients != CARD_STATUS_UNCHANGED) {
      this.cardsStatus.recipients = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.recipients = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    this.forceUpdate()
  }

  cancelInvitation = async invitation => {
    const { CANCEL_INVITATION } = this.props
    try {
      const res = await CANCEL_INVITATION(invitation.id)
      showMessage('success', _get(res, 'data.message') || 'Successfully cancel!', 5, true)
    } catch (err) {
      showAxiosError(err, _get(err, 'err[0].details'))
      throw err
    }
  }

  render() {
    const { event, tickets, emailTemplates } = this.props

    const that = this

    const contentEdittedFields = []

    let recipients = false
    Object.keys(form_helper_get()).forEach((field, index) => {
      if (field == 'subject') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Subject
          </div>,
        )
        that.cardsStatus.message = CARD_STATUS_CHANGED
      } else if (field == 'body') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Invitation Content
          </div>,
        )
        that.cardsStatus.message = CARD_STATUS_CHANGED
      } else if (field == 'ticketTypeID') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Hidden Ticket Type
          </div>,
        )
        that.cardsStatus.unlock = CARD_STATUS_CHANGED
      } else if (field == 'recipient.first_name') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> First Name
          </div>,
        )
        that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field == 'recipient.last_name') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Last Name
          </div>,
        )
        that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field == 'recipient.email') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Notes
          </div>,
        )
        that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field == 'recipient.maxQuantity') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Maximum number of tickets
          </div>,
        )
        that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field == 'recipient.maxUses') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Maximum number of transactions
          </div>,
        )
        that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field == 'recipients_mode') {
        // contentEdittedFields.push(<div key={field}><i className="fa fa-info-circle" aria-hidden="true"></i> Recipients Mode</div>)
        // that.cardsStatus.recipients = CARD_STATUS_CHANGED
      } else if (field.startsWith('recipients[')) {
        if (!recipients) {
          contentEdittedFields.push(
            <div key={field}>
              <i className="fa fa-info-circle" aria-hidden="true" /> Recipients from CSV
            </div>,
          )
          that.cardsStatus.recipients = CARD_STATUS_CHANGED
          recipients = true
        }
      } else {
        contentEdittedFields.push(<div key={field}> - {field}</div>)
      }
    })

    return (
      <div className="EventInvitation">
        <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
        <h3 className="heading_style">Send New Invitation</h3>
        <EventInvitationForm
          onSubmit={this.sendNotification}
          cardsStatus={this.cardsStatus}
          event={event}
          tickets={tickets}
          emailTemplates={emailTemplates}
        />
        <h3 className="heading_style">Existing Invitations</h3>
        <ExistingInvitations event={event} onCancelInvitation={this.cancelInvitation} />
      </div>
    )
  }
}
