import React from 'react'
import LoadingBar from '../../_library/LoadingBar'
import SortableTable from '../../_library/SortableTable/index'
import EmptyBar from '../../_library/EmptyBar'
import { getTableColumns } from '../../utils/sortableTableUtils'
import CardHeader from '../../_library/CardHeader'
import { Chart } from '../../_library/Chart'
import { connect } from 'react-redux'
import { FETCH_EVENT_TRAFFIC } from '../../../_common/redux/performance/actions'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import { showAxiosError } from '../../utils/messenger'
import moment from 'moment'
import { defaultDateFormat } from '../../../_common/core/validation/normalizers'
import { DATE_TRAFFIC_SUPPORT } from '../performance/constants/constants'

@connect(null, { FETCH_EVENT_TRAFFIC })
export default class TrafficViews extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'locale',
        label: 'Language',
        isSortable: true
      },
      {
        key: 'total',
        label: 'No. of Users',
        isSortable: true
      }
    ])

    this.footbarColumns = [{ key: 'total', sourceKey: 'total' }]

    this.state = {
      sortBy: { column: 'key_1', asc: true },
      isLoading: true,
      unique: []
    }

    this.mounted = true
    this.trafficInterval = null
  }

  componentDidMount() {
    this.fetchTraffic()
    this.trafficInterval = setInterval(() => {
      this.fetchTraffic()
    }, 600 * 1000)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.trafficInterval)
  }

  fetchTraffic = async () => {
    const { FETCH_EVENT_TRAFFIC, event, showWarning, setShowWarning } = this.props
    const { isLoading, unique: stateUnique } = this.state
    if (!this.mounted) return
    try {
      const languages = getLanguages()
      const unique_users = await FETCH_EVENT_TRAFFIC(event.id, { group_by: ['language', 'unique'] })
      const unique = _map(_groupBy(unique_users, "locale"), ([firstUser, ...users]) => {
        firstUser.total = users.length + 1
        firstUser.locale = languages[firstUser.locale] ? languages[firstUser.locale] : firstUser.locale
        return firstUser
      }) ?? []
      if (_isEqual(unique, stateUnique) && !isLoading) {
        return
      }
      if (!showWarning) {
        if (unique.find(row => 
          moment(row.created_at, defaultDateFormat).isBefore(moment(DATE_TRAFFIC_SUPPORT, defaultDateFormat))
        )) {
          setShowWarning()
        }
      }
      this.setState({
        unique,
        isLoading: false
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  render() {
    const { sortBy, unique, isLoading } = this.state

    const labels = unique.map(lang => lang.locale)
    const data = unique.map(lang => lang.total)

    return (
      <div ref="cardContainer" className="performance-sales">
        <CardHeader
          imageUrl="/resources/images/event/performance/ticket-sales-ico.svg"
          bodyText="Languages"
        />
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your statistics..."} />
          ) : unique.length === 0 ? (
            <EmptyBar />
          ) : (
            <div>
              <Chart
                height="200px"
                chartConfig={{
                  type: 'pie',
                  hideLegend: true,
                  displayPercentage: true,
                  data: {
                    labels,
                    datasets: [{ data }]
                  },
                  options: {
                    legend: {
                      display: true,
                      position: 'bottom'
                    }
                  }
                }}
              />
              <SortableTable
                data={unique}
                tableColumns={this.tableColumns}
                enableSort={true}
                enableCopyTable={true}
                disableMobileView={true}
                sortBy={sortBy}
                footbar={{
                  label: 'Total',
                  columns: this.footbarColumns
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
