import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import Field from '../../_library/CountryField'

const CountryField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  onBlurNormalizer = _identity,
  showErrorOnlyTouched = true,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
  }
  const onChange = e => {
    const value = e.target ? e.target.value : e
    if (props.onChange) {
      props.onChange(value)
    }
    setValue(value)
  }
  const onBlur = e => {
    const value = e.target ? e.target.value : e
    setValue(value, onBlurNormalizer)
    touchedOnBlur && setFieldTouched(field.name, value)
    if (props.onBlur) {
      props.onBlur(value)
    }
  }
  return (
    <div>
      <Field
        {...field}
        {...props}
        id={field.name}
        touched={!showErrorOnlyTouched || _get(touched, field.name)}
        error={_get(errors, field.name)}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { CountryField }
