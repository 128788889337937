import React from 'react'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import { shortid } from '../../../_common/core/rand'

const activeTooltip = <Tooltip id={`tt-${shortid()}`}>This ticket is available for sale</Tooltip>
const inActiveTooltip = (
  <Tooltip id={`tt-${shortid()}`}>
    This ticket is <strong>not</strong> available for sale
  </Tooltip>
)
const alwaysAvailableTooltip = (
  <Tooltip id={`tt-${shortid()}`}>This ticket is always visible on the event page</Tooltip>
)
const hiddenTooltip = (
  <Tooltip id={`tt-${shortid()}`}>
    This ticket is hidden unless revealed with a special link
  </Tooltip>
)

export class TableIconLabels extends React.Component {
  render() {
    const {
      flagAlwaysAvailable,
      flagHidden,
      active,
      isDesktop,
      hideDragIcon,
      hideToolTip,
      enabled
    } = this.props
    const moveLabel = hideDragIcon ? null : <i className="fa fa-arrows" />
    const activeLabel = (
      <OverlayTrigger
        placement="top"
        overlay={hideToolTip ? <span /> : active || enabled ? activeTooltip : inActiveTooltip}
        trigger={['hover', 'click']}
      >
        <i className={`fa fa-circle ${active || enabled ? 'text-success' : 'text-danger'} fa-fw`} />
      </OverlayTrigger>
    )

    const visibleLabel = flagAlwaysAvailable ? (
      <OverlayTrigger placement="top" overlay={alwaysAvailableTooltip} trigger={['hover', 'click']}>
        <i className="fa fa-eye fa-fw brand-info" />
      </OverlayTrigger>
    ) : null

    const hiddenLabel = flagHidden ? (
      <OverlayTrigger placement="top" overlay={hiddenTooltip} trigger={['hover', 'click']}>
        <i className="fa fa-eye-slash fa-fw brand-info" />
      </OverlayTrigger>
    ) : null

    return isDesktop ? (
      <td className="tickets-table-td-desktop">
        {moveLabel}
        {visibleLabel}
        {hiddenLabel}
        {activeLabel}
      </td>
    ) : (
      <div className="ticket-table-td-mobile-labels">
        {moveLabel}
        {visibleLabel}
        {hiddenLabel}
        {activeLabel}
      </div>
    )
  }
}
