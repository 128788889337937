import React from 'react'
import _get from 'lodash/get'
import _xorWith from 'lodash/xorWith'
import _isEqual from 'lodash/isEqual'
import { getPerfomanceData } from '../../../../_common/core/http_services'
import PromoCodeComponent from './PromoCodeComponent'
import { showAxiosError } from '../../../utils/messenger'
import EmptyBar from '../../../_library/EmptyBar'
import LoadingBar from '../../../_library/LoadingBar'
import CardHeader from '../../../_library/CardHeader'

export default class PerformanceDiscountCodeBreakdown extends React.PureComponent {
  constructor(props) {
    super(props)

    this.initialData = null
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    const { event } = this.props

    if (event && event.id) {
      this.fetchData()
      this.fetchDataID = setInterval(async () => {
        this.fetchData()
      }, 10 * 1000)
    } else {
      this.setData()
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
    this.initialData = null
    clearInterval(this.fetchDataID)
  }

  fetchData = async () => {
    const { event } = this.props
    const section = 'discount_code_breakdown'

    try {
      const res = await getPerfomanceData(event.id, section)
      const performanceData = _get(res, section) || []

      if (_xorWith(performanceData, this.initialData, _isEqual)) {
        this.initialData = performanceData
        this.handleResponse(performanceData)
      }
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  handleResponse = (performanceData, error) => {
    const { isLoading } = this.state
    if (this.isUnmounted) return
    isLoading && this.setState({ isLoading: false })

    if (error) {
      showAxiosError(error)
    } else {
      this.setState({ performanceData })
    }
  }

  setData = () => {
    const { data } = this.props

    this.setState({
      performanceData: data,
      isLoading: false,
    })
  }

  render() {
    const { event } = this.props
    const { isLoading, performanceData } = this.state
    const isEmptyData = performanceData && !performanceData.length

    return (
      <div ref="cardContainer" className="performance-sales">
        <CardHeader
          imageUrl="/resources/images/event/performance/sales-by-promo-ico.svg"
          bodyText="Sales by Promo Code"
        />
        {isLoading ? (
          <LoadingBar title={`Hold tight! We're getting your event's sales details...`} />
        ) : isEmptyData ? (
          <EmptyBar />
        ) : (
          <PromoCodeComponent event={event} data={performanceData} />
        )}
      </div>
    )
  }
}
