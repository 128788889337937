import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _forEach from 'lodash/forEach'
import _reduce from 'lodash/reduce'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDate
} from '../../../../_common/core/validation/normalizers'
import { DISPLAY_FORMAT } from '../../../constants/timeFormats'
import EmptyBar from '../../../_library/EmptyBar'

export default class SalesByTimeSlots extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'release_type',
        label: 'Time Slot Start Date',
        normalizer: value => formatDate(value, DISPLAY_FORMAT)
      },
      {
        key: 'num_sales',
        label: 'No. of Sales'
      },
      {
        key: 'totalSales',
        label: 'Running Total (No. of Sales)',
        isSortable: false
      },
      {
        key: 'revenue',
        label: 'Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0')
          )
      },
      {
        key: 'revenueTotal',
        label: 'Running Total (Revenue)',
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ])

    this.detailsTableColumns = getTableColumns(
      [
        {
          key: 'release_type',
          label: 'Ticket Type'
        },
        {
          key: 'num_sales',
          label: 'No. of Sales'
        },
        {
          key: 'cost',
          label: 'Price (excl. Fees)',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'revenue',
          label: 'Revenue',
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        }
      ],
      getCurrencySymbol(props.event)
    )

    this.footbarColumns = [
      { key: 'totalSales', sourceKey: 'num_sales' },
      {
        key: 'revenueTotal',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]

    this.state = {
      sortBy: { column: 'release_type', asc: true },
      rows: null,
      detailsRows: []
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    const { groupedByTimeSlots } = this.props
    if (!_isEqual(groupedByTimeSlots, prevProps.groupedByTimeSlots)) {
      this.updateData(groupedByTimeSlots)
    }
  }

  updateData = () => {
    const { groupedByTimeSlots } = this.props
    const tempRows = []
    const detailsRows = []
    let totalSales = 0
    let totalRevenue = 0

    _forEach(groupedByTimeSlots, (value, key) => {
      const numSales = this.getTotalNumSales(value)
      totalSales += parseInt(numSales)
      totalRevenue += this.calculateRevenue(value)
      this.getDetailRows(value, value[0].slotStartDate, detailsRows)

      tempRows.push({
        id: value[0].slotStartDate,
        release_type: key,
        num_sales: numSales,
        cost: value[0].cost,
        revenue: this.calculateRevenue(value),
        revenueTotal: totalRevenue,
        totalSales
      })
    })

    const mergedStock = {}
    this.setState({
      rows: _map(
        _filter(tempRows, rowItem => !!rowItem.id),
        item => ({
          ...item,
          stock: mergedStock[item.id]
        })
      ),
      detailsRows
    })
  }

  getTotalNumSales = (ticketsArray = []) => {
    let totalSales = 0
    _forEach(ticketsArray, item => {
      totalSales += parseInt(item.num_sales)
    })

    return totalSales
  }

  calculateRevenue = (ticketsArray = []) => {
    let totalRevenue = 0
    _forEach(ticketsArray, item => {
      totalRevenue += parseFloat(item.num_sales) * parseFloat(item.cost)
    })

    return totalRevenue
  }

  calculateTotalValues = data => {
    const totalNumSales = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0)
    const totalRevenue = _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].cost)
    const totalGrossRevenue =
      _reduce(data, (sum, n) => sum + parseInt(n.num_sales), 0) * parseFloat(data[0].price)

    return [totalNumSales, totalRevenue, totalGrossRevenue]
  }

  getDetailRows = (data, date, detailsRows) => {
    const products = _groupBy(data, item => [item.slotGroupName, item.cost])
    const detailRows = []

    _forEach(products, item => {
      const [totalNumSales, totalRevenue] = this.calculateTotalValues(item)

      detailRows.push({
        id: date,
        release_type: item[0].slotGroupName || item[0].release_type,
        num_sales: totalNumSales,
        cost: item[0].cost,
        revenue: totalRevenue
      })
    })

    !!detailRows.length &&
      detailsRows.push({
        id: date,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`sales_by_time_slots-${detailRowIndex}`}
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            e2e_test_id="sales_by_time_slots"
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            detailsRows={detailsRows}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
            calculatedColumns={[
              { for: 'totalSales', column: 'num_sales' },
              { for: 'revenueTotal', column: 'revenue' }
            ]}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
