import React from 'react'
import _isEqual from 'lodash/isEqual'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import LoadingBar from '../../../_library/LoadingBar'
import { Chart } from '../../../_library/Chart'
import { RadioGroup, Radio } from '../../../_library/RadioGroup'
import SalesByAddOn from './SalesByAddOn'
import SalesByOrderDate from './SalesByOrderDate'
import EmptyBar from '../../../_library/EmptyBar'
import Toggle from '../../../_library/Toggle'

export default class AddOnBreakdownComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 'salesByAddOn',
      showGroups: false
    }

    this.initialData = null
  }

  componentDidMount() {
    const { data } = this.props
    this.updateComponent(data)
  }

  componentDidUpdate() {
    const { data } = this.props
    this.updateComponent(data)
  }

  updateComponent = data => {
    const { hideDateBreakdowns } = this.props
    if (!_isEqual(this.initialData, data)) {
      const groupedByOrderDate = _groupBy(data, 'orderDate')
      const groupedByAddOn = _groupBy(
        _map(data, item => ({ ...item, id: item.name + parseFloat(item.cost) })),
        'id'
      )
      this.initialData = data
      this.setState({
        addOnBreakdownData: data,
        groupedByAddOn,
        groupedByOrderDate: hideDateBreakdowns ? null : groupedByOrderDate
      })
    }
  }

  onChangeShowGroupsByDate = selectedTab => {
    this.setState({
      selectedTab
    })
  }

  onChangeShowGroups = checked => {
    this.setState({
      showGroups: checked
    })
  }

  getChildComponent = () => {
    const { event, tickets, hideDateBreakdowns } = this.props
    const { showGroups, selectedTab, addOnBreakdownData, groupedByOrderDate, groupedByAddOn } = this.state

    if (!(addOnBreakdownData && addOnBreakdownData.length)) return <EmptyBar />
    switch (selectedTab) {
    case 'salesByAddOn':
      return (
        <SalesByAddOn
          event={event}
          data={addOnBreakdownData}
          groupedByAddOn={groupedByAddOn}
          tickets={tickets}
          showGroups={showGroups}
          hideDateBreakdowns={hideDateBreakdowns}
        />
      )
      break
    case 'salesByOrderDate':
      return (
        <SalesByOrderDate
          event={event}
          data={addOnBreakdownData}
          groupedByOrderDate={groupedByOrderDate}
          tickets={tickets}
          showGroups={showGroups}
        />
      )
      break
    }
  }

  render() {
    const { event, hideDateBreakdowns } = this.props
    const { selectedTab, showGroups, addOnBreakdownData } = this.state

    const showToggle = !!(
      addOnBreakdownData &&
      addOnBreakdownData.reduce((curr, next) => {
        if (curr) {
          return curr
        }
        if (next.group !== null) {
          return true
        } 
        return false
        
      }, false)
    )

    return (
      <div className="performance-by-ticket">
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/add-ons-ico.svg')}
          />
          Add-Ons
        </div>
        <div className="table-background">
          {!hideDateBreakdowns && (
            <RadioGroup name="fruit" selectedValue={selectedTab} onChange={this.onChangeShowGroupsByDate}>
              <Radio name="salesByAddOn" value="salesByAddOn" label="Sales by Add-Ons" />
              <Radio name="salesByOrderDate" value="salesByOrderDate" label="Total Daily Sales" />
            </RadioGroup>
          )}
          {showToggle ? (
            <Toggle
              title="Show in groups"
              id="showGroups"
              value={showGroups}
              onChange={this.onChangeShowGroups}
            />
          ) : null}
          {this.getChildComponent()}
        </div>
      </div>
    )
  }
}
