import React, {
  useEffect,
  useState,
} from 'react'
import _get from 'lodash/get'
import { Chart } from '../../_library/Chart'
import ProgressCircle from '../../_library/ProgressCircle'
import EmptyBar from '../../_library/EmptyBar'
import LoadingBar from '../../_library/LoadingBar'
import Card from '../../_library/Card'

const GenderSection = React.memo((props) => {
  const { audience_gender, loading } = props
  const [gender, setGender] = useState({ male: 0, female: 0, male_percent: 0, female_percent: 0 })

  const _gender = _get(audience_gender, 'audience.gender')

  useEffect(() => {
    if (_gender) {
      const { male, female } = _gender
      let sum = male + female
      sum = sum < 1 ? 1 : sum
      const female_percent = parseInt(female / sum * 100)
      const male_percent = 100 - female_percent
      setGender({
        male,
        female,
        female_percent,
        male_percent,
      })
    }
  }, [audience_gender])

  let content = null

  if (loading) {
    content = <LoadingBar />
  } else if (!_gender) {
    content = <EmptyBar />
  } else {
    content = (
      <div className="row gender-breakdown">
        <div className="col-xs-12 col-sm-4 pie-component">
          <Chart
            height='200px'
            width='200px'
            chartConfig = {{
              type: 'pie',
              displayPercentage: true,
              hideLegend: true,
              data: {
                labels: ['FEMALE', 'MALE'],
                datasets: [{
                  data: [gender.female, gender.male],
                  backgroundColor: ['#FB52AE', '#5876FF']
                }]
              },
              options: {
                legend: {
                  display: false
                }
              }
            }}
          />
          <div className="pie-component-bar">
            <div className="pie-component-bar-left">
              <div>MALE</div>
            </div>
            <div className="pie-component-bar-center">
              <div>OVERALL</div>
            </div>
            <div className="pie-component-bar-right">
              <div>FEMALE</div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 female-circle">
          <ProgressCircle img={asset('/resources/images/icon-female.svg')} value={gender.female_percent}/>
          <div className="circle-info">
            <div className="circle-description">
              <div className="description1">
                <div className="circle-value">
                  {gender.female}
                </div>
                <div className="circle-type">
                  FEMALE
                </div>
              </div>
              <div className="description2">
                Ticket Buyers
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 male-circle">
          <ProgressCircle img={asset('/resources/images/icon-male.svg')} value={gender.male_percent}/>
          <div className="circle-info">
            <div className="circle-description">
              <div className="description1">
                <div className="circle-value">
                  {gender.male}
                </div>
                <div className="circle-type">
                  MALE
                </div>
              </div>
              <div className="description2">
                Ticket Buyers
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card icon='fa-male' icon2='fa-female' title='Gender Breakdown'>
      <div className="card-block-title">
        <div className="highlight">
          Ticket Buyers by<strong className="left-space">Gender</strong>
        </div>
      </div>
      {content}
    </Card>
  )
})

export { GenderSection }
