import _map from 'lodash/map'
import _result from 'lodash/result'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { connect } from 'react-redux'
import React from 'react'
import Scroll from 'react-scroll'
import { Tab, TabView } from '../_library/TabView'
import LoadingBar from '../_library/LoadingBar'
import {
  CREATE_AD,
  CREATE_ADCREATIVE,
  GENERATE_PREVIEW,
  CLEAR_PREVIEW,
  DEPLOY_AD,
} from '../../_common/redux/ads/actions'
import { FETCH_AUDIENCE } from '../../_common/redux/audience/actions'
import AdForm from './AdForm'
import Button from '../_library/Button'
import Card from '../_library/Card'
import getSymbolFromCurrency from 'currency-symbol-map'
import { ENV } from '../constants/env'

import { TYPE_EVENT, SECTION_LIKES, SECTION_MUSIC, SECTION_MUSICSTREAMING } from '../_library/OboeAudience'

import { get_event, get_event_audience } from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

function filterEvents(eventsArr) {
  const eventsMap = _map(eventsArr, event => ({
    displayName: _get(event, 'displayName'),
    slug: _get(event, 'slug'),
    id: _get(event, 'id'),
  }))
  return _sortBy(eventsMap, 'displayName')
}

function humanize(str) {
  const frags = str.split('_')
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
  }
  return frags.join(' ')
}

function addRow(params) {
  params.rows.push(
    <tr key={params.key}>
      <td className="text-left">{params.field}</td>
      <td className="text-right">{params.value}</td>
    </tr>,
  )
}

@connect(
  state => {
    const event = get_event(state)
    const audience = get_event_audience(state)
    let { events } = state.events
    const { previewGenerated } = state.ads //boolean
    const adCreativeId = state.ads.creative_id //id of the created ad creative
    const { previewIframe } = state.ads //html for ad creative preview iFrame
    const hasError = state.ads.has_error
    const hasSuccess = state.ads.has_success
    const { ad } = state.ads
    const campaignId = state.ads.campaign_id
    if (events.length) events = filterEvents(events)
    else events = []
    return {
      user: state.auth.user,
      event,
      audience,
      events,
      previewGenerated,
      adCreativeId,
      previewIframe,
      hasError,
      hasSuccess,
      ad,
      campaignId,
    }
  },
  { CREATE_AD, CREATE_ADCREATIVE, GENERATE_PREVIEW, CLEAR_PREVIEW, DEPLOY_AD, FETCH_AUDIENCE },
)
export default class AdPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loaded: false,
      type: null,
      section: null,
      audienceItem: null,
      form: {},
      created: false,
      deploying: false,
      deployed: false,
      scrollNeeded: false,
      initialFormValues: {
        attributes: {
          adSet: {
            is_autobid: true,
          },
        },
      },
    }
    this.backToForm = this.backToForm.bind(this)
    this.deployAd = this.deployAd.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const {
      match: {
        params: { id, audienceItemId },
      },
      FETCH_AUDIENCE,
      configs,
      event: { displayName },
    } = this.props
    const path = this.props.location.pathname
    const that = this

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    if (path === `/event/${id}/musicad/${audienceItemId}`) {
      this.setState(
        {
          type: TYPE_EVENT,
          section: SECTION_MUSIC,
          loading: true,
        },
        () => {
          Promise.resolve(FETCH_AUDIENCE(id, 'event', 'likes'))
            .catch(() => {
              that.setState({
                loading: false,
              })
            })
            .then(res => {
              const audienceItem = this.props.audience.likes.audience.likes.find(l => l.id === audienceItemId)
              that.setState({
                loading: false,
                loaded: true,
                audienceItem,
              })
            })
        },
      )
    } else if (path === `/event/${id}/musicstreamingad/${audienceItemId}`) {
      this.setState(
        {
          type: TYPE_EVENT,
          section: SECTION_MUSICSTREAMING,
          loading: true,
        },
        () => {
          Promise.resolve(FETCH_AUDIENCE(id, 'event', 'music'))
            .catch(() => {
              that.setState({
                loading: false,
              })
            })
            .then(res => {
              const audienceItem = this.props.audience.music.audience.musicstreaming.find(
                l => l.id === audienceItemId,
              )
              that.setState({
                loading: false,
                loaded: true,
                audienceItem,
              })
            })
        },
      )
    } else if (path === `/event/${id}/likesad/${audienceItemId}`) {
      this.setState(
        {
          type: TYPE_EVENT,
          section: SECTION_LIKES,
          loading: true,
        },
        () => {
          Promise.resolve(FETCH_AUDIENCE(id, 'event', 'likes'))
            .catch(() => {
              that.setState({
                loading: false,
              })
            })
            .then(res => {
              const audienceItem = this.props.audience.likes.audience.likes.find(l => l.id === audienceItemId)
              that.setState({
                loading: false,
                loaded: true,
                audienceItem,
              })
            })
        },
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.hasError || nextProps.hasSuccess) && this.state.scrollNeeded) {
      Scroll.animateScroll.scrollToTop()
      this.setState({ scrollNeeded: false })
    }
  }

  componentWillUnmount() {
    const { CLEAR_PREVIEW } = this.props
    CLEAR_PREVIEW()
  }

  backToForm() {
    this.setState({ created: false })
    const scroll = Scroll.animateScroll
    scroll.scrollToTop()
  }

  deployAd() {
    const { ad, DEPLOY_AD } = this.props
    const { form } = this.state
    this.setState({ deploying: true, scrollNeeded: true }, function () {
      return Promise.resolve(DEPLOY_AD(_get(ad, 'id'), form.attributes.user)).then(res => {
        this.setState({ deploying: false, deployed: true })
      })
    })
  }

  handleSubmit(form) {
    const { adCreativeId } = this.props
    const { audienceItem } = this.state
    form.attributes.adCreative.object_story_spec.link_data.link =
      ENV.API_CONSUMER + '/event/' + form.attributes.adCreative.object_story_spec.link_data.link
    if (!form.attributes.adSet.bid_amount) {
      form.attributes.adSet.is_autobid = true
    }
    form.attributes.adCreativeId = adCreativeId
    form.attributes.ad.status = 'PAUSED'
    form.attributes.audience = { user_ids: audienceItem.userIDs, page_name: audienceItem.name }
    form.attributes.userId = this.props.user.id
    this.setState({ scrollNeeded: true })
    if (form.submitType === 'generatePreview') {
      return this.generatePreview(form)
    }
    return this.createAd(form)
  }

  generatePreview(form) {
    const { CREATE_ADCREATIVE, GENERATE_PREVIEW } = this.props
    return Promise.resolve(CREATE_ADCREATIVE(form))
      .then(res => {
        const creativeId = res.id
        return Promise.resolve(GENERATE_PREVIEW(creativeId))
          .then(res => res)
          .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      })
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
  }

  createAd(form) {
    const { CREATE_AD } = this.props
    form.attributes.adSet.billing_event = (() => {
      switch (form.attributes.campaign.objective) {
        case 'LINK_CLICKS':
          return 'LINK_CLICKS'
        case 'REACH':
          return 'IMPRESSIONS'
        case 'CONVERSIONS':
          return 'IMPRESSIONS'
        default:
          break
      }
    })()
    return Promise.resolve(CREATE_AD(form))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(result => {
        if (!this.props.hasError) {
          this.setState({ created: true, form })
        }
        return result
      })
  }

  render() {
    const { event, events, previewGenerated, adCreativeId, previewIframe } = this.props
    const {
      form,
      created,
      deploying,
      deployed,
      initialFormValues,
      loading,
      loaded,
      audienceItem,
      type,
      section,
    } = this.state

    const adCreativePreview = {
      id: adCreativeId,
      previewGenerated,
      previewIframe: () => ({ __html: previewIframe }),
    }

    let symbol = ''
    if (form && form.attributes && form.attributes.user) {
      symbol = getSymbolFromCurrency(form.attributes.user.currency)
    }

    const campaign_rows = []
    const adSet_rows = []

    if (form && form.attributes) {
      const { campaign } = form.attributes
      const { adSet } = form.attributes
      if (campaign.name)
        addRow({
          rows: campaign_rows,
          key: 'name',
          field: 'Name:',
          value: campaign.name,
        })
      if (campaign.objective)
        addRow({
          rows: campaign_rows,
          key: 'objective',
          field: 'Objective:',
          value: humanize(campaign.objective),
        })
      if (campaign.start_time)
        addRow({
          rows: campaign_rows,
          key: 'start_time',
          field: 'Start Time:',
          value: new Date(campaign.start_time).toString(),
        })
      if (campaign.stop_time)
        addRow({
          rows: campaign_rows,
          key: 'stop_time',
          field: 'Stop Time:',
          value: new Date(campaign.stop_time).toString(),
        })
      if (adSet.name)
        addRow({
          rows: adSet_rows,
          key: 'name',
          field: 'Name:',
          value: adSet.name,
        })
      if (adSet.billing_event)
        addRow({
          rows: adSet_rows,
          key: 'billing_event',
          field: 'Billing Event:',
          value: humanize(adSet.billing_event),
        })
      if (adSet.is_autobid || adSet.bid_amount)
        addRow({
          rows: adSet_rows,
          key: 'bid_amount',
          field: 'Bid Amount:',
          value: adSet.is_autobid ? 'Automatic Bidding' : symbol + (adSet.bid_amount / 100).toFixed(2),
        })
      if (adSet.daily_budget)
        addRow({
          rows: adSet_rows,
          key: 'daily_budget',
          field: 'Daily Budget:',
          value: symbol + (adSet.daily_budget / 100).toFixed(2),
        })
    }

    return (
      <div className="ad-page">
        {loading && <LoadingBar title={"Hold tight! We're getting audience info..."} />}
        {created && (
          <Card icon="fa-info" title="Review Your Information">
            <TabView all={false}>
              <Tab title="Campaign">
                <table className="table">
                  <tbody>{campaign_rows}</tbody>
                </table>
              </Tab>
              <Tab title="Ad Set">
                <table className="table">
                  <tbody>{adSet_rows}</tbody>
                </table>
              </Tab>
              <Tab title="Ad Creative">
                <div
                  id="adCreativePreview"
                  className="text-center"
                  dangerouslySetInnerHTML={adCreativePreview.previewIframe()}
                />
              </Tab>
            </TabView>
            <div className="btn-toolbar text-center">
              <Button className="btn btn-cancel btn-lg btn-shadow" type="button" onClick={this.backToForm}>
                Back
              </Button>
              <Button
                className="btn btn-success btn-lg btn-shadow"
                type="button"
                loading={deploying}
                disabled={deployed}
                id="deployAdBtn"
                onClick={this.deployAd}
              >
                Deploy Ad
              </Button>
            </div>
          </Card>
        )}
        {loaded && !created && (
          <AdForm
            onSubmit={this.handleSubmit}
            events={events}
            adCreativePreview={adCreativePreview}
            initialValues={initialFormValues}
            event={event}
            type={type}
            section={section}
            audienceItem={audienceItem}
          />
        )}
      </div>
    )
  }
}
