import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import { Formik } from 'formik'
import _isObject from 'lodash/isObject'
import _forEach from 'lodash/forEach'
import _isArray from 'lodash/isArray'
import { get_event } from '../../../_common/core/selectors'
import { Repeat } from '../../../_common/core/repeat'
import { showAxiosError, showSuccessMessage, showMessage } from '../../../web/utils/messenger'
import { 
  getContract,
  createContract,
  updateContract,
  getContractInteractionStatuses,
  getContractStatus,
  deployContract,
  deleteContract,
} from '../../../_common/core/http_services'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import Button from '../../_library/Button'
import { NFTForm } from './NFTForm'
import { Statuses } from './Statuses'
import Disabled from '../../_library/Disabled'

const repeat = new Repeat(3000)

const createInitialValues = (values) => {
  return {
    name: values?.name ?? '',
    symbol: values?.symbol ?? '',
    networkType: values?.networkType ?? 'ethereum',
    address: values?.address ?? '',
    mintTime: values?.mintTime ?? 'buy',

    image: values?.image ?? '',
    description: values?.description ?? '',
    tokenName: values?.tokenName ?? '',
    externalUrl: values?.externalUrl ?? '',

    afterScan: values?.afterScan ?? false,

    afterScanImage: values?.afterScanImage ?? '',
    afterScanDescription: values?.afterScanDescription ?? '',
    afterScanTokenName: values?.afterScanTokenName ?? '',
    afterScanExternalUrl: values?.afterScanExternalUrl ?? '',

    secondarySalesRoyalties: values?.secondarySalesRoyalties ?? 5,
  }
}

const showUpdateMessages = (res) => {
  const parts = ['contractUpdate', 'tokenUpdate']
  const allSuccess = parts.every(part => !res?.[part]?.error)
  if (allSuccess) {
    showSuccessMessage('Saved')
  } else {
    parts.forEach(part => {
      if (res?.[part]?.error) {
        const message = res?.[part]?.message
        if (_isArray(message)) {
          _forEach(message, message => showMessage('error', message))
        } else if (_isObject(message)) {
          _forEach(message, item => _forEach(item, message => showMessage('error', message)))
        } else {
          showMessage('error', message)
        }
      }
    })
  }
}

let NFTs = (props) => {
  const {event} = props

  const [contract, setContract] = useState({
    fetchLoading: false,
    createLoading: false,
    updateLoading: false,
    deployLoading: false,
    data: null,
    dataFetched: false,
    status: '',
    statuses: {},
  })

  const [initialValues, setInitialValues] = useState(createInitialValues())

  const isNotExist = contract.status === 'not-exist'
  const isNotReady = contract.status === 'not-ready'
  const isDraft = contract.status === 'draft'
  const isDeploying = contract.status === 'deploying'
  const isDeployed = contract.status === 'deployed'

  useEffect(() => {
    fetchContract()
    updateInteractionStatuses()
    repeat.run()
    return () => repeat.stop()
  }, [])

  useEffect(() => {
    const update = () => {
      if (isNotReady || isDeploying) {
        updateStatus()
      }
      updateInteractionStatuses()
    }
    repeat.on(update)
    return () => repeat.off(update)
  })


  const updateStatus = async () => {
    try {
      const status = await getContractStatus(event.id)
      if (status === 'draft' && isNotReady) {
        showSuccessMessage('Saved')
      }
      if (status === 'deployed' && isDeploying) {
        showSuccessMessage('Deployed')
      }
      setContract(state => ({...state, status}))
    } catch(err) {}
  }

  const updateInteractionStatuses = async () => {
    try {
      const statuses = await getContractInteractionStatuses(event.id)
      setContract(state => ({...state, statuses}))
    } catch(err) {}
  }

  const fetchContract = async () => {
    if (!contract.dataFetched && !contract.fetchLoading) {
      try {
        setContract(state => ({...state, fetchLoading: true}))
        const contract = await getContract(event.id)
        const status = contract ? (contract.status ?? 'not-ready') : 'not-exist'
        setContract(state => ({...state, fetchLoading: false, dataFetched: true, data: contract, status}))
        setInitialValues(createInitialValues(contract))
      } catch(err) {
        showAxiosError(err)
      }
    }
  }

  const save = async (values) => {
    try {
      setContract(state => ({...state, createLoading: true}))
      await createContract(event.id, values)
      setContract(state => ({...state, status: 'not-ready'}))
      setInitialValues(createInitialValues(values))
    } catch (err) {
      showAxiosError(err)
    } finally {
      setContract(state => ({...state, createLoading: false}))
    }
  }

  const update = async (values) => {
    try {
      setContract(state => ({...state, updateLoading: true}))
      const res = await updateContract(event.id, values)
      showUpdateMessages(res)
      setInitialValues(createInitialValues(res.contract))
    } catch (err) {
      showAxiosError(err)
    } finally {
      setContract(state => ({...state, updateLoading: false}))
    }
  }


  const onSubmit = async (values, actions) => {
    try {
      if (isNotExist) {
        await save(values)
      } else {
        await update(values)
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const onDeploy = async () => {
    try {
      setContract(state => ({...state, deployLoading: true}))
      await deployContract(event.id)
      setContract(state => ({...state, deployLoading: false, status: 'deploying'}))
    } catch (err) {
      setContract(state => ({...state, deployLoading: false}))
      showAxiosError(err)
    }
  }

  if (event && !event.flagRequireEthereumWallet) {
    return <Redirect to='/' />
  }

  if (!contract.dataFetched) {
    return <LoadingBar />
  }


  return (
    <div className="nft-contract">
      <div className='event-nft-header'>
        <h3 className="heading_style">NFT Contract</h3>
        <Statuses statuses={contract.statuses} />
      </div>
      <small>Enhance your attendees' experience with NFT collectibles airdropped directly into their wallets.</small>
      <Card title="Contract Details" status={''}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
          { props => {
            const { values, dirty, setFieldValue, handleSubmit } = props
            
            const isCreateMode = isNotExist || isNotReady
            const isUpdateMode = !isCreateMode && dirty
            const isDeployMode = !isCreateMode && !isUpdateMode

            const isSomeProcess = isNotReady || isDeploying || contract.createLoading || contract.deployLoading || contract.updateLoading
            const afterCreateDisabled = !isNotExist
            const afterDeployDisabled = isDeployed
            const afterSalesDisabled = event.hasSales
            const afterMintDisabled = contract.data?.hasMintedToken

            return (
              <>
                <Disabled opacity={1} disabled={isSomeProcess}>
                  <NFTForm
                    {...props}
                    afterCreateDisabled={afterCreateDisabled}
                    afterDeployDisabled={afterDeployDisabled}
                    afterSalesDisabled={afterSalesDisabled}
                    afterMintDisabled={afterMintDisabled}
                  />
                  <br/>
                  {isCreateMode && (
                    <div className="row">
                      <div className="col-xs-12 text-center save-btn">
                        <Button className="btn btn-success btn-lg btn-shadow" onClick={handleSubmit} loading={isSomeProcess}>
                          Save NFT Contract Details
                        </Button>
                      </div>
                    </div>
                  )}

                  {isUpdateMode && (
                    <div className="row">
                      <div className="col-xs-12 text-center save-btn">
                        <Button className="btn btn-success btn-lg btn-shadow" loading={isSomeProcess} onClick={handleSubmit}>
                          Update Contract
                        </Button>
                      </div>
                    </div>
                  )}

                  {isDeployMode && (
                      <div className="row">
                        <div className="col-xs-12 text-center">
                          <Button 
                            className="btn btn-primary btn-lg btn-shadow"
                            type="submit"
                            loading={isSomeProcess}
                            disabled={isDeployed}
                            onClick={onDeploy}
                          >
                            {isDeployed ? 'Deployed' : 'Deploy'}
                          </Button>
                        </div>
                      </div>
                    )
                  }

                  {/* <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="row">
                    <div className="col-xs-12 text-center">
                      <Button 
                        className="btn btn-primary btn-lg btn-shadow"
                        onClick={() => (deleteContract(event.id))}
                      >
                        Delete
                      </Button>
                    </div>
                  </div> */}
                </Disabled>
                
              </>
            )
          }}
        </Formik>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  event: get_event(state)
})

NFTs = connect(mapStateToProps)(NFTs)

export { NFTs }
