import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'

import { createAsyncHandlers } from '../actions'

const initialState = {
  zoomAuthUrl: null,
  zoomMeetings: null,
  zoomMeeting: null,
  zoomUserData: null,
}

const GET_ZOOM_AUTH_URL = createAsyncHandlers('GET_ZOOM_AUTH_URL', {
  success(state, action) {
    const data = _get(action, 'payload.data')
    state.zoomAuthUrl = _get(data, '$original.attributes')
  },
})

const GET_ZOOM_MEETINGS = createAsyncHandlers('GET_ZOOM_MEETINGS', {
  success(state, action) {
    const data = _get(action, 'payload.data')
    state.zoomMeetings = _get(data, '$original.attributes')
  },
})

const GET_ZOOM_MEETING = createAsyncHandlers('GET_ZOOM_MEETING', {
  success(state, action) {
    const data = _get(action, 'payload.data')
    state.zoomMeeting = _get(data, '$original.attributes')
  },
})

const GET_ZOOM_USER_DATA = createAsyncHandlers('GET_ZOOM_USER_DATA', {
  success(state, action) {
    const data = _get(action, 'payload.data')
    state.zoomUserData = _get(data, '$original.attributes')
  },
})

export default handleActions(
  {
    ...GET_ZOOM_AUTH_URL,
    ...GET_ZOOM_MEETINGS,
    ...GET_ZOOM_MEETING,
    ...GET_ZOOM_USER_DATA,
  },
  initialState,
)
