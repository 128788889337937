import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import { get_event_id } from '.'

export const get_alternative_view = state => _get(state, 'alternativeview')
export const get_event_alternative_views = createSelector(
  get_alternative_view,
  get_event_id,
  (alternative_view, eid) => {
    if (eid === _get(alternative_view, 'eid')) {
      return _get(alternative_view, 'alternativeview', [])
    }
    return []
  },
)
