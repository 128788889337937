import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import Card from '../../../_library/Card'
import Filters from '../../../_library/Filters'
import Toggle from '../../../_library/Toggle'

export default class FiltersSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterTypes: []
    }
  }

  componentDidMount() {
    const { tags } = this.props
    this.setFilters(tags)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.tags, this.props.tags)) {
      this.setFilters(this.props.tags)
    }
  }

  setFilters = (tags = []) => {
    this.setState({
      filterTypes: _.map(tags, item => ({ value: item, label: item }))
    })
  }

  render() {
    const { onFiltersChange, onlyShowPaidTickets, onChangeOnlyShowPaidTickets } = this.props
    const { filterTypes } = this.state

    return (
      <Card icon={'fa-filter'} title={'Filter'} closed={true} openedClassName = 'overflow-visible-card'>
        <Filters
          filters={[{ label: 'Tags', type: 'tags', options: filterTypes }]}
          onFiltersChange={filters => onFiltersChange(filters)}
        >
          <div className="row">
            <div className="guest-row guest-ticket-sm col-xs-6">
              <div className="line pt-10">
                <Toggle
                  title="Only show paid tickets"
                  id="onlyShowPaidTickets"
                  value={onlyShowPaidTickets}
                  onChange={onChangeOnlyShowPaidTickets}
                />
              </div>
            </div>
          </div>
        </Filters>
      </Card>
    )
  }
}
