import React from 'react'

const LocationRow = ({ value, onClick }) => (
  <div>
    <div className="value-loc value-loc-mobile">{value}</div>
    <div className="go-to-loc" onClick={onClick}>
      <i className="fa fa-location-arrow" />
    </div>
    <div className="value-loc value-loc-desktop">{value}</div>
  </div>
)

export { LocationRow }
