import React from 'react'
import ConfirmModal from '../../../_library/ConfirmModal'

const DeleteAddOnVariantModal = ({
  deleteingAddonVariantModalData: { isOpen, loading },
  handleDeleteAddonVariant,
  handleResetDeleteingAddonVariantState,
}) => {
  const onConfirmAddOnVariantDeleteResponse = response => {
    switch (response) {
      case 'delete':
        handleDeleteAddonVariant()
        break
      default:
        handleResetDeleteingAddonVariantState()
    }
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      header="Are you sure?"
      content={
        <p>
          Deleting the variant will prevent users from buying it and will trigger the next available variant
          to go live.
        </p>
      }
      actions={[
        {
          value: 'delete',
          label: 'Delete',
          className: 'btn btn-danger btn-shadow',
          loading,
        },
        { value: 'cancel', label: 'Cancel', className: 'btn btn-cancel', disabled: loading },
      ]}
      onAction={onConfirmAddOnVariantDeleteResponse}
    />
  )
}

export default DeleteAddOnVariantModal
