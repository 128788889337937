import React from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import EmptyBar from '../../_library/EmptyBar'
import { JSONDatatable, IS_FOUND, DATATABLE, TYPE_FROM_URL } from '../../_library/JSONDatatable'

const AUTO_REFRESH = 10 * 1000

export default class PerformanceCharges extends React.PureComponent {
  getFilteredRows = (rows, search) => {
    let rows_filtered = rows

    // filter by search
    const keyword = search.join('').trim().toLowerCase()
    if (keyword != '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += IS_FOUND(item.short_description, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getSortedRows = (rows_filtered, sort) => {
    if (sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch (sort.index) {
        case 0:
          rows_filtered = _orderBy(rows_filtered, t => t.short_description, dir)
          break
        case 1:
          rows_filtered = _orderBy(rows_filtered, t => t.quantity, dir)
          break
        case 2:
          rows_filtered = _orderBy(rows_filtered, t => t.net_charge, dir)
          break
        case 3:
          rows_filtered = _orderBy(rows_filtered, t => parseFloat(t.net_charge) * parseInt(t.quantity), dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  getTableData = (datatable, rows_filtered, sort) => {
    const clipboard_text = this.getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: 'Charge Description', sort: false },
        { title: 'Quantity', sort: false },
        { title: 'Amount (excl. fees)', sort: false },
        { title: 'Revenue (excl. fees)', sort: false },
        { title: clipboard, sort: false, className: 'column-clipboard' },
      ],
      sort,
    )

    const { event } = this.props
    const currency = getCurrencySymbol(event)

    let totalQuantity = 0
    let totalRevenue = 0
    rows_filtered.map((row, index) => {
      totalQuantity += parseInt(row.quantity)
      totalRevenue += parseFloat(row.net_amount) * parseInt(row.quantity)
    })

    return rows_filtered.length != 0 ? (
      <table className="table tickets-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.map((row, index) => {
            const revenue = parseFloat(row.net_amount) * parseInt(row.quantity)
            return (
              <tr key={index} className={index % 2 == 0 ? 'row-stale' : ''}>
                <td>{row.short_description}</td>
                <td>{row.quantity}</td>
                <td>
                  {currency}
                  {parseFloat(row.net_amount).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {currency}
                  {parseFloat(revenue).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td />
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>Total:</strong>
            </td>
            <td>{totalQuantity}</td>
            <td />
            <td>
              {currency}
              {parseFloat(totalRevenue).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td />
          </tr>
        </tfoot>
      </table>
    ) : (
      <EmptyBar />
    )
  }

  getClipboardText = rows_filtered => {
    let ret = ''
    ret +=
      'Charge Description' +
      '\t' +
      'Quantity' +
      '\t' +
      'Amount (excl. fees)' +
      '\t' +
      'Revenue (excl. fees)' +
      '\n'
    _map(rows_filtered, t => {
      const revenue = parseFloat(t.net_amount) * parseInt(t.quantity)
      ret +=
        t.short_description +
        '\t' +
        t.quantity +
        '\t' +
        parseFloat(t.net_amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) +
        '\t' +
        parseFloat(revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
        '\n'
    })
    return ret
  }

  render() {
    const { event } = this.props
    return (
      <div>
        <div className="table-caption">
          <img
            className="section-main-heading"
            src={asset('/resources/images/event/performance/additional-charges-ico.svg')}
          />
          Additional Charges
        </div>
        <div className="table-background">
          <JSONDatatable
            type={TYPE_FROM_URL}
            data={{
              url: `/api/events/${event.id}/relationships/performance/`,
              param: { section: 'charges' },
              node: 'data.charges.*',
            }}
            sort={{ index: 1, asc: false }}
            getFilteredRows={(rows, search) => this.getFilteredRows(rows, search)}
            getSortedRows={(rows_filtered, sort) => this.getSortedRows(rows_filtered, sort)}
            getTableData={(datatable, rows_filtered, sort) =>
              this.getTableData(datatable, rows_filtered, sort)
            }
            getClipboardText={rows_filtered => this.getClipboardText(rows_filtered)}
            usePagination={true}
            autoRefresh={AUTO_REFRESH}
            loadingBarTitle={"Hold tight! We're getting your event's charges..."}
          >
            <div ref={DATATABLE} />
          </JSONDatatable>
        </div>
      </div>
    )
  }
}
