// Re-render Optimization 14.05.2020
import React from 'react'
import { Link } from 'react-router-dom'

export default React.memo(props => {
  const { link, buttonTitle, content } = props

  return (
    <div className="card text-center">
      <div className="card-block">
        <div>
          {!!link && (
            <Link className="btn btn-lg btn-primary btn-shadow" to={link}>
              <i className="fa fa-fw fa-plus" /> {buttonTitle || 'Create'}
            </Link>
          )}
          {!link &&
            (content || (
              <img
                alt="no data"
                className="no_data_ico"
                src={asset('/resources/images/no_data_icon_new.png')}
              />
            ))}
        </div>
      </div>
    </div>
  )
})
