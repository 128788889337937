import React, { Component } from 'react'
import Button from '../../../_library/Button'
import { FieldArray, Field } from 'formik'
import { SimpleField } from '../../../formik/Fields'
import {
  combineNormalizers,
  toNumber,
  requiredValidator,
  combineValidators,
  minNumberValidator,
  maxNumberValidator
} from '../../../../_common/core/validation'

const normalizeTempPriceRanges = priceRanges => {
  priceRanges.reduce((prev, cur) => {
    if (prev) {
      cur.from = prev.to
    }
    return cur
  }, null)
}

export default class PriceRangesComponent extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      values: { priceRanges: pTempPriceRanges }
    } = this.props
    const {
      values: { priceRanges: nTempPriceRanges }
    } = nextProps
    if (pTempPriceRanges !== nTempPriceRanges) {
      normalizeTempPriceRanges(nTempPriceRanges)
    }
  }

  render() {
    const { values } = this.props
    return (
      <div>
        <div className="col-xs-12 col-sm-12">
          <p className="h4" style={{ color: '#fff', fontWeight: '600' }}>
            {'Sliding Scale'}
          </p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p style={{ fontSize: 11, lineHeight: 'normal' }}>
            {
              'Please specify the number of registrations after which the price should change and the corresponding prices for each round.'
            }
          </p>
        </div>
        <div className="div-spacing-10" />
        <FieldArray
          name="priceRanges"
          render={arrayHelpers => (
            <div className="col-sm-6 col-lg-6" style={{ padding: 0 }}>
              {values.priceRanges.map((data, index) => {
                const isInfoRow = values.priceRanges[index].type === 'info'
                return (
                  <div key={index}>
                    <div className="col-xs-5 col-lg-5" style={{ paddingRight: 10 }}>
                      <Field
                        name={`priceRanges[${index}].to`}
                        label={`From ${
                          index - 1 >= 0 ? values.priceRanges[index - 1].to : '0'
                        } to:`}
                        component={SimpleField}
                        touchedOnBlur={true}
                        validate={combineValidators(
                          requiredValidator(),
                          minNumberValidator(
                            undefined,
                            index - 1 >= 0 ? Number(values.priceRanges[index - 1].to) + 1 : 0
                          )
                        )}
                        normalizer={toNumber}
                        disabled={isInfoRow}
                        title={isInfoRow ? 'Maximum Number of Sales' : ''}
                      />
                    </div>
                    <div className="col-xs-5 col-lg-5" style={{ paddingLeft: 10 }}>
                      <Field
                        name={`priceRanges.${index}.price`}
                        label="Price"
                        component={SimpleField}
                        touchedOnBlur={true}
                        onBlurNormalizer={combineNormalizers(toNumber)}
                        validate={
                          isInfoRow
                            ? requiredValidator()
                            : combineValidators(
                              requiredValidator(),
                              maxNumberValidator(
                                undefined,
                                index - 1 >= 0
                                  ? Number(values.priceRanges[index - 1].price)
                                  : 9999999999999
                              )
                            )
                        }
                        normalizer={toNumber}
                      />
                    </div>
                    {!!index && !isInfoRow && (
                      <div className="col-xs-2 col-sm-2" style={{ padding: '24px 0' }}>
                        <i
                          id={data.to}
                          onClick={() => arrayHelpers.remove(index)}
                          style={{ fontSize: '22px', cursor: 'pointer' }}
                          className="fa fa-trash"
                        />
                      </div>
                    )}
                  </div>
                )
              })}
              <div className="col-xs-12 col-sm-12">
                <Button
                  className="btn btn-success"
                  type="button"
                  disabled={
                    values.priceRanges.length - 2 >= 0
                      ? !values.priceRanges[values.priceRanges.length - 2].to ||
                        !values.priceRanges[values.priceRanges.length - 2].price
                      : false
                  }
                  onClick={() =>
                    arrayHelpers.insert(values.priceRanges.length - 1, {
                      from: values.priceRanges[values.priceRanges.length - 1].to,
                      to: '',
                      price: ''
                    })
                  }
                >
                  {'Add new range'}
                </Button>
              </div>
              <div className="div-spacing-20" />
            </div>
          )}
        />
      </div>
    )
  }
}
