import { API_BASE } from '../../../../_common/core/http'

export const getUrl = (eventId, day, groupId) => {
  const url = `${API_BASE}/api/events/${eventId}/relationships/tickets/?date=${day}&group_ids[]=${groupId}`
  const opts = {
    credentials: 'include',
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }
  }
  return { url, opts }
}
