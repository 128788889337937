import React, {
  useEffect,
  useState,
} from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _orderBy from 'lodash/orderBy'
import _values from 'lodash/values'

import EmptyBar from '../../_library/EmptyBar'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import {
  DATATABLE,
  JSONDatatable,
  PAGINATIONBAR,
  TYPE_FROM_ARRAY,
} from '../../_library/JSONDatatable'

const Professions = React.memo((props) => {
  const { audience_psychographics, loading } = props
  const jobs = _get(audience_psychographics, 'audience.jobs')
  const [data, setData] = useState({
    total_titles: 0,
    profile_count: 0,
    total_companies: 0,
    rows_job_titles: [],
    rows_job_companies: [],
  })

  const {
    total_titles,
    profile_count,
    total_companies,
    rows_job_titles,
    rows_job_companies,
  } = data


  useEffect(() => {
    const profile_count = _get(jobs, 'profile_count')
    const job_titles = _get(jobs, 'titles')
    const job_companies = _get(jobs, 'companies')
    let rows_job_titles = []
    let rows_job_companies = []

    let total_titles = 0
    let total_companies = 0

    if (job_titles) {
      const filtered_titles = {}
      const filtered_companies = {}

      _forEach(job_titles, (value, key) => {
        const lower_key = key.toLowerCase()
        if (!filtered_titles[lower_key]) {
          filtered_titles[lower_key] = {
            title: key,
            value,
          }
          total_titles += value
        } else {
          const _value = filtered_titles[lower_key].value
          if (value > _value) {
            filtered_titles[lower_key] = {
              title: key,
              value,
            }
            total_titles -= _value
            total_titles += value
          }
        }
      })

      rows_job_titles = _values(filtered_titles)


      _forEach(job_companies, (value, key) => {
        const lower_key = key.toLowerCase()
        if (!filtered_companies[lower_key]) {
          filtered_companies[lower_key] = {
            title: key,
            value,
          }
          total_companies += value
        } else {
          const _value = filtered_companies[lower_key].value
          if (value > _value) {
            filtered_companies[lower_key] = {
              title: key,
              value,
            }
            total_companies -= _value
            total_companies += value
          }
        }
      })

      rows_job_companies = _values(filtered_companies)


      setData({
        total_titles,
        profile_count,
        total_companies,
        rows_job_titles,
        rows_job_companies,
      })
    }
  }, [audience_psychographics])


  const getClipboardText = (rows_filtered) => {
    let ret = ''
    ret += 'Title' + '\t' + 'Number' + '\n'
    _map(rows_filtered, (t) => {
      ret += t.title + '\t' + t.value.toLocaleString() + '\n'
    })
    return ret
  }

  const getSortedRows = (rows_filtered, sort) => {
    if(sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch(sort.index) {
      case 0:
        rows_filtered = _orderBy(rows_filtered, (t) => t.title, dir)
        break
      case 1:
        rows_filtered = _orderBy(rows_filtered, (t) => parseInt(t.value), dir)
        break
      default:
        break
      }
    }
    return rows_filtered
  }

  const getTableData = (datatable, rows_filtered, sort, isCompany) => {
    const clipboard_text = getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const content_header = datatable.getHeaderRow(datatable, [
      { title: isCompany ? 'Company' : 'Title', sort: true },
      { title: 'Number', sort: true },
      { title: clipboard, sort: false, className: 'column-clipboard' },
    ], sort)

    let total = 0
    _forEach(rows_filtered, (row)=>{
      total += row.value
    })

    return (rows_filtered.length) ? (
      <table className="table tickets-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {
            rows_filtered.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'row-stale' : ''}>
                <td>{row.title}</td>
                <td>{row.value.toLocaleString()}</td>
                <td>&nbsp;</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    ): (
      <EmptyBar />
    )
  }




  let content = null
  if (loading) {
    content = <LoadingBar />
  } else if(jobs) {
    content = (
      <div>
        <div className="card-block-title">
          <div className="highlight">
            <div className="profession_stat">
              <img src={asset('/resources/images/system_icons/profession.svg')} className="profession_icons"/>
              <div className="profession_count">
                <span>{total_titles}</span>
                <div className="profession_count_title">
                  <sup>Job Titles</sup>
                </div>
              </div>
            </div>
            <span className="across">AND</span>
            <div className="profession_stat">
              <img src={asset('/resources/images/event/demographics/company.svg')} className="profession_icons"/>
              <div className="profession_count">
                <span>{total_companies}</span>
                <div className="profession_count_title">
                  <sup>Companies</sup>
                </div>
              </div>
            </div>
            <span className="across">ACROSS</span>
            <div className="profession_stat">
              <img src={asset('/resources/images/system_icons/tickets.svg')} className="profession_icons"/>
              <div className="profession_count">
                <span>{profile_count}</span>
                <div className="profession_count_title">
                  <sup>Ticket Buyers</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-spacing-20"/>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="table-responsive demographics-table">
              <JSONDatatable
                type={TYPE_FROM_ARRAY}
                data={rows_job_titles}
                sort={{ index: 1, asc: false }}
                getSortedRows={(rows_filtered, sort) => getSortedRows(rows_filtered, sort)}
                getTableData={(datatable, rows_filtered, sort) => getTableData(datatable, rows_filtered, sort, false)}
                getClipboardText={(rows_filtered) => getClipboardText(rows_filtered)}
                usePagination={true}
                paginationPageSize={100}
              >
                <div ref={DATATABLE}/>
                <div ref={PAGINATIONBAR}/>
              </JSONDatatable>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="table-responsive demographics-table">
              <JSONDatatable
                type={TYPE_FROM_ARRAY}
                data={rows_job_companies}
                sort={{ index: 1, asc: false }}
                getSortedRows={(rows_filtered, sort) => getSortedRows(rows_filtered, sort)}
                getTableData={(datatable, rows_filtered, sort) => getTableData(datatable, rows_filtered, sort, true)}
                getClipboardText={(rows_filtered) => getClipboardText(rows_filtered)}
                usePagination={true}
                paginationPageSize={100}
              >
                <div ref={DATATABLE}/>
                <div ref={PAGINATIONBAR}/>
              </JSONDatatable>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card icon={'fa-briefcase'} title={'Professions'}>
      {content}
    </Card>
  )
})

export { Professions }
