import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import _zipObject from 'lodash/zipObject'
import moment from 'moment'
import { DISPLAY_FORMAT } from '../../../../../constants/timeFormats'

export const generateTimeSlots = (values, timeSlotsValues, selectedDates) => {
  const generatedSlots = []
  for (let i = 0; i < selectedDates.length; i++) {
    const date = selectedDates[i]
    const startDate = moment(date.startDate).format('D MMM YYYY')
    const startDateTime = moment(`${startDate} ${timeSlotsValues.startTime}`, DISPLAY_FORMAT)
    const endDateTime = moment(`${startDate} ${timeSlotsValues.endTime}`, DISPLAY_FORMAT)

    // Duration
    const durationHour = timeSlotsValues.durationHours
    const { durationMinutes } = timeSlotsValues
    const duration = durationHour * 60 + Number(durationMinutes)

    let tempStartDateTime = startDateTime
    while (endDateTime.diff(tempStartDateTime) >= duration * 60000) {
      generatedSlots.push({
        active: true,
        checkInEnd: moment(tempStartDateTime)
          .add(durationHour, 'hours')
          .add(durationMinutes, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'),
        checkInStart: moment(tempStartDateTime).format('YYYY-MM-DD HH:mm:ss'),
        description: values.description,
        displayName: values.displayName,
        flagAlwaysAvailable: values.flagAlwaysAvailable,
        flagHidden: values.flagHidden,
        flagTicketHasTimeSlots: true,
        maxInventory: timeSlotsValues.quantity || values.maxInventory,
        price: timeSlotsValues.price || values.faceValue,
        quantityIncrement: values.quantityIncrement,
        duration
      })

      tempStartDateTime = tempStartDateTime
        .add(durationHour, 'hours')
        .add(durationMinutes, 'minutes')
    }
  }

  return generatedSlots
}

export const removeDates = (selectedDates, excludedDates, selectedWeekdays) => {
  let dates = selectedDates
  if (excludedDates && excludedDates.length) {
    dates = _filter(
      selectedDates,
      d =>
        !_find(excludedDates, x => moment(x.startDate).format() === moment(d.startDate).format())
    )
  }

  dates = _filter(dates, d =>
    _find(selectedWeekdays, w => moment(d.startDate).format('ddd') === w)
  )
  return dates
}

export const formatDate = (date, format) => {
  const formattedDate = format ? moment(date).format(format) : date
  return formattedDate.replace(/\+|\:|\-/g, '_')
}

export const getTimeSlotTicketsByDay = tickets => {
  const timeSlotTickets = _filter(tickets, t => !!t.slotGroupName)
  const timeSlotTicketsUniqGroups = _uniqBy(timeSlotTickets, 'slotGroupName')
  const groupNames = _map(timeSlotTicketsUniqGroups, g => g.slotGroupName)
  const days = _map(timeSlotTicketsUniqGroups, g => {
    const selectedDates = _map(timeSlotTickets, o => ({
      startDate: moment(o.slotStartDate),
      slotGroupName: o.slotGroupName
    })).filter(s => s.slotGroupName === g.slotGroupName)
    return getGroupedTimeSlots(timeSlotTickets, selectedDates)
  })

  return _zipObject(groupNames, days)
}

export const getGroupedTimeSlots = (generatedTimeSlots, selectedDates) => {
  const timeSlotsArray = generatedTimeSlots
    ? _map(selectedDates, d => {
      const tempArr = _filter(generatedTimeSlots, g => {
        const start = g.checkInStart || g.slotStartDate
        return (
          start.includes(moment(d.startDate).format('YYYY-MM-DD')) &&
            d.slotGroupName === g.slotGroupName
        )
      })
      return tempArr
    })
    : []
  const keys = _map(selectedDates, s => formatDate(s.startDate, 'D MMM YYYY'))
  return _zipObject(keys, timeSlotsArray)
}
