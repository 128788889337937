import _get from 'lodash/get'

export const eventAdapter = data => {
  const event = {
    ...data,
    ..._get(data, 'attributes', {}),
    self: _get(data, 'relationships.self.data', {}),
    owner: _get(data, 'relationships.owner.data', {}),
  }
  delete event.attributes
  delete event.relationships
  return event
}