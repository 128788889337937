import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import localforage from 'localforage'

import { FETCH_EVENTS_INCREMENTAL, SET_EVENTS_FROM_INDEXED_DB, CLEAR_EVENTS } from '../../_common/redux/events/actions'
import { FETCH_SESSION } from '../../_common/redux/auth/actions'
import { GET_COUNTRIES, SET_COUNTRIES_FROM_INDEXED_DB } from '../../_common/redux/shared/actions'
import { logCorrruptedEventList } from '../../_common/core/utils'
import { logEventListGetFromStorageError } from '../../_common/core/sentry'

@connect(
  state => {
    const { user } = state.auth
    const { events } = state.events
    const eventsFullReady = state.events.fullReady
    const eventsIsCache = state.events.isCache
    const { countries } = state.shared

    return {
      user,
      events,
      eventsFullReady,
      eventsIsCache,
      countries
    }
  },
  {
    FETCH_EVENTS_INCREMENTAL,
    SET_EVENTS_FROM_INDEXED_DB,
    FETCH_SESSION,
    GET_COUNTRIES,
    SET_COUNTRIES_FROM_INDEXED_DB,
    CLEAR_EVENTS
  }
)
export default class DataManager extends React.PureComponent {
  componentDidMount() {
    const { user, FETCH_SESSION, enableIncrementalDataFetch, noAppcues, eventsFullReady, countries } = this.props
    if (user && !countries?.length) {
      this.fetchCountries()
    }
    if (user && enableIncrementalDataFetch && !eventsFullReady) {
      this.fetchInitialData()
      this.setEventsFromIndexedDB(user)
    } else {
      FETCH_SESSION({ hideError: true, noAppcues })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { 
      user: pUser, 
      events: pEvents, 
      enableIncrementalDataFetch, 
      countries,
      CLEAR_EVENTS 
    } = this.props
    const { user: nUser, events: nEvents, eventsFullReady } = nextProps
    if (!pUser && nUser && !countries?.length) {
      this.fetchCountries()
    }
    if (!pUser && nUser && enableIncrementalDataFetch) {
      this.fetchInitialData()
      this.setEventsFromIndexedDB(nUser)
    }

    if (pEvents !== nEvents && eventsFullReady && nUser) {
      this.setEventsInIndexedDB(nEvents, nUser)
    }

    if (!_isEqual(countries, nextProps.countries)) {
      try {
        const strCountries = JSON.stringify(nextProps.countries)
        sessionStorage.setItem('countries', strCountries)
      } catch (e) {}
    }

    if(pUser && nUser?.tfRole && pUser?.tfRole !== nUser?.tfRole) {
      this.setEventsInIndexedDB([])
      CLEAR_EVENTS([])
      this.fetchInitialData()
    }
  }

  setEventsInIndexedDB = (events, user) => {
    const accountId = _get(user, 'id', '')
    localforage.setItem('events', {
      accountId,
      events
    })
  }

  setEventsFromIndexedDB = async user => {
    const { SET_EVENTS_FROM_INDEXED_DB } = this.props
    try {
      const { accountId, events = [] } = (await localforage.getItem('events')) ?? {}

      logCorrruptedEventList(accountId, events)
      events.length && SET_EVENTS_FROM_INDEXED_DB(_filter(events, item => item))
    } catch (err) {
      logEventListGetFromStorageError(err)
    }
  }

  fetchInitialData = () => {
    const { FETCH_EVENTS_INCREMENTAL } = this.props
    FETCH_EVENTS_INCREMENTAL()
  }

  fetchCountries = async () => {
    const { GET_COUNTRIES, SET_COUNTRIES_FROM_INDEXED_DB } = this.props

    try {
      const countriesString = sessionStorage.getItem('countries')
      const countries = JSON.parse(countriesString)
      if (countries && !_isEmpty(countries)) {
        SET_COUNTRIES_FROM_INDEXED_DB(countries)
      } else {
        GET_COUNTRIES()
      }
    } catch (err) {}
  }

  render() {
    return null
  }
}
