import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import FileUploader from '../../_library/FileUploader'

const FileUploaderField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  normalizer = _identity,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
    setFieldTouched(field.name, true)
  }
  const onChange = value => {
    setValue(value || '')
    if (props.onChange) {
      props.onChange(value)
    }
  }
  let errorContent = null
  const errorMessage = _get(errors, field.name, '')
  const isTouched = _get(touched, field.name, false)
  if (errorMessage && isTouched) {
    errorContent = <small className="text-danger">{errorMessage}</small>
  }

  return (
    <div>
      <div className="file-upload-error-container">{errorContent}</div>
      <FileUploader {...field} {...props} id={field.name} onChange={onChange} />
    </div>
  )
}

export { FileUploaderField }
