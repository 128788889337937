// Re-render Optimization 18.05.2020
import React from 'react'
import moment from 'moment'
import Address from '../../../_library/Address'
import DateLabel from '../../../_library/DateLabel'
import { Link } from 'react-router-dom'

export default React.memo(props => {
  const { event, isActive, brandDetails } = props
  const { isOnlineEvent } = event
  const addr = !!event ? event.venue : null
  const startDate_utc = moment.utc(new Date(event.startDate))
  return (
    <div className="event-content" style={{ display: 'table-cell' }}>
      {isActive && (
        <img className="event-content-tick" src={asset('/resources/images/check-mark-round.svg')} />
      )}
      {brandDetails?.id && (
        <div className="event-content-name">
          <Link className="event-brand-link" to={'/brand/' + brandDetails.id + '/details'}>
            {brandDetails?.displayName}
          </Link>
        </div>
      )}
      <div className="event-content-name inline">{event.displayName}</div>
      <div className="event-startDate">
        <img src={asset('/resources/images/cal-ico.svg')} />
        <DateLabel className="starttime" value={startDate_utc} format="LLL" />
      </div>
      {isOnlineEvent ? (
        <div className="event-address">
          <img src={asset('/resources/images/location-ico.svg')} />
          <div className="address-notdefined">Online Event</div>
        </div>
      ) : (
        !!addr && (
          <div className="event-address">
            <img src={asset('/resources/images/location-ico.svg')} />
            <Address type="simple" className="address-form" {...addr} />
          </div>
        )
      )}
      {brandDetails?.id && (
        <div className="event-id">
        Event ID {event.id}
        </div>
      )}
    </div>
  )
})
