import React, { useEffect } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { FETCH_TRACKING_DATA_BY_TOKEN } from '../../../../_common/redux/ticketSalesTracking/actions'
import ReleaseBreakdownComponent from '../PerformanceReleaseBreakdown/ReleaseBreakdownComponent'
import AddOnBreakdownComponent from '../PerformanceAddOnBreakdown/AddOnBreakdownComponent'
import LoadingBar from '../../../_library/LoadingBar'
import EmptyBar from '../../../_library/EmptyBar'
import { addOnBreakdownDataAdapter } from '../../../../_common/core/http_services/adapters'

const SalesTrackingPage = props => {
  const { match, isLoading, trackingDataByToken, FETCH_TRACKING_DATA_BY_TOKEN } = props

  const releaseBreakdownData = _get(trackingDataByToken, 'release_breakdown') || []
  const isReleaseDataEmpty = _isEmpty(releaseBreakdownData)
  const hideDateBreakdowns = _get(trackingDataByToken, 'hideDateBreakdowns') || false
  const addOnBreakdownData = _get(trackingDataByToken, 'add_on_breakdown') || []
  const adaptedAddOnBreakdownData = addOnBreakdownDataAdapter(addOnBreakdownData)
  const isAddOnDataEmpty = _isEmpty(adaptedAddOnBreakdownData)
  const tickets = _get(trackingDataByToken, 'ticket_types') || []
  const event = _get(trackingDataByToken, 'event') || []

  let fetchReleaseBreakdownId = null

  useEffect(() => {
    if (match.params.token) {
      getDataByToken(match.params.token)
    }

    return () => clearInterval(fetchReleaseBreakdownId)
  }, [])

  const getDataByToken = async token => {
    try {
      const res = await FETCH_TRACKING_DATA_BY_TOKEN(token)
      if (res) {
        fetchReleaseBreakdownId = setInterval(async () => {
          FETCH_TRACKING_DATA_BY_TOKEN(token)
        }, 10 * 1000)
      }
    } catch (err) {
      clearInterval(fetchReleaseBreakdownId)
    }
  }
  
  return (
    <div className="performance" style={{ marginLeft: 20 }}>
      <div className="performance-tab-body" style={{ padding: 0, backgroundColor: 'transparent' }}>
        <div className="tab selected">
          {isLoading ? (
            <LoadingBar title={`Hold tight! We're getting your tracking sales details...`} />
          ) : isReleaseDataEmpty && isAddOnDataEmpty ? (
            <EmptyBar />
          ) : (
            <div>
              {isReleaseDataEmpty ? null : (
                <ReleaseBreakdownComponent
                  event={event}
                  tickets={tickets}
                  data={releaseBreakdownData}
                  showPieChart={false}
                  hideDateBreakdowns={hideDateBreakdowns}
                />
              )}
              <div className="div-spacing-30" />
              {isAddOnDataEmpty ? null : (
                <AddOnBreakdownComponent
                  event={event}
                  tickets={tickets}
                  data={adaptedAddOnBreakdownData}
                  hideDateBreakdowns={hideDateBreakdowns}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const trackingDataByToken = _get(state, 'ticketSalesTracking.trackingDataByToken')
  const isLoading = state.loading.has('FETCH_TRACKING_DATA_BY_TOKEN') && _isEmpty(trackingDataByToken)

  return {
    isLoading,
    trackingDataByToken
  }
}

const mapDispatchToProps = {
  FETCH_TRACKING_DATA_BY_TOKEN
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackingPage)
