import _head from 'lodash/head'
import _map from 'lodash/map'
import _get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'

import Button from '../_library/Button'
import Currency from '../_library/Currency'
import Field from '../_library/Field'
import { FETCH_EVENT_TICKETS, UPDATE_TICKET } from '../../_common/redux/tickets/actions'

import { get_event, get_event_tickets } from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const event = get_event(state)
    const tickets = get_event_tickets(state)

    return {
      event,
      tickets
    }
  },
  { FETCH_EVENT_TICKETS, UPDATE_TICKET }
)
export default class EventInventory extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loadingFetch: false, editing: null, adding: false, editingFee: false }
  }
  componentDidMount() {
    const {
      event: { displayName },
      tickets,
      configs
    } = this.props

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    if (tickets.length) {
      return
    }
    this.refresh()
  }
  refresh() {
    const { event, FETCH_EVENT_TICKETS } = this.props
    if (this.state.loading) {
      return
    }
    const loadingSetter = val => () => this.setState({ loadingFetch: val })
    Promise.resolve(FETCH_EVENT_TICKETS(event.id)).catch(loadingSetter(false)).then(loadingSetter(false))
    loadingSetter(true)()
  }
  render() {
    const { loadingFetch, adding, editing, deleting, editingFee } = this.state
    const { event, tickets } = this.props
    const currency = getCurrencySymbol(event)
    const defaultTicket = _head(tickets)

    const convertToCurrency = val => {}

    return (
      <div className="card">
        <div className="row">
          <div className="col-xs-12">
            <div className="table_reponsive">
              <table className="table tickets-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price (excl. fees)</th>
                    <th>Price (incl. fees)</th>
                    <th>In stock</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {_map(tickets, function (ticket, index) {
                    const price1 = parseFloat(ticket.price) / 1.1
                    const price2 = parseFloat(ticket.price)

                    return (
                      <tr key={index} className={index++ % 2 == 0 ? 'row-stale' : ''}>
                        <td>{ticket.displayName}</td>
                        <td>
                          <Currency code={currency} value={price1} />
                        </td>
                        <td>
                          <Currency code={currency} value={price2} />
                        </td>
                        <td>
                          <Field
                            id="stock"
                            type="number"
                            label="Stock"
                            value={ticket.stock}
                            onBlur={e => this.handleStockChange('onBlur', e)}
                            onChange={e => this.handleStockChange('onChange', e)}
                          />
                        </td>
                        <td>
                          <Button
                            id={'btnUpdate' + index}
                            className="btn btn-primary btn-shadow"
                            type="button"
                          >
                            Update
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
