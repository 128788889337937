import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import { get_event_id } from '.'

export const get_all_promotions = state => _get(state, 'promotions')

export const get_event_promotions = createSelector(get_all_promotions, get_event_id, (all_promotions, eid) =>
  _get(all_promotions, eid, []),
)
