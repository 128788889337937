import React, { useState, useEffect, useMemo } from 'react'
import _startCase from 'lodash/startCase'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import EmptyBar from '../../_library/EmptyBar'
import SortableTableWithSearchHOC from '../../hoc/SortableTableWithSearchHOC'
import SortableTableWithPaginationHOC from '../../hoc/SortableTableWithPaginationHOC'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { getPerfomanceData } from '../../../_common/core/http_services'
import { RadioGroup, Radio } from '../../_library/RadioGroup'
import LoadingBar from '../../_library/LoadingBar'
import Button from '../../_library/Button'
import { DOWNLOAD_CSV, convertToCSV, getCSVData } from '../../utils/csv'

const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)
const AUTO_REFRESH = 10 * 1000
const searchBy = ['firstName', 'lastName', 'email', 'name', 'quantityRequested', 'status', 'createdAt']
const DEFAULT_PAGE_SIZE = 250

const PerformanceWaitingList = props => {
  const [performanceList, setPerformanceList] = useState([])
  const [allowExport, setAllowExport] = useState(true)
  const [selectedTab, setSelectedTab] = useState('ticket_waiting_list')
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [resetPagination, setResetPagination] = useState(0)
  const [sortBy, setSortBy] = useState({ column: 'createdAt', asc: false })

  const columnSortBy = column => {
    setSortBy({ column: column.key, asc: !sortBy.asc })
  }

  const tableColumns = getTableColumns([
    { key: 'full_name', label: 'Name', sort: columnSortBy },
    { key: 'email', label: 'Email Address', sort: columnSortBy },
    {
      key: 'name',
      label: selectedTab === 'ticket_waiting_list' ? 'Ticket Type' : 'Add-on',
      sort: columnSortBy,
    },
    { key: 'quantityRequested', label: 'Quantity Requested', sort: columnSortBy },
    {
      key: 'status',
      label: 'Status',
      showStatusName: true,
      normalizer: value => _startCase(value),
      sort: columnSortBy,
    },
    { key: 'createdAt', label: 'Application Date', sort: columnSortBy },
  ])

  const {
    event: { id: eventId },
    configs,
  } = props

  const onChangeShowGroupsByDate = value => {
    setSelectedTab(value)
    setSearchValue('')
  }

  const handleSearchChange = data => {
    setAllowExport(!!data.length)
  }

  useEffect(() => {
    setPerformanceList([])
    setAllowExport(true)

    const fetchPerformanceData = async () => {
      try {
        setIsLoading(true)
        const result = await getPerfomanceData(eventId, selectedTab)
        const selectedTabData = result[selectedTab]

        const filteredSelectedTabData = selectedTabData.map(
          ({ name, firstName, lastName, email, createdAt, status, quantityRequested }) => ({
            full_name: firstName + ' ' + lastName,
            name: name ?? '',
            email: email ?? '',
            quantityRequested: quantityRequested ?? '',
            status: status ?? '',
            createdAt: createdAt ?? '',
          }),
        )

        if (_isEmpty(filteredSelectedTabData)) {
          setIsLoading(false)
        }

        setPerformanceList(filteredSelectedTabData)
      } catch (err) {
        setIsLoading(false)
      }
    }

    fetchPerformanceData()
    const timer = setInterval(() => {
      fetchPerformanceData()
    }, AUTO_REFRESH)

    return () => {
      clearTimeout(timer)
    }
  }, [selectedTab, eventId])

  const csvFileName = `performance_waitinglist_${props.event.id}.csv`

  const getSearchedData = ({ searchValue }) => {
    setSearchValue(searchValue)
    setResetPagination(state => state + 1)
  }

  const searchValueLower = searchValue.toLowerCase()

  const filteredData = useMemo(
    () =>
      performanceList
        .filter(
          el =>
            el.full_name.toLowerCase().includes(searchValueLower) ||
            el.email.toLowerCase().includes(searchValueLower) ||
            el.quantityRequested.toLowerCase().includes(searchValueLower) ||
            el.status.toLowerCase().includes(searchValueLower) ||
            el.createdAt.toLowerCase().includes(searchValueLower) ||
            el.name.toLowerCase().includes(searchValueLower),
        )
        .sort((a, b) =>
          sortBy.asc
            ? a[sortBy.column].localeCompare(b[sortBy.column])
            : b[sortBy.column].localeCompare(a[sortBy.column]),
        ),
    [searchValueLower, performanceList, sortBy],
  )

  const onClickExportCSV = csvFileName => {
    const csvData = getCSVData(tableColumns, filteredData)

    const csv = convertToCSV({
      data: csvData,
    })

    DOWNLOAD_CSV(csv, csvFileName)
  }

  const showAddOns = _get(configs, 'appearance.showAddOns', true)

  return (
    <div>
      <div className="table-caption margin-btm-15">
        <img
          className="section-main-heading"
          src={asset('/resources/images/event/performance/waiting-list-ico.svg')}
          alt=""
        />
        Waiting List
      </div>
      <div className="performance-waiting-list-container">
        <RadioGroup selectedValue={selectedTab} onChange={onChangeShowGroupsByDate}>
          <Radio name="ticketWaitingList" value="ticket_waiting_list" label="Tickets" />
          {showAddOns && <Radio name="addOn" value="add_on_waiting_list" label="Add-Ons" />}
        </RadioGroup>
        {performanceList && performanceList.length ? (
          <>
            <SortableTableWithSearchAndPagination
              e2e_test_id="waiting_list"
              resetPagination={resetPagination}
              getSearchedData={getSearchedData}
              data={filteredData}
              tableColumns={tableColumns}
              enableCopyTable={true}
              sortBy={sortBy}
              searchBy={searchBy}
              disableMobileView={true}
              footbar={{
                label: 'Total:',
                value: filteredData.length,
              }}
              enableSort={true}
              onSearchChange={handleSearchChange}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              isAsyncSearch={true}
            />
            <Button
              id="waiting-list-button"
              className="btn btn-primary export-to-csv-waiting"
              disabled={!allowExport}
              onClick={() => onClickExportCSV(csvFileName)}
            >
              Export To CSV
            </Button>
          </>
        ) : isLoading ? (
          <LoadingBar title="Hold tight! We're getting your event's details..." />
        ) : (
          <EmptyBar />
        )}
      </div>
    </div>
  )
}

export default PerformanceWaitingList
