import React, { useEffect } from 'react'
import _get from 'lodash/get'

import { IS_MOBILE_DEVICE } from '../../_common/core/utils'

import Root from '../Root'

import { loadAppcues } from '../../_common/core/lazy_load'

import store from '../../_common/redux/store'
import { CLEAR } from '../../_common/redux/notification/actions'

const rootWrapper = (C, options) => props => {
  const globalStates = store.getState()
  const isRnDomain = _get(globalStates, 'shared.isRnDomain')
  const isMcDomain = _get(globalStates, 'shared.isMcDomain')

  const { location } = props
  useEffect(() => {
    const bodyEl = $('body')
    if (IS_MOBILE_DEVICE()) {
      bodyEl.removeClass('sidebar-open')
    } else {
      bodyEl.addClass('sidebar-open')
    }
    if (!isRnDomain && !isMcDomain && !options?.noAppcues) {
      loadAppcues().then(() => {
        window.Appcues && window.Appcues.page()
      })
    }

    store.dispatch(CLEAR())
  }, [location.pathname, isRnDomain, isMcDomain])

  return (
    <Root options={options}>
      <C {...props} />
    </Root>
  )
}

export { rootWrapper }
