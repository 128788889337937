export const loadScript = options =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    for (const key in options) {
      script[key] = options[key]
    }
    script.onload = () => resolve(script)
    script.onerror = () => reject(script)
    document.head.append(script)
  })

export const loadScripts = (urlsOptions = []) => Promise.all(urlsOptions.map(options => loadScript(options)))
