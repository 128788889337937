import _map from 'lodash/map'
import React from 'react'
import TimeSlotsTableRow from './TimeSlotsTableRow'
import Button from '../../../../_library/Button'
import TimeSlotsEditModal from './TimeSlotsEditModal'

export default class TimeSlotsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
      selectedRow: null
    }
  }

  handleEditButtonClick = value => {
    this.setState({
      display: true,
      selectedRow: value
    })
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      selectedRow: {
        ...prevState.selectedRow,
        [key]: value
      }
    }))
  }

  handleUpdateOrDelete = (t, isDeleting) => {
    const { handleUpdate } = this.props
    const { selectedRow } = this.state
    handleUpdate(isDeleting ? t : selectedRow, !!isDeleting)
    this.setState({
      display: false,
      selectedRow: null
    })
  }

  handleCancelEdit = () => {
    this.setState({
      display: false,
      selectedRow: null
    })
  }

  render() {
    const {
      slots,
      isInventoryMode,
      currency,
      values,
      handleDelete,
      isExisting,
      handleEdit,
      handleActivate,
      handleDeactivate,
      tableColumns
    } = this.props
    const { display, selectedRow } = this.state

    return (
      <div>
        {display && (
          <TimeSlotsEditModal
            display={display}
            values={selectedRow}
            handleChange={this.handleChange}
            onSubmit={this.handleUpdateOrDelete}
            onCancel={this.handleCancelEdit}
          />
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className="table tickets-table">
                <thead>
                  {tableColumns && tableColumns.length ? (
                    <tr>
                      {_map(tableColumns, t => (
                        <td>{t}</td>
                      ))}
                    </tr>
                  ) : (
                    <tr>
                      {isExisting && <th>Status</th>}
                      <th>Start Time</th>
                      <th>End Time</th>
                      {isExisting && <th>Face Value</th>}
                      <th>Price (incl. Fees)</th>
                      {isInventoryMode && <th className="text-center">Max Number of Sales</th>}
                      {isExisting && <th>Stock</th>}
                      <th />
                    </tr>
                  )}
                </thead>
                <tbody>
                  {slots && slots.length
                    ? _map(slots, (t, index) => (
                      (t.active || isExisting) && (
                        <TimeSlotsTableRow
                          {...t}
                          isExisting={isExisting}
                          key={t.id || index}
                          index={index}
                          isInventoryMode={isInventoryMode}
                          currency={currency ? currency.currency : 'USD'}
                        >
                          <div className="btn-toolbar2" style={{ textAlign: 'center' }}>
                            <Button
                              className="btn btn-primary"
                              type="button"
                              onClick={() =>
                                isExisting ? handleEdit(t, true) : this.handleEditButtonClick(t)
                              }
                            >
                              <i className="fa fa-pencil fa-fw" />
                                  Edit
                            </Button>
                            <Button
                              className={
                                isExisting
                                  ? t.active
                                    ? 'btn btn-seablue'
                                    : 'btn btn-ok'
                                  : 'btn btn-danger'
                              }
                              type="button"
                              onClick={() =>
                                isExisting
                                  ? t.active
                                    ? handleDeactivate(t.id, true)
                                    : handleActivate(t.id, true)
                                  : this.handleUpdateOrDelete(t, true)
                              }
                            >
                              <i
                                className={
                                  isExisting
                                    ? t.active
                                      ? 'fa fa-ban fa-fw'
                                      : 'fa fa-cog fa-fw'
                                    : 'fa fa-trash fa-fw'
                                }
                              />
                              {isExisting ? (t.active ? 'Deactivate' : 'Activate') : 'Delete'}
                            </Button>
                          </div>
                        </TimeSlotsTableRow>
                      )
                    ))
                    : null}
                </tbody>
              </table>
              {(!slots || (slots && !slots.length)) && (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '20px 0',
                    fontSize: 16,
                    fontWeight: '700',
                    background: '#24262c',
                    marginTop: -5
                  }}
                >
                  {'No slots for today'}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
