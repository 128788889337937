export const FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DISPLAY_FORMAT = 'D MMM YYYY h:mm a'
export const DAY_FORMAT = 'YYYY-MM-DD'
export const DAY_DISPLAY_FORMAT = 'D MMM YYYY'
export const TIME_FORMAT = 'HH:mm:ss'
export const TIME_FORMAT_SHORT = 'HH:mm'
export const TIME_DISPLAY_FORMAT = 'h:mm a'
export const DAY_FORMAT_1 = 'M/D/YYYY'
export const HOURSE_MINUTES = 'H:mm'
export const TIME_FORMAT_ONLY_TIME = 'HH'
export const PROJECT_WIDE_FORMAT = 'DD MMM YYYY hh:mm a'
