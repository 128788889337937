import _get from 'lodash/get'
import _set from 'lodash/set'
import { Formik, Form } from 'formik'
import React from 'react'

import Button from '../../_library/Button'

import SupportBlock from './SupportBlock'

function validateForm(data, props) {
  const required = ['internalSupportEmail', 'externalSupportEmail']
  const errors = {}

  const internalSupportEmail = _get(data, 'internalSupportEmail')
  const externalSupportEmail = _get(data, 'externalSupportEmail')

  if (!_get(data, 'useBrandContacts')) {
    required.forEach((f) => {
      if (!_.get(data, f)) {
        _.set(errors, f, 'Required')
      }
    })

    if (internalSupportEmail && !validateEmail(internalSupportEmail)) {
      _set(errors, 'internalSupportEmail', 'Invalid email address')
    }

    if (externalSupportEmail && !validateEmail(externalSupportEmail)) {
      _set(errors, 'externalSupportEmail', 'Invalid email address')
    }
  }
  return errors
}

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  renderInitialValues = () => ({
    useBrandContacts: _get(this.props, 'eventSupport.useBrandContacts'),
    internalSupportEmail: _get(this.props, 'eventSupport.internalSupportEmail') || '',
    externalSupportEmail: _get(this.props, 'eventSupport.externalSupportEmail') || ''
  })
  render() {
    const { submitLabel, handleSubmit, event } = this.props

    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.renderInitialValues()}
        validate={validateForm}
        onSubmit={(values, actions) => {
          const { setFieldValue, setSubmitting, resetForm } = actions
          handleSubmit(values).finally(() => {
            setSubmitting(false)
            if (values.useBrandContacts) {
              resetForm()
              setFieldValue('useBrandContacts', values.useBrandContacts)
            }
          })
        }}
        render={({ values, isSubmitting, isValid }) => (
          <Form autoComplete="off">
            <SupportBlock useBrandContacts={values.useBrandContacts} event={event}/>

            <div className="card-block card-footer text-center btn-toolbar">
              <Button
                type="submit"
                className="btn btn-primary btn-lg btn-shadow"
                loading={isSubmitting}
              >
                {submitLabel}
              </Button>
            </div>
          </Form>
        )}
      />
    )
  }
}

export default MyForm
