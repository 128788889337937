import React, {
  useEffect,
  useState,
} from 'react'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'

import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import ProgressCircle from '../../_library/ProgressCircle'

const initialData = {
  datum: [['< 18', 0, 0], ['18-20', 0, 0], ['21-25', 0, 0], ['26-30', 0, 0], ['31-40', 0, 0], ['41+', 0, 0]],
  total: 0,
  highest: 0,
  max: 0,
  min: 0,
  lowest: 5,
}

const AgeRanges = React.memo((props) => {
  const { performance, loading } = props
  const [data, setData] = useState(_cloneDeep(initialData))
  const {
    datum,
    total,
    highest,
    lowest,
  } = data

  const age_breakdown = _get(performance, 'age_breakdown')

  useEffect(() => {
    if (age_breakdown) {
      const _data = _cloneDeep(initialData)

      _forEach(age_breakdown, (t) => {
        const age = parseInt(t.age)
        const number = parseInt(t.number)
        _data.total += number
        if(age <= 17){
          _data.datum[0][1] += number
        }else if(age<=20){
          _data.datum[1][1] += number
        }else if(age<=25){
          _data.datum[2][1] += number
        }else if(age<=30){
          _data.datum[3][1] += number
        }else if(age<=40){
          _data.datum[4][1] += number
        }else{
          _data.datum[5][1] += number
        }
      })

      _forEach(_data.datum, (t, index) => {
        if(_data.max < t[1]){
          _data.max = t[1]
          _data.highest = index
        }
        if(_data.min > t[1]){
          _data.min = t[1]
          _data.lowest = index
        }
        t[2] = parseInt(Math.round(t[1] / (_data.total === 0 ? 1 : _data.total) * 100))
      })

      setData(_data)

    }
  }, [performance])


  let content = null

  if (loading) {
    content = <LoadingBar />
  } else {
    content = (
      <div className="row age-range">
        {
          _map(datum, (t, index) => (
            <div key={index} className={`col-xs-12 col-sm-2 range-${index}`}>
              <div className="range-info-decoration"/>
              <div className={`age-range-circle`}>
                <ProgressCircle key={index} value={t[2]}/>
              </div>
              <div className="range-info-bg"/>
              <div className="range-info text-center">
                <div className="ticket-count">
                  {t[1].toLocaleString('en-US')}
                </div>
                <hr/>
                <div className="age-group">
                  {t[0]}
                </div>
              </div>
              {index === 0 &&
                <div className="range-info-note">
                  <div className="ticket-count">
                    TICKET<br/>BUYERS
                  </div>
                  <div className="age-group">
                    AGE<br/>GROUPS
                  </div>
                </div>
              }
            </div>
          ))
        }
        <div className="age-range-sumup">
          <div className="age-range-sumup-panel col-12 row">
            <div className="col-xs-12 col-sm-4 total-sold text-center">
              <div className="description1">
                <div className="icon">
                  <img src={asset('/resources/images/system_icons/ticket.svg')}/>
                </div>
                <div className="number">
                  {total.toLocaleString('en-US')}
                </div>
              </div>
              <div className="description2">
                TOTAL TICKET BUYERS
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 highest-group text-center">
              <div className="description1">
                <div className="icon">
                  <img src={asset('/resources/images/demographics-highest.svg')}/>
                </div>
                <div className="number">
                  {datum[highest][0]}
                </div>
              </div>
              <div className="description2">
                HIGHEST AGE GROUP
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 lowest-group text-center">
              <div className="description1">
                <div className="icon">
                  <img src={asset('/resources/images/demographics-lowest.svg')}/>
                </div>
                <div className="number">
                  {datum[lowest][0]}
                </div>
              </div>
              <div className="description2">
                LOWEST AGE GROUP
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card icon={'fa-dot-circle-o'} title={'Age Ranges'}>
      <div className="card-block-title">
        <div className="highlight">
          Tickets Buyers by<strong className="left-space">AGE RANGE</strong>
        </div>
      </div>
      {content}
    </Card>
  )
})

export { AgeRanges }
