
import React from 'react'
import TagsField from '../../../_library/TagsField'

export default class TagsRow extends React.Component {
  updateField = (fieldKey, value) => {
    this.props.updateField(fieldKey, value)
  }

  handleChangeTags = tags => {
    this.updateField('tags', tags)
  }

  render() {
    const { values, typeName } = this.props
    const isTable = typeName === 'table'
    return (
      <div className="row">
        <div className="col-xs-12" style={{ paddingBottom: '5px' }}>
          {`Enter tags that describe this ${isTable ? 'table' : 'ticket'} type and others like it e.g. 1-Day, Early Bird, VIP, Comp`}
        </div>
        <div className="col-xs-12">
          <TagsField
            ref='tag'
            value={values.tags}
            onChange={this.handleChangeTags}
          />
        </div>
      </div>
    )
  }
}
