import React from 'react'
import _map from 'lodash/map'
import EmptyBar from '../../_library/EmptyBar'
import SortableTable from '../../_library/SortableTable'
import { fetchAPI } from '../../../_common/core/http'
import { getTableColumns } from '../../utils/sortableTableUtils'
import SearchInput from '../../_library/SearchInput'
import Chip from '../../_library/Chip'
import LoadingBar from '../../_library/LoadingBar'
import Button from '../../_library/Button'
import _startCase from 'lodash/startCase'
import { formatDate } from '../../../_common/core/validation'
import { DISPLAY_FORMAT } from '../../constants/timeFormats'

const AUTO_REFRESH = 10 * 1000

export default class PerformanceResale extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setInterval = null
    this.mounted = true
    this.state = {
      isLoading: true,
      data: [],
      search: '',
      selectedChips: [],
      dataByTicketTypeDetails: [],
      dataByTicketType: []
    }
    this.tableColumnsByTicket = getTableColumns([
      { key: 'name', label: 'Ticket Type' },
      { key: 'total', label: 'No. of Tickets' }
    ])
    this.detailsTableColumns = getTableColumns([
      { key: 'full_name', label: 'Ticket Holder', className: 'detail-cell' },
      { key: 'ticket_hash', label: 'Ticket ID', className: 'detail-cell' },
      {
        key: 'sent_for_resale_at',
        label: 'Sent For Resale At',
        className: 'detail-cell',
        normalizer: value => formatDate(value, DISPLAY_FORMAT)
      },
      { key: 'status_str', label: 'Status', className: 'detail-cell' }
    ])
  }

  componentDidMount() {
    const { event } = this.props
    const fetchData = async () => {
      try {
        const response = await fetchAPI(`/api/events/${event.id}/relationships/performance/?`, {
          params: { section: 'resale' },
          node: 'data.resale.*'
        })
        if (this.mounted) {
          const { filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails } =
            this.updateComponent(response.data.$original.resale, this.state.search, this.state.selectedChips)
          this.setState({
            isLoading: false,
            data: response.data.$original.resale,
            filteredDataBySearch,
            chipOptions,
            dataByTicketType,
            dataByTicketTypeDetails
          })
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({
            isLoading: false
          })
        }
      }
    }
    fetchData()
    this.setInterval = setInterval(() => {
      fetchData()
    }, AUTO_REFRESH)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.setInterval)
  }

  onSearch = search => {
    const { filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails } =
      this.updateComponent(this.state.data, search, this.state.selectedChips)
    this.setState({ search, filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails })
  }

  clearTags = () => {
    const { filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails } =
      this.updateComponent(this.state.data, this.state.search, [])
    this.setState({
      selectedChips: [],
      filteredDataBySearch,
      chipOptions,
      dataByTicketType,
      dataByTicketTypeDetails
    })
  }

  chunk = (arr, size) => arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), [])

  onSelectChip = selectedChip => {
    const { selectedChips } = this.state
    if (selectedChips.includes(selectedChip)) {
      const removeChips = selectedChips.filter(chip => chip !== selectedChip)
      const { filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails } =
        this.updateComponent(this.state.data, this.state.search, removeChips)
      this.setState({
        selectedChips: removeChips,
        filteredDataBySearch,
        chipOptions,
        dataByTicketType,
        dataByTicketTypeDetails
      })
    } else {
      const { filteredDataBySearch, chipOptions, dataByTicketType, dataByTicketTypeDetails } =
        this.updateComponent(this.state.data, this.state.search, [...selectedChips, selectedChip])
      this.setState({
        selectedChips: [...selectedChips, selectedChip],
        filteredDataBySearch,
        chipOptions,
        dataByTicketType,
        dataByTicketTypeDetails
      })
    }
  }

  updateComponent = (data, search, selectedChips) => {
    const searchLower = search.toLowerCase()
    const mappedData = data.map(row => ({
      ...row,
      full_name: row.first_name + ' ' + row.last_name,
      ticket_hash: row.ticket_hash.toUpperCase(),
      status_str: _startCase(row.status)
    }))
    const filteredDataByChip =
      selectedChips.length === 0 ? mappedData : mappedData.filter(row => selectedChips.includes(row.status))
    const filteredDataBySearch = filteredDataByChip.filter(
      row =>
        row.full_name.toLowerCase().includes(searchLower) ||
        row.ticket_hash.toLowerCase().includes(searchLower) ||
        row.ticket_type.toLowerCase().includes(searchLower)
    )
    const chipOptions = data.reduce((prev, next) => {
      if (!prev.includes(next.status)) {
        prev.push(next.status)
      }
      return prev
    }, [])
    const dataByTicketType = filteredDataBySearch.reduce((prev, next) => {
      const groupExists = prev.findIndex(row => row.name === next.ticket_type)
      if (groupExists !== -1) {
        prev[groupExists].resales.push(next)
        prev[groupExists].total += 1
      } else {
        prev.push({ name: next.ticket_type, resales: [next], total: 1, id: next.id })
      }
      return prev
    }, [])
    const dataByTicketTypeDetails = dataByTicketType.map(row => ({
      id: row.id,
      type: 'detailRow',
      component: ({ detailRowIndex }) => (
        <SortableTable
          e2e_test_id={`ticket_resale-${detailRowIndex}`}
          data={row.resales}
          tableColumns={this.detailsTableColumns}
          enableCopyTable={true}
          enableSort={false}
          disableMobileView={true}
          className="child-table"
        />
      )
    }))
    return {
      filteredDataBySearch,
      chipOptions,
      dataByTicketType,
      dataByTicketTypeDetails
    }
  }

  render() {
    const {
      data,
      search,
      selectedChips,
      isLoading,
      filteredDataBySearch,
      chipOptions,
      dataByTicketType,
      dataByTicketTypeDetails
    } = this.state
    return (
      <div className="">
        <div className="table-caption margin-btm-15">
          <img
            alt="nodata"
            className="section-main-heading"
            src={asset('/resources/images/event/performance/tickets-resale-ico.svg')}
          />
          Ticket Resale
        </div>
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your resales..."} />
          ) : data.length === 0 ? (
            <EmptyBar />
          ) : (
            <>
              <div className="searchbar-resale-box">
                {(search || selectedChips.length !== 0) && (
                  <div className="number-of-matching-tickets">
                    Number of Matching Tickets: {filteredDataBySearch.length}
                  </div>
                )}
                <div className="datatable-searchbar">
                  <SearchInput onChange={this.onSearch} />
                </div>
              </div>
              <div className="div-spacing-10" />
              <div className="tag-filter-label-resale-box">
                <div className="tag-filter-label tag-filter-label-resale">
                  <i className="fa fa-tags fa-tags-resale" />
                  {'Filter by Resale Status'}
                </div>
                <div className="chip-box">
                  {_map(chipOptions, (item, i) => (
                    <Chip
                      key={item}
                      value={item}
                      style={{ marginLeft: i ? 10 : 0 }}
                      isSelected={selectedChips.includes(item)}
                      disableClear={true}
                      onSelectChip={this.onSelectChip}
                    />
                  ))}
                  {selectedChips.length !== 0 && (
                    <Button
                      className="btn btn-default btn-shadow tags-clear btn-resale"
                      type="button"
                      onClick={this.clearTags}
                    >
                      Clear Filter
                    </Button>
                  )}
                </div>
              </div>
              <SortableTable
                e2e_test_id="ticket_resale"
                data={dataByTicketType}
                tableColumns={this.tableColumnsByTicket}
                enableCopyTable={true}
                enableSort={true}
                detailsRows={dataByTicketTypeDetails}
                disableMobileView={true}
                footbar={{
                  label: 'Total:',
                  columns: ['total']
                }}
              />
            </>
          )}
        </div>
      </div>
    )
  }
}
