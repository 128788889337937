import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _reduce from 'lodash/reduce'
import _startCase from 'lodash/startCase'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns, makeRunningTotalLabel } from '../../../utils/sortableTableUtils'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
} from '../../../../_common/core/validation/normalizers'

export default class SalesByAddOn extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns(
      [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'num_sales',
          label: 'No. of Sales',
        },
        {
          key: 'total_num_sales',
          label: makeRunningTotalLabel('No. of Sales').value,
          labelComponent: makeRunningTotalLabel('No. of Sales').component,
          isSortable: false,
        },
        {
          key: 'cost',
          label: 'Price (excl. Fees)',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'revenue',
          label: 'Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'total_revenue',
          label: makeRunningTotalLabel('Revenue').value,
          labelComponent: makeRunningTotalLabel('Revenue').component,
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          isSortable: true,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
            ),
        },
        {
          key: 'grossRevenueTotal',
          label: makeRunningTotalLabel('Gross Revenue').value,
          labelComponent: makeRunningTotalLabel('Gross Revenue').component,
          isSortable: false,
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value)),
            ),
        },
      ],
      getCurrencySymbol(props.event),
    )

    this.detailsTableColumns = getTableColumns([
      {
        key: 'orderDate',
        label: 'Date',
        className: 'detail-cell',
        normalizer: formatDay,
      },
      {
        key: 'num_sales',
        label: 'No. of Sales',
        className: 'detail-cell',
      },
      {
        key: 'cost',
        label: 'Price (excl. Fees)',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
      {
        key: 'revenue',
        label: 'Revenue',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
      {
        key: 'grossRevenue',
        label: 'Gross Revenue',
        className: 'detail-cell',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) || '0'),
          ),
      },
    ])

    this.footbarColumns = [
      {
        key: 'total_num_sales',
        sourceKey: 'num_sales',
      },
      {
        key: 'total_revenue',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'grossRevenueTotal',
        sourceKey: 'grossRevenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
    ]

    this.state = {
      sortBy: { column: 'name', asc: true },
      rows: null,
      detailsRows: [],
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, tickets, showGroups } = this.props
    if (
      !_isEqual(data, prevProps.data) ||
      !_isEqual(tickets, prevProps.tickets) ||
      !_isEqual(showGroups, prevProps.showGroups)
    ) {
      this.updateData()
    }
  }

  updateData = () => {
    const { data, groupedByAddOn } = this.props
    const dataGroupedByGroupName = _groupBy(data, 'group')

    const groupedRows = {}
    const detailsRows = []
    const tempRows = []

    _map(dataGroupedByGroupName, (groupItem, groupKey) => {
      const currentGroupData = _filter(data, item => item.group === groupKey)
      const groupedTempRows = []
      const groupedDetailsRows = []
      this.getRows(
        groupItem,
        _groupBy(
          _map(currentGroupData, item => ({ ...item, id: item.name + parseFloat(item.cost) })),
          'id',
        ),
        groupedTempRows,
        groupedDetailsRows,
      )
      groupedRows[groupKey] = { data: groupedTempRows, detailsRows: groupedDetailsRows }
    })

    this.getRows(data, groupedByAddOn, tempRows, detailsRows)

    this.setState({
      rows: _filter(tempRows, rowItem => !!rowItem.id),
      detailsRows,
      groupedRows,
    })
  }

  getRows = (data, groupedData, tempRows, detailsRows) => {
    const { hideDateBreakdowns } = this.props
    _map(groupedData, (value, key) => {
      const totalNumSales = _reduce(value, (sum, n) => sum + parseInt(n.num_sales), 0)
      const totalRevenue = _reduce(
        value,
        (sum, n) => sum + (parseInt(n.num_sales) * parseFloat(n.cost) - parseFloat(n.refunded_amount)),
        0,
      )
      const grossRevenue = _reduce(
        value,
        (sum, n) => sum + parseInt(n.num_sales) * parseFloat(n.price) - parseFloat(n.refunded_amount),
        0,
      )
      !hideDateBreakdowns && this.getDetailRows(value, key, detailsRows)

      const updatedObj = {
        id: key,
        name: value[0].name,
        cost: value[0].cost,
        num_sales: totalNumSales,
        revenue: totalRevenue,
        grossRevenue,
      }

      tempRows.push(updatedObj)
    })
  }

  getDetailRows = (data, groupId, detailsRows) => {
    let totalSales = 0
    let totalRevenue = 0
    const runingTotalByDate = 0
    const detailRows = []

    _map(_sortBy(data, 'orderDate'), item => {
      const id = item.name + parseFloat(item.cost)
      if (id === groupId) {
        totalSales += parseInt(item.num_sales)
        totalRevenue += parseFloat(item.num_sales) * parseFloat(item.cost)

        detailRows.push({
          id,
          orderDate: item.orderDate,
          num_sales: item.num_sales,
          cost: item.cost,
          revenue: parseInt(item.num_sales) * parseFloat(item.cost) - parseFloat(item.refunded_amount),
          grossRevenue: parseInt(item.num_sales) * parseFloat(item.price) - parseFloat(item.refunded_amount),
        })
      }
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].id,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`sales_by_add_ons-${detailRowIndex}`}
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        ),
      })
  }

  render() {
    const { showGroups } = this.props
    const { rows, sortBy, detailsRows, groupedRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          showGroups ? (
            <div>
              {_map(groupedRows, (value, key) => (
                <div key={key}>
                  <h4 className="inline group-title">{_startCase(key)}</h4>
                  <SortableTable
                    e2e_test_id="sales_by_add_ons"
                    data={value.data}
                    tableColumns={this.tableColumns}
                    enableSort={true}
                    enableCopyTable={true}
                    disableMobileView={true}
                    sortBy={sortBy}
                    detailsRows={value.detailsRows}
                    calculatedColumns={[
                      { for: 'total_num_sales', column: 'num_sales' },
                      { for: 'total_revenue', column: 'revenue' },
                      { for: 'grossRevenueTotal', column: 'grossRevenue' },
                    ]}
                    footbar={{
                      label: 'Total',
                      columns: this.footbarColumns,
                    }}
                  />
                  <div className="div-spacing-30" />
                </div>
              ))}
            </div>
          ) : (
            <SortableTable
              e2e_test_id="sales_by_add_ons"
              data={rows}
              tableColumns={this.tableColumns}
              enableSort={true}
              enableCopyTable={true}
              disableMobileView={true}
              sortBy={sortBy}
              detailsRows={detailsRows}
              calculatedColumns={[
                { for: 'total_num_sales', column: 'num_sales' },
                { for: 'total_revenue', column: 'revenue' },
                { for: 'grossRevenueTotal', column: 'grossRevenue' },
              ]}
              footbar={{
                label: 'Total',
                columns: this.footbarColumns,
              }}
            />
          )
        ) : null}
      </div>
    )
  }
}
