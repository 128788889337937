/* eslint-disable react/no-multi-comp */
import React from 'react'
import { connect } from 'react-redux'
import _result from 'lodash/result'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _set from 'lodash/set'
import _isArray from 'lodash/isArray'
import Field from '../_library/Field'
import Button from '../_library/Button'
import { withRouter } from 'react-router-dom'
import {
  SEARCH_TALENT,
  GET_EVENT_TALENT,
  GET_TALENT,
  ASSIGN_TALENT,
  REASSIGN_TALENT,
  UNASSIGN_TALENT
} from '../../_common/redux/talent/actions'
import { JSONDatatable, DATATABLE, PAGINATIONBAR, TYPE_FROM_ARRAY } from '../_library/JSONDatatable'
import EmptyBar from '../_library/EmptyBar'
import LoadingBar from '../_library/LoadingBar'
import LazyLoad from 'react-lazyload'
import { withFormik } from 'formik'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { get_event, get_talent_search, get_talent_event } from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const searchTalent = get_talent_search(state)
    const eventTalent = get_talent_event(state)

    return {
      event,
      searchTalent,
      eventTalent,
    }
  },
  { SEARCH_TALENT, GET_EVENT_TALENT, GET_TALENT, ASSIGN_TALENT, REASSIGN_TALENT, UNASSIGN_TALENT }
)
export default class EventTalent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searching: false,
      loading: false,
      eventTalentFull: []
    }

    this.reassignTalentToEvent = this.reassignTalentToEvent.bind(this)
    this.unassignTalentToEvent = this.unassignTalentToEvent.bind(this)
    this.searchTalent = this.searchTalent.bind(this)
    this.assignTalentToEvent = this.assignTalentToEvent.bind(this)
  }

  componentDidMount() {
    const { event: { displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }
    this.loadEventTalent()
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  async loadEventTalent() {
    try {
      const { event, GET_EVENT_TALENT, GET_TALENT } = this.props
      !this.isUnmounted && this.setState(() => ({ loading: true, eventTalentFull: [] }))
      await GET_EVENT_TALENT(event.id)
      const eventTalent = this.props.eventTalent || []
      const results = await Promise.all(
        eventTalent.map(async talent => {
          const res = await GET_TALENT(talent.talentId)
          return res.result
        })
      )
      const new_results = results.map(r => {
        const ee = eventTalent.find(ev => ev.talentId === r.objectID)
        r.isPublic = ee.isPublic === '1'
        return r
      })
      !this.isUnmounted && this.setState({ eventTalentFull: new_results, loading: false })
    } catch (e) {
      !this.isUnmounted && this.setState(() => ({ loading: false }))
    }
  }

  async searchTalent(payload) {
    const { SEARCH_TALENT } = this.props
    try {
      this.setState({ searching: true })
      await SEARCH_TALENT(payload.query, payload.type)
      this.setState({ searching: false })
    } catch (e) {
      this.setState({ searching: false })
    }
  }

  async assignTalentToEvent(talent, isPublic) {
    try {
      const { event, ASSIGN_TALENT, GET_EVENT_TALENT, GET_TALENT } = this.props
      const form = {
        attributes: {
          talentId: talent.objectID,
          isPublic
        }
      }
      await ASSIGN_TALENT(event.id, form)
      Messenger().post({
        type: 'success',
        message: 'Assigned',
        hideAfter: 3,
        showCloseButton: true
      })

      GET_EVENT_TALENT(event.id)
      const { eventTalentFull } = this.state
      const new_eventTalentFull = [...eventTalentFull]
      const res = await GET_TALENT(talent.objectID)
      const rs = res.result
      rs.isPublic = isPublic
      new_eventTalentFull.push(rs)
      this.setState({ eventTalentFull: new_eventTalentFull })
    } catch (err) {
      throw _result(err, 'toFieldErrors', err)
    }
  }

  async reassignTalentToEvent(talent, isPublic) {
    try {
      const { event, REASSIGN_TALENT, GET_EVENT_TALENT, GET_TALENT } = this.props
      const form = {
        attributes: {
          talentId: talent.objectID,
          isPublic
        }
      }
      await REASSIGN_TALENT(event.id, form)
      await GET_EVENT_TALENT(event.id)
      await GET_TALENT(talent.objectID)
      const { eventTalentFull } = this.state
      const new_eventTalentFull = [...eventTalentFull]
      const ix = new_eventTalentFull.findIndex(ev => ev.objectID === talent.objectID)
      new_eventTalentFull[ix].isPublic = isPublic
      this.setState({ eventTalentFull: new_eventTalentFull })
      Messenger().post({
        type: 'success',
        message: 'Reassigned',
        hideAfter: 3,
        showCloseButton: true
      })
    } catch (err) {
      Messenger().post({
        type: 'error',
        message: err,
        hideAfter: 3,
        showCloseButton: true
      })
      throw _result(err, 'toFieldErrors', err)
    }
  }

  async unassignTalentToEvent(talent) {
    try {
      const { event, UNASSIGN_TALENT, GET_EVENT_TALENT } = this.props
      const form = {
        attributes: {
          talentId: talent.objectID
        }
      }
      await UNASSIGN_TALENT(event.id, form)
      GET_EVENT_TALENT(event.id)
      const { eventTalentFull } = this.state
      const new_eventTalentFull = eventTalentFull.filter(a => a.objectID !== talent.objectID)
      this.setState({ eventTalentFull: new_eventTalentFull })
      Messenger().post({
        type: 'success',
        message: 'Unassigned',
        hideAfter: 3,
        showCloseButton: true
      })
    } catch (err) {
      Messenger().post({
        type: 'error',
        message: err,
        hideAfter: 3,
        showCloseButton: true
      })
      throw _result(err, 'toFieldErrors', err)
    }
  }

  render() {
    const { event, searchTalent, eventTalent } = this.props
    const { searching, loading, eventTalentFull } = this.state

    return (
      <DisableSectionByPermissionWrapper>
        <div className="EventTalent">
          <h3 className="heading_style">Talent</h3>
          <TalentTable
            event={event}
            eventTalent={eventTalentFull}
            loading={loading}
            reassignTalentToEvent={this.reassignTalentToEvent}
            unassignTalentToEvent={this.unassignTalentToEvent}
          />
          <h3 className="heading_style">Add Talent</h3>
          <TalentSearchForm searching={searching} onSubmit={this.searchTalent} />
          <TalentSearchResults
            event={event}
            searchTalent={searchTalent.result || []}
            eventTalent={eventTalent || []}
            searching={searching}
            assignTalentToEvent={this.assignTalentToEvent}
          />
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

class TalentTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assigning: false
    }

    this.getTableData = this.getTableData.bind(this)
  }

  async reassign(talent, isPublic) {
    this.setState({
      assigning: true
    })
    if (this.props.reassignTalentToEvent) {
      try {
        await this.props.reassignTalentToEvent(talent, isPublic)
        this.setState({
          assigning: false
        })
      } catch (err) {
        this.setState({
          assigning: false
        })
      }
    }
  }

  async unassign(talent) {
    this.setState({
      assigning: true
    })
    if (this.props.unassignTalentToEvent) {
      try {
        await this.props.unassignTalentToEvent(talent)
        this.setState({
          assigning: false
        })
      } catch (err) {
        this.setState({
          assigning: false
        })
      }
    }
  }

  getTableData(datatable, rows_filtered, sort) {
    const { assigning } = this.state

    const talent = rows_filtered.map(talent => (
      <div className="talent-details" key={talent.objectID}>
        <div className="talent-details-left">
          <div className="talent-name">{talent.name}</div>
          {talent.genres && talent.genres.length > 0 && (
            <div className="talent-genres">
              <div className="talent-genres-label">Genres:</div>
              {talent.genres.join(', ')}
            </div>
          )}
          {talent.aliases && talent.aliases.length > 0 && (
            <div className="talent-aliases">
              <div className="talent-aliases-label">Aliases:</div>
              {talent.aliases.join(', ')}
            </div>
          )}
          {talent.about && <div className="talent-bio">{talent.about}</div>}
          {talent.relations && talent.relations.length > 0 && (
            <div className="talent-relations">
              {talent.relations.map((r, i) => (
                <div className="talent-relation" key={i}>
                  <i
                    className={'talent-relation-icon fa ' + (faIconNames[r.name] || 'fa-share-square')}
                    aria-hidden="true"
                  />
                  <a className="talent-relation-url" href={r.url} target="_blank" rel="noreferrer">
                    {r.url}
                  </a>
                </div>
              ))}
            </div>
          )}
          <Button
            className="btn btn-danger talent-unassign"
            onClick={e => this.unassign(talent)}
            disabled={assigning}
          >
            Unassign
          </Button>
          {talent.isPublic && (
            <Button
              className="btn btn-primary talent-assign"
              onClick={e => this.reassign(talent, false)}
              disabled={assigning}
            >
              Make Unannounced
            </Button>
          )}
          {!talent.isPublic && (
            <Button
              className="btn btn-primary talent-assign"
              onClick={e => this.reassign(talent, true)}
              disabled={assigning}
            >
              Make Announced
            </Button>
          )}
        </div>
        <div className="talent-details-right">
          {talent.pictures && talent.pictures.profile && talent.pictures.profile.smallUrl && (
            <LazyLoad width={120} height={120} once>
              <img className="talent-avatar" src={talent.pictures.profile.smallUrl} alt="No Data" />
            </LazyLoad>
          )}
        </div>
      </div>
    ))

    return <div className="talent-list">{talent}</div>
  }

  render() {
    const { loading } = this.props
    const eventTalent = this.props.eventTalent || []

    let content
    if (loading) {
      content = <LoadingBar title={"Hold tight! We're loading assigned talent..."} />
    } else if (eventTalent.length) {
      content = (
        <JSONDatatable
          ref="JSONDatatable"
          type={TYPE_FROM_ARRAY}
          data={eventTalent}
          getTableData={this.getTableData}
          usePagination={true}
          paginationPageSize={5}
          loadingBarTitle={"Hold tight! We're loading assigned talent..."}
          saveSearchKey={'TalentPage'}
        >
          <div ref={DATATABLE} />
          <div ref={PAGINATIONBAR} />
        </JSONDatatable>
      )
    } else {
      content = <EmptyBar content={'There is no talent assigned.'} />
    }

    return <div className="talent-table">{content}</div>
  }
}

function validateTalentSearch(data) {
  const required = ['query', 'type']
  const errors = {}
  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })
  return errors
}

class MyForm extends React.Component {
  changeType = e => {
    const value = e.target.name
    const { setFieldValue } = this.props
    setFieldValue('type', value)
  }

  formSubmitHandler = e => {
    e.preventDefault()
    this.props.handleSubmit(e)
  }

  render() {
    const { values, touched, errors, handleChange, isSubmitting } = this.props

    return (
      <form onSubmit={this.formSubmitHandler} className="talent-search-form">
        <div className="row">
          <div className="col-sm-12">
            <Field
              id="query"
              label="Query"
              value={values.query}
              onChange={handleChange}
              error={errors.query}
              touched={touched.query}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="talent-search-form-type-radio">
              <input
                type="radio"
                id="talentSearchTypeArtist"
                name="artist"
                onChange={this.changeType}
                checked={values.type === 'artist'}
              />
              <label htmlFor="talentSearchTypeArtist">Artist</label>
            </div>
            <div className="talent-search-form-type-radio">
              <input
                type="radio"
                id="talentSearchTypeStreamer"
                name="streamer"
                onChange={this.changeType}
                checked={values.type === 'streamer'}
              />
              <label htmlFor="talentSearchTypeStreamer">Streamer</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Button
              type="submit"
              className="btn btn-primary btn-search"
              disabled={isSubmitting}
            >
              <i className="fa fa-search" />
              Search
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

const TalentSearchForm = withFormik({
  mapPropsToValues: props => ({
    query: _get(props, 'initialValues.attributes.query') || '',
    type: _get(props, 'initialValues.attributes.type') || 'artist'
  }),

  // Custom sync validation
  validate: values => validateTalentSearch(values),

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props
      .onSubmit({
        ...values
      })
      .then(() => {
        resetForm()
        setSubmitting(false)
      })
      .catch(() => {
        resetForm()
        setSubmitting(false)
      })
  },
  displayName: 'TalentSearchForm' // helps with React DevTools
})(MyForm)

const faIconNames = {
  facebook: 'fa-facebook-official',
  twitter: 'fa-twitter',
  instagram: 'fa-instagram',
  wikipedia: 'fa-wikipedia-w',
  imdb: 'fa-imdb',
  mixcloud: 'fa-mixcloud'
}

class TalentSearchResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assigning: false
    }

    this.getTableData = this.getTableData.bind(this)
  }

  async assign(talent, isPublic) {
    this.setState({
      assigning: true
    })
    if (this.props.assignTalentToEvent) {
      try {
        await this.props.assignTalentToEvent(talent, isPublic)
        this.setState({
          assigning: false
        })
      } catch (e) {
        this.setState({
          assigning: false
        })
      }
    }
  }

  getTableData(datatable, rows_filtered) {
    const { assigning } = this.state
    const { eventTalent } = this.props

    const talent = rows_filtered.map(talent => {
      const assigned = _find(eventTalent, ea => ea.talentId === talent.objectID)
      return (
        <div className="talent-details" key={talent.objectID}>
          <div className="talent-details-left">
            <div className="talent-name">{talent.name}</div>
            {talent.genres && talent.genres.length > 0 && (
              <div className="talent-genres">
                <div className="talent-genres-label">Genres:</div>
                {talent.genres.join(', ')}
              </div>
            )}
            {talent.aliases && talent.aliases.length > 0 && (
              <div className="talent-aliases">
                <div className="talent-aliases-label">Aliases:</div>
                {talent.aliases.join(', ')}
              </div>
            )}
            {talent.about && <div className="talent-bio">{talent.about}</div>}
            {talent.relations && talent.relations.length > 0 && (
              <div className="talent-relations">
                {talent.relations.map((r, i) => (
                  <div className="talent-relation" key={i}>
                    <i
                      className={'talent-relation-icon fa ' + (faIconNames[r.name] || 'fa-share-square')}
                      aria-hidden="true"
                    />
                    <a className="talent-relation-url" href={r.url} target="_blank" rel="noreferrer">
                      {r.url}
                    </a>
                  </div>
                ))}
              </div>
            )}
            {!assigned && (
              <div className="talent-buttons">
                <Button
                  className="btn btn-primary talent-assign"
                  onClick={e => this.assign(talent, true)}
                  disabled={assigning}
                >
                  Assign as Announced
                </Button>
                <Button
                  className="btn btn-primary talent-assign"
                  onClick={e => this.assign(talent, false)}
                  disabled={assigning}
                >
                  Assign as Unannounced
                </Button>
              </div>
            )}
          </div>
          <div className="talent-details-right">
            {talent.pictures && talent.pictures.profile && talent.pictures.profile.smallUrl && (
              <LazyLoad width={120} height={120} once>
                <img className="talent-avatar" src={talent.pictures.profile.smallUrl} alt="No Data" />
              </LazyLoad>
            )}
          </div>
        </div>
      )
    })

    return <div className="talent-list">{talent}</div>
  }

  render() {
    const { searching } = this.props
    const searchTalent = this.props.searchTalent || []
    const isResultArray = _isArray(searchTalent)

    let content
    if (searching) {
      content = <LoadingBar title={"Hold tight! We're searching for talent..."} />
    } else if (searchTalent.length) {
      content = (
        <JSONDatatable
          ref="JSONDatatable"
          type={TYPE_FROM_ARRAY}
          data={searchTalent}
          getTableData={this.getTableData}
          usePagination={true}
          paginationPageSize={10}
          loadingBarTitle={"Hold tight! We're searching for talent..."}
          saveSearchKey={'TalentPage'}
        >
          <div ref={DATATABLE} />
          <div ref={PAGINATIONBAR} />
        </JSONDatatable>
      )
    } else if (!isResultArray) {
      content = <EmptyBar content={'We could not find result in 30 seconds, please try again later.'} />
    }

    return <div className="talent-table">{content}</div>
  }
}
