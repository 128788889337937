import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  influencers: {},
  eid: '',
}

const FETCH_EVENT_INFLUENCERS = createAsyncHandlers('FETCH_EVENT_INFLUENCERS', {
  success(state, action) {
    const {
      eid,
      influencers: { data },
    } = action.payload
    state.influencers = _get(data, '$original')
    state.eid = eid
  },
})

export default handleActions(
  {
    ...FETCH_EVENT_INFLUENCERS,
  },
  initialState,
)
