
import ProgressBar from 'react-bootstrap/lib/ProgressBar'
import _isEmpty from 'lodash/isEmpty'

const Statuses = (props) => {
    const {statuses} = props
    if (_isEmpty(statuses)) {
        return null
    }
    return (
        <div className='nft-statuses'>
            <h4>Broadcasting</h4>
            {statuses?.['token-metadata-update'] ? (
                <div className='nft-statuses-item'>
                    <span>Token Details Update</span>
                    <div><ProgressBar active bsStyle="warning" now={60} /></div>
                </div>
            ) : null}
        </div>
    )
}

export {Statuses}