import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  passwords: {},
}

const FETCH_PASSWORDS = createAsyncHandlers('FETCH_PASSWORDS', {
  success(state, action) {
    const {
      passwords: { data },
    } = action.payload
    state.passwords = _get(data, '$original')
  },
})

export default handleActions(
  {
    ...FETCH_PASSWORDS,
  },
  initialState,
)
