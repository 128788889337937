import _get from 'lodash/get'
import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'

import { UPDATE_BRAND, FETCH_BRAND } from '../../_common/redux/brands/actions'
import {
  SHOPIFY_CHECK_CONNECT,
  SHOPIFY_CONNECT,
  SHOPIFY_DISCONNECT,
  SHOPIFY_UPDATE,
  SHOPIFY_REFRESH,
} from '../../_common/redux/shopify/actions'
import BrandForm from './BrandForm'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const brandShopifyInfo = state.shopify.brand
    const shopifyConnectLoading = state.loading.has('SHOPIFY_CONNECT')
    const shopifyDisconnectLoading = state.loading.has('SHOPIFY_DISCONNECT')
    const shopifyRefreshLoading = state.loading.has('SHOPIFY_REFRESH')
    const fetchProductsLoading = state.loading.has('FETCH_PRODUCTS')

    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
    const brand = state.brands.selected
    const { isRnDomain } = state.shared
    const { user } = state.auth
    return {
      user,
      brand,
      isRnDomain,
      brandShopifyInfo,
      dynamicConfigs,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
    }
  },
  {
    UPDATE_BRAND,
    FETCH_BRAND,
    SHOPIFY_CHECK_CONNECT,
    SHOPIFY_CONNECT,
    SHOPIFY_DISCONNECT,
    SHOPIFY_UPDATE,
    SHOPIFY_REFRESH,
  },
)
export default class BrandDetails extends React.PureComponent {
  componentDidMount() {
    const {
      brand: { displayName },
      match: {
        params: { id },
      },
      SHOPIFY_CHECK_CONNECT,
      configs,
    } = this.props

    SHOPIFY_CHECK_CONNECT(id, 'brand')
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  shopifyConnectSubmit = url => {
    const {
      match: {
        params: { id },
      },
      SHOPIFY_CONNECT,
    } = this.props
    SHOPIFY_CONNECT(id, 'brand', url)
  }
  onDisconnectShopify = () => {
    const {
      match: {
        params: { id },
      },
      SHOPIFY_DISCONNECT,
    } = this.props
    SHOPIFY_DISCONNECT(id, 'brand')
  }
  onSopifyUpdate = newAddedIds => {
    const {
      match: {
        params: { id },
      },
      SHOPIFY_UPDATE,
    } = this.props
    return SHOPIFY_UPDATE(id, 'brand', newAddedIds)
  }
  onSopifyRefresh = () => {
    const {
      match: {
        params: { id },
      },
      SHOPIFY_REFRESH,
    } = this.props
    return SHOPIFY_REFRESH(id, 'brand')
  }

  handleSubmit = form => {
    const {
      brand,
      match: {
        params: { id },
      },
      UPDATE_BRAND,
    } = this.props

    if (brand.parent) {
      form.attributes.parentId = brand.parent.id
    }

    return Promise.resolve(UPDATE_BRAND(id, { ...form }))
      .catch(err =>
        Promise.reject(
          Messenger().post({
            type: 'error',
            message: err.errors[0].details,
            hideAfter: 3,
            showCloseButton: true,
          }),
        ),
      )
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Saved',
          hideAfter: 3,
          showCloseButton: true,
        })
        return v
      })
  }

  render() {
    const {
      brand,
      match: {
        params: { id },
      },
      isRnDomain,
      brandShopifyInfo,
      dynamicConfigs,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      configs,
      user,
    } = this.props

    const { parent } = brand

    return (
      <div>
        {!parent && !isRnDomain && (
          <div className="create_sub_brand">
            <Link className="btn btn-primary btn-shadow" to={`/brand/${id}/sub-brands/new`}>
              <img src={asset('/resources/images/plus-ico.svg')} className="plus-ico" alt="" /> Create
              sub-brand
            </Link>
          </div>
        )}
        <BrandForm
          user={user}
          onSubmit={this.handleSubmit}
          submitLabel="Save"
          initialValues={brand.$original}
          isRnDomain={isRnDomain}
          parent={parent}
          brand={brand}
          shopifyConnectSubmit={this.shopifyConnectSubmit}
          onDisconnectShopify={this.onDisconnectShopify}
          onSopifyUpdate={this.onSopifyUpdate}
          onSopifyRefresh={this.onSopifyRefresh}
          shopifyInfo={brandShopifyInfo}
          shopifyConnectLoading={shopifyConnectLoading}
          shopifyDisconnectLoading={shopifyDisconnectLoading}
          shopifyRefreshLoading={shopifyRefreshLoading}
          fetchProductsLoading={fetchProductsLoading}
          dynamicConfigs={dynamicConfigs}
          configs={_get(configs, 'children.BrandForm')}
        />
      </div>
    )
  }
}
