import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_AUDIENCE } = createAsyncAction('FETCH_AUDIENCE', function (eid, type, section) {
  const url = `/api/${type}s/${eid}/relationships/audience/${section}`

  return async dispatch =>
    fetchAPI(url)
      .catch(err => {
        const newErrors = err.errors.filter(
          err => err.message === 'This report is not available yet. Please try again in 24 hours',
        )
        if (newErrors.length !== 0) {
          dispatch(ERROR(...newErrors))
        }
        throw err
      })
      .then(res => {
        const audience = res
        const out = { eid, audience, type, section }
        dispatch(this.success(out))
        return res
      })
})

export { FETCH_AUDIENCE }
