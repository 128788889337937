import React, { PureComponent } from 'react'
import ChartJs from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _merge from 'lodash/merge'
import { makeId } from '../../../_common/core/utils'
import { getColors } from './utils'

// unregister ChartDataLabels plugin
ChartJs.plugins.unregister(ChartDataLabels)

// global default configs
ChartJs.defaults.global.defaultFontColor = '#EEE'
ChartJs.defaults.global.defaultFontSize = 12

ChartJs.defaults.global.elements.arc.borderWidth = 2
ChartJs.defaults.global.elements.arc.borderColor = '#30363F'
ChartJs.defaults.global.elements.arc.backgroundColor = 'rgba(0, 0, 0, 0)'

ChartJs.defaults.global.legend.labels.boxWidth = 14
ChartJs.defaults.global.legend.labels.padding = 20

const getFullConfigs = chartConfig => {
  const { type } = chartConfig
  const config = {
    options: {
      maintainAspectRatio: false,
      title: {
        fontSize: 16,
      },
    },
  }

  if (!chartConfig.options.backgroundColor) {
    const dataLength = _get(chartConfig, 'data.labels.length', 0)
    const backgroundColor = _get(chartConfig, 'data.datasets.0.backgroundColor', getColors(dataLength))
    _set(config, 'data.datasets.0.backgroundColor', backgroundColor)
  }

  // pie
  if (type === 'pie') {
    config.plugins = [ChartDataLabels]
    config.options.plugins = {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.chart.config.type === 'pie') {
            let sum = 0
            const dataArr = ctx.chart.data.datasets[0].data
            dataArr.map(data => {
              sum += data
              return data
            })
            if (chartConfig.displayPercentage) {
              const percentage = Math.round((value * 100) / sum)
              if (percentage < 6) {
                return ''
              }
              return percentage + '%'
            }
          }
          return ''
        },
        color: '#EEE',
        font: {
          size: 14,
        },
      },
    }
    config.options.legend = {
      display: false,
    }

    config.options.legendCallback = chart => {
      const text = []
      for (let i = 0; i < chart.data.labels.length; i++) {
        if (chart.data.labels[i]) {
          text.push(
            '<div class="legend">' +
              '<div style="background-color:' +
              chart.data.datasets[0].backgroundColor[i] +
              '"  class="legend-rect"></div>' +
              '<div class="legend-text">' +
              chart.data.labels[i] +
              '</div>' +
              '</div>',
          )
        }
      }
      return text.join('')
    }
  }

  // bar
  if (type === 'bar') {
    config.options.scales = {
      yAxes: [
        {
          gridLines: {
            display: true,
            color: '#43464A',
          },
          ticks: {
            beginAtZero: true,
            fontSize: 11,
          },
        },
      ],
      xAxes: [
        {
          maxBarThickness: 40,
          gridLines: {
            display: false,
          },
          ticks: {
            display: !chartConfig.hideXLabel,
            fontSize: 9,
          },
        },
      ],
    }
  }

  return _merge(config, chartConfig)
}

class Chart extends PureComponent {
  constructor(props) {
    super(props)
    this.uniqId = makeId(30)
  }

  componentDidMount() {
    const { chartConfig } = this.props

    this.drawChart(chartConfig)
  }

  componentWillReceiveProps(nextProps) {
    const { chartConfig: p_chartConfig } = this.props
    const { chartConfig: n_chartConfig } = nextProps

    if (!_isEqual(_get(p_chartConfig.data), _get(n_chartConfig.data))) {
      this.drawChart(n_chartConfig)
    }
  }

  drawChart = chartConfig => {
    const canvasEl = document.getElementById(this.uniqId).getContext('2d')
    const configs = getFullConfigs(_cloneDeep(chartConfig))

    if (this.chart) {
      this.chart.destroy()
    }

    this.chart = new ChartJs(canvasEl, configs)
    if (chartConfig.type === 'pie') {
      window.jQuery('.piechart-legends').prepend(this.chart.generateLegend())
    }
  }

  render() {
    const { width, height, chartConfig } = this.props
    return (
      <div>
        <div
          className="chart-container"
          style={{
            position: 'relative',
            margin: '20px auto',
            display: 'flex',
            justifyContent: 'center',
            width,
            height,
          }}
        >
          <canvas id={this.uniqId} />
        </div>
        {chartConfig && chartConfig.type === 'pie' && !chartConfig.hideLegend && (
          <div className="piechart-legends" />
        )}
      </div>
    )
  }
}

export { Chart }
