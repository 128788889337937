import React from 'react'
import EmptyBar from '../../../../_library/EmptyBar'

export default class ZoomResponseComponent extends React.PureComponent {
  componentWillMount() {
    const searchValue = location.search
    const urlParams = new URLSearchParams(searchValue)
    const errorMessage = urlParams.get('error_message')
    const stateValue = urlParams.get('state')

    if (errorMessage || stateValue) {
      if (errorMessage && !localStorage.getItem('errorMessage')) {
        const decodedErrorMessage = window.atob(errorMessage)
        localStorage.setItem('errorMessage', decodedErrorMessage)
      } else if (stateValue === 'accountAttached') {
        localStorage.setItem('accountAttached', true)
      }
      window.close()
    }
  }

  render() {
    return (
      <div style={{ paddingLeft: 20 }}>
        <EmptyBar />
      </div>
    )
  }
}
