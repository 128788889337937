import React, { Component } from 'react'
import Modal from 'react-modal'
import { Formik, Form, Field } from 'formik'
import _get from 'lodash/get'
import Button from '../../_library/Button'

import { SimpleField, SelectField } from '../../formik/Fields'

// validators
import {
  // field level validators
  requiredValidator,
  // normalizer
  toInt,
  combineNormalizers,
  minNormalizerCreator
} from '../../../_common/core/validation'
import modalStyle from '../../../_common/core/modalStyle'

class AddTicketModal extends Component {

  render() {
    const {
      onCloseModal,
      submitModal,
      isModalOpenedForEdit,
      isModalOpened,
      onCloseRemoveModal,
      ticketGroups,
      fieldsName,
      values,
      modalTitle
    } = this.props

    return (
      <Modal
        className="modal-dialog modal-trans modal-medium"
        contentLabel="Modal"
        style={modalStyle}
        isOpen={isModalOpened}
        onRequestClose={onCloseModal}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-medium">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{modalTitle || 'Membership Details'}</p>
              </div>
              <div>
                <div className="modal-body">
                  <Formik
                    initialValues={{
                      ticketTypeGroupId: _get(values, 'ticketTypeGroupId', ''),
                      count: _get(values, 'count', '')
                    }}
                    onSubmit={values => submitModal(values)}
                    render={({ values, isSubmitting }) => (
                      <Form autoComplete="off">
                        <div className="row">
                          <div className="col-xs-12">
                            <Field
                              label={'Ticket Type'}
                              name="ticketTypeGroupId"
                              component={SelectField}
                              options={ticketGroups}
                              validate={requiredValidator('Required')}
                            />
                          </div>
                          <div className="col-xs-12">
                            <Field
                              label={'Quantity'}
                              name="count"
                              component={SimpleField}
                              normalizer={combineNormalizers(toInt, minNormalizerCreator(1))}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="modal-footer footer-in-form">
                            <div className="col-xs-12 text-center">
                              <Button
                                className="btn btn-success btn-lg btn-shadow submit-button"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="btn btn-cancel btn-lg btn-shadow"
                                onClick={onCloseModal}
                                type="button"
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default AddTicketModal
