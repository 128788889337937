import React from 'react'
import { Chart } from '../../../_library/Chart'
import { TabView, Tab } from '../../../_library/TabView'
import EmptyBar from '../../../_library/EmptyBar'
import moment from 'moment'
import { DAY_DISPLAY_FORMAT } from '../../../constants/timeFormats'

export default React.memo(props => {
  const { traffic, time, changeTime, isConversions } = props

  const data = traffic.reduce((curr, { total, created_at }) => {
    curr.labels.push(time === 'week'
    ? moment(created_at).startOf('isoWeek').format(DAY_DISPLAY_FORMAT) +
      ' - ' +
      moment(created_at).endOf('isoWeek').format(DAY_DISPLAY_FORMAT)
    : moment(created_at).format(
        time === 'hour'
          ? DAY_DISPLAY_FORMAT + ' h:[00] a'
          : time === 'month'
          ? 'MMMM YYYY'
          : DAY_DISPLAY_FORMAT
    ))
    curr.datasets[0].data.push(total)
    return curr
  }, {
    labels: [],
    datasets: [{
      data: []
    }]
  })

  const selectedIndex = (() => {
    if (isConversions) {
      switch (time) {
        case 'day':
          return 1
        case 'week':
          return 2
        default:
          return 3
      }
    }
    switch (time) {
      case 'hour':
        return 1
      case 'day':
        return 2
      case 'week':
        return 3
      default:
        return 4
    }
  })()

  return (
    <TabView
      selectedIndex={selectedIndex}
      onSelectTab={i => {
        const newTime = (() => {
          if (isConversions) {
            switch (i) {
              case 1:
                return 'day'
              case 2:
                return 'week'
              default:
                return 'month'
            }
          }
          switch (i) {
            case 1:
              return 'hour'
            case 2:
              return 'day'
            case 3:
              return 'week'
            default:
              return 'month'
          }
        })()
        changeTime(newTime)
      }}
    >
      {[...(isConversions ? [] : ['Hourly']), 'Daily', 'Weekly', 'Monthly'].map(title => (
        <Tab
          title={
            <span>
              {title}
            </span>
          }
        >
          <br />
          {traffic.length === 0 ? (
          <EmptyBar />
          ) : (
            <Chart
              width={'100%'}
              height="400px"
              chartConfig={{
                type: 'bar',
                hideXLabel: true,
                data,
                options: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          )}
          <br />
        </Tab>
      ))}
    </TabView>
  )
})
