// Re-render Optimization 20.05.2020
import _ from 'lodash'
import { withFormik } from 'formik'
import React from 'react'
import Button from '../_library/Button'
import Field from '../_library/Field'

function validateReset(data) {
  const required = [
    'password',
    'repeatPassword',
  ]
  const errors = {}

  required.forEach((f) => {
    if (!_.get(data, f)) {
      _.set(errors, f, 'Required')
    }
  })

  if (_.get(data, 'password') !== _.get(data, 'repeatPassword')){
    _.set(errors, 'repeatPassword', 'Password doesn\'t match')
  }

  if (_.get(data, 'password') && _.get(data, 'password').length < 6) {
    _.set(errors, 'password', 'Password must be at least 6 characters')
  }
  return errors
}

class MyForm extends React.PureComponent {

  render() {
    const { error, resetPasswordResponse, tokenValid, tokenValidating } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      configs
    } = this.props
    const {assets , messages} = configs

    return (
      <form onSubmit={handleSubmit}>
        <div className="login_holder">
          <div className="login_content clearfix">
            <div className="login_card">
              <div className="login_data">
                <div className="login_logo text-center">
                  <img src={asset(assets.logo)} style={{ width:80 }}/>
                </div>
                <h2 className="heading">Reset Password</h2>
                { tokenValidating && 
                    <div className="reset-password-token-validating">
                      <i className="fa fa-fw fa-circle-o-notch fa-spin" />
                      <div>Checking Reset Password Hash...</div>
                    </div>
                }
                { !tokenValidating && tokenValid && <div className="form_holder">
                  {!!error && <div className="alert alert-danger">{error.details || error.title}</div>}
                  <Field
                    id="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    id="repeatPassword"
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm Password"
                    value={values.repeatPassword}
                    error={errors.repeatPassword}
                    touched={touched.repeatPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="text-center">
                    <Button type="submit" className="login_btn" loading={isSubmitting}>Reset</Button>
                  </div>
                  { resetPasswordResponse !== '' &&
                      <div className="reset-password-response">
                        { resetPasswordResponse }
                      </div>
                  }
                </div> }
                { !tokenValidating && !tokenValid && <div className="reset-password-token-invalid">Token is invalid</div>}
                <div className="copy_right">
                    Copyright &copy; {getCurrentYear()} {messages.copyright || <>THE<strong>TICKET</strong>FAIRY, Inc.</>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const ResetPasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', repeatPassword: '' }),

  // Custom sync validation
  validate: values => validateReset(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit({
      attributes: values,
    })
      .finally(() => {
        setSubmitting(false)
      })
  },
  displayName: 'ResetPasswordForm', // helps with React DevTools
})(MyForm)

export default ResetPasswordForm
