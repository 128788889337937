import React from 'react'
import _get from 'lodash/get'

import { getTableColumns } from '../../utils/sortableTableUtils'
import SortableTableWithPaginationHOC from '../../hoc/SortableTableWithPaginationHOC'
import SortableTableWithSearchHOC from '../../hoc/SortableTableWithSearchHOC'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDate,
} from '../../../_common/core/validation/normalizers'
import { DISPLAY_FORMAT } from '../../constants/timeFormats'

export const DEFAULT_PAGE_SIZE = 100
const SortableTableWithSearch = SortableTableWithSearchHOC()
const SortableTableWithSearchAndPagination = SortableTableWithPaginationHOC(SortableTableWithSearch)

export default class TourPerformanceByTicketType extends React.Component {
  constructor(props) {
    super(props)
    this.searchBy = ['eventName', 'ticketName']
    this.tableColumns = getTableColumns([
      { key: 'eventName', label: 'Event', sort: props.handleSortChange },
      {
        key: 'eventDate',
        label: 'Start Date',
        normalizer: value => formatDate(value, DISPLAY_FORMAT),
        sort: props.handleSortChange,
      },
      {
        key: 'venue',
        label: 'Venue',
        sort: props.handleSortChange,
        normalizer: value => (value === '' || value === null ? 'Online' : value),
      },
      { key: 'ticketName', label: 'Ticket Type', className: 'mw-160', sort: props.handleSortChange },
      {
        key: 'ticketFaceValue',
        label: 'Face Value',
        sort: props.handleSortChange,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'ticketPrice',
        label: 'Price (incl. Fees)',
        sort: props.handleSortChange,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value)),
          ),
      },
      {
        key: 'ticketsSold',
        label: 'Number of Tickets Sold',
        className: 'mw-160',
        sort: props.handleSortChange,
      },
      {
        key: 'maxInventory',
        label: 'Allocated Maximum Inventory',
        className: 'mw-160',
        sort: props.handleSortChange,
      },
      {
        key: 'percentageSold',
        label: 'Percentage Sold',
        className: 'mw-160',
        sort: props.handleSortChange,
        normalizer: value => parseFloat(Math.round(value * 100) / 100).toFixed(2) + ' %',
      },
    ])
  }

  render() {
    const {
      data,
      isLoadingPageData,
      showPageLoading,
      pagination,
      handlePageChange,
      isLoadingSearchData,
      showSearchLoading,
      getSearchedData,
      searchValue,
      showEmptyBar,
      sortBy,
    } = this.props

    return (
      <SortableTableWithSearchAndPagination
        data={data}
        tableColumns={this.tableColumns}
        enableCopyTable={true}
        enableSort={true}
        sortBy={sortBy}
        disableMobileView={true}
        // pagination props
        showPageLoading={showPageLoading}
        isLoadingPageData={isLoadingPageData}
        pageNumber={_get(pagination, 'page', 1)}
        totalRecords={_get(pagination, 'total', DEFAULT_PAGE_SIZE)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        onPageOrLimitChange={handlePageChange}
        getSearchedData={getSearchedData}
        // search props
        showSearchLoading={showSearchLoading}
        isLoadingSearchData={isLoadingSearchData}
        searchValue={searchValue}
        searchBy={this.searchBy}
        isAsyncSearch={true}
        resultsCount={_get(pagination, 'total', DEFAULT_PAGE_SIZE)}
        showEmptyBar={showEmptyBar}
      />
    )
  }
}
