import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'
import { produce } from 'immer'
import handleAction from 'redux-actions/lib/handleAction'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'
import store from '../store'
import { logCorrruptedEventList, mergeArraysBy, isValidEventObject } from '../../core/utils'
import { getConfig } from '../../../web/utils/configUtils'

const initialState = {
  initial: true,
  fullReady: false,
  isCache: false,
  total: 0,
  skip: 0,
  events: [],
  selected: null,
  ticketStats: {},
}

const FETCH_EVENTS_INCREMENTAL = createAsyncHandlers('FETCH_EVENTS_INCREMENTAL', {
  success(state, action) {
    let { data = [] } = action.payload
    const { fullReady, pagination } = action.payload
    const { total } = pagination
    const gState = store.getState()
    const userId = gState.auth.user.id
    const { isRnDomain, isMcDomain } = gState.shared
    const manaDomains = _get(getConfig(), 'global.CONSTANTS.DOMAINS')
    const externalDomain = isRnDomain || isMcDomain
    if (isRnDomain) {
      data = _filter(data, d => d.externalEventPartner === 'restlessnites')
    }
    if (isMcDomain) {
      data = _filter(data, d => manaDomains.includes(d.externalEventPartner))
    }
    state.initial = false
    state.isCache = !fullReady
    state.total = total
    if (!state.fullReady) {
      state.fullReady = fullReady
    }

    logCorrruptedEventList(userId, mergeArraysBy(state.events, data, 'id'))

    if ((data.length === 0) & !externalDomain) {
      state.events = []
    } else {
      state.events = _filter(mergeArraysBy(state.events, data, 'id'), item => !!item)
    }
  },
})

const FETCH_EVENT = createAsyncHandlers('FETCH_EVENT', {
  start(state, action) {
    const { eid } = action.payload
    state.selectedId = eid
  },
  success(state, action) {
    const { event } = action.payload
    if (event.id === state.selectedId) {
      state.selected = event
    }
  },
})

const CREATE_EVENT = createAsyncHandlers('CREATE_EVENT', {
  success(state, action) {
    const { event } = action.payload
    event.imageURL = _get(event, 'imageURLs.en')
    state.events.push(event)
    state.total++
  },
})

const DUPLICATE_EVENT = createAsyncHandlers('DUPLICATE_EVENT', {
  success(state, action) {
    const { event } = action.payload
    if (isValidEventObject(event)) {
      event.imageURL = _get(event, 'imageURLs.en')
      state.events.push(event)
    }
    state.total++
  },
})

const UPDATE_EVENT = createAsyncHandlers('UPDATE_EVENT', {
  success(state, action) {
    const { event } = action.payload

    const sid = _get(state, 'selected.id', '')
    const eid = _get(event, 'id')
    const defaultImageURL = _get(event, 'imageURLs.en') || null
    const compactEvent = {
      ...event,
      imageURL: defaultImageURL,
    }
    const eventIndex = _findIndex(state.events, e => e.id === eid)

    if (eventIndex > -1) {
      state.events[eventIndex] = compactEvent
    }
    state.isEventUpdated = true

    if (sid && sid === eid) {
      state.selected = event
    }
  },
})

const PIN_EVENT = createAsyncHandlers('PIN_EVENT', {
  success(state, action) {
    const { eventId, pinned } = action.payload
    const { events } = state
    const eventIndex = _findIndex(events, item => item.id === eventId)
    if (eventIndex >= 0) {
      events[eventIndex].pinned = pinned
      if (!pinned && events[eventIndex].defaultPinned === undefined) {
        events[eventIndex].defaultPinned = true
      }
      if (!events[eventIndex].defaultPinned) {
        events[eventIndex].defaultPinned = false
      }
      events[eventIndex].skipSort = true
    }
  },
})

const DELETE_EVENT = createAsyncHandlers('DELETE_EVENT', {
  success(state, action) {
    const { eid } = action.payload
    const eventIndex = _findIndex(state.events, e => e.id === eid)
    const eventsUpdated = state.events.filter(item => item.id !== eid)
    if (eventIndex > -1) {
      state.events = eventsUpdated
      state.total--
      state.isEventUpdated = true
    }
  },
})

const UPDATE_EVENT_STATUS = createAsyncHandlers('UPDATE_EVENT_STATUS', {
  success(state, action) {
    const { eid, status } = action.payload

    const sid = _get(state, 'selected.id', '')
    const eventIndex = _findIndex(state.events, e => e.id === eid)

    if (eventIndex > -1) {
      state.events[eventIndex].status = status
    }
    if (sid === eid) {
      state.selected.status = status
    }
    state.isEventUpdated = true
  },
})

const UPDATE_EVENT_TICKET_STATISTICS = handleAction(
  'UPDATE_EVENT_TICKET_STATISTICS',
  produce((state, action) => {
    const stats = action.payload
    const eid = stats.id
    state.ticketStats[eid] = stats
  }),
  initialState,
)

const SET_EVENTS_FROM_INDEXED_DB = handleAction(
  'SET_EVENTS_FROM_INDEXED_DB',
  produce((state, action) => {
    const { events } = action.payload
    if (state.fullReady) {
      return
    }
    state.initial = false
    state.isCache = true
    state.fullReady = true
    state.total = events.length
    state.events = events
  }),
  initialState,
)

const CLEAR_SELECTED_EVENT = handleAction(
  'CLEAR_SELECTED_EVENT',
  produce(state => {
    state.selected = null
  }),
  initialState,
)

const CLEAR_EVENTS = handleAction(
  'CLEAR_EVENTS',
  produce(state => {
    state.events = []
  }),
  initialState,
)

export default handleActions(
  {
    ...FETCH_EVENTS_INCREMENTAL,
    ...FETCH_EVENT,
    ...CREATE_EVENT,
    ...DUPLICATE_EVENT,
    ...UPDATE_EVENT,
    ...UPDATE_EVENT_STATUS,
    ...PIN_EVENT,
    ...DELETE_EVENT,
    UPDATE_EVENT_TICKET_STATISTICS,
    SET_EVENTS_FROM_INDEXED_DB,
    CLEAR_SELECTED_EVENT,
    CLEAR_EVENTS,
  },
  initialState,
)
