import React from 'react'
import { Field } from 'formik'
import { RadioGroupField } from '../../../formik/Fields'
import { PickerSingleRow } from './SaleDatesRows/PickerSingleRow'
import _map from 'lodash/map'
import _filter from 'lodash/filter'

export default class HiddenRow extends React.Component {
  setFlags = value => {
    const { hidingOptions, updateField } = this.props
    const [pickedOption] = _filter(hidingOptions, option => option.value === value)
    const { displayHidingDates } = pickedOption
    updateField('displayHidingDates', displayHidingDates)
  }
  render() {
    const { hidingOptions } = this.props
    return (
      <div className="col-sm-12" style={{ padding: 0 }}>
        <div>This ticket type should be unhidden:</div>
        <div className="div-spacing-10" />
        <Field
          name="hidingOption"
          id="hidingOption"
          classNames={{
            container: 'radio-group-container display-block',
            container_item: 'radio-group-item',
            component: ''
          }}
          groups={_map(hidingOptions, hidingOption => {
            let option = hidingOption
            if (hidingOption.displayHidingDates) {
              option = {
                component: <PickerSingleRow name="hidingOptionDate" />,
                ...hidingOption
              }
            }
            return option
          })}
          component={RadioGroupField}
          removeSpacing={true}
          onChange={this.setFlags}
        />
      </div>
    )
  }
}
