import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import {
  get_event,
} from '../../_common/core/selectors'

import {
  OboeAudience, 
  TYPE_EVENT,
  SECTION_LIKES, 
} from '../_library/OboeAudience'
import { getTitle } from '../utils/getTitle'

@connect(
  (state) => {
    const event = get_event(state)

    return {
      event,
    }
  }
)
export default class EventLikes extends React.Component {
  componentDidMount() {
    const { event: { displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }
  
  render() {
    const { event } = this.props
    return (
      <div className="audience-likes">
        <OboeAudience
          type={TYPE_EVENT}
          section={SECTION_LIKES}
          limitShowing={true}
          event={event}
          data={{
            url: `/api/events/${event.id}/relationships/audience/likes`, 
            node: 'data.audience.likes.*'
          }} 
        />
      </div>
    )
  }
}

