import React from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { formatDay } from '../../../../_common/core/validation/normalizers'
import SortableTableWithPaginationHOC from '../../../hoc/SortableTableWithPaginationHOC'
import EmptyBar from '../../../_library/EmptyBar'

const SortableTableWithPagination = SortableTableWithPaginationHOC()

export default class ByNameTable extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'date',
        label: 'Date',
        normalizer: formatDay,
        sort: this.sortByColumn,
      },
      {
        key: 'quantity',
        label: 'Quantity',
        sort: this.sortByColumn,
      },
      {
        key: 'name',
        label: 'Name',
        sort: this.sortByColumn,
      },
      {
        key: 'email',
        label: 'Email',
        sort: this.sortByColumn,
      },
      {
        key: 'points',
        label: 'Points',
        sort: this.sortByColumn,
      },
    ])

    this.footbarColumns = [{ key: 'quantity', component: null }]

    this.state = {
      sortBy: { column: 'date', asc: true },
      rows: null,
      detailsRows: [],
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupedData } = this.props
    if (!_isEqual(groupedData, prevProps.groupedData)) {
      this.updateData()
    }
  }

  updateData = () => {
    const { groupedData } = this.props
    const tempRows = []

    _map(groupedData, (value, key) => {
      const quantity = _reduce(value, (result, item) => result + parseInt(item.quantity), 0)
      tempRows.push({ ...value[0], quantity })
    })

    this.footbarColumns = [{ key: 'quantity', component: this.getTotalComponent(tempRows) }]
    const rows = _filter(_orderBy(tempRows, 'date', 'asc'), rowItem => !!rowItem.prereg_id)
    this.setState({
      rows: rows.map(pre => ({
        ...pre,
        points: Number(pre.points),
        id: pre.prereg_id,
      })),
      detailsRows: rows
        .filter(pre => pre.data_capture)
        .map(pre => ({
          id: pre.prereg_id,
          type: 'detailRow',
          component: (
            <div className="detail-row-pre-registration">
              <div className="row-ticketDataCapture">
                <div className="row-title row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <span>
                    <i className="fa fa-table" aria-hidden="true" /> Data Capture
                  </span>
                </div>
                <div className="row-content-expanded">
                  <div className="row-content row-ticket row" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className="col-xs-6">
                      <div className="content">
                        <div className="field">Field</div>
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <div className="content">
                        <div className="field">Value</div>
                      </div>
                    </div>
                  </div>
                  {pre.data_capture.map(data_capture_el => (
                    <div
                      key={data_capture_el.id}
                      className={'row-content row-ticket row ticket-gray'}
                      style={{ marginLeft: 0, marginRight: 0 }}
                    >
                      <div className="col-xs-6">
                        <div className="content">
                          <div className="value">{data_capture_el.name}</div>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="content">
                          <div className="value">{data_capture_el.value}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ),
        })),
    })
  }

  sortByColumn = column => {
    this.setState(prevState => {
      const isAsc = column.key === prevState.sortBy.column ? !prevState.sortBy.asc : prevState.sortBy.asc
      return {
        rows: _orderBy(prevState.rows, column.key, isAsc ? 'asc' : 'desc'),
        sortBy: {
          column: column.key,
          asc: isAsc,
        },
      }
    })
  }

  getTotalComponent = data => {
    const total = _reduce(data, (result, item) => result + parseInt(item.quantity), 0)

    return (
      <span>
        <strong>{total}</strong>
      </span>
    )
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state

    return (
      <div style={{ position: 'relative' }}>
        {rows && rows.length ? (
          <SortableTableWithPagination
            data={rows}
            dataForCopy={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns,
            }}
            detailsRows={detailsRows}
          />
        ) : (
          <EmptyBar />
        )}
      </div>
    )
  }
}
