import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _result from 'lodash/result'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import _merge from 'lodash/merge'
import React from 'react'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import { countryName } from '../../_common/core/countries'

import EventForm from './form/EventForm/index'
import BrandForm from '../brands/BrandForm'
import AlternativeViewForm from './form/AlternativeViewForm/index'
import { CREATE_BRAND } from '../../_common/redux/brands/actions'
import { UPDATE_EVENT } from '../../_common/redux/events/actions'
import { FETCH_BRANDS } from '../../_common/redux/brands/actions'
import { FETCH_PASSWORDS, CREATE_PASSWORD, DELETE_PASSWORD } from '../../_common/redux/passwords/actions'
import {
  FETCH_ALTERNATIVE_VIEWS,
  CREATE_ALTERNATIVE_VIEW,
  UPDATE_ALTERNATIVE_VIEW,
  DELETE_ALTERNATIVE_VIEW,
} from '../../_common/redux/alternativeview/actions'
import {
  SHOPIFY_CHECK_CONNECT,
  SHOPIFY_CONNECT,
  SHOPIFY_DISCONNECT,
  SHOPIFY_UPDATE,
  SHOPIFY_REFRESH,
} from '../../_common/redux/shopify/actions'

import { CARD_STATUS_UNCHANGED, CARD_STATUS_SAVED } from '../../_common/core/form_helper'
import {
  get_rn_event_metadata,
  get_event,
  get_brands,
  get_event_alternative_views,
} from '../../_common/core/selectors'

import OnlineEventForm from './form/OnlineEventForm'
import { getEdittedFields } from '../utils/eventEdittedFields'
import { showAxiosError, showSuccessMessage } from '../utils/messenger'
import { changeBodyOverflow } from '../utils/changeBodyOverflow'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => {
    const { user } = state.auth
    const brands = get_brands(state)
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
    const passwords = _get(state.passwords, 'passwords.passwords')
    const event = get_event(state)
    const { isRnDomain } = state.shared
    const alternativeViews = get_event_alternative_views(state)
    const eventShopifyInfo = state.shopify.event
    const shopifyConnectLoading = state.loading.has('SHOPIFY_CONNECT')
    const shopifyDisconnectLoading = state.loading.has('SHOPIFY_DISCONNECT')
    const shopifyRefreshLoading = state.loading.has('SHOPIFY_REFRESH')
    const fetchProductsLoading = state.loading.has('FETCH_PRODUCTS')
    const rnEventMetadataObj = get_rn_event_metadata(state)
    const globalCountries = state.shared.countries

    return {
      brands,
      user,
      event,
      passwords,
      isRnDomain,
      alternativeViews,
      dynamicConfigs,
      eventShopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      rnEventMetadataObj,
      globalCountries,
    }
  },
  {
    UPDATE_EVENT,
    CREATE_BRAND,
    FETCH_BRANDS,
    FETCH_PASSWORDS,
    CREATE_PASSWORD,
    DELETE_PASSWORD,
    CREATE_ALTERNATIVE_VIEW,
    FETCH_ALTERNATIVE_VIEWS,
    UPDATE_ALTERNATIVE_VIEW,
    DELETE_ALTERNATIVE_VIEW,
    SHOPIFY_CHECK_CONNECT,
    SHOPIFY_CONNECT,
    SHOPIFY_DISCONNECT,
    SHOPIFY_UPDATE,
    SHOPIFY_REFRESH,
  },
)
export default class EventDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.createdBrand = null
    this.selectedView = {}

    this.state = {
      showNewBrand: false,
      resetedDescriptions: [],
      createAlternativeView: false,
      editAlternativeView: false,
    }

    this.cardsStatus = {
      details: CARD_STATUS_UNCHANGED,
      description: CARD_STATUS_UNCHANGED,
      images: CARD_STATUS_UNCHANGED,
      type: CARD_STATUS_UNCHANGED,
      settings: CARD_STATUS_UNCHANGED,
      social: CARD_STATUS_UNCHANGED,
      tags: CARD_STATUS_UNCHANGED,
      paymentDetails: CARD_STATUS_UNCHANGED,
    }
    form_helper_reset()
  }
  async componentDidMount() {
    const {
      FETCH_PASSWORDS,
      SHOPIFY_CHECK_CONNECT,
      event: { id, displayName },
      dynamicConfigs,
      FETCH_BRANDS,
    } = this.props
    try {
      Messenger.options = {
        extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
        theme: 'future',
      }

      const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
      document.title = getTitle(configDocTitle, [displayName])

      FETCH_BRANDS()
      FETCH_PASSWORDS(id)
      SHOPIFY_CHECK_CONNECT(id, 'event')
      if (_get(dynamicConfigs, 'show_alternative_view', false)) {
        this.getAlternativeViews()
      }
    } catch (e) {
      showAxiosError(e)
    }
  }

  componentDidUpdate(prevProps) {
    const { dynamicConfigs } = this.props

    if (
      !_isEqual(dynamicConfigs, prevProps.dynamicConfigs) &&
      _get(dynamicConfigs, 'show_alternative_view', false)
    ) {
      this.getAlternativeViews()
    }
  }

  getAlternativeViews = async () => {
    const { FETCH_ALTERNATIVE_VIEWS, event } = this.props

    try {
      await FETCH_ALTERNATIVE_VIEWS(event.id)
    } catch (e) {
      showAxiosError(e)
    }
  }

  componentWillUnmount() {
    form_helper_reset()
    this.isUnmounted = true
  }

  updateResetedDescriptions = value => {
    const { resetedDescriptions } = this.state
    if (!_isEqual(resetedDescriptions, value)) {
      this.setState(() => ({
        resetedDescriptions: value,
      }))
    }
  }

  onClickNewBrand = () => {
    this.setState(() => ({ showNewBrand: true }))
  }

  onCancelNewBrand = () => {
    this.setState(() => ({ showNewBrand: false }))
  }

  onClickAlternativeView = () => {
    this.setState(() => ({ createAlternativeView: true }))
  }

  onEditAlternativeView = data => {
    const dataImageUrls = _get(data, 'imageURLs')
    const country = _get(data.venue, 'country') || null
    this.setState(() => ({ createAlternativeView: true, editAlternativeView: true }))
    this.selectedView = {
      ...data,
      displayName: data.title,
      viewId: data.id,
      languages: Object.keys(dataImageUrls),
      venue: {
        ...data.venue,
        country: !!country ? countryName(country) : '',
      },
    }
  }

  handleAlternativeView = async form => {
    const { event, CREATE_ALTERNATIVE_VIEW, UPDATE_ALTERNATIVE_VIEW, FETCH_BRANDS, FETCH_ALTERNATIVE_VIEWS } =
      this.props
    const { editAlternativeView } = this.state
    const errorMsg = 'An alternative view with this link already exists - please choose a different one.'
    changeBodyOverflow('unset')
    const action = editAlternativeView ? UPDATE_ALTERNATIVE_VIEW : CREATE_ALTERNATIVE_VIEW
    try {
      const res = await action(form)
      Messenger().post({
        type: 'success',
        message: editAlternativeView ? 'Successfully Updated!' : 'Successfully Created!',
        hideAfter: 10,
        showCloseButton: true,
      })
      FETCH_BRANDS()
      FETCH_ALTERNATIVE_VIEWS(event.id)
      this.setState(() => ({ createAlternativeView: false, editAlternativeView: false }))
      return res
    } catch (err) {
      if (err.errors[0].meta.message === errorMsg) {
        this.setState({ createAlternativeView: true })
        return Promise.reject(err.errors[0].meta.message)
      }
      Messenger().post({
        type: 'error',
        message: err.errors[0].meta.message,
        hideAfter: 5,
        showCloseButton: true,
      })
      this.setState({ createAlternativeView: false, editAlternativeView: false })
    }
  }

  deleteAlternativeView = async id => {
    const { event, DELETE_ALTERNATIVE_VIEW, FETCH_BRANDS, FETCH_ALTERNATIVE_VIEWS } = this.props
    try {
      const res = await DELETE_ALTERNATIVE_VIEW(id)
      Messenger().post({
        type: 'success',
        message: 'Successfully Deleted!',
        hideAfter: 10,
        showCloseButton: true,
      })
      FETCH_BRANDS()
      FETCH_ALTERNATIVE_VIEWS(event.id)
      this.setState(() => ({ createAlternativeView: false, editAlternativeView: false }))
      return Promise.resolve(res)
    } catch (err) {
      Messenger().post({
        type: 'error',
        message: err,
        hideAfter: 5,
        showCloseButton: true,
      })
      this.setState({ createAlternativeView: false, editAlternativeView: false })
      return Promise.reject(err)
    }
  }

  onCancelAlternativeView = form => {
    this.setState(() => ({ createAlternativeView: false, editAlternativeView: false }))
  }

  changeCardStatusToNormal = () => {
    const that = this
    if (this.cardsStatus.details !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.details = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.details = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.description !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.description = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.description = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.images !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.images = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.images = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.type !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.type = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.type = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.settings !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.settings = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.settings = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.social !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.social = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.social = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.tags !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.tags = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatus.tags = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    this.forceUpdate()
  }

  routerWillLeave = () => !form_helper_isEditted()

  handleSubmit = form => {
    const { event, UPDATE_EVENT } = this.props
    const venueKeys = [
      'city',
      'country',
      'flagDisabled',
      'flagHidden',
      'googlePlaceId',
      'latitude',
      'longitude',
      'postalCode',
      'state',
      'street',
      'streetNumber',
      'hideUntil',
      'notifyTicketHolders',
    ]
    const venue = _pick(form.attributes, venueKeys)
    venue.displayName = form.attributes.venueName
    const formData = {
      ...form,
      attributes: {
        ..._omit(form.attributes, [...venueKeys, 'venueName']),
        venue,
      },
    }
    return Promise.resolve(UPDATE_EVENT(event.id, formData)).then(event => {
      showSuccessMessage('Saved')
      form_helper_reset()
      this.changeCardStatusToNormal()
      return event
    })
  }

  handleBrandSubmit = form => {
    const { CREATE_BRAND, FETCH_BRANDS } = this.props
    return Promise.resolve(CREATE_BRAND({ ...form }))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(() => {
        FETCH_BRANDS()
        this.createdBrand = form.attributes.displayName
        this.setState(() => ({ showNewBrand: false }))
      })
  }

  onCreatePassword = password => {
    const { event, CREATE_PASSWORD, FETCH_PASSWORDS } = this.props
    return Promise.resolve(CREATE_PASSWORD(event.id, password))
      .catch(err => {
        Messenger().post({
          type: 'error',
          message: err.errors[0].meta.message,
          hideAfter: 3,
          showCloseButton: true,
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Created password',
          hideAfter: 3,
          showCloseButton: true,
        })
        FETCH_PASSWORDS(event.id)
      })
  }

  onDeletePassword = password => {
    const { event, DELETE_PASSWORD, FETCH_PASSWORDS } = this.props
    return Promise.resolve(DELETE_PASSWORD(event.id, password))
      .catch(err => {
        Messenger().post({
          type: 'error',
          message: err.errors[0].meta.message,
          hideAfter: 3,
          showCloseButton: true,
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Removed password',
          hideAfter: 3,
          showCloseButton: true,
        })
        FETCH_PASSWORDS(event.id)
      })
  }

  onRefreshPasswords = () => {
    const { event, FETCH_PASSWORDS } = this.props
    return Promise.resolve(FETCH_PASSWORDS(event.id))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => v)
  }

  shopifyConnectSubmit = url => {
    const { event, SHOPIFY_CONNECT } = this.props
    SHOPIFY_CONNECT(event.id, 'event', url)
  }
  onDisconnectShopify = () => {
    const { event, SHOPIFY_DISCONNECT } = this.props
    SHOPIFY_DISCONNECT(event.id, 'event')
  }
  onSopifyUpdate = newAddedIds => {
    const { event, SHOPIFY_UPDATE } = this.props
    return SHOPIFY_UPDATE(event.id, 'event', newAddedIds)
  }
  onSopifyRefresh = () => {
    const { event, SHOPIFY_REFRESH } = this.props
    return SHOPIFY_REFRESH(event.id, 'event')
  }

  render() {
    const {
      isRnDomain,
      brands,
      eventShopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      dynamicConfigs,
      user,
      event,
      rnEventMetadataObj,
      passwords,
      alternativeViews,
      globalCountries,
      configs,
    } = this.props

    const isOnlineEvent = event ? event.isOnlineEvent : false
    const showOnlineEvents = _get(dynamicConfigs, 'show_online_events', false)
    let selectedBrand = null
    if (this.createdBrand) {
      const found = _find(brands, { displayName: this.createdBrand })
      if (!!found) {
        this.createdBrand = null
        selectedBrand = found.id
      }
    }

    const { showNewBrand, createAlternativeView, editAlternativeView, resetedDescriptions } = this.state
    const { cardsStatus } = getEdittedFields()
    if (!editAlternativeView) {
      this.selectedView = _merge(null, {
        languages: Object.keys(event.imageURLs),
        owner: event.owner,
        self: event.self,
      })
    }
    return (
      <div>
        <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
        <Modal
          className="event-details modal-trans"
          style={update(modalStyle, { content: { maxHeight: { $set: '100%' } } })}
          isOpen={showNewBrand}
          contentLabel="Modal"
          ariaHideApp={false}
          onRequestClose={this.onCancelNewBrand}
          closeTimeoutMS={150}
          id="event-detail-create-brand"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Create Brand</div>
              <div className="modal-body">
                <BrandForm
                  user={user}
                  closed={true}
                  isNew={true}
                  onSubmit={this.handleBrandSubmit}
                  onCancel={this.onCancelNewBrand}
                  submitLabel="Create Brand"
                  initialValues={null}
                  isRnDomain={isRnDomain}
                  containerId="event-detail-create-brand"
                  configs={_get(configs, 'children.BrandForm')}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="event-details modal-trans alternative-view-modal"
          style={modalStyle}
          isOpen={createAlternativeView}
          contentLabel="Modal"
          onRequestClose={this.onCancelAlternativeView}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {editAlternativeView ? 'Edit Alternative View' : 'Create New Alternative View'}
                <p className="header-note">
                  Please select which elements of your event page to override for this view
                </p>
              </div>
              <div className="modal-body">
                <AlternativeViewForm
                  event={event}
                  alternativeview={true}
                  formKey={event.id}
                  initialValues={this.selectedView}
                  isNew={!editAlternativeView}
                  onSubmit={this.handleAlternativeView}
                  onCancel={this.onCancelAlternativeView}
                  submitLabel={editAlternativeView ? 'Save' : 'Create View'}
                  onClickAlternativeView={this.onClickAlternativeView}
                  updateResetedDescriptions={this.updateResetedDescriptions}
                  resetedDescriptions={resetedDescriptions}
                  user={user}
                  organizations={brands}
                  cardsStatus={cardsStatus}
                  isRnDomain={isRnDomain}
                  configs={_get(configs, 'children.AlternativeViewForm')}
                />
              </div>
            </div>
          </div>
        </Modal>
        {isOnlineEvent && showOnlineEvents ? (
          <OnlineEventForm
            event={event}
            user={user}
            formKey={event.id}
            organizations={brands}
            initialValues={_merge(event, {
              description: mediumEditor_revert(event.description),
            })}
            rnEventMetadataObj={rnEventMetadataObj}
            submitLabel="Save"
            onSubmit={this.handleSubmit}
            onClickNewBrand={this.onClickNewBrand}
            onClickAlternativeView={this.onClickAlternativeView}
            dynamicConfigs={dynamicConfigs}
            onEditAlternativeView={this.onEditAlternativeView}
            deleteView={this.deleteAlternativeView}
            selectedBrand={selectedBrand}
            cardsStatus={cardsStatus}
            passwords={passwords}
            onCreatePassword={this.onCreatePassword}
            onDeletePassword={this.onDeletePassword}
            onRefreshPasswords={this.onRefreshPasswords}
            updateResetedDescriptions={this.updateResetedDescriptions}
            resetedDescriptions={resetedDescriptions}
            shopifyConnectSubmit={this.shopifyConnectSubmit}
            onDisconnectShopify={this.onDisconnectShopify}
            onSopifyUpdate={this.onSopifyUpdate}
            onSopifyRefresh={this.onSopifyRefresh}
            shopifyInfo={eventShopifyInfo}
            shopifyConnectLoading={shopifyConnectLoading}
            shopifyDisconnectLoading={shopifyDisconnectLoading}
            shopifyRefreshLoading={shopifyRefreshLoading}
            fetchProductsLoading={fetchProductsLoading}
            configs={_get(configs, 'children.EventForm')}
          />
        ) : (
          <EventForm
            event={event}
            isRnDomain={isRnDomain}
            formKey={event.id}
            initialValues={_merge(event, {
              description: mediumEditor_revert(event.description),
            })}
            rnEventMetadataObj={rnEventMetadataObj}
            submitLabel="Save"
            onSubmit={this.handleSubmit}
            onClickNewBrand={this.onClickNewBrand}
            alternativeViews={alternativeViews}
            onClickAlternativeView={this.onClickAlternativeView}
            dynamicConfigs={dynamicConfigs}
            onEditAlternativeView={this.onEditAlternativeView}
            deleteView={this.deleteAlternativeView}
            user={user}
            organizations={brands}
            selectedBrand={selectedBrand}
            cardsStatus={this.cardsStatus}
            passwords={passwords}
            onCreatePassword={this.onCreatePassword}
            onDeletePassword={this.onDeletePassword}
            onRefreshPasswords={this.onRefreshPasswords}
            updateResetedDescriptions={this.updateResetedDescriptions}
            resetedDescriptions={resetedDescriptions}
            shopifyConnectSubmit={this.shopifyConnectSubmit}
            onDisconnectShopify={this.onDisconnectShopify}
            onSopifyUpdate={this.onSopifyUpdate}
            onSopifyRefresh={this.onSopifyRefresh}
            shopifyInfo={eventShopifyInfo}
            shopifyConnectLoading={shopifyConnectLoading}
            shopifyDisconnectLoading={shopifyDisconnectLoading}
            shopifyRefreshLoading={shopifyRefreshLoading}
            fetchProductsLoading={fetchProductsLoading}
            globalCountries={globalCountries}
            configs={_get(configs, 'children.EventForm')}
          />
        )}
      </div>
    )
  }
}
