import React from 'react'
import Select from 'react-select'
import _findIndex from 'lodash/findIndex'
import { defaultValue, sortArr } from './SelectTimeSlot'

const sortHours = (arr = []) => arr.sort((a, b) => (a.label > b.label ? 1 : -1))

export default class SelectTimeSlotItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slotsValue: []
    }
  }
  onChangeDay = e => {
    if (this.props.day.value !== e.value) {
      this.props.onChangeDay(e, this.props.day)
    }
  }
  onChangeTime = e => {
    this.setState(() => ({ slotsValue: e }))
    this.props.onChangeTime(e || [], this.props.day)
  }
  onDelete = () => {
    this.props.onDelete(this.props.day)
  }
  render() {
    const { day, noSelectedDays, hours, index } = this.props
    const dayOptions = sortArr([...noSelectedDays])
    if (index === 0 && _findIndex(dayOptions, d => d.value === 'all') === -1) {
      dayOptions.unshift(defaultValue)
    }
    const hourOptions = sortHours(hours)
    const isAllOptionSelected = day.value === 'all'

    return (
      <div>
        <div style={{ display: 'inline-block', float: 'left', width: 300, margin: '5px 10px 5px 20px' }}>
          <Select onChange={this.onChangeDay} value={day} options={dayOptions} />
        </div>
        {!isAllOptionSelected && (
          <div style={{ display: 'inline-block', float: 'left', width: 300, margin: '5px 0' }}>
            <Select
              multi
              value={this.state.slotsValue}
              placeholder="All Time Slots"
              onChange={this.onChangeTime}
              options={hourOptions}
            />
          </div>
        )}
        {!isAllOptionSelected && (
          <div style={{ display: 'inline-block', float: 'left', padding: '12px 0 0 10px', cursor: 'pointer' }}>
            <i onClick={this.onDelete} style={{ fontSize: '22px' }} className="fa fa-trash" />
          </div>
        )}
      </div>
    )
  }
}
