import { createAsyncAction } from '../actions'
import { fetchEventSupport, updateEventSupport } from '../../core/http_services'
import { showAxiosError, showSuccessMessage } from '../../../web/utils/messenger'

const { FETCH_EVENT_SUPPORT } = createAsyncAction('FETCH_EVENT_SUPPORT', function (eid) {
  return async dispatch => {
    try {
      const res = await fetchEventSupport(eid)
      dispatch(this.success(res))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_EVENT_SUPPORT } = createAsyncAction('UPDATE_EVENT_SUPPORT', function (eid, form) {
  return async dispatch => {
    try {
      const res = await updateEventSupport(eid, form)
      dispatch(this.success(res))
      showSuccessMessage()
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

export { FETCH_EVENT_SUPPORT, UPDATE_EVENT_SUPPORT }
