import React from 'react'
import TextArea from '../../_library/TextArea'
import Field from '../../_library/Field'

export default class EventBrandLevelFBPixel extends React.Component {
  render() {
    const { values } = this.props
    return (
      <div className="event-ticket-advanced-options">
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="showPagePixels"
              label="View of Event Page"
              value={values.pagePixels}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="showCheckoutPixels"
              label="Start of Checkout"
              value={values.checkoutPixels}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="showConversionPixels"
              label="Completion of Purchase (use {CURRENCY} and {VALUE} to auto-insert conversion data)"
              value={values.conversionPixels}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              className="ga-field"
              id="showGoogleAnalyticsKey"
              label="Google Analytics Tracking ID"
              value={values.googleAnalyticsKey || ''}
              placeholder="UA-XXXXX-Y"
              readOnly
            />
          </div>
        </div>
      </div>
    )
  }
}
