import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DataTable from '../../_library/DataTable'
import CSVReader from 'react-csv-reader'
import { Formik, Form, Field, FieldArray } from 'formik'
import { isEmpty, cloneDeep } from 'lodash'
import Button from '../../_library/Button'

import {
  SimpleField,
  SelectField,
} from '../../formik/Fields'

import {
  // field level validators
  requiredValidator,
  isEmailValidator,
  combineValidators,

  // normalizer
  toInt,

} from '../../../_common/core/validation'


@withRouter
class ImportGiftCardForm extends Component {
  constructor() {
    super()
    this.state = {
      uploadedDataTable: []
    }
    this.isInitialValidated = false
    this.tableHead = [
      'First Name',
      'Last Name',
      'Email',
      'Type of Ticket',
      'Number of Tickets',
      'Notes',
      '',
    ]
    this.tableBody = [
      [
        'Jane',
        'Smith',
        'jane.smith@gmail.com',
        'General Access',
        '1',
        'Some Note',
      ],
      [
        'Calvin',
        'Francis',
        'dj.calvin.francis@gmail.com',
        'General Access',
        '1',
        'Some Note',
      ],
      [
        'Clark',
        'Kent',
        'superman@yahoo.com',
        'Fantascene Dream',
        '1',
        'Some Note',
      ],
    ]
  }

  onCSVUpload = data => {
    this.isInitialValidated = false
    const notEmptyArray = []
    data.forEach(item => {
      if(item.filter(el => el).length) {
        notEmptyArray.push(item)
      }
    })
    this.setState({
      uploadedDataTable: notEmptyArray, 
    })
  }

  compareTicketGroupLabels = (selectedLabel = '', ticketGroups) => {
    const selectedTicketTypes = ticketGroups.find(item => item.label.toLowerCase() === selectedLabel.toLowerCase())

    if(selectedTicketTypes) {
      return selectedTicketTypes.value
    }
  } 

  handleDeleteTableRow = (data, index) => {
    this.isInitialValidated = false
    const newArray = [...data]
    newArray.splice(index, 1)
    this.setState({ uploadedDataTable: [...newArray] })
  }


  render() {
    const { uploadedDataTable } = this.state
    const { ticketGroups, onSubmit, emailTemplatesOptions, history: { replace }, eventId } = this.props
    
    const formValues = uploadedDataTable.map(item => {
      const selectedGiftId = this.compareTicketGroupLabels(item[3], ticketGroups)
      return {
        firstName: item[0] || '',
        lastName: item[1] || '',
        email: item[2] || '',
        giftId: selectedGiftId || '',
        quantity: item[4] || '',
        message: item[5] || '',
        type: 'ticket',
      }
    })

    const formInitialData = { giftCards: formValues }

    const formFields = uploadedDataTable.map((item, index) => [
      <Field
        name={`giftCards.${index}.firstName`}
        component={SimpleField}
        showErrorOnlyTouched={false}
        validate={requiredValidator()}
      />,
      <Field
        name={`giftCards.${index}.lastName`}
        component={SimpleField}
        showErrorOnlyTouched={false}
        validate={requiredValidator()}
      />,
      <Field
        name={`giftCards.${index}.email`}
        component={SimpleField}
        showErrorOnlyTouched={false}
        validate={combineValidators(requiredValidator(), isEmailValidator('Invalid'))}
      />,
      <Field
        name={`giftCards.${index}.giftId`}
        component={SelectField}
        showErrorOnlyTouched={false}
        options={ticketGroups}
        validate={requiredValidator()}
      />,
      <Field
        name={`giftCards.${index}.quantity`}
        component={SimpleField}
        showErrorOnlyTouched={false}
        validate={requiredValidator()}
        normalizer={toInt}
      />,
      <Field
        name={`giftCards.${index}.message`}
        component={SimpleField}
      />,
      <i 
        className="fa fa-trash" 
        aria-hidden="true"
        onClick={() => this.handleDeleteTableRow(uploadedDataTable, index)} />
    ])
    return (
      <div>
        <Formik
          initialValues={this.isInitialValidated ? { ...formInitialData } : { emailTemplateId: '' }}
          onSubmit={(values, actions) => {
            const { setSubmitting, resetForm } = actions
            const giftCards = cloneDeep(values.giftCards)
            giftCards.forEach(el => el.emailTemplateId = values.emailTemplateId)
            onSubmit({ giftCards })
              .finally(() => {
                this.setState({ uploadedDataTable: [] })
                setSubmitting(false)
              })
          }}
          render={({ values, errors, setValues }) => {
            this.isInitialValidated = true
            return (
              <Form autoComplete='off' className="upload-form">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <Field
                      name='emailTemplateId'
                      label='Email Templates'
                      disabled={!emailTemplatesOptions.length}
                      options={emailTemplatesOptions}
                      component={SelectField}
                    />
                  </div>
                  <div className="col-xs-6 col-xs-12">
                    <div style={{ paddingTop: '18px' }}>
                      <Button
                        type='button'
                        className="btn btn-primary btn-shadow"
                        onClick={() => replace(`/event/${eventId}/email-templates`)}
                      >
                            Add New Template
                      </Button>
                    </div>
                  </div>
                </div>

                <p>
                        Format guidelines &nbsp;&nbsp;&nbsp;
                  <i className="fa fa-caret-right" aria-hidden="true" />
                        Please do <strong>not</strong> include a header row in the file. Each row should include the following:
                </p>
                <div className="upload-button-container">
                  <CSVReader
                    onFileLoaded={data => this.onCSVUpload(data)}
                    inputId="ObiWan"
                    cssClass="csv-upload"
                  />
                  <label htmlFor="ObiWan">
                    <div className="btn btn-success btn-shadow btn-importcsv">
                      <i className="fa fa-upload" aria-hidden="true" />
                      <span>Add from CSV</span>
                    </div>
                  </label>
                </div>

                <FieldArray
                  name="giftCards"
                  render={props => (
                    <DataTable
                      thead={this.tableHead}
                      tbody={formFields.length
                        ? formFields
                        : (
                          <tbody>
                            <tr>
                              <td colSpan="6" className="text-center empty-table">No CSV uploaded yet</td>
                            </tr>
                          </tbody>)
                      }
                    />
                  )}
                />
                {formFields.length ? (isEmpty(errors) && (
                  <Button
                    className="btn btn-primary btn-shadow"
                    type="submit"
                  >
                        Issue Gift Certificates
                  </Button>
                )) : <div />}
              </Form>
            )
          }}
        />
      </div>
    )
  }
}

export default ImportGiftCardForm
