import React, { Component } from 'react'
import { FieldArray, Field } from 'formik'
import { SimpleField } from '../../../formik/Fields'
import { combineNormalizers, toNumber, requiredValidator } from '../../../../_common/core/validation'

const ExternalRedirectComponent = props => {
  const { values, isEditing, handleRowDelete = () => null } = props

  return (
    <div className="col-xs-12 bottom-field-ticket-form">
      <FieldArray
        name="externalRedirect"
        render={arrayHelpers => (
          <div className="col-xs-12 col-12" style={{ padding: 0 }}>
            <Field
              name={`externalRedirect`}
              label={`External platform link`}
              component={SimpleField}
              touchedOnBlur={true}
              value={values.externalRedirect}
              validate={requiredValidator()}
            />
          </div>
        )}
      />
    </div>
  )
}

export default ExternalRedirectComponent
