import React from 'react'
import Modal from 'react-modal'

// components
import Button from '../_library/Button'
import { ProductItem } from '.'



import modalStyle from '../../_common/core/modalStyle'

class AddNewProducts extends React.Component {
  constructor(props) {
    super(props)
    const { addedIds } = this.props
    this.state = {
      modalOpen: false,
      newAddedIds: new Set(addedIds),
      loading: false
    }
  }

  openOrCloseModal = (isOpen = true) => {
    const { addedIds } = this.props
    this.setState(() => ({ modalOpen: isOpen, newAddedIds: new Set(addedIds) }))
  }

  onClickSave = async () => {
    const { onUpdate } = this.props
    const { newAddedIds } = this.state
    try {
      this.setState(() => ({ loading: true }))
      await onUpdate([...newAddedIds])
      this.setState(() => ({ loading: false, modalOpen: false }))
    } catch (e) {
      this.setState(() => ({ loading: false }))
    }
  }

  onClickCancel = () => {
    this.openOrCloseModal(false)
  }

  onClickAddProduct = (e, id) => {
    const v = e.target.checked
    this.setState((state) => {
      const newAddedIds = new Set(state.newAddedIds)
      v ? newAddedIds.add(id) : newAddedIds.delete(id)
      return {
        newAddedIds
      }
    })
  }

  render() {
    const { modalOpen, newAddedIds, loading } = this.state
    const { allProducts } = this.props
    return (
      <div style={{ margin: '5px', display: 'inline-block' }} >
        <Button
          className="btn btn-default"
          type='button'
          onClick={this.openOrCloseModal}
        >
          Add New Products +
        </Button>
        {
          modalOpen ? (
            <div>
              { modalOpen &&
              <Modal
                className="modal-dialog modal-trans"
                style={modalStyle}
                contentLabel="Modal"
                isOpen={true}
                // onRequestClose={() => this.openOrCloseModal(false)}
                closeTimeoutMS={150}
                ariaHideApp={false}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-confirm-switch">
                      <div className="modal-header">
                        Add New Products
                      </div>
                      <div className="modal-body">
                        <div>
                          {
                            allProducts.map((el) => (
                              <div key={el.id} style={{ margin: '20px', display: 'inline-block' }}>
                                <ProductItem product={el} />
                                <div>
                                  <label style={{ marginRight: '5px' }} htmlFor={`a_p${el.id}`}>Add Product</label>
                                  <input
                                    id={`a_p${el.id}`}
                                    type='checkbox'
                                    disabled={loading}
                                    checked={newAddedIds.has(el.id)}
                                    onChange={(e) => this.onClickAddProduct(e, el.id)}
                                  />
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="btn-toolbar btn-toolbar-right">
                          <Button
                            className="btn btn-success btn-shadow"
                            type="button"
                            loading={loading}
                            onClick={this.onClickSave}>Save</Button>
                          <Button
                            disabled={loading}
                            className="btn btn-default btn-shadow" type="button"
                            onClick={this.onClickCancel}>Cancel</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              }

            </div>
          ) : null
        }
      </div>
    )
  }
}

export { AddNewProducts }