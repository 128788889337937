import React, { Component } from 'react'
import Modal from 'react-modal'
import { Formik, Form, Field } from 'formik'
import { get } from 'lodash'
import Button from '../../_library/Button'

import { TextAreaField, SimpleField, SelectField } from '../../formik/Fields'

// validators
import {
  // field level validators
  requiredValidator,

  // normalizer
  toInt,
  combineNormalizers,
  minNormalizerCreator
} from '../../../_common/core/validation'

import FileUploader from '../../_library/FileUploader'
import modalStyle from '../../../_common/core/modalStyle'

class AddItemsModal extends Component {
  
  render() {
    const { onCloseModal, values, submitModal, isModalOpened, ticketTypes, modalTitle } = this.props
    return (
      <Modal
        className="modal-dialog modal-trans"
        contentLabel="Modal"
        style={modalStyle}
        isOpen={isModalOpened}
        onRequestClose={onCloseModal}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{modalTitle || 'Add Additional Item'}</p>
              </div>
              <div>
                <div className="modal-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: get(values, 'name', ''),
                      description: get(values, 'description', ''),
                      type: get(values, 'type', ''),
                      quantity: get(values, 'quantity', ''),
                      url: get(values, 'url', '')
                    }}
                    onSubmit={(values, actions) => {
                      const { setSubmitting, resetForm } = actions
                      submitModal(values)
                    }}
                    render={({ values, isSubmitting, setFieldValue }) => (
                      <Form autoComplete="off">
                        <div className="row">
                          <div className="col-xs-12">
                            <Field
                              label={'Name'}
                              name="name"
                              component={SimpleField}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="col-xs-12">
                            <Field
                              label="Description"
                              name="description"
                              component={TextAreaField}
                            />
                          </div>
                          <div className="div-spacing-15" />
                          <div className="col-xs-6">
                            <Field
                              label="Type"
                              name="type"
                              component={SelectField}
                              options={ticketTypes}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="col-xs-6">
                            <Field
                              label={'Quantity'}
                              name="quantity"
                              component={SimpleField}
                              normalizer={combineNormalizers(toInt, minNormalizerCreator(1))}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="col-xs-12">
                            <div className="image-upload">
                              <FileUploader
                                id="url"
                                label="Upload Image"
                                filetype="image"
                                value={values.url}
                                onChange={url => setFieldValue('url', url)}
                              />
                            </div>
                          </div>
                          <div className="modal-footer footer-in-form">
                            <div className="col-xs-12 text-center">
                              <Button
                                className="btn btn-success btn-lg btn-shadow submit-button"
                                type="submit"
                              >
                                Save
                              </Button>
                              <Button
                                className="btn btn-cancel btn-lg btn-shadow"
                                onClick={onCloseModal}
                                type="button"
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default AddItemsModal
