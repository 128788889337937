import React, { useEffect } from 'react'
import _find from 'lodash/find'
import { getDayOptions } from '../../../utils/ticketUtils'
import Select from '../../../_library/Select'
import Field from '../../../_library/Field'

const RegularTickets = ({
  values,
  ticketTableLabel,
  optionsTickets,
  errors,
  touched,
  options_quantity,
  filteredTimeSlotTickets,
  handleChangeTicketTypeID,
  handleChangeQuantity,
  handleDayChange,
  handleTimeChange,
  handleChangeEmail,
  handleChangeConfirmEmail,
}) => {
  const showDayTimeSelects = !!values.selectedGroup
  const dayOptions = showDayTimeSelects ? getDayOptions(filteredTimeSlotTickets, values.selectedGroup) : null
  const selectedDay = _find(dayOptions, d => d.value === values.selectedDay) || {}
  const timeOptions = selectedDay.timeOptions || [{ value: '', label: 'Time' }]

  useEffect(() => {
    handleChangeQuantity(1)
  }, [])
  return (
    <>
      <div>
        <div className="row">
          <div className="col-xs-6 col-12">
            <Select
              id="ticketTypeID"
              label={ticketTableLabel}
              options={optionsTickets}
              value={showDayTimeSelects ? values.selectedGroup : values.ticketTypeID}
              error={showDayTimeSelects ? errors.selectedGroup : errors.ticketTypeID}
              touched={!showDayTimeSelects ? touched.ticketTypeID : touched.selectedGroup}
              onChange={handleChangeTicketTypeID}
            />
          </div>
          <div className="col-xs-6 col-12">
            <Select
              id="quantity"
              label="Quantity"
              options={options_quantity}
              value={values.quantity}
              error={errors.quantity}
              onChange={handleChangeQuantity}
            />
          </div>
        </div>
        <div>
          {showDayTimeSelects && (
            <div className="row">
              <div className="col-xs-6 col-12">
                <Select
                  id="selectedDay"
                  label="Ticket Day"
                  options={dayOptions}
                  value={values.selectedDay}
                  error={errors.selectedDay}
                  touched={touched.selectedDay}
                  onChange={handleDayChange}
                />
              </div>
              <div className="col-xs-6 col-12">
                <Select
                  id="selectedTime"
                  label="Ticket Time"
                  options={timeOptions}
                  value={values.selectedTime}
                  error={errors.selectedTime}
                  touched={touched.selectedTime}
                  onChange={handleTimeChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-12">
          <Field
            label="Email Address"
            type="email"
            id="email"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onChange={handleChangeEmail}
          />
        </div>
        <div className="col-xs-6 col-12">
          <Field
            label="Confirm Email Address"
            type="email"
            id="confirmEmail"
            value={values.confirmEmail}
            error={errors.confirmEmail}
            touched={touched.confirmEmail}
            onChange={handleChangeConfirmEmail}
          />
        </div>
      </div>
    </>
  )
}

export default RegularTickets
