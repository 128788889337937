import React from 'react'
import _  from 'lodash'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import { Field, Form, withFormik } from 'formik'
import { Element } from 'react-scroll'

import Button from '../../../_library/Button'
import { EVENT_DESCRIPTION_LANGUAGES } from '../../../constants/languages.js'

// cards
import AlternativeViewEventDetails from './AlternativeViewEventDetails'
import AlternativeViewEventVenue from './AlternativeViewEventVenue'
import AlternativeViewEventDescription from './AlternativeViewEventDescription'
import AlternativeViewEventImages from './AlternativeViewEventImages'
import UserReferenceDescription from './UserReferenceDescription'


import { validateAlternativeEvent } from '../../form/EventForm/validation'
import { getAlternativeViewFromInitialValues, alternativeViewChecks, formatUncheckedData } from '../../form/EventForm/utils'
import { EventUrlField } from '../../../formik/Fields'
import {
  requiredValidator,
  combineValidators,
  isValidSlug,
  isEmailValidator
} from '../../../../_common/core/validation'

const languages = getLanguages()
export const defaultLanguage = 'en'
const inValidSlug = 'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)'

const AlternativeViewForm = (props) => {
  const {
    event,
    submitLabel,
    isNew,
    isRnDomain,
    cardsStatus,
    onCancel,
    updateResetedDescriptions,
    resetedDescriptions,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    configs
  } = props

  /** close autocomplete list on modal scroll **/
  $('.ReactModal__Overlay').on('scroll', () => {
    $('#alternative-view .floating-field-group .floating-field input').blur()
  })

  let eventUrlCopied = false
  const copyEventUrlOut = e => {
    if (eventUrlCopied) {
      setTimeout(() => {
        eventUrlCopied = false
      }, 500)
    }
  }
  const copyEventUrlAfter = () => {
    eventUrlCopied = true
    // this.setState({eventUrlCopied:true})
  }
  const handleChangeImageAsAlternativeEventName = e => {
    const checked = e.target ? e.target.checked : e
    setFieldValue( 'useImageInsteadOfTitle', checked)
  }
  const handleChangeAlternativeAutoBackground = e => {
    const { checked } = e.target
    setFieldValue('autoBackground', checked)
  }

  const { autoBackground, useImageInsteadOfTitle, titleReplacementImage } = values
  return (
    <div id="alternative-view">
      <div className="event-form alternative-view-form">
        <div className={!!isNew ? 'body-panel-content' : ''}>
          <Form autoComplete='off'>
            <Element name="scroll-to-top" />
            <UserReferenceDescription
              cardsStatus={cardsStatus}
            />
            <div className="alternative-view-field">
              <Field
                name='slug'
                label='Alternative View URL'
                isNew={isNew}
                isRnDomain={isRnDomain}
                values={values}
                eventUrlCopied={eventUrlCopied}
                copyEventUrlOut={copyEventUrlOut()}
                copyEventUrlAfter={copyEventUrlAfter()}
                component={EventUrlField}
                validate={combineValidators(requiredValidator(), isValidSlug())}
                inValidSlugClass={errors.slug === inValidSlug ? 'slugfield-holder-with-err' : ''}
              />
            </div>
            <AlternativeViewEventDetails
              event={event}
              cardsStatus={cardsStatus}
              values={values}
              isNew={isNew}
              isRnDomain={isRnDomain}
            />
            <AlternativeViewEventVenue
              closed={true}
              event={event}
              values={values}
              cardsStatus={cardsStatus}
              setFieldValue={setFieldValue}
              configs={_get(configs, 'children.AlternativeViewEventVenue')}
            />
            <AlternativeViewEventDescription
              closed={true}
              event={event}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              cardsStatus={cardsStatus}
              resetedDescriptions={resetedDescriptions}
              updateResetedDescriptions={updateResetedDescriptions}
            />
            <AlternativeViewEventImages
              closed={true}
              isAlternativeView={true}
              event={event}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              cardsStatus={cardsStatus}
              autoBackground={autoBackground}
              useImageInsteadOfTitle={useImageInsteadOfTitle}
              handleChangeAlternativeAutoBackground={handleChangeAlternativeAutoBackground}
              handleChangeImageAsAlternativeEventName={handleChangeImageAsAlternativeEventName}
              titleReplacementImage={titleReplacementImage}
            />
            <div className="row">
              <div className="col-xs-12 text-center btn-toolbar">
                <Button
                  className="btn btn-success btn-lg btn-shadow"
                  type="button"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {submitLabel}
                </Button>
                <Button
                  className="btn btn-cancel btn-lg btn-shadow"
                  type="button"
                  disabled={isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: (props) => initialData(props),
  validate: values => validateAlternativeEvent(values),
  handleSubmit: (values, { props, setSubmitting, setValues, setErrors }) => {
    const { useImageInsteadOfTitle } = values
    let formValues = _cloneDeep(values)
    delete formValues.owner
    //set title image field only if set by user
    if(!useImageInsteadOfTitle) {
      formValues.titleReplacementImage = null
      setValues({ ...formValues,'titleReplacementImage': null })
    } else {
      const { titleReplacementImage } = formValues
      if((titleReplacementImage && !titleReplacementImage.startsWith('https://'))) {
        const { titleReplacementImage } = formValues
        delete formValues.titleReplacementImage
        setValues({ ...formValues, titleReplacementImage })
      }
    }
    formValues = venueData(formValues, values)
    const attributes = checkData(formValues)
    const data = {
      attributes: { ...attributes, eventId: props.formKey },
      relationships: {
        owner: values.owner
      },
      eventId: props.formKey
    }
    props.onSubmit(data)
      .catch((err) => {
        if(err === 'An alternative view with this link already exists - please choose a different one.') {
          setErrors({ slug: 'URL already exists' })
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
})(AlternativeViewForm)


const venueData = (form, data) => {
  const venue = {
    displayName: _.get(data, 'venueName') || '',
    streetNumber: _.get(data, 'streetNumber') || '',
    street: _.get(data, 'street') || '',
    city: _.get(data, 'city') || '',
    state: _.get(data, 'state') || '',
    postalCode: _.get(data, 'postalCode') || '',
    country: _.get(data, 'country') || '',
    flagHidden: _.get(data, 'flagHidden'),
    flagDisabled: _.get(data, 'flagDisabled'),
    googlePlaceId: _.get(data, 'googlePlaceId'),
    latitude: _.get(data, 'latitude'),
    longitude: _.get(data, 'longitude'),
    searchValue: '',
    hideUntil: _.get(data, 'hideUntil'),
    locationInformation: _.get(data, 'locationInformation') || null
  }
  delete form.venueName
  delete form.venueId
  const venueProps = Object.keys(venue)
  _.map(venueProps, prop => {
    delete form[prop]
  })
  return { ...form, venue }
}

const initialData = (props) => {
  const values = getAlternativeViewFromInitialValues(props)
  const v = values.backgroundImage
  let languageOptions = _.map(_.keys(values.descriptions), d => ({
    label: languages[d] + `${d === defaultLanguage ? ' (Default)' : ''}`,
    value: d
  }))
  if (!languageOptions.length) {
    languageOptions = EVENT_DESCRIPTION_LANGUAGES
  }
  return {
    ...values,
    autoBackground: !v,
    descriptionLanguage: defaultLanguage,
    imageLanguage: defaultLanguage,
    languageOptions,
  }
}

const checkData = values => {
  const imageURLs = !!values.imageURLs ? values.imageURLs : {}
  const descriptions = !!values.descriptions ? values.descriptions : {}
  alternativeViewChecks.forEach(check => {
    if(values[check] === false){
      values = formatUncheckedData(check, values)
    }
  })
  _.map(alternativeViewChecks, field => {
    delete values[field]
  })
  _.map(values.languageOptions, locale => {
    if (!values[locale.value + 'Check']) {
      imageURLs[locale.value] = null
    }
    if (!values[locale.value + 'DescriptionCheck']) {
      descriptions[locale.value] = null
    }
    delete values[locale.value + 'Check']
    delete values[locale.value + 'DescriptionCheck']
  })
  values.imageURLs = imageURLs
  values.descriptions = descriptions

  return values
}
