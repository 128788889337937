export const passesAdapter = data => {
  const newData = data.map(row => ({
    id: row.id,
    entrypassTypeName: row.entrypassType.name,
    entrypassTypeId: row.entrypassType.id,
    firstName: row.firstName,
    lastName: row.lastName,
    full_name: row.firstName + " " + row.lastName,
    email: row.email,
    issuedByNotes: row.issuedByNotes,
    maxUsesPerEvent: row.maxUsesPerEvent,
  }))
  return newData
}

