import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _map from 'lodash/map'

import Card from '../_library/Card'
import LoadingBar from '../_library/LoadingBar'
import Button from '../_library/Button'
import SortableTable from '../_library/SortableTable/index'
import { getTableColumns } from '../utils/sortableTableUtils'

import {
  FETCH_MEMBERSHIPS,
  FETCH_MEMBERSHIP_ITEMS,
  ADD_MEMBERSHIP_VISIT,
  FETCH_MEMBERSHIP_VISITS,
  UPDATE_MEMBERSHIP_VISIT,
  FETCH_MEMBERSHIP_LIST
} from '../../_common/redux/memberships/actions'

import EmptyBar from '../_library/EmptyBar'
import SortableTableWithSearchHOC from '../../web/hoc/SortableTableWithSearchHOC'
import MembershipUsageModal from './membership/UsageModal'
import {
  currencyNormalizerCreator,
  createFixedFloatNormalizer,
  durationNormalizerCreater
} from '../../_common/core/validation'
import {
  get_event,
  get_memberships,
  get_membership_list,
  get_membership_items,
  get_visit_added,
  get_membership_visits,
  get_visit_updated,
} from '../../_common/core/selectors'
import { formatDate } from '../../_common/core/validation/normalizers'
import { DAY_DISPLAY_FORMAT } from '../constants/timeFormats'

const SortableTableWithSearch = SortableTableWithSearchHOC()

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const memberships = get_memberships(state)
    const membershipList = get_membership_list(state)
    const membershipItems = get_membership_items(state)
    const addedVisit = get_visit_added(state)
    const membershipVisits = get_membership_visits(state)
    const updatedVisit = get_visit_updated(state)

    const membershipsLoading = state.loading.has('FETCH_MEMBERSHIPS')
    const membershipItemsLoading = state.loading.has('FETCH_MEMBERSHIP_ITEMS')
    const membershipListLoading = state.loading.has('FETCH_MEMBERSHIP_LIST')
    const addMembershipVisitLoading = state.loading.has('ADD_MEMBERSHIP_VISIT')
    const membershipVisitsLoading = state.loading.has('FETCH_MEMBERSHIP_VISITS')
    const updateMembershipVisitLoading = state.loading.has('UPDATE_MEMBERSHIP_VISIT')

    return {
      event,
      memberships,
      membershipsLoading,
      membershipItems,
      membershipItemsLoading,
      addedVisit,
      addMembershipVisitLoading,
      membershipVisits,
      membershipVisitsLoading,
      updatedVisit,
      updateMembershipVisitLoading,
      membershipListLoading,
      membershipList
    }
  },
  {
    FETCH_MEMBERSHIPS,
    FETCH_MEMBERSHIP_ITEMS,
    FETCH_MEMBERSHIP_LIST,
    ADD_MEMBERSHIP_VISIT,
    FETCH_MEMBERSHIP_VISITS,
    UPDATE_MEMBERSHIP_VISIT,
  }
)
export default class EventMembershipNew extends React.Component {
  constructor(props) {
    super(props)
    // plans table columns
    this.plansTableColumns = getTableColumns([
      'name',
      {
        key: 'cost',
        label: 'Face Value',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'price',
        label: 'Price (incl. Fees)',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'duration',
        label: 'Duration',
        normalizer: durationNormalizerCreater('month')
      }
    ])

    // search by following columns
    this.searchBy = ['fullName', 'membershipPlan', 'expirationDate']

    // memberships table columns
    this.membershipsTableColumns = getTableColumns([
      'fullName',
      { key: 'membershipPlan', label: 'Membership' },
      { key: 'expirationDate', normalizer: value => formatDate(value, DAY_DISPLAY_FORMAT) },
      { key: 'usage', className: 'center', isSortable: false }
    ])

    this.state = {
      isRecordCollectedItemModalOpen: false,
      isUsageDetailsModalOpen: false,
      targetMembershipId: null,
      membershipItems: {},
      addVisitData: {}
    }
  }

  componentWillMount() {
    const { history: { push } } = this.props
    if (!this.props.event.flagTimeSlotsEnabled) {
      push('/')
    }
  }

  componentDidMount() {
    const { event, FETCH_MEMBERSHIPS, FETCH_MEMBERSHIP_LIST } = this.props
    FETCH_MEMBERSHIPS(event.id)
    FETCH_MEMBERSHIP_LIST(event.id)
  }

  editMembership = id => {
    const { history: { push }, event } = this.props
    push(`/event/${event.id}/membership/edit/${id}`)
  }

  addMembership = () => {
    const { history: { push }, event } = this.props
    push(`/event/${event.id}/membership/create`)
  }

  getMembershipUsageTableData = (memberships = []) => memberships.map(item => ({
    ...item,
    fullName: `${item.firstName || ''} ${item.lastName || ''}`
  }))

  toggleModal = (actionName, membershipId = null) => {
    if (!membershipId) {
      this.setState({
        [`is${actionName}ModalOpen`]: false
      })
      return
    }
    const { event, FETCH_MEMBERSHIP_ITEMS, FETCH_MEMBERSHIP_VISITS } = this.props
    if (membershipId) {
      this.setState({
        [`is${actionName}ModalOpen`]: !this.state[`is${actionName}ModalOpen`],
        targetMembershipId: membershipId
      })
      switch (actionName) {
      case 'UsageDetails': {
        FETCH_MEMBERSHIP_ITEMS(event.id, membershipId)
        FETCH_MEMBERSHIP_VISITS(event.id, membershipId)
        break
      }
      case 'RecordCollectedItem': {
        FETCH_MEMBERSHIP_ITEMS(event.id, membershipId)
        break
      }
      }
    }
  }

  render() {
    const { membershipsLoading, memberships, membershipList, membershipListLoading } = this.props
    const { isUsageDetailsModalOpen } = this.state
    const membershipUsageTableData = this.getMembershipUsageTableData(memberships)

    return membershipsLoading || membershipListLoading ? (
      <LoadingBar title={"Hold tight! We're getting your event membership details..."} />
    ) : (
      <div>
        <Card className="membership-list" title="Membership Plans">
          <div className="row">
            <div className="div-spacing-10" />
            <div className="col-xs-12">
              <Button className="btn btn-success btn-shadow" onClick={this.addMembership}>
                <i className="fa fa-plus" aria-hidden="true" />Add Membership
              </Button>
            </div>
            <div className="div-spacing-20" />
          </div>
          {this.getMembershipListTable(membershipList)}
        </Card>
        <Card className="membership-usage" title="Membership Usage">
          {this.getMembershipUsageTable(membershipUsageTableData)}
          {this.getUsageDetailsModal()}
          {this.getRecordCollectedItemModal()}
        </Card>
      </div>
    )
  }

  getMembershipListTable = data => data && data.length ? (
    <SortableTable
      data={data}
      tableColumns={this.plansTableColumns}
      enableSort={true}
      statuses={['enabled']}
      actions={[
        {
          label: 'Edit',
          className: 'btn btn-primary',
          icon: 'fa fa-pencil fa-fw',
          onClick: data => this.editMembership(data.id)
        }
      ]}
    />
  ) : (
    <EmptyBar />
  )

  getMembershipUsageTable = data => {
    const updatedData = _map(data, item => ({
      ...item,
      usage: (
        <Button
          id={item.id}
          className="btn btn-primary"
          onClick={e => {
            this.setState({ selectedMembership: item })
            this.toggleModal('UsageDetails', e.currentTarget.id)
          }}
        >
          <i className="fa fa-pencil fa-fw" />
          Edit
        </Button>
      )
    }))
    return data && data.length ? (
      <SortableTableWithSearch
        data={updatedData}
        tableColumns={this.membershipsTableColumns}
        enableSort={true}
        enableCopyTable={true}
        sortBy={{ column: 'fullName', asc: false }}
        searchBy={this.searchBy}
        actionsLabel="Record a Visit"
        actions={[
          {
            label: '',
            className: 'btn btn-plus-round',
            onClick: updatedData => this.toggleModal('RecordCollectedItem', updatedData.id)
          }
        ]}
      />
    ) : (
      <EmptyBar />
    )
  }

  getUsageDetailsModal = () => {
    const {
      membershipItemsLoading,
      membershipItems,
      membershipVisitsLoading,
      membershipVisits,
      event,
      UPDATE_MEMBERSHIP_VISIT
    } = this.props
    const {
      isUsageDetailsModalOpen,
      targetVisitedTicketId,
      targetMembershipId,
      selectedMembership = null
    } = this.state
    return isUsageDetailsModalOpen ? (
      <MembershipUsageModal
        modalTarget="usageDetails"
        event={event}
        updateMembershipVisit={UPDATE_MEMBERSHIP_VISIT}
        toggleModal={this.toggleModal}
        targetMembershipId={targetMembershipId}
        ticketDetails={membershipItems && membershipItems.$original}
        membershipItems={membershipItems}
        isOpen={isUsageDetailsModalOpen}
        isLoading={membershipItemsLoading || membershipVisitsLoading || !isUsageDetailsModalOpen}
        targetVisitedTicketId={targetVisitedTicketId}
        membershipVisits={membershipVisits}
        selectedMembership={selectedMembership}
      />
    ) : null
  }

  getRecordCollectedItemModal = () => {
    const {
      membershipItemsLoading,
      membershipItems,
      addMembershipVisitLoading,
      event,
      ADD_MEMBERSHIP_VISIT
    } = this.props
    const { isRecordCollectedItemModalOpen, targetMembershipId, selectedMembership } = this.state

    return isRecordCollectedItemModalOpen ? (
      <MembershipUsageModal
        modalTarget="addVisit"
        event={event}
        addMembershipVisit={ADD_MEMBERSHIP_VISIT}
        targetMembershipId={targetMembershipId}
        toggleModal={this.toggleModal}
        isOpen={isRecordCollectedItemModalOpen}
        isLoading={
          membershipItemsLoading || addMembershipVisitLoading || !isRecordCollectedItemModalOpen
        }
        membershipItems={membershipItems}
        selectedMembership={selectedMembership}
      />
    ) : null
  }
}
