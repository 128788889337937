import _get from 'lodash/get'

export const showMessage = (
  type = 'error',
  message = 'Error',
  hideAfter = 10,
  showCloseButton = true,
  options
) => {
  Messenger.options = options || {
    extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
    theme: 'future'
  }

  Messenger().post({ type, message, hideAfter, showCloseButton })
}

export const showAxiosError = (
  error,
  defaultMessage = "Something isn't quite right. Please try again.",
  errorTime
) => {
  const { isAxiosError, response, message } = error

  if (message === 'cancel') {
    return
  }

  let error_message = defaultMessage
  if (isAxiosError && response) {
    const { data: { message = '' } = {} } = response
    if (message) {
      error_message = message
    }
  }
  if ((_get(error, 'message', '') || _get(error, 'meta.message', '')).includes('Network Error')) {
    return
  }
  showMessage('error', error_message)
}

export const showSuccessMessage = (message = 'Success!') => {
  showMessage('success', message, 3)
}
