import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_FAQS } = createAsyncAction('FETCH_FAQS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/faqs`, null)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { eid: eid.toString(), faqs: res.data }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_FAQ } = createAsyncAction('CREATE_FAQ', function (eid, faq) {
  const body = {
    data: {
      attributes: {
        title: faq.title,
        isPublic: faq.isPublic,
      },
      type: 'faq',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/faq`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_FAQ } = createAsyncAction('UPDATE_FAQ', function (faq, eid) {
  const body = {
    data: {
      attributes: {
        title: faq.title,
        sortOrder: faq.sortOrder,
        isPublic: faq.isPublic,
      },
      type: 'faq',
    },
  }
  return dispatch =>
    fetchAPI(`/api/faq/${faq.id}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success({ data: res.data, eid }))
        return res
      })
})

const { DELETE_FAQ } = createAsyncAction('DELETE_FAQ', function (fid, eid) {
  return dispatch =>
    fetchAPI(`/api/faq/${fid}`, {
      method: 'DELETE',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { fid: fid.toString(), eid: eid.toString() }
        dispatch(this.success(out))
        return res
      })
})

const { SORT_FAQS } = createAsyncAction('SORT_FAQS', function (eid, faqs) {
  const body = {
    data: {
      attributes: faqs,
      type: 'faq',
      relationships: {
        event: {
          data: { type: 'event', id: eid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/faqs`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { eid: eid.toString(), faqs: res }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_QUESTION } = createAsyncAction('CREATE_QUESTION', function (eid, fid, question) {
  const body = {
    data: {
      attributes: {
        title: question.title,
        content: question.content,
        isPublic: question.isPublic,
      },
      type: 'question',
      relationships: {
        faq: {
          data: { type: 'faq', id: fid },
        },
      },
    },
  }
  return dispatch =>
    fetchAPI(`/api/faq/question`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { eid: eid.toString(), fid: fid.toString(), res }
        dispatch(this.success(out))
        return res
      })
})

const { UPDATE_QUESTION } = createAsyncAction('UPDATE_QUESTION', function (fid, question, eid) {
  const body = {
    data: {
      attributes: {
        title: question.title,
        sortOrder: question.sortOrder,
        content: question.content,
        isPublic: question.isPublic,
      },
      type: 'question',
    },
  }
  return dispatch =>
    fetchAPI(`/api/faq/question/${question.id}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { fid: fid.toString(), qid: (question?.id || '').toString(), res, eid }
        dispatch(this.success(out))
        return res
      })
})

const { DELETE_QUESTION } = createAsyncAction('DELETE_QUESTION', function (fid, qid, eid) {
  return dispatch =>
    fetchAPI(`/api/faq/question/${qid}`, {
      method: 'DELETE',
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const out = { fid: fid.toString(), qid: qid.toString(), res, eid }
        dispatch(this.success(out))
        return res
      })
})

export {
  FETCH_FAQS,
  CREATE_FAQ,
  UPDATE_FAQ,
  DELETE_FAQ,
  SORT_FAQS,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
}
