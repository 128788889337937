// Re-render Optimization 13.05.2020
import React, { PureComponent } from 'react'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'
import { MenuItem } from './_Library.jsx'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { LOGOUT } from '../../_common/redux/auth/actions'
import localforage from 'localforage'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { RN_ROLES } from '../constants/roles'

import { get_event } from '../../_common/core/selectors'
import {
  getEventMenuItems,
  getDraftEventMenuItems,
  getBrandsMenuItems,
  getToursMenuItems,
  getUsersMenuItems,
  getEventsMenuItems,
  getPageInfoData,
} from './utils/index'

@withRouter
@connect(
  (state, props) => {
    const event = get_event(state)
    const brand = state.brands.selected
    const tour = state.tours.selected
    const { user } = state.auth
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
    const { isRnDomain, isMcDomain } = state.shared
    const { influencers } = state.influencers
    const path = _get(props, 'location.pathname')
    return {
      path,
      user,
      event,
      brand,
      tour,
      dynamicConfigs,
      isRnDomain,
      isMcDomain,
      influencers,
    }
  },
  { LOGOUT },
)
export default class Sidebar extends PureComponent {
  constructor(props) {
    super(props)
    this.unMounted = true
    this.state = {
      isMobile: window.innerWidth < 768,
    }
  }

  componentDidMount() {
    this.unMounted = false
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    this.unMounted = true
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    if (this.unMounted) return
    this.setState({
      isMobile: window.innerWidth < 768,
    })
  }

  onChangeLanguage = e => {
    Localize.setLanguage(e.target.value)
  }

  handleLogout = async () => {
    const { LOGOUT } = this.props
    try {
      await LOGOUT()
      this.removeUsersInformationFromStorage()
    } catch (e) {}
  }

  removeUsersInformationFromStorage = () => {
    localStorage.removeItem('EventsPage')
    localStorage.removeItem('EventPagesFilterByBrand')
    localStorage.removeItem('isModalAlreadyShowed')
    localforage.setItem('events', [])
  }

  render() {
    const {
      event,
      path,
      brand,
      tour,
      user,
      dynamicConfigs,
      isRnDomain,
      isMcDomain,
      influencers,
      routePath,
      configFile,
    } = this.props
    const { isMobile } = this.state

    const userTfRole = _get(user, 'tfRole')
    const abbName = _startCase(_get(user, 'firstName', '').charAt(0) + _get(user, 'lastName', '').charAt(0))
    const permission = _get(event, 'self.role', '') || null
    const enablePromotions = _get(event, 'enablePromotions') || false
    const enableGiftCertificates = _get(event, 'flagGiftCertificatesEnabled') || false
    const flagDisableReferral = _get(event, 'flagDisableReferral') || false
    const flagTimeSlotsEnabled = _get(event, 'flagTimeSlotsEnabled') || false
    const showEventCredentials = _get(dynamicConfigs, 'show_event_credentials') || false
    const flagMultiEntryPasses = _get(event, 'multientryPass') || false
    const flagRequireEthereumWallet = _get(event, 'flagRequireEthereumWallet') || false
    const flagPredefinedInvitations = _get(event, 'flagPredefinedInvitations', false)
    const hasInfluencers = !!_get(influencers, 'referrers', []).length
    const routePathArr = routePath.split('/')
    const toBeSpliced = routePathArr.length - 3
    routePathArr.splice(routePathArr.length - toBeSpliced, toBeSpliced, '*')
    const configs = _get(configFile, routePathArr.join('/'))
    const isVerified = _get(event, 'isVerified') || false
    const pageInfoData = getPageInfoData({ path, event, brand, tour })
    const {
      itemId,
      page,
      isHome,
      isEventPage,
      isBrandPage,
      isTourPage,
      isUsersPage,
      isDraftEventsPage,
      isEventsPage,
      isNewEventPage,
      isBrandsPage,
      isNewBrandPage,
      isToursPage,
      isNewTourPage,
    } = pageInfoData

    const menuGroup = []
    menuGroup.push({
      img: 'home',
      title: 'Home',
      href: `/`,
      active: isHome,
      visible: !isRnDomain && !isMcDomain,
    })

    // Events Menu Items
    const menuGroupItemsForEvents = getEventsMenuItems({
      page,
      isEventPage,
      isEventsPage,
      isNewEventPage,
      configs,
    })
    menuGroup.push(...menuGroupItemsForEvents)

    // Event Menu Items
    if (isEventPage && !isBrandPage && page !== '' && !isNewEventPage) {
      const menuGroupItemsForEvent = getEventMenuItems({
        path,
        page,
        event,
        itemId,
        isRnDomain,
        isMcDomain,
        userTfRole,
        permission,
        showEventCredentials,
        enablePromotions,
        flagDisableReferral,
        flagTimeSlotsEnabled,
        enableGiftCertificates,
        flagMultiEntryPasses,
        flagRequireEthereumWallet,
        hasInfluencers,
        configs,
        flagPredefinedInvitations,
        isVerified,
      })
      menuGroup.push(...menuGroupItemsForEvent)
    }

    if (isRnDomain && user && RN_ROLES.hasRoles(_get(user, 'rnRoles'), [RN_ROLES.ADMIN])) {
      const menuGroupItemsForDraftEvents = getDraftEventMenuItems({ isDraftEventsPage })
      menuGroup.push(...menuGroupItemsForDraftEvents)
    }

    // Brands Menu Items
    const menuGroupItemsForBrands = getBrandsMenuItems({ page, isBrandPage, isBrandsPage, isNewBrandPage })
    menuGroup.push(...menuGroupItemsForBrands)

    // Tours Menu Items
    const menuGroupItemsForTours = getToursMenuItems({
      page,
      isTourPage,
      isRnDomain,
      isToursPage,
      isNewTourPage,
      isMcDomain,
    })
    menuGroup.push(...menuGroupItemsForTours)

    if (isRnDomain && RN_ROLES.hasRoles(_get(user, 'rnRoles'), [RN_ROLES.ADMIN])) {
      const menuGroupItemsForUsers = getUsersMenuItems({ isUsersPage })
      menuGroup.push(...menuGroupItemsForUsers)
    }

    const getFirstVisibleItemHref = childGroup => {
      const visibleItem = childGroup.find(item => item.visible && !item.disabled)
      if (visibleItem) {
        return visibleItem.href
      }
      //if messaging menuitem from interact is hidden,then on clicking interact will redirect first visible items href
      return null
    }

    const menuBar = _map(menuGroup, (menu, index) => (
      <div key={index}>
        <MenuItem
          isMobile={isMobile}
          level={menu.level}
          title={menu.title}
          open={menu.open}
          img={menu.img}
          icon={menu.icon}
          href={menu.level === 2 ? getFirstVisibleItemHref(menu.childGroup) : menu.href}
          active={menu.active}
          visible={menu.visible}
          childGroup={menu.childGroup}
          disabled={menu.disabled}
        />
      </div>
    ))

    const bottomPart = (
      <div className="sidebar-bottom">
        <Link className="account-settings-link" to="/account">
          <div className="account-settings-link-ab">{abbName}</div>
          <div className="account-settings-link-name">Account Settings</div>
        </Link>
        <div aria-hidden={true} className="logout" onClick={this.handleLogout}>
          <i className="fa fa-power-off" />
          Log out
        </div>
        <select
          className="form-control language-switcher"
          onChange={this.onChangeLanguage}
          defaultValue={Localize.getLanguage()}
        >
          <option value={'en'}>EN</option>
          <option value={'es'}>ES</option>
        </select>
      </div>
    )

    return isMobile ? (
      <div className="sidebar mobile">
        <PerfectScrollbar>{menuBar}</PerfectScrollbar>
        {bottomPart}
      </div>
    ) : (
      <div className="sidebar">
        {menuBar}
        {bottomPart}
      </div>
    )
  }
}
