import React, { Component } from 'react'
import AdditionalItemsTable from './AdditionalItemsTable'
import { orderBy, upperFirst } from 'lodash'

class FilterDataForItemsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    this.createTableBody(this.props.tableData)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.createTableBody(nextProps.tableData)
    }
  }

  onDropped = isDragging => {
    if (!isDragging) {
      this.createTableBody(orderBy(this.props.tableData, ['sortOrder']))
    }
  }

  createTableBody = data => {
    this.setState(
      {
        tableData: data.map((item, index) => ({
          sortOrder: item.sortOrder,
          index,
          values: [
            <i className="fa fa-arrows" />,
            item.name || '',
            upperFirst((item.type || '').replace('-', ' ')),
            item.quantity || '',
            this.props.getActions(item.sortOrder)
          ]
        }))
      },
      () => {
        this.props.updateAdditionalItemsFieldValues(data)
      }
    )
  }

  render() {
    const { tableData } = this.state
    return (
      <AdditionalItemsTable
        onDropped={this.onDropped}
        updateSortOrder={this.props.updateSortOrder}
        items={tableData}
      />
    )
  }
}

export default FilterDataForItemsTable
