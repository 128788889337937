import _ from 'lodash'
import React from 'react'
import { withFormik } from 'formik'
import Field from '../../_library/Field'
import Button from '../../_library/Button'

function validateNote(data) {
  const required = [
    'note'
  ]
  const errors = {}

  required.forEach((f) => {
    if (!_.get(data, f)) {
      _.set(errors, f, 'Required')
    }
  })

  return errors
}

class MyForm extends React.Component{
  render() {
    const { onCancel, submitLabel } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      dirty,
    } = this.props
    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <Field
              id="note"
              label="Note"
              value={values.note}
              error={errors.note}
              touched={touched.note}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="text-center btn-toolbar">
          <Button type="submit" className="btn btn-primary btn-lg btn-shadow" loading={isSubmitting}>{submitLabel}</Button>
          {onCancel && <Button className="btn btn-danger btn-lg btn-shadow" type="button" disabled={isSubmitting} onClick={onCancel}>Cancel</Button>}
        </div>
      </form>
    )
  }
}

const EventOrderNoteForm = withFormik({
  mapPropsToValues: (props) => ({
    note: _.get(props, 'initialValues.note') || '',
  }),

  // Custom sync validation
  validate: values => validateNote(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit({ ...values })
      .then((v) => {
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
      })
  },
  displayName: 'EventOrderNoteForm', // helps with React DevTools
})(MyForm)

export default EventOrderNoteForm
