import React from 'react'

const bodyWrapper = C => props =>
  (
    <div className="body-main">
      <C {...props} />
    </div>
  )

export { bodyWrapper }
