import _map from 'lodash/map'

export const arrayFromNum = num => {
  if (!Number.isNaN(num)) {
    return _map(new Array(Number(Number(num).toFixed())), (_, i) => ({
      value: i + 1,
      label: i + 1,
    }))
  }

  return []
}
