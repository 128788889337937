import React from 'react'
import { Field } from 'formik'

import Card from '../../../../_library/Card'
import { SimpleField } from '../../../../formik/Fields'

export default class OnlineEventSocialMedia extends React.Component {

  render() {
    const { cardsStatus } = this.props
    return (
      <Card
        icon={'fa-share-square'}
        title={'Social Media'}
        status={cardsStatus && cardsStatus.social}
      >
        <div className="social-media">
          <img src={asset('/resources/images/event-facebook-link.png')} />
          <div className="social-media-link">
            <Field
              name={'facebookEvent'}
              type="text"
              label="Facebook Event URL"
              component={SimpleField}
            />
          </div>
        </div>
        <div className="social-media">
          <img src={asset('/resources/images/event-hashtag-link.png')} />
          <div className="social-media-link">
            <Field
              name={'twitterHashtag'}
              type="text"
              label="Official Event Hashtag"
              component={SimpleField}
            />
          </div>
        </div>
      </Card>
    )
  }
}
