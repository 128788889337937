// Deprecated -- need to merge functionality into Clipboard.jsx
import React from 'react'
import ClipboardButton from 'react-clipboard.js'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'

export default class CustomClipboardButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
    }
  }

  handleSuccess = () => {
    this.setState({ isCopied: true })
  }

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 500)
  }

  handleClick = e => {
    e.stopPropagation()
  }

  getText = value => {
    switch (typeof value) {
      case 'string':
        return value
      case 'object':
        return value.props.children
      default:
        return ''
    }
  }

  render() {
    const { id, clipboardText, tooltipBeforeCopyText, tooltipAfterCopyText, icon, label } = this.props
    const { isCopied } = this.state

    return (
      <div onMouseLeave={this.handleMouseLeave}>
        <ClipboardButton
          component="span"
          data-clipboard-text={this.getText(clipboardText)}
          onSuccess={this.handleSuccess}
          onClick={this.handleClick}
        >
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={id}>{isCopied ? tooltipAfterCopyText || 'Copied' : tooltipBeforeCopyText}</Tooltip>
            }
            trigger={['hover']}
          >
            <span>
              {icon ? <i className={`fa ${icon} event-url-copy`} /> : null}
              {label ? <span className="overlay-label">{label}</span> : null}
            </span>
          </OverlayTrigger>
        </ClipboardButton>
      </div>
    )
  }
}
