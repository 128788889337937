/* eslint-disable react/require-default-props */
// Need to be refactored -- separate functionalities
import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import DropboxChooser from 'react-dropbox-chooser'
import GooglePicker from 'react-google-picker'
import { shortid } from '../../_common/core/rand'
import { fetchAPI } from '../../_common/core/http'
import { loadMediumEditor } from '../../_common/core/lazy_load'
import { ENV } from '../constants/env'
import Button from './Button'
import Image from './Image'

function hasClass(el, className) {
  if (el.classList) {
    el.classList.contains(className)
  } else {
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
  }
}

export default class FileUploader extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onUploadSuccess: PropTypes.func,
    onUploadFailed: PropTypes.func,
    filetype: PropTypes.oneOf(['image', 'archive']),
  }

  constructor(props) {
    super(props)
    this.state = {
      downloading: false,
      uploading: false,
      fileURL: '',
      fileName: '',
    }
    this.widgetId = `#uploader_${shortid()}`
    this.googleAuthToken = null
    this.uploadRequest = null
  }

  onClear() {
    this.setState({
      uploading: false,
      downloading: false,
      fileURL: '',
      fileName: '',
    })
    if (this.props.onChange) {
      this.props.onChange(null)
    }
  }

  onSelectDropboxFile(files) {
    const [file] = files
    if (file && !file.isDir && file.name && file.link) {
      this.setState({ uploading: true })

      axios
        .get(file.link, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          const blob = new Blob([response.data])
          this.setState({ fileName: file.name })
          fetchAPI('/api/uploads', {
            method: 'POST',
            body: JSON.stringify({
              filename: file.name,
            }),
          })
            .then(res => {
              const bodyFormData = new FormData()
              bodyFormData.set('AWSAccessKeyId', res.aws)
              bodyFormData.set('acl', 'public-read')
              bodyFormData.set('success_action_status', 200)
              bodyFormData.set('key', res.key)
              bodyFormData.set('policy', res.policy)
              bodyFormData.set('signature', res.signature)
              bodyFormData.append('file', blob)
              axios({
                method: 'post',
                url: res.url,
                data: bodyFormData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
              })
                .then(_response => {
                  const { onChange, onUploadSuccess } = this.props
                  const fileURL = (ENV.CDN_URL || '') + res.dest

                  this.setState({ uploading: false, fileURL })
                  if (onChange) {
                    onChange(fileURL)
                  }

                  if (onUploadSuccess) {
                    onUploadSuccess(fileURL)
                  }
                })
                .catch(_response => {
                  const { onChange, onUploadFailed } = this.props

                  this.setState({ uploading: false, fileURL: null })
                  if (onChange) {
                    onChange(null)
                  }

                  if (onUploadFailed) {
                    onUploadFailed()
                  }
                })
            })
            .catch(res => {
              this.setState({ uploading: false })
            })
        })
        .catch(response => {
          this.setState({ uploading: false })
        })
    }
  }

  onGoogleAuthenticate(token) {
    this.googleAuthToken = token
  }

  onSelectGoogleDriveFile(data) {
    if (!data || !data.docs || data.docs.length === 0) {
      return
    }
    if (!this.googleAuthToken) {
      return
    }
    const fileId = data.docs[0].id
    const fileUrl = 'https://www.googleapis.com/drive/v2/files/' + fileId + '?alt=media'
    const fileName = data.docs[0].name

    this.setState({ uploading: true })

    axios
      .get(fileUrl, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + this.googleAuthToken,
        },
      })
      .then(response => {
        const blob = new Blob([response.data])
        this.setState({ fileName })
        fetchAPI('/api/uploads', {
          method: 'POST',
          body: JSON.stringify({
            filename: fileName,
          }),
        })
          .then(res => {
            const bodyFormData = new FormData()
            bodyFormData.set('AWSAccessKeyId', res.aws)
            bodyFormData.set('acl', 'public-read')
            bodyFormData.set('success_action_status', 200)
            bodyFormData.set('key', res.key)
            bodyFormData.set('policy', res.policy)
            bodyFormData.set('signature', res.signature)
            bodyFormData.append('file', blob)
            axios({
              method: 'post',
              url: res.url,
              data: bodyFormData,
              config: { headers: { 'Content-Type': 'multipart/form-data' } },
            })
              .then(_response => {
                const { onChange, onUploadSuccess } = this.props
                const fileURL = (ENV.CDN_URL || '') + res.dest

                this.setState({ uploading: false, fileURL })
                if (onChange) {
                  onChange(fileURL)
                }

                if (onUploadSuccess) {
                  onUploadSuccess(fileURL)
                }
              })
              .catch(_response => {
                const { onChange, onUploadFailed } = this.props

                this.setState({ uploading: false, fileURL: null })
                if (onChange) {
                  onChange(null)
                }

                if (onUploadFailed) {
                  onUploadFailed()
                }
              })
          })
          .catch(res => {
            this.setState({ uploading: false })
          })
      })
      .catch(response => {
        this.setState({ uploading: false })
      })
  }

  componentWillUnmount() {
    this.unMounted = true
    loadMediumEditor().then(() => {
      $(this.widgetId).fileupload('destroy')
    })
  }

  componentDidMount() {
    loadMediumEditor().then(() => {
      if (this.unMounted) {
        return
      }
      $(this.widgetId).fileupload({
        forceIframeTransport: hasClass(document.documentElement, 'lte-ie9'),
        autoUpload: true,
        add: (event, data) => {
          const fileName = data.files[0].name
          this.setState({ fileName, uploading: true })
          fetchAPI('/api/uploads', {
            method: 'POST',
            body: JSON.stringify({
              filename: data.files[0].name,
            }),
          }).then(res => {
            // Abort previous pending request
            if (this.uploadRequest) {
              this.uploadRequest.abort()
            }

            const formData = {
              AWSAccessKeyId: res.aws,
              acl: 'public-read',
              success_action_status: 200,
              key: res.key,
              policy: res.policy,
              signature: res.signature,
            }
            if (res.contentType) {
              formData['Content-Type'] = res.contentType
            }
            data.formData = formData
            data.url = res.url
            data.context = { fileURL: (ENV.CDN_URL || '') + res.dest }
            this.uploadRequest = data.submit()
          })
        },
        send: () => {
          this.setState({ uploading: true, fileURL: null })
        },
        fail: (event, data) => {
          const { onChange, onUploadFailed } = this.props

          this.setState({ uploading: false, fileURL: null })
          if (onChange) {
            onChange(null)
          }

          if (onUploadFailed) {
            onUploadFailed()
          }
        },
        done: (event, data) => {
          const { onChange, onUploadSuccess } = this.props
          const { fileURL } = data.context
          const { size } = data.files[0]

          this.setState({ uploading: false, fileURL })
          if (onChange) {
            onChange(`${fileURL}?size=${size}`)
          }

          if (onUploadSuccess) {
            onUploadSuccess(data.context.fileURL)
          }
        },
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        fileURL: this.props.value,
      })
    }
  }

  render() {
    const { label, filetype, value, accept, imgClassName = '', touched, error } = this.props
    const { fileURL, downloading, uploading, fileName } = this.state

    const widgetId = this.widgetId.slice(1)
    const file = value || fileURL

    let fileNode
    if (filetype === 'image') {
      fileNode = (
        <Image
          transition={true}
          key={file}
          className={`${imgClassName} fileuploader-image img-responsive`}
          src={file}
          onLoading={() => {
            this.setState({ downloading: true })
          }}
          onCompleted={() => {
            this.setState({ downloading: false })
          }}
        />
      )
    } else {
      fileNode = (
        <div className="fileuploader-file">
          <i className={'fa ' + (filetype ? `fa-file-${filetype}-o` : 'fa-file-o')} aria-hidden="true" />
          <div>{fileName}</div>
        </div>
      )
    }

    let labelNode
    if (uploading) {
      labelNode = (
        <label className="control-label" htmlFor={widgetId}>
          <i className="fa fa-fw fa-circle-o-notch fa-spin" />
          <div className="label-label">Uploading</div>
        </label>
      )
    } else if (downloading) {
      labelNode = (
        <label className="control-label" htmlFor={widgetId}>
          <i className="fa fa-fw fa-circle-o-notch fa-spin" />
          <div className="label-label">Downloading</div>
        </label>
      )
    } else {
      labelNode = (
        <label className="control-label" htmlFor={widgetId}>
          <img alt="" src={asset('/resources/images/event-add-background.png')} />
          <div className="label-label">{label}</div>
        </label>
      )
    }

    return (
      <div className="file-uploader form-group">
        {touched && error && <div className="file-uploader-error">{error}</div>}
        <div className={'fileuploader ' + (file && !downloading ? 'fileuploader-set' : '')}>
          {file && fileNode}
          {labelNode}
        </div>
        <div className="file-uploader-buttons">
          {ENV.DROPBOX_APP_KEY && (
            <div className="file-uploader-button file-uploader-button-dropbox">
              <DropboxChooser
                appKey={ENV.DROPBOX_APP_KEY}
                success={this.onSelectDropboxFile.bind(this)}
                cancel={() => {}}
                multiselect={false}
                linkType={'direct'}
              >
                <Button className="btn btn-primary btn-shadow" type="button">
                  <img alt="" src={asset('/resources/images/dropbox-ico.svg')} />
                </Button>
              </DropboxChooser>
            </div>
          )}
          {ENV.GOOGLE_DRIVE_CLIENT_ID && (
            <div className="file-uploader-button file-uploader-button-googledrive">
              <GooglePicker
                clientId={ENV.GOOGLE_DRIVE_CLIENT_ID}
                developerKey={ENV.GOOGLE_DRIVE_KEY}
                scope={['https://www.googleapis.com/auth/drive.readonly']}
                onChange={this.onSelectGoogleDriveFile.bind(this)}
                onAuthenticate={this.onGoogleAuthenticate.bind(this)}
                onAuthFailed={data => console.log('on auth failed:', data)}
                multiselect={false}
                navHidden={true}
                authImmediate={false}
              >
                <Button className="btn btn-primary btn-shadow" type="button">
                  <img alt="" src={asset('/resources/images/gdrive-ico.svg')} />
                </Button>
              </GooglePicker>
            </div>
          )}
          {!!file && (
            <div className="file-uploader-button file-uploader-button-clear">
              <Button
                className="btn btn-danger btn-shadow"
                type="button"
                title="Clear image"
                onClick={this.onClear.bind(this)}
              >
                <i className="fa fa-times" /> Clear
              </Button>
            </div>
          )}
        </div>
        <input id={widgetId} type="file" accept={accept || '*'} name="file" style={{ display: 'none' }} />
      </div>
    )
  }
}
