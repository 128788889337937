import React, { useEffect, useState } from 'react'
import { 
  removeCustomerMedia, 
  getCustomerMedias, 
  createCustomerMedia, 
  updateCustomerMedia 
} from '../../../_common/core/http_services'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import EventOrderMediaForm from '../order/MediaForm'
import { useCallback } from 'react'
import ConfirmModal from '../../_library/ConfirmModal'
import { showAxiosError, showSuccessMessage } from '../../utils/messenger'

export const SocialMediaRow = ({ customerId }) => {
  const [socialMedias, setSocialMedias] = useState([])
  const [mediaEditModalShow, setMediaEditModalShow] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [isMediaLoading, setIsMediaLoading] = useState(true)
  const [mediaData, setMediaData] = useState(null)
  const closeDeleteModal = () => {
    setDeleteModalShow(false)
    setMediaData(null)
  }
  const closeMediaEditModal = () => {
    setMediaEditModalShow(false)
    setMediaData(null)
  }
  const openMediaEditModal = mediaData => {
    setMediaData(mediaData)
    setMediaEditModalShow(true)
  }
  const openDeleteModal = mediaData => {
    setMediaData(mediaData)
    setDeleteModalShow(true)
  }
  const fetchMedias = useCallback(async () => {
    try {
      const data = await getCustomerMedias(customerId)
      setSocialMedias(data)
      setIsMediaLoading(false)
    } catch(e) {
      setSocialMedias([])
      setIsMediaLoading(false)
    }
  }, [customerId])
  useEffect(() => {
    fetchMedias()
  }, [fetchMedias])
  const handleMediaSave = async form => {
    try {
      setIsMediaLoading(true)
      setMediaEditModalShow(false)
      if (mediaData) {
        await updateCustomerMedia(customerId, form.name, form.url, mediaData.id)
      } else {
        await createCustomerMedia(customerId, form.name, form.url)
      }
      showSuccessMessage(mediaData ? 'Successfully updated' : 'Successfully added')
      fetchMedias()
    } catch(e) {
      showAxiosError(e)
    }
  }
  const handleMediaDelete = async () => {
    try {
      setIsMediaLoading(true)
      setDeleteModalShow(false)
      await removeCustomerMedia(customerId, mediaData.id)
      await fetchMedias()
      showSuccessMessage('Successfully deleted')
    } catch(e) {
      showAxiosError(e)
    }
  }
  const onConfirmResponse = response => {
    switch(response) {
      case "delete":
        return handleMediaDelete()
      default:
        return closeDeleteModal()
    }
  }
  return (
    <>
      <div className="row-separator" style={{ margin: "16px 0px" }} />
      <div className="row-title row row-content">
        <div className={"col-xs-12 withDecorator ticket-type-bulk-update"}>
          <span>Social Media</span>&nbsp;&nbsp;&nbsp;
          {isMediaLoading && <i className="fa fa-spin fa-spinner" aria-hidden="true" />}
          <button
            type="button"
            className="btn btn-primary btn-shadow pull-right button-event-order"
            onClick={() => openMediaEditModal(null)}
          >
            Add Link
          </button>
        </div>
      </div>
      <div className="div-spacing-20" />
      <div className="row-content row-notes row">
        <div className="col-xs-3">
          <div className="content">
            <div className="field">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Social Media
            </div>
          </div>
        </div>
        <div className="col-xs-9">
          <div className="content">
            <div className="field">URL</div>
          </div>
        </div>
      </div>
      {socialMedias.map(socialMedia => (
        <div key={socialMedia.id}>
          <div className={'row-content row-social-media row note-bg'}>
            <div className="col-xs-3">
              <div className="content">
                <div className="value" style={{ display: 'flex' }}>
                  <i
                    className="fa fa-edit"
                    aria-hidden="true"
                    onClick={() => openMediaEditModal(socialMedia)}
                  />
                    &nbsp;&nbsp;
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    onClick={() => openDeleteModal(socialMedia)}
                  />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>{socialMedia.social_media}</div>
                </div>
              </div>
            </div>
            <div className="col-xs-9">
              <div className="content">
                <div className="value">{`https://${socialMedia.url}`}&nbsp;&nbsp;
                  <span>
                    <a
                      target="_blank"
                      href={`https://${socialMedia.url}`} 
                      className="fa fa-external-link" 
                      aria-hidden="true"
                      rel="noreferrer"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {socialMedias.length === 0 ? <div>No Social Media</div> : null}
      <Modal
        className="modal-dialog modal-trans modal-social-media"
        style={modalStyle}
        isOpen={mediaEditModalShow}
        contentLabel="Modal"
        onRequestClose={closeMediaEditModal}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-event-order-ticket modal-social-media">
          <div className="modal-content">
            <div className="modal-header">{mediaData ? 'Edit' : 'Add'} Social Media</div>
            <div className="modal-body">
              <EventOrderMediaForm
                onSubmit={form => handleMediaSave(form)}
                onCancel={closeMediaEditModal}
                submitLabel={mediaData ? 'Edit' : 'Add'}
                initialValues={mediaData ? { ...mediaData, name: mediaData.social_media } : {}}
              />
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        isOpen={deleteModalShow}
        header="Warning"
        content={
          <div>
            <p>Are you sure you want to delete the social media information for this person?</p>
          </div>
        }
        actions={[
          { value: 'delete', label: 'Delete', className: 'btn btn-success btn-shadow' },
          { value: 'cancel', label: 'Cancel', className: 'btn btn-danger btn-shadow' }
        ]}
        onAction={onConfirmResponse}
        classNames={{
          modal: 'modal-dialog modal-trans modal-medium modal-medium',
          container: 'modal-dialog modal-medium modal-medium'
        }}
      />
    </>
  )
}
