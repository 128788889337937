import { createSelector } from 'reselect'
import _get from 'lodash/get'

export const get_brands = state => _get(state, 'brands.brands')
export const get_brands_pagination = state => _get(state, 'brands.pagination')
export const get_brand = state => _get(state, 'brands.selected')
export const get_brand_pixel_data = state => _get(state, 'brands.brandPixelData')

export const get_sorted_brands = createSelector(get_brands, brands =>
  [...brands].sort((a, b) => a.displayName.trim().localeCompare(b.displayName)),
)
