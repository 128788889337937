import React from 'react'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _find from 'lodash/find'

import Card from '../../../_library/Card'
import Field from '../../../_library/Field'
import Button from '../../../_library/Button'
import Select from '../../../_library/Select'
import RichTextArea from '../../../_library/RichTextArea'
import { ENV } from '../../../constants/env'

import { defaultLanguage } from './index'

export default class EventDescription extends React.PureComponent {
  handleChangeDescription = (val, resetedDescriptions) => {
    const { values, updateField, updateResetedDescriptions, descriptionLanguage } = this.props

    const updatedDescriptions = {
      ...values.descriptions,
      [descriptionLanguage]: val
    }
    const descriptions = resetedDescriptions || this.props.resetedDescriptions
    const updatedResetedDescriptions = !!val
      ? _filter(descriptions, r => r !== descriptionLanguage)
      : descriptions

    updateField('descriptions', updatedDescriptions)
    if (updateResetedDescriptions) {
      updateResetedDescriptions(updatedResetedDescriptions)
    }
  }

  resetDescription = () => {
    const { resetedDescriptions, descriptionLanguage } = this.props
    const isReseted = !!_find(resetedDescriptions, d => d === descriptionLanguage)
    if (!isReseted) {
      const updatedResetedDescriptions = resetedDescriptions
      updatedResetedDescriptions.push(descriptionLanguage)
      this.handleChangeDescription('', updatedResetedDescriptions)
    }
  }

  handleChangeShortDescription = e => {
    const { updateField } = this.props
    const { id, value } = e.target
    let updatedValue = value
    if (value && value.length > 100) {
      updatedValue = value.slice(0, 100)
    }
    updateField(id, updatedValue)
  }

  render() {
    const {
      values,
      errors,
      touched,
      cardsStatus,
      descriptionLanguage,
      languageOptions,
      onLanguageChange,
      isRnDomain,
      closed,
      configs,
      user,
    } = this.props

    const showClearButton = descriptionLanguage !== defaultLanguage
    const fieldId = `description-${descriptionLanguage}`
    const value = values.descriptions ? values.descriptions[descriptionLanguage] : ''
    const showShortDescription = _get(configs, 'appearance.showShortDescriptionField', false)
    const showBriefDescription = _get(configs, 'appearance.showBriefDescriptionField', false)

    return (
      <div className="event-details-description">
        <Card
          closed={closed}
          icon={'fa-pencil-square'}
          title={'Event Description'}
          status={cardsStatus && cardsStatus.description}
          rightAction={
            isRnDomain ? null : (
              <Select
                fieldKey="descriptionLanguage"
                style={{
                  width: 'auto',
                  margin: '0px 15px 0px 0px',
                  height: 30,
                  paddingRight: 30
                }}
                fieldGroupStyle={{ marginTop: 5 }}
                value={descriptionLanguage}
                onChange={onLanguageChange}
                onClick={e => e.stopPropagation()}
                options={languageOptions}
              />
            )
          }
        >
          <div className="row">
            <div className="col-xs-12">
              <RichTextArea
                id={fieldId}
                label=""
                height={700}
                baseurl={ENV.CDN_URL}
                value={value}
                onChange={this.handleChangeDescription}
                error={errors.descriptions}
                touched={touched.descriptions}
                editorStyle={
                  errors.descriptions && touched.descriptions ? { border: '2px solid #d9534f' } : null
                }
                isTTFUser={user?.tfStaff}
              />
            </div>
          </div>
          {showClearButton && (
            <div className="row">
              <div className="col-xs-12" style={{ textAlign: 'center' }}>
                <Button className="btn btn-default btn-shadow" type="button" onClick={this.resetDescription}>
                  Clear and use default description
                </Button>
              </div>
            </div>
          )}
          {showBriefDescription && (
            <>
              <div className="div-spacing-20" />
              <Field
                id="brief"
                label="Short Description (max 100 characters)"
                size="large"
                value={values.brief}
                error={errors.brief}
                touched={touched.brief}
                onChange={this.handleChangeShortDescription}
              />
            </>
          )}
          {showShortDescription && (
            <>
              <div className="div-spacing-20" />
              <Field
                id="shortDescription"
                label="Short Description (max 100 characters)"
                size="large"
                value={values.shortDescription}
                error={errors.shortDescription}
                touched={touched.shortDescription}
                onChange={this.handleChangeShortDescription}
              />
            </>
          )}
        </Card>
      </div>
    )
  }
}
