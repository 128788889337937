import React from 'react'

import EventContainer from '../events/_Container'

const eventWrapper = C => props =>
  (
    <EventContainer>
      <C {...props} isEvent />
    </EventContainer>
  )

export { eventWrapper }
