import _set from 'lodash/set'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import { withFormik } from 'formik'

import modalStyle from '../../../_common/core/modalStyle'
import { extractContent } from '../../../_common/core/utils'
import { ENV } from '../../constants/env'
import Field from '../../_library/Field'
import Button from '../../_library/Button'
import RichTextArea from '../../_library/RichTextArea'
import LoadingBar from '../../_library/LoadingBar'
import SelectTimeSlots from '../../events/_library/SelectTimeSlots/'
import {
  // normalizers
  formatDay,
  formatTime,
} from '../../../_common/core/validation'

import { MultiCheckbox } from '../../formik/Fields'

const getDayAndHour = date => {
  const d = formatDay(date)
  const h = formatTime(date)
  return [d, h]
}
function validateForm(data) {
  const errors = {}
  if (!_get(data, 'subject')) {
    _set(errors, 'subject', 'Required')
  }
  const extract_content = extractContent(_get(data, 'body'))
  const pure_body = extract_content === '+' ? '' : extract_content
  if (!pure_body) {
    _set(errors, 'body', 'Body is Required')
  }
  return errors
}

@withRouter
class EmailToTicketHolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toSelectedAddonHolders: false,
      addOnsStateInitialized: false,
      toTicketHolders: false,
      emailTemplatesModalOpen: false,
      isConfirmingModalOpen: false,
      addOnsWereFetched: false,
      ticketsWereFetched: false,
      checkedAddOnsArray: [],
      selectedNoGroupedTickets: [],
    }
    this.editorRef = React.createRef()
  }

  componentDidMount() {
    const { values, event } = this.props

    if (values.subject === null || values.subject === '') {
      setTimeout(() => {
        this.props.setFieldValue('subject', 'Message to attendees of ' + event.displayName)
      }, 0)
    }
  }

  componentWillUpdate(nextProps) {
    if (
      !_isEqual(this.props.values, nextProps.values) &&
      !_isEqual(this.props.submitCount, nextProps.submitCount)
    ) {
      this.setState({
        toTicketHolders: !!nextProps.values.toSelected,
        toSelectedAddonHolders: !!nextProps.values.toSelectedAddonHolders,
        selectedNoGroupedTickets: [],
      })
    }
  }

  onCheckboxChange = domEvent => {
    const isChecked = domEvent.target.checked
    if (isChecked) {
      const targetCheckboxId = domEvent.target.id
      const { setFieldValue, eventAddOns } = this.props
      const addOnsArray = _map(eventAddOns, item => item.id)
      setFieldValue('toAll', targetCheckboxId === 'toAllTicketHolders')
      setFieldValue('toCheckedInCustomersOnly', targetCheckboxId === 'toCheckedInCustomersOnly')
      setFieldValue('toWaitingList', targetCheckboxId === 'toWaitingList')
      setFieldValue('toBrandSubscribers', targetCheckboxId === 'toBrandSubscribers')
      setFieldValue('toPreregisteredUsers', targetCheckboxId === 'toPreregisteredUsers')
      setFieldValue('toFrozenOrders', targetCheckboxId === 'toFrozenOrders')
      setFieldValue('toRefundedTicketHolders', targetCheckboxId === 'toRefundedTicketHolders')
      setFieldValue('toAllEventTeamMembers', targetCheckboxId === 'toAllEventTeamMembers')
      setFieldValue('toInfluencers', targetCheckboxId === 'toInfluencers')

      setFieldValue('toSelected', '')

      setFieldValue(
        'toSelectedAddonHolders',
        targetCheckboxId === 'toSelectedAddonHolders' ? addOnsArray : [],
      )
      this.setState({
        toSelectedAddonHolders: targetCheckboxId === 'toSelectedAddonHolders',
        toTicketHolders: targetCheckboxId === 'toTicketHolders',
      })
      // before initaliazitation eventAddOns prop will not be available
      if (this.state.addOnsStateInitialized) {
        this.setState({
          checkedAddOnsArray: addOnsArray,
        })
      }
      this.fetchCheckboxRelatedData(targetCheckboxId)
    }
  }

  fetchCheckboxRelatedData = fieldName => {
    const { fetchEventAddOns, fetchEventTickets } = this.props
    switch (fieldName) {
      case 'toTicketHolders': {
        if (!this.state.ticketsWereFetched) {
          fetchEventTickets()
          this.setState({ ticketsWereFetched: true })
        }
        return
      }
      case 'toSelectedAddonHolders': {
        if (!this.state.addOnsWereFetched) {
          fetchEventAddOns()
          this.setState({ addOnsWereFetched: true })
        }
        return
      }
    }
  }

  initializeAddOnsState = () => {
    const addOnIdsArray = _map(this.props.eventAddOns, item => item.id)
    this.setState({ checkedAddOnsArray: addOnIdsArray })
    this.props.setFieldValue('toSelectedAddonHolders', addOnIdsArray)
  }

  onAddOnSelect = addOnId => () => {
    const { checkedAddOnsArray } = this.state
    const addOnIdIndex = checkedAddOnsArray.indexOf(addOnId)
    if (addOnIdIndex !== -1) {
      checkedAddOnsArray.splice(addOnIdIndex, 1)
    } else {
      checkedAddOnsArray.push(addOnId)
    }
    this.setState({ checkedAddOnsArray })
    this.props.setFieldValue('toSelectedAddonHolders', checkedAddOnsArray)
  }

  setEmailTemplatesModalState = value => () => {
    this.setState({
      emailTemplatesModalOpen: value,
    })
  }

  selectEmailTemplate(template) {
    this.props.setFieldValue('subject', template.subject)
    if (template.body) {
      this.editorRef.current?.setContent(template.body)
    } else if (template.preview_url) {
      $.get(template.preview_url, result => {
        this.editorRef.current?.setContent(result)
      })
    }
    this.setState({
      emailTemplatesModalOpen: false,
    })
  }

  onBodyChange = content => this.props.setFieldValue('body', content)

  setConfirmingModalState = value => () => {
    this.setState({ isConfirmingModalOpen: value })
  }

  handleSubmit = () => {
    this.setConfirmingModalState(false)()
    this.props.handleSubmit()
  }

  componentDidUpdate() {
    if (
      !this.state.addOnsStateInitialized &&
      Object.keys(this.props.eventAddOns).length &&
      this.state.toSelectedAddonHolders
    ) {
      this.setState({ addOnsStateInitialized: true })
      this.initializeAddOnsState()
    }
  }

  isSendButtonDisabled = (eventTickets, addOnsList) => {
    const { values } = this.props
    return (
      (this.state.toTicketHolders && (!values.toSelected.length || !eventTickets.length)) ||
      (this.state.toSelectedAddonHolders && (!values.toSelectedAddonHolders.length || !addOnsList.length))
    )
  }
  getOnlyTimeSlotTickets = () => {
    const { eventTickets } = this.props
    try {
      const filteredData = _filter(eventTickets, item => {
        const [d, h] = getDayAndHour(item.startDate)
        item.time = h
        item.date = d
        return item.slotGroupId ? true : false
      })
      return filteredData
    } catch (error) {
      return []
    }
  }

  render() {
    const { emailTemplatesModalOpen, isConfirmingModalOpen, toSelectedAddonHolders, toTicketHolders } =
      this.state
    let {
      event,
      values,
      eventTickets,
      isSubmitting,
      handleChange,
      handleBlur,
      errors,
      touched,
      submitLabel,
      emailTemplates = [],
      eventAddOns,
      eventAddOnsLoading,
      eventTicketsLoading,
      history: { replace },
      configs,
    } = this.props
    const hideRSVPText = _get(configs, 'appearance.hideRSVPText', false)
    const hideAllPreregisteredCustomers = _get(configs, 'appearance.hideAllPreregisteredCustomers', false)
    const hideHoldersOfRefundedTickets = _get(configs, 'appearance.hideHoldersOfRefundedTickets', false)
    const hideHoldersOfSlidingScaleTickets = _get(
      configs,
      'appearance.hideHoldersOfSlidingScaleTickets',
      false,
    )
    const hideHoldersOfSpecificAddOns = _get(configs, 'appearance.hideHoldersOfSpecificAddOns', false)

    if (emailTemplates.length) {
      emailTemplates = emailTemplates.filter(el => el.type === 'messaging' && el.enabled === 1)
    }

    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(eventTickets)

    const addOnsList = _map(eventAddOns, (addOn, index) => (
      <div key={index} className="line addon">
        <div className="line-cell">
          <input
            type="checkbox"
            id={`addon-${addOn.id}`}
            defaultChecked={true}
            name={addOn.id}
            onChange={this.onAddOnSelect(addOn.id)}
          />
          <label htmlFor={`addon-${addOn.id}`} />
        </div>
        <div className="line-cell" style={{ paddingLeft: 10 }}>
          <label htmlFor={`addon-${addOn.id}`}>{addOn.name}</label>
        </div>
      </div>
    ))

    return (
      <form ref="form" onSubmit={e => e.preventDefault()}>
        <div className="EmailToTicketHolders">
          <div className="row">
            <div className="col-xs-12 text-right">
              <div className="btn btn-success btn-shadow" onClick={this.setEmailTemplatesModalState(true)}>
                Select from Email Templates
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Field
                id="subject"
                label="Subject"
                value={values.subject}
                error={errors.subject}
                touched={touched.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllTicketHolders"
                checked={values.toAll}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllTicketHolders">{`All ticket holders${
                hideRSVPText ? '' : ' and RSVPs'
              }`}</label>
            </div>

            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toCheckedInCustomersOnly"
                checked={values.toCheckedInCustomersOnly}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toCheckedInCustomersOnly">Checked in customers only</label>
            </div>

            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toWaitingList"
                checked={values.toWaitingList}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toWaitingList">All waiting list members</label>
            </div>
            {hideAllPreregisteredCustomers ? null : (
              <div className="col-xs-4 col-12 padding-bottom10">
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  id="toPreregisteredUsers"
                  checked={values.toPreregisteredUsers}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="toPreregisteredUsers">All pre-registered customers</label>
              </div>
            )}
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toBrandSubscribers"
                checked={values.toBrandSubscribers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toBrandSubscribers">All event opted-in ticket holders</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllEventTeamMembers"
                checked={values.toAllEventTeamMembers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllEventTeamMembers">All event team members</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toTicketHolders"
                checked={toTicketHolders}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toTicketHolders">Holders of specific tickets</label>
              <br />
            </div>
            {//TODO: add back when the issue is fixed
            /*hideHoldersOfSlidingScaleTickets ? null : (
              <div className="col-xs-4 col-12 padding-bottom10">
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  id="toFrozenOrders"
                  checked={values.toFrozenOrders}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="toFrozenOrders">Holders of sliding scale tickets</label>
              </div>
            )*/}
            {hideHoldersOfSpecificAddOns ? null : (
              <div className="col-xs-4 col-12 padding-bottom10">
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  id="toSelectedAddonHolders"
                  checked={toSelectedAddonHolders}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="toSelectedAddonHolders">Holders of specific add-ons</label>
              </div>
            )}
            {hideHoldersOfRefundedTickets ? null : (
              <div className="col-xs-4 col-12 padding-bottom10">
                <input
                  style={{ marginRight: 10 }}
                  type="checkbox"
                  id="toRefundedTicketHolders"
                  checked={values.toRefundedTicketHolders}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="toRefundedTicketHolders">Holders of refunded tickets</label>
              </div>
            )}
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toInfluencers"
                checked={values.toInfluencers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toInfluencers">All Influencers</label>
            </div>
            <div className="col-xs-12 col-12" />
            {toTicketHolders &&
              (eventTickets.length ? (
                <div
                  style={{ marginLeft: '5px' }}
                  className={'col-xs-12 col-12 tickets ' + (values.toSelected ? 'show' : '')}
                >
                  {noGroupedTickets.length ? (
                    <div className="no-grouped-tickets">
                      <MultiCheckbox
                        options={_map(noGroupedTickets, e => ({
                          value: e.id,
                          label: e.displayName,
                        }))}
                        value={this.state.selectedNoGroupedTickets}
                        onChange={e => {
                          this.setState(() => ({ selectedNoGroupedTickets: e }))
                          this.props.setFieldValue('toSelected', e)
                        }}
                      />
                    </div>
                  ) : null}

                  {groupedTickets.length ? (
                    <SelectTimeSlots
                      groupedTickets={groupedTickets}
                      timeSlotTickets={this.getOnlyTimeSlotTickets()}
                      onChange={v => {
                        this.props.setFieldValue('toSelected', v)
                      }}
                    />
                  ) : null}
                </div>
              ) : eventTicketsLoading ? null : (
                <div className="col-xs-12 col-12">No tickets found for this event</div>
              ))}
            {toSelectedAddonHolders &&
              (addOnsList.length ? (
                <div className="col-xs-8 col-12 tickets">{addOnsList}</div>
              ) : eventAddOnsLoading ? null : (
                <div className="col-xs-12 col-12">No add-ons found for this event</div>
              ))}
          </div>
          {(eventAddOnsLoading || eventTicketsLoading) && <LoadingBar />}
          <div className="row">
            <div className="col-xs-12">Body</div>
            <div className="col-xs-12">
              <RichTextArea
                ref={this.editorRef}
                id="body"
                disableEmbeds={true}
                baseurl={ENV.CDN_URL}
                value={values.body}
                error={errors.body}
                touched={touched.body}
                onChange={this.onBodyChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row text-center">
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="button"
              onClick={this.setConfirmingModalState(true)}
              disabled={this.isSendButtonDisabled(eventTickets, addOnsList)}
            >
              {submitLabel || (
                <span>
                  <i className="fa fa-paper-plane" aria-hidden="true" /> Send
                </span>
              )}
            </Button>
          </div>
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!emailTemplatesModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setEmailTemplatesModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Select Email Template</p>
                </div>
                <div className="modal-body">
                  <div className="emailtemplates-table-container">
                    <div className="email-template-containter">
                      <div className="email-tempalate-title clearfix">
                        <span className="template-left-side">Details</span>
                        <span className="template-right-side mobile-template-action">Action</span>
                      </div>
                      <div className="email-template-content">
                        {emailTemplates &&
                          emailTemplates.map((template, index) => (
                            <div key={index} className="clearfix">
                              <div className="template-list">
                                <div className="template-left-side">
                                  <div>
                                    <b className="template-subtitle">Name:</b>
                                    {template.name}
                                  </div>
                                  <div>
                                    <b className="template-subtitle">Subject:</b>
                                    {template.subject}
                                  </div>
                                  <div>
                                    <b className="template-subtitle">Description:</b>
                                    {template.description}
                                  </div>
                                </div>
                                <div className="template-right-side clearfix">
                                  <span
                                    className="btn btn-primary btn-shadow select-template-btn"
                                    onClick={this.selectEmailTemplate.bind(this, template)}
                                  >
                                    Select
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => replace(`/event/${event.id}/email-templates`)}
                    >
                      Add New Template
                    </Button>
                    <Button
                      className="btn btn-default"
                      type="button"
                      onClick={this.setEmailTemplatesModalState(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans modal-confirming-emailing"
          style={modalStyle}
          isOpen={isConfirmingModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setConfirmingModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Email Content Review</div>
              <div className="modal-body">
                <h4>Subject: {values.subject}</h4>
                <p className="email-body" dangerouslySetInnerHTML={{ __html: values.body }} />
                {toTicketHolders && (
                  <div className="confirmation-message">
                    Are you sure you want to message the selected ticket holders?
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.handleSubmit}
                  >
                    Send Email
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.setConfirmingModalState(false)}
                  >
                    Cancel &amp; Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

export default withFormik({
  mapPropsToValues: props => ({
    subject: _get(props, 'initialValues.attributes.subject') || '',
    body: _get(props, 'initialValues.attributes.body') || '',
    toBrandSubscribers: _get(props, 'initialValues.attributes.toBrandSubscribers') || false,
    toWaitingList: _get(props, 'initialValues.attributes.toWaitingList') || false,
    toAll: _get(props, 'initialValues.attributes.toAll') || true,
    toPreregisteredUsers: false,
    toSelected: _get(props, 'initialValues.attributes.toSelected') || '',
    toSelectedAddonHolders: _get(props, 'initialValues.attributes.toAddOnHolders') || '', // or []
    toFrozenOrders: _get(props, 'initialValues.attributes.toFrozenOrders') || false,
    toRefundedTicketHolders: _get(props, 'initialValues.attributes.toRefundedTicketHolders') || false,
    toAllEventTeamMembers: _get(props, 'initialValues.attributes.toAllEventTeamMembers') || false,
    toInfluencers: _get(props, 'initialValues.attributes.toInfluencers') || false,
  }),

  // Custom sync validation
  validate: (values, props) => validateForm(values, props),

  handleSubmit: (values, { props, setSubmitting, resetForm, setFieldValue }) => {
    props
      .onSubmit({
        ...values,
      })
      .then(v => {
        setSubmitting(false)
        resetForm()
        setFieldValue('subject', 'Message to attendees of ' + props.event.displayName)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'EmailToTicketHolders', // helps with React DevTools
})(EmailToTicketHolders)
