import React, { useState } from 'react'
import { Form, Formik, Field } from 'formik'
import { SimpleField } from '../formik/Fields'
import { requiredValidator } from '../../_common/core/validation'
import { showAxiosError } from '../utils/messenger'

const RnResetPasswordForm = ({ onSubmit, history, tokenValidating, tokenValid }) => {
  const [loading, setLoading] = useState(false)

  return (
    <div className="rn_login_page">
      <div className="rn_login_page__header">
        <div className="rn_login_page__svgContent">
          <div className="rn_login__header__leftside">
            <div className="rn_login_page__image">
              <img src={asset('/resources/images/rn-logo.svg')} alt="no data" />
            </div>
            <div className="rn_login_page__restlessnites__title">Restless Nites</div>
          </div>
          <div className="header-subtext-container">
            {'POWERED BY'}
            <br />
            {'THE'}
            <strong>{'TICKET'}</strong>
            {'FAIRY'}
          </div>
        </div>
        <div className="rn_login_modal-title">Reset Your Password</div>
      </div>
      {!tokenValidating && !tokenValid ? (
        <div className="rn-reset-password-token-invalid">Token is invalid</div>
      ) : tokenValidating ? (
        <div className="rn-reset-password-token-validating">
          <i className="fa fa-fw fa-circle-o-notch fa-spin" />
          <div>Checking Reset Password Hash...</div>
        </div>
      ) : (
        <Formik
          initialValues={{
            password: '',
            repeatPassword: ''
          }}
          onSubmit={values => {
            const { push } = history
            setLoading(true)
            try {
              onSubmit({
                attributes: values
              }).then(data => {
                if (data.success) {
                  push('signin')
                }
              })
            } catch (e) {
              showAxiosError(e)
            } finally {
              setLoading(false)
            }
          }}
          render={() => (
            <Form>
              <div className="rn_forgot_page_content">
                <div className="rn_forgot_input_field">
                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="rn_forgot_input_field">
                  <Field
                    name="repeatPassword"
                    type="password"
                    placeholder="Confirm Password"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="rn_reset_button">
                  <button type="submit" disabled={loading}>
                    RESET PASSWORD
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      )}
    </div>
  )
}

export default RnResetPasswordForm
