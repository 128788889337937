// eslint-disable-next-line import/no-named-as-default
import produce from 'immer'
import handleAction from 'redux-actions/lib/handleAction'
import handleActions from 'redux-actions/lib/handleActions'
import _get from 'lodash/get'
import { createAsyncHandlers } from '../actions'
import { getConfig } from '../../../web/utils/configUtils'

const configFile = getConfig()
const requestSoure = _get(configFile, '*.domain', 'theticketfairy')
const consumers = _get(configFile, 'global.CONSTANTS.MANA_CONSUMERS', [])

const isRnDomain = () => {
  const { host } = window.location
  return requestSoure === 'restlessnites' || host.includes('restlessnites')
}

const isMcDomain = () => {
  const { host } = window.location
  return requestSoure === 'manacommon' || host.includes('manacommon') || host.includes('preproduction-manage')
}

const getManaConsumer = () => {
  const { host } = window.location
  switch (host) {
    case 'preproduction-manage.ticketfairy.com':
      return consumers[3]
    case 'manage-dev.mana1.co':
      return consumers[2]
    case 'manage-staging.mana1.co':
      return consumers[1]
    case 'manage.mana1.co':
      return consumers[0]
    default:
      return consumers[1]
  }
}

const initialState = {
  isRnDomain: isRnDomain(),
  countries: null,
  isMcDomain: isMcDomain(),
  MANA_CONSUMER: getManaConsumer(),
}

const GET_COUNTRIES = createAsyncHandlers('GET_COUNTRIES', {
  success: (state, action) => {
    state.countries = _get(action, 'payload.countries')
  },
})

const SET_COUNTRIES_FROM_INDEXED_DB = handleAction(
  'SET_COUNTRIES_FROM_INDEXED_DB',
  produce((state, action) => {
    const { countries } = action.payload
    state.countries = countries
  }),
  initialState,
)

export default handleActions(
  {
    ...GET_COUNTRIES,
    SET_COUNTRIES_FROM_INDEXED_DB,
  },
  initialState,
)
