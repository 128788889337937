import React from 'react'

import Card from '../../../_library/Card'
import Field from '../../../_library/Field'

export default class EventSocialMedia extends React.PureComponent {
  handleChange = e => {
    const { updateField } = this.props
    let { id, value } = e.target
    if (id === 'facebookEvent') {
      value = value.replace('business.facebook.com', 'www.facebook.com')
    }
    updateField(id, value)
  }

  render() {
    const { values, errors, touched, cardsStatus, isRnDomain, user } = this.props
    return (
      <Card
        icon={'fa-share-square'}
        title={isRnDomain ? 'Additional Information' : 'Social Media'}
        status={cardsStatus && cardsStatus.social}
      >
        {user.tfStaff ? (
          <div className="social-media">
            <img src={asset('/resources/images/event-facebook-link.png')} />
            <div className="social-media-link">
              <Field
                ref="facebookEvent"
                id="facebookEvent"
                label={isRnDomain ? 'Facebook URL' : 'Facebook Event URL'}
                size="large"
                value={values.facebookEvent}
                error={errors.facebookEvent}
                touched={touched.facebookEvent}
                onChange={this.handleChange}
              />
            </div>
          </div>
        ) : null}
        {!isRnDomain && (
          <div className="social-media">
            <img src={asset('/resources/images/event-hashtag-link.png')} />
            <div className="social-media-link">
              <Field
                ref="twitterHashtag"
                id="twitterHashtag"
                label="Official Event Hashtag"
                size="large"
                value={values.twitterHashtag}
                error={errors.twitterHashtag}
                touched={touched.twitterHashtag}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
        {isRnDomain && (
          <div className="social-media">
            <img src={asset('/resources/images/event-url.png')} />
            <div className="social-media-link">
              <Field
                ref="rsvpUrl"
                id="rsvpUrl"
                label="RSVP URL"
                size="large"
                value={values.rsvpUrl}
                error={errors.rsvpUrl}
                touched={touched.rsvpUrl}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
        {isRnDomain && (
          <div className="social-media">
            <img src={asset('/resources/images/event-url.png')} />
            <div className="social-media-link">
              <Field
                ref="externalUrl"
                id="externalUrl"
                label="External Ticketing URL"
                size="large"
                value={values.externalUrl}
                error={errors.externalUrl}
                touched={touched.externalUrl}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
      </Card>
    )
  }
}
