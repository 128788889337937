import React from 'react'
import { connect } from 'react-redux'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import _uniqBy from 'lodash/uniqBy'
import _get from 'lodash/get'
import LoadingBar from '../_library/LoadingBar'
import Button from '../_library/Button'
import Promotion from './promotion/Promotion'
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import { FETCH_PROMOTIONS, CREATE_PROMOTION, UPDATE_PROMOTION } from '../../_common/redux/promotion/actions'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { get_event, get_event_tickets, get_event_promotions } from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const event = get_event(state)
    const tickets = get_event_tickets(state)
    const promotions = get_event_promotions(state)

    return {
      user: state.auth.user,
      event,
      tickets,
      promotions,
    }
  },
  { FETCH_EVENT_TICKETS, FETCH_PROMOTIONS, CREATE_PROMOTION, UPDATE_PROMOTION }
)
export default class EventPromotion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promotions:
        props.promotions && props.promotions.length !== 0
          ? this.getPromotionState(props.promotions, props.tickets)
          : [],
      loadingTickets: false,
      loadingPromotions: false,
      creatingPromotions: false,
      updatingPromotions: false,
      deletingPromotions: false,
      refresh: false,
      sharedQuantityLimit: props.promotions.sharedQuantityLimit || null
    }
  }

  async componentDidMount() {
    const { event: { id, displayName }, FETCH_EVENT_TICKETS, FETCH_PROMOTIONS, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    try {
      this.setState({ loadingTickets: true })
      await FETCH_EVENT_TICKETS(id)
      this.setState({ loadingPromotions: true, loadingTickets: false })
      await FETCH_PROMOTIONS(id)
      this.setState({ loadingPromotions: false })
    } catch (e) {
      this.setState({ loadingTickets: false, loadingPromotions: false })
    }
  }

  getPromotionState = (promotions, tickets) => {
    const newProms = _map(promotions, p => {
      const items = _map(p.items, item => {
        const tSelected = _find(tickets, { id: item.ticketTypeID })
        return {
          ...item,
          ticketName: tSelected ? tSelected.displayName : ''
        }
      })
      return {
        id: p.id,
        name: p.name,
        items,
        codes: p.codes,
        startDate: p.startDate,
        endDate: p.endDate,
        isNew: false,
        expanded: false,
        token: p.token,
        promoCodeTokens: p.promoCodeTokens,
        hasQuantityLimit: p.hasQuantityLimit,
        quantity_limit: p.sharedQuantityLimit
      }
    })
    return newProms
  }

  componentWillReceiveProps(newProps) {
    const { promotions, tickets } = newProps
    const newProms = this.getPromotionState(promotions, tickets)

    if (!_isEqual(promotions, this.props.promotions)) {
      this.setState({
        promotions: newProms
      })
    }
  }

  changeMaxQuantityLimit(value) {
    this.setState({
      sharedQuantityLimit: value
    })
  }

  async refreshPromotions() {
    const { event, FETCH_PROMOTIONS } = this.props
    try {
      this.setState({ loadingPromotions: true, refresh: false })
      await FETCH_PROMOTIONS(event.id)
      this.setState({ loadingPromotions: false, refresh: true })
    } catch (e) {
      this.setState({ loadingPromotions: false, refresh: true })
    }
  }

  newPromotion() {
    const { promotions } = this.state
    if (promotions.find(p => p.isNew)) {
      return
    }
    _map(promotions, (p, no) => {
      p.expanded = false
    })
    promotions.unshift({
      id: new Date().valueOf(),
      name: '',
      items: [],
      codes: [],
      startDate: null,
      endDate: null,
      isNew: true,
      expanded: true,
      quantity_limit: null,
      token: null
    })
    this.setState({
      promotions
    })
  }

  async savePromotion(index) {
    const { promotions, sharedQuantityLimit } = this.state
    const { CREATE_PROMOTION, UPDATE_PROMOTION, FETCH_PROMOTIONS, event } = this.props
    // get promotion object
    const promotion = promotions[index]

    // filter item
    const items = []
    _map(promotion.items, (item, no) => {
      const newItem = {
        ticketTypeID: item.ticketTypeID,
        discountType: item.discountType,
        discountValue: item.discountValue
      }
      // optional
      if (item.quantityLimit != null && item.quantityLimit !== '') newItem.quantityLimit = item.quantityLimit
      if (!promotion.isNew) newItem.id = item.id
      items.push(newItem)
    })

    const form = {
      name: promotion.name,
      sharedQuantityLimit,
      items,
      codes: promotion.codes,
    }

    if (promotion.startDate != null && promotion.startDate !== '') form.startDate = promotion.startDate
    if (promotion.endDate != null && promotion.endDate !== '') form.endDate = promotion.endDate

    // submit
    if (promotion.isNew) {
      try {
        this.setState({ creatingPromotions: true })
        await CREATE_PROMOTION(event.id, form)
        this.setState({ creatingPromotions: false, loadingPromotions: true })
        await FETCH_PROMOTIONS(event.id)
        this.setState({
          loadingPromotions: false
        })
      } catch (e) {
        this.setState({ creatingPromotions: false, loadingPromotions: false })
      }
    } else {
      try {
        this.setState({ updatingPromotions: true })
        await UPDATE_PROMOTION(promotion.id, event.id, form)
        this.setState({ updatingPromotions: false, loadingPromotions: true })
        await FETCH_PROMOTIONS(event.id)
        this.setState({ loadingPromotions: false })
      } catch (e) {
        this.setState({ updatingPromotions: false, loadingPromotions: false })
      }
    }
  }

  async deletePromotion(index) {
    const { promotions } = this.state
    const { UPDATE_PROMOTION, FETCH_PROMOTIONS, event } = this.props

    // get promotion object
    const promotion = promotions[index]

    // filter item
    const items = []
    _map(promotion.items, (item, no) => {
      const newItem = {
        ticketTypeID: item.ticketTypeID,
        discountType: item.discountType,
        discountValue: item.discountValue
      }
      // optional
      if (item.quantityLimit != null && item.quantityLimit !== '') newItem.quantityLimit = item.quantityLimit
      if (!promotion.isNew) newItem.id = item.id
      items.push(newItem)
    })

    const form = {
      name: promotion.name,
      items,
      codes: promotion.codes,
      enabled: false
    }
    if (promotion.startDate != null && promotion.startDate !== '') form.startDate = promotion.startDate
    if (promotion.endDate != null && promotion.endDate !== '') form.endDate = promotion.endDate

    try {
      this.setState({ deletingPromotions: true })
      await UPDATE_PROMOTION(promotion.id, event.id, form)
      this.setState({ deletingPromotions: false, loadingPromotions: true })
      await FETCH_PROMOTIONS(event.id)
      this.setState({ loadingPromotions: false })
    } catch (e) {
      this.setState({ deletingPromotions: false, loadingPromotions: false })
    }
  }

  expandPromotion(index) {
    const { promotions } = this.state
    const newPromotions = _map(promotions, (p, order) => ({
      ...p,
      expanded: order === index
    }))
    this.setState({
      promotions: newPromotions
    })
  }

  collapsePromotion(index) {
    const { promotions } = this.state
    if (promotions[index].isNew) {
      promotions.splice(index, 1)
    } else {
      promotions[index].expanded = false
    }
    this.setState({
      promotions
    })
  }

  changePromotionName(index, val) {
    const { promotions } = this.state
    promotions[index].name = val
    this.setState({
      promotions
    })
  }


  changePromotionCodes(index, val) {
    const { promotions } = this.state
    const uniqedVal = _uniqBy(val, n => n.toLowerCase())

    // get all codes across the event, except for current promotion
    const merged = []
    _map(promotions, (p, order) => {
      if (order != index) {
        _map(p.codes, code => {
          merged.push(code)
        })
      }
    })

    // add current promotion without duplication
    const newVal = []
    _map(uniqedVal, code => {
      const found = merged.indexOf(code)
      if (found === -1) {
        newVal.push(code)
      }
    })

    promotions[index].codes = newVal
    this.changePromoCodeTokens(index, promotions)
    this.setState({
      promotions
    })
  }

  changePromotionStartDate(index, val) {
    const { promotions } = this.state
    promotions[index].startDate = val
    this.setState({
      promotions
    })
  }

  changePromotionEndDate(index, val) {
    const { promotions } = this.state
    promotions[index].endDate = val
    this.setState({
      promotions
    })
  }

  newPromotionItem(index, item) {
    const { promotions } = this.state

    promotions[index].items.push(item)
    this.setState(
      {
        promotions
      },
      () => {
        this.expandPromotion(index)
      }
    )
  }

  deleteItem(indexSection, indexItem) {
    const { promotions } = this.state
    promotions[indexSection].items.splice(indexItem, 1)
    this.setState({
      promotions
    })
  }

  moveItemInsideSection(indexSection, dragIndex, hoverIndex) {
    const { promotions } = this.state
    const dragItem = promotions[indexSection].items[dragIndex]
    promotions[indexSection].items.splice(dragIndex, 1)
    promotions[indexSection].items.splice(hoverIndex, 0, dragItem)
    this.setState({
      promotions
    })
  }

  changePromotionToken(index, value) {
    const { promotions } = this.state
    promotions[index].token = value
    this.setState({
      promotions
    })
  }

  changePromoCodeTokens(i, promotions) {
    const updatedPromotions = promotions
    const { codes } = promotions[i]
    const tokens = _map(codes, c => {
      const promoCodeToken = _find(promotions[i].promoCodeTokens, p => p.code === c)
      if (promoCodeToken) {
        return promoCodeToken
      }
      return { code: c, token: null }
    })
    updatedPromotions[i].promoCodeTokens = tokens
    this.setState({
      promotions: updatedPromotions
    })
  }

  render() {
    const { event, tickets, configs } = this.props
    const currency = getCurrencySymbol(event)
    const {
      promotions,
      loadingTickets,
      loadingPromotions,
      updatingPromotions,
      creatingPromotions,
      deletingPromotions,
      refresh
    } = this.state

    const isDoingSth =
      loadingTickets || loadingPromotions || updatingPromotions || creatingPromotions || deletingPromotions
    return (
      <DisableSectionByPermissionWrapper>
        <div className="eventpromotion">
          {loadingTickets && <LoadingBar title={'Loading tickets...'} />}
          {loadingPromotions && <LoadingBar title={'Loading promotions...'} />}
          {creatingPromotions && <LoadingBar title={'Creating promotion...'} />}
          {updatingPromotions && <LoadingBar title={'Updating promotion...'} />}
          {deletingPromotions && <LoadingBar title={'Deleting promotion...'} />}
          {promotions.length === 0 && !isDoingSth && (
            <div className="newscreen">
              <img src={asset('/resources/images/promotion-empty.png')} alt="" />
              <br />
              <Button
                className="btn btn-success btn-shadow eventpromotion-newpromotion"
                type="button"
                onClick={e => this.newPromotion()}
              >
                <i className="fa fa-plus" aria-hidden="true" />
              Create Promotion
              </Button>
              <div className="newpromotion_text">
                <div className="np_heading">New Promotion</div>
                <div className="np_description">You are not offering any promotion or discount</div>
              </div>
            </div>
          )}
          {promotions.length > 0 && (
            <div className="eventpromotion-buttons">
              <Button
                className="btn btn-success btn-shadow eventpromotion-newpromotion"
                type="button"
                onClick={e => this.newPromotion()}
              >
                <i className="fa fa-plus" aria-hidden="true" />
              Create Promotion
              </Button>
              <Button
                className="btn btn-seablue btn-shadow eventpromotion-newpromotion"
                type="button"
                onClick={e => this.refreshPromotions()}
              >
                <i className="fa fa-fw fa-refresh" />
              Refresh
              </Button>
            </div>
          )}
          {!loadingPromotions && (
            <div>
              {_map(promotions, (promotion, i) => (
                <Promotion
                  id={promotion.id}
                  key={promotion.id}
                  event={event}
                  tickets={tickets}
                  currency={currency}
                  promotion={promotion}
                  onChangeName={val => this.changePromotionName(i, val)}
                  onChangeMaxQuantityLimit={value => this.changeMaxQuantityLimit(value)}
                  onChangePromoCodes={val => this.changePromotionCodes(i, val)}
                  onChangePromoStartDate={val => this.changePromotionStartDate(i, val)}
                  onChangePromoEndDate={val => this.changePromotionEndDate(i, val)}
                  onNewPromotionItem={item => this.newPromotionItem(i, item)}
                  onSavePromotion={() => this.savePromotion(i)}
                  onDeleteItem={index => this.deleteItem(i, index)}
                  onMoveItemInsideSection={(dragIndex, hoverIndex) =>
                    this.moveItemInsideSection(i, dragIndex, hoverIndex)
                  }
                  onDelete={() => this.deletePromotion(i)}
                  onExpand={() => this.expandPromotion(i)}
                  onCollapse={() => this.collapsePromotion(i)}
                  onTokenChange={val => this.changePromotionToken(i, val)}
                  refresh={refresh}
                  configs={_get(configs, 'children.Promotion')}
                />
              ))}
            </div>
          )}
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}
