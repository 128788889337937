/* eslint-disable no-underscore-dangle */
import React from 'react'
import _isFinite from 'lodash/isFinite'
import { Form, Field } from 'formik'
import { MetaMaskIcon } from './MetaMaskIcon'
import { metaMask } from '../../../_common/core/web3/metamask'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Disabled from '../../_library/Disabled'

// form fields
import {
  TextAreaField,
  SimpleField,
  SelectField,
  RadioGroupField,
  FileUploaderField,
  CustomToggleField
} from '../../formik/Fields'

// validators
import {
  // field level validators
  requiredValidator,
  fileSizeLimitValidator,

  // form level validators

  // normalizers

  // utils
  combineValidators
} from '../../../_common/core/validation'

const NFTForm = props => {
  const { 
    values,
    setFieldValue,
    afterCreateDisabled,
    afterDeployDisabled,
    afterSalesDisabled,
    afterMintDisabled,
  } = props


  const onMetamaskConnect = address => {
    setFieldValue('address', address)
  }

  const onArrowClick = (fieldName, type) => {
    let value = values[fieldName]
    value = _isFinite(Number(value)) ? Number(value) : 0
    value = type === 'inc' ? value + 1 : value - 1
    setFieldValue(fieldName, value)
  }

  return (
    <div>
      <Form autoComplete="off">
        <div>
          <h4>General</h4>
          <small>Enter a name and symbol which represents your project and choose a network.</small>
          <div className="row">
            <div className="col-sm-6 col-12">
              <label>
                NFT Collection Name
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id='Collection Name'>A descriptive name for a collection of NFTs in this contract.</Tooltip>
                  }
                >
                  <span>?</span>
                </OverlayTrigger>
              </label>
              <Disabled opacity={0.4} disabled={afterDeployDisabled}>
                <Field
                  name="name"
                  component={SimpleField}
                  validate={requiredValidator()}
                  helperText="e.g. Drink Outside The Box"
                />
              </Disabled>
            </div>
            <div className="col-sm-6 col-12">
              <label>
                NFT Collection Symbol
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id='Collection Symbol'>An abbreviated name for NFTs in this contract.</Tooltip>}
                >
                  <span>?</span>
                </OverlayTrigger>
              </label>
              <Disabled opacity={0.4} disabled={afterDeployDisabled}>
                <Field
                  name="symbol"
                  component={SimpleField}
                  validate={requiredValidator()}
                  helperText="e.g. DOTB"
                />
              </Disabled>
            </div>
          </div>



          <div className="row">
            <div className="col-sm-6 col-12">
              <label>Network</label>
              <Disabled opacity={0.4} disabled={afterDeployDisabled}>
                <Field
                  name="networkType"
                  component={SelectField}
                  options={[
                    { value: 'ethereum', label: 'Ethereum' },
                    { value: 'polygon', label: 'Polygon' },
                  ]}
                  validate={requiredValidator()}
                />
              </Disabled>
            </div>
            <div className="col-sm-6 col-12 metamask-field">
              <label>
                Collection Owner Address
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id='Owner Address'>
                      The address which owns the contract and has the ability to reclaim it on network.
                    </Tooltip>
                  }
                >
                  <span>?</span>
                </OverlayTrigger>
              </label>
              <Disabled opacity={0.4} disabled={afterDeployDisabled}>
                <Field name="address" component={SimpleField} validate={requiredValidator()} />
                {metaMask.isInstalled ? <MetaMaskIcon onConnect={onMetamaskConnect} /> : null}
              </Disabled>
            </div>
          </div>




          <h4>Select when NFT is minted</h4>
          <div className="minted-radio">
          <Disabled opacity={0.4} disabled={afterSalesDisabled}>
            <Field
                name="mintTime"
                component={RadioGroupField}
                groups={[
                  {
                    label: 'When a customer buys a ticket',
                    value: 'buy'
                  },
                  {
                    label: ` When a customer's ticket is scanned`,
                    value: 'scan'
                  }
                ]}
                classNames={{
                  container: 'radio-group-container single-column',
                  container_item: 'radio-group-item',
                  component: ''
                }}
              />
          </Disabled>
          </div>
          <div className="nft-details">
            <h4>NFT Details</h4>
            <div className="row">
              <div className="col-xs-6 col-12">
                <label>Photo/Video</label>
                <Field
                  name="image"
                  filetype="image"
                  label="Upload Photo/Video"
                  validate={combineValidators(requiredValidator(), fileSizeLimitValidator())}
                  component={FileUploaderField}
                  accept="video/*,image/*"
                />
              </div>
              <div className="col-xs-6 col-12 textarea">
                <label>
                  Description
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id='Description-1'>A human readable description of the item.</Tooltip>}
                  >
                    <span>?</span>
                  </OverlayTrigger>
                </label>
                <Field
                  name="description"
                  component={TextAreaField}
                  rows={7}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <label>
                  Token Name
                  <OverlayTrigger placement="top" overlay={<Tooltip id='Token Name-1'>Name of the item.</Tooltip>}>
                    <span>?</span>
                  </OverlayTrigger>
                </label>
                <Field name="tokenName" component={SimpleField} validate={requiredValidator()} />
              </div>
              <div className="col-sm-6 col-12">
                <label>
                  External URL
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id='External URL-1'>This is the URL that will appear to view the item on your site.</Tooltip>
                    }
                  >
                    <span>?</span>
                  </OverlayTrigger>
                </label>
                <Field name="externalUrl" component={SimpleField} />
              </div>
            </div>
          </div>
          {values.mintTime === 'buy' ? (
            <div className="scan-toggle">
              <Disabled opacity={0.4} disabled={afterSalesDisabled}>
                <Field
                  name="afterScan"
                  onText="Change token details after the ticket is scanned"
                  component={CustomToggleField}
                />
              </Disabled>
            </div>
          ) : null}

          {values.afterScan && values.mintTime === 'buy' ? (
            <div className="nft-details">
              <h4>NFT Details after ticket scan</h4>
              <div className="row">
                <div className="col-xs-6 col-12">
                  <label>Photo/Video</label>
                  <Field
                    name="afterScanImage"
                    label="Upload Photo/Video"
                    filetype="image"
                    validate={combineValidators(requiredValidator(), fileSizeLimitValidator())}
                    component={FileUploaderField}
                    accept="video/*,image/*"
                  />
                </div>
                <div className="col-xs-6 col-12 textarea">
                  <label>
                    Description
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id='Description-2'>A human readable description of the item.</Tooltip>}
                    >
                      <span>?</span>
                    </OverlayTrigger>
                  </label>
                  <Field
                    name="afterScanDescription"
                    component={TextAreaField}
                    rows={7}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <label>
                    Token Name
                    <OverlayTrigger placement="top" overlay={<Tooltip id='Token Name-2'>Name of the item.</Tooltip>}>
                      <span>?</span>
                    </OverlayTrigger>
                  </label>
                  <Field
                    name="afterScanTokenName"
                    component={SimpleField}
                    validate={requiredValidator()}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <label>
                    External URL
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id='External URL-2'>
                          This is the URL that will appear to view the item on your site.
                        </Tooltip>
                      }
                    >
                      <span>?</span>
                    </OverlayTrigger>
                  </label>
                  <Field
                    name="afterScanExternalUrl"
                    component={SimpleField}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <h4>Minting</h4>
          <div className="row minting">
            <div className="col-sm-6 col-12">
              <label>
                Secondary Sales Royalties, %
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id='Secondary Sales Royalties'>The percentage you wish to charge as secondary fees.</Tooltip>}
                >
                  <span>?</span>
                </OverlayTrigger>
              </label>
              <Disabled opacity={0.4} disabled={afterDeployDisabled}>
                <Field
                  name="secondarySalesRoyalties"
                  component={SimpleField}
                  type="number"
                  validate={requiredValidator()}
                  className="remove-number-input-arrows"
                />
                <div className="arrows">
                  <button onClick={() => onArrowClick('secondarySalesRoyalties', 'inc')} type="button">
                    <img src="/resources/images/arrow-top.svg" alt="top" />
                  </button>
                  <button onClick={() => onArrowClick('secondarySalesRoyalties', 'dec')} type="button">
                    <img src="/resources/images/arrow-bottom.svg" alt="bottom" />
                  </button>
                </div>
              </Disabled>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export { NFTForm }
