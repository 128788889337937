import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'
import { mergeArraysBy } from '../../core/utils'

const initialState = {
  initial: true,
  fullReady: false,
  total: 0,
  skip: 0,
  draftEvents: [],
  selected: null,
}

const FETCH_DRAFT_EVENTS_INCREMENTAL = createAsyncHandlers('FETCH_DRAFT_EVENTS_INCREMENTAL', {
  success(state, action) {
    const { data, fullReady, pagination } = action.payload
    let total = (data && data.length) || 0
    if (pagination) {
      // eslint-disable-next-line prefer-destructuring
      total = pagination.total
    }

    state.initial = false
    state.fullReady = fullReady
    state.total = total
    state.draftEvents = mergeArraysBy(state.draftEvents, data, 'id')
  },
})

export default handleActions(
  {
    ...FETCH_DRAFT_EVENTS_INCREMENTAL,
  },
  initialState,
)
