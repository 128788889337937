import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUpdateCheck } from 'react-update-notification'
import { toast } from 'react-toastify'

export const MouseflowWrapper = ({ children }) => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 10000,
    ignoreServerCache: true,
  })
  useEffect(() => {
    if (!(status === 'checking' || status === 'current')) {
      toast.info(<div style={{ marginLeft: 11 }}>
        <strong>A new version is available. </strong>Please click here to reload the page.
      </div>, {
        toastId: 'appUpdateAvailable', // Prevent duplicate toasts
        onClick: reloadPage,
        autoClose: false, // Prevents toast from auto closing
        icon: <img src={asset('/resources/images/information-icon.svg')} />
      })
    }
  }, [status, reloadPage])
  const history = useHistory() 
  useEffect(() => {
    if (history.location?.pathname?.includes('/welcome')) {
      window._mfq.push(['newPageView', history.location.pathname])
    }
  }, [])
  useEffect(() => history.listen(location => {
    if (location?.pathname?.includes('/welcome')) {
      window._mfq.push(['newPageView', location.pathname])
    }
  }), [history])
  return (
    <>
      {children}
    </>
  )
} 