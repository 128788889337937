import { MCConfigs } from "./mcConfigs"
import _cloneDeep from "lodash/cloneDeep"

const QAWConfigs = _cloneDeep(MCConfigs)

QAWConfigs.global.CONSTANTS.DOMAINS = ['qawolf']
QAWConfigs.global.CONSTANTS.MANA_CONSUMERS.push('https://tfgatsbyintegrationprerelease.gatsbyjs.io')

for (const key in QAWConfigs) {
  if (QAWConfigs[key]?.messages?.documentTitle) {
    QAWConfigs[key].messages.documentTitle = QAWConfigs[key].messages.documentTitle.replace("Mana Common", "QA Wolf")
  }
  if (QAWConfigs[key]?.messages?.copyright) {
    QAWConfigs[key].messages.copyright = QAWConfigs[key].messages.copyright.replace("Mana Common", "QA Wolf")
  }
}

QAWConfigs['*'].appearance.assets.headerLogo = '/resources/images/manacommon/QA_WOLF_DARK_BACKGROUND.png'
QAWConfigs['*'].appearance.assets.logo = '/resources/images/manacommon/QA_WOLF_LIGHT_BACKGROUND.svg'
QAWConfigs['*'].appearance.icons.favicon = '/resources/images/qaw_favicon.ico'
QAWConfigs['/reset-password'].assets.logo = '/resources/images/manacommon/QA_WOLF_DARK_BACKGROUND.svg'

export { QAWConfigs }