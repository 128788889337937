import React from 'react'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'
import SelectTimeSlotItem from './SelectTimeSlotItem'

import {
  // normalizers
  formatDay,
  formatTime
} from '../../../../_common/core/validation'
import { DAY_DISPLAY_FORMAT } from '../../../constants/timeFormats'

export const defaultValue = { value: 'all', label: 'All' }

const getDayAndHour = date => {
  const d = formatDay(date)
  const h = formatTime(date)
  return [d, h]
}
const getOption = (value, label, id) => ({ value, label })

const getOptions = (timeSlots = []) => {
  const noSelectedDays = new Set()
  const daysMap = new Map()
  timeSlots.forEach(el => {
    const [d, h] = getDayAndHour(el.startDate)
    if (daysMap.has(d)) {
      daysMap.get(d).push(getOption(el.id, h))
    } else {
      noSelectedDays.add(getOption(d, d))
      daysMap.set(d, [getOption(el.id, h)])
    }
  })
  return { noSelectedDays, daysMap }
}

const getAllDaysSlots = daysMap => {
  let ids = []
  daysMap.forEach(el => {
    ids = [...ids, ...getAllDaySlots(el)]
  })
  return ids
}

const getAllDaySlots = daySlots => daySlots.reduce((acm, el) => [...acm, el.value], [])

export const sortArr = (arr = []) => {
  const allOptionIndex = _.findIndex(arr, a => a.label === 'All')
  let allOption = null
  if (allOptionIndex !== -1) {
    allOption = arr[allOptionIndex]
    arr.splice(allOptionIndex, 1)
  }
  arr = arr.sort((a, b) =>
    moment(a.value, DAY_DISPLAY_FORMAT).valueOf() > moment(b.value, DAY_DISPLAY_FORMAT).valueOf()
      ? 1
      : -1
  )
  if (allOption) {
    arr.unshift(allOption)
  }
  return arr
}

export default class SelectTimeSlot extends React.Component {
  constructor(props) {
    super(props)
    const { timeSlots } = props
    const { noSelectedDays, daysMap } = getOptions(timeSlots)
    this.daysMap = daysMap
    this.selectedSlots = new Map()
    this.state = {
      noSelectedDays,
      selectedDays: new Set()
    }
  }

  componentDidMount() {
    const { selectedDays } = this.state
    selectedDays.add(defaultValue)
    this.setState({ selectedDays })
    this.onChange()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.timeSlots.length !== this.props.timeSlots.length) {
      const { noSelectedDays, daysMap } = getOptions(nextProps.timeSlots)
      this.daysMap = daysMap
      this.selectedSlots = new Map()
      this.setState(s => ({
        noSelectedDays,
        selectedDays: new Set()
      }))
    }
  }

  onChange = () => {
    let ids = []
    if (!this.selectedSlots.size) {
      ids = getAllDaysSlots(this.daysMap)
    } else {
      this.selectedSlots.forEach((el, key) => {
        if (!el.length) {
          ids = [...ids, ...getAllDaySlots(this.daysMap.get(key))]
        } else {
          ids = [...ids, ...getAllDaySlots(el)]
        }
      })
    }
    this.props.onChange(ids.join(','))
  }

  addDay = e => {
    const { noSelectedDays, selectedDays } = this.state
    this.selectedSlots.set(e.value, [])
    if (e.value === 'all') {
      this.selectedSlots = new Map()
    } else {
      noSelectedDays.delete(e)
    }
    selectedDays.add(e)
    this.setState(s => ({ noSelectedDays, selectedDays }))
    this.onChange()
  }

  onDeleteDay = e => {
    const { noSelectedDays, selectedDays } = this.state
    noSelectedDays.add(e)
    selectedDays.delete(e)
    if (!selectedDays.size) {
      selectedDays.add(defaultValue)
    }
    this.selectedSlots.delete(e.value)
    this.setState(s => ({ noSelectedDays, selectedDays }))
    this.onChange()
  }

  onChangeDay = (_new, _old) => {
    const { noSelectedDays, selectedDays } = this.state
    noSelectedDays.add(_old).delete(_new)
    selectedDays.add(_new).delete(_old)
    if (_new.value === 'all') {
      this.selectedSlots = new Map()
    } else {
      this.selectedSlots.set(_new.value, []).delete(_old.value)
    }
    this.setState(s => ({ noSelectedDays, selectedDays }))
    this.onChange()
  }

  onChangeTime = (e, d) => {
    this.selectedSlots.set(d.value, e)
    this.onChange()
  }

  render() {
    let { noSelectedDays, selectedDays } = this.state
    noSelectedDays = sortArr([...noSelectedDays])
    selectedDays = [...selectedDays]

    const isAllOptionSelected = _.findIndex(selectedDays, s => s.value === 'all') !== -1
    if (!selectedDays.length) {
      noSelectedDays.unshift(defaultValue)
    } else {
      noSelectedDays = _.filter(noSelectedDays, n => n.value !== 'all')
    }

    return (
      <div style={{ width: 'max-content' }}>
        {selectedDays.map((el, index) => (
          <SelectTimeSlotItem
            key={el.value}
            index={index}
            day={el}
            noSelectedDays={noSelectedDays}
            hours={el.value === 'all' ? [] : this.daysMap.get(el.value)}
            onChangeDay={this.onChangeDay}
            onChangeTime={this.onChangeTime}
            onDelete={this.onDeleteDay}
          />
        ))}
        {noSelectedDays.length && !isAllOptionSelected ? (
          <div>
            <div style={{ display: 'inline-block', width: 300, margin: '5px 10px 5px 20px' }}>
              <Select menuIsOpen onChange={this.addDay} value={null} options={noSelectedDays} />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
