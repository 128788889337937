import { ROLES } from '../../constants/roles'
import { getPermissions } from './permissions'
import _get from 'lodash/get'
import { visibleByConfig } from './visibleByConfig'

export const getEventSupportMenuItems = ({ permission, itemId, path, userTfRole, configs }) => {
  const permissions = getPermissions(permission)
  const isAdminOrOwner = permissions.isAdminOrOwner()
  const isTfSupport = ROLES.is_tf_support(userTfRole)
  const isTtfTeam = ROLES.is_ttf_team(userTfRole)

  const childGroup_support = []
  const isSupportVisible = _get(configs, 'appearance.isSupportVisible', true)

  const isTfSupportOrTtfTeam = isTfSupport || isTtfTeam

  childGroup_support.push({
    img: 'support',
    title: 'Contacts',
    href: `/event/${itemId}/contact`,
    active: path === `/event/${itemId}/contact`,
    visible: visibleByConfig(isSupportVisible, isTfSupportOrTtfTeam, isAdminOrOwner),
  })
  childGroup_support.push({
    img: 'notes',
    title: 'Internal Notes',
    href: `/event/${itemId}/notes`,
    active: path === `/event/${itemId}/notes`,
    visible: isTfSupportOrTtfTeam,
  })

  return childGroup_support
}
