import React from 'react'
import SortableTable from '../../_library/SortableTable'
import { getTableColumns } from '../../utils/sortableTableUtils'

const tableOrdersColumns = getTableColumns(['name', 'quantity'])

const IssueAddOnTable = props => {
  const { data = [], onDeleteRow } = props

  return (
    <>
      <hr styles={{ borderTop: '1px solid #767e8d' }} />
      <SortableTable
        data={data}
        tableColumns={tableOrdersColumns}
        enableCopyTable={false}
        enableSort={false}
        disableMobileView={true}
        actions={[
          {
            label: 'Delete',
            className: 'btn btn-danger',
            icon: 'fa fa-trash',
            onClick: onDeleteRow,
          },
        ]}
      />
      <div className="div-spacing-20" />
    </>
  )
}

export default IssueAddOnTable
