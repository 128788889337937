import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'

const LazyLoadImage = ({ width = 150, height = 150, imageUrl, linkTo = '/', disableLink, ...props }) => (
  <div className="LazyLoad">
    <LazyLoad width={width} height={height} once>
      {!!imageUrl ? (
        disableLink ? (
          <img alt="no data" className="LazyLoadImg" src={imageUrl} />
        ) : (
          <Link to={linkTo}>
            <img alt="no data" className="LazyLoadImg" src={imageUrl} />
          </Link>
        )
      ) : (
        <div>Not set</div>
      )}
    </LazyLoad>
  </div>
)

export default LazyLoadImage
