import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import { ROLES } from '../../constants/roles'

import { UPDATE_EVENT_STATUS } from '../../../_common/redux/events/actions'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'
import SquareButton from '../../_library/SquareButton'

import { PATHS } from '../../constants/paths'
import { ENV } from '../../constants/env'

import { get_event } from '../../../_common/core/selectors'
import { isFullVerified } from '../../utils/checkVerification'
import { getConfig } from '../../utils/configUtils'
import { getPermissions } from '../../_theme/utils'

@withRouter
@connect(
  (state, props) => {
    const event = get_event(state)
    const { user } = state.auth
    const tour = state.tours.selected
    const dynamicConfigs = _get(state.launchDarklyInfo, 'launchDarklyFlags')
    const path = _get(props, 'location.pathname')
    const isRnDomain = _get(state.shared, 'isRnDomain')
    const isMcDomain = _get(state.shared, 'isMcDomain')
    const MANA_CONSUMER = _get(state.shared, 'MANA_CONSUMER')
    return {
      path,
      event,
      user,
      tour,
      dynamicConfigs,
      isRnDomain,
      isMcDomain,
      MANA_CONSUMER,
    }
  },
  { UPDATE_EVENT_STATUS },
)
class EventPublish extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      publishing: false,
      showConfirmModal: false,
      showVerificationModal: false,
    }
  }

  handleIDVerify = () => {
    const {
      history: { push },
    } = this.props
    push('/account')
  }

  handlePreview = type => {
    const { isRnDomain, isMcDomain, MANA_CONSUMER, user } = this.props
    const userTfRole = _get(user, 'tfRole')
    let key = ''
    if (type === 'tour') {
      key = location.pathname.split('/')[2]
    }
    const item = this.props[type]
    const viewURL = isRnDomain
      ? `${ENV.RN_CONSUMER}/events/`
      : isMcDomain
      ? `${MANA_CONSUMER}/events/`
      : `${ENV.API_CONSUMER}/${type}/`
    const role = type === 'event' && !!item ? item.self.role : ''
    const isAdmin =
      type === 'event' && !!item
        ? role === 'admin' || role === 'owner'
        : item.role === 'admin' || item.role === 'owner'
        ? true
        : false
    const isTfSupport = ROLES.is_tf_support(userTfRole)
    const published = !!item
      ? item.status === 'published'
      : item.role === 'admin' || item.role === 'owner'
      ? true
      : false
    const { slug } = item
    if (type === 'tour') {
      window.open(viewURL + slug + '/', '_blank')
    }
    const previewKey = type === 'event' ? item.previewKey : ''
    if (!!published && type === 'event') {
      window.open(viewURL + slug + '/', '_blank')
    } else if (!published && (isAdmin || isTfSupport) && previewKey) {
      window.open(viewURL + slug + '/?pk=' + previewKey, '_blank')
    }
  }

  handleConfirmModal = () => {
    this.setState({ showConfirmModal: false })
  }

  handlePublish = () => {
    const { user } = this.props

    if (this.state.publishing) return

    const isVerified = isFullVerified(_get(user, 'verifyInfo'))
    const configs = getConfig()
    const canSkipIDVerification = _get(configs, 'global.behavior.skipIDVerificationStep', false)

    if (!isVerified && !canSkipIDVerification) {
      this.setState({
        showVerificationModal: true,
      })
    } else {
      this.setState({
        showConfirmModal: true,
      })
    }
  }

  handleCloseVerificationModal = () => {
    this.setState({ showVerificationModal: false })
  }

  processPublish = async () => {
    const { event, UPDATE_EVENT_STATUS } = this.props
    const status = event.status === 'unpublished' ? 'published' : 'unpublished'

    // loading
    const loadingSetter = value => () => {
      this.setState({
        publishing: value,
      })
    }

    // close confirm modal
    this.handleConfirmModal()

    try {
      loadingSetter(true)()
      await UPDATE_EVENT_STATUS(event.id, status)
    } finally {
      loadingSetter(false)()
    }
  }

  render() {
    const { publishing, showConfirmModal, showVerificationModal } = this.state
    const { event, path, tour, dynamicConfigs, user } = this.props

    const role = event ? event.self.role : ''
    const permissions = getPermissions(role)

    const isOnlineEvent = event ? event.isOnlineEvent : false
    const showOnlineEvents = dynamicConfigs ? dynamicConfigs.show_online_events : false

    let isEditSection = false
    let isTourSection = false
    let hidePublish = false
    const isStatsOrLimitedStats = permissions.isStatsOrLimitedStats()
    let isAdmin = permissions.isAdminOrOwner()
    const isEvent = path.indexOf('/event') >= 0
    const isTour = path.indexOf('/tour') >= 0
    const published = event ? event.status === 'published' : false
    const isAccessRole = ROLES.is_tf_support(user?.tfRole) || isAdmin
    const isTfSupportAdminOrClientAdmin = ROLES.is_tf_support_admin_or_client_admin(user?.tfRole)

    if (
      PATHS.isEditLevelPath(path) ||
      PATHS.isInteractLevelPath(path) ||
      PATHS.isSupportLevelPath(path) ||
      PATHS.isStatisticsLevelPath(path) ||
      PATHS.isPerformanceLevelPath(path) ||
      PATHS.isTrafficLevelPath(path)
    ) {
      isEditSection = true
    }
    if (
      PATHS.isSupportLevelPath(path) ||
      PATHS.isStatisticsLevelPath(path) ||
      PATHS.isPerformanceLevelPath(path)
    ) {
      hidePublish = true
    }

    if (PATHS.isTourLevelPath(path)) {
      isTourSection = true
    }

    if (isTour && isTourSection) {
      const tourRole = _get(tour, 'role', '')
      isAdmin = tourRole === 'admin' || tourRole === 'owner'
    }

    if (
      (!isEvent && !isTour) ||
      (!isEditSection && !isTourSection) ||
      (!isEditSection && !(isAdmin || isAccessRole)) ||
      isStatsOrLimitedStats
    ) {
      return null
    }

    return (
      <div className="eventPublish">
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showVerificationModal}
          contentLabel="Modal"
          onRequestClose={this.handleCloseVerificationModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">ID Verification Needed</p>
                </div>
                <div className="modal-body">
                  <p>You need to pass ID Verification process in order to publish event.</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.handleIDVerify}
                    >
                      ID Verify
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={this.handleCloseVerificationModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showConfirmModal}
          contentLabel="Modal"
          onRequestClose={this.handleConfirmModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Are you sure?</p>
                </div>
                <div className="modal-body">
                  <p>Please confirm that you want to {!published ? 'publish' : 'unpublish'} this event?</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.processPublish}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={this.handleConfirmModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <SquareButton
          title="Preview"
          published={published}
          loading={false}
          onBtnClick={() => this.handlePreview(isEvent ? 'event' : 'tour')}
        />
        {!hidePublish && (isTfSupportAdminOrClientAdmin || isAdmin) && (
          <SquareButton
            isBtnVisible={isEditSection}
            title={!published ? 'Publish' : 'Unpublish'}
            loading={publishing}
            onBtnClick={this.handlePublish}
            disabled={isOnlineEvent && !showOnlineEvents}
          />
        )}
      </div>
    )
  }
}

export default EventPublish
