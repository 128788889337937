import React from 'react'

// components
import EmptyBar from '../_library/EmptyBar'
import Button from '../_library/Button'
import { ProductItem } from '.'

class ProductsAddedList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingId: ''
    }
  }

  render() {
    const {
      addedIds,
      allProducts,

      onDelete
    } = this.props

    const { loadingId } = this.state

    if (!addedIds.length) {
      return (<EmptyBar />)
    }

    const clickDelete = async (id) => {
      this.setState(() => ({ loadingId: id }))
      try {
        await onDelete(id)
      } catch (e) {}
      finally {
        this.setState(() => ({ loadingId: '' }))
      }
    }

    const addedProducts = allProducts.filter(el => addedIds.includes(el.id))

    return (
      <div>
        {
          addedProducts.map((el) => (
            <div key={el.id} style={{ margin: '20px', display: 'inline-block' }}>
              <ProductItem product={el} />
              <Button
                style={{ width: '100px', marginTop: '3px' }}
                type='button'
                loading={el.id === loadingId}
                disabled={loadingId}
                className="btn btn-danger"
                onClick={() => clickDelete(el.id)}
              >
                  Delete
              </Button>
            </div>
          ))
        }
      </div>
    )
  }
}

export { ProductsAddedList }