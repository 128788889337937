import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  faqs: {},
}

const FETCH_FAQS = createAsyncHandlers('FETCH_FAQS', {
  success(state, action) {
    const { eid, faqs } = action.payload
    state.faqs[eid] = faqs
  },
})

const CREATE_FAQ = createAsyncHandlers('CREATE_FAQ', {
  success(state, action) {
    const { data } = action.payload
    const eid = _get(data, '$relationships.event.id')
    if (state.faqs[eid]) {
      state.faqs[eid].push(data)
    }
  },
})

const UPDATE_FAQ = createAsyncHandlers('UPDATE_FAQ', {
  success(state, action) {
    const { data, eid } = action.payload
    const fid = _get(data, '$original.attributes.id')
    const index = _findIndex(state.faqs[eid], el => el.id === fid)
    if (index !== -1) {
      state.faqs[eid][index] = data
    }
  },
})

const DELETE_FAQ = createAsyncHandlers('DELETE_FAQ', {
  success(state, action) {
    const { fid, eid } = action.payload
    const index = _findIndex(state.faqs[eid], el => el.id === fid)
    if (index !== -1) {
      state.faqs[eid].splice(index, 1)
    }
  },
})

const SORT_FAQS = createAsyncHandlers('SORT_FAQS', {
  success(state, action) {
    const {
      eid,
      faqs: { data },
    } = action.payload
    state.faqs[eid] = data
  },
})

const CREATE_QUESTION = createAsyncHandlers('CREATE_QUESTION', {
  success(state, action) {
    const {
      eid,
      fid,
      res: { data },
    } = action.payload
    const qid = _get(data, '$original.attributes.id')

    const index = _findIndex(state.faqs[eid], el => el.id === fid)
    if (index !== -1) {
      const faq = state.faqs[eid][index]
      faq.questions.push({
        id: qid,
        title: data.title,
        content: data.content,
        sortOrder: data.sortOrder,
      })
    }
  },
})

const UPDATE_QUESTION = createAsyncHandlers('UPDATE_QUESTION', {
  success(state, action) {
    const {
      fid,
      qid,
      res: { data },
      eid,
    } = action.payload

    const index = _findIndex(state.faqs[eid], el => el.id === fid)
    if (index !== -1) {
      const faq = state.faqs[eid][index]
      const q_index = _findIndex(faq.questions, el => el.id === qid)
      if (q_index !== -1) {
        faq.questions[q_index] = {
          title: data.title,
          content: data.content,
          sortOrder: data.sortOrder,
        }
      }
    }
  },
})

const DELETE_QUESTION = createAsyncHandlers('DELETE_QUESTION', {
  success(state, action) {
    const { fid, qid, res, eid } = action.payload
    if (res.status !== 'success') {
      return
    }
    const index = _findIndex(state.faqs[eid], el => el.id === fid)
    if (index !== -1) {
      const faq = state.faqs[eid][index]
      const q_index = _findIndex(faq.questions, el => el.id === qid)
      if (q_index !== -1) {
        faq.questions.splice(q_index, 1)
      }
    }
  },
})

export default handleActions(
  {
    ...FETCH_FAQS,
    ...CREATE_FAQ,
    ...UPDATE_FAQ,
    ...DELETE_FAQ,
    ...SORT_FAQS,
    ...CREATE_QUESTION,
    ...UPDATE_QUESTION,
    ...DELETE_QUESTION,
  },
  initialState,
)
