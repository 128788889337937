import React from 'react'
import _identity from 'lodash/identity'
import DateTimePickerReact from '../../_library/DateTimePickerReact'

const DateTimePickerFieldReact = ({
  field,
  form: { touched, errors, setFieldValue },
  normalizer = _identity,
  ...props
}) => {
  const onChange = e => {
    setFieldValue(field.name, normalizer(e))
  }
  return (
    <DateTimePickerReact
      {...field}
      {...props}
      id={field.name}
      value={field.value}
      error={errors[field.name]}
      touched={touched[field.name]}
      onChange={onChange}
    />
  )
}

export { DateTimePickerFieldReact }
